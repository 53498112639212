import React, { Component } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { connect } from 'react-redux';

import { getCategoryUrl, getStringCategoriesName } from 'constants/categoryTree/categoryTree';
import getUrlForProduct from 'constants/getUrlForProduct/getUrlForProduct';
import imageSizes from 'constants/imageSizes/imageSizes';
import language, { getLocale } from 'constants/language/language';
import dataTracking from 'constants/trackingAttributes/dataTracking';

import RemoveFromCartLink from 'containers/RemoveFromCart/RemoveFromCartLink';

import AddToCartButton from 'components/molecules/AddToCartButton/AddToCartButton';

import { Col, Row } from 'components/atoms/Grid/Grid';
import Headline from 'components/atoms/Headline/Headline';
import IconLink from 'components/atoms/IconLink/IconLink';
import Icon, { ICON_ADD_CIRCLE_OUTLINE } from 'components/atoms/Icon/Icon';
import Image from 'components/atoms/Image/Image';
import InputSelect from 'components/atoms/InputSelect/InputSelect';
import Price from 'components/atoms/Price/Price';
import ShortenedHeadline from 'components/atoms/ShortenedHeadline/ShortenedHeadline';

import { HeaderListConfig } from './ProductComparisonHeader.config';
import styles from './ProductComparisonListHeader.scss';
import { cypressAttributes } from 'constants/cypress/cypress';
import { productClick } from 'actions/trackingActions/trackingActions';
import { NextFeatureLink } from 'components/atoms/NextFeatureLink';

export const headerSkins = {
  TOP: 'TOP',
  BOTTOM: 'BOTTOM',
};

const mapDispatchToProps = {
  productClick: productClick,
};

class ProductComparisonListHeader extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (JSON.stringify(nextProps.productCodes) !== JSON.stringify(this.props.productCodes)) {
      this.props.getComparisonList(nextProps.productCodes, true);
    }
  }

  getImages = (imageData) => imageData?.sizes || [];

  handleChange = (category) => {
    this.props.changeCategory(category);
  };

  removeProduct = (code) => {
    this.props.removeFromComparison(code, this.props.selectedCategory);
  };

  renderHeadlineWithInput = ({ skin, categoryArray, options }) => {
    if (skin === headerSkins.TOP) {
      return (
        <Col md={3}>
          <Headline margin={HeaderListConfig.headlineMargin}>{language('productComparison.list.header')}</Headline>
          <InputSelect
            value={this.props.selectedCategory || categoryArray[0]}
            options={{ ...options }}
            handleChange={this.handleChange}
            disabled={categoryArray.length === 1}
            cyData={cypressAttributes.product.comparisonCategoriesDropdown}
          />
        </Col>
      );
    }
    return <Col md={3}></Col>;
  };

  renderEmptyProduct = (index) => (
    <Col key={index} md={3} className={styles.emptyCol}>
      <Link
        to={getCategoryUrl(this.props.selectedCategory, getLocale(), this.props.categories)}
        className={styles.linkToCategory}
      >
        <div className={cx(styles.emptyContent, styles[this.props.skin])}>
          <div className={styles.iconPlus}>
            <Icon path={ICON_ADD_CIRCLE_OUTLINE} size={Icon.sizes.xxl} />
          </div>
          <div>{language('productComparison.list.addMore')}</div>
        </div>
      </Link>
    </Col>
  );

  renderComparisonProduct = (index, product) => (
    <Col key={index} md={3} className={styles.productWrapper}>
      <NextFeatureLink
        feature="nextPdpEnabled"
        data-cy={'comparisonProductPDPLink'}
        className={styles.linkToProduct}
        to={getUrlForProduct(
          this.props.selectedCategory,
          product.name,
          product.code,
          getLocale(),
          this.props.categories
        )}
        onClick={() => this.props.productClick(product, '')}
      >
        {product.customImageData?.length && this.props.skin === headerSkins.TOP && (
          <div className={styles.imageWrapper}>
            <Image
              alt={'testImage'}
              images={this.getImages(product.customImageData[0])}
              size={imageSizes.productDetail.size}
              sizes={imageSizes.productDetail.sizes}
              responsive={imageSizes.productDetail.responsive}
              code={product.customImageData[0].code}
            />
          </div>
        )}
        <div className={styles.priceTag}>
          <Price productPriceData={product.productPriceData} className={styles.priceTag} showInsteadPrice />
        </div>
        <div className={styles.productTitleHeight}>
          <ShortenedHeadline text={product.name} />
        </div>
      </NextFeatureLink>
      <div className={styles.buttonContainer}>
        <AddToCartButton
          product={product}
          buttonText={language('product.addToCart')}
          cyData={cypressAttributes.comparisonList.addToCartButton}
          productAttributes={{ elementTitle: product.elementTitle }}
        />
        <div className={styles.removeLink}>
          <IconLink onClick={() => this.removeProduct(product.code)}>
            <RemoveFromCartLink
              trackingData={dataTracking({
                TYPE: 'compare',
                ATTRIBUTE_1: 'remove',
                SKU: product.code,
              })}
              cyData={cypressAttributes.comparisonList.removeFromComparisonListLink}
            />
          </IconLink>
        </div>
      </div>
    </Col>
  );

  render() {
    const { products, categories, comparisonCategories, skin } = this.props;

    const productsArray = products;
    const categoryArray = Object.keys(comparisonCategories);

    // Fills the array up with empty entries, up to 3
    while (productsArray.length < 3) {
      productsArray.push('');
    }

    const options = {};
    categoryArray.forEach((category) => {
      options[category] = getStringCategoriesName(category, getLocale(), categories);
    });

    return (
      <Row className={styles.headerWrapper}>
        {this.renderHeadlineWithInput({ skin, categoryArray, options })}
        {productsArray.map((product, index) =>
          !product.code ? this.renderEmptyProduct(index) : this.renderComparisonProduct(index, product)
        )}
      </Row>
    );
  }
}

ProductComparisonListHeader.displayName = 'molecules/ProductComparisonListHeader';
ProductComparisonListHeader.propTypes = {
  addToWatchlist: PropTypes.func,
  categories: PropTypes.object,
  changeCategory: PropTypes.func,
  comparisonCategories: PropTypes.object,
  getComparisonList: PropTypes.func,
  isProductInWatchlist: PropTypes.func,
  productCodes: PropTypes.array,
  products: PropTypes.array,
  removeFromComparison: PropTypes.func,
  removeFromWatchlist: PropTypes.func,
  selectedCategory: PropTypes.string,
  skin: PropTypes.string,
  productClick: PropTypes.func,
};
ProductComparisonListHeader.defaultProps = {
  addToWatchlist: () => {},
  categories: {},
  changeCategory: () => {},
  comparisonCategories: {},
  getComparisonList: () => {},
  isProductInWatchlist: () => {},
  productCodes: [],
  products: [],
  removeFromComparison: () => {},
  removeFromWatchlist: () => {},
  selectedCategory: '',
  skin: headerSkins.TOP,
  productClick: () => {},
};

export default connect(undefined, mapDispatchToProps)(ProductComparisonListHeader);
