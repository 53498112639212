import React from 'react';
import PropTypes from 'prop-types';

import AccountRatingList from 'components/molecules/AccountRatingList/AccountRatingList';
import CmsPagination from 'components/molecules/CmsPagination/CmsPagination';
import Headline from 'components/atoms/Headline/Headline';
import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';

import language, { getLocale } from 'constants/language/language';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import { ACCOUNT, ACCOUNT_RATINGS, ACCOUNT_RATINGS_NOTRATED } from 'constants/routePaths/routePaths';
import styles from './AccountRatingBox.scss';

const AccountRatingBox = ({
  headline,
  ratings = [],
  footerBtn = {
    name: language('myAccountRatings.showAll'),
    href: mapPathToLocalizedUrl(getLocale(), [ACCOUNT, ACCOUNT_RATINGS, ACCOUNT_RATINGS_NOTRATED]),
  },
  showPagination,
  totalRatings = 0,
  currentPage,
  numberOfPages,
  itemsPerPage = 3,
  rated,
  // handler
  onItemClick,
  onPageChange,
  onToggleDeleteConfirmation,
  categories,
}) => {
  const renderContent = () => {
    // limit entries to max items per page
    const entries = ratings.slice(0, itemsPerPage);

    const backButton = (
      <ButtonLink
        href={mapPathToLocalizedUrl(getLocale(), [ACCOUNT, ACCOUNT_RATINGS])}
        variety={ButtonLink.varieties.stroked}
      >
        {language('myAccountRatings.backToRatings')}
      </ButtonLink>
    );

    if (!ratings.length) {
      return (
        <>
          <p className={styles.emptyMsg}>
            {language(`myAccountRatings.${rated ? 'ratedEmptyMsg' : 'notRatedEmptyMsg'}`)}
          </p>
          {showPagination && <div className={styles.footer}>{backButton}</div>}
        </>
      );
    }

    return (
      <>
        <AccountRatingList
          ratings={entries}
          rated={rated}
          onItemClick={onItemClick}
          onItemDelete={onToggleDeleteConfirmation}
          categories={categories}
        />
        {showPagination && (
          <div className={styles.paginationWrapper}>
            <CmsPagination currentPage={currentPage} totalPages={numberOfPages} changePage={onPageChange} />
          </div>
        )}
        <div className={styles.footer}>
          {showPagination && backButton}
          {!showPagination && totalRatings > itemsPerPage && (
            <ButtonLink href={footerBtn.href} variety={ButtonLink.varieties.stroked}>
              {footerBtn.name}
            </ButtonLink>
          )}
        </div>
      </>
    );
  };

  return (
    <div className={styles.accountRatingBox}>
      {headline && (
        <Headline tag={Headline.tags.H2} margin={Headline.margins.MB_15}>
          {headline} {totalRatings > 0 && `(${totalRatings})`}
        </Headline>
      )}
      {renderContent()}
    </div>
  );
};

AccountRatingBox.displayName = 'organisms/AccountRatingBox';

AccountRatingBox.propTypes = {
  headline: PropTypes.string,
  rated: PropTypes.bool,
  showPagination: PropTypes.bool,
  footerBtn: PropTypes.shape({
    name: PropTypes.string,
    href: PropTypes.string,
  }),
  ratings: PropTypes.array,
  itemsPerPage: PropTypes.number,
  totalRatings: PropTypes.number,
  currentPage: PropTypes.number,
  numberOfPages: PropTypes.number,
  onItemClick: PropTypes.func,
  onPageChange: PropTypes.func,
  onToggleDeleteConfirmation: PropTypes.func,
  categories: PropTypes.object,
};

export default AccountRatingBox;
