import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { optinNewsletter as optinNewsletterAction } from 'actions/newsletterActions/newsletterActions';
import { apiStatus } from 'constants/apiStatus/apiStatus';
import language, { getLocale } from 'constants/language/language';
import { newsletterFeedbackTypes } from 'constants/newsletterFeedbackTypes/newsletterFeedbackTypes';
import { NEWSLETTER } from 'constants/routePaths/routePaths';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import Meta from 'containers/Meta/Meta';

const mapStateToProps = (state, ownProps) => ({
  hash: ownProps?.location?.query?.hash,
  newsletterStatus: state?.user?.newsletterStatus,
  newsletterApiStatus: state?.user?.newsletterApiStatus,
});
const mapDispatchToProps = {
  optinNewsletter: optinNewsletterAction,
};

const Optin = ({
  hash = '',
  optinNewsletter = () => {},
  newsletterStatus = newsletterFeedbackTypes.VERIFICATION,
  newsletterApiStatus = apiStatus.request,
}) => {
  useEffect(() => {
    if (hash) {
      optinNewsletter(encodeURIComponent(hash)).then(() => {
        if (newsletterApiStatus === apiStatus.failure) {
          pushTo(newsletterFeedbackTypes.FAILURE);
        } else {
          pushTo(newsletterStatus.toLowerCase());
        }
      });
    } else {
      pushTo(newsletterFeedbackTypes.FAILURE);
    }
  }, []);

  const pushTo = (path) => {
    browserHistory.push(mapPathToLocalizedUrl(getLocale(), [NEWSLETTER, path]));
  };

  return <Meta title={language('pageTitles.newsletterOptin')} meta={[{ name: 'robots', content: 'noindex' }]} />;
};

Optin.displayName = 'routes/Optin';
Optin.propTypes = {
  hash: PropTypes.string,
  optinNewsletter: PropTypes.func,
  newsletterStatus: PropTypes.string,
  newsletterApiStatus: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(Optin);
