import PropTypes from 'prop-types';
import { cloneElement, useEffect, useState } from 'react';
import { getCategoryMapByPriority2, hasNewCategoryLevels } from 'constants/categoryTree/categoryTree';

const CategoryDrilldownContainer = ({
  children,
  categories,
  categoryCode,
  categoryTree,
  resetNavigation,
  canDrill,
  setCanDrill,
}) => {
  const [drilledCategoryTree, setDrilledCategoryTree] = useState([]);
  const [categoryParents, setCategoryParents] = useState([categoryCode]);
  const [desktopCategoryParents, setDesktopCategoryParents] = useState([]);

  useEffect(() => {
    const parentLevel = categories[categoryCode]?.level;
    const initialCategories = getCategoryMapByPriority2(categories, { level: parentLevel + 1, parent: categoryCode });
    const lastCategoryCode = categoryTree?.slice(-1)[0];
    setDrilledCategoryTree(initialCategories);
    setCanDrill(hasNewCategoryLevels(categories, initialCategories));
    setCategoryParents(lastCategoryCode === categoryCode ? [...categoryTree] : [categoryCode]);
    if (categoryTree?.length) setDesktopCategoryParents(Array.from(new Set([...categoryTree, categoryCode])));
  }, [categories, categoryCode, categoryTree]);

  const drillToCategory = (categoryId) => {
    const currentLevel = categories[categoryId].level;
    const nextCategories = getCategoryMapByPriority2(categories, { level: currentLevel + 1, parent: categoryId });
    setDrilledCategoryTree(nextCategories);
    const parents = categoryParents;
    if (parents.length > currentLevel) {
      parents.splice(currentLevel);
    } else {
      parents.push(categoryId);
    }
    setCategoryParents(parents);
    setCanDrill(hasNewCategoryLevels(categories, nextCategories));
  };

  return cloneElement(children, {
    categories,
    categoryTree,
    drilledCategoryTree,
    drillToCategory,
    categoryParents,
    desktopCategoryParents,
    resetNavigation,
    canDrill,
  });
};

export default CategoryDrilldownContainer;

CategoryDrilldownContainer.propTypes = {
  categories: PropTypes.object,
  categoryCode: PropTypes.string,
  categoryTree: PropTypes.array,
  resetNavigation: PropTypes.func,
  canDrill: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  setCanDrill: PropTypes.func,
};

CategoryDrilldownContainer.defaultProps = {
  categories: {},
};
