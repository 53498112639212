import React, { useEffect, useRef, useState } from 'react';
import Markdown from 'markdown-to-jsx';
import Recaptcha from 'react-recaptcha';
import { verifyRecaptcha } from 'actions/userActions/userActions';
import OrderAPI from 'api/OrderAPI/OrderAPI';
import postLog from 'api/promiseMiddleware';
import language, { getLocale } from 'constants/language/language';
import { types } from 'constants/flashMessages/flashMessages';
import { ValidationTypes } from 'constants/validation/validation';
import { getOrderDocumentType } from 'constants/documents/documents';
import { recaptchaAreas } from 'constants/recaptcha/recaptchaAreas';
import { apiResponseRecaptchaNeeded } from 'constants/apiStatus/apiStatus';
import FieldSetInput from 'components/molecules/FieldSetInput/FieldSetInput';
import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';
import Headline from 'components/atoms/Headline/Headline';
import { Row, Col } from 'components/atoms/Grid/Grid';
import InputText from 'components/atoms/InputText/InputText';
import InputCheckbox from 'components/atoms/InputCheckbox/InputCheckbox';
import FlashMessage from 'components/atoms/FlashMessage/FlashMessage';
import styles from './OrderDocuments.scss';

const recaptchaKey = __CLIENT__ && window.__ENV_VARIABLES__.recaptchaKey;
const OrderDocuments = () => {
  const [isCaptchaShown, setIsCaptchaShown] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isReceiptSelected, setIsReceiptSelected] = useState(true);
  const [isWarrantySelected, setIsWarrantySelected] = useState(true);
  const [orderNumber, setOrderNumber] = useState({
    value: '',
    error: ValidationTypes.Valid,
    isTouched: false,
  });
  const [lastName, setLastName] = useState({
    value: '',
    error: ValidationTypes.Valid,
    isTouched: false,
  });
  const recaptchaRef = useRef(null);

  useEffect(() => {
    if (
      !isCaptchaShown &&
      orderNumber.error === ValidationTypes.Valid &&
      lastName.error === ValidationTypes.Valid &&
      orderNumber.value &&
      lastName.value &&
      (isReceiptSelected || isWarrantySelected)
    ) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [orderNumber, lastName, isReceiptSelected, isWarrantySelected, isCaptchaShown]);

  useEffect(() => {
    if (orderNumber.isTouched && orderNumber.error !== ValidationTypes.Valid && orderNumber.value) {
      setOrderNumber({ ...orderNumber, error: ValidationTypes.Valid });
    }
  }, [orderNumber]);

  useEffect(() => {
    if (lastName.isTouched && lastName.error !== ValidationTypes.Valid && lastName.value) {
      setLastName({ ...lastName, error: ValidationTypes.Valid });
    }
  }, [lastName]);

  const validateInputFields = () => {
    if (orderNumber.isTouched && !orderNumber.value) {
      setOrderNumber({
        ...orderNumber,
        error: ValidationTypes.Missing,
      });
    }

    if (lastName.isTouched && !lastName.value) {
      setLastName({
        ...lastName,
        error: ValidationTypes.Missing,
      });
    }
  };

  const captchaOnloadCallback = () => {
    setIsCaptchaShown(true);
  };
  const captchaVerifyCallback = (response) => {
    verifyRecaptcha(response, orderNumber.value, recaptchaAreas.anonymousOrderPdfArea)
      .promise.then(() => {
        setIsCaptchaShown(false);
      })
      .catch(() => {
        recaptchaRef.reset();
      });
  };

  const handleClick = () => {
    OrderAPI.requestOrderDocuments(
      orderNumber.value,
      lastName.value,
      getOrderDocumentType(isReceiptSelected, isWarrantySelected)
    )
      .then(() => setIsEmailSent(true))
      .catch((e) => {
        postLog(e);
        setOrderNumber((prevProps) => ({
          ...prevProps,
          isTouched: false,
          error: ValidationTypes.Invalid,
        }));
        setLastName((prevProps) => ({
          ...prevProps,
          isTouched: false,
          error: ValidationTypes.Invalid,
        }));

        if (e.response?.data?.errors?.[0]?.type === apiResponseRecaptchaNeeded) {
          setIsCaptchaShown(true);
        }
      });
  };

  return (
    <Row>
      <Col md={6}>
        <div>
          <Headline tag={Headline.tags.H1}>{language('orderDocuments.title')}</Headline>
          <p>{language('orderDocuments.description')}</p>
          <ul className={styles.additionalInformation}>
            {language('orderDocuments.additionalInformation').map((information, index) => (
              <li key={index}>
                <Markdown>{information}</Markdown>
              </li>
            ))}
          </ul>
        </div>
        {isEmailSent ? (
          <FlashMessage type={types.SUCCESS}>{language('orderDocuments.flashMessage')}</FlashMessage>
        ) : (
          <>
            <div className={styles.documentSelection}>
              <Headline tag={Headline.tags.H4}>{language('orderDocuments.form.title')}</Headline>
              <InputCheckbox onClick={() => setIsReceiptSelected(!isReceiptSelected)} selected={isReceiptSelected}>
                {language('orderDocuments.form.receipt')}
              </InputCheckbox>
              <InputCheckbox onClick={() => setIsWarrantySelected(!isWarrantySelected)} selected={isWarrantySelected}>
                {language('orderDocuments.form.warranty')}
              </InputCheckbox>
            </div>

            <div>
              <FieldSetInput
                name={'orderNumber'}
                handleBlur={() => validateInputFields()}
                handleFocus={() => setOrderNumber({ ...orderNumber, isTouched: true })}
                handleChange={(value) => setOrderNumber({ ...orderNumber, value })}
                forceValidation={!!orderNumber.error}
                validationResult={orderNumber.error}
                value={orderNumber.value}
              >
                <InputText type={'number'} />
              </FieldSetInput>
              <FieldSetInput
                name={'lastName'}
                handleBlur={() => validateInputFields()}
                handleFocus={() => setLastName({ ...lastName, isTouched: true })}
                handleChange={(value) => setLastName({ ...lastName, value })}
                forceValidation={!!lastName.error}
                validationResult={lastName.error}
                value={lastName.value}
              >
                <InputText />
              </FieldSetInput>
              {isCaptchaShown && (
                <div className={styles.captcha}>
                  <Recaptcha
                    ref={recaptchaRef}
                    sitekey={recaptchaKey}
                    verifyCallback={captchaVerifyCallback}
                    onloadCallback={captchaOnloadCallback}
                    hl={getLocale()}
                  />
                </div>
              )}
              <ButtonLink onClick={handleClick} disabled={isButtonDisabled}>
                {language('newsletterFeedback.userDataForm.submit')}
              </ButtonLink>
            </div>
          </>
        )}
      </Col>
    </Row>
  );
};

OrderDocuments.displayName = 'organisms/OrderDocuments';

export default OrderDocuments;
