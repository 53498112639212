import { set as _set } from 'lodash/fp';
import has from 'constants/helper/has';
import omit from 'constants/helper/omit';

/**
 * This function is similar to _.set() but instead of always returning a new object it changes as little as possible.
 * It takes a string `path` consisting of the nested keys (eg. `some.nested[0].keys.here`), tries to find it in `state`,
 * and if every key (but the last two) exist it sets it to `value`.
 * @param state {object}
 * @param path {string}
 * @param value {anything}
 * @returns state {object}
 */
export const set = (state, path, value) => {
  if (path === undefined || path === '') return state;

  const pathParts = path.split('.');

  if (pathParts.length > 2) {
    const pathWithoutLastKeys = pathParts.slice(0, -2).join('.');
    if (!has(state, pathWithoutLastKeys)) {
      throw new RangeError(`path ${path} not found in state.`);
    }
  }

  return _set(path, value, state);
};

/**
  This is a chainable helper function to change the redux state in reducers
  without error-prone destructuring, or expensive calls to _.cloneDeep()
  Call this function with the old state, and then on the returned value you can call
  `set` with a path and value to add or edit values to the state,
  `setSingleKey` with a key (can contain dots) and value,
  `omit` with a path to remove a value from the state.
  At the end call `value()` to get the changed state.
*/
export default (state) => {
  let _state = state;

  const out = {
    value: () => _state,

    set: (path, value) => {
      _state = set(_state, path, value);
      return out;
    },

    setSingleKey: (key, value) => {
      _state = {
        ..._state,
        [key]: value,
      };
      return out;
    },

    omit: (path) => {
      _state = omit(_state, [path]);
      return out;
    },
  };
  return out;
};
