import pull from 'constants/helper/pull';

export const showServices = (quantity) => quantity < 2;

export const countTimesAddedAsAccessory = (accessories, code) =>
  accessories.filter((accessoryCode) => accessoryCode === code).length;

export const updateServices = (updateEntry, serviceItemCodes, entryID, productCode, quantity) => {
  return (code, incompatibleServiceItemCodes = []) => {
    let newServiceItemCodes = [...serviceItemCodes];

    if (serviceItemCodes.includes(code)) {
      newServiceItemCodes = pull(serviceItemCodes, code);
    } else {
      if (serviceItemCodes.includes(incompatibleServiceItemCodes[0])) {
        newServiceItemCodes = pull(serviceItemCodes, incompatibleServiceItemCodes[0]);
      }
      newServiceItemCodes.push(code);
    }

    updateEntry(entryID, productCode, quantity, newServiceItemCodes);
  };
};
