import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Icon from 'components/atoms/Icon/Icon';
import styles from './InteractionIcon.scss';

const InteractionIcon = ({ className, isAdded = false, size = Icon.sizes.md, addedIcon, cyData }) => (
  <Icon
    className={cx(className, styles.icon, { [styles.isAdded]: isAdded })}
    path={addedIcon}
    size={size}
    cyData={cyData}
  />
);

InteractionIcon.displayName = 'molecules/ProductInteractions/InteractionIcon';
InteractionIcon.propTypes = {
  className: PropTypes.string,
  isAdded: PropTypes.bool.isRequired,
  addedIcon: PropTypes.string,
  size: PropTypes.oneOf(Object.values(Icon.sizes)),
  cyData: PropTypes.string,
};

export default InteractionIcon;
