import { BREAKPOINTS } from 'constants/breakpoints/breakpoints';

export const elementsPerRow = {
  [BREAKPOINTS.XL]: 15,
  [BREAKPOINTS.LG]: 15,
  [BREAKPOINTS.MD]: 15,
  [BREAKPOINTS.SM]: 15,
  [BREAKPOINTS.XS]: 15,
  [BREAKPOINTS.XXS]: 15,
};

export const elementsPerRowFullWidth = {
  ...elementsPerRow,
  [BREAKPOINTS.XL]: 15,
  [BREAKPOINTS.LG]: 15,
};

export const getInitialRows = (initialRow) => {
  const rows = {
    ONE: 1,
    TWO: 2,
  };
  if (typeof rows[initialRow] !== 'undefined') {
    return rows[initialRow];
  }
  return rows.ONE;
};

export const INITIAL_ROWS = {
  ONE: 'ONE',
  TWO: 'TWO',
};
