import restClient from 'api/RestClient/RestClient';
import { resourceKeys } from 'constants/restResources/restResources';
import language, { getLocale } from 'constants/language/language';
import { contactSubjects } from 'constants/contact/contact.helper';

const salesforceOrgId = __CLIENT__ && window.__ENV_VARIABLES__.salesforceOrgId;

const salesforceConfig = {
  productNumber: '00N1i000002s3bp',
  receiptNumber: '00N1i000002s3bs',
  purchaseDate: '00N1i000002s3br',
  instoreName: '00N1i000002s3bc',
  supercardNumber: '00N1i000002s3bx',
  repairNumber: '00N1i000002s3bt',
  customerNumber: '00N1i000002s3bX',
  contractNumber: '00N1i000002s3bV',
  invoiceNumber: '00N1i000002s3be',
  productBrand: '00N1i000002s3bn',
  productModel: '00N1i000002s3bo',
  purchaseAmount: '00N1i000002s3bq',
  orderNumber: '00N1i000002s3bk',
  caseTypeLevel2: '00N1i000002s3bT',
  brand: '00N1i000002s3bQ',
  language: '00N1i000002s3bR',
  recordType: '0121i000000YFIO',
  recaptchaKeyname: 'IDMSP_Google_Recaptcha_Key',
};

const { CONTACT_RESOURCE } = resourceKeys;
const salesforceSubjects = {
  GENERAL: 'General Inquiry',
  CONSULTING_SALES: 'Consultation',
  SUPERPOINTS: 'Order / Purchase',
  FEEDBACK: 'Customer Feedback',
  PRICE_CONFIRMATION: 'General Inquiry',
  INVOICE: 'Contract',
  ONLINE_ORDER: 'Order / Purchase',
  ADDRESS: 'Customer Account',
  REPAIR: 'Repair',
  RETURN: 'Return',
  DELIVERY: 'Delivery / Pick-Up',
  CANCELATION: 'Cancellation',
  RECEIPT: 'General Inquiry',
};

const salesforceSubjectsSecondLevel = {
  GENERAL: '',
  CONSULTING_SALES: '',
  SUPERPOINTS: 'Superpunkte',
  FEEDBACK: '',
  PRICE_CONFIRMATION: 'Document Request',
  INVOICE: '',
  ONLINE_ORDER: '',
  ADDRESS: '',
  REPAIR: '',
  RETURN: '',
  DELIVERY: '',
  CANCELATION: '',
  RECEIPT: '',
};

const languages = {
  de: 'German',
  fr: 'French',
  it: 'Italian',
  en: 'English',
};

const getLanguage = () => {
  return languages[getLocale()];
};

const getSalutation = (salutationValue) => language(`form.fields.salutation.options.${salutationValue}`);
const getName = (formFields) => `${formFields.firstName.value} ${formFields.lastName.value}`;
const getNameLabel = () => `${language('form.fields.firstName.label')} / ${language('form.fields.lastName.label')}`;

const getAddress = (formFields) =>
  `${language('form.fields.addressLine1.label')}: ${formFields.addressLine1.value}
    ${language('form.fields.addressLine2.label')}: ${formFields.addressLine2.value}
    ${language('form.fields.address_postalCode.label')}: ${formFields.postalCode.value}
    ${language('form.fields.address_town.label')}: ${formFields.town.value}
    ${language('form.fields.address_country.label')}: ${formFields.country.value}`;

const getMessage = (formFields) =>
  `${language('form.fields.contactTextarea.label')}: ${formFields.contactTextarea.value}`;

const getNewAddress = (formFields) => `${language('service.contact.fieldsetHeadlineAddress')}:
  ${language('form.fields.salutation.label')}: ${getSalutation(formFields.address_salutation.value)}
  ${getNameLabel()}: ${formFields.address_firstName.value} ${formFields.address_lastName.value}
  ${language('form.fields.addressLine1.label')}: ${formFields.address_addressLine1.value}
  ${language('form.fields.addressLine2.label')}: ${formFields.address_addressLine2.value}
  ${language('form.fields.address_postalCode.label')}: ${formFields.address_postalCode.value}
  ${language('form.fields.address_town.label')}: ${formFields.address_town.value}
  ${language('form.fields.address_country.label')}: ${formFields.address_country.value}

  ${language('service.contact.additionalFieldsetHeadlineAddress')}:
  ${language('form.fields.salutation.label')}: ${getSalutation(formFields.salutation.value)}
  ${getNameLabel()}: ${getName(formFields)}
  ${getAddress(formFields)}
`;

const getDescription = (formFields, selectedSubject) => {
  const isAddressIncluded = selectedSubject !== contactSubjects.RECEIPT;

  const addressTitle = `${language('checkout.addressForm.title')}:`;

  return `
  ${isAddressIncluded ? addressTitle : ''}
  ${isAddressIncluded ? getAddress(formFields) : ''}

  "${getMessage(formFields)}"

  ${selectedSubject === contactSubjects.ADDRESS ? getNewAddress(formFields) : ''}
  `;
};

const zeroPad = (num) => String(num).padStart(2, '0');

const formatNumber = (num) => {
  if (typeof num !== 'string') {
    return num;
  }
  return num.replace(',', '.');
};

const getDate = (date) => {
  if (date == null || !Date.parse(date)) {
    return '';
  }

  const parsedDate = new Date(date);

  return `${zeroPad(parsedDate.getDate())}.${zeroPad(parsedDate.getMonth() + 1)}.${parsedDate.getFullYear()}`;
};

function sendContactRequest(formFields, selectedSubject) {
  if (!formFields) {
    return Promise.reject('Invalid Parameters');
  }

  const payload = {
    type: salesforceSubjects[formFields.contactSubjects.value],
    [salesforceConfig.caseTypeLevel2]: salesforceSubjectsSecondLevel[formFields.contactSubjects.value],

    name: `${getSalutation(formFields.salutation.value)} ${getName(formFields)}`,
    description: getDescription(formFields, selectedSubject),
    company: formFields.company.value,
    email: formFields.contactEmail.value,
    phone: formFields.phone.value,

    /* superpoints */
    [salesforceConfig.supercardNumber]: formFields.superpoints_supercardCode.value,
    [salesforceConfig.receiptNumber]: formFields.superpoints_receiptNumber.value,
    [salesforceConfig.purchaseDate]: getDate(formFields.superpoints_dateOfPurchase.value),
    [salesforceConfig.instoreName]: formFields.superpoints_store.value,

    /* repairInfo */
    [salesforceConfig.repairNumber]: formFields.repair_feedback_repairNumber.value,
    [salesforceConfig.customerNumber]: formFields.repair_feedback_customerNumber.value,
    [salesforceConfig.contractNumber]: formFields.repair_feedback_contractNumber.value,
    [salesforceConfig.invoiceNumber]: formFields.repair_feedback_invoiceNumber.value,
    [salesforceConfig.productNumber]: formFields.repair_feedback_articleNumber.value,

    [salesforceConfig.orderNumber]: formFields.orderNumber.value,

    [salesforceConfig.productModel]: formFields.modelType.value,
    [salesforceConfig.purchaseAmount]: formatNumber(formFields.purchasePrice.value),
    [salesforceConfig.productBrand]: formFields.brand.value,

    [salesforceConfig.language]: getLanguage(),
    [salesforceConfig.brand]: 'Interdiscount',

    recordType: salesforceConfig.recordType,

    //    captcha_settings: JSON.stringify({
    //      keyname: salesforceConfig.recaptchaKeyname,
    //      fallback: true,
    //      orgId: salesforceOrgId,
    //      ts: new Date().getTime(),
    //    }),
    //    'g-captcha-response': formFields.recaptcha.value,
    orgid: salesforceOrgId,
    subject: language(`form.fields.contactSubjects.options.${selectedSubject}`),
  };

  const params = new URLSearchParams();
  Object.keys(payload).forEach((key) => params.append(key, payload[key]));

  return restClient.post(CONTACT_RESOURCE, '', params, 'application/x-www-form-urlencoded');
}

const ContactAPI = {
  sendContactRequest,
};

export { ContactAPI as default, sendContactRequest };
