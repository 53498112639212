// @flow
export const CLIENT_BOOTSTRAP_FAILURE = 'CLIENT_BOOTSTRAP_FAILURE';
export const REFRESH_ACCESSTOKEN_FAILURE = 'REFRESH_ACCESSTOKEN_FAILURE';
export const REFRESH_ACCESSTOKEN_NETWORKERROR = 'REFRESH_ACCESSTOKEN_NETWORKERROR';

// PRODUCTS
export const SEARCH_PRODUCTS = 'SEARCH_PRODUCTS';

export const QUERY_PRODUCTS = 'QUERY_PRODUCTS';
export const QUERY_PRODUCTS_REQUEST = 'QUERY_PRODUCTS_REQUEST';
export const QUERY_PRODUCTS_SUCCESS = 'QUERY_PRODUCTS_SUCCESS';
export const QUERY_PRODUCTS_FAILURE = 'QUERY_PRODUCTS_FAILURE';
export const QUERY_PRODUCTS_NETWORKERROR = 'QUERY_PRODUCTS_NETWORKERROR';

export const LOAD_PRODUCTS = 'LOAD_PRODUCTS';
export const LOAD_PRODUCTS_REQUEST = 'LOAD_PRODUCTS_REQUEST';
export const LOAD_PRODUCTS_SUCCESS = 'LOAD_PRODUCTS_SUCCESS';
export const LOAD_PRODUCTS_FAILURE = 'LOAD_PRODUCTS_FAILURE';
export const LOAD_PRODUCTS_NETWORKERROR = 'LOAD_PRODUCTS_NETWORKERROR';

export const LOAD_PRODUCT_LIST = 'LOAD_PRODUCT_LIST';
export const LOAD_PRODUCT_LIST_REQUEST = 'LOAD_PRODUCT_LIST_REQUEST';
export const LOAD_PRODUCT_LIST_SUCCESS = 'LOAD_PRODUCT_LIST_SUCCESS';
export const LOAD_PRODUCT_LIST_FAILURE = 'LOAD_PRODUCT_LIST_FAILURE';
export const LOAD_PRODUCT_LIST_NETWORKERROR = 'LOAD_PRODUCT_LIST_NETWORKERROR';

export const LOAD_RECOMMENDED_PRODUCTS = 'LOAD_RECOMMENDED_PRODUCTS';
export const LOAD_RECOMMENDED_PRODUCTS_REQUEST = 'LOAD_RECOMMENDED_PRODUCTS_REQUEST';
export const LOAD_RECOMMENDED_PRODUCTS_FAILURE = 'LOAD_RECOMMENDED_PRODUCTS_FAILURE';
export const LOAD_RECOMMENDED_PRODUCTS_SUCCESS = 'LOAD_RECOMMENDED_PRODUCTS_SUCCESS';
export const LOAD_RECOMMENDED_PRODUCTS_NETWORKERROR = 'LOAD_RECOMMENDED_PRODUCTS_NETWORKERROR';

export const LOAD_RECOMMENDED_TOP_PRODUCTS = 'LOAD_RECOMMENDED_TOP_PRODUCTS';
export const LOAD_RECOMMENDED_TOP_PRODUCTS_REQUEST = 'LOAD_RECOMMENDED_TOP_PRODUCTS_REQUEST';
export const LOAD_RECOMMENDED_TOP_PRODUCTS_FAILURE = 'LOAD_RECOMMENDED_TOP_PRODUCTS_FAILURE';
export const LOAD_RECOMMENDED_TOP_PRODUCTS_SUCCESS = 'LOAD_RECOMMENDED_TOP_PRODUCTS_SUCCESS';
export const LOAD_RECOMMENDED_TOP_PRODUCTS_NETWORKERROR = 'LOAD_RECOMMENDED_TOP_PRODUCTS_NETWORKERROR';

export const LOAD_PRODUCT = 'LOAD_PRODUCT';
export const LOAD_PRODUCT_REQUEST = 'LOAD_PRODUCT_REQUEST';
export const LOAD_PRODUCT_SUCCESS = 'LOAD_PRODUCT_SUCCESS';
export const LOAD_PRODUCT_FAILURE = 'LOAD_PRODUCT_FAILURE';
export const LOAD_PRODUCT_NETWORKERROR = 'LOAD_PRODUCT_NETWORKERROR';

export const SET_VISIBLE_PRODUCTS = 'SET_VISIBLE_PRODUCTS';

export const PRODUCTS_ADD = 'PRODUCTS_ADD';

export const TRACK_PRODUCT_CLICK = 'TRACK_PRODUCT_CLICK';
export const TRACK_CMS_CLICK = 'TRACK_CMS_CLICK';
export const TRACK_PLACE_ORDER = 'TRACK_PLACE_ORDER';
export const TRACK_FACET_CHANGE = 'TRACK_FACET_CHANGE';
export const TRACK_CHECKOUT = 'TRACK_CHECKOUT';
export const TRACK_PRODUCT_OVERVIEW = 'TRACK_PRODUCT_OVERVIEW';
export const TRACK_CART_LOGIN = 'TRACK_CART_LOGIN';
export const TRACK_NAVIGATION_ELEMENTS = 'TRACK_NAVIGATION_ELEMENTS';
export const TRACK_ACCESSORIES = 'TRACK_ACCESSORIES';
export const TRACK_WATCHLIST = 'TRACK_WATCHLIST';
export const TRACK_COMPARISON = 'TRACK_COMPARISON';
export const TRACK_RECOMMENDED_PRODUCTS = 'TRACK_RECOMMENDED_PRODUCTS';

export const SEARCH_AS_YOU_TYPE = 'SEARCH_AS_YOU_TYPE';
export const SEARCH_AS_YOU_TYPE_REQUEST = 'SEARCH_AS_YOU_TYPE_REQUEST';
export const SEARCH_AS_YOU_TYPE_SUCCESS = 'SEARCH_AS_YOU_TYPE_SUCCESS';
export const SEARCH_AS_YOU_TYPE_FAILURE = 'SEARCH_AS_YOU_TYPE_FAILURE';
export const SEARCH_AS_YOU_TYPE_NETWORKERROR = 'SEARCH_AS_YOU_TYPE_NETWORKERROR';

export const SUGGEST_AS_YOU_TYPE = 'SUGGEST_AS_YOU_TYPE';
export const SUGGEST_AS_YOU_TYPE_REQUEST = 'SUGGEST_AS_YOU_TYPE_REQUEST';
export const SUGGEST_AS_YOU_TYPE_SUCCESS = 'SUGGEST_AS_YOU_TYPE_SUCCESS';
export const SUGGEST_AS_YOU_TYPE_FAILURE = 'SUGGEST_AS_YOU_TYPE_FAILURE';
export const SUGGEST_AS_YOU_TYPE_NETWORKERROR = 'SUGGEST_AS_YOU_TYPE_NETWORKERROR';

export const CLEAR_SEARCH_AS_YOU_TYPE = 'CLEAR_SEARCH_AS_YOU_TYPE';
export const STARTED_PRODUCT_SEARCH = 'STARTED_PRODUCT_SEARCH';

export const ROUTER_UPDATE_LOCATION_NEED = 'ROUTER_UPDATE_LOCATION_NEED';

export const GET_PRODUCT_ACCESSORIES = 'GET_PRODUCT_ACCESSORIES';
export const GET_PRODUCT_ACCESSORIES_REQUEST = 'GET_PRODUCT_ACCESSORIES_REQUEST';
export const GET_PRODUCT_ACCESSORIES_SUCCESS = 'GET_PRODUCT_ACCESSORIES_SUCCESS';
export const GET_PRODUCT_ACCESSORIES_FAILURE = 'GET_PRODUCT_ACCESSORIES_FAILURE';
export const GET_PRODUCT_ACCESSORIES_NETWORKERROR = 'GET_PRODUCT_ACCESSORIES_NETWORKERROR';

export const GET_PRODUCT_WALL = 'GET_PRODUCT_WALL';
export const GET_PRODUCT_WALL_REQUEST = 'GET_PRODUCT_WALL_REQUEST';
export const GET_PRODUCT_WALL_SUCCESS = 'GET_PRODUCT_WALL_SUCCESS';
export const GET_PRODUCT_WALL_FAILURE = 'GET_PRODUCT_WALL_FAILURE';
export const GET_PRODUCT_WALL_NETWORKERROR = 'GET_PRODUCT_WALL_NETWORKERROR';

export const GET_CATEGORY_WALL = 'GET_CATEGORY_WALL';
export const GET_CATEGORY_WALL_REQUEST = 'GET_CATEGORY_WALL_REQUEST';
export const GET_CATEGORY_WALL_SUCCESS = 'GET_CATEGORY_WALL_SUCCESS';
export const GET_CATEGORY_WALL_FAILURE = 'GET_CATEGORY_WALL_FAILURE';
export const GET_CATEGORY_WALL_NETWORKERROR = 'GET_CATEGORY_WALL_NETWORKERROR';

export const TOGGLE_PRODUCT_VIEW = 'TOGGLE_PRODUCT_VIEW';

// CART
export const GET_CART = 'GET_CART';
export const GET_CART_REQUEST = 'GET_CART_REQUEST';
export const GET_CART_SUCCESS = 'GET_CART_SUCCESS';
export const GET_CART_FAILURE = 'GET_CART_FAILURE';
export const GET_CART_NETWORKERROR = 'GET_CART_NETWORKERROR';

export const RESET_CART = 'RESET_CART';
export const RESET_CART_SUCCESS = 'RESET_CART_SUCCESS';

export const MERGE_CART = 'MERGE_CART';
export const MERGE_CART_REQUEST = 'MERGE_CART_REQUEST';
export const MERGE_CART_SUCCESS = 'MERGE_CART_SUCCESS';
export const MERGE_CART_FAILURE = 'MERGE_CART_FAILURE';
export const MERGE_CART_NETWORKERROR = 'MERGE_CART_NETWORKERROR';

export const ADD_TO_CART = 'ADD_TO_CART';
export const ADD_TO_CART_REQUEST = 'ADD_TO_CART_REQUEST';
export const ADD_TO_CART_SUCCESS = 'ADD_TO_CART_SUCCESS';
export const ADD_TO_CART_FAILURE = 'ADD_TO_CART_FAILURE';
export const ADD_TO_CART_NETWORKERROR = 'ADD_TO_CART_NETWORKERROR';

export const ADD_ACCESSORY_TO_CART = 'ADD_ACCESSORY_TO_CART';
export const ADD_ACCESSORY_TO_CART_REQUEST = 'ADD_ACCESSORY_TO_CART_REQUEST';
export const ADD_ACCESSORY_TO_CART_SUCCESS = 'ADD_ACCESSORY_TO_CART_SUCCESS';
export const ADD_ACCESSORY_TO_CART_FAILURE = 'ADD_ACCESSORY_TO_CART_FAILURE';
export const ADD_ACCESSORY_TO_CART_NETWORKERROR = 'ADD_ACCESSORY_TO_CART_NETWORKERROR';

export const UPDATE_ENTRY = 'UPDATE_ENTRY';
export const UPDATE_ENTRY_REQUEST = 'UPDATE_ENTRY_REQUEST';
export const UPDATE_ENTRY_SUCCESS = 'UPDATE_ENTRY_SUCCESS';
export const UPDATE_ENTRY_FAILURE = 'UPDATE_ENTRY_FAILURE';
export const UPDATE_ENTRY_NETWORKERROR = 'UPDATE_ENTRY_NETWORKERROR';

export const DELETE_FROM_CART = 'DELETE_FROM_CART';
export const DELETE_FROM_CART_REQUEST = 'DELETE_FROM_CART_REQUEST';
export const DELETE_FROM_CART_SUCCESS = 'DELETE_FROM_CART_SUCCESS';
export const DELETE_FROM_CART_FAILURE = 'DELETE_FROM_CART_FAILURE';
export const DELETE_FROM_CART_NETWORKERROR = 'DELETE_FROM_CART_NETWORKERROR';

export const REMOVE_LAST_DELETED_ITEM = 'REMOVE_LAST_DELETED_ITEM';

export const RESET_ADD_TO_CART_STATE = 'RESET_CART_STATE';

export const CHANGE_AGE_VERIFICATION_TYPE = 'CHANGE_AGE_VERIFICATION_TYPE';
export const VERIFY_AGE = 'VERIFY_AGE';
export const VERIFY_AGE_REQUEST = 'VERIFY_AGE_REQUEST';
export const VERIFY_AGE_SUCCESS = 'VERIFY_AGE_SUCCESS';
export const VERIFY_AGE_FAILURE = 'VERIFY_AGE_FAILURE';
export const VERIFY_AGE_NETWORKERROR = 'VERIFY_AGE_NETWORKERROR';

export const SET_VERIFY_AGE_MODAL_ISOPEN = 'SET_VERIFY_AGE_MODAL_ISOPEN';
export const RESET_AGE_VERIFICATION_TYPE = 'RESET_AGE_VERIFICATION_TYPE';
export const SET_PEGI_FORCE_VALIDATION = 'SET_PEGI_FORCE_VALIDATION';

export const CLEAR_UPDATED_CART_HISTORY = 'CLEAR_UPDATED_CART_HISTORY';

// CHECKOUT

export const UPDATE_GUEST_EMAIL = 'UPDATE_GUEST_EMAIL';

export const CHANGE_GUEST = 'CHANGE_GUEST';
export const CHANGE_GUEST_REQUEST = 'CHANGE_GUEST_REQUEST';
export const CHANGE_GUEST_SUCCESS = 'CHANGE_GUEST_SUCCESS';
export const CHANGE_GUEST_FAILURE = 'CHANGE_GUEST_FAILURE';
export const CHANGE_GUEST_NETWORKERROR = 'CHANGE_GUEST_NETWORKERROR';

export const SET_GUEST_ADDRESS = 'SET_GUEST_ADDRESS';
export const SET_GUEST_ADDRESS_REQUEST = 'SET_GUEST_ADDRESS_REQUEST';
export const SET_GUEST_ADDRESS_SUCCESS = 'SET_GUEST_ADDRESS_SUCCESS';
export const SET_GUEST_ADDRESS_FAILURE = 'SET_GUEST_ADDRESS_FAILURE';
export const SET_GUEST_ADDRESS_NETWORKERROR = 'SET_GUEST_ADDRESS_NETWORKERROR';

export const SET_ADDRESS = 'SET_ADDRESS';
export const SET_ADDRESS_REQUEST = 'SET_ADDRESS_REQUEST';
export const SET_ADDRESS_SUCCESS = 'SET_ADDRESS_SUCCESS';
export const SET_ADDRESS_FAILURE = 'SET_ADDRESS_FAILURE';
export const SET_ADDRESS_NETWORKERROR = 'SET_ADDRESS_NETWORKERROR';

export const REMOVE_ADDRESS = 'REMOVE_ADDRESS';
export const REMOVE_ADDRESS_REQUEST = 'REMOVE_ADDRESS_REQUEST';
export const REMOVE_ADDRESS_SUCCESS = 'REMOVE_ADDRESS_SUCCESS';
export const REMOVE_ADDRESS_FAILURE = 'REMOVE_ADDRESS_FAILURE';
export const REMOVE_ADDRESS_NETWORKERROR = 'REMOVE_ADDRESS_NETWORKERROR';

export const GET_PAYMENT_MODES = 'GET_PAYMENT_MODES';
export const GET_PAYMENT_MODES_REQUEST = 'GET_PAYMENT_MODES_REQUEST';
export const GET_PAYMENT_MODES_SUCCESS = 'GET_PAYMENT_MODES_SUCCESS';
export const GET_PAYMENT_MODES_FAILURE = 'GET_PAYMENT_MODES_FAILURE';
export const GET_PAYMENT_MODES_NETWORKERROR = 'GET_PAYMENT_MODES_NETWORKERROR';

export const SET_PAYMENT_MODE = 'SET_PAYMENT_MODE';
export const SET_PAYMENT_MODE_REQUEST = 'SET_PAYMENT_MODE_REQUEST';
export const SET_PAYMENT_MODE_SUCCESS = 'SET_PAYMENT_MODE_SUCCESS';
export const SET_PAYMENT_MODE_FAILURE = 'SET_PAYMENT_MODE_FAILURE';
export const SET_PAYMENT_MODE_NETWORKERROR = 'SET_PAYMENT_MODE_NETWORKERROR';
export const SAVE_PREVIOUS_PAYMENT_MODE = 'SAVE_PREVIOUS_PAYMENT_MODE';

export const UPDATE_PAYMENT_MODE = 'UPDATE_PAYMENT_MODE';
export const UPDATE_PAYMENT_MODE_REQUEST = 'UPDATE_PAYMENT_MODE_REQUEST';
export const UPDATE_PAYMENT_MODE_SUCCESS = 'UPDATE_PAYMENT_MODE_SUCCESS';
export const UPDATE_PAYMENT_MODE_FAILURE = 'UPDATE_PAYMENT_MODE_FAILURE';
export const UPDATE_PAYMENT_MODE_NETWORKERROR = 'UPDATE_PAYMENT_MODE_NETWORKERROR';

export const RESET_PAYMENT_MODE = 'RESET_PAYMENT_MODE';
export const RESET_PAYMENT_MODE_REQUEST = 'RESET_PAYMENT_MODE_REQUEST';
export const RESET_PAYMENT_MODE_SUCCESS = 'RESET_PAYMENT_MODE_SUCCESS';
export const RESET_PAYMENT_MODE_FAILURE = 'RESET_PAYMENT_MODE_FAILURE';
export const RESET_PAYMENT_MODE_NETWORKERROR = 'RESET_PAYMENT_MODE_NETWORKERROR';

export const REMOVE_PAYMENT_MODE = 'REMOVE_PAYMENT_MODE';
export const REMOVE_PAYMENT_MODE_REQUEST = 'REMOVE_PAYMENT_MODE_REQUEST';
export const REMOVE_PAYMENT_MODE_SUCCESS = 'REMOVE_PAYMENT_MODE_SUCCESS';
export const REMOVE_PAYMENT_MODE_FAILURE = 'REMOVE_PAYMENT_MODE_FAILURE';
export const REMOVE_PAYMENT_MODE_NETWORKERROR = 'REMOVE_PAYMENT_MODE_NETWORKERROR';

export const GET_FOOTER_PAYMENT_MODES = 'GET_FOOTER_PAYMENT_MODES';
export const GET_FOOTER_PAYMENT_MODES_REQUEST = 'GET_FOOTER_PAYMENT_MODES_REQUEST';
export const GET_FOOTER_PAYMENT_MODES_SUCCESS = 'GET_FOOTER_PAYMENT_MODES_SUCCESS';
export const GET_FOOTER_PAYMENT_MODES_FAILURE = 'GET_FOOTER_PAYMENT_MODES_FAILURE';
export const GET_FOOTER_PAYMENT_MODES_NETWORKERROR = 'GET_FOOTER_PAYMENT_MODES_NETWORKERROR';

export const GET_CURRENT_DELIVERY_MODE = 'GET_CURRENT_DELIVERY_MODE';
export const GET_CURRENT_DELIVERY_MODE_REQUEST = 'GET_CURRENT_DELIVERY_MODE_REQUEST';
export const GET_CURRENT_DELIVERY_MODE_SUCCESS = 'GET_CURRENT_DELIVERY_MODE_SUCCESS';
export const GET_CURRENT_DELIVERY_MODE_FAILURE = 'GET_CURRENT_DELIVERY_MODE_FAILURE';
export const GET_CURRENT_DELIVERY_MODE_NETWORKERROR = 'GET_CURRENT_DELIVERY_MODE_NETWORKERROR';

export const GET_DELIVERY_MODES = 'GET_DELIVERY_MODES';
export const GET_DELIVERY_MODES_REQUEST = 'GET_DELIVERY_MODES_REQUEST';
export const GET_DELIVERY_MODES_SUCCESS = 'GET_DELIVERY_MODES_SUCCESS';
export const GET_DELIVERY_MODES_FAILURE = 'GET_DELIVERY_MODES_FAILURE';
export const GET_DELIVERY_MODES_NETWORKERROR = 'GET_DELIVERY_MODES_NETWORKERROR';

export const SET_DELIVERY_MODE = 'SET_DELIVERY_MODE';
export const SET_DELIVERY_MODE_REQUEST = 'SET_DELIVERY_MODE_REQUEST';
export const SET_DELIVERY_MODE_SUCCESS = 'SET_DELIVERY_MODE_SUCCESS';
export const SET_DELIVERY_MODE_FAILURE = 'SET_DELIVERY_MODE_FAILURE';
export const SET_DELIVERY_MODE_NETWORKERROR = 'SET_DELIVERY_MODE_NETWORKERROR';

export const UPDATE_DELIVERY_MODE = 'UPDATE_DELIVERY_MODE';
export const UPDATE_DELIVERY_MODE_REQUEST = 'UPDATE_DELIVERY_MODE_REQUEST';
export const UPDATE_DELIVERY_MODE_SUCCESS = 'UPDATE_DELIVERY_MODE_SUCCESS';
export const UPDATE_DELIVERY_MODE_FAILURE = 'UPDATE_DELIVERY_MODE_FAILURE';
export const UPDATE_DELIVERY_MODE_NETWORKERROR = 'UPDATE_DELIVERY_MODE_NETWORKERROR';

export const UPDATE_PAYMENT_DETAILS = 'UPDATE_PAYMENT_DETAILS';
export const UPDATE_PAYMENT_DETAILS_REQUEST = 'UPDATE_PAYMENT_DETAILS_REQUEST';
export const UPDATE_PAYMENT_DETAILS_SUCCESS = 'UPDATE_PAYMENT_DETAILS_SUCCESS';
export const UPDATE_PAYMENT_DETAILS_FAILURE = 'UPDATE_PAYMENT_DETAILS_FAILURE';
export const UPDATE_PAYMENT_DETAILS_NETWORKERROR = 'UPDATE_PAYMENT_DETAILS_NETWORKERROR';

export const UPDATE_ORDER_REFERENCE = 'UPDATE_ORDER_REFERENCE';
export const UPDATE_ORDER_REFERENCE_REQUEST = 'UPDATE_ORDER_REFERENCE_REQUEST';
export const UPDATE_ORDER_REFERENCE_SUCCESS = 'UPDATE_ORDER_REFERENCE_SUCCESS';
export const UPDATE_ORDER_REFERENCE_FAILURE = 'UPDATE_ORDER_REFERENCE_FAILURE';
export const UPDATE_ORDER_REFERENCE_NETWORKERROR = 'UPDATE_ORDER_REFERENCE_NETWORKERROR';

export const PLACE_ORDER = 'PLACE_ORDER';
export const PLACE_ORDER_REQUEST = 'PLACE_ORDER_REQUEST';
export const PLACE_ORDER_SUCCESS = 'PLACE_ORDER_SUCCESS';
export const PLACE_ORDER_FAILURE = 'PLACE_ORDER_FAILURE';
export const PLACE_ORDER_NETWORKERROR = 'PLACE_ORDER_NETWORKERROR';

export const START_PLACE_ORDER = 'START_PLACE_ORDER';

export const CHECK_STOCK_AVAILABILITY = 'CHECK_STOCK_AVAILABILITY';
export const CHECK_STOCK_AVAILABILITY_REQUEST = 'CHECK_STOCK_AVAILABILITY_REQUEST';
export const CHECK_STOCK_AVAILABILITY_SUCCESS = 'CHECK_STOCK_AVAILABILITY_SUCCESS';
export const CHECK_STOCK_AVAILABILITY_FAILURE = 'CHECK_STOCK_AVAILABILITY_FAILURE';
export const CHECK_STOCK_AVAILABILITY_NETWORKERROR = 'CHECK_STOCK_AVAILABILITY_NETWORKERROR';

export const CHECK_ORDER_VALIDITY = 'CHECK_ORDER_VALIDITY';
export const CHECK_ORDER_VALIDITY_REQUEST = 'CHECK_ORDER_VALIDITY_REQUEST';
export const CHECK_ORDER_VALIDITY_SUCCESS = 'CHECK_ORDER_VALIDITY_SUCCESS';
export const CHECK_ORDER_VALIDITY_FAILURE = 'CHECK_ORDER_VALIDITY_FAILURE';
export const CHECK_ORDER_VALIDITY_NETWORKERROR = 'CHECK_ORDER_VALIDITY_NETWORKERROR';

export const UPDATE_TRANSPORT_GUARANTEE = 'UPDATE_TRANSPORT_GUARANTEE';
export const UPDATE_TRANSPORT_GUARANTEE_REQUEST = 'UPDATE_TRANSPORT_GUARANTEE_REQUEST';
export const UPDATE_TRANSPORT_GUARANTEE_SUCCESS = 'UPDATE_TRANSPORT_GUARANTEE_SUCCESS';
export const UPDATE_TRANSPORT_GUARANTEE_FAILURE = 'UPDATE_TRANSPORT_GUARANTEE_FAILURE';
export const UPDATE_TRANSPORT_GUARANTEE_NETWORKERROR = 'UPDATE_TRANSPORT_GUARANTEE_NETWORKERROR';

export const UPDATE_NEUTRAL_DELIVERY = 'UPDATE_NEUTRAL_DELIVERY';
export const UPDATE_NEUTRAL_DELIVERY_REQUEST = 'UPDATE_NEUTRAL_DELIVERY_REQUEST';
export const UPDATE_NEUTRAL_DELIVERY_SUCCESS = 'UPDATE_NEUTRAL_DELIVERY_SUCCESS';
export const UPDATE_NEUTRAL_DELIVERY_FAILURE = 'UPDATE_NEUTRAL_DELIVERY_FAILURE';
export const UPDATE_NEUTRAL_DELIVERY_NETWORKERROR = 'UPDATE_NEUTRAL_DELIVERY_NETWORKERROR';

export const UPDATE_PACKAGE_ANNOUNCEMENT = 'UPDATE_PACKAGE_ANNOUNCEMENT';
export const UPDATE_PACKAGE_ANNOUNCEMENT_REQUEST = 'UPDATE_PACKAGE_ANNOUNCEMENT_REQUEST';
export const UPDATE_PACKAGE_ANNOUNCEMENT_SUCCESS = 'UPDATE_PACKAGE_ANNOUNCEMENT_SUCCESS';
export const UPDATE_PACKAGE_ANNOUNCEMENT_FAILURE = 'UPDATE_PACKAGE_ANNOUNCEMENT_FAILURE';
export const UPDATE_PACKAGE_ANNOUNCEMENT_NETWORKERROR = 'UPDATE_PACKAGE_ANNOUNCEMENT_NETWORKERROR';

export const SET_FORCE_VALIDATION_ERRORS_VISIBLE = 'SET_FORCE_VALIDATION_ERRORS_VISIBLE';

export const GET_DATATRANS_AUTHORISATION = 'GET_DATATRANS_AUTHORISATION';
export const GET_DATATRANS_AUTHORISATION_REQUEST = 'GET_DATATRANS_AUTHORISATION_REQUEST';
export const GET_DATATRANS_AUTHORISATION_SUCCESS = 'GET_DATATRANS_AUTHORISATION_SUCCESS';
export const GET_DATATRANS_AUTHORISATION_FAILURE = 'GET_DATATRANS_AUTHORISATION_FAILURE';
export const GET_DATATRANS_AUTHORISATION_NETWORKERROR = 'GET_DATATRANS_AUTHORISATION_NETWORKERROR';

export const INIT_DATATRANS_TRANSACTION = 'INIT_DATATRANS_TRANSACTION';
export const INIT_DATATRANS_TRANSACTION_ERROR = 'INIT_DATATRANS_TRANSACTION_REQUEST';
export const INIT_DATATRANS_TRANSACTION_SUCCESS = 'INIT_DATATRANS_TRANSACTION_SUCCESS';
export const INIT_DATATRANS_TRANSACTION_FAILURE = 'INIT_DATATRANS_TRANSACTION_FAILURE';
export const INIT_DATATRANS_TRANSACTION_NETWORKERROR = 'INIT_DATATRANS_TRANSACTION_NETWORKERROR';

export const APPLY_VOUCHER = 'APPLY_VOUCHER';
export const APPLY_VOUCHER_REQUEST = 'APPLY_VOUCHER_REQUEST';
export const APPLY_VOUCHER_SUCCESS = 'APPLY_VOUCHER_SUCCESS';
export const APPLY_VOUCHER_FAILURE = 'APPLY_VOUCHER_FAILURE';
export const APPLY_VOUCHER_NETWORKERROR = 'APPLY_VOUCHER_NETWORKERROR';

export const REMOVE_VOUCHER = 'REMOVE_VOUCHER';
export const REMOVE_VOUCHER_REQUEST = 'REMOVE_VOUCHER_REQUEST';
export const REMOVE_VOUCHER_SUCCESS = 'REMOVE_VOUCHER_SUCCESS';
export const REMOVE_VOUCHER_FAILURE = 'REMOVE_VOUCHER_FAILURE';
export const REMOVE_VOUCHER_NETWORKERROR = 'REMOVE_VOUCHER_NETWORKERROR';

export const CLEAR_VOUCHER_VALIDATION_RESULT = 'CLEAR_VOUCHER_VALIDATION_RESULT';

export const UPDATE_PEGI_FIELD_VALUE = 'UPDATE_PEGI_FIELD_VALUE';

export const RESET_ORDER_API_STATUS = 'RESET_ORDER_API_STATUS';

export const APPLY_GIFTCARD = 'APPLY_GIFTCARD';
export const APPLY_GIFTCARD_REQUEST = 'APPLY_GIFTCARD_REQUEST';
export const APPLY_GIFTCARD_SUCCESS = 'APPLY_GIFTCARD_SUCCESS';
export const APPLY_GIFTCARD_FAILURE = 'APPLY_GIFTCARD_FAILURE';
export const APPLY_GIFTCARD_NETWORKERROR = 'APPLY_GIFTCARD_NETWORKERROR';

export const REMOVE_GIFTCARD = 'REMOVE_GIFTCARD';
export const REMOVE_GIFTCARD_REQUEST = 'REMOVE_GIFTCARD_REQUEST';
export const REMOVE_GIFTCARD_SUCCESS = 'REMOVE_GIFTCARD_SUCCESS';
export const REMOVE_GIFTCARD_FAILURE = 'REMOVE_GIFTCARD_FAILURE';
export const REMOVE_GIFTCARD_NETWORKERROR = 'REMOVE_GIFTCARD_NETWORKERROR';

export const CLEAR_GIFTCARD_VALIDATION_RESULT = 'CLEAR_GIFTCARD_VALIDATION_RESULT';

export const ADD_SUPERCARD = 'ADD_SUPERCARD';
export const ADD_SUPERCARD_REQUEST = 'ADD_SUPERCARD_REQUEST';
export const ADD_SUPERCARD_SUCCESS = 'ADD_SUPERCARD_SUCCESS';
export const ADD_SUPERCARD_FAILURE = 'ADD_SUPERCARD_FAILURE';
export const ADD_SUPERCARD_NETWORKERROR = 'ADD_SUPERCARD_NETWORKERROR';

export const RESET_SUPERCARD_VALIDATION = 'RESET_SUPERCARD_VALIDATION';

export const SET_CHECKOUT_NEED_STATUS = 'SET_CHECKOUT_NEED_STATUS';

export const SET_CHECKOUT_GIFTCARD_DISALLOWED_CHANGED = 'SET_CHECKOUT_GIFTCARD_DISALLOWED_CHANGED';

// USER

export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';
export const CREATE_USER_NETWORKERROR = 'CREATE_USER_NETWORKERROR';

export const GET_USER = 'GET_USER';
export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';
export const GET_USER_NETWORKERROR = 'GET_USER_NETWORKERROR';

export const GET_USER_CONSENTS = 'GET_USER_CONSENTS';
export const GET_USER_CONSENTS_REQUEST = 'GET_USER_CONSENTS_REQUEST';
export const GET_USER_CONSENTS_SUCCESS = 'GET_USER_CONSENTS_SUCCESS';
export const GET_USER_CONSENTS_FAILURE = 'GET_USER_CONSENTS_FAILURE';
export const GET_USER_CONSENTS_NETWORKERROR = 'GET_USER_CONSENTS_NETWORKERROR';

export const UPDATE_USER_CONSENTS = 'UPDATE_USER_CONSENTS';
export const UPDATE_USER_CONSENTS_REQUEST = 'UPDATE_USER_CONSENTS_REQUEST';
export const UPDATE_USER_CONSENTS_SUCCESS = 'UPDATE_USER_CONSENTS_SUCCESS';
export const UPDATE_USER_CONSENTS_FAILURE = 'UPDATE_USER_CONSENTS_FAILURE';
export const UPDATE_USER_CONSENTS_NETWORKERROR = 'UPDATE_USER_CONSENTS_NETWORKERROR';

export const GET_ONLINE_ID = 'GET_ONLINE_ID';
export const GET_ONLINE_ID_REQUEST = 'GET_ONLINE_ID_REQUEST';
export const GET_ONLINE_ID_SUCCESS = 'GET_ONLINE_ID_SUCCESS';
export const GET_ONLINE_ID_FAILURE = 'GET_ONLINE_ID_FAILURE';
export const GET_ONLINE_ID_NETWORKERROR = 'GET_ONLINE_ID_NETWORKERROR';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const UPDATE_USER_NETWORKERROR = 'UPDATE_USER_NETWORKERROR';

export const DEACTIVATE_USER = 'DEACTIVATE_USER';
export const DEACTIVATE_USER_REQUEST = 'DEACTIVATE_USER_REQUEST';
export const DEACTIVATE_USER_SUCCESS = 'DEACTIVATE_USER_SUCCESS';
export const DEACTIVATE_USER_FAILURE = 'DEACTIVATE_USER_FAILURE';
export const DEACTIVATE_USER_NETWORKERROR = 'DEACTIVATE_USER_NETWORKERROR';

export const USER_IS_ANONYMOUS = 'USER_IS_ANONYMOUS';
export const SET_USER_ID = 'SET_USER_ID';

export const CREATE_USER_ADDRESS = 'CREATE_USER_ADDRESS';
export const CREATE_USER_ADDRESS_REQUEST = 'CREATE_USER_ADDRESS_REQUEST';
export const CREATE_USER_ADDRESS_SUCCESS = 'CREATE_USER_ADDRESS_SUCCESS';
export const CREATE_USER_ADDRESS_FAILURE = 'CREATE_USER_ADDRESS_FAILURE';
export const CREATE_USER_ADDRESS_NETWORKERROR = 'CREATE_USER_ADDRESS_NETWORKERROR';

export const UPDATE_USER_ADDRESS = 'UPDATE_USER_ADDRESS';
export const UPDATE_USER_ADDRESS_REQUEST = 'UPDATE_USER_ADDRESS_REQUEST';
export const UPDATE_USER_ADDRESS_SUCCESS = 'UPDATE_USER_ADDRESS_SUCCESS';
export const UPDATE_USER_ADDRESS_FAILURE = 'UPDATE_USER_ADDRESS_FAILURE';
export const UPDATE_USER_ADDRESS_NETWORKERROR = 'UPDATE_USER_ADDRESS_NETWORKERROR';

export const DELETE_USER_ADDRESS = 'DELETE_USER_ADDRESS';
export const DELETE_USER_ADDRESS_REQUEST = 'DELETE_USER_ADDRESS_REQUEST';
export const DELETE_USER_ADDRESS_SUCCESS = 'DELETE_USER_ADDRESS_SUCCESS';
export const DELETE_USER_ADDRESS_FAILURE = 'DELETE_USER_ADDRESS_FAILURE';
export const DELETE_USER_ADDRESS_NETWORKERROR = 'DELETE_USER_ADDRESS_NETWORKERROR';

export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';
export const LOGIN_USER_NETWORKERROR = 'LOGIN_USER_NETWORKERROR';

export const VERIFY_RECAPTCHA = 'VERIFY_RECAPTCHA';
export const VERIFY_RECAPTCHA_REQUEST = 'VERIFY_RECAPTCHA_REQUEST';
export const VERIFY_RECAPTCHA_SUCCESS = 'VERIFY_RECAPTCHA_SUCCESS';
export const VERIFY_RECAPTCHA_FAILURE = 'VERIFY_RECAPTCHA_FAILURE';
export const VERIFY_RECAPTCHA_NETWORKERROR = 'VERIFY_RECAPTCHA_NETWORKERROR';

export const VERIFY_MAIL = 'VERIFY_MAIL';
export const VERIFY_MAIL_REQUEST = 'VERIFY_MAIL_REQUEST';
export const VERIFY_MAIL_SUCCESS = 'VERIFY_MAIL_SUCCESS';
export const VERIFY_MAIL_FAILURE = 'VERIFY_MAIL_FAILURE';
export const VERIFY_MAIL_NETWORKERROR = 'VERIFY_MAIL_NETWORKERROR';

export const RESEND_DOUBLE_OPTIN_MAIL = 'RESEND_DOUBLE_OPTIN_MAIL';
export const RESEND_DOUBLE_OPTIN_MAIL_REQUEST = 'RESEND_DOUBLE_OPTIN_MAIL_REQUEST';
export const RESEND_DOUBLE_OPTIN_MAIL_SUCCESS = 'RESEND_DOUBLE_OPTIN_MAIL_SUCCESS';
export const RESEND_DOUBLE_OPTIN_MAIL_FAILURE = 'RESEND_DOUBLE_OPTIN_MAIL_FAILURE';
export const RESEND_DOUBLE_OPTIN_MAIL_NETWORKERROR = 'RESEND_DOUBLE_OPTIN_MAIL_NETWORKERROR';

export const SUBSCRIBE_NEWSLETTER = 'SUBSCRIBE_NEWSLETTER';
export const SUBSCRIBE_NEWSLETTER_REQUEST = 'SUBSCRIBE_NEWSLETTER_REQUEST';
export const SUBSCRIBE_NEWSLETTER_SUCCESS = 'SUBSCRIBE_NEWSLETTER_SUCCESS';
export const SUBSCRIBE_NEWSLETTER_FAILURE = 'SUBSCRIBE_NEWSLETTER_FAILURE';
export const SUBSCRIBE_NEWSLETTER_NETWORKERROR = 'SUBSCRIBE_NEWSLETTER_NETWORKERROR';

export const NEWSLETTER_OPTIN = 'NEWSLETTER_OPTIN';
export const NEWSLETTER_OPTIN_REQUEST = 'NEWSLETTER_OPTIN_REQUEST';
export const NEWSLETTER_OPTIN_SUCCESS = 'NEWSLETTER_OPTIN_SUCCESS';
export const NEWSLETTER_OPTIN_FAILURE = 'NEWSLETTER_OPTIN_FAILURE';
export const NEWSLETTER_OPTIN_NETWORKERROR = 'NEWSLETTER_OPTIN_NETWORKERROR';

export const SEND_OPTOUT_REASONS = 'SEND_OPTOUT_REASONS';
export const SEND_OPTOUT_REASONS_REQUEST = 'SEND_OPTOUT_REASONS_REQUEST';
export const SEND_OPTOUT_REASONS_SUCCESS = 'SEND_OPTOUT_REASONS_SUCCESS';
export const SEND_OPTOUT_REASONS_FAILURE = 'SEND_OPTOUT_REASONS_FAILURE';
export const SEND_OPTOUT_REASONS_NETWORKERROR = 'SEND_OPTOUT_REASONS_NETWORKERROR';

export const VERIFY_EMAIL_TOKEN = 'VERIFY_EMAIL_TOKEN';
export const VERIFY_EMAIL_TOKEN_REQUEST = 'VERIFY_EMAIL_TOKEN_REQUEST';
export const VERIFY_EMAIL_TOKEN_SUCCESS = 'VERIFY_EMAIL_TOKEN_SUCCESS';
export const VERIFY_EMAIL_TOKEN_FAILURE = 'VERIFY_EMAIL_TOKEN_FAILURE';
export const VERIFY_EMAIL_TOKEN_NETWORKERROR = 'VERIFY_EMAIL_TOKEN_NETWORKERROR';

export const CHANGE_LOGIN = 'CHANGE_LOGIN';
export const CHANGE_LOGIN_REQUEST = 'CHANGE_LOGIN_REQUEST';
export const CHANGE_LOGIN_SUCCESS = 'CHANGE_LOGIN_SUCCESS';
export const CHANGE_LOGIN_FAILURE = 'CHANGE_LOGIN_FAILURE';
export const CHANGE_LOGIN_NETWORKERROR = 'CHANGE_LOGIN_NETWORKERROR';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';
export const CHANGE_PASSWORD_NETWORKERROR = 'CHANGE_PASSWORD_NETWORKERROR';

export const REQUEST_ACCOUNT_DELETION = 'REQUEST_ACCOUNT_DELETION';

export const ROUTER_ENTER_ROUTE = 'ROUTER_ENTER_ROUTE';

export const BLUR_PASSWORD_FIELD = 'BLUR_PASSWORD_FIELD ';
export const UPDATE_PASSWORD_FIELD = 'UPDATE_PASSWORD_FIELD';

export const UPDATE_EMAIL_FIELD = 'UPDATE_EMAIL_FIELD';
export const BLUR_EMAIL_FIELD = 'BLUR_EMAIL_FIELD';

export const UPDATE_BIRTHDAY_FIELD = 'UPDATE_BIRTHDAY_FIELD';
export const BLUR_BIRTHDAY_FIELD = 'BLUR_BIRTHDAY_FIELD';

export const UPDATE_AGB_FIELD = 'UPDATE_AGB_FIELD';

export const CREATE_GUEST_USER = 'CREATE_GUEST_USER';

export const LOGOUT_USER = 'LOGOUT_USER';
export const RESET_USER = 'RESET_USER';

export const INITIALIZE_FORGOTTEN_PASSWORD_GENERATE_TOKEN = 'INITIALIZE_FORGOTTEN_PASSWORD_GENERATE_TOKEN';
export const FORGOTTEN_PASSWORD_GENERATE_TOKEN = 'FORGOTTEN_PASSWORD_GENERATE_TOKEN';
export const FORGOTTEN_PASSWORD_GENERATE_TOKEN_SUCCESS = 'FORGOTTEN_PASSWORD_GENERATE_TOKEN_SUCCESS';
export const FORGOTTEN_PASSWORD_GENERATE_TOKEN_FAILURE = 'FORGOTTEN_PASSWORD_GENERATE_TOKEN_FAILURE';
export const FORGOTTEN_PASSWORD_GENERATE_TOKEN_NETWORKERROR = 'FORGOTTEN_PASSWORD_GENERATE_TOKEN_NETWORKERROR';

export const FORGOTTEN_PASSWORD_CHECK_TOKEN = 'FORGOTTEN_PASSWORD_CHECK_TOKEN';
export const FORGOTTEN_PASSWORD_CHECK_TOKEN_FAILURE = 'FORGOTTEN_PASSWORD_CHECK_TOKEN_FAILURE';
export const FORGOTTEN_PASSWORD_CHECK_TOKEN_NETWORKERROR = 'FORGOTTEN_PASSWORD_CHECK_TOKEN_NETWORKERROR';
export const FORGOTTEN_PASSWORD_CHECK_TOKEN_SUCCESS = 'FORGOTTEN_PASSWORD_CHECK_TOKEN_SUCCESS';

export const FORGOTTEN_PASSWORD_RESTORE_PASSWORD = 'FORGOTTEN_PASSWORD_RESTORE_PASSWORD';
export const FORGOTTEN_PASSWORD_RESTORE_PASSWORD_FAILURE = 'FORGOTTEN_PASSWORD_RESTORE_PASSWORD_FAILURE';
export const FORGOTTEN_PASSWORD_RESTORE_PASSWORD_NETWORKERROR = 'FORGOTTEN_PASSWORD_RESTORE_PASSWORD_NETWORKERROR';
export const FORGOTTEN_PASSWORD_RESTORE_PASSWORD_SUCCESS = 'FORGOTTEN_PASSWORD_RESTORE_PASSWORD_SUCCESS';
export const FORGOTTEN_PASSWORD_RESTORE_PASSWORD_REQUEST = 'FORGOTTEN_PASSWORD_RESTORE_PASSWORD_REQUEST';

export const LOAD_MYORDERS = 'LOAD_MYORDERS';
export const LOAD_MYORDERS_SUCCESS = 'LOAD_MYORDERS_SUCCESS';
export const LOAD_ORDER = 'LOAD_ORDER';
export const LOAD_ORDER_SUCCESS = 'LOAD_ORDER_SUCCESS';

export const LOAD_ARCHIVED_ORDERS = 'LOAD_ARCHIVED_ORDERS';
export const LOAD_ARCHIVED_ORDERS_SUCCESS = 'LOAD_ARCHIVED_ORDERS_SUCCESS';
export const LOAD_ARCHIVED_ORDER = 'LOAD_ARCHIVED_ORDER';
export const LOAD_ARCHIVED_ORDER_SUCCESS = 'LOAD_ARCHIVED_ORDER_SUCCESS';

export const BLUR_PERSONAL_DATA_FIELD = 'BLUR_PERSONAL_DATA_FIELD';

export const CHANGE_CUSTOMER_TYPE = 'CHANGE_CUSTOMER_TYPE';
export const DOWNLOAD_PDF = 'DOWNLOAD_PDF';
export const DOWNLOAD_PDF_SUCCESS = 'DOWNLOAD_PDF_SUCCESS';

export const BLUR_SUPERCARD_FIELD = 'BLUR_SUPERCARD_FIELD';
export const UPDATE_SUPERCARD_FIELD = 'UPDATE_SUPERCARD_FIELD';

// ADDRESS
export const REHYDRATE_GUEST_ADDRESSES = 'REHYDRATE_GUEST_ADDRESSES';
export const CREATE_GUEST_DELIVERY_ADDRESS = 'CREATE_GUEST_DELIVERY_ADDRESS';
export const REMOVE_GUEST_DELIVERY_ADDRESS = 'REMOVE_GUEST_DELIVERY_ADDRESS';
export const UPDATE_ADDRESS_FIELD = 'UPDATE_ADDRESS_FIELD';
export const BLUR_ADDRESS_FIELD = 'BLUR_ADDRESS_FIELD';

export const SELECT_ADDRESS_ID_FOR_TYPE = 'SELECT_ADDRESS_ID_FOR_TYPE';
export const FLAG_ADDRESS_TYPE_FOR_SELECTION = 'FLAG_ADDRESS_TYPE_FOR_SELECTION';
export const FLAG_ADDRESS_FOR_DELETION = 'FLAG_ADDRESS_FOR_DELETION';

export const CREATE_TEMP_ADDRESS = 'CREATE_TEMP_ADDRESS';
export const DELETE_TEMP_ADDRESS = 'DELETE_TEMP_ADDRESS';
export const COPY_INTO_TEMP_ADDRESS = 'COPY_INTO_TEMP_ADDRESS';

export const CREATE_USER_ADDRESS_AND_SELECT = 'CREATE_USER_ADDRESS_AND_SELECT';
export const CREATE_USER_ADDRESS_AND_SELECT_REQUEST = 'CREATE_USER_ADDRESS_AND_SELECT_REQUEST';
export const CREATE_USER_ADDRESS_AND_SELECT_SUCCESS = 'CREATE_USER_ADDRESS_AND_SELECT_SUCCESS';
export const CREATE_USER_ADDRESS_AND_SELECT_FAILURE = 'CREATE_USER_ADDRESS_AND_SELECT_FAILURE';
export const CREATE_USER_ADDRESS_AND_SELECT_NETWORKERROR = 'CREATE_USER_ADDRESS_AND_SELECT_NETWORKERROR';

export const TOKEN_AUTHENTICATED = 'TOKEN_AUTHENTICATED';

export const CHECK_TOKEN = 'CHECK_TOKEN';
export const CHECK_TOKEN_REQUEST = 'CHECK_TOKEN_REQUEST';
export const CHECK_TOKEN_SUCCESS = 'CHECK_TOKEN_SUCCESS';
export const CHECK_TOKEN_FAILURE = 'CHECK_TOKEN_FAILURE';
export const CHECK_TOKEN_NETWORKERROR = 'CHECK_TOKEN_NETWORKERROR';

// STORE
export const GET_STORE = 'GET_STORE';
export const GET_STORE_REQUEST = 'GET_STORE_REQUEST';
export const GET_STORE_SUCCESS = 'GET_STORE_SUCCESS';
export const GET_STORE_FAILURE = 'GET_STORE_FAILURE';
export const GET_STORE_NETWORKERROR = 'GET_STORE_NETWORKERROR';

export const GET_STORES = 'GET_STORES';
export const GET_STORES_REQUEST = 'GET_STORES_REQUEST';
export const GET_STORES_SUCCESS = 'GET_STORES_SUCCESS';
export const GET_STORES_FAILURE = 'GET_STORES_FAILURE';
export const GET_STORES_NETWORKERROR = 'GET_STORES_NETWORKERROR';

export const GET_LOCATION = 'GET_LOCATION';
export const GET_LOCATION_REQUEST = 'GET_LOCATION_REQUEST';
export const GET_LOCATION_SUCCESS = 'GET_LOCATION_SUCCESS';
export const GET_LOCATION_FAILURE = 'GET_LOCATION_FAILURE';
export const GET_LOCATION_NETWORKERROR = 'GET_LOCATION_NETWORKERROR';

export const GET_LOCATION_NAME = 'GET_LOCATION_NAME';
export const GET_LOCATION_NAME_REQUEST = 'GET_LOCATION_NAME_REQUEST';
export const GET_LOCATION_NAME_SUCCESS = 'GET_LOCATION_NAME_SUCCESS';
export const GET_LOCATION_NAME_FAILURE = 'GET_LOCATION_NAME_FAILURE';
export const GET_LOCATION_NAME_NETWORKERROR = 'GET_LOCATION_NAME_NETWORKERROR';

export const SEARCH_STORE = 'SEARCH_STORE';
export const SEARCH_STORE_REQUEST = 'SEARCH_STORE_REQUEST';
export const SEARCH_STORE_SUCCESS = 'SEARCH_STORE_SUCCESS';
export const SEARCH_STORE_FAILURE = 'SEARCH_STORE_FAILURE';
export const SEARCH_STORE_NETWORKERROR = 'SEARCH_STORE_NETWORKERROR';

export const SEARCH_STORE_RESET = 'SEARCH_STORE_RESET';

export const OPEN_STORE_MODAL = 'OPEN_STORE_MODAL';
export const OPEN_MY_STORE_MODAL = 'OPEN_MY_STORE_MODAL';
export const CLOSE_STORE_MODAL = 'CLOSE_STORE_MODAL';
export const CLOSE_MY_STORE_MODAL = 'CLOSE_MY_STORE_MODAL';

export const SAVE_AS_MY_STORE = 'SAVE_AS_MY_STORE';
export const SAVE_AS_MY_STORE_REQUEST = 'SAVE_AS_MY_STORE_REQUEST';
export const SAVE_AS_MY_STORE_SUCCESS = 'SAVE_AS_MY_STORE_SUCCESS';
export const SAVE_AS_MY_STORE_FAILURE = 'SAVE_AS_MY_STORE_FAILURE';
export const SAVE_AS_MY_STORE_NETWORKERROR = 'SAVE_AS_MY_STORE_NETWORKERROR';

export const GET_MY_STORE = 'GET_MY_STORE';
export const GET_MY_STORE_REQUEST = 'GET_MY_STORE_REQUEST';
export const GET_MY_STORE_SUCCESS = 'GET_MY_STORE_SUCCESS';
export const GET_MY_STORE_FAILURE = 'GET_MY_STORE_FAILURE';
export const GET_MY_STORE_NETWORKERROR = 'GET_MY_STORE_NETWORKERROR';

// UI
export const INITIALIZE_MAIN_MENU = 'INITIALIZE_MAIN_MENU';
export const SET_MAIN_MENU_ISOPEN = 'SET_MAIN_MENU_ISOPEN';
export const SET_MAIN_MENU_CLOSE = 'SET_MAIN_MENU_CLOSE';
export const SET_SEARCH_FOCUS = 'SET_SEARCH_FOCUS';
export const OPEN_CART_MODAL = 'OPEN_CART_MODAL';
export const CLOSE_CART_MODAL = 'CLOSE_CART_MODAL';
export const SET_SEARCH_STRING = 'SET_SEARCH_STRING';
export const SET_FACET_COLLAPSE_FLAG = 'SET_FACET_COLLAPSE_FLAG';
export const RESET_ALL_FACET_COLLAPSE_FLAGS = 'RESET_ALL_FACET_COLLAPSE_FLAGS';
export const SET_LOGIN_NOTIFICATION = 'SET_LOGIN_NOTIFICATION';
export const RESET_LOGIN_NOTIFICATION = 'RESET_LOGIN_NOTIFICATION';

export const DISMISS_TOPBAR = 'DISMISS_TOPBAR';
export const LOAD_IS_TOPBAR_DIMISSED = 'LOAD_IS_TOPBAR_DIMISSED';

export const SAVE_CURRENT_BREAKPOINT = 'SAVE_CURRENT_BREAKPOINT';

export const SHOW_POPOVER_LOGIN = 'SHOW_POPOVER_LOGIN';
export const SHOW_POPOVER_CART = 'SHOW_POPOVER_CART';

export const TOGGLE_NAVIGATION = 'TOGGLE_NAVIGATION';

export const ADD_FLASHMESSAGE = 'ADD_FLASHMESSAGE';
export const REMOVE_FLASHMESSAGE = 'REMOVE_FLASHMESSAGE';

export const LOAD_FLASHMESSAGES = 'LOAD_FLASHMESSAGES';
export const LOAD_FLASHMESSAGES_REQUEST = 'LOAD_FLASHMESSAGES_REQUEST';
export const LOAD_FLASHMESSAGES_SUCCESS = 'LOAD_FLASHMESSAGES_SUCCESS';
export const LOAD_FLASHMESSAGES_FAILURE = 'LOAD_FLASHMESSAGES_FAILURE';
export const LOAD_FLASHMESSAGES_NETWORKERROR = 'LOAD_FLASHMESSAGES_NETWORKERROR';

// CMS
export const GET_CMS_CONTENT_FOR_PAGE = 'GET_CMS_CONTENT_FOR_PAGE';
export const GET_CMS_CONTENT_FOR_PAGE_REQUEST = 'GET_CMS_CONTENT_FOR_PAGE_REQUEST';
export const GET_CMS_CONTENT_FOR_PAGE_SUCCESS = 'GET_CMS_CONTENT_FOR_PAGE_SUCCESS';
export const GET_CMS_CONTENT_FOR_PAGE_FAILURE = 'GET_CMS_CONTENT_FOR_PAGE_FAILURE';
export const GET_CMS_CONTENT_FOR_PAGE_NETWORKERROR = 'GET_CMS_CONTENT_FOR_PAGE_NETWORKERROR';

export const NO_BRAND_PAGE = 'NO_BRAND_PAGE';

export const GET_WALL_CONTENT = 'GET_WALL_CONTENT';
export const GET_WALL_CONTENT_SUCCESS = 'GET_WALL_CONTENT_SUCCESS';

export const GET_CMS_NAVIGATION_NODES = 'GET_CMS_NAVIGATION_NODES';
export const GET_CMS_NAVIGATION_NODES_SUCCESS = 'GET_CMS_NAVIGATION_NODES_SUCCESS';
export const GET_CMS_NAVIGATION_NODES_FAILURE = 'GET_CMS_NAVIGATION_NODES_FAILURE';
export const GET_CMS_NAVIGATION_NODES_NETWORKERROR = 'GET_CMS_NAVIGATION_NODES_NETWORKERROR';

export const SET_BRAND = 'SET_BRAND';

export const SET_CURRENT_PAGE_ID = 'SET_CURRENT_PAGE_ID';

export const SET_REQUEST_FOR_CMSCONTENT_STATUS_TO_SUCCESS = 'SET_REQUEST_FOR_CMSCONTENT_STATUS_TO_SUCCESS';

export const SET_INITIAL_CMS_STATE = 'SET_INITIAL_CMS_STATE';

export const GET_USP_ELEMENTS = 'GET_USP_ELEMENTS';
export const GET_USP_ELEMENTS_SUCCESS = 'GET_USP_ELEMENTS_SUCCESS';
export const GET_USP_ELEMENTS_FAILURE = 'GET_USP_ELEMENTS_FAILURE';
export const GET_USP_ELEMENTS_NETWORKERROR = 'GET_USP_ELEMENTS_NETWORKERROR';

// CONTACT

export const GET_CONTACT_SUBJECTS = 'GET_CONTACT_SUBJECTS';
export const GET_CONTACT_SUBJECTS_FAILURE = 'GET_CONTACT_SUBJECTS_FAILURE';
export const GET_CONTACT_SUBJECTS_NETWORKERROR = 'GET_CONTACT_SUBJECTS_NETWORKERROR';
export const GET_CONTACT_SUBJECTS_SUCCESS = 'GET_CONTACT_SUBJECTS_SUCCESS';
export const UPDATE_CONTACT_FIELD = 'UPDATE_CONTACT_FIELD';
export const BLUR_CONTACT_FIELD = 'BLUR_CONTACT_FIELD';
export const CONTACT_VALIDATION_ERROR = 'CONTACT_VALIDATION_ERROR';
export const SEND_CONTACT = 'SEND_CONTACT';
export const SEND_CONTACT_FAILURE = 'SEND_CONTACT_FAILURE';
export const SEND_CONTACT_NETWORKERROR = 'SEND_CONTACT_NETWORKERROR';
export const SEND_CONTACT_SUCCESS = 'SEND_CONTACT_SUCCESS';
export const REINITIALIZE_CONTACT_FORM = 'REINITIALIZE_CONTACT_FORM';
export const CHANGE_CONTACT_SUBJECT = 'CHANGE_CONTACT_SUBJECT';

// ROUTER
export const ROUTER_UPDATE_LOCATION = '@@router/LOCATION_CHANGE';

export const CHANGE_PERSONAL_DATA = 'CHANGE_PERSONAL_DATA';

// NEWSLETTER
export const SUBSCRIBE_TO_NEWSLETTER = 'SUBSCRIBE_TO_NEWSLETTER';
export const SUBSCRIBE_TO_NEWSLETTER_REQUEST = 'SUBSCRIBE_TO_NEWSLETTER_REQUEST';
export const SUBSCRIBE_TO_NEWSLETTER_FAILURE = 'SUBSCRIBE_TO_NEWSLETTER_FAILURE';
export const SUBSCRIBE_TO_NEWSLETTER_SUCCESS = 'SUBSCRIBE_TO_NEWSLETTER_SUCCESS';
export const SUBSCRIBE_TO_NEWSLETTER_NETWORKERROR = 'SUBSCRIBE_TO_NEWSLETTER_NETWORKERROR';

export const UNSUBSCRIBE_NEWSLETTER = 'UNSUBSCRIBE_NEWSLETTER';
export const UNSUBSCRIBE_NEWSLETTER_FAILURE = 'UNSUBSCRIBE_NEWSLETTER_FAILURE';
export const UNSUBSCRIBE_NEWSLETTER_SUCCESS = 'UNSUBSCRIBE_NEWSLETTER_SUCCESS';
export const UNSUBSCRIBE_NEWSLETTER_NETWORKERROR = 'UNSUBSCRIBE_NEWSLETTER_NETWORKERROR';

export const COMPLETE_USER_DATA = 'COMPLETE_USER_DATA';
export const COMPLETE_USER_DATA_FAILURE = 'COMPLETE_USER_DATA_FAILURE';
export const COMPLETE_USER_DATA_SUCCESS = 'COMPLETE_USER_DATA_SUCCESS';
export const COMPLETE_USER_DATA_NETWORKERROR = 'COMPLETE_USER_DATA_NETWORKERROR';

export const VERIFY_NEWSLETTER_TOKEN = 'VERIFY_NEWSLETTER_TOKEN';
export const VERIFY_NEWSLETTER_TOKEN_FAILURE = 'VERIFY_NEWSLETTER_TOKEN_FAILURE';
export const VERIFY_NEWSLETTER_TOKEN_SUCCESS = 'VERIFY_NEWSLETTER_TOKEN_SUCCESS';
export const VERIFY_NEWSLETTER_TOKEN_NETWORKERROR = 'UNSUBSCRIBE_NEWSLETTER_NETWORKERROR';

export const GET_EMAIL_FROM_HASH = 'GET_EMAIL_FROM_HASH';
export const GET_EMAIL_FROM_HASH_SUCCESS = 'GET_EMAIL_FROM_HASH_SUCCESS';
export const GET_EMAIL_FROM_HASH_FAILURE = 'GET_EMAIL_FROM_HASH_FAILURE';
export const GET_EMAIL_FROM_HASH_NETWORKERROR = 'GET_EMAIL_FROM_HASH_NETWORKERROR';
export const GET_EMAIL_FROM_HASH_REQUEST = 'GET_EMAIL_FROM_HASH_REQUEST';

// PRODUCTVIDEO
export const ON_VIDEO_AVAILABLE = 'ON_VIDEO_AVAILABLE';

// WATCHLIST
export const CREATE_WATCHLIST = 'CREATE_WATCHLIST';
export const CREATE_WATCHLIST_FAILURE = 'CREATE_WATCHLIST_FAILURE';
export const CREATE_WATCHLIST_SUCCESS = 'CREATE_WATCHLIST_SUCCESS';
export const CREATE_WATCHLIST_NETWORKERROR = 'CREATE_WATCHLIST_NETWORKERROR';

export const GET_WATCHLIST = 'GET_WATCHLIST';
export const GET_WATCHLIST_FAILURE = 'GET_WATCHLIST_FAILURE';
export const GET_WATCHLIST_SUCCESS = 'GET_WATCHLIST_SUCCESS';
export const GET_WATCHLIST_NETWORKERROR = 'GET_WATCHLIST_NETWORKERROR';

export const ADD_TO_WATCHLIST = 'ADD_TO_WATCHLIST';
export const ADD_TO_WATCHLIST_REQUEST = 'ADD_TO_WATCHLIST_REQUEST';
export const ADD_TO_WATCHLIST_FAILURE = 'ADD_TO_WATCHLIST_FAILURE';
export const ADD_TO_WATCHLIST_SUCCESS = 'ADD_TO_WATCHLIST_SUCCESS';
export const ADD_TO_WATCHLIST_NETWORKERROR = 'ADD_TO_WATCHLIST_NETWORKERROR';

export const REMOVE_FROM_WATCHLIST = 'REMOVE_FROM_WATCHLIST';
export const REMOVE_FROM_WATCHLIST_REQUEST = 'REMOVE_FROM_WATCHLIST_REQUEST';
export const REMOVE_FROM_WATCHLIST_FAILURE = 'REMOVE_FROM_WATCHLIST_FAILURE';
export const REMOVE_FROM_WATCHLIST_SUCCESS = 'REMOVE_FROM_WATCHLIST_SUCCESS';
export const REMOVE_FROM_WATCHLIST_NETWORKERROR = 'REMOVE_FROM_WATCHLIST_NETWORKERROR';

export const DELETE_WATCHLIST = 'DELETE_WATCHLIST';
export const DELETE_WATCHLIST_FAILURE = 'DELETE_WATCHLIST_FAILURE';
export const DELETE_WATCHLIST_SUCCESS = 'DELETE_WATCHLIST_SUCCESS';
export const DELETE_WATCHLIST_NETWORKERROR = 'DELETE_WATCHLIST_NETWORKERROR';

export const MERGE_WATCHLIST = 'MERGE_WATCHLIST';
export const MERGE_WATCHLIST_FAILURE = 'MERGE_WATCHLIST_FAILURE';
export const MERGE_WATCHLIST_SUCCESS = 'MERGE_WATCHLIST_SUCCESS';
export const MERGE_WATCHLIST_NETWORKERROR = 'MERGE_WATCHLIST_NETWORKERROR';

// COMPARISON
export const GET_PRODUCT_COMPARISON_SHORT = 'GET_PRODUCT_COMPARISON_SHORT';
export const GET_PRODUCT_COMPARISON_SHORT_REQUEST = 'GET_PRODUCT_COMPARISON_SHORT_REQUEST';
export const GET_PRODUCT_COMPARISON_SHORT_SUCCESS = 'GET_PRODUCT_COMPARISON_SHORT_SUCCESS';
export const GET_PRODUCT_COMPARISON_SHORT_FAILURE = 'GET_PRODUCT_COMPARISON_SHORT_FAILURE';
export const GET_PRODUCT_COMPARISON_SHORT_NETWORKERROR = 'GET_PRODUCT_COMPARISON_SHORT_NETWORKERROR';

export const GET_PRODUCT_COMPARISON_FULL = 'GET_PRODUCT_COMPARISON_FULL';
export const GET_PRODUCT_COMPARISON_FULL_REQUEST = 'GET_PRODUCT_COMPARISON_FULL_REQUEST';
export const GET_PRODUCT_COMPARISON_FULL_SUCCESS = 'GET_PRODUCT_COMPARISON_FULL_SUCCESS';
export const GET_PRODUCT_COMPARISON_FULL_FAILURE = 'GET_PRODUCT_COMPARISON_FULL_FAILURE';
export const GET_PRODUCT_COMPARISON_FULL_NETWORKERROR = 'GET_PRODUCT_COMPARISON_FULL_NETWORKERROR';

export const CHANGE_COMPARISON_CATEGORY = 'CHANGE_COMPARISON_CATEGORY';
export const CHANGE_COMPARISON_CATEGORY_SUCCESS = 'CHANGE_COMPARISON_CATEGORY_SUCCESS';
export const CHANGE_COMPARISON_CATEGORY_FAILURE = 'CHANGE_COMPARISON_CATEGORY_FAILURE';
export const CHANGE_COMPARISON_CATEGORY_NETWORKERROR = 'CHANGE_COMPARISON_CATEGORY_NETWORKERROR';

export const ADD_PRODUCT_TO_COMPARISON = 'ADD_PRODUCT_TO_COMPARISON';
export const ADD_PRODUCT_TO_COMPARISON_SUCCESS = 'ADD_PRODUCT_TO_COMPARISON_SUCCESS';
export const ADD_PRODUCT_TO_COMPARISON_FAILURE = 'ADD_PRODUCT_TO_COMPARISON_FAILURE';
export const ADD_PRODUCT_TO_COMPARISON_NETWORKERROR = 'ADD_PRODUCT_TO_COMPARISON_NETWORKERROR';

export const REMOVE_PRODUCT_FROM_COMPARISON = 'REMOVE_PRODUCT_FROM_COMPARISON';
export const REMOVE_PRODUCT_FROM_COMPARISON_SUCCESS = 'REMOVE_PRODUCT_FROM_COMPARISON';
export const REMOVE_PRODUCT_FROM_COMPARISON_FAILURE = 'REMOVE_PRODUCT_FROM_COMPARISON';
export const REMOVE_PRODUCT_FROM_COMPARISON_NETWORKERROR = 'REMOVE_PRODUCT_FROM_COMPARISON_NETWORKERROR';

export const CLOSE_COMPARISON_HEADER = 'CLOSE_COMPARISON_HEADER';

export const GET_TIME_CRITICAL_PRODUCT_DATA = 'GET_TIME_CRITICAL_PRODUCT_DATA';
export const GET_TIME_CRITICAL_PRODUCT_DATA_REQUEST = 'GET_TIME_CRITICAL_PRODUCT_DATA_REQUEST';
export const GET_TIME_CRITICAL_PRODUCT_DATA_FAILURE = 'GET_TIME_CRITICAL_PRODUCT_DATA_FAILURE';
export const GET_TIME_CRITICAL_PRODUCT_DATA_SUCCESS = 'GET_TIME_CRITICAL_PRODUCT_DATA_SUCCESS';
export const GET_TIME_CRITICAL_PRODUCT_NETWORKERROR = 'GET_TIME_CRITICAL_PRODUCT_DATA_NETWORKERROR';

// RATINGS
export const LOAD_RATING_SUMMARY = 'LOAD_RATING_SUMMARY';
export const LOAD_RATING_SUMMARY_REQUEST = 'LOAD_RATING_SUMMARY_REQUEST';
export const LOAD_RATING_SUMMARY_SUCCESS = 'LOAD_RATING_SUMMARY_SUCCESS';
export const LOAD_RATING_SUMMARY_FAILURE = 'LOAD_RATING_SUMMARY_FAILURE';
export const LOAD_RATINGS = 'LOAD_RATINGS';
export const LOAD_RATINGS_REQUEST = 'LOAD_RATINGS_REQUEST';
export const LOAD_RATINGS_SUCCESS = 'LOAD_RATINGS_SUCCESS';
export const LOAD_RATINGS_FAILURE = 'LOAD_RATINGS_FAILURE';
export const TOGGLE_RATING_MODAL = 'TOGGLE_RATING_MODAL';
export const CREATE_RATING = 'CREATE_RATING';
export const CREATE_RATING_REQUEST = 'CREATE_RATING_REQUEST';
export const CREATE_RATING_SUCCESS = 'CREATE_RATING_SUCCESS';
export const CREATE_RATING_FAILURE = 'CREATE_RATING_FAILURE';
export const UPDATE_RATING = 'UPDATE_RATING';
export const UPDATE_RATING_REQUEST = 'UPDATE_RATING_REQUEST';
export const UPDATE_RATING_SUCCESS = 'UPDATE_RATING_SUCCESS';
export const UPDATE_RATING_FAILURE = 'UPDATE_RATING_FAILURE';
export const DELETE_RATING = 'DELETE_RATING';
export const DELETE_RATING_REQUEST = 'DELETE_RATING_REQUEST';
export const DELETE_RATING_SUCCESS = 'DELETE_RATING_SUCCESS';
export const DELETE_RATING_FAILURE = 'DELETE_RATING_FAILURE';
export const LOAD_RATING = 'LOAD_RATING';
export const LOAD_RATING_REQUEST = 'LOAD_RATING_REQUEST';
export const LOAD_RATING_SUCCESS = 'LOAD_RATING_SUCCESS';
export const LOAD_RATING_FAILURE = 'LOAD_RATING_FAILURE';
export const RESET_RATING_FAILURE = 'RESET_RATING_FAILURE';
export const LOAD_PRODUCT_RATINGS = 'LOAD_PRODUCT_RATINGS';
export const LOAD_PRODUCT_RATINGS_REQUEST = 'LOAD_PRODUCT_RATINGS_REQUEST';
export const LOAD_PRODUCT_RATINGS_SUCCESS = 'LOAD_PRODUCT_RATINGS_SUCCESS';
export const LOAD_PRODUCT_RATINGS_FAILURE = 'LOAD_PRODUCT_RATINGS_FAILURE';

// STOCK
export const GET_STOCK_INFO = 'GET_STOCK_INFO';
export const GET_STOCK_INFO_REQUEST = 'GET_STOCK_INFO_REQUEST';
export const GET_STOCK_INFO_SUCCESS = 'GET_STOCK_INFO_SUCCESS';
export const GET_STOCK_INFO_FAILURE = 'GET_STOCK_INFO_FAILURE';
export const GET_STOCK_INFO_NETWORKERROR = 'GET_STOCK_INFO_NETWORKERROR';

export const RESET_STOCK_INFO = 'RESET_STOCK_INFO';
export const RESET_STOCK_INFO_REQUEST = 'RESET_STOCK_INFO_REQUEST';
export const RESET_STOCK_INFO_SUCCESS = 'RESET_STOCK_INFO_SUCCESS';
export const RESET_STOCK_INFO_FAILURE = 'RESET_STOCK_INFO_FAILURE';
export const RESET_STOCK_INFO_NETWORKERROR = 'RESET_STOCK_INFO_NETWORKERROR';

// CATEGORYTREE
export const SET_INITIAL_CATEGORIES = 'SET_INITIAL_CATEGORIES';

export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_CATEGORIES_REQUEST = 'GET_CATEGORIES_REQUEST';
export const GET_CATEGORIES_FAILURE = 'GET_CATEGORIES_FAILURE';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_NETWORKERROR = 'GET_CATEGORIES_NETWORKERROR';

export const LOAD_FEATURE_CONFIG = 'LOAD_FEATURE_CONFIG';
export const LOAD_FEATURE_CONFIG_REQUEST = 'LOAD_FEATURE_CONFIG_REQUEST';
export const LOAD_FEATURE_CONFIG_FAILURE = 'LOAD_FEATURE_CONFIG_FAILURE';
export const LOAD_FEATURE_CONFIG_SUCCESS = 'LOAD_FEATURE_CONFIG_SUCCESS';
export const LOAD_FEATURE_CONFIG_NETWORKERROR = 'LOAD_FEATURE_CONFIG_NETWORKERROR';

export const REQUEST_ORDER_DOCUMENTS_FAILURE = 'REQUEST_ORDER_DOCUMENTS_FAILURE';

// BRAND
export const GET_BRAND = 'GET_BRAND';
export const GET_BRAND_REQUEST = 'GET_BRAND_REQUEST';
export const GET_BRAND_FAILURE = 'GET_BRAND_FAILURE';
export const GET_BRAND_SUCCESS = 'GET_BRAND_SUCCESS';
export const GET_BRAND_NETWORKERROR = 'GET_BRAND_NETWORKERROR';

// RENDERING
export const RENDER_APP_BAD_REQUEST = 'RENDER_APP_BAD_REQUEST';
export const RENDER_APP_NOT_FOUND = 'RENDER_APP_NOT_FOUND';
export const RENDER_APP_INTERNAL_SERVER_ERROR = 'RENDER_APP_INTERNAL_SERVER_ERROR';
