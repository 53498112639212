import {
  MERGE_WATCHLIST,
  GET_WATCHLIST,
  CREATE_WATCHLIST,
  ADD_TO_WATCHLIST,
  REMOVE_FROM_WATCHLIST,
  DELETE_WATCHLIST,
} from 'constants/ActionTypes/ActionTypes';
import WatchlistAPI from 'api/WatchlistAPI/WatchlistAPI';

export const createWatchlist = () => ({
  type: CREATE_WATCHLIST,
  promise: WatchlistAPI.createWatchlist(),
});

export const getWatchlist = () => ({
  type: GET_WATCHLIST,
  promise: WatchlistAPI.getOrCreateWatchlist(),
});

export const mergeWatchlist = () => ({
  type: MERGE_WATCHLIST,
  promise: WatchlistAPI.mergeWatchlist(),
});

export const addToWatchlist = (productId) => ({
  type: ADD_TO_WATCHLIST,
  payload: { productCode: productId },
  promise: WatchlistAPI.addToWatchlist(productId),
});

export const removeFromWatchlist = (arrayIndex) => ({
  type: REMOVE_FROM_WATCHLIST,
  promise: WatchlistAPI.removeFromWatchlist(arrayIndex),
});

export const deleteWatchlist = () => ({
  type: DELETE_WATCHLIST,
  promise: WatchlistAPI.deleteWatchlist(),
});

const actions = {
  createWatchlist,
  getWatchlist,
  mergeWatchlist,
  addToWatchlist,
  removeFromWatchlist,
  deleteWatchlist,
};

export default actions;
