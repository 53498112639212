import { types } from 'components/organisms/Breadcrumb/Breadcrumb';
import { NEW_PRODUCT_URL_REGEX } from 'constants/urlHelper/urlHelper';

export const positions = {
  home: 'home',
  back: 'back',
  icon: 'icon',
  first: 'first',
  center: 'center',
  placeholder: 'placeholder',
  last: 'last',
};

export const sizes = {
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  XL: 'xl',
};

/**
 * Create skin pattern for specific types and positions of breadcrumb elements.
 * @returns {object} skin
 */
const getSkin = (type, position, size = sizes.SM) => ({
  type,
  position,
  size,
});

/**
 * Returns class-names depending on the used skin.
 * @param skin
 * @param css breadcrumbItem styles
 * @param hasPlaceholder
 */
const parseSkin = (skin, css) => {
  // account
  if (skin.type === types.account) {
    // active item
    if (skin.position === positions.last) {
      return `${css.item} ${css.active}`;
    }
  }

  // detail
  if (skin.type === types.detail) {
    // center items
    if (skin.position === positions.center) {
      return `${css.item}`;
    }
    // placeholder
    if (skin.position === positions.placeholder) {
      return `${css.item}`;
    }
    // active item
    if (skin.position === positions.last) {
      return `${css.item} ${css.active}`;
    }
  }

  // category
  if (skin.type === types.category) {
    // center items
    if (skin.position === positions.center) {
      return `${css.item}`;
    }
    // placeholder
    if (skin.position === positions.placeholder) {
      return `${css.item}`;
    }
    // active item
    if (skin.position === positions.last) {
      return `${css.item} ${css.active}`;
    }
  }

  // search
  if (skin.type === types.search) {
    // center items
    if (skin.position === positions.center) {
      return `${css.item}`;
    }
    // placeholder
    if (skin.position === positions.placeholder) {
      return `${css.item}`;
    }
    // active item
    if (skin.position === positions.last) {
      return `${css.item} ${css.active}`;
    }
  }

  return `${css.item}`;
};

const isBreadcrumbBack = () => {
  if (
    (__CLIENT__ && window.location.pathname.includes('--c') && window.location.search?.includes('?search')) ||
    (__CLIENT__ && NEW_PRODUCT_URL_REGEX.test(window.location.pathname) && sessionStorage.getItem('previousPath'))
  ) {
    return true;
  } else {
    return false;
  }
};

export default {
  getSkin,
  parseSkin,
  isBreadcrumbBack,
};
