export const getNewestProductPriceData = (prevProductPriceData, nextProductPriceData) => {
  if (
    prevProductPriceData?.timestampCreation &&
    nextProductPriceData?.timestampCreation &&
    new Date(prevProductPriceData.timestampCreation) > new Date(nextProductPriceData.timestampCreation)
  ) {
    return prevProductPriceData;
  }
  return nextProductPriceData;
};
