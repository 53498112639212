import React from 'react';
import PropTypes from 'prop-types';

import DrilldownAccordion from 'components/atoms/DrilldownAccordion/DrilldownAccordion';
import MobileNavigationItem from 'components/atoms/MobileNavigationItem/MobileNavigationItem';

import language, { getLocale } from 'constants/language/language';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import { WALL } from 'constants/routePaths/routePaths';
import { CMS_UID_BROCHURE } from 'constants/cms/cms';
import CmsLink from 'containers/CmsLink/CmsLink';
import BrochureNavigationLink from 'components/atoms/BrochureNavigationLink/BrochureNavigationLink';
import { NAVIGATION_SUB } from 'constants/trackingAttributes/trackingAttributes';

import styles from './MobileDrilldown.scss';

const PromotionsSection = ({ isResetToStart }) => {
  const navigationPromotionLabel = language('navigation.promotion');

  return (
    <DrilldownAccordion title={navigationPromotionLabel} pos={2} isResetToStart={isResetToStart}>
      <MobileNavigationItem
        href={mapPathToLocalizedUrl(getLocale(), [WALL])}
        tracking={{
          type: NAVIGATION_SUB,
          position: 1,
          title: language('navigation.onlinePromotion'),
          parentTitle: navigationPromotionLabel,
        }}
      >
        {language('navigation.onlinePromotion')}
      </MobileNavigationItem>
      <div className={styles.navigationItem}>
        <CmsLink pageId={CMS_UID_BROCHURE}>
          <BrochureNavigationLink type={NAVIGATION_SUB} parentTitle={navigationPromotionLabel} pos={2} />
        </CmsLink>
      </div>
    </DrilldownAccordion>
  );
};

export default PromotionsSection;

PromotionsSection.propTypes = {
  title: PropTypes.string,
  entries: PropTypes.array,
  isResetToStart: PropTypes.func,
};
