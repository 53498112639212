/**
 * getPriceData
 *
 * Purpose of the different price objects:
 * finalPrice = End price, this is how much the customer will pay
 * insteadPrice = The previous price of the product (can be fixInsteadPrice or discount or fixInsteadPrice+discount)
 * basePrice = Is the fixInsteadPrice without discount. Will only be returend when fixInsteadPrice AND discount is given
 * savings =  The total savings between the finalPrice and the insteadPrice
 * discount = Contains the discount infromation. Discounts can be a percentage (e.g. 10%) OR absolute (fix CHF value)
 *
 * ignoreExpiredOfLastPrice is meant for timeCritical calls. Those calls are up to date anyway
 * so we don't want to return an empty object and just return the latest not expired price.
 */
export const getPriceData = (priceData = {}, ignoreExpiredOfLastPrice = false) => {
  if (!priceData?.prices) {
    return {};
  }

  const isDateExpired = (date) => {
    const expires = new Date(date);
    const now = new Date();
    return expires < now;
  };

  const getPrice = (price) => {
    if (!price?.value) {
      return {};
    }
    const currencyIso = price.currencyIso;
    const value = price.value;
    return { currencyIso, value };
  };

  const getDiscount = (discount) => {
    if (!discount) {
      return {};
    }

    const now = new Date();
    const from = new Date(discount.from);
    const to = new Date(discount.to);
    if (now < from || now > to) {
      // is upcomming or expired
      return {};
    }

    const absolute = discount.absolute;
    if (!absolute) {
      // is percentage discount else absolute discount
      const percentage = discount.percentage;
      return { absolute, percentage, from, to };
    }

    const absoluteDiscount = getPrice(discount.absoluteDiscount);
    return { absolute, absoluteDiscount, from, to };
  };

  const timestampCreation = priceData.timestampCreation;
  const prices = priceData.prices || [];
  const validPrice =
    prices.find((element) => !isDateExpired(element.expires) && element) ||
    (ignoreExpiredOfLastPrice ? prices.slice(-1)[0] : {});
  const finalPrice = getPrice(validPrice.finalPrice);
  const basePrice = getPrice(validPrice.basePrice);
  const insteadPrice = getPrice(validPrice.insteadPrice);
  const savings = getPrice(validPrice.savings);
  const discount = getDiscount(validPrice.discount);
  const price = { timestampCreation, finalPrice, basePrice, insteadPrice, savings, discount };

  return price;
};
