import loadGoogleMapsAPI from 'load-google-maps-api';

import restClient from 'api/RestClient/RestClient';
import { resourceKeys } from 'constants/restResources/restResources';
import { getLocale } from 'constants/language/language';
const GOOGLE_SERVICE_RESOURCE = resourceKeys.GOOGLE_SERVICE_RESOURCE;

const getGoogleKey = () => {
  if (__CLIENT__) {
    return window?.__ENV_VARIABLES__?.googleKey;
  }
  return '';
};

const config = {
  key: getGoogleKey(),
  channel: 'shop-interdiscount',
};

const googleMapsFallback = (googleMaps, address) => {
  const geocoder = new googleMaps.Geocoder();
  const stores = [];
  const shouldContain = ['administrative_area_level_1', 'locality', 'postal_code'];
  const promiseLI = new Promise((resolve, reject) => {
    geocoder.geocode(
      {
        address,
        componentRestrictions: {
          country: 'LI',
        },
      },
      (results, status) => {
        if (
          status === googleMaps.GeocoderStatus.OK &&
          shouldContain.some((value) => results[0].types.includes(value))
        ) {
          stores.push(results[0]);
          resolve(stores[0]);
        } else {
          reject(`Geocode was not successful for the following reason: ${status}`);
        }
      }
    );
  });
  const promiseCH = new Promise((resolve, reject) => {
    geocoder.geocode(
      {
        address,
        componentRestrictions: {
          country: 'CH',
        },
      },
      (results, status) => {
        if (status === googleMaps.GeocoderStatus.OK || stores.length) {
          if (results.length) {
            stores.push(results[0]);
          }
          resolve(stores[0]);
        } else {
          reject(`Geocode was not successful for the following reason: ${status}`);
        }
      }
    );
  });
  // this Promise.all is constructed as a Promise.some
  return Promise.all(
    [promiseLI, promiseCH].map((promise) =>
      // when a promise fails it's counted as a resolution, so the second promise will be executed.
      // as soon as a promise succeeds, it's treated as a rejection, so the Promise.all immediately bails out.
      promise.then(
        (value) => Promise.reject(value),
        (error) => Promise.resolve(error)
      )
    )
  ).then(
    // important: reject and resolve are called in it's opposite (resolve => reject, reject => resolve)
    // if P.all resolved, we've got an array of all errors
    // if P.all rejected, we've got an array of the wanted values
    (errors) => Promise.reject(errors),
    (values) => Promise.resolve(values)
  );
};

function mapsGetGeoLocation(googleMaps, address) {
  return restClient
    .get(GOOGLE_SERVICE_RESOURCE, `${address}?language=${getLocale()}`, false)
    .then((response) => response.data)
    .catch(() =>
      googleMapsFallback(googleMaps, address).then((response) => ({
        locality: response.formatted_address,
        latitude: response.geometry.location.lat(),
        longitude: response.geometry.location.lng(),
      }))
    );
}

function mapsGetGeoLocationName(googleMaps, coordinates = {}) {
  const geocoder = new googleMaps.Geocoder();
  const LatLng = { lat: parseFloat(coordinates.latitude), lng: parseFloat(coordinates.longitude) };

  return new Promise((resolve, reject) =>
    geocoder.geocode({ location: LatLng }, (results, status) => {
      if (status === googleMaps.GeocoderStatus.OK) {
        resolve(results[0]);
      } else {
        reject(`Reverse-Geocode was not successful for the following reason: ${status}`);
      }
    })
  );
}

/**
 * Returns position of a location stated in the search term by using the google geocode Javascript API
 * @param address
 */
function getGeoLocation(address) {
  return loadGoogleMapsAPI(config)
    .then((googleMaps) => mapsGetGeoLocation(googleMaps, address))
    .catch((err) => Promise.reject(err));
}

/**
 * Returns the nearest town of the current user position
 * @param latitude
 * @param longitude
 */
function getGeoLocationName(latitude, longitude) {
  return loadGoogleMapsAPI(config)
    .then((googleMaps) => mapsGetGeoLocationName(googleMaps, { latitude, longitude }))
    .catch((err) => Promise.reject(err));
}

function getMaps() {
  return loadGoogleMapsAPI(config).catch((err) => Promise.reject(err));
}

const GoogleAPI = {
  getGeoLocation,
  getGeoLocationName,
  getMaps,
  mapsGetGeoLocation,
  mapsGetGeoLocationName,
};

export {
  GoogleAPI as default,
  getGeoLocation,
  getGeoLocationName,
  getMaps,
  mapsGetGeoLocation,
  mapsGetGeoLocationName,
};
