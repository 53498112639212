import { useEffect } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { useEffectOnce } from 'usehooks-ts';
import { useSpeedCurve } from 'containers/SpeedCurve/useSpeedCurve';

const handleRouteLeave = () => {
  window.LUX?.markLoadTime();
};
const handleRouteEnter = () => {
  window.LUX?.send();
  window.LUX?.init();
};

const setLabel = (label) => {
  if (!window.LUX?.label) return;
  window.LUX.label = label;
};

function SpeedCurve(props) {
  const { children, label, router } = props;

  useSpeedCurve();

  useEffectOnce(() => {
    router.listenBefore(handleRouteLeave);
    router.listen(handleRouteEnter);
    return () => {
      router.unsubscribe();
    };
  });

  useEffect(() => setLabel(label), [label]);

  return children;
}

SpeedCurve.displayName = 'containers/SpeedCurve';

SpeedCurve.propTypes = { children: PropTypes.node, label: PropTypes.string };

export default withRouter(SpeedCurve);
