export const isStoreOpen = (openStatusInfo) => {
  const isOpen = openStatusInfo?.isCurrentlyOpen ?? false;
  // Check if openStatusInfo expired and load new Data (just for the info that store is open/closed)
  const expires = openStatusInfo?.expires;
  const expiryDate = +new Date(expires);
  if (expiryDate < +new Date()) {
    return !isOpen;
  }
  return isOpen;
};

export const loadStoreWithGeolocation = (getMyStore, storeId) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition((position) => {
      getMyStore(storeId, position.coords.latitude, position.coords.longitude);
    });
  }
};

export const STOREFINDER_INTERDISCOUNT_LOGO_URL = '/medien/id/marketing/filialleiter/12345.jpg';

export default {
  isStoreOpen,
  loadStoreWithGeolocation,
  STOREFINDER_INTERDISCOUNT_LOGO_URL,
};
