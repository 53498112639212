import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { composeRecoProductsUrl } from 'api/RecoAPI/RecoAPI.helper';
import { loadProductList } from 'actions/productActions/productActions';
import { loadRecommendedProducts as loadRecommendedProductsAction } from 'actions/recoActions/recoActions';
import { trackRecommendedProducts } from 'actions/trackingActions/trackingActions';
import getCookieStorage from 'constants/storage/cookie';
import { RECO_COOKIE } from 'constants/storage/storageKeys';
import { getLocale } from 'constants/language/language';
import ProductsBox from 'components/organisms/ProductsBox/ProductsBox';
import { joinProductCodes } from 'containers/ProductRecommendations/ProductRecommendations.helper';
import { getMatchingProductsFromStore } from './RecommendationsBox.helper';

const mapStateToProps = (state, ownProps) => ({
  similar: state.recommendations?.[ownProps.productId]?.similar,
  boughtByOthers: state.recommendations?.[ownProps.productId]?.boughtByOthers,
  products: state.products,
  cartEntries: state.cart.entries,
});

const mapDispatchToProps = {
  loadRecommendedProducts: loadRecommendedProductsAction,
  trackRecommendedProductsAction: trackRecommendedProducts,
  loadProductList,
};

export const recoCategories = {
  SIMILAR_PRODUCTS: 'box1',
  BOUGHT_BY_OTHERS: 'box2',
};

const cookie = getCookieStorage();

const RecommendationsBox = ({
  similar,
  boughtByOthers,
  products,
  productId,
  categoryId,
  loadRecommendedProducts,
  trackRecommendedProductsAction,
  cartEntries = [],
}) => {
  useEffect(() => {
    const sessionId = cookie.getItem(RECO_COOKIE);
    const locale = getLocale();
    const currentBasket = joinProductCodes(cartEntries);
    const recoProductsUrl = composeRecoProductsUrl(productId, categoryId, locale, currentBasket, sessionId);

    loadRecommendedProducts(productId, categoryId, sessionId, recoProductsUrl).then(() => {
      trackRecommendedProductsAction({ type: productId, hasRecommendedProducts: true });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId, categoryId, loadRecommendedProducts, trackRecommendedProductsAction]);

  return (
    <>
      <ProductsBox
        title={similar?.title}
        cyData={'similarProducts'}
        visibleElements={getMatchingProductsFromStore(similar?.products, products)}
      />
      <ProductsBox
        title={boughtByOthers?.title}
        cyData={'alsoBoughtProducts'}
        visibleElements={getMatchingProductsFromStore(boughtByOthers?.products, products)}
      />
    </>
  );
};

RecommendationsBox.displayName = 'containers/RecommendationsBox';
RecommendationsBox.propTypes = {
  boughtByOthers: PropTypes.object,
  similar: PropTypes.object,
  products: PropTypes.object,
  categoryId: PropTypes.string,
  loadRecommendedProducts: PropTypes.func,
  trackRecommendedProductsAction: PropTypes.func,
  productId: PropTypes.string,
  cartEntries: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(RecommendationsBox);
