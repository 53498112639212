import React from 'react';
import PropTypes from 'prop-types';
import language from 'constants/language/language';
import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import Markdown from 'components/atoms/Markdown/Markdown';

import styles from './OrderConfirmation.scss';
import OrderAPI from 'api/OrderAPI/OrderAPI';
import { isB2BCustomer } from 'constants/customerGroup/customerGroup';
import {
  DELIVERY_MODE,
  ESD_MIXED_CART,
  ESD_ONLY_CART,
  IS_LOGGED_IN,
  PAYMENT_MODE,
  PEGI_VERIFICATION_NEEDED,
} from 'constants/storage/storageKeys';
import { getFullCheckoutStepsKey } from 'constants/orderConfirmation/orderConfirmation';
import getStorage from 'constants/storage/storage';
import {useFeatureToggle} from "hooks/index";

const OrderConfirmation = ({ orderNumber, orderGuid, customerEmail }) => {
  const localStorage = getStorage(true);
  const isLoggedIn = JSON.parse(localStorage.getItem(IS_LOGGED_IN));
  const deliveryMode = localStorage.getItem(DELIVERY_MODE);
  const paymentMode = localStorage.getItem(PAYMENT_MODE);
  const pegiNeeded = localStorage.getItem(PEGI_VERIFICATION_NEEDED) === 'true';
  const esdOnly = localStorage.getItem(ESD_ONLY_CART) === 'true';
  const esdMixed = localStorage.getItem(ESD_MIXED_CART) === 'true';

  const confirmationMessages = language(
    getFullCheckoutStepsKey(deliveryMode, paymentMode, pegiNeeded, esdOnly, esdMixed)
  );

  const handlePrint = () => {
    OrderAPI.generateInvoiceDocument(orderGuid, orderNumber, false, true);
  };

  const nextHomepageEnabled = useFeatureToggle('nextHomepageEnabled') === "true" ?? false;

  return (
    <div className={styles.container}>
      <div>
        <h2>
          {language('checkoutSuccess.orderNumber')} {orderNumber}
        </h2>
        {__CLIENT__ && confirmationMessages.emailConfirmation && (
          <Markdown areLinksDisabled>
            {confirmationMessages.emailConfirmation.replace('{email}', customerEmail)}
          </Markdown>
        )}
        {__CLIENT__ &&
          confirmationMessages.instructions &&
          confirmationMessages.instructions.map((message) => (
            <Markdown areLinksDisabled key={message}>
              {message}
            </Markdown>
          ))}
      </div>
      <div className={styles.buttonWrapper}>
        <ButtonLink variety={ButtonLink.varieties.flat} color={'red'} href={mapPathToLocalizedUrl()} outsideRouter={nextHomepageEnabled}>
          <span>{language('checkoutSuccess.continueShopping')}</span>
        </ButtonLink>
        {!isB2BCustomer() && isLoggedIn && (
          <ButtonLink variety={ButtonLink.varieties.flat} color={'white'} onClick={handlePrint}>
            <span>{language('checkoutSuccess.printOrder')}</span>
          </ButtonLink>
        )}
      </div>
    </div>
  );
};

OrderConfirmation.propTypes = {
  orderNumber: PropTypes.string.isRequired,
  orderGuid: PropTypes.string.isRequired,
  customerEmail: PropTypes.string.isRequired,
};

OrderConfirmation.defaultProps = {
  orderNumber: '',
  orderGuid: '',
  customerEmail: '',
};

OrderConfirmation.displayName = 'templates/CheckoutSuccess/OrderConfirmation';

export default OrderConfirmation;
