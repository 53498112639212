import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';

import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';
import CheckoutButton from 'components/molecules/CheckoutButton/CheckoutButton';
import language, { getLocale } from 'constants/language/language';
import { checkout } from 'constants/routePaths/routePathCombinations';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import { cypressAttributes } from 'constants/cypress/cypress';
import dataTracking from 'constants/trackingAttributes/dataTracking';
import styles from './OrderBox.scss';
import useTelesalesMode from 'hooks/useTelesalesMode';
import { useFeatureToggle } from 'hooks/index';

const OrderBox = ({
  clearUpdatedCartHistory = () => {},
  deliveryCost = {},
  fullTaxRate = '',
  handlePlaceOrder = () => {},
  isCart = false,
  orderTotal = {},
  paymentCost = {},
  reducedTaxRate = '',
  subTotal = {},
  totalFullTax = {},
  totalItems,
  totalPriceNet = {},
  totalReducedTax = {},
  transportGuarantee = {},
  amountRemainingToPay = {},
  couponInvalid,
  cartInvalid,
}) => {
  const isTelesalesMode = useTelesalesMode();
  const nextHomepageEnabled = useFeatureToggle('nextHomepageEnabled') === 'true' ?? false;
  const getTaxRows = () => {
    // necessary because cart and checkout use different tax objects to handle transport guarantee fee
    const fullTax = totalFullTax?.rate || fullTaxRate;
    const reducedTax = totalReducedTax?.rate || reducedTaxRate;

    return (
      <>
        {totalReducedTax.value !== 0 && (
          <div className={styles.row}>
            <span>{`${reducedTax}% ${language('orderBox.tax')}`}</span>
            <strong>{totalReducedTax.formattedValue}</strong>
          </div>
        )}
        {totalFullTax.value !== 0 && (
          <div className={styles.row}>
            <span>{`${fullTax}% ${language('orderBox.tax')}`}</span>
            <strong>{totalFullTax.formattedValue}</strong>
          </div>
        )}
      </>
    );
  };

  const nextCheckoutEnabled = useFeatureToggle('nextCheckoutEnabled') === 'true' ?? false;

  const goToCheckout = () => {
    clearUpdatedCartHistory();

    if (nextCheckoutEnabled) {
      window.location.replace(window.location.origin + mapPathToLocalizedUrl(getLocale(), checkout));
    } else {
      browserHistory.push(mapPathToLocalizedUrl(getLocale(), checkout));
    }
  };

  const goToTelesalesCheckout = () => {
    // go to TS screen 0100 - Order Overview - articles/overview route
    // TODO TELESALES: add the real cart id number
    window.location.replace(window.location.origin + '/telesales/articles/overview');
  };

  // CART
  if (isCart) {
    return (
      <div className={styles.container}>
        <h3 className={styles.title}>{language('orderBox.orderOverview')}</h3>
        <span className={styles.articles}>{`${totalItems} ${language('orderBox.article')}`}</span>

        <hr className={styles.hr} />

        <div className={styles.row}>
          <span>{language('orderBox.totalExklTax')}</span>
          <strong>{totalPriceNet.formattedValue}</strong>
        </div>
        {getTaxRows()}

        <hr className={styles.hr} />

        <div className={styles.row}>
          <strong>{language('orderBox.subtotal')}</strong>
          <strong data-cy={cypressAttributes.cart.subTotal}>{orderTotal.formattedValue}</strong>
        </div>
        <div className={styles.shippingCost}>
          {deliveryCost.value === 0 ? language('orderBox.shippingCostsFree') : language('orderBox.shippingCostsUnkown')}
        </div>

        <div className={styles.toCheckout}>
          <ButtonLink
            color={isTelesalesMode ? ButtonLink.colors.blue : ButtonLink.colors.red}
            onClick={isTelesalesMode ? goToTelesalesCheckout : goToCheckout}
            stretched
            data-cy={cypressAttributes.cart.btnToBoxOffice}
            data-tracking={dataTracking({
              TYPE: 'form:button',
              FUNCTION: 'go-to',
              ATTRIBUTE_1: 'primary',
              ATTRIBUTE_2: language('orderBox.goToOrder'),
            })}
          >
            {language('orderBox.goToOrder')}
          </ButtonLink>
        </div>
        <ButtonLink
          href={mapPathToLocalizedUrl()}
          variety={ButtonLink.varieties.stroked}
          stretched
          data-tracking={dataTracking({
            TYPE: 'form:button',
            FUNCTION: 'go-to',
            ATTRIBUTE_1: 'basic',
            ATTRIBUTE_2: language('orderBox.continueShopping'),
          })}
          outsideRouter={nextHomepageEnabled}
        >
          {language('orderBox.continueShopping')}
        </ButtonLink>
      </div>
    );
  }

  // CHECKOUT
  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{language('orderBox.orderOverview')}</h3>
      <span className={styles.articles}>{`${totalItems} ${language('orderBox.article')}`}</span>

      <hr className={styles.hr} />

      <div className={styles.row}>
        <span>{language('orderBox.subtotal')}</span>
        <strong data-cy={cypressAttributes.checkout.subtotalPrice}>{subTotal.formattedValue}</strong>
      </div>
      {!!transportGuarantee.cost.value && (
        <div className={styles.row}>
          <span>{language('orderBox.transportGuarantee')}</span>
          <strong>{transportGuarantee.cost.formattedValue}</strong>
        </div>
      )}
      <div className={styles.row}>
        <span>{language('orderBox.deliveryCost')}</span>
        <strong>{deliveryCost.formattedValue}</strong>
      </div>
      {!!paymentCost.value && (
        <div className={styles.row}>
          <span>{language('checkout.orderSummary.paymentCharge')}</span>
          <strong>{paymentCost.formattedValue}</strong>
        </div>
      )}

      <hr className={styles.hr} />

      <div className={styles.row}>
        <span>{language('orderBox.totalExklTax')}</span>
        <strong>{totalPriceNet.formattedValue}</strong>
      </div>
      {getTaxRows()}

      <hr className={styles.hr} />

      <div className={styles.row}>
        <strong>{language('orderBox.total')}</strong>
        <strong data-cy={cypressAttributes.checkout.totalPrice}>{amountRemainingToPay.formattedValue}</strong>
      </div>

      <div className={styles.placeOrder}>
        <CheckoutButton handlePlaceOrder={handlePlaceOrder} couponInvalid={couponInvalid} cartInvalid={cartInvalid} />
      </div>

      <hr className={styles.hr} />

      <small>{language('orderBox.contact.text')}</small>
      <a className={styles.helpTelephone} href={language('orderBox.contact.telephoneHref')}>
        {language('orderBox.contact.telephone')}
      </a>
      <small>
        <div>{language('orderBox.contact.openingDays')}</div>
        <div>{language('orderBox.contact.openingHours')}</div>
      </small>
    </div>
  );
};

OrderBox.displayName = 'molecules/OrderBox';
OrderBox.propTypes = {
  clearUpdatedCartHistory: PropTypes.func,
  deliveryCost: PropTypes.object,
  fullTaxRate: PropTypes.string,
  handlePlaceOrder: PropTypes.func,
  isCart: PropTypes.bool,
  orderTotal: PropTypes.object,
  paymentCost: PropTypes.object,
  reducedTaxRate: PropTypes.string,
  subTotal: PropTypes.object,
  totalFullTax: PropTypes.object,
  totalItems: PropTypes.number,
  totalPriceNet: PropTypes.object,
  totalReducedTax: PropTypes.object,
  transportGuarantee: PropTypes.object,
  amountRemainingToPay: PropTypes.object,
  couponInvalid: PropTypes.bool,
  cartInvalid: PropTypes.bool,
};

export default OrderBox;
