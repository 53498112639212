import React, { useState } from 'react';
import PropTypes from 'prop-types';
import language from 'constants/language/language';
import dataTracking from 'constants/trackingAttributes/dataTracking';
import ModalUpsellingCart from 'components/organisms/ModalUpsellingCart/ModalUpsellingCart';
import ServicesAndAccessories from 'components/organisms/ServicesAndAccessories/ServicesAndAccessories';
import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';

const ProductCartAccessoires = ({
  cartEntryCodes = [],
  code = '',
  entryID = 0,
  fetchReferences = () => {},
  imageSizes = [],
  name = '',
  productReferences = [],
  shouldFetchReferences = false,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    if (shouldFetchReferences) {
      fetchReferences(code);
    }
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <ButtonLink
        variety={ButtonLink.varieties.stroked}
        onClick={() => openModal()}
        data-tracking={dataTracking({
          TYPE: 'form:button',
          FUNCTION: 'apply',
          ATTRIBUTE_1: 'secondary',
          ATTRIBUTE_2: language('cart.addAccessories'),
        })}
      >
        {language('cart.addAccessories')}
      </ButtonLink>
      {isModalOpen && (
        <ModalUpsellingCart
          closeModal={closeModal}
          code={imageSizes?.[0]?.code}
          images={imageSizes?.[0]?.sizes}
          isModalOpen={isModalOpen}
          productName={name}
        >
          <ServicesAndAccessories
            accessories={cartEntryCodes}
            entryID={entryID}
            isUpsellingCart
            productCode={code}
            referenceItemCodes={productReferences}
          />
        </ModalUpsellingCart>
      )}
    </>
  );
};

ProductCartAccessoires.displayName = 'molecules/ProductCartAccessoires';
ProductCartAccessoires.propTypes = {
  cartEntryCodes: PropTypes.array,
  code: PropTypes.string,
  entryID: PropTypes.number,
  fetchReferences: PropTypes.func,
  imageSizes: PropTypes.array,
  name: PropTypes.string,
  productReferences: PropTypes.array,
  shouldFetchReferences: PropTypes.bool,
};

export default ProductCartAccessoires;
