import React, { Component } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import language from 'constants/language/language';
import viewTypes from 'constants/storeFinderResultsViewTypes/storeFinderResultsViewTypes';
import { cypressAttributes } from 'constants/cypress/cypress';
import SeoStructuredData, { seoStructuredDataTypes } from 'components/organisms/SeoStructuredData/SeoStructuredData';
import MoreInformation from './MoreInformation';
import StoreFinderListItemDetail from './StoreFinderListItemDetail';
import { Col, Container, Row } from 'components/atoms/Grid/Grid';
import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';
import OpeningHoursRegular from 'components/atoms/OpeningHoursRegular/OpeningHoursRegular';
import OpeningHoursSpecial from 'components/atoms/OpeningHoursSpecial/OpeningHoursSpecial';
import StoreManagerInfo from 'components/atoms/StoreManagerInfo/StoreManagerInfo';
import styles from './StoreFinderListItem.scss';

class StoreFinderListItem extends Component {
  static displayName = 'molecules/StoreFinderListItem';

  static propTypes = {
    store: PropTypes.object.isRequired,
    selectPickupStore: PropTypes.func.isRequired,
    selectPickupStoreEnabled: PropTypes.bool.isRequired,
    detailMode: PropTypes.bool,
    borderless: PropTypes.bool,
    switchView: PropTypes.func,
    activeView: PropTypes.string,
    isMyStore: PropTypes.bool,
    saveAsMyStore: PropTypes.func,
    isLoggedIn: PropTypes.bool,
    isInventoryStorefinder: PropTypes.bool,
    showDistance: PropTypes.bool,
    availability: PropTypes.string,
    releaseDate: PropTypes.string,
  };

  static defaultProps = {
    detailMode: false,
    borderless: false,
    switchView: () => {},
    activeView: viewTypes.LIST,
    isMyStore: false,
    saveAsMyStore: () => {},
    isLoggedIn: false,
    selectPickupStoreEnabled: false,
    isInventoryStorefinder: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      showMoreInformation: false,
    };
  }

  toggleInformation = () => {
    this.setState({ showMoreInformation: !this.state.showMoreInformation });
  };

  hasOpeningHours = (attr) => {
    const { openingHours } = this.props.store;
    return !!Object.keys(openingHours?.[attr] || {})?.length;
  };

  hasWeekDayOpeningHours = () => this.hasOpeningHours('weekDayOpeningList');

  hasSpecialDayOpeningHours = () => this.hasOpeningHours('specialDayOpeningList');

  handleSwitchView = () => {
    const storeName = this.props.store?.name;
    this.props.switchView(viewTypes.MAP, storeName);
  };

  selectMyStore = (code) => {
    this.props.saveAsMyStore(code);
  };

  render() {
    const {
      selectPickupStore,
      selectPickupStoreEnabled,
      store,
      detailMode,
      borderless,
      isMyStore,
      isLoggedIn,
      activeView,
      isInventoryStorefinder,
      showDistance,
      availability,
      releaseDate,
    } = this.props;

    const {
      format,
      type,
      description,
      formattedDistance,
      address,
      openingHours,
      awardUrl,
      manager,
      quantity,
    } = this.props.store;

    const showSaveAsMyStore = isLoggedIn && !selectPickupStoreEnabled && !isMyStore;

    return (
      <Container className={cx(styles.wrapper, { [styles.borderless]: borderless })}>
        <Row>
          <SeoStructuredData type={seoStructuredDataTypes.LOCALBUSINESS} data={this.props.store} />
          <StoreFinderListItemDetail
            format={format}
            type={type}
            description={description}
            formattedDistance={formattedDistance}
            address={address}
            openingHours={openingHours}
            awardUrl={awardUrl}
            isMyStore={isMyStore}
            availableQuantity={quantity}
            showDistance={showDistance}
            availability={availability}
            releaseDate={releaseDate}
          />
          <Col sm={9} offsetSm={3} lg={3} offsetLg={0} className={styles.buttons}>
            {!isInventoryStorefinder && selectPickupStoreEnabled && (
              <div className={styles.btn}>
                <ButtonLink
                  size={ButtonLink.sizes.sm}
                  variety={ButtonLink.varieties.flat}
                  onClick={() => selectPickupStore(store.name)}
                  data-cy={borderless ? cypressAttributes.checkout.storeFinderPickUpHere : undefined}
                >
                  <span>{language('pickupStore.pickupHere')}</span>
                </ButtonLink>
              </div>
            )}
            {!isInventoryStorefinder && showSaveAsMyStore && (
              <div className={styles.btn}>
                <ButtonLink
                  size={ButtonLink.sizes.sm}
                  variety={ButtonLink.varieties.flat}
                  onClick={() => this.selectMyStore(store.name)}
                >
                  <span>{language('storeFinder.myStore.save')}</span>
                </ButtonLink>
              </div>
            )}
            {!isInventoryStorefinder && !selectPickupStoreEnabled && isMyStore && (
              <div className={styles.myStoreLabel}>{language('storeFinder.myStore.mySavedStore')}</div>
            )}
            {!detailMode && (this.hasWeekDayOpeningHours() || this.hasSpecialDayOpeningHours()) && (
              <MoreInformation toggle={this.toggleInformation} showMore={this.state.showMoreInformation} />
            )}
          </Col>
        </Row>
        {(detailMode || this.state.showMoreInformation) &&
          (this.hasWeekDayOpeningHours() || this.hasSpecialDayOpeningHours()) && (
            <Row className={styles.openingHours}>
              <Col lg={9}>
                <Row>
                  {this.hasWeekDayOpeningHours() && (
                    <Col xxs={12} sm={9} offsetSm={3} lg={4} offsetLg={3} className={styles.weekDayOpeningList}>
                      <OpeningHoursRegular weekDayOpeningList={openingHours.weekDayOpeningList} />
                    </Col>
                  )}
                  {this.hasSpecialDayOpeningHours() && (
                    <Col
                      xxs={12}
                      sm={9}
                      offsetSm={3}
                      lg={4}
                      offsetLg={0}
                      xl={3}
                      className={styles.specialDayOpeningList}
                    >
                      <OpeningHoursSpecial specialDayOpeningList={openingHours.specialDayOpeningList} />
                    </Col>
                  )}
                </Row>
              </Col>
              <Col sm={9} offsetSm={3} lg={3} offsetLg={0}>
                <Row>
                  <Col>
                    <StoreManagerInfo storeManager={manager} phone={address?.phone} />
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        {(detailMode || this.state.showMoreInformation) && activeView === viewTypes.LIST && (
          <Row>
            <Col xxs={12} sm={9} offsetSm={3} lg={12} offsetLg={0}>
              <div className={styles.goToMap} onClick={this.handleSwitchView}>
                {language('pickupStore.goToMap')}
              </div>
            </Col>
          </Row>
        )}
        {(detailMode || this.state.showMoreInformation) && (
          <Row>
            <Col xxs={12} sm={9} offsetSm={3} lg={10} offsetLg={2}>
              <div className={styles.infoBox}>
                <div className={styles.infoText}>
                  <span>{language('pickupStore.infoBox.text1')}</span>
                </div>
                <div className={styles.phone}>{language('pickupStore.infoBox.phone')}</div>
                <div className={styles.openingInfo}>
                  <span className={styles.openingDays}>{language('pickupStore.infoBox.openingDays')}</span>
                  <span>{language('pickupStore.infoBox.openingHours')}</span>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </Container>
    );
  }
}

export default StoreFinderListItem;
