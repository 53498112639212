import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import styles from './SidebarNavigationHeader.scss';
import cx from 'classnames';

const SidebarNavigationHeader = ({ text, route, currentPath }) => {
  const classes = cx(styles.link, {
    [styles.active]: currentPath === route,
  });
  return (
    <div className={styles.container}>
      {route ? (
        <div className={classes}>
          <Link to={route}>{text}</Link>
        </div>
      ) : (
        <p className={classes}>{text}</p>
      )}
    </div>
  );
};

SidebarNavigationHeader.displayName = 'atoms/SidebarNavigationHeader';

SidebarNavigationHeader.propTypes = {
  text: PropTypes.string,
  route: PropTypes.string,
  currentPath: PropTypes.string,
};

export default SidebarNavigationHeader;
