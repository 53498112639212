import restClient from 'api/RestClient/RestClient';
import { postLog } from 'api/promiseMiddleware';
import { resourceKeys } from 'constants/restResources/restResources';
import { ANONYMOUS_USER_ID, getUserId } from 'api/UserAPI/UserAPI';

import addressMappings from '../mappings/AddressMappings';
import { addressTypes } from 'constants/addresses/addresses';
import { LAST_ORDER_GUID, CART_ID, DEFAULT_DELIVERY_APPLIED } from 'constants/storage/storageKeys';
import { getLocale } from 'constants/language/language';
import getStorage from 'constants/storage/storage';
import { INVALID_PARAMS, INVALID_PARAM_REFERENCE } from 'constants/promises/promises';
import { FALLBACK_PRODUCT_IMAGE } from 'constants/products/index';
import validation, { ValidationTypes } from 'constants/validation/validation';

const storage = getStorage(true);

const USER_RESOURCE = resourceKeys.USER_RESOURCE;
const BASE_RESOURCE = resourceKeys.BASE_RESOURCE;
const ANONYMOUS_CART_ID = 'new';

export const addFallbackToEntryIfNeeded = (entry) => {
  if (Array.isArray(entry.product.customImageData)) {
    return entry;
  }

  const newEntry = entry;
  newEntry.product.customImageData = [FALLBACK_PRODUCT_IMAGE];

  return newEntry;
};

const CartAPI = (function CartAPI() {
  const removeCartId = () => {
    storage.removeItem(CART_ID);
    storage.removeItem(DEFAULT_DELIVERY_APPLIED);
  };

  const setCartId = (_cartId) => {
    removeCartId();

    storage.setItem(CART_ID, _cartId);
  };

  const setLastOrderGuid = (guid) => {
    if (guid) {
      storage.setItem(LAST_ORDER_GUID, guid);
    }
  };

  const getCartId = () => storage.getItem(CART_ID);
  const getLastOrderGuid = () => storage.getItem(LAST_ORDER_GUID);

  /**
   * Removes the cart IDs from local storage
   *
   * Even though there are no async calls here, we're using a promise to stay consistent with
   * the other cart apis
   */
  const resetCart = () => {
    removeCartId();
    return Promise.resolve(true);
  };

  const mergeCart = () => {
    const cartIdOrNew = getCartId() || ANONYMOUS_CART_ID;

    return restClient.put(USER_RESOURCE, `/${getUserId()}/carts`, { anonymousCartGuid: cartIdOrNew }).then((res) => {
      const cartCode = res?.data?.cart?.code;
      setCartId(cartCode);
      return res;
    });
  };

  /**
   * Returns the cart with a given identifier
   * @returns CartDTO
   */
  const getCart = (refresh = false) => {
    const cartId = getCartId();

    if (!cartId) {
      // do not execute backend call since we do not have a cart id yet
      return Promise.resolve({ data: {} });
    }

    const throwError = (err) => {
      // delete cartId if it has expired
      if (err && err.message.indexOf('400') !== -1) {
        removeCartId();
        postLog(err);

        // @see https://atlassian.interdiscount.ch/jira/browse/SPQR-10244
        if (/checkout/.test(window.location.pathname)) {
          // redirect to cart page with no-entries notice when carts expires in checkout
          window.location = `/${getLocale()}/cart?noEntries=true`;
        } else {
          // reload current page to update ui when carts expires
          window.location.reload();
        }
      }

      return err;
    };

    if (refresh) {
      return restClient
        .put(USER_RESOURCE, `/${getUserId()}/carts/${cartId}/refresh`)
        .then((req) => {
          const newReq = req;
          const cartEntries = newReq?.data?.entries?.map((entry) => addFallbackToEntryIfNeeded(entry));
          newReq.data.entries = cartEntries;

          return newReq;
        })
        .catch((err) => {
          throwError(err);
        });
    }

    return restClient
      .get(USER_RESOURCE, `/${getUserId()}/carts/${cartId}`)
      .then((req) => {
        const newReq = req;
        const cartEntries = newReq?.data?.entries?.map((entry) => addFallbackToEntryIfNeeded(entry));
        newReq.data.entries = cartEntries;

        return newReq;
      })
      .catch((err) => {
        throwError(err);
      });
  };

  /**
   * Adds a product to the cart.
   * @param code
   * @param quantity
   * @param serviceItemCodes
   * @returns CartDTO
   */
  const addCartEntry = (code, quantity, serviceItemCodes = []) => {
    if (!code || !quantity) {
      return Promise.reject(INVALID_PARAMS);
    }

    const cartEntry = {
      product: {
        code,
      },
      quantity,
      serviceItemCodes,
    };

    const cartIdOrNew = getCartId() || ANONYMOUS_CART_ID;
    return restClient.post(USER_RESOURCE, `/${getUserId()}/carts/${cartIdOrNew}/entries`, cartEntry).then((req) => {
      const newReq = req;
      if (cartIdOrNew === ANONYMOUS_CART_ID) {
        // store cart id in localstorage when added the first item

        if (getUserId() === ANONYMOUS_USER_ID) {
          setCartId(newReq?.data?.cart?.guid);
        } else {
          setCartId(newReq?.data?.cartCode);
        }
      }

      const cartEntries = newReq?.data?.cart?.entries?.map((entry) => addFallbackToEntryIfNeeded(entry));
      newReq.data.cart.entries = cartEntries;

      return newReq;
    });
  };

  /**
   * Updates a cart entries.
   * @param entryID
   * @param code
   * @param quantity
   * @param serviceItemCodes
   * @returns CartDTO
   */
  const updateCartEntry = (entryID, code, quantity, serviceItemCodes = []) => {
    if (isNaN(entryID) || !code || isNaN(quantity)) {
      return Promise.reject(INVALID_PARAMS);
    }

    const cartEntry = {
      product: {
        code,
      },
      quantity,
      serviceItemCodes,
    };

    return restClient.put(USER_RESOURCE, `/${getUserId()}/carts/${getCartId()}/entries/${entryID}`, cartEntry);
  };

  /**
   * Deletes a cart entries.
   * @param entryID
   * @returns HTTP status code 200
   */
  const deleteCartEntry = (entryID = -1) => {
    if (entryID < 0) {
      return Promise.reject(INVALID_PARAMS);
    }

    return restClient.del(USER_RESOURCE, `/${getUserId()}/carts/${getCartId()}/entries/${entryID}`);
  };

  /**
   * Removes the address of a specific type in cart. Could be used in the guest and normal checkout.
   * @param type
   * @returns HTTP status code:
   *   200 -> OK
   *   201 -> Created
   *   401 -> Unauthorized
   *   403 -> Forbidden
   *   404 -> Not Found
   */
  const removeAddress = (type) => {
    if (!type) {
      return Promise.reject('Invalid Parameter type');
    }

    let addressType = type;
    if (type === addressTypes.INVOICE) addressType = 'payment';

    return restClient.del(USER_RESOURCE, `/${getUserId()}/carts/${getCartId()}/addresses/${addressType}`);
  };

  /**
   * Creates an address in the guest checkout and assigns it to the specific address type in the cart.
   * @param type
   * @param address
   * @param isSameAddressForInvoiceAndDelivery
   * @param reference
   * @returns addressData
   */
  const setGuestAddress = (type, address, isSameAddressForInvoiceAndDelivery, reference) => {
    if (!type || !Object.keys(address).length) {
      return Promise.reject(INVALID_PARAMS);
    }

    const addressData = addressMappings.mapAddressToAddressData(address);

    if (isSameAddressForInvoiceAndDelivery) {
      addressData.shippingAddress = true;
      addressData.billingAddress = true;
    }

    let addressType = type;
    if (type === addressTypes.INVOICE) addressType = 'payment';

    if (reference) {
      return restClient
        .put(USER_RESOURCE, `/${getUserId()}/carts/${getCartId()}/addresses/${addressType}/${reference}`, addressData)
        .then((response) => {
          if (response.status === 202) {
            return {
              id: reference,
              fields: address,
            };
          }
          return Promise.reject();
        });
    }

    return restClient
      .post(USER_RESOURCE, `/${getUserId()}/carts/${getCartId()}/addresses/${addressType}`, addressData)
      .then((response) => addressMappings.mapAddressDataToAddress(response.data));
  };

  /**
   * Sets an existing address on a specific address type in the cart.
   * @param addressId
   * @returns HTTP status code:
   *   200 -> OK
   *   201 -> Created
   *   401 -> Unauthorized
   *   403 -> Forbidden
   *   404 -> Not Found
   */
  const setAddress = (type, addressId) => {
    if (!type || !addressId) {
      return Promise.reject(INVALID_PARAMS);
    }

    let addressType = type;
    if (type === addressTypes.INVOICE) addressType = 'payment';

    return restClient.put(
      USER_RESOURCE,
      `/${getUserId()}/carts/${getCartId()}/addresses/${addressType}/${addressId}`,
      {}
    );
  };

  /**
   * Returns all delivery modes supported for the current cart
   * @retunrs DeliveryModeListDTO
   */
  const getDeliveryModes = () => restClient.get(USER_RESOURCE, `/${getUserId()}/carts/${getCartId()}/deliverymodes`);

  /**
   * Returns the delivery mode selected for the cart
   * @returns DeliveryModeDTO
   */
  const getCurrentDeliveryMode = () =>
    restClient.get(USER_RESOURCE, `/${getUserId()}/carts/${getCartId()}/deliverymode`);

  /**
   * Sets the delivery mode with a given identifier for the cart.
   * @param deliveryModeValue
   * @returns DeliveryModeDTO
   */
  const setDeliveryMode = (deliveryModeValue, pointOfServiceId) => {
    if (!deliveryModeValue) {
      return Promise.reject('Invalid Parameter deliveryModeValue');
    }

    const deliveryMode = {
      deliveryModeId: deliveryModeValue,
      pointOfServiceId,
    };

    return restClient.put(USER_RESOURCE, `/${getUserId()}/carts/${getCartId()}/deliverymode`, deliveryMode);
  };

  /**
   * Removes the delivery mode from the cart
   * @returns HTTP status code 200
   */
  const deleteCurrentDeliveryMode = () =>
    restClient.del(USER_RESOURCE, `/${getUserId()}/carts/${getCartId()}/deliverymode`);

  /**
   * Returns all payment modes supported for the current cart
   * @returns PaymentModeListDTO
   */
  const getPaymentModesAction = () =>
    restClient.get(USER_RESOURCE, `/${getUserId()}/carts/${getCartId()}/paymentmodes`);

  /**
   * Returns the payment mode selected for the cart
   * @returns PaymentModeDTO
   */
  const getCurrentPaymentMode = () => restClient.get(USER_RESOURCE, `/${getUserId()}/carts/${getCartId()}/paymentmode`);

  const getPaymentModes = () => {
    let paymentModes;
    let paymentMode;
    let alias;
    const getPaymentModesPromise = getPaymentModesAction().then((response) => {
      paymentModes = response.data.paymentModes || [];
      if (Object.keys(response.data.aliasCCSafeView || {}).length) {
        alias = {
          code: response.data.aliasCCSafeView.paymentModeCode,
          maskedCC: response.data.aliasCCSafeView.maskedCC,
        };
      }
    });
    const getCurrentPaymentModePromise = getCurrentPaymentMode().then((response) => {
      paymentMode = response.data.code ? response.data.code : '';
    });

    return Promise.all([getPaymentModesPromise, getCurrentPaymentModePromise]).then(() => ({
      paymentMode,
      paymentModes,
      alias,
    }));
  };

  /**
   * Sets the paymentmode with a given identifier for the cart.
   * @param paymentModeId
   * @param useAlias
   * @returns DeliveryModeDTO
   */
  const setPaymentMode = (paymentModeId, useAlias) => {
    if (!paymentModeId) {
      return Promise.reject('Invalid Parameter paymentModeValue');
    }

    const paymentMode = {
      paymentModeId,
    };

    paymentMode.usingPaymentAlias = useAlias;

    return restClient.put(USER_RESOURCE, `/${getUserId()}/carts/${getCartId()}/paymentmode`, paymentMode);
  };

  /**
   * Returns ordered payment modes list for footer
   * @returns FooterPaymentModeList
   */
  const getFooterPaymentModes = () => restClient.get(BASE_RESOURCE, `/paymentmodes/pagefooter`);

  /**
   * updates the email address of an guest customer
   * @param email
   */
  const changeGuest = (email) => {
    const isEmailValid = validation.email(email) === ValidationTypes.Valid;
    if (!email || !isEmailValid) {
      return Promise.reject('Invalid Parameter email');
    }

    const emailObject = {
      email: email.toLowerCase(),
    };

    return restClient
      .put(USER_RESOURCE, `/${getUserId()}/carts/${getCartId()}/guestemail`, emailObject)
      .then((response) => {
        if (response?.data?.errors?.[0]?.message === 'A Customer must be a GUEST to be modified setting the email') {
          return changeGuest(email);
        }
        return response;
      });
  };

  /**
   * Removes the delivery mode from the cart
   * @returns HTTP status code 200
   */
  const deleteCurrentPaymentMode = () =>
    restClient.del(USER_RESOURCE, `/${getUserId()}/carts/${getCartId()}/paymentmode`);

  /**
   * Updates payment details
   * @param paymentDetails
   * @returns HTTP status code 200
   */
  const updatePaymentDetails = (paymentDetails) => {
    if (!paymentDetails) {
      return Promise.reject('Invalid Parameter paymentDetails');
    }

    return restClient.post(USER_RESOURCE, `/${getUserId()}/carts/${getCartId()}/paymentdetails`, paymentDetails);
  };

  /**
   * Updates transport guarantee
   * @param transportGuaranteeData
   * @returns HTTP status code 200
   */
  const updateTransportGuarantee = (transportGuaranteeData) => {
    if (transportGuaranteeData === undefined) {
      return Promise.reject('Invalid Parameter transportGuaranteeData');
    }

    return restClient.post(USER_RESOURCE, `/${getUserId()}/carts/${getCartId()}/transportGuarantee/`, {
      active: transportGuaranteeData,
    });
  };

  const updatePackageAnnouncement = (isChecked) => {
    if (isChecked === undefined) {
      return Promise.reject('Invalid Parameter: isChecked');
    }

    return restClient.put(USER_RESOURCE, `/${getUserId()}/carts/${getCartId()}/packageAnnouncement`, {
      packageAnnouncement: isChecked,
    });
  };

  /**
   * Updates neutral delivery
   */
  const updateNeutralDelivery = (neutralDeliveryActive) =>
    restClient.post(USER_RESOURCE, `/${getUserId()}/carts/${getCartId()}/neutralDelivery/`, {
      active: neutralDeliveryActive,
    });

  /**
   * Updates refrence for order
   * @param reference
   * @returns HTTP status code 200
   */
  const updateOrderReference = (reference) => {
    if (reference === undefined) {
      return Promise.reject(INVALID_PARAM_REFERENCE);
    }

    return restClient.put(USER_RESOURCE, `/${getUserId()}/carts/${getCartId()}/orderreference`, reference);
  };

  /**
   * Applies a voucher based on the voucherId defined for the cart.
   * @param voucherCode
   * @returns HTTP status code 200 and CartDTO
   */
  const applyVoucher = (voucherCode) => {
    if (!voucherCode) {
      return Promise.reject(INVALID_PARAM_REFERENCE);
    }

    return restClient.post(
      USER_RESOURCE,
      `/${getUserId()}/carts/${getCartId()}/vouchers/${voucherCode.toUpperCase()}`,
      {}
    );
  };

  /**
   * Removes a voucher based on the voucherId defined for the current cart.
   * @param voucherCode
   * @returns HTTP status code 200 and CartDTO
   */
  const removeVoucher = (voucherCode) => {
    if (!voucherCode) {
      return Promise.reject(INVALID_PARAM_REFERENCE);
    }

    return restClient.del(USER_RESOURCE, `/${getUserId()}/carts/${getCartId()}/vouchers/${voucherCode}`, {});
  };

  const verifyAge = (pegiData) => {
    if (!Object.keys(pegiData).length) {
      return Promise.reject(INVALID_PARAM_REFERENCE);
    }

    return restClient.post(USER_RESOURCE, `/${getUserId()}/carts/${getCartId()}/verifycustomer`, pegiData);
  };

  /**
   * Applies a giftcard on a card
   * @param giftcardNumber, pin
   * @returns HTTP status code 200 and CartDTO
   */
  const applyGiftcard = (giftcardNumber, pin) => {
    if (!giftcardNumber || !pin) {
      return Promise.reject(INVALID_PARAM_REFERENCE);
    }

    const giftCard = {
      giftcardNumber,
      pin,
    };

    return restClient.post(USER_RESOURCE, `/${getUserId()}/carts/${getCartId()}/giftcards`, giftCard);
  };

  /**
   * Removes a giftcard on a cart
   * @param giftcardNumber
   * @returns HTTP status code 200 and CartDTO
   */
  const removeGiftcard = (giftcardNumber) => {
    if (!giftcardNumber) {
      return Promise.reject(INVALID_PARAM_REFERENCE);
    }

    return restClient.del(USER_RESOURCE, `/${getUserId()}/carts/${getCartId()}/giftcards/${giftcardNumber}`, {});
  };

  const removePaymentMode = () => restClient.del(USER_RESOURCE, `/${getUserId()}/alias`, {});

  /**
   * add a supercard on a cart
   * @param supercardCode
   * @returns HTTP status code 200
   */
  const addSupercard = (supercardCode) =>
    restClient.put(USER_RESOURCE, `/${getUserId()}/carts/${getCartId()}/supercard`, {
      supercardCode,
    });

  return {
    getCartId,
    setCartId,
    removeCartId,
    getLastOrderGuid,
    resetCart,
    mergeCart,
    getCart,
    addCartEntry,
    updateCartEntry,
    deleteCartEntry,
    removeAddress,
    setGuestAddress,
    setAddress,
    getDeliveryModes,
    getCurrentDeliveryMode,
    setDeliveryMode,
    deleteCurrentDeliveryMode,
    getPaymentModes,
    getCurrentPaymentMode,
    setPaymentMode,
    deleteCurrentPaymentMode,
    getFooterPaymentModes,
    changeGuest,
    updatePaymentDetails,
    updateTransportGuarantee,
    updatePackageAnnouncement,
    updateOrderReference,
    setLastOrderGuid,
    applyVoucher,
    removeVoucher,
    verifyAge,
    applyGiftcard,
    removeGiftcard,
    removePaymentMode,
    updateNeutralDelivery,
    addSupercard,
  };
})();

export const getCartId = CartAPI.getCartId;
export const setLastOrderGuid = CartAPI.setLastOrderGuid;
export default CartAPI;
