import { cloneElement, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { provideLink, provideLinks } from 'constants/navigation/navigation';
import { CMS_UID_BLOG, CMS_UID_PAYMENT } from 'constants/cms/cms';

import { getLocale } from 'constants/language/language';

export const mapStateToProps = (state) => ({
  pages: state?.cms?.navigation?.pages ?? null,
});

export const types = {
  SERVICE: 'service',
  COMPANY: 'company',
  BLOG: CMS_UID_BLOG,
  PAYMENT_SERVICES: CMS_UID_PAYMENT,
};

class FooterColumnCmsContainer extends Component {
  static displayName = 'containers/FooterColumnCmsContainer';

  static propTypes = {
    type: PropTypes.oneOf(Object.values(types)).isRequired,
    children: PropTypes.node.isRequired,
    pages: PropTypes.object,
  };

  static defaultProps = {
    pages: {},
  };

  static types = types;

  render() {
    const { children, pages, type, ...otherProps } = this.props;

    const locale = getLocale();
    let links = [];
    let globalLink = {};
    if (pages) {
      links = provideLinks(pages, type, locale);
      globalLink = provideLink(pages, type, locale);
    }

    return cloneElement(children, {
      links,
      globalLink,
      ...otherProps,
    });
  }
}

export default connect(mapStateToProps)(FooterColumnCmsContainer);
