import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@interdiscount/component-library-interdiscount';
import MarkdownCollapsible from 'components/atoms/MarkdownCollapsible/MarkdownCollapsible';
import Headline from 'components/atoms/Headline/Headline';
import CheckMarkText from 'components/atoms/CheckMarkText/CheckMarkText';
import ModalMieleLink from 'components/organisms/ModalMieleLink/ModalMieleLink';
import { ICON_KEYBOARD_ARROW_RIGHT } from 'components/atoms/Icon/Icon';
import ArrowText from 'components/atoms/ArrowText/ArrowText';
import ModalZoovuLink from 'components/organisms/ModalZoovuLink/ModalZoovuLink';
import useFeatureToggle from 'hooks/useFeatureToggle';
import styles from './MarkdownFactory.scss';

export const FACTORY_TYPE = {
  HEADLINE: 'HEADLINE',
  CHECKMARK: 'CHECKMARK',
  ANCHOR: 'ANCHOR',
  ARROW: 'ARROW',
  DETAILS: 'DETAILS',
  PARAGRAPH: 'PARAGRAPH',
};

const MarkdownFactory = (props) => {
  const { type, children, ...rest } = props;

  const showZoovuAssistants = useFeatureToggle('zoovuAssistant') === 'true' ?? false;

  switch (type) {
    case FACTORY_TYPE.ANCHOR: {
      // Check if the anchor text contains "{{" -> render ModaliFrameLink
      const text = children.length ? String(children[0]) : '';

      if (text.indexOf('{{') !== -1) {
        if (showZoovuAssistants) return <ModalZoovuLink data={text} assistantId={rest.href} />;
        else return <ModalMieleLink src={rest.href} data={text} />;
      }
      return <Link href={rest.href}>{children}</Link>;
    }
    case FACTORY_TYPE.ARROW:
      return <ArrowText name={ICON_KEYBOARD_ARROW_RIGHT}>{children}</ArrowText>;
    case FACTORY_TYPE.CHECKMARK:
      return <CheckMarkText {...rest}>{children}</CheckMarkText>;
    case FACTORY_TYPE.DETAILS:
      return <MarkdownCollapsible>{children}</MarkdownCollapsible>;
    case FACTORY_TYPE.HEADLINE:
      return <Headline {...rest}>{children}</Headline>;
    case FACTORY_TYPE.PARAGRAPH:
      // conversion needed otherwise we get invalid DOM nesting errors
      return <div className={styles.paragraph}>{children}</div>;
    default:
      return null;
  }
};

MarkdownFactory.displayName = 'atoms/MarkdownFactory';

MarkdownFactory.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node,
  href: PropTypes.string,
  tag: PropTypes.string,
  skin: PropTypes.string,
};

export default MarkdownFactory;
