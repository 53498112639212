import restClient from 'api/RestClient/RestClient';
import { resourceKeys } from 'constants/restResources/restResources';
import { AVAILABLE_RETURN_PAYMENT_TYPES, AVAILABLE_RETURN_REASONS } from 'constants/returns/returns';
import language from 'constants/language/language';

/**
 * Since the api mappings differs from Hybris to SAP we need to map the title codes
 * for the returns in reverse order
 */
const getTitleFromUserByTitleCode = (titleCode) => {
  switch (titleCode) {
    case 1:
      return language('form.fields.salutation.options.02');
    case 2:
      return language('form.fields.salutation.options.01');
    default:
      return language('form.fields.company.label');
  }
};

export const mapOrder = (orderData) => ({
  guid: orderData.orderId,
  code: orderData.orderCode,
  user: {
    id: orderData.customer.customerId,
    lastName: orderData.customer.lastName,
    firstName: orderData.customer.firstName,
    title: getTitleFromUserByTitleCode(orderData.customer.titleCode),
    address: orderData.customer.address,
    email: orderData.customer.email,
    phoneNumber: orderData.customer.phoneNumber,
  },
  payment: orderData.payment,
  merchants: orderData.merchantList.map((merchant) => ({
    id: merchant.merchantId,
    name: merchant.name,
    products: merchant.productList.map((product) => ({
      id: product.uniqueId,
      code: product.code,
      name: product.name,
      image: product.imageURL,
      price: product.price,
      positionNumber: product.positionNumber,
      notReturnable: product.notReturnable,
      openPackageNotAllowed: product.openPackageNotAllowed,
    })),
  })),
});

export const mapPostPayload = (data) => {
  const order = data.order;
  const paybackMethod =
    data.refund.type === AVAILABLE_RETURN_PAYMENT_TYPES.IBAN ? String(data.refund.iban) : data.refund.type;

  return {
    merchantId: data.selectedMerchantId,
    orderCode: order.code,
    paybackMethod,
    returnItems: data.selectedProducts.map((product) => {
      const returnReasons = product.returnReasons;
      const apiProduct = {
        name: product.name,
        code: product.code,
        positionNumber: product.positionNumber,
      };

      if (data.returnType === AVAILABLE_RETURN_REASONS.ARTICLE_RETURN_ONLINE) {
        return {
          ...apiProduct,
          type: 'RETURN_ITEM_UNQUALIFIED',
          packingCondition: returnReasons && returnReasons.packagingCondition,
          itemCondition: returnReasons && returnReasons.articleCondition,
          unqualifiedReturnItemReason: returnReasons && returnReasons.reason,
        };
      }

      return {
        ...apiProduct,
        type: 'RETURN_ITEM_WRONG_DEFECTIVE',
        wrongDefectiveReturnItemReason: returnReasons && returnReasons.reason,
        explanation: product.returnDescription,
      };
    }),
  };
};

/**
 * Fetches a list of returnable orders for the authenticated user
 */
const getReturnableOrders = () =>
  restClient.get(resourceKeys.RETURN_MICROSERVICE_RESOURCE, `/orders`).then((res) => {
    const orders = Array.isArray(res.data) ? res.data : [];
    return orders.map((order) => ({
      guid: order.orderId,
      code: order.orderCode,
    }));
  });

/**
 * Fetches a specific order by its GUID
 */
const getOrderById = (orderGuid) =>
  restClient.get(resourceKeys.RETURN_MICROSERVICE_RESOURCE, `/orders/${orderGuid}`).then(({ data }) => mapOrder(data));

/**
 * Fetches a specific order by users lastname and the order code.
 */
const searchOrder = ({ lastName, orderCode }) =>
  restClient
    .get(resourceKeys.RETURN_MICROSERVICE_RESOURCE, `/orders?lastName=${lastName}&orderCode=${orderCode}`)
    .then(({ data }) => {
      const orderData = Array.isArray(data) ? data[0] : data;

      if (!orderData || !orderData.orderId) {
        // order not found
        return Promise.reject();
      }

      return mapOrder(orderData);
    });

/**
 * Posts data to create a new return to backend and receives the pdf label id
 */
const createReturn = (data) => {
  const { order } = data;
  const payload = mapPostPayload(data);

  let additionalHeaders;
  if (!data.userLoggedIn) {
    additionalHeaders = {
      Authorization: order.user.lastName,
    };
  }

  return restClient
    .post(resourceKeys.RETURN_MICROSERVICE_RESOURCE, '/returns', payload, 'application/json', additionalHeaders)
    .then((res) => ({
      returnLabelId: res.data.returnDoc,
    }));
};

/**
 * Download the returns label pdf file
 */
const downloadLabel = (labelId, orderCode = '') =>
  restClient.getPdf(
    resourceKeys.RETURN_MICROSERVICE_RESOURCE,
    `/doc/${labelId}`,
    `${language('returns.returnConclusion.pdfName')}_${orderCode}.pdf`
  );

export default {
  getReturnableOrders,
  getOrderById,
  searchOrder,
  createReturn,
  downloadLabel,
};
