import React from 'react';
import PropTypes from 'prop-types';
import language from 'constants/language/language';
import { TOP_OF_PRODUCTS_OVERVIEW } from 'constants/scrollToElement/scrollToElement';
import IconLink from 'components/atoms/IconLink/IconLink';
import Icon, { ICON_KEYBOARD_ARROW_LEFT } from 'components/atoms/Icon/Icon';
import styles from './FacetCategoryBack.scss';

const FacetCategoryBack = ({ isCmsPage = false, query = '', removeCategoryFacet }) => (
  <div className={styles.container}>
    {isCmsPage ? (
      <IconLink onClick={removeCategoryFacet} scrollToId={TOP_OF_PRODUCTS_OVERVIEW}>
        <Icon path={ICON_KEYBOARD_ARROW_LEFT} className={styles.Icon} />
        <span className={styles.Button}>{language('common.return')}</span>
      </IconLink>
    ) : (
      <IconLink href={query} scrollToId={TOP_OF_PRODUCTS_OVERVIEW}>
        <Icon path={ICON_KEYBOARD_ARROW_LEFT} className={styles.Icon} />
        <span className={styles.Button}>{language('common.return')}</span>
      </IconLink>
    )}
  </div>
);

FacetCategoryBack.displayName = 'atoms/FacetCategoryBack';
FacetCategoryBack.propTypes = {
  code: PropTypes.string,
  isCmsPage: PropTypes.bool,
  query: PropTypes.string,
  removeCategoryFacet: PropTypes.func,
};

export default FacetCategoryBack;
