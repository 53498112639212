/**
 * Created by michaels on 13.07.16.
 */
import deliveryModes from 'constants/deliveryModes/deliveryModes';
import { parseDate, isDate16orMoreYearsAgo, isDate100orLessYearsAgo } from 'constants/formatDate/formatDate';
import isIbanValid from 'constants/validateIban/validateIban';

// enum
export const ValidationTypes = {
  Pending: 'Pending',

  Valid: true,

  Missing: 'Missing',
  MissingEmail: 'MissingEmail',
  TakenEmail: 'TakenEmail',
  MissingPaymentMethod: 'MissingPaymentMethod',
  MissingLogin: 'MissingLogin',

  Invalid: 'Invalid',
  InvalidEmail: 'InvalidEmail',
  InvalidNumber: 'InvalidNumber',
  InvalidPhoneNumber: 'InvalidPhoneNumber',
  InvalidPhoneNumberLengthMin: 'InvalidPhoneNumberLengthMin',
  InvalidPhoneNumberLengthMax: 'InvalidPhoneNumberLengthMax',
  InvalidPhoneNumberEmpty: 'InvalidPhoneNumberEmpty',
  InvalidPostalCode: 'InvalidPostalCode',
  InvalidNot16: 'InvalidNot16',

  SameId: 'SameId',
  SameAsUid: 'SameAsUid',
  SameAsEmail: 'SameAsEmail',

  NetworkError: 'NetworkError',

  PasswordToWeak: 'PasswordToWeak',
  PasswordGood: 'PasswordGood',
  PasswordVeryGood: 'PasswordVeryGood',

  Initial: 'Initial',
  GiftcardAlreadyUsed: 'GiftcardAlreadyUsed',
  PinMissing: 'PinMissing',
  GiftcardRecaptchaRequired: 'GiftcardEventCounterExceededError',

  PriceCannotBeLowered: 'PriceCannotBeLowered',
};

export const noSpecialCharsRegex = /[^',´`ˋ‘\-_.?/&()0-9a-zA-ZÀ-ÿ@+\s]/g;
export const noSpecialCharsRegexStrict = /[^',´`ˋ‘\-./&()0-9a-zA-ZÀ-ÿ+\s]/g;

export const validateNoSpecialChars = (value, allowAt = false) => {
  const regex = allowAt ? noSpecialCharsRegex : noSpecialCharsRegexStrict;

  if (value.match(regex)) {
    return ValidationTypes.Invalid;
  }

  return ValidationTypes.Valid;
};

export const required = (value) => {
  if (!value || value.trim() === '') return ValidationTypes.Missing;
  return ValidationTypes.Valid;
};

export const number = (value) => {
  // regex valid if empty string or only number characters
  const re = /^$|^[0-9]+$/;
  if (!re.test(value)) return ValidationTypes.Invalid;
  return ValidationTypes.Valid;
};

export const phoneNumber = (value = '') => {
  const trimmedValue = value.trim();

  const isEmpty = trimmedValue.length === 0;
  if (isEmpty) {
    return ValidationTypes.InvalidPhoneNumberEmpty;
  }

  const tooLong = trimmedValue.length > 30;
  if (tooLong) {
    return ValidationTypes.InvalidPhoneNumberLengthMax;
  }

  // regex valid if min. 10 characters and only 0-9 -./+ and whitespace characters but not only whitespace characters
  const onlyWhiteSpaceRegex = /^\s+$/;
  if (onlyWhiteSpaceRegex.test(trimmedValue)) {
    return ValidationTypes.InvalidPhoneNumber;
  }

  // replace special chars and white spaces for minimum length validation
  const minLengthRegex = /.{10,}/;
  if (!minLengthRegex.test(trimmedValue.replace(/([\s-+/.])/g, ''))) {
    return ValidationTypes.InvalidPhoneNumberLengthMin;
  }

  const re = /^$|^([0-9-/+.\s])+$/;
  if (!re.test(trimmedValue)) {
    return ValidationTypes.InvalidPhoneNumber;
  }
  return ValidationTypes.Valid;
};

export const postalCode = (value) => {
  // regex valid if not start with 0 and only 4 number characters
  const re = /^[1-9]{1}[0-9]{3}$/;
  if (!re.test(value)) return ValidationTypes.Invalid;
  return ValidationTypes.Valid;
};

export const validateRequiredTextInput = (value) => {
  const isValid = validateNoSpecialChars(value);
  if (isValid !== ValidationTypes.Valid) {
    return isValid;
  }
  return required(value) === ValidationTypes.Valid ? ValidationTypes.Valid : ValidationTypes.Missing;
};

export const email = (value) => {
  if (required(value) !== ValidationTypes.Valid) {
    return ValidationTypes.Missing;
  }
  // regex valid if empty string or email address, case insensitive
  const re = /^$|^(?!.*?[._-]{2})[a-zß0-9.%+_-]+[^_\W]+@(?!.*?[.-]{2})[a-zß0-9.-]+\.[a-z]{2,10}$/i;
  if (!re.test(value)) return ValidationTypes.Invalid;
  return ValidationTypes.Valid;
};

export const emailRequired = (value) => {
  const isValidEmail = email(value);
  if (isValidEmail !== ValidationTypes.Valid) {
    return isValidEmail;
  }
  return required(value) === ValidationTypes.Valid ? ValidationTypes.Valid : ValidationTypes.Missing;
};

// Minimum 8 characters and two different types
const passwordRegex = /^(?=.*[A-Za-z])((?=.*?[^\w\s])|(?=.*\d)).{8,}$/;

export const passwordComplexity = (value) => {
  const length = value.length;

  const hasUpperCase = /[A-Z]/.test(value);
  const hasLowerCase = /[a-z]/.test(value);
  const hasNumbers = /\d/.test(value);
  const hasNonalphas = /\W/.test(value);

  const numRequirements = hasUpperCase + hasLowerCase + hasNumbers + hasNonalphas;

  let passwordStrength = 2 * length * numRequirements;

  // the password strength couldn't be over 25 if the password does not fulfil the requirements
  if (!passwordRegex.test(value)) {
    passwordStrength = passwordStrength > 25 ? 25 : passwordStrength;
  }

  // the max value for the progress bar couldn't be over 100%
  if (passwordStrength > 100) {
    passwordStrength = 100;
  }

  return passwordStrength;
};

export const password = (passwordValue = '', emailValue) => {
  const passwordStrength = passwordComplexity(passwordValue);

  if (passwordValue.length === 0) return ValidationTypes.Missing;
  if (passwordValue === emailValue) return ValidationTypes.SameAsEmail;
  if (!passwordRegex.test(passwordValue)) return ValidationTypes.PasswordToWeak;
  if (passwordStrength < 80) return ValidationTypes.PasswordGood;

  return ValidationTypes.PasswordVeryGood;
};

export const deliveryMode = (selectedDeliveryMode, pointOfServiceId) => {
  if (selectedDeliveryMode === deliveryModes.pickup) {
    if (!pointOfServiceId) {
      return ValidationTypes.Invalid;
    }
  }
  return ValidationTypes.Valid;
};

/**
 * The date-validator
 */
export const date = (dateString, referenceDate = new Date()) => {
  if (!dateString) {
    return ValidationTypes.Missing;
  }

  const value = dateString.indexOf('T') !== -1 ? dateString.split('T').shift() : dateString;

  const dateRegex = /([0-9]{4})-([0-9]{1,2})-([0-9]{1,2})/g;
  if (!value.match(dateRegex)) {
    return ValidationTypes.Invalid;
  }

  const parsedDate = parseDate(value, { format: 'yyyy-MM-dd' });
  if (!parsedDate) {
    return ValidationTypes.Invalid;
  }

  if (!isDate100orLessYearsAgo(parsedDate, referenceDate)) {
    return ValidationTypes.Invalid;
  }

  return ValidationTypes.Valid;
};

/**
 * The birthday-validator
 */
export const birthday = (dateString, referenceDate = new Date()) => {
  const getDateValidation = date(dateString, referenceDate);
  if (getDateValidation !== ValidationTypes.Valid) {
    return getDateValidation;
  }

  const value = dateString.indexOf('T') !== -1 ? dateString.split('T').shift() : dateString;
  const birthdayDate = parseDate(value, { format: 'yyyy-MM-dd' });
  if (!isDate16orMoreYearsAgo(birthdayDate, referenceDate)) {
    return ValidationTypes.InvalidNot16;
  }

  return ValidationTypes.Valid;
};

export const supercardCode = (code) => {
  if (code === '' || code === null || typeof code === 'undefined') {
    return ValidationTypes.Valid;
  }
  if (code.length !== 13) {
    return ValidationTypes.Invalid;
  }
  const codeWithoutDigit = code.slice(0, -1);
  const digit = +code.slice(code.length - 1);
  let result = 0;
  codeWithoutDigit.split('').forEach((item, index) => {
    result += item * Math.pow(3, index % 2);
  });

  if (digit === (10 - (result % 10)) % 10) {
    return ValidationTypes.Valid;
  }
  return ValidationTypes.Invalid;
};

export const employeeEan = (code) => {
  if (code === '' || code === null || typeof code === 'undefined') {
    return ValidationTypes.Valid;
  }

  const regex = /(([Cc][Pp][Pp])|([Cc][Pp][Dd]))[0-9]{10,14}/;

  if (!code.match(regex)) {
    return ValidationTypes.Invalid;
  }

  return ValidationTypes.Valid;
};

export const receiptNumber = (value) => {
  if (value === '' || value === null || typeof value === 'undefined') {
    return ValidationTypes.Valid;
  }
  const regex = /[0-9]{28}/;

  if (!value.match(regex)) {
    return ValidationTypes.Invalid;
  }

  return ValidationTypes.Valid;
};

/**
 * validates a set of input fields and returns the first error field. If no error exits the function returns undefined
 */
export const validateInputFields = (fields) =>
  Object.values(fields).find((field) => field.validationResult !== ValidationTypes.Valid);

/**
 * validates multiple password fields. If no error exits the function returns undefined
 */
export const validatePasswordFields = (fields) =>
  Object.values(fields).find(
    (field) =>
      ![ValidationTypes.PasswordGood, ValidationTypes.PasswordVeryGood, ValidationTypes.Valid].includes(
        field.validationResult
      )
  );

/**
 * validates address form fields for senseless characters
 */
export const validateAddressFields = (value, isRequired = false, allowAt = false) => {
  if (!value) {
    return isRequired ? ValidationTypes.Missing : ValidationTypes.Valid;
  }

  const regex = allowAt ? noSpecialCharsRegex : noSpecialCharsRegexStrict;

  if (value.match(regex)) {
    return ValidationTypes.Invalid;
  }

  return ValidationTypes.Valid;
};

export const validateAddressFieldsWithAt = (value, isRequired = false) =>
  validateAddressFields(value, isRequired, true);

export const iban = (value) => isIbanValid(value);

export const isValidIBANCharacter = (e) => {
  if (!/^([a-zA-Z0-9\s])+$/.test(e.key)) {
    e.preventDefault();
  }
};

export const hasMinWords = (value = '', minWords = 2) => value.trim().split(' ').length >= minWords;

export const isBelowMaxLength = (value = '', maxNumOfChars = 200) => value.trim().length <= maxNumOfChars;

export default {
  required,
  number,
  phoneNumber,
  postalCode,
  email,
  emailRequired,
  password,
  ValidationTypes,
  passwordComplexity,
  deliveryMode,
  supercardCode,
  employeeEan,
  birthday,
  date,
  validateInputFields,
  validatePasswordFields,
  receiptNumber,
  validateAddressFields,
  noSpecialCharsRegex,
  validateAddressFieldsWithAt,
  iban,
  isValidIBANCharacter,
  hasMinWords,
  isBelowMaxLength,
};
