import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SvgContainer from 'components/atoms/SvgContainer/SvgContainer';
import styles from './Spinner.scss';
import cx from 'classnames';

export const SPINNER_STATES = {
  REQUEST: 'REQUEST',
  FAILURE: 'FAILURE',
  SUCCESS: 'SUCCESS',
};

const getStyleClass = (baseStyleClass, status, isCentered) => {
  switch (status) {
    case SPINNER_STATES.REQUEST:
      return cx(baseStyleClass, { [styles.centeredContainer]: isCentered });
    case SPINNER_STATES.FAILURE:
      return cx(baseStyleClass, { [styles.centeredContainer]: isCentered }, styles.error);
    case SPINNER_STATES.SUCCESS:
      return cx(baseStyleClass, { [styles.centeredContainer]: isCentered }, styles.finished);
    default:
      return cx(baseStyleClass, { [styles.centeredContainer]: isCentered }, styles.error);
  }
};

export const getStatusClass = (status) => getStyleClass(styles.spinner, status);
export const getSpinnerContainerClass = (status, isCentered) =>
  getStyleClass(styles.spinnerContainer, status, isCentered);

export default class Spinner extends Component {
  static displayName = 'atoms/Spinner';

  static propTypes = {
    status: PropTypes.oneOf(Object.keys(SPINNER_STATES)),
    height: PropTypes.number,
    width: PropTypes.number,
    isCentered: PropTypes.bool,
  };

  static defaultProps = {
    status: SPINNER_STATES.REQUEST,
    height: 66,
    width: 66,
    isCentered: false,
  };

  render() {
    const spinnerClass = getStatusClass(this.props.status);
    const spinnerContainerClass = getSpinnerContainerClass(this.props.status, this.props.isCentered);
    const { height, width } = this.props;
    return (
      <div className={spinnerContainerClass}>
        <SvgContainer
          height={height}
          width={width}
          className={spinnerClass}
          vbWidth={66}
          vbHeight={66}
          vbXCoords={0}
          vbYCoords={0}
        >
          <g className={styles.spinnerGroup}>
            <circle className={styles.path} strokeLinecap="round" cx="33" cy="33" r="30" />
          </g>
          <g transform="scale(0.4)">
            <svg x="50px" y="50px" viewBox="0 0 400 400" className={styles.errorIcon}>
              <line x1="400" y1="0" x2="0" y2="400" />
              <line x1="0.2" y1="0" x2="400" y2="400" />
            </svg>
          </g>
          <g className={styles.polylineGroup} transform="translate(10)">
            <polyline points="10 32 20 40 35 20" strokeDasharray="150" strokeLinecap="butt" strokeLinejoin="miter" />
          </g>
        </SvgContainer>
      </div>
    );
  }
}
