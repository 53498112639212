import React from 'react';
import PropTypes from 'prop-types';
import { TEASER_TYPE } from 'constants/wall/wall';
import CardWallAll from 'components/molecules/CardWallAll/CardWallAll';
import CardWallHome from 'components/molecules/CardWallHome/CardWallHome';
import CardWallMini from 'components/molecules/CardWallMini/CardWallMini';
import Card from 'components/molecules/Card/Card';

const SKINS = Card.SKINS;

const CardWall = ({ bricks, skin, callToAction }) => {
  // Filter for card bricks only because BE could send not expected brick types such as COLOR, IMAGE, etc.
  const cards = bricks.filter((brick) => brick?.type === TEASER_TYPE.CARD);
  switch (skin) {
    case SKINS.ALL:
      return <CardWallAll cards={cards} skin={skin} />;
    case SKINS.MINI:
      return <CardWallMini cards={cards} skin={skin} />;
    case SKINS.HOME:
      return <CardWallHome cards={cards} skin={skin} callToAction={callToAction} />;
    default: {
      return null;
    }
  }
};

CardWall.SKINS = SKINS;

CardWall.displayName = 'organisms/CardWall';
CardWall.propTypes = {
  bricks: PropTypes.array,
  skin: PropTypes.oneOf(Object.values(SKINS)),
  callToAction: PropTypes.object,
};
CardWall.defaultProps = {
  bricks: [],
  skin: SKINS.ALL,
  callToAction: {},
};

export default CardWall;
