import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import language, { getLocale } from 'constants/language/language';
import {
  accountOrders,
  accountAddresses,
  accountUserdata,
  accountRatings,
  accountNotifications,
  accountReturns,
  accountRegistration,
} from 'constants/routePaths/routePathCombinations';

import { ACCOUNT } from 'constants/routePaths/routePaths';
import {
  ICON_ACCOUNT_CIRCLE,
  ICON_FORMAT_LIST_BULLETED,
  ICON_LOGIN,
  ICON_LOGOUT,
  ICON_PERSON_ADD_OUTLINE,
} from 'components/atoms/Icon/Icon';

const getSubNavigationItems = () => ({
  orders: {
    name: language('myAccountOrders.header'),
    href: mapPathToLocalizedUrl(getLocale(), accountOrders),
    mobile: true,
  },
  ratings: {
    name: language('myAccountRatings.header'),
    href: mapPathToLocalizedUrl(getLocale(), accountRatings),
    mobile: true,
  },
  userData: {
    name: language('myAccountUserData.header'),
    href: mapPathToLocalizedUrl(getLocale(), accountUserdata),
    mobile: true,
  },
  addresses: {
    name: language('myAccountAddresses.header'),
    href: mapPathToLocalizedUrl(getLocale(), accountAddresses),
    mobile: true,
  },
  notifications: {
    name: language('myAccountNotifications.header'),
    href: mapPathToLocalizedUrl(getLocale(), accountNotifications),
    mobile: true,
  },
  returns: {
    name: language('returnsOverview.header'),
    href: mapPathToLocalizedUrl(getLocale(), accountReturns),
    mobile: true,
  },
  logoutUser: {
    name: language('myAccount.logout'),
    mobile: true,
    icon: ICON_LOGOUT,
  },
});

export const cdcAccountPopover = [
  {
    link: [ACCOUNT],
    icon: ICON_ACCOUNT_CIRCLE,
    text: 'header.myAccount',
  },
  {
    link: accountOrders,
    icon: ICON_FORMAT_LIST_BULLETED,
    text: 'navigation.orders',
  },
];

export const cdcLoginPopover = [
  {
    link: [ACCOUNT],
    icon: ICON_LOGIN,
    text: 'login.headlineShort',
  },
  {
    link: accountRegistration,
    icon: ICON_PERSON_ADD_OUTLINE,
    text: 'navigation.register',
  },
];

export default getSubNavigationItems;
