import React, { Component } from 'react';
import PropTypes from 'prop-types';
import StoreFinderListItem from 'components/molecules/StoreFinderListItem/StoreFinderListItem';
import viewTypes from 'constants/storeFinderResultsViewTypes/storeFinderResultsViewTypes';

import GoogleAPI from 'api/GoogleAPI/GoogleAPI';
import styles from './StoreFinderMap.scss';

import getMapMarkerIcon from './getMapMarkerIcon';

const MIN_VISIBLE_MARKERS = 1;
const DEFAULT_MAP_SETTINGS = {
  center: { lat: 46.8207646, lng: 8.2403084 }, // DEFAULT TO ALL OF SWISS
  zoom: 7,
  zoomControl: true,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: false,
};
const TOO_CLOSE = 0.00001;
const MIN_OFFSET = 0.00008;

export default class StoreFinderMap extends Component {
  static displayName = 'organisms/StoreFinderMap';

  static propTypes = {
    stores: PropTypes.array.isRequired,
    locality: PropTypes.string,
    geoLocation: PropTypes.object,
    selectPickupStore: PropTypes.func,
    selectPickupStoreEnabled: PropTypes.bool,
    switchedStorePosition: PropTypes.number,
  };

  static defaultProps = {
    locality: '',
    geoLocation: {},
    selectPickupStore: () => {},
    selectPickupStoreEnabled: false,
    switchedStorePosition: null,
  };

  constructor(props) {
    super(props);

    this.googleMaps = null;
    this.map = null;
    this.markers = [];

    // when user switched from list to map view
    this.switchedStorePosition = props?.switchedStorePosition || null;

    this.mapRef = React.createRef();

    this.state = {
      selectedStore: null,
    };
  }

  componentDidMount() {
    const node = this.mapRef.current;

    GoogleAPI.getMaps()
      .then((googleMaps) => {
        this.googleMaps = googleMaps;
        this.map = new googleMaps.Map(node, { ...DEFAULT_MAP_SETTINGS });

        this.updateMap();
      })
      .catch((error) => {
        console.error(error); // eslint-disable-line
      });
  }

  componentDidUpdate(prevProps) {
    if (prevProps && this.props.stores !== prevProps.stores) {
      this.updateMap();
    }
  }

  getGeoPointCoordinates = (store) => {
    const geoPointLat = store?.geoPoint?.latitude || 0;
    const geoPointLng = store?.geoPoint?.longitude || 0;
    return { lat: geoPointLat, lng: geoPointLng };
  };

  updateMap() {
    if (this.googleMaps && this.map) {
      this.clearMap();
      if (this.switchedStorePosition !== null) {
        this.centerMapToSelectedStore();
      } else {
        this.centerMapToSearch();
      }

      if (this.props.stores) {
        const stores = this.props.stores;

        const bounds = new this.googleMaps.LatLngBounds();
        // add search location to be visible
        if (this.props.geoLocation.lat && this.props.geoLocation.lng) {
          bounds.extend(this.props.geoLocation);
        }

        // create a marker for all given Markers
        for (let i = 0; i < stores.length; i++) {
          const store = stores[i];

          // add nearest shops to be visible on the map
          if (i < MIN_VISIBLE_MARKERS) {
            const geoPoints = this.getGeoPointCoordinates(store);
            const location = { lat: geoPoints.lat, lng: geoPoints.lng };
            // add only valid locations
            if (!(location.lat === 0 && location.lng === 0)) {
              bounds.extend(location);
            }
          }

          this.addStoreAsMarker(store);
        }

        if (this.switchedStorePosition === null) {
          // fit map to show nearest shops and search location
          this.map.setCenter(bounds.getCenter());
          this.map.fitBounds(bounds);
          // select nearest shop as default
          this.selectStore(this.markers[0], stores[0]);
        } else {
          // find selected store in array
          const newStore = stores[this.switchedStorePosition];
          const geoPosition = {
            lat: newStore?.geoPoint?.latitude || 46.8207646,
            lng: newStore?.geoPoint?.longitude || 8.2403084,
          };
          // fit map to show selected store
          this.map.setCenter(geoPosition);

          // select marker of selected store
          this.selectStore(this.markers[this.switchedStorePosition], stores[this.switchedStorePosition]);

          // set zoom
          this.map.setZoom(14);
        }
      }

      // cap zoom to an adequate level (about 6km top to bottom)
      this.map.setZoom(Math.min(14, this.map.getZoom()));

      this.switchedStorePosition = null;
    }
  }

  centerMapToSearch() {
    if (this.props.geoLocation.lat && this.props.geoLocation.lng) {
      this.map.setCenter(this.props.geoLocation);
    } else if (this.props.locality) {
      GoogleAPI.mapsGetGeoLocation(this.googleMaps, this.props.locality)
        .then((response) => {
          if (response) {
            this.map.setCenter({ lat: response.latitude, lng: response.longitude });
          }
        })
        .catch((error) => {
          console.error(error); // eslint-disable-line
        });
    }
  }

  centerMapToSelectedStore() {
    if (this.switchedStorePosition !== null) {
      const newStore = this.props.stores?.[this.switchedStorePosition];
      const location = {
        lat: newStore?.geoPoint?.latitude || 46.8207646,
        lng: newStore?.geoPoint?.longitude || 8.2403084,
      };
      // center to selected store
      this.map.setCenter(location);
    }
  }

  addStoreAsMarker(store) {
    // create LatLng Location
    const geoPoints = this.getGeoPointCoordinates(store);
    let position = new this.googleMaps.LatLng(geoPoints.lat, geoPoints.lng);
    // add an offset when 2 Markers contain the same position
    for (let j = 0; j < this.markers.length; j++) {
      const pos = this.markers[j].getPosition();
      const diffLat = Math.abs(position.lat() - pos.lat());
      const diffLng = Math.abs(position.lng() - pos.lng());

      if (diffLat < TOO_CLOSE && diffLng < TOO_CLOSE) {
        position = new this.googleMaps.LatLng(pos.lat(), pos.lng() - MIN_OFFSET);
      }
    }

    // create selectable map markers
    const marker = new this.googleMaps.Marker({
      map: this.map,
      position,
      icon: getMapMarkerIcon(store.format, false),
    });

    marker.addListener('click', () => {
      this.selectStore(marker, store);
    });

    this.markers.push(marker);
  }

  clearMap() {
    if (this.map) {
      for (let i = 0; i < this.markers.length; i++) {
        this.markers[i].setMap(null);
      }
      this.markers.length = 0;

      this.selectStore(null);
    }
  }

  selectStore(marker, store) {
    if (marker && store) {
      // RESET PREVIOUS
      if (this.state.selectedMarker && this.state.selectedStore) {
        this.state.selectedMarker.setIcon(getMapMarkerIcon(this.state.selectedStore.format, false));
      }
      // SET NEW
      marker.setIcon(getMapMarkerIcon(store.format, true));
    }
    this.setState({
      selectedStore: store,
      selectedMarker: marker,
    });
  }

  render() {
    const { selectPickupStore, selectPickupStoreEnabled } = this.props;

    return (
      <React.Fragment>
        <div className={styles.map} ref={this.mapRef} />
        {this.state.selectedStore && (
          <StoreFinderListItem
            store={this.state.selectedStore}
            selectPickupStore={selectPickupStore}
            selectPickupStoreEnabled={selectPickupStoreEnabled}
            detailMode
            borderless
            activeView={viewTypes.MAP}
          />
        )}
      </React.Fragment>
    );
  }
}
