import React from 'react';
import PropTypes from 'prop-types';
import truncate from 'constants/helper/truncate';

import Headline from 'components/atoms/Headline/Headline';

export const CONTEXTS = {
  DEFAULT: 'DEFAULT',
  TEASER: 'TEASER',
};

const ShortenedHeadline = ({ text, maxLength, margin }) => (
  <Headline tag={Headline.tags.H4} skin={Headline.skins.productname} title={text} margin={margin}>
    {truncate(text, maxLength)}
  </Headline>
);

ShortenedHeadline.displayName = 'atoms/ShortenedHeadline';

ShortenedHeadline.propTypes = {
  text: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  margin: PropTypes.oneOf(Object.values(Headline.margins)),
};

ShortenedHeadline.defaultProps = {
  maxLength: 40,
  margin: Headline.margins.NORMAL,
};

export default ShortenedHeadline;
