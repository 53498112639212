import React, { useState } from 'react';
import cx from 'classnames';
import { recoTrackingEndpoints as recoTypes } from 'api/RecoAPI/RecoAPI';
import language from 'constants/language/language';
import { HOME_PAGE } from 'constants/cms/cms';
import { TOP_OF_PRODUCTS_OVERVIEW } from 'constants/scrollToElement/scrollToElement';
import Meta from 'containers/Meta/Meta';
import ProductRecoContainer from 'containers/ProductRecommendations/ProductRecommendations';
import WallContainer from 'containers/Wall/Wall';
import MainMenu from 'containers/MainMenu/MainMenu';
import CmsContentSuccess from 'containers/CmsContentSuccess/CmsContentSuccess';
import MyStoreContainer from 'containers/MyStoreContainer/MyStoreContainer';
import ProductRecoSlider from 'components/organisms/ProductRecoSlider/ProductRecoSlider';
import CardWall from 'components/organisms/CardWall/CardWall';
import CmsPagesAfter from 'components/organisms/CmsPagesAfter/CmsPagesAfter';
import CmsPagesBefore from 'components/organisms/CmsPagesBefore/CmsPagesBefore';
import SeoStructuredData, { seoStructuredDataTypes } from 'components/organisms/SeoStructuredData/SeoStructuredData';
import StoreFinderBox from 'components/molecules/StoreFinderBox/StoreFinderBox';
import ProductNavigation from 'components/molecules/ProductNavigation/ProductNavigation';
import ProductComparisonHeader from 'components/molecules/ProductComparisonHeader/ProductComparisonHeader';
import ProductComparisonContainer from 'containers/ProductComparison/ProductComparison';
import { Col, Row } from 'components/atoms/Grid/Grid';
import bootstrap from 'scss/component.scss';
import styles from './HomePage.scss';
import PropTypes from 'prop-types';
import { getEnv } from 'config/config';

const HomePage = ({ comparisonHasError = false }) => {
  const [hasRecoProducts, setHasRecoProducts] = useState(true);

  const setCanonicalUrl = () => {
    if (!__CLIENT__) return null;

    const url = new URL(window.location.href);
    let { pathname } = url;

    return [{ rel: 'canonical', href: `${getEnv('web')}${pathname}` }];
  };

  return (
    <>
      <SeoStructuredData type={seoStructuredDataTypes.SEARCHACTION} />
      {__CLIENT__ && comparisonHasError && (
        <ProductComparisonContainer>
          <ProductComparisonHeader />
        </ProductComparisonContainer>
      )}
      <Row id={TOP_OF_PRODUCTS_OVERVIEW} className={styles.container}>
        <Meta
          title={language('pageTitles.homePage')}
          titleFirst={false}
          contentId={HOME_PAGE}
          meta={[
            {
              name: 'description',
              content: language('meta.homePage'),
            },
          ]}
          canonical={setCanonicalUrl()}
        />
        <Col lg={3} className={cx(bootstrap.hiddenMdDown, styles.naviWrapper)}>
          <div className={cx(styles.naviContainer, bootstrap.hiddenMdDown)}>
            <MainMenu>
              <ProductNavigation isOnHomePage />
            </MainMenu>
          </div>
          <div className={cx(styles.storeFinderBox)}>
            <MyStoreContainer>
              <StoreFinderBox />
            </MyStoreContainer>
          </div>
        </Col>
        <Col lg={9}>
          <div className={styles.wallContainer}>
            <WallContainer>
              <CardWall skin={CardWall.SKINS.HOME} />
            </WallContainer>
          </div>
        </Col>
      </Row>
      {hasRecoProducts && (
        <Row>
          <Col className={styles.recoSlider}>
            <ProductRecoContainer type={recoTypes.HOME} setHasRecoProducts={setHasRecoProducts}>
              <ProductRecoSlider />
            </ProductRecoContainer>
          </Col>
        </Row>
      )}
      <CmsContentSuccess>
        <CmsPagesBefore contentId={HOME_PAGE} />
        <CmsPagesAfter contentId={HOME_PAGE} />
      </CmsContentSuccess>
    </>
  );
};

HomePage.displayName = 'templates/Homepage';

HomePage.propTypes = {
  comparisonHasError: PropTypes.bool,
};

export default HomePage;
