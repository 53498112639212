import React, { useState, useEffect, useCallback, useRef } from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import PropTypes from 'prop-types';

import FieldSetInput from 'components/molecules/FieldSetInput/FieldSetInput';
import InputRadioList from 'components/molecules/InputRadioList/InputRadioList';
import { Row, Col } from 'components/atoms/Grid/Grid';
import Icon, { ICON_CHECK_CIRCLE_OUTLINE } from 'components/atoms/Icon/Icon';
import Headline from 'components/atoms/Headline/Headline';
import Markdown from 'components/atoms/Markdown/Markdown';
import InputText from 'components/atoms/InputText/InputText';
import MandatoryFieldInfo from 'components/atoms/MandatoryFieldInfo/MandatoryFieldInfo';
import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';

import language from 'constants/language/language';
import { ValidationTypes } from 'constants/validation/validation';
import matchMedia from 'constants/matchMedia/matchMedia';
import { BREAKPOINTS } from 'constants/breakpoints/breakpoints';
import formatDate from 'constants/formatDate/formatDate';
import {
  validateSalutation,
  validateFirstName,
  validateLastName,
  validateEmail,
  validateBirthday,
} from './NewsletterRegistrationForm.helpers.js';

import styles from './NewsletterRegistrationForm.scss';

dayjs.extend(customParseFormat); // Workaround for Firefox and Safari only supporting standard date formats.
const DATE_FORMAT = 'DD.MM.YYYY';

// Helper Effect to prevent initial check
function useDidUpdateEffect(fn, inputs) {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) fn();
    else didMountRef.current = true;
  }, inputs);
}

const NewsletterRegistrationForm = ({ handleSubmit, userFields = undefined, isLoggedIn = false }) => {
  const [salutation, setSalutation] = useState('');
  const [salutationValidation, setSalutationValidation] = useState(ValidationTypes.Initial);
  const [firstName, setFirstName] = useState('');
  const [firstNameValidation, setFirstNameValidation] = useState(ValidationTypes.Initial);
  const [lastName, setLastName] = useState('');
  const [lastNameValidation, setLastNameValidation] = useState(ValidationTypes.Initial);
  const [email, setEmail] = useState('');
  const [emailValidation, setEmailValidation] = useState(ValidationTypes.Initial);
  const [birthday, setBirthday] = useState('');
  const [birthdayValidation, setBirthdayValidation] = useState(ValidationTypes.Initial);
  const isMobile = !matchMedia(BREAKPOINTS.MD);
  const [forceValidation, setForceValidation] = useState(false);

  const isFormValid = useCallback(() => {
    return (
      salutationValidation === ValidationTypes.Valid &&
      firstNameValidation === ValidationTypes.Valid &&
      lastNameValidation === ValidationTypes.Valid &&
      emailValidation === ValidationTypes.Valid &&
      birthdayValidation === ValidationTypes.Valid
    );
  }, [salutationValidation, firstNameValidation, lastNameValidation, emailValidation, birthdayValidation]);

  useEffect(() => {
    if (isLoggedIn && userFields) {
      setSalutation(userFields.titleCode.value);
      setFirstName(userFields.firstName.value);
      setLastName(userFields.lastName.value);
      setEmail(userFields.email.value);
      setBirthday(formatDate(userFields.birthday.value));
    }
  }, [isLoggedIn, userFields]);

  useDidUpdateEffect(() => {
    setSalutationValidation(validateSalutation(salutation));
  }, [salutation, forceValidation]);

  useDidUpdateEffect(() => {
    setFirstNameValidation(validateFirstName(firstName));
  }, [firstName, forceValidation]);

  useDidUpdateEffect(() => {
    setLastNameValidation(validateLastName(lastName));
  }, [lastName, forceValidation]);

  useDidUpdateEffect(() => {
    setEmailValidation(validateEmail(email));
  }, [email, forceValidation]);

  useDidUpdateEffect(() => {
    if (dayjs(birthday?.toString(), DATE_FORMAT, true).isValid()) {
      const dateString = dayjs(birthday?.toString(), DATE_FORMAT).format('YYYY-MM-DD');
      setBirthdayValidation(validateBirthday(dateString));
    } else {
      setBirthdayValidation(ValidationTypes.Invalid);
    }
  }, [birthday, forceValidation]);

  const getDataProtectionText = () => {
    // set innerHTML to visualize <b> Tags
    return { __html: language('newsletterFeedback.registration.dataProtectionText') };
  };

  const getAdvantages = () => {
    const advantages = language('newsletterFeedback.registration.advantages');

    if (advantages && advantages.length) {
      return advantages;
    }

    return [];
  };

  const onSubmit = (event) => {
    event.preventDefault();

    const valid = isFormValid();

    if (valid) {
      handleSubmit(email, dayjs(birthday, DATE_FORMAT).toISOString(), salutation, firstName, lastName);
    } else {
      setForceValidation(true);
    }
  };

  const getValueIfLoggedIn = (value) => {
    if (!isLoggedIn) {
      return null;
    } else {
      return value;
    }
  };

  return (
    <div>
      <div className={styles.advantgesContainer}>
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <Headline tag="h4" margin="mb-10">
              {language('newsletterFeedback.registration.advantagesTitle')}
            </Headline>
          </Col>
          {getAdvantages().map((advantage) => (
            <Col xl={12} lg={12} md={12} sm={12} xs={12} key={advantage.id} className={styles.advantageContainer}>
              <Icon className={styles.advantageIcon} path={ICON_CHECK_CIRCLE_OUTLINE}></Icon>
              <span>{advantage.value}</span>
            </Col>
          ))}
        </Row>
      </div>
      <form onSubmit={onSubmit}>
        <Row>
          <Col xl={8} lg={8} md={8} sm={12} xs={12}>
            <FieldSetInput
              name="salutation"
              required
              value={salutation}
              handleChange={(value) => setSalutation(value)}
              forceValidation={
                (salutationValidation !== ValidationTypes.Valid && salutationValidation !== ValidationTypes.Initial) ||
                forceValidation
              }
              validationResult={salutationValidation}
            >
              <InputRadioList inline />
            </FieldSetInput>
          </Col>
        </Row>
        <Row>
          <Col xl={8} lg={8} md={8} sm={12} xs={12}>
            <FieldSetInput
              name="firstName"
              required
              value={firstName}
              handleChange={(value) => setFirstName(value)}
              forceValidation={
                (firstNameValidation !== ValidationTypes.Valid && firstNameValidation !== ValidationTypes.Initial) ||
                forceValidation
              }
              validationResult={firstNameValidation}
            >
              <InputText updateOnce={getValueIfLoggedIn(firstName)} />
            </FieldSetInput>
          </Col>
          <Col xl={8} lg={8} md={8} sm={12} xs={12}>
            <FieldSetInput
              name="lastName"
              required
              value={lastName}
              handleChange={(value) => setLastName(value)}
              forceValidation={
                (lastNameValidation !== ValidationTypes.Valid && lastNameValidation !== ValidationTypes.Initial) ||
                forceValidation
              }
              validationResult={lastNameValidation}
            >
              <InputText updateOnce={getValueIfLoggedIn(lastName)} />
            </FieldSetInput>
          </Col>
          <Col xl={8} lg={8} md={8} sm={12} xs={12}>
            <FieldSetInput
              name="contactEmail"
              required
              value={email}
              handleChange={(value) => setEmail(value)}
              forceValidation={
                (emailValidation !== ValidationTypes.Valid && emailValidation !== ValidationTypes.Initial) ||
                forceValidation
              }
              validationResult={emailValidation}
            >
              <InputText updateOnce={getValueIfLoggedIn(email)} type="email" />
            </FieldSetInput>
          </Col>
          <Col xl={8} lg={8} md={8} sm={12} xs={12}>
            <FieldSetInput
              name="birthday"
              required
              value={birthday}
              handleChange={(value) => setBirthday(value)}
              placeholder={'TT.MM.JJJJ'}
              maxLength={10}
              forceValidation={
                (birthdayValidation !== ValidationTypes.Valid && birthdayValidation !== ValidationTypes.Initial) ||
                forceValidation
              }
              validationResult={birthdayValidation}
            >
              <InputText updateOnce={getValueIfLoggedIn(birthday)} />
            </FieldSetInput>
          </Col>
        </Row>
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} className={styles.mandatoryFieldInfoContainer}>
            <MandatoryFieldInfo />
          </Col>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <ButtonLink variety={ButtonLink.varieties.flat} color="red" type="submit" stretched={isMobile}>
              {language('newsletterFeedback.registration.submit')}
            </ButtonLink>
          </Col>
        </Row>
      </form>
      <Row className={styles.footerSection}>
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <Markdown>
            {language('newsletterFeedback.registration.recoText').replace(
              '{RECO_URL}',
              language('newsletterFeedback.registration.dataPrivacyUrl')
            )}
          </Markdown>
        </Col>
        <Col xl={12} lg={12} md={12} sm={12} xs={12} className={styles.marginContainer}>
          <span>{language('newsletterFeedback.registration.footerText')}</span>
        </Col>
        <Col xl={12} lg={12} md={12} sm={12} xs={12} className={styles.marginContainer}>
          <span dangerouslySetInnerHTML={getDataProtectionText()}></span>
        </Col>
      </Row>
    </div>
  );
};

NewsletterRegistrationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  userFields: PropTypes.object,
  isLoggedIn: PropTypes.bool,
};

NewsletterRegistrationForm.displayName = 'organisms/NewsletterRegistrationForm';

export default NewsletterRegistrationForm;
