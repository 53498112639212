export const provideSearchString = (props) => {
  // remove searchString if you are not on the productsOverview page
  if (!props.pathname.includes('/search')) {
    return '';
  }

  // check for a valid searchString
  if (typeof props.searchString === 'string') {
    return props.searchString;
  }

  // extract search string from url
  if (!props.searchString && props.query && props.query.split('search=').length > 1) {
    return decodeURIComponent(props.query.split('search=')[1].split('&')[0]);
  }

  return props.searchString || '';
};
