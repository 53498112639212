import React from 'react';
import PropTypes from 'prop-types';
import styles from './CategoryFacet.scss';
import cx from 'classnames';
import FacetCategoryBack from 'components/atoms/FacetCategoryBack/FacetCategoryBack';
import MobileNavigationItem from 'components/atoms/MobileNavigationItem/MobileNavigationItem';
import CmsFacetListItem from 'components/atoms/CmsFacetListItem/CmsFacetListItem';

import { FacetPropType } from 'constants/facetsHelper/facetsHelper';

const CmsCategoryFacet = ({ categoryFacet, prefetchFilter }) => {
  if (categoryFacet?.code !== 'categoryPath') return null;

  const wrapperStyle = cx(styles.facetList, styles.spacer, styles.categoryFacet);
  const selectedCategoryFacet =
    categoryFacet.selectedValues?.length > 0 && categoryFacet.selectedValues[categoryFacet.selectedValues.length - 1];
  const selectedCategory = selectedCategoryFacet?.code !== '/1' && selectedCategoryFacet?.name;

  return (
    <div className={wrapperStyle}>
      {categoryFacet.showBackLink && (
        <FacetCategoryBack
          isCmsPage
          code={categoryFacet.removeQueryCode}
          query={categoryFacet.removeQuery}
          removeCategoryFacet={() => {
            prefetchFilter({
              facetCode: categoryFacet.code,
              filter: [{ code: categoryFacet.removeQueryCode }],
              autoSubmit: true,
            });
          }}
        />
      )}

      <MobileNavigationItem
        className={cx(styles.activeFacet, !categoryFacet.showBackLink ? styles.activeFacetFirst : '')}
        disabled
        id="selected"
        highlightedItem="selected"
      >
        {selectedCategory || categoryFacet.name}
      </MobileNavigationItem>
      {categoryFacet?.values
        ?.filter((facet) => !facet?.selected)
        .map((category) => (
          <CmsFacetListItem
            key={category.code}
            facetValue={category}
            addFacet={() => {
              prefetchFilter({
                facetCode: categoryFacet.code,
                filter: [category],
                autoSubmit: true,
              });
            }}
          />
        ))}
    </div>
  );
};

CmsCategoryFacet.displayName = 'organisms/CmsCategoryFacet';
CmsCategoryFacet.propTypes = {
  categoryFacet: FacetPropType.isRequired,
  prefetchFilter: PropTypes.func.isRequired,
};

export default CmsCategoryFacet;
