// Dynamic endings for product/page/filter
export const _CATEGORY_PRIMARY = '_category_primaryCategory';
export const _CATEGORY_SUB = '_category_subCategory';
export const PLATFORM = 'platform';

// ARRIVAL
const ARRIVAL = 'arrival';

const ARRIVAL_USER = `${ARRIVAL}_user`;
export const ARRIVAL_USER_AGENT = `${ARRIVAL_USER}_agent`;
export const ARRIVAL_USER_REFERRER = `${ARRIVAL_USER}_referrer`;

export const ARRIVAL_PERFORMANCE = `${ARRIVAL}_performance`;

// PAGE
export const PAGE = 'page';
export const PAGE_ATTR = `${PAGE}_attributes`;

export const PAGE_ATTR_VIRTUAL_PAGE_VIEW = `${PAGE_ATTR}_virtualPageView`;
export const PAGE_ATTR_URL = `${PAGE_ATTR}_URL`;
export const PAGE_ATTR_URL_HOST = `${PAGE_ATTR}_URLhostname`;
export const PAGE_ATTR_URL_PATH = `${PAGE_ATTR}_URLpath`;
export const PAGE_ATTR_URL_QUERY = `${PAGE_ATTR}_URLqueryString`;
export const PAGE_INFO = `${PAGE}_pageInfo`;

export const PAGE_INFO_ONSITE_RESULTS_DISPLAYED = `${PAGE_INFO}_onsiteResultsDisplayed`;
export const PAGE_INFO_NAME = `${PAGE_INFO}_pageName`;
export const PAGE_INFO_LANGUAGE = `${PAGE_INFO}_language`;
export const PAGE_INFO_TEMPLATE = `${PAGE_INFO}_templateType`;

export const PAGE_CATEGORY_PRIMARY = `${PAGE}${_CATEGORY_PRIMARY}`;
export const PAGE_CATEGORY_SUB = `${PAGE}${_CATEGORY_SUB}`;
export const PAGE_CATEGORY_CODE_PATH = 'page_categoryCode_path';

export const PAGE_INFO_SEARCH_TERM = `${PAGE_INFO}_onsiteSearchTerm`;

export const PAGE_INFO_ONSITE_RESULTS_TOTAL = `${PAGE_INFO}_onsiteResultsTotal`;
// PRODUCT
export const PRODUCT = 'product';
const PRODUCT_INFO = `${PRODUCT}_productInfo`;
export const PRODUCT_INFO_SKU = `${PRODUCT_INFO}_sku`;
export const PRODUCT_INFO_SKU_NOZERO = `${PRODUCT_INFO_SKU}_noZero`;
export const PRODUCT_INFO_NAME = `${PRODUCT_INFO}_productName`;
export const PRODUCT_INFO_MANUFACTURERARTICLENO = `${PRODUCT_INFO}_ManufacturerArticleNo`;
export const PRODUCT_INFO_WARRANTY = `${PRODUCT_INFO}_Warranty`;
export const PRODUCT_INFO_ACTIVE = `${PRODUCT_INFO}_Active`;
export const PRODUCT_INFO_ARTICLESELLABLE = `${PRODUCT_INFO}_ArticleSellable`;
export const PRODUCT_INFO_RELEASEDATE = `${PRODUCT_INFO}_ReleaseDate`;
export const PRODUCT_INFO_AVAILABLEFROM = `${PRODUCT_INFO}_AvailableFrom`;
export const PRODUCT_INFO_AVAILABLETILL = `${PRODUCT_INFO}_AvailableTill`;
export const PRODUCT_INFO_IDENTIFIER = `${PRODUCT_INFO}_Identifier`;
export const PRODUCT_INFO_STOCKLEVEL = `${PRODUCT_INFO}_StockLevel`;
export const PRODUCT_INFO_AVAILABILITYSTATUSCODE = `${PRODUCT_INFO}_AvailabilityStatusCode`;
export const PRODUCT_INFO_MARGIN = `${PRODUCT_INFO}_MARGIN`;
export const PRODUCT_INFO_IMAGEURL = `${PRODUCT_INFO}_IMAGEURL`;
export const PRODUCT_INFO_ELEMENT_TITLE = `${PRODUCT_INFO}_elementTitle`;

const PRODUCT_ATTR = `${PRODUCT}_attributes`;
export const PRODUCT_ATTR_VARIANT = `${PRODUCT_ATTR}_variant`;
export const PRODUCT_ATTR_BRAND = `${PRODUCT_ATTR}_brand`;
export const PRODUCT_ATTR_FINAL_PRICE = `${PRODUCT_ATTR}_finalPrice`;
export const PRODUCT_ATTR_BASE_PRICE = `${PRODUCT_ATTR}_basePrice`;
export const PRODUCT_ATTR_INSTEAD_PRICE = `${PRODUCT_ATTR}_insteadPrice`;
export const PRODUCT_ATTR_QUANTITY = `${PRODUCT_ATTR}_quantity`;
export const PRODUCT_ATTR_AVAILABILITY = `${PRODUCT_ATTR}_availability`;
export const PRODUCT_ATTR_PRODUCT_BOX_ID = `${PRODUCT_ATTR}_productBoxId`;
export const PRODUCT_ATTR_PRODUCT_RECO_SERVICE = `${PRODUCT_ATTR}_recoService`;

export const PRODUCT_CATEGORY_PRIMARY = `${PRODUCT}${_CATEGORY_PRIMARY}`;
export const PRODUCT_CATEGORY_SUB = `${PRODUCT}${_CATEGORY_SUB}`;

export const PRODUCT_LIST_NAME = `${PRODUCT}_list_name`;

export const PRODUCT_CATEGORY_PRIMARYCATEGORY = `${PRODUCT}_category_primaryCategory`;
export const PRODUCT_CATEGORY_SUBCATEGORY1 = `${PRODUCT}_category_subCategory1`;
export const PRODUCT_CATEGORY_LOWESTCATEGORY = `${PRODUCT}_category_lowestCategory`;
export const PRODUCT_CATEGORY_SUBCATEGORY = `${PRODUCT}_category_subCategory`;

export const PRODUCT_CATEGORY_CODE_PRIMARYCATEGORY = `${PRODUCT}_categoryCode_primaryCategory`;
export const PRODUCT_CATEGORY_CODE_SUBCATEGORY1 = `${PRODUCT}_categoryCode_subCategory1`;
export const PRODUCT_CATEGORY_CODE_LOWESTCATEGORY = `${PRODUCT}_categoryCode_lowestCategory`;
export const PRODUCT_CATEGORY_CODE_SUBCATEGORY = `${PRODUCT}_categoryCode_subCategory`;

export const PRODUCT_ACTION = `${PRODUCT}_action`;

// PROMOTIONS
const PROMO = 'promo';
const PROMO_INFO = `${PROMO}_promoInfo`;
export const PROMO_INFO_CREATIVE = `${PROMO_INFO}_creative`;
export const PROMO_INFO_POSITION = `${PROMO_INFO}_position`;
export const PROMO_INFO_LINK = `${PROMO_INFO}_link`;

// EVENTS
const EVENT = 'event';
const EVENT_ATTR = `${EVENT}_attributes`;
export const EVENT_ATTR_NAME = `${EVENT_ATTR}_eventName`;
export const EVENT_CATEGORY_PRIMARY = `${EVENT}_category_primaryCategory`;
export const EVENT_EVENTINFO_EFFECT = `${EVENT}_eventInfo_effect`;

// FILTER
export const FILTER = 'filter';
const FILTER_ELEMENT = `${FILTER}_element`;
export const FILTER_ELEMENT_NAME = `${FILTER_ELEMENT}_elementName`;
export const FILTER_ELEMENT_VALUE = `${FILTER_ELEMENT}_elementValue`;
export const FILTER_ELEMENT_SECTION = `${FILTER_ELEMENT}_sectionName`;

const FILTER_INFO = `${FILTER}_filterInfo`;
export const FILTER_INFO_COMPLEXITY = `${FILTER_INFO}_complexity`;
export const FILTER_INFO_CRITERIA = `${FILTER_INFO}_criteria`;
export const FILTER_INFO_LOGIC = `${FILTER_INFO}_logic`;
export const FILTER_INFO_TERM = `${FILTER_INFO}_term`;
export const FILTER_INFO_TYPE = `${FILTER_INFO}_type`;
export const FILTER_INFO_SORT = `${FILTER_INFO}_sort`; // [custom]

const FILTER_RESULT = `${FILTER}_result`;
export const FILTER_RESULT_ELEMENT_NAME = `${FILTER_RESULT}_elementName`;
export const FILTER_RESULT_ELEMENT_VALUE = `${FILTER_RESULT}_elementValue`;
export const FILTER_RESULT_ITERATION = `${FILTER_RESULT}_iteration`;
export const FILTER_RESULT_CURRENT_PAGE = `${FILTER_RESULT}_currentPage`;

export const FILTER_CATEGORY_PRIMARY = `${FILTER}${_CATEGORY_PRIMARY}`;
export const FILTER_CATEGORY_SUB = `${FILTER}${_CATEGORY_SUB}`;
export const FILTER_CATEGORY_SUB1 = `${FILTER}${_CATEGORY_SUB}1`;
export const FILTER_CATEGORY_SUB2 = `${FILTER}${_CATEGORY_SUB}2`;
export const FILTER_CATEGORY_SUB3 = `${FILTER}${_CATEGORY_SUB}3`;

// GENERIC TRACKING INTERACTIONS
export const COMPONENT = 'component';
export const COMPONENT_TYPE = `${COMPONENT}_type`;
export const COMPONENT_LINK = `${COMPONENT}_link`;
export const COMPONENT_LOCATION = `${COMPONENT}_location`;
export const COMPONENT_INNERLOCATION = `${COMPONENT}_innerlocation`;
export const COMPONENT_INTERACTION = `${COMPONENT}_interaction`;
export const COMPONENT_ATTRIBUTE_1 = `${COMPONENT}_attribute1`;
export const COMPONENT_ATTRIBUTE_2 = `${COMPONENT}_attribute2`;
export const COMPONENT_ATTRIBUTE_3 = `${COMPONENT}_attribute3`;
export const COMPONENT_ID = `${COMPONENT}_id`;
export const COMPONENT_FUNCTION = `${COMPONENT}_function`;

// CONVERSION
export const CONVERSION = 'conversion';
const CONVERSION_FUNNEL = `${CONVERSION}_funnel`;
export const CONVERSION_FUNNEL_NAME = `${CONVERSION_FUNNEL}_name`;
export const CONVERSION_FUNNEL_STEP = `${CONVERSION_FUNNEL}_step`;
export const CONVERSION_FUNNEL_STEP_NR = `${CONVERSION_FUNNEL}_stepNr`;

const CONVERSION_INFO = `${CONVERSION}_conversionInfo`;
export const CONVERSION_INFO_LABEL = `${CONVERSION_INFO}_conversionLabel`;

const CONVERSION_CATEGORY = `${CONVERSION}_category`;
export const CONVERSION_CATEGORY_PRIMARY = `${CONVERSION_CATEGORY}_primary`;

// TRANSACTION
export const TRANSACTION = 'transaction';
const TRANSACTION_ATTR = `${TRANSACTION}_attributes`;
export const TRANSACTION_ATTR_EVENT_TYPE = `${TRANSACTION_ATTR}_eventType`;
export const TRANSACTION_ATTR_PAYMENT_METHOD = `${TRANSACTION_ATTR}_paymentMethod`;
export const TRANSACTION_ATTR_DATE = `${TRANSACTION_ATTR}_transactionDate`;
export const TRANSACTION_ATTR_TIME = `${TRANSACTION_ATTR}_transactionTime`;
export const TRANSACTION_ATTR_TAX_VALUE = `${TRANSACTION_ATTR}_taxValue`;
export const TRANSACTION_ATTR_SHIPPING_METHOD = `${TRANSACTION_ATTR}_shippingMethod`;
export const TRANSACTION_ATTR_FUNNEL_TYPE = `${TRANSACTION_ATTR}_funnelType`;
export const TRANSACTION_ATTR_FUNNEL_STEP = `${TRANSACTION_ATTR}_funnelStep`;
export const TRANSACTION_ATTR_DIGITAL_VOUCHER = `${TRANSACTION_ATTR}_digitalVoucher`;
export const TRANSACTION_ATTR_CHECKOUT_TYPE = `${TRANSACTION_ATTR}_checkoutType`;

const TRANSACTION_ITEM_PRICE = `${TRANSACTION}_item_price`;
export const TRANSACTION_ITEM_PRICE_TOTAL_PRICE = `${TRANSACTION_ITEM_PRICE}_totalPrice`;
export const TRANSACTION_ITEM_PRICE_VOUCHER_CODE = `${TRANSACTION_ITEM_PRICE}_voucherCode`;

const TRANSACTION_TOTAL = `${TRANSACTION}_total`;
export const TRANSACTION_TOTAL_SHIPPING = `${TRANSACTION_TOTAL}_shipping`;
export const TRANSACTION_TOTAL_FINAL_PRICE = `${TRANSACTION_TOTAL}_finalPrice`;
export const TRANSACTION_TOTAL_TOTAL_PRICE = `${TRANSACTION_TOTAL}_totalPrice`;
export const TRANSACTION_TOTAL_VOUCHER_CODE = `${TRANSACTION_TOTAL}_voucherCode`;
export const TRANSACTION_TRANSACTION_ID = `${TRANSACTION}_transactionID`;
// [custom]
const TRANSACTION_PAYMENT = `${TRANSACTION}_payment`;
export const TRANSACTION_PAYMENT_FIELDS = `${TRANSACTION_PAYMENT}_fields`;
export const TRANSACTION_PAYMENT_VALIDITY = `${TRANSACTION_PAYMENT}_validity`;
// [custom]
const TRANSACTION_DELIVERY = `${TRANSACTION}_delivery`;
export const TRANSACTION_DELIVERY_FIELDS = `${TRANSACTION_DELIVERY}_fields`;
export const TRANSACTION_DELIVERY_VALIDITY = `${TRANSACTION_DELIVERY}_validity`;

// USER
export const USER = 'user';
const USER_PROFILE = `${USER}_profile`;
const USER_PROFILE_INFO = `${USER_PROFILE}_profileInfo`;
export const USER_PROFILE_INFO_LOGIN_STATUS = `${USER_PROFILE_INFO}_loginStatus`;
export const USER_PROFILE_INFO_CUSTOMER_TYPE = `${USER_PROFILE_INFO}_customerType`;
export const USER_PROFILE_INFO_PROFILE_ID = `${USER_PROFILE_INFO}_profileID`;
export const USER_PROFILE_INFO_EMAIL_ID = `${USER_PROFILE_INFO}_emailID`;
export const USER_PROFILE_INFO_ONLINE_ID = `${USER_PROFILE_INFO}_coid`;
export const USER_PROFILE_ACCESSMODE = `${USER_PROFILE}_accessMode`;
export const USER_PROFILE_TELESALES_AGENT = `${USER_PROFILE}_telesales_agent`;
export const USER_PROFILE_CONSENT_NEWSLETTER = `${USER_PROFILE}_consent_newsletter`;
export const USER_PROFILE_CONSENT_SMS = `${USER_PROFILE}_consent_sms`;
export const USER_PROFILE_CONSENT_IND_GLOBAL = `${USER_PROFILE}_consent_ind_global`;
export const USER_PROFILE_CONSENT_IND_EMAIL = `${USER_PROFILE}_consent_ind_email`;
export const USER_PROFILE_CONSENT_IND_SMS = `${USER_PROFILE}_consent_ind_sms`;
export const USER_PROFILE_CONSENT_IND_POST = `${USER_PROFILE}_consent_ind_post`;

// [custom]
const USER_LOGIN = `${USER}_login`;
export const USER_LOGIN_FIELDS = `${USER_LOGIN}_fields`;
export const USER_LOGIN_VALIDITY = `${USER_LOGIN}_validity`;
// [custom]
const USER_REGISTER = `${USER}_register`;
export const USER_REGISTER_FIELDS = `${USER_REGISTER}_fields`;
export const USER_REGISTER_VALIDITY = `${USER_REGISTER}_validity`;

// EXIT [custom]
export const EXIT = 'exit';
const EXIT_TOTAL = `${EXIT}_total`;
export const EXIT_TOTAL_TIME = `${EXIT_TOTAL}_time`;
export const EXIT_TOTAL_PAGE_COUNT = `${EXIT_TOTAL}_pageCount`;

const EXIT_PAGE = `${EXIT}_page`;
export const EXIT_PAGE_TIME = `${EXIT_PAGE}_time`;
export const EXIT_PAGE_SCROLL = `${EXIT_PAGE}_scroll`;

// WEBTRENDS
const WEBTRENDS = 'toolWebtrends_wt';
export const WEBTRENDS_TX = `${WEBTRENDS}_tx`;
export const WEBTRENDS_TX_DATE = `${WEBTRENDS_TX}_id`;
export const WEBTRENDS_TX_EVENT_TYPE = `${WEBTRENDS_TX}_e`;
export const WEBTRENDS_BOUNCE_VALUE = `${WEBTRENDS}_i_bounce`;
export const WEBTRENDS_EVENT_TYPE = `${WEBTRENDS}_dl`;

// CONTENT
const CONTENT = 'content';
const CONTENT_INFO = `${CONTENT}_contentInfo`;
export const CONTENT_INFO_VERSION = `${CONTENT_INFO}_version`;
export const CONTENT_INFO_TITLE = `${CONTENT_INFO}_title`;
export const CONTENT_INFO_ISSUE_DATE = `${CONTENT_INFO}_issueDate`;
export const CONTENT_INFO_EFFECTIVE_DATE = `${CONTENT_INFO}_effectiveDate`;
export const CONTENT_INFO_TYPE = `${CONTENT_INFO}_contentType`;
export const CONTENT_INFO_AUTHOR = `${CONTENT_INFO}_author`;
const CONTENT_ATTR = `${CONTENT}_attributes`;
export const CONTENT_ATTR_TOPIC = `${CONTENT_ATTR}_topic`;
export const CONTENT_ATTR_CONTACT = `${CONTENT_ATTR}_contactPerson`;

// Search
export const SEARCH_INFO_TYPE = 'search_searchInfo_type';
export const THEME_SEARCH = 'theme_search';
export const SUGGESTION_SEARCH = 'suggestion_search';
export const ACTIVE_SEARCH = 'active_search';
export const TERM_SUGGESTION_SEARCH = 'term_suggestion_search';

// Search History
export const SEARCH_HISTORY = 'search_history';
export const SEARCH_HISTORY_TYPE_LIST = `${SEARCH_HISTORY}:list`;
export const SEARCH_HISTORY_TYPE_LIST_ENTRY = `${SEARCH_HISTORY}:entry`;

// Navigation
export const NAVIGATION = 'nav';
export const NAVIGATION_MAIN = `${NAVIGATION}:mainnav`;
export const NAVIGATION_SUB = `${NAVIGATION}:subnav`;
export const NAVIGATION_CLICK = `${NAVIGATION}:click`;
export const NAVIGATION_OPEN = 'open';
export const NAVIGATION_CLOSE = 'close';
export const NAVIGATION_SELECT = 'select';
export const NAVIGATION_BACK = 'back';
export const NAVIGATION_GO_TO = 'go-to';

// Interaction
export const TOOLGA_NONINTERACTIONEVENT = 'toolGA_nonInteractionEvent';

// Event
export const EVENT_CATEGORY_PRIMARYCATEGORY = `event${_CATEGORY_PRIMARY}`;
