import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import addresses from 'reducers/addresses/addresses';
import products from 'reducers/products/products';
import productsQueries from 'reducers/productsQueries/productsQueries';
import cart from 'reducers/cart/cart';
import user from 'reducers/user/user';
import api from 'reducers/api/api';
import orders from 'reducers/orders/orders';
import storefinder from 'reducers/storefinder/storefinder';
import ui from 'reducers/ui/ui';
import cms from 'reducers/cms/cms';
import contact from 'reducers/contact/contact';
import suggestions from 'reducers/suggestions/suggestions';
import watchlist from 'reducers/watchlist/watchlist';
import recommendations from 'reducers/recommendations/recommendations';
import comparison from 'reducers/comparison/comparison';
import ratings from 'reducers/ratings/ratings';
import inventory from 'reducers/inventory/inventory';
import categories from 'reducers/categories/categories';
import rendering from 'reducers/rendering/rendering';

export default combineReducers({
  api,
  addresses,
  products,
  productsQueries,
  cart,
  user,
  orders,
  storefinder,
  ui,
  cms,
  contact,
  suggestions,
  watchlist,
  recommendations,
  comparison,
  inventory,
  routing: routerReducer,
  ratings,
  categories,
  rendering,
});
