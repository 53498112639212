import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import bootstrap from 'scss/component.scss';

const Container = ({ children, id, className }) => (
  <div id={id} className={cx(bootstrap.container, { [className]: !!className })}>
    {children}
  </div>
);

Container.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
};

Container.displayName = 'atoms/Grid/Container';

export default Container;
