import React from 'react';
import PropTypes from 'prop-types';
import StoreFinderListItem from 'components/molecules/StoreFinderListItem/StoreFinderListItem';
import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';
import Icon, { ICON_ADD } from 'components/atoms/Icon/Icon';
import language from 'constants/language/language';
import { availabilityStates } from 'constants/deliveryStates/availabilityStates';
import viewTypes from 'constants/storeFinderResultsViewTypes/storeFinderResultsViewTypes';
import styles from './StoreFinderList.scss';
import { cypressAttributes } from 'constants/cypress/cypress';

const StoreFinderList = ({
  stores = [],
  selectPickupStore = () => {},
  loadMoreStores = () => {},
  totalStores = 0,
  selectPickupStoreEnabled = false,
  switchView = () => {},
  myStoreId = '',
  saveAsMyStore = () => {},
  isLoggedIn = false,
  isStandalone = false,
  isInventoryStorefinder = false,
  showDistance = false,
  availability = '',
  releaseDate = '',
}) => (
  <div>
    <ul className={styles.list} data-cy={cypressAttributes.checkout.storeFinderList}>
      {stores?.map(
        (store, i) =>
          typeof store !== 'undefined' && (
            <li className={styles.item} key={`store-item-${i}`}>
              <StoreFinderListItem
                store={store}
                selectPickupStore={selectPickupStore}
                selectPickupStoreEnabled={selectPickupStoreEnabled}
                borderless={i === 0}
                switchView={switchView}
                activeView={viewTypes.LIST}
                isMyStore={store.name === myStoreId}
                saveAsMyStore={saveAsMyStore}
                isLoggedIn={isLoggedIn}
                isInventoryStorefinder={isInventoryStorefinder}
                showDistance={showDistance}
                availability={availability}
                releaseDate={releaseDate}
              />
            </li>
          )
      )}
    </ul>
    {isStandalone && stores?.length < totalStores && (
      <p className={styles.footer}>
        <ButtonLink variety={ButtonLink.varieties.stroked} onClick={loadMoreStores}>
          <Icon path={ICON_ADD} />
          <span>{language('storeFinder.results.loadMore')}</span>
        </ButtonLink>
      </p>
    )}
  </div>
);

StoreFinderList.propTypes = {
  stores: PropTypes.array.isRequired,
  selectPickupStore: PropTypes.func,
  loadMoreStores: PropTypes.func,
  totalStores: PropTypes.number,
  selectPickupStoreEnabled: PropTypes.bool,
  switchView: PropTypes.func,
  myStoreId: PropTypes.string,
  saveAsMyStore: PropTypes.func,
  isLoggedIn: PropTypes.bool,
  isStandalone: PropTypes.bool,
  isInventoryStorefinder: PropTypes.bool,
  showDistance: PropTypes.bool,
  availability: PropTypes.oneOf(Object.values(availabilityStates)),
  releaseDate: PropTypes.string,
};

StoreFinderList.displayName = 'organisms/StoreFinderList';

export default StoreFinderList;
