import React from 'react';
import PropTypes from 'prop-types';
import CmsBreadcrumb from 'containers/CmsBreadcrumb/CmsBreadcrumb';
import CmsContentSuccess from 'containers/CmsContentSuccess/CmsContentSuccess';
import CmsHeadline from 'containers/CmsHeadline/CmsHeadline';
import CmsPagesDispatcher from 'containers/CmsPagesDispatcher/CmsPagesDispatcher';
import CmsSidebar from 'containers/CmsSidebar/CmsSidebar';
import Breadcrumb, { types } from 'components/organisms/Breadcrumb/Breadcrumb';
import CmsPagesAfter from 'components/organisms/CmsPagesAfter/CmsPagesAfter';
import CmsPagesBefore from 'components/organisms/CmsPagesBefore/CmsPagesBefore';
import ProductComparisonHeader from 'components/molecules/ProductComparisonHeader/ProductComparisonHeader';
import ProductComparisonContainer from 'containers/ProductComparison/ProductComparison';
import { Row, Col } from 'components/atoms/Grid/Grid';
import { QualtricsCreativeContainer } from '@interdiscount/component-library-interdiscount';

const Cms = ({ isBrandPage = false, options = {}, pageId = '', comparisonHasError = false }) => (
  <>
    {__CLIENT__ && comparisonHasError && (
      <ProductComparisonContainer>
        <ProductComparisonHeader />
      </ProductComparisonContainer>
    )}
    <Row>
      <CmsPagesDispatcher contentId={pageId} />
      <CmsContentSuccess isBrandPage={isBrandPage}>
        {options.showBreadcrumbs ? (
          <Col lg={12}>
            <CmsBreadcrumb currentPageId={pageId}>
              <Breadcrumb type={isBrandPage ? types.invisible : types.detail} />
              {/* Breadcrumb will be invisible on brand pages until logic is completed for it */}
            </CmsBreadcrumb>
          </Col>
        ) : (
          <Breadcrumb type={types.invisible} />
        )}
        {options.showNavigation && <CmsSidebar />}
        <Col lg={options.showNavigation ? 9 : 12}>
          <CmsHeadline contentId={pageId} hasSidebar={options.showNavigation} />
          <QualtricsCreativeContainer qId="qInterceptCms" />
          <CmsPagesBefore contentId={pageId} hasSidebar={options.showNavigation} />
          <CmsPagesAfter contentId={pageId} hasSidebar={options.showNavigation} />
        </Col>
      </CmsContentSuccess>
    </Row>
  </>
);

Cms.displayName = 'templates/Cms';
Cms.propTypes = {
  isBrandPage: PropTypes.bool,
  options: PropTypes.object,
  pageId: PropTypes.string,
  comparisonHasError: PropTypes.bool,
};

export default Cms;
