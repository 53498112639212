// @flow
import RatingAPI from 'api/RatingAPI/RatingAPI';
import {
  LOAD_RATING_SUMMARY,
  LOAD_RATINGS,
  CREATE_RATING,
  UPDATE_RATING,
  DELETE_RATING,
  LOAD_RATING,
  RESET_RATING_FAILURE,
  LOAD_PRODUCT_RATINGS,
  TOGGLE_RATING_MODAL,
} from 'constants/ActionTypes/ActionTypes';
import type { IRatingState, ILoadRatings, IModalRating, ICreateRatings, IUpdateRatings } from 'flow/ratings';

export const loadCustomerRatingsSummary = () => ({
  type: LOAD_RATING_SUMMARY,
  payload: {
    lastFetched: Date.now(),
  },
  promise: RatingAPI.getCustomerRatingsSummary(),
});

export const loadCustomerRatings = (params: ILoadRatings) => (
  dispatch: (any) => Promise<any>,
  getState: () => { reviews: IRatingState }
) => {
  const ratingState = getState().ratings[params.type];
  const pageSize = params.pageSize || ratingState.pageSize;
  const page = params.page || ratingState.currentPage;

  return dispatch({
    type: LOAD_RATINGS,
    payload: {
      type: params.type,
      page,
      pageSize,
      lastFetched: Date.now(),
    },
    promise: RatingAPI.getCustomersRatings({ type: params.type, page, pageSize }),
  });
};

export const loadProductRatings = (productCode: string, params: Object) => (
  dispatch: (any) => Promise<any>,
  getState: () => { ratings: IRatingState }
) => {
  const ratingState = getState().ratings;
  const allParams = {
    ...ratingState.commentsQuery,
    ...params,
  };

  return dispatch({
    type: LOAD_PRODUCT_RATINGS,
    promise: RatingAPI.getProductRatings(productCode, allParams),
    params: allParams,
  });
};

export const loadMoreProductRatings = (productCode: string) => (
  dispatch: (any) => Promise<any>,
  getState: () => { ratings: IRatingState }
) => {
  const { commentsQuery } = getState().ratings;
  const params = {
    ...commentsQuery,
    page: commentsQuery.page + 1,
  };

  return dispatch({
    type: LOAD_PRODUCT_RATINGS,
    promise: RatingAPI.getProductRatings(productCode, params),
    params,
  });
};

export const ratingModalAction = (params: IModalRating = {}) => ({
  type: TOGGLE_RATING_MODAL,
  payload: params,
});

export const createRating = (params: ICreateRatings, productCode: string) => ({
  type: CREATE_RATING,
  payload: params,
  promise: RatingAPI.createRating(params, productCode),
});

export const updateRating = (params: IUpdateRatings, productCode: string) => ({
  type: UPDATE_RATING,
  payload: params,
  promise: RatingAPI.updateRating(productCode, params),
});

export const deleteRating = (productCode: string) => ({
  type: DELETE_RATING,
  promise: RatingAPI.deleteRating(productCode),
  productCode,
});

export const loadCustomerRating = (productCode: string) => ({
  type: LOAD_RATING,
  promise: RatingAPI.getCustomersRatingByProduct(productCode),
  productCode,
});

export const resetRatingError = () => ({
  type: RESET_RATING_FAILURE,
});
