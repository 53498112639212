import { useEffect } from 'react';

const useKeyPress = (key, callback, deps = []) => {
  useEffect(() => {
    const handler = (event) => {
      if (event.key === key || (Array.isArray(key) && key.includes(event.key))) {
        callback(event);
      }
    };

    window.addEventListener('keydown', handler);

    return () => {
      window.removeEventListener('keydown', handler);
    };
  }, deps);
};

export default useKeyPress;
