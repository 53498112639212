import React, { cloneElement, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getPageContent as getPageContentAction,
  getBrandContentNeed as getBrandContentNeedAction,
} from 'actions/cmsActions/cmsActions';
import { apiStatus } from 'constants/apiStatus/apiStatus';
import has from 'constants/helper/has';
import { providePageOptionsForUrl, provideUrls } from 'constants/navigation/navigation';
import Spinner from 'components/atoms/Spinner/Spinner';
import { noop } from 'constants/noop/noop';

export const mapStateToProps = (state) => {
  const currentPageId = state?.cms?.currentPageId;
  return {
    currentPageId,
    currentPath: state?.cms?.currentPath,
    navigation: state?.cms?.navigation,
    isPageExisting: has(state, `cms.pages.${currentPageId}`),
    requestForCmsContentStatus: state?.cms?.requestForCmsContentStatus,
    comparisonHasError: state?.comparison?.hasError,
  };
};

export const mapDispatchToProps = {
  getPageContent: getPageContentAction,
  getBrandContentNeed: getBrandContentNeedAction,
};

const CmsCurrentPageIdContainer = ({
  children,
  currentPageId = '',
  currentPath = '',
  getPageContent = noop,
  getBrandContentNeed = noop,
  isBrandPage = false,
  navigation = {},
  isPageExisting = false,
  requestForCmsContentStatus = apiStatus.request,
  comparisonHasError = false,
}) => {
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    if (!isPageExisting) {
      setShowSpinner(true);
      getPageContent(currentPageId).then(() => {
        setShowSpinner(false);
      });
    }
    if (isBrandPage) {
      getBrandContentNeed({}, window.location);
    }
  }, [currentPageId, isPageExisting, currentPath]);

  if (showSpinner && currentPageId !== 'HomePage') {
    return <Spinner isCentered />;
  }

  if (requestForCmsContentStatus === apiStatus.request) {
    return null;
  }

  return cloneElement(children, {
    isBrandPage,
    comparisonHasError,
    options: providePageOptionsForUrl(provideUrls(navigation.pages), currentPath),
    pageId: currentPageId,
  });
};

CmsCurrentPageIdContainer.displayName = 'containers/CmsCurrentPageIdContainer';
CmsCurrentPageIdContainer.propTypes = {
  children: PropTypes.node.isRequired,
  currentPageId: PropTypes.string,
  currentPath: PropTypes.string,
  getPageContent: PropTypes.func,
  getBrandContentNeed: PropTypes.func,
  isBrandPage: PropTypes.bool,
  navigation: PropTypes.object,
  isPageExisting: PropTypes.bool,
  requestForCmsContentStatus: PropTypes.oneOf([apiStatus.request, apiStatus.success, apiStatus.failure]),
  comparisonHasError: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(CmsCurrentPageIdContainer);
