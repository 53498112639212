import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import bootstrap from 'scss/component.scss';
import language from 'constants/language/language';
import { apiStatus } from 'constants/apiStatus/apiStatus';
import Spinner from 'components/atoms/Spinner/Spinner';
import Headline from 'components/atoms/Headline/Headline';
import styles from './MessagePasswordReset.scss';

const MessagePasswordReset = ({ statusCode = '', errorMessage = '' }) => {
  const getMessage = (statusCode) =>
    statusCode === apiStatus.success ? language(`passwordReset.${statusCode}`) : errorMessage;

  return (
    <div className={cx(styles.MessageContainer, bootstrap.clearfix)}>
      <div className={styles.MessageContent}>
        <div className={styles.SpinnerContainer}>
          <Spinner status={apiStatus.success} />
        </div>
        <div className={styles.TextContainer}>
          <Headline tag={Headline.tags.DIV} margin={Headline.margins.NONE}>
            {getMessage(statusCode)}
          </Headline>
        </div>
      </div>
    </div>
  );
};

MessagePasswordReset.displayName = 'molecules/MessagePasswordReset';

MessagePasswordReset.propTypes = {
  statusCode: PropTypes.string,
  errorMessage: PropTypes.string,
};

export default MessagePasswordReset;
