import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Meta from 'containers/Meta/Meta';

import PasswordResetTemplate from 'components/templates/PasswordReset/PasswordReset';

import language from 'constants/language/language';
import NextFeatureEnabled from 'routes/ProductsOverview/NextFeatureEnabled';

const mapStateToProps = (state, ownProps) => ({
  token: ownProps?.location?.query?.token,
});

const PasswordReset = ({ token }) => (
  <div>
    <NextFeatureEnabled conditions={[{ featureName: 'nextLoginRegistrationEnabled', include: 'reset' }]} />
    <Meta title={language('pageTitles.account')} />
    <PasswordResetTemplate token={token} />
  </div>
);

PasswordReset.propTypes = {
  token: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(PasswordReset);
