import { LOAD_RECOMMENDED_PRODUCTS, LOAD_RECOMMENDED_TOP_PRODUCTS } from 'constants/ActionTypes/ActionTypes';
import { fetchRecommendedProducts, fetchTopRecommendations } from 'api/RecoAPI/RecoAPI';
import { loadProductList } from 'actions/productActions/productActions';

/**
 * Loads the recommendations (product ids only) from prudsys and enriches them with full product data
 */
export const loadRecommendedProducts = (productId, categoryId, sessionId, productsUrl) => (dispatch, getState) =>
  dispatch({
    type: LOAD_RECOMMENDED_PRODUCTS,
    payload: {
      productId,
      categoryId,
      sessionId,
      productsUrl,
    },
    promise: fetchRecommendedProducts(productsUrl),
  }).then(() => {
    const recommendations = getState().recommendations[productId];
    const productCodes = Object.keys(getState().products) || [];

    const products = [...new Set([...recommendations.similar.products, ...recommendations.boughtByOthers.products])];

    const diff = products.filter((rec) => !productCodes.includes(rec.productId));

    // Loads the product data from backend (by the product ids received from prudsys)
    dispatch(loadProductList(diff));
  });

export const loadRecommendedTopProducts = (sessionId, type, locale, currentBasket, params = {}, recoLevel = 'box1') => (
  dispatch,
  getState
) =>
  dispatch({
    type: LOAD_RECOMMENDED_TOP_PRODUCTS,
    payload: {
      sessionId,
      type,
    },
    promise: fetchTopRecommendations(sessionId, type, locale, currentBasket, params),
  })
    .then(() => {
      const recommendations = getState().recommendations?.[type]?.[recoLevel] || [];
      const productCodes = getState().products || [];

      const diff = recommendations.filter((rec) => !Object.keys(productCodes).includes(rec.productId));

      return dispatch(loadProductList(diff));
    })
    .then(() => getState().recommendations?.[type]?.[recoLevel] || []);

export default {
  loadRecommendedProducts,
  loadRecommendedTopProducts,
};
