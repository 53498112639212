import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import FeedbackForm from './FeedbackForm';
import FeedbackFormResponse from './FeedbackFormResponse/FeedbackFormResponse';
import Headline from 'components/atoms/Headline/Headline';
import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';

import language, { getLocale } from 'constants/language/language';
import { TOP_FEEDBACK_FORM } from 'constants/scrollToElement/scrollToElement';
import { HELPCENTER_LINK } from 'constants/links/links';

import bootstrap from 'scss/component.scss';
import styles from './FeedbackFormWrapper.scss';

const FeedbackFormWrapper = ({
  formOpen,
  responseOpen,
  loggedIn,
  email,
  requestSuccessful,
  onOpenForm = () => {},
  onSubmitFeedback = () => {},
  onCancel = () => {},
}) => {
  return (
    <div id={TOP_FEEDBACK_FORM} className={bootstrap.row}>
      <div className={cx(bootstrap.colLg6, styles.feedbackFormWrapper)}>
        <Headline margin={Headline.margins.MB_05}>{language('feedbackForm.title')}</Headline>
        {!formOpen && !responseOpen && (
          <ButtonLink onClick={onOpenForm} variety={ButtonLink.varieties.basic} size={ButtonLink.sizes.sm}>
            {language('feedbackForm.link')}
          </ButtonLink>
        )}
        {formOpen && (
          <>
            <span className={styles.infoText}>
              {language('feedbackForm.infoText')}
              <a href={HELPCENTER_LINK + getLocale()} target="_blank" rel="noopener noreferrer">
                {language('feedbackForm.clientService')}
              </a>
              .
            </span>
            <FeedbackForm
              loggedIn={loggedIn}
              email={email}
              submitFeedback={onSubmitFeedback}
              onCancelClick={onCancel}
            />
          </>
        )}
        {responseOpen && <FeedbackFormResponse requestSuccessful={requestSuccessful} />}
      </div>
    </div>
  );
};

FeedbackFormWrapper.propTypes = {
  requestSuccessful: PropTypes.bool,
  formOpen: PropTypes.bool,
  responseOpen: PropTypes.bool,
  loggedIn: PropTypes.bool,
  email: PropTypes.string,
  onOpenForm: PropTypes.func,
  onSubmitFeedback: PropTypes.func,
  onCancel: PropTypes.func,
};

export default FeedbackFormWrapper;
