import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { getLocale } from 'constants/language/language';
import { noop } from 'constants/noop/noop';

import styles from './VideoButton.scss';

const VideoButton = ({
  mid = '',
  height = 60,
  width = 120,
  onVideoAvailable = noop,
  productVideoAvailable = false,
}) => {
  const hasVideo = () => {
    if (!productVideoAvailable) {
      onVideoAvailable();
    }
  };

  const DemoUpButton = useMemo(() => require('demoup-react')('interdiscount.ch').button, [mid]);

  // get currentLocale for the Video so the video will be played in the correct language.
  // add en for default if no video has been found for the selected language.
  const languages = [getLocale(), 'en'];

  return (
    <div className={styles.videoWrapper}>
      <div className={styles.videoList}>
        <DemoUpButton
          mid={mid}
          height={height}
          width={width}
          class={styles.videoButton}
          languages={languages}
          onVideoAvailable={hasVideo}
          multiButton
        />
      </div>
    </div>
  );
};

VideoButton.displayName = 'atoms/VideoButton';
VideoButton.propTypes = {
  mid: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  onVideoAvailable: PropTypes.func,
  productVideoAvailable: PropTypes.bool,
};

export default VideoButton;
