import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import uuid from 'uuid/v1';
import deepEqual from 'constants/helper/deepEqual';
import omit from 'constants/helper/omit';
import language, { getLocale } from 'constants/language/language';

import {
  getProductAccessoriesNeed,
  getProductWallNeed,
  getTimeCriticalProductData,
  loadProductNeed,
  routerUpdateLocationNeed,
} from 'actions/productActions/productActions';

import { addToWatchlist, removeFromWatchlist } from 'actions/watchlistActions/watchlistActions';
import { addToComparison, removeFromComparison } from 'actions/comparisonActions/comparisonActions';
import { trackNavigationElements } from 'actions/trackingActions/trackingActions';
import { addFlashmessage } from 'actions/uiActions/uiActions';

import SpinnerOverlay from 'components/molecules/SpinnerOverlay/SpinnerOverlay';
import MessageBadRequest, { pageType } from 'components/molecules/MessageBadRequest/MessageBadRequest';
import Breadcrumb, { types } from 'components/organisms/Breadcrumb/Breadcrumb';
import { getBreadcrumbs } from 'constants/getBreadcrumbs/getBreadcrumbs';
import ProductDetailTemplate from 'components/templates/ProductDetail/ProductDetail';
import ProductComparisonContainer from 'containers/ProductComparison/ProductComparison';
import ProductComparisonHeader from 'components/molecules/ProductComparisonHeader/ProductComparisonHeader';

import Meta from 'containers/Meta/Meta';

import { apiStatus, invalidApiStatus } from 'constants/apiStatus/apiStatus';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import { PRODUCT_COMPARISON, WATCHLIST } from 'constants/routePaths/routePaths';
import { getFlashMessage, locations, types as flashMessageTypes } from 'constants/flashMessages/flashMessages';
import { getEnv } from 'config/config';
import getProductLink from 'constants/getUrlForProduct/getUrlForProduct';
import NextFeatureEnabled from 'routes/ProductsOverview/NextFeatureEnabled';

const mapStateToProps = (state, ownProps) => {
  const productCode = ownProps.params.productCode;

  // omit referenceItemCodes because this items will be loaded in a separate container
  const product = omit(state.products[productCode], ['referenceItemCodes']);
  const categoryCode = product.categoryCode;
  const productsQuery = state.productsQueries.currentQuery;
  const breadcrumbs = productsQuery.breadcrumb;
  const watchlistEntries = state?.watchlist?.entries;
  const addedToWatchlist = !!watchlistEntries.find((entry) => entry.code === productCode);
  const breakpoint = state.ui.breakpoint;
  const comparisonList = state?.comparison;
  const comparisonCategories = comparisonList?.categories || [];
  const addedToComparison = Object.values(comparisonCategories?.[categoryCode] || {}).includes(productCode);
  const comparisonHasError = state?.comparison?.hasError;
  const flashMessage = getFlashMessage(state?.ui?.flashMessages, locations.PRODUCT);
  const promoBox = state.cms?.promoBox[productCode] || {};
  const location = ownProps.location;

  return {
    product,
    breadcrumbs,
    productCode,
    categoryCode,
    breakpoint,
    addedToWatchlist,
    watchlistEntries,
    addedToComparison,
    comparisonHasError,
    categories: state.categories,
    flashMessage,
    comparisonCategories,
    promoBox,
    location,
  };
};

const mapDispatchToProps = {
  loadProductNeed,
  routerUpdateLocationNeed,
  getProductAccessoriesNeed,
  getProductWallNeed,
  addToWatchlist,
  removeFromWatchlist,
  addToComparison,
  removeFromComparison,
  addFlashmessage,
  getTimeCriticalProductData,
  trackNavigationElements,
};

export class ProductDetail extends Component {
  static propTypes = {
    loadProductNeed: PropTypes.func.isRequired,
    getProductAccessoriesNeed: PropTypes.func.isRequired,
    product: PropTypes.object,
    productCode: PropTypes.string,
    categoryCode: PropTypes.string,
    getProductWallNeed: PropTypes.func,
    addToWatchlist: PropTypes.func,
    removeFromWatchlist: PropTypes.func,
    addedToWatchlist: PropTypes.bool,
    watchlistEntries: PropTypes.array,
    addedToComparison: PropTypes.bool,
    addToComparison: PropTypes.func,
    removeFromComparison: PropTypes.func,
    comparisonHasError: PropTypes.bool,
    getTimeCriticalProductData: PropTypes.func,
    trackNavigationElements: PropTypes.func,
    categories: PropTypes.object,
    flashMessage: PropTypes.object,
    addFlashmessage: PropTypes.func,
    comparisonCategories: PropTypes.object,
    promoBox: PropTypes.object,
  };

  static need = [routerUpdateLocationNeed, loadProductNeed, getProductWallNeed, getProductAccessoriesNeed];

  static defaultProps = {
    product: {},
    productCode: '',
    categoryCode: '',
    watchlistEntries: [],
    categories: {},
    flashMessage: {},
    getProductWallNeed: () => {},
    addToWatchlist: () => {},
    removeFromWatchlist: () => {},
    addToComparison: () => {},
    removeFromComparison: () => {},
    getTimeCriticalProductData: () => {},
    trackNavigationElements: () => {},
    promoBox: {},
  };

  UNSAFE_componentWillMount() {
    if (__CLIENT__) {
      this.loadProductData(this.props.productCode);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.productCode !== nextProps.productCode) {
      this.loadProductData(nextProps.productCode);
    }
  }

  loadProductData = (productCode) => {
    this.props.loadProductNeed({ productCode }).then(() => {
      this.props.getProductAccessoriesNeed({ productCode });
      this.props.getProductWallNeed({ productCode });

      // if maxOrderCounter is active or product has no productPriceData, make timeCritical call to get productPriceData
      if (this.props.product.maxOrderCounterActive || !this.props.product?.productPriceData?.finalPrice?.value) {
        this.props.getTimeCriticalProductData([productCode]);
      }
    });
  };

  shouldComponentUpdate = (nextProps) =>
    JSON.stringify(nextProps.product) !== JSON.stringify(this.props.product) ||
    !deepEqual(nextProps.product.productPriceData, this.props.product.productPriceData) ||
    nextProps.addedToWatchlist !== this.props.addedToWatchlist ||
    nextProps.addedToComparison !== this.props.addedToComparison ||
    nextProps.comparisonHasError !== this.props.comparisonHasError ||
    !deepEqual(nextProps.promoBox, this.props.promoBox);

  timeCriticalProductPriceData = (props, code) => {
    if (__CLIENT__) {
      // if product has no productPriceData, make timeCritical call to get productPriceData
      if (!props.product?.productPriceData?.finalPrice?.value) {
        this.props.getTimeCriticalProductData([code]);
      }
    }
  };

  addToWatchlist = (productCode) => {
    const id = uuid();
    const isAddable = this.props.watchlistEntries.length < 100;
    this.props.addFlashmessage({
      type: !isAddable ? flashMessageTypes.ERROR : flashMessageTypes.SUCCESS,
      content: !isAddable
        ? language('product.watchListIsFull')
        : language('product.addedToWatchList').replace('{URL}', mapPathToLocalizedUrl(getLocale(), [WATCHLIST])),
      id,
    });
    isAddable && this.props.addToWatchlist(productCode);
  };

  removeFromWatchlist = (productCode) => {
    const arrayIndex = this.props.watchlistEntries.findIndex((entry) => entry.code === productCode);
    this.props.removeFromWatchlist(arrayIndex);
  };

  addToComparison = (productCode) => {
    const id = uuid();
    const isAddable = Object.entries(this.props.comparisonCategories?.[this.props.categoryCode] || {})?.length < 3;
    if (isAddable) {
      this.props.addFlashmessage({
        type: flashMessageTypes.SUCCESS,
        content: language('product.addedToComparison').replace(
          '{URL}',
          mapPathToLocalizedUrl(getLocale(), [PRODUCT_COMPARISON])
        ),
        id,
      });
    }
    this.props.addToComparison(productCode, this.props.categoryCode);
  };

  removeFromComparison = (productCode) => {
    this.props.removeFromComparison(productCode, this.props.categoryCode);
  };

  hasPromoEnded = () => {
    const endTime = this.props.product?.promotion?.endTime || 0;
    const stockMax = this.props.product?.promotion?.stockMax || 0;
    const stockCurrent = this.props.product?.promotion?.stockCurrent || 0;

    return endTime > Date.now() && stockMax > 0 && stockCurrent <= 0;
  };

  setCanonicalUrl = () => {
    if (!__CLIENT__) return null;

    const url = new URL(window.location.href);
    let { pathname } = url;

    return [{ rel: 'canonical', href: `${getEnv('web')}${pathname}` }];
  };

  render() {
    const product = { ...this.props.product };
    const breadcrumbs = getBreadcrumbs(product.categoryCode, '', '', getLocale(), this.props.categories);
    // check if the product already exists in the store
    if (product.code) {
      this.tempProductStorage = product;
    }

    const metaDescription = product?.metaDescription ?? `${product?.name || ''} ${language('meta.productDetail')}`;
    const productSeoData = {
      name: product?.nameShort || product?.name,
      href: getProductLink(this.props.categoryCode, product.name, product.code, getLocale(), this.props.categories),
    };

    return (
      <div>
        <NextFeatureEnabled
          location={this.location}
          conditions={[{ include: 'product', featureName: 'nextPdpEnabled' }]}
        />
        {!(invalidApiStatus.indexOf(this.props.product.apiStatus) > -1) && this.tempProductStorage && (
          <div>
            <Meta
              title={this.tempProductStorage.name || ''}
              meta={[
                {
                  name: 'description',
                  content: metaDescription,
                },
              ]}
              canonical={this.setCanonicalUrl()}
            />
            {__CLIENT__ && this.props.comparisonHasError && (
              <ProductComparisonContainer categoryCode={product.categoryCode}>
                <ProductComparisonHeader selectedCategory={product.categoryCode} />
              </ProductComparisonContainer>
            )}
            <Breadcrumb
              type={types.detail}
              items={[...breadcrumbs, productSeoData]}
              categories={this.props.categories}
            />
            <ProductDetailTemplate
              product={this.tempProductStorage}
              addToWatchlist={this.addToWatchlist}
              removeFromWatchlist={this.removeFromWatchlist}
              productAddedToWatchlist={this.props.addedToWatchlist}
              addToComparison={this.addToComparison}
              removeFromComparison={this.removeFromComparison}
              productAddedToComparison={this.props.addedToComparison}
              hasPromoEnded={this.hasPromoEnded()}
              trackNavigationElements={this.props.trackNavigationElements}
              categories={this.props.categories}
              flashMessage={this.props.flashMessage}
              promoBox={this.props.promoBox}
            />
          </div>
        )}
        {(product.apiStatus === apiStatus.request || !product.apiStatus) && !this.tempProductStorage && (
          <SpinnerOverlay />
        )}
        {invalidApiStatus.indexOf(this.props.product.apiStatus) > -1 && (
          <MessageBadRequest pageType={pageType.productDetail} apiStatus={product.apiStatus} />
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail);
