import React from 'react';
import PropTypes from 'prop-types';
import { TOP_CONTENT_ANCHOR } from 'constants/scrollToElement/scrollToElement';
import CmsFooter from 'containers/CmsFooter/CmsFooter';
import ErrorBoundary from 'containers/ErrorBoundary/ErrorBoundary';
import CheckoutHeader from 'components/organisms/CheckoutHeader/CheckoutHeader';
import CheckoutFooter from 'components/organisms/CheckoutFooter/CheckoutFooter';
import FeatureTogglingProvider from 'contexts/FeatureTogglingProvider/FeatureTogglingProvider';
import { getNavigationNodes } from 'actions/cmsActions/cmsActions';
import { getCategories } from 'actions/categoryActions/categoryActions';
import { loadFeatureConfig, loadFlashMessages } from 'actions/uiActions/uiActions';
import styles from './CheckoutLayout.scss';
import NextFeatureEnabled from 'routes/ProductsOverview/NextFeatureEnabled';

const CheckoutLayout = ({ children }) => (
  <FeatureTogglingProvider>
    <div id={TOP_CONTENT_ANCHOR}>
      <NextFeatureEnabled
        conditions={[
          {
            featureName: 'nextCheckoutEnabled',
            include: 'checkout',
          },
          {
            featureName: 'nextCheckoutEnabled',
            include: 'payment',
          },
          {
            featureName: 'nextCheckoutEnabled',
            include: 'orderconfirmation',
          },
        ]}
      />
      <CheckoutHeader />
      <ErrorBoundary>
        <div className={styles.container}>{children}</div>
      </ErrorBoundary>
      <CmsFooter>
        <CheckoutFooter />
      </CmsFooter>
    </div>
  </FeatureTogglingProvider>
);

CheckoutLayout.need = [getNavigationNodes, getCategories, loadFeatureConfig, loadFlashMessages];

CheckoutLayout.displayName = 'layouts/CheckoutLayout';
CheckoutLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CheckoutLayout;
