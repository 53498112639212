// @flow
import merge from 'lodash/merge';
import { handleActions } from 'redux-actions';

import {
  GET_CMS_CONTENT_FOR_PAGE_REQUEST,
  GET_CMS_CONTENT_FOR_PAGE_SUCCESS,
  GET_CMS_CONTENT_FOR_PAGE_FAILURE,
  SET_REQUEST_FOR_CMSCONTENT_STATUS_TO_SUCCESS,
  GET_CMS_NAVIGATION_NODES_SUCCESS,
  SET_CURRENT_PAGE_ID,
  ROUTER_UPDATE_LOCATION,
  SET_INITIAL_CMS_STATE,
  GET_WALL_CONTENT_SUCCESS,
  GET_PRODUCT_WALL_SUCCESS,
  GET_CATEGORY_WALL_SUCCESS,
  GET_TIME_CRITICAL_PRODUCT_DATA_SUCCESS,
  GET_BRAND_SUCCESS,
  GET_BRAND_FAILURE,
  GET_USP_ELEMENTS_SUCCESS,
  GET_USP_ELEMENTS_FAILURE,
} from 'constants/ActionTypes/ActionTypes';
import { provideUrls, provideAnchorForUrl } from 'constants/navigation/navigation';
import { getLocale } from 'constants/language/language';
import { NON_CMS_PAGE, HOME_PAGE, MAIN_WALL_ID } from 'constants/cms/cms';
import { BRAND } from 'constants/routePaths/routePaths';
import transform from 'constants/reducerHelper/reducerHelper';
import CmsAPI from 'api/CmsAPI/CmsAPI';
import { TEASER_TYPE } from 'constants/wall/wall';

import type { Cms } from 'reducers/cms/cms';
import { apiStatus } from 'constants/apiStatus/apiStatus';
import { get } from 'lodash/object';
import { replaceUmlauts } from 'constants/helper/replace-umlaut';

export const initialState = {
  brandName: '',
  currentPageId: NON_CMS_PAGE,
  currentPath: '',
  pages: {},
  navigation: {},
  wall: {},
  promoBox: {},
  richMediaContent: {},
};

export const getBrandFromUrl = (pathname: string) => {
  const pathArray = pathname?.split('/');
  const brand = pathArray?.[2] === BRAND ? pathArray?.[3] || '' : '';
  return decodeURIComponent(brand).toUpperCase();
};

export const mapBackendWallTeaserToFrontend = (teaser: any, index: number = 0) => {
  let logo;
  if (teaser?.intro?.logo) {
    logo = {
      src: teaser.intro.logo,
      alt: teaser.intro.logoAltText,
    };
  }

  switch (teaser?.type) {
    case 'CMSImageBrick':
      return {
        type: TEASER_TYPE.IMAGE,
        title: teaser.text || '',
        image: {
          src: teaser.image || '',
          alt: '', // since the image is a background image, the BE does not send an alt tag
        },
        cta: {
          text: teaser.link.title || '',
          href: teaser.link.url || '',
          openNewTab: teaser.externalLink || false,
        },
        intro: teaser.intro.text,
        logo,
        position: index + 1,
        brandPattern: teaser.brandPattern || false,
        color: teaser.bgColor,
      };
    case 'CMSCardBrick':
      return {
        bgColor: teaser.bgColor || '',
        imageAltText: teaser.imageAltText || '',
        intro: {
          logo: teaser.intro?.logo,
          logoAltText: teaser.intro?.logoAltText,
          text: teaser.intro?.text,
        },
        link: teaser.link || '',
        picture: {
          // Only get first image because BE will send only one for all screen sizes
          sizes: teaser.picture.sizes[0] ? [{ url: teaser.picture.sizes[0].url }] : [],
        },
        subtitle: teaser.subtitle || '',
        text: teaser.text || '',
        title: teaser.title || '',
        type: TEASER_TYPE.CARD,
      };
    default:
      return null; // OMG UNKNOWN TEASER TYPE
    // @TODO this crashed the wall
  }
};

export const mapBackendBlogPostsToFrontend = (post: any) => ({
  link: {
    href: post.link,
    title: post.linkTitle,
    external: post.externalLink,
  },
  image: post.image,
  imageAltText: post.imageAltText,
  title: post.introText,
  text: post.text,
});

const getCurrentPage = (state: any) => get(state?.cms?.pages, state?.cms?.currentPageId);

export const CmsSelectors = { getCurrentPage };

export default handleActions(
  {
    [GET_CMS_CONTENT_FOR_PAGE_REQUEST]: (state: Cms): Cms =>
      transform(state).set('requestForCmsContentStatus', 'REQUEST').value(),

    [GET_CMS_CONTENT_FOR_PAGE_SUCCESS]: (state: Cms, action): Cms => {
      const pageData = action.req?.data;
      const newPageId = action.payload?.pageId;

      return transform(state)
        .set('requestForCmsContentStatus', 'SUCCESS')
        .set(`pages.${newPageId}`, pageData)
        .set('currentPageId', newPageId)
        .value();
    },

    [GET_CMS_CONTENT_FOR_PAGE_FAILURE]: (state: Cms) =>
      transform(state).set('requestForCmsContentStatus', 'FAILURE').value(),

    [SET_REQUEST_FOR_CMSCONTENT_STATUS_TO_SUCCESS]: (state: Cms): Cms =>
      transform(state).set('requestForCmsContentStatus', 'SUCCESS').value(),

    [GET_CMS_NAVIGATION_NODES_SUCCESS]: (state: Cms, action): Cms => {
      const data = action.req?.data;
      if (!data) return state;

      const processedUrls = provideUrls(data);

      let currentPageId = processedUrls?.[state.currentPath]?.pageId;
      if (!currentPageId) {
        const locale = getLocale();
        if ([`/${locale}/`, `/${locale}`].includes(state.currentPath)) {
          currentPageId = HOME_PAGE;
        } else {
          currentPageId = NON_CMS_PAGE;
        }
      }

      return {
        ...state,
        navigation: {
          pages: data,
        },
        currentPageId,
        // anchor (or parent) is used to determine the navigation
        // see navigation.provideLink / navigation.provideLinks
        anchor: provideAnchorForUrl(processedUrls, state.currentPath),
      };
    },

    [SET_CURRENT_PAGE_ID]: (state: Cms, action): Cms =>
      transform(state).set('currentPageId', action.payload?.pageId).value(),

    [GET_WALL_CONTENT_SUCCESS]: (state: Cms, action): Cms => {
      const data = action.req?.data;
      if (!data) return state;

      const wallId = action?.payload?.wallId || MAIN_WALL_ID;
      const bricks = data.bricks || [];

      const callToAction = data.callToAction || {};

      let validUntil = data.validUntil;
      const now = new Date();
      if (new Date(validUntil) < now) {
        // in the past?
        const updateInMilliseconds = 5 * 60 * 1000;
        const newUpdateTime = new Date(now.getTime() + updateInMilliseconds);
        validUntil = newUpdateTime.toISOString();
      }

      return transform(state)
        .set(`wall.${wallId}`, {
          content: bricks.map(mapBackendWallTeaserToFrontend),
          callToAction,
          validUntil,
        })
        .value();
    },

    [GET_PRODUCT_WALL_SUCCESS]: (state: Cms, action): Cms => {
      const productCode = action.payload?.productCode;
      const cmsData = action.req?.data || {};

      if (!productCode) return state;

      const newState = transform(state);

      // handle product wall
      const bricks = cmsData?.wall?.bricks;
      if (bricks) {
        newState.set(`wall.${productCode}`, {
          content: bricks.map(mapBackendWallTeaserToFrontend),
        });
      }

      // handle blog posts
      const blogTitle = cmsData?.blog?.title;
      const blogPosts = cmsData?.blog?.bricks || [];

      if (blogTitle && blogPosts.length > 0) {
        newState.set(`blogs.${productCode}`, {
          title: blogTitle,
          posts: blogPosts.map(mapBackendBlogPostsToFrontend),
        });
      }

      const promoBox = cmsData?.promoBox || {};
      if (Object.keys(promoBox).length) {
        newState.set(`promoBox.${productCode}`, promoBox);
      }

      const richMediaContent = cmsData?.richMediaContent || {};
      if (Object.keys(richMediaContent).length) {
        newState.set(`richMediaContent.${productCode}`, richMediaContent);
      }

      return newState.value();
    },

    [GET_CATEGORY_WALL_SUCCESS]: (state: Cms, action): Cms => {
      const categoryCode = action.payload?.categoryCode;
      const bricks = action.req?.data?.bricks;

      if (!categoryCode || !bricks) return state;

      return transform(state)
        .set(`wallMini.ws${categoryCode}`, {
          content: bricks.map(mapBackendWallTeaserToFrontend),
        })
        .value();
    },

    // provideInitialStateAction -> dispatched in entry.server
    [SET_INITIAL_CMS_STATE]: (state: Cms, action): Cms => {
      // this "api" function does not actually call the API, the data has already been called in the CmsApi.init
      // we just receive the data synchronously from the CmsAPI closure
      const cmsData = CmsAPI.provideInitialState();
      const newState = {
        ...state,
        ...cmsData,
      };

      let currentPath = decodeURI(action.pathname || '/');
      currentPath = currentPath.split('?')[0];
      const brandName = getBrandFromUrl(action.pathname);
      const pages = cmsData?.navigation?.pages || {};
      const processedUrls = provideUrls(pages);

      return transform(newState)
        .set('currentPath', currentPath)
        .set('navigation', {
          pages,
        })
        .set('currentPageId', processedUrls?.[currentPath]?.pageId)
        .set('anchor', provideAnchorForUrl(processedUrls, currentPath))
        .set('brandName', brandName)
        .value();
    },

    [ROUTER_UPDATE_LOCATION]: (state: Cms, action): Cms => {
      const locale = getLocale();
      let pathname = decodeURI(action.payload?.pathname || '');
      pathname = replaceUmlauts(pathname.split('?')[0]);
      const brandName = getBrandFromUrl(action.payload?.pathname || '');
      const processedUrls = provideUrls(state?.navigation?.pages || {});
      if (!pathname) return state;

      const newPageId = processedUrls?.[pathname]?.pageId;

      const newState = transform(state);

      if (newPageId && newPageId !== state.currentPageId) {
        newState.set('anchor', provideAnchorForUrl(processedUrls, pathname)).set('currentPageId', newPageId);
      } else if ([`/${locale}/`, `/${locale}`].includes(pathname)) {
        newState.set('currentPageId', HOME_PAGE);
      } else if (!newPageId) {
        newState.set('currentPageId', NON_CMS_PAGE);
      }

      if (!brandName) {
        newState.set('requestForCmsContentStatus', apiStatus.success);
      }

      return newState.set('currentPath', pathname).set('brandName', brandName).value();
    },

    [GET_TIME_CRITICAL_PRODUCT_DATA_SUCCESS]: (state: Cms, action): Cms => {
      const code = action.payload?.productCodes?.[0];

      if (!code || !action.payload?.isWall) {
        return state;
      }
      const newState = transform(state);

      const bricks = state?.wall?.MAIN_WALL_ID?.content || [];
      const happyDayBrickIndex = bricks.findIndex((brick) => brick.productCode === code);
      const happyDayBrick = bricks.find((brick) => brick.productCode === code);
      const data = action.req?.data?.[0];

      const mergeObject = {
        stockMax: data?.stockMax || 0,
        stockCurrent: data?.stockCurrent || 0,
        endTime: +new Date(data?.endTime || 0),
      };

      newState.set(`wall.MAIN_WALL_ID.content[${happyDayBrickIndex}]`, { ...merge(happyDayBrick, mergeObject) });

      return newState.value();
    },

    [GET_BRAND_SUCCESS]: (state) => {
      const newState = transform(state);
      newState.set('requestForBrandValidity', apiStatus.success);
      return newState.value();
    },

    [GET_BRAND_FAILURE]: (state) => {
      const newState = transform(state);
      newState.set('requestForBrandValidity', apiStatus.failure);
      return newState.value();
    },

    [GET_USP_ELEMENTS_SUCCESS]: (state, action = {}) => {
      const uspElements = action.req?.data?.elements;
      const uspTitle = action.req?.data?.title;
      const newState = transform(state);
      newState.set('uspElements', uspElements);
      newState.set('uspTitle', uspTitle);
      return newState.value();
    },

    [GET_USP_ELEMENTS_FAILURE]: (state) => {
      const newState = transform(state);
      newState.set('uspElements', []);
      newState.set('uspTitle', '');
      return newState.value();
    },
  },
  initialState
);
