import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import language from 'constants/language/language';
import formatDate from 'constants/formatDate/formatDate';
import styles from './StoreFinderListItem.scss';
import { availabilityStates } from 'constants/deliveryStates/availabilityStates';
import Icon, { ICON_STORE_OUTLINE } from 'components/atoms/Icon/Icon';

const StoreFinderQuantityText = ({ quantity = 0, availability = '', releaseDate = '' }) => {
  return (
    <div className={styles.availableQuantity}>
      {quantity >= 1 && (
        <Icon
          path={ICON_STORE_OUTLINE}
          size={Icon.sizes.sm}
          className={quantity > 1 ? cx(styles.dlvIconCh, styles['green']) : cx(styles.dlvIconCh, styles['grey'])}
        />
      )}

      <p className={styles.quantityText}>
        {quantity <= 0 ? (
          language('storeFinder.quantity.none')
        ) : (
          <>
            {[availabilityStates.R, availabilityStates.Y].includes(availability) &&
              `${language('deliveryStates.detail.asOfDate')} ${formatDate(releaseDate)} `}
            {quantity >= 20
              ? language('storeFinder.quantity.moreThan')
              : `${quantity} ${language('storeFinder.quantity.available')}`}
          </>
        )}
      </p>
    </div>
  );
};

StoreFinderQuantityText.displayName = 'molecules/StoreFinderListItem/StoreFinderQuantityText';

StoreFinderQuantityText.propTypes = {
  quantity: PropTypes.number,
  availability: PropTypes.oneOf(Object.values(availabilityStates)),
  releaseDate: PropTypes.any,
};

export default StoreFinderQuantityText;
