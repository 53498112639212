import React from 'react';
import PropTypes from 'prop-types';
import Inventory from 'containers/Inventory/Inventory';
import { deliverySkins } from 'components/molecules/DeliveryState/DeliveryState';
import { Col, Row } from 'components/atoms/Grid/Grid';
import Headline from 'components/atoms/Headline/Headline';
import B2BQuoteRequest from 'components/molecules/B2BQuoteRequest/B2BQuoteRequest';
import DeliveryStateDetail from 'components/molecules/DeliveryStateDetail/DeliveryStateDetail';
import language from 'constants/language/language';
import styles from './ProductInfoAdditional.scss';
import HorizontalRule from 'components/atoms/HorizontalRule/HorizontalRule';

const ProductInfoAdditional = ({ product }) => {
  return (
    <Row>
      <Col>
        <HorizontalRule />
      </Col>
      <Col>
        <Headline tag={Headline.tags.H2}>{language('product.expectedDelivery')}</Headline>
        <div className={styles.infoContainerCentered}>
          <Inventory productId={product.code} productImages={product.customImageData}>
            <DeliveryStateDetail
              skin={deliverySkins.DETAIL}
              showSpeedFlag={product.speedProduct}
              deliveryDate={product.deliveryTime}
              pickupDate={product.pickupTime}
              releaseDate={product.releaseDate}
              neutralDeliveryPossible={product?.neutralDeliveryPossible ?? false}
              availability={product.availability}
              productId={product.displayedCode}
            />
          </Inventory>
        </div>
      </Col>
      <Col>
        <HorizontalRule />
      </Col>
      <B2BQuoteRequest />
    </Row>
  );
};

ProductInfoAdditional.propTypes = {
  product: PropTypes.object,
};

ProductInfoAdditional.displayName = 'organisms/ProductInfoAdditional';

export default ProductInfoAdditional;
