import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'components/atoms/Grid/Grid';
import InputText from 'components/atoms/InputText/InputText';
import FieldSetInput from 'components/molecules/FieldSetInput/FieldSetInput';
import ContactField from 'containers/ContactField/ContactField';
import { noop } from 'constants/noop/noop';

const ContactDetailsPartial = ({ telNumberValidateChar = noop }) => (
  <Row>
    <Col sm={12} md={8}>
      <ContactField fieldName="contactEmail">
        <FieldSetInput>
          <InputText type="email" />
        </FieldSetInput>
      </ContactField>
    </Col>
    <Col sm={12} md={8}>
      <ContactField fieldName="phone">
        <FieldSetInput>
          <InputText type="tel" onKeyPress={telNumberValidateChar} />
        </FieldSetInput>
      </ContactField>
    </Col>
  </Row>
);

ContactDetailsPartial.propTypes = {
  telNumberValidateChar: PropTypes.func,
};

export default ContactDetailsPartial;
