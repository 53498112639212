import { handleActions } from 'redux-actions';
import merge from 'lodash/merge';

import contactBase, { initialState } from './contact.base';

import validation from 'constants/validation/validation';
import formFieldConfigurations from 'reducers/contact/contact.config';

import {
  REINITIALIZE_CONTACT_FORM,
  UPDATE_CONTACT_FIELD,
  CHANGE_CONTACT_SUBJECT,
  GET_CONTACT_SUBJECTS_SUCCESS,
  ROUTER_UPDATE_LOCATION,
} from 'constants/ActionTypes/ActionTypes';
import transform from 'constants/reducerHelper/reducerHelper';

import { contactSubjects, getFieldData } from 'constants/contact/contact.helper';

const contact = {
  [ROUTER_UPDATE_LOCATION]: (state) => transform(state).set('sent', false).value(),

  [REINITIALIZE_CONTACT_FORM]: (state) => {
    if (!state.sent) return state;

    return transform(state)
      .set('fields.contactSubjects.options', state?.fields?.contactSubjects?.options || contactSubjects)
      .set('sent', false)
      .value();
  },

  [GET_CONTACT_SUBJECTS_SUCCESS]: (state) =>
    transform(state).set('fields.contactSubjects.options', contactSubjects).set('hasFetchedSubjects', true).value(),

  [UPDATE_CONTACT_FIELD]: (state, action) => {
    const fieldName = action.payload?.fieldName;
    const value = action.payload?.value || '';
    if (!fieldName) return state;

    const isRequired = formFieldConfigurations?.[fieldName]?.required ?? false;
    const validationFunction = formFieldConfigurations?.[fieldName]?.validation ?? false;

    let validationResult = true;
    if (isRequired) {
      validationResult = validation.required(value);
    }
    if (typeof validationFunction === 'function') {
      validationResult = validationFunction(value);
    }

    return transform(state)
      .set(`fields.${fieldName}.value`, value)
      .set(`fields.${fieldName}.validationResult`, validationResult)
      .omit('fields.firstError')
      .value();
  },

  [CHANGE_CONTACT_SUBJECT]: (state, action) => {
    const fields = {
      contactSubjects: {
        value: action.payload?.subject || '',
      },
      salutation: getFieldData(action, 'salutation'),
      firstName: getFieldData(action, 'firstName'),
      lastName: getFieldData(action, 'lastName'),
      addressLine1: getFieldData(action, 'addressLine1'),
      addressLine2: getFieldData(action, 'addressLine2'),
      postalCode: getFieldData(action, 'postalCode'),
      town: getFieldData(action, 'town'),
      country: {
        ...getFieldData(action, 'country'),
        value: 'CH',
      },
      company: getFieldData(action, 'company'),
      contactEmail: getFieldData(action, 'contactEmail'),
      phone: getFieldData(action, 'phone'),
      articleNumber: getFieldData(action, 'articleNumber'),
      contactTextarea: getFieldData(action, 'contactTextarea'),
      orderNumber: getFieldData(action, 'orderNumber'),
      superpoints_receiptNumber: getFieldData(action, 'superpoints_receiptNumber'),
      superpoints_dateOfPurchase: getFieldData(action, 'superpoints_dateOfPurchase'),
      superpoints_store: getFieldData(action, 'superpoints_store'),
      superpoints_supercardCode: getFieldData(action, 'superpoints_supercardCode'),
      repair_feedback_repairNumber: getFieldData(action, 'repair_feedback_repairNumber'),
      repair_feedback_customerNumber: getFieldData(action, 'repair_feedback_customerNumber'),
      repair_feedback_contractNumber: getFieldData(action, 'repair_feedback_contractNumber'),
      repair_feedback_invoiceNumber: getFieldData(action, 'repair_feedback_invoiceNumber'),
      repair_feedback_articleNumber: getFieldData(action, 'repair_feedback_articleNumber'),
      brand: getFieldData(action, 'brand'),
      modelType: getFieldData(action, 'modelType'),
      purchasePrice: getFieldData(action, 'purchasePrice'),
      address_salutation: getFieldData(action, 'address_salutation'),
      address_firstName: getFieldData(action, 'address_firstName'),
      address_lastName: getFieldData(action, 'address_lastName'),
      address_addressLine1: getFieldData(action, 'address_addressLine1'),
      address_addressLine2: getFieldData(action, 'address_addressLine2'),
      address_postalCode: getFieldData(action, 'address_postalCode'),
      address_country: {
        ...getFieldData(action, 'address_country'),
        value: 'CH',
      },
      recaptcha: {
        ...getFieldData(action, 'recaptcha'),
        value: state.fields?.recaptcha?.value,
      },
      address_town: getFieldData(action, 'address_town'),
      copyWanted: {
        value: action.payload?.copyWanted ?? false,
      },
    };

    return transform(state).set('fields', fields).value();
  },
};

const mergedReducers = merge(contact, contactBase);

export default handleActions(mergedReducers, initialState);
