import mappingHelper from './AccountMapping.config';
import validation, { ValidationTypes } from 'constants/validation/validation';

export const mapAccountToAccountData = (props) => mappingHelper(props);

// this is basically a part of the initial state in the user reducer
export const mapAccountDataToAccount = ({
  titleCode,
  group,
  firstName,
  lastName,
  birthday,
  language,
  supercardCode,
  employeeEan,
  uid,
  onlineId,
}) => ({
  fields: {
    titleCode: {
      name: 'titleCode',
      value: titleCode,
      validationResult: titleCode !== undefined ? ValidationTypes.Valid : ValidationTypes.Missing,
    },
    group: {
      name: 'group',
      value: group,
      validationResult: group !== undefined ? ValidationTypes.Valid : ValidationTypes.Missing,
    },
    firstName: {
      name: 'firstName',
      value: firstName,
      validationResult: firstName !== undefined ? ValidationTypes.Valid : ValidationTypes.Missing,
    },
    lastName: {
      name: 'lastName',
      value: lastName,
      validationResult: lastName !== undefined ? ValidationTypes.Valid : ValidationTypes.Missing,
    },
    birthday: {
      name: 'birthday',
      value: birthday,
      validationResult: validation.birthday(birthday),
    },
    language: {
      name: 'language',
      value: language.isocode,
      validationResult: language.isocode !== undefined ? ValidationTypes.Valid : ValidationTypes.Missing,
    },
    supercardCode: {
      name: 'supercardCode',
      value: supercardCode,
      validationResult: ValidationTypes.Valid,
    },
    employeeEan: {
      name: 'employeeEan',
      value: employeeEan,
      validationResult: ValidationTypes.Valid,
    },
    email: {
      name: 'email',
      value: uid.toLowerCase(),
      validationResult: uid !== '' ? ValidationTypes.Valid : ValidationTypes.MissingEmail,
    },
    onlineId: {
      name: 'onlineId',
      value: onlineId,
      validationResult: onlineId !== '' ? ValidationTypes.Valid : ValidationTypes.Missing,
    },
    password: {
      name: 'password',
      value: '',
      validationResult: ValidationTypes.Missing,
    },
    oldPassword: {
      name: 'oldPassword',
      value: '',
      validationResult: ValidationTypes.Missing,
    },
    newPassword: {
      name: 'newPassword',
      value: '',
      validationResult: ValidationTypes.Missing,
    },
  },
});

export default {
  mapAccountToAccountData,
  mapAccountDataToAccount,
};
