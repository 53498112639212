import React from 'react';
import PropTypes from 'prop-types';
import CmsNavigationProvider from 'containers/CmsNavigationProvider/CmsNavigationProvider';
import LanguageSelectList from './LanguageSelectList';

const LanguageSelect = ({ useLongValue }) => (
  <CmsNavigationProvider>
    <LanguageSelectList useLongValue={useLongValue} />
  </CmsNavigationProvider>
);

LanguageSelect.displayName = 'molecules/LanguageSelect';

LanguageSelect.propTypes = {
  useLongValue: PropTypes.bool,
};

LanguageSelect.defaultProps = {
  useLongValue: false,
};

export default LanguageSelect;
