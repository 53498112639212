/**
 * Define multiple API calls which won't affect the b2b pricing
 */
const EXCLUDED_CALLS = [
  'cms/navigation',
  'cms/content/Service',
  '/deliverymode',
  '/paymentmodes',
  '/addresses',
  '/contact',
];

/**
 * Adds the b2b price group as a parameter to the path if one exists and
 * the path isn't excluded from this logic.
 * @param path - rest resource
 * @param b2bGroup - B2B Customer group
 * @return {String} edited path (may be with new b2b parameter)
 */
export const getPricingParameter = (path, b2bGroup) => {
  if (b2bGroup) {
    // skip excluded endpoints
    const matches = EXCLUDED_CALLS.filter((excludedPath) => path.includes(excludedPath));
    if (matches.length === 0) {
      if (path.includes('?')) {
        return `${path}&bpp=${b2bGroup}`;
      }
      return `${path}?bpp=${b2bGroup}`;
    }
  }
  return path;
};
