import React, { cloneElement } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import language from 'constants/language/language';
import resolveErrorMessage from 'constants/resolveErrorMessage/resolveErrorMessage';
import { ValidationTypes, passwordComplexity } from 'constants/validation/validation';
import ErrorMessageInput from 'components/molecules/FieldSetInput/ErrorMessageInput';
import bootstrap from 'scss/component.scss';
import styles from './PasswordCreation.scss';

export const PasswordCreation = ({
  name = '',
  validationResult = '',
  children,
  value = '',
  showError = false,
  focus = false,
  onBlur = () => {},
  onFocus = () => {},
}) => {
  const getProgressBarClass = (result) => {
    // add helper class text(anything) for IE, because IE doesn't render the helper class progress(anything) correctly
    switch (result) {
      case ValidationTypes.PasswordToWeak:
        return cx(bootstrap.bgDanger, bootstrap.textDanger);
      case ValidationTypes.PasswordGood:
        return cx(bootstrap.bgWarning, bootstrap.textWarning);
      case ValidationTypes.PasswordVeryGood:
        return cx(bootstrap.bgSuccess, bootstrap.textSuccess);
      default:
        return '';
    }
  };

  const progressBarClass = getProgressBarClass(validationResult);
  const progressWidth = {
    width: `${passwordComplexity(value)}%`,
  };

  let validationMessage;
  // if the validation type is missing don't display error message except showError is true
  if (validationResult !== ValidationTypes.Missing && validationResult !== ValidationTypes.SameAsEmail) {
    validationMessage = resolveErrorMessage(validationResult, name);
  }

  if (validationResult === ValidationTypes.SameAsEmail) {
    showError = true;
  }

  const disableErrorMessage =
    (validationResult !== ValidationTypes.Missing && validationResult !== ValidationTypes.SameAsEmail) || focus;

  const adviceTextClasses = cx({
    [styles.passwordDescription]: true,
    [styles.error]: showError,
  });
  return (
    <div>
      {cloneElement(children, {
        type: 'password',
        showError,
        onFocus,
        onBlur,
      })}
      <ErrorMessageInput disableMessage={disableErrorMessage} showError={showError}>
        {resolveErrorMessage(validationResult, name)}
      </ErrorMessageInput>
      <div className={styles.passwordStrength}>
        {`${language('form.fields.password.passwordStrength')} ${validationMessage || ''}`}
      </div>
      <div className={cx(bootstrap.progress, styles.progress)}>
        <div
          className={progressBarClass}
          style={progressWidth}
          aria-valuenow={passwordComplexity(value)}
          aria-valuemin="0"
          aria-valuemax="100"
        />
      </div>
      <small className={`${showError ? 'error-message' : ''} ${adviceTextClasses}`}>
        {language('form.fields.password.adviceText')}
      </small>
    </div>
  );
};

PasswordCreation.displayName = 'molecules/PasswordCreation';

PasswordCreation.propTypes = {
  name: PropTypes.string,
  validationResult: PropTypes.string,
  children: PropTypes.element.isRequired,
  value: PropTypes.string,
  showError: PropTypes.bool,
  focus: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
};

export default PasswordCreation;
