import React, { cloneElement, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FlyInList from 'components/molecules/FlyInList/FlyInList';
import { removeFlashmessage } from 'actions/uiActions/uiActions';
import { DESKTOP_NAVIGATON } from 'constants/scrollToElement/scrollToElement';
import debounce from 'constants/helper/debounce';

const mapStateToProps = (state) => ({
  messages: state.ui?.flyIns,
});

const mapDispatchToProps = {
  removeFlashMessage: removeFlashmessage,
};

const FlyInContainer = ({ messages = [], removeFlashMessage = () => {}, cyData }) => {
  const [topMargin, setTopMargin] = useState(0);

  const handleScroll = debounce(() => {
    const desktopNavigation = document.getElementById(DESKTOP_NAVIGATON);
    const navigationRect = desktopNavigation?.getBoundingClientRect();
    const { height, top } = navigationRect || {};

    if (!Number.isNaN(height) && !Number.isNaN(top)) setTopMargin(height + top);
  }, 100);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });
  return cloneElement(<FlyInList />, { messages, removeFlashMessage, topMargin, cyData });
};

FlyInContainer.propTypes = {
  messages: PropTypes.array,
  removeFlashMessage: PropTypes.func,
  cyData: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(FlyInContainer);
