export const PLATFORM =  {
  UNDETECTED:  "undetected",
  IOS:  "ios",
  ANDROID:  "android",
}

export function usePlatform() {
  if (typeof window === 'undefined') {
    return PLATFORM.UNDETECTED;
  }
  const userAgent = window.navigator.userAgent.toLowerCase();
  if (userAgent.includes('android')) {
    return PLATFORM.ANDROID;
  } else if (userAgent.includes('iphone') || userAgent.includes('ipad') || userAgent.includes('ipod')) {
    return PLATFORM.IOS;
  } else {
    return PLATFORM.UNDETECTED;
  }
}
