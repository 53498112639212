/**
 * Created by michaels on 08.07.16.
 */
import { handleActions } from 'redux-actions';
import {
  LOAD_ARCHIVED_ORDER_SUCCESS,
  LOAD_ARCHIVED_ORDERS_SUCCESS,
  LOAD_MYORDERS_SUCCESS,
  LOAD_ORDER_SUCCESS,
  PLACE_ORDER_FAILURE,
  PLACE_ORDER_NETWORKERROR,
  PLACE_ORDER_REQUEST,
  PLACE_ORDER_SUCCESS,
  RESET_ORDER_API_STATUS,
  RESET_USER,
  START_PLACE_ORDER,
} from 'constants/ActionTypes/ActionTypes';
import { apiStatus } from 'constants/apiStatus/apiStatus';

export const initialState = {
  lastOrder: {
    code: undefined,
    guestCustomer: true,
  },
  myOrders: [],
  myOrdersPagination: {},
};

export default handleActions(
  {
    [PLACE_ORDER_SUCCESS]: (state, action) => {
      const newState = {
        ...state,
        lastOrder: {
          code: action.req.data.code,
          guestCustomer: action.req.data.guestCustomer,
          products: action.payload?.products,
        },
        apiStatus: apiStatus.success,
      };
      return newState;
    },

    [PLACE_ORDER_FAILURE]: (state) => {
      const newState = {
        ...state,
        apiStatus: apiStatus.failure,
      };
      return newState;
    },

    [PLACE_ORDER_NETWORKERROR]: (state) => {
      const newState = {
        ...state,
        apiStatus: apiStatus.networkerror,
      };
      return newState;
    },

    [PLACE_ORDER_REQUEST]: (state) => {
      const newState = {
        ...state,
        apiStatus: apiStatus.request,
      };
      return newState;
    },

    [LOAD_MYORDERS_SUCCESS]: (state, action) => {
      const newState = {
        ...state,
        myOrders: action.req.data.orders,
        myOrdersPagination: action.req.data.pagination,
      };
      return newState;
    },

    [LOAD_ARCHIVED_ORDERS_SUCCESS]: (state, action) => {
      const newState = {
        ...state,
        archivedOrders: action.req.data.orders,
        archivedOrdersPagination: action.req.data.pagination,
      };
      return newState;
    },

    [LOAD_ORDER_SUCCESS]: (state, action) => {
      const newState = {
        ...state,
        [action.req.data.guid]: action.req.data,
      };
      return newState;
    },

    [LOAD_ARCHIVED_ORDER_SUCCESS]: (state, action) => {
      const newState = {
        ...state,
        [action.req.data.guid]: { ...action.req.data, archived: true },
      };
      return newState;
    },

    [RESET_ORDER_API_STATUS]: (state) => {
      const newState = {
        ...state,
        apiStatus: apiStatus.success,
      };
      return newState;
    },

    [RESET_USER]: () => initialState,

    [START_PLACE_ORDER]: (state) => ({
      ...state,
      apiStatus: apiStatus.request,
    }),
  },
  initialState
);
