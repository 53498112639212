import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import cx from 'classnames';

import LogoBrand from 'components/atoms/LogoBrand/LogoBrand';
import { getEnv } from 'config/config';

import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import { getLocale } from 'constants/language/language';
import { getStringCategoriesName, getCategoryUrl } from 'constants/categoryTree/categoryTree';

import styles from './BrandCategoryLink.scss';

const BrandCategoryLink = ({
  brandLogoUrl = '',
  manufacturer = '',
  brandName = '',
  brandPageUrl = '',
  category = '',
  categories = {},
}) => {
  const getBrandPageLink = () => {
    if (brandPageUrl) {
      return mapPathToLocalizedUrl(getLocale(), [brandPageUrl], false);
    }
    return mapPathToLocalizedUrl(getLocale(), ['brand', brandName.toLowerCase()], false);
  };

  const getCategoryLink = () =>
    `${getCategoryUrl(category, getLocale(), categories)}?brand=${encodeURIComponent(manufacturer)}`;

  const renderBrandLink = () => (
    <small>
      <Link to={getBrandPageLink()}>{manufacturer}</Link>
    </small>
  );

  const renderCategoryLink = () => {
    const categoryName = getStringCategoriesName(category, getLocale(), categories);
    if (categoryName) {
      return (
        <small>
          <Link to={getCategoryLink()}>{categoryName}</Link>
        </small>
      );
    }
    return '';
  };

  return (
    <div className={styles.brandLinkContainer}>
      {brandLogoUrl && (
        <div className={styles.logoWrapper}>
          <LogoBrand path={`${getEnv('web')}${brandLogoUrl}`} altText={manufacturer} />
        </div>
      )}
      <div className={cx(styles.linkContainer, { [styles.noLogoSpacer]: !brandLogoUrl })}>
        {renderBrandLink()}
        <span>|</span>
        {renderCategoryLink()}
      </div>
    </div>
  );
};

BrandCategoryLink.displayName = 'molecules/BrandCategoryLink';

BrandCategoryLink.propTypes = {
  brandLogoUrl: PropTypes.string,
  manufacturer: PropTypes.string,
  brandName: PropTypes.string,
  brandPageUrl: PropTypes.string,
  category: PropTypes.string,
  categories: PropTypes.object,
};

export default BrandCategoryLink;
