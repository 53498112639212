import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { CMS_UID_SUPERCARD } from 'constants/cms/cms';
import language, { getLocale } from 'constants/language/language';
import { account, accountRegistration } from 'constants/routePaths/routePathCombinations';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import LoginContainer from 'containers/Login/Login';
import CmsLink from 'containers/CmsLink/CmsLink';
import Breadcrumb, { types } from 'components/organisms/Breadcrumb/Breadcrumb';
import Login from 'components/organisms/Login/Login';
import LoginAlternative from 'components/organisms/LoginAlternative/LoginAlternative';
import IconTextCombi from 'components/molecules/IconTextCombi/IconTextCombi';
import LoginNotificationProvider from 'components/molecules/LoginNotificationProvider/LoginNotificationProvider';
import { Row, Col } from 'components/atoms/Grid/Grid';
import Headline from 'components/atoms/Headline/Headline';
import Pagetitle from 'components/atoms/Pagetitle/Pagetitle';
import HorizontalRule from 'components/atoms/HorizontalRule/HorizontalRule';
import CmsLinkItem from 'components/atoms/CmsLinkItem/CmsLinkItem';
import Icon, { ICON_SUPERCARD } from 'components/atoms/Icon/Icon';
import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';
import { CART_ID, WATCHLIST_ID } from 'constants/storage/storageKeys';
import useFeatureToggle from 'hooks/useFeatureToggle';

import styles from './AccountLogin.scss';
import { previews } from 'previewFeatures';

const AccountLogin = ({
  buttonText = 'login.registration.button.text',
  description = 'login.registration.content.text2',
  linkTo = accountRegistration,
  pageTitle = 'login.accountHeader',
  questionText = 'login.registration.content.text1',
  redirectTo = account,
  registrationHeadline = 'login.registration.headline',
  showBenefits = true,
}) => {
  let loginHref = `/supercard/api/login?anonymousCartGuid=${localStorage?.getItem(
    CART_ID
  )}&anonymousWatchlist=${localStorage?.getItem(WATCHLIST_ID)}&locale=${getLocale()}`;

  let supercardEnabled = useFeatureToggle('supercardLogin') === 'true' ?? false;
  if (!supercardEnabled) supercardEnabled = previews.supercard.status;

  return (
    <>
      <Breadcrumb type={types.invisible} />
      <div className={cx(styles.accountHeader)}>
        <Pagetitle>{language(pageTitle)}</Pagetitle>
      </div>

      {/* preview enabled (*/}
      {__CLIENT__ && supercardEnabled && (
        <div>
          <Row className={styles.loginContainer}>
            <Col md={6}>
              <div className={cx(styles.loginWrapper, styles.loginWrapperLeft)}>
                <div className={styles.supercardButton}>
                  <ButtonLink color={ButtonLink.colors.supercard} customTarget={'_self'} href={loginHref} stretched>
                    <Icon path={ICON_SUPERCARD} className={styles.supercardIcon} size={Icon.sizes.md} />
                    {language('login.supercardLogin')}
                  </ButtonLink>
                </div>
                <div className={styles.hintLink}>
                  <CmsLink pageId={CMS_UID_SUPERCARD}>
                    <CmsLinkItem customText={language('login.loginExplanation')} className={styles.hintLink} />
                  </CmsLink>
                </div>
              </div>
            </Col>
            <Col md={6} />
          </Row>
          <HorizontalRule text={language('login.dividerText')} />
        </div>
      )}

      <LoginContainer>
        <LoginNotificationProvider />
      </LoginContainer>
      <Row className={styles.loginContainer}>
        <Col md={6} className={styles.loginLeft}>
          <div className={cx(styles.loginWrapper, styles.loginWrapperLeft)}>
            <Headline margin={Headline.margins.MB_15}>{language('login.headline')}</Headline>
            <LoginContainer redirectTo={mapPathToLocalizedUrl(getLocale(), redirectTo)}>
              <Login />
            </LoginContainer>
          </div>
        </Col>

        <Col md={6} className={styles.loginRight}>
          <div className={cx(styles.loginWrapper, styles.loginWrapperRight)}>
            <Headline margin={Headline.margins.NONE}>{language(registrationHeadline)}</Headline>
            <LoginAlternative
              linkTo={mapPathToLocalizedUrl(getLocale(), linkTo)}
              questionText={language(questionText)}
              description={language(description)}
              buttonText={language(buttonText)}
            />
          </div>
        </Col>
      </Row>
      {showBenefits && (
        <>
          <div className={styles.benefits}>
            <Headline alignment={Headline.alignments.CENTER}>{language('login.benefitsText')}</Headline>
          </div>
          <IconTextCombi />
        </>
      )}
    </>
  );
};

AccountLogin.displayName = 'templates/AccountLogin';
AccountLogin.propTypes = {
  buttonText: PropTypes.string,
  description: PropTypes.string,
  linkTo: PropTypes.arrayOf(PropTypes.string),
  pageTitle: PropTypes.string,
  questionText: PropTypes.string,
  redirectTo: PropTypes.arrayOf(PropTypes.string),
  registrationHeadline: PropTypes.string,
  showBenefits: PropTypes.bool,
};

export default AccountLogin;
