import React from 'react';
import PropTypes from 'prop-types';
import Accessories from 'containers/Accessories/Accessories';
import WallContainer from 'containers/WallSingle/WallSingle';
import ProductBlogContainer from 'containers/ProductBlog/ProductBlog';
import ProductAnchorContainer from 'containers/ProductAnchor/ProductAnchor';
import RecommendationsBox from 'containers/RecommendationsBox/RecommendationsBox';
import TopProductsContainer from 'containers/TopProductsContainer/TopProductsContainer';
import FeedbackFormContainer from 'containers/FeedbackFormContainer/FeedbackFormContainer';
import RatingDetailsContainer from 'containers/RatingDetailsContainer/RatingDetailsContainer';
import AddedToCartModal from 'containers/AddedToCartModal/AddedToCartModal';
import FlashMessage from 'components/atoms/FlashMessage/FlashMessage';
import HorizontalRule from 'components/atoms/HorizontalRule/HorizontalRule';
import { Row, Col } from 'components/atoms/Grid/Grid';
import AnchorLinks from 'components/molecules/AnchorLinks/AnchorLinks';
import WallSingle from 'components/organisms/WallSingle/WallSingle';
import ProductBlog from 'components/organisms/ProductBlog/ProductBlog';
import Rating from 'components/organisms/Rating/Rating/Rating';
import ProductInfoAdditional from 'components/organisms/ProductInfoAdditional/ProductInfoAdditional';
import ProductInfoMain from 'components/organisms/ProductInfoMain/ProductInfoMain';
import ProductDetailOrganismus from 'components/organisms/ProductDetail/ProductDetail';
import FeedbackFormWrapper from 'components/organisms/FeedbackForm/FeedbackFormWrapper';
import SeoStructuredData, { seoStructuredDataTypes } from 'components/organisms/SeoStructuredData/SeoStructuredData';
import language from 'constants/language/language';
import productLinkConfiguration from 'constants/productLinkConfiguration/productLinkConfiguration';
import config from './ProductDetail.config';
import styles from './ProductDetail.scss';
import bootstrap from 'scss/component.scss';
import { cypressAttributes } from 'constants/cypress/cypress';
import ProductInfoMainContainer from 'containers/ProductInfoMainContainer/ProductInfoMainContainer';

const ProductDetail = ({
  product,
  addToWatchlist,
  productAddedToWatchlist,
  removeFromWatchlist,
  addToComparison,
  productAddedToComparison,
  removeFromComparison,
  hasPromoEnded,
  trackNavigationElements,
  categories,
  flashMessage,
  promoBox = {},
}) => {
  const { code = '', categoryCode, hasReferences } = product;

  const productLinks = config.getProductLinks(product);

  return (
    <div className={styles.ProductDetailWrapper}>
      <SeoStructuredData type={seoStructuredDataTypes.PRODUCT} data={product} categories={categories} />

      <ProductInfoMainContainer>
        <ProductInfoMain
          product={product}
          addProductToWatchlist={addToWatchlist}
          removeProductFromWatchlist={removeFromWatchlist}
          addedToWatchlist={productAddedToWatchlist}
          addProductToComparison={addToComparison}
          removeProductFromComparison={removeFromComparison}
          addedToComparison={productAddedToComparison}
          trackNavigationElements={trackNavigationElements}
          hasPromoEnded={hasPromoEnded}
          categories={categories}
          promoBox={promoBox}
          cyData={cypressAttributes.comparisonList.addToComparisonListButtonPDP}
        />
      </ProductInfoMainContainer>

      {flashMessage.message && (
        <Row>
          <Col lg={6} offsetLg={6}>
            <FlashMessage content={flashMessage.message} type={flashMessage.type} />
          </Col>
        </Row>
      )}

      <ProductInfoAdditional product={product} flashMessage={flashMessage} />

      <Row>
        <Col xl={8} offsetXl={2} lg={10} offsetLg={1}>
          <WallContainer wallId={code}>
            <WallSingle />
          </WallContainer>
        </Col>
      </Row>

      <div className={bootstrap.hiddenMdDown}>
        <ProductAnchorContainer productLinks={productLinks} productCode={code}>
          <AnchorLinks />
        </ProductAnchorContainer>
      </div>

      <div id={productLinkConfiguration().description.linkTo}>
        <Row>
          <Col>
            <ProductDetailOrganismus product={product} categories={categories} />
          </Col>
          <Col>
            <FeedbackFormContainer product={product}>
              <FeedbackFormWrapper />
            </FeedbackFormContainer>
          </Col>
        </Row>
      </div>

      {hasReferences && (
        <>
          <Row>
            <Col>
              <HorizontalRule />
            </Col>
          </Row>
          <section id={productLinkConfiguration().references.linkTo}>
            <Accessories productCode={code}>
              <TopProductsContainer title={<span>{language('product.accessoriesHeader.title')}</span>} fullWidth />
            </Accessories>
          </section>
        </>
      )}

      <RecommendationsBox productId={code} categoryId={categoryCode} />

      <section>
        <Row>
          <Col>
            <HorizontalRule />
          </Col>
        </Row>
        <RatingDetailsContainer productCode={code}>
          <Rating />
        </RatingDetailsContainer>
      </section>

      <section className={bootstrap.container} id={productLinkConfiguration().blogPost.linkTo}>
        <ProductBlogContainer productCode={code}>
          <ProductBlog />
        </ProductBlogContainer>
      </section>

      <AddedToCartModal />
    </div>
  );
};

ProductDetail.displayName = 'templates/ProductDetail';
ProductDetail.propTypes = {
  product: PropTypes.object.isRequired,
  addToWatchlist: PropTypes.func,
  removeFromWatchlist: PropTypes.func,
  productAddedToWatchlist: PropTypes.bool,
  productAddedToComparison: PropTypes.bool,
  addToComparison: PropTypes.func,
  removeFromComparison: PropTypes.func,
  trackNavigationElements: PropTypes.func,
  hasPromoEnded: PropTypes.bool,
  categories: PropTypes.object,
  flashMessage: PropTypes.object,
  promoBox: PropTypes.object,
};

ProductDetail.defaultProps = {
  addToWatchlist: () => {},
  removeFromWatchlist: () => {},
  addToComparison: () => {},
  removeFromComparison: () => {},
  trackNavigationElements: () => {},
  categories: {},
};

export default ProductDetail;
