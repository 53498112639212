import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { cypressAttributes } from 'constants/cypress/cypress';
import InputNumber, { skins } from 'components/atoms/InputNumber/InputNumber';
import styles from './InputDate.scss';
import bootstrap from 'scss/component.scss';

const FIELDS = {
  DAY: 'd',
  MONTH: 'm',
  YEAR: 'y',
};

const InputDate = ({ value: initialValue, handleChange, disabled, onBlur, onFocus, showError }) => {
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [hasFocus, setFocus] = useState(null);
  const monthInput = useRef();
  const yearInput = useRef();

  const prependZero = (val) => (parseInt(val, 10) < 10 ? `0${parseInt(val, 10)}` : val);

  useEffect(() => {
    if (initialValue !== '--') {
      const [y = '', m = '', d = ''] = initialValue.split('T')[0].split('-');
      setDay(d);
      setMonth(m);
      setYear(y);
    }
  }, []);

  useEffect(() => {
    const birthday = `${year}-${month}-${day}`;
    if (hasFocus === false) {
      onBlur();
    }
    if (birthday !== '--') {
      handleChange(birthday);
    } else {
      handleChange('');
    }
  }, [day, month, year, hasFocus]);

  const onInputFocus = () => {
    setFocus(true);
    onFocus();
  };

  const onInputBlur = (field, val) => {
    const shouldPrependZero = val.length === 1 && parseInt(val, 10) > 0;
    if (shouldPrependZero && field === FIELDS.DAY) setDay(prependZero(val));
    if (shouldPrependZero && field === FIELDS.MONTH) setMonth(prependZero(val));
    setFocus(false);
    onBlur();
  };

  const onInputChange = (field, value) => {
    if (field === FIELDS.DAY) {
      setDay(value);
      if (value.length === 2) {
        monthInput.current.focus();
        monthInput.current.select();
      }
    }

    if (field === FIELDS.MONTH) {
      setMonth(value);
      if (value.length === 2) {
        yearInput.current.focus();
        yearInput.current.select();
      }
    }

    if (field === FIELDS.YEAR) setYear(value);
  };

  const provideTrackingAttributes = (type) => {
    return { TYPE: 'form:field', FUNCTION: 'focus', ATTRIBUTE_1: 'text', ATTRIBUTE_2: type };
  };

  const container = classNames({
    [styles.container]: true,
    [bootstrap.container]: true,
  });
  const dateStyle = classNames({
    [styles.input]: true,
    [styles.date]: true,
  });
  const monthStyle = classNames({
    [styles.input]: true,
    [styles.month]: true,
  });
  const yearStyle = classNames({
    [styles.input]: true,
    [styles.year]: true,
  });
  return (
    <div className={container}>
      <div className={bootstrap.row}>
        <div className={dateStyle}>
          <InputNumber
            onChange={(value) => onInputChange(FIELDS.DAY, value)}
            value={day}
            maxLength={'2'}
            placeholder={'tt'}
            changeOnBlur={(val) => onInputBlur(FIELDS.DAY, val)}
            onFocus={onInputFocus}
            avoidInlineStates
            skin={skins.BIRTHDATE_DAY}
            forceValidation={showError}
            showError={showError && !hasFocus}
            disabled={disabled}
            cyData={cypressAttributes.checkout.birthdayDay}
            trackingAttributes={provideTrackingAttributes('day')}
          />
        </div>
        <div className={monthStyle}>
          <InputNumber
            ref={monthInput}
            onChange={(value) => onInputChange(FIELDS.MONTH, value)}
            value={month}
            maxLength={'2'}
            placeholder={'mm'}
            changeOnBlur={(val) => onInputBlur(FIELDS.MONTH, val)}
            onFocus={onInputFocus}
            avoidInlineStates
            skin={skins.BIRTHDATE_MONTH}
            forceValidation={showError}
            showError={showError && !hasFocus}
            disabled={disabled}
            cyData={cypressAttributes.checkout.birthdayMonth}
            trackingAttributes={provideTrackingAttributes('month')}
          />
        </div>
        <div className={yearStyle}>
          <InputNumber
            ref={yearInput}
            onChange={(value) => onInputChange(FIELDS.YEAR, value)}
            value={year}
            maxLength={'4'}
            placeholder={'jjjj'}
            changeOnBlur={(val) => onInputBlur(FIELDS.YEAR, val)}
            onFocus={onInputFocus}
            avoidInlineStates
            skin={skins.BIRTHDATE_YEAR}
            showError={showError && !hasFocus}
            disabled={disabled}
            cyData={cypressAttributes.checkout.birthdayYear}
            trackingAttributes={provideTrackingAttributes('year')}
          />
        </div>
      </div>
    </div>
  );
};

InputDate.displayName = 'molecules/InputDate';

InputDate.propTypes = {
  value: PropTypes.string,
  handleChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  showError: PropTypes.bool,
  disabled: PropTypes.bool,
};

InputDate.defaultProps = {
  value: '',
  handleChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
  showError: false,
  disabled: false,
};

export default InputDate;
