import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import MessageBadRequest, { pageType } from 'components/molecules/MessageBadRequest/MessageBadRequest';
import SpinnerOverlay from 'components/molecules/SpinnerOverlay/SpinnerOverlay';

import ModalApiError from 'components/organisms/ModalApiError/ModalApiError';

import ProductsOverviewSearch from 'components/templates/ProductsOverview/ProductsOverviewSearch';
import ProductsOverviewCategory from 'components/templates/ProductsOverview/ProductsOverviewCategory';

import { ProductViewTypes } from 'constants/ProductViewTypes/ProductViewTypes';

import {
  loadProducts,
  loadProductsNeed,
  routerUpdateLocationNeed,
  getCategoryWall,
  getCategoryWallNeed,
} from 'actions/productActions/productActions';
import { trackProductOverview } from 'actions/trackingActions/trackingActions';
import { mapUrlToQueryParams, mapQueryParamsToUrl } from 'constants/urlMapping/urlMapping';

import { apiStatus, invalidApiStatus } from 'constants/apiStatus/apiStatus';
import { getCategoryUrl } from 'constants/categoryTree/categoryTree';
import { getLocale } from 'constants/language/language';
import NextFeatureEnabled from './NextFeatureEnabled';

export const routeTypes = {
  CATEGORY: 'category',
  CATEGORY_SEARCH: 'category_search',
  PRODUCT_SEARCH: 'product_search',
};

export const getNumberOfFacets = (type, facets) => {
  const excluded = ['categoryPath'];
  if (type === routeTypes.CATEGORY) {
    return facets.filter((f) => !excluded.includes(f.code)).length;
  }
  return facets.length;
};

const defaultEmptyArray = [];
const defaultEmptyObject = {};

const mapStateToProps = (state, ownProps) => {
  const productsQuery = state.productsQueries.currentQuery;

  const numberOfFacets = productsQuery.facets ? getNumberOfFacets(ownProps.route.type, productsQuery.facets) : 0;

  const numberOfSelectedFacets = productsQuery.selectedFacetsCount;

  const pagination = productsQuery.pagination || defaultEmptyObject;
  const numberOfPages = pagination.numberOfPages;
  const totalNumberOfResults = pagination.totalNumberOfResults;
  const currentPage = pagination.currentPage + 1 || 1;

  const isFilterMenuOpen = state.ui.filterMenu.isOpen;

  const sorts = productsQuery.sorts || defaultEmptyArray;

  // will be used for the default value in select sort
  const location = ownProps.location;
  const currentURL = `${location.pathname}${location.search}`;

  const categoryCode = productsQuery.categoryCode;

  const brandQuery = state?.routing?.locationBeforeTransitions?.query?.brand;

  const pathname = ownProps?.location?.pathname || '/';

  const comparisonHasError = state?.comparison?.hasError;

  const productViewType = state?.ui?.productViewType;

  const categoryTree = productsQuery?.categoryTree;

  const originalSearchString = state.productsQueries?.currentQuery?.originalSearchString;
  const hasCmsContent = state.cms?.wallMini?.[`ws${categoryCode}`]?.content?.length > 0;

  return {
    breadcrumb: productsQuery.breadcrumb || defaultEmptyArray,
    queryString: productsQuery.queryString,
    visibleProducts: productsQuery.visibleProducts || defaultEmptyArray,
    numberOfPages,
    totalNumberOfResults,
    currentPage,
    apiStatus: productsQuery.apiStatus,
    apiErrorType: productsQuery.apiErrorType,
    searchString: productsQuery.searchString,
    isFilterMenuOpen,
    numberOfFacets,
    numberOfSelectedFacets,
    spellingSuggestion: productsQuery.spellingSuggestion,
    sorts,
    currentURL,
    categoryCode,
    cmsContent: productsQuery.cmsContent,
    urlHash: state?.routing?.locationBeforeTransitions?.key || '',
    brandQuery,
    pathname,
    comparisonHasError,
    productViewType,
    categories: state.categories,
    categoryTree,
    originalSearchString,
    hasCmsContent,
  };
};

const mapDispatchToProps = {
  loadProducts,
  getCategoryWall,
  trackProductOverview,
};

export const isInvalidAPIStatus = (status) => invalidApiStatus.includes(status);

export class ProductsOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialAPIError: isInvalidAPIStatus(this.props.apiStatus),
      initialApiRequestPending: !props.apiStatus,
      openAPIErrorModal: false,
    };

    this.visibleProducts = props.visibleProducts;
    this.currentURL = props.currentURL;
    this.sorts = props.sorts;
    this.breadcrumb = props.breadcrumb;
    this.currentPage = props.currentPage;
    this.totalNumberOfResults = props.totalNumberOfResults;
    this.numberOfPages = props.numberOfPages;
    this.numberOfFacets = props.numberOfFacets;
    this.numberOfSelectedFacets = props.numberOfSelectedFacets;
    this.categoryCode = props.categoryCode;
    this.brandQuery = props.brandQuery;
    this.comparisonHasError = props.comparisonHasError;
    this.categories = props.categories;
    this.categoryTree = props.categoryTree;
    this.categories = props.categories;
    this.originalSearchString = props.originalSearchString;
    this.hasCmsContent = props.hasCmsContent;
    this.searchString = props.searchString;
  }

  UNSAFE_componentWillMount() {
    if (__CLIENT__) {
      if (this.props.currentURL && this.props.currentURL.match(/\/--c/)) {
        // url is not formatted
        browserHistory.replace(
          getCategoryUrl(this.props.categoryCode, getLocale()) + this.props.currentURL.replace(/^.*?(\?|$)/, '$1')
        );
      }

      this.props.loadProducts(this.props.queryString).then(() => {
        // trigger tracking action for category
        this.props.trackProductOverview();
      });

      if (this.props.categoryCode) {
        this.props.getCategoryWall(this.props.categoryCode);
      }
    }

    const brand = this.props.params.brand;
    if (brand) {
      // TODO: take pathname and search from props or context
      const pathname = this.currentURL;
      // eslint-disable-next-line no-void
      const search = void 0;
      this.props.setBrand(brand, pathname, search);
    }
  }

  componentDidMount() {
    // remove selected category facet on language switch
    if (this.props.totalNumberOfResults === 0 && this.props.numberOfSelectedFacets > 0) {
      browserHistory.push(this.props.pathname);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (__CLIENT__) {
      if (
        (!nextProps.apiStatus || isInvalidAPIStatus(nextProps.apiStatus)) &&
        nextProps.urlHash !== this.props.urlHash
      ) {
        this.props.loadProducts(nextProps.queryString).then(() => {
          // trigger tracking action for category
          this.props.trackProductOverview();
        });
      } else if (nextProps.urlHash !== this.props.urlHash) {
        this.props.trackProductOverview();
      }

      if (nextProps.categoryCode !== this.props.categoryCode) {
        if (nextProps.categoryCode) {
          this.props.getCategoryWall(nextProps.categoryCode);
        }
      }
    }

    const { brand: currentBrand } = this.props.params;
    const { brand: nextBrand } = nextProps.params;
    if (nextBrand && nextBrand !== currentBrand) {
      // TODO: take pathname and search from props or context
      const pathname = this.currentURL;
      // eslint-disable-next-line no-void
      const search = void 0;
      nextProps.setBrand(nextBrand, pathname, search);
    }

    // reset all api failure states
    if (nextProps.apiStatus === apiStatus.success && nextProps.apiStatus !== this.props.apiStatus) {
      this.setState({
        initialAPIError: false,
        initialApiRequestPending: false,
        openAPIErrorModal: false,
      });
    }

    // check for initial api errors
    if (!this.state.initialAPIError) {
      if (invalidApiStatus.indexOf(nextProps.apiStatus) > -1 && nextProps.apiStatus !== this.props.apiStatus) {
        if (this.state.initialApiRequestPending) {
          this.setState({
            initialAPIError: true,
            openAPIErrorModal: false,
          });
        } else {
          this.setState({
            initialAPIError: false,
            openAPIErrorModal: true,
          });
        }
      }
    }
  }

  getBreadcrumb = () => {
    const { brand } = this.props.params;
    if (brand) {
      return [{ name: brand, href: this.props.currentURL }];
    }
    return this.props.breadcrumb;
  };

  retryAction = () => {
    this.props.loadProducts(this.props.queryString).then(() => {
      // trigger tracking action for category
      this.props.trackProductOverview();
    });
  };

  closeModal = () => {
    this.setState({ openAPIErrorModal: false });
  };

  onSortChange = (sort) => {
    const { currentURL, categories } = this.props;
    const currentQueryParams = mapUrlToQueryParams(currentURL, categories);
    const url = mapQueryParamsToUrl({ ...currentQueryParams, page: 0, sort }, getLocale(), categories);
    browserHistory.push(url);
  };

  render() {
    const conditions = [
      { include: 'search', exclude: '--c', featureName: 'nextSearchEnabled' },
      { include: '--c', featureName: 'nextCategoryEnabled' },
      // here add then for categories
    ];

    const Template =
      {
        [ProductsOverview.types.CATEGORY]: ProductsOverviewCategory,
      }[this.props.route.type] || ProductsOverviewSearch; // default

    if (this.props.apiStatus === apiStatus.success) {
      this.visibleProducts = this.props.visibleProducts;
      this.currentURL = this.props.currentURL;
      this.sorts = this.props.sorts;
      this.breadcrumb = this.getBreadcrumb();
      this.currentPage = this.props.currentPage;
      this.totalNumberOfResults = this.props.totalNumberOfResults;
      this.numberOfPages = this.props.numberOfPages;
      this.numberOfFacets = this.props.numberOfFacets;
      this.numberOfSelectedFacets = this.props.numberOfSelectedFacets;
      this.categoryCode = this.props.categoryCode;
      this.spellingSuggestion = this.props.spellingSuggestion;
      this.searchString = this.props.searchString;
      this.cmsContent = this.props.cmsContent;
      this.brandQuery = this.props.brandQuery;
      this.comparisonHasError = this.props.comparisonHasError;
      this.productViewType = this.props.productViewType;
      this.categories = this.props.categories;
      this.originalSearchString = this.props.originalSearchString;
      this.hasCmsContent = this.props.hasCmsContent;
    }

    return (
      <div>
        <NextFeatureEnabled location={this.currentURL} conditions={conditions} />
        {!this.state.initialAPIError && (
          <Template
            currentPage={this.currentPage}
            numberOfPages={this.numberOfPages}
            visibleProducts={this.visibleProducts}
            numberOfFacets={this.numberOfFacets}
            totalNumberOfResults={this.totalNumberOfResults}
            searchString={this.searchString}
            numberOfSelectedFacets={this.numberOfSelectedFacets}
            sorts={this.sorts}
            currentURL={this.currentURL}
            breadcrumb={this.breadcrumb}
            categoryCode={this.categoryCode}
            spellingSuggestion={this.spellingSuggestion}
            cmsContent={this.cmsContent}
            brandQuery={this.brandQuery}
            comparisonHasError={this.comparisonHasError}
            productViewType={this.productViewType}
            categories={this.props.categories}
            onSortChange={this.onSortChange}
            categoryTree={this.props.categoryTree}
            originalSearchString={this.props.originalSearchString}
            hasCmsContent={this.hasCmsContent}
          />
        )}
        {(this.props.apiStatus === apiStatus.request || !this.props.apiStatus) && <SpinnerOverlay />}
        {this.state.initialAPIError && (
          <div>
            <MessageBadRequest pageType={pageType.productOverview} apiStatus={this.props.apiStatus} />
          </div>
        )}
        {this.state.openAPIErrorModal && (
          <ModalApiError
            requestCloseModal={this.closeModal}
            retryAction={this.retryAction}
            isModalOpen={this.state.openAPIErrorModal}
            apiStatus={this.props.apiStatus}
          />
        )}
      </div>
    );
  }
}

ProductsOverview.displayName = 'routes/ProductsOverview';
ProductsOverview.propTypes = {
  queryString: PropTypes.string,
  loadProducts: PropTypes.func,
  getCategoryWall: PropTypes.func,

  apiStatus: PropTypes.string,
  visibleProducts: PropTypes.array,
  currentPage: PropTypes.number,
  totalNumberOfResults: PropTypes.number,
  numberOfPages: PropTypes.number,
  breadcrumb: PropTypes.array,
  numberOfFacets: PropTypes.number,
  numberOfSelectedFacets: PropTypes.number,
  searchString: PropTypes.string,
  spellingSuggestion: PropTypes.string,
  sorts: PropTypes.array,
  currentURL: PropTypes.string,

  route: PropTypes.object, // contains the "type" prop from routes.js
  params: PropTypes.object,

  setBrand: PropTypes.func,
  categoryCode: PropTypes.string,
  urlHash: PropTypes.string,
  cmsContent: PropTypes.object,
  brandQuery: PropTypes.string,
  pathname: PropTypes.string,
  trackProductOverview: PropTypes.func,
  comparisonHasError: PropTypes.bool,
  productViewType: PropTypes.oneOf(Object.values(ProductViewTypes)),
  categories: PropTypes.object,
  onSortChange: PropTypes.func,
  categoryTree: PropTypes.array,
  originalSearchString: PropTypes.string,
  hasCmsContent: PropTypes.bool,
};
ProductsOverview.defaultProps = {
  categories: {},
};

ProductsOverview.need = [routerUpdateLocationNeed, loadProductsNeed, getCategoryWallNeed];
ProductsOverview.types = routeTypes;

export default connect(mapStateToProps, mapDispatchToProps)(ProductsOverview);
