import React from 'react';
import PropTypes from 'prop-types';
import MobileNavigationItem from '../MobileNavigationItem/MobileNavigationItem';

const FacetListItem = ({ facetValue, query }) => {
  return (
    <MobileNavigationItem href={query}>
      <span>{`${facetValue.name} (${facetValue.count.toLocaleString('de-CH')})`}</span>
    </MobileNavigationItem>
  );
};

FacetListItem.propTypes = {
  facetValue: PropTypes.shape({
    name: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
  }).isRequired,

  // all required, passed in through ProductsFacetContainer:
  query: PropTypes.string.isRequired,
};

FacetListItem.displayName = 'atoms/FacetListItem';

export default FacetListItem;
