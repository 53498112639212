import React from 'react';
import PropTypes from 'prop-types';
import styles from './Badge.scss';

const Badge = ({ counter, showEmptyBadge = true }) => {
  if (counter || (!counter && showEmptyBadge)) {
    return <div className={styles.badge}>{typeof counter === 'number' && counter > 9 ? '9+' : counter}</div>;
  }
  return <></>;
};

Badge.displayName = 'atoms/Badge';
Badge.propTypes = {
  counter: PropTypes.oneOfType([PropTypes.number, PropTypes.node, PropTypes.string]),
  showEmptyBadge: PropTypes.bool,
};
Badge.defaultProps = {
  counter: 0,
  showEmptyBadge: true,
};

export default Badge;
