import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import debounce from 'constants/helper/debounce';

import styles from './Collapsible.scss';

class Collapsible extends Component {
  static propTypes = {
    handle: PropTypes.func,
    renderHandle: PropTypes.func,
    // children need to be wrapped in a single node to calc outerheight (with margins!) correctly
    children: PropTypes.element.isRequired,
    initialOpen: PropTypes.bool,
    showPlus: PropTypes.bool,
    disabled: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.innerRef = React.createRef();
    this.state = {
      outerHeight: props.initialOpen ? 'auto' : '0px',
      isCollapsed: !props.initialOpen,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', debounce(this.setHeight, 100));
    if (!this.state.isCollapsed) {
      this.setHeight();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setHeight);
  }

  setHeight = () => {
    if (!this.state.isCollapsed && this.innerRef.current) {
      this.setState({
        outerHeight: this.outerHeight(this.innerRef.current),
      });
    }
  };

  toggle = () => {
    if (!this.props.disabled) {
      this.setState((prevState) => ({
        outerHeight: prevState.isCollapsed ? this.outerHeight(this.innerRef.current) : '0px',
        isCollapsed: !prevState.isCollapsed,
      }));
    }
  };

  outerHeight = (elm) => {
    if (!__CLIENT__) {
      return 'auto';
    }

    const style = window.getComputedStyle(elm);
    // calc height WITH top/bottom margin!
    const height = elm.offsetHeight + parseInt(style.marginTop, 10) + parseInt(style.marginBottom, 10);
    return `${height}px`;
  };

  render() {
    const { handle, renderHandle, children, showPlus, disabled } = this.props;
    const plus = <div className={cx(styles.plus, !this.state.isCollapsed && styles.open)} />;
    const style = {
      height: disabled ? 'auto' : this.state.outerHeight,
    };

    const handleElm = renderHandle ? (
      renderHandle({ isopen: this.state.isCollapsed, plus: showPlus ? plus : null })
    ) : (
      <>
        {handle}
        {showPlus && plus}
      </>
    );

    return [
      <div key="handle" onClick={this.toggle} className={cx(styles.handle, disabled && styles.disabled)}>
        {handleElm}
      </div>,
      <div key="content" style={style} className={styles.content}>
        {React.Children.map(children, (child) => React.cloneElement(child, { ref: this.innerRef }))}
      </div>,
    ];
  }
}

export default Collapsible;
