import language from 'constants/language/language';
import { TOP_RATING_FORM } from 'constants/scrollToElement/scrollToElement';

const productLinkConfiguration = () => ({
  description: {
    displayName: language('product.details'),
    linkTo: 'details',
    hideOnSmallViews: true,
    trackingLabel: 'beschrieb',
  },
  classifications: {
    displayName: language('product.productFeatures'),
    linkTo: 'classifications',
    hideOnSmallViews: false,
    trackingLabel: 'eigenschaften',
  },
  downloads: {
    displayName: language('product.downloads'),
    linkTo: 'downloads',
    hideOnSmallViews: true,
    trackingLabel: 'downloads',
  },
  references: {
    displayName: language('product.accessories'),
    linkTo: 'accessories',
    hideOnSmallViews: false,
    trackingLabel: 'zubehoer',
  },
  ratings: {
    displayName: language('product.ratings'),
    linkTo: TOP_RATING_FORM,
    hideOnSmallViews: false,
    trackingLabel: 'bewertungen',
  },
  blogPost: {
    displayName: language('product.blogPost'),
    linkTo: 'blog',
    hideOnSmallViews: true,
    trackingLabel: 'blog',
  },
});

export default productLinkConfiguration;
