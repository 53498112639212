import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import language from 'constants/language/language';
import { Row } from 'components/atoms/Grid/Grid';
import Headline from 'components/atoms/Headline/Headline';
import Icon, { ICON_BASKET, ICON_LOCAL_OFFER_OUTLINED, ICON_LOCATION_ON_OUTLINED } from 'components/atoms/Icon/Icon';
import styles from './IconTextCombi.scss';

const IconTextCombi = ({ isVertical }) => (
  <Row>
    <div className={cx(styles.benefitsContainer, { [styles.isVertical]: isVertical })}>
      <div className={styles.benefit}>
        <div className={styles.icon}>
          <Icon path={ICON_LOCATION_ON_OUTLINED} size={Icon.sizes.xxl} />
        </div>
        <Headline tag={Headline.tags.H4} margin={Headline.margins.NORMAL} alignment={Headline.alignments.CENTER}>
          {language('checkoutSuccess.fastOrder')}
        </Headline>
        <p>{language('checkoutSuccess.fastOrderDescription')}</p>
      </div>
      <div className={styles.benefit}>
        <div className={styles.icon}>
          <Icon path={ICON_BASKET} size={Icon.sizes.xxl} />
        </div>
        <Headline tag={Headline.tags.H4} margin={Headline.margins.NORMAL} alignment={Headline.alignments.CENTER}>
          {language('checkoutSuccess.manageOrder')}
        </Headline>
        <p>{language('checkoutSuccess.manageOrderDescription')}</p>
      </div>
      <div className={styles.benefit}>
        <div className={styles.icon}>
          <Icon path={ICON_LOCAL_OFFER_OUTLINED} size={Icon.sizes.xxl} />
        </div>
        <Headline tag={Headline.tags.H4} margin={Headline.margins.NORMAL} alignment={Headline.alignments.CENTER}>
          {language('checkoutSuccess.promotion')}
        </Headline>
        <p>{language('checkoutSuccess.promotionDescription')}</p>
      </div>
    </div>
  </Row>
);

IconTextCombi.propTypes = {
  isVertical: PropTypes.bool,
};

IconTextCombi.displayName = 'molecules/IconTextCombi';

export default IconTextCombi;
