import React from 'react';

import { WALL_OF_ALL_ID } from 'constants/cms/cms';
import language from 'constants/language/language';
import Meta from 'containers/Meta/Meta';
import WallContainer from 'containers/Wall/Wall';
import CardWall from 'components/organisms/CardWall/CardWall';
import { getEnv } from 'config/config';
import NextFeatureEnabled from 'routes/ProductsOverview/NextFeatureEnabled';

const WallOfAll = () => {
  const setCanonicalUrl = () => {
    if (!__CLIENT__) return null;

    const url = new URL(window.location.href);
    let { pathname } = url;

    return [{ rel: 'canonical', href: `${getEnv('web')}${pathname}` }];
  };

  const conditions = [{ include: 'promotions', featureName: 'nextPromotionsEnabled' }];

  return (
    <>
      <NextFeatureEnabled conditions={conditions} />
      <Meta title={language('wall.header')} titleFirst={false} canonical={setCanonicalUrl()} />
      <WallContainer wallId={WALL_OF_ALL_ID}>
        <CardWall skin={CardWall.SKINS.ALL} />
      </WallContainer>
    </>
  );
};

WallOfAll.displayName = 'templates/WallOfAll';

export default WallOfAll;
