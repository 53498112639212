import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { StepperButtons } from 'components/molecules/Stepper/Stepper';
import ReturnableProduct from 'components/molecules/ReturnableProduct/ReturnableProduct';
import Headline from 'components/atoms/Headline/Headline';
import InputCheckbox from 'components/atoms/InputCheckbox/InputCheckbox';
import language from 'constants/language/language';
import FlashMessage from 'components/atoms/FlashMessage/FlashMessage';
import { sizes, types as FlashMessageTypes } from 'constants/flashMessages/flashMessages';

import styles from './ReturnProductSelect.scss';

const ReturnProductSelect = ({
  onNextClick = () => {},
  onPrevClick = () => {},
  activeStep = 0,
  state = { order: {} },
}) => {
  const merchants = state.order.merchants;
  const [merchantId, setMerchantId] = useState('');
  const [selectedProducts, setSelectedProducts] = useState([]);

  const handleProductSelect = (selectedMerchantId, product) => {
    if (selectedProducts.findIndex((p) => p.id === product.id) !== -1) {
      const products = selectedProducts.filter((p) => p.id !== product.id);
      setSelectedProducts(products);

      if (products.length === 0) {
        setMerchantId('');
      }
    } else {
      setSelectedProducts((prevProducts) => [...prevProducts, product]);
      setMerchantId(selectedMerchantId);
    }
  };

  const handleNextClick = () => {
    onNextClick({ ...state, selectedProducts, selectedMerchantId: merchantId });
  };

  const activeMerchant = merchants.find((m) => m.id === merchantId);
  const merchantName = activeMerchant && activeMerchant.name;

  return (
    <>
      <Headline tag={Headline.tags.H4} margin={Headline.margins.NONE}>
        {language('returns.productSelect.orderNumber')} {state.order.code}
      </Headline>
      {merchants.map((merchant) => (
        <div key={merchant.id} className={styles.distributor}>
          {merchantId && merchantId !== merchant.id && (
            <div className={styles.distributorDisabled}>
              <FlashMessage
                size={sizes.LARGE}
                type={FlashMessageTypes.WARNING_INFO}
                id={`returnProductSelect-${merchant.id}`}
              >
                {language('returns.productSelect.distiWarn.part1')}
                <strong>{merchantName}</strong>
                {language('returns.productSelect.distiWarn.part2')}
              </FlashMessage>
            </div>
          )}

          {state.order.code.startsWith('07') && (
            <div className={styles.distributorHeader} data-cy="formControl-distributorHeader">
              {`${language('returns.productSelect.soldBy')} ${merchant.name || ''}`}
            </div>
          )}

          {merchant.products.map((product) => (
            <ReturnableProduct key={product.id} product={product} childPos="inline">
              <InputCheckbox
                id={`formControl-returnableProductSelect-${product.id}-checkbox`}
                onClick={() => handleProductSelect(merchant.id, product)}
                selected={selectedProducts.findIndex((p) => p.id === product.id) !== -1}
                labelPos={InputCheckbox.labelPositions.LEFT}
                disabled={merchantId && merchantId !== merchant.id ? true : !!product.notReturnable}
                skin={InputCheckbox.skins.RETURNS}
              >
                {language('returns.productSelect.returnArticle')}
              </InputCheckbox>
            </ReturnableProduct>
          ))}
        </div>
      ))}
      <StepperButtons
        onPrevClick={onPrevClick}
        onNextClick={handleNextClick}
        activeStep={activeStep}
        nextDisabled={selectedProducts.length === 0}
      />
    </>
  );
};

ReturnProductSelect.displayName = 'molecules/ReturnSteps/ReturnProductSelect';

ReturnProductSelect.propTypes = {
  onNextClick: PropTypes.func,
  onPrevClick: PropTypes.func,
  activeStep: PropTypes.number,
  state: PropTypes.object,
};

export default ReturnProductSelect;
