import React, { Component } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import BrochureNavigationLink from 'components/atoms/BrochureNavigationLink/BrochureNavigationLink';
import ProductNavigationItems from 'components/molecules/ProductNavigation/ProductNavigationItems';
import CmsLink from 'containers/CmsLink/CmsLink';
import { CMS_UID_BROCHURE } from 'constants/cms/cms';
import { cancel, debounce } from 'constants/lodash/debounce';
import styles from './ProductNavigation.scss';
import bootstrap from 'scss/component.scss';

import language from 'constants/language/language';
import { cypressAttributes } from 'constants/cypress/cypress';

const debounceId = 'navigation';

export default class ProductNavigation extends Component {
  static displayName = 'molecules/ProductNavigation';

  static propTypes = {
    isSticky: PropTypes.bool,
    categories: PropTypes.object,
    isHeader: PropTypes.bool,
  };

  static defaultProps = {
    isSticky: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      activeParent: '1',
      isMainBigger: false,
    };

    this.mainRef = React.createRef();
    this.lastLevelRef = React.createRef();
  }

  render() {
    let hoveredItem = '';
    const setParent = (parent) => {
      // set hoveredItem to parent so the category change only if the user hover more than 200ms over the same item.
      hoveredItem = parent;
      debounce(
        debounceId,
        () => {
          if (hoveredItem === parent && parent !== this.state.activeParent) {
            this.setState({
              activeParent: parent,
            });
          }
          if (__CLIENT__) {
            const main = this.mainRef.current;
            const child = this.lastLevelRef.current;
            if (main && child) {
              const target = child.getBoundingClientRect();
              const mainBounding = main.getBoundingClientRect();
              this.setState({
                isMainBigger: mainBounding.height > target.height,
              });
            }
          }
        },
        100
      );
    };

    const resetHoveredItem = () => {
      debounce(
        debounceId,
        () => {
          setParent('1');
          hoveredItem = '1';
        },
        200
      );
    };

    const cancelClose = () => {
      cancel(debounceId);
    };

    const PROSPEKT_OFFSET = 12;

    if (__CLIENT__ && !Object.keys(this.props.categories).length) {
      return null;
    }

    return (
      <div
        itemScope
        itemType="https://schema.org/SiteNavigationElement"
        className={cx(bootstrap.hiddenPrint, styles.container)}
      >
        <div onMouseLeave={resetHoveredItem} ref={this.navigationRef}>
          <div ref={this.mainRef}>
            <div className={styles.title}>{language('mobileDrilldown.overview')}</div>
            <ProductNavigationItems
              onHover={setParent}
              onMouseLeave={resetHoveredItem}
              activeParent={this.state.activeParent}
              categories={this.props.categories}
              title={language('mobileDrilldown.overview')}
              cyData={cypressAttributes.homepage.categories}
            />
            <CmsLink pageId={CMS_UID_BROCHURE}>
              <BrochureNavigationLink pos={PROSPEKT_OFFSET} parentTitle={language('mobileDrilldown.overview')} />
            </CmsLink>
          </div>
          {this.state.activeParent !== '1' && (
            <div
              ref={this.lastLevelRef}
              className={cx(styles.list, styles.childList, { [styles.smaller]: this.state.isMainBigger })}
              onMouseEnter={cancelClose}
            >
              <ProductNavigationItems
                parent={this.state.activeParent}
                categories={this.props.categories}
                noIcons
                lastLevel
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
