import React, { memo, useEffect, useState } from 'react';
import { browserHistory } from 'react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  clearUpdatedCartHistory as clearUpdatedCartHistoryAction,
  getCart as getCartAction,
  getCurrentDeliveryMode as getCurrentDeliveryModeAction,
  getDeliveryModes as getDeliveryModesAction,
  getPaymentModes as getPaymentModesAction,
  setCheckoutNeedStatus as setCheckoutNeedStatusAction,
} from 'actions/cartActions/cartActions';
import { trackCartLogin as trackCartLoginAction } from 'actions/trackingActions/trackingActions';
import { apiStatus } from 'constants/apiStatus/apiStatus';
import { getLocale } from 'constants/language/language';
import { cart, checkoutLogin } from 'constants/routePaths/routePathCombinations';
import { storageEnabled } from 'constants/storage/storage';
import { cookiesEnabled } from 'constants/storage/cookie';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import ModalAlert from 'components/organisms/ModalAlert/ModalAlert';
import MessageBadRequest, { pageType } from 'components/molecules/MessageBadRequest/MessageBadRequest';
import SpinnerOverlay from 'components/molecules/SpinnerOverlay/SpinnerOverlay';
import StickyContainer from 'components/atoms/Sticky/StickyContainer';

export const mapStateToProps = (state, ownProps) => {
  const validationResult = state.cart.orderData.deliveryMode.validationResult;
  const cartApiStatus = state.cart.apiStatus;
  const isCheckoutLogin = (ownProps?.location?.pathname || '/') === mapPathToLocalizedUrl(getLocale(), checkoutLogin);
  const appliedVouchers = state?.cart?.appliedVouchers;
  const stockAvailabilityChanged = state?.cart?.stockAvailabilityChanged;
  const showModalAlert = (!isCheckoutLogin && state.cart.cartUpdated) || stockAvailabilityChanged;
  const cartIsEmpty = !state.cart.entries.length;
  const checkoutNeedStatus = state?.cart?.checkoutNeedStatus;
  return {
    validationResult,
    cartApiStatus,
    isCheckoutLogin,
    showModalAlert,
    cartIsEmpty,
    appliedVouchers,
    checkoutNeedStatus,
    stockAvailabilityChanged,
  };
};
const mapDispatchToProps = {
  getCart: getCartAction,
  getDeliveryModes: getDeliveryModesAction,
  getCurrentDeliveryMode: getCurrentDeliveryModeAction,
  getPaymentModes: getPaymentModesAction,
  clearUpdatedCartHistory: clearUpdatedCartHistoryAction,
  setCheckoutNeedStatus: setCheckoutNeedStatusAction,
  trackCartLogin: trackCartLoginAction,
};

const CheckoutGateway = ({
  cartApiStatus = '',
  cartIsEmpty = false,
  checkoutNeedStatus = '',
  children,
  clearUpdatedCartHistory = () => {},
  getCart = () => {},
  getCurrentDeliveryMode = () => {},
  getDeliveryModes = () => {},
  getPaymentModes = () => {},
  isCheckoutLogin = false,
  setCheckoutNeedStatus = () => {},
  showModalAlert = false,
  stockAvailabilityChanged = false,
  trackCartLogin = () => {},
}) => {
  const [isAlertModalVisible, setIsAlertModalVisible] = useState(showModalAlert);
  const [isCookiesEnabled] = useState(cookiesEnabled || storageEnabled);
  const [isInitialRequestDone, setIsInitialRequestDone] = useState(false);

  useEffect(() => {
    const checkoutTimeout = setTimeout(() => {
      // make a redirect to the cart page
      browserHistory.push(mapPathToLocalizedUrl(getLocale(), cart));
    }, 1800000);

    return () => {
      clearTimeout(checkoutTimeout);
    };
  }, []);

  useEffect(() => {
    if (!isCheckoutLogin) {
      fetchData();
    } else {
      setIsInitialRequestDone(true);
      trackCartLogin();
    }

    return () => {
      setCheckoutNeedStatus(apiStatus.success);
    };
  }, []);

  const fetchData = () => {
    const needs = [getCart(true), getDeliveryModes(), getCurrentDeliveryMode(), getPaymentModes()];
    return Promise.all(needs).then((response) => {
      // set CheckoutNeedStatus to FAILURE if one of the needs fails
      if (response.some((elem) => elem === false)) {
        setCheckoutNeedStatus(apiStatus.failure);
      } else {
        setCheckoutNeedStatus(apiStatus.success);
      }
      setIsInitialRequestDone(true);
    });
  };

  const closeModalAlert = () => {
    setIsAlertModalVisible(false);
    clearUpdatedCartHistory();
  };

  const renderMessageBadRequest = (failure, page = pageType.checkout) => {
    const props = {
      apiStatus: failure,
      pageType: page,
      retryAction: fetchData,
    };
    return <MessageBadRequest {...props} />;
  };

  const renderSpinnerOverlay = () => (
    <StickyContainer>
      <SpinnerOverlay />
    </StickyContainer>
  );

  const renderModalAlert = () => (
    <>
      <ModalAlert
        isCartEmpty={cartIsEmpty}
        closeModal={closeModalAlert}
        stockAvailabilityChanged={stockAvailabilityChanged}
        clearUpdatedCartHistory={clearUpdatedCartHistory}
      />
      <div>{children}</div>
    </>
  );

  if (!isCookiesEnabled) {
    return renderMessageBadRequest(apiStatus.failure, pageType.cookiesDisabled);
  }
  if (isAlertModalVisible) {
    return renderModalAlert();
  }
  if ((cartApiStatus === apiStatus.networkerror || cartApiStatus === apiStatus.failure) && isCheckoutLogin) {
    return renderMessageBadRequest(cartApiStatus);
  }
  if (checkoutNeedStatus === apiStatus.failure && isCheckoutLogin) {
    return renderMessageBadRequest(checkoutNeedStatus);
  }
  if (cartApiStatus === apiStatus.request) {
    return renderSpinnerOverlay();
  }
  return isInitialRequestDone && <div>{children}</div>;
};

const areEqual = (prevProps, nextProps) => nextProps.cartApiStatus === apiStatus.request;

CheckoutGateway.displayName = 'containers/CheckoutGateway/CheckoutGateway';
CheckoutGateway.propTypes = {
  cartApiStatus: PropTypes.string,
  cartIsEmpty: PropTypes.bool,
  checkoutNeedStatus: PropTypes.string,
  children: PropTypes.object.isRequired,
  clearUpdatedCartHistory: PropTypes.func,
  getCart: PropTypes.func,
  getCurrentDeliveryMode: PropTypes.func,
  getDeliveryModes: PropTypes.func,
  getPaymentModes: PropTypes.func,
  isCheckoutLogin: PropTypes.bool,
  setCheckoutNeedStatus: PropTypes.func,
  showModalAlert: PropTypes.bool,
  stockAvailabilityChanged: PropTypes.bool,
  trackCartLogin: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(CheckoutGateway, areEqual));
