import { useEffect } from 'react';
import debounce from 'lodash/debounce';

const useWindowResize = (handler = () => {}, refs = [], debounceTimer = 500) => {
  useEffect(() => {
    if (!__CLIENT__) {
      return false;
    }

    handler();

    const debounceHandler = debounce(handler, debounceTimer);

    window.addEventListener('resize', debounceHandler);
    return () => {
      window.removeEventListener('resize', debounceHandler);
      debounceHandler.cancel();
    };
  }, refs);
};

export default useWindowResize;
