import { useEffect, useState } from 'react';
import { PLATFORM, usePlatform } from 'hooks/usePlatform';
import isMobileApp from 'constants/mobileApp/mobileApp';
import { BREAKPOINTS, smallerThan } from 'constants/breakpoints/breakpoints';
import getCookieStorage from 'constants/storage/cookie';
import useBreakpointChange from 'hooks/useBreakpointChange';

// iOS uses the native banner, so we only utilize the android link
const androidLink = 'https://play.google.com/store/apps/details?id=ch.coop.microspot';

export const COOKIE_BANNER_EXITED = 'smartbanner_exited';

export default function useAppBanner() {
  const isNativeApp = isMobileApp();
  const platform = usePlatform();
  const { getItem: getCookie, setItem: setCookie } = getCookieStorage();
  const cookieValue = getCookie(COOKIE_BANNER_EXITED);

  const breakpoint = useBreakpointChange();
  const isMobile = smallerThan(breakpoint, BREAKPOINTS.LG);


  const shouldShowBanner = isMobile && !isNativeApp && (platform === PLATFORM.ANDROID);

  const [shouldShowAppBanner, setShouldShowAppBanner] = useState(shouldShowBanner);

  useEffect(() => {
    if (isMobile && cookieValue !== '1') {
      setShouldShowAppBanner(shouldShowBanner);
    }
  }, [isMobile, cookieValue, shouldShowBanner]);

  const closeAppBanner = () => {
    setCookie(COOKIE_BANNER_EXITED, '1', { expiresInDays: 360, path: '/' });
    setShouldShowAppBanner(false);
  };

  return { shouldShowAppBanner, closeAppBanner, appLink: androidLink };
}
