import {
  GET_STORE,
  GET_STORES,
  GET_LOCATION,
  GET_LOCATION_NAME,
  SEARCH_STORE,
  SEARCH_STORE_RESET,
  OPEN_STORE_MODAL,
  GET_MY_STORE,
  OPEN_MY_STORE_MODAL,
  CLOSE_STORE_MODAL,
  CLOSE_MY_STORE_MODAL,
} from 'constants/ActionTypes/ActionTypes';
import StoreAPI from 'api/StoreAPI/StoreAPI';
import { getGeoLocation, getGeoLocationName } from 'api/GoogleAPI/GoogleAPI';

/**
 * Action creator which returns a specific store
 */
export const getStore = (storeId) => ({
  type: GET_STORE,
  promise: StoreAPI.getSpecificStore(storeId),
});

/**
 * Action creator which returns all stores
 */
export const getStores = () => ({
  type: GET_STORES,
  promise: StoreAPI.getAllStores(),
});

/**
 * Action creator which returns a latitude and longitude
 */
export const getLocation = (searchTerm) => ({
  type: GET_LOCATION,
  payload: { searchTerm },
  promise: getGeoLocation(searchTerm),
});

/**
 * Action creator which returns a town name
 */
export const getLocationName = (latitude, longitude) => ({
  type: GET_LOCATION_NAME,
  promise: getGeoLocationName(latitude, longitude),
  latitude,
  longitude,
});

/**
 * Action creator which returns a specific range of stores
 */
export const searchStore = (latitude, longitude, searchParams, all = false) => ({
  type: SEARCH_STORE,
  promise: StoreAPI.searchStore(latitude, longitude, searchParams, all),
});

export const resetStoreSearch = () => ({
  type: SEARCH_STORE_RESET,
});

/**
 * Action creator which opens modal and clears already found data
 */
export const openStoreModal = () => ({
  type: OPEN_STORE_MODAL,
});

export const openMyStoreModal = () => ({
  type: OPEN_MY_STORE_MODAL,
});

export const closeStoreModal = () => ({
  type: CLOSE_STORE_MODAL,
});

export const closeMyStoreModal = () => ({
  type: CLOSE_MY_STORE_MODAL,
});

export const getMyStore = (storeId, latitude = '', longitude = '') => ({
  type: GET_MY_STORE,
  promise: StoreAPI.getMyStore(storeId, latitude, longitude),
});

const actions = {
  getStore,
  getStores,
  getLocation,
  getLocationName,
  searchStore,
  openStoreModal,
  getMyStore,
  openMyStoreModal,
  closeStoreModal,
  closeMyStoreModal,
};

export default actions;
