import { Component, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import chunk from 'constants/helper/chunk';

export const getLatestProducts = (entries, products) => {
  const selectedProducts = [];
  const latestEntries = entries.length ? chunk([].concat(entries).reverse(), 3)[0] : [];

  latestEntries.forEach((entry) => {
    const product = products[entry.productCode];

    if (product) {
      selectedProducts.push({
        code: product.code,
        name: product.name,
        quantity: entry.quantity,
        formattedPrice: entry?.postDiscountPrice?.formattedValue || entry?.basePrice?.formattedValue || '',
        customImageData: product.customImageData,
      });
    }
  });

  return selectedProducts;
};

export const mapStateToProps = (state) => {
  const latestProducts = getLatestProducts(state?.cart?.entries || [], state?.products || {});

  return {
    latestProducts,
    totalItems: state?.cart?.totalItems || 0,
    totalPrice: state?.cart?.subTotal?.formattedValue || '0.00',
  };
};

class PopOverCartContainer extends Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
    latestProducts: PropTypes.array,
    totalItems: PropTypes.number,
    totalPrice: PropTypes.string,
    showPopOverCart: PropTypes.func,
  };

  static defaultProps = {
    latestProducts: {},
  };

  render() {
    const { latestProducts, totalItems, totalPrice } = this.props;

    return cloneElement(this.props.children, {
      latestProducts,
      totalItems,
      totalPrice,
    });
  }
}

export default connect(mapStateToProps)(PopOverCartContainer);
