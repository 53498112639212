import React, { useEffect, useState, useRef, useCallback } from 'react';
import debounce from 'lodash/debounce';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import noScroll from 'no-scroll';
import { CSSTransition } from 'react-transition-group';
import { provideSearchString } from 'constants/provideSearchString/provideSearchString';
import { BREAKPOINTS } from 'constants/breakpoints/breakpoints';
import isTouchDevice from 'constants/isTouchDevice/isTouchDevice';
import { ACTIVE_SEARCH } from 'constants/trackingAttributes/trackingAttributes';
import dataTracking from 'constants/trackingAttributes/dataTracking';

import language, { getLocale } from 'constants/language/language';
import matchMedia from 'constants/matchMedia/matchMedia';
import { getSearchUrl } from 'constants/urlHelper/urlHelper';
import SearchSuggest from 'containers/SearchSuggest/SearchSuggest';
import HeadLogo, { TYPE_SMALL } from 'components/molecules/HeadLogo/HeadLogo';
import Icon, { ICON_ARROW_BACK, ICON_CLOSE, ICON_SEARCH } from 'components/atoms/Icon/Icon';
import { Container, Row, Col } from 'components/atoms/Grid/Grid';

import { trackGenericInteraction } from 'tracking/tracking';

import { useFeatureToggle, useKeyPress } from 'hooks';

import bootstrap from 'scss/component.scss';
import styles from './HeaderSearch.scss';
import { useGoogleOptimize } from 'hooks/index';
import { cypressAttributes } from 'constants/cypress/cypress';
import { provideSearchLink } from 'constants/navigation/navigation';
import { connect } from 'react-redux';
import getCookieStorage from 'constants/storage/cookie';
import uuid from 'uuid/v5';
import { recoTrackProductSearch } from 'api/RecoAPI/RecoAPI';
import { RECO_COOKIE } from 'constants/storage/storageKeys';

const cookie = getCookieStorage();

const mapStateToProps = (state) => ({
  email: state?.user?.fields?.email?.value || undefined,
  onlineId: state?.user?.onlineId || undefined,
});

const HeaderSearch = ({
  searchString = '',
  setSearchString = () => {},
  loadProductSuggestions = () => {},
  loadSuggestions = () => {},
  clearProductSuggestions = () => {},
  startedProductSearch = () => {},
  isSearchFocused = false,
  isSticky = false,
  isCmsPage = false,
  isBrandPage = false,
  isSearchPage = false,
  categories = {},
  trackSearch = () => {},
  query = '',
  pathname = '',
  addSearchEntry = () => {},
  keywordRedirectUrl = '',
  email = undefined,
  onlineId = undefined,
}) => {
  const nextSearchEnabled = useFeatureToggle('nextSearchEnabled') === 'true' ?? false;

  const [placeholderText, setPlaceholderText] = useState('');
  const [stateSearchString, setStateSearchString] = useState(
    provideSearchString({
      pathname,
      query,
      searchString,
    })
  );

  const isMobile = !matchMedia(BREAKPOINTS.LG);
  const [hasFocus, setHasFocus] = useState(isSearchFocused);
  const inputRef = useRef(null);
  const areAbTestsEnabled = useRef(false);
  const initialLoad = useRef(true);

  // A/B test for searchShowPrice
  const abShowSearchPriceMobileFeature = useFeatureToggle('abShowSearchPriceMobile');
  const { variant: abShowSearchPriceMobileVariant, triggerActivation: triggerAbSearchShowPrice } = useGoogleOptimize(
    abShowSearchPriceMobileFeature,
    false
  );

  useEffect(() => {
    // if BE response has the attribute keywordRedirectUrl, FE should redirect to the provided URL
    if (keywordRedirectUrl) {
      const redirectURL = new URL(keywordRedirectUrl);
      const currentURL = new URL(window.location.href);

      // delete the search string from the UI
      setSearchInput('');

      // Get domain - without subdomain
      const currentHostname = redirectURL.hostname.toLowerCase();
      const redirectHostname = currentURL.hostname.toLowerCase();

      if (currentHostname !== redirectHostname) {
        window.location.href = redirectURL.href;
      } else {
        browserHistory.replace({ pathname: redirectURL.pathname });
      }
    }
  }, [keywordRedirectUrl]);

  const unsetFocus = () => {
    noScroll.off();
    setHasFocus(false);
    inputRef.current.blur();
  };

  useKeyPress('Escape', unsetFocus);

  useEffect(() => {
    setPlaceholderText(
      isMobile ? language('header.searchboxPlaceholderShort') : language('header.searchboxPlaceholder')
    );
  }, [isMobile]);

  useEffect(() => {
    // remove focus on scroll for touch devices to hide the keyboard - FOSS-216
    // this code was refactored, before it was done in SWAT-1635
    const isTouchDeviceBool = isTouchDevice();
    const handleScroll = () => inputRef?.current.blur();

    const debounceHandler = debounce(handleScroll, 0);

    if (isMobile && isTouchDeviceBool && hasFocus) {
      // fix for iOS: scroll to top, to see the input field when keyboard opens
      setTimeout(() => window.scrollTo(0, 0), 250);
      // fix for iOS: blur the focus on scroll to make whole content visible
      window.addEventListener('touchmove', debounceHandler);
    }

    return () => {
      window.removeEventListener('touchmove', debounceHandler);
    };
  }, [hasFocus, isMobile]);

  useEffect(() => {
    const debounceSuggestions = debounce(() => {
      if (stateSearchString.length >= 2 && initialLoad.current === false) {
        loadProductSuggestions(stateSearchString);
        loadSuggestions(stateSearchString);
      } else {
        clearProductSuggestions();
      }
      initialLoad.current = false;
    }, 300);
    debounceSuggestions();

    return () => {
      debounceSuggestions.cancel();
    };
  }, [stateSearchString, hasFocus]);

  // fix to change stateSearchString, if suggestions are clicked
  // maybe refactoring from scratch would make sense in NextJS version
  useEffect(() => {
    if (searchString) setStateSearchString(searchString);
  }, [searchString]);

  useEffect(() => {
    if (searchString) setStateSearchString(searchString);
  }, [searchString]);

  useEffect(() => {
    if (isMobile && hasFocus) {
      noScroll.on();
    } else {
      noScroll.off();
    }
  }, [hasFocus, isMobile]);

  const handleSearchString = useCallback(() => {
    const RESET_SEARCH_CONDITIONS =
      (isCmsPage && !isBrandPage) ||
      (isSearchPage && !searchString && !stateSearchString) ||
      (!isCmsPage && !isBrandPage && !isSearchPage);

    if (RESET_SEARCH_CONDITIONS) {
      setSearchInput('');
    }
  }, [pathname, isCmsPage, isBrandPage, isSearchPage]);

  useEffect(() => {
    handleSearchString();
  }, [handleSearchString]);

  const submitSearch = (e) => {
    if (e) e.preventDefault();

    if (inputRef.current.value) {
      clearProductSuggestions();
      handleSearch(inputRef.current.value);
      unsetFocus();
      inputRef.current.blur();
      window.scrollTo(0, 0);
    }
  };

  const setSearchInput = (searchValue) => {
    inputRef.current.value = searchValue;
    setStateSearchString(searchValue);
    setSearchString(searchValue);
  };

  const clearInput = () => {
    setSearchInput('');
    inputRef.current.focus();
  };

  const handleSearch = (searchTerm = '') => {
    let recoID = '';
    if (email) recoID = uuid(email, uuid.DNS);
    if (onlineId) recoID = onlineId;

    trackSearch(searchTerm, ACTIVE_SEARCH);
    recoTrackProductSearch(searchTerm, cookie.getItem(RECO_COOKIE), recoID);

    if (searchTerm.trim() !== '') {
      // only add search term to search history if not empty
      const { url } = provideSearchLink(searchTerm);
      addSearchEntry(searchTerm, url);
    }
    if (nextSearchEnabled) {
      const url = `/${getLocale()}/search?search=${encodeURIComponent(searchTerm)}`;
      window.location.href = url;
    } else {
      startedProductSearch();
      browserHistory.push({
        pathname: `${getSearchUrl(getLocale())}`,
        query: { search: searchTerm },
      });
    }
  };

  const cancelSearch = (formSubmitted = true) => {
    unsetFocus();
    trackUnfocus();
    formSubmitted && window.scrollTo(0, 0);
  };

  const handleChange = () => {
    const value = inputRef.current.value;
    if (value) {
      setSearchInput(value);
    } else {
      clearInput();
    }
  };

  const handleFocus = () => {
    if (!areAbTestsEnabled.current) {
      triggerAbSearchShowPrice();
      areAbTestsEnabled.current = true;
    }

    if (!matchMedia(BREAKPOINTS.LG)) {
      noScroll.on();
    }
    setHasFocus(true);
    trackFocus(true);
  };

  const trackUnfocus = () => {
    setHasFocus(false); //fix for double opening of suggestions overlay
    trackFocus(false);
  };

  const trackFocus = (focus = true) => {
    trackGenericInteraction(
      dataTracking({
        TYPE: 'search:field',
        FUNCTION: focus ? 'focus' : 'unfocus',
        INTERACTION: 'click',
        LINK: window.location.pathname,
      })
    );
  };

  return (
    <Container>
      <Row className={styles.flexWrapper}>
        <Col md={3} className={bootstrap.hiddenSmDown}>
          <HeadLogo isSticky={isSticky} />
        </Col>
        <Col xxs={3} className={bootstrap.hiddenMdUp}>
          <HeadLogo type={TYPE_SMALL} isSticky={isSticky} />
        </Col>
        <Col xl={6} md={8} sm={8} xxs={9} className={cx({ [styles.focused]: hasFocus })}>
          <form action="search" onSubmit={submitSearch} className={styles.form}>
            {hasFocus && (
              <button type="button" className={styles.arrowBackWrapper} onClick={() => cancelSearch(false)}>
                <Icon path={ICON_ARROW_BACK} className={styles.arrowBack} />
              </button>
            )}
            <input
              type="text"
              name="search"
              ref={inputRef}
              data-cy={cypressAttributes.search.searchInput}
              onChange={handleChange}
              className={cx(styles.searchInput)}
              placeholder={placeholderText}
              value={stateSearchString}
              autoComplete="off"
              maxLength={255}
              aria-label="search"
              onFocus={handleFocus}
            />
            <div className={styles.buttonsRight}>
              <button
                type="button"
                className={cx(styles.clearSearch, { [styles.hasFocus]: hasFocus })}
                onClick={clearInput}
                data-cy={cypressAttributes.search.clearSearch}
              >
                <Icon path={ICON_CLOSE} />
              </button>
              <button type="submit" className={styles.searchButton} data-cy={cypressAttributes.search.searchButton}>
                <Icon path={ICON_SEARCH} className={styles.searchButtonIcon} />
                <span className={bootstrap.hiddenSmDown}>{language('search.doSearch')}</span>
              </button>
            </div>
          </form>
          <CSSTransition
            in={hasFocus}
            timeout={{
              enter: 200,
            }}
            unmountOnExit
            classNames={{
              enterDone: styles.searchSuggestEnterDone,
            }}
          >
            <div className={cx(styles.searchSuggest)}>
              <SearchSuggest
                trackUnfocus={trackUnfocus}
                clearProductSuggestions={clearProductSuggestions}
                searchTerm={stateSearchString}
                mobile={matchMedia(BREAKPOINTS.XXS) && !matchMedia(BREAKPOINTS.LG)}
                categories={categories}
                closeSearch={unsetFocus}
                trackSearch={trackSearch}
                addHistoryEntry={addSearchEntry}
                hideSearchPriceMobile={Boolean(!abShowSearchPriceMobileVariant)}
              />
            </div>
          </CSSTransition>
        </Col>
      </Row>
    </Container>
  );
};

HeaderSearch.displayName = 'molecules/HeaderSearch';
HeaderSearch.propTypes = {
  searchString: PropTypes.string,
  setSearchString: PropTypes.func,
  loadProductSuggestions: PropTypes.func,
  loadSuggestions: PropTypes.func,
  clearProductSuggestions: PropTypes.func,
  startedProductSearch: PropTypes.func,
  isSearchFocused: PropTypes.bool,
  isCmsPage: PropTypes.bool,
  isBrandPage: PropTypes.bool,
  isSearchPage: PropTypes.bool,
  isSticky: PropTypes.bool,
  categories: PropTypes.object,
  trackSearch: PropTypes.func,
  query: PropTypes.string,
  pathname: PropTypes.string,
  addSearchEntry: PropTypes.func,
  setSearchFocus: PropTypes.func,
  keywordRedirectUrl: PropTypes.string,
  email: PropTypes.string,
  onlineId: PropTypes.string,
};

export default connect(mapStateToProps)(HeaderSearch);
