import React from "react";

import styles from "./OrderThankYou.scss"
import language from "constants/language/language";

export const OrderThankYou = () => {

  return (
    <div className={styles.wrapper}>
      <div className={styles.heartWrapper}>
        <img className={styles.heart} alt="heart" src="/images/icon/heart.svg"/>
      </div>
      <h1 className={styles.message}>{language("checkoutSuccess.header")}</h1>
    </div>
  )
}
