import React from 'react';

import styles from './SearchTips.scss';
import language from 'constants/language/language';
import Headline from 'components/atoms/Headline/Headline';

const SearchTips = () => (
  <div className={styles.wrapper}>
    <Headline>{language('searchTips.headline')}</Headline>
    <ul className={styles.list}>
      <li>{language('searchTips.tip1')}</li>
      <li>{language('searchTips.tip2')}</li>
      <li>{language('searchTips.tip3')}</li>
    </ul>
  </div>
);

SearchTips.displayName = 'molecules/SearchTips';

export default SearchTips;
