import { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

export const mapStateToProps = (state, ownProps) => {
  const wallId = ownProps?.wallId || '';
  return {
    bricks: state?.cms?.wall?.[wallId]?.content || [],
    productCode: wallId,
  };
};

const WallSingle = ({ children, bricks, productCode }) => {
  if (bricks.length <= 0) return null;

  return cloneElement(children, {
    bricks,
    productCode,
  });
};

WallSingle.displayName = 'containers/WallSingle';
WallSingle.propTypes = {
  children: PropTypes.element.isRequired,
  productCode: PropTypes.string,
  bricks: PropTypes.array,
};

export default connect(mapStateToProps)(WallSingle);
