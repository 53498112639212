import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import cx from 'classnames';

import CmsLink from 'containers/CmsLink/CmsLink';
import LoginIndicator from 'containers/LoginIndicator/LoginIndicator';

import PopOverUser from 'components/molecules/PopOverUser/PopOverUser';
import LanguageSelect from 'components/molecules/LanguageSelect/LanguageSelect';

import CmsLinkItem, { skins } from 'components/atoms/CmsLinkItem/CmsLinkItem';
import Icon, {
  ICON_ACCOUNT_CIRCLE,
  ICON_ACCOUNT_CIRCLE_OUTLINE,
  ICON_COMPARE_ARROWS,
  ICON_FAVORITE_BORDER,
  ICON_LOGOUT,
  ICON_ROCKET_LAUNCH,
  ICON_STORE_OUTLINE,
} from 'components/atoms/Icon/Icon';

import language, { getLocale } from 'constants/language/language';
import { CMS_UID_SERVICE } from 'constants/cms/cms';
import { ACCOUNT, B2B_PAGE, CMS, PRODUCT_COMPARISON, WATCHLIST } from 'constants/routePaths/routePaths';
import { blog } from 'constants/routePaths/routePathCombinations';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import styles from './MetaNavigation.scss';
import useTelesalesMode from 'hooks/useTelesalesMode';
import { LOGOUT_TELESALES_EMPLOYEE } from 'constants/storage/storageKeys';
import useEmployeeNumber from 'hooks/useEmployeeNumber';
import useTelesalesStore from 'hooks/useTelesalesStore';
import { cypressAttributes } from 'constants/cypress/cypress';
import WatchlistIndicator from 'containers/Indications/WatchlistIndicator';
import ProductComparisonIndicator from 'containers/Indications/ProductComparisonIndicator';
import useFeatureToggle from 'hooks/useFeatureToggle';
import useTelesalesGroup from 'hooks/useTelesalesGroup';

const MetaNavigation = ({ isSticky = false, isLoggedIn = false }) => {
  const popoverRef = useRef(null);
  const [showLogin, setShowLogin] = useState(false);
  let timer = null;
  const isTelesalesMode = useTelesalesMode();
  const employeeNumber = useEmployeeNumber();
  const storeCode = useTelesalesStore();
  const { isHeadquarterEmployee } = useTelesalesGroup();
  const b2bEnabled = /true/.test(useFeatureToggle('b2bToggle'));

  const handleMouseEnter = () => {
    clearTimeout(timer);
    setShowLogin(true);
  };

  const handleMouseLeave = (e) => {
    // Fixes https://atlassian.interdiscount.ch/jira/browse/SWAT-1052
    // e.relatedTarget may be the window object in FF when hovering the "native saved passwords" dropdown
    if (e?.relatedTarget === window || popoverRef.current.contains(e?.relatedTarget)) {
      return;
    }
    // blur active element (to make sure native saved password dropdown is closed as well)
    document.activeElement.blur();
    timer = setTimeout(() => setShowLogin(false), 500);
  };

  const logoutTelesales = () => {
    // logout will be handled in telesales app
    localStorage.setItem(LOGOUT_TELESALES_EMPLOYEE, true);
    window.location.replace(window.location.origin + '/telesales?logout=true');
  };

  const changeTelesalesStore = () => {
    window.location.replace(window.location.origin + '/telesales/store/select');
  };

  const goToTelesalesQuickorder = () => {
    window.location.replace(window.location.origin + '/telesales/articles/overview?quickOrder=true');
  };

  return (
    <>
      <div className={styles.flexWrapper}>
        <div className={styles.linkWrapper}>
          <CmsLink pageId={CMS_UID_SERVICE}>
            <CmsLinkItem skin={skins.METANAVIGATION} />
          </CmsLink>
          <div className={styles.linkItem}>
            <Link className={styles.link} to={mapPathToLocalizedUrl(getLocale(), blog, false)}>
              {language('navigation.blog')}
            </Link>
          </div>
          {b2bEnabled && (
            <div className={styles.linkItem}>
              <Link className={styles.link} to={mapPathToLocalizedUrl(getLocale(), [CMS, B2B_PAGE], false)}>
                {language('header.b2bHeaderText')}
              </Link>
            </div>
          )}
        </div>
        <div className={styles.linkWrapper}>
          {!isTelesalesMode && (
            <>
              <div className={styles.rightItem}>
                <Link to={mapPathToLocalizedUrl(getLocale(), [PRODUCT_COMPARISON], false)} className={styles.iconLink}>
                  <div className={cx(styles.iconLinkItem, styles.iconLinkItemAction)}>
                    <div className={styles.iconLinkItemSvg}>
                      <Icon path={ICON_COMPARE_ARROWS} className={styles.icon} />
                      <ProductComparisonIndicator />
                    </div>
                    <span>{language('header.productComparison')}</span>
                  </div>
                </Link>
              </div>
              <div className={styles.rightItem}>
                <Link to={mapPathToLocalizedUrl(getLocale(), [WATCHLIST], false)} className={styles.iconLink}>
                  <div className={cx(styles.iconLinkItem, styles.iconLinkItemAction)}>
                    <div className={styles.iconLinkItemSvg}>
                      <Icon path={ICON_FAVORITE_BORDER} className={styles.icon} />
                      <WatchlistIndicator />
                    </div>
                    <span>{language('header.shoppingList')}</span>
                  </div>
                </Link>
              </div>
            </>
          )}
          {isTelesalesMode ? (
            // Telesales nav with user code and telesales logout button
            <div className={styles.telesalesItems}>
              {isHeadquarterEmployee && (
                <div onClick={goToTelesalesQuickorder} className={styles.telesalesStore}>
                  <Icon path={ICON_ROCKET_LAUNCH} className={cx(styles.icon)} />
                  {language('header.quickOrder')}
                </div>
              )}
              {storeCode && (
                <div onClick={changeTelesalesStore} className={styles.telesalesStore}>
                  <Icon path={ICON_STORE_OUTLINE} className={cx(styles.icon)} />
                  {storeCode}
                </div>
              )}
              <div>
                <Icon path={ICON_ACCOUNT_CIRCLE_OUTLINE} className={cx(styles.icon)} />
                {employeeNumber}
              </div>
              <div onClick={logoutTelesales}>
                <Icon path={ICON_LOGOUT} className={cx(styles.icon, styles.logoutIcon)} />
              </div>
            </div>
          ) : (
            // Regular nav with My Account dropdown
            <div
              className={cx(styles.rightItem, { [styles.hovered]: showLogin })}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <Link className={styles.iconLink} to={mapPathToLocalizedUrl(getLocale(), [ACCOUNT], false)}>
                <div
                  className={cx(styles.iconLinkItem, styles.myAccount)}
                  data-cy={cypressAttributes.account.myAccountMenuItem}
                >
                  <Icon path={isLoggedIn ? ICON_ACCOUNT_CIRCLE : ICON_ACCOUNT_CIRCLE_OUTLINE} className={styles.icon} />
                  {isLoggedIn ? language('header.myAccount') : language('header.myAccountLogin')}
                </div>
              </Link>
            </div>
          )}
          <div className={styles.rightItem}>
            <LanguageSelect />
          </div>
        </div>
      </div>
      {showLogin && !isSticky && (
        <div
          className={styles.loginPopover}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          ref={popoverRef}
        >
          <LoginIndicator>
            <PopOverUser />
          </LoginIndicator>
        </div>
      )}
    </>
  );
};

MetaNavigation.propTypes = {
  isSticky: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
};
MetaNavigation.displayName = 'molecules/MetaNavigation';

export default MetaNavigation;
