import React from 'react';
import language from 'constants/language/language';
import styles from './Trustmarks.scss';

const Trustmarks = () => (
  <div className={styles.container}>
    <span className={styles.text}>{language('checkout.footer.trustmarks')}</span>
    <img
      className={styles.icon}
      src="/images/trustmarks/trustmark_oauth.png"
      alt={language('footer.trustmarks.oauth')}
    />
    <img className={styles.icon} src="/images/trustmarks/trustmark_ssl.png" alt={language('footer.trustmarks.ssl')} />
  </div>
);

Trustmarks.displayName = 'molecules/Trustmarks';

export default Trustmarks;
