import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { apiStatus } from 'constants/apiStatus/apiStatus';
import PageNotFound from 'components/templates/PageNotFound/PageNotFound';
import CmsProductListingContainer from 'containers/CmsProductListingContainer/CmsProductListingContainer';
import Breadcrumb, { types } from 'components/organisms/Breadcrumb/Breadcrumb';
import { searchQueryTypes } from 'components/organisms/CmsProductListing/CmsProductListing';
import Spinner from 'components/atoms/Spinner/Spinner';
import styles from './CmsContentSuccess.scss';
import { Col } from 'components/atoms/Grid/Grid';

export const mapStateToProps = (state) => ({
  requestForCmsContentStatus: state?.cms?.requestForCmsContentStatus,
  brandName: state?.cms?.brandName,
  categories: state?.categories,
});

const CmsContentSuccess = ({
  brandName = '',
  categories = {},
  children,
  isBrandPage = false,
  requestForCmsContentStatus = apiStatus.request,
}) => {
  const encodedBrandName = encodeURIComponent(brandName);
  switch (requestForCmsContentStatus) {
    case apiStatus.success:
      return children;
    case apiStatus.failure:
      if (isBrandPage) {
        return (
          <Col>
            <Breadcrumb type={types.invisible} />
            <CmsProductListingContainer
              searchQueryType={searchQueryTypes.BRAND}
              inputValue={brandName}
              searchQuery={`:relevance:brand:${encodedBrandName}`}
              isBrandPage
              brandFallback
            />
          </Col>
        );
      }
      return (
        <Col>
          <Breadcrumb type={types.invisible} />
          <PageNotFound categories={categories} />
        </Col>
      );
    default:
      return (
        <div className={styles.spinnerContainer}>
          <Spinner />
        </div>
      );
  }
};

CmsContentSuccess.displayName = 'containers/CmsContentSuccess';
CmsContentSuccess.propTypes = {
  brandName: PropTypes.string,
  categories: PropTypes.object,
  children: PropTypes.node.isRequired,
  isBrandPage: PropTypes.bool,
  requestForCmsContentStatus: PropTypes.oneOf([apiStatus.request, apiStatus.success, apiStatus.failure]),
};

export default connect(mapStateToProps)(CmsContentSuccess);
