import React from 'react';

import styles from './MandatoryFieldInfo.scss';
import language from 'constants/language/language';

const MandatoryFieldInfo = () => <small className={styles.mandatoryField}>{language('form.mandatoryFields')}</small>;

MandatoryFieldInfo.displayName = 'atoms/MandatoryFieldInfo';

export default MandatoryFieldInfo;
