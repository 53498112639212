import { PRIVACY_POLICY } from './links';

/* SWAT-5164: This takes the hard coded links from the links.js files instead of getitng them from CMS,
since the link to an external site (coop.ch) doesn't seem to work properly */
export const getPrivacyPolicyLink = (locale) => {
  if (locale) {
    const privacyLink = PRIVACY_POLICY.find((link) => link.locale === locale);

    return {
      url: privacyLink.url,
      text: privacyLink.text,
      openNewTab: true,
    };
  }
};
