import { handleActions } from 'redux-actions';

import {
  SEARCH_AS_YOU_TYPE_REQUEST,
  SUGGEST_AS_YOU_TYPE_REQUEST,
  STARTED_PRODUCT_SEARCH,
  SEARCH_AS_YOU_TYPE_SUCCESS,
  SUGGEST_AS_YOU_TYPE_SUCCESS,
  CLEAR_SEARCH_AS_YOU_TYPE,
  ROUTER_UPDATE_LOCATION,
  LOAD_PRODUCTS_SUCCESS,
  GET_TIME_CRITICAL_PRODUCT_DATA_SUCCESS,
} from 'constants/ActionTypes/ActionTypes';

import transform from 'constants/reducerHelper/reducerHelper';
import { getPriceData, getProductsWithNewestProductPriceData } from 'constants/price/price';

export const initialState = {
  productQueryDispatched: false,
  currentQuery: '',
};

export default handleActions(
  {
    [STARTED_PRODUCT_SEARCH]: (state) => transform(state).set('productQueryDispatched', true).value(),

    [LOAD_PRODUCTS_SUCCESS]: () => initialState,

    [SEARCH_AS_YOU_TYPE_REQUEST]: (state, action) => {
      if (state.productQueryDispatched) return state;
      const searchTerm = action.payload?.searchTerm;

      return transform(state).set('currentQuery', searchTerm).value();
    },

    [SUGGEST_AS_YOU_TYPE_REQUEST]: (state, action) => {
      if (state.productQueryDispatched) return state;
      const searchTerm = action.payload?.searchTerm;

      return transform(state).set('currentQuery', searchTerm).value();
    },

    [SEARCH_AS_YOU_TYPE_SUCCESS]: (state, action) => {
      if (state.productQueryDispatched) return state;
      const data = action.req?.data || {};
      const searchTerm = action.payload?.searchTerm;
      if (!data || !Object.keys(data).length || state.currentQuery !== searchTerm) return state;
      let products = data.products;

      // Get newest productPriceData and transform prices
      products = getProductsWithNewestProductPriceData(state, data.products);

      return transform(state)
        .set('result', {
          searchTerm,
          products,
          productsCount: data.totalNumberOfResults,
        })
        .value();
    },

    [SUGGEST_AS_YOU_TYPE_SUCCESS]: (state, action) => {
      if (state.productQueryDispatched) return state;
      const data = action.req?.data || {};
      const searchTerm = action.payload?.searchTerm;
      if (!data || !Object.keys(data).length || state.currentQuery !== searchTerm) return state;

      return transform(state).set('autocomplete', data.autocomplete).set('directlinks', data.directlinks).value();
    },

    [CLEAR_SEARCH_AS_YOU_TYPE]: () => initialState,

    [ROUTER_UPDATE_LOCATION]: () => initialState,

    [GET_TIME_CRITICAL_PRODUCT_DATA_SUCCESS]: (state, action) => {
      const productCodes = action?.payload?.productCodes || {};
      if (!Object.keys(productCodes).length) {
        return state;
      }

      const newState = transform(state);
      const timeCriticalProducts =
        action?.req?.data.reduce((accumulator, currentValue) => {
          accumulator[currentValue.code] = currentValue;
          return accumulator;
        }, {}) || {};
      const currentProducts = state?.result?.products || [];

      const products = [];
      currentProducts.forEach((currentProduct, index) => {
        const code = currentProduct.code;
        const timeCriticalProduct = timeCriticalProducts[code];
        const productPriceData = timeCriticalProduct
          ? getPriceData(timeCriticalProduct.productPriceData)
          : currentProduct.productPriceData;
        const product = { ...currentProduct, ...{ productPriceData } };
        products[index] = product;
      });
      newState.set('result.products', [...products]);

      return newState.value();
    },
  },
  initialState
);
