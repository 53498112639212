import React from 'react';
import PropTypes from 'prop-types';

import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';
import language from 'constants/language/language';
import { useKeyPress } from 'hooks';
import styles from './Stepper.scss';

const StepperButtons = ({
  prevLabel = '',
  nextLabel = '',
  onPrevClick = () => {},
  onNextClick = () => {},
  prevDisabled = false,
  nextDisabled = false,
  prevHidden = false,
  nextHidden = false,
  submitOnEnter = true,
}) => {
  useKeyPress(
    'Enter',
    () => {
      if (submitOnEnter && !nextDisabled) {
        onNextClick();
      }
    },
    [onNextClick]
  );

  return (
    <div className={styles.stepperButtons}>
      {!prevHidden && (
        <>
          <ButtonLink
            onClick={onPrevClick}
            variety={ButtonLink.varieties.basic}
            disabled={prevDisabled}
            id="formControl-stepperPrev-button"
          >
            {prevLabel || language('common.return')}
          </ButtonLink>{' '}
        </>
      )}
      {!nextHidden && (
        <ButtonLink
          onClick={onNextClick}
          variety={ButtonLink.varieties.flat}
          disabled={nextDisabled}
          id="formControl-stepperNext-button"
        >
          {nextLabel || language('common.continue')}
        </ButtonLink>
      )}
    </div>
  );
};

StepperButtons.displayName = 'molecules/Stepper/StepperButtons';

StepperButtons.propTypes = {
  prevLabel: PropTypes.string,
  nextLabel: PropTypes.string,
  onPrevClick: PropTypes.func,
  onNextClick: PropTypes.func,
  prevDisabled: PropTypes.bool,
  nextDisabled: PropTypes.bool,
  prevHidden: PropTypes.bool,
  nextHidden: PropTypes.bool,
  submitOnEnter: PropTypes.bool,
};

export default StepperButtons;
