import React from 'react';
import PropTypes from 'prop-types';

import InternalOrExternalLink from 'components/atoms/InternalOrExternalLink/InternalOrExternalLink';
import Icon, { ICON_KEYBOARD_ARROW_RIGHT } from 'components/atoms/Icon/Icon';
import styles from './LinkListItemWrapper.scss';

const LinkListItemWrapper = ({ linkData }) =>
  linkData.url ? (
    <InternalOrExternalLink
      itemProp="item"
      href={linkData.url}
      openNewTab={linkData.target === '_blank' || linkData.openNewTab || false}
    >
      <span className={styles.LinkIcon}>
        <Icon path={ICON_KEYBOARD_ARROW_RIGHT} size={Icon.sizes.xs} />
      </span>
      {linkData.text}
    </InternalOrExternalLink>
  ) : null;

LinkListItemWrapper.displayName = 'molecules/LinkList/ListItemWrapper';

LinkListItemWrapper.propTypes = {
  linkData: PropTypes.shape({
    text: PropTypes.string,
    url: PropTypes.string,
    target: PropTypes.string,
    openNewTab: PropTypes.bool,
  }).isRequired,
};

LinkListItemWrapper.defaultProps = {
  linkData: {
    text: '',
    url: '',
    target: '',
  },
};

export default LinkListItemWrapper;
