import React, { Component } from 'react';
import debounce from 'constants/helper/debounce';
import { noop } from 'constants/noop/noop';
import cx from 'classnames';
import PropTypes from 'prop-types';
import styles from './InputTextarea.scss';
import bootstrap from 'scss/component.scss';

export default class InputTextarea extends Component {
  static displayName = 'atoms/InputTextarea';

  static propTypes = {
    handleChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    showError: PropTypes.bool,
    name: PropTypes.string,
    value: PropTypes.string,
    debounce: PropTypes.bool,
    maxLength: PropTypes.number,
    placeholder: PropTypes.string,
    className: PropTypes.string,
  };

  static defaultProps = {
    handleChange: noop,
    onBlur: noop,
    onFocus: noop,
    showError: false,
    name: '',
    value: '',
    placeholder: '',
    debounce: true,
  };

  constructor(props) {
    super(props);
    this.textareaRef = React.createRef();
  }

  onChange = () => {
    const value = this.textareaRef.current.value;
    if (this.props?.maxLength - value.length === -1) {
      return;
    }
    if (this.props.debounce) {
      this.debounceOnChange(value);
    } else {
      this.props.handleChange(value);
    }
  };

  onBlur = () => {
    this.debounceOnBlur();
  };

  debounceOnChange = debounce((value) => {
    this.props.handleChange(value);
  }, 100);

  debounceOnBlur = debounce(() => {
    this.props.onBlur();
  }, 100);

  render() {
    const limitReached = this.props.maxLength - this.props.value?.length === 0;

    return (
      <div className={styles.wrapper}>
        <textarea
          ref={this.textareaRef}
          className={cx(
            bootstrap.formControl,
            styles.textarea,
            {
              [styles.hasDanger]: this.props.showError,
              [styles.limitReachedTextArea]: limitReached,
            },
            this.props.className
          )}
          defaultValue={this.props.value}
          onBlur={this.onBlur}
          onChange={this.onChange}
          onFocus={this.props.onFocus}
          aria-label={this.props.name}
          maxLength={this.props.maxLength}
          placeholder={this.props.placeholder}
        />
        {this.props.maxLength && (
          <span
            className={cx(styles.count, {
              [styles.limitReached]: limitReached,
            })}
          >
            {this.props.maxLength - this.props.value.length}
          </span>
        )}
      </div>
    );
  }
}
