import React from 'react';
import PropTypes from 'prop-types';
import language from 'constants/language/language';
import Headline from 'components/atoms/Headline/Headline';
import RatingProductSummary from 'components/molecules/Rating/RatingProductSummary/RatingProductSummary';
import RatingEditor from 'components/organisms/Rating/RatingEditor/RatingEditor';
import { Modal } from '@interdiscount/component-library-interdiscount';
import styles from './RatingModal.scss';
import bootstrap from 'scss/component.scss';

const RatingModal = ({
  rated = false,
  rating = {},
  onSubmitRating = () => {},
  onCancelRating = () => {},
  buttonsDisabled = false,
}) => (
  <Modal size="LG" onRequestClose={onCancelRating} isOpen>
    <div className={styles.title}>
      <Headline tag={Headline.tags.H1} margin={Headline.margins.MB_25}>
        {rated ? language('productRating.editRating') : language('productRating.addRating')}
      </Headline>
    </div>
    <RatingProductSummary {...rating} />
    <hr className={bootstrap.defaultHorizontalRule} />
    <div className={styles.editorWrapper}>
      <RatingEditor
        useInModal
        rating={rating}
        onSubmitRating={onSubmitRating}
        onCancelRating={onCancelRating}
        buttonsDisabled={buttonsDisabled}
      />
    </div>
  </Modal>
);

RatingModal.propTypes = {
  rated: PropTypes.bool,
  rating: PropTypes.object,
  onSubmitRating: PropTypes.func,
  onCancelRating: PropTypes.func,
  buttonsDisabled: PropTypes.bool,
};

export default RatingModal;
