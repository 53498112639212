import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';

import LinkListItemWrapper from './LinkListItemWrapper';
import styles from './LinkList.scss';

const LinkList = ({ data }) => (
  <ul className={styles.LinkList}>
    {data.map((link, i) => (
      <li className={styles.ListItem} key={`${i}_${uuid()}`}>
        <LinkListItemWrapper key={i} linkData={link} />
      </li>
    ))}
  </ul>
);

LinkList.displayName = 'molecules/LinkList';

LinkList.propTypes = {
  data: PropTypes.array.isRequired,
};

export default LinkList;
