import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { getDiscount } from 'constants/price/price';
import language from 'constants/language/language';
import { noop } from 'constants/noop/noop';
import styles from './DiscountFlag.scss';

export const skins = {
  DETAIL: 'DETAIL',
  TEASER: 'TEASER',
  MODAL: 'MODAL',
  LIST: 'LIST',
};

const DiscountFlag = ({ discount = {}, onClickAction = noop, clickable = false, skin = skins.DETAIL }) => {
  const renderDetailFlag = () => (
    <div className={styles.contentWrapper}>
      <div className={styles.title}>{`${language('discount.bon')} ${getDiscount(discount)}`}</div>
      <small className={styles.subLine}>{language('discount.flagText')}</small>
    </div>
  );

  const renderTeaserFlag = () => (
    <div className={styles.contentWrapper}>
      <span className={cx(styles.title, styles.titleOnly)}>{language('discount.bon')}</span>
    </div>
  );

  return (
    <div className={cx(styles.flagWrapper, styles[skin], { [styles.clickable]: clickable })} onClick={onClickAction}>
      <div className={cx(styles.flagContainer, styles[skin])}>
        {skin === skins.TEASER || skin === skins.LIST ? renderTeaserFlag() : renderDetailFlag()}
      </div>
    </div>
  );
};

DiscountFlag.displayName = 'atoms/DiscountFlag';
DiscountFlag.propTypes = {
  discount: PropTypes.object,
  onClickAction: PropTypes.func,
  clickable: PropTypes.bool,
  skin: PropTypes.oneOf(Object.values(skins)),
};

export default DiscountFlag;
