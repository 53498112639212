import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import RichMediaContainer from 'containers/RichMediaContainer/RichMediaContainer';
import { Row, Col } from 'components/atoms/Grid/Grid';
import Headline from 'components/atoms/Headline/Headline';
import ProductCode from 'components/atoms/ProductCode/ProductCode';
import Downloads from 'components/molecules/Downloads/Downloads';
import ProductFeatures from 'components/molecules/ProductFeatures/ProductFeatures';
import BrandCategoryLink from 'components/molecules/BrandCategoryLink/BrandCategoryLink';
import ProductDescription from 'components/molecules/ProductDescription/ProductDescription';
import DangerousGoods from 'components/molecules/DangerousGoods/DangerousGoods';
import productDetailAnchor from 'constants/productDetailAnchor/productDetailAnchor';
import { scrollIntoView as scrollToElement, scrollUpToId } from 'constants/scrollToElement/scrollToElement';
import productLinkConfiguration from 'constants/productLinkConfiguration/productLinkConfiguration';
import language from 'constants/language/language';
import styles from './ProductDetail.scss';
import bootstrap from 'scss/component.scss';
import HorizontalRule from 'components/atoms/HorizontalRule/HorizontalRule';

const ProductDetail = ({ product, categories = {} }) => {
  useEffect(() => {
    const anchor = window.location.hash;
    if (!!anchor && anchor === `#${productDetailAnchor}`) {
      scrollToElement(productDetailAnchor);
    }
  }, []);

  const onDescriptionToggled = (isCollapsed) => {
    if (isCollapsed) {
      const detailContainerId = productLinkConfiguration().description.linkTo;
      scrollUpToId(detailContainerId);
    }
  };

  const {
    code,
    description,
    markupDescription,
    classifications,
    name,
    manufacturerAID,
    manufacturer,
    brandName,
    documentDownloads,
    categoryCode,
    brandLogoUrl,
    brandPageUrl,
    hazardPictograms,
  } = product;

  return (
    <section>
      <h2 className={bootstrap.srOnly}>{language('product.details')}</h2>
      <div className={styles.visiblePrintBlock}>
        <Row>
          <Col>
            <div className={cx(bootstrap.dPrintNone, styles.descriptionHeader)}>
              <div>
                <Headline tag={Headline.tags.H2} margin={Headline.margins.NORMAL}>
                  {name}
                </Headline>
                {!!manufacturerAID && (
                  <p className={styles.manufacturerAID}>
                    <ProductCode label={language('product.manufacturerAID')} itemNumber={manufacturerAID} />
                  </p>
                )}
              </div>
              <BrandCategoryLink
                category={categoryCode}
                brandLogoUrl={brandLogoUrl}
                manufacturer={manufacturer}
                brandName={brandName}
                brandPageUrl={brandPageUrl || ''}
                categories={categories}
              />
            </div>
          </Col>
          {(!!description || !!markupDescription) && (
            <Col xl={9}>
              <div className={cx(styles.printHeadline, bootstrap.dNone, bootstrap.dPrintBlock)}>
                <Headline tag={Headline.tags.H2}>{language('product.description')}</Headline>
              </div>
              <ProductDescription
                description={description}
                markupDescription={markupDescription}
                onToggle={onDescriptionToggled}
              />
            </Col>
          )}
          <Col>
            <RichMediaContainer productCode={code} />
            <HorizontalRule />
          </Col>
        </Row>
        {!!classifications?.length && (
          <Row id={productLinkConfiguration().classifications.linkTo}>
            <Col>
              <ProductFeatures classifications={classifications} />
              <HorizontalRule />
            </Col>
          </Row>
        )}
        {!!hazardPictograms?.length && (
          <Row>
            <Col>
              <DangerousGoods hazardPictograms={product?.hazardPictograms} />
              <HorizontalRule />
            </Col>
          </Row>
        )}
        {!!documentDownloads?.length && (
          <Row id={productLinkConfiguration().downloads.linkTo}>
            <Col>
              <Downloads downloads={documentDownloads} />
              <HorizontalRule />
            </Col>
          </Row>
        )}
      </div>
    </section>
  );
};

ProductDetail.displayName = 'organisms/ProductDetail';
ProductDetail.propTypes = {
  product: PropTypes.object.isRequired,
  categories: PropTypes.object,
};

export default ProductDetail;
