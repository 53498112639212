import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import language, { getLocale } from 'constants/language/language';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';

import OptoutReasonForm from 'components/organisms/OptoutReasonForm/OptoutReasonForm';
import Headline from 'components/atoms/Headline/Headline';
import MarkdownBox from 'components/molecules/MarkdownBox/MarkdownBox';
import EmailProviderButton from 'components/molecules/EmailProviderButton/EmailProviderButton';
import NewsletterRegistrationContainer from 'containers/NewsletterRegistrationContainer/NewsletterRegistrationContainer';
import Spinner from 'components/atoms/Spinner/Spinner';
import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';
import IconLink from 'components/atoms/IconLink/IconLink';
import Icon, { ICON_KEYBOARD_ARROW_LEFT } from 'components/atoms/Icon/Icon';
import { Row, Col } from 'components/atoms/Grid/Grid';

import { getProvider, isProviderSupported } from 'constants/EmailProviderHelper/EmailProviderHelper';
import styles from './NewsletterFeedbackTemplate.scss';
import { newsletterFeedbackTypes } from 'constants/newsletterFeedbackTypes/newsletterFeedbackTypes';

const NewsletterFeedbackTemplate = ({
  heading,
  subhead,
  text,
  text2,
  feedbackType,
  sendOptoutReasons,
  hash,
  email,
}) => (
  <Row>
    <Col lg={8} md={12} className={styles.wrapper}>
      <div className={styles.backButton}>
        <IconLink
          onClick={() => {
            browserHistory.goBack();
          }}
        >
          <Icon path={ICON_KEYBOARD_ARROW_LEFT} className={styles.backIcon} />
          <span className={styles.backButtonText}>{language('newsletterFeedback.return')}</span>
        </IconLink>
      </div>
      <Headline tag={Headline.tags.H1} margin={Headline.margins.MB_20}>
        {heading}
      </Headline>
      {feedbackType === newsletterFeedbackTypes.UNSUBSCRIBE_PERSONALIZED && <strong>{subhead}</strong>}
      {feedbackType !== newsletterFeedbackTypes.UNSUBSCRIBE_PERSONALIZED && subhead && (
        <Headline tag={Headline.tags.H2}>{subhead}</Headline>
      )}
      <div className={styles.messageWrapper}>
        {feedbackType === newsletterFeedbackTypes.FAILURE && (
          <div className={styles.spinnerWrapper}>
            <Spinner status="FAILURE" />
          </div>
        )}
        <div className={styles.markdownBox}>
          <MarkdownBox markdown={text} />
        </div>
      </div>
      {!!text2 && (
        <div className={styles.text2}>
          <MarkdownBox markdown={text2} />
        </div>
      )}
      {feedbackType === newsletterFeedbackTypes.UNSUBSCRIBE && (
        <OptoutReasonForm sendOptoutReasons={sendOptoutReasons} hash={hash} />
      )}
      {feedbackType === newsletterFeedbackTypes.REGISTRATION && <NewsletterRegistrationContainer />}
      {feedbackType !== newsletterFeedbackTypes.UNSUBSCRIBE && feedbackType !== newsletterFeedbackTypes.REGISTRATION && (
        <Row>
          <Col className={styles.buttonWrapper}>
            {feedbackType === newsletterFeedbackTypes.SUBSCRIBE && isProviderSupported(getProvider(email)) && (
              <EmailProviderButton email={email} />
            )}
            <ButtonLink variety={ButtonLink.varieties.stroked} href={mapPathToLocalizedUrl(getLocale())}>
              {language('newsletterFeedback.backToHomepage')}
            </ButtonLink>
          </Col>
        </Row>
      )}
    </Col>
  </Row>
);

NewsletterFeedbackTemplate.propTypes = {
  heading: PropTypes.string.isRequired,
  subhead: PropTypes.string,
  text: PropTypes.string.isRequired,
  text2: PropTypes.string,
  feedbackType: PropTypes.oneOf(Object.values(newsletterFeedbackTypes)),
  sendOptoutReasons: PropTypes.func,
  hash: PropTypes.string,
  email: PropTypes.string,
};

NewsletterFeedbackTemplate.defaultProps = {
  feedbackType: newsletterFeedbackTypes.VERIFICATION,
};

export default NewsletterFeedbackTemplate;
