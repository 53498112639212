import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Headline from 'components/atoms/Headline/Headline';
import Icon, { ICON_GET_APP } from 'components/atoms/Icon/Icon';
import language, { getLocale } from 'constants/language/language';
import { B2B_REGISTRATION_PDF } from 'constants/routePaths/routePaths';

import { getEnv } from 'config/config';

import styles from './DownloadBox.scss';

export const boxTypes = {
  registration: 'registration',
  checkout: 'checkout',
};

const getDownloadPath = () => `${getEnv('web')}${B2B_REGISTRATION_PDF}${getLocale()}.pdf`;

export const DownloadBox = ({ type, pdfLink }) => (
  <div
    className={cx(styles.downloadBox, {
      [styles.checkoutBox]: type === boxTypes.checkout,
      [styles.registrationBox]: type === boxTypes.registration,
    })}
  >
    <Headline margin={Headline.margins.NORMAL}>{language(`downloadBox.${type}.title`)}</Headline>

    <p>{language(`downloadBox.${type}.body`)}</p>

    <p className={styles.subHead}>{language(`downloadBox.${type}.subTitle`, '')}</p>

    <div className={styles.downloadLinkBox}>
      <a href={pdfLink ?? getDownloadPath()} target="_blank" rel="noreferrer">
        <span className={styles.downloadLinkWrapper}>
          <Icon path={ICON_GET_APP} size={Icon.sizes.xs} />
          <span className={styles.fileText}>{language('downloadBox.filename')}</span>
        </span>
      </a>
    </div>
  </div>
);

DownloadBox.displayName = 'molecules/DownloadBox';

DownloadBox.propTypes = {
  type: PropTypes.string.isRequired,
  pdfLink: PropTypes.string,
};

export default DownloadBox;
