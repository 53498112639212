// @flow
import { getLocale } from 'constants/language/language';
import type { Headers, Pages, Page } from 'reducers/cms/cms';

let headers = {};

/**
 * Generates the pageHeaders for all the pages
 * @param pages
 * @returns {{}}
 */
const providePageHeaders = (pages: Pages): Headers => {
  if (typeof headers !== 'undefined' && Object.keys(headers).length > 0) {
    return headers;
  }
  const handleChildren = (parent: Page) => {
    if (typeof parent.pageId !== 'undefined' && parent.pageTitle) {
      headers[parent.pageId] = parent.pageTitle;
    }
    if (typeof parent.children !== 'undefined' && parent.children.length > 0) {
      parent.children.forEach(handleChildren);
    }
  };

  headers = {};

  Object.keys(pages).forEach((key) => handleChildren(pages[key]));

  return headers;
};

/**
 * Returns the pageTitle from the given headers for a certain contentId and locale
 * @param pages
 * @param contentId
 * @returns {string}
 */
export const providePageTitle = (pages: Pages, contentId: string): string => {
  const locale = getLocale();
  return providePageHeaders(pages)?.[contentId]?.[locale] || '';
};

export const reset = () => {
  headers = {};
};

export default providePageTitle;
