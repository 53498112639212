import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ProductVariant from 'components/molecules/ProductVariant/ProductVariant';
import style from './ProductVariants.scss';

const ProductVariants = ({ variants, variantsLoading, currentProduct }) => (
  <div className={cx({ [style.variantsLoading]: variantsLoading })}>
    {variants?.length &&
      variants.map((variant, index) => (
        <ProductVariant key={index} name={variant.name} options={variant.options} currentProduct={currentProduct} />
      ))}
  </div>
);

ProductVariants.displayName = 'molecules/ProductVariants';
ProductVariants.propTypes = {
  variants: PropTypes.array,
  variantsLoading: PropTypes.bool,
  currentProduct: PropTypes.object.isRequired,
};

export default ProductVariants;
