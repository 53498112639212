import React from 'react';
import PropTypes from 'prop-types';

const CmsElement = (props) => {
  const { contentElements, contentId, element, fullWidth, render } = props;

  const CmsComponent = contentElements[element.type];

  if (CmsComponent) {
    const children = <CmsComponent {...element} fullWidth={fullWidth} contentId={contentId} />;

    return typeof render === 'function' ? render({ children }) : children;
  }

  if (element.type === 'CmsCategoryListComponent') {
    // this condition is needed cuz CmsCategoryListComponent may appear somewhere in the site
    return <React.Fragment />;
  }

  return <div>Unknown element: {element.type}</div>;
};

CmsElement.displayName = 'molecules/CmsElement';

CmsElement.propTypes = {
  contentElements: PropTypes.object.isRequired,
  contentId: PropTypes.string.isRequired,
  element: PropTypes.object.isRequired,
  fullWidth: PropTypes.bool.isRequired,
  render: PropTypes.func,
};

export default CmsElement;
