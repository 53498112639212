import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { getEnv } from 'config/config';
import { condProperty } from 'constants/condProperty/condProperty';

import styles from './Image.scss';

/**
 * Get image alt text
 * @param imageData {array}
 * @returns {string}
 */
export const getAltText = (imageData) => {
  if (imageData) {
    let altText;
    const firstImage = imageData[0];
    if (firstImage && firstImage.altText) {
      altText = firstImage.altText;
    } else {
      altText = '';
    }

    return altText;
  }
  return '';
};

const sortImages = (a, b) => {
  if (!a?.size) return -1;
  // eslint-disable-next-line no-nested-ternary
  return parseInt(a.size, 10) < parseInt(b.size, 10) ? -1 : parseInt(a.size, 10) > parseInt(b.size, 10) ? 1 : 0;
};

/**
 * Get the closest (by width) appliable image for a certain width
 * @param images {array}
 * @param size {int}
 * @returns {object}
 */
export const getClosestImage = (images, size) => {
  if (!Array.isArray(images)) return undefined;

  const _size = parseInt(size, 10) || 256;
  const sortedImages = images.sort(sortImages);
  const filteredImages = sortedImages?.filter((image) => image.size?.length && image.url?.length);
  const closestImage = filteredImages?.find((image) => parseInt(image.size, 10) >= _size);

  return closestImage || filteredImages[filteredImages.length - 1];
};

/**
 * Get optimized image srcset string
 * @param images {array}
 * @param size {string}
 * @returns {string}
 */
export const getSrcSet = (images, size) => {
  if (!Array.isArray(images)) return undefined;

  const _size = parseInt(size, 10) || 256;
  const sortedImages = images.sort(sortImages);
  const filteredImages = sortedImages.filter((image) => image.size && image.url && parseInt(image.size, 10) >= _size);
  const imageVersions = filteredImages.map((image) => `${getEnv('web')}${image.url} ${image.size}w`);

  return imageVersions.join(', ');
};

/**
 * Get default image url for the src attribute of the image (used by old IE)
 * @param images {array}
 * @param size {string}
 * @returns {string}
 */
export const getImageUrl = (images, size) => {
  const image = getClosestImage(images, size) || {};

  // if !image.length => get the media url for a broken image - the "image not found" image
  if (!Object.keys(image).length || image?.url?.includes('noImageContainer')) return '/images/noImages.png';

  return image.url;
};

const Image = ({
  className = '',
  images = [],
  size,
  sizes = '50vw',
  alt,
  responsive = false,
  schemaProperty,
  lazyLoad = false,
  color = '#f8f7f7',
  aspectRatio = 100,
}) => {
  const containerClassNames = cx({
    [styles.ImageContainer]: true,
    [className]: className,
  });

  const imageRef = useRef();
  const imgUrlFallback = getImageUrl(images, size);
  const srcSetFallback = getSrcSet(images, size);

  return (
    <div
      className={containerClassNames}
      itemScope
      itemType="https://schema.org/ImageObject"
      style={{
        backgroundColor: color,
        paddingTop: `${aspectRatio}%`,
      }}
    >
      {responsive ? (
        <img
          ref={imageRef}
          src={condProperty(!lazyLoad, imgUrlFallback)}
          data-src={condProperty(lazyLoad, imgUrlFallback)}
          alt={alt}
          srcSet={condProperty(!lazyLoad, srcSetFallback)}
          data-srcset={condProperty(lazyLoad, srcSetFallback)}
          sizes={sizes}
          itemProp="contentUrl"
          property={schemaProperty}
          className={cx({ 'swiper-lazy': lazyLoad })}
        />
      ) : (
        <img
          ref={imageRef}
          src={condProperty(!lazyLoad, imgUrlFallback)}
          data-src={condProperty(lazyLoad, imgUrlFallback)}
          alt={alt}
          itemProp="contentUrl"
          property={schemaProperty}
          className={cx({ 'swiper-lazy': lazyLoad })}
        />
      )}
    </div>
  );
};

const areEqual = (prevProps, nextProps) =>
  !(
    prevProps.size !== nextProps.size ||
    prevProps.sizes !== nextProps.sizes ||
    JSON.stringify(prevProps.images) !== JSON.stringify(nextProps.images)
  );

Image.displayName = 'atoms/Image';

Image.propTypes = {
  imageId: PropTypes.string,
  className: PropTypes.string,
  alt: PropTypes.string.isRequired,
  color: PropTypes.string,
  aspectRatio: PropTypes.number,
  images: PropTypes.array,
  size: PropTypes.number,
  sizes: PropTypes.any,
  responsive: PropTypes.bool,
  schemaProperty: PropTypes.string,
  lazyLoad: PropTypes.bool,
  code: PropTypes.string,
};

export default React.memo(Image, areEqual);
