import TopProductsCategory from 'components/organisms/TopProductsCategory/TopProductsCategory';
import TopProductsPage from 'components/organisms/TopProductsPage/TopProductsPage';
import CmsTextComponent from 'components/organisms/CmsTextComponent/CmsTextComponent';
import CmsImageBanner from 'components/organisms/CmsImageBanner/CmsImageBanner';
import CmsImageTextComponent from 'components/organisms/CmsImageTextComponent/CmsImageTextComponent';
import TeaserContentList from 'components/organisms/TeaserContentList/TeaserContentList';
import ContactSubmit from 'containers/ContactSubmit/ContactSubmit';
import CmsProductListingContainer from 'containers/CmsProductListingContainer/CmsProductListingContainer';
import CmsVideoComponent from 'components/molecules/CmsVideoComponent/CmsVideoComponent';
import CardWall from 'components/organisms/CardWall/CardWall';
import ReturnsOverviewContainer from 'containers/ReturnsOverviewContainer/ReturnsOverviewContainer';
import ReturnsCreationContainer from 'containers/ReturnsCreationContainer/ReturnsCreationContainer';
import CmsIFrame from 'components/molecules/CmsIFrame/CmsIFrame';

export default {
  pages: {
    ProductCarouselComponent: TopProductsPage,
    CMSImageBannerComponent: CmsImageBanner,
    CMSTextComponent: CmsTextComponent,
    CMSImageTextComponent: CmsImageTextComponent,
    CMSContactFormComponent: ContactSubmit,
    CMSTeaserSiteComponent: TeaserContentList,
    CMSProductListingComponent: CmsProductListingContainer,
    CMSVideoComponent: CmsVideoComponent,
    CMSCardWallComponent: CardWall,
    CMSReturnsOverviewComponent: ReturnsOverviewContainer,
    CMSReturnsCreationComponent: ReturnsCreationContainer,
    CMSIFrameComponent: CmsIFrame,
  },
  categories: {
    ProductCarouselComponent: TopProductsCategory,
    CMSImageBannerComponent: CmsImageBanner,
    CMSTextComponent: CmsTextComponent,
    CMSImageTextComponent: CmsImageTextComponent,
    CMSContactFormComponent: ContactSubmit,
    CMSTeaserSiteComponent: TeaserContentList,
    CMSProductListingComponent: CmsProductListingContainer,
    CMSVideoComponent: CmsVideoComponent,
    CMSCardWallComponent: CardWall,
    CMSReturnsOverviewComponent: ReturnsOverviewContainer,
    CMSReturnsCreationComponent: ReturnsCreationContainer,
    CMSIFrameComponent: CmsIFrame,
  },
};
