import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import AddedToCartModal from 'containers/AddedToCartModal/AddedToCartModal';
import Swiper from 'components/molecules/ProductsBoxSwiper/ProductsBoxSwiper';
import Spinner from 'components/atoms/Spinner/Spinner';
import Headline from 'components/atoms/Headline/Headline';
import styles from './ProductsBox.scss';
import bootstrap from 'scss/component.scss';

const ProductsBox = ({
  anchor = '',
  fullWidth = false,
  loading = false,
  title = '',
  visibleElements = [],
  cyData = '',
}) => (
  <div id={anchor}>
    <div className={cx(styles.placeholder)}>
      <div className={cx({ [styles.fullWidthContainer]: fullWidth && __CLIENT__ })}>
        <div className={cx(styles.wrapper)}>
          <div className={styles.container}>
            {title && (
              <div className={cx(styles.headlineContainer)}>
                <Headline margin={Headline.margins.MB_20}>{title}</Headline>
              </div>
            )}
            {loading ? (
              <div className={cx(bootstrap.textCenter, styles.spinnerFlexbox)}>
                <Spinner />
              </div>
            ) : (
              <div className={cx(styles.swiperContainer)}>
                <Swiper cyData={cyData} visibleElements={visibleElements} />
              </div>
            )}
          </div>
          <AddedToCartModal />
        </div>
      </div>
    </div>
  </div>
);

ProductsBox.displayName = 'organisms/ProductsBox';
ProductsBox.propTypes = {
  anchor: PropTypes.string,
  fullWidth: PropTypes.bool,
  loading: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  visibleElements: PropTypes.array,
  cyData: PropTypes.string,
};

export default ProductsBox;
