import React from 'react';

import Icon, { ICON_DATE_RANGE } from 'components/atoms/Icon/Icon';
import LabelInput from 'components/molecules/FieldSetInput/LabelInput';

import i18n from 'constants/language/language';

import styles from './Contact.scss';

const ContactPhone = () => {
  return (
    <>
      <div className={styles.appointmentSection}>
        <LabelInput>{i18n('form.fields.recall.recallDate')}</LabelInput>
        <div className={styles.iconLink}>
          <Icon path={ICON_DATE_RANGE} />
          <a
            className={styles.recallAppointment}
            href={i18n('form.fields.recall.serviceOceanLink')}
            rel="noopener noreferrer"
            target="_blank"
          >
            {i18n('form.fields.recall.bookRecallAppointment')}
          </a>
        </div>
      </div>
      <div>
        <LabelInput>{i18n('form.fields.recall.phoneLabel')}</LabelInput>
        <div className={styles.openingTimes}>
          <p>{i18n('form.fields.recall.phoneNumber')}</p>
          <p>{i18n('form.fields.recall.openingTimes')}</p>
        </div>
      </div>
    </>
  );
};

ContactPhone.displayName = 'organisms/Contact.Phone';

export default ContactPhone;
