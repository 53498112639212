// @flow
import * as React from 'react';
import ProductViewToggle from 'containers/ProductViewToggle/ProductViewToggle';
import GridListToggle from 'components/molecules/GridListToggle/GridListToggle';
import { Col, Container, Row } from 'components/atoms/Grid/Grid';

import language from 'constants/language/language';

import styles from './ProductListHeader.scss';
import SortingProducts from '../SortingProducts/SortingProducts';

import cx from 'classnames';

type IProps = {
  hasCmsContent?: boolean,
  sorts: Array<{
    code: string,
    name: string,
    selected: boolean,
  }>,
  onSortChange: (sortKey: string) => void,
};

const ProductListHeader = ({ sorts = [], onSortChange, hasCmsContent }: IProps) => {
  const sortOptions = Array.isArray(sorts) ? sorts.reduce((acc, sort) => ({ ...acc, [sort.code]: sort.name }), {}) : [];
  const currentSortOption = Array.isArray(sorts) ? sorts.find((sort) => sort.selected) : null;
  const currentSortValue = currentSortOption ? currentSortOption.code : 'relevance';

  return (
    <Container className={cx(styles.productListHeader, { [styles.noCmsContent]: !hasCmsContent })}>
      <Row className={styles.alignSort}>
        <Col sm={12} md={6} className={styles.listInfo}>
          <span className={styles.viewText}>{language('productsOverview.viewType.view')}</span>
          <div className={styles.toggler}>
            <ProductViewToggle>
              <GridListToggle />
            </ProductViewToggle>
          </div>
        </Col>
        <Col sm={12} md={6} className={styles.listSort}>
          <span className={styles.sortText}>{language('productsOverview.sortingProducts.text')}</span>
          <div className={styles.sortWrapper}>
            <SortingProducts
              onChange={(e) => onSortChange(e.target.value)}
              options={sortOptions}
              value={currentSortValue}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

ProductListHeader.displayName = 'molecules/ProductListHeader';

export default ProductListHeader;
