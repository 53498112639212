import React from 'react';
import PropTypes from 'prop-types';
import { TOP_CONTENT_ANCHOR } from 'constants/scrollToElement/scrollToElement';
import Header from 'components/organisms/Header/Header';
import Footer from 'components/organisms/Footer/Footer';
import { Container } from 'components/atoms/Grid/Grid';
import StickyContainer from 'components/atoms/Sticky/StickyContainer';
import ErrorBoundary from 'containers/ErrorBoundary/ErrorBoundary';
import FlyInContainer from 'containers/FlyInContainer/FlyInContainer';
import FeatureTogglingProvider from 'contexts/FeatureTogglingProvider/FeatureTogglingProvider';
import { getNavigationNodes } from 'actions/cmsActions/cmsActions';
import { getCategories } from 'actions/categoryActions/categoryActions';
import { loadFeatureConfig, loadFlashMessages } from 'actions/uiActions/uiActions';
import { cypressAttributes } from 'constants/cypress/cypress';

const DefaultLayout = ({ children }) => (
  <FeatureTogglingProvider>
    <StickyContainer>
      <Header />
      <ErrorBoundary>
        <FlyInContainer cyData={cypressAttributes.comparisonList.flashMessage} />
        <Container id={TOP_CONTENT_ANCHOR}>{children}</Container>
      </ErrorBoundary>
      <Footer />
    </StickyContainer>
  </FeatureTogglingProvider>
);

DefaultLayout.need = [getNavigationNodes, getCategories, loadFeatureConfig, loadFlashMessages];

DefaultLayout.displayName = 'layouts/DefaultLayout';
DefaultLayout.propTypes = {
  children: PropTypes.node,
};

export default DefaultLayout;
