import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import language from 'constants/language/language';
import { noop } from 'constants/noop/noop';
import Price from 'components/atoms/Price/Price';
import Counter from 'components/atoms/Counter/Counter';
import styles from './CartProductCounter.scss';

export const getPrice = (entry = {}) =>
  entry.postDiscountPrice?.value
    ? entry.postDiscountPrice?.value || entry.basePrice?.value || 0
    : entry.basePrice?.value || entry.postDiscountPrice?.value || 0;

export const getOrderLimit = (product = {}) => {
  // 200 as default value is a business decision to prevent scam orders and
  // force B2B users to use the telesales channel. It's also the BE default value.
  const maxOrderValue = product.maxOrderValue === 0 ? 0 : Number(product.maxOrderValue) || Infinity;
  const stockLevel = product.stock?.stockLevel === 0 ? 0 : Number(product.stock?.stockLevel) || Infinity;
  const orderLimit = Math.min(maxOrderValue, stockLevel);
  return orderLimit !== Infinity ? orderLimit : 200;
};

const CartProductCounter = ({
  cartEntry = {},
  product = {},
  totalPrice = {},
  updateStore = noop,
  isDisabled = false,
}) => {
  const [quantity, setQuantity] = useState(cartEntry.quantity);
  const regex = /^([0-9]+)$/;
  const minQuantity = 1;

  useEffect(() => {
    if (quantity !== cartEntry.quantity) {
      setQuantity(cartEntry.quantity);
    }
  }, [cartEntry]);

  const totalPriceValue = totalPrice.value || 0;
  const orderLimit = getOrderLimit(product);
  const updateCounter = (newQuantity) => {
    setQuantity(newQuantity);
    updateStore(newQuantity);
  };

  const handleKeyboardChange = (newQuantity) => {
    const validatedNewQuantity = newQuantity > orderLimit ? orderLimit : newQuantity || minQuantity;
    updateCounter(validatedNewQuantity);
  };

  const handleKeyPress = (event) => {
    if (!regex.test(event.key)) event.preventDefault();
  };

  return (
    <>
      <div className={styles.priceWrapper}>
        <Price productPriceData={{ finalPrice: { value: totalPriceValue } }} skin={Price.SKINS.PRODUCTCARD} />
      </div>
      <div className={styles.counter}>
        <Counter
          quantity={quantity}
          maxQuantity={orderLimit}
          minQuantity={minQuantity}
          onIncrement={() => updateCounter(quantity + 1)}
          onDecrement={() => updateCounter(quantity - 1)}
          onChange={(newQuantity) => handleKeyboardChange(newQuantity)}
          onKeyPress={(event) => handleKeyPress(event)}
          isDisabled={isDisabled}
        />
        {quantity >= orderLimit && (
          <div className={styles.error}>{`${language('cart.max')} ${orderLimit} ${language('cart.pieces')}`}</div>
        )}
      </div>
    </>
  );
};

CartProductCounter.displayName = 'molecules/CartProductCounter';
CartProductCounter.propTypes = {
  cartEntry: PropTypes.object,
  product: PropTypes.object,
  totalPrice: PropTypes.object,
  updateStore: PropTypes.func,
  isDisabled: PropTypes.bool,
};

export default CartProductCounter;
