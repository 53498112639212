import React from 'react';
import PropTypes from 'prop-types';
import styles from './LabelInput.scss';
import classNames from 'classnames/bind';
import language from 'constants/language/language';

import Tooltip from 'components/atoms/Tooltip/Tooltip';

const cx = classNames.bind(styles);

const LabelInput = ({
  children,
  required = false,
  tooltip = '',
  id = '',
  disabled = false,
  description = '',
  requiredAsteriskVisible = true,
  optionalTextVisible = false,
}) => (
  <label
    htmlFor={id}
    className={cx({
      label: true,
      disabled,
    })}
  >
    <div className={styles.labelContainer}>
      <span className={styles.labelText}>{children}</span>
      {!required && optionalTextVisible && (
        <span className={styles.optionalText}>({language('form.optionalField')})</span>
      )}
      {!!required && !!children && requiredAsteriskVisible && <abbr title={language('form.mandatoryField')}>*</abbr>}
      {description && <span className={styles.description}>{description}</span>}
      {!disabled && tooltip && <Tooltip infoText={tooltip} />}
    </div>
  </label>
);

LabelInput.propTypes = {
  children: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  required: PropTypes.bool,
  requiredAsteriskVisible: PropTypes.bool,
  optionalTextVisible: PropTypes.bool,
  showError: PropTypes.bool,
  tooltip: PropTypes.string,
  disabled: PropTypes.bool,
  description: PropTypes.string,
};

LabelInput.displayName = 'molecules/LabelInput';

export default LabelInput;
