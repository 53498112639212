import React, { cloneElement, Component } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import bootstrap from 'scss/component.scss';
import language from 'constants/language/language';

export const mapStateToProps = (state, ownProps) => {
  const referenceItemCodes = state.products[ownProps.productCode].referenceItemCodes || [];
  const references = [];

  referenceItemCodes.forEach((referenceCode) => {
    const productReference = state.products[referenceCode];
    if (productReference) {
      references.push({ ...productReference, elementTitle: language('product.accessoriesHeader.title') });
    }
  });

  return {
    references,
  };
};

class Accessories extends Component {
  static displayName = 'containers/Accessories/Accessories';

  static propTypes = {
    children: PropTypes.node.isRequired,
    productCode: PropTypes.string,
    references: PropTypes.array,
  };

  static defaultProps = {
    productCode: '',
    references: [],
  };

  render() {
    const { children, references } = this.props;

    return (
      <div className={cx(bootstrap.container, bootstrap.hiddenPrint)}>
        {cloneElement(children, {
          elements: references,
          loadingProducts: !references.length,
        })}
      </div>
    );
  }
}

export default connect(mapStateToProps)(Accessories);
