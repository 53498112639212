import { useEffectOnce, useScript } from 'usehooks-ts';

/**
 * For RUM to work we have to load the lux script and additionally initialise some global LUX object
 * @see https://support.speedcurve.com/docs/get-started-real-user-monitoring#step-1--insert-the-rum-snippet-and-adjust-your-sample-rate
 */

export const useSpeedCurve = () => {
  useScript('https://cdn.speedcurve.com/js/lux.js?id=4369705836');

  useEffectOnce(() => {
    window.LUX = (function () {
      function n() {
        return Date.now ? Date.now() : +new Date();
      }
      var r,
        e = n(),
        t = window.performance || {},
        a = t.timing || { navigationStart: (null === (r = window.LUX) || void 0 === r ? void 0 : r.ns) || e };
      function o() {
        return t.now ? t.now() : n() - a.navigationStart;
      }
      window.LUX = {
        ac: [],
        addData: function (n, r) {
          return window.LUX.cmd(['addData', n, r]);
        },
        cmd: function (n) {
          return window.LUX.ac.push(n);
        },
        init: function () {
          return window.LUX.cmd(['init']);
        },
        mark: function () {
          for (var n = [], r = 0; r < arguments.length; r++) n[r] = arguments[r];
          if (t.mark) return t.mark.apply(t, n);
          var e = n[0],
            a = n[1] || {};
          void 0 === a.startTime && (a.startTime = o());
          window.LUX.cmd(['mark', e, a]);
        },
        markLoadTime: function () {
          return window.LUX.cmd(['markLoadTime', o()]);
        },
        measure: function () {
          for (var n = [], r = 0; r < arguments.length; r++) n[r] = arguments[r];
          if (t.measure) return t.measure.apply(t, n);
          var e,
            a = n[0],
            i = n[1],
            u = n[2];
          e = 'object' == typeof i ? n[1] : { start: i, end: u };
          e.duration || e.end || (e.end = o());
          window.LUX.cmd(['measure', a, e]);
        },
        send: function () {
          return window.LUX.cmd(['send']);
        },
        ns: e,
      };
      var i = window.LUX;
      if (
        ((window.LUX_ae = []),
        window.addEventListener('error', function (n) {
          window.LUX_ae.push(n);
        }),
        (window.LUX_al = []),
        'function' == typeof PerformanceObserver && 'function' == typeof PerformanceLongTaskTiming)
      ) {
        var u = new PerformanceObserver(function (n) {
          for (var r = n.getEntries(), e = 0; e < r.length; e++) window.LUX_al.push(r[e]);
        });
        try {
          u.observe({ type: 'longtask' });
        } catch (n) {
          console.error(n);
        }
      }
      return i;
    })();
  });
};
