import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import HorizontalRule from 'components/atoms/HorizontalRule/HorizontalRule';
import RichMediaContent from 'components/organisms/RichMediaContent/RichMediaContent';

const mapStateToProps = (state) => ({
  richMediaContents: state.cms?.richMediaContent,
});

const RichMediaContainer = ({ productCode, richMediaContents }) => {
  const richMediaContent = richMediaContents[productCode];

  const Content = () => (
    <>
      <HorizontalRule />
      <RichMediaContent
        title={richMediaContent?.title}
        text={richMediaContent?.text}
        elements={richMediaContent?.elements}
        footNotes={richMediaContent?.footnotes}
        trackingAttributes={{ sku: productCode }}
      />
    </>
  );

  if (!richMediaContent) return null;
  return <Content />;
};

RichMediaContainer.propTypes = {
  productCode: PropTypes.string,
  richMediaContents: PropTypes.object,
};

export default connect(mapStateToProps, null)(RichMediaContainer);
