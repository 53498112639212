import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Breadcrumb, { types } from 'components/organisms/Breadcrumb/Breadcrumb';
import Pagetitle from 'components/atoms/Pagetitle/Pagetitle';
import ProductComparisonEmpty from 'components/molecules/ProductComparisonEmpty/ProductComparisonEmpty';
import ProductComparisonFull from 'components/molecules/ProductComparisonFull/ProductComparisonFull';
import AddedToCartModal from 'containers/AddedToCartModal/AddedToCartModal';

import language from 'constants/language/language';
import { apiStatus as API_STATUS } from 'constants/apiStatus/apiStatus';

export class ProductComparison extends Component {
  UNSAFE_componentWillMount() {
    if (this.props.productCodes.length) {
      this.props.getComparisonList(this.props.productCodes, true, true);
    }
  }

  render() {
    const {
      comparisonList,
      changeCategory,
      selectedCategory,
      categories,
      products,
      getComparisonList,
      productCodes,
      removeFromComparison,
      apiStatus,
      addToWatchlist,
      removeFromWatchlist,
      isProductInWatchlist,
      comparisonCategories,
    } = this.props;

    return (
      <div>
        <Breadcrumb type={types.invisible} />
        <Pagetitle centered>{language('productComparison.header')}</Pagetitle>
        {!Object.keys(comparisonList).length && apiStatus !== API_STATUS.request ? (
          <ProductComparisonEmpty />
        ) : (
          <ProductComparisonFull
            comparisonList={comparisonList}
            changeCategory={changeCategory}
            selectedCategory={selectedCategory}
            categories={categories}
            comparisonCategories={comparisonCategories}
            products={products}
            getComparisonList={getComparisonList}
            productCodes={productCodes}
            removeProduct={removeFromComparison}
            apiStatus={apiStatus}
            addToWatchlist={addToWatchlist}
            removeFromWatchlist={removeFromWatchlist}
            isProductInWatchlist={isProductInWatchlist}
          />
        )}
        <AddedToCartModal />
      </div>
    );
  }
}

ProductComparison.displayName = 'templates/ProductComparison';
ProductComparison.propTypes = {
  addToWatchlist: PropTypes.func,
  apiStatus: PropTypes.string,
  categories: PropTypes.object,
  changeCategory: PropTypes.func,
  comparisonCategories: PropTypes.object,
  comparisonList: PropTypes.object,
  getComparisonList: PropTypes.func,
  isProductInWatchlist: PropTypes.func,
  products: PropTypes.array,
  productCodes: PropTypes.array,
  removeFromComparison: PropTypes.func,
  removeFromWatchlist: PropTypes.func,
  selectedCategory: PropTypes.string,
};
ProductComparison.defaultProps = {
  addToWatchlist: () => {},
  apiStatus: '',
  categories: {},
  changeCategory: () => {},
  comparisonCategories: {},
  comparisonList: {},
  getComparisonList: () => {},
  isProductInWatchlist: () => {},
  products: [],
  productCodes: [],
  removeFromComparison: () => {},
  removeFromWatchlist: () => {},
  selectedCategory: '',
};

export default ProductComparison;
