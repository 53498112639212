import React from 'react';
import Meta from 'containers/Meta/Meta';
import DocumentsTemplate from 'components/templates/Documents/Documents';
import language from 'constants/language/language';

const Documents = () => (
  <>
    <Meta title={language('orderDocuments.title')} />
    <DocumentsTemplate />
  </>
);

Documents.displayName = 'routes/Documents';

export default Documents;
