import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { availabilityStates } from 'constants/deliveryStates/availabilityStates';
import Icon, {
  ICON_CALENDAR_CHECK_OUTLINE,
  ICON_CALENDAR_DOWNLOAD_OUTLINE,
  ICON_CALENDAR_QUESTION_OUTLINE,
  ICON_CHECK_SQUARE_OUTLINE,
  ICON_CLOCK_SQUARE_OUTLINE,
  ICON_DOWNLOAD_SQUARE_OUTLINE,
  ICON_MINUS_SQUARE_OUTLINE,
  ICON_QUESTION_SQUARE_OUTLINE,
  ICON_STORE_OUTLINE,
} from 'components/atoms/Icon/Icon';
import styles from './DeliveryStateIcon.scss';
import { getProductDateYearDifferenceFromNow } from 'constants/date/date';

const StatusColors = {
  GREY: 'grey',
  GREEN: 'green',
};

export const DeliverIconState = {
  NOT_STOCK_OR_ONORDER: 'notStockOrOnOder',
  PRE_ORDER: 'preOrder',
  PICKUP_DELIVERY: 'pickupDelivery',
};

export const DeliveryStateIcons = ({ type, duration, releaseDateString, availability }) => {
  const iconProps = {
    size: Icon.sizes.sm,
    className: cx(styles.dlvIconCh, styles[StatusColors.GREY]),
  };
  const releaseDateInYears = getProductDateYearDifferenceFromNow(releaseDateString);

  switch (type) {
    case DeliverIconState.PICKUP_DELIVERY:
      if (duration <= 4) {
        iconProps.path = ICON_CHECK_SQUARE_OUTLINE;
        iconProps.className = cx(styles.dlvIconCh, styles[StatusColors.GREEN]);
      } else if (duration <= 179) {
        iconProps.path = ICON_CLOCK_SQUARE_OUTLINE;
      } else {
        iconProps.path = ICON_QUESTION_SQUARE_OUTLINE;
      }
      break;
    case DeliverIconState.PRE_ORDER:
      iconProps.path = releaseDateInYears <= 2 ? ICON_CALENDAR_CHECK_OUTLINE : ICON_CALENDAR_QUESTION_OUTLINE;
      break;
    case DeliverIconState.NOT_STOCK_OR_ONORDER:
      if (availability === availabilityStates.M) {
        iconProps.path = ICON_DOWNLOAD_SQUARE_OUTLINE;
        iconProps.className = cx(styles.dlvIconCh, styles[StatusColors.GREEN]);
      } else if (availability === availabilityStates.Y) {
        iconProps.path = ICON_CALENDAR_DOWNLOAD_OUTLINE;
      } else if (availability === availabilityStates.G2) {
        iconProps.path = ICON_QUESTION_SQUARE_OUTLINE;
      } else if (availability === availabilityStates.Z) {
        iconProps.path = ICON_MINUS_SQUARE_OUTLINE;
      }
      break;
    default:
      break;
  }

  return <Icon {...iconProps} />;
};

DeliveryStateIcons.propTypes = {
  type: PropTypes.oneOf(Object.values(DeliverIconState)),
  duration: PropTypes.number,
  releaseDateString: PropTypes.string,
  availability: PropTypes.string,
};

DeliveryStateIcons.displayName = 'molecules/DeliveryStateIcon';

export const StoreIcon = ({ productStore, articleAvailableStore }) => {
  return (
    <Icon
      path={ICON_STORE_OUTLINE}
      size={Icon.sizes.sm}
      className={
        productStore >= articleAvailableStore
          ? cx(styles.dlvIconCh, styles[StatusColors.GREEN])
          : cx(styles.dlvIconCh, styles[StatusColors.GREY])
      }
    />
  );
};
StoreIcon.propTypes = {
  productStore: PropTypes.number,
  articleAvailableStore: PropTypes.number,
};

export default DeliveryStateIcons;
