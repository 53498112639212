import React from 'react';
import PropTypes from 'prop-types';
import { Link, browserHistory } from 'react-router';
import { connect } from 'react-redux';

import { setSearchString } from 'actions/uiActions/uiActions';

import { getLocale } from 'constants/language/language';
import { getSearchUrl } from 'constants/urlHelper/urlHelper';
import cx from 'classnames';

import AutoSuggestHighlighter from 'components/atoms/AutoSuggestHighlighter/AutoSuggestHighlighter';
import Icon, { ICON_SEARCH } from 'components/atoms/Icon/Icon';

import styles from './SearchSuggestions.scss';
import useFeatureToggle from 'hooks/useFeatureToggle';

const mapDispatchToProps = {
  setSearch: setSearchString,
};

const SearchSuggestions = ({ title, searchTerm, isActive, setSearch, onClick }) => {
  const nextSearchEnabled = useFeatureToggle('nextSearchEnabled') === 'true' ?? false;
  const handleSearch = () => {
    if (!nextSearchEnabled) {
      setSearch(title);
    }
    onClick();
    if (!nextSearchEnabled) {
      browserHistory.push({ pathname: `${getSearchUrl(getLocale())}`, query: { search: title } });
    }
  };

  return (
    <Link href="#" onClick={handleSearch} className={cx(styles.searchSuggestion, { [styles.active]: isActive })}>
      <Icon path={ICON_SEARCH} className={styles.icon} />
      <AutoSuggestHighlighter searchTerm={searchTerm} className={styles.highlight}>
        {title}
      </AutoSuggestHighlighter>
    </Link>
  );
};

SearchSuggestions.displayName = 'molecules/SearchSuggestions';
SearchSuggestions.propTypes = {
  title: PropTypes.string.isRequired,
  searchTerm: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  setSearch: PropTypes.func,
  onClick: PropTypes.func,
};

SearchSuggestions.defaultProps = {
  onClick: () => {},
  setSearch: () => {},
};

export default connect(undefined, mapDispatchToProps)(SearchSuggestions);
