import { cloneElement, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addAccessoryToCart } from 'actions/cartActions/cartActions';

const mapStateToProps = (state, ownProps) => {
  const productCode = ownProps.accessoryCode;
  const isInCart = state.cart.entries.findIndex((entry) => entry.productCode === productCode) >= 0;

  return {
    isInCart,
  };
};

export class AddAccessoryToCart extends Component {
  static displayName = 'containers/AddAccessoryToCart';

  static propTypes = {
    accessoryCode: PropTypes.string.isRequired,
    addAccessoryToCart: PropTypes.func,
    children: PropTypes.node,
    isInCart: PropTypes.bool,
    productAttributes: PropTypes.object,
  };

  handleClick = (e, quantity = 1) => {
    e.preventDefault();
    this.props.addAccessoryToCart(this.props.accessoryCode, quantity, this.props.productAttributes);
  };

  render() {
    const { handleClick } = this;
    const { isInCart } = this.props;
    return cloneElement(this.props.children, { handleClick, isInCart });
  }
}

export default connect(mapStateToProps, { addAccessoryToCart })(AddAccessoryToCart);
