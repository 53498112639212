import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NON_CMS_PAGE } from 'constants/cms/cms';
import language from 'constants/language/language';
import Meta from 'containers/Meta/Meta';
import { getFlattendNavigationNodes } from 'constants/navigation/navigation';
import pageTitle from 'constants/pageTitle/pageTitle';
import { getEnv } from 'config/config';

export const mapStateToProps = (state, ownProps) => ({
  pages: state?.cms?.navigation?.pages || {},
  metaData: state?.cms?.pages?.[ownProps?.contentId]?.metaData || {},
  noIndex: getFlattendNavigationNodes(Object.values(state?.cms?.navigation?.pages || {})).find(
    (page) => page.pageId === ownProps.contentId
  )?.noIndex,
  brandName: state?.cms?.brandName,
});

const CmsPagesDispatcher = ({ brandName = '', contentId = '', metaData = {}, noIndex = false, pages = {} }) => {
  const { metaDescription, metaKeywords, searchTitle } = metaData;

  // use user-specified page title rather than cms page name
  let title = !searchTitle ? pageTitle(pages, contentId) : searchTitle;
  let meta = metaDescription;

  if ((brandName && contentId === NON_CMS_PAGE && !String(title).length) || (brandName && !metaDescription)) {
    // Set brandname as default page title if no other title is defined in backend
    // @see https://atlassian.interdiscount.ch/jira/browse/SPQR-9970
    title = language('pageTitles.brandPage').replace('{BRAND}', brandName);
    meta = language('meta.brandPage').replace('{BRAND}', brandName);
  }

  const setCanonicalUrl = () => {
    if (!__CLIENT__) return null;

    const url = new URL(window.location.href);
    let { pathname } = url;

    return [{ rel: 'canonical', href: `${getEnv('web')}${pathname}` }];
  };

  return (
    <Meta
      title={title}
      meta={[
        { name: 'description', content: meta },
        { name: 'keywords', content: metaKeywords },
        noIndex ? { name: 'robots', content: 'noindex' } : {},
      ]}
      canonical={setCanonicalUrl()}
    />
  );
};

CmsPagesDispatcher.displayName = 'containers/CmsPagesDispatcher';
CmsPagesDispatcher.propTypes = {
  brandName: PropTypes.string,
  contentId: PropTypes.string,
  metaData: PropTypes.object,
  noIndex: PropTypes.bool,
  pages: PropTypes.object,
};

export default connect(mapStateToProps)(CmsPagesDispatcher);
