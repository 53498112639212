import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import isTouchDevice from 'constants/isTouchDevice/isTouchDevice';

import styles from 'components/atoms/FacetListItem/FacetListItem.scss';

const CmsFacetListItem = ({ facetValue, addFacet }) => (
  <div className={styles.facetListValues}>
    <button
      type="button"
      className={cx(styles.facetListItemLink, { [styles.mobileFacetListItemLink]: isTouchDevice() })}
      onClick={addFacet}
    >
      <span className={styles.facetListItemName}>{facetValue.name}</span>
    </button>
  </div>
);

CmsFacetListItem.propTypes = {
  facetValue: PropTypes.object.isRequired,
  addFacet: PropTypes.func.isRequired,
  facetName: PropTypes.string,
};

CmsFacetListItem.displayName = 'atoms/CmsFacetListItem';

export default CmsFacetListItem;
