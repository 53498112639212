import React from 'react';
import { IndexRoute, Route } from 'react-router';
import DefaultLayout from 'components/layouts/DefaultLayout/DefaultLayout';
import Login from 'routes/Login/Login';
import {
  ACCOUNT,
  ACCOUNT_ADDRESSES,
  ACCOUNT_ARCHIVE,
  ACCOUNT_LOGIN,
  ACCOUNT_NOTIFICATIONS,
  ACCOUNT_ORDER_DETAILS,
  ACCOUNT_ORDER_DETAILS_PARAMS,
  ACCOUNT_ORDERS,
  ACCOUNT_ORDERS_ARCHIVE,
  ACCOUNT_ORDERSPARAMS,
  ACCOUNT_RATINGS,
  ACCOUNT_RATINGS_PARAMS,
  ACCOUNT_REGISTRATION,
  ACCOUNT_RETURN,
  ACCOUNT_RETURN_CREATE,
  ACCOUNT_RETURNS,
  ACCOUNT_USERDATA,
  ACCOUNT_DELETE_CONFIRMATION,
} from 'constants/routePaths/routePaths';
import { account, accountLogin } from 'constants/routePaths/routePathCombinations';
import {
  prepareStateForRoute,
  redirectToHomepage,
  requireLogin,
  requireNoLogin,
  routeHelperKeys,
} from '../routeHelpers';
import isTelesalesMode from 'constants/helper/telesalesMode';

/* eslint-disable no-console */
const errorLoading = (err) => {
  console.error('Dynamic page loading failed', err);
};
const loadRoute = (cb) => (module) => cb(null, module.default);

const getRegistration = (nextState, cb) => {
  import('routes/Registration/Registration').then(loadRoute(cb)).catch(errorLoading);
};

const getAccount = (nextState, cb) => {
  import('routes/account/Account/Account').then(loadRoute(cb)).catch(errorLoading);
};

const getAccountOrders = (nextState, cb) => {
  import('routes/account/AccountOrders/AccountOrders').then(loadRoute(cb)).catch(errorLoading);
};

const getAccountOrderDetails = (nextState, cb) => {
  import('routes/account/AccountOrderDetails/AccountOrderDetails').then(loadRoute(cb)).catch(errorLoading);
};

const getAccountAddresses = (nextState, cb) => {
  import('routes/account/AccountAddresses/AccountAddresses').then(loadRoute(cb)).catch(errorLoading);
};

const getAccountUserData = (nextState, cb) => {
  import('routes/account/AccountUserData/AccountUserData').then(loadRoute(cb)).catch(errorLoading);
};

const getAccountRatings = (nextState, cb) => {
  import('routes/account/AccountRatings/AccountRatings').then(loadRoute(cb)).catch(errorLoading);
};

const getAccountNotifications = (nextState, cb) => {
  import('routes/account/AccountNotifications/AccountNotifications').then(loadRoute(cb)).catch(errorLoading);
};

const getAccountReturns = (nextState, cb) => {
  import('routes/account/AccountReturns/AccountReturns').then(loadRoute(cb)).catch(errorLoading);
};

const getAccountReturnDetails = (nextState, cb) => {
  import('routes/account/AccountReturnDetails/AccountReturnDetails').then(loadRoute(cb)).catch(errorLoading);
};

const getAccountDeleteConfirmation = (nextState, cb) => {
  import('routes/account/AccountDeleteConfirmation/AccountDeleteConfirmation').then(loadRoute(cb)).catch(errorLoading);
};

export default (store) => (
  <Route>
    <Route path={ACCOUNT} component={DefaultLayout}>
      <IndexRoute
        getComponent={getAccount}
        onEnter={isTelesalesMode ? redirectToHomepage() : requireLogin(accountLogin)}
      />
      <Route
        path={ACCOUNT_ARCHIVE}
        getComponent={getAccount}
        onEnter={isTelesalesMode ? redirectToHomepage() : requireLogin(accountLogin)}
      />
      <Route
        path={ACCOUNT_REGISTRATION}
        getComponent={getRegistration}
        onEnter={isTelesalesMode ? redirectToHomepage() : prepareStateForRoute(store, routeHelperKeys.REGISTRATION)}
      />
      <Route
        path={`${ACCOUNT_ORDERS}${ACCOUNT_ORDERS_ARCHIVE}${ACCOUNT_ORDERSPARAMS}`}
        getComponent={getAccountOrders}
        onEnter={isTelesalesMode ? redirectToHomepage() : requireLogin(accountLogin)}
      />
      <Route
        path={`${ACCOUNT_ORDER_DETAILS}${ACCOUNT_ORDERS_ARCHIVE}/${ACCOUNT_ORDER_DETAILS_PARAMS}`}
        getComponent={getAccountOrderDetails}
        onEnter={isTelesalesMode ? redirectToHomepage() : requireLogin(accountLogin)}
      />
      <Route path={ACCOUNT_ADDRESSES} getComponent={getAccountAddresses} onEnter={requireLogin(accountLogin)} />
      <Route
        path={ACCOUNT_USERDATA}
        getComponent={getAccountUserData}
        onEnter={isTelesalesMode ? redirectToHomepage() : requireLogin(accountLogin)}
      />
      <Route
        path={`${ACCOUNT_RATINGS}/${ACCOUNT_RATINGS_PARAMS}`}
        getComponent={getAccountRatings}
        onEnter={isTelesalesMode ? redirectToHomepage() : requireLogin(accountLogin)}
      />
      <Route
        path={ACCOUNT_RATINGS}
        getComponent={getAccountRatings}
        onEnter={isTelesalesMode ? redirectToHomepage() : requireLogin(accountLogin)}
      />
      <Route
        path={ACCOUNT_NOTIFICATIONS}
        getComponent={getAccountNotifications}
        onEnter={isTelesalesMode ? redirectToHomepage() : requireLogin(accountLogin)}
      />
      <Route
        path={ACCOUNT_RETURNS}
        getComponent={getAccountReturns}
        onEnter={isTelesalesMode ? redirectToHomepage() : requireLogin(accountLogin)}
      />
      <Route
        path={`${ACCOUNT_RETURN}/${ACCOUNT_RETURN_CREATE}`}
        getComponent={getAccountReturnDetails}
        onEnter={isTelesalesMode ? redirectToHomepage() : requireLogin(accountLogin)}
      />
      <Route path={`${ACCOUNT_DELETE_CONFIRMATION}`} getComponent={getAccountDeleteConfirmation} />
    </Route>
    <Route path={ACCOUNT} component={DefaultLayout}>
      <Route
        path={ACCOUNT_LOGIN}
        component={Login}
        onEnter={isTelesalesMode ? redirectToHomepage() : requireNoLogin(account)}
      />
    </Route>
  </Route>
);
