const asciiArt = `

██████  ██████  ███████ ██    ██ ██ ███████ ██     ██     ███████ ███████  █████  ████████ ██    ██ ██████  ███████ ███████
██   ██ ██   ██ ██      ██    ██ ██ ██      ██     ██     ██      ██      ██   ██    ██    ██    ██ ██   ██ ██      ██
██████  ██████  █████   ██    ██ ██ █████   ██  █  ██     █████   █████   ███████    ██    ██    ██ ██████  █████   ███████
██      ██   ██ ██       ██  ██  ██ ██      ██ ███ ██     ██      ██      ██   ██    ██    ██    ██ ██   ██ ██           ██
██      ██   ██ ███████   ████   ██ ███████  ███ ███      ██      ███████ ██   ██    ██     ██████  ██   ██ ███████ ███████

`;
const toggle = (featureName) => {
  return {
    status: __CLIENT__ && !!(localStorage?.getItem(featureName) || sessionStorage?.getItem(featureName) || false),

    enable(permanent = false) {
      (permanent ? localStorage : sessionStorage).setItem(featureName, 'true');
      console.log(this);
      this.status = true;
      console.log('refresh your browser');
      window?.location.reload();
    },
    disable() {
      localStorage?.removeItem(featureName);
      sessionStorage?.removeItem(featureName);
      this.status = false;
      console.log('refresh your browser');
      window?.location.reload();
    },
  };
};

export const previews = {
  telesalesMode: toggle('preview_telesalesMode'),
  supercard: toggle('preview_supercard'),
  appMode: toggle('preview_appMode'),

  showAvailable: () => {
    console.info(
      asciiArt +
        '\n' +
        Object.entries(previews)
          .filter(([, value]) => typeof value?.status !== 'undefined')
          .map(([key, value]) => ` [${value?.status ? '*' : ' '}] ${key}`)
          .join('\n') +
        '\n'
    );
  },
};

if (__CLIENT__) window.previews = previews;
