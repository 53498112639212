import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getTimeCriticalProductData } from 'actions/productActions/productActions';
import { setVisibleProducts } from 'actions/uiActions/uiActions';

import { elementsPerRow, elementsPerRowFullWidth, getInitialRows, INITIAL_ROWS } from './configTopProductsBox';
import ProductsBox from 'components/organisms/ProductsBox/ProductsBox';
import { scrollToElement } from 'constants/scrollToElement/scrollToElement';
import { BREAKPOINTS } from 'constants/breakpoints/breakpoints';
import { getEnumValues } from 'constants/GetEnumValues/GetEnumValues';

export const mapStateToProps = (state, ownProps) => {
  const elements = [];
  const cmsProducts = ownProps?.elements || [];

  cmsProducts.forEach((cmsProduct) => {
    const element = state?.products?.[cmsProduct?.code];
    if (element) {
      elements.push({ ...element, elementTitle: cmsProduct?.elementTitle || ownProps.title });
    }
  });

  return {
    breakpoint: state.ui.breakpoint,
    elements,
  };
};

let instanceCounter = 0;

export const mapDispatchToProps = {
  getTimeCriticalProductData,
  setVisibleProducts,
};

export class TopProductsContainer extends Component {
  static propTypes = {
    elements: PropTypes.array,
    title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    initialRows: PropTypes.oneOf(getEnumValues(INITIAL_ROWS)),
    anchor: PropTypes.string,
    breakpoint: PropTypes.oneOf(getEnumValues(BREAKPOINTS)),
    fullWidth: PropTypes.bool,
    loadingProducts: PropTypes.bool,
    getTimeCriticalProductData: PropTypes.func,
    setVisibleProducts: PropTypes.func,
  };

  static defaultProps = {
    elements: [],
    title: '',
    initialRows: INITIAL_ROWS.ONE,
    anchor: 'tpc',
    loadingProducts: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      step: getInitialRows([this.props.initialRows]),
      visibleElements: [],
      fullyExpanded: false,
      anchor: `${this.props.anchor}_${instanceCounter++}`,
    };
  }

  componentDidMount() {
    if (this.props.elements.length) {
      this.getTimeCriticalProducts(this.props.elements);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { breakpoint, elements, fullWidth } = nextProps;

    const calcElements = this.getElementsPerRow(breakpoint, fullWidth);

    this.setState((oldState) => ({
      // eslint-disable-line react/no-did-mount-set-state
      visibleElements: elements.slice(0, oldState.step * calcElements),
      fullyExpanded: calcElements * oldState.step >= elements.slice(0, oldState.step * calcElements),
    }));
  }

  componentDidUpdate(prevState, snapshot) {
    if (snapshot.visibleElements && snapshot.visibleElements.length !== this.state.visibleElements.length) {
      this.props.setVisibleProducts(this.state.visibleElements);
    }
  }

  getTimeCriticalProducts = (products = []) => {
    const timeCriticalProducts = [];

    products.forEach((product) => {
      if (product.maxOrderCounterActive) {
        timeCriticalProducts.push(product.code);
      }
    });

    if (timeCriticalProducts.length) {
      this.props.getTimeCriticalProductData(timeCriticalProducts);
    }
  };

  getStepValue = () =>
    this.props.elements.length > this.state.visibleElements.length
      ? this.state.step + 1
      : getInitialRows([this.props.initialRows]);

  getElementsPerRow = (breakPoint, fullWidth) =>
    fullWidth ? elementsPerRowFullWidth[breakPoint] : elementsPerRow[breakPoint];

  handle = () => {
    const step = this.getStepValue();
    const { breakpoint, elements, fullWidth, initialRows } = this.props;

    const calcElements = this.getElementsPerRow(breakpoint, fullWidth);

    this.setState({
      step,
      visibleElements: elements.slice(0, step * calcElements),
      fullyExpanded: step * calcElements >= elements.length,
    });

    if (step === getInitialRows([initialRows])) {
      scrollToElement(this.state.anchor);
    }
  };

  render() {
    const { breakpoint, elements, fullWidth, title } = this.props;
    const elementsPerRowCalculated = this.getElementsPerRow(breakpoint || BREAKPOINTS.LG, fullWidth);
    const visibleElements = elements.slice(0, this.state.step * elementsPerRowCalculated);

    const { anchor } = this.state;
    const props = {
      anchor,
      breakpoint,
      fullyExpanded: this.state.fullyExpanded,
      fullWidth,
      handle: this.handle,
      hasExpander: elements.length > elementsPerRowCalculated * getInitialRows([this.props.initialRows]),
      title,
      visibleElements,
    };

    return <ProductsBox {...props} loading={this.props.loadingProducts} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopProductsContainer);
