import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './SvgContainer.scss';

export default class SvgContainer extends Component {
  static displayName = 'atoms/SvgContainer';

  static propTypes = {
    height: PropTypes.number,
    width: PropTypes.number,
    vbHeight: PropTypes.number,
    vbWidth: PropTypes.number,
    vbXCoords: PropTypes.number,
    vbYCoords: PropTypes.number,
    color: PropTypes.string,
    children: PropTypes.node,
    render: PropTypes.bool,
    disableTooltip: PropTypes.bool,
    className: PropTypes.string,
  };

  static defaultProps = {
    height: 32,
    vbWidth: 400,
    vbHeight: 400,
    vbXCoords: 0,
    vbYCoords: 0,
    render: true,
    disableTooltip: true,
  };

  getAspectRatio() {
    return this.props.vbWidth / this.props.vbHeight;
  }

  getViewBox() {
    return [this.props.vbXCoords, this.props.vbYCoords, this.props.vbWidth, this.props.vbHeight].join(' ');
  }

  getWidth() {
    return this.props.width || Math.ceil(this.props.height * this.getAspectRatio());
  }

  render() {
    const getPath = this.props.render ? this.props.children : <path d="" />;

    return (
      <svg
        viewBox={this.getViewBox()}
        width={this.getWidth()}
        height={this.props.height}
        style={{ fill: this.props.color }}
        className={cx({ [styles.disableTooltip]: this.props.disableTooltip }, this.props.className)}
        focusable="false"
      >
        {getPath}
      </svg>
    );
  }
}
