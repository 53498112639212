import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import FormattedPrice from 'components/atoms/FormattedPrice/FormattedPrice';
import language from 'constants/language/language';
import styles from './Price.scss';
import bootstrap from 'scss/component.scss';

export const SKINS = {
  DETAIL: 'DETAIL',
  SEARCHSUGGEST: 'SEARCHSUGGEST',
  PRODUCTCARD: 'PRODUCTCARD',
  PRODUCTTEASER: 'PRODUCTTEASER',
  STICKY_ADD_TO_CART: 'STICKY',
};

const Price = ({
  productPriceData = {},
  showCurrency = false,
  showInsteadPrice = false,
  skin = '',
  showCentAsSup = true,
  cypressId,
}) => {
  const currencyIso = productPriceData.finalPrice?.currencyIso || 'CHF';
  const finalPriceValue = productPriceData.finalPrice?.value;
  const insteadPriceValue = productPriceData.insteadPrice?.value;
  return (
    <div
      className={cx(
        styles.container,
        { [styles.hasInsteadPrice]: insteadPriceValue && showInsteadPrice },
        { [styles[skin]]: skin }
      )}
      data-cy={cypressId}
    >
      <div className={styles.finalPrice}>
        <span className={cx({ [bootstrap.srOnly]: !showCurrency })}>{currencyIso}</span>
        <FormattedPrice value={finalPriceValue} showCentAsSup={showCentAsSup} skin={skin} />
      </div>
      {insteadPriceValue && showInsteadPrice && (
        <div className={styles.insteadPrice}>
          {language('common.strikeThroughPricePrefixText')} <FormattedPrice value={insteadPriceValue} />
        </div>
      )}
    </div>
  );
};

Price.SKINS = SKINS;

Price.displayName = 'atoms/Price';
Price.propTypes = {
  productPriceData: PropTypes.shape({
    finalPrice: PropTypes.object,
    insteadPrice: PropTypes.object,
  }),
  showCurrency: PropTypes.bool,
  showInsteadPrice: PropTypes.bool,
  skin: PropTypes.oneOf([...Object.values(SKINS), '']),
  showCentAsSup: PropTypes.bool,
  cypressId: PropTypes.string,
};

export default Price;
