// @flow

// Type "Facet" is also defined in file reducers/productsQueries/productsQueries.js as "productsQueryFacetValue"
export type Facet = {
  code: string,
  name: string,
  selected: boolean,
  count?: number,
  query: string, // the filter or remove queryString
};

export type FacetList = {
  code: string,
  name: string,
  values: Array<Facet>,
  selectedValues?: Array<Facet>,
};

export const FALLBACK_PRODUCT_IMAGE = {
  code: 'noImageContainer',
  altText: 'no image',
  sizes: [
    { size: '260', url: '/static-shops/products/260/noImageContainer' },
    { size: '720', url: '/static-shops/products/720/noImageContainer' },
  ],
  containerQualifier: 'noImageContainer',
};
