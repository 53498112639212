import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';

const SmartEditComponent = lazy(() => import('containers/SmartEditContainer/SmartEditComponent'));

// eslint-disable-next-line react/display-name
const withSmartEdit = (config) => (props) => {
  // eslint-disable-next-line react/prop-types
  const { children } = props;

  return (
    <Suspense fallback={null}>
      <SmartEditComponent {...config}>{children}</SmartEditComponent>
    </Suspense>
  );
};

withSmartEdit.displayName = 'containers/withSmartEditComponent';

withSmartEdit.propTypes = { config: PropTypes.object.isRequired };

export default withSmartEdit;
