import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { downloadPdf } from 'actions/userActions/userActions';
import language from 'constants/language/language';
import { B2B_REGISTRATION } from 'constants/pdfTypes/pdfTypes';
import getStorage from 'constants/storage/storage';
import {
  ORDER_CODE,
  GUEST_EMAIL,
  ESD_ONLY_CART,
  MILA_APPLICABLE,
  LAST_ORDER_GUID,
} from 'constants/storage/storageKeys';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import Meta from 'containers/Meta/Meta';
import CheckoutSuccess from 'components/templates/CheckoutSuccess/CheckoutSuccess';
import NextFeatureEnabled from 'routes/ProductsOverview/NextFeatureEnabled';

const storage = getStorage(true);

const mapStateToProps = (state) => {
  const uid = state?.user?.uid;
  const guestCustomer = state?.orders?.lastOrder?.guestCustomer;
  const guestEmail = state?.user?.fields?.email?.value || storage.getItem(GUEST_EMAIL);
  const orderCodeFromState = state?.orders?.lastOrder?.code;
  const orderGuidFromState = state?.orders?.lastOrder?.guid;
  const orderedProducts = state?.orders?.lastOrder?.products ?? [];
  return {
    lastOrderCode: orderCodeFromState || storage.getItem(ORDER_CODE),
    lastOrderGuid: orderGuidFromState || storage.getItem(LAST_ORDER_GUID),
    guestCustomer: uid === 'anonymous' || false,
    emailCustomer: guestCustomer ? guestEmail : uid,
    userGroup: state?.user?.group || 'CUSTOMERGROUP',
    milaApplicable: storage.getItem(MILA_APPLICABLE) === 'true',
    esdOnlyCart: storage.getItem(ESD_ONLY_CART) === 'true',
    orderedProducts,
  };
};
const mapDispatchToProps = {
  downloadB2BPdf: downloadPdf,
};

const OrderConfirmation = ({
  downloadB2BPdf = () => {},
  emailCustomer = '',
  guestCustomer = false,
  lastOrderCode = '',
  lastOrderGuid = '',
  userGroup = '',
  orderedProducts = [],
}) => {
  useEffect(() => {
    if (!emailCustomer && !lastOrderCode) {
      browserHistory.push(mapPathToLocalizedUrl());
    }
  }, []);

  return (
    <>
      <NextFeatureEnabled
        conditions={[
          {
            featureName: 'nextCheckoutEnabled',
            include: 'checkout',
          },
          {
            featureName: 'nextCheckoutEnabled',
            include: 'payment',
          },
          {
            featureName: 'nextCheckoutEnabled',
            include: 'orderconfirmation',
          },
        ]}
      />
      <Meta title={language('pageTitles.orderConfirmation')} meta={[{ name: 'robots', content: 'noindex' }]} />
      <CheckoutSuccess
        orderNumber={lastOrderCode}
        orderGuid={lastOrderGuid}
        guestCustomer={guestCustomer}
        emailCustomer={emailCustomer}
        userGroup={userGroup}
        downloadB2BPdf={() => {
          downloadB2BPdf(B2B_REGISTRATION);
        }}
        orderedProducts={orderedProducts}
      />
    </>
  );
};

OrderConfirmation.displayName = 'routes/checkout/OrderConfirmation';
OrderConfirmation.propTypes = {
  downloadB2BPdf: PropTypes.func,
  emailCustomer: PropTypes.string,
  esdOnlyCart: PropTypes.bool,
  guestCustomer: PropTypes.bool,
  lastOrderCode: PropTypes.string,
  lastOrderGuid: PropTypes.string,
  lastOrderDeliveryMode: PropTypes.string,
  milaApplicable: PropTypes.bool,
  userGroup: PropTypes.string,
  orderedProducts: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderConfirmation);
