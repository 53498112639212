// @flow
import * as React from 'react';
import cx from 'classnames';
import styles from './IconText.scss';
import Icon, { ICON_CHECK } from 'components/atoms/Icon/Icon';

type IProps = {
  children: React.Node,
  icon?: string,
  border?: boolean,
};

const IconText = ({ children, icon = ICON_CHECK, border = false }: IProps) => (
  <p className={styles.iconText}>
    <Icon path={icon} className={cx(styles.icon, border && styles.roundBorder)} />
    {children}
  </p>
);

export default IconText;
