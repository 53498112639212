import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import language from 'constants/language/language';
import { noop } from 'constants/noop/noop';
import { calculatePages } from 'constants/paginationHelper/paginationHelper';
import { scrollUpToId, TOP_OF_PRODUCTS_LIST } from 'constants/scrollToElement/scrollToElement';
import CmsPaginationLink from 'components/atoms/CmsPaginationLink/CmsPaginationLink';
import Icon, { ICON_KEYBOARD_ARROW_LEFT, ICON_KEYBOARD_ARROW_RIGHT } from 'components/atoms/Icon/Icon';
import bootstrap from 'scss/component.scss';
import styles from 'components/molecules/Pagination/Pagination.scss';

const CmsPagination = ({ changePage, currentPage, totalPages }) => {
  const leadingZero = (page) => (page < 10 ? `0${page}` : `${page}`);

  const pages = () =>
    calculatePages(currentPage, totalPages).map((page) => (
      <CmsPaginationLink
        key={page}
        page={page}
        className={currentPage === page ? styles.current : bootstrap.hiddenXsDown}
        changePage={handleClick(page)}
      >
        {leadingZero(page)}
      </CmsPaginationLink>
    ));

  const handleClick = (page) => () => {
    changePage(page);
    scrollUpToId(TOP_OF_PRODUCTS_LIST, 0);
  };

  if (totalPages <= 1) return null; // don't show if only one page

  return (
    <div className={styles.pagination}>
      <div
        onClick={handleClick(currentPage - 1)}
        className={cx(styles.paginationLink, { [styles.disabled]: currentPage <= 1 })}
      >
        <Icon path={ICON_KEYBOARD_ARROW_LEFT} />
        <span>{language('common.return')}</span>
      </div>
      {pages()}
      <div
        onClick={currentPage === totalPages ? noop : handleClick(currentPage + 1)}
        className={cx(styles.paginationLink, { [styles.disabled]: currentPage === totalPages })}
      >
        <span>{language('common.continue')}</span>
        <Icon path={ICON_KEYBOARD_ARROW_RIGHT} />
      </div>
    </div>
  );
};

CmsPagination.displayName = 'molecules/CmsPagination';
CmsPagination.propTypes = {
  changePage: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
};

export default CmsPagination;
