import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { recoTrackClickOnProduct } from 'api/RecoAPI/RecoAPI';
import getCookieStorage from 'constants/storage/cookie';
import { RECO_COOKIE } from 'constants/storage/storageKeys';

export const mapStateToProps = (state, ownProps) => {
  const productCode = ownProps.code;
  const product = state.products[productCode] || {};

  return {
    productCode: productCode || '',
    recoTrackingToken: product.recoTrackingToken || '',
  };
};

const cookie = getCookieStorage();

const RecoTracking = ({ children, productCode, recoTrackingToken, product = {}, ...props }) => {
  if (recoTrackingToken === '' && product.recoTrackingToken && product.recoTrackingToken.length > 0) {
    recoTrackingToken = product.recoTrackingToken;
  }

  const trackRecoProduct = () => {
    recoTrackClickOnProduct(productCode, recoTrackingToken, cookie.getItem(RECO_COOKIE));
  };
  return React.cloneElement(children, {
    ...props,
    trackRecoProduct,
  });
};

RecoTracking.displayName = 'containers/RecoTracking';
RecoTracking.propTypes = {
  children: PropTypes.node.isRequired,
  productCode: PropTypes.string.isRequired,
  recoTrackingToken: PropTypes.string.isRequired,
  product: PropTypes.object.optional,
};

export default connect(mapStateToProps)(RecoTracking);
