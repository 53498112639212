import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Headline from 'components/atoms/Headline/Headline';

import styles from './FooterHeadline.scss';

const FooterHeadline = ({ children, className, headlineTag }) => (
  <div className={cx(className, styles.headline)}>
    <Headline tag={headlineTag} margin={Headline.margins.NONE}>
      {children}
    </Headline>
  </div>
);

FooterHeadline.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  headlineTag: PropTypes.oneOf(Object.values(Headline.tags)),
};

FooterHeadline.defaultProps = {
  headlineTag: Headline.tags.H3,
};

FooterHeadline.displayName = 'atoms/FooterHeadline';

export default FooterHeadline;
