import React from 'react';
import PropTypes from 'prop-types';

import utils, { positions, sizes } from 'constants/breadcrumbUtils/breadcrumbUtils';
import { types } from 'components/organisms/Breadcrumb/Breadcrumb';

import BreadcrumbLink from 'components/atoms/BreadcrumbLink/BreadcrumbLink';
import { BackIcon } from 'components/atoms/Icon/Icon';

import language from 'constants/language/language';

import styles from './BreadcrumbItem.scss';

const isArrowVisible = (skin, breadcrumbPositions) =>
  skin.position === breadcrumbPositions.first ||
  skin.position === breadcrumbPositions.home ||
  skin.position === breadcrumbPositions.center;

const provideContent = (skin, breadcrumbPositions, item, useNativeLink = false) => {
  if (skin.position === breadcrumbPositions.placeholder) {
    return (
      <BreadcrumbLink useNativeLink={useNativeLink} name={item.name} onClick={item.onClick} showArrow>
        {item.name}
      </BreadcrumbLink>
    );
  } else if (skin.position === breadcrumbPositions.back) {
    return (
      <BreadcrumbLink useNativeLink={useNativeLink} name={item.name} onClick={item.onClick} href={item.href} showArrow={false} goBack>
        <BackIcon />
        <span>{item.name}</span>
      </BreadcrumbLink>
    );
  } else if (skin.position === breadcrumbPositions.last && skin.type !== types.detail) {
    return <span>{item.name}</span>;
  }

  const showArrow = isArrowVisible(skin, breadcrumbPositions);
  return (
    <BreadcrumbLink useNativeLink={useNativeLink} name={item.name} href={item.href} showArrow={showArrow}>
      {skin.position === breadcrumbPositions.icon ? language('breadcrumb.home') : item.name}
    </BreadcrumbLink>
  );
};

const BreadcrumbItem = ({ pos, item, skin, hasPlaceholder, useNativeLink }) => {
  if (isNaN(pos)) {
    return <li className={utils.parseSkin(skin, styles, hasPlaceholder)}>{provideContent(skin, positions, item, useNativeLink)}</li>;
  }

  return <li className={utils.parseSkin(skin, styles, hasPlaceholder)}>{provideContent(skin, positions, item, useNativeLink)}</li>;
};

BreadcrumbItem.displayName = 'molecules/BreadcrumbItem';

BreadcrumbItem.propTypes = {
  pos: PropTypes.number,
  item: PropTypes.object.isRequired,
  skin: PropTypes.shape({
    position: PropTypes.oneOf(Object.values(positions)).isRequired,
    size: PropTypes.oneOf(Object.values(sizes)).isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  hasPlaceholder: PropTypes.bool,
  hasProducts: PropTypes.bool,
  useNativeLink: PropTypes.bool,
};

BreadcrumbItem.defaultProps = {
  hasPlaceholder: false,
  hasProducts: false,
  useNativeLink: false,
};

export default BreadcrumbItem;
