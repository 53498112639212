const initial = {
  value: '',
};

export const mapAccountToAccountData = ({
  titleCode = initial,
  group = initial,
  firstName = initial,
  lastName = initial,
  birthday = initial,
  language = initial,
  supercardCode = initial,
  employeeEan = initial,
  newsletter,
  personalizedNewsletter,
}) => ({
  titleCode: titleCode.value,
  group: group.value,
  firstName: firstName.value,
  lastName: lastName.value,
  birthday: new Date(birthday.value),
  supercardCode: supercardCode.value || '', // return '' because it can be undefined
  employeeEan: employeeEan.value || '', // return '' because it can be undefined
  language: {
    isocode: language.value,
  },
  newsletter,
  personalizedNewsletter,
});

export default mapAccountToAccountData;
