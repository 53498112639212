import React from 'react';
import PropTypes from 'prop-types';

/**
 * We have to inline TWINT, because it uses gradients as fills. Chrome cannot handle this with sprites or
 * when those styles are applied from an external file.
 * @see https://bugs.chromium.org/p/chromium/issues/detail?id=109212
 */
// prettier-ignore

const TWINT = (
  <svg id="TWINT" viewBox="0 0 60 40">
    <path
      d="M57 0.000244141H3C1.34315 0.000244141 0 1.34339 0 3.00024V37.0002C0 38.6571 1.34315 40.0002 3 40.0002H57C58.6569 40.0002 60 38.6571 60 37.0002V3.00024C60 1.34339 58.6569 0.000244141 57 0.000244141Z"
      fill="black"
    />
    <path
      d="M18.9006 24.1721C18.9006 24.387 18.746 24.6532 18.5605 24.7555L11.9339 28.5531C11.7484 28.6657 11.4392 28.6657 11.2537 28.5531L4.62703 24.7555C4.44153 24.6429 4.28694 24.387 4.28694 24.1721V16.5666C4.28694 16.3516 4.44153 16.0855 4.62703 15.9831L11.2537 12.1855C11.4392 12.0729 11.7484 12.0729 11.9339 12.1855L18.5605 15.9831C18.746 16.0957 18.9006 16.3516 18.9006 16.5666V24.1721Z"
      fill="white"
    />
    <path d="M55.3728 16.9788H49.4197V18.3651H51.5487V24.316H53.2335V18.3651H55.3728V16.9788Z" fill="white" />
    <path d="M28.3356 16.9788H22.3825V18.3651H24.5219V24.316H26.2067V18.3651H28.3356V16.9788Z" fill="white" />
    <path
      d="M45.5645 16.7461C43.6938 16.7461 42.65 17.92 42.65 19.6101V24.3161H44.314V19.5696C44.314 18.8309 44.7584 18.2641 45.5852 18.2641C46.4017 18.2641 46.8461 18.9321 46.8461 19.5696V24.3161H48.5101V19.6101C48.5101 17.92 47.4352 16.7461 45.5645 16.7461Z"
      fill="white"
    />
    <path d="M39.2705 16.9788V24.316H40.9445V16.9788H39.2705Z" fill="white" />
    <path
      d="M33.6273 19.8934L33.6892 20.2172L35.2498 24.316H35.9321L38.061 16.9788H36.4178L35.3944 20.8348L35.3429 21.2494L35.2602 20.8348L33.8958 16.9788H33.3585L32.0044 20.8348L31.9218 21.2494L31.8598 20.8348L30.8469 16.9788H29.2036L31.333 24.316H32.0148L33.5754 20.2172L33.6273 19.8934Z"
      fill="white"
    />
    <path
      d="M14.603 20.2308L12.9057 22.6526L12.0317 21.3531L13.0379 19.896C13.2208 19.6401 13.6273 18.9214 13.1598 17.9467C12.7838 17.1591 11.9707 16.7751 11.2592 16.7751C10.5478 16.7751 9.7652 17.1296 9.35866 17.9467C8.89113 18.882 9.29768 19.6204 9.47046 19.8665C9.47046 19.8665 10.0295 20.6639 10.497 21.3334L11.2592 22.3967L12.3976 24.0802C12.4077 24.09 12.5907 24.3558 12.9057 24.3558C13.2106 24.3558 13.3936 24.09 13.4241 24.0605L16.0971 20.2308H14.603ZM11.2592 20.2898C11.2592 20.2898 10.812 19.6302 10.5275 19.1773C10.2124 18.6851 10.5681 17.9566 11.2592 17.9566C11.9605 17.9566 12.3061 18.6851 11.991 19.1773C11.7064 19.6401 11.2592 20.2898 11.2592 20.2898Z"
      fill="#FF9102"
    />
    <path
      d="M9.61274 22.5738L7.93575 20.2996C7.93575 20.2996 7.48856 19.64 7.20398 19.1871C6.88891 18.6948 7.24463 17.9663 7.93575 17.9663C8.02723 17.9663 8.10854 17.9762 8.17968 17.9959L8.76917 16.9523C8.49475 16.844 8.21017 16.7849 7.93575 16.7849C7.22431 16.7849 6.44171 17.1393 6.03517 17.9565C5.56765 18.8917 5.97419 19.6301 6.14697 19.8763L9.07407 24.0801C9.0944 24.1195 9.28751 24.3853 9.59241 24.3853C9.90748 24.3853 10.0803 24.1293 10.1108 24.0899L10.995 22.7904L10.2327 21.7074L9.61274 22.5738Z"
      fill="#01A9DE"
    />
  </svg>
);

const PaymentMethods = {
  AMERICANEXPRESS: 'AMERICANEXPRESS',
  COMPANYINVOICE: 'MONTHLYINVOICE_PCF',
  MASTERCARD: 'MASTERCARD',
  MONTHLYINVOICE_PCF: 'MONTHLYINVOICE_PCF',
  PAYCARD: 'PAYCARD',
  PAY_IN_ADVANCE: 'PAY_IN_ADVANCE',
  PAYPAL: 'PAYPAL',
  POSTCARD: 'POSTCARD',
  POSTFINANCE: 'POSTFINANCE',
  SAMSUNG_PAY: 'SAMSUNG_PAY',
  DINERS_CLUB: 'DINERS_CLUB',
  TWINT: 'TWINT',
  VISA: 'VISA',
  PAY_AT_PICKUP: 'PAY_AT_PICKUP',
  POINTSPAY: 'POINTSPAY',
  SUPERCARD: 'SUPERCARD',
  SUPERCARD_VISA: 'SUPERCARD_VISA',
  SUPERCARD_MASTERCARD: 'SUPERCARD_MASTERCARD',
};

const PaymentIcon = ({ paymentMethod }) => {
  return (
    <svg width="60px" height="40px" viewBox="0 0 60 40">
      {paymentMethod === PaymentMethods.TWINT ? (
        TWINT
      ) : (
        <use href={`/images/logos/paymentIconsV2.svg#${paymentMethod}`} />
      )}
    </svg>
  );
};

PaymentIcon.displayName = 'atoms/PaymentIcon';

PaymentIcon.PaymentMethods = PaymentMethods;

PaymentIcon.propTypes = {
  paymentMethod: PropTypes.oneOf(Object.values(PaymentMethods)).isRequired,
};

export default PaymentIcon;
