// Need to provide a Unix Timestamp (integer), eg.: 25200000 - (Thu Jan 01 1970 08:00:00 GMT+0100 (CET))

const getTimeFromTimestamp = (d, formattingStyleDot = false) => {
  const date = new Date(d);
  const h = date.getHours();
  const m = date.getMinutes();
  const separator = formattingStyleDot ? '.' : ':';

  return `${h < 10 ? `0${h}` : `${h}`}${separator}${m < 10 ? `0${m}` : `${m}`}`;
};

export default getTimeFromTimestamp;
