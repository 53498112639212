import React from 'react';

import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './RemoveFromCart.scss';
import language from 'constants/language/language';

const RemoveFromCartLink = ({ disabled, trackingData, cyData }) => {
  return (
    <span className={cx(styles.Container, { [styles.Disabled]: disabled })} data-tracking={!disabled && trackingData}>
      <span className={styles.Button} data-cy={cyData}>
        {language('cart.remove')}
      </span>
    </span>
  );
};

RemoveFromCartLink.propTypes = {
  disabled: PropTypes.bool,
  trackingData: PropTypes.object,
  cyData: PropTypes.string,
};

export default RemoveFromCartLink;
