import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';
import dataTracking from 'constants/trackingAttributes/dataTracking';
import { noop } from 'constants/noop/noop';
import styles from './CancelSaveButton.scss';

const CancelSaveButton = ({
  onSubmit = noop,
  onCancel = noop,
  linkTo,
  submitText,
  cancelText,
  disabled,
  cySaveButton,
  location,
  alignLeft = false,
}) => (
  <div className={cx(styles.container, { [styles.alignLeft]: alignLeft })}>
    {cancelText && linkTo ? (
      <ButtonLink variety={ButtonLink.varieties.basic} href={linkTo}>
        {cancelText}
      </ButtonLink>
    ) : (
      <ButtonLink
        disabled={disabled}
        variety={ButtonLink.varieties.basic}
        onClick={onCancel}
        data-tracking={dataTracking({
          TYPE: 'form:button',
          FUNCTION: 'close',
          ATTRIBUTE_1: 'text',
          ATTRIBUTE_2: cancelText,
          ATTRIBUTE_3: location,
        })}
      >
        {cancelText}
      </ButtonLink>
    )}
    {submitText && (
      <ButtonLink
        size={ButtonLink.sizes.md}
        onClick={onSubmit}
        disabled={disabled}
        variety={ButtonLink.varieties.flat}
        data-cy={cySaveButton}
        data-tracking={dataTracking({
          TYPE: 'form:button',
          FUNCTION: 'apply',
          ATTRIBUTE_1: 'neutral',
          ATTRIBUTE_2: submitText,
          ATTRIBUTE_3: location,
        })}
      >
        {submitText}
      </ButtonLink>
    )}
  </div>
);

CancelSaveButton.displayName = 'molecules/CancelSaveButton';

CancelSaveButton.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  linkTo: PropTypes.string,
  submitText: PropTypes.string,
  cancelText: PropTypes.string,
  disabled: PropTypes.bool,
  cySaveButton: PropTypes.string,
  location: PropTypes.string,
  alignLeft: PropTypes.bool,
};

export default CancelSaveButton;
