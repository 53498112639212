import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Icon, { ICON_LOCATION_ON_OUTLINED, ICON_VIEW_HEADLINE } from 'components/atoms/Icon/Icon';
import viewTypes from 'constants/storeFinderResultsViewTypes/storeFinderResultsViewTypes';

import styles from './StoreFinderSwitchViewButtons.scss';

const StoreFinderSwitchViewButtons = ({ activeView, switchView }) => (
  <div className={styles.list}>
    {Object.values(viewTypes).map((viewType, i) => {
      const icon =
        viewType === viewTypes.LIST ? <Icon path={ICON_VIEW_HEADLINE} /> : <Icon path={ICON_LOCATION_ON_OUTLINED} />;
      return (
        <button
          key={i}
          className={cx(styles.button, styles[`button-${viewType}`], { [styles.active]: viewType === activeView })}
          onClick={() => switchView(viewType)}
          type="button"
        >
          {icon}
        </button>
      );
    })}
  </div>
);

StoreFinderSwitchViewButtons.propTypes = {
  activeView: PropTypes.oneOf(Object.values(viewTypes)),
  switchView: PropTypes.func.isRequired,
};

StoreFinderSwitchViewButtons.defaultProps = {
  activeView: viewTypes.LIST,
  switchView: () => {},
};

export default StoreFinderSwitchViewButtons;
