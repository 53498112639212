import React from 'react';
import PropTypes from 'prop-types';
import SvgContainer from 'components/atoms/SvgContainer/SvgContainer';

const renderPath = [
  <path
    key={'path'}
    // eslint-disable-next-line max-len
    d="M355 309.6H44.4c-8.9 0-16.1-7.2-16.1-16.1V103.6c0-8.9 7.2-16.1 16.1-16.1H355c8.9 0 16.1 7.2 16.1 16.1v189.8c0 9-7.2 16.2-16.1 16.2zM44.4 94.2c-5.2 0-9.4 4.2-9.4 9.4v189.8c0 5.2 4.2 9.4 9.4 9.4H355c5.2 0 9.4-4.2 9.4-9.4V103.6c0-5.2-4.2-9.4-9.4-9.4H44.4z"
  />,
  <path
    key={'path2'}
    // eslint-disable-next-line max-len
    d="M57 113.7v38h38v-38H57zm34.4 23.4H80.3v11.1h-8.8v-11.1h-11v-8.8h11.1v-11.1h8.8v11.1h11.1c-.1.1-.1 8.8-.1 8.8zm-34.4 25h83.4v108H57v-108zm96.1 15.4h114.7v12.7H153.1v-12.7zm0 21h90.3v12.7h-90.3v-12.7zm0 21.9h114.7v12.7H153.1v-12.7zm0 21.1h90.3v12.7h-90.3v-12.7zm153.8-85.1c-19.7 0-35.7-10.6-35.7-23.6 0-13.1 16.1-23.6 35.7-23.6s35.7 10.6 35.7 23.6c0 12.9-15.9 23.6-35.7 23.6zm0-43.2c-17.5 0-31.7 8.8-31.7 19.6s14.3 19.6 31.7 19.6c17.5 0 31.7-8.8 31.7-19.6s-14.2-19.6-31.7-19.6z"
  />,
  <path
    key={'path3'}
    // eslint-disable-next-line max-len
    d="M299.4 126.7c-1.5 0-2.6.5-3.3 1.6-.8 1.1-1.2 2.6-1.2 4.6 0 4 1.5 6.1 4.6 6.1 1.3 0 2.8-.3 4.6-.9v3.3c-1.5.6-3.2.9-5 .9-2.7 0-4.6-.8-6.1-2.4-1.4-1.6-2.1-4-2.1-7 0-1.9.3-3.5 1-5s1.7-2.5 3-3.3c1.3-.8 2.8-1.1 4.6-1.1 1.8 0 3.5.4 5.3 1.3l-1.2 3.2-2.1-.9c-.7-.3-1.4-.4-2.1-.4zm23.5 15.3H319v-7.8h-7.1v7.8H308v-18.2h3.9v7.1h7.2v-7.1h3.9l-.1 18.2z"
  />,
];
const vbWidth = 400;
const vbHeight = 400;

const IconCHID = ({ height }) => (
  <SvgContainer height={height} vbWidth={vbWidth} vbHeight={vbHeight}>
    {renderPath}
  </SvgContainer>
);

IconCHID.displayName = 'atoms/IconCHID';

IconCHID.propTypes = {
  height: PropTypes.number,
};

IconCHID.defaultProps = {
  height: 32,
};

export default IconCHID;
