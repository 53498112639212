// @flow

export const deliveryModes = {
  pickup: 'pickup',
  homeDelivery: 'home-delivery-gross',
  software: 'software',
};

export const deliveryDateFormat = {
  days: 'days',
  dates: 'dates',
};

export const nowDate = new Date();

export const isHomeDelivery = (code: string) => code === deliveryModes.homeDelivery;

/**
 * Indicates that a previously selected pickup point is not valid as pickup point for the current cart.
 * This might happen when the selected store can not handle products with a weight > 30 kg
 * @see https://atlassian.interdiscount.ch/jira/browse/SPQR-9570)
 */
export const isPointOfServiceInvalid = (state) => {
  if (!state.cart.bulkyGoods) {
    // users current cart does not contain bulkyGoods at all
    return false;
  }

  // the current cart has no pointOfServiceId definied, which indicates, that the preffered pick up
  // store is invalid for the current cart
  return (state?.cart?.deliveryMode?.pointOfServiceId || '') === '';
};

export default deliveryModes;
