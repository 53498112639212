import React from 'react';

import { Container } from 'components/atoms/Grid/Grid';
import Headline from 'components/atoms/Headline/Headline';
import Breadcrumb, { types } from 'components/organisms/Breadcrumb/Breadcrumb';
import i18n from 'constants/language/language';
import styles from './ErrorBoundary.scss';

const Fallback = () => {
  const items = [{ name: i18n('fallBackPage.breadcrumb'), href: '' }];

  return (
    <Container className={styles.container}>
      <div className={styles.breadCrumbWrapper}>
        <Breadcrumb type={types.error} items={items} />
      </div>
      <Headline>{i18n('fallBackPage.title')}</Headline>
      <p>{i18n('fallBackPage.text')}</p>
    </Container>
  );
};

export default Fallback;
