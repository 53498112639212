import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'components/atoms/Grid/Grid';
import DeliveryStateStockInfo from 'components/molecules/DeliveryStateStockInfo/DeliveryStateStockInfo';
import language from 'constants/language/language';
import { noop } from 'constants/noop/noop';
import { availabilityStates, isInStockOrOnOrder } from 'constants/deliveryStates/availabilityStates';
import { getDeliveryStateFull, getDeliveryStateShort } from 'constants/deliveryStates/getDeliveryStatus';
import styles from './DeliveryStateDetail.scss';
import Headline from 'components/atoms/Headline/Headline';
import HorizontalRule from 'components/atoms/HorizontalRule/HorizontalRule';
import { getProductDateDayDifferenceFromNow } from 'constants/date/date';
import { getDeliveryLabel } from 'constants/deliveryStates/getDeliveryLabel';
import { DeliveryStateIcons, StoreIcon, DeliverIconState } from '../DeliveryStateIcons/DeliveryStateIcons';
import { deliveryModes } from 'constants/deliveryModes/deliveryModes';

const DeliveryStateDetail = ({
  showSpeedFlag = false,
  ownStore = {},
  openStorefinderModal = noop,
  onlyAvailableOnline = false,
  availability = availabilityStates.Z,
  deliveryDate,
  pickupDate,
  releaseDate,
  productInventory = {},
  productId,
}) => {
  const ARTICLE_AVAILABLE_STORE = 101;
  const nowDate = new Date();
  const deliveryDateMessage = getDeliveryLabel(nowDate, deliveryDate, deliveryModes.homeDelivery);
  const deliveryPickUpDateMessage = getDeliveryLabel(nowDate, pickupDate, deliveryModes.pickup);
  const productStore = productInventory[productId]?.shops?.length || 0;
  const inStockOrOnOrder = isInStockOrOnOrder(availability);

  const {
    text,
    text2,
    pickup,
    delivery,
    immediatelyAvailable, // availabilityStates M | Y
    isReleaseArticle, // availabilityStates R | Y
    isInFuture,
    ...rest
  } = getDeliveryStateFull({
    availability,
    speedFlag: showSpeedFlag,
    deliveryDate,
    pickupDate,
    releaseDate,
    isCart: false,
  });

  const homeDeliveryLabel = language('product.onHomeDelivery');

  const productStoreInfo =
    productStore < 3 || onlyAvailableOnline
      ? language('inventory.deliveryInfo.availableOnline')
      : language('inventory.deliveryInfo.availableIndividualStore');

  const storeAvailibilityLabel =
    productStore >= ARTICLE_AVAILABLE_STORE ? language('inventory.deliveryInfo.availableAllStore') : productStoreInfo;

  const isAvailableForPickup = pickup && pickupDate;
  const releaseDateCheck = rest?.releaseDate ? ` ${rest.releaseDate}` : '';
  const isReleaseDateInPast = new Date(releaseDate) < nowDate || !releaseDate;

  return (
    <Container>
      {availability === availabilityStates.G2 && (
        <Row>
          <DeliveryStateIcons type={DeliverIconState.NOT_STOCK_OR_ONORDER} availability={availability} />
          <Headline tag={Headline.tags.H6}>{language('deliveryStates.short.unknown')}</Headline>
        </Row>
      )}
      {availability !== availabilityStates.Z ? (
        <>
          {(isReleaseArticle && !isReleaseDateInPast) || immediatelyAvailable ? ( // availabilityStates M | Y | R
            <>
              <Row>
                {availability === (availabilityStates.M || availabilityStates.Y) && (
                  <DeliveryStateIcons type={DeliverIconState.NOT_STOCK_OR_ONORDER} availability={availability} />
                )}
                {/** PreOrder with state R: check release date */}
                {availability === availabilityStates.R && releaseDate && (
                  <DeliveryStateIcons type={DeliverIconState.PRE_ORDER} releaseDateString={releaseDate} />
                )}
                <Headline tag={Headline.tags.H6} margin={Headline.margins.MR_025}>
                  {availability === availabilityStates.R && releaseDate
                    ? language('deliveryStates.short.esd')
                    : language('deliveryStates.short.immediately')}
                </Headline>
              </Row>
              <p className={styles.immediatelyDetail}>
                {isInFuture
                  ? getDeliveryStateShort({ availability, releaseDate }).text
                  : `${text}${releaseDateCheck}. ${text2}`}
              </p>
            </>
          ) : (
            <>
              <Row>
                {delivery && (
                  <>
                    {inStockOrOnOrder && deliveryDate && (
                      <DeliveryStateIcons
                        type={DeliverIconState.PICKUP_DELIVERY}
                        duration={getProductDateDayDifferenceFromNow(deliveryDate)}
                      />
                    )}
                    {/** delivery date unknown */}
                    {availability === availabilityStates.G2 && (
                      <DeliveryStateIcons type={DeliverIconState.NOT_STOCK_OR_ONORDER} availability={availability} />
                    )}
                    <Headline tag={Headline.tags.H6} margin={Headline.margins.MR_025}>
                      {homeDeliveryLabel},
                    </Headline>
                    <span>{deliveryDateMessage}</span>
                  </>
                )}
              </Row>

              {isAvailableForPickup && (
                <Row>
                  <DeliveryStateIcons
                    type={DeliverIconState.PICKUP_DELIVERY}
                    duration={getProductDateDayDifferenceFromNow(pickupDate)}
                  />
                  <Headline tag={Headline.tags.H6} margin={Headline.margins.MR_025}>
                    {language('product.onPickup')},
                  </Headline>
                  <span>{deliveryPickUpDateMessage}</span>
                </Row>
              )}
            </>
          )}

          <HorizontalRule />
          <Row>
            <Headline tag={Headline.tags.H2}>{language('product.storeStock')}</Headline>
          </Row>
          <Row className={styles.BranchAvailabilityContainer}>
            <StoreIcon productStore={productStore} articleAvailableStore={ARTICLE_AVAILABLE_STORE} />
            <span>{storeAvailibilityLabel}</span>

            {productStore >= 3 && (
              <div className={styles.BranchAvailabilityBox}>
                <DeliveryStateStockInfo
                  releaseDate={rest.releaseDate}
                  text={text}
                  isReleaseArticle={isReleaseArticle}
                  openStorefinderModal={openStorefinderModal}
                  ownStore={ownStore}
                />
              </div>
            )}
          </Row>
        </>
      ) : (
        // Z
        <Row className={styles.infoRow}>
          <Col className={styles.infoRowTitle}>
            <DeliveryStateIcons type={DeliverIconState.NOT_STOCK_OR_ONORDER} availability={availability} />
            {getDeliveryStateShort({ availability, releaseDate }).text}
          </Col>
        </Row>
      )}
    </Container>
  );
};

DeliveryStateDetail.displayName = 'molecules/DeliveryStateDetail';

DeliveryStateDetail.propTypes = {
  showSpeedFlag: PropTypes.bool,
  ownStore: PropTypes.object,
  openStorefinderModal: PropTypes.func,
  onlyAvailableOnline: PropTypes.bool,
  availability: PropTypes.oneOf(Object.values(availabilityStates)),
  deliveryDate: PropTypes.object,
  pickupDate: PropTypes.object,
  releaseDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  productInventory: PropTypes.object,
  productId: PropTypes.string,
};

export default DeliveryStateDetail;
