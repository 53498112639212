import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import StarRating, { THEMES, SIZES } from 'components/atoms/StarRating/StarRating';
import styles from './RatingCount.scss';
import language from 'constants/language/language';
import { scrollToElement, TOP_RATING_FORM } from 'constants/scrollToElement/scrollToElement';

const scrollToRatings = () => scrollToElement(TOP_RATING_FORM);

const RatingCount = ({ count = 0, rating = null, theme = THEMES.primary }) => {
  if (count < 3) {
    return (
      <div onClick={scrollToRatings} className={cx(styles.ratingCount, styles.underlined)}>
        {`${count} ${language('productRating.count.title')}`}
      </div>
    );
  }
  return (
    <div onClick={scrollToRatings} className={styles.ratingCount}>
      <StarRating value={rating} theme={theme} size={SIZES.medium} />
      <span className={styles.ratingCountText}>({count})</span>
    </div>
  );
};

RatingCount.propTypes = {
  count: PropTypes.number,
  rating: PropTypes.number,
  theme: PropTypes.oneOf(Object.values(THEMES)),
};

export default RatingCount;
