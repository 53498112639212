import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
  featureTogglingConfig: state.ui.featureTogglingConfig,
});

export const FeatureToggleContext = createContext([]);

const FeatureTogglingProvider = ({ featureTogglingConfig = [], children }) => (
  <FeatureToggleContext.Provider value={{ featureTogglingConfig: featureTogglingConfig }}>
    {children}
  </FeatureToggleContext.Provider>
);

FeatureTogglingProvider.displayName = 'contexts/FeatureTogglingProvider';

FeatureTogglingProvider.propTypes = {
  children: PropTypes.node,
  featureTogglingConfig: PropTypes.array,
};

export default connect(mapStateToProps)(FeatureTogglingProvider);
