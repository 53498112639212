import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Markdown from 'components/atoms/Markdown/Markdown';
import ModalPromotion from 'components/molecules/ModalPromotion/ModalPromotion';

import styles from './PromotionBox.scss';

const PromotionBox = ({ flag = {}, container = {}, popUp = {} }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <div className={styles.promotionContainer}>
      <div style={{ borderColor: flag.bgColor, color: flag.textColor }} className={styles.promotionFlag}>
        <Markdown options={{ forceInline: true }}>{flag.text}</Markdown>
      </div>
      <div
        style={{ borderColor: container.borderColor, color: container.textColor }}
        className={styles.promotionWrapper}
      >
        <Markdown options={{ forceInline: true }}>{container.text}</Markdown>
        {!!Object.keys(popUp).length && (
          <div className={styles.modalLink} onClick={() => setIsModalOpen(true)}>
            {container.linkText}
          </div>
        )}
      </div>
      {isModalOpen && <ModalPromotion {...popUp} closeModal={() => setIsModalOpen(false)} />}
    </div>
  );
};

PromotionBox.displayName = 'molecules/PromotionBox';
PromotionBox.propTypes = {
  flag: PropTypes.object,
  container: PropTypes.object,
  popUp: PropTypes.object,
};

export default PromotionBox;
