import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import LinkList from 'components/molecules/LinkList/LinkList';
import FooterColumn from 'components/molecules/FooterColumn/FooterColumn';
import styles from './FooterColumnCms.scss';
import { replaceUmlauts } from 'constants/helper/replace-umlaut';

const FooterColumnCms = ({ title, headlineTag, links, globalLink }) => {
  const [processedLinks, setProcessedLinks] = useState([]);
  const [processedGlobalLink, setProcessedGlobalLink] = useState({});

  useEffect(() => {
    const newLinks = links.map((link) => ({
      ...link,
      url: replaceUmlauts(link.url),
    }));
    setProcessedLinks(newLinks);

    if (globalLink.url) {
      setProcessedGlobalLink({
        ...globalLink,
        url: replaceUmlauts(globalLink.url),
      });
    }
  }, [links, globalLink]);

  return (
    <FooterColumn headline={title} headlineTag={headlineTag}>
      <LinkList data={processedLinks} />
      {processedLinks.length > 10 && processedGlobalLink.text && (
        <Link to={processedGlobalLink.url} className={styles.globalLink}>
          {processedGlobalLink.text}
        </Link>
      )}
    </FooterColumn>
  );
};

FooterColumnCms.displayName = 'molecules/FooterColumnCms';

FooterColumnCms.propTypes = {
  links: PropTypes.array,
  title: PropTypes.string,
  headlineTag: PropTypes.string,
  globalLink: PropTypes.object,
  offset: PropTypes.bool,
  columnOffset: PropTypes.number,
};

FooterColumnCms.defaultProps = {
  links: [],
  title: '',
  globalLink: {},
  offset: false,
  columnOffset: 2,
};

export default FooterColumnCms;
