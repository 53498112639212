import { handleActions } from 'redux-actions';
import transform from 'constants/reducerHelper/reducerHelper';
import { apiStatus } from 'constants/apiStatus/apiStatus';

import {
  ADD_TO_WATCHLIST_SUCCESS,
  GET_WATCHLIST_SUCCESS,
  MERGE_WATCHLIST_SUCCESS,
  CREATE_WATCHLIST_SUCCESS,
  DELETE_WATCHLIST_SUCCESS,
  REMOVE_FROM_WATCHLIST_SUCCESS,
  REMOVE_FROM_WATCHLIST_REQUEST,
  ADD_TO_WATCHLIST_REQUEST,
  REMOVE_FROM_WATCHLIST_FAILURE,
  REMOVE_FROM_WATCHLIST_NETWORKERROR,
  ADD_TO_WATCHLIST_FAILURE,
  ADD_TO_WATCHLIST_NETWORKERROR,
} from 'constants/ActionTypes/ActionTypes';
import { getProductsWithNewestProductPriceData } from 'constants/price/price';
import productModel from 'reducers/products/product';

export const initialState = {
  code: '',
  entries: [],
};

export default handleActions(
  {
    [GET_WATCHLIST_SUCCESS]: (state, action) => {
      const data = action.req?.data || {};
      let entries = data.entries?.map((product) => productModel({}, product));

      // Get newest productPriceData and transform prices
      entries = getProductsWithNewestProductPriceData(state, entries);

      return transform(state)
        .set('code', data.code)
        .set('entries', entries)
        .set('apiStatus', apiStatus.success)
        .value();
    },

    [MERGE_WATCHLIST_SUCCESS]: (state, action) => {
      const data = action.req?.data || {};

      return transform(state)
        .set('code', data.code)
        .set('entries', data.entries)
        .set('apiStatus', apiStatus.success)
        .value();
    },

    [CREATE_WATCHLIST_SUCCESS]: (state, action) => {
      const data = action.req?.data || {};

      return transform(state)
        .set('code', data.code)
        .set('entries', data.entries)
        .set('apiStatus', apiStatus.success)
        .value();
    },

    [REMOVE_FROM_WATCHLIST_SUCCESS]: (state, action) => {
      const data = action.req?.data || {};
      let entries = data.entries;

      // Get newest productPriceData and transform prices
      entries = getProductsWithNewestProductPriceData(state, entries);

      return transform(state)
        .set('code', data.code)
        .set('entries', entries)
        .set('apiStatus', apiStatus.success)
        .value();
    },

    [REMOVE_FROM_WATCHLIST_REQUEST]: (state) => transform(state).set('apiStatus', apiStatus.request).value(),

    [REMOVE_FROM_WATCHLIST_FAILURE]: (state) => transform(state).set('apiStatus', apiStatus.failure).value(),

    [REMOVE_FROM_WATCHLIST_NETWORKERROR]: (state) => transform(state).set('apiStatus', apiStatus.networkerror).value(),

    [ADD_TO_WATCHLIST_SUCCESS]: (state, action) => {
      const data = action.req?.data || {};
      let entries = data.entries;

      // Get newest productPriceData and transform prices
      entries = getProductsWithNewestProductPriceData(state, entries);

      return transform(state)
        .set('code', data.code)
        .set('entries', entries)
        .set('apiStatus', apiStatus.success)
        .value();
    },

    [ADD_TO_WATCHLIST_REQUEST]: (state) => transform(state).set('apiStatus', apiStatus.request).value(),

    [ADD_TO_WATCHLIST_FAILURE]: (state) => transform(state).set('apiStatus', apiStatus.failure).value(),

    [ADD_TO_WATCHLIST_NETWORKERROR]: (state) => transform(state).set('apiStatus', apiStatus.networkerror).value(),

    [DELETE_WATCHLIST_SUCCESS]: (state) => transform(state).set('entries', []).set('code', null).value(),
  },
  initialState
);
