import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { CmsLink } from 'containers/CmsLink/CmsLink';
import CmsLinkItem from 'components/atoms/CmsLinkItem/CmsLinkItem';
import { StepperButtons } from 'components/molecules/Stepper/Stepper';
import SpinnerOverlay from 'components/molecules/SpinnerOverlay/SpinnerOverlay';
import FieldSetInput from 'components/molecules/FieldSetInput/FieldSetInput';
import InputSelect from 'components/atoms/InputSelect/InputSelect';
import InputText from 'components/atoms/InputText/InputText';
import FlashMessage from 'components/atoms/FlashMessage/FlashMessage';
import Markdown from 'components/atoms/Markdown/Markdown';
import ReturnsAPI from 'api/ReturnsAPI/ReturnsAPI';
import { CMS_UID_RETURNS_POLICY } from 'constants/cms/cms';
import language, { getLocale } from 'constants/language/language';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import { accountReturns } from 'constants/routePaths/routePathCombinations';
import { types as flashMessageTypes } from 'constants/flashMessages/flashMessages';

import { browserHistory } from 'react-router';

const GUID_PREFIX = 'order-';

const ReturnOrderSelect = ({ onNextClick = () => {}, activeStep = 0, state = {} }) => {
  const { userLoggedIn } = state;
  const [orderGuid, setOrderGuid] = useState('');
  const [orderCode, setOrderCode] = useState('');
  const [lastName, setLastName] = useState('');
  const [orders, setOrders] = useState();
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (userLoggedIn && !orders) {
      setLoading(true);

      // Get users orders from backend when a user is already logged in
      ReturnsAPI.getReturnableOrders()
        .then((returnableOrders) => {
          setLoading(false);

          if (returnableOrders.length < 1) {
            setErrors((prevState) => ({
              ...prevState,
              noOrdersFound: true,
            }));
            return;
          }

          const orderCodes = {};
          returnableOrders.forEach((o) => {
            // Prefix numerical guid to fix https://atlassian.interdiscount.ch/jira/browse/PLEX-461
            orderCodes[`${GUID_PREFIX}${o.guid}`] = o.code;
          });

          setOrders(orderCodes);
          setOrderGuid(returnableOrders[0].guid);
        })
        .catch(() => {
          setLoading(false);

          setErrors((prevState) => ({
            ...prevState,
            noOrdersFound: true,
          }));
        });
    }
  }, [userLoggedIn]);

  const validateOrderCode = () => {
    const valid = orderCode.trim().length > 0;
    setErrors((prevState) => ({
      ...prevState,
      orderCode: !valid ? language('returns.orderSelect.missingOrderId') : null,
    }));
    return valid;
  };

  const validateLastName = () => {
    const valid = lastName.trim().length > 0;
    setErrors((prevState) => ({
      ...prevState,
      lastName: !valid ? language('returns.orderSelect.missingLastName') : null,
    }));
    return valid;
  };

  const handlePrevClick = () => {
    if (userLoggedIn) {
      browserHistory.push(mapPathToLocalizedUrl(getLocale(), accountReturns));
    } else {
      window.history.back();
    }
  };

  const handleNextClick = () => {
    let fetchOrder;

    if (!userLoggedIn) {
      if (!validateOrderCode() || !validateLastName()) {
        return;
      }

      fetchOrder = ReturnsAPI.searchOrder({ lastName: lastName.trim(), orderCode: orderCode.trim() });
    } else {
      fetchOrder = ReturnsAPI.getOrderById(orderGuid.replace(GUID_PREFIX, ''));
    }

    fetchOrder
      .then((fetchedOrder) => {
        onNextClick({
          ...state,
          order: fetchedOrder,
          selectedMerchantId: '',
          selectedProducts: [],
        });
      })
      .catch(() => {
        setErrors((prevState) => ({
          ...prevState,
          orderCode: language('returns.orderSelect.orderNotFound'),
        }));
      });
  };

  const render = () => {
    if (userLoggedIn && !!errors.noOrdersFound) {
      return (
        <FlashMessage type={flashMessageTypes.WARNING}>
          <Markdown options={{ forceInline: true }}>
            {language('returns.orderSelect.ordersFromUserNotFound.preLink')}
          </Markdown>
          <CmsLink pageId={CMS_UID_RETURNS_POLICY}>
            <CmsLinkItem customText={language('returns.orderSelect.ordersFromUserNotFound.link')} target="_blank" />
          </CmsLink>
          {language('returns.orderSelect.ordersFromUserNotFound.afterLink')}
        </FlashMessage>
      );
    }

    if (userLoggedIn) {
      return (
        <FieldSetInput
          options={orders}
          value={orderGuid}
          label={language('returns.orderSelect.orderNumber')}
          handleChange={(value) => setOrderGuid(value)}
        >
          <InputSelect disabled={loading} />
        </FieldSetInput>
      );
    }

    return (
      <>
        <FieldSetInput
          id="returnOrder"
          name="orderNumber"
          label={language('returns.orderSelect.orderNumber')}
          value={orderCode}
          handleChange={setOrderCode}
          handleBlur={validateOrderCode}
          forceValidation={!!errors.orderCode}
          customErrorMessage={errors.orderCode}
          required
        >
          <InputText />
        </FieldSetInput>
        <FieldSetInput
          id="returnOrder"
          name="lastName"
          label={language('returns.orderSelect.lastName')}
          value={lastName}
          handleChange={setLastName}
          handleBlur={validateLastName}
          forceValidation={!!errors.lastName}
          customErrorMessage={errors.lastName}
          required
        >
          <InputText />
        </FieldSetInput>
      </>
    );
  };

  return (
    <>
      {loading && <SpinnerOverlay />}
      {render()}
      <StepperButtons
        onPrevClick={handlePrevClick}
        onNextClick={handleNextClick}
        activeStep={activeStep}
        nextDisabled={loading || (!orderGuid && !orderCode)}
      />
    </>
  );
};

ReturnOrderSelect.displayName = 'molecules/ReturnSteps/ReturnOrderSelect';

ReturnOrderSelect.propTypes = {
  onNextClick: PropTypes.func,
  activeStep: PropTypes.number,
  state: PropTypes.object,
};

export default ReturnOrderSelect;
