import React from 'react';
import PropTypes from 'prop-types';

import { noop } from 'constants/noop/noop';

import RecoTracking from 'containers/RecoTracking/RecoTracking';
import AddAccessoryToCart from 'containers/AddAccessoryToCart/AddAccessoryToCart';
import SimpleProduct from 'containers/Product/SimpleProduct';
import ProductCard from 'components/molecules/ProductCard/ProductCard';
import AddToCartIcon from 'components/atoms/AddToCartIcon/AddToCartIcon';
import styles from './Accessory.scss';

function Accessory({
  accessoryCode,
  accessories,
  countTimesAddedAsAccessory = noop,
  onProductClick,
  productAttributes,
}) {
  return (
    <RecoTracking productCode={accessoryCode} doUpdate={countTimesAddedAsAccessory(accessories, accessoryCode) > 0}>
      <SimpleProduct productCode={accessoryCode} productAttributes={productAttributes}>
        <ProductCard productClickHandler={onProductClick}>
          <AddAccessoryToCart accessoryCode={accessoryCode} productAttributes={productAttributes}>
            <AddToCartIcon className={styles.addToCartButton} />
          </AddAccessoryToCart>
        </ProductCard>
      </SimpleProduct>
    </RecoTracking>
  );
}

Accessory.propTypes = {
  accessoryCode: PropTypes.string,
  accessories: PropTypes.array,
  countTimesAddedAsAccessory: PropTypes.func,
  onProductClick: PropTypes.func,
  productAttributes: PropTypes.object,
};
Accessory.displayName = 'organisms/ServicesAndAccessories/Accessory';

export default Accessory;
