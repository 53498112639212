import { GET_CATEGORIES } from 'constants/ActionTypes/ActionTypes';

import CategoryAPI from 'api/CategoryAPI/CategoryAPI';

export const getCategories = () => ({
  type: GET_CATEGORIES,
  promise: CategoryAPI.getCategories(),
});

export default {
  getCategories,
};
