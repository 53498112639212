import React from 'react';
import cx from 'classnames';
import language from 'constants/language/language';
import Icon, { ICON_COMPARE_ARROWS } from 'components/atoms/Icon/Icon';
import styles from './ProductComparisonEmpty.scss';

const ProductComparisonEmpty = () => (
  <div className={styles.emptyWrapper}>
    <span className={cx(styles.infoText, styles.title)}>{language('productComparison.empty.title')}</span>
    <span className={cx(styles.infoText)}>{language('productComparison.empty.partOne')}</span>
    <span className={cx(styles.infoText)}>{language('productComparison.empty.partTwo')}</span>
    <div className={styles.emptyIcon}>
      <Icon path={ICON_COMPARE_ARROWS} />
    </div>
  </div>
);

ProductComparisonEmpty.displayName = 'molecules/ProductComparisonEmpty';

export default ProductComparisonEmpty;
