import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Headline from 'components/atoms/Headline/Headline';
import { Row, Col } from 'components/atoms/Grid/Grid';
import styles from './CmsVideoComponent.scss';

const videoServices = {
  YOUTUBE: 'YOUTUBE',
  VIMEO: 'VIMEO',
};

const CmsVideoComponent = ({
  videoId = '',
  videoService = videoServices.YOUTUBE,
  title = '',
  elements = [],
  text = '',
}) => {
  const [activeVideo, setActiveVideo] = useState({ videoId, videoService });
  const getEmbedURL = (video) => ({
    VIMEO: `https://player.vimeo.com/video/${video}?byline=0&amp;portrait=0`,
    YOUTUBE: `https://www.youtube.com/embed/${video}`,
  });
  const getVimeoThumbnailUrl = (id) => {
    const firstPart = id.slice(0, 3);
    const lastPart = id.slice(3, 6);
    return `http://ts.vimeo.com.s3.amazonaws.com/${firstPart}/${lastPart}/${id}_200.jpg`;
  };
  const getThumbnailByVideoId = (id) => ({
    VIMEO: getVimeoThumbnailUrl(id),
    YOUTUBE: `https://img.youtube.com/vi/${id}/1.jpg`,
  });
  const setVideo = (video) => {
    setActiveVideo({
      videoId: video.videoId,
      videoService: video.videoService,
    });
  };
  const getVideos = () => {
    const mergedVideos = [{ videoId, videoService }, ...elements];
    mergedVideos.length = 6;
    mergedVideos.fill(false, elements.length + 1, mergedVideos.length);
    return mergedVideos;
  };
  const videos = getVideos();
  return (
    <Row>
      <Col>
        <div className={styles.container}>
          {title && <Headline>{title}</Headline>}
          <div className={cx(styles.video, styles.embedResponsive, styles.embedResponsive16By9)}>
            <iframe
              title="video"
              className={styles.embedResponsiveItem}
              src={getEmbedURL(activeVideo.videoId)[activeVideo.videoService]}
              allowFullScreen
            />
          </div>
          {!!elements.length && (
            <div className={styles.videoList}>
              {videos.map((video) => (
                <div key={video.videoId} className={styles.thumbnailWrapper}>
                  {!video ? (
                    <div className={cx(styles.thumbnail, styles.empty)} />
                  ) : (
                    <div
                      className={cx(styles.thumbnail, { [styles.active]: video.videoId === activeVideo.videoId })}
                      onClick={() => setVideo(video)}
                    >
                      <div className={styles.thumbInner}>
                        <img
                          className={styles.thumbImage}
                          alt={`${video.videoService} video`}
                          src={getThumbnailByVideoId(video.videoId)[video.videoService]}
                        />
                      </div>
                      {video.videoId !== activeVideo.videoId && <div className={styles.playButton} />}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
          {text && <div>{text}</div>}
        </div>
      </Col>
    </Row>
  );
};
CmsVideoComponent.propTypes = {
  title: PropTypes.string,
  videoId: PropTypes.string,
  videoService: PropTypes.oneOf(Object.values(videoServices)),
  elements: PropTypes.array,
  text: PropTypes.string,
};
CmsVideoComponent.displayName = 'molecules/CmsVideoComponent';
export default CmsVideoComponent;
