import {
  ADD_ACCESSORY_TO_CART,
  ADD_SUPERCARD,
  ADD_TO_CART,
  APPLY_GIFTCARD,
  APPLY_VOUCHER,
  CHANGE_AGE_VERIFICATION_TYPE,
  CLEAR_GIFTCARD_VALIDATION_RESULT,
  CLEAR_UPDATED_CART_HISTORY,
  CLEAR_VOUCHER_VALIDATION_RESULT,
  DELETE_FROM_CART,
  GET_CART,
  GET_CURRENT_DELIVERY_MODE,
  GET_DELIVERY_MODES,
  GET_FOOTER_PAYMENT_MODES,
  GET_PAYMENT_MODES,
  MERGE_CART,
  REMOVE_ADDRESS,
  REMOVE_GIFTCARD,
  REMOVE_LAST_DELETED_ITEM,
  REMOVE_PAYMENT_MODE,
  REMOVE_VOUCHER,
  RESET_ADD_TO_CART_STATE,
  RESET_AGE_VERIFICATION_TYPE,
  RESET_CART,
  RESET_PAYMENT_MODE,
  RESET_SUPERCARD_VALIDATION,
  SAVE_PREVIOUS_PAYMENT_MODE,
  SET_ADDRESS,
  SET_CHECKOUT_GIFTCARD_DISALLOWED_CHANGED,
  SET_CHECKOUT_NEED_STATUS,
  SET_DELIVERY_MODE,
  SET_GUEST_ADDRESS,
  SET_PAYMENT_MODE,
  SET_PEGI_FORCE_VALIDATION,
  SET_VERIFY_AGE_MODAL_ISOPEN,
  UPDATE_ENTRY,
  UPDATE_NEUTRAL_DELIVERY,
  UPDATE_ORDER_REFERENCE,
  UPDATE_PACKAGE_ANNOUNCEMENT,
  UPDATE_PAYMENT_DETAILS,
  UPDATE_PEGI_FIELD_VALUE,
  UPDATE_TRANSPORT_GUARANTEE,
  VERIFY_AGE,
} from 'constants/ActionTypes/ActionTypes';
import CartAPI from 'api/CartAPI/CartAPI';

/**
 * Action creator which returns a specific cart
 */
export const getCart = (refresh) => ({
  type: GET_CART,
  payload: {
    cartId: CartAPI.getCartId(),
  },
  promise: CartAPI.getCart(refresh),
});

export const resetCart = () => ({
  type: RESET_CART,
  promise: CartAPI.resetCart(),
});

export const mergeCart = () => ({
  type: MERGE_CART,
  promise: CartAPI.mergeCart(),
});

/**
 * Action creator which add a product to an existing cart
 * @param code
 * @param quantity
 * @param productAttributes
 */
export const addToCart = (code, quantity, productAttributes) => ({
  type: ADD_TO_CART,
  payload: { productAttributes },
  promise: CartAPI.addCartEntry(code, quantity),
});

/**
 * Action creator which adds an accessory to an existing cart
 * @param code
 * @param quantity
 * @param productAttributes
 */
export const addAccessoryToCart = (code, quantity, productAttributes) => ({
  type: ADD_ACCESSORY_TO_CART,
  payload: { productAttributes },
  promise: CartAPI.addCartEntry(code, quantity),
});

/**
 * Action creator which reset the current add-to-cart-state.
 */
export const resetAddToCartState = () => ({
  type: RESET_ADD_TO_CART_STATE,
});

/**
 * Action creator which changes the count of  a product in the cart
 * @param entryID
 * @param code
 * @param quantity
 */
export const updateEntry = (entryID, code, quantity, serviceItemCodes, oldServiceItemCodes = []) => ({
  type: UPDATE_ENTRY,
  payload: {
    entryID,
    RequestTimestamp: new Date().getTime(),
    quantity,
    serviceItemCodes,
    oldServiceItemCodes,
  },
  promise: CartAPI.updateCartEntry(entryID, code, quantity, serviceItemCodes),
});

/**
 * Action creator which removes a product from an existing cart
 * @param entryNumber
 */
export const removeFromCart = (entryNumber) => ({
  type: DELETE_FROM_CART,
  payload: { entryNumber: entryNumber },
  promise: CartAPI.deleteCartEntry(entryNumber),
});

/**
 * Action creator which removes the last deleted item in the store
 */
export const removeLastDeletedItem = () => ({
  type: REMOVE_LAST_DELETED_ITEM,
  payload: {},
});

/**
 * Action creator which creates an address in the guest checkout.
 *
 * @param type
 * @param address
 * @param isSameAddressForInvoiceAndDelivery
 * @param reference
 */
export const setGuestAddress = (type, address, isSameAddressForInvoiceAndDelivery, reference) => ({
  type: SET_GUEST_ADDRESS,
  payload: { type },
  promise: CartAPI.setGuestAddress(type, address, isSameAddressForInvoiceAndDelivery, reference),
});

/**
 * Action creator which sets an existing address on a specific address type in the cart.

 * @param type
 * @param addressId
 */
export const setAddress = (type, addressId) => ({
  type: SET_ADDRESS,
  payload: { type, addressId },
  promise: CartAPI.setAddress(type, addressId),
});

/**
 * Action creator which removes the address of a specific type in cart.

 * @param type
 */
export const removeAddress = (type) => ({
  type: REMOVE_ADDRESS,
  payload: { type },
  promise: CartAPI.removeAddress(type),
});

/**
 * Action creator which updates the current payment details
 * @param paymentDetails
 */
export const updatePaymentDetails = (paymentDetails) => ({
  type: UPDATE_PAYMENT_DETAILS,
  payload: {},
  promise: CartAPI.updatePaymentDetails(paymentDetails),
});

/**
 * Action creator which updates the order reference
 * @param orderReference
 */
export const updateOrderReference = (orderReference) => ({
  type: UPDATE_ORDER_REFERENCE,
  payload: {},
  promise: CartAPI.updateOrderReference({ orderReference }),
});

/**
 * Action creator which returns the supported payment modes for a cart
 */
export const getPaymentModes = () => ({
  type: GET_PAYMENT_MODES,
  payload: {},
  promise: CartAPI.getPaymentModes(),
});

/**
 * Action creator which sets the payment mode for a cart
 * @param paymentMode
 * @param useAlias
 */
export const setPaymentMode = (paymentMode, useAlias) => ({
  type: SET_PAYMENT_MODE,
  payload: { paymentMode, useAlias },
  promise: CartAPI.setPaymentMode(paymentMode, useAlias),
});

/**
 * Action creator which resets the payment mode validation
 */
export const resetPaymentMode = () => ({
  type: RESET_PAYMENT_MODE,
  payload: {},
  promise: CartAPI.deleteCurrentPaymentMode(),
});

/**
 * Action to remove the saved payment mode
 * @param savedPaymentModeCode
 */
export const removePaymentMode = () => ({
  type: REMOVE_PAYMENT_MODE,
  payload: {},
  promise: CartAPI.removePaymentMode(),
});

/**
 * Action creator which returns the supported payment modes for footer
 */
export const getFooterPaymentModes = () => ({
  type: GET_FOOTER_PAYMENT_MODES,
  payload: {},
  promise: CartAPI.getFooterPaymentModes(),
});

/**
 * Action creator which returns the current payment mode for a cart
 */
export const getCurrentDeliveryMode = () => ({
  type: GET_CURRENT_DELIVERY_MODE,
  payload: {},
  promise: CartAPI.getCurrentDeliveryMode(),
});

/**
 * Action creator which returns the supported delivery modes for a cart
 */
export const getDeliveryModes = () => ({
  type: GET_DELIVERY_MODES,
  payload: {},
  promise: CartAPI.getDeliveryModes(),
});

/**
 * Action creator which sets the delivery mode for a cart
 */
export const setDeliveryMode = (deliveryMode, pointOfServiceId, defaultModeApplied = false) => {
  let promise;
  if (!(deliveryMode === 'pickup' && pointOfServiceId === '')) {
    promise = CartAPI.setDeliveryMode(deliveryMode, pointOfServiceId);
  } else {
    promise = Promise.resolve({ resolvedLocally: true });
  }
  return {
    type: SET_DELIVERY_MODE,
    payload: { deliveryMode, pointOfServiceId, defaultModeApplied },
    promise,
  };
};

/**
 * Action creator which updates transport guarantee
 * @param transportGuaranteeData
 * {
 *  active: true
 * }
 */
export const updateTransportGuarantee = (transportGuaranteeData) => ({
  type: UPDATE_TRANSPORT_GUARANTEE,
  payload: updateTransportGuarantee,
  promise: CartAPI.updateTransportGuarantee(transportGuaranteeData),
});

export const savePreviousPaymentMode = (paymentMode) => ({
  type: SAVE_PREVIOUS_PAYMENT_MODE,
  payload: paymentMode,
});

export const applyVoucher = (voucherCode) => ({
  type: APPLY_VOUCHER,
  payload: {},
  promise: CartAPI.applyVoucher(voucherCode),
});

export const removeVoucher = (voucherCode) => ({
  type: REMOVE_VOUCHER,
  payload: {},
  promise: CartAPI.removeVoucher(voucherCode),
});

export const clearVoucherValidationResult = () => ({ type: CLEAR_VOUCHER_VALIDATION_RESULT });

export const changeAgeVerificationType = (verificationType) => ({
  type: CHANGE_AGE_VERIFICATION_TYPE,
  payload: verificationType,
});

export const setVerifyAgeModalIsOpen = () => ({
  type: SET_VERIFY_AGE_MODAL_ISOPEN,
});

export const verifyAge = (pegiData) => ({
  type: VERIFY_AGE,
  payload: {},
  promise: CartAPI.verifyAge(pegiData),
});

export const updatePegiFieldValue = (fieldName, value) => ({
  type: UPDATE_PEGI_FIELD_VALUE,
  payload: {
    fieldName,
    value,
  },
});

export const setPegiForceValidation = (value) => ({
  type: SET_PEGI_FORCE_VALIDATION,
  payload: value,
});

export const resetAgeVerificationType = () => ({
  type: RESET_AGE_VERIFICATION_TYPE,
});

export const applyGiftcard = (giftcardCode, giftcardPin) => ({
  type: APPLY_GIFTCARD,
  payload: {},
  promise: CartAPI.applyGiftcard(giftcardCode, giftcardPin),
});

export const removeGiftcard = (giftcardCode) => ({
  type: REMOVE_GIFTCARD,
  payload: {},
  promise: CartAPI.removeGiftcard(giftcardCode),
});

export const clearGiftcardValidationResult = () => ({ type: CLEAR_GIFTCARD_VALIDATION_RESULT });

export const clearUpdatedCartHistory = () => ({ type: CLEAR_UPDATED_CART_HISTORY });

export const setCheckoutNeedStatus = (status) => ({
  type: SET_CHECKOUT_NEED_STATUS,
  payload: {
    status,
  },
});

export const updatePackageAnnouncement = (isChecked) => ({
  type: UPDATE_PACKAGE_ANNOUNCEMENT,
  payload: {
    active: isChecked,
  },
  promise: CartAPI.updatePackageAnnouncement(isChecked),
});

export const addSupercard = (supercardCode) => ({
  type: ADD_SUPERCARD,
  payload: {
    supercardCode,
  },
  promise: CartAPI.addSupercard(supercardCode),
});

export const resetSupercardValidation = () => ({
  type: RESET_SUPERCARD_VALIDATION,
  payload: {},
});

export const updateNeutralDeliveryFlag = (neutralDeliveryActive) => ({
  type: UPDATE_NEUTRAL_DELIVERY,
  neutralDeliveryActive,
  promise: CartAPI.updateNeutralDelivery(neutralDeliveryActive),
});

export const setGiftcardDisallowedChanged = (isGiftcardDisallowed) => ({
  type: SET_CHECKOUT_GIFTCARD_DISALLOWED_CHANGED,
  payload: {
    isGiftcardDisallowed,
  },
});

const actions = {
  getCart,
  resetCart,
  mergeCart,
  addToCart,
  addAccessoryToCart,
  updateEntry,
  removeFromCart,
  removeLastDeletedItem,
  getPaymentModes,
  setPaymentMode,
  resetPaymentMode,
  getFooterPaymentModes,
  resetAddToCartState,
  setGuestAddress,
  setAddress,
  removeAddress,
  getCurrentDeliveryMode,
  getDeliveryModes,
  setDeliveryMode,
  updatePaymentDetails,
  updateOrderReference,
  updateTransportGuarantee,
  savePreviousPaymentMode,
  applyVoucher,
  removeVoucher,
  clearVoucherValidationResult,
  changeAgeVerificationType,
  setVerifyAgeModalIsOpen,
  verifyAge,
  updatePegiFieldValue,
  setPegiForceValidation,
  resetAgeVerificationType,
  applyGiftcard,
  removeGiftcard,
  clearGiftcardValidationResult,
  clearUpdatedCartHistory,
  setCheckoutNeedStatus,
  removePaymentMode,
  updatePackageAnnouncement,
  addSupercard,
  setGiftcardDisallowedChanged,
};

export default actions;
