import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import LazyLoad from 'react-lazyload';

import { getLocale } from 'constants/language/language';
import getUrlForProduct from 'constants/getUrlForProduct/getUrlForProduct';
import imageSizes from 'constants/imageSizes/imageSizes';
import { cypressAttributes } from 'constants/cypress/cypress';

import RecoTracking from 'containers/RecoTracking/RecoTracking';
import AddToCart from 'containers/AddToCart/AddToCart';

import ListInteraction from 'components/molecules/ProductInteractions/ListInteraction';
import DeliveryState, { deliverySkins } from 'components/molecules/DeliveryState/DeliveryState';
import TeaserPromotion, { skins as promotionSkins } from 'components/molecules/TeaserPromotion/TeaserPromotion';

import DiscountFlag, { skins } from 'components/atoms/DiscountFlag/DiscountFlag';
import EnergyEfficiency, {
  contexts as energyContexts,
  types,
} from 'components/atoms/EnergyEfficiency/EnergyEfficiency';
import { margins as headlineMargins } from 'components/atoms/Headline/Headline';
import Icon, { ICON_CHECK, ICON_COMPARE_ARROWS, ICON_FAVORITE_BORDER } from 'components/atoms/Icon/Icon';
import Image from 'components/atoms/Image/Image';
import Price from 'components/atoms/Price/Price';
import ProductFlag from 'components/atoms/ProductFlag/ProductFlag';
import AddToCartIcon from 'components/atoms/AddToCartIcon/AddToCartIcon';
import ShortenedHeadline from 'components/atoms/ShortenedHeadline/ShortenedHeadline';
import StarRating, { SIZES as ratingSizes, THEMES as ratingThemes } from 'components/atoms/StarRating/StarRating';
import { apiStatus } from 'constants/apiStatus/apiStatus';

import styles from './TeaserProduct.scss';
import { NextFeatureLink } from 'components/atoms/NextFeatureLink';

export const CONTEXTS = {
  accessory: 'accessory',
  teaser: 'teaser',
  swiper: 'swiper',
};

const TeaserProduct = ({
  addToComparison = () => {},
  addToWatchlist = () => {},
  addedAsAccessory = false,
  availability = '',
  averageRating = 0,
  canBeBought = false,
  categories = {},
  categoryCode = '',
  code = '',
  context = CONTEXTS.teaser,
  customImageData = [],
  energyEfficiency = {},
  energyEfficiencyEnEv2020 = {},
  layzload = true,
  maxOrderCounterActive = false,
  name = '',
  neutralDeliveryPossible = false,
  productAddedToComparison = false,
  productAddedToWatchlist = false,
  productClickAction = () => {},
  productOrderable = false,
  productPriceData = {},
  promoEnded = false,
  promoLabels = [],
  promotion = {},
  removeFromComparison = () => {},
  removeFromWatchlist = () => {},
  speedProduct = false,
  trackRecoProduct = () => {},
  trackingContext = '',
  productAttributes,
  watchlistApiStatus,
}) => {
  /**
   * Used to track click events. The context is necessary to determine in which
   * category the user clicked on a teaser
   */
  const trackProductClick = () => {
    productClickAction(trackingContext);
    if (context === CONTEXTS.swiper) {
      trackRecoProduct();
    }
    if (sessionStorage.getItem('previousPath')) sessionStorage.removeItem('previousPath');
    if (window.location.href.includes('search')) sessionStorage.setItem('previousPath', window.location.href);
  };

  const renderImage = (isPlaceholder) => (
    <Image
      className={styles.image}
      alt={customImageData?.[0]?.altText || name}
      imageId={code}
      images={isPlaceholder ? [] : customImageData?.[0]?.sizes || []}
      size={imageSizes.productTeaser.size}
      responsive={imageSizes.productTeaser.responsive}
      code={customImageData[0]?.code}
    />
  );

  const locale = getLocale();

  const hasEnergyEfficiency =
    !!Object.keys(energyEfficiency || {})?.length || !!Object.keys(energyEfficiencyEnEv2020 || {})?.length;

  const isWatchlistDisabled = watchlistApiStatus === apiStatus.request;

  return (
    <div
      className={cx(styles.teaserContainer, {
        [styles.addedAsAccessory]: context === CONTEXTS.accessory && addedAsAccessory,
      })}
    >
      <NextFeatureLink
        feature="nextPdpEnabled"
        className={styles.link}
        to={getUrlForProduct(categoryCode, name, code, locale, categories)}
        onClick={trackProductClick}
      >
        {context === CONTEXTS.accessory && addedAsAccessory && (
          <i className={styles.AccessoryFlag}>
            <Icon path={ICON_CHECK} />
          </i>
        )}
        <div className={styles.imageArea}>
          <div className={styles.flagList}>
            <div className={styles.flagItem}>
              {!!Object.keys(productPriceData.discount || {}).length && <DiscountFlag skin={skins.TEASER} />}
            </div>
          </div>
          {layzload ? <LazyLoad placeholder={renderImage(true)}>{renderImage()}</LazyLoad> : renderImage()}
          <div className={cx(styles.flagItem, styles.energyEfficiency)}>
            {hasEnergyEfficiency && (
              <EnergyEfficiency
                energyEfficiency={energyEfficiency}
                energyEfficiencyEnEv2020={energyEfficiencyEnEv2020}
                type={types.sm}
                context={energyContexts.TEASER}
              />
            )}
          </div>
          <div className={styles.productFlag}>{!!promoLabels.length && <ProductFlag text={promoLabels[0].text} />}</div>
        </div>
        <div className={styles.infoArea}>
          <div className={styles.priceInfo}>
            {canBeBought && (
              <Price productPriceData={productPriceData} skin={Price.SKINS.PRODUCTTEASER} showInsteadPrice />
            )}
          </div>
          <div className={styles.productName}>
            <ShortenedHeadline text={name} margin={headlineMargins.NONE} />
          </div>
          {averageRating > 0 && (
            <div className={styles.ratingArea}>
              <StarRating value={averageRating} theme={ratingThemes.secondary} size={ratingSizes.small} />
            </div>
          )}
          <div className={styles.promo}>
            {maxOrderCounterActive ? (
              <div>
                <TeaserPromotion {...promotion} hasEnded={promoEnded} skin={promotionSkins.TEASER} />
              </div>
            ) : (
              <>
                <DeliveryState
                  skin={deliverySkins.TEASER}
                  speedFlag={speedProduct}
                  productOrderable={productOrderable}
                  neutralDeliveryPossible={neutralDeliveryPossible}
                  availability={availability}
                />
              </>
            )}
          </div>
        </div>
      </NextFeatureLink>
      <div className={styles.actionsArea} data-cy={cypressAttributes.product.productActions}>
        <ListInteraction
          addProductToList={addToComparison}
          removeProductFromList={removeFromComparison}
          addedToList={productAddedToComparison}
          productCode={code}
          addedIcon={ICON_COMPARE_ARROWS}
          trackingType="compare"
          cyData={[
            cypressAttributes.comparisonList.addToComparisonListLink,
            productAddedToComparison
              ? cypressAttributes.comparisonList.arrowsIn
              : cypressAttributes.comparisonList.arrowsOut,
          ]}
        />
        <ListInteraction
          addProductToList={addToWatchlist}
          removeProductFromList={removeFromWatchlist}
          addedToList={productAddedToWatchlist}
          productCode={code}
          addedIcon={ICON_FAVORITE_BORDER}
          trackingType="bookmark"
          isDisabled={isWatchlistDisabled}
        />
        <RecoTracking code={code}>
          <AddToCart productCode={code} productAttributes={productAttributes}>
            <AddToCartIcon />
          </AddToCart>
        </RecoTracking>
      </div>
    </div>
  );
};

TeaserProduct.displayName = 'molecules/TeaserProduct';
TeaserProduct.propTypes = {
  addToComparison: PropTypes.func,
  addToWatchlist: PropTypes.func,
  addedAsAccessory: PropTypes.bool,
  availability: PropTypes.string,
  averageRating: PropTypes.number,
  canBeBought: PropTypes.bool,
  categories: PropTypes.object,
  categoryCode: PropTypes.string,
  code: PropTypes.string,
  context: PropTypes.oneOf(Object.values(CONTEXTS)),
  customImageData: PropTypes.array,
  energyEfficiency: PropTypes.object,
  energyEfficiencyEnEv2020: PropTypes.object,
  id: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  layzload: PropTypes.bool,
  maxOrderCounterActive: PropTypes.bool,
  name: PropTypes.string,
  neutralDeliveryPossible: PropTypes.bool,
  productAddedToComparison: PropTypes.bool,
  productAddedToWatchlist: PropTypes.bool,
  productClickAction: PropTypes.func,
  productOrderable: PropTypes.bool,
  productPriceData: PropTypes.object,
  promoEnded: PropTypes.bool,
  promoLabels: PropTypes.array,
  promotion: PropTypes.object,
  removeFromComparison: PropTypes.func,
  removeFromWatchlist: PropTypes.func,
  speedProduct: PropTypes.bool,
  trackRecoProduct: PropTypes.func,
  trackingContext: PropTypes.string,
  productAttributes: PropTypes.object,
  watchlistApiStatus: PropTypes.string,
};

export default TeaserProduct;
