import { useState } from 'react';
import getCookieStorage from 'constants/storage/cookie';

const { getItem: getCookie, setItem: setCookie } = getCookieStorage();

export function useCookie(key) {
  const [item, setItem] = useState(() => {
    return getCookie(key);
  });

  const updateCookie = (value, expiresInDays) => {
    setItem(value);
    setCookie(key, value, expiresInDays);
  };

  return [item, updateCookie];
}
