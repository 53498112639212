import contactActionsBase from './contactActions.base';

import { CHANGE_CONTACT_SUBJECT, GET_CONTACT_SUBJECTS } from 'constants/ActionTypes/ActionTypes';

export const getContactSubjects = () => ({
  type: GET_CONTACT_SUBJECTS,
});

export const changeContactSubject = (subject) => ({
  type: CHANGE_CONTACT_SUBJECT,
  payload: { subject },
});

export const { updateContactField, blurContactField, sendContactRequest, reinitializeContactForm } = contactActionsBase;

export default contactActionsBase;
