// @flow

import { handleActions } from 'redux-actions';
import {
  LOAD_PRODUCTS_SUCCESS,
  LOAD_PRODUCT_SUCCESS,
  LOAD_PRODUCT_REQUEST,
  LOAD_PRODUCT_FAILURE,
  LOAD_PRODUCT_NETWORKERROR,
  LOAD_PRODUCT_LIST_SUCCESS,
  GET_CART_SUCCESS,
  MERGE_CART_SUCCESS,
  ADD_TO_CART_SUCCESS,
  GET_CMS_CONTENT_FOR_PAGE_SUCCESS,
  GET_PRODUCT_ACCESSORIES_SUCCESS,
  ON_VIDEO_AVAILABLE,
  ADD_TO_WATCHLIST_SUCCESS,
  ADD_TO_WATCHLIST_FAILURE,
  ADD_TO_WATCHLIST_NETWORKERROR,
  GET_TIME_CRITICAL_PRODUCT_DATA_SUCCESS,
} from 'constants/ActionTypes/ActionTypes';
import productModel from './product';

import type { Products, Product } from 'reducers/products/products';

import transform from 'constants/reducerHelper/reducerHelper';
import { apiStatus } from 'constants/apiStatus/apiStatus';
import { getPriceData, getProductsWithNewestProductPriceData } from 'constants/price/price';

const initialState = {};

export const getProductsFromCmsCall = (data: Object) => {
  const cmsDataBefore = data?.before?.cmsDatas || [];
  const cmsDataAfter = data?.after?.cmsDatas || [];

  const cmsElements = cmsDataBefore.concat(cmsDataAfter).filter((element) => element.elements?.length);

  return cmsElements.reduce((accumulator, currentValue) => {
    accumulator.push(...currentValue.elements);
    return accumulator;
  }, []);
};

export const setProductByApiStatus = (state: Object, action: Object, newApiStatus: String) => {
  const productCode = action.payload?.productCode;
  if (!productCode) return state;

  return transform(state).set(`${productCode}.apiStatus`, newApiStatus).value();
};

export const assignProductsByCartEntries = (state: Object, entries: Array<{ product: Product }>) => {
  if (!entries.length) {
    return state;
  }

  const newProducts = {};
  entries.forEach(({ deliveryDate, product = {} }) => {
    if (product.code) {
      const productWithNewestPriceData = getProductsWithNewestProductPriceData(state, [product])[0];
      newProducts[product.code] = productModel(state[product.code], { ...productWithNewestPriceData, deliveryDate });
    }
  });
  return { ...state, ...newProducts };
};

export default handleActions(
  {
    [LOAD_PRODUCTS_SUCCESS]: (state, action): Products => {
      const products = action.req?.data?.products || [];
      const cmsProducts = getProductsFromCmsCall(action.req?.data?.cmsContent || {});
      let finalProducts = cmsProducts.concat(products).filter(Boolean);

      // ensure that all products have the same format
      finalProducts = finalProducts.map((product) => productModel(state[product.code], product));

      // Get newest productPriceData and transform prices
      finalProducts = getProductsWithNewestProductPriceData(state, finalProducts);

      const newState = transform(state);
      finalProducts.forEach((product) => {
        newState.set(product.code, product);
      });

      return newState.value();
    },

    /* TODO: Remove when multi-load API is available */
    [LOAD_PRODUCT_SUCCESS]: (state, action): Products => {
      const newProduct = action.req?.data || {};
      const code = newProduct.code;
      if (!code) return state;
      const oldProduct = state[code] || {};
      const newState = transform(state);

      // Get newest productPriceData and transform prices
      const productWithNewestPriceData = getProductsWithNewestProductPriceData(state, [newProduct])[0];

      return newState
        .set(code, {
          ...productModel(oldProduct, productWithNewestPriceData),
          apiStatus: apiStatus.success,
        })
        .value();
    },

    [LOAD_PRODUCT_REQUEST]: (state, action): Products => setProductByApiStatus(state, action, apiStatus.request),

    [LOAD_PRODUCT_FAILURE]: (state, action): Products => setProductByApiStatus(state, action, apiStatus.failure),

    [LOAD_PRODUCT_NETWORKERROR]: (state, action): Products =>
      setProductByApiStatus(state, action, apiStatus.networkerror),

    [LOAD_PRODUCT_LIST_SUCCESS]: (state, action): Products => {
      let newProducts = action.req?.data || [];

      // Get newest productPriceData and transform prices
      newProducts = getProductsWithNewestProductPriceData(state, newProducts);

      const products = newProducts.map((product) => {
        if (product.name) {
          return productModel(state[product.code], product);
        }
        return null;
      });
      const newState = transform(state);

      products.forEach((product, i) => {
        if (product !== null) {
          newState.set(product.code, {
            ...product,
            recoTrackingToken: action.payload?.productsToFetch?.[i]?.trackingToken || '',
            recoFeedbackUrl: action.payload?.productsToFetch?.[i]?.feedbackUrl || '',
          });
        }
      });

      return newState.value();
    },

    [GET_CART_SUCCESS]: (state, action) => {
      const entries = action.req?.data?.entries || [];
      return assignProductsByCartEntries(state, entries);
    },

    [MERGE_CART_SUCCESS]: (state, action) => {
      const entries = action.req?.data?.cart?.entries || [];
      return assignProductsByCartEntries(state, entries);
    },

    [ADD_TO_CART_SUCCESS]: (state, action) => {
      const entries = action.req?.data?.cart?.entries || [];
      return assignProductsByCartEntries(state, entries);
    },

    /**
     * CMS Elements may contain product data, which will be saved in the products store
     * @param state
     * @param action
     * @returns {{}}
     */
    [GET_CMS_CONTENT_FOR_PAGE_SUCCESS]: (state, action) => {
      let newProducts = action.req.data ? getProductsFromCmsCall(action.req.data).filter(Boolean) : [];

      // Get newest productPriceData and transform prices
      newProducts = getProductsWithNewestProductPriceData(state, newProducts);

      newProducts = newProducts.map((product) => productModel(state[product.code], product));
      let alternateProducts = action.req?.data?.products || [];
      alternateProducts = alternateProducts.map((product) => productModel(state[product.code], product));
      const newState = transform(state);

      if (alternateProducts.length) {
        alternateProducts.forEach((product) => {
          newState.set(product.code, product);
        });
      }

      newProducts.forEach((product) => {
        newState.set(product.code, product);
      });

      return newState.value();
    },

    [GET_PRODUCT_ACCESSORIES_SUCCESS]: (state, action) => {
      const productCode = action.payload?.productCode;
      let references = action.req?.data?.references || [];
      references = references.map((reference) => reference.target);

      // Get newest productPriceData and transform prices
      references = getProductsWithNewestProductPriceData(state, references);

      const numberOfPages = action.req?.data?.numberOfPages ?? 1;
      const hasAllReferences = references.length === 25 || numberOfPages === 1;
      const newProducts = references.map((reference) => productModel(state[reference.code], reference));
      const referenceItemCodes = [...new Set(newProducts.map((newProduct) => newProduct.code))];
      const product = productModel(state[productCode], {
        ...state[productCode],
        referenceItemCodes,
        hasAllReferences,
      });
      const newState = transform(state);

      newProducts.forEach((newProduct) => {
        newState.set(`${newProduct.code}`, newProduct);
      });

      newState.set(`${productCode}`, product);

      return newState.value();
    },

    [ON_VIDEO_AVAILABLE]: (state, action) => {
      const productCode = action.productCode;
      if (!productCode) return state;

      const newState = transform(state);
      newState.set(`${productCode}.isProductVideoAvailable`, true);

      return newState.value();
    },

    [ADD_TO_WATCHLIST_SUCCESS]: (state, action) => {
      const productCode = action.payload?.productCode;
      if (!productCode) return state;

      const isAdded = state?.[productCode]?.addedToWatchlist ?? false;

      const newState = transform(state);

      newState.set(`${productCode}.addedToWatchlist`, !isAdded);

      return newState.value();
    },

    [ADD_TO_WATCHLIST_FAILURE]: (state, action) => setProductByApiStatus(state, action, apiStatus.failure),

    [ADD_TO_WATCHLIST_NETWORKERROR]: (state, action) => setProductByApiStatus(state, action, apiStatus.networkerror),

    [GET_TIME_CRITICAL_PRODUCT_DATA_SUCCESS]: (state, action) => {
      const productCodes = action.payload?.productCodes || [];
      if (!productCodes.length) {
        return state;
      }

      const newState = transform(state);

      productCodes.forEach((code) => {
        const products = action.req?.data || [];
        const product = products.filter((element) => element.code === code)[0] || {};

        if (state[code]) {
          const promotion = {
            stockMax: product?.stockMax || 0,
            stockCurrent: product?.stockCurrent || 0,
            endTime: +new Date(product?.endTime || 0),
          };
          const productPriceData = getPriceData(product.productPriceData, true);
          newState.set(`[${code}]`, {
            ...state[code],
            promotion,
            productPriceData,
          });
        }
      });
      return newState.value();
    },
  },
  initialState
);
