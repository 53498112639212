import { useEffect, useState } from 'react';
import {TELESALES_EMPLOYEE_GROUP} from 'constants/storage/storageKeys';

const useTelesalesGroup = () => {
  const [isHeadquarterEmployee, setIsHeadquarterEmployee] = useState(false);
  const [isStoreEmployee, setIsStoreEmployee] = useState(false);

  useEffect(() => {
    if (__CLIENT__) {
      setIsHeadquarterEmployee(localStorage?.getItem(TELESALES_EMPLOYEE_GROUP) === "TELESALESHQGROUP");
      setIsStoreEmployee(localStorage?.getItem(TELESALES_EMPLOYEE_GROUP) === "TELESALESGROUP");
    }
  }, []);
  return {isHeadquarterEmployee, isStoreEmployee};
};

export default useTelesalesGroup;
