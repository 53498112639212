// @flow
import React from 'react';

import { TOP_RATING_FORM } from 'constants/scrollToElement/scrollToElement';
import Spinner from 'components/atoms/Spinner/Spinner';
import RatingDetails from 'components/organisms/Rating/RatingDetails/RatingDetails';
import RatingEditor from 'components/organisms/Rating/RatingEditor/RatingEditor';
import styles from './Rating.scss';

import type { IRating } from 'flow/ratings';

type IProps = {
  showEditor: boolean,
  isLoading: boolean,
  selectedRating?: ?IRating,
  ratingOverview?: {},
  pages?: {},
  toggleRateEditor: Function,
  onSubmitRating: Function,
  onCancelRating: Function,
};

const Rating = (props: IProps) => (
  <div id={TOP_RATING_FORM} className={styles.rating}>
    {props.isLoading && (
      <div className={styles.loadingOverlay}>
        <Spinner />
      </div>
    )}
    {props.showEditor ? (
      <RatingEditor rating={props.selectedRating || undefined} {...props} />
    ) : (
      <RatingDetails {...props.ratingOverview} {...props} />
    )}
  </div>
);

export default Rating;
