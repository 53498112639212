import React from 'react';
import PropTypes from 'prop-types';

import language, { getLocale } from 'constants/language/language';
import { types as flashMessageTypes } from 'constants/flashMessages/flashMessages';
import FormattedPrice from 'components/atoms/FormattedPrice/FormattedPrice';
import FlashMessage from 'components/atoms/FlashMessage/FlashMessage';

import { HELPCENTER_LINK } from 'constants/links/links';

import styles from './ReturnableProduct.scss';

const getMessage = (productCode, msg) => {
  const showContactLink = msg === 'BULKY_GOOD' || msg === 'ESD';
  const flashMessageType = msg === 'ESD' ? flashMessageTypes.WARNING : flashMessageTypes.INFO;

  return (
    <FlashMessage id={`formControl-returnableProductNotReturnable-${productCode}-text`} type={flashMessageType}>
      {language(`returns.productSelect.notReturnable.${msg}`)}
      {showContactLink && (
        <>
          {language(`returns.productSelect.contactLink.text`)}
          <a href={HELPCENTER_LINK + getLocale()} target="_blank" rel="noopener noreferrer">
            {language('returns.productSelect.contactLink.link')}
          </a>
        </>
      )}
    </FlashMessage>
  );
};

const ReturnableProduct = ({ product = {}, children, childPos = 'block' }) => (
  <div className={styles.returnableProduct}>
    <span>{product.name}</span>
    <span>{`${language('product.itemNumber')} ${product.code}`}</span>
    <img alt={product.name} src={product.image} className={styles.productImage} />
    {!!product.notReturnable && getMessage(product.code, product.notReturnable)}
    <div className={styles.footer}>
      <span>
        {language('returns.productSelect.price')} <FormattedPrice value={product.price} />
      </span>
      {childPos === 'inline' && children}
    </div>
    {childPos === 'block' && children}
  </div>
);

ReturnableProduct.propTypes = {
  product: PropTypes.object,
  children: PropTypes.node.isRequired,
  childPos: PropTypes.oneOf(['inline', 'block']),
};

export default ReturnableProduct;
