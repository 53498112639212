import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './CookieBanner.scss';
import Icon, { ICON_CLOSE } from 'components/atoms/Icon/Icon';
import language, { getLocale } from 'constants/language/language';
import { getPrivacyPolicyLink } from 'constants/links/getPrivacyPolicyLinks';
import cx from 'classnames';
import useFeatureToggle from 'hooks/useFeatureToggle';
import matchMedia from 'constants/matchMedia/matchMedia';
import { BREAKPOINTS } from 'constants/breakpoints/breakpoints';
import getCookieStorage from 'constants/storage/cookie';
import { isClient } from 'constants/isClient/isClient';
import { trackExitCookieBanner, trackMoreInfoCookieBanner } from 'tracking/tracking';
import { ID_DOMAIN } from 'constants/storage/cookieKeys';

export const COOKIE_BANNER_EXITED = 'LSKey-c$cookiebanner_exited';

/**
 * Cookie consent. Should disappear for 1 year if user clicks on "close".
 * Appears as "fixed" element at the bottom of the page.
 * If added to a element that is positioned "relative", the banner is positioned relative to that element and the given bottomMargin is added. (e.g PDP)
 * To exclude the banner from certain routes, you can pass a pattern to excludeRoutePattern. (e.g. "/checkout"). This way, we can exclude the PDP from normal "fixed" banner
 * and have a more relative one there and be more agnostic to the "Add to basket" button that appears when user is scrolling.
 *
 * @param marginBottom: The bottom margin of the banner. Default 0
 * @param excludeRoutePattern: A pattern that is used to exclude the banner from certain routes. Default undefined
 * @returns
 */
const CookieBanner = ({ marginBottom = 0, excludeRoutePattern = null }) => {
  const { getItem: getCookie, setItem: setCookie } = getCookieStorage();
  const enabled = useFeatureToggle('cookieBanner') === 'true' ?? false;
  const [showBanner, setShowBanner] = useState(true);

  // Feature toggle
  if (!enabled && !__DEV__) return null;

  // Only render on client
  if (!isClient()) return null;

  // 0 = wide banner, 1 = narrow banner
  const variant = 0;

  // cookie consent already given
  if (getCookie(COOKIE_BANNER_EXITED) === '1' || !showBanner) {
    return null;
  }

  // exclude given patterns
  const location = window.location.pathname;
  const routePattern = new RegExp(excludeRoutePattern);
  if (excludeRoutePattern && routePattern.test(location)) return null;

  const isMobile = !matchMedia(BREAKPOINTS.MD);

  // Banner logic
  const onClose = () => {
    setCookie(COOKIE_BANNER_EXITED, '1', 180, ID_DOMAIN);
    setShowBanner(false);
    trackExitCookieBanner();
  };

  const trackMoreInfo = () => {
    trackMoreInfoCookieBanner();
  };

  const privacyLink = getPrivacyPolicyLink(getLocale());

  return (
    <div className={styles.container}>
      <div className={styles.bannerGrid}>
        <div
          id="cookie-consent-banner"
          className={cx(styles.cookieBanner, variant === 0 ? styles.wide : styles.narrow)}
          style={{ bottom: marginBottom }}
        >
          <div className={styles.content}>
            <p>
              {language('cookieBanner.text')}
              <a
                target="_blank"
                rel="noreferrer"
                className={styles.cookieBannerLink}
                href={privacyLink.url}
                onClick={trackMoreInfo}
              >
                {language('cookieBanner.linkText')}
              </a>
            </p>
          </div>

          <span className={styles.closeButton} role="button" tabIndex={0} onKeyDown={onClose} onClick={onClose}>
            <Icon
              path={ICON_CLOSE}
              size={isMobile ? 20 : Icon.sizes.sm}
              alt="close-cookie-banner"
              cyData="close-cookie-banner"
            />
          </span>
        </div>
      </div>
    </div>
  );
};

CookieBanner.propTypes = {
  marginBottom: PropTypes.number,
  excludeRoutePattern: PropTypes.string,
};

CookieBanner.defaultProps = {
  marginBottom: 0,
  excludeRoutePattern: null,
};

CookieBanner.displayName = 'atoms/CookieBanner';

export default CookieBanner;
