export const apiStatus = {
  request: 'REQUEST',
  success: 'SUCCESS',
  failure: 'FAILURE',
  networkerror: 'NETWORKERROR',
  notFound: 'NOTFOUND',
};

export const apiResponseRecaptchaNeeded = 'CoreEventCounterError';

export const invalidApiStatus = [apiStatus.failure, apiStatus.networkerror];
