/**
 * Created by michaels on 08.07.16.
 */
import {
  CHECK_ORDER_VALIDITY,
  CHECK_STOCK_AVAILABILITY,
  GET_DATATRANS_AUTHORISATION, INIT_DATATRANS_TRANSACTION,
  LOAD_ARCHIVED_ORDER,
  LOAD_ARCHIVED_ORDERS,
  LOAD_MYORDERS,
  LOAD_ORDER,
  PLACE_ORDER,
  RESET_ORDER_API_STATUS,
  START_PLACE_ORDER,
} from 'constants/ActionTypes/ActionTypes';
import OrderAPI from 'api/OrderAPI/OrderAPI';

/**
 * Action creator which places an order
 */
export const placeOrder = (datatransResponse, productEans = []) => {
  const promise = OrderAPI.placeOrder(datatransResponse);
  return {
    type: PLACE_ORDER,
    promise,
    payload: {
      products: productEans,
    },
  };
};

/**
 * Action creator which sends Datatrans authorisation info request
 */
export const getDatatransAuthorisation = (refno, shouldGetAlias) => ({
  type: GET_DATATRANS_AUTHORISATION,
  payload: {},
  promise: OrderAPI.getDatatransAuthorisation(refno, shouldGetAlias),
});

/**
 * Alternative action creator for Datatrans used for the new Datatrans API
 */
export const initDatatransTransaction = () => ({
  type: INIT_DATATRANS_TRANSACTION,
  payload: {},
  promise: OrderAPI.initiateDatatransTransaction(),
});

export const loadMyOrders = (page) => ({
  type: LOAD_MYORDERS,
  payload: {},
  promise: OrderAPI.getAllOrders(page),
});

export const loadArchivedOrders = (page) => ({
  type: LOAD_ARCHIVED_ORDERS,
  payload: {},
  promise: OrderAPI.getAllArchivedOrders(page),
});

export const loadOrder = (guid) => ({
  loadArchivedOrder,
  type: LOAD_ORDER,
  payload: {},
  promise: OrderAPI.getSpecificOrder(guid),
});

export const loadArchivedOrder = (guid) => ({
  type: LOAD_ARCHIVED_ORDER,
  payload: {},
  promise: OrderAPI.getSpecificArchivedOrder(guid),
});

export const resetOrderApiStatus = () => ({
  type: RESET_ORDER_API_STATUS,
  payload: {},
});

export const startPlaceOrder = () => ({
  type: START_PLACE_ORDER,
  payload: {},
});

export const checkStockAvailability = () => ({
  type: CHECK_STOCK_AVAILABILITY,
  payload: {},
  promise: OrderAPI.checkStockAvailability(),
});

export const checkOrderValidity = () => ({
  type: CHECK_ORDER_VALIDITY,
  payload: {},
  promise: OrderAPI.checkOrderValidity(),
});

const actions = {
  placeOrder,
  getDatatransAuthorisation,
  initDatatransTransaction,
  loadMyOrders,
  loadArchivedOrders,
  loadOrder,
  loadArchivedOrder,
  resetOrderApiStatus,
  startPlaceOrder,
  checkStockAvailability,
  checkOrderValidity,
};

export default actions;
