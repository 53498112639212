import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import MarkdownBox from 'components/molecules/MarkdownBox/MarkdownBox';
import { getEnv } from 'config/config';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';

import styles from './ProductBlogPost.scss';

const ProductBlogPost = ({ link, image, imageAltText, title, text }) => (
  <div className={styles.Wrapper}>
    <div className={styles.Image}>
      <Link to={mapPathToLocalizedUrl(undefined, [link.href])} target={link.external ? '_blank' : '_self'}>
        <img src={`${getEnv('api')}${image}`} alt={imageAltText} />
      </Link>
    </div>
    <div>
      <span className={styles.Title}>{title}</span>
      <span className={styles.Description}>
        <MarkdownBox markdown={text} />
      </span>
      <span className={styles.Description}>
        <Link to={mapPathToLocalizedUrl(undefined, [link.href])} target={link.external ? '_blank' : '_self'}>
          {link.title}
        </Link>
      </span>
    </div>
  </div>
);

ProductBlogPost.propTypes = {
  link: PropTypes.shape({
    href: PropTypes.string.isRequired,
    title: PropTypes.string,
    external: PropTypes.bool,
  }),
  image: PropTypes.string.isRequired,
  imageAltText: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
};

ProductBlogPost.defaultProps = {
  link: {
    href: '',
    title: '',
  },
  image: '',
  imageAltText: '',
  title: '',
  text: '',
};

ProductBlogPost.displayName = 'molecules/ProductBlogPost';

export default ProductBlogPost;
