import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { ProductViewTypes } from 'constants/ProductViewTypes/ProductViewTypes';
import ProductContainer from 'containers/Product/Product';
import AddedToCartModal from 'containers/AddedToCartModal/AddedToCartModal';
import { Col, Row } from 'components/atoms/Grid/Grid';
import ProductListItem from 'components/molecules/ProductListItem/ProductListItem';
import TeaserProduct from 'components/molecules/TeaserProduct/TeaserProduct';
import { QualtricsCreativeContainer } from '@interdiscount/component-library-interdiscount';
import BrandTeaser from 'components/molecules/BrandTeaser/BrandTeaser';
import styles from './ProductList.scss';
import { ET } from 'tracking/trackingHelper';

const insertAdditionalItems = (products, brand) => {
  const clonedProducts = [...products];

  // Insert Container at 7th Position. This will result in 3th row on Desktop and 4th row on Tablet.
  // This will not cost that much then checking the current Breakpoint wihtin rendering
  clonedProducts.splice(6, 0, 'qInterceptProductList');
  brand && clonedProducts.splice(3, 0, 'brandModule');

  return clonedProducts;
};

const ProductList = ({ products, viewType, trackingContext, brand }) => (
  <Row>
    {insertAdditionalItems(products, brand).map((productCode, key) => {
      if (productCode === 'qInterceptProductList') {
        return (
          <Col className={styles.qualtrics} xs={12} key={key}>
            <QualtricsCreativeContainer qId={productCode} />
          </Col>
        );
      }
      if (productCode === 'brandModule') {
        return (
          <Col
            xs={12}
            key={key}
            className={cx(styles.brand, {
              [styles.brandGrid]: viewType === ProductViewTypes.GRID,
              [styles.brandList]: viewType === ProductViewTypes.LIST,
            })}
          >
            {brand && brand.cmsSearchComponent && (
              <BrandTeaser
                title={brand.cmsSearchComponent.title}
                text={brand.cmsSearchComponent.text}
                logoSrc={brand.cmsSearchComponent.image}
                logoAlt={brand.cmsSearchComponent.imageAltText}
                viewType={viewType}
              />
            )}
          </Col>
        );
      }
      if (viewType === ProductViewTypes.LIST) {
        return (
          <Col key={key} className={styles.listitem} cyData="productListItem">
            <ProductContainer
              productCode={productCode}
              productViewType={viewType}
              productAttributes={{ elementTitle: ET.LIST_ROW }}
            >
              <ProductListItem />
            </ProductContainer>
          </Col>
        );
      }
      return (
        <Col xs={12} sm={6} md={4} key={key} className={styles.column} cyData="productListItem">
          <ProductContainer
            productCode={productCode}
            productViewType={viewType}
            productAttributes={{ elementTitle: ET.LIST_TILE }}
          >
            <TeaserProduct trackingContext={trackingContext} />
          </ProductContainer>
        </Col>
      );
    })}
    <AddedToCartModal />
  </Row>
);

ProductList.propTypes = {
  products: PropTypes.array.isRequired,
  viewType: PropTypes.oneOf(Object.values(ProductViewTypes)),
  trackingContext: PropTypes.string,
  brand: PropTypes.object,
};

ProductList.defaultProps = {
  viewType: ProductViewTypes.GRID,
  trackingContext: '',
  brand: {},
};

ProductList.displayName = 'organisms/ProductList';

export default ProductList;
