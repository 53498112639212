import React from 'react';
import PropTypes from 'prop-types';
import SpeedCurve from 'containers/SpeedCurve/SpeedCurve';
import DynamicProvider from 'contexts/DynamicProvider/DynamicProvider';

const Providers = ({ children }) => {
  return (
    <SpeedCurve>
      <DynamicProvider>{children}</DynamicProvider>
    </SpeedCurve>
  );
};

Providers.displayName = 'layouts/Providers';

Providers.propTypes = { children: PropTypes.node.isRequired };

export default Providers;
