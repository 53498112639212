import React from 'react';
import PropTypes from 'prop-types';
import OpeningHoursSpecialDay from './OpeningHoursSpecialDay';
import language from 'constants/language/language';
import styles from './OpeningHoursSpecialDay.scss';

const OpeningHoursSpecial = ({ specialDayOpeningList }) => (
  <div className={styles.table}>
    <caption className={styles.caption}>{language('pickupStore.openingHoursSpecial')}</caption>
    <div>
      {specialDayOpeningList.map(
        (day, index) =>
          !!day.date &&
          typeof day.openingDayTimeRangeDataList !== 'undefined' && (
            <OpeningHoursSpecialDay
              key={`opening-hours-special-${index}`}
              name={day.name}
              date={day.date}
              closed={day.closed}
              openingDayTimeRangeDataList={day.openingDayTimeRangeDataList}
            />
          )
      )}
    </div>
  </div>
);

OpeningHoursSpecial.displayName = 'atoms/OpeningHoursSpecial';
OpeningHoursSpecial.propTypes = {
  specialDayOpeningList: PropTypes.array.isRequired,
};

export default OpeningHoursSpecial;
