export const HELPCENTER_DOMAIN = `https://helpcenter.interdiscount.ch`;
export const HELPCENTER_LINK = HELPCENTER_DOMAIN + `/s/contactsupport?language=`;
export const FOOTER_HELPCENTER_LINK = `https://helpcenter.interdiscount.ch/s/?language=`;

export const PRIVACY_POLICY = [
  {
    locale: 'de',
    url: `https://www.coop.ch/de/unternehmen/datenschutz.html?source=interdiscount`,
    text: 'Datenschutz',
  },
  {
    locale: 'fr',
    url: `https://www.coop.ch/fr/entreprise/protection-des-donnees.html?source=interdiscount`,
    text: 'Protection des données',
  },
  {
    locale: 'it',
    url: `https://www.coop.ch/it/azienda/protezione-dei-dati.html?source=interdiscount`,
    text: 'Protezione dei dati',
  },
];
