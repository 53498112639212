import React from 'react';
import PropTypes from 'prop-types';
import language from 'constants/language/language';
import styles from './NumberResults.scss';
import cx from 'classnames';

const NumberResults = ({ currentPage, pageSize = 24, totalNumberOfResults = 0, hasCmsContent }) => {
  if (totalNumberOfResults === 0) return null;

  const start = (currentPage - 1) * pageSize + 1;
  const end = Math.min(totalNumberOfResults, currentPage * pageSize);

  return (
    <div className={cx(styles.container, { [styles.noCmsContent]: !hasCmsContent })}>
      <strong>
        {' '}
        {start}-{end}{' '}
      </strong>{' '}
      {language('productsOverview.numberResults.of')}
      <strong> {totalNumberOfResults.toLocaleString('de-CH')}</strong>{' '}
      {language('productsOverview.numberResults.products')}
    </div>
  );
};

NumberResults.displayName = 'atom/NumberResults';
NumberResults.propTypes = {
  currentPage: PropTypes.number.isRequired, // first page === 1
  pageSize: PropTypes.number,
  totalNumberOfResults: PropTypes.number,
  hasCmsContent: PropTypes.bool,
};

export default NumberResults;
