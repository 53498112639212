import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import noScroll from 'no-scroll';
import language from 'constants/language/language';
import { noop } from 'constants/noop/noop';
import { Container } from 'components/atoms/Grid/Grid';
import styles from './SelectModal.scss';

const SelectModal = ({ options = [], optionsActiveIndex = 0, closeSelect = noop, optionsClickHandler = noop }) => {
  const [willClose, setWillClose] = useState(false);

  useEffect(() => {
    noScroll.on();
    return () => {
      noScroll.off();
    };
  });

  // Buffer for CSS closing animation
  const optionsClickHandlerBuffer = (index) => {
    setWillClose(true);
    setTimeout(() => {
      optionsClickHandler(index);
    }, 210);
  };
  const closeSelectBuffer = () => {
    setWillClose(true);
    setTimeout(() => {
      closeSelect();
    }, 210);
  };

  return (
    <>
      <div className={cx(styles.overlay, { [styles.overlayClosing]: willClose })} onClick={closeSelectBuffer} />
      <Container className={styles.optionsContainer}>
        <div className={cx(styles.options, { [styles.optionsClosing]: willClose })}>
          {options.map((option, index) => (
            <div
              key={index}
              className={cx(styles.option, { [styles.optionActive]: optionsActiveIndex === index })}
              onClick={() => optionsClickHandlerBuffer(index)}
            >
              {option}
            </div>
          ))}
          <div className={cx(styles.option, styles.optionCancel)} onClick={closeSelectBuffer}>
            {language('select.cancel')}
          </div>
        </div>
      </Container>
    </>
  );
};

SelectModal.displayName = 'atoms/SelectModal';
SelectModal.propTypes = {
  options: PropTypes.array,
  optionsActiveIndex: PropTypes.number,
  closeSelect: PropTypes.func,
  optionsClickHandler: PropTypes.func,
};

export default SelectModal;
