import React, { Component, cloneElement } from 'react';
import PropTypes from 'prop-types';
import resolveErrorMessage from 'constants/resolveErrorMessage/resolveErrorMessage';
import language from 'constants/language/language';
import { ValidationTypes } from 'constants/validation/validation';
import LabelInput from './LabelInput';
import ErrorMessageInput from './ErrorMessageInput';
import bootstrap from 'scss/component.scss';

export default class FieldSetInput extends Component {
  static displayName = 'molecules/FieldSetInput';

  static propTypes = {
    name: PropTypes.string,
    required: PropTypes.bool,
    labelDescriptionVisible: PropTypes.bool,
    requiredAsteriskVisible: PropTypes.bool,
    optionalTextVisible: PropTypes.bool,
    forceValidation: PropTypes.bool,
    validationResult: PropTypes.oneOf(Object.values(ValidationTypes)),
    children: PropTypes.element.isRequired,
    value: PropTypes.string,
    pending: PropTypes.bool,
    disabled: PropTypes.bool,
    blurred: PropTypes.bool,
    showError: PropTypes.bool,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    onKeyPress: PropTypes.func,
    handleFocus: PropTypes.func,
    disableMessage: PropTypes.bool,
    id: PropTypes.string,
    showTooltip: PropTypes.bool,
    options: PropTypes.object,
    label: PropTypes.string,
    customErrorMessage: PropTypes.string,
    placeholder: PropTypes.string,
    cyErrorMessage: PropTypes.string,
  };

  static defaultProps = {
    name: '',
    required: false,
    requiredAsteriskVisible: true,
    optionalTextVisible: false,
    labelDescriptionVisible: true,
    forceValidation: false,
    validationResult: ValidationTypes.Initial,
    pending: false,
    disabled: false,
    blurred: false,
    showError: true,
    handleChange: () => {},
    handleBlur: () => {},
    onKeyPress: () => {},
    handleFocus: () => {},
    disableMessage: false,
    id: '',
    showTooltip: true,
    label: '',
    customErrorMessage: '',
    placeholder: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      focus: false,
    };
  }

  onBlur = () => {
    this.props.handleBlur();
    this.setState({ focus: false });
  };

  onFocus = () => {
    this.setState({ focus: true });
    this.props.handleFocus();
  };

  getLabel = (name) => this.props.label || language(`form.fields.${name}.label`, '');

  getTooltip = (name) => (this.props.showTooltip ? language(`form.fields.${name}.tooltip`, '') : '');

  getOptions = (name) => this.props.options || language(`form.fields.${name}.options`, {});

  getPlaceholder = (name) => this.props.placeholder || language(`form.fields.${name}.placeholder`, '');

  getLabelDescription = (name) => language(`form.fields.${name}.labelDescription`, '');

  isInputValid = () => {
    const validPassword = [ValidationTypes.PasswordGood, ValidationTypes.PasswordVeryGood];

    if (this.props.name === 'password') {
      return validPassword.indexOf(this.props.validationResult) === -1;
    }

    return this.props.validationResult !== true;
  };

  render() {
    const {
      name,
      required,
      labelDescriptionVisible,
      requiredAsteriskVisible,
      optionalTextVisible,
      forceValidation,
      validationResult,
      children,
      value,
      pending,
      disabled,
      blurred,
      handleChange,
      id,
      customErrorMessage,
      onKeyPress,
      cyErrorMessage,
    } = this.props;
    const isInvalid = this.isInputValid();
    let showError = forceValidation
      ? isInvalid && !this.state.focus
      : isInvalid && !this.state.focus && blurred && this.props.showError;
    showError = !!showError;
    const label = this.getLabel(name);
    const tooltip = this.getTooltip(name);
    const placeholder = this.getPlaceholder(name);
    const anchor = id ? `${id}-formControl-${name}` : `formControl-${name}`;
    const formId = `formControl-${name}`;
    const formIdInput = `formControl-${name}-input`;
    const labelDescription = this.getLabelDescription(name);
    return (
      <div id={anchor} className={bootstrap.formGroup}>
        <LabelInput
          required={required}
          tooltip={tooltip}
          requiredAsteriskVisible={requiredAsteriskVisible}
          optionalTextVisible={optionalTextVisible}
          showError={showError}
          name={name}
          id={formId}
          disabled={disabled}
          description={labelDescriptionVisible && labelDescription}
        >
          {label}
        </LabelInput>
        {cloneElement(children, {
          id: formIdInput,
          name,
          value,
          placeholder,
          pending,
          disabled,
          showError,
          validationResult,
          handleChange,
          options: this.getOptions(name),
          onBlur: this.onBlur,
          focus: this.state.focus,
          onFocus: this.onFocus,
          onKeyPress,
        })}
        <ErrorMessageInput disableMessage={this.props.disableMessage} showError={showError} cyData={cyErrorMessage}>
          {customErrorMessage || resolveErrorMessage(validationResult, name)}
        </ErrorMessageInput>
      </div>
    );
  }
}
