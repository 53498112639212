import React from 'react';
import styles from './FacetFilterType.scss';
import Icon, { ICON_CLOSE } from 'components/atoms/Icon/Icon';
import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';
import language from 'constants/language/language';
import PropTypes from 'prop-types';
import FacetTypeSelector from 'components/atoms/FacetFilterType/FacetTypeSelector';
import { facetHasFilters, FacetPropType } from 'constants/facetsHelper/facetsHelper';

const FacetFilterType = ({
  facet,
  prefetchedFacet,
  onClose,
  totalNumberOfResults,
  submitFilters,
  handleSliderChange,
  prefetchFilter,
  breadcrumb,
  isCmsPage,
}) => {
  const hasFiltersApplied = facetHasFilters(prefetchedFacet) || facetHasFilters(facet);

  const onSubmit = () => {
    submitFilters();
    onClose(false);
  };

  const renderButton = () => {
    return hasFiltersApplied && totalNumberOfResults > 0 ? (
      <ButtonLink color={ButtonLink.colors.red} stretched onClick={onSubmit}>
        {totalNumberOfResults.toLocaleString('de-CH')} {language('filter.products')}
      </ButtonLink>
    ) : (
      <ButtonLink variety={ButtonLink.varieties.stroked} stretched onClick={onClose}>
        {language('filter.close')}
      </ButtonLink>
    );
  };

  return (
    <>
      <div className={styles.facetFilterPopup}>
        <div className={styles.facetFilterContent}>
          <button className={styles.facetFilterClose} onClick={onClose}>
            <span>{facet.name}</span>
            <Icon path={ICON_CLOSE} />
          </button>
          <FacetTypeSelector
            facet={facet}
            prefetchedFacet={prefetchedFacet}
            handleSliderChange={handleSliderChange}
            prefetchFilter={prefetchFilter}
            breadcrumb={breadcrumb}
            isCmsPage={isCmsPage}
          />
        </div>
        {renderButton()}
      </div>
    </>
  );
};

FacetFilterType.propTypes = {
  facet: FacetPropType.isRequired,
  prefetchedFacet: FacetPropType.isRequired,
  onClose: PropTypes.func.isRequired,
  totalNumberOfResults: PropTypes.number,
  submitFilters: PropTypes.func,
  handleSliderChange: PropTypes.func,
  prefetchFilter: PropTypes.func,
  breadcrumb: PropTypes.array,
  isCmsPage: PropTypes.bool,
};

FacetFilterType.displayName = 'atoms/FacetFilterType';
export default FacetFilterType;
