import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './Headline.scss';

export const tags = {
  H1: 'h1',
  H2: 'h2',
  H3: 'h3',
  H4: 'h4',
  H5: 'h5',
  H6: 'h6',
  P: 'p',
  DIV: 'div',
  SPAN: 'span',
  STRONG: 'strong',
};

export const margins = {
  NONE: 'mb-0',
  MB_025: 'mb-025',
  NORMAL: 'mb-5',
  MB_10: 'mb-10',
  MB_15: 'mb-15',
  MB_20: 'mb-20',
  MB_25: 'mb-25',
  MB_30: 'mb-30',
  MB_35: 'mb-35',
  MB_40: 'mb-40',
  MB_45: 'mb-45',
  MB_50: 'mb-50',
  MR_025: 'mr-025',
  ML_025: 'ml-025',
};

export const skins = {
  pagetitle: 'pagetitle',
  productname: 'productname',
  brandname: 'brandname',
  header: 'header',
};

export const alignments = {
  LEFT: 'left',
  RIGHT: 'right',
  CENTER: 'center',
};

const Headline = ({
  skin = '',
  margin = margins.NORMAL,
  tag = tags.H2,
  title = '',
  alignment = alignments.LEFT,
  cyData,
  children,
  id = '',
}) => {
  const s = cx(styles.heading, styles[margin], styles[skin], styles[alignment]);

  const Tag = tag;
  return (
    <Tag className={s} title={title || null} data-cy={cyData} id={id}>
      {children}
    </Tag>
  );
};

Headline.propTypes = {
  children: PropTypes.node.isRequired,
  tag: PropTypes.oneOf(Object.values(tags)),
  margin: PropTypes.oneOf(Object.values(margins)),
  title: PropTypes.string, // title tag, for when the text is too long
  skin: PropTypes.oneOf(Object.values(skins)),
  cyData: PropTypes.string,
  alignment: PropTypes.string,
  id: PropTypes.string,
};

Headline.tags = tags;
Headline.margins = margins;
Headline.skins = skins;
Headline.alignments = alignments;

Headline.displayName = 'atoms/Headline';

export default Headline;
