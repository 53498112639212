import React, { useState } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import { Modal } from '@interdiscount/component-library-interdiscount';
import MobileDrilldown from 'components/molecules/MobileDrilldown/MobileDrilldown';
import Icon, { ICON_CLOSE } from 'components/atoms/Icon/Icon';

import language from 'constants/language/language';

import styles from './ModalMenu.scss';
import { trackMobileNavigationClose } from 'tracking/tracking';

export const ModalMenu = ({
  triggerMenu,
  isOpen,
  categories,
  cmsPages,
  variant,
  categoryCode,
  categoryTree,
  route,
  isDesktop,
  isHome,
}) => {
  if (!isDesktop) {
    return (
      <Modal size="LG" isOpen={isOpen} isClosable={false}>
        <ModalContent
          triggerMenu={triggerMenu}
          categories={categories}
          variant={variant}
          cmsPages={cmsPages}
          categoryCode={isHome ? null : categoryCode}
          categoryTree={categoryTree}
          route={route}
        />
      </Modal>
    );
  } else {
    return null;
  }
};

ModalMenu.displayName = 'organisms/ModalMenu';

ModalMenu.propTypes = {
  triggerMenu: PropTypes.func,
  isOpen: PropTypes.bool,
  categories: PropTypes.object,
  cmsPages: PropTypes.object,
  variant: PropTypes.string,
  setMainMenuIsClose: PropTypes.func,
  categoryCode: PropTypes.string,
  categoryTree: PropTypes.array,
  route: PropTypes.string,
  isDesktop: PropTypes.bool,
  isHome: PropTypes.bool,
};

ModalMenu.defaultProps = {
  triggerMenu: () => {},
  isOpen: false,
  categories: {},
  cmsPages: {},
  variant: '0',
};

export const ModalContent = ({ triggerMenu, categories, variant, cmsPages, categoryTree, categoryCode, route }) => {
  const [isResetToStart, setIsResetToStart] = useState(false);

  const handleClick = () => {
    triggerMenu();
    trackMobileNavigationClose();
  };

  return (
    <div className={styles.container}>
      <div className={cx(styles.iconCloseContainer)}>
        <button className={styles.headerText} onClick={() => setIsResetToStart(!isResetToStart)}>
          {language('header.menu')}
        </button>
        <button className={styles.closeButton} onClick={handleClick}>
          {variant === '0' && <span>{language('header.closeMenu')}</span>}
          <Icon path={ICON_CLOSE} />
        </button>
      </div>
      <MobileDrilldown
        categories={categories}
        cmsPages={cmsPages}
        categoryTree={categoryTree}
        categoryCode={categoryCode}
        route={route}
        isResetToStart={isResetToStart}
      />
    </div>
  );
};

ModalContent.propTypes = {
  triggerMenu: PropTypes.func,
  categories: PropTypes.object,
  cmsPages: PropTypes.object,
  variant: PropTypes.string,
  categoryTree: PropTypes.array,
  categoryCode: PropTypes.string,
  route: PropTypes.string,
};
ModalContent.defaultProps = {
  triggerMenu: () => {},
  categories: {},
  cmsPages: {},
  variant: '0',
};
