import React from 'react';
import PropTypes from 'prop-types';
import SvgContainer from 'components/atoms/SvgContainer/SvgContainer';

const renderPath = [
  <circle key={1} cx="199.8" cy="200.5" r="200" fillRule="evenodd" clipRule="evenodd" fill="#e20713" />,
  <path
    key={2}
    // eslint-disable-next-line max-len
    d="M310.6 197.7c4.1-4.7 3.7-11.9-.9-16L208.1 89.9c-4.6-4.2-12-4.1-16.5.2l-102 96.5c-4.5 4.3-4.7 11.4-.5 16l2.6 2.8c4.2 4.6 11.1 5.1 15.3 1.2l7.6-7v103.2c0 6.3 5 11.5 11.1 11.5h39.7c6.1 0 11.1-5.1 11.1-11.5v-72.2h50.7v72.2c-.1 6.3 4.3 11.5 10.4 11.5h42.1c6.1 0 11.1-5.1 11.1-11.5V201s2.1 1.9 4.7 4.3 8 .5 12.2-4.2l2.9-3.4z"
    fill="#fff"
  />,
];

const vbHeight = 400;
const vbWidth = 424;

export const skins = {
  WHITE: 'WHITE',
  BLACK: 'BLACK',
  RED: 'RED',
};

const IconMyStore = ({ height }) => (
  <SvgContainer height={height} vbHeight={vbHeight} vbWidth={vbWidth}>
    {renderPath}
  </SvgContainer>
);

IconMyStore.displayName = 'atoms/IconMyStore';

IconMyStore.propTypes = {
  height: PropTypes.number,
  skin: PropTypes.oneOf(Object.keys(skins)),
};

IconMyStore.defaultProps = {
  height: 32,
  skin: skins.BLACK,
};

export default IconMyStore;
