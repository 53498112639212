import React from 'react';
import PropTypes from 'prop-types';
import Markdown from 'components/atoms/Markdown/Markdown';
import styles from './MarkdownBox.scss';

const MarkdownBox = ({ markdown }) => (
  <div className={styles.MarkdownBox}>
    <Markdown>{markdown}</Markdown>
  </div>
);

MarkdownBox.propTypes = {
  markdown: PropTypes.string,
};

export default MarkdownBox;
