import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import Card from 'components/molecules/Card/Card';
import Icon, { ICON_KEYBOARD_ARROW_LEFT, ICON_KEYBOARD_ARROW_RIGHT } from 'components/atoms/Icon/Icon';

import bootstrap from 'scss/component.scss';
import styles from './CardWallMini.scss';

// Install swiper modules
SwiperCore.use([Navigation, Pagination]);

const SKINS = Card.SKINS;

const CardWallMini = ({ cards, skin }) => {
  const [slidesPerView, setSlidesPerView] = useState(1);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const onResize = (swiper) => {
    setSlidesPerView(swiper.params.slidesPerView);
  };

  const onSwiper = (swiper) => {
    setSlidesPerView(swiper.params.slidesPerView);
  };

  const onSlideChange = (swiper) => {
    setIsBeginning(swiper.isBeginning);
    setIsEnd(swiper.isEnd);
  };

  const swiperConfig = {
    initialSlide: 0,
    slidesPerView: 1,
    className: cx(styles.container, bootstrap.clearfix, bootstrap.hiddenPrint),
    breakpoints: {
      544: {
        slidesPerView: 2,
      },
      768: {
        slidesPerView: 3,
      },
    },
    pagination: {
      el: `.${styles.pagination}`,
      type: 'bullets',
      clickable: true,
      bulletClass: styles.bullet,
      bulletActiveClass: styles.bulletActive,
    },
    navigation: {
      nextEl: `.${styles.nav}.${styles.navNext}`,
      prevEl: `.${styles.nav}.${styles.navPrev}`,
      disabledClass: styles.navDisabled,
    },
    onSwiper,
    onResize,
    onSlideChange,
  };

  return (
    <>
      <Swiper {...swiperConfig}>
        {cards.map((card, index) => (
          <SwiperSlide key={index} className={cx(styles.slide, bootstrap.colXs12, bootstrap.colSm6, bootstrap.colMd4)}>
            <Card card={card} skin={skin} trackingAttributes={{ index }} />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className={cx(styles.controls, { [styles.controlsHidden]: slidesPerView >= cards.length })}>
        <span className={cx(styles.nav, styles.navPrev, { [styles.isBeginning]: isBeginning })}>
          <Icon path={ICON_KEYBOARD_ARROW_LEFT} />
        </span>
        <div className={styles.pagination} />
        <span className={cx(styles.nav, styles.navNext, { [styles.isEnd]: isEnd })}>
          <Icon path={ICON_KEYBOARD_ARROW_RIGHT} />
        </span>
      </div>
    </>
  );
};

CardWallMini.SKINS = SKINS;

CardWallMini.displayName = 'molecules/CardWallMini';
CardWallMini.propTypes = {
  cards: PropTypes.array,
  skin: PropTypes.oneOf(Object.values(SKINS)),
};
CardWallMini.defaultProps = {
  cards: [],
  skin: SKINS.MINI,
};

export default CardWallMini;
