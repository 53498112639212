import React from 'react';
import PropTypes from 'prop-types';
import FlashMessage from 'components/atoms/FlashMessage/FlashMessage';
import { Container } from 'components/atoms/Grid/Grid';

import styles from './FlyInList.scss';

const FlyInList = ({ messages = [], removeFlashMessage = () => {}, topMargin = 0, cyData }) => {
  return (
    <div className={styles.container} style={{ top: `${topMargin}px` }}>
      <Container className={styles.innerContainer}>
        <div className={styles.list}>
          {messages.map((msg) => (
            <FlashMessage
              key={msg.id}
              content={msg.content}
              id={msg.id}
              removeFlashMessage={removeFlashMessage}
              type={msg.type}
              isFlyIn
              cyData={cyData}
            />
          ))}
        </div>
      </Container>
    </div>
  );
};

FlyInList.displayName = 'molecules/FlyInList';
FlyInList.propTypes = {
  messages: PropTypes.array,
  removeFlashMessage: PropTypes.func,
  topMargin: PropTypes.number,
  cyData: PropTypes.string,
};

export default FlyInList;
