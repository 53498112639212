import { Component, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

export const mapStateToProps = (state) => ({
  pages: state?.cms?.navigation?.pages || {},
  actualPath: state?.routing?.locationBeforeTransitions?.pathname || '',
  categories: state.categories,
});

export class CmsNavigationProvider extends Component {
  static displayName = 'containers/CmsNavigationProvider';

  static propTypes = {
    pages: PropTypes.object,
    children: PropTypes.node.isRequired,
    actualPath: PropTypes.string,
    categories: PropTypes.object,
  };

  static defaultProps = {
    pages: {},
    categories: {},
    actualPath: '',
  };

  render() {
    const { pages, actualPath, children, categories } = this.props;

    return cloneElement(children, {
      pages,
      actualPath,
      categories,
    });
  }
}

export default connect(mapStateToProps)(CmsNavigationProvider);
