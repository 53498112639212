import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'components/atoms/Grid/Grid';
import Icon, { ICON_KEYBOARD_ARROW_DOWN, ICON_KEYBOARD_ARROW_UP } from 'components/atoms/Icon/Icon';
import bootstrap from 'scss/component.scss';
import styles from './ComparisonListRow.scss';

const ComparisonListRow = ({ classification = {}, productNames = [] }) => {
  const [isVisible, setIsVisible] = useState(true);
  return (
    <div className={styles.classificationRow}>
      <div className={styles.title} onClick={() => setIsVisible(!isVisible)}>
        <label className={styles.arrowIcon}>
          {isVisible ? <Icon path={ICON_KEYBOARD_ARROW_UP} /> : <Icon path={ICON_KEYBOARD_ARROW_DOWN} />}
        </label>
        {classification.name}
      </div>
      {isVisible &&
        classification.features?.map((feature, featureIndex) => (
          <Row className={styles.featureRow} key={featureIndex}>
            <Col lg={3} className={styles.featureName}>
              {feature.name}
            </Col>
            <Col className={bootstrap.hiddenLgUp}>
              {feature.values?.map((value, valueIndex) => (
                <Row className={styles.productRow} key={valueIndex}>
                  <Col xxs={12} sm={6} className={styles.productName}>
                    {!!productNames.length && productNames[valueIndex]}
                  </Col>
                  <Col xxs={11} offsetXxs={1} sm={6} offsetSm={0} className={styles.featureCell} key={valueIndex}>
                    {value}
                  </Col>
                </Row>
              ))}
            </Col>
            <Col lg={9} className={bootstrap.hiddenMdDown}>
              <Row>
                {feature.values?.map((value, valueIndex) => (
                  <Col lg={4} className={styles.featureCell} key={valueIndex}>
                    {value}
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        ))}
    </div>
  );
};

ComparisonListRow.displayName = 'molecules/ComparisonListRow';
ComparisonListRow.propTypes = {
  classification: PropTypes.object,
  productNames: PropTypes.array,
};

export default ComparisonListRow;
