import React from 'react';
import language from 'constants/language/language';
import Icon, {ICON_BASKET} from 'components/atoms/Icon/Icon';
import Pagetitle from 'components/atoms/Pagetitle/Pagetitle';
import Breadcrumb, {types} from 'components/organisms/Breadcrumb/Breadcrumb';
import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';
import {mapPathToLocalizedUrl} from 'constants/urlMapping/urlMapping';
import styles from './CartEmpty.scss';
import {BREAKPOINTS} from 'constants/breakpoints/breakpoints';
import {possibleStrokes} from 'constants/iconStrokes/iconStrokes';
import IconContainer from 'containers/IconContainer/IconContainer';
import TimeSensitiveInfoBox from 'components/atoms/TimeSensitiveInfoBox/TimeSensitiveInfoBox';
import PropTypes from 'prop-types';
import {useFeatureToggle} from "hooks/index";

const breakpointConfigurations = {
  [BREAKPOINTS.XXS]: {height: 150, stroke: possibleStrokes.strokeWidth020},
  [BREAKPOINTS.SM]: {height: 300, stroke: possibleStrokes.strokeWidth020},
};

const CartEmpty = ({cart, noEntries}) => {
  const nextHomepageEnabled = useFeatureToggle('nextHomepageEnabled') === "true" ?? false;

  return (
    <div className={styles.container}>
      <Breadcrumb type={types.invisible}/>
      <Pagetitle centered beforeBox={false}>
        {language('cart.title')}
      </Pagetitle>
      {cart && cart.cartUpdated && (
        <div className={styles.timeSensitiveInfoBox}>
          <TimeSensitiveInfoBox isMainInfo removedEntries={cart.removedEntries}/>
        </div>
      )}
      {noEntries && (
        <div className={styles.timeSensitiveInfoBox}>
          <TimeSensitiveInfoBox isMainInfo stockUpdated/>
        </div>
      )}
      <div className={styles.textContainer}>
        <div className={styles.text}>{language('cart.isEmpty')}</div>
        <div>{language('cart.isEmptyDeliveryText')}</div>
        <div>{language('cart.isEmptyAdditionalDeliveryText')}</div>
      </div>
      <ButtonLink variety={ButtonLink.varieties.stroked} size={ButtonLink.sizes.md} href={mapPathToLocalizedUrl()}
                  outsideRouter={nextHomepageEnabled}>
        {language('cart.continue')}
      </ButtonLink>
      <div className={styles.icon}>
        <IconContainer breakpointConfigurations={breakpointConfigurations}>
          <Icon path={ICON_BASKET}/>
        </IconContainer>
      </div>
    </div>
  )
};

CartEmpty.propTypes = {
  cart: PropTypes.object,
  noEntries: PropTypes.bool,
};

CartEmpty.defaultProps = {
  cart: null,
  noEntries: false,
};

CartEmpty.displayName = 'molecules/CartEmpty';

export default CartEmpty;
