// @flow
import React from 'react';
import { connect } from 'react-redux';

import FlashMessageList from 'components/molecules/FlashMessageList/FlashMessageList';
import { locations, getFlashMessage } from 'constants/flashMessages/flashMessages';

type IProps = {
  closeFlash: () => void,
  onLoad: () => void,
  flashMessage: Object,
  isLoaded: Boolean,
};

const mapStateToProps = (state) => {
  const flashMessages = state?.ui?.flashMessages || [];
  return {
    flashMessage: getFlashMessage(flashMessages, locations.HEADER),
  };
};

function FlashMessageContainer({ closeFlash, onLoad, flashMessage }: IProps) {
  if (Object.keys(flashMessage).length) {
    return <FlashMessageList message={flashMessage} closeFlash={closeFlash} onLoad={onLoad} />;
  }

  return null;
}

export default connect(mapStateToProps)(FlashMessageContainer);
