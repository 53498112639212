import { CMS_UID_RETURNS_CREATION, CMS_UID_TRANSPORT_DAMAGE, CMS_UID_REPAIR } from 'constants/cms/cms';
import { STOREFINDER } from 'constants/routePaths/routePaths';

export const getInitialState = (returnType = AVAILABLE_RETURN_REASONS.ARTICLE_RETURN_ONLINE, userLoggedIn = false) => ({
  returnType,
  userLoggedIn,
  selectedProducts: [],
  selectedMerchantId: '',
  refund: {
    type: AVAILABLE_RETURN_PAYMENT_TYPES.VOUCHER,
  },
  returnLabelId: '',
});

export const AVAILABLE_RETURN_REASONS = Object.freeze({
  ARTICLE_RETURN_STORE: 'ARTICLE_RETURN_STORE',
  ARTICLE_RETURN_ONLINE: 'ARTICLE_RETURN_ONLINE',
  WRONG_ARTICLE: 'WRONG_ARTICLE',
  TRANSIT_DAMAGE: 'TRANSIT_DAMAGE',
  TECHNICAL_DAMAGE: 'TECHNICAL_DAMAGE',
  WARRANTY: 'WARRANTY',
});

export const RETURN_REASONS = Object.freeze([
  {
    id: AVAILABLE_RETURN_REASONS.ARTICLE_RETURN_STORE,
    routePath: [STOREFINDER],
    domId: 'formControl-articleReturnStore-button',
  },
  {
    id: AVAILABLE_RETURN_REASONS.ARTICLE_RETURN_ONLINE,
    pageId: CMS_UID_RETURNS_CREATION,
    domId: 'formControl-articleReturnOnline-button',
  },
  {
    id: AVAILABLE_RETURN_REASONS.WRONG_ARTICLE,
    pageId: CMS_UID_RETURNS_CREATION,
    domId: 'formControl-wrongArticle-button',
  },
  {
    id: AVAILABLE_RETURN_REASONS.TRANSIT_DAMAGE,
    pageId: CMS_UID_TRANSPORT_DAMAGE,
    domId: 'formControl-transitDamage-button',
  },
  {
    id: AVAILABLE_RETURN_REASONS.TECHNICAL_DAMAGE,
    pageId: CMS_UID_RETURNS_CREATION,
    domId: 'formControl-technicalDamage-button',
  },
  {
    id: AVAILABLE_RETURN_REASONS.WARRANTY,
    pageId: CMS_UID_REPAIR,
    domId: 'formControl-warranty-button',
  },
]);

export const AVAILABLE_RETURN_REASON_TYPES = Object.freeze({
  DONT_LIKE_IT: 'DONT_LIKE_IT',
  WRONG_SIZE: 'WRONG_SIZE',
  LATE_DELIVERY: 'LATE_DELIVERY',
  WRONG_ORDERED: 'WRONG_ORDERED',
  OTHER: 'OTHER',
  UNUSED: 'UNUSED',
  USED: 'USED',
  UNUSED_AND_UNDAMAGED: 'UNUSED_AND_UNDAMAGED',
  USED_AND_OR_DAMAGED: 'USED_AND_OR_DAMAGED',
  ORIGINAL_PACKAGING_NOT_AVAILABLE: 'ORIGINAL_PACKAGING_NOT_AVAILABLE',
  WRONG_ARTICLE: 'WRONG_ARTICLE',
  NOT_ORDERED: 'NOT_ORDERED',
  INCOMPLETE_ARTICLE: 'INCOMPLETE_ARTICLE',
  FAULTY_ARTICLE: 'FAULTY_ARTICLE',
  WRONG_ARTICLE_DESCRIPTION: 'WRONG_ARTICLE_DESCRIPTION',
});

export const REASONS_NEED_DESCRIPTION = Object.freeze([
  AVAILABLE_RETURN_REASON_TYPES.WRONG_ARTICLE,
  AVAILABLE_RETURN_REASON_TYPES.NOT_ORDERED,
  AVAILABLE_RETURN_REASON_TYPES.INCOMPLETE_ARTICLE,
  AVAILABLE_RETURN_REASON_TYPES.WRONG_ARTICLE_DESCRIPTION,
  AVAILABLE_RETURN_REASON_TYPES.FAULTY_ARTICLE,
]);

export const AVAILABLE_RETURN_PAYMENT_TYPES = Object.freeze({
  VOUCHER: 'VOUCHER',
  IBAN: 'IBAN',
  NONE: 'NONE',
});

export const allReasonsFilled = (returnType, product) => {
  const steps = getReasonSteps(returnType);
  const filledReasons = product.returnReasons ? Object.values(product.returnReasons).filter(Boolean).length : 0;
  return filledReasons === steps.length;
};

export const getReasonSteps = (returnType) => {
  if (AVAILABLE_RETURN_REASONS.WRONG_ARTICLE === returnType) {
    return [
      {
        key: 'reason',
        options: [
          AVAILABLE_RETURN_REASON_TYPES.WRONG_ARTICLE,
          AVAILABLE_RETURN_REASON_TYPES.NOT_ORDERED,
          AVAILABLE_RETURN_REASON_TYPES.INCOMPLETE_ARTICLE,
          AVAILABLE_RETURN_REASON_TYPES.WRONG_ARTICLE_DESCRIPTION,
        ],
      },
    ];
  }

  if (AVAILABLE_RETURN_REASONS.TECHNICAL_DAMAGE === returnType) {
    return [
      {
        key: 'reason',
        options: [AVAILABLE_RETURN_REASON_TYPES.FAULTY_ARTICLE],
      },
    ];
  }

  return [
    {
      key: 'reason',
      options: [
        AVAILABLE_RETURN_REASON_TYPES.DONT_LIKE_IT,
        AVAILABLE_RETURN_REASON_TYPES.WRONG_SIZE,
        AVAILABLE_RETURN_REASON_TYPES.LATE_DELIVERY,
        AVAILABLE_RETURN_REASON_TYPES.WRONG_ORDERED,
        AVAILABLE_RETURN_REASON_TYPES.OTHER,
      ],
    },
    {
      key: 'packagingCondition',
      options: [
        AVAILABLE_RETURN_REASON_TYPES.UNUSED_AND_UNDAMAGED,
        AVAILABLE_RETURN_REASON_TYPES.USED_AND_OR_DAMAGED,
        AVAILABLE_RETURN_REASON_TYPES.ORIGINAL_PACKAGING_NOT_AVAILABLE,
      ],
    },
    {
      key: 'articleCondition',
      options: [AVAILABLE_RETURN_REASON_TYPES.UNUSED, AVAILABLE_RETURN_REASON_TYPES.USED],
    },
  ];
};

/**
 * Returns true if the passed reason not disqualifies the product for a return
 */
export const isProductReturnable = (returnReasonType, product) => {
  if (!!product.openPackageNotAllowed && returnReasonType === AVAILABLE_RETURN_REASON_TYPES.USED_AND_OR_DAMAGED) {
    // hygiene product packaging was opened or damaged
    return false;
  }

  if (returnReasonType === AVAILABLE_RETURN_REASON_TYPES.ORIGINAL_PACKAGING_NOT_AVAILABLE) {
    // original packaging is not available
    return false;
  }

  if (returnReasonType === AVAILABLE_RETURN_REASON_TYPES.USED) {
    // product is used
    return false;
  }

  return true;
};
