import language from 'constants/language/language';
import formatDate from 'constants/formatDate/formatDate';
import { availabilityStates } from 'constants/deliveryStates/availabilityStates';
import { nowDate } from 'constants/deliveryModes/deliveryModes';

/**
 As a special case, a product with availabilityStatus G can have two different states:
 1. Leadtime < 180 days (you can get it in less than 6 months)
 2. Leadtime > 180 days
 In the first case the deliveryDate has the key `value`, otherwise it's an empty object. But the backend can only
 determine the exact deliveryDate on ProductDetail pages, so on ProductOverview pages it sends the boolean
 `productOrderable` instead.
 */
export const isProductOrderable = (deliveryDate, productOrderable) => {
  if (productOrderable !== undefined) return !!productOrderable;
  return deliveryDate?.value !== undefined;
};

/**
 * checks if a date expires
 * @returns {boolean}
 */
export const isDateExpired = (date) => {
  const expires = new Date(date.expires);
  const now = new Date();

  return expires < now;
};

export const getDaysBetween = (firstDate = new Date(), secondDate = new Date()) => {
  if (firstDate instanceof Date && secondDate instanceof Date) {
    const diff = Math.abs(firstDate.getTime() - secondDate.getTime());
    return Math.round(diff / (1000 * 60 * 60 * 24));
  }
  return 0;
};

export const isReleaseDateInFuture = (releaseDate = new Date()) => {
  if (!(releaseDate instanceof Date)) return false;
  const currDate = new Date();
  const daysBetween = getDaysBetween(currDate, releaseDate);
  return daysBetween >= 732;
};

export const getDeliveryStateShort = ({ availability, releaseDate = {}, isCart = false }) => {
  const immediateDeliveryStates = [availabilityStates.M, availabilityStates.Y];
  const identifier = isCart && immediateDeliveryStates.includes(availability) ? 'checkout' : 'short';
  const isReleaseDateInPast = new Date(releaseDate) < nowDate || !releaseDate;

  const data = {
    text: isReleaseDateInPast
      ? language(`deliveryStates.${identifier}.inStock`)
      : language(`deliveryStates.${identifier}.${availability}`),
    isDisabled: availability === availabilityStates.G2,
    timestamp:
      isCart && immediateDeliveryStates.includes(availability)
        ? formatDate(releaseDate?.value || releaseDate)
        : undefined,
    immediatelyAvailable: immediateDeliveryStates.includes(availability),
    isReleaseArticle: [availabilityStates.R, availabilityStates.Y].includes(availability),
    isReleaseDateInPast,
  };

  if ([availabilityStates.R].includes(availability)) {
    const isInFuture = isReleaseDateInFuture(new Date(releaseDate)) && !isReleaseDateInPast;
    if (isInFuture) {
      data.text = `${language('deliveryStates.detail.reservation.unknown')}. ${language(
        'deliveryStates.detail.reservation.text2'
      )}.`;
    }
  }

  return data;
};

export const getDeliveryStateFull = ({
  availability,
  speedFlag,
  deliveryDate = {},
  deliveryDateTo = {},
  pickupDate = {},
  releaseDate,
  isCart,
}) => {
  const immediateDeliveryStates = [availabilityStates.M, availabilityStates.Y];
  const identifier = isCart ? 'checkout' : 'detail';
  const isReleaseDateInPast =
    [availabilityStates.R, availabilityStates.Y].includes(availability) &&
    (new Date(releaseDate) < nowDate || !releaseDate);

  const data = {
    isDisabled: availability === availabilityStates.G2,
    text: isReleaseDateInPast
      ? language(`deliveryStates.${identifier}.inStock`)
      : language(`deliveryStates.${identifier}.${availability}.text`, '') ||
        language(`deliveryStates.${identifier}.${availability}`),
    immediatelyAvailable: immediateDeliveryStates.includes(availability),
    isReleaseArticle: [availabilityStates.R, availabilityStates.Y].includes(availability),
    isReleaseDateInPast,
  };
  if (!isDateExpired(pickupDate) && speedFlag && !immediateDeliveryStates.includes(availability)) {
    data.pickup = formatDate(pickupDate.value);
    data.delivery = formatDate(deliveryDate.value);
    data.timestamp = formatDate(deliveryDate.value);
    data.timestampTo = formatDate(deliveryDateTo?.value);
  } else {
    data.text2 = language(`deliveryStates.${identifier}.${availability}.text2`, '');
    data.pickup = formatDate(pickupDate.value);
    data.delivery = formatDate(deliveryDate.value);
    data.timestamp = formatDate(deliveryDate.value || releaseDate);
    data.timestampTo = formatDate(deliveryDateTo?.value);
    data.releaseDate = releaseDate ? formatDate(releaseDate.value || releaseDate) : undefined;
  }

  if ([availabilityStates.R].includes(availability)) {
    const isInFuture = isReleaseDateInFuture(new Date(releaseDate)) && !isReleaseDateInPast;
    if (isInFuture) {
      data.isInFuture = true;
    }
  }

  return data;
};

export const getDeliveryState = (
  {
    availability,
    releaseDate,
    pickupDate = {},
    deliveryDate = {},
    deliveryDateTo = {},
    speedFlag = false,
    isCart = false,
  },
  getFull = false
) =>
  getFull
    ? getDeliveryStateFull({ availability, speedFlag, deliveryDate, deliveryDateTo, releaseDate, pickupDate, isCart })
    : getDeliveryStateShort({ availability, releaseDate, isCart });

const sortEntries = (data) => {
  const getDate = (string = '') => {
    const [day, month, year] = string.split('.');
    return new Date(year, month - 1, day);
  };
  // sort entries by finalDate
  const dateSort = data.sort((a, b) => getDate(formatDate(a.finalDate)) - getDate(formatDate(b.finalDate)));
  // move esd articles to top
  return dateSort.sort((a) => (a.availability === 'M' ? -1 : 1));
};

const mapProperties = (
  finalDate,
  deliveryMethodValue,
  deliveryValueDate,
  pickUpValueDate,
  availability,
  entry,
  data,
  productCodeValue,
  baseProductPrice,
  productQuantity,
  productReleaseDate,
  productBulkyGoods,
  productDiscounts,
  productServiceItems,
  productPreDiscountTotalPrice,
  employeeDiscounts,
  productTotalPrice,
  deliveryDataDate,
  finalDateTo,
  isReleaseDateInPast
) => {
  const unKnownDeliveryDate = language('deliveryStates.checkout.unknown');
  const deliveryStates = {
    M: language('deliveryStates.checkout.immediately'),
    G2: unKnownDeliveryDate,
  };
  const deliveryDateUnknown = !finalDate ? unKnownDeliveryDate : null;

  const dateUnknownReleaseProduct =
    availability === 'R' && !isReleaseDateInPast
      ? `${language('deliveryStates.checkout.reservation')}: ${formatDate(productReleaseDate)}`
      : null;

  const isFutureReleaseProduct =
    availability === 'R' && isReleaseDateInFuture(new Date(productReleaseDate)) && !isReleaseDateInPast
      ? unKnownDeliveryDate
      : null;

  const esdProduct =
    availability === 'Y' && !isReleaseDateInPast
      ? `${language('deliveryStates.checkout.reservation')}: ${formatDate(productReleaseDate)}`
      : null;

  return {
    finalDate,
    deliveryValueDate,
    pickUpValueDate,
    availability,
    deliveryMethodValue,
    productCodeValue,
    productReleaseDate,
    deliveryDataDate,
    text:
      deliveryStates[availability] ||
      isFutureReleaseProduct ||
      dateUnknownReleaseProduct ||
      esdProduct ||
      deliveryDateUnknown ||
      `${language('deliveryStates.checkout.inStock')}: ${formatDate(finalDate)}${
        finalDateTo ? ' - ' + formatDate(finalDateTo) : ''
      }`,
    entries: [
      ...(data.get(availability === 'M' ? 'M' : formatDate(finalDate))?.entries ?? []),
      {
        name: splitName(entry),
        productQuantity,
        baseProductPrice,
        productDiscounts,
        productServiceItems,
        productPreDiscountTotalPrice,
        employeeDiscounts,
        productBulkyGoods,
        productTotalPrice,
        deliveryDataDate,
        finalDate,
        availability,
        productCodeValue,
        productReleaseDate,
        finalDateTo,
      },
    ],
  };
};

const splitName = (entry) => ({
  firstSplit: entry.product.name?.split(' ')[0],
  secondSplit: entry.product.name?.split(' ').slice(1).join(' '),
});

const mapEntriesToBoxes = (cartEntries) => {
  const deliveryMethodValue = cartEntries?.orderData?.deliveryMode?.reference;
  const cartList = Array.isArray(cartEntries?.entries) ? cartEntries.entries : [];
  const data = new Map();
  cartList?.forEach((entry) => {
    const availability = entry.product?.availabilityStatus;
    const finalDateTo = entry?.deliveryDateTo;
    const deliveryValueDate = entry?.product?.deliveryTime;
    const pickUpValueDate = entry?.product?.pickupTime;
    const baseProductPrice = entry?.basePrice;
    const productCodeValue = entry?.productCode;
    const productQuantity = entry?.quantity;
    const productReleaseDate = entry?.product?.releaseDate;
    const productBulkyGoods = entry?.bulkyGoods;
    const productDiscounts = entry?.productDiscount;
    const productServiceItems = entry?.serviceItems;
    const productPreDiscountTotalPrice = entry?.preDiscountTotalPrice;
    const employeeDiscounts = entry?.employeeDiscount;
    const productTotalPrice = entry?.totalPrice;
    const isEsdArticle = availability === 'M';
    const deliveryValueDateValue = formatDate(entry?.product?.deliveryTime?.value);
    const pickUpValueDateValue = formatDate(entry?.product?.pickupTime?.value);
    const deliveryDataDate =
      deliveryMethodValue === 'home-delivery-gross' ? deliveryValueDateValue : pickUpValueDateValue;
    const isReleaseDateInPast = new Date(productReleaseDate) < nowDate || !productReleaseDate;

    const finalDate = (() => {
      if (availability === 'R' && !isReleaseDateInPast) {
        return isReleaseDateInFuture(new Date(productReleaseDate)) ? undefined : productReleaseDate;
      } else if (entry?.deliveryDate) {
        return entry.deliveryDate;
      }
      return undefined;
    })();

    const commonArgs = [
      finalDate,
      deliveryMethodValue,
      deliveryValueDate,
      pickUpValueDate,
      availability,
      entry,
      data,
      productCodeValue,
      baseProductPrice,
      productQuantity,
      productReleaseDate,
      productBulkyGoods,
      productDiscounts,
      productServiceItems,
      productPreDiscountTotalPrice,
      employeeDiscounts,
      productTotalPrice,
      deliveryDataDate,
      finalDateTo,
      isReleaseDateInPast,
    ];
    if (isEsdArticle) {
      data.set('M', mapProperties(...commonArgs));
    } else {
      data.set(formatDate(finalDate), mapProperties(...commonArgs));
    }
  });
  return Array.from(data.values());
};

export const getDeliveryBoxState = (cartEntries = []) => {
  // Map Entries with same Date into same Box
  const data = mapEntriesToBoxes(cartEntries);
  // Sort Array
  const sortedArray = sortEntries(data);

  return sortedArray;
};
