import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateEntry as updateEntryAction } from 'actions/cartActions/cartActions';
import debounce from 'constants/helper/debounce';
import ProductCounter from 'components/molecules/CartProductCounter/CartProductCounter';

export const mapStateToProps = ({ cart, products }, { entryID }) => ({
  cartEntry: cart.entries[entryID],
  product: cart.entries[entryID] ? products[cart.entries[entryID].productCode] : undefined,
  totalPrice: cart.entries?.[entryID]?.totalPrice || {},
});
const mapDispatchToProps = {
  updateEntry: updateEntryAction,
};

const CartProductCounter = ({
  cartEntry = {},
  className = '',
  entryID,
  isDisabled = false,
  product = {},
  totalPrice = {},
  updateEntry,
}) => {
  const updateStore = debounce((quantity) => {
    updateEntry(entryID, product.code, quantity, cartEntry.serviceItemCodes);
  }, 500);

  return (
    <ProductCounter
      product={product}
      className={className}
      cartEntry={cartEntry}
      updateStore={updateStore}
      entryID={entryID}
      totalPrice={totalPrice}
      isDisabled={isDisabled}
    />
  );
};

CartProductCounter.displayName = 'containers/CartProductCounter';
CartProductCounter.propTypes = {
  cartEntry: PropTypes.object,
  className: PropTypes.string,
  entryID: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool,
  product: PropTypes.object,
  totalPrice: PropTypes.object,
  updateEntry: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CartProductCounter);
