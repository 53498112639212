import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { getEnv } from 'config/config';
import { STOREFINDER_INTERDISCOUNT_LOGO_URL } from 'constants/storefinder/storefinder';

import language from 'constants/language/language';
import styles from './StoreManagerInfo.scss';

const StoreManagerInfo = ({ storeManager, phone }) => {
  const [imageSrc, setImageSrc] = useState(
    storeManager.name ? storeManager.imageUrl : STOREFINDER_INTERDISCOUNT_LOGO_URL
  );

  const handleImageError = (e) => {
    e.stopPropagation();
    setImageSrc(STOREFINDER_INTERDISCOUNT_LOGO_URL);
  };

  return (
    <div className={styles.managerWrapper}>
      <div className={styles.managerImg}>
        <img
          src={`${getEnv('api')}${imageSrc}`}
          alt={`${storeManager?.name ?? 'ID Logo'}`}
          className={styles.managerImage}
          onError={handleImageError}
        />
      </div>

      {(storeManager?.name || phone) && (
        <div className={styles.managerInfo}>
          <div className={styles.title}>{language('pickupStore.storeManager')}</div>
          <span>{storeManager?.name}</span>
          <span>{phone}</span>
        </div>
      )}
    </div>
  );
};

StoreManagerInfo.propTypes = {
  storeManager: PropTypes.shape({
    name: PropTypes.string,
    phone: PropTypes.string,
    imageUrl: PropTypes.string,
    image: PropTypes.shape({
      sizes: PropTypes.array,
    }),
  }).isRequired,
  phone: PropTypes.string,
};

StoreManagerInfo.displayName = 'atoms/StoreManagerInfo';

export default StoreManagerInfo;
