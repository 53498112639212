import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import language from 'constants/language/language';
import { calculatePages } from 'constants/paginationHelper/paginationHelper';
import ProductsFacetContainer from 'containers/ProductsFacetContainer/ProductsFacetContainer';
import Icon, { ICON_KEYBOARD_ARROW_LEFT, ICON_KEYBOARD_ARROW_RIGHT } from 'components/atoms/Icon/Icon';
import PaginationLink from 'components/atoms/PaginationLink/PaginationLink';
import bootstrap from 'scss/component.scss';
import styles from './Pagination.scss';

const Pagination = ({ currentPage, scrollToId = {}, totalPages }) => {
  const leadingZero = (page) => (page < 10 ? `0${page}` : `${page}`);

  const pages = () =>
    calculatePages(currentPage, totalPages).map((page) => (
      <ProductsFacetContainer key={page} page={page}>
        <PaginationLink
          className={currentPage === page ? styles.current : bootstrap.hiddenXsDown}
          scrollToId={scrollToId}
        >
          {leadingZero(page)}
        </PaginationLink>
      </ProductsFacetContainer>
    ));

  if (totalPages <= 1) return null; // don't show if only one page

  return (
    <ul className={styles.pagination}>
      {currentPage <= 1 ? (
        <li>
          <button className={styles.pageButton}>
            <div className={cx(styles.paginationLink, styles.left, styles.disabled)}>
              <Icon path={ICON_KEYBOARD_ARROW_LEFT} />
              <span>{language('common.return')}</span>
            </div>
          </button>
        </li>
      ) : (
        <ProductsFacetContainer key={currentPage - 1} page={currentPage - 1}>
          <PaginationLink className={styles.pageButton} scrollToId={scrollToId} displayPrevSEO>
            <div className={cx(styles.paginationLink, styles.left)}>
              <Icon path={ICON_KEYBOARD_ARROW_LEFT} />
              <span>{language('common.return')}</span>
            </div>
          </PaginationLink>
        </ProductsFacetContainer>
      )}

      {pages()}

      {currentPage === totalPages ? (
        <li>
          <button className={styles.pageButton}>
            <div className={cx(styles.paginationLink, styles.right, styles.disabled)}>
              <span>{language('common.continue')}</span>
              <Icon path={ICON_KEYBOARD_ARROW_RIGHT} />
            </div>
          </button>
        </li>
      ) : (
        <ProductsFacetContainer key={currentPage + 1} page={currentPage + 1}>
          <PaginationLink className={styles.pageButton} scrollToId={scrollToId} displayNextSEO>
            <div className={cx(styles.paginationLink, styles.right)}>
              <span>{language('common.continue')}</span>
              <Icon path={ICON_KEYBOARD_ARROW_RIGHT} />
            </div>
          </PaginationLink>
        </ProductsFacetContainer>
      )}
    </ul>
  );
};

export default Pagination;

Pagination.displayName = 'molecules/Pagination';
Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  scrollToId: PropTypes.object,
  totalPages: PropTypes.number.isRequired,
};
