import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'constants/noop/noop';
import styles from './EnergyEfficiency.scss';
import { EnergyEfficiency, OldEnergyEfficiency } from '@interdiscount/component-library-interdiscount';

export const types = {
  lg: 'lg',
  sm: 'sm',
  xs: 'xs',
};

export const contexts = {
  NONE: '',
  WATCHLIST: 'watchlist',
  TEASER: 'teaser',
  SMALL_TEASER: 'smallTeaser',
};

const EnergyEfficiencyFlag = ({
  canOpenModal = false,
  context = contexts.NONE,
  energyEfficiency = {},
  energyEfficiencyEnEv2020 = {},
  openModal = noop,
  type = types.lg,
  orientation = 'right',
}) => {
  const renderFlag = () => {
    return (
      <div className={styles[context]}>
        {Object.keys(energyEfficiencyEnEv2020 || {})?.length ? (
          <EnergyEfficiency
            efficiency={energyEfficiencyEnEv2020.efficiencyClass}
            color={energyEfficiencyEnEv2020.colorCode}
            size={type}
            orientation={orientation}
          ></EnergyEfficiency>
        ) : (
          <OldEnergyEfficiency
            efficiency={energyEfficiency.efficiencyClass}
            color={energyEfficiency.colorCode}
            size={type}
            orientation={orientation}
          ></OldEnergyEfficiency>
        )}
      </div>
    );
  };

  return !canOpenModal ? (
    renderFlag()
  ) : (
    <div className={styles.clickable} onClick={openModal}>
      {renderFlag()}
    </div>
  );
};

EnergyEfficiencyFlag.displayName = 'atoms/EnergyEfficiency';
EnergyEfficiencyFlag.propTypes = {
  canOpenModal: PropTypes.bool,
  context: PropTypes.oneOf(Object.values(contexts)),
  energyEfficiency: PropTypes.object,
  energyEfficiencyEnEv2020: PropTypes.object,
  openModal: PropTypes.func,
  type: PropTypes.oneOf(Object.values(types)),
  orientation: PropTypes.string,
};

export default EnergyEfficiencyFlag;
