import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ModalMiele from 'components/organisms/ModalMiele/ModalMiele';

import styles from './ModalMieleLink.scss';

export default class ModalMieleLink extends Component {
  static displayName = 'organisms/ModalMieleLink';

  static propTypes = {
    data: PropTypes.string,
    src: PropTypes.string,
  };
  static defaultProps = {
    src: '',
    data: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  openModal = () => {
    this.setState({
      isOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      isOpen: false,
    });
  };

  sliceLinkText = (text) => {
    if (!text) {
      return text;
    }
    return text.slice(2, -2);
  };

  render() {
    const { src, data } = this.props;

    return (
      <span>
        <span onClick={this.openModal} className={styles.modalLink}>
          {this.sliceLinkText(data)}
        </span>
        {this.state.isOpen && <ModalMiele src={src} closeModal={this.closeModal} />}
      </span>
    );
  }
}
