import React from 'react';
import PropTypes from 'prop-types';

import styles from './ProductSubnavigation.scss';
import MobileNavigationItem from 'components/atoms/MobileNavigationItem/MobileNavigationItem';
import { ICON_ARROW_HISTORY_BACK } from 'components/atoms/Icon/Icon';
import language, { getLocale } from 'constants/language/language';
import { getCategoryUrl } from 'constants/categoryTree/categoryTree';
import { browserHistory } from 'react-router';
import { NAVIGATION_CLICK } from 'constants/trackingAttributes/trackingAttributes';
import { trackMobileNavigationBack, trackMobileNavigationGoTo } from 'tracking/tracking';
import { sanitizeAndEncode } from 'constants/sanitizeAndEncode/sanitizeAndEncode';

const ProductSubnavigationItems = ({ categories, desktopCategoryParents, drilledCategoryTree }) => {
  const POSITION_OFFSET = 1;

  const goBackInitial = () => {
    trackMobileNavigationBack(
      NAVIGATION_CLICK,
      POSITION_OFFSET,
      categories?.[desktopCategoryParents[desktopCategoryParents?.length - 1]]?.[getLocale()],
      language('header.allProducts')
    );
    browserHistory.push(`/${getLocale()}`);
  };

  const handleCategoryParents = (index, categoryId) => {
    trackMobileNavigationBack(
      NAVIGATION_CLICK,
      index + POSITION_OFFSET,
      categories[desktopCategoryParents?.[desktopCategoryParents?.length - 1]][getLocale()],
      categories[categoryId]?.[getLocale()]
    );
    browserHistory.push(getCategoryUrl(categoryId, getLocale(), categories));
  };

  const handleDrilledCategories = (category, i) => {
    trackMobileNavigationGoTo(
      NAVIGATION_CLICK,
      i + POSITION_OFFSET,
      categories[category.id]?.[getLocale()],
      categories[desktopCategoryParents?.[desktopCategoryParents?.length - 1]][getLocale()]
    );
    browserHistory.push(getCategoryUrl(category.id, getLocale(), categories));
  };

  return (
    <ul className={styles.container}>
      <MobileNavigationItem
        indicatorIcon={ICON_ARROW_HISTORY_BACK}
        showIndicator
        onClick={goBackInitial}
        className={styles.itemFirst}
      >
        {language('header.allProducts')}
      </MobileNavigationItem>

      <div className={styles.drilldownBreadcrumb}>
        {desktopCategoryParents?.length >= 0 &&
          desktopCategoryParents.map((categoryId, index) => {
            if (categoryId !== '1') {
              const canGoBack = desktopCategoryParents.length - 1 !== index;
              return (
                <MobileNavigationItem
                  disabled={!canGoBack}
                  indicatorIcon={ICON_ARROW_HISTORY_BACK}
                  showIndicator={canGoBack}
                  key={categoryId}
                  onClick={() => handleCategoryParents(index, categoryId)}
                >
                  {categories[categoryId][getLocale()]}
                </MobileNavigationItem>
              );
            }
            return null;
          })}
      </div>

      {drilledCategoryTree &&
        drilledCategoryTree.map((category, index) => (
          <MobileNavigationItem
            dataCy={sanitizeAndEncode(categories[category.id][getLocale()])}
            key={category.id}
            id={category.id}
            showIndicator={false}
            onClick={() => handleDrilledCategories(category, index)}
          >
            {categories[category.id][getLocale()]}
          </MobileNavigationItem>
        ))}
    </ul>
  );
};

ProductSubnavigationItems.displayName = 'molecules/ProductSubnavigationItems';
ProductSubnavigationItems.propTypes = {
  drilledCategoryTree: PropTypes.array,
  desktopCategoryParents: PropTypes.array,
  categories: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};
ProductSubnavigationItems.defaultProps = {
  categories: [],
};

export default ProductSubnavigationItems;
