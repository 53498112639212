import React from 'react';
import { Route } from 'react-router';
import { CX_PREVIEW } from 'constants/routePaths/routePaths';

const errorLoading = (err) => {
  console.error('Dynamic page loading failed', err);
};
const loadRoute = (cb) => (module) => cb(null, module.default);

const getSmartEditInitialize = (nextState, cb) => {
  import('containers/SmartEditInitialize/SmartEditInitialize').then(loadRoute(cb)).catch(errorLoading);
};

export default function createSmartEditRoutes() {
  return <Route path={CX_PREVIEW} getComponent={getSmartEditInitialize} />;
}
