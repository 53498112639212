import React from 'react';
import language from 'constants/language/language';
import Spinner from 'components/atoms/Spinner/Spinner';
import styles from './LoadingState.scss';

const LoadingState = () => (
  <div className={styles.container}>
    <div className={styles.spinnerContainer}>
      <Spinner height={70} />
    </div>
    <strong>{language('checkout.orderInProgressPart1')}</strong>
    <br />
    <strong>{language('checkout.orderInProgressPart2')}</strong>
  </div>
);

LoadingState.displayName = 'molecules/LoadingState';

export default LoadingState;
