import { handleActions } from 'redux-actions';

import {
  INITIALIZE_MAIN_MENU,
  SET_MAIN_MENU_ISOPEN,
  SET_SEARCH_FOCUS,
  OPEN_CART_MODAL,
  CLOSE_CART_MODAL,
  SET_MAIN_MENU_CLOSE,
  SET_SEARCH_STRING,
  ROUTER_UPDATE_LOCATION,
  SET_FACET_COLLAPSE_FLAG,
  RESET_ALL_FACET_COLLAPSE_FLAGS,
  SET_LOGIN_NOTIFICATION,
  RESET_LOGIN_NOTIFICATION,
  FORGOTTEN_PASSWORD_RESTORE_PASSWORD_SUCCESS,
  FORGOTTEN_PASSWORD_RESTORE_PASSWORD_FAILURE,
  FORGOTTEN_PASSWORD_CHECK_TOKEN_FAILURE,
  CHANGE_LOGIN_SUCCESS,
  CHANGE_LOGIN_FAILURE,
  DISMISS_TOPBAR,
  LOAD_IS_TOPBAR_DIMISSED,
  SAVE_CURRENT_BREAKPOINT,
  SET_FORCE_VALIDATION_ERRORS_VISIBLE,
  SHOW_POPOVER_LOGIN,
  SHOW_POPOVER_CART,
  TOGGLE_PRODUCT_VIEW,
  TOGGLE_NAVIGATION,
  LOAD_FEATURE_CONFIG_SUCCESS,
  ADD_FLASHMESSAGE,
  REMOVE_FLASHMESSAGE,
  LOAD_FLASHMESSAGES_SUCCESS,
  SET_VISIBLE_PRODUCTS,
} from 'constants/ActionTypes/ActionTypes';

import transform from 'constants/reducerHelper/reducerHelper';

export const loginStates = {
  NONE: 'NONE',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
};

export const loginNotifications = {
  PASSWORD_RESET_SUCCESS: 'PASSWORD_RESET_SUCCESS',
  PASSWORD_RESET_FAILURE: 'PASSWORD_RESET_FAILURE',
  CHANGE_LOGIN_SUCCESS: 'CHANGE_LOGIN_SUCCESS',
  CHANGE_LOGIN_FAILURE: 'CHANGE_LOGIN_FAILURE',
};

export const initialState = {
  mainMenu: {
    isOpen: false,
  },
  cartModal: {
    isOpen: false,
  },
  search: null,
  searchFocus: false,
  filterMenu: {
    isOpen: false,
  },
  openFacets: {},
  loginNotification: null,
  isClient: false,
  forceValidation: false,
  isPopOverLoginVisible: false,
  showNavigation: false,
  featureTogglingConfig: [],
  flyIns: [],
  flashMessages: [],
  visibleProducts: [],
};

export default handleActions(
  {
    [INITIALIZE_MAIN_MENU]: (state, action) => ({
      ...state,
      mainMenu: {
        isOpen: action.payload.isOpen,
      },
    }),
    [SET_MAIN_MENU_ISOPEN]: (state) => ({
      ...state,
      searchFocus: false,
      mainMenu: {
        isOpen: state.mainMenu ? !state.mainMenu.isOpen : false,
      },
    }),
    [SET_SEARCH_FOCUS]: (state) => ({
      ...state,
      searchFocus: true,
      mainMenu: {
        isOpen: true,
      },
    }),
    [OPEN_CART_MODAL]: (state) => ({
      ...state,
      cartModal: {
        isOpen: true,
      },
    }),
    [CLOSE_CART_MODAL]: (state) => ({
      ...state,
      cartModal: {
        isOpen: false,
      },
    }),
    [SET_MAIN_MENU_CLOSE]: (state) => ({
      ...state,
      searchFocus: false,
      mainMenu: {
        isOpen: false,
      },
    }),
    [SET_SEARCH_STRING]: (state, action) => ({
      ...state,
      search: action.payload,
    }),
    [ROUTER_UPDATE_LOCATION]: (state) => ({
      ...state,
      // if search is reset here, then right after LOAD_PRODUCTS_SUCCESS has fired,
      // InputSearch.debounceStorage() is called and thus also loadProductSuggestions.
      // This makes the suggestions disappear and re-appear when clicking "search"
      // (described behaviour seen on tablet Nexus 9)
      // search: null,
      mainMenu: {
        isOpen: false,
      },
      cartModal: {
        isOpen: false,
      },
      forceValidation: false,
      showNavigation: false,
      isPopOverCartVisible: false,
      visibleProducts: [],
    }),
    [SET_FACET_COLLAPSE_FLAG]: (state, action) => {
      const { facetCode, collapsed } = action.payload;

      const openFacets = { ...state.openFacets };

      if (collapsed !== undefined) {
        openFacets[facetCode] = {
          collapsed,
        };
      } else {
        // check if the facet was already triggered
        if (openFacets[facetCode]) {
          // reverse collapsed state
          openFacets[facetCode] = {
            collapsed: !openFacets[facetCode].collapsed,
          };
        } else {
          openFacets[facetCode] = {
            collapsed: true,
          };
        }
      }

      return {
        ...state,
        openFacets,
      };
    },
    [RESET_ALL_FACET_COLLAPSE_FLAGS]: (state) => ({
      ...state,
      openFacets: {},
    }),
    // @TODO is this still needed?
    [SET_LOGIN_NOTIFICATION]: (state, action) => ({
      ...state,
      loginNotification: action.payload.status,
    }),
    [RESET_LOGIN_NOTIFICATION]: (state) => ({
      ...state,
      loginNotification: undefined,
    }),
    [FORGOTTEN_PASSWORD_RESTORE_PASSWORD_SUCCESS]: (state) => ({
      ...state,
      loginNotification: loginNotifications.PASSWORD_RESET_SUCCESS,
    }),

    [FORGOTTEN_PASSWORD_RESTORE_PASSWORD_FAILURE]: (state) => ({
      ...state,
      loginNotification: loginNotifications.PASSWORD_RESET_FAILURE,
    }),

    [FORGOTTEN_PASSWORD_CHECK_TOKEN_FAILURE]: (state) => ({
      ...state,
      loginNotification: loginNotifications.PASSWORD_RESET_FAILURE,
    }),
    [CHANGE_LOGIN_SUCCESS]: (state) => ({
      ...state,
      loginNotification: loginNotifications.CHANGE_LOGIN_SUCCESS,
    }),
    [CHANGE_LOGIN_FAILURE]: (state) => ({
      ...state,
      loginNotification: loginNotifications.CHANGE_LOGIN_FAILURE,
    }),

    [DISMISS_TOPBAR]: (state, action) => {
      if (!action.payload) return state;
      return {
        ...state,
        dismissedTopbar: action.payload,
      };
    },
    [LOAD_IS_TOPBAR_DIMISSED]: (state, action) => {
      if (!action.payload) return state;
      return {
        ...state,
        dismissedTopbar: action.payload,
      };
    },

    [SAVE_CURRENT_BREAKPOINT]: (state, action) => {
      const newState = transform(state);

      if (!action.payload) return newState.set('isClient', true).value();

      const breakpoint = action.payload;

      newState.set('isClient', true);
      newState.set('breakpoint', breakpoint);

      return newState.value();
    },

    [SET_FORCE_VALIDATION_ERRORS_VISIBLE]: (state, action) => {
      const newState = { ...state };
      newState.forceValidation = action.value;
      return newState;
    },

    [SHOW_POPOVER_LOGIN]: (state, action) => {
      if (typeof action.isPopOverLoginVisible === 'undefined') return state;
      return {
        ...state,
        isPopOverLoginVisible: action.isPopOverLoginVisible,
      };
    },

    [SHOW_POPOVER_CART]: (state, action) => {
      if (typeof action.isPopOverCartVisible === 'undefined') return state;
      return {
        ...state,
        isPopOverCartVisible: action.isPopOverCartVisible,
      };
    },

    [TOGGLE_PRODUCT_VIEW]: (state, action) => {
      const viewType = action.payload?.viewType;
      if (!viewType) return state;
      const newState = transform(state);
      newState.set('productViewType', viewType);

      return newState.value();
    },

    [TOGGLE_NAVIGATION]: (state, action) => {
      const shouldShow = action.payload?.shouldShow ?? false;
      const newState = transform(state);
      newState.set('showNavigation', shouldShow);

      return newState.value();
    },

    [LOAD_FEATURE_CONFIG_SUCCESS]: (state, action) => {
      const newState = transform(state);
      const data = action?.req?.data;
      newState.set('featureTogglingConfig', data);
      return newState.value();
    },

    [ADD_FLASHMESSAGE]: (state, action) => {
      const { message } = action.payload;
      if (!message) return state;

      const newState = transform(state);
      newState.set('flyIns', [...state.flyIns, message]);
      return newState.value();
    },

    [REMOVE_FLASHMESSAGE]: (state, action) => {
      const id = action.payload?.id;
      const newState = transform(state);
      const messages = state.flyIns?.filter((msg) => msg.id !== id);
      return newState.set('flyIns', [...messages]).value();
    },
    [LOAD_FLASHMESSAGES_SUCCESS]: (state, action) => {
      const flashMessages = action?.req?.data?.flashMessages;
      return transform(state)
        .set('flashMessages', [...flashMessages])
        .value();
    },
    [SET_VISIBLE_PRODUCTS]: (state, action) => ({
      ...state,
      visibleProducts: action.payload,
    }),
  },
  initialState
);
