import React from 'react';
import PropTypes from 'prop-types';

import { getJsonLd } from 'constants/getSeoStructuredData/getSeoStructuredData';

// Available types can be found here: https://schema.org/docs/full.html
export const seoStructuredDataTypes = {
  BREADCRUMB: 'BreadcrumbList',
  LOCALBUSINESS: 'LocalBusiness',
  PRODUCT: 'Product',
  SEARCHACTION: 'SearchAction',
};

/**
 * Seo Structured Data
 *
 * Returns a script tag with a JSON in it which provides structured
 * data for the search engine as required/wished from Google.
 *
 * Source: https://developers.google.com/search/docs/guides/intro-structured-data
 */
const SeoStructuredData = (props) => (
  <script
    type="application/ld+json"
    dangerouslySetInnerHTML={{
      __html: getJsonLd(props.type, props.data, props.categories),
    }}
  />
);

SeoStructuredData.displayName = 'organisms/SeoStructuredData';

SeoStructuredData.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  categories: PropTypes.object,
};

SeoStructuredData.defaultProps = {
  data: {},
  categories: {},
};

export default SeoStructuredData;
