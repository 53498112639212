import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import language from 'constants/language/language';
import styles from './BrochureNavigationLink.scss';
import { trackMobileNavigationGoTo } from 'tracking/tracking';
import { NAVIGATION_CLICK } from 'constants/trackingAttributes/trackingAttributes';

const BrochureNavigationLink = ({ url, text, parentTitle, pos }) => (
  <Link
    className={styles.brochureLink}
    to={url}
    title={text || url}
    onClick={() => trackMobileNavigationGoTo(NAVIGATION_CLICK, pos, text, parentTitle)}
  >
    <span className={styles.brochurePercent}>{language('navigation.brochures.percent')}</span>
    {text || url}
  </Link>
);

BrochureNavigationLink.propTypes = {
  url: PropTypes.string,
  text: PropTypes.string,
  parentTitle: PropTypes.string,
  pos: PropTypes.number,
};

BrochureNavigationLink.defaultProps = {
  url: '',
  text: '',
};

BrochureNavigationLink.displayName = 'atoms/BrochureNavigationLink';

export default BrochureNavigationLink;
