import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import rootReducer from './rootReducer';
import promiseMiddleware from 'api/promiseMiddleware';
import trackingMiddleware from 'api/trackingMiddleware';

export default function configureStore(initialState = {}, history) {
  // Sync with router via history instance (main.js)
  const middleware = [thunk, promiseMiddleware, trackingMiddleware];
  // Installs hooks that always keep react-router and redux
  // store in sync
  const router = routerMiddleware(history);
  if (__DEV__ && __CLIENT__) {
    const { createLogger } = require('redux-logger');

    middleware.push(
      router,
      createLogger({
        duration: true,
        colors: {
          title: (action) => {
            if (action.type.indexOf('_FAILURE') > -1) return 'red';
            if (action.type.indexOf('_NETWORKERROR') > -1) return 'darkred';
            if (action.type.indexOf('_REQUEST') > -1) return 'gray';
            if (action.type.indexOf('UPDATE_LOCATION') > -1) return 'lightgray';
            if (action.type.indexOf('_SUCCESS') > -1) return 'DarkGreen';
            return 'black';
          },
        },
        // log only redux errors on console (uncomment to log all)
        predicate: (getState, action) => /_FAILURE|_NETWORKERROR/.test(action.type),
        collapsed: true,
      })
    );
    // middleware.push(router);
  } else {
    middleware.push(router);
  }

  let finalCreateStore;

  if (typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION__) {
    finalCreateStore = compose(applyMiddleware(...middleware), window.__REDUX_DEVTOOLS_EXTENSION__())(createStore);
  } else {
    finalCreateStore = compose(applyMiddleware(...middleware))(createStore);
  }

  const store = finalCreateStore(rootReducer, initialState);

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./rootReducer', () => {
      const nextReducer = require('./rootReducer').default;
      store.replaceReducer(nextReducer);
    });
  }

  return store;
}
