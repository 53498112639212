import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Spinner from 'components/atoms/Spinner/Spinner';
import Headline from 'components/atoms/Headline/Headline';
import { Col, Row } from 'components/atoms/Grid/Grid';
import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';
import { apiStatus } from 'constants/apiStatus/apiStatus';
import language, { getLocale } from 'constants/language/language';
import { cart } from 'constants/routePaths/routePathCombinations';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import styles from './MessageBadRequest.scss';
import { setBadRequestError } from 'actions/renderingActions/renderingActions';
import { NextFeatureLink } from 'components/atoms/NextFeatureLink/NextFeatureLink';

export const pageType = {
  productOverview: 'productOverview',
  productDetail: 'productDetail',
  jsDisabled: 'jsDisabled',
  cookiesDisabled: 'cookiesDisabled',
  general: 'general',
  checkout: 'checkout',
  placeOrder: 'placeOrder',
};

export const mapDispatchToProps = {
  setBadRequestState: setBadRequestError,
};

class MessageBadRequest extends Component {
  static displayName = 'molecules/MessageBadRequest';

  static propTypes = {
    apiStatus: PropTypes.oneOf(Object.values(apiStatus)).isRequired,
    pageType: PropTypes.string.isRequired,
    retryAction: PropTypes.func,
    setBadRequestState: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      spinnerState: 'FAILURE',
    };
  }

  componentWillUnmount = () => {
    clearTimeout(this.spinnerTimeout);
    this.spinnerTimeout = null;
  };

  getMessageContent = () => ({
    headline: language(`apiErrorMessages.${this.props.pageType}.${this.props.apiStatus}.headline`, ''),
    text: language(`apiErrorMessages.${this.props.pageType}.${this.props.apiStatus}.text`, ''),
  });

  handleRetry = () => {
    this.setState({
      spinnerState: 'REQUEST',
    });
    this.props.retryAction().then(() => {
      clearTimeout(this.spinnerTimeout);
      this.spinnerTimeout = setTimeout(() => {
        this.setState({
          spinnerState: 'FAILURE',
        });
      }, 500);
    });
  };

  render() {
    if (!__CLIENT__) {
      this.props.setBadRequestState();
    }
    const { headline, text } = this.getMessageContent();
    return (
      <Row>
        <Col offsetLg={4} lg={4}>
          <div className={styles.mainContainer}>
            <div className={styles.spinnerContainer}>
              <Spinner status={this.state.spinnerState} />
            </div>
            <Headline alignment={Headline.alignments.CENTER}>{headline}</Headline>
            <div className={styles.messageContainer}>
              <span>{text}</span>
            </div>
            {this.props.apiStatus === apiStatus.networkerror && this.props.pageType === pageType.checkout && (
              <div className={styles.retryContainer}>
                <ButtonLink variety={ButtonLink.varieties.stroked} onClick={this.handleRetry}>
                  {language('apiErrorMessages.retryButton')}
                </ButtonLink>
              </div>
            )}
            {this.props.pageType === pageType.checkout && (
              <NextFeatureLink feature="nextCartEnabled" to={mapPathToLocalizedUrl(getLocale(), cart)}>
                {language('checkout.header.backToCart')}
              </NextFeatureLink>
            )}
          </div>
        </Col>
      </Row>
    );
  }
}

export default connect(null, mapDispatchToProps)(MessageBadRequest);
