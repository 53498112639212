import { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { videoAvailable as videoAvailableAction } from 'actions/productActions/productActions';

export const mapDispatchToProps = {
  videoAvailable: videoAvailableAction,
};

const ProductVideo = ({ children, videos, code, videoAvailable }) => {
  const handleVideoAvailable = () => {
    videoAvailable(code);
  };

  return cloneElement(children, {
    onVideoAvailable: handleVideoAvailable,
    productVideoAvailable: videos.length > 0,
    videos,
  });
};

ProductVideo.displayName = 'containers/ProductVideo';

ProductVideo.propTypes = {
  children: PropTypes.node.isRequired,
  videoAvailable: PropTypes.func,
  code: PropTypes.string,
  videos: PropTypes.array,
};

export default connect(null, mapDispatchToProps)(ProductVideo);
