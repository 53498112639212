import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import cx from 'classnames';
import { getEnumValues } from 'constants/GetEnumValues/GetEnumValues';
import styles from './CmsLinkItem.scss';

export const skins = {
  DEFAULT: 'DEFAULT',
  METANAVIGATION: 'METANAVIGATION',
  MOBILENAVIGATION: 'MOBILENAVIGATION',
};

const CmsLinkItem = ({ url, text, target, customText, skin, className, anchor }) => {
  if (anchor && url) {
    url = url.concat('#', anchor);
  }

  return (
    <Link
      to={url}
      title={text || url}
      target={target}
      rel={target === '_blank' ? 'noopener' : ''}
      className={cx(
        {
          [styles.metaNavigation]: skin === skins.METANAVIGATION,
          [styles.mobileNavigation]: skin === skins.MOBILENAVIGATION,
        },
        className
      )}
    >
      {customText || text || url}
    </Link>
  );
};

CmsLinkItem.displayName = 'atoms/CmsLinkItem';

CmsLinkItem.propTypes = {
  url: PropTypes.string,
  text: PropTypes.string,
  customText: PropTypes.string,
  target: PropTypes.string,
  skin: PropTypes.oneOf(getEnumValues(skins)),
  className: PropTypes.string,
  anchor: PropTypes.string,
};

CmsLinkItem.defaultProps = {
  text: '',
  target: '',
  skin: skins.DEFAULT,
};

export default CmsLinkItem;
