import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import language from 'constants/language/language';
import ProductComparisonListHeader from './ProductComparisonListHeader';
import ProductComparisonOverviewHeader from './ProductComparisonOverviewHeader';
import styles from './ProductComparisonHeader.scss';
import { cypressAttributes } from 'constants/cypress/cypress';

export const skins = {
  OVERVIEW: 'OVERVIEW',
  LIST: 'LIST',
};

const ProductComparisonHeader = ({
  products = [],
  selectedCategory = '',
  categories = {},
  skin = skins.OVERVIEW,
  changeCategory = () => {},
  removeFromComparison = () => {},
  showError = false,
  getComparisonList = () => {},
  productCodes = [],
  comparisonCategories = {},
  addToWatchlist = () => {},
  removeFromWatchlist = () => {},
  isProductInWatchlist = () => {},
}) => {
  if (productCodes.length === 0) {
    return null;
  }

  return (
    <div className={styles.container} data-cy={cypressAttributes.product.comparisonHeader}>
      <div className={styles.comparison}>
        {skin === skins.OVERVIEW && (
          <ProductComparisonOverviewHeader
            products={products}
            categoryCode={selectedCategory}
            removeFromComparison={removeFromComparison}
            showError={showError}
            getComparisonList={getComparisonList}
            productCodes={productCodes}
          />
        )}
        {skin === skins.LIST && (
          <ProductComparisonListHeader
            products={products}
            categories={categories}
            selectedCategory={selectedCategory}
            removeFromComparison={removeFromComparison}
            changeCategory={changeCategory}
            getComparisonList={getComparisonList}
            productCodes={productCodes}
            addToWatchlist={addToWatchlist}
            removeFromWatchlist={removeFromWatchlist}
            isProductInWatchlist={isProductInWatchlist}
            comparisonCategories={comparisonCategories}
          />
        )}
      </div>
      {showError && (
        <div className={cx(styles.error)} data-cy={cypressAttributes.product.comparisonHeaderError}>
          {language('productComparison.errorText')}
        </div>
      )}
    </div>
  );
};

ProductComparisonHeader.displayName = 'molecules/ProductComparisonHeader';
ProductComparisonHeader.propTypes = {
  products: PropTypes.array,
  categories: PropTypes.object,
  selectedCategory: PropTypes.string,
  skin: PropTypes.oneOf(Object.values(skins)),
  changeCategory: PropTypes.func,
  removeFromComparison: PropTypes.func,
  showError: PropTypes.bool,
  getComparisonList: PropTypes.func,
  productCodes: PropTypes.array,
  addToWatchlist: PropTypes.func,
  removeFromWatchlist: PropTypes.func,
  isProductInWatchlist: PropTypes.func,
  comparisonCategories: PropTypes.object,
};

export default ProductComparisonHeader;
