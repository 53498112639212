import { LoginValidationTypes } from 'constants/loginValidation/loginValidation';
import {
  REHYDRATE_GUEST_ADDRESSES,
  UPDATE_GUEST_EMAIL,
  UPDATE_PASSWORD_FIELD,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGIN_USER_NETWORKERROR,
  LOGIN_USER_REQUEST,
  BLUR_EMAIL_FIELD,
  SUBSCRIBE_NEWSLETTER,
  CHANGE_GUEST_SUCCESS,
  CHANGE_GUEST_FAILURE,
  CHANGE_GUEST_NETWORKERROR,
  PLACE_ORDER_SUCCESS,
  GET_CURRENT_DELIVERY_MODE_SUCCESS,
  LOGOUT_USER,
  CHECK_TOKEN_SUCCESS,
  FORGOTTEN_PASSWORD_GENERATE_TOKEN_SUCCESS,
  FORGOTTEN_PASSWORD_GENERATE_TOKEN_FAILURE,
  INITIALIZE_FORGOTTEN_PASSWORD_GENERATE_TOKEN,
  FORGOTTEN_PASSWORD_RESTORE_PASSWORD_FAILURE,
  FORGOTTEN_PASSWORD_RESTORE_PASSWORD_SUCCESS,
  FORGOTTEN_PASSWORD_RESTORE_PASSWORD_REQUEST,
  FORGOTTEN_PASSWORD_RESTORE_PASSWORD_NETWORKERROR,
  CHANGE_PERSONAL_DATA,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_LOGIN_SUCCESS,
  CHANGE_LOGIN_FAILURE,
  BLUR_PERSONAL_DATA_FIELD,
  BLUR_PASSWORD_FIELD,
  RESET_USER,
  ROUTER_ENTER_ROUTE,
  CREATE_USER_SUCCESS,
  CREATE_USER_REQUEST,
  CREATE_USER_FAILURE,
  CREATE_USER_NETWORKERROR,
  CHANGE_CUSTOMER_TYPE,
  UNSUBSCRIBE_NEWSLETTER_FAILURE,
  UNSUBSCRIBE_NEWSLETTER_SUCCESS,
  UNSUBSCRIBE_NEWSLETTER_NETWORKERROR,
  SUBSCRIBE_TO_NEWSLETTER_FAILURE,
  SUBSCRIBE_TO_NEWSLETTER_SUCCESS,
  SUBSCRIBE_TO_NEWSLETTER_NETWORKERROR,
  SUBSCRIBE_TO_NEWSLETTER_REQUEST,
  COMPLETE_USER_DATA_FAILURE,
  COMPLETE_USER_DATA_SUCCESS,
  COMPLETE_USER_DATA_NETWORKERROR,
  VERIFY_NEWSLETTER_TOKEN,
  VERIFY_NEWSLETTER_TOKEN_FAILURE,
  VERIFY_NEWSLETTER_TOKEN_SUCCESS,
  VERIFY_NEWSLETTER_TOKEN_NETWORKERROR,
  RESET_LOGIN_NOTIFICATION,
  GET_EMAIL_FROM_HASH_SUCCESS,
  GET_EMAIL_FROM_HASH_REQUEST,
  GET_EMAIL_FROM_HASH_FAILURE,
  GET_EMAIL_FROM_HASH_NETWORKERROR,
  VERIFY_RECAPTCHA_SUCCESS,
  VERIFY_RECAPTCHA_REQUEST,
  VERIFY_RECAPTCHA_FAILURE,
  VERIFY_RECAPTCHA_NETWORKERROR,
  VERIFY_MAIL_SUCCESS,
  NEWSLETTER_OPTIN_REQUEST,
  NEWSLETTER_OPTIN_SUCCESS,
  NEWSLETTER_OPTIN_FAILURE,
  NEWSLETTER_OPTIN_NETWORKERROR,
  UPDATE_BIRTHDAY_FIELD,
  BLUR_BIRTHDAY_FIELD,
  VERIFY_EMAIL_TOKEN_SUCCESS,
  VERIFY_EMAIL_TOKEN_FAILURE,
  UPDATE_AGB_FIELD,
} from 'constants/ActionTypes/ActionTypes';

import transform from 'constants/reducerHelper/reducerHelper';
import messageMapping from 'constants/messageMapping/messageMapping';
import validation, { ValidationTypes } from 'constants/validation/validation';
import getStorage from 'constants/storage/storage';
import { apiStatus } from 'constants/apiStatus/apiStatus';
import { customerGroup } from 'constants/customerGroup/customerGroup';
import { routeHelperKeys } from 'routes/routeHelpers';

// @todo reducers should be pure functions!
const storage = getStorage(true);

export const initialState = {
  loginValidation: ValidationTypes.Valid,
  uid: 'anonymous',
  onlineId: '',
  fields: {
    email: {
      name: 'email',
      validationResult: ValidationTypes.Missing,
      required: true,
    },
  },
  toUpdate: {
    email: {
      name: 'email',
      validationResult: ValidationTypes.Missing,
      required: true,
    },
    password: {
      name: 'password',
      validationResult: ValidationTypes.Missing,
      required: true,
    },
    birthday: {
      name: 'birthday',
      validationResult: ValidationTypes.Missing,
      required: true,
    },
    oldPassword: {
      name: 'oldPassword',
      validationResult: ValidationTypes.Missing,
      required: true,
    },
    newPassword: {
      name: 'newPassword',
      validationResult: ValidationTypes.Missing,
      required: true,
    },
    language: {
      name: 'language',
      validationResult: ValidationTypes.Valid,
      required: false,
    },
  },
  consentsData: {},
};

export const getPersonalDataValidationConfiguration = () => ({
  titleCode: {
    validation: validation.required,
  },
  firstName: {
    validation: validation.required,
  },
  lastName: {
    validation: validation.required,
  },
  supercardCode: {
    validation: validation.supercardCode,
  },
  employeeEan: {
    validation: validation.employeeEan,
  },
  birthday: {
    validation: validation.birthday,
  },
  email: {
    validation: validation.emailRequired,
  },
  password: {
    validation: validation.required,
  },
  oldPassword: {
    validation: validation.required,
  },
  newPassword: {
    validation: validation.password,
  },
  language: {
    validation: validation.required,
  },
  // group: {
  //   validation: validation.required,
  // },
});

const getPersonalDataValidation = (key, newField, value, state) => {
  const validationFunction = getPersonalDataValidationConfiguration()?.[key]?.validation;

  if (typeof validationFunction === 'function') {
    if (key === 'newPassword') {
      return validationFunction(value, state.uid);
    } else {
      return validationFunction(value);
    }
  }

  return undefined;
};

const reducerActions = {
  [ROUTER_ENTER_ROUTE]: (state, action) => {
    const keys = Object.values(routeHelperKeys);
    if (!keys.includes(action.payload?.key)) {
      return state;
    }
    // email value will be used for order confirmation site
    const emailValue = state?.fields?.email?.value;
    const emailValidationResult =
      validation.required(emailValue) === ValidationTypes.Valid
        ? validation.email(emailValue)
        : ValidationTypes.Missing;

    const newState = transform(state);

    newState.set('valid', false);
    newState.set('firstError', { name: 'email' });
    newState.set('forceValidation', false);

    const supercardCode = state?.fields?.supercardCode?.value;
    const employeeEan = state?.fields?.employeeEan?.value;

    newState.set('fields.email', {
      name: 'email',
      value: emailValue,
      validationResult: emailValidationResult,
      required: true,
      blurred: false,
    });

    newState.set('fields.password', {
      name: 'password',
      value: undefined,
      validationResult: ValidationTypes.Missing,
      required: true,
      blurred: false,
    });

    newState.set('fields.supercardCode', {
      name: 'supercardCode',
      value: supercardCode,
      validationResult: ValidationTypes.Initial,
      required: false,
    });

    newState.set('fields.employeeEan', {
      name: 'employeeEan',
      value: employeeEan,
      validationResult: ValidationTypes.Initial,
      required: false,
    });

    if (action.payload.key === 'account_registration') {
      // initialize fields for registration
      newState.set('fields.group', {
        name: 'group',
        value: customerGroup.CUSTOMERGROUP,
        required: false,
      });

      newState.set('fields.email', {
        name: 'email',
        validationResult: ValidationTypes.Missing,
        required: true,
        blurred: false,
      });

      newState.set('fields.birthday', {
        name: 'birthday',
        validationResult: ValidationTypes.Missing,
        required: true,
        blurred: false,
      });

      newState.set('fields.newsletter', {
        name: 'newsletter',
        value: false,
        required: false,
      });

      newState.set('fields.acceptAGBs', {
        name: 'acceptAGBs',
        value: false,
        blurred: false,
        validationResult: ValidationTypes.Valid,
        required: true,
      });

      // @todo why twice?
      newState.set('fields.supercardCode', {
        name: 'supercardCode',
        validationResult: ValidationTypes.Initial,
        required: false,
      });
    }

    return newState.value();
  },

  [RESET_USER]: () => initialState,

  [UPDATE_GUEST_EMAIL]: (state, action) => {
    const value = action.payload?.value;
    const passwordValue = state?.fields?.password?.value;
    let validationResult = validation.required(value); // email not empty
    if (validationResult === ValidationTypes.Valid) {
      validationResult = validation.email(value); // valid email address
    }

    if (passwordValue !== '') {
      return transform(state)
        .set('fields.email.validationResult', validationResult)
        .set('fields.password.validationResult', validation.password(passwordValue, value))
        .set('fields.email.value', value)
        .value();
    }

    return transform(state)
      .set('fields.email.validationResult', validationResult)
      .set('fields.email.value', value)
      .value();
  },

  [UPDATE_AGB_FIELD]: (state) => {
    const val = !state.fields.acceptAGBs.value;

    return transform(state)
      .set(`fields.acceptAGBs.value`, val)
      .set(`fields.acceptAGBs.valid`, val)
      .set(`fields.acceptAGBs.blurred`, true)
      .value();
  },

  [BLUR_EMAIL_FIELD]: (state) => {
    const errorTypes = [
      ValidationTypes.Missing,
      ValidationTypes.TakenEmail,
      ValidationTypes.PasswordToWeak,
      ValidationTypes.Invalid,
      ValidationTypes.InvalidNot16,
    ];
    const fields = state.fields;
    const firstErrorKey = Object.keys(fields).find((key) => errorTypes.includes(fields[key].validationResult));
    const firstError = fields[firstErrorKey];

    return transform(state)
      .set('fields.email.blurred', true)
      .set('firstError', firstError)
      .set('valid', !Object.keys(firstError || {}).length)
      .value();
  },

  [BLUR_PASSWORD_FIELD]: (state) => {
    const errorTypes = [
      ValidationTypes.Missing,
      ValidationTypes.TakenEmail,
      ValidationTypes.PasswordToWeak,
      ValidationTypes.Invalid,
      ValidationTypes.InvalidNot16,
      ValidationTypes.SameAsEmail,
    ];
    const fields = state.fields;
    const firstErrorKey = Object.keys(fields).find((key) => errorTypes.includes(fields[key].validationResult));
    const firstError = fields[firstErrorKey];

    return transform(state)
      .set('fields.password.blurred', true)
      .set('firstError', firstError)
      .set('valid', !Object.keys(firstError || {}).length)
      .value();
  },

  [UPDATE_PASSWORD_FIELD]: (state, action) => {
    const value = action.payload?.value;
    const email = action.payload?.email;

    const newState = transform(state)
      .set('fields.password.validationResult', validation.password(value, email))
      .set('fields.password.value', value);

    const errorTypes = [
      ValidationTypes.Missing,
      ValidationTypes.TakenEmail,
      ValidationTypes.PasswordToWeak,
      ValidationTypes.Invalid,
      ValidationTypes.InvalidNot16,
      ValidationTypes.SameAsEmail,
    ];
    const fields = newState.value().fields;
    const firstErrorKey = Object.keys(fields).find((key) => errorTypes.includes(fields[key].validationResult));
    const firstError = fields[firstErrorKey];

    return newState
      .set('firstError', firstError)
      .set('valid', !!Object.keys(firstError || {}).length)
      .value();
  },

  [BLUR_BIRTHDAY_FIELD]: (state, action) => {
    const f = action.options?.useToUpdate ? 'toUpdate' : 'fields';

    return transform(state).set(`${f}.birthday.blurred`, true).value();
  },

  [UPDATE_BIRTHDAY_FIELD]: (state, action) => {
    const f = action.options?.useToUpdate ? 'toUpdate' : 'fields';
    const value = action.payload?.value;

    let validationResult = validation.required(value);
    if (validationResult === ValidationTypes.Valid) {
      validationResult = validation.birthday(value);
    }

    const newState = transform(state)
      .set(`${f}.birthday.validationResult`, validationResult)
      .set(`${f}.birthday.value`, value);

    const errorTypes = [
      ValidationTypes.Missing,
      ValidationTypes.TakenEmail,
      ValidationTypes.PasswordToWeak,
      ValidationTypes.Invalid,
      ValidationTypes.InvalidNot16,
    ];
    const fields = newState.value()[f] || {};
    const firstErrorKey = Object.keys(fields).find((key) => errorTypes.includes(fields[key].validationResult));
    const firstError = fields[firstErrorKey];

    return newState
      .set(`${f}.birthday.value`, value)
      .set('firstError', firstError)
      .set('valid', !Object.keys(firstError || {}).length)
      .value();
  },

  [REHYDRATE_GUEST_ADDRESSES]: (state, action) => {
    const email = action.payload?.email;
    if (!email) {
      return state;
    }
    return transform(state).set('fields.email', email).value();
  },

  [CHANGE_GUEST_SUCCESS]: (state, action) => {
    const newState = transform(state);

    const allowed = action.req?.data?.allowed;
    if (allowed === true) {
      newState.set('fields.email.validationResult', ValidationTypes.Valid);
      storage.setItem('email', JSON.stringify(state?.fields?.email));
    } else if (allowed === false) {
      newState.set('fields.email.validationResult', ValidationTypes.TakenEmail);
    }

    return newState.value();
  },

  [CHANGE_GUEST_NETWORKERROR]: (state) => {
    const newState = transform(state);
    newState.set('fields.email.validationResult', ValidationTypes.Pending);
    return newState.value();
  },

  [CHANGE_GUEST_FAILURE]: (state) => {
    const newState = transform(state);
    newState.set('fields.email.validationResult', ValidationTypes.Pending);
    return newState.value();
  },

  [LOGIN_USER_SUCCESS]: (state, action) => ({
    ...state,
    uid: action.payload?.username,
    onlineId: action.payload?.onlineId,
    loginValidation: ValidationTypes.Valid,
    apiStatus: apiStatus.success,
  }),

  [LOGIN_USER_REQUEST]: (state) => ({
    ...state,
    apiStatus: apiStatus.request,
  }),

  [LOGIN_USER_FAILURE]: (state, action) => {
    const errorDescription = action.error?.data?.['error_description'] || LoginValidationTypes.BadCredentials;
    return {
      ...state,
      loginValidation: errorDescription,
      apiStatus: apiStatus.failure,
      recaptchaNeeded: errorDescription === 're-captcha',
      resetCaptcha: errorDescription === 're-captcha',
    };
  },

  [LOGIN_USER_NETWORKERROR]: (state) => ({
    ...state,
    loginValidation: ValidationTypes.NetworkError,
    apiStatus: apiStatus.networkerror,
  }),

  [RESET_LOGIN_NOTIFICATION]: (state) => ({
    ...state,
    loginValidation: ValidationTypes.Valid,
  }),

  // TODO: use API payload to trigger subscribe
  [SUBSCRIBE_NEWSLETTER]: (state) =>
    transform(state).set('fields.newsletter.value', !state?.fields?.newsletter?.value).value(),

  [UNSUBSCRIBE_NEWSLETTER_SUCCESS]: (state, action) => ({
    ...state,
    newsletterApiStatus: apiStatus.success,
    newsletterResponse: action.req?.data?.result,
    newsletterUID: '',
  }),

  [UNSUBSCRIBE_NEWSLETTER_FAILURE]: (state) => ({
    ...state,
    newsletterApiStatus: apiStatus.failure,
  }),

  [UNSUBSCRIBE_NEWSLETTER_NETWORKERROR]: (state) => ({
    ...state,
    newsletterApiStatus: apiStatus.networkerror,
  }),

  [PLACE_ORDER_SUCCESS]: (state, action) => {
    // prepare fields for anonymous order confirmation
    const guid = action.req?.data?.guid;
    const email = state?.fields?.email?.value;
    if (guid) {
      return transform(state).set('fields.email', { value: email }).set('fields.password', {}).value();
    }

    // prepare fields for customer order confirmation
    const user = action.req?.data?.user;
    if (user) {
      return transform(state).set('fields.email', { value: user.uid }).value();
    }

    return state;
  },

  [GET_CURRENT_DELIVERY_MODE_SUCCESS]: (state, action) => {
    const pointOfService = action.req?.data?.pointOfServiceId;

    if (pointOfService) {
      return transform(state).set('pointOfService', pointOfService).value();
    }

    return state;
  },

  [LOGOUT_USER]: (state) => transform(state).set('user.loginValidation', false).value(),

  [CHECK_TOKEN_SUCCESS]: (state, action) => ({
    ...state,
    uid: action.req?.['user_name'] || state?.uid,
  }),

  [CHANGE_PERSONAL_DATA]: (state, action) => {
    const key = action.payload?.key;
    if (!key) {
      return state;
    }
    const value = action.payload?.value;

    let newField = state?.toUpdate?.[key] || {};
    newField = { ...newField };

    newField.validationResult = getPersonalDataValidation(key, newField, value, state);

    newField.value = value;

    return transform(state).set(`toUpdate.${key}`, newField).value();
  },

  [BLUR_PERSONAL_DATA_FIELD]: (state, action) => {
    const key = action.payload?.key;
    if (!key) {
      return state;
    }
    const value = action.payload?.value;

    let newField = state?.toUpdate?.[key] || {};
    newField = { ...newField };

    newField.validationResult = getPersonalDataValidation(key, newField, value, state);

    newField.value = value;
    newField.blurred = true;

    return transform(state).set(`toUpdate.${key}`, newField).value();
  },

  [CHANGE_PASSWORD_SUCCESS]: (state) => ({
    ...state,
    password: '',
    newPassword: '',
  }),

  [CHANGE_PASSWORD_FAILURE]: (state, action) =>
    transform(state)
      .set(
        'toUpdate.oldPassword',
        messageMapping(state?.toUpdate?.oldPassword || {}, action.error?.data?.errors || [], 'PasswordMismatchError')
      )
      .value(),

  [CHANGE_LOGIN_SUCCESS]: (state) =>
    transform(state).set('loginNotification.success', true).set('loginNotification.fieldKey', 'Login').value(),

  [CHANGE_LOGIN_FAILURE]: (state, action) => {
    const newState = transform(state)
      .set('fields.email.validationResult', ValidationTypes.Valid)
      .set('fields.password.validationResult', ValidationTypes.Valid);
    return newState
      .set(
        'fields.email',
        messageMapping(newState.value()?.fields?.email || {}, action.error?.data?.errors || [], 'DuplicateUidError')
      )
      .set(
        'fields.password',
        messageMapping(
          newState.value()?.fields?.password || {},
          action.error?.data?.errors || [],
          'PasswordMismatchError'
        )
      )
      .value();
  },

  [INITIALIZE_FORGOTTEN_PASSWORD_GENERATE_TOKEN]: (state) => ({
    ...state,
    generateResetPasswordTokenSuccess: undefined,
  }),

  [FORGOTTEN_PASSWORD_GENERATE_TOKEN_SUCCESS]: (state) => ({
    ...state,
    generateResetPasswordTokenSuccess: apiStatus.success,
  }),

  [FORGOTTEN_PASSWORD_GENERATE_TOKEN_FAILURE]: (state, action) => ({
    ...state,
    generateResetPasswordTokenSuccess: apiStatus.failure,
    passwordResetFailureMessage: action.error?.data?.errors?.[0]?.message,
  }),

  [FORGOTTEN_PASSWORD_RESTORE_PASSWORD_FAILURE]: (state, action) => ({
    ...state,
    restorePasswordStatus: apiStatus.failure,
    restorePasswordFailureMessage: action?.error?.data?.errors?.[0]?.message,
  }),

  [FORGOTTEN_PASSWORD_RESTORE_PASSWORD_SUCCESS]: (state) => ({
    ...state,
    restorePasswordStatus: apiStatus.success,
  }),

  [FORGOTTEN_PASSWORD_RESTORE_PASSWORD_REQUEST]: (state) => ({
    ...state,
    restorePasswordStatus: apiStatus.request,
  }),

  [FORGOTTEN_PASSWORD_RESTORE_PASSWORD_NETWORKERROR]: (state) => ({
    ...state,
    restorePasswordStatus: apiStatus.networkerror,
  }),

  [CREATE_USER_SUCCESS]: (state) => ({
    ...state,
    apiStatus: apiStatus.success,
  }),

  [CREATE_USER_REQUEST]: (state) => ({
    ...state,
    apiStatus: apiStatus.request,
  }),

  [CREATE_USER_FAILURE]: (state, action) => {
    const newState = transform(state);
    const erroredFields = action.error?.data?.errors || [];
    erroredFields.forEach((field) => {
      newState
        .set(`fields.${field.subject}.validationResult`, ValidationTypes.Invalid)
        .set('apiStatus', apiStatus.failure);
    });
    return newState.value();
  },

  [CREATE_USER_NETWORKERROR]: (state) => ({
    ...state,
    apiStatus: apiStatus.networkerror,
  }),

  [SUBSCRIBE_TO_NEWSLETTER_SUCCESS]: (state, action) => ({
    ...state,
    isRegisteredCustomer: action.req?.data?.registeredCustomer,
    newsletterStatus: action.req?.data?.result,
    newsletterApiStatus: apiStatus.success,
    newsletterEmail: action.payload?.email,
  }),

  [SUBSCRIBE_TO_NEWSLETTER_FAILURE]: (state) => ({
    ...state,
    newsletterApiStatus: apiStatus.failure,
  }),

  [SUBSCRIBE_TO_NEWSLETTER_NETWORKERROR]: (state) => ({
    ...state,
    newsletterApiStatus: apiStatus.networkerror,
  }),
  [SUBSCRIBE_TO_NEWSLETTER_REQUEST]: (state) => ({
    ...state,
    newsletterApiStatus: apiStatus.request,
  }),
  [NEWSLETTER_OPTIN_SUCCESS]: (state, action) => ({
    ...state,
    newsletterStatus: action.req?.data?.status,
    newsletterApiStatus: apiStatus.success,
  }),
  [NEWSLETTER_OPTIN_REQUEST]: (state) => ({
    ...state,
    newsletterApiStatus: apiStatus.request,
  }),
  [NEWSLETTER_OPTIN_FAILURE]: (state) => ({
    ...state,
    newsletterApiStatus: apiStatus.failure,
  }),
  [NEWSLETTER_OPTIN_NETWORKERROR]: (state) => ({
    ...state,
    newsletterApiStatus: apiStatus.networkerror,
  }),
  [COMPLETE_USER_DATA_SUCCESS]: (state) => ({
    ...state,
    newsletterApiStatus: apiStatus.success,
  }),

  [COMPLETE_USER_DATA_FAILURE]: (state) => ({
    ...state,
    newsletterApiStatus: apiStatus.failure,
  }),

  [COMPLETE_USER_DATA_NETWORKERROR]: (state) => ({
    ...state,
    newsletterApiStatus: apiStatus.networkerror,
  }),

  [VERIFY_NEWSLETTER_TOKEN_SUCCESS]: (state, action) => ({
    ...state,
    isRegisteredCustomer: action.req?.data?.registeredCustomer,
    verificationResult: action.req?.data?.result,
    newsletterApiStatus: apiStatus.success,
    newsletterToken: action.payload?.token,
  }),

  [VERIFY_NEWSLETTER_TOKEN_FAILURE]: (state) => ({
    ...state,
    newsletterApiStatus: apiStatus.failure,
  }),

  [VERIFY_NEWSLETTER_TOKEN_NETWORKERROR]: (state) => ({
    ...state,
    newsletterApiStatus: apiStatus.networkerror,
  }),

  [VERIFY_NEWSLETTER_TOKEN]: (state, action) => ({
    ...state,
    newsletterToken: action.payload?.token || '',
  }),

  [GET_EMAIL_FROM_HASH_REQUEST]: (state) => ({
    ...state,
    newsletterApiStatus: apiStatus.request,
  }),

  [GET_EMAIL_FROM_HASH_FAILURE]: (state) => ({
    ...state,
    newsletterApiStatus: apiStatus.failure,
  }),

  [GET_EMAIL_FROM_HASH_NETWORKERROR]: (state) => ({
    ...state,
    newsletterApiStatus: apiStatus.networkerror,
  }),

  [GET_EMAIL_FROM_HASH_SUCCESS]: (state, action) => ({
    ...state,
    newsletterApiStatus: apiStatus.success,
    newsletterUID: action.req?.data?.customerUid || '',
  }),

  [VERIFY_EMAIL_TOKEN_SUCCESS]: (state, action) => ({
    ...state,
    verificationResult: action.req?.data?.result,
    // BE sends email on SUCCESS in data.uid and on EXPIRED in data.customerUid
    tokenMail: action?.req?.data?.uid || action?.req?.data?.customerUid,
  }),

  [VERIFY_EMAIL_TOKEN_FAILURE]: (state, action) => ({
    ...state,
    verificationResult: action.req?.data?.result,
  }),

  [CHANGE_CUSTOMER_TYPE]: (state, action) => transform(state).set('fields.group.value', action.payload).value(),

  [VERIFY_RECAPTCHA_SUCCESS]: (state, action) => ({
    ...state,
    recaptchaNeeded: !action.req?.data?.success,
    resetCaptcha: !action.req?.data?.success,
    apiStatus: apiStatus.success,
  }),
  [VERIFY_RECAPTCHA_REQUEST]: (state) => ({
    ...state,
    resetCaptcha: false,
    apiStatus: apiStatus.request,
  }),
  [VERIFY_RECAPTCHA_FAILURE]: (state) => ({
    ...state,
    resetCaptcha: true,
    recaptchaNeeded: true,
    apiStatus: apiStatus.failure,
  }),
  [VERIFY_RECAPTCHA_NETWORKERROR]: (state) => ({
    ...state,
    resetCaptcha: true,
    recaptchaNeeded: true,
    apiStatus: apiStatus.networkerror,
  }),
  [VERIFY_MAIL_SUCCESS]: (state, action) => {
    const newState = transform(state);
    const allowed = action.req?.data?.allowed ?? false;

    if (allowed) {
      newState.set('fields.email.validationResult', ValidationTypes.Valid);
      storage.setItem('email', JSON.stringify(action.req?.data?.email || ''));
    } else {
      newState.set('fields.email.validationResult', ValidationTypes.TakenEmail);
    }

    return newState.value();
  },
};

export default reducerActions;
