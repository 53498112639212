import { getEnv } from 'config/config';

import omit from 'constants/helper/omit';
import { getLocale } from 'constants/language/language';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import { PAYMENT, PAYMENT_SUCCESS, PAYMENT_CANCEL, PAYMENT_ERROR } from 'constants/routePaths/routePaths';
import CartAPI from 'api/CartAPI/CartAPI';

import { MFGROUP_UUID, ORDER_CODE, LAST_CART_ID, GUEST_EMAIL } from 'constants/storage/storageKeys';
import getStorage from 'constants/storage/storage';
const localStorage = getStorage(true);

const createCallbackURL = (tail) => {
  if (window?.location?.origin) {
    return `${window.location.origin}${tail}`;
  }
  return getEnv('web') + tail;
};

const config = {
  theme: 'Inline',
  uppWebResponseMethod: 'POST',
  get successUrl() {
    const path = mapPathToLocalizedUrl(getLocale(), [PAYMENT, PAYMENT_SUCCESS]);
    return createCallbackURL(path);
  },
  get cancelUrl() {
    const path = mapPathToLocalizedUrl(getLocale(), [PAYMENT, PAYMENT_CANCEL]);
    return createCallbackURL(path);
  },
  get errorUrl() {
    const path = mapPathToLocalizedUrl(getLocale(), [PAYMENT, PAYMENT_ERROR]);
    return createCallbackURL(path);
  },
};

// TODO: this can be removed when we fully move to the datatrans v2 api
export const getDatatransURL = (tempDatatransAuthorisation) => {
  const datatransAuthorisation = { ...tempDatatransAuthorisation };
  // populate correct datatrans values
  datatransAuthorisation.refno = datatransAuthorisation.orderCode;
  datatransAuthorisation.reqtype = datatransAuthorisation.requestType;

  // populate user information for datatrans
  datatransAuthorisation.uppCustomerDetails = datatransAuthorisation.customer.details;
  datatransAuthorisation.uppCustomerFirstName = datatransAuthorisation.customer.firstName;
  datatransAuthorisation.uppCustomerLastName = datatransAuthorisation.customer.lastName;
  datatransAuthorisation.uppCustomerZipCode = datatransAuthorisation.customer.zipCode;
  datatransAuthorisation.uppCustomerGender = datatransAuthorisation.customer.gender;
  datatransAuthorisation.uppCustomerEmail = datatransAuthorisation.customer.email;
  datatransAuthorisation.uppCustomerBirthDate = datatransAuthorisation.customer.birthDay;
  datatransAuthorisation.uppCustomerCity = datatransAuthorisation.customer.city;
  datatransAuthorisation.uppCustomerPhone = datatransAuthorisation.customer.phone;
  datatransAuthorisation.uppCustomerStreet = datatransAuthorisation.customer.street;
  datatransAuthorisation.uppCustomerLanguage = datatransAuthorisation.customer.language;
  datatransAuthorisation.uppCustomerCountry = datatransAuthorisation.customer.country;
  datatransAuthorisation.uppForwardCustomerDetails = datatransAuthorisation.customer.forwardCustomerDetails;
  datatransAuthorisation.uppDisplayShippingDetails = datatransAuthorisation.customer.displayShippingDetails;

  // Only send company (customerName) when Paycard OR monthly invoice is used (SWAT-5654) and set customer type to C (SWAT-6374)
  if (datatransAuthorisation.paymentMethod === 'MPX' || datatransAuthorisation.paymentMethod === 'MFX') {
    datatransAuthorisation.uppCustomerName = datatransAuthorisation.customer.customerName ?? null;
    datatransAuthorisation.uppCustomerType = datatransAuthorisation.customer.customerName ? 'C' : 'P';
  }

  if (typeof datatransAuthorisation.rememberMe !== 'undefined') {
    datatransAuthorisation.uppRememberMe = datatransAuthorisation.rememberMe;
  }

  // if alias is set
  if (Object.keys(datatransAuthorisation.paymentAliasInfo || {}).length) {
    datatransAuthorisation.useAlias = 'yes';
    datatransAuthorisation.aliasCC = datatransAuthorisation.paymentAliasInfo.aliasCC;
    datatransAuthorisation.expm = datatransAuthorisation.paymentAliasInfo.expiryMonth;
    datatransAuthorisation.expy = datatransAuthorisation.paymentAliasInfo.expiryYear;
  }

  // send user identifier to datatrans for mfgroup fraud detection
  datatransAuthorisation.refno2 = localStorage.getItem(MFGROUP_UUID);

  // will be used for graylog analysis
  datatransAuthorisation.uppCartId = CartAPI.getCartId();

  // extract the base url from the auth object
  const baseURL = datatransAuthorisation.link;
  const datatransAuth = omit(datatransAuthorisation, [
    'link',
    'themeConfig',
    'customer',
    'orderCode',
    'requestType',
    'paymentAliasInfo',
    'rememberMe',
  ]);

  localStorage.setItem(LAST_CART_ID, CartAPI.getCartId());
  localStorage.setItem(GUEST_EMAIL, datatransAuthorisation.customer.email);
  localStorage.setItem(ORDER_CODE, datatransAuthorisation.orderCode);

  const requestParameters = {
    ...datatransAuth,
    paymentmethod: datatransAuth.paymentMethod,
    ...config,
    theme: 'DT2015',
    uppStartTarget: '_top',
  };

  return {
    baseURL,
    requestParameters,
  };
};

export const mapDatatransResponse = (datatransResponse) => ({
  requestType: datatransResponse.reqtype,
  paymentMethod: datatransResponse.pmethod,
  transactionID: datatransResponse.uppTransactionId,
  authorizationCode: datatransResponse.authorizationCode,
  acquirerAuthorizationCode: datatransResponse.acqAuthorizationCode,
  customerInfo: {
    firstName: datatransResponse.uppCustomerFirstName,
    lastName: datatransResponse.uppCustomerLastName,
    customerName: datatransResponse.uppCustomerName,
    gender: datatransResponse.uppCustomerGender,
    phone: datatransResponse.uppCustomerPhone,
    birthDate: datatransResponse.uppCustomerBirthDate,
    city: datatransResponse.uppCustomerCity,
    street: datatransResponse.uppCustomerStreet,
    postalCode: datatransResponse.uppCustomerZipCode,
    ipAddress: datatransResponse.uppCustomerIpAddress,
    email: datatransResponse.uppCustomerEmail,
  },
});

export default getDatatransURL;
