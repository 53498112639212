import React, { cloneElement, useEffect, useState } from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { noop } from 'constants/noop/noop';

import styles from './ButtonLink.scss';
import { NextFeatureLink } from '../NextFeatureLink/NextFeatureLink';

const colors = {
  red: 'red',
  black: 'black',
  blue: 'blue',
  white: 'white',
  supercard: 'supercard',
};

const sizes = {
  sm: 'sm',
  md: 'md',
  lg: 'lg',
};

const varieties = {
  basic: 'basic',
  flat: 'flat',
  stroked: 'stroked',
  icon: 'icon',
};

const ButtonLink = ({
  children,
  color = colors.black,
  disabled = false,
  stretched = false,
  onClick = noop,
  selected = false,
  size = sizes.sm,
  type = 'button',
  variety = varieties.flat,
  id = undefined,
  href = '',
  openNewTab = false,
  isExternalLink = false,
  customTarget = undefined,
  outsideRouter = false,
  cyData,
  featureName = null,
  ...props
}) => {
  const [ripples, setRipples] = useState([]);
  const ripplesHandler = (e, callback) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    setRipples((prevRipples) => [...prevRipples, { x, y }]);
    callback(e);
  };

  useEffect(() => {
    const timeoutHandler = setTimeout(() => {
      if (ripples.length > 0) {
        setRipples(ripples.filter((prevRipple, index) => index !== ripples.length - 1));
      }
    }, 1000);
    return () => clearTimeout(timeoutHandler);
  }, [ripples]);

  const getProperties = () => {
    let properties = {
      id,
      className: cx(
        styles.button,
        styles[size],
        styles[variety],
        styles[color],
        { [styles.disabled]: disabled },
        { [styles.active]: selected },
        { [styles.stretched]: stretched }
      ),
      onClick: disabled
        ? noop
        : (e) => {
            ripplesHandler(e, onClick);
          },
      ...(disabled && { tabIndex: '-1' }),
      disabled,
      ['data-cy']: cyData,
      ...props,
    };
    if (outsideRouter) {
      properties = {
        ...properties,
        href,
        type,
      };
    } else if (href) {
      properties = {
        ...properties,
        to: href,
        target: customTarget ?? (openNewTab || isExternalLink ? '_blank' : ''),
        tabIndex: '0',
      };
    } else {
      properties = {
        ...properties,
        type,
      };
    }

    return properties;
  };

  // eslint-disable-next-line
  let Tag = href ? <Link /> : <button />;
  Tag = featureName && href ? <NextFeatureLink /> : Tag;

  if (outsideRouter) {
    // eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid
    Tag = <a />;
  }

  return cloneElement(
    Tag,
    getProperties(),
    <>
      {children}
      {!disabled &&
        ripples.map((ripple, index) => (
          <i key={index} className={styles.ripple} style={{ left: ripple.x, top: ripple.y }} />
        ))}
    </>
  );
};

ButtonLink.varieties = varieties;
ButtonLink.colors = colors;
ButtonLink.sizes = sizes;

ButtonLink.displayName = 'atoms/ButtonLink';

ButtonLink.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
  color: PropTypes.oneOf(Object.values(ButtonLink.colors)),
  disabled: PropTypes.bool,
  stretched: PropTypes.bool,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(ButtonLink.sizes)),
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  variety: PropTypes.oneOf(Object.values(ButtonLink.varieties)),
  props: PropTypes.object,
  id: PropTypes.string,
  href: PropTypes.string,
  openNewTab: PropTypes.bool,
  isExternalLink: PropTypes.bool,
  customTarget: PropTypes.string,
  cyData: PropTypes.string,
  outsideRouter: PropTypes.bool,
  featureName: PropTypes.string,
};

export default ButtonLink;
