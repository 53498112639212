import React from 'react';
import Meta from 'containers/Meta/Meta';
import WatchlistContainer from 'containers/WatchlistContainer/WatchlistContainer';
import WatchlistTemplate from 'components/templates/Watchlist/Watchlist';

const Watchlist = () => (
  <>
    <Meta meta={[{ name: 'robots', content: 'noindex' }]} />
    <WatchlistContainer>
      <WatchlistTemplate />
    </WatchlistContainer>
  </>
);

export default Watchlist;
