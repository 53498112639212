import React from 'react';
import PropTypes from 'prop-types';

import formatDate from 'constants/formatDate/formatDate';
import language from 'constants/language/language';

import Headline from 'components/atoms/Headline/Headline';

import ProductDiscount from 'components/molecules/ProductDiscount/ProductDiscount';
import ReductionInfo from 'components/molecules/ReductionInfo/ReductionInfo';

import { Modal } from '@interdiscount/component-library-interdiscount';

import styles from './ModalDiscount.scss';

const ModalDiscount = ({ closeModal, product, price }) => (
  <Modal onRequestClose={closeModal} isOpen>
    <div>
      <div className={styles.headlineContainer}>
        <Headline margin={Headline.margins.MB_025}>{language('discount.headline')}</Headline>
        <span>
          {language('discount.headlineFrom')}
          {formatDate(price.discount.from)}
          {language('discount.headlineTo')}
          {formatDate(price.discount.to)}
        </span>
      </div>
      <ProductDiscount price={price} product={product} />
      <ReductionInfo price={price} />
      <div className={styles.infoContainer}>
        {language('discount.onlineInfo')}
        <p className={styles.additionalInfo}>{language('discount.infoText')}</p>
      </div>
    </div>
  </Modal>
);
ModalDiscount.displayName = 'organisms/ModalDiscount';
ModalDiscount.propTypes = {
  closeModal: PropTypes.func,
  product: PropTypes.object.isRequired,
  price: PropTypes.object.isRequired,
};
ModalDiscount.defaultProps = {
  closeModal: () => {},
};

export default ModalDiscount;
