import {
  REHYDRATE_GUEST_ADDRESSES,
  CREATE_GUEST_DELIVERY_ADDRESS,
  REMOVE_GUEST_DELIVERY_ADDRESS,
  SELECT_ADDRESS_ID_FOR_TYPE,
  FLAG_ADDRESS_TYPE_FOR_SELECTION,
  FLAG_ADDRESS_FOR_DELETION,
  CREATE_TEMP_ADDRESS,
  COPY_INTO_TEMP_ADDRESS,
  DELETE_TEMP_ADDRESS,
  CREATE_USER_ADDRESS,
  UPDATE_USER_ADDRESS,
  DELETE_USER_ADDRESS,
  CREATE_USER_ADDRESS_AND_SELECT,
  UPDATE_ADDRESS_FIELD,
  BLUR_ADDRESS_FIELD,
  SET_ADDRESS,
} from 'constants/ActionTypes/ActionTypes';
import { addressTypes } from 'constants/addresses/addresses';
import getStorage from 'constants/storage/storage';
import { DELIVERY, PAYMENT } from 'constants/storage/storageKeys';

import UserAPI from 'api/UserAPI/UserAPI';
import CartAPI from 'api/CartAPI/CartAPI';

const storage = getStorage(false);

export const rehydrateGuestAddresses = () => {
  const payload = {};
  const addresses = {};
  const invoice = storage.getItem(PAYMENT);
  const delivery = storage.getItem(DELIVERY);
  if (invoice) addresses[addressTypes.INVOICE] = JSON.parse(invoice);
  if (delivery) addresses[addressTypes.DELIVERY] = JSON.parse(delivery);
  if (Object.keys(addresses).length) payload.addresses = addresses;
  return {
    type: REHYDRATE_GUEST_ADDRESSES,
    payload,
  };
};

export const createGuestDeliveryAddress = () => ({
  type: CREATE_GUEST_DELIVERY_ADDRESS,
});

export const removeGuestDeliveryAddress = () => ({
  type: REMOVE_GUEST_DELIVERY_ADDRESS,
});

export const selectThisAddressAsAddressType = (addressType, addressId) => ({
  type: SELECT_ADDRESS_ID_FOR_TYPE,
  payload: {
    addressType,
    addressId,
  },
});

export const flagThisAddressTypeForSelection = (addressType, bool) => ({
  type: FLAG_ADDRESS_TYPE_FOR_SELECTION,
  payload: {
    addressType,
    flagged: bool,
  },
});

export const flagThisAddressForDeletion = (addressId, bool) => ({
  type: FLAG_ADDRESS_FOR_DELETION,
  payload: {
    addressId,
    flagged: bool,
  },
});

export const createTemporaryAddress = () => ({
  type: CREATE_TEMP_ADDRESS,
});

export const copyIntoTemporaryAddress = (addressId, user, isCompanyNotRequired) => ({
  type: COPY_INTO_TEMP_ADDRESS,
  payload: {
    addressId,
    user,
    isCompanyNotRequired,
  },
});

export const deleteTemporaryAddress = (isOnlyOneAddressInStore = false) => ({
  type: DELETE_TEMP_ADDRESS,
  payload: {
    isOnlyOneAddressInStore,
  },
});

/**
 * Action creator which creates a new address
 * @param address
 * @param user
 */
export const createUserAddress = (address, user) => ({
  type: CREATE_USER_ADDRESS,
  payload: { user },
  promise: UserAPI.createAddress(address),
});

/**
 * Action creator which creates a new address and sets it for the current cart
 * @param address
 * @param user
 */
export const createUserAddressAndSelectAsType = (address, user, type) => (dispatch, getState) => {
  return dispatch({
    type: CREATE_USER_ADDRESS_AND_SELECT,
    payload: { user },
    promise: UserAPI.createAddress(address),
  }).then(() => {
    const state = getState().addresses;
    const addressId = state[addressTypes.TEMPORARY]?.id;

    if (type === addressTypes.UNIFIED) {
      return dispatch({
        type: SET_ADDRESS,
        payload: { type: addressTypes.INVOICE, addressId },
        promise: CartAPI.setAddress(addressTypes.INVOICE, addressId),
      }).then(() => {
        return dispatch({
          type: SET_ADDRESS,
          payload: { type: addressTypes.DELIVERY, addressId },
          promise: CartAPI.setAddress(addressTypes.DELIVERY, addressId),
        });
      });
    } else {
      return dispatch({
        type: SET_ADDRESS,
        payload: { type, addressId },
        promise: CartAPI.setAddress(type, addressId),
      });
    }
  });
};

/**
 * Action creator which updates the address.
 * Attributes not provided in the request will be defined again (set to null or default).
 * @param addressId
 * @param address
 * @param user
 */
export const updateUserAddress = (addressId, address, user, isCompanyNotRequired) => ({
  type: UPDATE_USER_ADDRESS,
  payload: {
    addressId,
    user,
    isCompanyNotRequired,
  },
  promise: UserAPI.putAddress(addressId, address),
});

/**
 * Action creator which returns removes customer's address.
 * @param addressId
 */
export const deleteUserAddress = (addressId) => (dispatch, getState) => {
  const addresses = Object.values(getState().addresses);
  let alternativeAddressId;

  // Check if we can provide a alternative address so that reducers can change their current value
  if (addresses.length > 1 && addressId) {
    alternativeAddressId = addresses?.find((addr) => addr.id !== addressId)?.id;
  }

  return dispatch({
    type: DELETE_USER_ADDRESS,
    payload: {
      addressId,
      alternativeAddressId,
    },
    promise: UserAPI.deleteAddress(addressId),
  });
};

/**
 * Action creator which sets the email address for a user
 * @param addressId
 * @param fieldName
 * @param value
 */
export const updateAddressField = (addressId, fieldName, value, isCompanyNotRequired) => (dispatch, getState) =>
  dispatch({
    type: UPDATE_ADDRESS_FIELD,
    payload: {
      addressId,
      fieldName,
      value,
      isCompanyNotRequired,
      user: getState().user,
    },
  });

export const blurAddressField = (addressId, fieldName) => (dispatch, getState) =>
  dispatch({
    type: BLUR_ADDRESS_FIELD,
    payload: {
      addressId,
      fieldName,
      user: getState().user,
    },
  });

export default {
  selectThisAddressAsAddressType,
  flagThisAddressTypeForSelection,
  flagThisAddressForDeletion,
  createUserAddress,
  createUserAddressAndSelectAsType,
  updateUserAddress,
  deleteUserAddress,
  createTemporaryAddress,
  deleteTemporaryAddress,
};
