import React, { memo } from 'react';
import cx from 'classnames';
import { Col } from 'components/atoms/Grid/Grid';
import Headline from 'components/atoms/Headline/Headline';
import HorizontalRule from 'components/atoms/HorizontalRule/HorizontalRule';
import Markdown from 'components/atoms/Markdown/Markdown';
import Icon, { ICON_BUSINESS_CENTER } from 'components/atoms/Icon/Icon';
import language, { getLocale } from 'constants/language/language';
import { B2B_PAGE, CMS } from 'constants/routePaths/routePaths';
import { isB2BCustomer } from 'constants/customerGroup/customerGroup';
import useFeatureToggle from 'hooks/useFeatureToggle';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import styles from './B2BQuoteRequest.scss';

const B2BQuoteRequest = () => {
  const b2bEnabled = /true/.test(useFeatureToggle('b2bToggle'));

  if (!b2bEnabled || (isB2BCustomer() && b2bEnabled)) {
    return null;
  }

  return (
    <>
      <Col>
        <Headline tag={Headline.tags.H2}>{language('product.information')}</Headline>
      </Col>
      <Col>
        <ul className={cx(styles.listCheckmark)}>
          <li className={styles.listItem}>
            <Icon path={ICON_BUSINESS_CENTER} className={styles.icon} />
            <Markdown>
              {language('product.b2bText').replace(
                '{URL}',
                mapPathToLocalizedUrl(getLocale(), [CMS, `${B2B_PAGE}-${language('product.b2bOffer')}`])
              )}
            </Markdown>
          </li>
        </ul>
      </Col>
      <Col>
        <HorizontalRule />
      </Col>
    </>
  );
};

B2BQuoteRequest.displayName = 'molecules/B2BQuoteRequest';

export default memo(B2BQuoteRequest);
