// @flow
import React from 'react';
import MarkdownJsx from 'markdown-to-jsx';
import MarkdownFactory, { FACTORY_TYPE } from './MarkdownFactory';

type IProps = {
  children: string,
  options?: {
    forceInline?: boolean,
  },
  areLinksDisabled: boolean,
};

const getFactory = (type: string, tag: string, skin: string) => ({
  component: MarkdownFactory,
  props: {
    type,
    tag,
    skin,
  },
});

// @see https://github.com/probablyup/markdown-to-jsx#parsing-options
const defaultOptions = {
  forceBlock: true,
  overrides: {
    h1: getFactory(FACTORY_TYPE.HEADLINE, 'h1'),
    h2: getFactory(FACTORY_TYPE.HEADLINE, 'h2'),
    h3: getFactory(FACTORY_TYPE.HEADLINE, 'h3'),
    h4: getFactory(FACTORY_TYPE.HEADLINE, 'h4'),
    h5: getFactory(FACTORY_TYPE.HEADLINE, 'h5'),
    h6: getFactory(FACTORY_TYPE.HEADLINE, 'h6'),
    cm: getFactory(FACTORY_TYPE.CHECKMARK),
    arrow: getFactory(FACTORY_TYPE.ARROW),
    a: getFactory(FACTORY_TYPE.ANCHOR),
    details: getFactory(FACTORY_TYPE.DETAILS),
    p: getFactory(FACTORY_TYPE.PARAGRAPH),
  },
};

const Markdown = ({ children, options = {}, areLinksDisabled = false }: IProps) => {
  if (!children) return <div />;

  let markdown = children
    // Replace "&&" with CheckMarkText component
    .replace(/()?(&&&) (.+|[)])/g, (match, p1: string, p2: string, p3: string) => `<arrow>${p3}</arrow>`)
    .replace(/()?(&&) (.+|[)])/g, (match, p1: string, p2: string, p3: string) => `<cm>${p3}</cm>`)
    // Replace "***" with a hr tag
    .replace(/\*\*\*/g, '<hr>')
    .replace(/\*!/g, '<span>')
    .replace(/!\*/g, '</span>')
    // Sanitize invalid markdown: Make sure lists always starts with \n\n
    .replace(/\n\* /g, '\n\n* ')
    // Sanitize invalid markdown: Replace empty headlines with normal line breaks
    .replace(/\n#{1,3}\s+\n/g, '\n\n\n')
    // Sanitize invalid markdown: Replace \r\n with \n\n
    .replace(/\r\n/g, '\n\n');

  if (areLinksDisabled) {
    // filter out markdown inline links [text](link) & footnotes [^1]
    markdown = markdown.replace(/\[([^[\]]*)\]\((.*?)\)/gm, '$1').replace(/\[\^([^[\]]*)\]/gm, '<sup>$1</sup>');
  }

  //this is a dirty hack to prevent an reaaaaaly expensive regex search from within MarkdownJsx "input.replace((^\n+|(\n|\s)+$), '')}\n\n" runs into a timeout when it's tooooo many newlines
  while (markdown.includes('\r\n\r\n\r\n')) {
    markdown = markdown.replace('\r\n\r\n\r\n', '\r\n\r\n');
  }
  while (markdown.includes('\n\n\n')) {
    markdown = markdown.replace('\n\n\n', '\n\n');
  }

  return <MarkdownJsx options={{ ...defaultOptions, ...options }}>{markdown}</MarkdownJsx>;
};

export default Markdown;
