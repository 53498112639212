import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { loadScript } from 'constants/scriptLoader/scriptLoader';
import { getLocale } from 'constants/language/language';

const scriptURL = 'https://orca-api.zoovu.com/advisor-fe-web/api/v1/advisors/{ID}/js-loader?locale={LOCALE}-CH';
const scriptID = 'zoovu';

const ZoovuAdvisor = ({ assistantId }) => {
  useEffect(() => {
    if (assistantId) {
      const script = scriptURL.replace('{ID}', assistantId).replace('{LOCALE}', getLocale());
      loadScript.js(script, scriptID);
    }

    return () => {
      if (__CLIENT__) {
        const parent = document.getElementsByTagName('head')[0];
        parent.removeChild(document.getElementById(scriptID));
      }
    };
  }, [assistantId]);

  return (
    <div id="zoovu-assistant" /> // placeholder for Zoovu Assistant
  );
};

ZoovuAdvisor.propTypes = {
  assistantId: PropTypes.string,
};

ZoovuAdvisor.defaultProps = {
  assistantId: '',
};

ZoovuAdvisor.displayName = 'organisms/ZoovuAdvisor';

export default ZoovuAdvisor;
