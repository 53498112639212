// @flow
import React from 'react';
import Headline, { tags, margins } from 'components/atoms/Headline/Headline';
import StarRating from 'components/atoms/StarRating/StarRating';
import styles from './RatingOverview.scss';
import language from 'constants/language/language';

type IProps = {
  reviewDistribution: {
    [string]: number,
  },
};

type BarProps = {
  count: number,
  total: number,
};

const RatingBar = ({ count, total }: BarProps) => (
  <div className={styles.ratingBar}>
    <div className={styles.ratingBarIndicator} style={{ width: `${100 * (count / total)}%` }} />
    <div className={styles.ratingBarBackground} />
  </div>
);

const RatingOverview = ({ reviewDistribution }: IProps) => {
  const distributionKeys: Array<string> = Object.keys(reviewDistribution);
  const sortedDistributionKeys: Array<string> = distributionKeys.sort((a, b) => parseInt(b, 10) - parseInt(a, 10));
  const counts: Array<number> = distributionKeys.map((key) => reviewDistribution[key]);
  const total: number = counts.reduce((count, sum) => count + sum, 0);

  return (
    <div className={styles.ratingOverviewWrapper}>
      <div className={styles.ratingOverviewHeadline}>
        <Headline tag={tags.H4} margin={margins.MB_15}>
          {language('productRating.overview.title')}
        </Headline>
      </div>
      <div>
        <div className={styles.ratingOverviewRow}>
          <div className={styles.ratingOverviewVisualization}>
            {sortedDistributionKeys.map((stars) => (
              <div className={styles.ratingOverviewVisualizationRow} key={stars}>
                <StarRating value={parseInt(stars, 10)} />
                <RatingBar count={reviewDistribution[stars]} total={total} />
              </div>
            ))}
          </div>
          <div className={styles.ratingOverviewAggregate}>
            {sortedDistributionKeys.map((stars) => (
              <div className={styles.ratingOverviewAggregateCount} key={stars}>
                {reviewDistribution[stars]}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

RatingOverview.defaultProps = {
  distribution: {
    // $FlowFixMe, @see https://github.com/facebook/flow/issues/380
    5: 0,
    // $FlowFixMe
    4: 0,
    // $FlowFixMe
    3: 0,
    // $FlowFixMe
    2: 0,
    // $FlowFixMe
    1: 0,
  },
};

export default RatingOverview;
