import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from '@interdiscount/component-library-interdiscount';
import { getEnv } from 'config/config';

import styles from './ModalEnergyLabel.scss';

const getImageUrl = (imageCode) => {
  return `${getEnv('googleStorageUrl')}/720/${imageCode}`;
};

const ModalEnergyLabel = ({ requestCloseModal = () => {}, imageUrl = '', imageCode = '' }) => {
  return (
    <Modal onRequestClose={requestCloseModal} isOpen>
      <div className={styles.contentContainer}>
        {imageUrl && imageCode ? (
          <img src={getImageUrl(imageCode)} alt="energyLabel" />
        ) : (
          <img src="/images/noEnergyLabelFound.png" alt="noEnergyLabel" />
        )}
      </div>
    </Modal>
  );
};

ModalEnergyLabel.displayName = 'organisms/ModalEnergyLabel';

ModalEnergyLabel.propTypes = {
  requestCloseModal: PropTypes.func,
  imageUrl: PropTypes.string,
  imageCode: PropTypes.string,
};

export default ModalEnergyLabel;
