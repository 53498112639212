import React from 'react';
import PropTypes from 'prop-types';

import Spinner, { SPINNER_STATES } from 'components/atoms/Spinner/Spinner';
import { Modal } from '@interdiscount/component-library-interdiscount';

import { apiStatus } from 'constants/apiStatus/apiStatus';
import styles from './ModalAPICallInProgress.scss';

export const status = {
  REQUEST: SPINNER_STATES.REQUEST,
  FAILURE: SPINNER_STATES.FAILURE,
  SUCCESS: SPINNER_STATES.SUCCESS,
};

const ModalAPICallInProgress = ({ orderInProgress, children }) => (
  <Modal isClosable={false} isOpen={orderInProgress} onRequestClose={() => {}}>
    <div className={styles.contentContainer}>
      <div className={styles.spinnerContainer}>
        <Spinner status={apiStatus.request} />
      </div>
      <div className={styles.messageContainer}>{children}</div>
    </div>
  </Modal>
);

ModalAPICallInProgress.displayName = 'organisms/ModalAPICallInProgress';

ModalAPICallInProgress.propTypes = {
  orderInProgress: PropTypes.bool,
  children: PropTypes.node,
};

ModalAPICallInProgress.defaultProps = {
  orderInProgress: true,
};

export default ModalAPICallInProgress;
