// @flow
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import { getLocale } from 'constants/language/language';
import { accountLogin } from 'constants/routePaths/routePathCombinations';
import { ANONYMOUS_USER_ID } from 'api/UserAPI/UserAPI';

type IUserState = {
  uid: string,
};

export const isLoggedIn = (user: IUserState = { uid: '' }) =>
  user.uid.toLowerCase() !== ANONYMOUS_USER_ID.toLowerCase();

export const getLoginUrl = (): string => mapPathToLocalizedUrl(getLocale(), accountLogin);
