import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import {
  AVAILABLE_RETURN_REASONS,
  REASONS_NEED_DESCRIPTION,
  getReasonSteps,
  isProductReturnable,
} from 'constants/returns/returns';
import language from 'constants/language/language';
import validation from 'constants/validation/validation';
import { CMS_UID_RETURNS_POLICY } from 'constants/cms/cms';
import { types as flashMessageTypes } from 'constants/flashMessages/flashMessages';
import { CmsLink } from 'containers/CmsLink/CmsLink';
import InputRadioList from 'components/molecules/InputRadioList/InputRadioList';
import CmsLinkItem from 'components/atoms/CmsLinkItem/CmsLinkItem';
import Headline from 'components/atoms/Headline/Headline';
import Icon, { ICON_CHECK_CIRCLE_OUTLINE } from 'components/atoms/Icon/Icon';
import InputTextarea from 'components/atoms/InputTextarea/InputTextarea';
import ErrorMessageInput from 'components/molecules/FieldSetInput/ErrorMessageInput';
import FlashMessage from 'components/atoms/FlashMessage/FlashMessage';

import styles from './ProductReturnSummary.scss';

export const validate = ({ id, returnReasons, returnDescription, ref }) => {
  if (!REASONS_NEED_DESCRIPTION.includes(returnReasons?.reason)) return null;

  if (returnDescription?.trim().length === 0) {
    return { id, error: language(`returns.returnReason.description.missing`), ref };
  }

  if (!validation.hasMinWords(returnDescription)) {
    return { id, error: language(`returns.returnReason.description.minlength`), ref };
  }

  if (!validation.isBelowMaxLength(returnDescription, 200)) {
    return { id, error: language(`returns.returnReason.description.maxlength`), ref };
  }

  return null;
};

const translateOptions = (options) => {
  const result = {};
  options.forEach((key) => {
    result[key] = language(`returns.returnReason.reason.${key}`);
  });
  return result;
};

const ProductReturnSummary = ({
  product = {},
  returnType = AVAILABLE_RETURN_REASONS.ARTICLE_RETURN_ONLINE,
  reasons = {},
  errorMessage = '',
  onReasonsChange = () => {},
}) => {
  const ref = useRef();
  const steps = getReasonSteps(returnType);
  const [currentStep, setCurrentStep] = useState(reasons ? Object.keys(reasons).length : 0);
  const [returnReasons, setReasons] = useState(reasons);
  const [validationError, setValidationError] = useState(false);
  const [returnDescription, setDescription] = useState(product.returnDescription || '');

  const getReason = (key) => (returnReasons ? returnReasons[key] : '');
  const i18n = (key) => language(`returns.returnReason.${key}`);

  useEffect(() => {
    onReasonsChange({ ...product, returnReasons, returnDescription, validationError, ref });
  }, [returnReasons, returnDescription]);

  const onReasonListChange = (value) => {
    setReasons((oldReasons) => ({ ...oldReasons, [steps[currentStep].key]: value }));
    setValidationError(!isProductReturnable(value, product));
    setCurrentStep(currentStep + 1);
    setDescription('');
  };

  const handleStepRevert = (step) => {
    setReasons(() => Object.fromEntries(Object.entries(returnReasons).slice(0, step)));
    setValidationError(false);
    setCurrentStep(step);
  };

  return (
    <ul className={styles.steps}>
      {steps.map((step, index) => (
        <li
          key={step.key}
          ref={ref}
          className={styles.step}
          id={`formControl-productReturnSummary-${step.key}-${product.code}`}
        >
          {currentStep > index && (
            <>
              <Headline tag={Headline.tags.H4} margin={Headline.margins.NONE}>
                {i18n(`${step.key}.label`)}
              </Headline>
              <div className={styles.stepFinished} onClick={() => handleStepRevert(index)}>
                <span className={styles.iconWrap}>
                  <Icon path={ICON_CHECK_CIRCLE_OUTLINE} className={styles.icon} />
                </span>
                {i18n(`reason.${getReason(step.key)}`)}
              </div>
              {REASONS_NEED_DESCRIPTION.includes(returnReasons?.reason) && (
                <div className={styles.textWrapper}>
                  <InputTextarea
                    placeholder={i18n(`description.${getReason(step.key)}`)}
                    value={returnDescription}
                    handleChange={setDescription}
                    maxLength={200}
                  />
                  {errorMessage && <ErrorMessageInput showError>{errorMessage}</ErrorMessageInput>}
                </div>
              )}
            </>
          )}
          {currentStep === index && !validationError && (
            <>
              <Headline tag={Headline.tags.H4} margin={Headline.margins.NONE}>
                {i18n(`${step.key}.label`)}
              </Headline>
              <InputRadioList options={translateOptions(step.options)} handleChange={onReasonListChange} />
            </>
          )}
        </li>
      ))}

      {validationError && (
        <li>
          <FlashMessage type={flashMessageTypes.ERROR} id="formControl-productReturnSummaryError-text">
            {i18n('errorMessages.Invalid.preLink')}{' '}
            <CmsLink pageId={CMS_UID_RETURNS_POLICY}>
              <CmsLinkItem customText={i18n('errorMessages.Invalid.link')} target="_blank" />
            </CmsLink>
            {i18n('errorMessages.Invalid.afterLink')}
          </FlashMessage>
        </li>
      )}
    </ul>
  );
};

ProductReturnSummary.displayName = 'molecules/ProductReturnSummary';

ProductReturnSummary.propTypes = {
  product: PropTypes.object,
  returnType: PropTypes.oneOf(Object.keys(AVAILABLE_RETURN_REASONS)),
  reasons: PropTypes.object,
  errorMessage: PropTypes.string,
  onReasonsChange: PropTypes.func,
};

export default ProductReturnSummary;
