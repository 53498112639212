import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, browserHistory } from 'react-router';

import ReturnSteps from 'components/molecules/ReturnSteps/ReturnSteps';
import { getInitialState, AVAILABLE_RETURN_REASONS } from 'constants/returns/returns';
import { CMS_UID_RETURNS_OVERVIEW } from 'constants/cms/cms';
import { isLoggedIn } from 'constants/user/user';
import { getLocale } from 'constants/language/language';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import { provideLink } from 'constants/navigation/navigation';
import { accountReturns } from 'constants/routePaths/routePathCombinations';

const mapStateToProps = (state = { user: { uid: '', lastName: '' }, cms: {} }) => ({
  userLoggedIn: isLoggedIn(state.user),
  pages: state?.cms?.navigation?.pages ?? {},
});

const ReturnsCreationContainer = ({ userLoggedIn = false, pages = {}, router = {} }) => {
  const { returnType } = router.location.query || {};
  const [state, setState] = useState(getInitialState(returnType, userLoggedIn));
  useEffect(() => {
    if (!Object.values(AVAILABLE_RETURN_REASONS).includes(returnType)) {
      // redirect to returns overview page since no valid returnType passed!
      const pathname = userLoggedIn
        ? mapPathToLocalizedUrl(getLocale(), accountReturns)
        : provideLink(pages, CMS_UID_RETURNS_OVERVIEW, getLocale())?.url ?? '';
      browserHistory.replace(pathname);
    }
  }, []);

  useEffect(() => {
    setState(getInitialState(returnType, userLoggedIn));
  }, [userLoggedIn]);

  const handleNextStep = (data) => {
    setState((prevState) => ({
      ...prevState,
      ...data,
    }));
  };

  return <ReturnSteps state={state} onStepComplete={handleNextStep} />;
};

ReturnsCreationContainer.displayName = 'containers/ReturnsCreationContainer';

ReturnsCreationContainer.propTypes = {
  userLoggedIn: PropTypes.bool,
  pages: PropTypes.object,
  router: PropTypes.object,
};

export default withRouter(connect(mapStateToProps)(ReturnsCreationContainer));
