import React from 'react';
import PropTypes from 'prop-types';
import SvgContainer from 'components/atoms/SvgContainer/SvgContainer';

const renderPath = [
  <path
    key={'path'}
    // eslint-disable-next-line max-len
    d="M307.1 399.4H76.3V0h230.8c12.8 0 23.2 10.4 23.2 23.2v353c0 12.8-10.4 23.2-23.2 23.2zm-223.5-7.3h223.5c8.7 0 15.8-7.1 15.8-15.8v-353c0-8.7-7.1-15.8-15.8-15.8H83.6v384.6z"
  />,
  <path
    key={'path2'}
    // eslint-disable-next-line max-len
    d="M306.6 142h-11.7v-11.7h-9.4V142h-11.7v9.4h11.7v11.7h9.4v-11.7h11.7M212.1 46.2h82.5v8.3h-82.5zM203.3 62h91.3v8.3h-91.3zm-14.5 15.8h105.8v8.3H188.8zm13 16.8h92.8v8.3h-92.8zm12.4 15.7h80.4v8.3h-80.4z"
  />,
];
const vbWidth = 400;
const vbHeight = 400;

const IconSwissPass = ({ height }) => (
  <SvgContainer height={height} vbWidth={vbWidth} vbHeight={vbHeight}>
    {renderPath}
  </SvgContainer>
);

IconSwissPass.displayName = 'atoms/IconSwissPass';

IconSwissPass.propTypes = {
  height: PropTypes.number,
};

IconSwissPass.defaultProps = {
  height: 32,
};

export default IconSwissPass;
