import React, { useState, useEffect, useMemo, useCallback } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import language from 'constants/language/language';

import ProductVideo from 'containers/ProductVideo/ProductVideo';

import ModalEnergyLabel from 'components/organisms/ModalEnergyLabel/ModalEnergyLabel';
import ModalDiscount from 'components/organisms/ModalDiscount/ModalDiscount';

import Gallery from 'components/molecules/Gallery/Gallery';
import ProductFlagList from 'components/molecules/ProductFlagList/ProductFlagList';
import ProductVariants from 'components/molecules/ProductVariants/ProductVariants';
import BrandCategoryLink from 'components/molecules/BrandCategoryLink/BrandCategoryLink';
import ProductFeatureMain from 'components/molecules/ProductFeatureMain/ProductFeatureMain';
import RatingCount from 'components/molecules/Rating/RatingCount/RatingCount';
import ProductInteractions from 'components/molecules/ProductInteractions/ProductInteractions';
import TeaserPromotion, { skins as promoSkins } from 'components/molecules/TeaserPromotion/TeaserPromotion';
import PromotionBox from 'components/molecules/PromotionBox/PromotionBox';
import StickyAddToCart from 'components/molecules/StickyAddToCart/StickyAddToCart';
import PegiFlag from 'components/atoms/PegiFlag/PegiFlag';
import Headline from 'components/atoms/Headline/Headline';
import Price from 'components/atoms/Price/Price';
import { THEMES } from 'components/atoms/StarRating/StarRating';
import ProductCode from 'components/atoms/ProductCode/ProductCode';
import DiscountFlag from 'components/atoms/DiscountFlag/DiscountFlag';
import Reduction from 'components/atoms/Reduction/Reduction';
import EnergyEfficiency, { types } from 'components/atoms/EnergyEfficiency/EnergyEfficiency';

import bootstrap from 'scss/component.scss';
import styles from './ProductInfoMain.scss';
import { noop } from 'constants/noop/noop';
import { nowDate } from 'constants/deliveryModes/deliveryModes';

export const ADD_TO_CART_IDENTIFIER = 'productInfoMain';
export const STICKY_ADD_TO_CART_IDENTIFIER = 'productInfoMainSticky';

const ProductInfoMain = ({
  categories = {},
  hasPromoEnded,
  product = {},
  addedToComparison,
  addedToWatchlist,
  addProductToComparison = noop,
  addProductToWatchlist = noop,
  removeProductFromComparison = noop,
  removeProductFromWatchlist = noop,
  promoBox = {},
  cyData,
  watchlistApiStatus,
}) => {
  const [isDiscountModalOpen, setIsDiscountModalOpen] = useState(false);
  const [isEnergyLabelModalOpen, setIsEnergyLabelModalOpen] = useState(false);
  const [variants, setVariants] = useState([]);
  const [variantsLoading, setVariantsLoading] = useState(false);
  const [energyEfficiencyType, setEnergyEfficiencyType] = useState({});
  const [isStickyButtonVisible, setIsStickyButtonVisible] = useState(false);

  const isEmpty = (obj) => Object.keys(obj).length === 0;

  const stickyButtonRef = useCallback((stickyButtonRefNode) => {
    setIsStickyButtonVisible(!!stickyButtonRefNode);
  }, []);

  const {
    apiStatus,
    categoryCode = '',
    classifications = [],
    code = '',
    canBeReserved,
    canBeBought,
    energyEfficiency = {},
    energyEfficiencyEnEv2020 = {},
    productPriceData,
    promotion,
    productVariants,
    maxOrderCounterActive,
  } = product;

  const DemoUpContainer = useMemo(() => require('demoup-react')('interdiscount.ch').container, [code]);
  const isReleaseDateInPast = new Date(product?.releaseDate) < nowDate || !product?.releaseDate;

  useEffect(() => {
    const storedProductVariantsIsEmpty = !productVariants?.length;
    // check if new productVariants are available
    if (apiStatus === 'SUCCESS' || !storedProductVariantsIsEmpty) {
      // return new productVariants if not empty
      if (storedProductVariantsIsEmpty) {
        setVariants([]);
      } else {
        setVariants(productVariants);
      }
      setVariantsLoading(false);
    } else {
      setVariantsLoading(true);
    }

    if (!isEmpty(energyEfficiencyEnEv2020)) {
      setEnergyEfficiencyType(energyEfficiencyEnEv2020);
    } else {
      setEnergyEfficiencyType(energyEfficiency);
    }
  }, [productVariants, apiStatus]);

  const buttonText = () => {
    if (canBeReserved && !isReleaseDateInPast) {
      return language('product.reserve');
    }
    return language('product.addToCart');
  };

  const openDiscountModal = () => {
    setIsDiscountModalOpen(true);
  };

  const closeDiscountModal = () => {
    setIsDiscountModalOpen(false);
  };

  const openEnergyModal = () => {
    setIsEnergyLabelModalOpen(true);
  };

  const closeEnergyModal = () => {
    setIsEnergyLabelModalOpen(false);
  };

  const renderRatingCount = () => {
    if (!product.numberOfReviews) {
      return null;
    }
    return <RatingCount count={product.numberOfReviews} rating={product.averageRating} theme={THEMES.secondary} />;
  };

  const hasEnergyEfficiency =
    !!Object.keys(energyEfficiency || {})?.length || !!Object.keys(energyEfficiencyEnEv2020 || {})?.length;

  return (
    <div className={bootstrap.row}>
      {isDiscountModalOpen && !!Object.keys(productPriceData.discount || {}).length && (
        <ModalDiscount closeModal={closeDiscountModal} product={product} price={productPriceData} />
      )}
      {isEnergyLabelModalOpen && (
        <ModalEnergyLabel
          imageUrl={energyEfficiencyType.imageUrl}
          requestCloseModal={closeEnergyModal}
          imageCode={energyEfficiencyType.imageCode}
        />
      )}
      <div className={cx(bootstrap.colMd6, styles.galleryContainer)}>
        <div className={styles.flagContainer}>
          {!!Object.keys(productPriceData.discount || {})?.length && (
            <DiscountFlag discount={productPriceData.discount} onClickAction={openDiscountModal} clickable />
          )}
          {!!product.promoLabels?.length && <ProductFlagList flags={product.promoLabels} />}
        </div>
        <DemoUpContainer threed="enabled">
          <ProductVideo code={code}>
            <Gallery
              data={product.customImageData}
              product={code}
              trackingAttributes={{ sku: code }}
              promoBoxImage={promoBox.productImage}
            />
          </ProductVideo>
        </DemoUpContainer>
        {!!Object.keys(promoBox).length && <PromotionBox {...promoBox} />}
      </div>
      <div className={bootstrap.colMd6}>
        {PegiFlag.shouldShowPegi(product.pegiValue) && (
          <div className={styles.PegiContainer}>
            <PegiFlag value={product.pegiValue} />
          </div>
        )}
        <Headline tag={Headline.tags.H1} margin={Headline.margins.NONE}>
          {product.name}
        </Headline>
        <ProductCode label={language('product.itemNumberOnline')} itemNumber={product.code} />
        <br />
        {product.displayedCode && (
          <ProductCode label={language('product.itemNumber')} itemNumber={product.displayedCode} />
        )}
        <BrandCategoryLink
          category={product.categoryCode}
          brandLogoUrl={product.brandLogoUrl}
          manufacturer={product.manufacturer}
          brandName={product.brandName}
          brandPageUrl={product.brandPageUrl || ''}
          categories={categories}
        />
        {renderRatingCount()}
        <div className={styles.mainWrapper}>
          {variants.length ? (
            <ProductVariants
              variants={variants}
              variantsLoading={variantsLoading}
              currentProduct={{ code, categoryCode, categories }}
            />
          ) : (
            <ProductFeatureMain classifications={classifications} />
          )}
          {hasEnergyEfficiency && (
            <div className={styles.EnergyEfficiencyContainer}>
              <EnergyEfficiency
                energyEfficiency={energyEfficiency}
                energyEfficiencyEnEv2020={energyEfficiencyEnEv2020}
                type={types.lg}
                canOpenModal={true}
                openModal={openEnergyModal}
              />
            </div>
          )}
          <div>
            {canBeBought && !!Object.keys(productPriceData.savings).length && (
              <div className={styles.savingsContainer}>
                <Reduction savings={productPriceData.savings} />
              </div>
            )}
            {canBeBought && maxOrderCounterActive && (
              <TeaserPromotion {...promotion} skin={promoSkins.DETAIL} hasEnded={hasPromoEnded} />
            )}
            {canBeBought && (
              <div className={styles.PriceActionsContainer}>
                {!hasPromoEnded && (
                  <Price productPriceData={productPriceData} skin={Price.SKINS.DETAIL} showInsteadPrice />
                )}
                <div className={styles.ProductInteractions}>
                  <ProductInteractions
                    buttonText={buttonText()}
                    product={product}
                    enableLinks
                    addProductToWatchlist={addProductToWatchlist}
                    addedToWatchlist={addedToWatchlist}
                    removeProductFromWatchlist={removeProductFromWatchlist}
                    addProductToComparison={addProductToComparison}
                    addedToComparison={addedToComparison}
                    removeProductFromComparison={removeProductFromComparison}
                    isAddToCartVisible={!isStickyButtonVisible}
                    id={ADD_TO_CART_IDENTIFIER}
                    cyData={cyData}
                    watchlistApiStatus={watchlistApiStatus}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {canBeBought && (
        <StickyAddToCart
          product={product}
          buttonText={buttonText()}
          id={STICKY_ADD_TO_CART_IDENTIFIER}
          buttonRef={stickyButtonRef}
        />
      )}
    </div>
  );
};

ProductInfoMain.displayName = 'organisms/ProductInfoMain';
ProductInfoMain.propTypes = {
  categories: PropTypes.object,
  hasPromoEnded: PropTypes.bool,
  product: PropTypes.object,
  addProductToComparison: PropTypes.func,
  addProductToWatchlist: PropTypes.func,
  addedToComparison: PropTypes.bool,
  addedToWatchlist: PropTypes.bool,
  removeProductFromComparison: PropTypes.func,
  removeProductFromWatchlist: PropTypes.func,
  trackNavigationElements: PropTypes.func,
  promoBox: PropTypes.object,
  energyEfficiency: PropTypes.object,
  energyEfficiencyEnEv2020: PropTypes.object,
  cyData: PropTypes.string,
  watchlistApiStatus: PropTypes.string,
};

export default ProductInfoMain;
