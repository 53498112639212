import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { noop } from 'constants/noop/noop';

import styles from './SelectDropdown.scss';

class SelectDropdown extends Component {
  static displayName = 'atoms/SelectDropdown';
  static propTypes = {
    options: PropTypes.array,
    optionsActiveIndex: PropTypes.number,
    optionsClickHandler: PropTypes.func,
    closeSelect: PropTypes.func,
  };
  static defaultProps = {
    options: [],
    optionsActiveIndex: 0,
    optionsClickHandler: noop,
    closeSelect: noop,
  };

  constructor(props) {
    super(props);
    this.options = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('click', this.closeSelectDropdownOnPageClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.closeSelectDropdownOnPageClick);
  }

  closeSelectDropdownOnPageClick = (e) => {
    let clickedElement = e.target;
    while (clickedElement) {
      if (clickedElement !== this.options.current) {
        this.props.closeSelect();
        clickedElement = false;
      }
      clickedElement = clickedElement.parentNode;
    }
  };

  render() {
    const { options, optionsActiveIndex, optionsClickHandler } = this.props;
    return (
      <div className={styles.options} ref={this.options}>
        {options.map((option, index) => (
          <div
            key={index}
            className={cx(styles.option, { [styles.optionActive]: optionsActiveIndex === index })}
            onClick={() => optionsClickHandler(index)}
          >
            {option}
          </div>
        ))}
      </div>
    );
  }
}

export default SelectDropdown;
