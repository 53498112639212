import { getPriceData, getNewestProductPriceData } from 'constants/price/price';

/**
 * getProductsWithNewestProductPriceData
 *
 * This function will return the incomingProucts with the newest productPriceData.
 * The function takes the incomingProducts array and will check for each item if there is a
 * newer productPriceData in the already stored Product (storedProducts). If there is a newer
 * productPriceData it will return this productPriceData object instead of the incoming one.
 *
 */
export const getProductsWithNewestProductPriceData = (storedProducts = {}, incomingProducts = []) =>
  incomingProducts.map((incomingProduct) => {
    const storedProductPriceData = storedProducts[incomingProduct.code]?.productPriceData;
    const incomingProductPriceData = incomingProduct?.productPriceData;
    const newestProductPriceData = getNewestProductPriceData(storedProductPriceData, incomingProductPriceData);
    const productPriceData = newestProductPriceData?.prices
      ? getPriceData(newestProductPriceData)
      : newestProductPriceData;
    const incomingProductWithNewestProductPriceData = { ...incomingProduct };
    incomingProductWithNewestProductPriceData.productPriceData = productPriceData;
    return incomingProductWithNewestProductPriceData;
  });
