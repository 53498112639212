import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Modal } from '@interdiscount/component-library-interdiscount';
import Spinner, { SPINNER_STATES } from 'components/atoms/Spinner/Spinner';
import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';
import { apiStatus } from 'constants/apiStatus/apiStatus';
import language from 'constants/language/language';
import styles from './ModalApiError.scss';

const propTypes = {
  isModalOpen: PropTypes.bool,
  retryAction: PropTypes.func,
  requestCloseModal: PropTypes.func,
  apiStatus: PropTypes.string,
};

const defaultProps = {
  apiStatus: apiStatus.failure,
};

export default class ModalApiError extends Component {
  static displayName = 'organisms/ModalApiError';
  static propTypes = propTypes;
  static defaultProps = defaultProps;

  render() {
    const { isModalOpen, requestCloseModal } = this.props;

    return (
      <div>
        <Modal onRequestClose={requestCloseModal} isOpen={isModalOpen}>
          <ModalContent {...this.props} />
        </Modal>
      </div>
    );
  }
}

export class ModalContent extends Component {
  // eslint-disable-line react/no-multi-comp
  static propTypes = propTypes;
  static defaultProps = defaultProps;

  constructor(props) {
    super(props);
    this.state = {
      requestState: SPINNER_STATES.FAILURE,
    };
  }

  UNSAFE_componentWillReceiveProps() {
    // set state after 500ms so the spinner animation will be visible
    clearTimeout(this.spinnerTimeout);
    this.spinnerTimeout = setTimeout(() => {
      this.setState({
        requestState: SPINNER_STATES.FAILURE,
      });
    }, 500);
  }

  componentWillUnmount = () => {
    clearTimeout(this.spinnerTimeout);
    this.spinnerTimeout = null;
  };

  getText = () => {
    if (this.props.apiStatus === apiStatus.networkerror) {
      return language('apiErrorMessages.networkErrorModal');
    }

    return language('apiErrorMessages.badRequestModal');
  };

  handleRetry = () => {
    this.setState({
      requestState: SPINNER_STATES.REQUEST,
    });
    this.props.retryAction();
  };

  render() {
    return (
      <div className={styles.contentContainer}>
        <div className={styles.spinnerContainer}>
          <Spinner status={this.state.requestState} />
        </div>
        <div className={styles.messageContainer}>
          <span>{this.getText()}</span>
          {this.props.apiStatus === apiStatus.networkerror && (
            <span className={styles.retryButtonContainer}>
              <ButtonLink variety={ButtonLink.varieties.stroked} onClick={this.handleRetry}>
                {language('apiErrorMessages.retryButton')}
              </ButtonLink>
            </span>
          )}
        </div>
      </div>
    );
  }
}
