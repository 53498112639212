import React from 'react';
import Icon, {ICON_CLOSE} from 'components/atoms/Icon/Icon';
import styles from './AppBanner.scss';
import useAppBanner from './useAppBanner';
import language from "constants/language/language";
import {trackGenericInteraction} from "tracking/tracking";
import dataTracking from "constants/trackingAttributes/dataTracking";
import {LogoInterdiscountBanner} from "components/atoms/logos/LogoInterdiscountBanner";

export const APP_BANNER_EXITED = 'smartbanner_exited';

const trackAppOpen = () => {
  trackGenericInteraction(
    dataTracking({
      TYPE: 'app_banner',
      FUNCTION: 'open',
      INTERACTION: 'click',
    })
  );
};

const trackAppClose = () => {
  trackGenericInteraction(
    dataTracking({
      TYPE: 'app_banner',
      FUNCTION: 'close',
      INTERACTION: 'click',
    })
  );
};

const AppBanner = () => {
  const {shouldShowAppBanner, closeAppBanner, appLink} = useAppBanner();

  const handleClickClose = () => {
    closeAppBanner();
    trackAppClose();
  };

  const handleClickOpen = () => {
    closeAppBanner();
    trackAppOpen();
  };

  if (!shouldShowAppBanner) {
    return null
  }

  return (
    <div aria-label="app-banner" className={styles.appBanner}>
      <button onClick={handleClickClose}>
        <Icon path={ICON_CLOSE} className={styles.closeIcon}/>
      </button>
      <div className={styles.bannerContent}>
        <div className={styles.appLogo}>
          <LogoInterdiscountBanner />
        </div>
        <div className={styles.bannerText}>
          <p className={styles.title}>{language("appBanner.title")}</p>
          <p className={styles.subTitle}>{language("appBanner.subTitle")}</p>
        </div>
      </div>
      <a onClick={handleClickOpen} href={appLink} className={styles.appLink}>
        {language("appBanner.cta")}
      </a>
    </div>
  );
};

export default AppBanner;
