import {
  SUBSCRIBE_TO_NEWSLETTER,
  UNSUBSCRIBE_NEWSLETTER,
  COMPLETE_USER_DATA,
  VERIFY_NEWSLETTER_TOKEN,
  GET_EMAIL_FROM_HASH,
  NEWSLETTER_OPTIN,
  VERIFY_EMAIL_TOKEN,
  SEND_OPTOUT_REASONS,
} from 'constants/ActionTypes/ActionTypes';

import NewsletterAPI from 'api/NewsletterAPI/NewsletterAPI';

/**
 * Action creator which subscribes the visitor with her email to the newsletter.
 * @param email
 * @param birthday
 * @param titleCode
 * @param firstName
 * @param lastName
 */
export const subscribeToNewsletter = (email, birthday, titleCode, firstName, lastName) => ({
  type: SUBSCRIBE_TO_NEWSLETTER,
  payload: {
    email,
    birthday,
    titleCode,
    firstName,
    lastName,
  },
  promise: NewsletterAPI.subscribeToNewsletter(email, birthday, titleCode, firstName, lastName),
});

/**
 * Action creator which un-subscribes the visitor from the newsletter.
 * @param hash
 * @param isPersonalized
 */
export const unsubscribeNewsletter = (hash, isPersonalized = false) => ({
  type: UNSUBSCRIBE_NEWSLETTER,
  payload: {
    hash,
  },
  promise: NewsletterAPI.unsubscribeNewsletter(hash, isPersonalized),
});

/**
 * Action creator which un-subscribes the visitor from the newsletter.
 * @param data
 */
export const completeUserData = (data) => ({
  type: COMPLETE_USER_DATA,
  payload: data,
  promise: NewsletterAPI.completeUserData(data),
});

/**
 * Action creator which un-subscribes the visitor from the newsletter.
 * @param token
 */
export const verifyNewsletterToken = (token) => ({
  type: VERIFY_NEWSLETTER_TOKEN,
  payload: {
    token,
  },
  promise: NewsletterAPI.verifyNewsletterToken(token),
});

/**
 * Action creator which gets the email from the hash provided by the logout link in the newsletter
 * @param hash
 */
export const getEmailFromHash = (hash) => ({
  type: GET_EMAIL_FROM_HASH,
  payload: {
    hash,
  },
  promise: NewsletterAPI.getEmailFromHash(hash),
});

export const optinNewsletter = (hash) => ({
  type: NEWSLETTER_OPTIN,
  payload: {
    hash,
  },
  promise: NewsletterAPI.optinNewsletter(hash),
});

export const verifyEmail = (token) => ({
  type: VERIFY_EMAIL_TOKEN,
  payload: {
    token,
  },
  promise: NewsletterAPI.verifyEmailToken(token),
});

export const sendOptoutReasons = (data) => ({
  type: SEND_OPTOUT_REASONS,
  promise: NewsletterAPI.sendOptoutReasons(data),
});

const actions = {
  subscribeToNewsletter,
  unsubscribeNewsletter,
  completeUserData,
  verifyNewsletterToken,
  getEmailFromHash,
  optinNewsletter,
  verifyEmail,
  sendOptoutReasons,
};

export default actions;
