import React from 'react';
import PropTypes from 'prop-types';
import { apiStatus } from 'constants/apiStatus/apiStatus';
import { TOP_OF_PRODUCTS_LIST, TOP_OF_PRODUCTS_OVERVIEW } from 'constants/scrollToElement/scrollToElement';
import CmsFacetsContainer from 'containers/CmsFacetsContainer/CmsFacetsContainer';
import CmsCategoryFacet from 'components/organisms/CategoryFacet/CmsCategoryFacet';
import ProductList from 'components/organisms/ProductList/ProductList';
import CmsPagination from 'components/molecules/CmsPagination/CmsPagination';
import { Col, Row } from 'components/atoms/Grid/Grid';
import Headline from 'components/atoms/Headline/Headline';
import Spinner from 'components/atoms/Spinner/Spinner';
import ProductsOverviewHeadline from 'components/atoms/ProductsOverviewHeadline/ProductsOverviewHeadline';
import ProductListContainer from 'containers/ProductListContainer/ProductListContainer';
import bootstrap from 'scss/component.scss';
import styles from './CmsProductListing.scss';
import ProductListHeader from 'components/molecules/ProductListHeader/ProductListHeader';
import NumberResults from 'components/atoms/NumberResults/NumberResults';
import FacetFilters from 'components/molecules/FacetFilters/FacetFilters';
import { FacetPropType } from 'constants/facetsHelper/facetsHelper';

export const searchQueryTypes = {
  BRAND: 'BRAND',
  PROMOTION: 'PROMOTION',
};

const CmsProductListing = ({
  prefetchedFacets,
  brand,
  changePage,
  changeSortOrder,
  currentPage,
  handleSliderChange,
  isBrandPage,
  numberOfPages,
  productsQuery,
  searchQueryType,
  sorts,
  totalNumberOfResults,
  visibleProducts,
  inputValue,
  requestStatus,
  submitFilters,
  hasFiltersApplied,
  prefetchFilter,
  resetFacet,
  resetAllFacets,
}) => {
  return (
    <>
      <Row>
        <Col xxs={12}>
          {searchQueryType !== searchQueryTypes.PROMOTION && (inputValue || brand) && (
            <div className={bootstrap.textCenter}>
              <ProductsOverviewHeadline
                category={brand}
                marginBottom={Headline.margins.MB_20}
                headlineTag={Headline.tags.H1}
              />
            </div>
          )}
        </Col>
      </Row>
      <Row id={TOP_OF_PRODUCTS_OVERVIEW}>
        <Col xxs={12} lg={3} className={bootstrap.hiddenMdDown}>
          <CmsFacetsContainer isBrandPage={isBrandPage} productsQuery={productsQuery} searchQueryType={searchQueryType}>
            <CmsCategoryFacet prefetchFilter={prefetchFilter} />
          </CmsFacetsContainer>
        </Col>
        <Col xxs={12} lg={9}>
          <section id={TOP_OF_PRODUCTS_LIST}>
            {totalNumberOfResults > 0 && (
              <>
                <NumberResults currentPage={currentPage} totalNumberOfResults={totalNumberOfResults} />

                <CmsFacetsContainer
                  isBrandPage={isBrandPage}
                  productsQuery={productsQuery}
                  searchQueryType={searchQueryType}
                >
                  <FacetFilters
                    prefetchedFacets={prefetchedFacets}
                    handleSliderChange={handleSliderChange}
                    submitFilters={submitFilters}
                    prefetchFilter={prefetchFilter}
                    totalNumberOfResults={totalNumberOfResults}
                    hasFiltersApplied={hasFiltersApplied}
                    resetFacet={resetFacet}
                    resetAllFacets={resetAllFacets}
                    isCmsPage
                  />
                </CmsFacetsContainer>

                <ProductListHeader sorts={sorts} onSortChange={changeSortOrder} />
                <ProductListContainer>
                  <ProductList products={visibleProducts} />
                </ProductListContainer>
              </>
            )}
            <div className={styles.pagination}>
              {currentPage && totalNumberOfResults && (
                <CmsPagination
                  currentPage={currentPage}
                  totalPages={numberOfPages}
                  scrollToId={TOP_OF_PRODUCTS_OVERVIEW}
                  changePage={changePage}
                />
              )}
            </div>
          </section>
        </Col>
      </Row>
      {requestStatus === apiStatus.request && (
        <div className={styles.spinner}>
          <Spinner status={requestStatus} />
        </div>
      )}
    </>
  );
};

CmsProductListing.displayName = 'organisms/CmsProductListing';
CmsProductListing.propTypes = {
  prefetchedFacets: FacetPropType,
  brand: PropTypes.string,
  changePage: PropTypes.func.isRequired,
  changeSortOrder: PropTypes.func.isRequired,
  currentPage: PropTypes.number,
  handleSliderChange: PropTypes.func,
  isBrandPage: PropTypes.bool.isRequired,
  numberOfPages: PropTypes.number,
  productsQuery: PropTypes.object.isRequired,
  searchQueryType: PropTypes.string,
  sorts: PropTypes.array,
  visibleProducts: PropTypes.array,
  inputValue: PropTypes.string,
  requestStatus: PropTypes.oneOf(Object.values(apiStatus)),
  submitFilters: PropTypes.func,
  prefetchFilter: PropTypes.func,
  resetFacet: PropTypes.func,
  resetAllFacets: PropTypes.func,
  totalNumberOfResults: PropTypes.number,
  hasFiltersApplied: PropTypes.bool,
};

export default CmsProductListing;
