import React, { Component } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  forgottenPasswordGenerateToken,
  initializeForgottenPasswordTokenSuccess,
} from 'actions/userActions/userActions';
import language from 'constants/language/language';
import dataTracking from 'constants/trackingAttributes/dataTracking';
import validation, { ValidationTypes } from 'constants/validation/validation';
import resolveErrorMessage from 'constants/resolveErrorMessage/resolveErrorMessage';
import { Modal } from '@interdiscount/component-library-interdiscount';
import LabelInput from 'components/molecules/FieldSetInput/LabelInput';
import MessagePasswordReset from 'components/molecules/MessagePasswordReset/MessagePasswordReset';
import ErrorMessageInput from 'components/molecules/FieldSetInput/ErrorMessageInput';
import Headline from 'components/atoms/Headline/Headline';
import InputText from 'components/atoms/InputText/InputText';
import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';
import styles from './PasswordReset.scss';
import bootstrap from 'scss/component.scss';

export const mapDispatchToProps = {
  forgottenPasswordGenerateToken,
  initializeForgottenPasswordTokenSuccess,
};

export const mapStateToProps = (state) => ({
  generateResetPasswordTokenSuccess: state.user.generateResetPasswordTokenSuccess,
  passwordResetFailureMessage: state.user.passwordResetFailureMessage,
});

class PasswordReset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      emailValidationResult: ValidationTypes.Valid,
      email: this.props.initialEmail,
    };
  }

  onKeyPress = (event) => {
    if (event.charCode === 13) {
      this.validateEmail();

      if (this.state.emailValidationResult === ValidationTypes.Valid) {
        this.resetPassword();
      }
    }
  };

  onEmailBlur = () => {
    this.validateEmail();
  };

  validateEmail = () => {
    const { email } = this.state;
    this.setState({
      emailValidationResult: email === '' ? ValidationTypes.Missing : validation.email(email),
    });
  };

  openModal = () => {
    this.setState({ modalIsOpen: true });
    this.setState({ email: this.props.initialEmail });
    this.props.initializeForgottenPasswordTokenSuccess();
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
    this.setState({ email: '' });
  };

  storeEmail = (email) => {
    this.setState({ email });
  };

  resetPassword = () => {
    const { email } = this.state;

    // we need to manually validate the email field because the onBlur validation
    // isn't working if the user presses the enter button
    const isValid = email === '' ? ValidationTypes.Missing : validation.email(email);

    if (isValid === ValidationTypes.Valid) {
      this.props.forgottenPasswordGenerateToken(email);
    }
  };

  render() {
    return (
      <>
        <button
          type="button"
          onClick={() => this.openModal()}
          className={styles.hintLink}
          tabIndex="-1"
          data-tracking={dataTracking({
            TYPE: 'form:button',
            FUNCTION: 'open-overlay',
            ATTRIBUTE_1: 'text',
            ATTRIBUTE_2: language('passwordReset.forgotPassword'),
          })}
        >
          {language('passwordReset.forgotPassword')}
        </button>
        {this.state.modalIsOpen && (
          <Modal onRequestClose={this.closeModal} isOpen={this.state.modalIsOpen}>
            {this.props.generateResetPasswordTokenSuccess ? (
              <MessagePasswordReset
                errorMessage={this.props.passwordResetFailureMessage}
                statusCode={this.props.generateResetPasswordTokenSuccess}
              />
            ) : (
              <div>
                <Headline tag={Headline.tags.H2}>{language('passwordReset.resetPassword')}</Headline>
                <p className={styles.explanatoryText}>{language('passwordReset.explanatoryText')}</p>
                <div className={bootstrap.row}>
                  <div className={bootstrap.colXl9}>
                    <LabelInput name="resetPassword" id="resetPassword">
                      {language('passwordReset.email')}
                    </LabelInput>
                    <InputText
                      type="email"
                      name="resetPassword"
                      pending={false}
                      value={this.state.email}
                      onBlur={this.onEmailBlur}
                      onKeyPress={this.onKeyPress}
                      handleChange={this.storeEmail}
                      showError={this.state.emailValidationResult !== true}
                      trackingAttributes={{
                        TYPE: 'form:field',
                        FUNCTION: 'focus',
                        ATTRIBUTE_1: 'text',
                        ATTRIBUTE_2: 'email',
                        ATTRIBUTE_3: 'recovery',
                      }}
                    />
                    <ErrorMessageInput showError={this.state.emailValidationResult !== true}>
                      {resolveErrorMessage(this.state.emailValidationResult, 'email')}
                    </ErrorMessageInput>
                  </div>
                  <div className={cx(bootstrap.colXl3, styles.button)}>
                    <ButtonLink
                      onClick={this.resetPassword}
                      variety={ButtonLink.varieties.flat}
                      size={ButtonLink.sizes.sm}
                      stretched
                      disabled={!this.state.email.length}
                      data-tracking={dataTracking({
                        TYPE: 'form:button',
                        FUNCTION: 'go-to',
                        ATTRIBUTE_1: 'neutral',
                        ATTRIBUTE_2: language('passwordReset.send'),
                        ATTRIBUTE_3: 'recovery',
                      })}
                    >
                      {language('passwordReset.send')}
                    </ButtonLink>
                  </div>
                </div>
              </div>
            )}
          </Modal>
        )}
      </>
    );
  }
}

PasswordReset.displayName = 'organisms/PasswordReset';
PasswordReset.propTypes = {
  forgottenPasswordGenerateToken: PropTypes.func,
  generateResetPasswordTokenSuccess: PropTypes.string,
  passwordResetFailureMessage: PropTypes.string,
  initializeForgottenPasswordTokenSuccess: PropTypes.func,
  initialEmail: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
