import { PRODUCT_SEARCH } from 'constants/routePaths/routePaths';

import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import { getCategoryUrl } from 'constants/categoryTree/categoryTree';
import { getProductLink } from 'constants/getUrlForProduct/getUrlForProduct';
import { getRelocalizedCmsUrlPath } from 'constants/navigation/navigation';
import { getLocale } from 'constants/language/language';

export const CATEGORY_REGEX = /--c(\d+)/;
export const OLD_PRODUCT_URL_REGEX = /(?:--c0.*?)?--p([\w]{3,})\/?/;
export const NEW_PRODUCT_URL_REGEX = /product\/.*-(\d{9,16})$/;

export const getSearchUrl = (locale) => mapPathToLocalizedUrl(locale, [PRODUCT_SEARCH]);

export const getProductCodeFromUrlPath = (path) => {
  const regex = path.includes('--p') ? OLD_PRODUCT_URL_REGEX : NEW_PRODUCT_URL_REGEX;
  const fullPath = path.match(regex);
  return fullPath ? fullPath[1] : fullPath;
};

export const getProductInfoFromUrlPath = (path) => {
  const decodedPath = decodeURIComponent(path);
  const decodedPathArray = decodedPath.split('/');

  let category, product, categoryName, categoryCode, productName, productCode;

  const newProductIndex = decodedPathArray.findIndex(
    (segment, index) =>
      segment === 'product' &&
      index < decodedPathArray.length - 1 &&
      /^[a-zA-Z-]+-\d{9,16}$/.test(decodedPathArray[index + 1])
  );

  if (newProductIndex !== -1) {
    product = decodedPathArray[newProductIndex + 1];

    category = null;
  } else {
    category = decodedPathArray.find((pathComponent) => pathComponent.includes('--c'));
    product = decodedPathArray.find((pathComponent) => pathComponent.includes('--p'));
  }

  if (!product) return null;

  if (category) {
    [categoryName, categoryCode] = category.split('--c');
  }

  if (product.includes('--p')) {
    if (!categoryCode) return null;
    [productName, productCode] = product.split('--p');
  } else {
    const productDetails = product.split('-');
    productCode = productDetails.pop();
    productName = productDetails.join('-');
  }

  return {
    categoryName,
    categoryCode,
    productName,
    productCode,
  };
};

export const getProductPathForSpecificCases = (path) => {
  const productInfo = getProductInfoFromUrlPath(path);
  return getProductLink(productInfo.categoryCode, productInfo.productName, productInfo.productCode, getLocale());
};

export const getCategoryCodeFromUrlPath = (path) => {
  // get categoryCode from url
  const regex = /--c([\d]{3,})\/?.*/;
  const fullPath = path.match(regex);
  return fullPath ? fullPath[1] : fullPath;
};

export const getLocalizedUrlPath = (path, newLocale) => {
  const regex = /^\/([a-z]{2})($|\/)(.*)/;
  const fullPath = path.match(regex);
  return fullPath ? path.replace(regex, `/${newLocale}/$3`) : false;
};

export const getRelocalizedUrlPath = (path, newLocale, pages, categories = {}) => {
  const productInfo = getProductInfoFromUrlPath(path);
  const productPath = productInfo
    ? getProductLink(productInfo.categoryCode, productInfo.productName, productInfo.productCode, newLocale, categories)
    : false;

  const categoryCode = getCategoryCodeFromUrlPath(path);
  const categoryPath = categoryCode ? getCategoryUrl(categoryCode, newLocale, categories) : false;

  const cmsPath = pages && getRelocalizedCmsUrlPath(pages, path, newLocale);

  const relocalizedPath = getLocalizedUrlPath(path, newLocale);

  // if it's not a productpath, not a categorypath, not a cmspath and there is no locale in the path, return given path
  return productPath || categoryPath || cmsPath || relocalizedPath || path;
};

export const changeToLocalizedPath = (newLocale, pages, categories = {}) => {
  if (!window || !window.location) {
    return;
  }
  const oldPath = window.location.pathname;
  window.location.pathname = getRelocalizedUrlPath(oldPath, newLocale, pages, categories);
};
