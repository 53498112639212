import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { cypressAttributes } from 'constants/cypress/cypress';

import Price from 'components/atoms/Price/Price';
import Image from 'components/atoms/Image/Image';

import getUrlForProduct from 'constants/getUrlForProduct/getUrlForProduct';
import language, { getLocale } from 'constants/language/language';
import imageSizes from 'constants/imageSizes/imageSizes';
import Icon, { ICON_SEARCH } from 'components/atoms/Icon/Icon';

import styles from './TeaserProductSmall.scss';
import { NextFeatureLink } from 'components/atoms/NextFeatureLink';

export const highlightSearch = (title, searchTerm) => {
  if (!searchTerm) return <span>{title}</span>;
  const start = title.toLowerCase().indexOf(searchTerm.toLowerCase());
  if (start === -1) return <span>{title}</span>;
  const end = start + searchTerm.length;

  const pre = title ? title.slice(0, start) : '';
  const term = title ? title.slice(start, end) : '';
  const post = title ? title.slice(end) : '';
  return (
    <span>
      {pre}
      <strong>{term}</strong>
      {post}
    </span>
  );
};

const TeaserProductSmall = ({
  name,
  code,
  customImageData,
  searchTerm = '',
  productClick = () => {},
  categoryCode = '',
  categoryName = '',
  price = {},
  isActive = false,
  categories = {},
  hideSearchPriceMobile = true,
  cyData,
}) => {
  const getImages = () => {
    if (customImageData.length === 0 || customImageData[0].sizes.length <= 1) return [];
    return customImageData[0].sizes;
  };

  const images = getImages();

  const onClickAction = () => {
    productClick();
  };

  const classDefinition = cx({
    [styles.wrapper]: true,
    [styles.active]: isActive,
  });

  return (
    <div className={classDefinition} data-cy={cyData}>
      <NextFeatureLink
        feature="nextPdpEnabled"
        className={styles.link}
        to={getUrlForProduct(categoryCode, name, code, getLocale(), categories)}
        onClick={onClickAction}
        data-cy={cypressAttributes.search.searchProduct}
      >
        <Icon path={ICON_SEARCH} className={styles.icon} />
        <div className={styles.info}>
          <div className={styles.productData}>
            <span className={styles.title}>{highlightSearch(name, searchTerm)}</span>
            <div className={styles.details}>
              <small className={styles.category}>
                {language('search.in')} {categoryName}
              </small>

              <div className={cx({ [styles.hidePrice]: hideSearchPriceMobile })}>
                <Price
                  showCentAsSup={false}
                  productPriceData={price}
                  skin={Price.SKINS.SEARCHSUGGEST}
                  cypressId={cypressAttributes.search.searchProductPrice}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.image} data-cy={cypressAttributes.search.searchProductImage}>
          <Image
            className={styles.image}
            alt="product image"
            imageId={code}
            images={images}
            size={imageSizes.searchAsYouType.size}
            responsive={imageSizes.searchAsYouType.responsive}
            code={customImageData[0].code}
          />
        </div>
      </NextFeatureLink>
    </div>
  );
};

TeaserProductSmall.displayName = 'molecules/TeaserProductSmall';

TeaserProductSmall.propTypes = {
  name: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  customImageData: PropTypes.array.isRequired,
  searchTerm: PropTypes.string,
  productClick: PropTypes.func,
  categoryCode: PropTypes.string,
  categoryName: PropTypes.string,
  price: PropTypes.object,
  isActive: PropTypes.bool,
  categories: PropTypes.object,
  hideSearchPriceMobile: PropTypes.bool,
  cyData: PropTypes.string,
};

TeaserProductSmall.defaultProps = {
  name: '',
  code: '',
  customImageData: [],
  categoryCode: '',
  categoryName: 'Misc',
  price: {},
  isActive: false,
  categories: {},
};

export default TeaserProductSmall;
