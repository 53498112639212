import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import { getEnv } from 'config/config';
import dataTracking from 'constants/trackingAttributes/dataTracking';
import language from 'constants/language/language';
import MarkdownBox from 'components/molecules/MarkdownBox/MarkdownBox';
import Headline from 'components/atoms/Headline/Headline';
import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';
import InternalOrExternalLink from 'components/atoms/InternalOrExternalLink/InternalOrExternalLink';
import bootstrap from 'scss/component.scss';
import styles from './CmsImageTextComponent.scss';
import { scrollToElement } from 'constants/scrollToElement/scrollToElement';

const formats = {
  IMAGE_LEFT_FLOAT: 'IMAGE_LEFT_FLOAT',
  IMAGE_LEFT: 'IMAGE_LEFT',
  IMAGE_RIGHT_FLOAT: 'IMAGE_RIGHT_FLOAT',
  IMAGE_RIGHT: 'IMAGE_RIGHT',
};

const CmsImageTextComponent = ({
  format = formats.IMAGE_LEFT,
  externalLink = false,
  imageAltText = '',
  title = '',
  text = '',
  imageUrl = '',
  link = '',
  button = {},
}) => {
  const float = format === formats.IMAGE_LEFT_FLOAT || format === formats.IMAGE_RIGHT_FLOAT;
  const right = format === formats.IMAGE_RIGHT || format === formats.IMAGE_RIGHT_FLOAT;

  if (!title && !text && !imageUrl) {
    return <div>{language('cmsFallback.missingContent')}</div>;
  }

  const scrollToId = __CLIENT__ && window.location.href.split('#')[1]; // get anchor id from url

  if (scrollToId) {
    requestAnimationFrame(() => {
      scrollToElement(scrollToId);
    });
  }

  return (
    <div className={bootstrap.row}>
      <div className={bootstrap.colMd12}>
        {title && (
          <Headline tag={Headline.tags.H2} margin={Headline.margins.MB_15}>
            {title}
          </Headline>
        )}
        <div className={cx(styles.content, { [styles.float]: float, [styles.right]: right })}>
          {imageUrl && (
            <div className={styles.imageWrapper}>
              <LazyLoad height={200}>
                {link ? (
                  <InternalOrExternalLink href={link} openNewTab={externalLink}>
                    <img className={styles.image} src={`${getEnv('api')}${imageUrl}`} alt={imageAltText} />
                  </InternalOrExternalLink>
                ) : (
                  <img className={styles.image} src={`${getEnv('api')}${imageUrl}`} alt={imageAltText} />
                )}
              </LazyLoad>
            </div>
          )}
          <div>
            {text && <MarkdownBox markdown={text} />}
            {button?.text && button?.link && (
              <div
                className={styles.bottomButton}
                data-tracking={dataTracking({
                  TYPE: 'button:click',
                  INTERACTION: 'click',
                  LINK: button.link,
                  ATTRIBUTE_1: 'secondary',
                  ATTRIBUTE_2: button.text,
                  FUNCTION: 'go-to',
                })}
              >
                <ButtonLink
                  href={button.link}
                  openNewTab={button.openNewTab}
                  variety={ButtonLink.varieties.stroked}
                  size={ButtonLink.sizes.MD}
                  isExternalLink={button.externalLink}
                >
                  {button.text}
                </ButtonLink>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

CmsImageTextComponent.displayName = 'organisms/CmsImageTextComponent';

CmsImageTextComponent.propTypes = {
  format: PropTypes.oneOf(Object.keys(formats)),
  title: PropTypes.string,
  text: PropTypes.string,
  imageUrl: PropTypes.string,
  link: PropTypes.string,
  imageAltText: PropTypes.string,
  externalLink: PropTypes.bool,
  button: PropTypes.shape({
    link: PropTypes.string,
    text: PropTypes.string,
    openNewTab: PropTypes.bool,
    externalLink: PropTypes.bool,
  }),
};

export default CmsImageTextComponent;
