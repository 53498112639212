import React from 'react';
import PropTypes from 'prop-types';

import AddService from './AddService';
import { getMobileProtectionServices, getWarantyServices } from 'constants/productServices/productServices';

import styles from './AddService.scss';

const getDisabledServices = (services = [], serviceItemCodes = []) => {
  let disabledServices = [];
  if (services.length > 0 && serviceItemCodes.length > 0) {
    for (let i = 0; i < services.length; i++) {
      if (serviceItemCodes.includes(services[i].code)) {
        disabledServices = disabledServices.concat(services[i].incompatibleServiceItemCodes);
      }
    }
  }

  return disabledServices;
};

const AddServices = ({ services, serviceItemCodes, updateServices }) => {
  const disabledServices = getDisabledServices(services, serviceItemCodes);
  const isSelected = (code) => serviceItemCodes.length > 0 && serviceItemCodes.includes(code);
  const isDisabled = (code) => disabledServices.includes(code);

  const garantyServices = getWarantyServices(services);
  const mobileProtectionServices = getMobileProtectionServices(services);

  return (
    <div className={styles.list}>
      <div className={mobileProtectionServices.length > 0 ? styles.serviceGroup : undefined}>
        {garantyServices.map((service) => (
          <AddService
            key={service.code}
            code={service.code}
            name={service.name}
            price={!!service.price && service.price.formattedValue}
            description={service.description}
            disabled={isDisabled(service.code)}
            handleChange={updateServices}
            selected={isSelected(service.code)}
            incompatibleServiceItemCodes={service.incompatibleServiceItemCodes}
          />
        ))}
      </div>
      {mobileProtectionServices.map((service) => (
        <AddService
          key={service.code}
          code={service.code}
          name={service.name}
          price={!!service.price && service.price.formattedValue}
          description={service.description}
          disabled={isDisabled(service.code)}
          handleChange={updateServices}
          selected={isSelected(service.code)}
          incompatibleServiceItemCodes={service.incompatibleServiceItemCodes}
        />
      ))}
    </div>
  );
};

AddServices.propTypes = {
  services: PropTypes.array.isRequired,
  updateServices: PropTypes.func.isRequired,
  serviceItemCodes: PropTypes.array,
};

AddServices.defaultProps = {
  serviceItemCodes: [],
};

export default AddServices;
