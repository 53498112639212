import uuid from 'uuid/v4';

import CartAPI from 'api/CartAPI/CartAPI';
import UserAPI from 'api/UserAPI/UserAPI';
import WatchlistAPI from 'api/WatchlistAPI/WatchlistAPI';

import { useEffect } from 'react';
import { getUser, rehydrateUserFields } from 'actions/userActions/userActions';
import { getCart, resetCart } from 'actions/cartActions/cartActions';
import { getWatchlist } from 'actions/watchlistActions/watchlistActions';
import { getCategories } from 'actions/categoryActions/categoryActions';
import { getNavigationNodes } from 'actions/cmsActions/cmsActions';
import { rehydrateGuestAddresses } from 'actions/addressActions/addressActions';
import {
  saveBreakpoint,
  initializeMainMenu,
  loadIsTopbarDismissed,
  loadFeatureConfig,
  loadFlashMessages,
} from 'actions/uiActions/uiActions';

import { initializeEnvVars } from 'config/config';
import { postLog } from 'api/promiseMiddleware';
import { PAYMENT, PAYMENT_SUCCESS } from 'constants/routePaths/routePaths';

import { CLIENT_BOOTSTRAP_FAILURE } from 'constants/ActionTypes/ActionTypes';
import {
  CART_ID,
  IS_LOGGED_IN,
  RECO_COOKIE,
  WINDOW_ID,
  ACCESS_TOKEN,
  ACCESS_TOKEN_EXPIRES,
  REFRESH_TOKEN,
  REFRESH_TOKEN_EXPIRES,
} from 'constants/storage/storageKeys';
import getCookieStorage from 'constants/storage/cookie';
import getStorage from 'constants/storage/storage';

const localStorage = getStorage(true);
const sessionStorage = getStorage(false);
let focus = true;
let wasLoggedIn = localStorage.getItem(IS_LOGGED_IN);

const dispatchGetWatchlistIfNeeded = (dispatch) => {
  const watchlistId = WatchlistAPI.getWatchlistId();
  if (watchlistId) {
    // has no stored WatchlistId
    return dispatch(getWatchlist());
  }

  return Promise.resolve(true);
};

const crossTabSync = (dispatch) => ({ key, oldValue, newValue }) => {
  if (oldValue === newValue || focus) return;

  if (key === IS_LOGGED_IN && oldValue === 'true' && newValue === 'false') {
    setTimeout(() => window.location.reload(), 3000);
  }
  if (key === IS_LOGGED_IN && oldValue === 'false' && newValue === 'true') {
    dispatch(getUser());
  }
  // When the CART_ID has been set to null in an other window (for example when an order has been placed)
  // we need to make sure to also reset the cart of other tabs
  useEffect(() => {
    if (key === CART_ID && oldValue !== null && newValue === null) {
      dispatch(resetCart());
    }
  }, [key, oldValue, newValue]);
};

const handleFocus = (dispatch) => () => {
  // We need to exclude the page account/userdata from focus handling, since a getUser call
  // might override user inputs.

  if (wasLoggedIn === 'true' && localStorage.getItem(IS_LOGGED_IN) === 'false') window.location.reload();
  focus = true;
  dispatchGetWatchlistIfNeeded(dispatch);
  dispatch(getCart());
};

const handleBlur = () => {
  focus = false;
  wasLoggedIn = localStorage.getItem(IS_LOGGED_IN);
};

const setRecoCookie = () => {
  const cookie = getCookieStorage();
  if (!cookie.getItem(RECO_COOKIE)) {
    cookie.setItem(RECO_COOKIE, uuid(), 10 * 365);
  }
};

const getValidCart = (dispatch) => {
  const href = window?.location?.href || '/';
  const isPaymentSuccessRoute = href.includes(`${PAYMENT}/${PAYMENT_SUCCESS}`);
  const cartId = CartAPI.getCartId();

  if (isPaymentSuccessRoute || !cartId) {
    return Promise.resolve(true);
  }

  return dispatch(getCart());
};

const setWindowID = () => {
  if (!sessionStorage.getItem(WINDOW_ID)) {
    sessionStorage.setItem(WINDOW_ID, uuid());
  }
};

const errorHandling = (error, dispatch) => {
  postLog(error);
  dispatch({
    type: CLIENT_BOOTSTRAP_FAILURE,
    error,
  });
};

const addEventListener = (dispatch) => {
  window.addEventListener('storage', crossTabSync(dispatch));
  window.addEventListener('focus', handleFocus(dispatch));
  window.addEventListener('blur', handleBlur);
};

const dispatchGuestActions = (dispatch) => {
  // all this actions can be done asynchronous
  Promise.all([
    rehydrateUserFields(dispatch),
    getValidCart(dispatch),
    dispatchGetWatchlistIfNeeded(dispatch),
    dispatch(rehydrateGuestAddresses()),
  ])
    .then(() => {
      addEventListener(dispatch);
    })
    .catch((error) => errorHandling(error, dispatch));

  return Promise.resolve(true);
};

const checkForOldAnonymousToken = () => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN);
  const refreshToken = localStorage.getItem(REFRESH_TOKEN);

  if (accessToken || refreshToken) {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(ACCESS_TOKEN_EXPIRES);
    localStorage.removeItem(REFRESH_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN_EXPIRES);
  }
};

const clientBootstrap = (dispatch) => {
  if (performance.mark) performance.mark('clientBootstrapStart');
  initializeEnvVars();

  setWindowID();
  setRecoCookie();

  dispatch(loadIsTopbarDismissed());
  dispatch(initializeMainMenu());
  dispatch(saveBreakpoint());

  if (__DEV__) {
    // In local development mode (npm run dev) serverside-only requests need to be
    // dispatched manually to load the required data. (In production mode this data
    // is loaded by the 'needs', see for example DefaultLayout.js)
    dispatch(getCategories());
    dispatch(getNavigationNodes());
    dispatch(loadFeatureConfig());
    dispatch(loadFlashMessages());
  }

  const isLoggedIn = JSON.parse(localStorage.getItem(IS_LOGGED_IN));

  if (isLoggedIn) {
    return UserAPI.checkAccessToken().then(
      () => {
        // all this actions can be done asynchronous
        Promise.all([getValidCart(dispatch), dispatch(getUser()), dispatchGetWatchlistIfNeeded(dispatch)])
          .then(() => {
            addEventListener(dispatch);
          })
          .catch((error) => errorHandling(error, dispatch));
      },
      () => dispatchGuestActions(dispatch)
    );
  }

  checkForOldAnonymousToken();

  return dispatchGuestActions(dispatch);
};

export default clientBootstrap;
