import FilterSliderNew from 'components/atoms/FacetFilterType/FilterSliderNew';
import FilterSelectboxNew from 'components/atoms/FacetFilterType/FilterSelectboxNew';
import React from 'react';
import PropTypes from 'prop-types';
import FilterToggle from 'components/atoms/FacetFilterType/FilterToggle';
import { FacetPropType, facetTypes } from 'constants/facetsHelper/facetsHelper';
import CategoryFacet from 'components/organisms/CategoryFacet/CategoryFacet';
import CmsCategoryFacet from 'components/organisms/CategoryFacet/CmsCategoryFacet';

const FacetTypeSelector = ({ facet, prefetchedFacet, handleSliderChange, prefetchFilter, breadcrumb, isCmsPage }) => {
  if (facet.code === 'categoryPath') {
    return isCmsPage ? (
      <CmsCategoryFacet categoryFacet={facet} prefetchFilter={prefetchFilter} />
    ) : (
      <CategoryFacet categoryFacet={facet} breadcrumb={breadcrumb} />
    );
  }

  if (facet.type === facetTypes.SLIDER) {
    return <FilterSliderNew facet={prefetchedFacet || facet} handleChange={handleSliderChange} />;
  }

  if (facet.type === facetTypes.TOGGLE) {
    return <FilterToggle facet={prefetchedFacet || facet} prefetchFilter={prefetchFilter} />;
  }

  return (
    <FilterSelectboxNew facet={facet} prefetchedFacet={prefetchedFacet || facet} prefetchFilter={prefetchFilter} />
  );
};

FacetTypeSelector.propTypes = {
  facet: FacetPropType.isRequired,
  prefetchedFacet: FacetPropType.isRequired,
  handleSliderChange: PropTypes.func,
  prefetchFilter: PropTypes.func,
  breadcrumb: PropTypes.array,
  isCmsPage: PropTypes.bool,
};
export default FacetTypeSelector;
