import { useEffect, cloneElement } from 'react';
import { connect } from 'react-redux';
import { toggleProductView as toggleProductViewAction } from 'actions/uiActions/uiActions';
import useInitialViewType from 'constants/ProductList/ProductListViewABTest';

const mapStateToProps = (state) => {
  const productViewType = state?.ui?.productViewType;

  return {
    viewType: productViewType,
  };
};

const mapDispatchToProps = {
  toggleProductView: toggleProductViewAction,
};

const ProductListContainer = ({ children, toggleProductView, viewType }) => {
  const initialView = useInitialViewType();

  useEffect(() => {
    if (initialView) {
      toggleProductView(initialView);
    }
  }, [initialView]);
  return cloneElement(children, { viewType: viewType ? viewType : initialView });
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductListContainer);
