import React from 'react';
import PropTypes from 'prop-types';
import SvgContainer from 'components/atoms/SvgContainer/SvgContainer';
import styles from 'components/molecules/HeadLogo/HeadLogo.scss';

const MorphingLogoInterdiscountSmall = ({ height, width, vbXCoords, vbYCoords, className, altText = '' }) => (
  <SvgContainer
    height={height}
    width={width}
    vbWidth={72}
    vbHeight={36}
    vbXCoords={vbXCoords}
    vbYCoords={vbYCoords}
    className={`${className ? `${className} ` : ''}${styles.morphing}`}
  >
    <g clipPath="url(#clip0_1656_13031)">
      <mask
        id="mask0_1656_13031"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="36"
        height="36"
      >
        <path
          d="M10.3657 0L25.6343 0C29.2387 0 30.5457 0.375291 31.8634 1.08001C33.1811 1.78473 34.2153 2.81887 34.92 4.13658C35.6247 5.45429 36 6.76133 36 10.3657V25.6343C36 29.2387 35.6247 30.5457 34.92 31.8634C34.2153 33.1811 33.1811 34.2153 31.8634 34.92C30.5457 35.6247 29.2387 36 25.6343 36H10.3657C6.76133 36 5.45429 35.6247 4.13658 34.92C2.81887 34.2153 1.78473 33.1811 1.08001 31.8634C0.375291 30.5457 0 29.2387 0 25.6343L0 10.3657C0 6.76133 0.375291 5.45429 1.08001 4.13658C1.78473 2.81887 2.81887 1.78473 4.13658 1.08001C5.45429 0.375291 6.76133 0 10.3657 0Z"
          fill="black"
        />
      </mask>
      <g mask="url(#mask0_1656_13031)">
        <path d="M36 0H0V36H36V0Z" fill="white" />
        <path
          d="M8.61328 11.5488C8.61328 9.11209 10.5886 7.13672 13.0254 7.13672C15.4621 7.13672 17.4375 9.11209 17.4375 11.5488C17.4375 13.9856 15.4621 15.9609 13.0254 15.9609C11.8551 15.9612 10.7327 15.4965 9.90525 14.669C9.07776 13.8415 8.613 12.7191 8.61328 11.5488Z"
          fill="#E20615"
        />
        <path d="M8.61328 16.5234H17.4375V28.8633H8.61328V16.5234Z" fill="#E20615" />
        <path
          d="M18.5977 28.8633C24.7823 28.8633 30.3398 23.9569 30.3398 17.9093C30.3398 11.8617 24.7779 7.13672 18.5977 7.13672V28.8633Z"
          fill="#E20615"
        />
      </g>
    </g>
    <g clipPath="url(#clip1_1656_13031)">
      <path d="M72 0H36V36H72V0Z" fill="white" />
      <path d="M55.5225 11.1074L61.5872 9.41239L66.1287 24.9632L60.064 26.6582L55.5225 11.1074Z" fill="#CEE7D5" />
      <path
        d="M46.4062 11.0968L46.4268 11.1673L50.953 26.6694L57.0258 24.9726L52.479 9.39941L46.4062 11.0968Z"
        fill="#CEE7D5"
      />
      <path
        d="M53.154 28.745C51.489 28.2789 50.5162 26.5621 50.9918 24.93L55.5245 9.37462C56 7.74248 57.7513 6.78886 59.4163 7.25502C61.0813 7.72118 62.0541 9.43792 61.5785 11.0701L57.0458 26.6254C56.5703 28.257 54.819 29.2112 53.154 28.745Z"
        fill="#00AE6E"
      />
      <path
        d="M43.9782 28.745C42.3133 28.2789 41.3405 26.5621 41.816 24.93L46.3487 9.37462C46.8242 7.74248 48.5755 6.78886 50.2405 7.25502C51.9055 7.72118 52.8783 9.43792 52.4028 11.0701L47.8701 26.6254C47.3939 28.257 45.6432 29.2112 43.9782 28.745Z"
        fill="#00AE6E"
      />
      <path
        d="M59.9414 25.7695C59.9414 27.4784 61.3656 28.8633 63.123 28.8633C64.8805 28.8633 66.3047 27.4778 66.3047 25.7695C66.3047 24.0613 64.8805 22.6758 63.123 22.6758C61.3662 22.6758 59.9414 24.0607 59.9414 25.7695Z"
        fill="#00AE6E"
      />
    </g>
    <defs>
      <clipPath id="clip0_1656_13031">
        <rect width="36" height="36" fill="white" />
      </clipPath>
      <clipPath id="clip1_1656_13031">
        <rect width="36" height="36" fill="white" transform="translate(36)" />
      </clipPath>
    </defs>

    {altText && <title>{altText}</title>}
  </SvgContainer>
);

MorphingLogoInterdiscountSmall.displayName = 'atoms/MorphingLogoInterdiscountSmall';

MorphingLogoInterdiscountSmall.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  vbXCoords: PropTypes.number,
  vbYCoords: PropTypes.number,
  className: PropTypes.string,
  altText: PropTypes.string,
};

MorphingLogoInterdiscountSmall.defaultProps = {
  height: 36,
};

export default MorphingLogoInterdiscountSmall;
