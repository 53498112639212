import React, { useMemo, useState } from 'react';
import styles from './FacetFilters.scss';

import cx from 'classnames';
import Icon, { ARROW_DROPDOWN, ICON_MENU } from 'components/atoms/Icon/Icon';
import language from 'constants/language/language';
import PropTypes from 'prop-types';
import FacetFilterType from 'components/atoms/FacetFilterType/FacetFilterType';
import AllFacetFilters from 'components/molecules/FacetFilters/AllFacetFilters';
import { BREAKPOINTS, smallerThan } from 'constants/breakpoints/breakpoints';
import {
  attachToArgument,
  CategoryPropType,
  checkDisplayFacet,
  FacetPropType,
  getFilterPreviewText,
  trackFacetModal,
} from 'constants/facetsHelper/facetsHelper';
import SelectedFilters from 'components/molecules/FacetFilters/SelectedFilters';
import useBreakpointChange from 'hooks/useBreakpointChange';
import priorizeNeutralDeliveryFilter from 'constants/priorizeNeutralDeliveryFilter/priorizeNeutralDeliveryFilter';
import Backdrop from 'components/atoms/Backdrop/Backdrop';

const SHOW_MAX_FILTERS = 6;

const FacetFilters = ({
  facets = [],
  prefetchedFacets,
  handleSliderChange,
  submitFilters,
  prefetchFilter,
  totalNumberOfResults,
  hasFiltersApplied,
  breadcrumb,
  resetFacet,
  resetAllFacets,
  isCmsPage,
  setMainMenuIsOpen,
  routing,
}) => {
  const pathname = routing.locationBeforeTransitions.pathname;
  const [openedFacet, setOpenedFacet] = useState();
  const [openedFacetIndex, setOpenedFacetIndex] = useState(null);
  const breakpoint = useBreakpointChange();
  const isMobile = smallerThan(breakpoint, BREAKPOINTS.LG);
  const facetsWithoutCategory = facets?.slice(1) ?? [];
  const isSearchPage = pathname.endsWith('/search');
  const isBrandPage = pathname.includes('/brand/');
  const facetsLength = isMobile
    ? priorizeNeutralDeliveryFilter(checkDisplayFacet(facets))?.length
    : priorizeNeutralDeliveryFilter(checkDisplayFacet(facetsWithoutCategory))?.length;

  const openFacet = (facetCode, index) => {
    prefetchFilter(null);
    if (!isCmsPage && !isSearchPage && !isBrandPage && facetCode === 'categoryPath') {
      setMainMenuIsOpen();
    } else {
      setOpenedFacet(facetCode);
      setOpenedFacetIndex(index);
    }
    trackFacetModal(facetCode, index, true);
  };

  const closeFacet = (index, track) => {
    setOpenedFacet(null);
    setOpenedFacetIndex(null);
    prefetchFilter(null);
    track && trackFacetModal(openedFacet, index, false);
  };

  const prefetchedFacet = useMemo(() => {
    return prefetchedFacets?.find((pf) => pf.code === openedFacet);
  }, [openedFacet, prefetchedFacets]);

  return (
    <div>
      <ul
        className={cx(styles.facetFiltersList, {
          [styles.desktopFacetFilters]: !isMobile,
          [styles.mobileFacetFilters]: isMobile,
        })}
      >
        {(isMobile
          ? priorizeNeutralDeliveryFilter(checkDisplayFacet(facets))
          : priorizeNeutralDeliveryFilter(checkDisplayFacet(facetsWithoutCategory))
        )
          ?.slice(0, facetsLength > SHOW_MAX_FILTERS ? SHOW_MAX_FILTERS - 1 : SHOW_MAX_FILTERS)
          .map((facet, index) => (
            <li key={facet.code} className={styles.facetFiltersItem}>
              <button className={styles.facetFiltersButton} onClick={() => openFacet(facet.code, index)}>
                <div className={styles.facetFiltersTitle}>
                  <span className={styles.facetFiltersSelected}>
                    {facet.code === 'categoryPath'
                      ? language('filter.category')
                      : facet.name ?? language('filter.more')}
                  </span>
                  <span className={styles.facetFiltersSelectedOptions}>{getFilterPreviewText(facet)}</span>
                </div>
                <Icon path={facet.code === 'categoryPath' ? ICON_MENU : ARROW_DROPDOWN} className={styles.chevron} />
              </button>

              {openedFacet === facet.code && (
                <FacetFilterType
                  facet={facet}
                  onClose={(track = true) => closeFacet(index, track)}
                  totalNumberOfResults={totalNumberOfResults}
                  submitFilters={submitFilters}
                  handleSliderChange={attachToArgument(handleSliderChange, { location: index })}
                  prefetchFilter={attachToArgument(prefetchFilter, { location: index })}
                  prefetchedFacet={prefetchedFacet}
                  breadcrumb={breadcrumb}
                  isCmsPage={isCmsPage}
                />
              )}
            </li>
          ))}

        {priorizeNeutralDeliveryFilter(checkDisplayFacet(isMobile ? facets : facetsWithoutCategory))?.length >
          SHOW_MAX_FILTERS && (
          <AllFacetFilters
            facets={priorizeNeutralDeliveryFilter(checkDisplayFacet(facetsWithoutCategory))}
            prefetchedFacets={priorizeNeutralDeliveryFilter(checkDisplayFacet(prefetchedFacets))}
            totalNumberOfResults={totalNumberOfResults}
            submitFilters={submitFilters}
            handleSliderChange={handleSliderChange}
            prefetchFilter={prefetchFilter}
            hasFiltersApplied={hasFiltersApplied}
            resetAllFacets={resetAllFacets}
          />
        )}
      </ul>
      <SelectedFilters
        facets={priorizeNeutralDeliveryFilter(checkDisplayFacet(facetsWithoutCategory))}
        resetFacet={resetFacet}
        resetAllFacets={resetAllFacets}
      />

      {openedFacet && <Backdrop onClose={(track = true) => closeFacet(openedFacetIndex, track)} />}
    </div>
  );
};

FacetFilters.propTypes = {
  facets: PropTypes.arrayOf(FacetPropType).isRequired,
  prefetchedFacets: PropTypes.arrayOf(FacetPropType),
  categories: PropTypes.arrayOf(CategoryPropType),
  handleSliderChange: PropTypes.func,
  submitFilters: PropTypes.func,
  prefetchFilter: PropTypes.func,
  resetAllFacets: PropTypes.func,
  totalNumberOfResults: PropTypes.number,
  hasFiltersApplied: PropTypes.bool,
  breadcrumb: PropTypes.array,
  resetFacet: PropTypes.func,
  isCmsPage: PropTypes.bool,
  setMainMenuIsOpen: PropTypes.func,
  routing: PropTypes.object,
};
FacetFilters.displayName = 'molecules/FacetFilters';
export default FacetFilters;
