import React from 'react';
import PropTypes from 'prop-types';
import bootstrap from 'scss/component.scss';

import CheckoutFooterLink from 'components/atoms/CheckoutFooterLink/CheckoutFooterLink';
import FooterHeadline from 'components/atoms/FooterHeadline/FooterHeadline';

const CheckoutFooterLinks = ({ className, links, headline = '' }) => (
  <div className={className}>
    {headline ? <FooterHeadline className={bootstrap.hiddenMdUp}>{headline}</FooterHeadline> : null}

    {links.map((link, key) => (
      <CheckoutFooterLink key={key} to={link.url} text={link.text} className={link.className} />
    ))}
  </div>
);

CheckoutFooterLink.displayName = 'molecules/CheckoutFooterLink';
CheckoutFooterLinks.propTypes = {
  className: PropTypes.string,
  links: PropTypes.array,
  headline: PropTypes.string,
};
CheckoutFooterLinks.defaultProps = {
  links: [],
  headline: '',
};

export default CheckoutFooterLinks;
