export const ASPECT_RATIOS = {
  ONE_BY_ONE: 'ONE_BY_ONE',
  TWO_BY_ONE: 'TWO_BY_ONE',
  THREE_BY_ONE: 'THREE_BY_ONE',
  FOUR_BY_ONE: 'FOUR_BY_ONE',
  SIX_BY_ONE: 'SIX_BY_ONE',
  THREE_BY_TWO: 'THREE_BY_TWO',
};

export const TEASER_TYPE = {
  CARD: 'CARD',
  STANDARD: 'STANDARD',
  COLOR: 'COLOR',
  IMAGE: 'IMAGE',
  HAPPYDAY: 'HAPPYDAY',
};

export const COLORS = {
  LIME_900: 'LIME_900',
  LIME_700: 'LIME_700',
  LIME_600: 'LIME_600',
  LIME_500: 'LIME_500',

  RASPBERRY_900: 'RASPBERRY_900',
  RASPBERRY_700: 'RASPBERRY_700',
  RASPBERRY_600: 'RASPBERRY_600',
  RASPBERRY_500: 'RASPBERRY_500',

  VANILLE_900: 'VANILLE_900',
  VANILLE_700: 'VANILLE_700',
  VANILLE_600: 'VANILLE_600',
  VANILLE_500: 'VANILLE_500',

  BLUEBERRY_900: 'BLUEBERRY_900',
  BLUEBERRY_700: 'BLUEBERRY_700',
  BLUEBERRY_600: 'BLUEBERRY_600',
  BLUEBERRY_500: 'BLUEBERRY_500',

  BLACK_900: 'BLACK_900',
  BLACK_700: 'BLACK_700',
  BLACK_600: 'BLACK_600',
  BLACK_500: 'BLACK_500',

  RED_900: 'RED_900',
  RED_700: 'RED_700',
  RED_600: 'RED_600',
  RED_500: 'RED_500',

  ORANGE: 'ORANGE',
  MAGENTA: 'MAGENTA',
  BLUE: 'BLUE',
  DARK_BLUE: 'DARK_BLUE',
  LIGHT_GRAY: 'LIGHT_GRAY',

  // used to mute react warnings due to invalid data on SBs/ACC
  DEFAULT: 'DEFAULT',
};

export const MAX_BRICKS_ID_WALL = 6;
