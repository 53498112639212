import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactRecaptcha from 'react-recaptcha';
import { Col, Row } from 'components/atoms/Grid/Grid';
import Link from 'components/atoms/ScrollingLink/ScrollingLink';
import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';
import InputSelect from 'components/atoms/InputSelect/InputSelect';
import ErrorMessageInput from 'components/molecules/FieldSetInput/ErrorMessageInput';
import FieldSetInput from 'components/molecules/FieldSetInput/FieldSetInput';
import { fieldNames } from 'components/organisms/Contact/Contact';
import FormGeneral from 'components/organisms/Contact/Contact.form.general';
import FormConsultingSales from 'components/organisms/Contact/Contact.form.consultingSales';
import FormFeedback from 'components/organisms/Contact/Contact.form.feedback';
import FormSuperpoints from 'components/organisms/Contact/Contact.form.superpoints';
import FormPriceConfirmation from 'components/organisms/Contact/Contact.form.priceConfirmation';
import FormInvoice from 'components/organisms/Contact/Contact.form.invoice';
import FormOnlineOrder from 'components/organisms/Contact/Contact.form.onlineOrder';
import FormAddress from 'components/organisms/Contact/Contact.form.address';
import FormRepair from 'components/organisms/Contact/Contact.form.repair';
import FormReturn from 'components/organisms/Contact/Contact.form.return';
import FormDelivery from 'components/organisms/Contact/Contact.form.delivery';
import FormCancelation from 'components/organisms/Contact/Contact.form.cancelation';
import FormReceipt from 'components/organisms/Contact/Contact.form.receipt';
import ContactField from 'containers/ContactField/ContactField';

import { onlyNumberCodeValidateChar } from 'constants/onlyNumberCodeValidateChar/onlyNumberCodeValidateChar';
import { onlyPhoneNumberValidateChar } from 'constants/onlyPhoneNumberValidateChar/onlyPhoneNumberValidateChar';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import language, { getLocale } from 'constants/language/language';
import { accountLogin } from 'constants/routePaths/routePathCombinations';
import { contactSubjects } from 'constants/contact/contact.helper';

import styles from './Contact.scss';

const forms = {
  [contactSubjects.GENERAL]: FormGeneral,
  [contactSubjects.CONSULTING_SALES]: FormConsultingSales,
  [contactSubjects.FEEDBACK]: FormFeedback,
  [contactSubjects.SUPERPOINTS]: FormSuperpoints,
  [contactSubjects.PRICE_CONFIRMATION]: FormPriceConfirmation,
  [contactSubjects.INVOICE]: FormInvoice,
  [contactSubjects.ONLINE_ORDER]: FormOnlineOrder,
  [contactSubjects.ADDRESS]: FormAddress,
  [contactSubjects.REPAIR]: FormRepair,
  [contactSubjects.RETURN]: FormReturn,
  [contactSubjects.DELIVERY]: FormDelivery,
  [contactSubjects.CANCELATION]: FormCancelation,
  [contactSubjects.RECEIPT]: FormReceipt,
};

// export the replaceMyAccountRoute function for testing
export const replaceMyAccountRoute = (text) => {
  if (!text) return null;
  const split = text.split('[a]');
  return (
    <div>
      {split?.[0] || ''}
      <Link to={mapPathToLocalizedUrl(getLocale(), accountLogin)}>{split?.[1] || ''}</Link>
      {split?.[2] || ''}
    </div>
  );
};

const ContactEmail = ({
  selectedSubject,
  changeContactType,
  handleChangeSubject,
  updateContactField,
  handleSubmit,
}) => {
  const recaptchaKey = __CLIENT__ && window.__ENV_VARIABLES__.recaptchaKey;
  const FormFields = forms?.[selectedSubject] || forms?.[contactSubjects.GENERAL];
  const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false);
  const [isRecaptchaInitial, setIsRecaptchaInitial] = useState(true);

  const getAdditionalText = () => {
    return selectedSubject === contactSubjects.ADDRESS || selectedSubject === contactSubjects.SUPERPOINTS ? (
      <div className={styles.infoBox}>
        <Col sm={12} md={8} className={styles.additionalTitle}>
          {language('service.contact.additionalTextTitle')}
        </Col>
        <Col sm={12} md={8} className={styles.additionalContent}>
          {selectedSubject === contactSubjects.ADDRESS
            ? replaceMyAccountRoute(language('service.contact.additionalTextAddressContent'))
            : language('service.contact.additionalTextSuperpointsContent')}
        </Col>
      </div>
    ) : null;
  };

  const telNumberValidateChar = (e) => {
    if (!onlyPhoneNumberValidateChar(e)) {
      e.preventDefault();
    }
  };

  const allowOnlyNumbers = (e) => {
    if (!onlyNumberCodeValidateChar(e)) {
      e.preventDefault();
    }
  };

  const handleRecaptchaVerify = (token) => {
    setIsRecaptchaVerified(Boolean(token));
    setIsRecaptchaInitial(false);
    updateContactField(fieldNames.recaptcha, token);
  };

  const hasRecaptchaError = () => {
    return !isRecaptchaInitial && !isRecaptchaVerified;
  };

  const checkAndHandleSubmit = () => {
    if (!isRecaptchaVerified && selectedSubject) {
      setIsRecaptchaInitial(false);
    }
    handleSubmit();
  };

  return (
    <fieldset>
      <Row className={styles.headerWrapper}>
        <Col sm={12} md={8}>
          <ContactField required fieldName={fieldNames.contactSubjects} handleChange={handleChangeSubject}>
            <FieldSetInput>
              <InputSelect selected={selectedSubject} />
            </FieldSetInput>
          </ContactField>
        </Col>
        {getAdditionalText()}
      </Row>
      <FormFields
        allowOnlyNumbers={allowOnlyNumbers}
        telNumberValidateChar={telNumberValidateChar}
        shouldShowPhoneDescription={true}
      />
      {selectedSubject !== '' && (
        <>
          <Row className={styles.recaptchaRow}>
            <Col>
              <ReactRecaptcha verifyCallback={handleRecaptchaVerify} sitekey={recaptchaKey} hl={getLocale()} />
              <ErrorMessageInput showError={hasRecaptchaError()}>
                {language('feedbackForm.recaptchaError')}
              </ErrorMessageInput>
            </Col>
          </Row>
        </>
      )}
      <Row>
        <Col md={12} className={styles.actionButton}>
          <ButtonLink size={ButtonLink.sizes.md} variety={ButtonLink.varieties.flat} onClick={checkAndHandleSubmit}>
            <span>{language('service.contact.send')}</span>
          </ButtonLink>
          <ButtonLink
            size={ButtonLink.sizes.md}
            variety={ButtonLink.varieties.stroked}
            onClick={() => {
              changeContactType(null);
              scrollTo(0, 0);
            }}
          >
            {language('service.contact.cancel')}
          </ButtonLink>
        </Col>
      </Row>
    </fieldset>
  );
};

ContactEmail.displayName = 'organisms/Contact.Email';

ContactEmail.propTypes = {
  selectedSubject: PropTypes.string,
  changeContactType: PropTypes.func,
  handleChangeSubject: PropTypes.func,
  updateContactField: PropTypes.func,
  handleSubmit: PropTypes.func,
};

export default ContactEmail;
