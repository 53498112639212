import React, { useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { isProductOrderable } from 'constants/deliveryStates/getDeliveryStatus';
import getUrlForProduct from 'constants/getUrlForProduct/getUrlForProduct';
import truncate from 'constants/helper/truncate';
import imageSizes from 'constants/imageSizes/imageSizes';
import language, { getLocale } from 'constants/language/language';

import RemoveFromCartLink from 'containers/RemoveFromCart/RemoveFromCartLink';
import DeliveryState, { deliverySkins } from 'components/molecules/DeliveryState/DeliveryState';
import ProductCartPegiFlag from 'components/molecules/ProductCartPegiFlag/ProductCartPegiFlag';
import AddToCartButton from 'components/molecules/AddToCartButton/AddToCartButton';
import EnergyEfficiency, {
  contexts as energyContexts,
  types,
} from 'components/atoms/EnergyEfficiency/EnergyEfficiency';
import { Col, Row } from 'components/atoms/Grid/Grid';
import Headline from 'components/atoms/Headline/Headline';
import IconLink from 'components/atoms/IconLink/IconLink';
import Image, { getAltText } from 'components/atoms/Image/Image';
import Price from 'components/atoms/Price/Price';
import bootstrap from 'scss/component.scss';
import styles from './WatchlistProduct.scss';
import dataTracking from 'constants/trackingAttributes/dataTracking';
import { NextFeatureLink } from 'components/atoms/NextFeatureLink';

const WatchlistProduct = ({ categories = {}, product = {}, removeProduct = () => {}, onProductClick }) => {
  const [isRemoveProductButtonDisabled, setIsRemoveProductButtonDisabled] = useState(false);

  const hasEnergyEfficiency =
    !!Object.keys(product?.energyEfficiency || {})?.length ||
    !!Object.keys(product?.energyEfficiencyEnEv2020 || {})?.length;
  return (
    <div className={bootstrap.clearfix}>
      <Row>
        <Col lg={2} className={cx(styles.GalleryContainer, bootstrap.hiddenMdDown)}>
          <div className={styles.PegiContainer}>
            <ProductCartPegiFlag pegiValue={product.pegiValue} />
          </div>
          <NextFeatureLink
            feature="nextPdpEnabled"
            to={getUrlForProduct(product.categoryCode, product.name, product.code, getLocale(), categories)}
            name={getAltText(imageSizes)}
            onClick={onProductClick}
          >
            <div className={cx(styles.Gallery)}>
              <Image
                alt={getAltText(product.customImageData)}
                images={product.customImageData && product.customImageData[0] && product.customImageData[0].sizes}
                size={imageSizes.productOrder.size}
                responsive={imageSizes.default.responsive}
                code={product.customImageData[0].code}
              />
            </div>
          </NextFeatureLink>
          {hasEnergyEfficiency && (
            <EnergyEfficiency
              energyEfficiency={product.energyEfficiency}
              energyEfficiencyEnEv2020={product.energyEfficiencyEnEv2020}
              type={types.sm}
              context={energyContexts.WATCHLIST}
            />
          )}
        </Col>
        <Col lg={10} className={styles.WatchlistProductContainer}>
          <div className={cx(styles.Gallery, bootstrap.hiddenLgUp)}>
            <div className={cx(styles.PegiContainer, bootstrap.hiddenLgUp)}>
              <ProductCartPegiFlag pegiValue={product.pegiValue} />
            </div>
            <NextFeatureLink
              feature="nextPdpEnabled"
              to={getUrlForProduct(product.categoryCode, product.name, product.code, getLocale(), categories)}
              name={getAltText(imageSizes)}
              onClick={onProductClick}
            >
              <Image
                alt={getAltText(product.customImageData)}
                images={product.customImageData && product.customImageData[0] && product.customImageData[0].sizes}
                size={imageSizes.productOrder.size}
                responsive={imageSizes.default.responsive}
                code={product.customImageData[0].code}
              />
            </NextFeatureLink>
            {hasEnergyEfficiency && (
              <EnergyEfficiency
                energyEfficiency={product.energyEfficiency}
                energyEfficiencyEnEv2020={product.energyEfficiencyEnEv2020}
                type={types.sm}
                context={energyContexts.WATCHLIST}
              />
            )}
          </div>

          <div className={styles.WatchlistProductHeadline}>
            <Headline tag={Headline.tags.H3} margin={Headline.margins.NONE} skin={Headline.skins.productname}>
              <NextFeatureLink
                feature="nextPdpEnabled"
                to={getUrlForProduct(product.categoryCode, product.name, product.code, getLocale(), categories)}
                name={product.name}
                onClick={onProductClick}
              >
                {truncate(product.name, 250)}
              </NextFeatureLink>
            </Headline>
            <div className={styles.deliveryState}>
              <DeliveryState
                skin={deliverySkins.CART}
                speedFlag={product.speedProduct}
                releaseDate={product.releaseDate}
                productOrderable={isProductOrderable(product.deliveryTime, product.productOrderable)}
                neutralDeliveryPossible={product.neutralDeliveryPossible}
                availability={product.availability}
              />
            </div>
          </div>

          <div className={styles.ActionsContainer}>
            <div className={styles.price}>
              <Price productPriceData={product.productPriceData} showInsteadPrice />
            </div>
            <div className={styles.addToCartButton}>
              <AddToCartButton
                product={product}
                buttonText={language('product.addToCart')}
                productAttributes={{ elementTitle: product.elementTitle }}
              />
            </div>
            <div className={styles.removeLink}>
              <IconLink
                onClick={() => {
                  setIsRemoveProductButtonDisabled(true);
                  removeProduct(product.code);
                }}
                disabled={isRemoveProductButtonDisabled}
              >
                <RemoveFromCartLink
                  trackingData={dataTracking({
                    TYPE: 'bookmark',
                    ATTRIBUTE_1: 'remove',
                    SKU: product.code,
                  })}
                  disabled={isRemoveProductButtonDisabled}
                />
              </IconLink>
            </div>
          </div>
        </Col>
      </Row>
      <div className={cx(bootstrap.clearfix, bootstrap.row)} />
    </div>
  );
};

WatchlistProduct.displayName = 'molecules/WatchlistProduct';
WatchlistProduct.propTypes = {
  categories: PropTypes.object,
  product: PropTypes.object,
  removeProduct: PropTypes.func,
  onProductClick: PropTypes.func,
};

export default WatchlistProduct;
