// @flow
import React, { Component } from 'react';
import TruncateMarkup from 'react-truncate-markup';

import styles from './TextEllipsis.scss';

type IProps = {
  text: string,
  showLines?: number,
  moreLabel?: string,
  lessLabel?: string,
  showLabel?: boolean,
};

type IState = {
  truncated: boolean,
};

export default class TextEllipsis extends Component<IProps, IState> {
  static defaultProps = {
    showLines: 2,
    moreLabel: 'Show more',
    lessLabel: 'Show less',
    showLabel: false,
  };

  state = {
    truncated: true,
  };

  toggleTruncate = () => {
    this.setState({ truncated: !this.state.truncated });
  };

  render() {
    const { text, showLines, moreLabel, lessLabel, showLabel } = this.props;

    if (this.state.truncated) {
      const readMoreEllipsis = (
        <span>
          ...{' '}
          {showLabel && (
            <span className={styles.link} onClick={this.toggleTruncate}>
              {moreLabel}
            </span>
          )}
        </span>
      );

      return (
        <TruncateMarkup lines={showLines} className={styles.textEllipsis} ellipsis={readMoreEllipsis}>
          <p className={styles.textEllipsis}>{text}</p>
        </TruncateMarkup>
      );
    }

    return (
      <p className={styles.textEllipsis}>
        {text}{' '}
        <span className={styles.link} onClick={this.toggleTruncate}>
          {lessLabel}
        </span>
      </p>
    );
  }
}
