import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { provideLinks, provideLink } from 'constants/navigation/navigation';
import { getLocale } from 'constants/language/language';

import SidebarNavigation from 'components/molecules/SidebarNavigation/SidebarNavigation';

export const mapStateToProps = (state) => ({
  pages: state?.cms?.navigation?.pages || {},
  anchor: state?.cms?.anchor || {},
  currentPath: state?.routing?.locationBeforeTransitions?.pathname,
});

const CmsSidebar = ({ anchor, pages, currentPath }) => {
  const header = provideLink(pages, pages[anchor].pageId, getLocale());
  const navigationLinks = provideLinks(pages, anchor, getLocale());

  return (
    <SidebarNavigation items={navigationLinks} text={header.text} baseRoute={header.url} currentPath={currentPath} />
  );
};

CmsSidebar.displayName = 'containers/CmsSidebar';
CmsSidebar.propTypes = {
  anchor: PropTypes.string,
  pages: PropTypes.object,
  currentPath: PropTypes.string,
};
CmsSidebar.defaultProps = {
  pages: {},
};

export default connect(mapStateToProps)(CmsSidebar);
