// @flow
import { clientProductReportTopics } from 'constants/productReportTypes/productReportTypes';
import language from 'constants/language/language';
import { ValidationTypes, email as emailValidationRegex } from 'constants/validation/validation';

export const generateInputTopicOptions = (topicEnum: {
  [$Keys<typeof clientProductReportTopics>]: $Values<typeof clientProductReportTopics>,
}): { [$Keys<typeof clientProductReportTopics>]: string } =>
  Object.keys(topicEnum).reduce(
    (prev, curr) => ({
      ...prev,
      [curr]: language(`feedbackForm.topic.${topicEnum[curr]}`) || '',
    }),
    {}
  );

export const validateTopic = (topic: string): $Keys<typeof ValidationTypes> =>
  topic === '' ? ValidationTypes.Missing : ValidationTypes.Valid;

export const validateMessage = (message: string): $Keys<typeof ValidationTypes> => {
  if (message === '') return ValidationTypes.Missing;
  if (message.length > 500) return ValidationTypes.Invalid;
  return ValidationTypes.Valid;
};

export const validateEmail = (email: string): $Keys<typeof ValidationTypes> =>
  emailValidationRegex(email) === ValidationTypes.Valid || email === ''
    ? ValidationTypes.Valid
    : ValidationTypes.Invalid;

export const validateRecaptcha = (
  recaptchaValidation: $Keys<typeof ValidationTypes>,
  loggedIn: boolean
): $Keys<typeof ValidationTypes> =>
  !loggedIn && recaptchaValidation !== ValidationTypes.Valid ? ValidationTypes.Invalid : ValidationTypes.Valid;
