/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { browserHistory } from 'react-router';
import PropTypes from 'prop-types';

import Icon, { ICON_GET_APP } from 'components/atoms/Icon/Icon';
import Headline from 'components/atoms/Headline/Headline';
import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';
import { StepperButtons } from 'components/molecules/Stepper/Stepper';
import ModalAPICallInProgress from 'components/organisms/ModalAPICallInProgress/ModalAPICallInProgress';
import ModalApiError from 'components/organisms/ModalApiError/ModalApiError';
import ReturnsAPI from 'api/ReturnsAPI/ReturnsAPI';
import language, { getLocale } from 'constants/language/language';

import styles from './ReturnConclusion.scss';

const PDF_STATUS = {
  INITIAL: 'INITIAL',
  CREATING: 'CREATING',
  CREATED: 'CREATED',
  FAILURE: 'FAILURE',
};

const ReturnConclusion = ({ onPrevClick = () => {}, activeStep = 0, state }) => {
  const [pdfStatus, setPDFCreationStatus] = useState(PDF_STATUS.INITIAL);

  const handleNextClick = () => {
    browserHistory.push(`/${getLocale()}/`);
  };

  const downloadPdf = (event) => {
    event.preventDefault();

    const tsStart = Date.now();
    const waitAtLeast500Ms = () =>
      Date.now() - tsStart > 500 ? setPDFCreationStatus(PDF_STATUS.CREATED) : setTimeout(waitAtLeast500Ms, 100);

    setPDFCreationStatus(PDF_STATUS.CREATING);
    ReturnsAPI.downloadLabel(state.returnLabelId, state.order?.code)
      .then(waitAtLeast500Ms)
      .catch(() => {
        setPDFCreationStatus(PDF_STATUS.FAILURE);
      });
  };

  return (
    <div>
      <p>{language('returns.returnConclusion.text')}</p>
      <p>
        {language('returns.returnConclusion.link1')}
        <span className={styles.buttonWrapper}>
          <ButtonLink
            onClick={downloadPdf}
            id="formControl-returnConclusionPdfDownload-button"
            variety={ButtonLink.varieties.flat}
          >
            <Icon path={ICON_GET_APP} size={Icon.sizes.small} />
            <span className={styles.pre}>{language('returns.returnConclusion.link2')}</span>
          </ButtonLink>
        </span>
      </p>
      <Headline tag={Headline.tags.H4}>{language('returns.returnConclusion.nextSteps')}</Headline>
      <ul>
        {language('returns.returnConclusion.steps').map((step) => (
          <li key={step}>{step}</li>
        ))}
      </ul>
      <StepperButtons
        onPrevClick={onPrevClick}
        onNextClick={handleNextClick}
        activeStep={activeStep}
        nextLabel={language('returns.returnConclusion.nextLabel')}
        submitOnEnter={false}
      />
      {pdfStatus === PDF_STATUS.CREATING && (
        <ModalAPICallInProgress>{language('myAccountOrderDetails.pdfCreationRequest')}</ModalAPICallInProgress>
      )}
      {pdfStatus === PDF_STATUS.FAILURE && (
        <ModalApiError isModalOpen requestCloseModal={() => setPDFCreationStatus(PDF_STATUS.INITIAL)} />
      )}
    </div>
  );
};

ReturnConclusion.displayName = 'molecules/ReturnSteps/ReturnConclusion';

ReturnConclusion.propTypes = {
  onPrevClick: PropTypes.func,
  activeStep: PropTypes.number,
  state: PropTypes.object,
};

export default ReturnConclusion;
