import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import dataTracking from 'constants/trackingAttributes/dataTracking';
import Icon, { ICON_CHECK } from 'components/atoms/Icon/Icon';
import styles from './InputCheckbox.scss';

const labelPositions = {
  RIGHT: 'right',
  LEFT: 'left',
};

const checkboxPositions = {
  TOP: 'top',
  CENTER: 'center',
};

const skins = {
  DEFAULT: '',
  RETURNS: 'returns',
};

const InputCheckbox = ({
  children,
  disabled = false,
  id = '',
  labelPos = labelPositions.RIGHT,
  checkboxPos = checkboxPositions.CENTER,
  onClick,
  pending = false,
  selected = false,
  showError = false,
  skin = skins.DEFAULT,
  trackingAttributes,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const onClickHandler = (e) => {
    if (!pending) {
      onClick(e);
    }
  };

  return (
    <label
      className={cx({
        [styles.wrap]: true,
        [styles.hasDanger]: showError && !isFocused,
        [styles.disabled]: disabled,
        [styles.pending]: pending,
        [styles[`labelPos-${labelPos}`]]: labelPos,
        [styles[skin]]: skin,
      })}
      id={id}
    >
      <span
        className={cx(styles.inner, {
          [styles[`inner-${checkboxPos}`]]: checkboxPos,
        })}
      >
        <i
          className={cx({
            [styles.indicator]: true,
            [styles.hasDanger]: showError && !isFocused,
            [styles.hasFocus]: isFocused,
            [styles.disabled]: disabled,
            [styles.pending]: pending,
          })}
        >
          {selected ? <Icon path={ICON_CHECK} /> : null}
        </i>
        <input
          type="checkbox"
          onFocus={() => {
            setIsFocused(true);
          }}
          onBlur={() => {
            setIsFocused(false);
          }}
          onChange={onClickHandler}
          checked={selected}
          className={styles.input}
          disabled={disabled}
          data-tracking={
            trackingAttributes &&
            dataTracking({
              ...trackingAttributes,
              FUNCTION: selected ? 'uncheck' : 'check',
              ATTRIBUTE_3: selected ? 'unchecked' : 'checked',
            })
          }
        />
        {children}
      </span>
    </label>
  );
};

InputCheckbox.labelPositions = labelPositions;
InputCheckbox.checkboxPositions = checkboxPositions;
InputCheckbox.skins = skins;

InputCheckbox.displayName = 'atoms/InputCheckbox';
InputCheckbox.propTypes = {
  children: PropTypes.any.isRequired,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  labelPos: PropTypes.oneOf(Object.values(labelPositions)),
  checkboxPos: PropTypes.oneOf(Object.values(checkboxPositions)),
  onClick: PropTypes.func.isRequired,
  pending: PropTypes.bool,
  selected: PropTypes.bool,
  showError: PropTypes.bool,
  skin: PropTypes.oneOf(Object.values(skins)),
  trackingAttributes: PropTypes.object,
};

export default InputCheckbox;
