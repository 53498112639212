import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import language, { getLocale } from 'constants/language/language';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import { ACCOUNT, ACCOUNT_LOGIN } from 'constants/routePaths/routePaths';
import { ValidationTypes } from 'constants/validation/validation';
import { apiStatus } from 'constants/apiStatus/apiStatus';
import { types as flashMessageTypes } from 'constants/flashMessages/flashMessages';

import FieldSetInput from 'components/molecules/FieldSetInput/FieldSetInput';
import PasswordCreation from 'components/molecules/PasswordCreation/PasswordCreation';
import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';
import InputText from 'components/atoms/InputText/InputText';
import FlashMessage from 'components/atoms/FlashMessage/FlashMessage';
import SpinnerOverlay from 'components/molecules/SpinnerOverlay/SpinnerOverlay';
import styles from './PasswordResetForm.scss';

const PasswordResetForm = ({
  user = {},
  updatePasswordField = () => {},
  forgottenPasswordRestorePassword = () => {},
  forgottenPasswordCheckToken = () => {},
  token = '',
  restorePasswordStatus = '',
  restorePasswordFailureMessage = '',
}) => {
  useEffect(() => {
    const path = mapPathToLocalizedUrl(getLocale(), [ACCOUNT, ACCOUNT_LOGIN]);

    forgottenPasswordCheckToken(token).then((isTokenValid) => {
      if (!isTokenValid) {
        browserHistory.push({ pathname: path });
      }
    });
  });

  const passwordResetHandle = () => {
    forgottenPasswordRestorePassword(token, user.fields?.password?.value).then((response) => {
      const path = mapPathToLocalizedUrl(getLocale(), [ACCOUNT, ACCOUNT_LOGIN]);
      if (response) {
        browserHistory.push({ pathname: path });
      }
    });
  };

  const isInvalid = () =>
    user.fields.password.validationResult === ValidationTypes.Missing ||
    user.fields.password.validationResult === ValidationTypes.PasswordToWeak;

  return (
    <>
      {restorePasswordStatus === apiStatus.failure && (
        <FlashMessage
          type={flashMessageTypes.ERROR}
          content={
            restorePasswordFailureMessage === language('passwordReset.resetFailureSameAsEmail')
              ? restorePasswordFailureMessage
              : language('passwordReset.messageFailureSubmit')
          }
        />
      )}
      <FieldSetInput disableMessage {...user.fields.password}>
        <PasswordCreation>
          <InputText
            type={'password'}
            handleChange={(value) => updatePasswordField(value, user.fields.email.value)}
            showError={isInvalid()}
          />
        </PasswordCreation>
      </FieldSetInput>
      <div className={styles.topMargin}>
        <ButtonLink onClick={passwordResetHandle} disabled={isInvalid()} stretched>
          {language('passwordReset.buttonSetPassword')}
        </ButtonLink>
      </div>
      {restorePasswordStatus === apiStatus.request && <SpinnerOverlay />}
    </>
  );
};

PasswordResetForm.displayName = 'molecules/PasswordResetForm';

PasswordResetForm.propTypes = {
  user: PropTypes.object,
  updatePasswordField: PropTypes.func,
  forgottenPasswordRestorePassword: PropTypes.func,
  forgottenPasswordCheckToken: PropTypes.func,
  token: PropTypes.string,
  restorePasswordStatus: PropTypes.string,
  restorePasswordFailureMessage: PropTypes.string,
};

export default PasswordResetForm;
