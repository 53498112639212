export const storeFinderSearchStatus = {
  initial: 'initial',
  googleError: 'googleError',
  noSearchTermProvided: 'noSearchTermProvided',
  empty: 'empty',
  emptyWithFilter: 'emptyWithFilter',
  success: 'success',
};

const getStoreFinderSearchStatus = ({
  resultsCount,
  searchTerm,
  hasActiveFilter,
  isSearchingByLocation,
  hasGoogleError,
}) => {
  let status = storeFinderSearchStatus.initial;

  if (hasGoogleError && resultsCount === 0) {
    // there was a problem with the google API
    status = storeFinderSearchStatus.googleError;
  } else {
    if (!!searchTerm || isSearchingByLocation) {
      // if a search term is provided
      if (resultsCount > 0) {
        // if there are any stores
        status = storeFinderSearchStatus.success;
      } else {
        // if there are no stores
        status = storeFinderSearchStatus.empty;

        if (hasActiveFilter) {
          // if there are no stores with an active filter
          status = storeFinderSearchStatus.emptyWithFilter;
        }
      }
    }
  }

  return status;
};

export default getStoreFinderSearchStatus;
