import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styles from './ProductSubnavigation.scss';

import ProductSubnavigationItems from 'components/molecules/ProductSubnavigation/ProductSubnavigationItems';
import CategoryDrilldownContainer from 'containers/CategoryDrilldownContainer/CategoryDrilldownContainer';

const ProductSubnavigation = ({ id = '', categoryTree, categoryCode, initialCategories, cyData }) => {
  const [canDrill, setCanDrill] = useState(true);

  return (
    <div itemScope itemType="https://schema.org/SiteNavigationElement" className={styles.container} data-cy={cyData}>
      <CategoryDrilldownContainer
        categories={initialCategories}
        canDrill={canDrill}
        setCanDrill={setCanDrill}
        categoryCode={categoryCode}
        categoryTree={categoryTree}
        resetNavigation={() => {}}
      >
        <ProductSubnavigationItems id={id} categoryCode={categoryCode} />
      </CategoryDrilldownContainer>
    </div>
  );
};

ProductSubnavigation.propTypes = {
  initialCategories: PropTypes.object,
  categoryCode: PropTypes.string,
  categoryTree: PropTypes.array,
  categories: PropTypes.array,
  cyData: PropTypes.string,
  id: PropTypes.string,
};

ProductSubnavigation.displayName = 'molecules/ProductSubnavigation';

export default ProductSubnavigation;
