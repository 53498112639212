import React from 'react';
import PropTypes from 'prop-types';
import language from 'constants/language/language';
import { checkout, checkoutGuest } from 'constants/routePaths/routePathCombinations';
import Meta from 'containers/Meta/Meta';
import AccountLogin from 'components/templates/AccountLogin/AccountLogin';
import NextFeatureEnabled from 'routes/ProductsOverview/NextFeatureEnabled';

const getRedirectUrl = () => {
  const url = new URL(window.location.href);

  // we need the redirect param for next support, i.e. redirect from login page to PDP
  return url.searchParams.get('redirect') ?? url.searchParams.get('redirectTo');
};

const Login = ({ location = {} }) => {
  const inCheckout = location.pathname.lastIndexOf('checkout') > -1;
  const pageTitle = inCheckout ? language('pageTitles.checkoutLogin') : language('pageTitles.accountLogin');

  let props = { redirectTo: [getRedirectUrl()] };

  if (location.state && location.state.skin === 'watchlist') {
    // user has been redirected from watchlist page
    props = {
      pageTitle: 'watchlist.accountHeader',
      questionText: 'watchlist.registration.content.text1',
      description: 'watchlist.registration.content.text2',
    };
  } else if (inCheckout) {
    // user needs to login/register during checkout
    props = {
      pageTitle: 'login.checkoutHeader',
      redirectTo: checkout,
      registrationHeadline: 'guestLogin.headline',
      linkTo: checkoutGuest,
      questionText: 'guestLogin.content.text1',
      description: 'guestLogin.content.text2',
      buttonText: 'guestLogin.button.text',
      showBenefits: false,
    };
  }

  return (
    <>
      <NextFeatureEnabled
        conditions={[
          {
            featureName: 'nextLoginRegistrationEnabled',
            include: 'login',
          },
        ]}
      />
      <Meta title={pageTitle} meta={[{ name: 'robots', content: 'noindex' }]} />
      <AccountLogin {...props} />
    </>
  );
};

Login.displayName = 'routes/AccountLogin';
Login.propTypes = {
  location: PropTypes.object,
};

export default Login;
