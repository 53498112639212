import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router';
import { Row, Col } from 'components/atoms/Grid/Grid';
import Icon, { ICON_CUBE, ICON_PICKUP, ICON_SHIELD, ICON_SHOWROOMS, ICON_TRUCK } from 'components/atoms/Icon/Icon';
import Headline from 'components/atoms/Headline/Headline';
import shuffle from 'constants/helper/shuffle';
import i18n from 'constants/language/language';

import styles from './UspElement.scss';

const uspIcons = {
  SHOWROOMS: ICON_SHOWROOMS,
  SHIELD: ICON_SHIELD,
  CUBE: ICON_CUBE,
  TRUCK: ICON_TRUCK,
  PICKUP: ICON_PICKUP,
};

const UspElement = ({ elements, title = i18n('footer.benefits.slogan') }) => {
  const [uspElements, setUspElements] = useState([]);

  useEffect(() => {
    setUspElements(shuffle(elements).splice(0, USP_ELEMENTS));
  }, [elements]);

  const USP_ELEMENTS = 3;

  return (
    <>
      {!!uspElements.length && (
        <Headline tag={Headline.tags.H2} alignment={Headline.alignments.CENTER} margin={Headline.margins.MB_15}>
          {title}
        </Headline>
      )}
      <Row className={styles.rowWrapper}>
        {uspElements.map((element, index) => (
          <Col key={index} xxs={12} md={4} className={styles.wrapper}>
            <div className={styles.uspElement}>
              <Link to={element.linkUrl} target={element?.linkUrl?.includes('https://') ? '_blank' : null}>
                <Row className={styles.row}>
                  <Col xl={2} md={3} xxs={2} className={styles.wrapper}>
                    <div className={styles.iconWrapper}>
                      <Icon path={uspIcons[element.icon.toUpperCase()]} size={Icon.sizes.md} />
                    </div>
                  </Col>
                  <Col xl={9} md={9} xxs={9} className={styles.textContainer}>
                    <p className={styles.title}>{element.title}</p>
                    <p className={styles.description}>{element.text}</p>
                  </Col>
                </Row>
              </Link>
            </div>
          </Col>
        ))}
      </Row>
    </>
  );
};

UspElement.displayName = 'molecules/UspElement';
UspElement.propTypes = {
  title: PropTypes.string,
  elements: PropTypes.array,
};

export default UspElement;
