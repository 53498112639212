import React from 'react';
import PropTypes from 'prop-types';
import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';
import Icon, { ICON_ADD, ICON_REMOVE } from 'components/atoms/Icon/Icon';
import cx from 'classnames';
import { cypressAttributes } from 'constants/cypress/cypress';
import styles from './Counter.scss';
import inputStyles from 'components/atoms/InputText/InputText.scss';

const Counter = ({
  isDisabled = false,
  maxQuantity = 200,
  minQuantity = 0,
  onDecrement,
  onIncrement,
  onChange,
  onKeyPress,
  quantity,
}) => (
  <div className={styles.container}>
    <ButtonLink
      variety={ButtonLink.varieties.stroked}
      size={ButtonLink.sizes.sm}
      onClick={onDecrement}
      disabled={quantity <= minQuantity || isDisabled}
      name="decrement"
      data-cy={cypressAttributes.cart.buttonMinus}
    >
      <Icon path={ICON_REMOVE} />
    </ButtonLink>
    <input
      className={cx(inputStyles.input, styles.quantity)}
      data-cy={cypressAttributes.cart.inputQty}
      type="text"
      value={quantity}
      onClick={(event) => event.target.select()}
      onKeyPress={(event) => onKeyPress(event)}
      onChange={(event) => {
        onChange(parseInt(event.target.value));
      }}
      pattern="[0-9]*"
    />
    <ButtonLink
      variety={ButtonLink.varieties.stroked}
      size={ButtonLink.sizes.sm}
      onClick={onIncrement}
      disabled={quantity >= maxQuantity || isDisabled}
      name="increment"
      data-cy={cypressAttributes.cart.buttonPlus}
    >
      <Icon path={ICON_ADD} />
    </ButtonLink>
  </div>
);

Counter.displayName = 'atoms/Counter';
Counter.propTypes = {
  isDisabled: PropTypes.bool,
  maxQuantity: PropTypes.number,
  minQuantity: PropTypes.number,
  onDecrement: PropTypes.func.isRequired,
  onIncrement: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func.isRequired,
  quantity: PropTypes.number.isRequired,
};

export default Counter;
