import { handleActions } from 'redux-actions';
import keyBy from 'constants/helper/keyBy';
import {
  GET_STORE_SUCCESS,
  GET_STORES_SUCCESS,
  GET_LOCATION_SUCCESS,
  GET_LOCATION_NAME_SUCCESS,
  SEARCH_STORE_SUCCESS,
  SEARCH_STORE_FAILURE,
  SEARCH_STORE_RESET,
  OPEN_STORE_MODAL,
  SET_DELIVERY_MODE_SUCCESS,
  OPEN_MY_STORE_MODAL,
  CLOSE_STORE_MODAL,
  CLOSE_MY_STORE_MODAL,
  PLACE_ORDER_SUCCESS,
  ROUTER_UPDATE_LOCATION,
} from 'constants/ActionTypes/ActionTypes';

export const initialState = {
  search: {},
  results: {},
};

export default handleActions(
  {
    [GET_STORE_SUCCESS]: (state, action) => ({
      ...state,
      selectedStore: action.req.data,
    }),

    [GET_STORES_SUCCESS]: (state, action) => {
      const newState = { ...state };
      const results = [];

      if (Array.isArray(action.req?.data?.results)) {
        action.req.data.results.forEach((store) => {
          results[store.name] = store;
        });
      }

      newState.results = keyBy(results, 'name');

      return {
        ...newState,
      };
    },

    [GET_LOCATION_SUCCESS]: (state, action) => {
      const newState = { ...state };

      if (action.req) {
        const latitude = parseFloat(action.req.latitude);
        const longitude = parseFloat(action.req.longitude);
        const locality = action.req.locality;

        if (locality) {
          newState.search = {
            locality,
            latitude,
            longitude,
          };
        } else {
          // if google answers are useless
          newState.search = {
            locality: '',
            latitude: undefined,
            longitude: undefined,
          };
        }
      } else {
        // if google api doesn't get any results
        newState.search = {
          locality: '',
          latitude: undefined,
          longitude: undefined,
        };
      }

      return newState;
    },

    [GET_LOCATION_NAME_SUCCESS]: (state, action) => {
      const newState = { ...state };

      if (action.req) {
        const name = action.req.locality;

        if (name) {
          newState.search.locality = name;
          newState.search.latitude = action.latitude;
          newState.search.longitude = action.longitude;
        }
      }

      return {
        ...newState,
      };
    },

    [SEARCH_STORE_SUCCESS]: (state, action) => {
      const newState = { ...state };
      const results = action.req.data.results;

      // array -> map
      newState.results = keyBy(results, 'name');

      return {
        ...newState,
      };
    },

    [SEARCH_STORE_FAILURE]: (state) => {
      const newState = { ...state };
      newState.results = {};

      return newState;
    },

    [SEARCH_STORE_RESET]: (state) => ({
      ...state,
      results: {},
    }),

    [OPEN_STORE_MODAL]: (state) => {
      const newState = { ...state };

      // clear all values
      newState.search.locality = '';
      newState.results = {};
      newState.storeModalOpen = true;

      return newState;
    },

    [OPEN_MY_STORE_MODAL]: (state) => {
      const newState = { ...state };

      newState.myStoreModalOpen = true;

      return newState;
    },

    [CLOSE_STORE_MODAL]: (state) => {
      const newState = { ...state };

      newState.storeModalOpen = false;

      return newState;
    },

    [CLOSE_MY_STORE_MODAL]: (state) => {
      const newState = { ...state };

      newState.myStoreModalOpen = false;

      return newState;
    },

    [SET_DELIVERY_MODE_SUCCESS]: (state, action) => {
      const newState = { ...state };
      if (action.payload.pointOfServiceId) {
        newState.selectedStore = state.results[action.payload.pointOfServiceId];
      }
      return newState;
    },

    [PLACE_ORDER_SUCCESS]: (state) => {
      const newState = { ...state };

      newState.selectedStore = undefined;

      return newState;
    },

    [ROUTER_UPDATE_LOCATION]: () => initialState,
  },
  initialState
);
