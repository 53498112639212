import React from 'react';
import PropTypes from 'prop-types';

import Icon, { ICON_MENU } from 'components/atoms/Icon/Icon';

import language from 'constants/language/language';
import { noop } from 'constants/noop/noop';
import styles from './MenuTrigger.scss';
import { trackMobileNavigationOpen } from 'tracking/tracking';

const MenuTrigger = ({ triggerMenu = noop, hasText = true }) => {
  const handleClick = () => {
    triggerMenu();
    trackMobileNavigationOpen();
  };

  return (
    <div className={styles.navigationItem} onClick={handleClick}>
      <Icon path={ICON_MENU} className={styles.icon} />
      {hasText && <small>{language('header.menu')}</small>}
    </div>
  );
};

MenuTrigger.displayName = 'atoms/MenuTrigger';

MenuTrigger.propTypes = {
  triggerMenu: PropTypes.func,
  hasText: PropTypes.bool,
};

export default MenuTrigger;
