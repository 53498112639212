import { Component, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

export const mapStateToProps = (state) => {
  const pages = state?.cms?.navigation?.pages || {};
  return {
    pages,
  };
};

class CmsFooter extends Component {
  static displayName = 'containers/CmsFooter';

  static propTypes = {
    pages: PropTypes.object,
    children: PropTypes.node.isRequired,
  };

  static defaultProps = {
    pages: {},
  };

  render() {
    const { pages, children } = this.props;
    return !Object.keys(pages).length
      ? null
      : cloneElement(children, {
          pages,
        });
  }
}

export default connect(mapStateToProps)(CmsFooter);
