// @flow

import { handleActions } from 'redux-actions';
import {
  GET_CMS_CONTENT_FOR_PAGE_SUCCESS,
  LOAD_PRODUCTS_FAILURE,
  LOAD_PRODUCTS_NETWORKERROR,
  LOAD_PRODUCTS_REQUEST,
  LOAD_PRODUCTS_SUCCESS,
  ROUTER_UPDATE_LOCATION,
  ROUTER_UPDATE_LOCATION_NEED,
  SET_BRAND,
} from 'constants/ActionTypes/ActionTypes';
import {
  mapQueryParamsToQueryString,
  mapQueryParamsToUrl,
  mapUrlToQueryParams,
  mergeQueryString,
  sanitizeQueryString,
} from 'constants/urlMapping/urlMapping';
import { getBreadcrumbs } from 'constants/getBreadcrumbs/getBreadcrumbs';
import language, { getLocale } from 'constants/language/language';
import transform from 'constants/reducerHelper/reducerHelper';

import type { Product } from 'reducers/products/products';
import type {
  ApiBreadcrumb,
  ApiFacet,
  ApiFacetValue,
  ApiProductsObject,
  ApiSpellingSuggestion,
  ProductsQueries,
  ProductsQuery,
  ProductsQueryFacet,
  ProductsQueryFacetValue,
  ProductsQuerySort,
} from 'reducers/productsQueries/productQueries';

import { facetTypes } from 'constants/facetsHelper/facetsHelper';

export const reduceVisibleProducts = (products: Array<Product> = []): Array<string> => products.map(({ code }) => code);

export const reduceCurrentSort = (sorts: Array<ProductsQuerySort>): string => sorts.find((sort) => sort.selected).code;

export const reduceCurrentCategoryTree = (breadcrumbs: Array<ApiBreadcrumb>): Array<number> => {
  const categoryPath = breadcrumbs.find((breadcrumb) => breadcrumb.facetCode === 'categoryPath');
  if (!categoryPath) return [];
  return categoryPath.facetValueCode.split('/').slice(1);
};

export const reduceProductsQueryFacetValue = (value: ApiFacetValue): ProductsQueryFacetValue => {
  const { code, count, name, requestQuery, selected = false }: ApiFacetValue = value;
  return {
    code,
    count,
    name,
    query: requestQuery,
    selected,
  };
};

export const reduceProductsQueryFacet = (facet: ApiFacet): ProductsQueryFacet => {
  const {
    category,
    code,
    multiSelect,
    name,
    priority,
    values = [],
    visible,
    min = 0,
    max = 10000,
    minSearched,
    maxSearched,
    solrFilterType = 'DEFAULT',
  }: ApiFacet = facet;
  const queryFacet: ProductsQueryFacet = {
    category,
    code,
    multiSelect,
    name,
    priority,
    values: values.map(reduceProductsQueryFacetValue),
    selectedValues: [],
    visible,
    type: solrFilterType,
  };

  if (solrFilterType === facetTypes.SLIDER) {
    const minValue = Math.floor(min);
    const maxValue = Math.ceil(max);

    queryFacet.minMaxRange = {
      min: minValue,
      max: maxValue,
      minSearched: Math.floor(minSearched) || minValue,
      maxSearched: Math.ceil(maxSearched) || maxValue,
    };
  }

  return queryFacet;
};

export const enrichFilterFacets = (filterFacets: Array<ProductsQueryFacet>, breadcrumbs: Array<ApiBreadcrumb>) => {
  const enrichedFilterFacets: Array<ProductsQueryFacet> = [...filterFacets];
  breadcrumbs.forEach((breadcrumb) => {
    if (enrichedFilterFacets.findIndex((facet) => facet.code === breadcrumb.facetCode)) {
      // create new facet
      const newFacet: ProductsQueryFacet = {
        category: false,
        multiselect: false,
        priority: 0,
        visible: true,
        code: breadcrumb.facetCode,
        name: breadcrumb.facetName,
        values: [],
        selectedValues: [],
      };
      enrichedFilterFacets.push(newFacet);
    }

    const facet = enrichedFilterFacets.find((filter) => filter.code === breadcrumb.facetCode);
    const selectedFacetValue = {
      code: breadcrumb.facetValueCode,
      name: breadcrumb.facetValueName,
      query: breadcrumb.customRemoveQuery,
      selected: true,
    };
    facet.selectedValues.push(selectedFacetValue);
  });

  return enrichedFilterFacets;
};

export const reduceProductsQueryFacets = (
  facets: ApiFacet[] = [],
  breadcrumbs: ApiBreadcrumb[]
): ProductsQueryFacet[] => {
  const filterFacets = facets.map(reduceProductsQueryFacet);
  return enrichFilterFacets(filterFacets, breadcrumbs);
};

export const reduceSpellingSuggestion = (spellingSuggestion: ApiSpellingSuggestion): string => {
  if (!spellingSuggestion) return '';
  return spellingSuggestion.suggestion;
};

export const getFilterCountByFacet = (facet: ProductsQueryFacet) => {
  let counter = 0;
  if (facet.type === 'SLIDER') {
    if (
      facet.minMaxRange &&
      (facet.minMaxRange.maxSearched !== facet.minMaxRange.max ||
        facet.minMaxRange.minSearched !== facet.minMaxRange.min)
    ) {
      counter++;
    }
  } else {
    counter = facet.selectedValues.length;
  }
  return counter;
};

export const reduceProductsQuery = (
  data: ApiProductsObject,
  queryString: string,
  catCode: ?string,
  categories: Object = {}
): ProductsQuery => {
  const {
    breadcrumbs,
    facets,
    freeTextSearch,
    pagination,
    products,
    sorts,
    spellingSuggestion,
    cmsContent,
    keywordRedirectUrl,
  }: ApiProductsObject = data;

  const out: ProductsQuery = {
    visibleProducts: reduceVisibleProducts(products),
    queryString,
    sort: reduceCurrentSort(sorts),
    categoryTree: reduceCurrentCategoryTree(breadcrumbs),
    facets: reduceProductsQueryFacets(facets, breadcrumbs),
    searchString: freeTextSearch,
    pagination,
    sorts,
    apiStatus: 'SUCCESS',
    spellingSuggestion: reduceSpellingSuggestion(spellingSuggestion),
    cmsContent,
    keywordRedirectUrl,
  };

  if (catCode) {
    // Fixes https://atlassian.interdiscount.ch/jira/browse/SPQR-10723
    out.breadcrumb = getBreadcrumbs(catCode, queryString, freeTextSearch, getLocale(), categories);
  }

  out.selectedFacetsCount = out.facets.reduce((total, facet) => {
    let counter = 0;
    if (facet.code !== 'categoryPath') {
      counter = getFilterCountByFacet(facet);
    }
    return total + counter;
  }, 0);

  return out;
};

export const reduceCurrentQuery = (url: string, state: Object, categories: Object): ProductsQuery => {
  if (categories === undefined) {
    return {};
  }

  const queryParams = mapUrlToQueryParams(url, categories);
  const currentQueryString = mapQueryParamsToQueryString(queryParams);
  const currentQuery: Object = !Object.keys(state[currentQueryString] || {}).length
    ? queryParams
    : state[currentQueryString];
  currentQuery.queryString = currentQueryString;

  currentQuery.originalSearchString = null;

  if (queryParams.searchString) {
    currentQuery.originalSearchString = decodeURI(queryParams.searchString);
  }

  if (!currentQuery.categoryCode) {
    currentQuery.categoryCode = queryParams.categoryCode;
  }

  if (queryParams.categoryCode !== undefined) {
    currentQuery.breadcrumb = getBreadcrumbs(
      queryParams.categoryCode,
      currentQueryString,
      queryParams.searchString,
      getLocale(),
      categories
    );
  } else {
    currentQuery.breadcrumb = [];
  }

  if (queryParams.searchString) {
    const href = mapQueryParamsToUrl({ ...queryParams, searchString: '' }, getLocale(), categories);

    currentQuery.breadcrumb.push({
      name: `${language('breadcrumb.search')} «${decodeURIComponent(queryParams.searchString || '') || ''}»`,
      href,
    });
  }

  if (!currentQuery.searchString) {
    currentQuery.searchString = decodeURI(queryParams.searchString);
  }

  return currentQuery;
};

export const createQueryParamsForBrandPage = (brand: string) => ({
  sort: 'relevance',
  facetQuery: `%3Abrand%3A${brand}`,
});

export const reduceQueryForBrand = (url: string, brand: string, state: Object): ProductsQuery => {
  const queryParamsFromUrl = mapUrlToQueryParams(url);
  const queryParamsForBrandPage = createQueryParamsForBrandPage(brand);
  const queryParams = {
    ...queryParamsFromUrl,
    ...queryParamsForBrandPage,
  };

  const currentQueryString = mapQueryParamsToQueryString(queryParams);
  const currentQuery: Object = !Object.keys(state[currentQueryString] || {}).length
    ? queryParams
    : state[currentQueryString];
  currentQuery.queryString = currentQueryString;

  if (queryParams.searchString) {
    const href = mapQueryParamsToUrl({ ...queryParams, searchString: '' }, getLocale());
    currentQuery.breadcrumb.push({
      name: `${language('breadcrumb.search')} «${queryParams.searchString || ''}»`,
      href,
    });
  }

  return currentQuery;
};

const initialState = {
  currentQuery: {},
};

type reduxAction = {
  type: 'string',
  payload?: Object,
  req?: Object,
};

export default handleActions(
  {
    [LOAD_PRODUCTS_SUCCESS]: (state: ProductsQueries, action: reduxAction): ProductsQueries => {
      const req = action.req;
      const payload = action.payload;
      const data = action.req?.data;
      const queryString = action.payload?.queryString;
      const categories = payload?.categories || {};

      if (req === undefined || data === undefined) return state;
      if (payload === undefined || queryString === undefined) return state;

      const newProductsQuery = reduceProductsQuery(data, queryString, state?.currentQuery?.categoryCode, categories);

      const newState = transform(state).setSingleKey(queryString, newProductsQuery);

      const currentQuery = state?.currentQuery;
      const currentQueryString = state?.currentQuery?.queryString;

      if (currentQuery && queryString === currentQueryString) {
        newState.set('currentQuery', {
          ...currentQuery,
          ...newProductsQuery,
          breadcrumb: currentQuery.breadcrumb,
          searchString: currentQuery.searchString,
        });
      }

      return newState.value();
    },

    [LOAD_PRODUCTS_REQUEST]: (state: ProductsQueries, action: reduxAction): ProductsQueries => {
      const payload = action.payload;
      const queryString = action.payload?.queryString;

      if (payload === undefined || queryString === undefined) return state;

      const newProductsQuery = {
        ...state[queryString],
        apiStatus: 'REQUEST',
      };

      const newState = transform(state).setSingleKey(queryString, newProductsQuery);

      const currentQuery = state?.currentQuery;
      const currentQueryString = state?.currentQuery?.queryString;

      if (currentQuery !== undefined && queryString === currentQueryString) {
        newState.set('currentQuery', {
          ...currentQuery,
          ...newProductsQuery,
          // fix: after first redirect the keywordRedirectUrl stays in the currentQuery.
          // Because of that, it's not possible to redirect the users again when they search
          // for the same term again. This is a reset of the keyWordRedirectUrl.
          keywordRedirectUrl: '',
        });
      }

      return newState.value();
    },

    [LOAD_PRODUCTS_FAILURE]: (state: ProductsQueries, action: reduxAction): ProductsQueries => {
      const payload = action.payload;
      const queryString = action.payload?.queryString;

      if (payload === undefined || queryString === undefined) return state;

      const newProductsQuery = {
        apiStatus: 'FAILURE',
      };

      const newState = transform(state).setSingleKey(queryString, newProductsQuery);

      const currentQuery = state?.currentQuery;
      const currentQueryString = state?.currentQuery?.queryString;

      if (currentQuery !== undefined && queryString === currentQueryString) {
        newState.set('currentQuery', {
          ...currentQuery,
          ...newProductsQuery,
        });
      }

      return newState.value();
    },

    [LOAD_PRODUCTS_NETWORKERROR]: (state: ProductsQueries, action: reduxAction): ProductsQueries => {
      const payload = action.payload;
      const queryString = action.payload?.queryString;

      if (payload === undefined || queryString === undefined) return state;

      const newProductsQuery = {
        apiStatus: 'NETWORKERROR',
      };

      const newState = transform(state).setSingleKey(queryString, newProductsQuery);

      const currentQuery = state?.currentQuery;
      const currentQueryString = state?.currentQuery?.queryString;

      if (currentQuery !== undefined && queryString === currentQueryString) {
        newState.set('currentQuery', {
          ...currentQuery,
          ...newProductsQuery,
        });
      }

      return newState.value();
    },

    [ROUTER_UPDATE_LOCATION]: (state, action) => {
      const { pathname, search, categories } = action.payload;
      const url = search ? `${pathname}${sanitizeQueryString(search)}` : `${pathname}`;
      // save current product query only for search and category page and brand page
      const regex = /--c\d+|(?:|\W)search[/]?\?search(?:|\W)+/g;
      if (!url.match(regex)) {
        return { ...state, currentQuery: {} };
      }
      return {
        ...state,
        currentQuery: reduceCurrentQuery(url, state, categories),
      };
    },
    [ROUTER_UPDATE_LOCATION_NEED]: (state, action) => {
      const { url, categories } = action.payload;
      // save current product query only for search and category page
      const regex = /--c\d+|(?:|\W)search[/]?\?search(?:|\W)+/g;
      if (!url.match(regex)) {
        return state;
      }
      return {
        ...state,
        currentQuery: reduceCurrentQuery(url, state, categories),
      };
    },

    [SET_BRAND]: (state, action) => {
      const { brand, pathname } = action.payload;
      const url = `${pathname}`;
      return {
        ...state,
        currentQuery: reduceQueryForBrand(url, brand, state),
      };
    },

    [GET_CMS_CONTENT_FOR_PAGE_SUCCESS]: (state, action) => {
      const req = action.req;
      const payload = action.payload;
      const data = action.req?.data;
      let queryString = action.req?.searchQuery;

      if (req === undefined || data === undefined) return state;

      if (payload === undefined || queryString === undefined) return state;

      queryString = mergeQueryString(queryString);

      const newProductsQuery = reduceProductsQuery(data, queryString);

      const newState = transform(state).setSingleKey(queryString, newProductsQuery);

      const currentQuery = state?.currentQuery;
      const currentQueryString = state?.currentQuery?.queryString;

      if (queryString === currentQueryString) {
        newState.set('currentQuery', {
          ...currentQuery,
          ...newProductsQuery,
        });
      }

      return newState.value();
    },
  },
  initialState
);
