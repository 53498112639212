import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setSearchString } from 'actions/uiActions/uiActions';
import {
  clearProductSuggestions,
  loadProductSuggestions,
  loadSuggestions,
  startedProductSearch,
} from 'actions/productActions/productActions';
import searchTypes from 'constants/inputSearch/inputSearch';
import { getLocale } from 'constants/language/language';
import ProductsFacet from 'containers/ProductsFacetContainer/ProductsFacetContainer';
import HeaderSearch from 'components/molecules/HeaderSearch/HeaderSearch';

import { SearchHistoryContext } from 'contexts/SearchHistoryProvider/SearchHistoryProvider';
import { trackSearchType } from 'tracking/tracking';
import { NON_CMS_PAGE } from 'constants/cms/cms';

export const mapStateToProps = (state) => ({
  searchString: state?.ui?.search,
  isSearchFocused: state?.ui?.searchFocus,
  isCmsPage: state.cms?.currentPageId !== NON_CMS_PAGE,
  isBrandPage: state.cms?.brandName !== '',
  isSearchPage: state.routing?.locationBeforeTransitions?.search !== '',
  pathname: state.routing?.locationBeforeTransitions?.pathname,
  keywordRedirectUrl: state.productsQueries?.currentQuery?.keywordRedirectUrl,
});
export const mapDispatchToProps = {
  _setSearchString: setSearchString,
  _loadProductSuggestions: loadProductSuggestions,
  _loadSuggestions: loadSuggestions,
  _clearProductSuggestions: clearProductSuggestions,
  _startedProductSearch: startedProductSearch,
};

const Search = ({
  searchString = '',
  isSearchFocused = false,
  isCmsPage = false,
  isBrandPage = false,
  isSearchPage = false,
  _setSearchString = () => {},
  _loadProductSuggestions = () => {},
  _loadSuggestions = () => {},
  _clearProductSuggestions = () => {},
  _startedProductSearch = () => {},
  type = searchTypes.header,
  isSticky = false,
  isFlashVisible = false,
  pathname = '',
  keywordRedirectUrl = '',
}) => {
  const { addSearchHistoryEntry } = useContext(SearchHistoryContext);
  const handleSuggestions = (searchTerm, additionalParameter = null) => {
    _loadSuggestions(searchTerm, undefined, additionalParameter, getLocale());
  };

  const trackSearch = (searchTerm, searchType = '') => {
    trackSearchType(searchTerm, searchType);
  };

  return (
    <ProductsFacet>
      <HeaderSearch
        searchString={searchString}
        setSearchString={_setSearchString}
        loadProductSuggestions={_loadProductSuggestions}
        loadSuggestions={handleSuggestions}
        clearProductSuggestions={_clearProductSuggestions}
        startedProductSearch={_startedProductSearch}
        isSearchFocused={isSearchFocused}
        isCmsPage={isCmsPage}
        isBrandPage={isBrandPage}
        isSearchPage={isSearchPage}
        type={type}
        isSticky={isSticky}
        isFlashVisible={isFlashVisible}
        trackSearch={trackSearch}
        pathname={pathname}
        addSearchEntry={addSearchHistoryEntry}
        keywordRedirectUrl={keywordRedirectUrl}
      />
    </ProductsFacet>
  );
};

Search.displayName = 'containers/Search';
Search.propTypes = {
  searchString: PropTypes.string,
  isSearchFocused: PropTypes.bool,
  isCmsPage: PropTypes.bool,
  isBrandPage: PropTypes.bool,
  isSearchPage: PropTypes.bool,
  _setSearchString: PropTypes.func,
  _loadProductSuggestions: PropTypes.func,
  _loadSuggestions: PropTypes.func,
  _clearProductSuggestions: PropTypes.func,
  _startedProductSearch: PropTypes.func,
  type: PropTypes.oneOf(Object.values(searchTypes)),
  isSticky: PropTypes.bool,
  isFlashVisible: PropTypes.bool,
  pathname: PropTypes.string,
  onlineId: PropTypes.string,
  email: PropTypes.string,
  keywordRedirectUrl: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
