import React from 'react';
import PropTypes from 'prop-types';
import DownloadLink from './DownloadLink';
import Headline from 'components/atoms/Headline/Headline';
import language from 'constants/language/language';
import { getEnv } from 'config/config';

import styles from './Downloads.scss';

const Downloads = ({ downloads = [] }) => {
  return (
    <div>
      <div className={styles.heading}>
        <Headline tag={Headline.tags.H3} margin={Headline.margins.MB_10}>
          {language('product.downloads')}
        </Headline>
      </div>
      <ul className={styles.list}>
        {downloads.map((download, i) => (
          <li key={i} className={styles.item}>
            <DownloadLink
              href={`${getEnv('googleStorageUrl')}/document/${download.code}`}
              title={download.title}
              type={download.type}
              size={download.size}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

Downloads.propTypes = {
  downloads: PropTypes.array,
};

export default Downloads;
