import React from 'react';
import PropTypes from 'prop-types';
import OrderConfirmation from './OrderConfirmation';
import CheckoutRatings from 'components/templates/CheckoutRatings/CheckoutRatings';
import Breadcrumb, {types} from 'components/organisms/Breadcrumb/Breadcrumb';
import GoogleSurvey from 'components/organisms/GoogleSurvey/GoogleSurvey';
import DownloadBox, {boxTypes} from 'components/molecules/DownloadBox/DownloadBox';
import Pagetitle from 'components/atoms/Pagetitle/Pagetitle';
import {customerGroup} from 'constants/customerGroup/customerGroup';
import {B2B_REGISTRATION} from 'constants/pdfTypes/pdfTypes';
import language from 'constants/language/language';
import {OrderThankYou} from "components/atoms/OrderThankYou/OrderThankYou";
import GuestAdvice from "components/organisms/GuestAdvice/GuestAdvice";
import User from "containers/User/User";
import isTouchDevice from "constants/isTouchDevice/isTouchDevice";
import ButtonLink from "components/atoms/ButtonLink/ButtonLink";
import {mapPathToLocalizedUrl} from "constants/urlMapping/urlMapping";
import {useFeatureToggle} from "hooks/index";

import styles from './CheckoutSuccess.scss';

const CheckoutSuccess = (props) => {
  const {
    orderedProducts,
    downloadB2BPdf,
    emailCustomer,
    guestCustomer,
    orderNumber,
    orderGuid,
    userGroup,
  } = props

  const isTouch = isTouchDevice()
  const nextHomepageEnabled = useFeatureToggle('nextHomepageEnabled') === "true" ?? false;

  const handleDownloadPdf = () => {
    downloadB2BPdf(B2B_REGISTRATION);
  };

  return (
    <div>
      <GoogleSurvey email={emailCustomer} orderCode={orderNumber} orderedProducts={orderedProducts}/>
      <div>
        <Breadcrumb type={types.detail} items={[{name: language("checkoutSuccess.title")}]}/>
        <div className={styles.container}>
          <Pagetitle>{language("checkoutSuccess.title")}</Pagetitle>
          <OrderThankYou/>
          <OrderConfirmation
            orderNumber={orderNumber}
            customerEmail={emailCustomer}
            orderGuid={orderGuid}
          />
          <div className={styles.center}>
            <img height={48} width={24} src={isTouch ? "/images/icon/touchScroll.svg" : "/images/icon/mouseScroll.svg"}
                 alt={"Scroll"}/>
          </div>
          {guestCustomer && (
            <User>
              <GuestAdvice email={emailCustomer}/>
            </User>
          )}
          {userGroup === customerGroup.B2BCUSTOMERGROUP && (
            <DownloadBox type={boxTypes.checkout} downloadB2BPdf={handleDownloadPdf}/>
          )}
          {!guestCustomer && <div><CheckoutRatings/></div>}
          <div className={styles.center}>
            <ButtonLink variety={ButtonLink.varieties.stroked} href={mapPathToLocalizedUrl()} outsideRouter={nextHomepageEnabled}>
              <span>{language('checkoutSuccess.backToHomepage')}</span>
            </ButtonLink>
          </div>
        </div>
      </div>
    </div>
  );
}
CheckoutSuccess.displayName = 'templates/CheckoutSuccess';

CheckoutSuccess.propTypes = {
  shouldHide: PropTypes.bool,
  orderNumber: PropTypes.string.isRequired,
  guestCustomer: PropTypes.bool.isRequired,
  emailCustomer: PropTypes.string.isRequired,
  userGroup: PropTypes.string,
  orderGuid: PropTypes.string,
  downloadB2BPdf: PropTypes.func,
  orderedProducts: PropTypes.array,
};

CheckoutSuccess.defaultProps = {
  shouldHide: true,
  userGroup: '',
  orderedProducts: [],
};

export default CheckoutSuccess
