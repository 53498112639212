import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import cx from 'classnames';
import { scrollUpToId } from 'constants/scrollToElement/scrollToElement';
import { noop } from 'constants/noop/noop';
import styles from './IconLink.scss';
import { NextFeatureLink } from '../NextFeatureLink/NextFeatureLink';

export const linkTargets = {
  BLANK: '_blank',
  UNDEFINED: undefined,
};

export const skins = {
  DEFAULT: 'DEFAULT',
  COMPARISON: 'COMPARISON',
  POPOVER: 'POPOVER',
};

export default class IconLink extends Component {
  static displayName = 'atoms/IconLink';

  static propTypes = {
    children: PropTypes.node.isRequired,
    disabled: PropTypes.bool,
    href: PropTypes.string,
    scrollToId: PropTypes.string,
    onClick: PropTypes.func,
    target: PropTypes.oneOf(Object.values(linkTargets)),
    skin: PropTypes.oneOf(Object.values(skins)),
    cyData: PropTypes.string,
    featureName: PropTypes.string,
  };

  static defaultProps = {
    disabled: false,
    onClick: noop,
    target: linkTargets.UNDEFINED,
    skin: skins.DEFAULT,
    featureName: null,
  };

  static linkTargets = linkTargets;

  handleClick = () => {
    if (this.props.scrollToId) {
      scrollUpToId(this.props.scrollToId);
    }
    this.props.onClick();
  };

  render() {
    const { children, disabled, href, target, skin, cyData, featureName } = this.props;

    let Tag = href && featureName ? Link : NextFeatureLink;

    return disabled ? ( // eslint-disable-line no-nested-ternary
      <div className={styles.disabled}>{children}</div>
    ) : (
      <div className={cx(styles.linkWrapper, { [styles[skin]]: styles[skin] })}>
        {href ? (
          <Tag
            className={styles.link}
            to={href}
            target={target}
            onClick={this.handleClick}
            data-cy={cyData}
            featureName={featureName}
          >
            {children}
          </Tag>
        ) : (
          <div onClick={this.handleClick}>{children}</div>
        )}
      </div>
    );
  }
}
