import React, { Component } from 'react';
import PropTypes from 'prop-types';
import language from 'constants/language/language';
import { scrollToElement } from 'constants/scrollToElement/scrollToElement';
import ContactPhone from 'components/organisms/Contact/Contact.Phone';
import ContactEmail from 'components/organisms/Contact/Contact.Email';
import LabelInput from 'components/molecules/FieldSetInput/LabelInput';
import InputRadioList from 'components/molecules/InputRadioList/InputRadioList';
import { Col, Row } from 'components/atoms/Grid/Grid';
import Headline from 'components/atoms/Headline/Headline';
import styles from './Contact.scss';

import { contactTypes } from 'constants/contact/contact.helper';

export const fieldNames = {
  contactSubjects: 'contactSubjects',
  copyWanted: 'copyWanted',
  recaptcha: 'recaptcha',
};

export default class Contact extends Component {
  static displayName = 'organisms/Contact';

  static propTypes = {
    successSent: PropTypes.bool,
    handleSubmit: PropTypes.func,
    handleChangeSubject: PropTypes.func,
    updateContactField: PropTypes.func,
    onContactTypeChange: PropTypes.func,
    contactType: PropTypes.string,
    visibleFields: PropTypes.array,
    selectedSubject: PropTypes.string,
    copyWanted: PropTypes.bool,
    firstError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  };

  static defaultProps = {
    successSent: false,
    handleSubmit: () => {},
    handleChangeSubject: () => {},
    updateContactField: () => {},
    onContactTypeChange: () => {},
    visibleFields: [],
    selectedSubject: '',
    copyWanted: false,
    firstError: false,
  };

  componentDidUpdate() {
    const { firstError } = this.props;
    if (firstError) {
      scrollToElement(`formControl-${firstError}`);
    }
  }

  render() {
    const {
      selectedSubject,
      successSent,
      contactType,
      onContactTypeChange,
      handleSubmit,
      updateContactField,
      handleChangeSubject,
    } = this.props;
    return (
      <div>
        {successSent && (
          <div className={styles.thanks}>
            <Headline margin={Headline.margins.MB_10}>{language('service.contact.sentTitle')}</Headline>
            <p>{language('service.contact.sentBody')}</p>
          </div>
        )}
        {!successSent && (
          <div>
            <LabelInput id="contactType">{language('service.contact.subline')}</LabelInput>
            <InputRadioList
              id="contactType"
              name="contactType"
              boldLabels
              options={{
                [contactTypes.PHONE]: language('service.contact.types.phone'),
                [contactTypes.EMAIL]: language('service.contact.types.email'),
              }}
              handleChange={onContactTypeChange}
              value={contactType}
            />
            {contactType === contactTypes.EMAIL && (
              <ContactEmail
                selectedSubject={selectedSubject}
                changeContactType={onContactTypeChange}
                handleChangeSubject={handleChangeSubject}
                handleSubmit={handleSubmit}
                updateContactField={updateContactField}
              />
            )}
            {contactType === contactTypes.PHONE && <ContactPhone />}
            <Row className={styles.bottomDivider}>
              <Col sm={12} md={8}>
                <hr />
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }
}
