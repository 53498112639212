import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed';
import uuid from 'uuid/v4';

import RcTooltip from 'rc-tooltip';
import IconInformation from 'components/atoms/icons/IconInformation/IconInformation';
import matchMedia from 'constants/matchMedia/matchMedia';
import { BREAKPOINTS } from 'constants/breakpoints/breakpoints';
import styles from './Tooltip.scss';

const Tooltip = ({ infoText }) => {
  let scrollTimeout = null;
  const destroyTooltipOnHide = true;
  const tooltipOffset = () => (matchMedia(BREAKPOINTS.MD) ? [8, -8] : [0, -8]);
  const id = `toolTip-${uuid()}`;

  useEffect(() => clearTimeout(scrollTimeout));

  const visibleChange = (visible) => {
    if (__CLIENT__) {
      scrollTimeout = setTimeout(() => {
        const elem = document.getElementById(id);
        if (visible && elem && !matchMedia(BREAKPOINTS.MD)) {
          scrollIntoViewIfNeeded(elem, {
            behavior: 'smooth',
            scrollMode: 'always',
          });
        }
      }, 100);
    }
  };

  return (
    <RcTooltip
      placement="topLeft"
      mouseEnterDelay={0.3}
      mouseLeaveDelay={0.1}
      trigger={['hover', 'click']}
      destroyTooltipOnHide={destroyTooltipOnHide}
      overlay={<div id={id}>{infoText}</div>}
      overlayClassName={styles.tooltip}
      align={{
        offset: tooltipOffset(),
      }}
      afterVisibleChange={visibleChange}
    >
      <span className={styles.infoIcon}>
        <IconInformation height={10} className={styles.infoIcon} />
      </span>
    </RcTooltip>
  );
};

Tooltip.propTypes = {
  infoText: PropTypes.string.isRequired,
};

Tooltip.displayName = 'atoms/Tooltip';

export default Tooltip;
