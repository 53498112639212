import React from 'react';
import PropTypes from "prop-types";

export function LogoInterdiscountBanner({size, altText}) {
  const renderPath = () => {
    return (
      <path
        style={{fill: "#E30613", scale: "50%"}}
        d="M0 7.5a7.5 7.5 0 1 1 15 0 7.5 7.5 0 0 1-15 0ZM0 16h15v21H0V16zM17 37c10.534 0 20-8.355 20-18.654C37 8.046 27.527 0 17 0v37Z"
      />
    );
  };

  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      {altText && <title>{altText}</title>}
      {renderPath()}
    </svg>
  );
}

LogoInterdiscountBanner.propTypes = {
  size: PropTypes.number,
  altText: PropTypes.string,
};

LogoInterdiscountBanner.defaultProps = {
  size: 19,
  altText: "Interdiscount Logo",
}
