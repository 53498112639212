import cloneDeep from 'lodash/cloneDeep';
import { stringify } from 'query-string';
import { browserHistory } from 'react-router';
import { getCredentials } from 'config/config';
import accountMappings from 'api/mappings/AccountMappings';
import addressMappings from 'api/mappings/AddressMappings';
import CartAPI from 'api/CartAPI/CartAPI';
import { postLog } from 'api/promiseMiddleware';
import restClient from 'api/RestClient/RestClient';
import throttle from 'constants/helper/throttle';
import { recaptchaAreas } from 'constants/recaptcha/recaptchaAreas';
import { resourceKeys } from 'constants/restResources/restResources';
import getStorage from 'constants/storage/storage';
import getCookieStorage from 'constants/storage/cookie';
import {
  ACCESS_TOKEN,
  ACCESS_TOKEN_EXPIRES,
  REFRESH_TOKEN,
  REFRESH_TOKEN_EXPIRES,
  ACCESS_TOKEN_CLIENT,
  USER_ACTIVE,
  USER_ID,
  ONLINE_ID,
  B2B_PRICE_GROUP,
  CUSTOMER_GROUP,
  IS_LOGGED_IN,
  PERSONALIZED_CONTENT,
  DSGV_CONSENTS,
} from 'constants/storage/storageKeys';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import { INVALID_PARAMS } from 'constants/promises/promises';

const storage = getStorage(true);
const cookie = getCookieStorage();

export const ANONYMOUS_USER_ID = 'anonymous';

const {
  USER_RESOURCE,
  TOKEN_RESOURCE,
  LOGOUT_RESOURCE,
  FORGOTTEN_PASSWORD_RESOURCE,
  RECAPTCHA_RESOURCE,
  VERIFY_RESOURCE,
  BASE_RESOURCE,
  SC_REFRESH_TOKEN
} = resourceKeys;

const UserAPI = (function UserAPI() {
  let activityInterval = null;

  const oneMinute = 60 * 1000;
  const timeToInacivityLogout = 25 * oneMinute; // 25 Minutes
  const timeToTrackActivity = oneMinute;
  const timeToCheckActivity = oneMinute;

  let tokenCreationActive = false;

  const getUserId = () => {
    const id = storage.getItem(USER_ID);

    try {
      if (id) return id.toLowerCase();
      return ANONYMOUS_USER_ID;
    } catch (e) {
      return ANONYMOUS_USER_ID;
    }
  };

  const setUserId = (userId) => {
    if (userId) {
      storage.setItem(USER_ID, userId.toLowerCase());
    }

    if (userId === ANONYMOUS_USER_ID) {
      storage.setItem(IS_LOGGED_IN, false);
    } else {
      storage.setItem(IS_LOGGED_IN, true);
    }
  };

  const getOnlineId = () => {
    const onlineId = storage.getItem(ONLINE_ID);

    if (onlineId) return onlineId;
    return undefined;
  };

  const setOnlineId = (onlineId) => {
    const isLoggedIn = storage.getItem(IS_LOGGED_IN);

    if (isLoggedIn) {
      if (onlineId) storage.setItem(ONLINE_ID, onlineId);
    }
  };

  const loadLoginType = () => {
    return localStorage.getItem(ACCESS_TOKEN_CLIENT);
  };

  const trackUserActivity = throttle(() => {
    storage.setItem(USER_ACTIVE, Date.now());
  }, timeToTrackActivity);

  const setActivityInterval = () => {
    trackUserActivity();
    document.addEventListener('click', trackUserActivity);
    document.addEventListener('scroll', trackUserActivity);
    document.addEventListener('mousemove', trackUserActivity);
    document.addEventListener('mousedown', trackUserActivity);
    document.addEventListener('keypress', trackUserActivity);

    clearInterval(activityInterval);
    activityInterval = setInterval(() => {
      const userActiveTimestamp = storage.getItem(USER_ACTIVE);
      if (userActiveTimestamp < Date.now() - timeToInacivityLogout) {
        logout().then(() => {
          browserHistory.push(mapPathToLocalizedUrl());
        });
      }
    }, timeToCheckActivity);
  };

  const removeActivityInterval = () => {
    clearInterval(activityInterval);
    document.removeEventListener('click', trackUserActivity);
    document.removeEventListener('scroll', trackUserActivity);
    document.removeEventListener('mousemove', trackUserActivity);
    document.removeEventListener('mousedown', trackUserActivity);
    document.removeEventListener('keypress', trackUserActivity);
    storage.removeItem(USER_ACTIVE);
  };

  const isSessionOnly = () => storage.getItem(REFRESH_TOKEN) === null && !!cookie.getItem(REFRESH_TOKEN);

  const resetUserId = () => {
    setUserId(ANONYMOUS_USER_ID);
  };

  /**
   *
   * Registers a customer
   * The following two sets of parameters are available:
   * First set is used to register a customer. In this case the required parameters are:
   *      login, password, firstName, lastName, titleCode.
   * Second set is used to convert a guest to a customer. In this case the required parameters are: guid, password.
   * @param UserDTO {
   *          "guid": "bf3f60ee-ff95-4b5b-a33d-1e4452f24407",
   *          "firstName": "Dominik",
   *          "lastName": "Rieder",
   *          "password": "1234"
   *          "login": "dominik.rieder@interdiscount.ch"
   *        }
   * @returns HTTP status code 201
   */
  const createUser = (userdata) => {
    if (!userdata) {
      return Promise.reject(INVALID_PARAMS);
    }

    const postData = {
      ...userdata,
      // convert date string to date
      birthday: new Date(userdata.birthday),
    };

    return restClient.post(USER_RESOURCE, '', postData);
  };

  const createUserAfterGuestCheckout = (password) => {
    if (!password) {
      return Promise.reject(INVALID_PARAMS);
    }

    const user = {
      password,
      guid: CartAPI.getLastOrderGuid(),
    };

    return restClient.post(USER_RESOURCE, '/anonymous/convertguest', user);
  };

  const saveAccessAndRefreshToken = (accessToken, refreshToken) => {
    const sessionOnly = isSessionOnly();
    if (sessionOnly === true) {
      cookie.setItem(ACCESS_TOKEN, accessToken);
      cookie.setItem(REFRESH_TOKEN, refreshToken);
    } else {
      storage.setItem(ACCESS_TOKEN, accessToken);
      storage.setItem(REFRESH_TOKEN, refreshToken);
    }

    tokenCreationActive = false;
  };

  const setAccessToken = (accessToken, accessTokenExpires, sessionOnly) => {
    storage.removeItem(ACCESS_TOKEN);
    storage.removeItem(ACCESS_TOKEN_EXPIRES);
    cookie.removeItem(ACCESS_TOKEN);
    cookie.removeItem(ACCESS_TOKEN_EXPIRES);

    if (sessionOnly === true) {
      cookie.setItem(ACCESS_TOKEN, accessToken);
      cookie.setItem(ACCESS_TOKEN_EXPIRES, accessTokenExpires);
    } else {
      storage.setItem(ACCESS_TOKEN, accessToken);
      storage.setItem(ACCESS_TOKEN_EXPIRES, accessTokenExpires);
    }
  };

  const removeAccessToken = () => {
    storage.removeItem(ACCESS_TOKEN);
    storage.removeItem(ACCESS_TOKEN_EXPIRES);
    cookie.removeItem(ACCESS_TOKEN);
    cookie.removeItem(ACCESS_TOKEN_EXPIRES);
  };

  const removeAccessTokenType = () => {
    localStorage.removeItem(ACCESS_TOKEN_CLIENT);
  };

  const setRefreshToken = (refreshToken, sessionOnly) => {
    const refreshTokenExpires = Date.now() + 60 * 24 * 60 * 60 * 1000; // 60 days
    if (sessionOnly === true) {
      storage.removeItem(REFRESH_TOKEN);
      storage.removeItem(REFRESH_TOKEN_EXPIRES);
      cookie.setItem(REFRESH_TOKEN, refreshToken);
      cookie.setItem(REFRESH_TOKEN_EXPIRES, refreshTokenExpires);
    } else {
      storage.setItem(REFRESH_TOKEN, refreshToken);
      storage.setItem(REFRESH_TOKEN_EXPIRES, refreshTokenExpires);
    }
  };

  const removeRefreshToken = () => {
    storage.removeItem(REFRESH_TOKEN);
    storage.removeItem(REFRESH_TOKEN_EXPIRES);
    cookie.removeItem(REFRESH_TOKEN);
    cookie.removeItem(REFRESH_TOKEN_EXPIRES);
  };

  /**
   * authenticate the user credentials
   * @param username
   * @param password
   * @returns {
   *             "access_token": "bb64d07a-7ebc-44cf-9356-8dffb336891a",
   *             "token_type": "bearer",
   *             "refresh_token": "1e03c108-d526-491c-ac9c-4c173d7c4554",
   *             "expires_in": 41346,
   *             "scope": "extended"
   *          }
   */
  const authenticateUser = (username, password, keepLogin) => {
    if (!username || !password) {
      return Promise.reject({ response: 'Missing Parameters' });
    }

    if (!__CLIENT__) return Promise.reject('you shall not login on the server');

    const credentials = {
      ...getCredentials(),
      grant_type: 'password',
      username: username.toLowerCase(),
      password,
    };
    return restClient
      .post(TOKEN_RESOURCE, '/token', stringify(credentials), 'application/x-www-form-urlencoded')
      .then(({ data }) => {
        if (keepLogin) {
          setAccessToken(data.access_token, Date.now() + data.expires_in * 1000);
          setRefreshToken(data.refresh_token);
          removeActivityInterval();
        } else {
          setAccessToken(data.access_token, Date.now() + data.expires_in * 1000, true);
          setRefreshToken(data.refresh_token, true);
          setActivityInterval();
        }
        setUserId(username);
        return data;
      })
      .catch((err) => {
        throw err;
      });
  };

  const loadRefreshToken = () => {
    const refreshTokenCookie = cookie.getItem(REFRESH_TOKEN);
    return refreshTokenCookie || storage.getItem(REFRESH_TOKEN);
  };

  const loadAccessToken = () => {
    const accessTokenCookie = cookie.getItem(ACCESS_TOKEN);
    const at = accessTokenCookie || storage.getItem(ACCESS_TOKEN);
    return at;
  };

  const cleanUpAfterLogout = (response) => {
    removeAccessToken();
    removeRefreshToken();
    removeActivityInterval();
    removeAccessTokenType();
    CartAPI.removeCartId();

    // keep dsgv consents
    const dsgvConsents = storage.getItem(DSGV_CONSENTS);
    // remove ALL remaining entries from localstorage
    storage.clear();
    // repopulate dsgv consents (SWAT-5927)
    storage.setItem(DSGV_CONSENTS, dsgvConsents);
    cookie.removeItem(B2B_PRICE_GROUP);

    setUserId(ANONYMOUS_USER_ID);
    setOnlineId('');

    return response;
  };

  const refreshAccessToken = () => {
    const refreshToken = loadRefreshToken();

    const credentials = {
      ...getCredentials(),
      refresh_token: refreshToken,
      grant_type: 'refresh_token',
    };

    if (!refreshToken) {
      cleanUpAfterLogout();
      return Promise.reject(false);
    }

    if (tokenCreationActive) {
      return Promise.resolve(true);
    }

    tokenCreationActive = true;

    // 'supercard' is set to localStorage on supercard side after successful login
    if (loadLoginType() === 'supercard') {
      return restClient
        .post(SC_REFRESH_TOKEN, '', {
          refreshToken,
        })
        .then((response) => {
          const accessToken = response?.data?.access_token;
          const refreshToken = response?.data?.refresh_token;
          saveAccessAndRefreshToken(accessToken, refreshToken);
          return Promise.resolve(true);
        })
        .catch((error) => {
          postLog(error);
          cleanUpAfterLogout();
        });
    }

    return restClient
      .postTokenless(TOKEN_RESOURCE, '/token', stringify(credentials), 'application/x-www-form-urlencoded')
      .then((response) => {
        const accessToken = response?.data?.['access_token'];
        const refreshToken = response?.data?.['refresh_token'];

        saveAccessAndRefreshToken(accessToken, refreshToken);

        return Promise.resolve(true);
      })
      .catch((error) => {
        postLog(error);

        cleanUpAfterLogout();
        return Promise.reject(false);
      });
  };

  const checkAccessToken = () => {
    const accessToken = loadAccessToken();

    return restClient
      .post(TOKEN_RESOURCE, `/check_token`, stringify({ token: accessToken }), 'application/x-www-form-urlencoded')
      .then((response) => {
        const username = response?.data?.['user_name'];

        if (username) {
          return Promise.resolve(true);
        }

        return Promise.reject(false);
      })
      .catch((error) => {
        postLog(error);

        removeAccessToken();

        return refreshAccessToken();
      });
  };

  /**
   * Returns customer profile
   * @returns HTTP status code 201
   */
  const getUser = (fields = 'FULL') =>
    restClient.get(USER_RESOURCE, `/${getUserId()}?fields=${fields}`).then((response) => {
      const resp = cloneDeep(response);

      if (__CLIENT__) {
        const group = resp?.data?.group;
        if (group) {
          localStorage.setItem(CUSTOMER_GROUP, group);
        }

        localStorage.setItem(PERSONALIZED_CONTENT, resp?.data?.personalizedNewsletter);

        const customerGroup = resp?.data?.customerGroup;
        if (customerGroup) {
          cookie.setItem(B2B_PRICE_GROUP, customerGroup);
        } else {
          // delete in case that something in the BE changed
          cookie.removeItem(B2B_PRICE_GROUP);
        }
      }

      if (resp && resp.data) {
        resp.data.isLinkedWithSCID = true;
        if (resp.data.addresses) {
          resp.data.addresses = resp.data.addresses.map((address) => addressMappings.mapAddressDataToAddress(address));
        }
        if (resp.data.onlineId) {
          setOnlineId(resp.data.onlineId);
        }
        if (resp.data.firstName) {
          const {
            titleCode,
            group,
            firstName,
            lastName,
            language,
            supercardCode,
            employeeEan,
            birthday,
            email,
            onlineId,
            password,
            oldPassword,
            newPassword,
          } = accountMappings.mapAccountDataToAccount(resp.data).fields;

          resp.data.fields = {
            ...resp.data.fields,
            titleCode,
            group,
            firstName,
            lastName,
            language,
            supercardCode,
            employeeEan,
            birthday,
            email,
            onlineId,
            password,
            oldPassword,
            newPassword,
          };
        }
        return resp;
      }
      return Promise.reject('No data received');
    });

  const getUserConsents = () => restClient.get(USER_RESOURCE, `/${getUserId()}/consents`);

  const updateUserConsents = (consents) => restClient.put(USER_RESOURCE, `/${getUserId()}/consents`, consents);

  /**
   * Updates customer profile
   * @returns HTTP status code 201
   */
  const putCustomer = (user, shouldMap) => {
    if (!user) {
      return Promise.reject(INVALID_PARAMS);
    }

    let finalUser = user;

    if (shouldMap) {
      finalUser = accountMappings.mapAccountToAccountData({ ...user });
    }

    return restClient.put(USER_RESOURCE, `/${getUserId()}`, finalUser);
  };

  /**
   * Removes customer profile
   * @returns HTTP status code 201
   */
  const deactivateCurrentUser = () => restClient.del(USER_RESOURCE, `/${getUserId()}`, {});

  /**
   * Returns customer's addresses
   * @returns AddressData
   */
  const getAddresses = () =>
    restClient.get(USER_RESOURCE, `/${getUserId()}/addresses`).then((response) => {
      if (Array.isArray(response.data.addresses)) {
        return response.data.addresses.map((addressData) => addressMappings.mapAddressDataToAddress(addressData));
      }
      return response;
    });

  /**
   * Creates a new address
   * @param address
   * @returns AddressWsDTO
   */
  const createAddress = (address) => {
    if (!address) {
      return Promise.reject(INVALID_PARAMS);
    }

    const addressData = addressMappings.mapAddressToAddressData(address);

    return new Promise((resolve, reject) => {
      restClient.post(USER_RESOURCE, `/${getUserId()}/addresses`, addressData).then(
        (response) => {
          resolve(addressMappings.mapAddressDataToAddress(response.data));
        },
        (error) => reject(error)
      );
    });
  };

  /**
   * Returns detailed information about address with a given id.
   * @param addressId
   * @returns AddressWsDTO
   */
  const getAddress = (addressId) => {
    if (!addressId) {
      return Promise.reject('Invalid Parameter addressId');
    }

    return restClient
      .get(USER_RESOURCE, `/${getUserId()}/addresses/${addressId}/`)
      .then((response) => addressMappings.mapAddressDataToAddress(response.data));
  };

  /**
   * Updates the address. Attributes not provided in the request will be defined again (set to null or default).
   * @param addressId
   * @param address
   * @returns HTTP status code 201
   *
   */
  const putAddress = (addressId, address) => {
    if (!addressId || !address) {
      return Promise.reject(INVALID_PARAMS);
    }

    const addressData = addressMappings.mapAddressToAddressData(address);

    return new Promise((resolve, reject) => {
      restClient.put(USER_RESOURCE, `/${getUserId()}/addresses/${addressId}/`, addressData).then(
        (response) => {
          resolve(addressMappings.mapAddressDataToAddress(response.data));
        },
        (error) => reject(error)
      );
    });
  };

  /**
   * Removes customer's address.
   * @param addressId
   * @returns HTTP status code 201
   */
  const deleteAddress = (addressId) => {
    if (!addressId) {
      return Promise.reject('Invalid Parameter addressId');
    }

    return restClient.del(USER_RESOURCE, `/${getUserId()}/addresses/${addressId}/`, {});
  };

  /**
   * Changes customer's login. (the login email address)-> customer's new login.
   * @param password -> Customer's current password.
   * @returns HTTP status code 201
   */
  const changeLogin = (password, newLogin) => {
    if ([password, newLogin].some((val) => val === undefined)) {
      return Promise.reject(INVALID_PARAMS);
    }

    const credentials = {
      newLogin: newLogin.toLowerCase(),
      password,
    };

    return restClient.put(USER_RESOURCE, `/${getUserId()}/login/`, credentials);
  };

  /**
   * Changes customer's password.
   * @param password -> Old Password
   * @param newPassword -> New Password
   * @returns HTTP status code 201
   */
  const changePassword = (password, newPassword) => {
    if (!password || !newPassword) {
      return Promise.reject(INVALID_PARAMS);
    }

    const credentials = {
      password,
      newPassword,
    };

    return restClient.put(USER_RESOURCE, `/${getUserId()}/password/`, credentials);
  };

  /**
   * Subscribe newsletter for user
   */
  const subscribeNewsletter = () => Promise.resolve(true);

  /**
   * Generate a new password token and send it to the user via his e-mail address
   * @param login
   * @returns {*}
   */
  const forgottenPasswordTokenGenerate = (login) => {
    if (!login) {
      return Promise.reject(INVALID_PARAMS);
    }

    const credentials = {
      login: login.toLowerCase(),
    };

    return restClient.post(FORGOTTEN_PASSWORD_RESOURCE, '', credentials);
  };

  /**
   * Checks if the provided token is valid to be used to reset the users password
   * @param token
   * @returns {Promise<R>|Promise.<*>}
   */
  const forgottenPasswordCheckToken = (token) => {
    if (!token) {
      return Promise.reject(INVALID_PARAMS);
    }

    return restClient.get(FORGOTTEN_PASSWORD_RESOURCE, `/checktoken?token=${encodeURIComponent(token)}`);
  };

  /**
   * Set a new password for the user
   * @param token
   * @param newPassword
   * @returns {*}
   */
  const forgottenPasswordRestorePassword = (token, newPassword) => {
    if (!token || !newPassword) {
      return Promise.reject(INVALID_PARAMS);
    }

    const credentials = {
      token,
      newPassword,
    };

    return restClient.put(FORGOTTEN_PASSWORD_RESOURCE, '/restorePassword', credentials);
  };

  /**
   * Log out
   */
  const logout = () => restClient.post(LOGOUT_RESOURCE, '').then(cleanUpAfterLogout).catch(cleanUpAfterLogout);

  /**
   * Download the B2BCustomer-Pdf
   */
  const downloadPdf = (pdfType) => {
    if (!pdfType) {
      return Promise.reject(INVALID_PARAMS);
    }
    return restClient.getPdf(USER_RESOURCE, `/${getUserId()}/pdf/${pdfType}`, 'B2B_Registration.pdf');
  };

  function saveAsMyStore(storeID) {
    if (!storeID) {
      return Promise.reject(INVALID_PARAMS);
    }

    const updateData = {
      pointOfService: storeID,
    };

    return restClient.put(USER_RESOURCE, `/${getUserId()}`, updateData);
  }

  function verifyRecaptcha(response, userId, area) {
    const isValidRecaptchaArea = Object.keys(recaptchaAreas).some(
      (recaptchaAreaKey) => area === recaptchaAreas[recaptchaAreaKey]
    );

    if (!response) return Promise.reject('No recaptchaResponse provided');
    if (!area) return Promise.reject('No recaptcha area provided');
    if (!isValidRecaptchaArea) {
      return Promise.reject('Invalid recaptchaArea provided');
    }
    if (area === recaptchaAreas.login && !userId) {
      return Promise.reject('No userId for login recaptcha provided');
    }
    // Giftcard Area does not accept an userId, omit it for the request
    if (area === recaptchaAreas.giftcard) {
      return restClient.post(RECAPTCHA_RESOURCE, '', {
        area,
        recaptchaResponse: response,
      });
    }

    return restClient.post(RECAPTCHA_RESOURCE, '', {
      area,
      recaptchaResponse: response,
      id: userId,
    });
  }

  function verifyEmail(email) {
    if (!email) {
      return Promise.reject(INVALID_PARAMS);
    }

    return restClient.post(VERIFY_RESOURCE, '/email', {
      email,
    });
  }

  const resendDoubleOptInMail = (email) => {
    if (!email) {
      return Promise.reject(INVALID_PARAMS);
    }
    return restClient.put(BASE_RESOURCE, `/resendDOIMail/${email}`);
  };

  const requestAccountDeletion = () => {
    return restClient.del(USER_RESOURCE, `/${getUserId()}/request-deletion`);
  };

  return {
    authenticateUser,
    setUserId,
    getUserId,
    setOnlineId,
    getOnlineId,
    resetUserId,
    createUser,
    createUserAfterGuestCheckout,
    getUser,
    getUserConsents,
    updateUserConsents,
    putCustomer,
    deactivateCurrentUser,
    getAddresses,
    createAddress,
    getAddress,
    putAddress,
    deleteAddress,
    changeLogin,
    changePassword,
    subscribeNewsletter,
    setAccessToken,
    logout,
    refreshAccessToken,
    forgottenPasswordTokenGenerate,
    forgottenPasswordCheckToken,
    forgottenPasswordRestorePassword,
    downloadPdf,
    saveAsMyStore,
    cleanUpAfterLogout,
    checkAccessToken,
    loadAccessToken,
    verifyRecaptcha,
    verifyEmail,
    resendDoubleOptInMail,
    requestAccountDeletion,
  };
})();

export default UserAPI;
export const getUserId = UserAPI.getUserId;
export const getOnlineId = UserAPI.getOnlineId;
