// @flow
import React from 'react';
import Headline, { tags, margins } from 'components/atoms/Headline/Headline';
import StarRating from 'components/atoms/StarRating/StarRating';
import language from 'constants/language/language';
import styles from './RatingTotal.scss';

type IProps = {
  averageRating: number,
  averageRecommendation: number,
  highlightRating?: boolean,
  showRecommendations?: boolean,
};

const RatingTotal = ({ averageRating, averageRecommendation, highlightRating, showRecommendations }: IProps) => (
  <div className={styles.ratingTotal}>
    <Headline tag={tags.H4} margin={margins.MB_15}>
      {language('productRating.total.title')}
    </Headline>
    <div className={styles.stars}>
      <StarRating value={averageRating} size="large" disabled={!highlightRating} />
      {averageRating > 0 && (
        <p className={styles.ratingText}>
          {averageRating} {language('productRating.total.of')} 5
        </p>
      )}
    </div>
    {showRecommendations && (
      <p className={styles.recommends}>
        <span className={styles.rate}>{averageRecommendation}%</span>
        {language('productRating.total.recommendation')}
      </p>
    )}
  </div>
);

RatingTotal.defaultProps = {
  averageRating: 0,
  averageRecommendation: 0,
};

export default RatingTotal;
