import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { clearProductSuggestions, getTimeCriticalProductData } from 'actions/productActions/productActions';
import { productClick } from 'actions/trackingActions/trackingActions';
import { mapDirectLinks } from 'constants/suggestionMapper/suggestionMapper';
import SearchSuggestOrganism from 'components/organisms/SearchSuggest/SearchSuggest';
import { setSearchString } from 'actions/uiActions/uiActions';
import { cypressAttributes } from 'constants/cypress/cypress';

export const mapStateToProps = (state) => ({
  productQueryDispatched: state?.suggestions?.productQueryDispatched ?? false,
  products: state?.suggestions?.result?.products || [],
  productsCount: state?.suggestions?.result?.productsCount || 0,
  autocomplete: state?.suggestions?.autocomplete || [],
  directlinks: state?.suggestions?.directlinks || [],
  pages: state?.cms?.navigation?.pages || {},
  categories: state?.categories || {},
});

export const mapDispatchToProps = {
  clearProductSuggestions,
  getTimeCriticalProductData,
  onProductClick: productClick,
  setSearchString,
};

const SearchSuggest = ({
  products,
  autocomplete,
  directlinks,
  searchTerm,
  pages,
  isSticky,
  categories,
  onProductClick,
  closeSearch,
  trackUnfocus, // eslint-disable-line no-unused-vars
  trackSearch,
  addHistoryEntry,
  setSearchString,
  clearProductSuggestions: _clearProductSuggestions,
  hideSearchPriceMobile,
}) => {
  directlinks.filter((value) => Object.keys(value).length !== 0);
  const enrichedLinks = mapDirectLinks(directlinks, pages, categories);

  const timeCritical = () => {
    if (__CLIENT__) {
      // if product has no productPriceData, make timeCritical call to get productPriceData
      products.forEach((product) => {
        if (!product?.productPriceData?.finalPrice?.value) {
          getTimeCriticalProductData([product.code]);
        }
      });
    }
  };

  useEffect(() => {
    timeCritical();
  }, [products]);

  // Track Unfocues when clearing product suggestions.
  const clearProductSuggestions = () => {
    _clearProductSuggestions();
    trackUnfocus();
  };

  return (
    <SearchSuggestOrganism
      products={products}
      autocomplete={autocomplete}
      directlinks={enrichedLinks}
      clearProductSuggestions={clearProductSuggestions}
      searchTerm={searchTerm}
      isSticky={isSticky}
      categories={categories}
      productClick={onProductClick}
      closeSearch={closeSearch}
      trackSearch={trackSearch}
      addHistoryEntry={addHistoryEntry}
      setSearchString={setSearchString}
      cyData={cypressAttributes.data.desktopSearch}
      hideSearchPriceMobile={hideSearchPriceMobile}
    />
  );
};

SearchSuggest.displayName = 'containers/SearchSuggest';
SearchSuggest.propTypes = {
  productQueryDispatched: PropTypes.bool,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      customImageData: PropTypes.array.isRequired,
      categoryCode: PropTypes.string.isRequired,
      productPricedata: PropTypes.object,
    })
  ),
  productsCount: PropTypes.number,
  autocomplete: PropTypes.array,
  directlinks: PropTypes.array,
  clearProductSuggestions: PropTypes.func,
  searchTerm: PropTypes.string,
  isMobile: PropTypes.bool,
  pages: PropTypes.object,
  isSticky: PropTypes.bool,
  getTimeCriticalProductData: PropTypes.func,
  categories: PropTypes.object,
  onProductClick: PropTypes.func,
  closeSearch: PropTypes.func,
  trackSearch: PropTypes.func,
  trackUnfocus: PropTypes.func,
  addHistoryEntry: PropTypes.func,
  setSearchString: PropTypes.func,
  hideSearchPriceMobile: PropTypes.bool,
};
SearchSuggest.defaultProps = {
  getTimeCriticalProductData: () => {},
  clearProductSuggestions: () => {},
  categories: {},
  trackUnfocus: () => {},
  trackSearch: () => {},
  addHistoryEntry: () => {},
  setSearchString: () => {},
  hideSearchPriceMobile: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchSuggest);
