import React from 'react';
import PropTypes from 'prop-types';
import language from 'constants/language/language';
import { noop } from 'constants/noop/noop';
import { Row, Col } from 'components/atoms/Grid/Grid';
import ContactField from 'containers/ContactField/ContactField';
import InputDate from 'components/molecules/InputDate/InputDate';
import FieldSetInput from 'components/molecules/FieldSetInput/FieldSetInput';
import Headline from 'components/atoms/Headline/Headline';
import InputText from 'components/atoms/InputText/InputText';
import InputTextarea from 'components/atoms/InputTextarea/InputTextarea';
import AddressFormPartial from './Contact.form.address.part';
import ContactDetailsPartial from './Contact.form.contactDetail.part';

import styles from './Contact.scss';
import { messageMaxLength } from 'constants/contact/contact.helper';

const FormFeedback = ({ allowOnlyNumbers = noop, telNumberValidateChar = noop }) => (
  <>
    <Row>
      <Col>
        <Headline margin={Headline.margins.MB_15}>{language('service.contact.fieldsetHeadline')}</Headline>
      </Col>
    </Row>
    <AddressFormPartial allowOnlyNumbers={allowOnlyNumbers} />
    <ContactDetailsPartial telNumberValidateChar={telNumberValidateChar} />
    <Row className={styles.sectionTwo}>
      <Col>
        <Headline margin={Headline.margins.MB_15}>
          {language('service.contact.additionalFieldsetHeadlineFeedback')}
        </Headline>
      </Col>
    </Row>
    <Row>
      <Col sm={12} md={8}>
        <ContactField fieldName="repair_feedback_repairNumber">
          <FieldSetInput>
            <InputText type="tel" pattern="\d*" onKeyPress={allowOnlyNumbers} />
          </FieldSetInput>
        </ContactField>
      </Col>
      <Col sm={12} md={8}>
        <ContactField fieldName="repair_feedback_customerNumber">
          <FieldSetInput>
            <InputText type="tel" pattern="\d*" onKeyPress={allowOnlyNumbers} />
          </FieldSetInput>
        </ContactField>
      </Col>
    </Row>
    <Row>
      <Col sm={12} md={8}>
        <ContactField fieldName="repair_feedback_contractNumber">
          <FieldSetInput>
            <InputText type="tel" pattern="\d*" onKeyPress={allowOnlyNumbers} />
          </FieldSetInput>
        </ContactField>
      </Col>
      <Col sm={12} md={8}>
        <ContactField fieldName="repair_feedback_invoiceNumber">
          <FieldSetInput>
            <InputText type="tel" pattern="\d*" onKeyPress={allowOnlyNumbers} />
          </FieldSetInput>
        </ContactField>
      </Col>
    </Row>
    <Row>
      <Col sm={12} md={8}>
        <ContactField fieldName="repair_feedback_articleNumber">
          <FieldSetInput>
            <InputText type="tel" pattern="\d*" onKeyPress={allowOnlyNumbers} />
          </FieldSetInput>
        </ContactField>
      </Col>
    </Row>
    <Row>
      <Col sm={12} md={8}>
        <ContactField fieldName="brand">
          <FieldSetInput>
            <InputText />
          </FieldSetInput>
        </ContactField>
      </Col>
      <Col sm={12} md={8}>
        <ContactField fieldName="modelType">
          <FieldSetInput>
            <InputText />
          </FieldSetInput>
        </ContactField>
      </Col>
    </Row>
    <Row>
      <Col sm={12} md={8}>
        <ContactField fieldName="superpoints_store">
          <FieldSetInput>
            <InputText />
          </FieldSetInput>
        </ContactField>
      </Col>
      <Col sm={12} md={8}>
        <ContactField fieldName="superpoints_dateOfPurchase">
          <FieldSetInput>
            <InputDate />
          </FieldSetInput>
        </ContactField>
      </Col>
    </Row>
    <Row>
      <Col sm={12} md={8}>
        <ContactField fieldName="contactTextarea">
          <FieldSetInput>
            <InputTextarea maxLength={messageMaxLength} />
          </FieldSetInput>
        </ContactField>
      </Col>
    </Row>
  </>
);

FormFeedback.propTypes = {
  allowOnlyNumbers: PropTypes.func,
  telNumberValidateChar: PropTypes.func,
  shouldShowPhoneDescription: PropTypes.bool,
};

export default FormFeedback;
