import { cloneElement, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import uuid from 'uuid/v1';
import deepEqual from 'constants/helper/deepEqual';
import { getTimeCriticalProductData } from 'actions/productActions/productActions';
import { addToComparison, removeFromComparison } from 'actions/comparisonActions/comparisonActions';
import { productClick } from 'actions/trackingActions/trackingActions';
import { addToWatchlist, removeFromWatchlist } from 'actions/watchlistActions/watchlistActions';
import { addFlashmessage } from 'actions/uiActions/uiActions';
import { ProductViewTypes } from 'constants/ProductViewTypes/ProductViewTypes';
import language, { getLocale } from 'constants/language/language';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import { PRODUCT_COMPARISON, WATCHLIST } from 'constants/routePaths/routePaths';
import { types as flashMessageTypes } from 'constants/flashMessages/flashMessages';

export const mapDispatchToProps = {
  productClick,
  addToWatchlist,
  removeFromWatchlist,
  addToComparison,
  removeFromComparison,
  getTimeCriticalProductData,
  addFlashmessage,
};

export const mapStateToProps = (state, ownProps) => {
  const productCode = ownProps.productCode;
  const productAttributes = ownProps.productAttributes;
  const product = { ...(state.products[productCode] || {}), ...productAttributes };

  const watchlistEntries = state?.watchlist?.entries;
  const addedToWatchlist = !!watchlistEntries.find((entry) => entry.code === productCode);

  const comparisonList = state?.comparison;
  const comparisonCategories = comparisonList?.categories || [];
  const addedToComparison = Object.values(comparisonCategories?.[product?.categoryCode] || {})?.includes(productCode);

  const cartEntries = state?.cart?.entries;
  const addedToCart = !!cartEntries.find((entry) => entry.productCode === productCode);

  const watchlistApiStatus = state?.watchlist?.apiStatus;

  return {
    name: product.name,
    categoryCode: product.categoryCode,
    code: product.code,
    promoLabels: product.promoLabels,
    speedProduct: product.speedProduct,
    neutralDeliveryPossible: product.neutralDeliveryPossible,
    availabilityStatus: product.availabilityStatus,
    productPriceData: product.productPriceData,
    customImageData: product.customImageData,
    canBeReserved: product.canBeReserved,
    isButtonDisabled: product.isButtonDisabled,
    canBeBought: product.canBeBought,
    maxOrderValue: product.maxOrderValue,
    productOrderable: product.productOrderable,
    energyEfficiency: product.energyEfficiency,
    energyEfficiencyEnEv2020: product.energyEfficiencyEnEv2020,
    recoTrackingToken: product.recoTrackingToken,
    addedToWatchlist,
    watchlistEntries,
    product,
    addedToComparison,
    addedToCart,
    topProductFeatures: product.topProductFeatures,
    promotion: product?.promotion || {},
    maxOrderCounterActive: product.maxOrderCounterActive,
    averageRating: product.averageRating,
    categories: state.categories,
    comparisonCategories,
    availability: product.availability,
    productAttributes,
    watchlistApiStatus,
  };
};

class ProductContainer extends Component {
  static displayName = 'containers/Product';
  static propTypes = {
    children: PropTypes.element.isRequired,
    product: PropTypes.object,
    productCode: PropTypes.string,
    name: PropTypes.string,
    categoryCode: PropTypes.string,
    code: PropTypes.string,
    productPriceData: PropTypes.object,
    promoLabels: PropTypes.array,
    speedProduct: PropTypes.bool,
    neutralDeliveryPossible: PropTypes.bool,
    availabilityStatus: PropTypes.string,
    customImageData: PropTypes.array,
    canBeReserved: PropTypes.bool,
    isButtonDisabled: PropTypes.bool,
    canBeBought: PropTypes.bool,
    doUpdate: PropTypes.bool,
    maxOrderValue: PropTypes.number,
    productOrderable: PropTypes.bool,
    energyEfficiency: PropTypes.object,
    energyEfficiencyEnEv2020: PropTypes.object,
    productClick: PropTypes.func,
    addToWatchlist: PropTypes.func,
    removeFromWatchlist: PropTypes.func,
    recoTrackingToken: PropTypes.string,
    addedToWatchlist: PropTypes.bool,
    watchlistEntries: PropTypes.array,
    addedToComparison: PropTypes.bool,
    addToComparison: PropTypes.func,
    addedToCart: PropTypes.bool,
    removeFromComparison: PropTypes.func,
    productViewType: PropTypes.oneOf(Object.values(ProductViewTypes)),
    topProductFeatures: PropTypes.array,
    maxOrderCounterActive: PropTypes.bool,
    promotion: PropTypes.object,
    averageRating: PropTypes.number,
    categories: PropTypes.object,
    getTimeCriticalProductData: PropTypes.func,
    addFlashmessage: PropTypes.func,
    comparisonCategories: PropTypes.object,
    availability: PropTypes.string,
    productAttributes: PropTypes.object,
    watchlistApiStatus: PropTypes.string,
  };
  static defaultProps = {
    doUpdate: false,
    topProductFeatures: [],
    categories: {},
    getTimeCriticalProductData: () => {},
  };

  shouldComponentUpdate(nextProps) {
    return (
      nextProps.doUpdate ||
      nextProps.productCode !== this.props.productCode ||
      !deepEqual(this.props.productPriceData, nextProps.productPriceData) ||
      nextProps.addedToWatchlist !== this.props.addedToWatchlist ||
      nextProps.addedToComparison !== this.props.addedToComparison ||
      nextProps.addedToCart !== this.props.addedToCart ||
      nextProps.productViewType !== this.props.productViewType ||
      nextProps.promotion !== this.props.promotion
    );
  }

  componentDidMount() {
    this.timeCritical();
  }

  componentDidUpdate(prevProps) {
    if (!deepEqual(prevProps.productPriceData, this.props.productPriceData)) {
      this.timeCritical();
    }
  }

  timeCritical = () => {
    if (__CLIENT__) {
      // if product has no productPriceData, make timeCritical call to get productPriceData
      if (!this.props.productPriceData?.finalPrice?.value) {
        this.props.getTimeCriticalProductData([this.props.productCode]);
      }
    }
  };

  addToWatchlist = (productCode) => {
    const id = uuid();
    this.props.addFlashmessage({
      type: this.props.watchlistEntries.length === 100 ? flashMessageTypes.ERROR : flashMessageTypes.SUCCESS,
      content:
        this.props.watchlistEntries.length === 100
          ? language('product.watchListIsFull')
          : language('product.addedToWatchList').replace('{URL}', mapPathToLocalizedUrl(getLocale(), [WATCHLIST])),
      id,
    });
    this.props.addToWatchlist(productCode);
  };

  removeFromWatchlist = (productCode) => {
    const arrayIndex = this.props.watchlistEntries.findIndex((entry) => entry.code === productCode);
    this.props.removeFromWatchlist(arrayIndex);
  };

  addToComparison = (productCode) => {
    const id = uuid();
    const isAddable = Object.entries(this.props.comparisonCategories?.[this.props.categoryCode] || {})?.length < 3;
    if (isAddable) {
      this.props.addFlashmessage({
        type: flashMessageTypes.SUCCESS,
        content: language('product.addedToComparison').replace(
          '{URL}',
          mapPathToLocalizedUrl(getLocale(), [PRODUCT_COMPARISON])
        ),
        id,
      });
    }
    this.props.addToComparison(productCode, this.props.categoryCode);
  };

  removeFromComparison = (productCode) => {
    this.props.removeFromComparison(productCode, this.props.categoryCode);
  };

  productClickAction = (context) => {
    this.props.productClick(this.props.product, context);
  };

  hasPromoEnded = () => {
    const endTime = this.props.promotion?.endTime || 0;
    const stockMax = this.props.promotion?.stockMax || 0;
    const stockCurrent = this.props.promotion?.stockCurrent || 0;

    return endTime > Date.now() && stockMax > 0 && stockCurrent <= 0;
  };

  render() {
    const {
      children,
      name,
      categoryCode,
      code,
      promoLabels,
      speedProduct,
      neutralDeliveryPossible,
      availabilityStatus,
      productPriceData,
      customImageData,
      canBeReserved,
      isButtonDisabled,
      canBeBought,
      maxOrderValue,
      productOrderable,
      energyEfficiency,
      energyEfficiencyEnEv2020,
      recoTrackingToken,
      addedToWatchlist,
      addedToComparison,
      addedToCart,
      promotion,
      topProductFeatures,
      maxOrderCounterActive,
      averageRating,
      categories,
      availability,
      productAttributes,
      watchlistApiStatus,
    } = this.props;

    return cloneElement(children, {
      name,
      categoryCode,
      code,
      promoLabels,
      speedProduct,
      neutralDeliveryPossible,
      availabilityStatus,
      productPriceData,
      customImageData,
      canBeReserved,
      isButtonDisabled,
      canBeBought,
      maxOrderValue,
      productOrderable,
      energyEfficiency,
      energyEfficiencyEnEv2020,
      productClickAction: this.productClickAction,
      recoTrackingToken,
      addToWatchlist: this.addToWatchlist,
      removeFromWatchlist: this.removeFromWatchlist,
      productAddedToWatchlist: addedToWatchlist,
      addToComparison: this.addToComparison,
      removeFromComparison: this.removeFromComparison,
      productAddedToComparison: addedToComparison,
      productAddedToCart: addedToCart,
      topProductFeatures,
      promotion,
      maxOrderCounterActive,
      promoEnded: this.hasPromoEnded(),
      averageRating,
      categories,
      availability,
      productAttributes,
      watchlistApiStatus,
    });
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductContainer);
