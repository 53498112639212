import productLinkConfiguration from 'constants/productLinkConfiguration/productLinkConfiguration';
import language from 'constants/language/language';

const getProductLinks = (product) => {
  const anchorLinks = [];

  if (product.description || !!product.classifications?.length) {
    anchorLinks.push(productLinkConfiguration().description);
  }

  return anchorLinks;
};

const getButtonText = (canBeReserved) => {
  if (canBeReserved) {
    return language('product.reserve');
  }
  return language('product.addToCart');
};

export default {
  getProductLinks,
  getButtonText,
};
