import { useEffectOnce, useLocalStorage } from 'usehooks-ts';
import { get, set } from 'lodash/object';
import { useState } from 'react';

export const STATE_KEY = 'state';

export const useReadPersistenceStore = (options) => {
  const [storage] = usePersistenceStore(options);
  return storage;
};

export const useWritePersistenceStore = (options) => {
  // eslint-disable-next-line no-unused-vars
  const [_, setStorage] = usePersistenceStore(options);
  return setStorage;
};

const usePersistenceStore = (options = {}) => {
  const { key } = options;

  const [inMemoryStorage, setInMemoryStorage] = useState();

  const [injectedStorage, setInjectedStorage] = useLocalStorage(STATE_KEY);

  const storage = key ? get(injectedStorage, key) : injectedStorage;

  // we to delay the storage initialization on the client to prevent prop mismatch in SSR
  useEffectOnce(() => setInMemoryStorage(storage));

  const setStorage = (value) => {
    const nextState = key ? set({ ...injectedStorage }, key, value) : value;
    setInjectedStorage(nextState);
    setInMemoryStorage(nextState);
  };

  return [inMemoryStorage, setStorage];
};

export default usePersistenceStore;
