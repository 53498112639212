import { Component, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BREAKPOINTS } from 'constants/breakpoints/breakpoints';
import { getEnumValues } from 'constants/GetEnumValues/GetEnumValues';

export const mapStateToProps = (state) => ({
  breakpoint: state?.ui?.breakpoint || BREAKPOINTS.XL,
});

class BreakpointListener extends Component {
  static propTypes = {
    breakpoint: PropTypes.oneOf(getEnumValues(BREAKPOINTS)),
    children: PropTypes.element.isRequired,
  };

  render() {
    const { children, breakpoint } = this.props;
    return cloneElement(children, { breakpoint });
  }
}

export default connect(mapStateToProps)(BreakpointListener);
