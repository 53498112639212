// HOME
export const HOME = '/';

// CART
export const CART = 'cart';

// CHECKOUT
export const CHECKOUT = 'checkout';
export const CHECKOUT_GUEST = 'guest';
export const CHECKOUT_LOGIN = 'login';

// ORDER
export const ORDER_CONFIRMATION = 'orderconfirmation';

// ACCOUNT
export const ACCOUNT = 'account';
export const ACCOUNT_ARCHIVE = '(archive)';
export const ACCOUNT_LOGIN = 'login';
export const ACCOUNT_REGISTRATION = 'registration';
export const ACCOUNT_ORDERS = 'orders';
export const ACCOUNT_ORDERS_ARCHIVE = '(/archive)';
export const ACCOUNT_ADDRESSES = 'addresses';
export const ACCOUNT_ORDERSPARAMS = '(/:page)';
export const ACCOUNT_ORDER_DETAILS = 'order';
export const ACCOUNT_ORDER_DETAILS_PARAMS = ':orderGuid';
export const ACCOUNT_USERDATA = 'userdata';
export const ACCOUNT_RATINGS = 'ratings';
export const ACCOUNT_RATINGS_RATED = 'rated';
export const ACCOUNT_RATINGS_NOTRATED = 'not-rated';
export const ACCOUNT_RATINGS_PARAMS = '(:ratingType)(/:page)';
export const ACCOUNT_NOTIFICATIONS = 'notifications';
export const ACCOUNT_RETURNS = 'returns';
export const ACCOUNT_RETURN = 'return';
export const ACCOUNT_RETURN_CREATE = 'create';
export const ACCOUNT_DELETE_CONFIRMATION = 'delete-account';

// PASSWORD RESET
export const PASSWORD = 'password';
export const PASSWORD_RESET = 'reset';

// CATEGORY
export const CATEGORY = '(**)--c:categoryCode';
export const CATEGORY_PRODUCTCODE = '(*)--p:productCode';
export const CATEGORY_SEARCH = 'search';

// PRODUCT
export const PRODUCT = 'product';
export const PRODUCT_CODE = '(**)-:productCode';

// PRODUCT SEARCH
export const PRODUCT_SEARCH = 'search';
export const PRODUCT_SEARCH_EMPTY = 'empty';

// BRAND
export const BRAND = 'brand';
export const BRAND_PARAMS = ':brand';

// STOREFINDER
export const STOREFINDER = 'storefinder';

// 404
export const NOTFOUND = '404';

// PAYMENT
export const PAYMENT = 'payment';
export const PAYMENT_SUCCESS = 'success';
export const PAYMENT_ERROR = 'error';
export const PAYMENT_CANCEL = 'cancel';

// CMS ROUTES
export const CMS = 'cms';
export const WALL = 'promotions';
export const SERVICE = 'service';
export const SERVICEAWARDS = 'best-service-award';
export const B2B_PAGE = 'b2b';
export const BLOG = 'blog';
export const CORONA = 'faq-corona';

// NEWSLETTER
export const NEWSLETTER = 'newsletter';
export const NEWSLETTER_REGISTRATION = 'registration';
export const NEWSLETTER_VERIFICATION = 'verification';
export const NEWSLETTER_VERIFICATION_TOKEN = ':token';
export const NEWSLETTER_FEEDBACK_PARAMS = ':feedbackType';
export const NEWSLETTER_OPTOUT = 'optout';
export const NEWSLETTER_OPTIN = 'optin';

// INDIVIDUALIZED ADVERTIZING
export const INDIVIDUAL_ADS = 'individualized-advertising';
export const INDIVIDUAL_ADS_UNSUBSCRIBE = 'unsubscribe';

// B2B REGISTRATION PDF
export const B2B_REGISTRATION_PDF = '/medien/id/marketing/Kartenantrag_AGB_A4_';

// WATCHLIST
export const WATCHLIST = 'watchlist';

// PRODUCT COMPARISON
export const PRODUCT_COMPARISON = 'comparison';

// OPTIN
export const OPTIN = 'opt-in';
export const OPTIN_TYPE_REGISTRATION = 'registration';
export const OPTIN_TYPE_UPDATEEMAIL = 'update-email';
export const OPTIN_CONFIRMATION_MAIL = 'confirmation-e-mail';
export const OPTIN_CONFIRMED = 'confirmed';
export const OPTIN_EXPIRED = 'expired';
export const OPTIN_FAILURE = 'failure';
export const OPTIN_USED = 'used';
export const OPTIN_VALIDATION = 'validation';
export const OPTIN_NOT_CONFIRMED = 'not-confirmed';

// DOCUMENTS
export const DOCUMENTS = 'documents';

// SMART_EDIT
export const CX_PREVIEW = 'cx-preview';
