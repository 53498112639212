import {
  CHANGE_COMPARISON_CATEGORY,
  GET_PRODUCT_COMPARISON_SHORT,
  GET_PRODUCT_COMPARISON_FULL,
  ADD_PRODUCT_TO_COMPARISON,
  REMOVE_PRODUCT_FROM_COMPARISON,
  CLOSE_COMPARISON_HEADER,
} from 'constants/ActionTypes/ActionTypes';

import {
  addProductToStorage,
  removeProductFromStorage,
  changeSelectedCategory,
} from 'constants/updateComparisonStorage/updateComparisonStorage';

import ComparisonAPI from 'api/ComparisonAPI/ComparisonAPI';

export const changeCategory = (categoryCode) => {
  changeSelectedCategory(categoryCode);
  return {
    type: CHANGE_COMPARISON_CATEGORY,
    payload: { categoryCode },
  };
};

export const addToComparison = (productCode, categoryCode) => {
  addProductToStorage(categoryCode, productCode);
  return {
    type: ADD_PRODUCT_TO_COMPARISON,
    payload: { productCode, categoryCode },
  };
};

export const removeFromComparison = (productCode, categoryCode) => {
  removeProductFromStorage(categoryCode, productCode);
  return {
    type: REMOVE_PRODUCT_FROM_COMPARISON,
    payload: { productCode, categoryCode },
  };
};

export const getFullComparison = (productCodes, categoryCode) => ({
  type: GET_PRODUCT_COMPARISON_FULL,
  payload: { productCodes, categoryCode },
  promise: ComparisonAPI.getFullComparison(productCodes),
});

export const getShortComparison = (productCodes, categoryCode) => ({
  type: GET_PRODUCT_COMPARISON_SHORT,
  payload: { productCodes, categoryCode },
  promise: ComparisonAPI.getShortComparison(productCodes),
});

export const closeComparisonHeader = () => ({
  type: CLOSE_COMPARISON_HEADER,
});

const actions = {
  changeCategory,
  addToComparison,
  removeFromComparison,
  getFullComparison,
  getShortComparison,
  closeComparisonHeader,
};

export default actions;
