import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { getEnv } from 'config/config';

import imageSizes from 'constants/imageSizes/imageSizes';
import language from 'constants/language/language';

import DiscountFlag, { skins as flagSkins } from 'components/atoms/DiscountFlag/DiscountFlag';
import Image from 'components/atoms/Image/Image';
import LogoBrand from 'components/atoms/LogoBrand/LogoBrand';
import Price from 'components/atoms/Price/Price';
import ProductCode from 'components/atoms/ProductCode/ProductCode';

import styles from './ProductDiscount.scss';

const ProductDiscount = ({ price, product }) => {
  const getImages = (imageData) => imageData?.sizes || [];
  return (
    <div className={styles.contentWrapper}>
      <div className={styles.imageOuterContainer}>
        <div className={styles.flagContainer}>
          <DiscountFlag discount={price.discount} skin={flagSkins.MODAL} />
        </div>
        <div className={styles.imageWrapper}>
          {!!product.customImageData.length && (
            <Image
              alt={product.customImageData[0].altText}
              images={getImages(product.customImageData[0])}
              size={imageSizes.productDetail.size}
              sizes={imageSizes.productDetail.sizes}
              responsive={imageSizes.productDetail.responsive}
              code={product.customImageData[0].code}
            />
          )}
        </div>
      </div>
      <div>
        <div className={styles.productName}>{product.name}</div>
        <div className={styles.PostHeadlineContainer}>
          {product.brandLogoUrl && (
            <div className={cx(styles.PostHeadlineLogoContainer, styles.PostHeadlineSpacer)}>
              <LogoBrand path={`${getEnv('web')}${product.brandLogoUrl}`} altText={product.manufacturer} />
            </div>
          )}
          <div className={styles.PostHeadlineSubContainer}>
            <ProductCode label={language('product.itemNumber')} itemNumber={product.code} />
            {product.displayedCode && (
              <ProductCode label={language('product.itemNumber')} itemNumber={product.displayedCode} />
            )}
          </div>
        </div>
        <Price productPriceData={price} showInsteadPrice />
      </div>
    </div>
  );
};

ProductDiscount.displayName = 'molecules/ProductDiscount';
ProductDiscount.propTypes = {
  price: PropTypes.object,
  product: PropTypes.object,
};
ProductDiscount.defaultProps = {
  price: {},
  product: {},
};

export default ProductDiscount;
