import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { headTitle } from 'constants/environment/environment';
import { validLocales } from 'constants/language/language';
import { getEnv } from 'config/config';
import { getRelocalizedUrlPath } from 'constants/urlHelper/urlHelper';
import { provideUrls } from 'constants/navigation/navigation';

const titleFirstTemplate = `%s - ${headTitle}`;
const titleLastTemplate = `${headTitle} - %s`;
const FALLBACK_PAGE_TITLE = 'Der Elektronik-Shop Nr. 1';

const getAlternatePages = (state) => {
  const pages = provideUrls(state?.cms?.navigation?.pages || {});
  const actualPath = state?.routing?.locationBeforeTransitions?.pathname || '';
  const categories = state?.categories || {};
  return Object.values(validLocales).map((locale) => ({
    rel: 'alternate',
    href: getEnv('web') + getRelocalizedUrlPath(actualPath, locale, pages, categories),
    hreflang: `${locale}-CH`,
  }));
};

const mapStateToProps = (state, ownProps) => {
  const alternatePages = getAlternatePages(state);

  if (ownProps.meta) {
    return {
      meta: ownProps.meta,
      alternatePages,
    };
  }

  if (ownProps.contentId) {
    const { metaDescription, metaKeywords } = state?.cms?.pages?.[ownProps.contentId]?.metaData || {};
    return {
      meta: [
        { name: 'description', content: metaDescription },
        { name: 'keywords', content: metaKeywords },
      ],
      alternatePages,
    };
  }

  return {};
};

const Meta = ({ title = FALLBACK_PAGE_TITLE, titleFirst = true, meta, canonical, alternatePages }) => {
  return (
    <Helmet
      title={title}
      titleTemplate={titleFirst ? titleFirstTemplate : titleLastTemplate}
      /* base={{"target": "_blank", "href": "http://microspot.ch/", "rel": "noopener"}} */
      meta={meta}
      link={[...(canonical ?? []), ...(alternatePages ?? [])]}
    />
  );
};

Meta.displayName = 'containers/Meta/Meta';
Meta.propTypes = {
  title: PropTypes.string,
  titleFirst: PropTypes.bool,
  meta: PropTypes.array,
  canonical: PropTypes.array,
  alternatePages: PropTypes.array,
};

export default connect(mapStateToProps)(Meta);
