import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MarkdownBox from 'components/molecules/MarkdownBox/MarkdownBox';
import Headline, { tags } from 'components/atoms/Headline/Headline';
import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';
import Spinner, { SPINNER_STATES } from 'components/atoms/Spinner/Spinner';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import language, { getLocale } from 'constants/language/language';
import { WALL, ACCOUNT } from 'constants/routePaths/routePaths';
import { QualtricsCreativeContainer } from '@interdiscount/component-library-interdiscount';
import styles from './OptIn.scss';

const types = {
  UPDATEEMAIL: 'updateEmail',
  REGISTRATION: 'registration',
};
const pages = {
  CONFIRMED: 'CONFIRMED',
  CONFIRMATIONMAIL: 'CONFIRMATIONMAIL',
  EXPIRED: 'EXPIRED',
  FAILURE: 'FAILURE',
  USED: 'USED',
};

const OptIn = ({ type = types.REGISTRATION, page = pages.FAILURE, email = '', resendDoubleOptInMail = () => {} }) => {
  const [resendState, setResendState] = useState('');
  const getText = (i10n) => {
    const text = language(i10n);
    if (typeof text !== 'string') {
      return text;
    }
    const escapedEmail = email.replace(/_/g, '\\_');
    return text.replace(/{EMAIL}/g, escapedEmail);
  };

  const buttons = {
    HOME: (
      <ButtonLink variety={ButtonLink.varieties.stroked} href={mapPathToLocalizedUrl(getLocale())}>
        {getText('optIn.actions.HOME')}
      </ButtonLink>
    ),
    ACCOUNT: (
      <ButtonLink variety={ButtonLink.varieties.stroked} href={mapPathToLocalizedUrl(getLocale(), [ACCOUNT])}>
        {getText('optIn.actions.ACCOUNT')}
      </ButtonLink>
    ),
    PROMOTIONS: (
      <ButtonLink variety={ButtonLink.varieties.stroked} href={mapPathToLocalizedUrl(getLocale(), [WALL])}>
        {getText('optIn.actions.PROMOTIONS')}
      </ButtonLink>
    ),
    RESEND: (
      <ButtonLink
        variety={ButtonLink.varieties.stroked}
        onClick={() => {
          setResendState(SPINNER_STATES.REQUEST);
          resendDoubleOptInMail(email).then((res) => {
            setTimeout(() => setResendState(res ? SPINNER_STATES.SUCCESS : SPINNER_STATES.FAILURE), 500);
          });
        }}
      >
        {language('optIn.actions.RESEND')}
      </ButtonLink>
    ),
  };

  return (
    <div className={styles.wrapper}>
      <Headline tag={tags.H1}>{getText(`optIn.types.${type}.${page}.headline`)}</Headline>
      <QualtricsCreativeContainer qId="qInterceptOptin" />
      <div className={styles.markdownWrapper}>
        <MarkdownBox markdown={getText(`optIn.types.${type}.${page}.text`)} />
      </div>
      <div className={styles.ctaWrapper}>
        {language(`optIn.types.${type}.${page}.actions`).map((action) => (
          <div className={styles.cta} key={action}>
            {buttons[action]}
          </div>
        ))}
        {resendState !== '' && (
          <div className={styles.successMessage}>
            <Spinner status={resendState} height={40} />
            {language(`optIn.responses.${resendState}`)}
          </div>
        )}
      </div>
    </div>
  );
};

OptIn.TYPES = types;
OptIn.PAGES = pages;

OptIn.displayName = 'organisms/Optin';
OptIn.propTypes = {
  type: PropTypes.oneOf(Object.values(types)),
  page: PropTypes.oneOf(Object.values(pages)),
  email: PropTypes.string,
  resendDoubleOptInMail: PropTypes.func,
};

export default OptIn;
