import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ModalZoovu } from '../ModalZoovu/ModalZoovu';

import styles from './ModalZoovuLink.scss';

const ModalZoovuLink = ({ data, assistantId }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const sliceLinkText = (text) => {
    if (!text) {
      return text;
    }
    return text.slice(2, -2);
  };

  return (
    <>
      <span onClick={openModal} className={styles.modalLink}>
        {sliceLinkText(data)}
      </span>
      {isOpen && <ModalZoovu assistantId={assistantId} closeModal={closeModal} />}
    </>
  );
};

ModalZoovuLink.displayName = 'organisms/ModalZoovuLink';

ModalZoovuLink.propTypes = {
  data: PropTypes.string,
  assistantId: PropTypes.string,
};

ModalZoovuLink.defaultProps = {
  src: '',
  assistantId: '',
};

export default ModalZoovuLink;
