import { ROUTER_ENTER_ROUTE } from 'constants/ActionTypes/ActionTypes';

/**
 * Action creator which opens modal and clears already found data
 *
 * What?
 */

export const enterRoute = (key) => ({
  type: ROUTER_ENTER_ROUTE,
  payload: {
    key,
  },
});

const actions = {
  enterRoute,
};

export default actions;
