import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Helmet from 'react-helmet';
import isTouchDevice from 'constants/isTouchDevice/isTouchDevice';
import { noop } from 'constants/noop/noop';
import Link from 'components/atoms/ScrollingLink/ScrollingLink';
import styles from 'components/molecules/Pagination/Pagination.scss';

const PaginationLink = ({
  children,
  className = '',
  query = '',
  loadProducts = noop,
  scrollToId = {},
  displayPrevSEO = false,
  displayNextSEO = false,
}) => (
  <li className={cx(styles.pageItem, className)}>
    <Link
      className={styles.pageLink}
      onMouseOver={isTouchDevice() ? noop : loadProducts}
      onMouseDown={loadProducts}
      query={query}
      scrollToId={scrollToId?.id}
      duration={scrollToId?.duration}
    >
      {children}
      {displayPrevSEO && <Helmet link={[{ rel: 'prev', href: query }]} />}
      {displayNextSEO && <Helmet link={[{ rel: 'next', href: query }]} />}
    </Link>
  </li>
);

PaginationLink.displayName = 'atoms/PaginationLink';
PaginationLink.propTypes = {
  className: PropTypes.string,
  query: PropTypes.string,
  children: PropTypes.node,
  loadProducts: PropTypes.func,
  scrollToId: PropTypes.object,
  displayPrevSEO: PropTypes.bool,
  displayNextSEO: PropTypes.bool,
};

export default PaginationLink;
