import React, { useMemo } from 'react';
import styles from './FacetFilters.scss';
import cx from 'classnames';
import { facetHasFilters, FacetPropType, getFilterPreviewText } from 'constants/facetsHelper/facetsHelper';
import Icon, { ICON_CLOSE } from 'components/atoms/Icon/Icon';
import PropTypes from 'prop-types';
import language from 'constants/language/language';

const SelectedFilters = ({ facets, resetFacet, resetAllFacets }) => {
  const filters = useMemo(() => facets?.filter(facetHasFilters), [facets]);

  return (
    <ul className={cx(styles.facetFiltersList, styles.selectedFacetFilters)}>
      {filters?.map((facet, index) => {
        return (
          <li key={facet.code}>
            <button onClick={() => resetFacet(facet.code, index)} className={styles.selectedFilterButton}>
              <div className={styles.selectedFacetFilterItem}>
                <span>
                  <b>{facet.name}</b>
                </span>
                <span className={styles.selectedFacetFilterSpan}>{getFilterPreviewText(facet)}</span>
                <Icon path={ICON_CLOSE} />
              </div>
            </button>
          </li>
        );
      })}

      {filters?.length > 0 && (
        <li>
          <button onClick={resetAllFacets} className={styles.selectedFilterDeleteButton}>
            <span className={styles.ResetButton}>{language('product.facets.reset')}</span>
            <Icon path={ICON_CLOSE} />
          </button>
        </li>
      )}
    </ul>
  );
};

SelectedFilters.propTypes = {
  facets: Array.of(FacetPropType),
  resetFacet: PropTypes.func,
  resetAllFacets: PropTypes.func,
};
export default SelectedFilters;
