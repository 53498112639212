import React from 'react';
import PropTypes from 'prop-types';

import styles from './MarkdownCollapsible.scss';

const MarkdownCollapsible = ({ children }) => (
  <div className={styles.collapsible}>
    <details>{children}</details>
  </div>
);

MarkdownCollapsible.displayName = 'atoms/MarkdownCollapsible';

MarkdownCollapsible.propTypes = {
  children: PropTypes.node,
};

export default MarkdownCollapsible;
