import React from 'react';
import PropTypes from 'prop-types';

import StoreFinderContainer from 'containers/StoreFinderContainer/StoreFinderContainer';
import StoreFinderTemplate from 'components/templates/InventoryStoreFinderTemplate/InventoryStoreFinderTemplate';
import { Modal } from '@interdiscount/component-library-interdiscount';

const InventoryModalStoreFinder = ({
  modalIsOpen,
  filteredStores,
  productImage,
  productName,
  productCode,
  closeModal,
}) => (
  <>
    {modalIsOpen && (
      <Modal onRequestClose={closeModal} isOpen size="LG">
        <StoreFinderContainer
          closeModal={() => closeModal(true)}
          inventoryStores={filteredStores}
          productCode={productCode}
          isInventory
          searchAllStores
        >
          <StoreFinderTemplate productImage={productImage} productName={productName} />
        </StoreFinderContainer>
      </Modal>
    )}
  </>
);

InventoryModalStoreFinder.propTypes = {
  modalIsOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  filteredStores: PropTypes.array,
  productImage: PropTypes.object,
  productName: PropTypes.string,
  productCode: PropTypes.string,
};

InventoryModalStoreFinder.defaultProps = {
  closeModal: () => {},
};

InventoryModalStoreFinder.displayName = 'organisms/InventoryModalStoreFinder';

export default InventoryModalStoreFinder;
