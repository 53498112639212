import restClient from 'api/RestClient/RestClient';
import { resourceKeys } from 'constants/restResources/restResources';

const resource = resourceKeys.COMPARISON_RESOURCE;

const ComparisonAPI = (function ComparisonAPI() {
  const getShortComparison = (productCodes) =>
    restClient
      .get(resource, `/short/${productCodes}`)
      .then((res) => res)
      .catch((err) => {
        throw err;
      });

  const getFullComparison = (productCodes) =>
    restClient
      .get(resource, `/full/${productCodes}`)
      .then((res) => res)
      .catch((err) => {
        throw err;
      });

  return {
    getShortComparison,
    getFullComparison,
  };
})();

export default ComparisonAPI;
