import React from 'react';
import PropTypes from 'prop-types';
import Icon, { ICON_KEYBOARD_ARROW_RIGHT } from 'components/atoms/Icon/Icon';

import styles from './ArrowText.scss';

const ArrowText = ({ children }) => (
  <div className={styles.arrowBlock}>
    <Icon className={styles.icon} path={ICON_KEYBOARD_ARROW_RIGHT} />
    {children}
  </div>
);

ArrowText.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
ArrowText.displayName = 'atoms/ArrowText';

export default ArrowText;
