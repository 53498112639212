import { Component, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import getIconConfig from 'constants/getIconConfig/getIconConfig';
import { possibleStrokes } from 'constants/iconStrokes/iconStrokes';
import { BREAKPOINTS } from 'constants/breakpoints/breakpoints';

const removeNilValues = (obj) => {
  const newMapped = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key]) newMapped[key] = obj[key];
  });
  return newMapped;
};

export const mapStateToProps = (state, ownProps) => {
  let calculatedConfig = getIconConfig(ownProps.breakpointConfigurations, state.ui.breakpoint);

  // set fallback height if breakpoint didn't match
  if (!calculatedConfig) {
    calculatedConfig = {
      height: ownProps.height,
      stroke: ownProps.stroke,
    };
  }

  return removeNilValues({
    calculatedHeight: calculatedConfig.height,
    calculatedStroke: calculatedConfig.stroke || ownProps.stroke,
  });
};

export class IconContainer extends Component {
  static propTypes = {
    height: PropTypes.number,
    stroke: PropTypes.oneOf(Object.keys(possibleStrokes)),
    breakpointConfigurations: PropTypes.shape({
      [BREAKPOINTS.XXS]: PropTypes.shape({
        height: PropTypes.number.isRequired,
        stroke: PropTypes.oneOf(Object.keys(possibleStrokes)),
      }),
      [BREAKPOINTS.XS]: PropTypes.shape({
        height: PropTypes.number.isRequired,
        stroke: PropTypes.oneOf(Object.keys(possibleStrokes)),
      }),
      [BREAKPOINTS.SM]: PropTypes.shape({
        height: PropTypes.number.isRequired,
        stroke: PropTypes.oneOf(Object.keys(possibleStrokes)),
      }),
      [BREAKPOINTS.MD]: PropTypes.shape({
        height: PropTypes.number.isRequired,
        stroke: PropTypes.oneOf(Object.keys(possibleStrokes)),
      }),
      [BREAKPOINTS.LG]: PropTypes.shape({
        height: PropTypes.number.isRequired,
        stroke: PropTypes.oneOf(Object.keys(possibleStrokes)),
      }),
      [BREAKPOINTS.XL]: PropTypes.shape({
        height: PropTypes.number.isRequired,
        stroke: PropTypes.oneOf(Object.keys(possibleStrokes)),
      }),
    }).isRequired,
    calculatedHeight: PropTypes.number,
    calculatedStroke: PropTypes.oneOf(Object.keys(possibleStrokes)),
    children: PropTypes.element.isRequired,
  };

  getProperties() {
    return removeNilValues({
      height: this.props.calculatedHeight,
      stroke: this.props.calculatedStroke,
    });
  }

  render() {
    return cloneElement(this.props.children, this.getProperties());
  }
}

export default connect(mapStateToProps)(IconContainer);
