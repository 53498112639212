import React from 'react';

import Headline from 'components/atoms/Headline/Headline';
import IconLink, { skins } from 'components/atoms/IconLink/IconLink';
import Icon from 'components/atoms/Icon/Icon';
import PopOverIndications from 'components/molecules/PopOverIndications/PopOverIndications';

import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import language, { getLocale } from 'constants/language/language';

import { cdcLoginPopover } from 'constants/subnavigationItems/subnavigationItems';

import styles from './PopOverLogin.scss';
import { cypressAttributes } from 'constants/cypress/cypress';

const PopOverLogin = () => (
  <div>
    <div className={styles.header}>
      <Headline tag={Headline.tags.SPAN} margin={Headline.margins.NONE}>
        {language('login.headlineShort')}
      </Headline>
    </div>
    <div className={styles.inner}>
      {cdcLoginPopover.map((entry) => (
        <IconLink
          featureName="nextLoginRegistrationEnabled"
          href={mapPathToLocalizedUrl(getLocale(), entry.link)}
          skin={skins.POPOVER}
          key={entry.text}
        >
          <div>
            <Icon path={entry.icon} />
            {language(entry.text)}
          </div>
        </IconLink>
      ))}
      <PopOverIndications
        cyData={[cypressAttributes.comparisonList.menuItem, cypressAttributes.comparisonList.badge]}
      />
    </div>
  </div>
);

export default PopOverLogin;
