import React from 'react';
import PropTypes from 'prop-types';
import Headline from 'components/atoms/Headline/Headline';
import StoreFinderSwitchViewButtons from 'components/molecules/StoreFinderSwitchViewButtons/StoreFinderSwitchViewButtons';
import viewTypes from 'constants/storeFinderResultsViewTypes/storeFinderResultsViewTypes';
import language from 'constants/language/language';
import styles from './StoreFinderResultsTitle.scss';

const StoreFinderResultsTitle = ({ locality, activeView, switchView }) => (
  <header className={styles.header}>
    <Headline tag={Headline.tags.H3} margin={Headline.margins.NONE}>
      {!!locality && (
        <>
          <span>{language('storeFinder.results.title')}: </span>
          <strong>{locality}</strong>
        </>
      )}
    </Headline>
    <div className={styles.buttons}>
      <StoreFinderSwitchViewButtons activeView={activeView} switchView={switchView} />
    </div>
  </header>
);

StoreFinderResultsTitle.propTypes = {
  locality: PropTypes.string,
  activeView: PropTypes.oneOf(Object.values(viewTypes)),
  switchView: PropTypes.func.isRequired,
};

StoreFinderResultsTitle.defaultProps = {
  locality: '',
  activeView: viewTypes.LIST,
};

export default StoreFinderResultsTitle;
