import React from 'react';
import language from 'constants/language/language';
import cx from 'classnames';
import styles from 'components/molecules/LinksSocialMedia/LinksSocialMedia.scss';

const LegalLinks = () => {
  const legalLinks = [
    {
      label: language('footer.legalSection.termsConditions'),
      url: language('footer.legalSection.termsConditionsUrl'),
    },
    {
      label: language('footer.legalSection.imprint'),
      url: language('footer.legalSection.imprintUrl'),
    },
    {
      label: language('footer.legalSection.privacyPolicy'),
      url: language('footer.legalSection.privacyPolicyUrl'),
    },
  ];

  return (
    <ul className={cx(styles.legalLinks)}>
      <li>&copy; {new Date().getFullYear()}</li>
      {legalLinks.map(({ label, url }) => (
        <li key={url} className="group flex items-center">
          <a key={label} href={url}>
            {label}
          </a>
        </li>
      ))}
    </ul>
  );
};

export default LegalLinks;
