import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { connect } from 'react-redux';

import { apiStatus as API_STATUS } from 'constants/apiStatus/apiStatus';
import { getCategoryUrl, getStringCategoriesName } from 'constants/categoryTree/categoryTree';
import getUrlForProduct from 'constants/getUrlForProduct/getUrlForProduct';
import imageSizes from 'constants/imageSizes/imageSizes';
import language, { getLocale } from 'constants/language/language';
import dataTracking from 'constants/trackingAttributes/dataTracking';

import { skins as positionSkin } from 'components/molecules/ProductComparisonHeader/ProductComparisonHeader';
import AddToCartButton from 'components/molecules/AddToCartButton/AddToCartButton';
import { Col, Row } from 'components/atoms/Grid/Grid';
import IconLink from 'components/atoms/IconLink/IconLink';
import Icon, { ICON_ADD_CIRCLE_OUTLINE, ICON_CLOSE } from 'components/atoms/Icon/Icon';
import Image from 'components/atoms/Image/Image';
import InputSelect from 'components/atoms/InputSelect/InputSelect';
import Price from 'components/atoms/Price/Price';

import styles from './ProductComparisonListHeaderMobile.scss';
import { productClick } from 'actions/trackingActions/trackingActions';
import { NextFeatureLink } from 'components/atoms/NextFeatureLink';

const mapDispatchToProps = {
  productClick: productClick,
};

class ProductComparisonListHeaderMobile extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (JSON.stringify(nextProps.productCodes) !== JSON.stringify(this.props.productCodes)) {
      this.props.getComparisonList(nextProps.productCodes, true);
    }
  }

  getImages = (imageData) => imageData?.sizes || [];

  handleChange = (category) => {
    this.props.changeCategory(category);
  };

  removeProduct = (code) => {
    this.props.removeProduct(code, this.props.selectedCategory);
  };

  render() {
    const { products, categories, selectedCategory, comparisonCategories } = this.props;

    const productsArray = [...products];
    const categoryArray = Object.keys(comparisonCategories);

    // Fills the array up with empty entries, up to 3
    while (productsArray.length < 3) {
      productsArray.push('');
    }

    const options = {};
    categoryArray.forEach((category) => {
      options[category] = getStringCategoriesName(category, getLocale(), categories);
    });

    if (this.props.apiStatus === API_STATUS.request) {
      return null;
    }

    return (
      <Row className={styles.headerWrapper}>
        <Col>
          {this.props.skin === positionSkin.TOP && (
            <div className={styles.selectWrapper}>
              <span className={styles.selectLabel}>{language('productComparison.list.header')}</span>
              <InputSelect
                value={selectedCategory || categoryArray[0]}
                options={{ ...options }}
                handleChange={this.handleChange}
                disabled={categoryArray.length === 1}
              />
            </div>
          )}
          {productsArray.map((product, index) =>
            !Object.keys(product).length ? (
              <Link key={index} to={getCategoryUrl(selectedCategory, getLocale(), this.props.categories)}>
                <div className={styles.emptyWrapper}>
                  <Icon path={ICON_ADD_CIRCLE_OUTLINE} size={Icon.sizes.xl} />
                  <div className={styles.addMore}>{language('productComparison.list.addMore')}</div>
                </div>
              </Link>
            ) : (
              <div key={index} className={styles.productWrapper}>
                <NextFeatureLink
                  feature="nextPdpEnabled"
                  data-cy={'comparisonProductPDPLink'}
                  to={getUrlForProduct(
                    selectedCategory,
                    product.name,
                    product.code,
                    getLocale(),
                    this.props.categories
                  )}
                  className={styles.imageWrapper}
                  onClick={() => this.props.productClick(product, '')}
                >
                  {!!product.customImageData.length && (
                    <Image
                      alt={product.name}
                      images={this.getImages(product.customImageData[0])}
                      size={imageSizes.productDetail.size}
                      sizes={imageSizes.productDetail.sizes}
                      responsive={imageSizes.productDetail.responsive}
                      code={product.customImageData[0].code}
                    />
                  )}
                </NextFeatureLink>
                <div className={styles.borderContainer}>
                  <NextFeatureLink
                    feature="nextPdpEnabled"
                    to={getUrlForProduct(
                      selectedCategory,
                      product.name,
                      product.code,
                      getLocale(),
                      this.props.categories
                    )}
                    className={styles.nameWrapper}
                    onClick={() => this.props.productClick(product, '')}
                  >
                    {product.name}
                  </NextFeatureLink>
                  <div className={styles.priceWrapper}>
                    <Price productPriceData={product.productPriceData} showInsteadPrice />
                  </div>
                  <div className={styles.buttonContainer}>
                    <AddToCartButton
                      product={product}
                      buttonText={language('cart.title')}
                      productAttributes={{ elementTitle: product.elementTitle }}
                    />
                  </div>
                </div>
                <div
                  className={styles.delete}
                  data-tracking={dataTracking({
                    TYPE: 'compare',
                    ATTRIBUTE_1: 'remove',
                    SKU: product.code,
                  })}
                >
                  <IconLink onClick={() => this.removeProduct(product.code)}>
                    <Icon path={ICON_CLOSE} size={Icon.sizes.xs} />
                  </IconLink>
                </div>
              </div>
            )
          )}
        </Col>
      </Row>
    );
  }
}

ProductComparisonListHeaderMobile.displayName = 'molecules/ProductComparisonListHeaderMobile';
ProductComparisonListHeaderMobile.propTypes = {
  apiStatus: PropTypes.string,
  categories: PropTypes.object,
  changeCategory: PropTypes.func,
  comparisonCategories: PropTypes.object,
  getComparisonList: PropTypes.func,
  productCodes: PropTypes.array,
  products: PropTypes.array,
  removeProduct: PropTypes.func,
  selectedCategory: PropTypes.string,
  skin: PropTypes.string,
  productClick: PropTypes.func,
};
ProductComparisonListHeaderMobile.defaultProps = {
  apiStatus: '',
  categories: {},
  changeCategory: () => {},
  comparisonCategories: {},
  getComparisonList: () => {},
  productCodes: [],
  products: [],
  removeProduct: () => {},
  selectedCategory: '',
  skin: positionSkin.TOP,
  productClick: PropTypes.func,
};

export default connect(undefined, mapDispatchToProps)(ProductComparisonListHeaderMobile);
