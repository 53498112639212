import { handleActions } from 'redux-actions';

import { GET_STOCK_INFO_SUCCESS, RESET_STOCK_INFO } from 'constants/ActionTypes/ActionTypes';

import transform from 'constants/reducerHelper/reducerHelper';

export const initialState = {};

export default handleActions(
  {
    [GET_STOCK_INFO_SUCCESS]: (state, action) => {
      const productCode = action.payload?.productCode;
      return transform(state).set(productCode, action.req.data).value();
    },
    [RESET_STOCK_INFO]: () => initialState,
  },
  initialState
);
