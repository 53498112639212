const truncate = (text = '', maxLength = text.length, separator = '') => {
  if (text.length <= maxLength) return text;

  let shortenedText = text.slice(0, maxLength - 3);

  if (separator) {
    const lastIndex = shortenedText.lastIndexOf(separator);
    shortenedText = shortenedText.slice(0, lastIndex);
  }

  return `${shortenedText.replace(/[^a-z0-9]+$/i, '')}...`;
};

export default truncate;
