// @flow
import { connect } from 'react-redux';

import RatingComments from 'components/organisms/Rating/RatingComments/RatingComments';
import { loadProductRatings, loadMoreProductRatings } from 'actions/ratingActions/ratingActions';
import { getProductCodeFromUrlPath } from 'constants/urlHelper/urlHelper';
import type { IRatingState } from 'flow/ratings';

type IProps = {
  toggleRateEditor: Function,
};

const mapStateToProps = (state: { ratings: IRatingState }, ownProps: IProps) => ({
  numberOfComments: state.ratings.numberOfComments,
  reviews: state.ratings.reviews,
  showMoreBtn: state.ratings.reviews.length < state.ratings.numberOfComments,
  sortOption: state.ratings.commentsQuery.order,
  onShowEditorClick: ownProps.toggleRateEditor,
});

const mapDispatchToProps = (dispatch) => ({
  onSortChange: (optionValue: string) => {
    const productCode = getProductCodeFromUrlPath(window.location.pathname);

    dispatch(
      loadProductRatings(productCode, {
        page: 1,
        pageSize: 3,
        order: optionValue,
      })
    );
  },

  onLoadMoreClick: () => {
    const productCode = getProductCodeFromUrlPath(window.location.pathname);
    dispatch(loadMoreProductRatings(productCode));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RatingComments);
