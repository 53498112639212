import React from 'react';
import PropTypes from 'prop-types';
import language from 'constants/language/language';
import Headline from 'components/atoms/Headline/Headline';
import styles from './ProductOverviewHeadline.scss';

const ProductsOverviewHeadline = ({
  category = '',
  headlineTag = Headline.tags.H1,
  searchString = '',
  totalNumberOfResults,
  brand = {},
}) => {
  const getTitle = () => {
    if (searchString && totalNumberOfResults < 1) {
      return (
        <>
          {language('productsOverview.noSearchResults.before')}
          <strong>{` «${decodeURIComponent(searchString)}» `}</strong>
          {language('productsOverview.noSearchResults.after')}
        </>
      );
    }
    if (totalNumberOfResults < 1) {
      return <>{language('productsOverview.noSearchResults.description')}</>;
    }
    if (searchString && totalNumberOfResults > 0) {
      const isSingularOrPlural = totalNumberOfResults > 1 ? 'plural' : 'singular';
      const searchResultsBeforeText = language(`productsOverview.searchResults.before.${isSingularOrPlural}`);
      const searchResultsAfterText = language(`productsOverview.searchResults.after.${isSingularOrPlural}`);

      return (
        <>
          {totalNumberOfResults.toLocaleString('de-CH')}
          {` ${searchResultsBeforeText}`}
          <strong>{` «${decodeURIComponent(searchString)}» `}</strong>
          {` ${searchResultsAfterText}`}
        </>
      );
    }
    if (category) {
      return category;
    }
    return '';
  };

  return (
    <div className={styles.root}>
      <Headline tag={headlineTag} cyData="noSearchResultHeader">
        {getTitle()}
      </Headline>
      {brand && brand.cmsSearchComponent && (
        <Headline tag={Headline.tags.DIV}>
          {language('search.brand.moreInfo')}{' '}
          <a href={brand.cmsSearchComponent.linkUrl} className={styles.link}>
            {brand.cmsSearchComponent.linkTitle}
          </a>
        </Headline>
      )}
    </div>
  );
};

ProductsOverviewHeadline.displayName = 'atoms/ProductsOverviewHeadline';
ProductsOverviewHeadline.propTypes = {
  category: PropTypes.string,
  headlineTag: PropTypes.oneOf(Object.values(Headline.tags)),
  marginBottom: PropTypes.oneOf(Object.values(Headline.margins)),
  searchString: PropTypes.string,
  totalNumberOfResults: PropTypes.number,
  brand: PropTypes.object,
};

export default ProductsOverviewHeadline;
