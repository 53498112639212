import React from 'react';
import language, { getLocale } from 'constants/language/language';
import { cart } from 'constants/routePaths/routePathCombinations';
import { HEADER } from 'constants/scrollToElement/scrollToElement';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';
import { Container } from 'components/atoms/Grid/Grid';
import HeadLogo, { TYPE_CHECKOUT } from 'components/molecules/HeadLogo/HeadLogo';
import styles from './CheckoutHeader.scss';

const CheckoutHeader = () => (
  <div id={HEADER} className={styles.checkoutHeader}>
    <Container className={styles.container}>
      <div className={styles.logoWrapper}>
        <HeadLogo type={TYPE_CHECKOUT} />
      </div>
      <ButtonLink
        variety={ButtonLink.varieties.stroked}
        color={ButtonLink.colors.blue}
        href={mapPathToLocalizedUrl(getLocale(), cart)}
      >
        {language('checkout.header.backToCart')}
      </ButtonLink>
    </Container>
  </div>
);

CheckoutHeader.displayName = 'organisms/CheckoutHeader';

export default CheckoutHeader;
