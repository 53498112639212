import { cloneElement, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { scrollToElement, TOP_CONTENT_ANCHOR } from 'constants/scrollToElement/scrollToElement';
import { deleteWatchlist, getWatchlist, removeFromWatchlist } from 'actions/watchlistActions/watchlistActions';
import { trackWatchlist } from 'actions/trackingActions/trackingActions';

export const mapStateToProps = (state) => ({
  products: state?.watchlist?.entries.map((entry) => ({ ...entry, elementTitle: 'watchlist' })) || [],
  isLoggedIn: (state?.user?.uid || 'anonymous') !== 'anonymous',
  categories: state?.categories || {},
});

export const mapDispatchToProps = {
  removeFromWatchlist,
  deleteWatchlist,
  getWatchlist,
  trackWatchlist,
};

export class WatchlistContainer extends Component {
  UNSAFE_componentWillMount() {
    this.props.getWatchlist().then(() => {
      this.props.trackWatchlist();
    });
  }

  removeFromWatchlist = (productCode) => {
    const arrayIndex = this.props.products.findIndex((product) => product.code === productCode);
    this.props.removeFromWatchlist(arrayIndex);
  };

  deleteWatchlist = () => {
    this.props.deleteWatchlist();
    scrollToElement(TOP_CONTENT_ANCHOR);
  };

  render() {
    return cloneElement(this.props.children, {
      products: this.props.products,
      removeFromWatchlist: this.removeFromWatchlist,
      deleteWatchlist: this.deleteWatchlist,
      isLoggedIn: this.props.isLoggedIn,
      categories: this.props.categories,
    });
  }
}

WatchlistContainer.displayName = 'containers/WatchlistContainer';
WatchlistContainer.propTypes = {
  categories: PropTypes.object,
  children: PropTypes.element.isRequired,
  deleteWatchlist: PropTypes.func,
  getWatchlist: PropTypes.func,
  isLoggedIn: PropTypes.bool,
  products: PropTypes.array,
  removeFromWatchlist: PropTypes.func,
  trackWatchlist: PropTypes.func,
};
WatchlistContainer.defaultProps = {
  categories: {},
  deleteWatchlist: () => {},
  getWatchlist: () => {},
  isLoggedIn: false,
  products: [],
  removeFromWatchlist: () => {},
  trackWatchlist: () => {},
};

export default connect(mapStateToProps, mapDispatchToProps)(WatchlistContainer);
