import React from 'react';
import PropTypes from 'prop-types';

import Stepper, { StepperStep } from 'components/molecules/Stepper/Stepper';
import ReturnOrderSelect from './ReturnOrderSelect';
import ReturnProductSelect from './ReturnProductSelect';
import ReturnReason from './ReturnReason';
import ReturnPayment from './ReturnPayment';
import ReturnOverview from './ReturnOverview';
import ReturnConclusion from './ReturnConclusion';
import language from 'constants/language/language';

const RETURN_DETAILS_STEPS = [
  {
    title: 'returns.orderSelect.title',
    component: ReturnOrderSelect,
  },
  {
    title: 'returns.productSelect.title',
    component: ReturnProductSelect,
  },
  {
    title: 'returns.returnReason.title',
    component: ReturnReason,
  },
  {
    title: 'returns.returnPayment.title',
    component: ReturnPayment,
  },
  {
    title: 'returns.returnOverview.title',
    component: ReturnOverview,
  },
  {
    title: 'returns.returnConclusion.title',
    component: ReturnConclusion,
  },
];

const ReturnSteps = ({ currentStep = 0, onStepComplete = () => {}, state = {} }) => (
  <Stepper initialStep={currentStep} scrollToActiveStep scrollOffset={-170}>
    {RETURN_DETAILS_STEPS.map((step) => (
      <StepperStep key={step.title} label={language(step.title)}>
        {({ onPrevClick, onNextClick, activeStep }) => (
          <step.component
            onPrevClick={onPrevClick}
            onNextClick={(data) => {
              onStepComplete(data);
              onNextClick();
            }}
            activeStep={activeStep}
            state={state}
          />
        )}
      </StepperStep>
    ))}
  </Stepper>
);

ReturnSteps.displayName = 'molecules/ReturnSteps';

ReturnSteps.propTypes = {
  onStepComplete: PropTypes.func,
  state: PropTypes.object,
  currentStep: PropTypes.number,
};

export default ReturnSteps;
