let temporaryCookieStorage = {};
let temporarySessionStorage = {};
let temporaryLocalStorage = {};

const getTemporaryStorage = (permanent, cookie) => {
  let storage;

  if (cookie) {
    storage = temporaryCookieStorage;
  } else if (permanent) {
    storage = temporaryLocalStorage;
  } else {
    storage = temporarySessionStorage;
  }

  return {
    setItem(key, value) {
      storage[key] = value;
    },
    getItem(key) {
      return storage[key] || null;
    },
    removeItem(key) {
      storage[key] = null;
    },
    clear() {
      if (cookie) {
        temporaryCookieStorage = {};
        storage = temporaryCookieStorage;
      } else if (permanent) {
        temporaryLocalStorage = {};
        storage = temporaryLocalStorage;
      } else {
        temporarySessionStorage = {};
        storage = temporarySessionStorage;
      }
    },
  };
};

export default getTemporaryStorage;
