import React from 'react';
import DrilldownAccordion from 'components/atoms/DrilldownAccordion/DrilldownAccordion';
import MobileNavigationItem from 'components/atoms/MobileNavigationItem/MobileNavigationItem';
import language, { getLocale } from 'constants/language/language';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';

import { STOREFINDER } from 'constants/routePaths/routePaths';
import { NAVIGATION_SUB } from 'constants/trackingAttributes/trackingAttributes';

import PropTypes from 'prop-types';
import useFeatureToggle from "hooks/useFeatureToggle";

const StoresSection = ({ isResetToStart }) => {
  const nextStorefinderEnabled = useFeatureToggle("nextStorefinderEnabled") === 'true' ?? false;
  return (
    <DrilldownAccordion title={language('navigation.stores')} pos={3} isResetToStart={isResetToStart}>
      <MobileNavigationItem
        href={mapPathToLocalizedUrl(getLocale(), [STOREFINDER])}
        external={nextStorefinderEnabled}
        tracking={{
          type: NAVIGATION_SUB,
          position: 1,
          title: language('navigation.storeFinder'),
          parentTitle: language('navigation.stores'),
        }}
      >
        {language('navigation.storeFinder')}
      </MobileNavigationItem>
    </DrilldownAccordion>
  );
};

export default StoresSection;

StoresSection.propTypes = {
  isResetToStart: PropTypes.func,
};
