import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactRecaptcha from 'react-recaptcha';

import ErrorMessageInput from 'components/molecules/FieldSetInput/ErrorMessageInput';
import { verifyRecaptcha } from 'actions/userActions/userActions';
import { recaptchaAreas } from 'constants/recaptcha/recaptchaAreas';
import language, { getLocale } from 'constants/language/language';
import styles from './Recaptcha.scss';

const recaptchaKey = __CLIENT__ && window.__ENV_VARIABLES__.recaptchaKey;

const mapStateToProps = (state, ownProps) => ({
  userId: ownProps.userId || state.user.uid,
});

const mapDispatchToProps = {
  verifyRecaptcha,
};

class Recaptcha extends Component {
  static propTypes = {
    captchaArea: PropTypes.string.isRequired,
    showRequiredError: PropTypes.bool,
    // mapped
    userId: PropTypes.string,
    verifyRecaptcha: PropTypes.func,
  };

  static areas = recaptchaAreas;

  constructor(props) {
    super(props);
    this.recaptchaInstance = React.createRef();
  }

  /**
   * Is called after reCAPTCHA has been solved successfully
   */
  onCaptchaVerify = (captchaResponse) => {
    const { userId, captchaArea } = this.props;
    this.props.verifyRecaptcha(captchaResponse, userId, captchaArea);
  };

  render() {
    const { showRequiredError } = this.props;

    return (
      <div className={styles.recaptchaWrapper}>
        <ReactRecaptcha
          ref={this.recaptchaInstance}
          sitekey={recaptchaKey}
          verifyCallback={this.onCaptchaVerify}
          hl={getLocale()}
        />
        <ErrorMessageInput showError={showRequiredError}>{language('feedbackForm.recaptchaError')}</ErrorMessageInput>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Recaptcha);
