import React from 'react';
import { Container, Col, Row } from 'components/atoms/Grid/Grid';
import language, { getLocale } from 'constants/language/language';
import Headline from 'components/atoms/Headline/Headline';
import Icon, { ICON_MAIL_OUTLINE } from 'components/atoms/Icon/Icon';
import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import { newsletterRegistration } from 'constants/routePaths/routePathCombinations';
import styles from './NewsletterWidget.scss';

const NewsletterWidget = () => {
  const getDescription = () => {
    // set innerHTML to visualize <b> Tags
    return { __html: language('newsletterWidget.description') };
  };

  const getNewsletterUrl = () => {
    return mapPathToLocalizedUrl(getLocale(), newsletterRegistration);
  };

  return (
    <Container className={styles.container}>
      <Row className={styles.wrapper}>
        <Col className={styles.textWrapper} xl={9} lg={7} md={7} sm={12} xs={12}>
          <Headline tag="h4">{language('newsletterWidget.title')}</Headline>
          <span dangerouslySetInnerHTML={getDescription()} />
        </Col>
        <Col className={styles.buttonWrapper} xl={3} lg={5} md={5} sm={12} xs={12}>
          <ButtonLink color="red" href={getNewsletterUrl()}>
            <Icon path={ICON_MAIL_OUTLINE} />
            <span>{language('newsletterWidget.button')}</span>
          </ButtonLink>
        </Col>
      </Row>
    </Container>
  );
};

NewsletterWidget.displayName = 'organisms/NewsletterWidget';

export default NewsletterWidget;
