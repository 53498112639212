import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import bootstrap from 'scss/component.scss';

const Col = ({
  children,
  xxs,
  xs,
  sm,
  md,
  lg,
  xl,
  offsetXxs,
  offsetXs,
  offsetSm,
  offsetMd,
  offsetLg,
  offsetXl,
  className,
  id,
  onClick,
  cyData,
}) => (
  <div
    className={cx({
      [bootstrap[`col${xxs}`]]: xxs,
      [bootstrap[`colXs${xs}`]]: xs,
      [bootstrap[`colSm${sm}`]]: sm,
      [bootstrap[`colMd${md}`]]: md,
      [bootstrap[`colLg${lg}`]]: lg,
      [bootstrap[`colXl${xl}`]]: xl,

      [bootstrap[`offset${offsetXxs}`]]: Number.isInteger(offsetXxs),
      [bootstrap[`offsetXs${offsetXs}`]]: Number.isInteger(offsetXs),
      [bootstrap[`offsetSm${offsetSm}`]]: Number.isInteger(offsetSm),
      [bootstrap[`offsetMd${offsetMd}`]]: Number.isInteger(offsetMd),
      [bootstrap[`offsetLg${offsetLg}`]]: Number.isInteger(offsetLg),
      [bootstrap[`offsetXl${offsetXl}`]]: Number.isInteger(offsetXl),

      [className]: !!className,
    })}
    id={id}
    onClick={onClick}
    data-cy={cyData}
  >
    {children}
  </div>
);

Col.displayName = 'atoms/Grid/Col';

Col.defaultProps = {
  xxs: 12,
};

Col.propTypes = {
  children: PropTypes.node,
  xxs: PropTypes.number,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
  offsetXxs: PropTypes.number,
  offsetXs: PropTypes.number,
  offsetSm: PropTypes.number,
  offsetMd: PropTypes.number,
  offsetLg: PropTypes.number,
  offsetXl: PropTypes.number,
  className: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
  cyData: PropTypes.string,
};

export default Col;
