import React from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';

import language from 'constants/language/language';

import Icon, { ICON_ACCESS_TIME, ICON_CANCEL_CIRCLE_OUTLINE, ICON_CLOSE } from 'components/atoms/Icon/Icon';
import SearchOverlayBox from 'components/atoms/SearchOverlayBox/SearchOverlayBox';

import {
  SEARCH_HISTORY,
  SEARCH_HISTORY_TYPE_LIST,
  SEARCH_HISTORY_TYPE_LIST_ENTRY,
} from 'constants/trackingAttributes/trackingAttributes';
import { trackSearchHistoryDelete, trackSearchType } from 'tracking/tracking';

import styles from './SearchHistory.scss';
import { recoTrackProductSearch } from 'api/RecoAPI/RecoAPI';
import { RECO_COOKIE } from 'constants/storage/storageKeys';

import getCookieStorage from 'constants/storage/cookie';
import uuid from 'uuid/v5';
import { connect } from 'react-redux';

const cookie = getCookieStorage();

const mapStateToProps = (state) => ({
  email: state?.user?.fields?.email?.value || undefined,
  onlineId: state?.user?.onlineId || undefined,
});

const SearchHistory = ({
  historyLinks,
  deleteSearchHistoryEntry,
  deleteSearchHistory,
  onClick,
  email = undefined,
  onlineId = undefined,
}) => {
  const handleRemoveEntry = (event, searchTerm) => {
    event.preventDefault();
    event.stopPropagation();
    trackSearchHistoryDelete(SEARCH_HISTORY_TYPE_LIST_ENTRY, window.location.pathname);
    deleteSearchHistoryEntry(searchTerm);
  };

  const handleDeleteSearchHistory = () => {
    trackSearchHistoryDelete(SEARCH_HISTORY_TYPE_LIST, window.location.pathname);
    deleteSearchHistory();
  };

  const handleClick = (link) => {
    let recoID = '';
    if (email) recoID = uuid(email, uuid.DNS);
    if (onlineId) recoID = onlineId;

    trackSearchType(link.text, SEARCH_HISTORY);
    recoTrackProductSearch(link.text, cookie.getItem(RECO_COOKIE), recoID);
    onClick(link);
  };

  if (historyLinks.length === 0) {
    return <></>;
  }

  // todo: remove 'toLowerCase' when BE sends all links lowercase (Also in ThemeSuggestion.js)
  return (
    <SearchOverlayBox hasItems={historyLinks.length > 0}>
      <div className={styles.historyContainer}>
        <button className={styles.deleteHistory} onClick={handleDeleteSearchHistory}>
          <Icon path={ICON_CANCEL_CIRCLE_OUTLINE} />
          <span>{language('search.clearHistory')}</span>
        </button>
        <ul className={styles.historyList}>
          {historyLinks.map((link) => (
            <li className={styles.historyListItem} key={link.uri}>
              <Link
                to={decodeURI(link.url ?? link.uri).toLowerCase()}
                className={styles.historyItem}
                onClick={() => handleClick(link)}
              >
                <div className={styles.icon}>
                  <Icon path={ICON_ACCESS_TIME} />
                </div>
                <div className={styles.textContainer}>
                  <span>{link.text}</span>
                  {link.type !== 'search' && (
                    <span className={styles.entryType}>{language(`search.topicTypes.${link.type}`)}</span>
                  )}
                </div>
              </Link>
              <button className={styles.historyItemDelete} onClick={(event) => handleRemoveEntry(event, link.text)}>
                <Icon path={ICON_CLOSE} />
              </button>
            </li>
          ))}
        </ul>
      </div>
    </SearchOverlayBox>
  );
};

export default connect(mapStateToProps)(SearchHistory);

SearchHistory.displayName = 'organisms/SearchHistory';

SearchHistory.propTypes = {
  historyLinks: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      text: PropTypes.string,
      openNewTab: PropTypes.bool,
      type: PropTypes.string,
      uri: PropTypes.string,
    })
  ),
  deleteSearchHistoryEntry: PropTypes.func,
  deleteSearchHistory: PropTypes.func,
  closeSearch: PropTypes.func,
  onClick: PropTypes.func,
  email: PropTypes.string,
  onlineId: PropTypes.string,
};

SearchHistory.defaultProps = {
  deleteSearchHistoryEntry: () => {},
  deleteSearchHistory: () => {},
  historyLinks: [],
  onClick: () => {},
};
