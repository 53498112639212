import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Icon, { ICON_BASKET } from 'components/atoms/Icon/Icon';
import { noop } from 'constants/noop/noop';
import styles from './AddToCartIcon.scss';

const AddToCartIcon = ({ className, handleClick = noop, isInCart }) => (
  <button className={cx(className, styles.icon, { [styles.iconActive]: isInCart })} onClick={handleClick}>
    <Icon path={ICON_BASKET} />
  </button>
);

AddToCartIcon.displayName = 'atoms/AddToCartIcon/AddToCartIcon';
AddToCartIcon.propTypes = {
  className: PropTypes.string,
  handleClick: PropTypes.func,
  isInCart: PropTypes.bool,
};

export default AddToCartIcon;
