import React, { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Portal from 'components/molecules/Portal/Portal';
import AddToCartButton from 'components/molecules/AddToCartButton/AddToCartButton';
import { ADD_TO_CART_IDENTIFIER } from 'components/organisms/ProductInfoMain/ProductInfoMain';
import Container from 'components/atoms/Grid/Container';
import Image, { getAltText } from 'components/atoms/Image/Image';
import Price from 'components/atoms/Price/Price';

import { BREAKPOINTS } from 'constants/breakpoints/breakpoints';
import matchMedia from 'constants/matchMedia/matchMedia';

import styles from './StickyAddToCart.scss';
import CookieBanner from 'components/atoms/CookieBanner/CookieBanner';

const StickyAddToCart = ({ buttonText, product, id, buttonRef = {} }) => {
  const [isStickyAddToCartVisible, setIsStickyAddToCartVisible] = useState(false);

  const isMobile = !matchMedia(BREAKPOINTS.MD);
  const stickyButtonRef = isMobile ? buttonRef : null;

  const isAddToCartVisible = useCallback(() => {
    const addToCartButton = document.getElementById(`formControl-${ADD_TO_CART_IDENTIFIER}-addToCart-button`);

    if (!addToCartButton) return;
    const clientRects = addToCartButton.getClientRects()?.[0];
    const headerHeight = 67;
    setIsStickyAddToCartVisible(isMobile || clientRects.top + clientRects.height - headerHeight < 0);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', isAddToCartVisible);

    return () => {
      window.removeEventListener('scroll', isAddToCartVisible);
    };
  }, [isAddToCartVisible]);

  const { customImageData, productPriceData } = product;

  const renderPrice = (className) => (
    <div className={className}>
      <h4 className={styles.productName}>{product.name}</h4>
      <Price skin={Price.SKINS.STICKY_ADD_TO_CART} productPriceData={productPriceData} showInsteadPrice />
    </div>
  );

  return (
    <Portal>
      <div
        className={cx(styles.sticky, {
          [styles.visible]: isStickyAddToCartVisible,
          [styles.stickyMobile]: isMobile,
        })}
        ref={stickyButtonRef}
      >
        <div id="cookieBannerAnchor">
          <CookieBanner marginBottom={isMobile ? 59 : 81} />
        </div>
        <Container className={styles.container}>
          {!isMobile && (
            <div className={styles.productImage}>
              <Image
                alt={getAltText(customImageData)}
                images={customImageData?.[0]?.sizes}
                code={customImageData?.[0]?.code}
              />
            </div>
          )}
          {!isMobile && renderPrice(styles.productInformation)}
          <div className={styles.cartButton}>
            <AddToCartButton product={product} buttonText={buttonText} id={id} showIcon />
          </div>
        </Container>
      </div>
    </Portal>
  );
};

StickyAddToCart.displayName = 'molecules/StickyAddToCart';

StickyAddToCart.propTypes = {
  buttonText: PropTypes.string.isRequired,
  product: PropTypes.object.isRequired,
  id: PropTypes.string,
  buttonRef: PropTypes.func,
};

export default StickyAddToCart;
