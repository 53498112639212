export const possibleStrokes = {
  strokeWidth010: 'strokeWidth010',
  strokeWidth012: 'strokeWidth012',
  strokeWidth015: 'strokeWidth015',
  strokeWidth017: 'strokeWidth017',
  strokeWidth020: 'strokeWidth020',
};

/**
 * Icon Stroke calculation
 * @param {object} data Object with height and Stroke information
 * @return {Number}
 */
const iconStrokes = (data) => {
  let result = null;
  let iconStrokesValues = {};

  if (data) {
    //  Calculation of the stroke based on basic values
    switch (data.stroke) {
      case 'strokeWidth010':
      default:
        iconStrokesValues = {
          width: 15,
          stroke: 36,
        };

        break;
      case 'strokeWidth012':
        iconStrokesValues = {
          width: 21,
          stroke: 36,
        };

        break;
      case 'strokeWidth015':
        iconStrokesValues = {
          width: 27,
          stroke: 36,
        };

        break;
      case 'strokeWidth017':
        iconStrokesValues = {
          width: 46,
          stroke: 36,
        };

        break;
      case 'strokeWidth020':
        iconStrokesValues = {
          width: 66,
          stroke: 36,
        };

        break;
    }

    result = parseInt((iconStrokesValues.stroke / data.height) * iconStrokesValues.width, 10);
  }

  return result;
};

export default iconStrokes;
