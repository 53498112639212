import { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { FeatureToggleContext } from 'contexts/FeatureTogglingProvider/FeatureTogglingProvider';
import { getFeatureToggleConfig } from 'hooks/useFeatureToggle';

const NextFeatureEnabled = ({ location, conditions }) => {
  const { featureTogglingConfig } = useContext(FeatureToggleContext);

  const features = conditions.map(({ featureName, include, exclude }) => ({
    featureName,
    isEnabled: getFeatureToggleConfig(featureTogglingConfig, featureName) === 'true' ?? false,
    include,
    exclude,
  }));

  useEffect(() => {
    const shouldReload = features.some(({ isEnabled, include, exclude }) => {
      const isIncluded = location.pathname.includes(include);
      const isExcluded = exclude && location.pathname.includes(exclude);
      return isIncluded && !isExcluded && isEnabled;
    });

    if (shouldReload) {
      console.log('Reloading page due to feature toggle and URL match');
      window.location.reload();
    }
  }, [location, features, conditions]);

  return null;
};

NextFeatureEnabled.propTypes = {
  location: PropTypes.object.isRequired,
  conditions: PropTypes.arrayOf(
    PropTypes.shape({
      include: PropTypes.string.isRequired,
      exclude: PropTypes.string,
      featureName: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default withRouter(NextFeatureEnabled);
