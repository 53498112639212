import { useEffect, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getUspElements as getUspElementsAction } from 'actions/cmsActions/cmsActions';

export const mapStateToProps = (state) => {
  const path = state.cms?.currentPath;
  const elements = state.cms?.uspElements;
  const title = state?.cms?.uspTitle;
  return {
    path,
    elements,
    title,
  };
};

const mapDispatchToProps = {
  getUspElements: getUspElementsAction,
};

const UspContainer = (props) => {
  const { getUspElements, path } = props;

  useEffect(() => {
    getUspElements();
  }, []);

  if (path.includes('registration') || path.includes('login')) return null;

  return cloneElement(props.children, props);
};

UspContainer.displayName = 'containers/UspContainer';
UspContainer.propTypes = {
  path: PropTypes.string,
  getUspElements: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(UspContainer);
