import React from 'react';
import PropTypes from 'prop-types';
import language from 'constants/language/language';

import FlashMessage from 'components/atoms/FlashMessage/FlashMessage';
import { types as flashMessageTypes } from 'constants/flashMessages/flashMessages';
import styles from './TimeSensitiveInfoBox.scss';

const TimeSensitiveInfoBox = ({
  isMainInfo,
  priceUpdated,
  stockUpdated,
  removedEntries = [],
  voucherRedeemed,
  discountUpdated,
}) => (
  <FlashMessage type={(priceUpdated || discountUpdated) ? flashMessageTypes.INFO : flashMessageTypes.WARNING}
                alignment={FlashMessage.alignments.CENTER}>
    {isMainInfo && <span className={styles.mainInfo}>{language('timeSensitive.infoBox.mainInfo')}</span>}
    {(priceUpdated || discountUpdated) && (
      <>
        <span>{language('timeSensitive.infoBox.updatedPrice.text1')}</span>
        <span className={styles.keyword}>{language('timeSensitive.infoBox.updatedPrice.keyword')}</span>
        <span>{language('timeSensitive.infoBox.updatedPrice.text2')}</span>
      </>
    )}
    {stockUpdated && (
      <>
        <span>{language('timeSensitive.infoBox.updatedStock.text1')}</span>
        <span className={styles.keyword}>{language('timeSensitive.infoBox.updatedStock.keyword')}</span>
        <span>{language('timeSensitive.infoBox.updatedStock.text2')}</span>
      </>
    )}
    {removedEntries.length > 0 && (
      <div className={styles.removedEntryWrapper}>
        <div>
          <span>{language('timeSensitive.infoBox.removedEntry.text1')}</span>
          <span className={styles.keyword}>{language('timeSensitive.infoBox.removedEntry.keyword')}</span>
          <span>{language('timeSensitive.infoBox.removedEntry.text2')}</span>
        </div>
        <div className={styles.removedEntries}>
          {removedEntries.map((entry, index) => (
            <span key={index}>{entry.productName}</span>
          ))}
        </div>
      </div>
    )}
    {voucherRedeemed && (
      <div className={styles.voucherRedeemed}>
        <span className={styles.keyword}>{language('timeSensitive.modalVoucherRedeemed.headline')}</span>
        <span>{language('timeSensitive.modalVoucherRedeemed.info')}</span>
      </div>
    )}
  </FlashMessage>
);

TimeSensitiveInfoBox.displayName = 'atoms/TimeSensitiveInfoBox';

TimeSensitiveInfoBox.propTypes = {
  isMainInfo: PropTypes.bool,
  priceUpdated: PropTypes.bool,
  stockUpdated: PropTypes.bool,
  removedEntries: PropTypes.array,
  voucherRedeemed: PropTypes.bool,
  discountUpdated: PropTypes.bool,
};

export default TimeSensitiveInfoBox;
