import React from 'react';
import PropTypes from 'prop-types';
import FooterToggle from 'components/molecules/FooterToggle/FooterToggle';

const FooterColumn = ({ headline, headlineTag, displayArrow, shouldHide, children, skin }) => (
  <FooterToggle
    headline={headline}
    headlineTag={headlineTag}
    displayArrow={displayArrow}
    shouldHide={shouldHide}
    skin={skin}
  >
    {children}
  </FooterToggle>
);

FooterColumn.displayName = 'molecules/FooterColumn';

FooterColumn.propTypes = {
  headline: PropTypes.string,
  headlineTag: PropTypes.string,
  children: PropTypes.node.isRequired,
  shouldHide: PropTypes.bool,
  displayArrow: PropTypes.bool,
  skin: PropTypes.string,
};

FooterColumn.defaultProps = {
  shouldHide: true,
  displayArrow: true,
};

export default FooterColumn;
