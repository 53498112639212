// @flow
import queryString from 'query-string';
import merge from 'lodash/merge';
import has from 'constants/helper/has';
import chunk from 'constants/helper/chunk';
import omit from 'constants/helper/omit';
import { getCategoriesPath, getCategoryUrl } from 'constants/categoryTree/categoryTree';
import { getLocale } from 'constants/language/language';
import { getCategoryCodeFromUrlPath } from 'constants/urlHelper/urlHelper';

import { CATEGORY_SEARCH, PRODUCT_SEARCH } from 'constants/routePaths/routePaths';
import { getFacetRegExp } from 'constants/facetsHelper/facetsHelper';

const defaultPage = 1;
const defaultPageSize = 24;
const defaultSort = 'relevance';

type queryParams = {
  facetQuery?: string,
  page?: number,
  searchString?: string,
  sort?: string,
  categoryCode?: string,
};

type urlParamsType = {
  search?: string,
  facets?: string,
  sortQuery?: string,
  pageQuery?: string,
};

export const getCategoryPathFromCategoryQuery = (_categoryQuery: string) => {
  // e.g. _categoryQuery = 500-5100-511000 will return /1/500_/1/500/5100_/1/500/5100/511000
  if (_categoryQuery) {
    const categoryPath = _categoryQuery.split('-').reduce((accumulator, currentValue, index) => {
      const isFirstIteration = index === 0;
      const pathBasis = isFirstIteration ? '/1' : `${accumulator.split('_').slice(-1)}`;
      const path = isFirstIteration ? `${pathBasis}/${currentValue}` : `${accumulator}_${pathBasis}/${currentValue}`;
      return path;
    }, '');
    return categoryPath;
  }
  return _categoryQuery;
};

export const prepareUrlToParse = (url: string): string => {
  // replace '+' in url with '_' because queryString.parse parse a '+' into a whitespace
  // if a value with a whitespace exists, we have too much values.
  if (url && url.indexOf('+') > 0) {
    // don't replace "+" in search term otherwise you will produce a or search in solr
    if (url.indexOf('search=') > -1) {
      // check if facet values exits
      if (url.indexOf('&') > -1) {
        // exclude search request param from replacing
        const searchPart = url.substring(0, url.indexOf('&'));

        let facetsValuePart = url.substring(url.indexOf('&'), url.length);
        facetsValuePart = facetsValuePart.replace(/[+]/g, '_');

        return `${searchPart}${facetsValuePart}`;
      }
      return url;
    }
    return url.replace(/[+]/g, '_');
  }
  return url;
};

export const createFacetQuery = (omittedURL: string, categoryQuery?: ?string) => {
  const facetArray = [];
  const clearedURL = {};

  // remove empty object properties
  // eslint-disable-next-line no-unused-expressions
  Object.keys(omittedURL || {})?.forEach((key) => {
    if (omittedURL[key]) {
      clearedURL[key] = omittedURL[key];
    }
  });

  Object.entries(clearedURL).forEach(([key, value]) => {
    if (value.indexOf('_') >= 0) {
      const values = value.split('_');
      values.forEach((val) => {
        const encodedVal = val;
        facetArray.push(`${key}:${encodedVal}`);
      });
    } else {
      const encodedValue = value;
      facetArray.push(`${key}:${encodedValue}`);
    }
  });

  let facetQuery = '';

  // check if a category was set
  if (categoryQuery) {
    if (facetArray.length === 0) {
      facetQuery = `${categoryQuery}`;
    } else {
      facetQuery = `${categoryQuery}:${facetArray.join(':')}`;
    }
  } else {
    // check if facetArray has elements, and add a ':' for a valid facetQuery
    if (facetArray.length > 0) {
      facetQuery = `:${facetArray.join(':')}`;
    }
  }

  return encodeURIComponent(facetQuery);
};

export const createCategoryFacetQuery = (categoryCode: string, categories: Object) => {
  const categoriesPath = getCategoriesPath(categoryCode, categories);

  if (categoriesPath.length > 1) {
    const parentCategoryPath = categoriesPath.slice(0, categoriesPath.length - 1);
    const currentCategoryQuery = `:categoryPath:/1/${categoriesPath.join('/')}`;
    const parentCategoryQuery = `:categoryPath:/1/${parentCategoryPath.join('/')}`;

    return `${parentCategoryQuery}${currentCategoryQuery}`;
  }

  return !categoriesPath.length ? '' : `:categoryPath:/1/${categoriesPath}`;
};

export const mapUrlToQueryParams = (url: string, categories: Object): queryParams => {
  // prepare url to iterate over it
  const splittedUrl = url.split('?');

  const parsedURL = queryString.parse(splittedUrl.length > 0 ? prepareUrlToParse(splittedUrl[1]) : '');

  // remove unwanted '&' at the end of url to prevent errors
  delete parsedURL[''];

  const { sort = defaultSort, search = '', page = defaultPage, ...rest } = parsedURL;

  // There is a problem with query string parameters that contain dots,
  // as some SEO paramters do http://jira:8080/browse/SPQR-5038
  // Our usual paramerters don't contain dots (or colons for that matter), so they can be filtered out
  // before the facet query is put together for the backend:
  const omittedURL = {};

  // eslint-disable-next-line no-unused-expressions
  Object.keys(rest)?.forEach((key) => {
    if (!key.includes('.') && !key.includes(':')) {
      omittedURL[key] = parsedURL[key];
    }
  });

  let categoryQuery;
  let categoryCode;

  if (splittedUrl[0].lastIndexOf('--c') > -1) {
    categoryCode = getCategoryCodeFromUrlPath(splittedUrl[0]);
    if (categoryCode) {
      categoryQuery = createCategoryFacetQuery(categoryCode, categories);
    }
  }

  if (omittedURL.category) {
    categoryCode = omittedURL.category.split('-').splice(-1)[0];
    const categoryPath = getCategoryPathFromCategoryQuery(omittedURL.category);
    delete omittedURL.category;
    omittedURL.categoryPath = categoryPath;
  }

  const facetQuery = createFacetQuery(omittedURL, categoryQuery);

  const _queryParams: queryParams = {
    searchString: encodeURIComponent(search),
    sort,
    facetQuery,
    page,
  };

  if (categoryCode) _queryParams.categoryCode = categoryCode;

  return _queryParams;
};

const createFacetQueryString = (queryArray, isCmsPage): string => {
  // first, remove empty values
  const facetQueryArray = queryArray.slice(1);
  let facetQueryObject = {};

  let key = '';
  for (let i = 0; i < facetQueryArray.length; i++) {
    if (i % 2 === 0) {
      key = facetQueryArray[i];
    } else {
      // prevent double encoding
      const facetValue = facetQueryArray[i];
      // check if the property already has a value
      if (facetQueryObject[key]) {
        facetQueryObject[key] = `${facetQueryObject[key]}+${facetValue}`;
      } else {
        facetQueryObject[key] = facetValue;
      }
    }
  }

  if (isCmsPage) {
    const categoryPath = facetQueryObject?.categoryPath;
    const categoryCodes = categoryPath?.substr(categoryPath?.lastIndexOf('%2F1%') + 7)?.replace(/%2F/g, '-');
    if (categoryCodes) {
      facetQueryObject = { ...{ category: categoryCodes }, ...facetQueryObject };
    }
  }

  const facetQueryRest = omit(facetQueryObject, ['categoryPath']);
  return queryString.stringify(facetQueryRest, { encode: false });
};

/**
 *
 * Converts a queryString to QueryParams
 * @param url
 * @returns {Object}
 */
export const mapQueryStringToQueryParams = (url: string): queryParams => {
  const parsedQueryString = url ? queryString.parse(url) : {};
  const { currentPage = 1 } = parsedQueryString;

  let facetQuery;

  // workaround to solve parse issue with special character in query string
  const queryParam = 'query=';
  if (url && url.indexOf(queryParam) > -1) {
    const queryParamIndex = url.lastIndexOf(queryParam);
    facetQuery = url.substring(queryParamIndex + queryParam.length);
  }

  const queryParameters = facetQuery ? facetQuery.split('%3A') : '';

  const searchString = queryParameters[0];
  const sort = queryParameters[1] ? queryParameters[1] : defaultSort;

  const page = parseInt(currentPage, 10);

  return {
    facetQuery,
    page,
    searchString,
    sort,
  };
};

export const mapFacetQueryParamsToCategoryCode = (facetQueryParams: Array<any>): string => {
  // get index of categoryPath to check if categoryPath exists - if yes then get categoryCode
  const categoryPathIndex = facetQueryParams.lastIndexOf('categoryPath');

  if (categoryPathIndex >= 0) {
    const categoryCodesArray = facetQueryParams[categoryPathIndex + 1].split('%2F');
    return categoryCodesArray[categoryCodesArray.length - 1];
  }
  return '';
};

export const mapFacetQueryStringToFacetQueryParams = (facetQueryString: string, sort: string): Array<string> => {
  const facetQueryArray = facetQueryString ? facetQueryString.split('%3A') : [];
  // remove sort value from facet query
  return facetQueryArray.filter((facet) => facet !== sort);
};

export const mapQueryParamsToCategoryCode = (params: queryParams) => {
  const { sort = defaultSort, facetQuery = '' }: queryParams = params;
  const facetQueryParams = mapFacetQueryStringToFacetQueryParams(facetQuery, sort);
  return mapFacetQueryParamsToCategoryCode(facetQueryParams);
};

export const getCategoryCodeFromQueryString = (_queryString: string) => {
  const _queryParams = mapQueryStringToQueryParams(_queryString);
  return mapQueryParamsToCategoryCode(_queryParams);
};

export const mapQueryParamsToUrlParams = (params: queryParams, isCmsPage: Boolean): urlParamsType => {
  const { sort = defaultSort, searchString = '', page = defaultPage, facetQuery = '' }: queryParams = params;
  const facetQueryParams = mapFacetQueryStringToFacetQueryParams(facetQuery, sort);
  const facetString = createFacetQueryString(facetQueryParams, isCmsPage);

  // prepare urlParams, filter all params which are not null, undefined or zero and join all with '&'
  const urlparams = {
    search: searchString ? `search=${searchString}` : '',
    facets: facetString ? `${facetString}` : '',
    sortQuery: sort !== defaultSort ? `sort=${sort}` : '',
    pageQuery: page !== defaultPage ? `page=${page}` : '',
  };
  return urlparams;
};

/**
 *
 * Concatenate all parameters for the search and returns a query string
 * @param params
 * @returns {string}
 */
// todo: use type definitions from Michael Reducers ;-)
export const mapQueryParamsToQueryString = (params: queryParams): string => {
  const { searchString = '', facetQuery, page = defaultPage, sort = defaultSort } = params;
  let query = decodeURIComponent(searchString);
  if (sort) query = `${query}:${decodeURIComponent(sort)}`;
  if (facetQuery) query = `${query}${decodeURIComponent(facetQuery).replace(/[+]/g, '%2B')}`;
  const queryObject = {
    query: query,
    currentPage: page,
    pageSize: defaultPageSize,
  };

  return queryString.stringify(queryObject, { encode: true });
};

export const computeQueryString = (filter, facetCode, currentQueryString) => {
  const query = filter.map(({ code }) => `%3A${facetCode}%3A${code}`).join('');
  const regExp = getFacetRegExp(facetCode);
  const oldQuery = currentQueryString.split('query=')[1];
  const facetQuery = oldQuery.replaceAll(regExp, '') + query;
  return mergeQueryString(currentQueryString, { facetQuery });
};

export const mergeQueryString = (currentQueryString: string, changeQueryObject: queryParams = {}): string => {
  const { searchString, sort, facetQuery, page } = changeQueryObject;

  if ([searchString, sort, facetQuery, page, currentQueryString].every((el) => !el)) {
    return '';
  }

  const currentQueryObject = mapQueryStringToQueryParams(currentQueryString);
  const mergeQueryObject = merge({ ...currentQueryObject }, changeQueryObject);
  const oldFacetQuery = currentQueryObject.facetQuery;
  let newfacetQuery = oldFacetQuery;

  // replace searchString in facetQuery
  if (searchString) {
    const tempFacetQuery = oldFacetQuery ? oldFacetQuery.slice(oldFacetQuery.indexOf('%3A')) : '';
    newfacetQuery = `${searchString}${tempFacetQuery}`;
  }

  // replace sort in facetQuery
  if (sort) {
    const oldSort = currentQueryObject.sort || '';
    newfacetQuery = oldFacetQuery ? oldFacetQuery.replace(oldSort, sort) : '';
  }

  // reset page value to one if a new facetQuery was selected
  if (facetQuery) {
    newfacetQuery = facetQuery;
    mergeQueryObject.page = 1;
  }

  const newQueryObject = {
    currentPage: mergeQueryObject.page,
    query: decodeURIComponent(newfacetQuery || ''),
    pageSize: defaultPageSize,
  };

  return queryString.stringify(newQueryObject, { encode: true });
};

/**
 * Removes all facets from the query string and returns it. If there are
 * no facets selected, an empty string is returned. This is useful to hide
 * the reset button on the page.
 * @param currentQuery
 * @returns {string}
 */
export const calculateResetQuery = (currentQuery: string): string => {
  let { query = '' } = queryString.parse(currentQuery);
  query = encodeURIComponent(query);

  // get static query part (searchTerm, sorting)
  const i = query.indexOf('%3A', query.indexOf('%3A') + 1);
  let resetQuery = query.substring(0, i);

  let parts = query.substring(i).split('%3A');
  // sample parts array ['', 'categoryPath', '%2F1%2F500%2F5100%2F511000', 'color26', 'Schwarz']
  const lastCatIdx = parts.lastIndexOf('categoryPath');

  // check if a category facet is selected
  if (lastCatIdx > -1) {
    // create reset query for category pages
    resetQuery += `%3A${parts[lastCatIdx]}%3A${parts[lastCatIdx + 1]}`;
  }

  return resetQuery;
};

/**
 * remove colon in queryString
 */
export const sanitizeQueryString = (dirtyString: string = '') => dirtyString.replace(/[:]|%3A/gi, '');

/**
 * Replace every special char, except "-", with "-" and remove "-" at the end of the string
 *
 * @param dirtyString
 */
export const sanitizeUrlString = (dirtyString: string = '') =>
  dirtyString
    ? dirtyString
        .toLowerCase()
        .replace(/[^-\wäüöèéàâôê]+/g, '-')
        .replace(/-[-]+/g, '-')
        .replace(/-+$/g, '')
    : 'no string';

export const normalizeSpecialCharacters = (input, locale) => {
  const replacements = {
    ä: 'ae',
    ö: 'oe',
    ü: 'ue',
    é: 'e',
    è: 'e',
    ê: 'e',
    ë: 'e',
    à: 'a',
    â: 'a',
    ù: 'u',
    û: 'u',
    ô: 'o',
    ç: 'c',
    î: 'i',
    ï: 'i',
    ÿ: 'y',
    œ: 'oe',
    æ: 'ae',
    ì: 'i',
    ò: 'o',
  };

  if (locale === 'fr') {
    // some special cases for french
    replacements['ä'] = 'a';
    replacements['ü'] = 'u';
  }

  return input.toLocaleLowerCase(locale).replace(/[\u00C0-\u00FF\u0153]/g, function (match) {
    return replacements[match] || match;
  });
};

export const generateSEOUrl = (dirtyString: string = '', locale) => {
  return dirtyString
    ? normalizeSpecialCharacters(dirtyString, locale)
        .replace(/[\W_]+/g, '-')
        .replace(/(^-+|-+$)/g, '')
    : 'no string';
};
/**
 * get the localized pathFragment from a string, an array or an object
 *
 * @param locale
 * @param pathElement
 * @returns string
 */
export const getLocalizedPathElement = (locale: string, pathElement: any): string => {
  let string = '';
  if (typeof pathElement === 'object') {
    if (Array.isArray(pathElement)) {
      string = pathElement?.[0] || '';
    } else {
      if (Object.prototype.hasOwnProperty.call(pathElement, locale)) {
        string = pathElement[locale];
      } else if (Object.values(pathElement).length > 0) {
        string = Object.values(pathElement)[0];
      }
    }
  } else if (typeof pathElement === 'string') {
    // remove the url locale as it is already appended by `mapPathToLocalizedUrl`
    string = pathElement.replace(`/${locale}/`, '');
  }
  return string;
};

/**
 * returns a localized url based on given pathElements
 *
 * @param locale
 * @param pathElements
 * @returns {string}
 */
export const mapPathToLocalizedUrl = (locale: string = getLocale(), pathElements: Array<any> = []): string => {
  let path = `/${locale}`;
  if (pathElements && Array.isArray(pathElements) && pathElements.length > 0) {
    const localizedPathElements = pathElements
      .filter(Boolean)
      .map((element) => getLocalizedPathElement(locale, element));
    path = `${path}/${localizedPathElements.join('/')}`;
  }
  return path;
};

/**
 * @param params
 * @param locale
 * @returns {string}
 */
export const mapQueryParamsToUrl = (
  params: queryParams,
  locale: string,
  categories: Object,
  isCmsPage: Boolean
): string => {
  const mapUrlParamsToString = (_params: urlParamsType): string => {
    // _params: {search: "search=apple", facets: "brand=Apple", sortQuery: "sort=price-desc", pageQuery: ""}
    let nonEmptyParams = Object.values(_params).filter(Boolean);
    // nonEmptyParams: ["search=apple", "brand=Apple", "sort=price-desc"]

    const searchTerm = nonEmptyParams.find((value) => !value.indexOf('search=') > 0);

    // exclude searchTerm form url sorting
    nonEmptyParams = nonEmptyParams.filter((value) => value.indexOf('search=') < 0);
    // sort params
    nonEmptyParams.sort();
    // sort the values
    nonEmptyParams = nonEmptyParams.map((param) =>
      // replace the trailing value part with its sorted equivalent
      param.replace(/[^=]*$/, (value) => value && value.split('+').sort().join('+'))
    );

    // ensure that the searchTerm is always on first position
    if (searchTerm) {
      nonEmptyParams.unshift(searchTerm);
    }

    // nonEmptyParams: ["brand=Apple", "search=apple", "sort=price-desc"]
    return nonEmptyParams.join('&');
  };

  const categoryCode = mapQueryParamsToCategoryCode(params);
  const urlParams = mapQueryParamsToUrlParams(params, isCmsPage);
  const urlParamsString = mapUrlParamsToString(urlParams) ? `?${mapUrlParamsToString(urlParams)}` : '';

  if (categoryCode && !isCmsPage) {
    const categoryPath = getCategoryUrl(categoryCode, locale, categories);
    if (urlParams.search) {
      return `${categoryPath}/${getLocalizedPathElement(locale, CATEGORY_SEARCH)}${urlParamsString}`;
    }
    return `${categoryPath}${urlParamsString}`;
  }

  if (urlParams.search) {
    if (urlParamsString.includes(':')) return '';
    return `${mapPathToLocalizedUrl(locale, [PRODUCT_SEARCH])}${urlParamsString}`;
  }

  return urlParamsString;
};

/**
 *
 * this function maps keys and values from a chunked array to Objects:
 *
 * ['key', 'value'] => { key: 'value' }
 *
 *
 * If a key is already defined in the new Object, it adds the value to the existing:
 *
 * [ ['key1', 'value1'], ['key1', 'value2'] ] =>
 * {
 *   key1: 'value1:key1:value2',
 * }
 *
 * @param keyValuePairArray
 * @returns {{}}
 */
export const mapQueryArrayToObject = (keyValuePairArray: Array<any> = []) => {
  const pairedObject = {};

  // map over array with Key-Value-Arrays
  // default value has only one value to return the empty pairedObject
  keyValuePairArray.forEach((keyValuePair = ['']) => {
    let newValue = '';

    if (keyValuePair.length !== 2) {
      return pairedObject;
    }

    // if the incoming key is already defined in the 'pairedObject'
    // add the incoming value to the existing value
    if (has(pairedObject, keyValuePair[0])) {
      const existingObject = pairedObject?.[keyValuePair[0]];
      newValue = `${existingObject}:${keyValuePair[0]}:${keyValuePair[1]}`;
    } else {
      newValue = keyValuePair[1];
    }

    // add the new Object to the paired Object. and return it after the mapper is through
    Object.assign(pairedObject, { [keyValuePair[0]]: newValue });
    return pairedObject;
  });
  return pairedObject;
};

/**
 * this function adds the max and min range of the given facet type to the queryString and returns it
 *
 * @param currentQuery
 * @param min
 * @param max
 * @param facetCode
 * @return string
 */

export const createFacetRangeQuery = (currentQuery: string = '', min: number, max: number, facetCode: string) => {
  // parse the URL to get an object
  const parsedQuery = queryString.parse(currentQuery);

  // get the query param from the object
  const queryParam = parsedQuery?.query;

  let indexOfQueryParamsWithoutSearch = queryParam.indexOf(':', queryParam.indexOf(':') + 1);

  // set indexOfQueryParamsWithoutSearch to length of queryParam if only a search term is set
  if (indexOfQueryParamsWithoutSearch <= 0) {
    indexOfQueryParamsWithoutSearch = queryParam.length;
  }
  const searchTerm = queryParam.substr(0, indexOfQueryParamsWithoutSearch);
  const queryParamsWithoutSearch = queryParam.substr(indexOfQueryParamsWithoutSearch + 1);

  // split string to array and set the first element to 'sort'
  const paramArray = queryParamsWithoutSearch.split(':');

  // form pairs
  const chunkedArray = chunk(paramArray, 2);

  // create an object with key and value from the paired array
  const facetObject = mapQueryArrayToObject(chunkedArray);

  // set new values
  Object.assign(facetObject, {
    [`${facetCode}_min`]: min,
    [`${facetCode}_max`]: max,
  });

  // stringify the facet query object
  const stringified = queryString.stringify(facetObject, { encode: true });

  const replacedString = decodeURIComponent(stringified.replace(/[=&]/g, ':'));

  // merge together with the whole query object
  const mergedQuery = {
    ...parsedQuery,
    currentPage: 1,
    query: encodeURIComponent(`${searchTerm}:${replacedString}`),
    // query: encodeURIComponent(replacedString),
  };

  // stringify to return the URL
  return queryString.stringify(mergedQuery, { encode: false });
};

export const removeUnusedParams = (searchString = '') => {
  if (!searchString) return '';
  let newSearchString = '';

  const splittedSearch = searchString.split('?');

  const parsedURL = queryString.parse(splittedSearch.length > 0 ? prepareUrlToParse(splittedSearch[1]) : '');

  const blackList = /^(utm_|gclid|dclid|fbclid|gclsrc|msclkid|WT.mc_id|WT.srch)/;

  Object.keys(parsedURL).forEach((key) => {
    if (!key.match(blackList)) {
      newSearchString += `${key}=${parsedURL[key]}&`;
    }
  });

  return newSearchString.slice(0, -1);
};
