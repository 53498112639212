import React from 'react';
import { Route } from 'react-router';
import { WATCHLIST } from 'constants/routePaths/routePaths';

import DefaultLayout from 'components/layouts/DefaultLayout/DefaultLayout';
import Watchlist from 'routes/Watchlist/Watchlist';

export default function createWatchlistRoutes() {
  return (
    <Route>
      <Route component={DefaultLayout}>
        <Route path={WATCHLIST} component={Watchlist} />
      </Route>
    </Route>
  );
}
