import React, { useContext } from 'react';
import { FeatureToggleContext } from 'contexts/FeatureTogglingProvider/FeatureTogglingProvider';
import { getFeatureToggleConfig } from 'hooks/useFeatureToggle';
import { Link } from 'react-router';
import PropTypes from 'prop-types';

export const NextFeatureLink = (props) => {
  const { children, feature, to, ...rest } = props;

  const { featureTogglingConfig } = useContext(FeatureToggleContext);

  const isNextFeatureEnabled = getFeatureToggleConfig(featureTogglingConfig, feature) === 'true' ?? false;

  return isNextFeatureEnabled ? (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <a {...rest} href={to}>
      {children}
    </a>
  ) : (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Link {...rest} to={to}>
      {children}
    </Link>
  );
};

NextFeatureLink.displayName = 'atoms/NextFeatureLink';

NextFeatureLink.propTypes = {
  children: PropTypes.node.isRequired,
  feature: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};
