const formatTime = (value) => {
  if (!value) return null;
  const days = Math.floor(value / (1000 * 60 * 60 * 24));
  const hours = Math.floor((value % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const min = Math.floor((value % (1000 * 60 * 60)) / (1000 * 60));
  const sec = Math.floor((value % (1000 * 60)) / 1000);
  if ([days, hours, min, sec].some((val) => val < 0)) {
    return '00:00:00';
  }
  return `${days > 0 ? `${`0${days}`.slice(-2)}d : ` : ''}${`0${hours}`.slice(-2)}h : ${`0${min}`.slice(-2)}m${
    days === 0 ? ` : ${`0${sec}`.slice(-2)}s` : ''
  }`; // eslint-disable-line
};

export default formatTime;
