import React from 'react';
import PropTypes from 'prop-types';

import { getLocale, i18n } from 'constants/language/language';
import { Row, Col } from 'components/atoms/Grid/Grid';
import Headline from 'components/atoms/Headline/Headline';
import styles from './ReturnOverview.scss';
import { HELPCENTER_DOMAIN } from 'constants/links/links';

const ReturnOverview = ({ reasons = [], onReasonClick = () => {} }) => {
  const getLocaleReturnsLink = () => {
    if (getLocale().toLowerCase().includes('de'))
      return (
        HELPCENTER_DOMAIN +
        '/s/article/R%C3%BCcknahmebedingungen-ID?c__cun=Cancel_Return&c__cl=Stornieren%20%26%20Zur%C3%BCckgeben&c__cc=Returns&c__at=Informative&language=de'
      );
    if (getLocale().toLowerCase().includes('fr'))
      return (
        HELPCENTER_DOMAIN +
        '/s/article/Conditions-de-reprise-ID?c__cun=Cancel_Return&c__cl=Annuler%20%26%20retourner&c__cc=Returns&c__at=Informative&language=fr'
      );
    if (getLocale().toLowerCase().includes('it'))
      return (
        HELPCENTER_DOMAIN +
        '/s/article/Condizioni-di-ripresa-ID?c__cun=Cancel_Return&c__cl=Annullamento%20e%20restituzione&c__cc=Returns&c__at=Informative&language=it'
      );
  };

  return (
    <>
      <Headline tag={Headline.tags.H2} margin={Headline.margins.MB_15}>
        {i18n('returnsOverview.overviewPreLink')}
        <a href={getLocaleReturnsLink()} target="_blank" rel="noopener noreferrer">
          {i18n('returnsOverview.overviewLink')}
        </a>
        {i18n('returnsOverview.overviewAfterLink')}
      </Headline>
      <Row>
        {reasons.map((reason) => (
          <Col key={reason.id} md={6}>
            <button type="button" id={reason.domId} className={styles.entry} onClick={() => onReasonClick(reason)}>
              <div className={styles.header}>
                <Headline tag={Headline.tags.H3}>{i18n(`returnsOverview.${reason.id}.title`)}</Headline>
              </div>
              <p className={styles.description}>{i18n(`returnsOverview.${reason.id}.description`)}</p>
            </button>
          </Col>
        ))}
      </Row>
    </>
  );
};

ReturnOverview.displayName = 'molecules/ReturnOverview';

ReturnOverview.propTypes = {
  reasons: PropTypes.array,
  onReasonClick: PropTypes.func,
};

export default ReturnOverview;
