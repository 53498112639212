// @flow
import React from 'react';
import { connect } from 'react-redux';

import language from 'constants/language/language';
import { RATING_TYPES, MODAL_TYPES } from 'constants/ratings/ratings';
import RatingProductSummary from 'components/molecules/Rating/RatingProductSummary/RatingProductSummary';
import RatingModal from 'components/organisms/Rating/RatingModal/RatingModal';
import ConfirmationModal from 'components/molecules/ConfirmationModal/ConfirmationModal';
import ModalApiError from 'components/organisms/ModalApiError/ModalApiError';

import {
  loadCustomerRatingsSummary,
  loadCustomerRatings,
  createRating,
  updateRating,
  deleteRating,
  resetRatingError,
  ratingModalAction,
} from 'actions/ratingActions/ratingActions';

import type { IRating, IRatingState, ICreateRatings, ILoadRatings, IUpdateRatings, IModalRating } from 'flow/ratings';

type IProps = {
  selectedRating?: ?IRating,
  listType: string,
  modalType: string,
  xhrActive: boolean,
  xhrError: boolean,
  ratingModalAction: (?IModalRating) => void,
  createRating: (ICreateRatings) => Promise<any>,
  updateRating: (IUpdateRatings) => Promise<any>,
  deleteRating: (string) => Promise<any>,
  resetRatingError: () => void,
  loadCustomerRatingsSummary: () => Promise<any>,
  loadCustomerRatings: (ILoadRatings) => Promise<any>,
};

const mapStateToProps = (state: { ratings: IRatingState }): Object => ({
  selectedRating: state.ratings.selectedRating,
  modalType: state.ratings.modalType,
  listType: state.ratings.listType,
  xhrActive: state.ratings.xhrActive,
  xhrError: state.ratings.xhrError,
});

const mapDispatchToProps = {
  loadCustomerRatingsSummary,
  loadCustomerRatings,
  createRating,
  updateRating,
  deleteRating,
  resetRatingError,
  ratingModalAction,
};

const AccountRatingsModalContainer = (props: IProps) => {
  const onCloseModal = () => {
    props.ratingModalAction();
  };

  const onSubmitRating = ({ rating, recommends, headline, comment }) => {
    if (!props.selectedRating) {
      return Promise.reject();
    }

    const payload = {
      rating,
      recommends,
      // send headline only, if changed by user
      headline: props.selectedRating.headline !== headline ? headline : undefined,
      // send comment only, if changed by user
      comment: props.selectedRating.comment !== comment ? comment : undefined,
    };

    const { productCode, rated } = props.selectedRating;
    if (rated) {
      // update existing rating...
      return props.updateRating({ ...payload }, productCode).then(loadEntries);
    }

    return props.createRating({ ...payload }, productCode).then(loadEntries);
  };

  const onDeleteRating = () => {
    if (props.selectedRating) {
      props.deleteRating(props.selectedRating.productCode).then(loadEntries).then(onCloseModal);
    }
  };

  const loadEntries = (): Promise<any> => {
    if (props.listType === RATING_TYPES.ALL) {
      return props.loadCustomerRatingsSummary();
    }

    return props.loadCustomerRatings({ type: props.listType });
  };

  if (props.selectedRating && props.modalType === MODAL_TYPES.EDIT) {
    return (
      <RatingModal
        rated={props.selectedRating.rated}
        rating={props.selectedRating}
        onSubmitRating={onSubmitRating}
        onCancelRating={onCloseModal}
        buttonsDisabled={props.xhrActive}
      />
    );
  }

  if (props.selectedRating && props.modalType === MODAL_TYPES.DELETE) {
    return (
      <ConfirmationModal
        isOpen
        title={language('myAccountRatings.delete.title')}
        okLabel={language('myAccountRatings.delete.okLabel')}
        cancelLabel={language('myAccountRatings.delete.cancelLabel')}
        onOk={onDeleteRating}
        onCancel={onCloseModal}
        buttonsDisabled={props.xhrActive}
      >
        <RatingProductSummary {...props.selectedRating} />
      </ConfirmationModal>
    );
  }

  if (props.xhrError) {
    return <ModalApiError isModalOpen requestCloseModal={props.resetRatingError} />;
  }

  return null;
};

AccountRatingsModalContainer.displayName = 'containers/AccountRatingsModalContainer';

export default connect(mapStateToProps, mapDispatchToProps)(AccountRatingsModalContainer);
