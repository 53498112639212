import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { noop } from 'constants/noop/noop';

import SearchSuggestions from 'components/molecules/SearchSuggestions/SearchSuggestions';
import { TERM_SUGGESTION_SEARCH } from 'constants/trackingAttributes/trackingAttributes';

import styles from './SearchSuggestionList.scss';
import { provideSearchLink } from 'constants/navigation/navigation';
import useFeatureToggle from 'hooks/useFeatureToggle';
import uuid from 'uuid/v5';
import getCookieStorage from 'constants/storage/cookie';
import { connect } from 'react-redux';
import { recoTrackProductSearch } from 'api/RecoAPI/RecoAPI';
import { RECO_COOKIE } from 'constants/storage/storageKeys';

const cookie = getCookieStorage();

const mapStateToProps = (state) => ({
  email: state?.user?.fields?.email?.value || undefined,
  onlineId: state?.user?.onlineId || undefined,
});

const SearchSuggestionList = ({
  autocomplete = [],
  searchTerm = '',
  activeIndex = -1,
  closeSearch = noop,
  trackSearch = noop,
  addHistoryEntry = () => {},
  email = undefined,
  onlineId = undefined,
}) => {
  const nextSearchEnabled = useFeatureToggle('nextSearchEnabled') === 'true' ?? false;

  if (autocomplete.length === 0) return null;

  const onClick = (autocompleteItem) => {
    const { url } = provideSearchLink(autocompleteItem);

    let recoID = '';
    if (email) recoID = uuid(email, uuid.DNS);
    if (onlineId) recoID = onlineId;

    trackSearch(searchTerm, TERM_SUGGESTION_SEARCH);
    recoTrackProductSearch(searchTerm, cookie.getItem(RECO_COOKIE), recoID);

    addHistoryEntry(autocompleteItem, url);
    closeSearch();
    if (nextSearchEnabled) {
      window.location.href = url;
    }
  };

  return (
    <ul className={cx(styles.searchSuggestionList)}>
      {autocomplete.map((autocompleteItem, i) => {
        return (
          <li key={autocompleteItem}>
            <SearchSuggestions
              title={autocompleteItem}
              searchTerm={searchTerm}
              isActive={i === activeIndex}
              onClick={() => onClick(autocompleteItem)}
            />
          </li>
        );
      })}
    </ul>
  );
};

SearchSuggestionList.displayName = 'molecules/SearchSuggestionList';
SearchSuggestionList.propTypes = {
  autocomplete: PropTypes.arrayOf(PropTypes.string).isRequired,
  searchTerm: PropTypes.string.isRequired,
  activeIndex: PropTypes.number,
  closeSearch: PropTypes.func,
  trackSearch: PropTypes.func,
  addHistoryEntry: PropTypes.func,
  email: PropTypes.string,
  onlineId: PropTypes.string,
};

export default connect(mapStateToProps)(SearchSuggestionList);
