// @flow
import { handleActions } from 'redux-actions';

import {
  LOAD_RATING_SUMMARY_SUCCESS,
  LOAD_RATINGS_REQUEST,
  LOAD_RATINGS_SUCCESS,
  LOAD_RATINGS_FAILURE,
  CREATE_RATING_REQUEST,
  CREATE_RATING_SUCCESS,
  CREATE_RATING_FAILURE,
  UPDATE_RATING_REQUEST,
  UPDATE_RATING_SUCCESS,
  UPDATE_RATING_FAILURE,
  DELETE_RATING_REQUEST,
  DELETE_RATING_SUCCESS,
  DELETE_RATING_FAILURE,
  LOAD_RATING_REQUEST,
  LOAD_RATING_SUCCESS,
  LOAD_RATING_FAILURE,
  RESET_RATING_FAILURE,
  PLACE_ORDER_SUCCESS,
  LOAD_PRODUCT_SUCCESS,
  LOAD_PRODUCT_RATINGS_SUCCESS,
  TOGGLE_RATING_MODAL,
} from 'constants/ActionTypes/ActionTypes';

import type {
  IRating,
  IRatingStateType,
  IRatingState,
  ILoadAction,
  ILoadSummaryAction,
  ILoadProductRatings,
} from 'flow/ratings';

/**
 * Make sure that each rating list entry has all needed fields initialized
 */
const hydrateEntry = (rating: IRating): IRating => ({
  // use productCode as fallback guid when guid is not set in API
  productCode: '',
  productName: '',
  productImage: '',
  rating: 0,
  headline: '',
  comment: '',
  status: '',
  alias: '',
  ...rating,
});

/**
 * Gives the general state structure for the rated / unrated store fields
 */
const hydrateRatingsStore = ({ reviews, meta }, payload = { page: 0, lastFetched: null }): IRatingStateType => ({
  entries: reviews.map((entry: IRating) => hydrateEntry(entry)),
  total: meta.total,
  pages: meta.pages,
  currentPage: payload.page,
  lastFetched: payload.lastFetched,
});

/**
 * Define the inital state object
 */
export const initialState: IRatingState = {
  rated: hydrateRatingsStore({ reviews: [], meta: { total: 0, pages: 0 } }),
  unrated: hydrateRatingsStore({ reviews: [], meta: { total: 0, pages: 0 } }),
  rejected: hydrateRatingsStore({ reviews: [], meta: { total: 0, pages: 0 } }),
  reviews: [],
  numberOfComments: 0,
  commentsQuery: {
    order: 'DATE_DESC',
    page: 1,
    pageSize: 3,
  },
  selectedRating: null,
  listType: '',
  modalType: '',
  xhrActive: false,
  xhrError: false,
};

const initialProductState = {
  averageRating: 0,
  numberOfReviews: 0,
  averageRecommendation: 0,
  reviewDistribution: {
    /* eslint-disable no-useless-computed-key */
    ['1']: 0,
    ['2']: 0,
    ['3']: 0,
    ['4']: 0,
    ['5']: 0,
    /* eslint-enable no-useless-computed-key */
  },
  numberOfComments: 0,
  reviews: [],
};

const handleReqStart = (state: IRatingState): IRatingState => ({
  ...state,
  xhrActive: true,
  xhrError: false,
});

const handleReqSuccess = (state: IRatingState): IRatingState => ({
  ...state,
  xhrActive: false,
});

const handleReqFailure = (state: IRatingState): IRatingState => ({
  ...state,
  xhrActive: false,
  xhrError: true,
});

// invalidate lastFetched to force loading of fresh entries on next requests
const invalidateCache = ({ state }: { state: IRatingState }) => {
  const newState = handleReqSuccess(state);
  return {
    ...newState,
    rated: {
      ...newState.rated,
      lastFetched: null,
    },
    unrated: {
      ...newState.unrated,
      lastFetched: null,
    },
    rejected: {
      ...newState.rejected,
      lastFetched: null,
    },
  };
};

export default handleActions(
  {
    //
    // REQUEST ACTIONS
    //

    [LOAD_RATINGS_REQUEST]: (state: IRatingState) => handleReqStart(state),
    [LOAD_RATING_REQUEST]: (state: IRatingState) => handleReqStart(state),
    [CREATE_RATING_REQUEST]: (state: IRatingState) => handleReqStart(state),
    [DELETE_RATING_REQUEST]: (state: IRatingState) => handleReqStart(state),
    [UPDATE_RATING_REQUEST]: (state: IRatingState) => handleReqStart(state),

    //
    // SUCCESS ACTIONS
    //
    [LOAD_RATING_SUMMARY_SUCCESS]: (state: IRatingState, action: ILoadSummaryAction) => ({
      ...handleReqSuccess(state),
      rated: {
        entries: (action.req?.data?.reviews?.reviews || []).map((entry) => hydrateEntry(entry)),
        total: action.req?.data?.reviews?.meta?.total || 0,
        pages: 1,
        lastFetched: action.payload.lastFetched,
      },
      unrated: {
        entries: (action.req?.data?.reviewableReviews?.reviews || []).map((entry) => hydrateEntry(entry)),
        total: action.req?.data?.reviewableReviews?.meta?.total || 0,
        pages: 1,
        lastFetched: action.payload.lastFetched,
      },
      rejected: {
        entries: (action.req?.data?.rejectedReviews?.reviews || []).map((entry) => hydrateEntry(entry)),
        total: action.req?.data?.rejectedReviews?.meta?.total || 0,
        pages: 1,
        lastFetched: action.payload.lastFetched,
      },
    }),
    [LOAD_RATINGS_SUCCESS]: (state: IRatingState, action: ILoadAction) => ({
      ...handleReqSuccess(state),
      [action.payload.type]: hydrateRatingsStore(action.req.data.reviews, action.payload),
    }),
    [LOAD_RATING_SUCCESS]: (state: IRatingState, action: { req: { data: IRating } }) => {
      const newState = handleReqSuccess(state);
      const rating = action.req.data;
      const userHasRated = rating && rating?.rating;
      newState.selectedRating = userHasRated ? hydrateEntry(rating) : null;
      return newState;
    },
    [CREATE_RATING_SUCCESS]: (state: IRatingState) => invalidateCache({ state }),
    [DELETE_RATING_SUCCESS]: (state: IRatingState) => invalidateCache({ state }),
    [PLACE_ORDER_SUCCESS]: (state: IRatingState) => invalidateCache({ state }),
    [UPDATE_RATING_SUCCESS]: (state: IRatingState) => invalidateCache({ state }),

    /**
     * Store the initial ratings (=comments) from the product api call
     */
    [LOAD_PRODUCT_SUCCESS]: (state: IRatingState, action: Object) => {
      const { reviews, numberOfComments } = action.req.data || {};

      return {
        ...initialProductState,
        ...state,
        // reset query params each time a new product is loaded
        commentsQuery: {
          ...initialState.commentsQuery,
        },
        numberOfComments: numberOfComments || 0,
        reviews: reviews || [],
      };
    },

    /**
     * Store the ratings on a product detail page when the user has changed the sort order or has
     * loaded additional (= "show more" button) ratings
     */
    [LOAD_PRODUCT_RATINGS_SUCCESS]: (
      state: IRatingState,
      action: { req: Object, params: ILoadProductRatings }
    ): IRatingState => {
      let reviews: Array<IRating> = action.req.data.reviews;

      if (action.params.page > 1) {
        // when the requested page was greater 1 we concat the received comments cause the user has
        // clicked the "show more" button
        reviews = state.reviews.concat(reviews);
      }

      return {
        ...state,
        reviews,
        commentsQuery: {
          ...action.params,
        },
      };
    },

    //
    // FAILURE ACTIONS
    //

    [LOAD_RATINGS_FAILURE]: (state: IRatingState) => handleReqFailure(state),
    [LOAD_RATING_FAILURE]: (state: IRatingState) => handleReqFailure(state),
    [CREATE_RATING_FAILURE]: (state: IRatingState) => handleReqFailure(state),
    [UPDATE_RATING_FAILURE]: (state: IRatingState) => handleReqFailure(state),
    [DELETE_RATING_FAILURE]: (state: IRatingState) => handleReqFailure(state),

    //
    // OTHER ACTIONS
    //

    [TOGGLE_RATING_MODAL]: (state: IRatingState, action) => ({
      ...state,
      selectedRating: action.payload.rating,
      modalType: action.payload.modalType,
      listType: action.payload.listType,
    }),

    // Reset request failure (= closes error info modal)
    [RESET_RATING_FAILURE]: (state: IRatingState) => ({
      ...state,
      xhrError: false,
    }),
  },
  initialState
);
