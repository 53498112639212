// @flow
import has from 'constants/helper/has';

import { sanitizeUrlString, mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import { BRAND, CMS } from 'constants/routePaths/routePaths';
import { getTranslation, getLocale } from 'constants/language/language';

import type { Page, Pages, ProcessedUrls } from 'reducers/cms/cms';
import type { Locale } from 'flow/localization';

import { getSearchUrl } from 'constants/urlHelper/urlHelper';
import { replaceUmlauts } from 'constants/helper/replace-umlaut';

// Link caches because of low performance on the two methods provideLink() and provideUrls()
let calculatedUrlCache: { [string]: any };
let linkCache = new Map();

export const resetNavigationLinkCache = () => {
  calculatedUrlCache = undefined;
  linkCache = new Map();
};

/**
 * Creates the url string for the cms navigation
 * @param locale
 * @param parents
 * @param isBrand
 * @param child
 * @returns {string}
 */
const makeUrlString = (locale, parents, isBrand, child) => {
  // @TODO this won't work with Brand-Pages :(
  const base = parents.map((elem) => sanitizeUrlString(elem)).join('/');
  const sanitizedChildUrl = child ? `/${sanitizeUrlString(child)}` : '';
  const childUrl = child ? `/${child}` : '';
  const prefix = isBrand ? `${BRAND}` : `${CMS}`;

  // todo: remove 'toLowerCase()' when K. Willis sends all urls in lowercase
  let url = `/${locale}/${prefix}/${base}${isBrand ? childUrl.toLowerCase() : sanitizedChildUrl}`;
  if (isBrand) {
    url = url.replace('brands/', '');
  }

  return url;
};

/**
 * Provides a link for the brand-pages from a given uid
 * @param pages
 * @param uid
 * @param locale
 * @returns {{url: string, text: boolean}}
 */
export const provideBrandLink = (pages: Pages, uid: string, locale: Locale) => {
  const brandPages = pages?.brands?.children || [];
  const brandPage = brandPages.find((page) => page.uid === uid);

  return {
    url: `${mapPathToLocalizedUrl(locale, [BRAND])}/${encodeURIComponent(uid)}`,
    text: brandPage ? brandPage?.pageTitle?.[locale] || uid : false,
  };
};

/**
 * Exclude brands root page explicitly (SPQR-4558)
 * @param brandPageUIds
 * @param rejectedUids
 * @returns {Array}
 */
export const rejectBrandPageUids = (brandPageUIds: Array<string>, rejectedUids: Array<string> = ['brands']) =>
  [brandPageUIds, rejectedUids].reduce((a, b) => a.filter((c) => !b.includes(c)));

/**
 * Generates all the urls, which will be saved into the navigation-state
 * @param navigationItems
 * @returns {{}}
 */

export const provideUrls = (navigationItems: Pages): ProcessedUrls => {
  if (calculatedUrlCache && Object.keys(calculatedUrlCache).length > 0) {
    return calculatedUrlCache;
  }

  let brandPagesUids = [];
  calculatedUrlCache = {};

  const isBrandPage = (uid) => brandPagesUids.includes(uid);

  const getBrandPagesUids = (brand) => {
    if (brand.uid) {
      brandPagesUids.push(brand.uid);
    }

    if (brand.children?.length) {
      brand.children.forEach((child) => {
        getBrandPagesUids(child);
      });
    }
  };

  const brands = navigationItems?.brands || {};
  if (Object.keys(brands)?.length) {
    getBrandPagesUids(brands);
  }

  brandPagesUids = rejectBrandPageUids(brandPagesUids);

  const generateUrlData = (navigationItem, locale, parentUid) => ({
    pageId: navigationItem?.pageId,
    locale,
    anchor: parentUid,
    options: {
      showInTopics: navigationItem?.options?.showInTopics ?? false,
      showBreadcrumbs: navigationItem?.options?.showBreadcrumbs ?? false,
      showNavigation: navigationItem?.options?.showNavigation ?? false,
      showNewsletterWidget: navigationItem?.options?.showNewsletterWidget ?? false,
    },
  });

  const handleChildren = (current: Page, locale: Locale, parent: Array<string>, parentUid: string) => {
    if (current.text[locale]) {
      const isBrand = isBrandPage(current.uid);
      const urlString = replaceUmlauts(makeUrlString(locale, parent, isBrand, current.text[locale]));
      calculatedUrlCache[urlString] = generateUrlData(current, locale, parentUid);
    }

    if (current.children?.length) {
      current.children.forEach((child) => {
        handleChildren(child, locale, parent.concat(current.text[locale]), parentUid);
      });
    }
  };

  Object.values(navigationItems || {}).forEach((navigationItem: Page) =>
    Object.keys(navigationItem.text || {}).forEach((locale) => {
      const localizedText = navigationItem.text[locale];
      if (localizedText) {
        const isBrand = isBrandPage(navigationItem.uid);
        const urlString = replaceUmlauts(makeUrlString(locale, [localizedText], isBrand));
        calculatedUrlCache[urlString] = generateUrlData(navigationItem, locale, navigationItem.uid);

        if (navigationItem.children?.length) {
          navigationItem.children.forEach((child) => {
            handleChildren(child, locale, [localizedText], navigationItem.uid);
          });
        }
      }
    })
  );

  return calculatedUrlCache;
};

/**
 * Provides the correct URL and text prop from a given PageId
 * @param pages
 * @param pageId
 * @param locale
 * @returns {*}
 */
export const provideLink = (pages = {}, pageId, locale) => {
  const cacheID = pageId + locale;
  const cached = linkCache.get(cacheID);
  if (cached) {
    return cached;
  }
  // saves the correct link for the given PageId and locale
  const urls = provideUrls(pages) || {};
  const url = Object.keys(urls).find((key) => urls[key].pageId === pageId && urls[key].locale === locale);

  let text = '';
  let openNewTab = false;
  const handleChildren = (parent: Page) => {
    if (parent.pageId === pageId) {
      text = parent?.text?.[locale] || '';
      openNewTab = parent?.openInNewWindow ?? false;
    } else if (typeof parent.children !== 'undefined' && parent.children.length > 0) {
      parent.children.forEach(handleChildren);
    }
  };

  if (typeof url !== 'undefined') {
    Object.values(pages).forEach(handleChildren);
    return { url, text, openNewTab };
  }

  return {};
};

/**
 * Generates the breadcrumb for cms pages originating from a given pageId
 * @param pages
 * @param pageId
 * @param locale
 * @returns {Array}
 */
export const provideBreadcrumb = (pages: Pages, pageId: string, locale: Locale): Array<{}> => {
  if (!__CLIENT__) return []; //HOTFIX for SSR Performance improvement
  const breadCrumb = [];
  let found = false;

  const handleChildren = (parent: Page) => {
    if (!found) {
      if (parent.pageId === pageId) {
        const url = provideLink(pages, parent.pageId, locale);

        breadCrumb.push({
          href: url.url,
          name: url.text,
        });

        found = true;
      } else if (typeof parent.children !== 'undefined' && parent.children.length > 0) {
        const url = provideLink(pages, parent.pageId, locale);

        breadCrumb.push({
          href: url.url,
          name: url.text,
        });

        parent.children.forEach(handleChildren);

        if (!found) {
          breadCrumb.pop();
        }
      }
    }
  };

  Object.values(pages).forEach(handleChildren);

  return found ? breadCrumb : [];
};

/**
 * Returns the correct anchor for a given route
 * @param processedUrls
 * @param pathName
 * @returns {String}
 */
export const provideAnchorForUrl = (processedUrls: ProcessedUrls, pathName: string): string => {
  const navigationInfo = processedUrls?.[pathName] || {};

  return navigationInfo?.anchor || '';
};

/**
 * Returns the page options for a given route
 * @param processedUrls
 * @param pathName
 * @returns {object}
 */
export const providePageOptionsForUrl = (processedUrls: ProcessedUrls, pathName: string): Object => {
  const navigationInfo = processedUrls?.[pathName] || {
    options: {
      showBreadcrumbs: false,
      showNavigation: false,
      showInTopics: false,
      showNewsletterWidget: false,
    },
  };
  return navigationInfo.options;
};

/**
 * Provides links for a certain category depending on the given parent
 * @param pages
 * @param parent (string) Identifier for the parent node in cms->pages
 * @param locale
 * @returns {Array}
 */
export const provideLinks = (pages: Pages, parent: string, locale: Locale): Array<{}> => {
  const links = [];

  if (has(pages, `${parent}.children`)) {
    pages[parent].children.forEach((navigationItem) => {
      if (typeof navigationItem.pageId !== 'undefined') {
        links.push(provideLink(pages, navigationItem.pageId, locale));
      } else if (
        typeof navigationItem.pageId === 'undefined' &&
        navigationItem?.externalUrl?.[locale] &&
        navigationItem?.text?.[locale]
      ) {
        links.push({
          url: navigationItem.externalUrl[locale],
          text: navigationItem.text[locale],
          openNewTab: navigationItem.openInNewWindow || false,
        });
      }
    });
  }

  return links?.filter((linkObject) => Object.keys(linkObject)?.length !== 0);
};

/**
 * Provides a search link given a search term
 * @param searchTerm
 * @returns {{text, url: string}}
 */

export const provideSearchLink = (searchTerm) => {
  const encodedSearchTerm = encodeURIComponent(searchTerm.trim())
    .replaceAll(/%3A(?=.)/g, '+')
    .replaceAll(/%20(?=.)/g, '+')
    .replaceAll(/%2b(?=.)/gi, '+');

  return {
    url: `${getSearchUrl(getLocale())}?search=${encodedSearchTerm}`,
    text: searchTerm,
  };
};

/**
 * Returns a new url for a given url with a new locale
 * @param processedUrls
 * @param pages
 * @param path
 * @param newLocale
 * @returns {string}
 */
export const getRelocalizedCmsUrlPath = (pages: Pages, path: string, newLocale: Locale): string | boolean => {
  const processedUrls = provideUrls(pages);
  const decodedPath = decodeURIComponent(path);
  if (!has(processedUrls, decodedPath)) {
    return false;
  }

  // DSG anchor translation handling for AGB site
  const anchor = __CLIENT__ && window.location.hash;
  if (anchor && anchor.includes('9-')) {
    const dsgAnchorTranslation = getTranslation('formRegistration.checkboxAGB.anchorForDSG', undefined, newLocale);
    if (!dsgAnchorTranslation.includes('[')) window.location.hash = `#${dsgAnchorTranslation}`;
  }

  return provideLink(pages, processedUrls?.[decodedPath]?.pageId, newLocale).url;
};

export const getFlattendNavigationNodes = (nodes = {}) =>
  nodes.flatMap((item) => {
    if (item.children.length) {
      const children = getFlattendNavigationNodes(item.children);
      return [item, ...children];
    }
    return item;
  });
