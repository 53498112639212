import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'constants/noop/noop';

import styles from './Toggle.scss';

const Toggle = ({ toggleAction = noop, isActive = false, isDisabled }) => {
  const handleToggle = () => toggleAction(!isActive);

  return (
    <div className={styles.toggleOption}>
      <div className={styles.toggleWrapper}>
        <input
          type="checkbox"
          className={styles.checkBox}
          checked={isActive}
          onChange={handleToggle}
          disabled={isDisabled}
        />
        <div className={styles.track} />
        <div className={styles.knob} />
      </div>
    </div>
  );
};

Toggle.propTypes = {
  toggleAction: PropTypes.func,
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

export default Toggle;
