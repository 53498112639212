import React from 'react';
import PropTypes from 'prop-types';
import SvgContainer from 'components/atoms/SvgContainer/SvgContainer';

const renderPath = [
  <path
    key={'path'}
    // eslint-disable-next-line max-len
    d="M354.6 310H43.2c-8.8 0-16.2-7.3-16.2-16.2V103.6c0-8.9 7.3-16.2 16.2-16.2h311.2c8.9 0 16.2 7.3 16.2 16.2v190.2c.1 8.9-7.2 16.2-16 16.2zM43.2 94.1c-5.2 0-9.4 4.2-9.4 9.4v190.2c0 5.2 4.2 9.4 9.4 9.4h311.2c5.2 0 9.4-4.2 9.4-9.4V103.6c0-5.2-4.2-9.4-9.4-9.4l-311.2-.1c0 .1 0 0 0 0z"
  />,
  <path
    key={'path2'}
    // eslint-disable-next-line max-len
    d="M55.9 114.1v38.1H94v-38.1H55.9zm34.5 23.4H79.3v11.1h-8.8v-11.1H59.4v-8.8h11.1v-11.1h8.8v11.1h11.1v8.8zm-34.5 25h83.6v108.3H55.9V162.5zm96.3 36.6h90.5v12.8h-90.5v-12.8zm0 21.9h115v12.8h-115V221z"
  />,
];
const vbWidth = 400;
const vbHeight = 400;

const IconFuehrerausweis = ({ height }) => (
  <SvgContainer height={height} vbWidth={vbWidth} vbHeight={vbHeight}>
    {renderPath}
  </SvgContainer>
);

IconFuehrerausweis.displayName = 'atoms/IconFuehrerausweis';

IconFuehrerausweis.propTypes = {
  height: PropTypes.number,
};

IconFuehrerausweis.defaultProps = {
  height: 32,
};

export default IconFuehrerausweis;
