import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Swiper, SwiperSlide } from 'swiper/react';

import styles from './Thumbnails.scss';

const Thumbnails = ({ activeSlide = 0, galleryData = [], goToSlide = () => {}, isLightBoxModal = false }) => {
  const [swiper, setSwiper] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(activeSlide);

  useEffect(() => {
    if (swiper && activeSlide !== currentSlide) {
      swiper.slideTo(activeSlide);
      setCurrentSlide(activeSlide);
    }
  });

  const breakpointsThumbnails = {
    0: {
      slidesPerView: 4,
      spaceBetween: 10,
    },
    544: {
      slidesPerView: 5,
    },
    768: {
      slidesPerView: 4,
    },
    992: {
      slidesPerView: 5,
    },
    1200: {
      slidesPerView: 6,
    },
  };

  const breakpointsLightbox = {
    0: {
      slidesPerView: 5,
      spaceBetween: 10,
    },
    544: {
      slidesPerView: 7,
    },
    768: {
      slidesPerView: 8,
    },
    992: {
      slidesPerView: 10,
    },
    1200: {
      slidesPerView: 12,
    },
  };

  const openLightbox = (index) => {
    goToSlide(index);
  };

  return (
    <Swiper
      className={styles.thumbnailWrapper}
      spaceBetween={isLightBoxModal ? 10 : 20}
      slidesPerView={isLightBoxModal ? 'auto' : 6}
      preloadImages
      initialSlide={activeSlide}
      onSwiper={setSwiper}
      breakpoints={isLightBoxModal ? breakpointsLightbox : breakpointsThumbnails}
      centerInsufficientSlides={isLightBoxModal}
    >
      {galleryData.map((data, i) => (
        <SwiperSlide
          className={cx(styles.thumbnail, { [styles.active]: activeSlide === i })}
          key={i}
          onClick={() => openLightbox(i)}
        >
          {data}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

Thumbnails.displayName = 'molecules/Thumbnails';
Thumbnails.propTypes = {
  activeSlide: PropTypes.number,
  galleryData: PropTypes.array,
  openLightboxModal: PropTypes.func,
  goToSlide: PropTypes.func,
  isLightBoxModal: PropTypes.bool,
};

export default Thumbnails;
