import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@interdiscount/component-library-interdiscount';
import { apiStatus } from 'constants/apiStatus/apiStatus';
import Spinner from 'components/atoms/Spinner/Spinner';
import MessageAddedToCartButtons from 'components/molecules/MessageAddedToCartButtons/MessageAddedToCartButtons';
import ServicesAndAccessories from 'components/organisms/ServicesAndAccessories/ServicesAndAccessories';
import AddedProduct from 'components/molecules/AddedProduct/AddedProduct';
import SimpleProduct from 'containers/Product/SimpleProduct';
import Headline, { tags } from 'components/atoms/Headline/Headline';
import language from 'constants/language/language';
import styles from './AddedToCartModalContent.scss';

const AddedToCartModalContent = ({
  addToCartState,
  referenceItemCodes,
  serviceItemCodes,
  updateEntry,
  goToCart,
  closeModal,
  confirmAndClose,
  modalIsOpen = false,
}) => (
  <Modal onRequestClose={closeModal} isOpen={modalIsOpen} contentLabel="AddedToCartModal" size="LG">
    {addToCartState.status !== apiStatus.success ? (
      <Spinner status={addToCartState.status} />
    ) : (
      <>
        <div className={styles.title}>
          <Headline tag={tags.H2}>{language('product.added')}</Headline>
        </div>
        <div className={styles.marginBottom}>
          <SimpleProduct productCode={addToCartState.productCode}>
            <AddedProduct />
          </SimpleProduct>
        </div>
        <ServicesAndAccessories
          updateEntry={updateEntry}
          entryID={addToCartState.entryID}
          productCode={addToCartState.productCode}
          quantity={addToCartState.quantity}
          productServices={addToCartState.productServices}
          accessories={addToCartState.accessories} // added accessories
          status={addToCartState.status}
          referenceItemCodes={referenceItemCodes}
          serviceItemCodes={serviceItemCodes}
        />
      </>
    )}
    <MessageAddedToCartButtons closeModal={confirmAndClose} goToCart={goToCart} />
  </Modal>
);

AddedToCartModalContent.propTypes = {
  addToCartState: PropTypes.object.isRequired,
  referenceItemCodes: PropTypes.array.isRequired,
  serviceItemCodes: PropTypes.array.isRequired,
  updateEntry: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  confirmAndClose: PropTypes.func.isRequired,
  goToCart: PropTypes.func.isRequired,
  modalIsOpen: PropTypes.bool,
};

AddedToCartModalContent.displayName = 'containers/AddedToCartModalContent';

export default AddedToCartModalContent;
