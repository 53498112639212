import React from 'react';
import PropTypes from 'prop-types';
import { Link, browserHistory } from 'react-router';

import { scrollUpToId, TOP_PAGE_ANCHOR } from 'constants/scrollToElement/scrollToElement';
import { noop } from 'constants/noop/noop';

const ScrollingLink = ({
  to,
  query,
  className = '',
  onMouseOver = noop,
  onMouseDown = noop,
  onMouseEnter = noop,
  onMouseLeave = noop,
  scrollToId = TOP_PAGE_ANCHOR,
  duration = 500,
  children,
}) => {
  const handleClick = () => {
    if (scrollToId) {
      browserHistory.push(query);
      scrollUpToId(scrollToId, duration);
    }
  };

  return (
    <Link
      to={to}
      className={className}
      onMouseOver={onMouseOver}
      onMouseDown={onMouseDown}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={handleClick}
    >
      {children}
    </Link>
  );
};

ScrollingLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  query: PropTypes.string,
  className: PropTypes.string,
  onMouseOver: PropTypes.func,
  onMouseDown: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  scrollToId: PropTypes.string,
  duration: PropTypes.number,
};

export default ScrollingLink;
