import { isB2BCustomer } from 'constants/customerGroup/customerGroup';

const NEUTRAL_DELIVERY_FILTER_CODE = 'neutralDeliveryPossible';

const priorizeNeutralDeliveryFilter = (facetFilters) => {
  const foundNeutralDeliveryPossibleFilter = facetFilters.find(
    (facetFilter) => facetFilter.code === NEUTRAL_DELIVERY_FILTER_CODE
  );

  if (!foundNeutralDeliveryPossibleFilter) {
    // not neutralDelivery facet at all
    return facetFilters;
  }

  const facetsWithoutNeutralDelivery = facetFilters.filter(
    (facetFilter) => facetFilter.code !== NEUTRAL_DELIVERY_FILTER_CODE
  );

  if (isB2BCustomer() && foundNeutralDeliveryPossibleFilter) {
    // move neutralDelivery facet to top of the list
    return [foundNeutralDeliveryPossibleFilter].concat(facetsWithoutNeutralDelivery);
  }

  return facetsWithoutNeutralDelivery;
};

export default priorizeNeutralDeliveryFilter;
