// @flow
import React from 'react';
import Spinner, { SPINNER_STATES } from 'components/atoms/Spinner/Spinner';
import language from 'constants/language/language';
import styles from './FeedbackFormResponse.scss';

const getSpinnerState = (requestSuccessful: ?boolean) => {
  if (requestSuccessful === null) return SPINNER_STATES.REQUEST;
  return requestSuccessful ? SPINNER_STATES.SUCCESS : SPINNER_STATES.FAILURE;
};

type Props = {
  /** If the request to create a product report was successful */
  requestSuccessful: boolean,
};

const FeedbackFormResponse = ({ requestSuccessful }: Props) => (
  <div className={styles.requestStatusContainer}>
    <Spinner status={getSpinnerState(requestSuccessful)} />
    <span className={styles.requestStatusContainerText}>
      {requestSuccessful ? language('feedbackForm.success') : language('feedbackForm.failure')}
    </span>
  </div>
);

export default FeedbackFormResponse;
