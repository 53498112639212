/* eslint-disable no-useless-concat */
const common = {
  continue: 'Weiter',
  return: 'Zurück',
  returnToSearchResults: 'Zurück zu den Suchresultaten',
  more: 'Mehr anzeigen',
  showLess: 'Weniger anzeigen -',
  moreInfo: 'Weitere Informationen',
  lessInfo: 'Weniger Informationen',
  moreInfoOld: 'Weitere Informationen +',
  lessInfoOld: 'Weniger Informationen -',
  less: 'Weniger anzeigen',
  next: 'Weiter',
  strikeThroughPricePrefixText: 'statt',
  daysAbbreviations: {
    // Days abbreviations german - english
    Mo: 'Mo',
    Tu: 'Di',
    Wd: 'Mi',
    Th: 'Do',
    Fr: 'Fr',
    Sa: 'Sa',
    Su: 'So',
  },
};

const header = {
  interdiscount: 'Interdiscount',
  login: 'Mein Konto | Anmelden',
  myAccount: 'Mein Konto',
  myAccountLogin: 'Anmelden',
  b2bLinkText: 'Sie sind Geschäftskunde?',
  b2bLink: 'https://b2b.interdiscount.ch/', // has to be in translation file, because /de doesn't exist.
  b2bMenuText: 'Geschäftskunden',
  b2bHeaderText: 'B2B',
  trendspot: 'Trendspot',
  service: 'Service',
  locations: 'Standorte',
  productComparison: 'Produktvergleich',
  shoppingList: 'Merkliste',
  searchboxPlaceholder: 'Stichwortsuche, Kategorien, Marken',
  searchboxPlaceholderShort: 'Stichwortsuche',
  allProducts: 'Sortiment',
  allProductsShort: 'Alle',
  menu: 'Menü',
  closeMenu: 'Schliessen',
  iconBar: {
    storeTitle: 'Über 170 Filialen in der Schweiz',
    storeLink: 'Filiale suchen',
    basketTitle: 'Einfache Bezahlung, schnelle Lieferung',
  },
  navigationText: 'Unser Sortiment',
  homepage: 'Startseite',
  quickOrder: 'Quick Order',
};

const mobileDrilldown = {
  overview: 'Übersicht',
  account: {
    help: 'Hilfe',
  },
};

const navigation = {
  brochures: {
    percent: '%',
    text: 'Prospekte',
  },
  service: 'Service',
  promotion: 'Aktionen',
  onlinePromotion: 'Online Aktionen',
  blog: 'Blog',
  storeFinder: 'Filialsuche',
  stores: 'Filialen',
  cart: 'Warenkorb',
  b2bSolo: 'Geschäftskunde',
  orders: 'Bestellungen',
  register: 'Registrieren',
};

const footer = {
  contact: 'Kontakt',
  help: 'Hilfe',
  helpAndContact: 'Helpcenter',
  findStore: 'Filiale suchen',
  products: 'Produkte',
  service: 'Service',
  company: 'Unternehmen',
  partner: 'Partner',
  paymentMethods: 'Zahlungsarten',
  paymentMethodsDescription: 'Ausführliche Informationen finden Sie im Servicebereich unter ',
  paymentMethodsLink: 'Alle Zahlungsarten',
  paymentMethodsExternalLink: 'https://helpcenter.interdiscount.ch/s/?c__cun=Payment&c__cl=Bezahlung&language=de',
  supercardDescription: 'Sammeln Sie ',
  superPointsLink: 'Superpunkte',
  followUs: 'Folgen Sie uns',
  coop: 'Ein Unternehmen der Coop Gruppe',
  coopName: 'Coop',
  jobOffers: 'Stellenangebote',
  benefits: {
    slogan: 'Einkaufen wie es dir gefällt',
    item01: {
      title: 'Online bestellen',
      text: 'und in jeder Filiale abholen',
    },
    item02: {
      title: 'Heute bis 20 Uhr bestellen',
      text: '- morgen geliefert',
    },
    item03: {
      title: 'Versandkostenfrei',
      text: 'bei Heimlieferung ab CHF 49.-',
    },
  },
  trustmarks: {
    oauth: 'OAuth 2.0',
    ssl: 'SSL certified',
    bosw2019: 'Best of Swiss Web 2019',
    bosw2020: 'Best of Swiss Web 2020',
  },
  legalSection: {
    coopGroup: 'Ein Unternehmen der Coop Gruppe',
    termsConditions: 'AGB',
    termsConditionsUrl: '/de/cms/unternehmen/agb',
    imprint: 'Impressum',
    imprintUrl: '/de/cms/unternehmen/impressum',
    privacyPolicy: 'Datenschutz',
    privacyPolicyUrl: 'https://www.coop.ch/de/unternehmen/datenschutz/interdiscount.html',
  },
};

const footerContact = {
  customer: {
    text: 'Sie haben Fragen oder möchten telefonisch bestellen? Unser Kundenservice hilft Ihnen gerne weiter.',
    customerService: 'Kundenservice',
    telephone: '+41 31 764 44 44',
    telephoneHref: 'tel:+41317644444',
    openingDays: 'Montag \u2013 Freitag',
    openingHours: '08.00 \u2013 18.00 Uhr',
    linkStorefinder: {
      text: 'Standorte finden',
    },
  },
};

const footerStore = {
  headline: 'Garantiert auch in Ihrer Nähe',
  text: 'Persönliche Beratung und alle Service-Leistungen direkt in Ihrer Nähe.',
};

const search = {
  doSearch: 'Suchen',
  clearHistory: 'Suchverlauf löschen',
  found: '',
  results: '', // Aligned with italian and french empty strings
  in: 'in',
  close: 'Schliessen',
  brand: {
    moreInfo: 'Weiterführende Informationen:',
  },
  topicTypes: {
    cms: 'Thema',
    category: 'Kategorie',
    brand: 'Marke',
  },
};

const filter = {
  doFilter: 'Filtern',
  showProducts: 'Artikel anzeigen',
  results: 'Resultate',
  close: 'Schliessen',
  more: 'Weitere Filter',
  reset: 'Zurücksetzen',
  products: 'Artikel',
  removeFilters: 'Alle Filter löschen',
  category: 'Kategorie',
  filterTitle: 'Filter',
};

const cart = {
  title: 'Warenkorb',
  deliveryInfo: 'Es fehlen noch %s für eine kostenlose Lieferung.',
  isEmpty: 'Ihr Warenkorb ist leer',
  isEmptyDeliveryText:
    'Wussten Sie schon? Ab einem Gesamtbestellwert von 49.- CHF liefern wir versandkostenfrei ' +
    'zu Ihnen nach Hause.',
  isEmptyAdditionalDeliveryText: 'Die Abholung in einer unserer Filialen ist selbstverständlich kostenlos.',
  continue: 'Jetzt shoppen',
  goToCart: 'Zur Kasse',
  tableCaption: {
    description: 'Beschreibung',
    unitPrice: 'Preis pro Stück',
    quantity: 'Menge',
    summary: 'Total',
  },
  serviceModal: {
    confirm: 'Übernehmen',
  },
  count: 'Anzahl',
  max: 'Max.',
  pieces: 'Stück',
  remove: 'Artikel entfernen',
  addService: 'Service dazubuchen',
  removeService: 'Service entfernen',
  addAccessories: 'Passendes Zubehör',
  discounts: 'Ihre Geschenkkarten und Rabatte können Sie im nächsten Schritt einlösen.',
  entries: 'Artikelliste',
  combinationProduct: {
    singular:
      'Schön, dass Sie sich für Microsoft 365 in Kombination mit Ihrem neuen Gerät entschieden haben. ' +
      'Als Dankeschön schenken wir Ihnen zusätzliche 3 Monate Laufzeit für Ihr ausgewähltes Microsoft 365 Produkt.',
    plural:
      'Schön, dass Sie sich für Microsoft 365 in Kombination mit Ihren neuen Geräten entschieden haben. ' +
      'Als Dankeschön schenken wir Ihnen zusätzliche 3 Monate Laufzeit für Ihre ausgewählten Microsoft 365 Produkte.',
  },
};

const checkout = {
  title: {
    guest: 'Gast Checkout',
    customer: 'Checkout',
  },
  orderSummary: {
    description: 'Beschreibung',
    article: 'Artikel',
    pricePerItem: 'Preis pro Stück',
    quantity: 'Menge',
    total: 'Total',
    totalProducts: 'Total Produkte',
    transportGuarantee: 'Transportgarantie auf Warenwert',
    chargeForSmallQuantity: 'Versandkosten',
    paymentCharge: 'Zahlungsgebühr',
    totalExcludingTax: 'Total exkl. MwSt.',
    totalIncludingTax: 'Total inkl. MwSt.',
    subTotalExcludingTax: 'Zwischentotal exkl. MwSt.',
    subTotalIncludingTax: 'Zwischentotal inkl. MwSt.',
    tax: 'MwSt.',
    totalTax: 'Total MwSt.',
    currency: 'CHF',
    totalIncludingGiftcard: 'Restlicher Betrag',
    bulkyGood: 'Gewicht über 30 kg - eingeschränkte Abholmöglichkeit',
  },
  email: {
    login: 'Jetzt anmelden',
  },
  addressForm: {
    title: 'Adresse',
    subTitle: 'Persönliche Angaben & Rechnungsadresse',
    alternativeAddress: 'Abweichende Lieferadresse',
    selectAddress: 'Adresse wählen',

    addressTitle: {
      unified: 'Rechnungs- & Lieferadresse',
      invoice: 'Rechnungsadresse',
      delivery: 'Lieferadresse',
    },
    changeAddress: {
      unified: 'Adresse ändern',
      invoice: 'Rechnungsadresse ändern',
      delivery: 'Lieferadresse ändern',
    },
    select: 'Auswählen',
    createAddress: 'Neue Adresse anlegen',

    confirmDeleteTitle: 'Möchten sie diese Adresse tatsächlich löschen?',
    confirmDelete: 'Ja, bitte löschen',
    cancelDelete: 'Abbrechen',

    cannotDeleteTitle: 'Diese Adresse kann nicht gelöscht werden',
    cannotDeleteText: 'Diese Adresse wird in der aktuellen Bestellung verwendet und kann daher nicht gelöscht werden.',
    cannotDeleteButton: 'OK',

    saveAndSelect: 'Speichern & auswählen',
    save: 'Speichern',

    discard: 'Verwerfen',

    monthlyInvoiceWarning:
      'Bitte beachten Sie, dass die Zahlungsart "Rechnung" nicht zur Verfügung steht, ' +
      'wenn Sie eine abweichende Lieferadresse wählen.',

    Invalid:
      'Die gewählte Adresse ist leider unvollständig. Dies kann durch die automatische Übernahme aus unserem ' +
      'alten Shop passiert sein. Wir bitten Sie, die Adresse anzupassen. Vielen Dank für Ihr Verständnis.',
    Missing: 'Bitte wählen Sie eine Adresse aus.',
    NETWORKERROR: 'Überprüfen sie Ihre Internetverbindung und versuchen Sie es später erneut.',
    FAILURE: 'Es ist ein Fehler beim Speichern der Adresse aufgetreten',
  },
  header: {
    backToCart: 'Zurück zum Warenkorb',
  },
  footer: {
    trustmarks: 'Bei Interdiscount kaufen Sie sicher ein!',
    contact: {
      terms: 'AGB',
      privacyProtection: 'Datenschutz',
      imprint: 'Impressum',
    },
    paymentAndServices: {
      returnPolicy: 'Rücknahme',
      delivery: 'Versand \u0026 Lieferung',
    },
  },
  change: 'Ändern',
  orderInProgressPart1: 'Ihre Bestellung ist in Arbeit. ',
  orderInProgressPart2: 'Bitte haben Sie noch etwas Geduld!',
  ageVerification: {
    title: 'Altersbeschränkung',
    products: 'Folgende Artikel Ihrer Bestellung weisen Altersbeschränkungen auf:',
    plsVerify:
      'Bitte verifizieren Sie Ihr Alter über einen Identitätsnachweis oder entfernen Sie die Artikel ' +
      'aus Ihrem Warenkorb.',
    verifyNow: 'Alter verifizieren',
    removeProducts: 'Alle altersbeschränkten Artikel entfernen',
  },
  giftcard: 'Geschenkkarte',
  coveredPayment: 'Der Zahlungsbetrag ist vollständig abgedeckt. Sie müssen kein weiteres Zahlungsmittel wählen.',
  softwareDelivery: 'Der Lizenzschlüssel wird Ihnen per E-Mail zugesendet.',
  orderTerms: {
    agb: 'Allgemeinen Geschäftsbedingungen',
    personalizedInfo: {
      before: 'Mit der Bestellung akzeptieren Sie unsere ',
      after: ' und den Erhalt von individualisierten Informationen, Angeboten und Gutscheinen.',
      info: 'Diese Genehmigung kann jederzeit z.B. im Kontobereich rückgängig gemacht werden.',
    },
    checkboxAGBs: {
      before: 'Mit der Bestellung akzeptieren Sie unsere ',
      after: '.',
    },
    paycardAGBs: {
      before: 'Mit der Bestellung akzeptieren Sie nebst unseren ',
      paycardAgb: 'Allgemeinen Geschäftsbedingungen ',
      connect: ' auch die ',
      paycard: 'von Paycard.',
      link: 'https://terms.mfgroup.ch/agbfiles/Paycard_Monatsrechnung_de.pdf',
    },
  },
  deliveryBoxSectionTitle: 'Liefertermin Informationen',
};

const productsOverview = {
  didYouMean: 'Meinten Sie vielleicht ',
  noResults: 'Leider keine Produkte gefunden ;(',
  noSearchResults: {
    before: 'Ihre Suche nach',
    after: 'ergab leider keine Treffer.',
    description: 'Prüfen Sie Ihre Schreibweise oder versuchen Sie es mit einem anderen Suchbegriff.',
  },
  searchResults: {
    before: {
      singular: 'Artikel zu',
      plural: 'Artikel zu',
    },
    after: {
      singular: 'gefunden',
      plural: 'gefunden',
    },
  },
  numberResults: {
    of: 'von',
    to: 'bis',
    products: 'Artikeln',
  },
  sortingProducts: {
    text: 'Sortierung',
  },
  viewType: {
    view: 'Ansicht',
    gridView: 'Galerie',
    listView: 'Liste',
  },
  refineSearch: 'Suche verfeinern',
  refineFilter: 'Filter verfeinern',
  categoriesHeadline: 'Kategorie-Übersicht',
  categories: 'Kategorien',
};

const searchTips = {
  headline: 'Tipps für Ihre Suche:',
  tip1: 'Überprüfen Sie Ihren Suchbegriff auf Schreibfehler.',
  tip2: 'Versuchen Sie es mit einem anderen Suchbegriff.',
  tip3: 'Verwenden Sie unsere Artikel-, EAN- oder Herstellernummer, falls sie Ihnen bekannt ist.',
};

const product = {
  addToWatchlist: 'Merken',
  added: 'Hinzugefügt',
  willBeAdded: 'Artikel wird hinzugefügt ...',
  notAdded: 'Artikel konnte nicht hinzugefügt werden',
  maxOrderQuantityExceeded: 'Leider ist die maximale Bestellmenge für diesen Artikel schon erreicht.',
  itemNumber: 'Artikel-Nr:',
  itemNumberOnline: 'Online-Artikel-Nr:',
  addToCart: 'In den Warenkorb',
  reserve: 'Reservieren',
  continueShopping: 'Weiter einkaufen',
  goToCart: 'Zum Warenkorb',
  speedProductFlag: 'Express',
  todayFrom: '',
  expectedDelivery: 'Lieferung voraussichtlich',
  onPickup: 'Abholung',
  onHomeDelivery: 'Heimlieferung',
  availabilityDelivery: 'Verfügbarkeit und Liefertermin',
  information: 'Information',
  storeStock: 'Filialbestand',
  reservationNowPossible: 'Reservierung ab sofort möglich',
  reservationNowPossibleESD:
    'Reservierung ab sofort möglich. Nach erfolgreicher Bezahlung erhalten Sie den ' +
    'Artikel innerhalb von 5-10 Min. direkt per E-Mail zugeschickt. Bei Vorauskasse verzögert sich der Versand ' +
    'bis zum Zahlungseingang. ESD-Code ab Releasedatum einlösbar.',
  speedFlagInfoLink: 'Information',
  speedFlagToolTip:
    'Bestellung bis 20 Uhr: Lieferung am nächsten Tag nach Hause oder abholbar ab 14 Uhr ' +
    '- ausgenommen sind Bestellungen auf Vorauskasse und Geräte > 30kg oder > 50 Zoll.',
  speedFlagToolTipNobrUnits: ['> 30kg', '> 50 Zoll'],
  infoLink: 'Information',
  pegiAbove: 'Ab',
  warranty: 'Garantie',
  monthsWarranty: 'Monate',
  yearsWarranty: 'Jahre',
  yearWarranty: 'Jahr',
  details: 'Produktebeschrieb',
  description: 'Produktbeschreibung',
  detailsHeader: 'Produktdetails',
  accessories: 'Zubehör',
  ratings: 'Bewertungen',
  manufacturerAID: 'Hersteller-Nr.',
  downloads: 'Downloads',
  accessoriesHeader: {
    title: 'Passendes Zubehör',
  },
  facets: {
    reset: 'Alle Filter löschen',
  },
  addServices: 'Services dazubuchen',
  addServicesTab: {
    title: 'Service',
    extended: 'dazubuchen',
  },
  addAccessoriesTab: {
    title: 'Zubehör',
    extended: 'mitbestellen',
  },
  consignments: {
    'home-delivery-gross': 'x versendet am ',
    'home-delivery-net': 'x versendet am ',
    pickup: 'x abgeholt am ',
  },
  guaranteeCard: 'Liefer- und Garantieschein',
  namedDeliveryDate: 'Voraussichtlicher Liefertermin',
  namedDeliveryDate2: 'Voraussichtlicher Liefertermin restliche Menge:',
  serviceItems: 'Gebuchte Services',
  canceledItems: '× storniert',
  allDetails: 'Alle Details anzeigen',
  b2bText: 'B2B Kunde | [Mengenanfrage für Firmenkunden]({URL})',
  b2bOffer: 'offerten',
  compare: 'Vergleichen',
  productFeatures: 'Produkteigenschaften',
  blogPost: 'Blogbeitrag',
  image: ' Bild',
  images: ' Bilder',
  video: ' Video',
  videos: ' Videos',
  addedToComparison: 'Das Produkt wurde in die Vergleichsliste gelegt. [Zur Vergleichsliste]({URL})',
  addedToWatchList: 'Das Produkt wurde in die Merkliste gelegt. [Zur Merkliste]({URL})',
  watchListIsFull: 'Es ist nicht möglich mehr als 100 Produkte zur Merkliste hinzuzufügen.',
};

const productFeatures = {
  and: 'und',
  to: 'bis',
  moreDetails: 'Mehr Details',
  expanderClose: 'Alle Details anzeigen',
  expanderOpen: 'Details ausblenden',
};

const orderBox = {
  orderOverview: 'Bestellübersicht',
  article: 'Artikel',
  totalExklTax: 'Total exkl. MwSt.',
  transportGuarantee: 'Transportgarantie',
  tax: 'MwSt.',
  total: 'Total',
  subtotal: 'Zwischensumme',
  goToOrder: 'Zur Kasse',
  orderNow: 'Jetzt bestellen',
  refreashOrder: 'Bestellung aktualisieren',
  continueShopping: 'Weiter einkaufen',
  securedText: 'Daten werden mit einer sicheren Verbindung übermittelt.',
  deliveryCost: 'Versandkosten',
  contact: {
    text: 'Fragen zur Bestellung?',
    telephone: '+41 31 764 44 44',
    telephoneHref: 'tel:+41317644444',
    openingDays: 'Montag \u2013 Freitag',
    openingHours: '08.00 \u2013 18.00 Uhr',
  },
  shippingCostsUnkown: 'Versandkosten werden im nächsten Schritt berechnet.',
  shippingCostsFree: 'Versandkostenfrei',
};

const form = {
  mandatoryFields: '*Pflichtfelder',
  mandatoryField: 'Pflichtfeld',
  optionalField: 'optional',

  fields: {
    email: {
      label: 'E-Mail-Adresse',
      tooltip: 'An diese E-Mail-Adresse senden wir Ihnen die Bestell- und Lieferbestätigung.',
      errorMessages: {
        Missing: 'Bitte geben Sie Ihre E-Mail-Adresse ein.',
        Invalid: 'Bitte geben Sie eine gültige E-Mail-Adresse in der Form name@domain.ch ein.',
        TakenEmail: 'Zu dieser E-Mail-Adresse existiert bereits ein Konto.',
        SameId: 'Diese E-Mail-Adresse ist aktuell mit Ihrem Konto verknüpft.',
        Pending: 'Die E-Mail-Adresse konnte nicht gespeichert werden. Bitte versuchen Sie es erneut.',
      },
    },
    contactSubjects: {
      label: 'Ihr Anliegen',
      placeholder: 'Bitte wählen',
      options: {
        ADDRESS: 'Adress- und Kontoänderung',
        GENERAL: 'Allgemeine Anfragen',
        CONSULTING_SALES: 'Beratung / Verkauf',
        DELIVERY: 'Lieferung und Lieferzeit',
        ONLINE_ORDER: 'Online-Bestellungen',
        CANCELATION: 'Stornierung',
        INVOICE: 'Verträge / Rechnungen',
        RECEIPT: 'Kauf-/Garantiebeleg',
        SUPERPOINTS: 'Nachträgliche Superpunkte',
        PRICE_CONFIRMATION: 'Preisbestätigung für Versicherung',
        FEEDBACK: 'Reklamation / Lob',
        REPAIR: 'Reparatur (defekter Artikel)',
        RETURN: 'Retoure (Rückgabe)',
      },
    },
    contactEmail: {
      label: 'E-Mail-Adresse',
      errorMessages: {
        Missing: 'Bitte geben Sie Ihre E-Mail-Adresse ein.',
        Invalid: 'Bitte geben Sie eine gültige E-Mail-Adresse in der Form name@domain.ch ein.',
      },
    },
    superpoints_receiptNumber: {
      label: 'Quittungsnummer',
      errorMessages: {
        Invalid: 'Die Quittungsnummer besteht aus 28 Ziffern. Bitte prüfen Sie Ihre Eingabe.',
        Missing: 'Bitte geben Sie die Quittungsnummer ein.',
        UNKNOWN: 'Die eingegebene Quittungsnummer ist nicht vergeben.',
      },
    },
    superpoints_store: {
      label: 'Filiale',
      errorMessages: {
        Missing: 'Bitte geben Sie die Filiale an.',
      },
    },
    contactTextarea: {
      label: 'Ihre Nachricht',
      errorMessages: {
        Missing: 'Bitte geben Sie Ihre Nachricht ein.',
      },
    },
    group: {
      label: 'Anmelden als',
      options: {
        CUSTOMERGROUP: 'Privatkunde',
        B2BCUSTOMERGROUP: 'Firmenkunde',
      },
    },
    salutation: {
      label: 'Anrede',
      options: {
        '01': 'Herr',
        '02': 'Frau',
      },
      errorMessages: {
        Missing: 'Bitte wählen Sie eine Anrede aus.',
      },
    },
    firstName: {
      label: 'Vorname',
      errorMessages: {
        Missing: 'Bitte geben Sie den Vornamen an.',
        Invalid: 'Bitte überprüfen Sie den Vornamen.',
      },
    },
    lastName: {
      label: 'Nachname',
      errorMessages: {
        Missing: 'Bitte geben Sie den Nachnamen an.',
        Invalid: 'Bitte überprüfen Sie den Nachnamen.',
      },
    },
    address_salutation: {
      label: 'Anrede',
      options: {
        '01': 'Herr',
        '02': 'Frau',
      },
      errorMessages: {
        Missing: 'Bitte wählen Sie eine Anrede aus.',
      },
    },
    address_firstName: {
      label: 'Vorname',
      errorMessages: {
        Missing: 'Bitte geben Sie den Vornamen an.',
      },
    },
    address_lastName: {
      label: 'Nachname',
      errorMessages: {
        Missing: 'Bitte geben Sie den Nachnamen an.',
      },
    },
    company: {
      label: 'Firma',
      errorMessages: {
        Missing: 'Bitte geben Sie den Namen Ihrer Firma an.',
      },
    },
    phone: {
      label: 'Telefonnummer',
      labelDescription: 'Auf diese Nummer rufen wir zurück',
      errorMessages: {
        Missing: 'Bitte geben Sie eine Telefonnummer an.',
        Invalid: 'Die angegebene Telefonnummer enthält ungültige Zeichen. Bitte korrigieren Sie Ihre Eingabe.',
        InvalidPhoneNumber:
          'Die angegebene Telefonnummer enthält ungültige Zeichen. ' +
          'Bitte verwenden Sie nur 0-9 und ./-+ sowie Leerzeichen.',
        InvalidPhoneNumberLengthMin: 'Die angegebene Telefonnummer ist zu kurz. Bitte überprüfen Sie Ihre Eingabe.',
        InvalidPhoneNumberLengthMax:
          'Die angegebene Telefonnummer ist zu lang. Bitte überprüfen Sie Ihre Eingabe. (max 30 Zeichen)',
        InvalidPhoneNumberEmpty: 'Bitte geben Sie eine gültige Telefonnummer ein.',
      },
    },
    recall: {
      label: 'Rückruf',
      phoneLabel: 'Telefon',
      phoneNumber: 'Telefon +41 31 764 44 44',
      openingTimes: 'Montag – Freitag, 8.00 – 18.00 Uhr',
      recallDate: 'Rückruftermin',
      bookRecallAppointment: 'Rückruftermin jetzt buchen',
      serviceOceanLink:
        'https://online.serviceocean.com/de/booking/form/624af900-e9bf-43c9-877d-b35a962896d6?requestid=1283#2',
    },
    mobile: {
      label: 'Handynummer',
      tooltip:
        'Damit wir Sie bei allfälligen Rückfragen zur Bestellung kontaktieren können, ' +
        'benötigen wir eine Telefonnummer, unter der wir Sie gut erreichen können.',
      errorMessages: {
        Missing: 'Bitte geben Sie Ihre Telefonnummer an.',
        Invalid: 'Die angegebene Telefonnummer enthält ungültige Zeichen. Bitte korrigieren Sie Ihre Eingabe.',
      },
    },
    addressLine1: {
      label: 'Strasse',
      errorMessages: {
        Missing: 'Bitte geben Sie die Strasse an.',
      },
    },
    addressLine2: {
      label: 'Nr.',
      errorMessages: {
        Missing: 'Bitte geben Sie die Hausnummer an.',
      },
    },
    address_addressLine1: {
      label: 'Strasse',
      errorMessages: {
        Missing: 'Bitte geben Sie die Strasse an.',
      },
    },
    address_addressLine2: {
      label: 'Nr.',
      errorMessages: {
        Missing: 'Bitte geben Sie die Hausnummer an.',
      },
    },
    addition: {
      label: 'Adresszusatz',
      errorMessages: {},
    },
    postalCode: {
      label: 'PLZ',
      errorMessages: {
        Missing: 'Bitte geben Sie die Postleitzahl (PLZ) an.',
        Invalid: 'Bitte geben Sie eine gültige Postleitzahl ein, z.B. 3038.',
      },
    },
    address_postalCode: {
      label: 'PLZ',
      errorMessages: {
        Missing: 'Bitte geben Sie die Postleitzahl (PLZ) an.',
        Invalid: 'Bitte geben Sie eine gültige Postleitzahl ein, z.B. 3038.',
      },
    },
    town: {
      label: 'Ort',
      errorMessages: {
        Missing: 'Bitte geben Sie den Ort an.',
      },
    },
    address_town: {
      label: 'Ort',
      errorMessages: {
        Missing: 'Bitte geben Sie den Ort an.',
        Invalid: '',
      },
    },
    country: {
      label: 'Land',
      placeholder: 'Bitte wählen Sie ein Land aus.',
      options: {
        CH: 'Schweiz',
        LI: 'Liechtenstein',
      },
      errorMessages: {
        Missing: 'Bitte wählen Sie ein Land aus.',
      },
    },
    address_country: {
      label: 'Land',
      placeholder: 'Bitte wählen Sie ein Land aus.',
      options: {
        CH: 'Schweiz',
        LI: 'Liechtenstein',
      },
      errorMessages: {
        Missing: 'Bitte wählen Sie ein Land aus.',
        Invalid: '',
      },
    },
    password: {
      label: 'Passwort',
      passwordStrength: 'Passwortstärke:',
      adviceText: 'Mind. 8 Zeichen, ein Gross- oder ein Kleinbuchstabe, eine Ziffer oder ein Sonderzeichen',
      showPassword: 'Passwort anzeigen',
      showPasswordShort: 'ANZEIGEN',
      hidePassword: 'VERBERGEN',
      errorMessages: {
        PasswordToWeak: 'zu schwach',
        PasswordGood: 'gut',
        PasswordVeryGood: 'sehr gut',
        Missing: 'Bitte geben Sie ein Passwort ein.',
        MissingLogin: 'Bitte geben Sie Ihr Passwort ein.',
        SameAsEmail: 'E-Mail-Adresse und Passwort dürfen nicht übereinstimmen',
      },
    },
    birthday: {
      label: 'Geburtsdatum',
      day: 'Tag',
      month: 'Monat',
      year: 'Jahr',
      tooltip:
        'Eine Altersprüfung ist zum Schutz von Minderjährigen in Bezug auf die Datenverarbeitung erforderlich. ' +
        'Deshalb wird das Geburtsdatum benötigt.',
      errorMessages: {
        Missing: 'Bitte geben Sie Ihr Geburtsdatum an',
        Invalid: 'Bitte geben Sie ein gültiges Datum ein',
        InvalidNot16:
          'Sie sind unter 16 Jahre alt und dürfen noch nicht bei uns einkaufen, weil wir personenbezogene ' +
          'Daten von Kindern nicht verarbeiten.',
      },
    },
    language: {
      label: 'Bevorzugte Sprache',
      options: {
        de: 'Deutsch',
        fr: 'Français',
        it: 'Italiano',
      },
      errorMessages: {},
    },
    supercardCode: {
      label: 'Supercard',
      labelDescription: '(13-stellig)',
      placeholder: '2501 _ _ _ _ _ _ _ _ _',
      tooltip:
        '13-stellige Nummer auf der Supercard. Wenn Sie Ihre Supercard hinterlegen, werden Ihnen beim Einkauf die Superpunkte gutgeschrieben.',
      tooltipCheckout:
        'Wenn Sie Ihre Supercard hinterlegen, werden Ihnen beim Einkauf die Superpunkte gutgeschrieben.' +
        " Ändern können Sie Ihre Supercard unter 'Mein Konto'.",
      errorMessages: {
        Invalid: 'Die eingegebene Supercard-Nummer ist ungültig. Bitte überprüfen Sie Ihre Eingabe.',
        NetworkError: 'Die Aktion kann nicht durchgeführt werden. Bitte prüfen Sie Ihre Internetverbindung.',
      },
    },
    employeeEan: {
      label: 'Personalrabattkartennummer',
      placeholder: 'CPP _ _ _ _ _ _ _ _ _ _',
      tooltip: '13-stellige Nummer unter dem Barcode auf der (Coop) Personalrabattkarte (CPP**********)',
      errorMessages: {
        Invalid: 'Die eingegebene Coop Mitarbeiter Kartennummer ist ungültig. Bitte überprüfen Sie Ihre Eingabe.',
      },
    },
    superpoints_supercardCode: {
      label: 'Supercard (13-stellig)',
      errorMessages: {
        Invalid: 'Die eingegebene Supercard-Nummer ist ungültig. Bitte überprüfen Sie Ihre Eingabe.',
        Missing: 'Bitte geben Sie die Supercard-Nummer an.',
      },
    },
    orderNumber: {
      label: 'Bestellnummer',
      tooltip:
        'Ihre 10-stellige Bestellnummer finden Sie in Ihrer Bestellbestätigung oder in ' +
        'Ihrem Kundenkonto unter "Meine Bestellungen".',
      errorMessages: {
        Missing: 'Bitte geben Sie eine Bestellnummer an.',
        Invalid: 'Bitte überprüfen Sie die Bestellnummer.',
      },
    },
    superpoints_dateOfPurchase: {
      label: 'Kaufdatum',
      errorMessages: {
        Missing: 'Bitte geben Sie das Kaufdatum ein.',
        Invalid: 'Bitte geben Sie ein gültiges Datum ein',
      },
    },
    repair_feedback_repairNumber: {
      label: 'Reparatur-Nummer',
      tooltip:
        'Ihre 10-stellige Reparatur-Nummer finden Sie in Ihrem Reparaturdokument unterhalb Ihrer ' +
        'persönlichen Daten (Reparaturauftrag 000XXXXXXX).',
    },
    repair_feedback_customerNumber: {
      label: 'Kunden-Nummer',
      tooltip:
        'Sofern Sie in einer unserer Filialen einen Vertrag mit Interdiscount abgeschlossen haben, ' +
        'finden Sie Ihre Kunden-Nummer oben rechts auf Ihrem Vertragsdokument.',
    },
    repair_feedback_contractNumber: {
      label: 'Vertrags-Nummer',
      tooltip:
        'Sofern Sie in einer unserer Filialen einen Vertrag mit Interdiscount abgeschlossen haben, ' +
        'finden Sie Ihre Vertrags-Nummer oben rechts auf Ihrem Vertragsdokument.',
    },
    repair_feedback_invoiceNumber: {
      label: 'Rechnungs-Nummer',
    },
    repair_feedback_articleNumber: {
      label: 'Artikelnummer',
    },
    purchasePrice: {
      label: 'Kaufpreis',
      errorMessages: {
        Missing: 'Bitte geben Sie den Kaufpreis an.',
      },
    },
    receiptNumber: {
      label: 'Quittungsnummer',
      errorMessages: {
        Invalid: 'Die eingegebene Quittungsnummer ist nicht vergeben.',
      },
    },
    brand: {
      label: 'Marke',
      errorMessages: {
        Missing: 'Bitte geben Sie die Marke an.',
      },
    },
    modelType: {
      label: 'Modell',
      errorMessages: {
        Missing: 'Bitte geben Sie das Modell an.',
      },
    },
    oldPassword: {
      label: 'Altes Passwort',
      errorMessages: {
        Missing: 'Bitte geben Sie Ihr Passwort ein.',
        Invalid: 'Das eingegebene Passwort entspricht nicht Ihrem aktuellen Passwort.',
      },
    },
    newPassword: {
      label: 'Neues Passwort',
      passwordStrength: 'Passwortstärke:',
      adviceText:
        'Verwenden Sie mindestens 8 Zeichen. Der Einsatz von Ziffern' + 'und Sonderzeichen erhöht die Passwortstärke.',
      showPassword: 'Passwort anzeigen',
      errorMessages: {
        PasswordToWeak: 'zu schwach',
        PasswordGood: 'gut',
        PasswordVeryGood: 'sehr gut',
        Missing: 'Bitte geben Sie ein Passwort ein.',
        MissingLogin: 'Bitte geben Sie Ihr Passwort ein.',
        SameAsEmail: 'E-Mail-Adresse und Passwort dürfen nicht übereinstimmen',
      },
    },
    reference: {
      label: 'Referenz',
      tooltip:
        'Geben Sie hier optional eine Bestellreferenz ein.' +
        ' Diese wird auf den Folgedokumenten (z.B. Lieferschein) vermerkt.',
    },

    feedbackFormEmail: {
      tooltip: 'An diese E-Mail-Adresse wenden wir uns, falls wir Fragen zu Ihrem Feedback haben.',
      errorMessages: {
        Missing: 'Bitte geben Sie Ihre E-Mail-Adresse ein.',
        Invalid: 'Bitte geben Sie eine gültige E-Mail-Adresse in der Form name@domain.ch ein.',
      },
    },

    feedbackFormMessage: {
      errorMessages: {
        Missing: 'Bitte geben Sie Ihre Nachricht ein.',
        Invalid: 'Ihre Nachricht darf nicht länger als 500 Zeichen sein.',
      },
    },

    feedbackFormTopic: {
      placeholder: 'Bitte wählen',
      errorMessages: {
        Missing: 'Bitte wählen Sie ein Thema aus.',
      },
    },
  },

  paymentMode: {
    label: 'Zahlungsart',
    errorMessages: {
      Missing: 'Bitte wählen Sie eine Zahlungsart aus',
    },
  },

  errorMessages: {
    Missing: 'Das Feld darf nicht leer sein',
    MissingEmail: 'Sie müssen eine E-Mail Adresse angeben',

    Invalid: 'Ihre Eingabe ist nicht korrekt',
    InvalidEmail: 'Geben Sie bitte eine gültige E-Mail Adresse ein',
    InvalidNumber: 'Es dürfen nur Zahlen eingegeben werden',
    InvalidPostalCode: 'Geben Sie bitte eine gültige Postleitzahl ein',

    Failure: 'Es gab einen Fehler bei der Prüfung ihrer Daten',

    IncompleteAddress: 'Bitte geben Sie eine vollständige Adresse ein',
    failure: 'Es ist ein Fehler beim Speichern der Adresse aufgetreten',
    mandatoryFields: '*Pflichtfelder',
  },
};

const formPayment = {
  title: 'Zahlungsart',
  headingOne: 'Diese Zahlungsarten bieten wir Ihnen ',
  headingBold: 'kostenlos ',
  headingTwo: 'und passend zur Lieferart an.',
  selectPaymentMode: 'Zahlungsart wählen',
  selectSavedPaymentMode: 'Gespeicherte Zahlungsart',
  paymentCosts: 'Zuschlag',
  convertGiftCard: 'Geschenkkarte einlösen',
  giftcard: 'Geschenkkarte',
  convertVoucher: 'Gutscheine / Rabatte einlösen',
  pay_in_advance: 'Vorauskasse',
  paycard: 'Paycard',
  invoice: 'Rechnung',
  sofortbanking: 'Sofortüberweisung',
  paypal: 'Paypal',
  postfinance: 'Postfinance E-Finance',
  postcard: 'Postcard',
  creditCard: 'Kreditkarten',
  pay_at_pickup: 'Bezahlung bei Abholung',
  twint: 'Twint',
  visa: 'Visa',
  mastercard: 'Mastercard',
  americanexpress: 'American Express',
  monthlyinvoice_pcf: 'Rechnung',
  companyinvoice: 'Firmenrechnung',
  samsung_pay: 'Samsung Pay',
  diners_club: 'Diners Club',
  notValid: 'Unkown Payment Mode',
  missing: 'Keine Zahlungsart ausgewählt',
  failure: 'Es ist ein Fehler beim Setzen der Zahlungsart aufgetreten',
  addition: 'Zuschlag',
  descriptions: {
    pay_in_advance:
      'Bei der Bezahlung auf Vorauskasse senden wir Ihnen die Zahlungsinformationen per E-Mail zu. ' +
      'Nach Eingang der Zahlung wird Ihre Bestellung ausgelöst und versendet. ' +
      'Bitte beachten Sie, dass die bestellte Ware erst ab dem Zeitpunkt des Zahlungseingangs für Sie reserviert ist ' +
      'und der kommunizierte Liefertermin sich um die Tage bis zum Zahlungseingang verzögert.',
    paycard:
      'Mit der Paycard können Sie in 10 Onlineshops und über 100 Verkaufsstellen der Coop Genossenschaft bargeldlos einkaufen.' +
      ' Sie bezahlen keine Jahresgebühr, bezahlen per Monatsrechnung und haben flexible Teilzahlungsoptionen. ' +
      'Die erste Rechnung erfolgt kostenfrei in Papierform. Danach wird für Papierrechnung eine Gebühr pro Rechnung erhoben. ' +
      'Sie können jederzeit bei Paycard auf die kostenfreie Rechnung per E-Mail umstellen. Die Bestellung wird sofort ausgelöst.',
    invoice: 'soon..',
    sofortbanking:
      'Sofortüberweisung funktioniert auf Basis des bewährten E-Bankings: Sie bezahlen bequem mit ' +
      'Ihren E-Banking Daten ohne zusätzliches Konto und ohne Registrierung. Die Bestellung wird sofort ausgelöst.',
    paypal: 'Bezahlen Sie schnell und einfach mit Ihrem PayPal-Konto. Ihre Bestellung wird sofort ausgelöst. ',
    postcard:
      'Bei der Bezahlung mittels Postfinance Card wird der Betrag direkt von Ihrem Konto abgebucht und ' +
      'die Bestellung sofort ausgelöst. ',
    postfinance:
      'Bei der Bezahlung mittels Postfinance E-Finance wird der Betrag direkt von Ihrem Konto ' +
      'abgebucht und die Bestellung sofort augelöst.',
    visa:
      'Die Zahlung mittels Kreditkarte ist sehr schnell, sicher und komfortabel. Ihre Bestellung wird sofort ' +
      'nach der Verifizierung des Kartenanbieters bei uns ausgelöst.',
    mastercard:
      'Die Zahlung mittels Kreditkarte ist sehr schnell, sicher und komfortabel. Ihre Bestellung wird sofort ' +
      'nach der Verifizierung des Kartenanbieters bei uns ausgelöst.',
    americanexpress:
      'Die Zahlung mittels Kreditkarte ist sehr schnell, sicher und komfortabel. ' +
      'Ihre Bestellung wird sofort nach der Verifizierung des Kartenanbieters bei uns ausgelöst.',
    pay_at_pickup:
      'Bei der Bezahlung bei Abholung wird Ihre Bestellung sofort ausgelöst. ' +
      'Sie können Ihre Bestellung bequem ' +
      'vor Ort an der Kasse, mit dem gewünschten Zahlungsmittel, bezahlen.',
    twint: 'Mit Twint bezahlen Sie bequem per Smartphone App (Android und iOS). Die Bestellung wird sofort ausgelöst.',
    monthlyinvoice_pcf:
      'Sie erhalten von der Paycard (Retail Marketing Switzerland AG) eine Rechnung innerhalb von 10 Arbeitstagen im Verlaufe des Folgemonats. ' +
      'Diese kann bei Erfüllung gewisser Bedingungen in Teilzahlungen beglichen werden. Die erste Rechnung erfolgt kostenfrei in Papierform. ' +
      'Danach wird für Papierrechnung eine Gebühr pro Rechnung erhoben. Sie können jederzeit bei Paycard auf die kostenfreie Rechnung per E-Mail umstellen. ' +
      'Bei positiver Bonitätsprüfung beim Zahlungsvorgang wird die Bestellung sofort ausgelöst. ' +
      'Für eine erfolgreiche Überprüfung ist wichtig, dass Sie Ihre Privatadresse angeben. ' +
      'Bei negativer Bonitätsprüfung wählen Sie bitte ein anderes Zahlungsmittel. ' +
      'Für geltende Bestimmungen und Konditionen verweisen wir auf die AGB der Retail Marketing Switzerland AG ' +
      '([Allgemeinen Geschäftsbedingungen](https://terms.mfgroup.ch/agbfiles/Paycard_Monatsrechnung_de.pdf)).',
    samsung_pay:
      'Ihre Zahlungen werden mit Samsung Pay einfacher, sicherer und schneller. Ihre Bestellung wird ' +
      'sofort ausgelöst.',
    diners_club:
      'Die Zahlung mittels Kreditkarte ist sehr schnell, sicher und komfortabel. Ihre Bestellung wird sofort ' +
      'nach der Verifizierung des Kartenanbieters bei uns ausgelöst.',
    companyinvoice:
      'Sie können Ware bis zu Ihrem vorgegebenen Kreditlimit auf Rechnung beziehen. ' +
      'Bestellungen, welche das Kreditlimit überschreiten, werden von uns manuell geprüft. ' +
      'Sollte eine Bestellung aufgrund des überschrittenen Kreditlimits nicht ausgeführt werden können, werden Sie ' +
      'von uns kontaktiert. ' +
      'Die Zustellung der Rechnung erfolgt per Briefpost nachdem alle Positionen der Bestellung versandt worden sind. ',
    notValid: 'Keine Beschreibung vorhanden',
  },
  changePaymentMode: 'Ändern',
  savePaymentMode: 'Übernehmen',
  cancelPaymentMode: 'Abbrechen',
  MissingPaymentMethod: 'Bitte wählen Sie eine Zahlungsart aus.',
  FAILURE: 'Die Zahlungsart konnte nicht geändert werden. Bitte versuchen Sie es später erneut.',
  NETWORKERROR:
    'Die Zahlungsart konnte nicht geändert werden. ' +
    'Bitte überprüfen Sie Ihre Internetverbindung und versuchen Sie es später erneut.',
  savedCardCode: '(Kartennummer ',
  deletePaymentMode: 'Gespeicherte Zahlungsart löschen',
};

const formDelivery = {
  title: 'Lieferart',
  'home-delivery-gross': {
    label: 'Heimlieferung',
    description: {
      deliveryFree: 'Die Lieferung ist versandkostenfrei.',
      deliveryCost: 'Die Lieferung ist ab einer Bestellung von {THRESHOLD} versandkostenfrei.',
    },
  },
  pickup: {
    label: 'Abholung',
    description: {
      deliveryFree: 'Die Lieferung in eine unserer 170 Filialen ist versandkostenfrei.',
    },
  },
  transportGuarantee: {
    label: 'Transportgarantie von interdiscount.ch hinzufügen',
    description:
      'Die Transportgarantie ist eine Absicherung gegen Transportschäden und -verluste. ' +
      'Mit Abschluss der Transportgarantie ist Ihre Bestellung gegen die tatsächlich entstandenen Schäden ' +
      'auf dem Versandweg (maximal dem Warenwert zum Zeitpunkt der Bestellung) geschützt.',
  },
  Invalid: 'Bitte wählen Sie einen Standort / Pick-up aus.',
  packageAnnouncement: {
    title: 'Paketankündigung',
    textShort:
      'Ja, ich möchte per E-Mail für diese Bestellung eine Paketankündigung von der Post erhalten.\n\n' +
      'Ihre E-Mailadresse wird nur für diesen einmaligen Zweck an die Post weitergeleitet.',
    textLong:
      'Wenn Sie der Paketankündigung zustimmen, erhalten Sie für diese Bestellung vorab von der Post ' +
      'eine Benachrichtigung per E-Mail über den voraussichtlichen Zustellzeitpunkt. Zudem werden Sie informiert, ' +
      'wenn das Paket nicht zugestellt werden konnte und wo das Paket zur Abholung bereit liegt.\n\n' +
      'Wenn Sie den Paketankündigungsservice nicht wünschen, Sie sich aber bereits für den Paketdienst bei der ' +
      'Post angemeldet haben, werden Sie trotzdem von der Post über die Paketankündigung informiert.\n\n' +
      'Wenn Sie Speditionsware bestellen, erhalten Sie hierfür keine E-Mail-Ankündigung von unserem Zulieferer. ' +
      'Sie werden von dem Spediteur telefonisch für eine Terminabsprache kontaktiert.',
    close: 'Schliessen',
  },
  hint:
    'Bei Bestellung auf Vorauskasse und Geräte schwerer als 30kg oder grösser als 50 Zoll ' +
    'sind Lieferverzögerungen möglich.',
};

const checkoutSuccess = {
  title: 'Bestellbestätigung',
  header: 'Herzlichen Dank für Ihre Bestellung',
  orderNumber: 'Ihre Bestellnummer lautet: ',
  nextStepsAdvancePay:
    'Sie erhalten eine Bestelleingangsbestätigung per E-Mail an <b>{email}</b> mit Ihren persönlichen Zahlungsinformationen.',
  nextSteps: 'Sie erhalten eine Bestelleingangsbestätigung per E-Mail an <b>{email}</b>.',
  continueShopping: 'Weiter einkaufen',
  printOrder: 'Bestellung drucken',
  backToHomepage: 'Zurück zur Startseite',
  guestSubHeader: 'Nächstes Mal noch schneller einkaufen',
  guestAdvice: 'Geben Sie jetzt nur noch ein Passwort ein und profitieren Sie von einem kostenlosen Kundenkonto.',
  createAccount: 'Jetzt anmelden',
  fastOrder: 'Meine Filiale',
  fastOrderDescription:
    'Lieblingsfiliale hinterlegen und immer über aktuelle Artikelverfügbarkeit, ' +
    'Öffnungszeiten und Events informiert sein.',
  manageOrder: 'Noch einfacher und schneller einkaufen',
  manageOrderDescription:
    'Einfacher denn je - Angaben im Kundenkonto einmalig hinterlegen und ' +
    'künftige Bestellungen ohne Aufwand auslösen.',
  promotion: 'Ihre Vorteile',
  promotionDescription:
    'Seien Sie immer als Erstes über die neusten Trends informiert und profitieren ' + 'Sie von exklusiven Rabatten',
};

const paymentFailed = {
  header: 'Zahlungsvorgang fehlgeschlagen',
  content: {
    twint: {
      description:
        'Ihre Zahlung wurde abgelehnt.\n\n' +
        'Versuchen Sie, Ihren Einkauf mit einer anderen Zahlungsart abzuschliessen.\n\n' +
        'Bei Fragen zu TWINT, kontaktieren Sie den [TWINT Support Ihrer Bank]({PAYMENT_LINK}).',
      paymentLink: 'https://www.twint.ch/privatkunden/support/kontakte/',
    },
    monthlyinvoice_pcf: {
      description:
        'Ihre Zahlung wurde abgelehnt.\n\n' +
        'Versuchen Sie, Ihren Einkauf mit einer anderen Zahlungsart abzuschliessen.\n\n' +
        'Bei Fragen zur Bonitätsprüfung, kontaktieren Sie den Rechnungssteller via E-Mail an [info@paycard.ch]({PAYMENT_LINK})',
      paymentLink: 'mailto:info@paycard.ch',
    },
    visa: {
      description:
        'Ihre Zahlung wurde abgelehnt.\n\n' +
        'Versuchen Sie, Ihren Einkauf mit einer anderen Zahlungsart abzuschliessen.\n\n' +
        'Bei Fragen zu Ihrer Kreditkarte, kontaktieren Sie den Kartenanbieter.',
      paymentLink: '',
    },
    mastercard: {
      description:
        'Ihre Zahlung wurde abgelehnt.\n\n' +
        'Versuchen Sie, Ihren Einkauf mit einer anderen Zahlungsart abzuschliessen.\n\n' +
        'Bei Fragen zu Ihrer Kreditkarte, kontaktieren Sie den Kartenanbieter.',
      paymentLink: '',
    },
    americanexpress: {
      description:
        'Ihre Zahlung wurde abgelehnt.\n\n' +
        'Versuchen Sie, Ihren Einkauf mit einer anderen Zahlungsart abzuschliessen.\n\n' +
        'Bei Fragen zu Ihrer Kreditkarte, kontaktieren Sie den Kartenanbieter.',
      paymentLink: '',
    },
    postcard: {
      description:
        'Ihre Zahlung wurde abgelehnt.\n\n' +
        'Versuchen Sie, Ihren Einkauf mit einer anderen Zahlungsart abzuschliessen.\n\n' +
        'Bei Fragen zu Postcard, kontaktieren Sie den [Support von PostFinance]({PAYMENT_LINK}).',
      paymentLink: 'https://www.postfinance.ch/de/support/konten-karten.html',
    },
    postfinance: {
      description:
        'Ihre Zahlung wurde abgelehnt.\n\n' +
        'Versuchen Sie, Ihren Einkauf mit einer anderen Zahlungsart abzuschliessen.\n\n' +
        'Bei Fragen zu PostFinance E-Finance, kontaktieren Sie den [Support von PostFinance]({PAYMENT_LINK}).',
      paymentLink: 'https://www.postfinance.ch/de/support/e-finance-login.html',
    },
    paypal: {
      description:
        'Ihre Zahlung wurde abgelehnt.\n\n' +
        'Versuchen Sie, Ihren Einkauf mit einer anderen Zahlungsart abzuschliessen.\n\n' +
        'Bei Fragen zu Paypal, kontaktieren Sie den [Support von Paypal]({PAYMENT_LINK}).',
      paymentLink: 'https://www.paypal.com/ch/smarthelp/contact-us',
    },
    paycard: {
      description:
        'Ihre Zahlung wurde abgelehnt.\n\n' +
        'Versuchen Sie, Ihren Einkauf mit einer anderen Zahlungsart abzuschliessen.\n\n' +
        'Bei Fragen zur Bonitätsprüfung, kontaktieren Sie den Rechnungssteller via E-Mail an [info@paycard.ch]({PAYMENT_LINK})',
      paymentLink: 'mailto:info@paycard.ch',
    },
    samsung_pay: {
      description:
        'Ihre Zahlung wurde abgelehnt.\n\n' +
        'Versuchen Sie, Ihren Einkauf mit einer anderen Zahlungsart abzuschliessen.\n\n' +
        'Bei Fragen zu Ihrer hinterlegten Kredit- oder Debitkarte, kontaktieren Sie den Kartenanbieter.',
      paymentLink: '',
    },
    pointspay: {
      description:
        'Ihre Zahlung wurde abgelehnt.\n\n' +
        'Versuchen Sie, Ihren Einkauf mit einer anderen Zahlungsart abzuschliessen.\n\n' +
        'Bei Fragen zu Pointspay, kontaktieren Sie den [Kundensupport von Pointspay]({PAYMENT_LINK}).',
      paymentLink: 'https://pointspay.com/contactus',
    },
    diners_club: {
      description:
        'Ihre Zahlung wurde abgelehnt.\n\n' +
        'Versuchen Sie, Ihren Einkauf mit einer anderen Zahlungsart abzuschliessen.\n\n' +
        'Bei Fragen zu Ihrer Kreditkarte, kontaktieren Sie den Kartenanbieter.',
      paymentLink: '',
    },
    notValid: {
      description: 'Keine Beschreibung vorhanden',
      paymentLink: '',
    },
  },
  button: 'Zurück zur Bestellung',
};

const paymentModal = {};

const countries = {};

const guestLogin = {
  headline: 'Ohne Anmeldung einkaufen',
  button: {
    text: 'Weiter zur Bestellung',
  },
  content: {
    text1: 'Sie möchten Ihre Bestellung ohne Anmeldung fortsetzen? Kein Problem.',
    text2:
      'Nach Abschluss der Bestellung haben Sie die Möglichkeit, Ihre Daten in einem Kundenkonto zu speichern, ' +
      'um künftig noch schneller und bequemer bestellen zu können.',
  },
};

const login = {
  headline: 'Mit Kundenkonto anmelden',
  headlineShort: 'Anmelden',
  email: {
    label: 'E-Mail-Adresse',
  },
  password: {
    label: 'Passwort',
  },
  button: {
    text: 'Anmelden',
  },
  checkbox: {
    label: 'Angemeldet bleiben?',
  },
  infoicon: {
    text:
      'Ihre Daten bleiben gespeichert und Sie sparen sich beim nächsten Besuch die Anmeldung. ' +
      'Sie können sich jederzeit wieder abmelden. Wählen Sie diese Funktion nicht, ' +
      'wenn Sie einen öffentlichen Computer verwenden oder diesen mit mehreren Personen teilen.',
  },
  checkoutHeader: 'Jetzt bequem bestellen',
  accountHeader: 'Jetzt bequem bestellen',
  errorMessages: {
    badCredentials:
      'E-Mail-Adresse oder Passwort stimmen nicht mit den bei uns hinterlegten Daten überein. ' +
      'Bitte überprüfen Sie Ihre Eingaben und versuchen Sie es erneut.',
    networkError: 'Überprüfen sie Ihre Internetverbindung und versuchen Sie es später erneut.',
    supercardMustBeUsed:
      'Ihr Konto ist mit einer Supercard ID verknüpft. Bitte verwenden Sie Ihre Supercard ID um sich einzuloggen.',
  },
  dividerText: 'oder',
  loginExplanation: 'Alles über die Supercard bei Interdiscount.ch',
  supercardLogin: 'Supercard ID Login',
  benefitsText: 'Ihre Vorteile',
  registration: {
    headline: 'Ich habe noch kein Konto',
    headlineShort: 'Registrieren',
    button: {
      text: 'Jetzt registrieren',
    },
    content: {
      text1: 'Sie möchten ein Kundenkonto eröffnen und Kundenvorteile nutzen?',
      text2:
        'Legen Sie jetzt in wenigen Schritten ein Konto an und profitieren Sie bereits bei Ihrer ersten ' +
        'Bestellung von exklusiven Vorteilen.',
    },
  },
};

const myAccount = {
  header: 'Mein Konto',
  logout: 'Abmelden',
  account: 'Konto',
  welcomeTextHeader: 'Hallo ',
  welcomeTextLogin: 'schön, Sie hier begrüssen zu dürfen.',
  welcomeTextRegister: 'wir gratulieren zu Ihrem neuen Benutzerkonto mit vielen exklusiven Vorteilen.',
  welcomeTextNewsletter:
    'Wir haben Ihnen eine E-Mail mit einem **Bestätigungslink** geschickt. ' +
    'Um die Anmeldung zu unserem Newsletter abzuschliessen, klicken Sie bitte auf den Bestätigungslink.\n\n' +
    'Sollte die E-Mail nicht in Ihrem Postfach erscheinen, überprüfen Sie bitte Ihren Spam-Ordner.',
  welcomeTextVoucher:
    'Als Dankeschön für Ihre Registrierung erhalten Sie von uns einen ' + '| Rabattcode | im Wert von {price}',
  welcomeTextFooter:
    'In Ihrem Konto können Sie Ihre Bestellungen einsehen, Ihre Benutzerdaten verwalten und ' +
    'Ihre Adressen pflegen.',
  myOrders: {
    orderDate: 'Bestelldatum',
    orderNumber: 'Bestellnummer',
    orderSum: 'Summe',
    orderState: 'Bestellstatus',
    noOrders: 'Sie haben bisher noch keine Bestellungen getätigt.',
    noOrdersHint:
      'Wenn Sie in den letzten 5 Jahren Bestellungen in unserem alten Shop getätigt haben,' +
      ' werden diese in den nächsten Tagen importiert und hier angezeigt',
    showAll: 'Alle Bestellungen anzeigen',
    overview: 'Bestellübersicht Interdiscount',
    overviewArchive: 'Bestellübersicht microspot.ch',
    overviewText:
      'Laden Sie einfach Bestellbelege, Garantien und Lieferscheine von den Detailseiten herunter.\n' +
      'Vielen Dank für Ihren Einkauf!',
    switchButton: 'Zur Bestellübersicht von microspot.ch',
    switchButtonArchive: 'Zur Bestellübersicht von Interdiscount',
  },
  loginAndRegistration: 'Anmelden / Registrieren',
};

const myAccountOrders = {
  header: 'Meine Bestellungen',
};

const myAccountDeleteAccount = {
  header: 'Benutzerkonto löschen',
  content: 'Ich möchte gerne mein Benutzerkonto von interdiscount.ch löschen.',
  modal: {
    title: 'Benutzerkonto löschen',
    confirmation: 'Sind Sie sicher, dass Sie Ihr Benutzerkonto löschen möchten?',
    yes: 'Ja',
    no: 'Nein',
  },
  alreadyRequested: 'Sie haben eine Löschanfrage gestartet. Wir sind daran, diese zu bearbeiten.',
  button: 'Löschen',
};

const deleteAccountConfirmationPage = {
  title: 'Wir hoffen, Sie bald wieder in unserem Shop begrüssen zu dürfen.',
  content:
    'Besten Dank für Ihre Anfrage. Sobald Ihr Benutzerkonto gelöscht wurde, senden wir Ihnen eine Bestätigung per E-Mail.',
};

const myAccountOrderDetails = {
  header: 'Bestellung Nr.',
  headerOrderDetails: 'Bestelldetails',
  soldBy: 'Verkauft durch:',
  orderInfo: {
    orderDate: 'Bestelldatum',
    orderNumber: 'Bestellnummer',
    orderSum: 'Summe',
    orderState: 'Bestellstatus',
    orderDelivery: {
      mode: 'Versandart',
      address: 'Lieferadresse',
    },
    orderPayment: {
      mode: 'Zahlungsart',
      address: 'Rechnungsadresse',
    },
    orderReference: 'Referenz',
    orderTracking: 'Tracking-Nr.',
    sameAddresses: 'entspricht Rechnungsadresse',
  },
  invoiceDocument: 'Beleg_Nr',
  downloadInvoiceDocument: 'Beleg herunterladen',
  pdfCreationRequest: 'Ihr Dokument wird erstellt. Bitte haben Sie noch etwas Geduld!',
  backToOrders: 'Zurück zu meinen Bestellungen',
};

const myAccountUserData = {
  header: 'Meine Benutzerdaten',
};

const myAccountAddresses = {
  header: 'Meine Adressen',
  headerCreate: 'Adresse anlegen',
  headerEdit: 'Adresse bearbeiten',
  buttonAdd: 'Neue Adresse anlegen',
  headerTextAddresses: 'Sie möchten eine Adresse löschen oder ändern? Gehen Sie dazu auf das X bzw. das Stift-Symbol.',
  headerTextNoAddress: 'Fügen Sie hier neue Adressen hinzu.',
  headerText2: 'Übrigens:',
  headerText3:
    'Welche Adresse als Rechnungs- bzw. Lieferadresse verwendet wird, legen Sie ' +
    'jeweils beim Abschluss einer Bestellung fest.',
  headerTextB2BInvoiceGroup:
    'Die Adresse, mit der Sie sich registriert haben, wird als Rechnungsadresse hinterlegt und kann ' +
    'nur über den Kundenservice geändert werden.',
  headerInvoiceAddress: 'Rechnungsadresse',
  manageAddresses: 'Adressen verwalten',
  invoiceAddress: 'Rechnungsadresse',
  deliveryAddress: 'Lieferadresse',
  sameAsInvoice: 'wie Rechnungsadresse',
};

const myAccountRatings = {
  header: 'Meine Bewertungen',
  rateOrders: 'Einkäufe bewerten',
  myRatedOrders: 'Meine bewerteten Produkte',
  showAll: 'Alle anzeigen',
  backToRatings: 'Zurück zu meine Bewertungen',
  delete: {
    title: 'Möchten Sie diese Bewertung tatsächlich löschen?',
    okLabel: 'Ja, bitte löschen',
    cancelLabel: 'Abbrechen',
  },
  list: {
    bought: 'Gekauft am',
    linkRate: 'Bewerten',
    linkEdit: 'Bearbeiten',
    linkDelete: 'Löschen',
  },
  ratedEmptyMsg: 'Sie haben bisher noch keine Produkte bewertet.',
  notRatedEmptyMsg: 'Sie haben aktuell keine unbewerteten Produkte.',
  rejectedInfo: 'Einer oder mehrere Ihrer Kommentare wurden abgelehnt.',
  recommended: 'Empfohlen',
  notRecommended: 'Nicht empfohlen',
};

const myAccountUserDataSection = {
  buttonSave: 'Speichern',
  saveMessage: {
    SUCCESS: 'Ihre Änderungen wurden erfolgreich gespeichert.',
    REQUEST: 'Ihre Änderungen werden gespeichert.',
    FAILURE: 'Das Speichern war leider nicht erfolgreich. Bitte versuchen Sie es später erneut.',
    SameId: 'Zu dieser E-Mail-Adresse existiert bereits ein Konto.',
    SameAsUid: 'Diese E-Mail-Adresse ist aktuell mit Ihrem Konto verknüpft.',
    Invalid: 'Das Passwort ist nicht gültig. ',
  },
};

const myAccountPersonalData = {
  header: 'Persönliche Daten',
  sc: {
    linked: {
      intro: 'Ihr Konto ist mit der **Supercard ID verknüpft**',
      subtext: 'Persönliche Daten können Sie via %sSupercard.ch%s bearbeiten',
      subtextHref: 'https://www.supercard.ch/de/mein-konto.html',
      cta: 'Supercard ID entkoppeln',
      editLink: 'Bearbeiten via %sSupercard.ch%s',
      editLinkHref: 'https://www.supercard.ch/de/mein-konto.html',
      link: 'Jetzt mit Supercard ID anmelden',
    },
    notLinked: {
      intro: 'Verknüpfen Sie ihr Konto mit der **Supercard ID**',
      subtext: 'Alles über die Supercard bei interdiscount.ch',
      cta: 'Supercard Konto verknüpfen',
    },
  },
};

const myAccountAdditionalData = {
  header: 'Weitere Angaben',
};

const myAccountPassword = {
  header: 'Passwort ändern',
};

const myAccountLogin = {
  header: 'E-Mail-Adresse ändern',
  currentEmail: 'Bestehende E-Mail-Adresse',
  newEmail: 'Neue E-Mail-Adresse',
  info:
    'Aus Sicherheitsgründen müssen Sie, die Änderung der E-Mail-Adresse mit Ihrem **aktuellen Passwort** bestätigen.' +
    '\n\n' +
    'Nach der Änderung werden Sie **automatisch abgemeldet**. ' +
    'Sie müssen anschliessend die **neue E-Mail-Adresse bestätigen**. ' +
    'Dazu senden wir Ihnen eine E-Mail mit dem **Bestätigungslink** an Ihre neue E-Mail-Adresse.',
  b2bInfo:
    'Aus Sicherheitsgründen müssen Sie, die Änderung der E-Mail-Adresse per Mail via <contact@b2b.interdiscount.ch> ' +
    'beantragen.',
};

const myAccountNewsletter = {
  header: 'Unser Newsletter',
  subscribedText: 'Sie sind für unseren Newsletter angemeldet und profitieren von allen Vorteilen.',
  unsubscribedText:
    'Unser Newsletter informiert Sie immer über aktuelle Angebote, attraktive Rabattaktionen und ' +
    'die neuesten Trends. Profitieren Sie immer von den besten Angeboten bei Interdiscount.',
  subscribe: 'Jetzt anmelden',
  unsubscribe: 'Abmelden',
};

const myAccountNotifications = {
  header: 'Benachrichtigungen',
  headerOld: 'Meine Benachrichtigungen',
  generalNotifications: 'Allgemeine Benachrichtigungen',
  personalizedNotifications: 'Auf mich abgestimmte Benachrichtigungen',
  subHead: 'Definieren Sie hier, welche Werbe-Benachrichtigungen Sie erhalten möchten:',
  subHeadOld: 'Definieren Sie hier, welche Benachrichtigungen Sie erhalten möchten:',
  newsletter: {
    headline: 'Newsletter',
    text: 'Wir informieren Sie in unserem wöchentlichen Newsletter über laufende Aktionen, Neuheiten und Gewinnspiele.',
  },
  personalized: {
    headline: 'Individualisierte Werbung',
    text:
      'Wir senden Ihnen individualisierte Marketing-Kommunikation mit speziell auf Sie abgestimmten Informationen, Angeboten und Gutscheinen zu.',
    moreInfo: {
      preAGB: `Bei Deaktivierung widersprechen Sie der Verwendung von Auswertungen und
        Profildaten, welche aus Ihren Personendaten im Zusammenhang mit dem vorliegenden
        Kundenkonto (insbesondere Einkaufs- und Transaktionsdaten) gewonnen wurden,
        innerhalb der Coop-Gruppe für die Zukunft. Wir halten aber das Profil weiterhin
        zu Ihrer Verfügung, falls Sie die Verwendung wieder aktivieren möchten.
        Weitere Informationen finden Sie auch in unseren `,
      agb: 'AGB',
      connect: ' oder ',
      privacy: 'Datenschutzerklärung',
      end: '.',
    },
  },
};

const formRegistration = {
  headline1: 'Neu bei Interdiscount?',
  headline2: 'Jetzt einfach registrieren.',
  titleEmailSection: 'E-Mail-Adresse und Passwort',
  titleAddressSection: 'Name und Anschrift',
  checkboxNewsletter: 'Ja, ich möchte den Interdiscount Newsletter mit exklusiven Angeboten erhalten.',
  checkboxAGB: {
    pre: 'Mit Erstellen des Kunden-Kontos akzeptieren Sie unsere ',
    agb: 'AGB',
    connect: ' und die Auswertung und Verwendung Ihrer Daten innerhalb der Coop Gruppe gemäss ',
    number: 'Ziff. 9 der AGB',
    anchorForDSG: '9-datenbearbeitung-und-datenschutz',
  },
  buttonCancel: 'Abbrechen',
  buttonOK: 'Jetzt registrieren',
  promoText: 'Registrieren Sie sich jetzt und profitieren Sie bereits beim ersten Einkauf.',
};

const downloadBox = {
  filename: 'Firmenrechnung',
  registration: {
    title: 'Hinweis zur Adresse:',
    body:
      'Die Adresse, mit der Sie sich registrieren, wird als Rechnungsadresse hinterlegt und kann nur über den ' +
      'Kundenservice geändert werden.',
    subTitle: 'Sie möchten per Firmenrechnung zahlen?',
  },
  checkout: {
    title: 'Sie möchten künftig per Firmenrechnung zahlen?',
    body: 'Senden Sie uns einen Antrag und wir schalten Sie gerne für diese Zahlungsart frei.',
  },
};

const shoppingCart = {
  quantityReduced:
    'Dieser Artikel kann nicht in der von Ihnen gewünschten Menge bestellt werden. Wir werden die Stückzahl für Sie an der Kasse reduzieren.',
  changeDeliveryDate: 'Lieferterminänderung. Das neue Lieferdatum wird an der Kasse angezeigt.',
  changeQuantityAndDeliveryDate:
    'Dieser Artikel kann nicht in der von Ihnen gewünschten Menge bestellt werden. Wir werden die Stückzahl für Sie an der Kasse reduzieren.' +
    ' Das neue Lieferdatum wird an der Kasse angezeigt.',
};

const pickupStore = {
  type: {
    STORE_01: 'Interdiscount',
    XXL_02: 'Interdiscount XXL',
    MEGASTORE_03: 'Interdiscount XXL',
    PICKUPTT_06: 'Pick-up T&T',
  },
  openingHours: 'Öffnungszeiten',
  openingHoursSpecial: 'Sonderöffnungszeiten',
  closed: 'Geschlossen',
  select: 'Filiale auswählen',
  change: 'Filiale ändern',
  away: 'entfernt',
  openToday: 'Heute geöffnet',
  details: 'Details',
  pickupHere: 'Hier abholen',
  storeManager: 'Filialleitung',
  infoBox: {
    text1: 'Sie haben Fragen rund um Beratung, Abholung oder Reklamationen? Hier hilft unser Kundenservice.',
    phone: '+41 31 764 44 44',
    openingDays: 'Montag - Freitag',
    openingHours: '08.00 - 18.00 Uhr',
  },
  goToMap: 'Zur Kartenansicht wechseln',
};

const storeFinder = {
  search: {
    headline: 'Interdiscount Filialen in Ihrer Nähe',
    headlineCheckout: 'Wo kann ich die Bestellung abholen?',
    button: 'Suchen',
    placeholder: 'Strasse, PLZ oder Ort eingeben',
    placeholderShort: 'PLZ oder Ort',
    geo: {
      text: 'Meine Position ermitteln',
      error:
        'Ihr Standort kann nicht ermittelt werden. Bitte überprüfen Sie die Standorteinstellungen Ihres Browsers' +
        ' bzw. Endgeräts oder geben Sie einfach den gesuchten Ort in das Suchfeld ein.',
    },
    opening: 'Aktuell geöffnet',
    showroom: 'Interdiscount XXL',
  },
  filter: {
    more: 'Weitere Filter anzeigen',
    formatFilter: 'Coop Formate',
    serviceFilter: 'Verfügbare Services',
    reset: 'Zurücksetzen',
    speedDeliveryPossible: 'Speedabholung möglich',
    paymentOnPickupPossible: 'Zahlung bei Abholung möglich',
  },
  bulkyGoodNotice:
    'In Ihrem Warenkorb befindet sich mindestens **1 Artikel** mit einem Gewicht **über 30kg**. ' +
    'Daher ist die Lieferung nur in ausgewählten Showrooms möglich.',
  cartInvalidToken:
    'Ein oder mehrere Produkte sind nicht verfügbar, bitte entfernen Sie diese aus Ihrem Warenkorb, um fortzufahren',
  voucherInvalidToken: `Der Gutschein ist ungültig. Bitte aktualisieren Sie die Bestellung`,
  collapsible: {
    title: 'Was ist bei der Abholung in der Filiale zu beachten?',
    content:
      'Lassen Sie Ihre Bestellung bequem in die Filiale in Ihrer Nähe liefern. ' +
      'Die Lieferung in die Filiale ist selbstverständlich kostenlos. Profitieren Sie von ' +
      'der Beratung und dem Service vor Ort. Bei Bestellungen mit Abholung in der Filiale ' +
      'steht Ihnen zusätzlich die Möglichkeit der Bezahlung bei Abholung zur Verfügung. ' +
      'Beachten Sie aber, dass die Zahlungsart Monatsrechnung entfällt.',
  },
  results: {
    title: 'Standorte in der Nähe von',
    messages: {
      initial: {
        title: 'Bitte geben Sie einen Suchbegriff ein.',
        text:
          'Suchen Sie einfach nach einem Ort, einer PLZ oder Strasse in der Schweiz. ' +
          'Wir ermitteln den nächst gelegenen Standort von interdiscount.ch für Sie.',
      },
      emptyWithFilter: {
        title: 'Zu Ihrer Suchanfrage konnte kein Standort gefunden werden.',
        text: 'Bitte probieren Sie es mit einer veränderten Eingabe oder heben Sie den gewählten Filter auf.',
      },
      empty: {
        title: 'Zu Ihrer Suchanfrage konnte kein Standort gefunden werden.',
        text:
          'Bitte überprüfen Sie Ihre Eingabe. Suchen Sie einfach nach einem Ort, einer PLZ oder Strasse in der ' +
          'Schweiz. Wir ermitteln den nächst gelegenen Standort von Interdiscount für Sie.',
      },
      googleError: {
        title: 'Ihr Standort kann nicht ermittelt werden.',
        text:
          'Bitte überprüfen Sie die Standorteinstellungen Ihres Browsers bzw. Endgeräts oder geben Sie einfach ' +
          'den gesuchten Ort in das Suchfeld ein.',
      },
    },
    loadMore: 'Weitere Filialen anzeigen',
  },
  box: {
    headline: 'Filialsuche',
    customer: {
      open: 'Jetzt geöffnet',
      closed: 'Jetzt geschlossen',
      visitUs: 'Kommen Sie vorbei:',
    },
    guest: {
      advise: 'Wir beraten Sie gerne persönlich.',
      visitUs: 'Besuchen Sie eine von rund **170 Filialen**.',
      buttonText: 'Filiale suchen',
    },
  },
  myStore: {
    mySavedStore: 'Als meine Filiale gespeichert',
    save: 'Als meine Filiale speichern',
  },
  modalSetStore: {
    title: 'Möchten Sie diese Filiale als meine Filiale festlegen?',
    save: 'Als meine Filiale speichern',
    cancel: 'Abbrechen',
  },
  quantity: {
    none: 'keine verfügbar',
    moreThan: 'mehr als 20 Stück verfügbar',
    available: 'Stück verfügbar',
  },
};

const breadcrumb = {
  home: 'Home',
  homepage: 'Startseite',
  search: 'Suche',
  pageNotFound: 'Seite nicht gefunden',
};

const pageTitles = {
  account: 'Mein Konto',
  accountLogin: 'Anmelden',
  accountRegistration: 'Registration',
  myOrders: 'Meine Bestellungen',
  orderDetails: 'Bestelldetails',
  myAddresses: 'Meine Adressen',
  myAccount: 'Meine Benutzerdaten',
  myRatings: 'Meine Bewertungen',
  cart: 'Warenkorb',
  notFound: 'Seite nicht gefunden',
  checkoutLogin: 'Anmelden zur Bestellung',
  checkout: 'Bestellung',
  orderConfirmation: 'Vielen Dank',
  homePage: 'Der Elektronik-Shop Nr. 1',
  search: 'Suchergebnisse',
  newsletterOptout: 'Newsletter abmelden',
  newsletterOptoutPersonalized: 'Personalisierte E-Mails abmelden',
  newsletterOptin: 'Newsletter anmelden',
  myNotifications: 'Meine Benachrichtigungen',
  brandPage: '{BRAND} - günstig online kaufen',
  storeFinder: 'Standorte & Öffnungszeiten',
};

const passwordReset = {
  SUCCESS:
    'Falls ein Benutzerkonto mit dieser E-Mail-Adresse existiert, wurde eine E-Mail mit einem Link zur Passwortänderung gesendet.',
  FAILURE: 'Der Vorgang ist leider fehlgeschlagen.',
  forgotPassword: 'Passwort vergessen?',
  resetPassword: 'Passwort zurücksetzen',
  explanatoryText:
    'Bitte geben Sie die zu Ihrem interdiscount.ch-Konto gehörende E-Mail-Adresse ein. ' +
    'Sie erhalten von uns dann umgehend eine Nachricht mit einem Link zur Passwortänderung.',
  send: 'Senden',
  email: 'E-Mail-Adresse',
  header: 'Neues Passwort vergeben',
  messageSet:
    'Bitte geben Sie Ihr neues Passwort ein, mit dem Sie sich zukünftig auf interdiscount.ch ' + 'anmelden möchten.',
  messageFailureSubmit:
    'Ihr Passwort konnte nicht geändert werden, bitte versuchen Sie es erneut. ' +
    'Sollte der Fehler weiterhin auftauchen, wenden Sie sich an den Support.',
  buttonSetPassword: 'Passwort vergeben',
  resetFailureSameAsEmail: 'E-Mail-Adresse und Passwort dürfen nicht übereinstimmen.',
};

const voucher = {
  voucherHeader: 'Preisnachlass',
  voucherTitle: 'Gutschein / Rabatt einlösen',
  voucherLabel: 'Gutschein- / Rabattcode',
  voucherDescription:
    'Bitte beachten Sie, dass immer nur ein Rabatt pro Bestellung eingelöst werden kann. ' +
    'Interdiscount ermittelt automatisch den Rabatt, der zu Ihrem Vorteil ist. \n' +
    'Es kann dadurch sein, dass bestehende Rabatte auf den Artikel zugunsten des hier eingegeben Gutscheins ersetzt ' +
    'werden. Ist die Ersparnis durch den Gutschein kleiner als der Artikel-Rabatt, wird der Gutschein verworfen.',
  coopGiftCardTitle: 'Geschenkkarte einlösen',
  coopGiftCardDescription: 'interdiscount.ch Geschenkkarte oder Coop Geschenkkarte',
  coopGiftCardLabel: 'Geschenkkarten-Nummer',
  coopGiftCardPinLabel: 'PIN',
  applyButton: 'Einlösen',
  removeButton: 'Entfernen',
  voucher: {
    Valid: 'Ihr Gutschein / Rabatt wurde erfolgreich eingelöst.',
    Missing: 'Bitte geben Sie einen Gutschein- bzw. Rabattcode ein.',
    Invalid: 'Der eingegebene Gutschein- bzw. Rabattcode ist ungültig. Bitte überprüfen Sie Ihre Eingabe.',
    PriceCannotBeLowered: 'Der eingegebene Gutschein- bzw. Rabattcode ist ungültig.',
  },
  giftcard: {
    Valid: 'Ihre Geschenkkarte wurde erfolgreich eingelöst.',
    Missing: 'Bitte geben Sie eine Geschenkkarten-Nummer ein',
    Invalid: 'Ihre Angaben sind nicht korrekt. Bitte überprüfen Sie Ihre Eingabe.',
    InvalidNumber: 'Es dürfen nur Zahlen eingegeben werden.',
    GiftcardAlreadyUsed: 'Auf der Geschenkkarte ist leider kein Guthaben mehr verfügbar.',
    PinMissing: 'Bitte geben Sie die PIN-Nummer ein.',
    disallowed: 'Sie können Ihre Geschenkkarte beim Bezahlen in der Filiale einlösen.',
    disallowedRemoved:
      'Da die Zahlungsart auf “Bezahlung bei Abholung” geändert wurde, haben wir Ihre Geschenkkarte entfernt. Sie können Ihre Geschenkkarte beim Bezahlen in der Filiale einlösen.',
  },
};

const service = {
  contact: {
    headline: 'Kontakt',
    subline: 'Wie möchten Sie mit uns kommunizieren?',
    types: {
      phone: 'Telefon',
      email: 'E-Mail',
    },
    fieldsetHeadline: 'Persönliche Daten',
    fieldsetHeadlineAddress: 'Neue Adresse',
    additionalFieldsetHeadlineSuperpoints: 'Angaben zum Einkauf',
    additionalFieldsetHeadlineFeedback: 'Angaben zum Artikel',
    additionalFieldsetHeadlinePriceConfirmation: 'Angaben zum Artikel',
    additionalFieldsetHeadlineInvoice: 'Angaben zum Vertrag / zur Rechnung',
    additionalFieldsetHeadlineOnlineOrder: 'Angaben zur Bestellung',
    additionalFieldsetHeadlineAddress: 'Alte Adresse',
    subheadline: {},
    additionalTextTitle: 'Bitte beachten Sie:',
    additionalTextSuperpointsContent:
      'Interdiscount schreibt Superpunkte gut, sofern der Einkaufsbetrag pro ' +
      'Quittung (exklusiv Dienstleistungen, VRG und Suisa), mindestens CHF 500,- beträgt.',
    additionalTextAddressContent:
      'Wenn Sie ein Interdiscount Kundenkonto haben, können Sie Ihre Adressen auch ' +
      'bequem im Bereich [a]Mein Konto[a] verwalten',
    copyWanted: 'Ich möchte eine Kopie der Kontaktanfrage an meine angegebene E-Mail-Adresse geschickt bekommen.',
    send: 'Senden',
    cancel: 'Abbrechen',
    sentTitle: 'Ihre Nachricht wurde versandt.',
    sentBody:
      'Vielen Dank für Ihre Nachricht. Unsere Kundendienst-Mitarbeiter werden Ihr Anliegen ' +
      'schnellstmöglich bearbeiten und sich bei Ihnen melden.',
    recall: {
      label: 'Rückruf',
      phoneLabel: 'Telefon',
      phoneNumber: 'Telefon +41 31 764 44 44',
      openingTimes: 'Montag – Freitag, 8.00 – 18.00 Uhr',
      recallDate: 'Rückruftermin',
      bookRecallAppointment: 'Rückruftermin jetzt buchen',
      serviceOceanLink:
        'https://online.serviceocean.com/de/booking/form/624af900-e9bf-43c9-877d-b35a962896d6?requestid=1283#2',
    },
  },
  cmsDeliveryLink: 'service/versand-und-lieferung',
};

const accountNotification = {
  PASSWORD_RESET_SUCCESS: {
    headerDone: 'Neues Passwort hinterlegt',
    messageDone: 'Ihr Passwort wurde erfolgreich gespeichert und Sie haben jetzt wieder Zugriff auf Ihr Kundenkonto. ',
    messageDone2: 'Um fortzufahren, melden Sie sich bitte mit Ihrem neuen Passwort an.',
  },
  PASSWORD_RESET_FAILURE: {
    headerFailure: 'Passwort ändern nicht möglich',
    messageFailure:
      'Der von Ihnen aufgerufene Link ist abgelaufen. ' +
      'Um fortzufahren, fordern Sie bitte einen neuen Link zur Passwort-Änderung an.',
  },
  CHANGE_LOGIN_SUCCESS: {
    headerDone: 'E-Mail-Adresse geändert',
    messageDone: 'Ihre E-Mail-Adresse wurde erfolgreich geändert.',
    messageDone2: 'Bitte melden Sie sich ab sofort mit der neuen E-Mail-Adresse an.',
  },
};

const internalErrorPage = {
  excuseUs: 'Wir bitten um Entschuldigung!',
  apologyPart1:
    'Wegen eines technischen Fehlers ist interdiscount.ch zurzeit leider nicht erreichbar. ' +
    'Wir arbeiten mit Hochdruck an einer Lösung.',
  apologyPart2: 'Bitte versuchen Sie es später erneut. Besten Dank für Ihr Verständnis.',
  supportInfo: 'Gerne stehen wir Ihnen in der Zwischenzeit telefonisch oder per E-Mail zur Verfügung.',
  phoneNumber: '+41 31 764 44 44',
  telephoneHref: '+41317644444',
  phoneInfo: '(MO - FR: 08.00 - 18.00 UHR)',
  email: 'online@interdiscount.ch ',
};

const ageVerification = {
  title: 'Bitte verifizieren Sie Ihr Alter',
  subtitle: 'Wählen Sie zuerst eine Art der Verifizierung',
  verifyNow: 'Jetzt verifizieren',
  fillAllRequiredFields: 'Bitte vervollständigen Sie Ihre Eingabe.',
  verifyError: 'Die eingegeben Daten sind ungültig. Bitte korrigieren Sie Ihre Eingabe.',
  cardTypes: {
    SWISSID: 'CH Identitätskarte',
    SWISSPASSPORT: 'Schweizer Pass',
    DRIVINGLICENSE: 'Führerausweis',
    MANUALLY: 'Ausweiskopie einschicken',
  },
  sendCopyInfo: {
    infoPart1: {
      plsSend: 'Bitte schicken Sie eine ',
      copy: 'Kopie Ihres Ausweises ',
      together: 'zusammen mit der ',
      orderNumber: 'Auftragsnummer ',
      to: 'an ',
      email: 'online@interdiscount.ch',
    },
    infoPart2:
      'Die Bestellung wird nach Eingang Ihres Auftrags bei uns weiterverarbeitet, ' +
      'sobald wir Ihr Alter geprüft haben.',
  },
  sendCopyAttention: 'Bitte beachten Sie: ',
  sendCopyVerifyInfo:
    'Die Bestellung wird nach Eingang Ihres Auftrags bei uns ' +
    'weiterverarbeitet, sobald wir Ihr Alter geprüft haben.',
  verifyWithSendCopy: 'Ja, mache ich',
  Invalid:
    'Bitte verifizieren Sie Ihr Alter über einen Identitätsnachweis ' +
    'oder entfernen Sie die Artikel aus dem Warenkorb.',
  notVerifiedForAllProducts:
    'Ihr Bestellung enthält Artikel, für die eine Altersbeschränkung besteht. ' +
    'Bitte entfernen Sie diese Artikel, um den Kauf fortzusetzen.',
  verified: 'Ihr Alter ist verifiziert.',
  verifiedSendCopy: 'Ihr Alter wird verifiziert.',
};

const pageNotFound = {
  title: 'Uups, Seite nicht gefunden, Fehler 404',
  info:
    'Wir können die Seite, die Sie suchen, nicht finden. Bitte überprüfen Sie die Adresse oder nutzen Sie die Suche, um Produkte zu finden.',
  rangeOverview: 'Sortimentsübersicht',
};

const brandProductsNotFound = {
  title: '{BRAND} - Markenseite',
  info:
    'Aktuell haben wir keine Artikel auf der Markenseite. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.',
};

const newsletterOptout = {
  title: 'Newsletter-Abmeldung',
  subTitle: 'Sie möchten das Abonnement des Interdiscount-Newsletters beenden?',
  textBefore: 'Sie sind aktuell mit der Adresse ',
  textAfter: ' für unseren Interdiscount-Newsletter registriert.',
  text3: 'Bitte bestätigen Sie, dass Sie unseren Newsletter künftig nicht mehr erhalten möchten.',
  buttonText: 'Jetzt abmelden',
  titleFailure: 'Abmeldung ist leider fehlgeschlagen',
  failure:
    'Die Abmeldung ist leider fehlgeschlagen. Bitte wiederholen Sie den Vorgang, indem Sie den Abmelde-Link ' +
    'im Newsletter erneut aufrufen.',
  backToHomepage: 'Zurück zur Startseite',
};

const newsletterOptoutPersonalized = {
  titleTag: 'Personalisierte E-Mails abmelden',
  title: 'Abmeldung personalisierter Inhalte per E-Mail',
  subTitle: 'Sie möchten keine personalisierten E-Mails erhalten?',
  textBefore: 'Sie sind aktuell mit der E-Mail Adresse ',
  textAfter: ' für personalisierte Inhalte per E-Mail registriert.',
  text3:
    'Nach erfolgter Abmeldung senden wir Ihnen keine E-Mails mehr mit speziell auf Sie abgestimmten Informationen, Angeboten und Gutscheinen.',
  buttonText: 'Jetzt abmelden',
  titleFailure: 'Abmeldung ist leider fehlgeschlagen',
  failure:
    'Die Abmeldung ist leider fehlgeschlagen. Bitte wiederholen Sie den Vorgang, indem Sie den Abmelde-Link ' +
    'im Newsletter erneut aufrufen.',
};

const newsletterWidget = {
  title: 'Interdiscount-Newsletter',
  description:
    'Abonnieren Sie unseren Newsletter und gehören Sie zu den Ersten, die von unseren wöchentlichen <b>Angeboten, Aktionen</b> und <b>Neuheiten</b> erfahren.',
  button: 'Jetzt abonnieren',
};

const newsletterFeedback = {
  registration: {
    title: 'Newsletter anmelden',
    heading: 'Newsletter-Anmeldung',
    advantagesTitle: 'Ihre Vorteile',
    advantages: [
      { id: '0', value: 'Keine Aktion mehr verpassen' },
      { id: '1', value: 'Exklusive Empfehlungen für Sie' },
      { id: '2', value: 'Produktneuheiten entdecken' },
    ],
    text:
      'Abonnieren Sie unseren Newsletter und gehören Sie zu den Ersten, die von unseren wöchentlichen Angeboten, Aktionen und Neuheiten erfahren.',
    submit: 'Gratis abonnieren',
    recoLink: 'unternehmen/datenschutz',
    dataPrivacyUrl: 'https://www.coop.ch/de/unternehmen/datenschutz.html?source=interdiscount',
    recoText:
      'Die Empfehlungen für Sie können aufgrund von vorhandenen Daten personalisiert werden. Weitere Informationen finden Sie in der [Datenschutzerklärung]({RECO_URL}).',
    footerText:
      'Falls Sie Ihre Abonnentendaten oder Ihr Newsletter-Abonnement später ändern oder löschen möchten, ist dies jederzeit möglich. Den entsprechenden Link finden Sie am Ende des Newsletters.',
    dataProtectionText:
      '<b>Datenschutz: </b>Ihre Daten werden nur für den Versand des Interdiscount-Newsletters verwendet und werden nicht an Dritte weitergegeben.',
  },
  subscribe: {
    title: 'Newsletter - Anmeldung erfolgreich',
    heading: 'Vielen Dank für Ihr Interesse',
    text:
      'Sie haben an die Adresse **{EMAIL}** eine E-Mail erhalten. Um die Anmeldung abzuschliessen, klicken Sie bitte auf den **Bestätigungslink**.',
    text2: 'Sollte die E-Mail nicht in Ihrem Postfach erscheinen, überprüfen Sie bitte Ihren Spam-Ordner.',
  },
  customer: {
    title: 'Newsletter - Anmeldung erfolgreich',
    heading: 'Newsletter-Anmeldung erfolgreich abgeschlossen',
    text:
      'Sie haben unseren Newsletter abonniert und gehören zu den Ersten, die von unseren wöchentlichen Angeboten, Aktionen und Neuigkeiten erfahren.',
  },
  confirmation: {
    title: 'Newsletter - Anmeldung erfolgreich',
    heading: 'Vielen Dank',
    text:
      'Ihre Daten wurden erfolgreich übermittelt.\n\n' +
      'Wir freuen uns darüber, Ihnen bald den ersten Newsletter zusenden zu dürfen.\n\n' +
      'Möchten Sie von weiteren Kundenvorteilen profitieren? Dann zögern Sie nicht und ' +
      '|registrieren sich für ein kostenloses Kundenkonto|.',
  },
  guest: {
    title: 'Newsletter - Anmeldung erfolgreich',
    heading: 'Newsletter-Anmeldung erfolgreich abgeschlossen',
    text:
      'Sie haben unseren Newsletter abonniert und gehören zu den Ersten, die von unseren wöchentlichen Angeboten, Aktionen und Neuigkeiten erfahren.',
  },
  unsubscribe: {
    title: 'Newsletter - Abmeldung erfolgreich',
    heading: 'Schade, dass Sie uns verlassen.',
    subhead: 'Sie haben sich erfolgreich abgemeldet.',
    text:
      'Ab sofort erhalten Sie von Interdiscount keinen Newsletter mehr. Selbstverständlich können Sie sich ' +
      'jederzeit wieder für unseren Newsletter anmelden.',
    personalizedText:
      'Ab sofort erhalten Sie von microspot.ch keine individualisierten Marketing-E-Mails mehr. \n\n' +
      'Selbstverständlich können Sie sich jederzeit wieder über Ihr Kundenkonto für ' +
      'individualisierte Marketing-E-Mails anmelden.',
  },
  unsubscribe_personalized: {
    title: 'Personalisierte E-Mails abmelden',
    heading: 'Sie haben sich erfolgreich abgemeldet',
    subhead: 'Sie erhalten keine personalisierten Inhalte per E-mail mehr.',
    text:
      'Ab sofort erhalten Sie von Interdiscount keine E-Mails mit personalisiertem Inhalt mehr. Die personalisierten E-Mails können Sie in Ihrem Benutzerkonto unter "Benachrichtigungen" wieder einschalten.',
  },
  backToHomepage: 'Zur Startseite', // Todo: re-translation needed
  return: 'Zurück',
  userDataForm: {
    birthday: {
      infoicon: {
        text: 'Ihr Geburtsdatum benötigen wir, damit wir Sie mit einer Geburtstagsaktion überraschen dürfen.',
      },
    },
    submit: 'Absenden',
  },
  failure: {
    title: 'Newsletter - Anmeldung fehlgeschlagen',
    heading: 'Anmeldung ist leider fehlgeschlagen',
    text:
      'Ihre Anmeldung zum Interdiscount-Newsletter ist leider fehlgeschlagen. ' +
      'Der aufgerufene Link ist abgelaufen oder ungültig. Bitte wiederholen Sie die Anmeldung.',
  },
  used: {
    title: 'Newsletter - Anmeldung erfolgreich',
    heading: 'Sie sind bereits angemeldet',
    text:
      'Sie haben sich bereits erfolgreich für unseren Newsletter angemeldet. Wir freuen uns darüber, Ihnen ' +
      'bald den ersten Newsletter zusenden zu dürfen.',
  },
  pending: {
    title: 'Newsletter - Bestätigung ausstehend',
    heading: 'Bestätigung ausstehend',
    text:
      'Sie sollten in den letzten Tagen eine E-Mail mit einem **Bestätigungslink** erhalten haben. Um die ' +
      'Anmeldung zu unserem Newsletter abzuschliessen, klicken Sie bitte auf den Bestätigungslink. Sollte die E-Mail ' +
      'nicht in Ihrem Postfach erscheinen, überprüfen Sie bitte Ihren Spam-Ordner.\n' +
      'Wir freuen uns darüber, Ihnen bald den ersten Newsletter zusenden zu dürfen.',
  },
  optoutReasons: {
    headline: 'Wir sind Ihnen dankbar, wenn Sie uns den Grund Ihrer Abmeldung bekannt geben.',
    UNINTERESTING: 'Uninteressante Angebote',
    NOT_PERSONALIZED: 'Zu wenig personalisierte Angebote',
    TOO_FREQUENT: 'Newsletter kommt zu oft',
    DISPLAY_ISSUE: 'Der Newsletter wird nicht richtig dargestellt',
    comment: 'Kommentarfeld',
    commentPlaceholder: 'Bitte geben Sie einen Kommentar ein.',
    send: 'Senden',
    thanks: 'Besten Dank für Ihre Antwort.',
    next: 'Weiter zum Shop',
  },
};

const loadingState = {
  pleaseWait: 'Einen Moment bitte ...',
};

const apiErrorMessages = {
  badRequestModal: 'Die Aktion kann nicht durchgeführt werden. Bitte versuchen Sie es später erneut.',
  networkErrorModal: 'Die Aktion kann nicht durchgeführt werden. Bitte prüfen Sie Ihre Internetverbindung.',
  badRequestError: {
    headline: 'Momentan sind leider keine Produktdaten verfügbar.',
    text: 'Wir bemühen uns um eine schnelle Lösung. Bitte versuchen Sie es später erneut.',
  },
  networkError: {
    headline: 'Die Aktion kann nicht durchgeführt werden.',
    text: 'Bitte prüfen Sie Ihre Internetverbindung.',
  },
  productOverview: {
    FAILURE: {
      headline: 'Momentan sind leider keine Daten verfügbar.',
      text: 'Wir bemühen uns um eine schnelle Lösung. Bitte versuchen Sie es später erneut.',
    },
    NETWORKERROR: {
      headline: 'Momentan sind leider keine Daten verfügbar.',
      text: 'Bitte prüfen Sie Ihre Internetverbindung.',
    },
  },
  productDetail: {
    FAILURE: {
      headline: 'Dieses Produkt ist leider nicht mehr verfügbar.',
      text: 'Nutzen Sie die Suche oder die Produktnavigation, um eine Alternative zu finden.',
    },
    NETWORKERROR: {
      headline: 'Die Aktion kann leider nicht durchgeführt werden.',
      text: 'Bitte prüfen Sie Ihre Internetverbindung.',
    },
  },
  jsDisabled: {
    FAILURE: {
      headline: 'Sie haben JavaScript in Ihrem Browser deaktiviert.',
      text:
        'Bitte aktivieren Sie JavaScript in Ihren Browser-Einstellungen, ' +
        'um interdiscount.ch in vollem Umfang nutzen zu können.',
    },
  },
  cookiesDisabled: {
    FAILURE: {
      headline: 'Sie haben Cookies in Ihrem Browser deaktiviert.',
      text:
        'Der Bestellprozess kann ohne Cookies nicht abgeschlossen werden. ' +
        'Bitte aktivieren Sie Cookies in Ihren Browser-Einstellungen und laden Sie die Seite neu.',
    },
  },
  checkout: {
    FAILURE: {
      headline: 'Momentan sind leider keine Daten verfügbar.',
      text: 'Wir bemühen uns um eine schnelle Lösung. Bitte versuchen Sie es später erneut.',
    },
    NETWORKERROR: {
      headline: 'Momentan sind leider keine Daten verfügbar.',
      text: 'Bitte prüfen Sie Ihre Internetverbindung.',
    },
  },
  general: {
    FAILURE: {
      headline: 'Die Aktion kann leider nicht durchgeführt werden.',
      text: 'Wir bemühen uns um eine schnelle Lösung. Bitte versuchen Sie es später erneut.',
    },
    NETWORKERROR: {
      headline: 'Die Aktion kann leider nicht durchgeführt werden.',
      text: 'Bitte prüfen Sie Ihre Internetverbindung.',
    },
  },
  placeOrder: {
    FAILURE: {
      headline: 'Die Aktion kann leider nicht durchgeführt werden.',
      text: 'Wir bitten um Ihr Verständnis und bemühen uns um eine schnelle Lösung.',
    },
  },
  retryButton: 'Erneut versuchen',
};

const modal = {
  close: 'Schliessen',
  addToCartModal: {
    moreInformation: 'Information',
    close: 'Weiter einkaufen',
  },
  noStockModal: {
    headline: 'Nicht hinzugefügt',
    flashMessageContent:
      'Leider kann der Artikel nicht mehr in den Warenkorb gelegt werden, da gerade das letzte Stück verkauft wurde.',
  },
  maxOrderQuantityExceeded: {
    flashMessageContent: 'Die maximale Anzahl dieses Artikels für den Warenkorb ist erreicht.',
  },
};

const timeSensitive = {
  modalAlert: {
    headline: 'Bitte beachten Sie',
    cartUpdated: {
      info: 'Seit Ihrem letzten Aufruf gab es automatisierte Änderungen in Ihrem Warenkorb.',
      whatToDo: 'Was möchten Sie tun?',
      checkOrderSummary: 'Bitte prüfen Sie die Bestellübersicht',
    },
    stockUpdated: {
      info: 'Leider ist einer Ihrer Artikel bereits ausverkauft',
      whatToDo: 'Sie werden automatisch zu Ihrem aktualisierten Warenkorb weitergeleitet.',
    },
  },
  modalVoucherRedeemed: {
    headline: 'Bitte beachten Sie:',
    info:
      'Seit Ihrem letzten Aufruf gab es automatisierte Änderungen in Ihrem Warenkorb.' +
      ' Bitte prüfen Sie die Bestellübersicht',
  },
  infoBox: {
    mainInfo: 'Seit Ihrem letzten Aufruf gab es automatisierte Änderungen in Ihrem Warenkorb.',
    updatedPrice: {
      text1: 'Der ',
      keyword: 'Preis',
      text2: ' dieses Artikels hat sich seit dem letzten Aufruf geändert.',
    },
    updatedStock: {
      text1: 'Dieser Artikel kann nicht in der von Ihnen ',
      keyword: 'gewünschten Menge',
      text2: ' bestellt werden. Wir haben die Stückzahl für Sie reduziert.',
    },
    removedEntry: {
      text1: 'Es sind nicht mehr alle Artikel Ihres Warenkorbs ',
      keyword: 'verfügbar',
      text2: '. Wir haben diese(n) Artikel für Sie entfernt:',
    },
  },
};

const filterSelectbox = {
  search: 'Bitte wählen',
  noResults: 'Keine (weiteren) Ergebnisse gefunden.',
};

const filterToggle = {
  speedProduct: {
    text: 'Zeige Artikel an, die innerhalb eines Arbeitstages geliefert werden können.',
    count: {
      plural: 'Produkte',
      singular: 'Produkt',
    },
  },
};

const wall = {
  header: 'Aktionen',
  showMore: 'Weitere Aktionen anzeigen',
};

const supercard = {
  headline: 'Sie sammeln Superpunkte',
  headlineEmpty: 'Jetzt Superpunkte sammeln',
  label: 'Ihre Kartennummer',
  save: 'Speichern',
  remove: 'Entfernen',
};

const filterSlider = {
  submitText: 'OK',
};

const popOverAccount = {
  lastOrder: 'Letzte Bestellung',
  orderedOn: 'Bestellung vom',
};

const popOverCart = {
  recentlyAdded: 'Zuletzt hinzugefügt',
  total: 'Total',
  goToCart: 'Zum Warenkorb',
};

const productVideo = {
  headline: 'Produktvideos',
};

const cmsFallback = {
  missingVideoId: 'Keine VideoID vorhanden',
  missingContent: 'Kein Inhalt vorhanden',
  missingBricks: 'Keine Kacheln vorhanden',
};

const discount = {
  headline: 'Rabatt-Bon',
  headlineFrom: 'Gültig vom ',
  headlineTo: ' bis ',
  bon: 'BON',
  flagText: 'Bereits abgezogen',
  insteadOf: ' statt ',
  savings: 'Sie sparen ',
  savingsCurrency: ' CHF',
  savingsheadline: 'Die Ersparnis ergibt sich aus:',
  comparison: 'Interdiscount Preis im Selbstvergleich ',
  discount: ' Rabatt durch Rabatt-Bon',
  onlineInfo: 'Bei ONLINE-KAUF ziehen wir den Rabatt automatisch ab.',
  infoText:
    'Rabatt nicht kumulierbar mit anderen Bons/Rabattaktivitäten. Die Bestellmenge pro Kunde bei ' +
    'Aktionsartikeln ist begrenzt. Angebot gilt solange Vorrat. Detaillierte Informationen in Ihrer Interdiscount ' +
    'Filiale.',
  discountName: 'Rabatt',
  employeeDiscount: 'Mitarbeiterrabatt',
};

const meta = {
  homePage:
    'Entdecken Sie den führenden Elektronik-Shop der Schweiz. Bis 20 Uhr bestellt, morgen geliefert! ' +
    'Abholung in 200 Filialen möglich.',
  category1: 'Grosse Auswahl im Bereich',
  category2: 'zu besten Preisen. Bis 20 Uhr bestellt, morgen geliefert ✓ Abholung in 170 Filialen möglich ✓',
  brand: 'günstig kaufen. Bis 20 Uhr bestellt, morgen geliefert! Abholung in 200 Filialen möglich.',
  productDetail: 'günstig bei Interdiscount kaufen. Bis 20 Uhr bestellt, morgen geliefert!',
  categoryTitle: 'günstig online kaufen',
  brandPage:
    'Grosse Auswahl von {BRAND} Produkten zu besten Preisen. ✓ Bis 20 Uhr bestellt, morgen geliefert ✓ ' +
    'Abholung in 170 Filialen möglich ✓',
  storeFinder:
    'Finden Sie den Interdiscount in Ihrer Nähe | Der Filialfinder für den bequemsten Weg zur nächsten Filiale von Interdiscount mit den aktuellen Öffnungszeiten.',
};

const watchlist = {
  accountHeader: 'Merkliste',
  registration: {
    content: {
      text1: 'Sie möchten Ihre Merkliste speichern, aber haben noch kein Kundenkonto?',
      text2:
        'Legen Sie jetzt in wenigen Schritten ein Kundenkonto an, speichern Sie Ihre Merkliste für spätere ' +
        'Besuche auf interdiscount.ch und profitieren Sie von weiteren exklusiven Vorteilen.',
    },
  },
  headline: 'Merkliste',
  empty: {
    title: 'Ihre Merkliste ist leer.',
    partOne: 'Fügen Sie Ihrer Merkliste Artikel hinzu!',
    partTwo:
      'Erkunden Sie die Artikelvielfalt auf interdiscount.ch und merken Sie sich Ihre Lieblingsartikel für ' +
      'eine spätere Bestellung.',
    shopNow: 'Jetzt shoppen',
  },
  saveText: 'Sie möchten die Merkliste dauerhaft speichern oder bereits gespeicherte Artikel sehen? ',
  loginText: 'Melden Sie sich dafür mit Ihrem Kundenkonto an.',
  saveButtonText: 'Merkliste speichern',
  deleteProduct: 'Artikel entfernen',
  deleteWatchlist: 'Alle Artikel entfernen',
  goToWatchlist: 'Zur Merkliste',
};

const recommendations = {
  similar: {
    title: 'Ähnliche Produkte',
  },
  boughtByOthers: {
    title: 'Andere Kunden kauften auch',
  },
  topProducts: 'Top Angebote aus unserem Sortiment',
};

const productComparison = {
  header: 'Produktvergleich',
  comparisonList: 'Vergleichsliste',
  empty: {
    title: 'Ihr Produktvergleich ist leer.',
    partOne: 'Fügen Sie Ihrem Vergleich Artikel hinzu!',
    partTwo:
      'Erkunden Sie die Artikelvielfalt auf interdiscount.ch und vergleichen Sie Ihre Lieblingsartikel für ' +
      'eine optimale Entscheidung.',
  },
  list: {
    header: 'Produktkategorie im Vergleich:',
    addMore: 'Fügen Sie dem Vergleich einen weiteren Artikel hinzu.',
  },
  mobile: {
    header: 'Meine Auswahl in: ',
  },
  overview: {
    header: 'Ihre Auswahl:',
    addMoreFull: 'Fügen sie einen weiteren Artikel hinzu, um den Vergleich zu starten.',
    addMore: 'Weiteren Artikel hinzufügen',
    compareNow: 'Jetzt vergleichen',
  },
  errorText: 'Sie haben bereits 3 Artikel ausgewählt. Bitte entfernen Sie zuerst einen Artikel aus dem Vergleich.',
  articles: 'Artikel',
  in: 'im',
  goToComparison: 'Zur Vergleichsliste',
};

const orderConfirmationSteps = {
  default: {
    default: {
      emailConfirmation: 'Sie erhalten eine Auftragsbestätigung per E-Mail an <b>{email}</b>',
      instructions: [
        'Unsere Versandabteilung wird Ihre Bestellung schnellstmöglich versandfertig machen.',
        'Sie erhalten von uns eine Versandbenachrichtigung per E-Mail.',
      ],
    },
  },
  pickup: {
    default: {
      emailConfirmation: 'Sie erhalten eine Bestelleingangsbestätigung per E-Mail an <b>{email}</b>',
      instructions: [
        'Unsere Versandabteilung wird Ihre Bestellung schnellstmöglich an Ihre gewählte Filiale liefern.',
        'Sie erhalten von uns eine Benachrichtigung per E-Mail, sobald Ware aus Ihrer Bestellung in der gewählten Filiale abholbereit ist.',
      ],
    },
    pegi: {
      emailConfirmation: 'Sie erhalten eine Bestelleingangsbestätigung per E-Mail an <b>{email}</b>',
      instructions: [
        'Unser Kundenservice wird Ihre Altersangaben schnellstmöglich prüfen.',
        'Nach positiver Prüfung wird unsere Versandabteilung Ihre Bestellung schnellstmöglich an Ihre gewählte Filiale liefern.',
        'Sie erhalten von uns eine Benachrichtigung per E-Mail, sobald Ware aus Ihrer Bestellung in der gewählten Filiale abholbereit ist.',
      ],
    },
  },
  delivery: {
    default: {
      emailConfirmation: 'Sie erhalten eine Bestelleingangsbestätigung per E-Mail an <b>{email}</b>',
      instructions: [
        'Unsere Versandabteilung wird Ihre Bestellung schnellstmöglich versandfertig machen.',
        'Sie erhalten von uns eine Versandbenachrichtigung per E-Mail und die Ware wird an Ihre angegebene Adresse geliefert.',
      ],
    },
    pegi: {
      emailConfirmation: 'Sie erhalten eine Bestelleingangsbestätigung per E-Mail an <b>{email}</b>',
      instructions: [
        'Unser Kundenservice wird Ihre Altersangaben schnellstmöglich prüfen.',
        'Nach positiver Prüfung wird unsere Versandabteilung Ihre Bestellung schnellstmöglich versandfertig machen.',
        'Sie erhalten von uns eine Versandbenachrichtigung per E-Mail und die Ware wird an Ihre angegebene Adresse geliefert.',
      ],
    },
  },
  deliveryCashInAdvance: {
    default: {
      emailConfirmation:
        'Sie erhalten eine Bestelleingangsbestätigung per E-Mail an <b>{email}</b> mit Ihren persönlichen Zahlungsinformationen.',
      instructions: [
        'Sobald Ihre Zahlung bei uns eingegangen ist, erhalten Sie eine Zahlungseingangsbestätigung.',
        'Nach Erhalt der Zahlung wird Ihre Bestellung schnellstmöglich versandfertig gemacht.',
        'Sie erhalten von uns eine Versandbenachrichtigung per E-Mail.',
      ],
    },
    pegi: {
      emailConfirmation:
        'Sie erhalten eine Bestelleingangsbestätigung per E-Mail an <b>{email}</b> mit Ihren persönlichen Zahlungsinformationen.',
      instructions: [
        'Sobald Ihre Zahlung bei uns eingegangen ist, erhalten Sie eine Zahlungseingangsbestätigung',
        'Unser Kundenservice wird Ihre Altersangaben schnellstmöglich prüfen.',
        'Nach positiver Prüfung wird unsere Versandabteilung Ihre Bestellung schnellstmöglich versandfertig machen.',
        'Sie erhalten von uns eine Versandbenachrichtigung per E-Mail und die Ware wird an Ihre angegebene Adresse geliefert.',
      ],
    },
  },
  esdCashInAdvance: {
    default: {
      emailConfirmation:
        'Sie erhalten eine Bestelleingangsbestätigung per E-Mail an <b>{email}</b> mit Ihren persönlichen Zahlungsinformationen.',
      instructions: [
        'Sobald Ihre Zahlung bei uns eingegangen ist, erhalten Sie eine Zahlungseingangsbestätigung.',
        'Nach Erhalt der Zahlung erhalten Sie eine E-Mail mit dem Lizenzschlüssel der Software und ein Link zum Software-Provider.',
        'Klicken Sie auf den Link um auf die Seite des Software-Providers zu gelangen. Auf der Seite des Software-Providers klicken Sie auf Produkt installieren, um Ihren Artikel herunterladen und installieren zu können.',
      ],
    },
  },
  esd: {
    default: {
      emailConfirmation: 'Sie erhalten eine Bestelleingangsbestätigung per E-Mail an <b>{email}</b>',
      instructions: [
        'Kurze Zeit später erhalten Sie eine E-Mail mit dem Lizenzschlüssel der Software und ein Link zum Software-Provider.',
        'Klicken Sie auf den Link um auf die Seite des Software-Providers zu gelangen. Auf der Seite des Software-Providers klicken Sie auf Produkt installieren, um Ihren Artikel herunterladen und installieren zu können.',
      ],
    },
  },
  esdMixedPickup: {
    default: {
      emailConfirmation: 'Sie erhalten eine Bestelleingangsbestätigung per E-Mail an <b>{email}</b>',
      instructions: [
        'Unsere Versandabteilung wird Ihre Bestellung schnellstmöglich an Ihre gewählte Filiale liefern.',
        'Zudem erhalten Sie eine E-Mail mit dem Lizenzschlüssel der Software und ein Link zum Software-Provider.',
        'Klicken Sie auf den Link um auf die Seite des Software-Providers zu gelangen. Auf der Seite des Software-Providers klicken Sie auf Produkt installieren, um Ihren Artikel herunterladen und installieren zu können.',
        'Sie erhalten von uns eine Benachrichtigung per E-Mail, sobald Ware aus Ihrer Bestellung in der gewählten Filiale abholbereit ist.',
      ],
    },
    pegi: {
      emailConfirmation: 'Sie erhalten eine Bestelleingangsbestätigung per E-Mail an <b>{email}</b>',
      instructions: [
        'Unser Kundenservice wird Ihre Altersangaben schnellstmöglich prüfen.',
        'Nach positiver Prüfung wird unsere Versandabteilung Ihre Bestellung schnellstmöglich an Ihre gewählte Filiale liefern.',
        'Zudem erhalten Sie eine E-Mail mit dem Lizenzschlüssel der Software und ein Link zum Software-Provider.',
        'Klicken Sie auf den Link um auf die Seite des Software-Providers zu gelangen. Auf der Seite des Software-Providers klicken Sie auf Produkt installieren, um Ihren Artikel herunterladen und installieren zu können.',
        'Sie erhalten von uns eine Benachrichtigung per E-Mail, sobald Ware aus Ihrer Bestellung in der gewählten Filiale abholbereit ist.',
      ],
    },
  },
  esdMixedDeliveryCashInAdvance: {
    default: {
      emailConfirmation:
        'Sie erhalten eine Bestelleingangsbestätigung per E-Mail an <b>{email}</b> mit Ihren persönlichen Zahlungsinformationen.',
      instructions: [
        'Sobald Ihre Zahlung bei uns eingegangen ist, erhalten Sie eine Zahlungseingangsbestätigung und Ihre Bestellung wird schnellstmöglich versandfertig gemacht.',
        'Zudem erhalten Sie eine E-Mail mit dem Lizenzschlüssel der Software und ein Link zum Software-Provider.',
        'Klicken Sie auf den Link um auf die Seite des Software-Providers zu gelangen. Auf der Seite des Software-Providers klicken Sie auf Produkt installieren, um Ihren Artikel herunterladen und installieren zu können.',
        'Sie erhalten von uns eine Versandbenachrichtigung per E-Mail.',
      ],
    },
    pegi: {
      emailConfirmation:
        'Sie erhalten eine Bestelleingangsbestätigung per E-Mail an <b>{email}</b> mit Ihren persönlichen Zahlungsinformationen.',
      instructions: [
        'Sobald Ihre Zahlung bei uns eingegangen ist, erhalten Sie eine Zahlungseingangsbestätigung.',
        'Unser Kundenservice wird Ihre Altersangaben schnellstmöglich prüfen.',
        'Nach positiver Prüfung wird unsere Versandabteilung Ihre Bestellung schnellstmöglich versandfertig machen.',
        'Zudem erhalten Sie eine E-Mail mit dem Lizenzschlüssel der Software und ein Link zum Software-Provider.',
        'Klicken Sie auf den Link um auf die Seite des Software-Providers zu gelangen. Auf der Seite des Software-Providers klicken Sie auf Produkt installieren, um Ihren Artikel herunterladen und installieren zu können.',
        'Sie erhalten von uns eine Versandbenachrichtigung per E-Mail und die Ware wird an Ihre angegebene Adresse geliefert.',
      ],
    },
  },
  esdMixedDelivery: {
    default: {
      emailConfirmation: 'Sie erhalten eine Bestelleingangsbestätigung per E-Mail an <b>{email}</b>',
      instructions: [
        'Unsere Versandabteilung wird Ihre Bestellung schnellstmöglich versandfertig machen.',
        'Zudem erhalten Sie eine E-Mail mit dem Lizenzschlüssel der Software und ein Link zum Software-Provider.',
        'Klicken Sie auf den Link um auf die Seite des Software-Providers zu gelangen. Auf der Seite des Software-Providers klicken Sie auf Produkt installieren, um Ihren Artikel herunterladen und installieren zu können.',
        'Sie erhalten von uns eine Versandbenachrichtigung per E-Mail und die Ware wird an Ihre angegebene Adresse geliefert.',
      ],
    },
    pegi: {
      emailConfirmation: 'Sie erhalten eine Bestelleingangsbestätigung per E-Mail an <b>{email}</b>',
      instructions: [
        'Unsere Versandabteilung wird Ihre Bestellung schnellstmöglich versandfertig machen.',
        'Unser Kundenservice wird Ihre Altersangaben schnellstmöglich prüfen.',
        'Nach positiver Prüfung wird unsere Versandabteilung Ihre Bestellung schnellstmöglich versandfertig machen.',
        'Zudem erhalten Sie eine E-Mail mit dem Lizenzschlüssel der Software und ein Link zum Software-Provider.',
        'Klicken Sie auf den Link um auf die Seite des Software-Providers zu gelangen. Auf der Seite des Software-Providers klicken Sie auf Produkt installieren, um Ihren Artikel herunterladen und installieren zu können.',
        'Sie erhalten von uns eine Versandbenachrichtigung per E-Mail und die Ware wird an Ihre angegebene Adresse geliefert.',
      ],
    },
  },
};

const productPromotion = {
  stock: {
    still: 'Noch ',
    of: ' von ',
    pieces: ' Stück ',
    available: ' verfügbar',
  },
  ended: 'Aktion beendet!',
  valid: 'Noch gültig ',
  day: 'Tag',
  hour: 'Std.',
  min: 'min.',
  sec: 'sek.',
};

const b2b = {
  productFlag: {
    label: 'Neutral',
    toolTip: 'Dieses Produkt kann mit neutralem Lieferschein versandt werden.',
  },
  myAccountOrderInfo: {
    neutralDeliveryNote: 'Neutraler Lieferschein',
    neutralDeliveryNoteType: {
      YES: 'Ja',
      NO: 'Nein',
      PARTIALLY: 'Teilweise',
    },
  },
  neutralDelivery: {
    label: 'Mit neutralem Lieferschein versenden',
    description:
      'Falls Ihre Bestellung Artikel ohne Neutral-Kennzeichnung enthält, wird Ihre Bestellung ' +
      'in separaten Lieferungen an dieselbe Adresse geliefert. Produkte mit der Kennzeichnung „Neutral“ werden ' +
      'mit neutralem Lieferschein verschickt.',
    notice: {
      headline: 'Bitte beachten Sie:',
      deliveries: {
        text1:
          'Ein oder mehrere Produkte Ihrer Bestellung können nicht mit einem ' +
          'neutralem Lieferschein versendet werden. Ihre Bestellung wird deshalb in ',
        keyword: 'separaten Lieferungen an die selbe Adresse',
        text2: ' geliefert.',
      },
      note: {
        text1: 'Produkte mit der ',
        keyword: 'Kennzeichnung "Neutral"',
        text2: ' werden mit einem neutralen Lieferschein verschickt.',
      },
    },
  },
};

const happyDayBrick = {
  title: 'ID Deal',
};

const flashMessages = {
  openStores: {
    headline: 'Wir haben heute geöffnet!',
    link: 'Offene Filiale suchen',
  },
};

const feedbackForm = {
  title: 'Fehlerhafte Daten melden',
  link: 'Meldeformular öffnen +',
  infoText:
    'Ist Ihnen ein Fehler in unseren Produktinformationen aufgefallen? Dann können Sie uns dies mit dem ' +
    'folgenden Meldeformular mitteilen.\n\nAllgemeine Fragen zum Produkt oder zu Ihrer Bestellung werden hier ' +
    'nicht beantwortet. Bitte wenden Sie sich für solche Anliegen direkt an unseren ',
  clientService: 'Kundendienst',
  topic: {
    label: 'Ihr Anliegen',
    placeholder: 'Bitte wählen*',
    image: 'Produktbild',
    video: 'Produktvideo',
    features: 'Produkteigenschaften',
    description: 'Beschreibung',
    documents: 'Dokumente',
    other: 'Sonstiges',
  },
  message: {
    label: 'Ihre Nachricht',
  },
  email: {
    label: 'E-Mail-Adresse',
  },
  cancel: 'Abbrechen',
  submit: 'Senden',
  recaptchaError: 'Bitte bestätigen Sie, dass Sie kein Roboter sind.',
  success: 'Vielen Dank für die Fehlermeldung. Wir werden die Daten so schnell wie möglich korrigieren.',
  failure: 'Der Vorgang ist leider fehlgeschlagen.',
};

const productRating = {
  overview: {
    title: 'Bewertungsübersicht',
  },
  details: {
    title: 'Bewertungen',
  },
  comments: {
    title: 'Kommentare',
    verified: 'Gekauft bei **interdiscount.ch**',
    sort: {
      newestFirst: 'Neueste zuerst',
      ratingDesc: 'Bewertung absteigend',
      ratingAsc: 'Bewertung aufsteigend',
    },
    empty: ['Geben Sie den ersten ', 'Kommentar', ' zu diesem Produkt ab.'],
    readMore: 'Alles lesen',
    readLess: 'Weniger zeigen',
    more: 'weitere anzeigen',
  },
  count: {
    title: 'Kundenbewertung(en)',
  },
  total: {
    title: 'Gesamtbewertung',
    of: 'von',
    recommendation: 'unserer Kunden würden dieses Produkt weiterempfehlen',
    btnDefault: 'Produkt bewerten',
    btnFirst: 'Produkt als erster bewerten',
  },
  addRating: 'Dieses Produkt bewerten',
  editRating: 'Bewertung bearbeiten',
  editor: {
    okLabel: 'Bewertung abgeben',
    editLabel: 'Bewertung aktualisieren',
    editInfo: 'Sie haben dieses Produkt bereits am **%s** bewertet.',
    cancelLabel: 'Abbrechen',
    commentForm: 'Ihr Kommentar',
    commentAdd: 'Kommentar verfassen',
    commentStatus: {
      OPEN:
        'Ihr Kommentar zu diesem Produkt wird von uns geprüft. Dies kann einige Tage in Anspruch ' +
        'nehmen. Sobald Ihr Kommentar freigeschaltet wurde, werden Sie von uns benachrichtigt.',
      REJECTED: 'Ihr Kommentar vom %s wurde abgelehnt.',
    },
    commentStatusShort: {
      OPEN: 'Ihr Kommentar vom %s wird aktuell durch uns geprüft.',
      REJECTED: 'Ihr Kommentar vom %s wurde abgelehnt.',
    },
    commentRejectedReason: 'Begründung',
    commentHelp: {
      contact: 'Bei Fragen zum Bestellvorgang oder zum Produkt ',
      contactLinked: 'kontaktieren Sie bitte unseren Kundenservice.',
      terms: 'Nutzungsbedingungen',
      guidelines: 'Richtlinien für die Bewertung',
    },
    labels: {
      rating: 'Meine Bewertung',
      recommends: 'Ich würde dieses Produkt weiterempfehlen',
      title: 'Titel / Zusammenfassung',
      comment: 'Kommentar',
    },
    errors: {
      rating: 'Bitte geben Sie eine Bewertung ab.',
      recommends: 'Bitte geben Sie eine Empfehlung an.',
      title: 'Bitte geben Sie einen Titel an.',
      comment: 'Bitte geben Sie einen Kommentar an.',
      commentLength: 'Bitte beschränken Sie den Kommentar auf %s Zeichen.',
    },
    doneLabel: 'Fertig',
    thanks: 'Vielen Dank für Ihre Bewertung',
    recommended: 'Sie würden das Produkt weiterempfehlen.',
    notRecommended: 'Sie würden das Produkt nicht weiterempfehlen.',
    patienceNew: 'Bitte beachten Sie, dass es einen Moment dauern kann, bis Ihre Bewertung online ist.',
    patienceUpd:
      'Bitte beachten Sie, dass es einen Moment dauern kann, bis ' + 'die Änderungen an Ihrer Bewertung online sind.',
    commentInfo:
      'Ihr Kommentar zu diesem Produkt wird nun von uns geprüft. Das kann einige Tage in Anspruch nehmen. ' +
      'Sobald Ihr Kommentar freigeschaltet wurde, werden Sie von uns benachrichtigt.',
    recommendsYes: 'Ja',
    recommendsNo: 'Nein',
  },
  noRatings: 'Es liegen noch keine Bewertungen vor.',
  fewRatings: 'Es liegen erst wenige Bewertungen vor.',
  fewRatingsWarn1: 'Bitte beachten Sie',
  fewRatingsWarn2: 'Je mehr Meinungen vorliegen, desto aussagekräftiger ist die Gesamtbewertung.',
};

const inventory = {
  deliveryInfo: {
    now: 'sofort ',
    availableAllStore: 'Verfügbar in unseren Filialen',
    availableIndividualStore: 'In einzelnen Filialen verfügbar',
    availableOnline: 'Nur Online verfügbar',
    checkStoreAvailability: 'Filialverfügbarkeit prüfen',
    estimated: 'voraussichtlich am ',
    in: ' in ',
    inAllStores: 'allen Filialen',
    inThisStores: 'diesen Filialen',
    tooltip:
      'Ist der gewünschte Artikel in Ihrer bevorzugten Filiale verfügbar? ' +
      'Dann reservieren Sie ihn jetzt telefonisch. Die Bezahlung erfolgt bei Abholung.',
  },
  storeFinder: {
    headline: 'In welcher Filiale ist der Artikel verfügbar',
    infoText:
      'In vielen unserer Filialen können Sie den Artikel telefonisch reservieren. ' +
      'Die Bezahlung erfolgt bei Abholung. Eine Onlinebestellung ' +
      'zur sofortigen Abholung ist nicht möglich. ',
  },
};

const mobileProtection = {
  excludeTax: '* Produkt unterliegt nicht der MwSt.',
  excludeTaxCheckout: 'Produkt unterliegt nicht der MwSt.',
  taxText: 'inkl. 5% Stempelsteuer',
};

const select = {
  cancel: 'Abbrechen',
};

const optIn = {
  responses: {
    REQUEST: '',
    FAILURE: 'Der Vorgang ist leider fehlgeschlagen.',
    SUCCESS: 'Ihre Daten wurden erfolgreich übermittelt.',
  },
  actions: {
    HOME: 'Zur Startseite',
    ACCOUNT: 'Mein Konto',
    PROMOTIONS: 'Aktionen',
    RESEND: 'Erneut anfordern',
  },
  myAccountModal: {
    headline: 'Überprüfung Ihrer E-Mail-Adresse',
    text:
      'Aus Sicherheitsgründen wollen wir Ihre E-Mail-Adresse verifizieren. Dazu haben wir Ihnen eine E-Mail mit ' +
      'einem Bestätigungslink an **{EMAIL}** gesendet. Wenn Sie keinen Zugang mehr zu Ihrem E-Mail-Postfach haben, ' +
      'bitten wir Sie unter "Meine Benutzerdaten" eine neue E-Mail-Adresse anzugeben.',
  },
  types: {
    registration: {
      CONFIRMATIONMAIL: {
        headline: 'Vielen Dank für Ihre Registrierung!',
        text:
          'Ihre Kontoregistrierung wurde erfolgreich ausgeführt. Bevor Sie Ihr Benutzerkonto verwenden können, ' +
          'ist es notwendig, dass Sie einmalig die Gültigkeit Ihrer **E-Mail-Adresse bestätigen**. ' +
          'Hierfür wurde Ihnen soeben eine E-Mail mit einem **Bestätigungslink** gesendet. \n\n' +
          'Sollte die E-Mail nicht in Ihrem Postfach erscheinen, überprüfen Sie bitte Ihren Spam-Ordner.',
        actions: ['HOME'],
      },
      CONFIRMED: {
        headline: 'Vielen Dank.',
        text: 'Ihr Konto wurde erfolgreich verifiziert.',
        actions: ['HOME', 'PROMOTIONS', 'ACCOUNT'],
      },
      EXPIRED: {
        headline: 'Abgelaufener Bestätigungslink',
        text: 'Ihr Bestätigungslink ist abgelaufen. Sie müssen den Bestätigungslink erneut anfordern.',
        actions: ['RESEND'],
      },
      FAILURE: {
        headline: 'Fehlgeschlagene Verifizierung',
        text:
          'Bei Ihrer Verifizierung ist etwas schiefgelaufen. ' +
          'Bitte wenden Sie sich an [online@interdiscount.ch](mailto:online@interdiscount.ch).',
        actions: [],
      },
      USED: {
        headline: 'Bereits bestätigte E-Mail-Adresse',
        text: 'Sie haben Ihre E-Mail-Adresse bereits bestätigt.',
        actions: ['HOME', 'PROMOTIONS', 'ACCOUNT'],
      },
      notConfirmed: {
        headline: 'Überprüfung Ihrer E-Mail-Adresse',
        text:
          'Aus Sicherheitsgründen wollen wir Ihre E-Mail-Adresse verifizieren. Dazu haben wir Ihnen eine E-Mail mit ' +
          'einem Bestätigungslink an {EMAIL} gesendet. \n\n Sobald Sie Ihre E-Mail-Adresse ' +
          'über den Bestätigungslink verifizieren, erhalten Sie Zugang zu Ihrem Kundenkonto.',
        cta: 'E-Mail erneut senden',
        textAfterCta:
          'Haben Sie kein E-Mail erhalten? ' +
          'Prüfen Sie bitte Ihren Spam-Ordner oder nehmen Sie mit uns [Kontakt]({CONTACT_LINK}) auf.',
      },
    },
    updateEmail: {
      CONFIRMATIONMAIL: {
        headline: 'Ihre neue E-Mail-Adresse wurde erfasst',
        text:
          'Bitte bestätigen Sie Ihre neue E-Mail-Adresse. ' +
          'Dazu haben wir Ihnen eine E-Mail an **{EMAIL}** mit dem **Bestätigungslink** gesendet.',
        actions: ['HOME'],
      },
      CONFIRMED: {
        headline: 'Vielen Dank.',
        text: 'Ihr Konto wurde erfolgreich verifiziert.',
        actions: ['HOME', 'PROMOTIONS', 'ACCOUNT'],
      },
      EXPIRED: {
        headline: 'Abgelaufener Bestätigungslink',
        text: 'Ihr Bestätigungslink ist abgelaufen. Sie müssen den Bestätigungslink erneut anfordern.',
        actions: ['RESEND'],
      },
      FAILURE: {
        headline: 'Fehlgeschlagene Verifizierung',
        text:
          'Bei Ihrer Verifizierung ist etwas schiefgelaufen. ' +
          'Bitte wenden Sie sich an [online@interdiscount.ch](mailto:online@interdiscount.ch).',
        actions: [],
      },
      USED: {
        headline: 'Bereits bestätigte E-Mail-Adresse',
        text: 'Sie haben Ihre E-Mail-Adresse bereits bestätigt.',
        actions: ['HOME', 'PROMOTIONS', 'ACCOUNT'],
      },
    },
  },
};

const coronaSpecial = {
  checkout: {
    closedStores:
      'Aufgrund der aktuellen Bundesratsverordnung sind **alle unsere Filialen geschlossen**. Bitte ' +
      'wählen Sie Heimlieferung als Lieferart.',
    openStores:
      'Unsere Filialen sind momentan für den Verkauf geschlossen. Abholungen von Artikeln sind in ' +
      'ausgewählten Filialen möglich. Mehr Infos finden Sie [hier]({CORONA_URL}).\n\nBitte beachten Sie bei der ' +
      'Abholung jederzeit die Social Distancing-Massnahmen des BAG.',
  },
};

const returnsOverview = {
  header: 'Meine Retouren',
  overviewPreLink: 'Interdiscount bietet ein beschränktes Rückgaberecht an. Hier finden Sie die geltenden ',
  overviewLink: 'Rücknahmebedingungen',
  overviewAfterLink: '.',
  ARTICLE_RETURN_STORE: {
    title: 'Filialkauf zurückgeben',
    description:
      'Möchten Sie einen Artikel zurückgeben, den Sie in einer Filiale gekauft haben? ' +
      'Bitte bringen Sie diesen zusammen mit dem Kaufbeleg in eine unserer Filialen zurück.',
  },
  ARTICLE_RETURN_ONLINE: {
    title: 'Rückgabe eines Artikels',
    description:
      'Sie möchten einen Artikel in Originalverpackung zurückgeben, den Sie innerhalb ' +
      'der letzten 14 Tage erhalten haben?',
  },
  WRONG_ARTICLE: {
    title: 'Erhalt einer falschen Sendung',
    description:
      'Sie haben eine unvollständige oder falsche Lieferung erhalten? ' +
      'Gerne können Sie die Sendung an uns zurückschicken. Wir kümmern uns darum, dass Sie den ' +
      'richtigen und vollständigen Artikel erhalten.',
  },
  TRANSIT_DAMAGE: {
    title: 'Transportschaden',
    description: 'Ist Ihre Sendung beim Transport beschädigt worden? Wir helfen Ihnen weiter.',
  },
  TECHNICAL_DAMAGE: {
    title: 'Erhalt eines defekten Artikels',
    description: 'War der Artikel beim Erhalt defekt? ' + 'Gerne können Sie den Artikel an uns zurückschicken.',
  },
  WARRANTY: {
    title: 'Garantiefall',
    description:
      'Bei einem defekten Gerät innerhalb der Garantiezeit helfen wir Ihnen ' +
      'die richtige Servicestelle zu finden. Falls Sie für den defekten Artikel eine ' +
      'Garantieverlängerung abgeschlossen haben, kümmern wir uns um Ihre Reparatur.',
  },
};

const returns = {
  header: 'Retoure erstellen',
  orderSelect: {
    title: 'Bestellung suchen',
    orderNumber: 'Bestellnummer',
    lastName: 'Nachname',
    selectOrder: 'Bestellung auswählen',
    missingOrderId: 'Bitte geben Sie eine Bestellnummer ein.',
    missingLastName: 'Bitte geben Sie Ihren Nachnamen ein.',
    orderNotFound: 'Die Bestellung konnte nicht gefunden werden',
    ordersFromUserNotFound: {
      preLink:
        'Wir haben keine Bestellung gefunden, die retourniert werden kann. <br />' +
        'Artikel können bis maximal 14 Tage nach Erhalt zurückgegeben werden. <br />' +
        'Weitere Informationen finden Sie in den ',
      link: 'Rücknahmebedingungen',
      afterLink: '.',
    },
  },
  productSelect: {
    orderNumber: 'Bestellnummer',
    title: 'Artikel auswählen',
    soldBy: 'Verkauft durch:',
    returnArticle: 'Artikel zurückschicken',
    price: 'Preis:',
    distiWarn: {
      part1: 'Sie haben einen Artikel von ',
      part2:
        ' ausgewählt. Um Artikel von einem anderen Händler zu retournieren, ' +
        'müssen Sie den Rückgabeprozess wiederholen.',
    },
    contactLink: {
      text: ' Bitte kontaktieren Sie den ',
      link: 'Kundendienst.',
    },
    notReturnable: {
      BULKY_GOOD: 'Der Artikel ist zu gross und/oder zu schwer um per Post verschickt zu werden.',
      NOT_SHIPPED: 'Dieser Artikel wurde noch nicht ausgeliefert.',
      RETURN_EXPIRED: 'Die Retourenfrist ist bereits abgelaufen.',
      ESD: 'Diesen Artikel können Sie nicht physisch retournieren, da Sie ihn elektronisch heruntergeladen haben.',
    },
  },
  returnReason: {
    title: 'Rückgabegrund Artikel',
    reason: {
      label: 'Bitte geben Sie den Rückgabegrund an',
      DONT_LIKE_IT: 'gefällt mir nicht',
      WRONG_SIZE: 'falsche Grösse',
      LATE_DELIVERY: 'Lieferung zu spät',
      WRONG_ORDERED: 'falsch bestellt',
      OTHER: 'andere',
      WRONG_ARTICLE: 'falscher Artikel',
      NOT_ORDERED: 'nicht bestellter Artikel',
      INCOMPLETE_ARTICLE: 'unvollständiger Artikel',
      FAULTY_ARTICLE: 'defekter Artikel',
      WRONG_ARTICLE_DESCRIPTION: 'falsche Produktbeschreibung',
      UNUSED_AND_UNDAMAGED: 'ungeöffnet und unbeschädigt',
      USED_AND_OR_DAMAGED: 'geöffnet und/oder beschädigt',
      ORIGINAL_PACKAGING_NOT_AVAILABLE: 'Originalverpackung nicht vorhanden',
      UNUSED: 'ungebraucht',
      USED: 'gebraucht',
    },
    description: {
      WRONG_ARTICLE: 'Was wurde stattdessen geliefert?',
      NOT_ORDERED: 'Was wurde zu viel geliefert?',
      INCOMPLETE_ARTICLE: 'Was hat gefehlt?',
      WRONG_ARTICLE_DESCRIPTION: 'Was stimmt nicht überein?',
      FAULTY_ARTICLE: 'Was ist konkret defekt?',
      missing: 'Bitte geben Sie den Rückgabegrund an',
      minlength: 'Bitte beschreiben Sie den Rückgabegrund mit mindestens zwei Wörtern',
      maxlength: 'Bitte beschränken Sie den Kommentar auf 200 Zeichen.',
    },
    packagingCondition: {
      label: 'Bitte geben Sie den Verpackungszustand an',
    },
    articleCondition: {
      label: 'Bitte geben Sie den Artikelzustand an',
    },
    errorMessages: {
      Invalid: {
        preLink:
          'Der von Ihnen ausgewählte Artikel kann leider nicht retourniert werden.' +
          ' Bitte lesen Sie unsere aktuellen',
        link: 'Rücknahmebedingungen',
        afterLink: '.',
      },
    },
  },
  returnPayment: {
    title: 'Rückzahlung',
    info:
      'Die Rückerstattung erfolgt auf das Zahlungsmittel mit welchem der Artikel' +
      ' bezahlt wurde. Der Betrag entspricht maximal dem Kaufpreis abzüglich eines Abschlages,' +
      ' welcher durch unseren Kundendienst aufgrund des Artikel- und Verpackungszustandes' +
      ' festgelegt wird.',
    refundType: 'Welche Rückerstattung ist gewünscht?',
    types: {
      VOUCHER: 'Gutschein',
      IBAN: 'IBAN',
    },
    ibanLabel: 'IBAN eingeben',
    ibanInvalid: 'Überprüfen Sie, ob Sie Ihre IBAN-Nummer korrekt eingegeben haben.',
  },
  returnOverview: {
    title: 'Übersicht Rücksendung',
    returnPaymentMethod: 'Rückzahlungsart',
    contactData: 'Kontaktdaten',
    none: 'keine',
  },
  returnConclusion: {
    title: 'Etikette ausdrucken',
    text: 'Wir senden Ihnen das Retourendokument per E-Mail zu.',
    pdfName: 'Retourendokument',
    link1: 'Retourendokument als ',
    link2: 'PDF herunterladen',
    nextSteps: 'Nächste Schritte',
    steps: [
      'Retourenschein ausdrucken und in das Versandpaket legen.',
      'Rücksendeetikette ausdrucken und auf das Versandpaket kleben.',
      'Paket an uns zurückschicken.',
      'Betrag nach Paketeingang und Prüfung der Retoure zurück erhalten.',
    ],
    nextLabel: 'Weiter zum Shop',
  },
};

const deliveryStates = {
  short: {
    inStock: 'Verfügbar',
    onOrder: 'Lieferung auf Bestellung',
    immediately: 'Sofort verfügbar',
    reservation: 'Vorreservation',
    esd: 'Vorreservation',
    notInStock: 'Zur Zeit nicht lieferbar',
    unknown: 'Liefertermin unbekannt',
  },
  detail: {
    inStock: 'voraussichtlich am',
    onOrder: 'Lieferung auf Bestellung',
    asOfDate: 'Ab dem',
    deliveredToday: 'Heute zugestellt',
    monday: 'Montag',
    tuesday: 'Dienstag',
    wednesday: 'Mittwoch',
    thursday: 'Donnerstag',
    friday: 'Freitag',
    saturday: 'Samstag',
    sunday: 'Sonntag',
    deliveredTomorrow: 'morgen geliefert',
    deliveredWeekDay: 'am {WEEKDAY} geliefert',
    deliveredDay: 'in {DAY} Tagen geliefert',
    deliveredWithinDay: 'innerhalb {DAY} Tagen geliefert',
    deliveredWithinDate: 'innerhalb {WEEKSFROM} Wochen geliefert',
    deliveredWithinDates: 'in {WEEKSFROM} Wochen geliefert',
    deliveredWithinEightWeeks: 'in mehr als 8 Wochen geliefert',
    deliveredUnknown: 'Liefertermin unbekannt',
    deliveredBetweenDate: 'innerhalb {WEEKSFROM} - {WEEKSTO} Wochen geliefert',
    deliveredBetweenDates: 'in {WEEKSFROM} - {WEEKSTO} Wochen geliefert',
    pickUpToday: 'abholbereit heute',
    pickUpTomorrow: 'morgen abholbereit',
    pickUpWeekDay: '{WEEKDAY} abholbereit',
    pickUpDay: 'in {DAY} Tagen abholbereit',
    pickUpWithinDay: 'innerhalb {DAY} Tagen abholbereit',
    pickUpWithinDate: 'innerhalb {WEEKSFROM} Wochen abholbereit',
    pickUpWithinDates: 'in {WEEKSFROM} Wochen abholbereit',
    pickUpWithinEightWeeks: 'in mehr als 8 Wochen abholbereit',
    pickUpUnknown: 'Liefertermin unbekannt',
    pickUpBetweenDate: 'innerhalb {WEEKSFROM} - {WEEKSTO}  Wochen abholbereit',
    pickUpBetweenDates: 'in {WEEKSFROM} - {WEEKSTO} Wochen abholbereit',
    immediately: {
      text: 'Jetzt herunterladen',
      text2:
        'Der Artikel wird nach erfolgreicher Bezahlung generiert. Der Versand erfolgt per E-Mail. ' +
        'Bei Vorauskasse verzögert sich der Versand bis zum Zahlungseingang.',
    },
    reservation: {
      text: 'Erhältlich ab',
      text2: 'Reservierung ab sofort möglich',
      unknown: 'Liefertermin unbekannt',
    },
    esd: {
      text: 'Erhältlich ab',
      text2:
        'Reservierung ab sofort möglich. Nach erfolgreicher Bezahlung erhalten Sie den ' +
        'Artikel innerhalb von 5-10 Min. direkt per E-Mail zugeschickt. Bei Vorauskasse verzögert sich der Versand ' +
        'bis zum Zahlungseingang. ESD-Code ab Releasedatum einlösbar.',
    },
    notInStock: 'Dieser Artikel ist zur Zeit nicht lieferbar.',
    unknown: 'Es liegt aktuell keine Information zum Liefertermin vor, Aktualisierung erfolgt sobald bekannt.',
  },
  checkout: {
    inStock: 'Voraussichtliches Lieferdatum',
    onOrder: 'Voraussichtliches Lieferdatum',
    immediately: 'Der Versand erfolgt per E-Mail',
    reservation: 'Vorreservation - erhältlich ab dem',
    esd: 'Vorreservation - erhältlich ab dem',
    notInStock: 'Zur Zeit nicht lieferbar',
    unknown: 'Liefertermin unbekannt',
  },
  fallback: '',
};

const orderDocuments = {
  title: 'Bestelldokumente abrufen',
  description:
    'Mit der Angabe einer Bestellnummer (zu finden auf der Bestell- und Versandbestätigung oder auf ' +
    'dem Lieferschein) und Ihrem Nachnamen erhalten Sie den Kauf oder Garantiebeleg Ihrer Bestellung. ' +
    'Das gewünschte Dokument wird an jene E-Mail-Adresse gesendet, welche Sie bei der Bestellung angegeben haben.',
  additionalInformation: [
    'Sollten Sie keinen Zugang zu der bei der Bestellung angegebenen E-Mail-Adresse haben, ' +
      'nehmen Sie bitte Kontakt mit unserem Kundenservice auf: ' +
      '<online@interdiscount.ch>',
    'Sind Sie nicht der ursprüngliche Besteller, dürfen wir Ihnen aus Datenschutzgründen keine Auskunft geben. ' +
      'Bitte nehmen Sie Kontakt mit dem Käufer der Produkte auf.',
  ],
  flashMessage:
    'Das gewünschte Dokument wurde soeben an jene E-Mail Adresse versendet, ' +
    'die Sie bei der Bestellung angegeben haben.',
  form: {
    title: 'Welche Dokumente wollen Sie abrufen?',
    warranty: 'Liefer- und Garantieschein',
    receipt: 'Kaufbeleg',
  },
};

const EmailProviderButton = {
  content: {
    GMAIL: 'Gmail öffnen',
    HOTMAIL: 'Hotmail öffnen',
    YAHOO: 'Yahoo öffnen',
    BLUEWIN: 'Bluewin öffnen',
    GMX: 'GMX öffnen',
    AOL: 'AOL öffnen',
  },
};

const fallBackPage = {
  breadcrumb: 'Inhalt verursacht Fehler',
  title: 'Uups, da ist etwas schiefgelaufen!',
  text:
    'Eine oder mehrere Inhalte können zurzeit nicht angezeigt werden und verursachen einen Fehler. Bitte versuchen Sie die Seite neu zu laden oder probieren Sie es zu einem späteren Zeitpunkt noch einmal. In der Zwischenzeit versuchen wir den Fehler zu beheben.',
};

const dangerousGoods = {
  title: 'Gefahrenpiktogramme',
};

const cookieBanner = {
  text:
    'Um unsere Webseite für Sie optimal zu gestalten und fortlaufend zu verbessern, die Nutzung zu analysieren und personalisierte Werbung zu schalten, verwenden wir Cookies und ähnliche Technologien. ',
  linkText: 'Erfahren Sie mehr.',
};

const appBanner = {
  title: 'Interdiscount-App',
  subTitle: 'Einkaufen wie es dir gefällt',
  cta: 'Öffnen',
};

export default {
  header,
  navigation,
  footer,
  footerContact,
  footerStore,
  cart,
  search,
  filter,
  checkout,
  productsOverview,
  searchTips,
  product,
  productFeatures,
  orderBox,
  form,
  formPayment,
  formDelivery,
  common,
  countries,
  guestLogin,
  login,
  myAccount,
  myAccountOrders,
  myAccountOrderDetails,
  myAccountUserData,
  myAccountAddresses,
  myAccountRatings,
  myAccountUserDataSection,
  myAccountPersonalData,
  myAccountPassword,
  myAccountLogin,
  myAccountAdditionalData,
  myAccountDeleteAccount,
  myAccountNewsletter,
  checkoutSuccess,
  paymentFailed,
  shoppingCart,
  pickupStore,
  formRegistration,
  downloadBox,
  storeFinder,
  paymentModal,
  breadcrumb,
  pageTitles,
  voucher,
  passwordReset,
  service,
  accountNotification,
  internalErrorPage,
  ageVerification,
  pageNotFound,
  brandProductsNotFound,
  apiErrorMessages,
  newsletterOptout,
  newsletterWidget,
  newsletterFeedback,
  loadingState,
  modal,
  timeSensitive,
  filterSelectbox,
  supercard,
  wall,
  filterSlider,
  popOverAccount,
  popOverCart,
  productVideo,
  cmsFallback,
  meta,
  discount,
  watchlist,
  recommendations,
  productComparison,
  orderConfirmationSteps,
  productPromotion,
  b2b,
  happyDayBrick,
  flashMessages,
  productRating,
  inventory,
  feedbackForm,
  mobileProtection,
  select,
  optIn,
  myAccountNotifications,
  returnsOverview,
  returns,
  coronaSpecial,
  deliveryStates,
  orderDocuments,
  EmailProviderButton,
  fallBackPage,
  dangerousGoods,
  filterToggle,
  mobileDrilldown,
  newsletterOptoutPersonalized,
  cookieBanner,
  deleteAccountConfirmationPage,
  appBanner,
};
/* eslint-enable no-useless-concat */
