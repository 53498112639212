import { Component, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { provideBreadcrumb } from 'constants/navigation/navigation';
import { getLocale } from 'constants/language/language';
import { BRAND, CMS } from 'constants/routePaths/routePaths';

export const mapStateToProps = (state) => ({
  currentPageId: state?.cms?.currentPageId ?? null,
  pages: state?.cms?.navigation?.pages || {},
});

export const filterBreadcrumbItems = (items, omitInBreadcrumb) =>
  items.filter((item) => !omitInBreadcrumb?.includes(item.name));

export class CmsBreadcrumb extends Component {
  static displayName = 'containers/CmsBreadcrumb/CmsBreadcrumb';

  static propTypes = {
    children: PropTypes.node.isRequired,
    pages: PropTypes.object,
    currentPageId: PropTypes.string,
  };

  static omitInBreadcrumb = [BRAND, CMS];

  shouldComponentUpdate(nextProps) {
    return nextProps.currentPageId !== this.props.currentPageId;
  }

  render() {
    const { pages, currentPageId, children } = this.props;
    const breadcrumbItems = provideBreadcrumb(pages, currentPageId, getLocale());
    return cloneElement(children, {
      ...this.props,
      items: filterBreadcrumbItems(breadcrumbItems, this.omitInBreadcrumb),
    });
  }
}

export default connect(mapStateToProps)(CmsBreadcrumb);
