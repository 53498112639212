import { cloneElement, Component } from 'react';
import { connect } from 'react-redux';
import {
  calculateResetQuery,
  computeQueryString,
  createFacetRangeQuery,
  mapQueryParamsToUrl,
  mapQueryStringToQueryParams,
  mergeQueryString,
} from 'constants/urlMapping/urlMapping';
import { searchQueryTypes } from 'components/organisms/CmsProductListing/CmsProductListing';
import PropTypes from 'prop-types';
import { loadProducts as loadProductsAction } from 'actions/productActions/productActions';
import { getLocale } from 'constants/language/language';
import { browserHistory } from 'react-router';
import omit from 'constants/helper/omit';
import {
  CategoryPropType,
  FacetPropType,
  getFacetRegExp,
  trackRemoveFilter,
  trackResetAllFacets,
  trackSelectFilter,
  trackSliderChange,
  trackSubmitFilter,
} from 'constants/facetsHelper/facetsHelper';
import { setMainMenuIsOpen as setMainMenuIsOpenAction } from 'actions/uiActions/uiActions';

// create a new array of unique facets.
// If a facet is selected, we have the same facetCategory multiple times in array 'facets'
export const getUniqueFacets = (facets = []) => {
  const uniqueFacets = [];
  facets.forEach((facet) => {
    const exists = uniqueFacets.findIndex((entry) => entry.code === facet.code);
    if (exists < 0) {
      uniqueFacets.push(facet);
    }
  });
  return uniqueFacets;
};

export const mapFacetProperties = (productsQuery = {}, state, ownProps) => {
  const apiStatus = productsQuery.apiStatus;
  let facets = getUniqueFacets(productsQuery?.facets || []);

  const categoryFacet = facets?.findLast((facet) => facet.code === 'categoryPath') || {};

  let selectedCategoryFacet;
  let selectedCategory = '';

  if (categoryFacet.selectedValues) {
    selectedCategoryFacet = categoryFacet.selectedValues
      ? categoryFacet.selectedValues[categoryFacet.selectedValues.length - 1]
      : null;
    selectedCategory = selectedCategoryFacet?.name;
  }

  //TODO: Remove Root Filter from Backend
  if (selectedCategoryFacet?.code === '/1') {
    selectedCategoryFacet = null;
    selectedCategory = null;
  }

  if (ownProps.isCategoryPage) {
    facets = facets.filter((facet) => facet.code !== 'categoryPath');
  } else {
    if (selectedCategory) {
      const categoryIndex = facets.findIndex((facet) => facet.code === 'categoryPath');
      facets[categoryIndex].removeQuery = selectedCategoryFacet && selectedCategoryFacet.query;
      facets[categoryIndex].removeQueryCode =
        selectedCategoryFacet && selectedCategoryFacet.code.split('/').slice(0, -1).join('/');
      facets[categoryIndex].showBackLink = true;

      // move category facet to the top
      let itemToMove = facets.splice(categoryIndex, 1)[0];
      facets.unshift(itemToMove);
    }

    // remove brand facet
    if (ownProps.isBrandPage && ownProps.searchQueryType !== searchQueryTypes.PROMOTION) {
      facets = facets.filter((facet) => facet.code !== 'brand');
    }
  }

  // remove selected categories from category list
  const categories = categoryFacet.values?.filter((category) => !category.selected);

  return {
    apiStatus,
    categories,
    facets,
    selectedCategory,
    categoryFacet,
  };
};

export const mapStateToProps = (state, ownProps) => {
  const productsQueries = state.productsQueries;
  const productsQuery = state.productsQueries.currentQuery;
  const facetProperties = mapFacetProperties(productsQuery, state, ownProps);
  const currentQueryString = productsQuery.queryString;

  return {
    ...facetProperties,
    productsQueries,
    productsQuery,
    currentQueryString,
    categoryTree: state.categories,
    routing: state.routing,
  };
};

export const mapDispatchToProps = {
  loadProducts: loadProductsAction,
  setMainMenuIsOpen: setMainMenuIsOpenAction,
};

class FacetsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { prefetchedQueryString: props.currentQueryString };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentQueryString !== this.props.currentQueryString) {
      this.setState({ prefetchedQueryString: this.props.currentQueryString });
    }
  }

  handleSliderChange = async ({ selectedMin, selectedMax, min, max, facetCode, location }) => {
    let facetRangeQuery = createFacetRangeQuery(this.props.currentQueryString, selectedMin, selectedMax, facetCode);
    if (selectedMax === max && selectedMin === min && facetCode === 'price') {
      facetRangeQuery = facetRangeQuery
        .replace('%3Aprice_min%3A' + selectedMin, '')
        .replace('%3Aprice_max%3A' + selectedMax, '');
    }

    await this.props.loadProducts(facetRangeQuery);
    this.setState({ prefetchedQueryString: facetRangeQuery });
    trackSliderChange(selectedMin, selectedMax, facetCode, location);
  };

  prefetchFilter = async (filterProps) => {
    if (!filterProps) {
      this.setState({ prefetchedQueryString: this.props.currentQueryString });
      return;
    }

    const { filter, facetCode, location, innerlocation } = filterProps;
    const currentQueryString = this.state.prefetchedQueryString || this.props.currentQueryString;
    const computedQueryString = computeQueryString(filter, facetCode, currentQueryString);
    await this.props.loadProducts(computedQueryString);
    this.setState({ prefetchedQueryString: computedQueryString });
    trackSelectFilter(filter, facetCode, location, innerlocation);
  };

  resetFacet = async (facetCode, index) => {
    const regExp = getFacetRegExp(facetCode);
    const oldQuery = this.props.currentQueryString.split('query=')[1];
    const facetQuery = oldQuery.replaceAll(regExp, '');
    const computedQueryString = mergeQueryString(this.props.currentQueryString, { facetQuery });
    await this.props.loadProducts(computedQueryString);
    this.routeToQuery(computedQueryString);
    trackRemoveFilter(facetQuery, oldQuery, index);
  };

  resetAllFacets = () => {
    const resetQuery = calculateResetQuery(this.props.currentQueryString);
    this.setState({ prefetchedQueryString: this.props.currentQueryString });
    this.routeToQuery(`query=${resetQuery}`);
    trackResetAllFacets();
  };

  submitFilters = () => {
    this.routeToQuery(this.state.prefetchedQueryString);
    trackSubmitFilter(this.props.productsQueries[this.state.prefetchedQueryString], this.props.categoryTree);
  };

  routeToQuery(queryString) {
    const queryParams = mapQueryStringToQueryParams(queryString);
    // remove the page property to turn to page 1
    const reducedParams = omit(queryParams, ['page']);
    const uri = mapQueryParamsToUrl(reducedParams, getLocale(), this.props.categoryTree) || `/${getLocale()}`;
    const { pathname, search } = new URL(uri, window.location.origin);
    browserHistory.push({ state: { disableScroll: true }, pathname, search });
    return uri;
  }

  categories;
  selectedCategory;
  totalNumberOfResults;

  render() {
    const { children, facets, apiStatus, categories, selectedCategory, productsQueries, productsQuery } = this.props;

    if (apiStatus === 'SUCCESS') {
      const prefetchedQuery = productsQueries?.[this.state.prefetchedQueryString];
      this.facets = facets; //should be unique already
      this.prefetchedFacets = prefetchedQuery?.facets || facets;
      this.categories = categories;
      this.selectedCategory = selectedCategory;
      this.totalNumberOfResults = (prefetchedQuery || productsQuery)?.pagination?.totalNumberOfResults;
    }

    return cloneElement(children, {
      facets: this.facets,
      categories: this.categories,
      selectedCategory: this.selectedCategory,
      handleSliderChange: this.handleSliderChange,
      submitFilters: this.submitFilters,
      categoryFacet: this.props.categoryFacet,
      prefetchFilter: this.prefetchFilter,
      totalNumberOfResults: this.totalNumberOfResults,
      hasFiltersApplied: this.state.prefetchedQueryString !== this.props.currentQueryString,
      resetFacet: this.resetFacet,
      resetAllFacets: this.resetAllFacets,
      prefetchedFacets: this.prefetchedFacets,
      setMainMenuIsOpen: this.props.setMainMenuIsOpen,
      routing: this.props.routing,
    });
  }
}

FacetsContainer.displayName = 'containers/FacetsContainer';
FacetsContainer.propTypes = {
  apiStatus: PropTypes.string,
  categories: PropTypes.arrayOf(CategoryPropType),
  children: PropTypes.element,
  currentQueryString: PropTypes.string,
  facets: PropTypes.arrayOf(FacetPropType),
  isBrandPage: PropTypes.bool,
  isCategoryPage: PropTypes.bool,
  isOpen: PropTypes.bool,
  selectedCategory: PropTypes.string,
  setFilterMenuClose: PropTypes.func,
  setFilterMenuIsOpen: PropTypes.func,
  categoryTree: PropTypes.object,
  productsQueries: PropTypes.object,
  productsQuery: PropTypes.object,
  categoryFacet: FacetPropType,
  loadProducts: PropTypes.func,
  setMainMenuIsOpen: PropTypes.func,
  routing: PropTypes.object,
};
FacetsContainer.defaultProps = {
  isCategoryPage: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(FacetsContainer);
