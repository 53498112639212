export const getProvider = (mailAddress = '') => {
  const regex = /@[^.]+(?=\.)/g;
  const matches = mailAddress.match(regex);

  if (matches && matches.length) {
    return matches[0].replace('@', '').toUpperCase();
  }

  return null;
};

export const isProviderSupported = (provider = '') => {
  if (!provider) return null;

  switch (provider.toUpperCase()) {
    case 'GMAIL':
    case 'HOTMAIL':
    case 'YAHOO':
    case 'BLUEWIN':
    case 'GMX':
    case 'AOL':
      return true;
    default:
      return false;
  }
};

export default {
  isProviderSupported,
  getProvider,
};
