import { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { logoutUser as isLogoutUser } from 'actions/userActions/userActions';

import { isLoggedIn as loggedIn } from 'constants/user/user';
import { getLocale } from 'constants/language/language';

const mapDispatchToProps = {
  logoutUser: isLogoutUser,
};

export const mapStateToProps = (state) => ({
  user: state.user,
  isLoggedIn: loggedIn(state.user),
});

const AccountSectionContainer = ({ children, isLoggedIn, user, logoutUser }) => {
  const handleLogout = () => {
    const redirect = user?.isLinkedWithScid ? '/supercard/api/logout' : undefined;
    logoutUser(redirect).then(() => {
      window.location.pathname = redirect ?? `/${getLocale()}`; // re-direct to homepage
    });
  };

  return cloneElement(children, {
    isLoggedIn,
    user,
    logoutUser: handleLogout,
  });
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountSectionContainer);

AccountSectionContainer.propTypes = {
  children: PropTypes.node,
  isloggedIn: PropTypes.bool,
  user: PropTypes.object,
  logoutUser: () => {},
};

AccountSectionContainer.defaultProps = {
  categories: {},
};
