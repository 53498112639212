import queryString from 'query-string';

export const paginationMapping = (result) => {
  const tempPagination = result.pagination;

  const pagination = {
    ...tempPagination,
    currentPage: tempPagination + 1,
  };

  return {
    ...result,
    pagination,
  };
};

// todo: extend unit test for new scenarios
export const reversePaginationMapping = (url) => {
  const queryURLObject = url ? queryString.parse(url) : {};
  const currentPage = queryURLObject.currentPage;
  const currentPageURL = queryString.stringify({ currentPage });

  const mappedPage = queryURLObject.currentPage ? parseInt(queryURLObject.currentPage, 10) - 1 : 0;

  if (url) {
    let urlWithoutPageNumber = url.replace(currentPageURL, '');
    // to ensure a valid url
    urlWithoutPageNumber = urlWithoutPageNumber.charAt(0) === '&' ? urlWithoutPageNumber : `&${urlWithoutPageNumber}`;
    return `currentPage=${mappedPage}${urlWithoutPageNumber}`;
  }

  return `currentPage=${mappedPage}`;
};
