// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { ProductViewTypes } from 'constants/ProductViewTypes/ProductViewTypes';
import { noop } from 'constants/noop/noop';
import language from 'constants/language/language';
import Icon, { ICON_ARROW_DROPDOWN } from 'components/atoms/Icon/Icon';
import styles from './GridListToggle.scss';

const GridListToggle = ({ onToggle = noop, viewType = ProductViewTypes.GRID }) => {
  const viewTypes = {
    GRID: language('productsOverview.viewType.gridView'),
    LIST: language('productsOverview.viewType.listView'),
  };

  return (
    <div className={styles.wrapper}>
      <select onChange={(e) => onToggle(e.target.value)} value={viewType}>
        {Object.keys(viewTypes).map((key) => (
          <option key={key} value={key}>
            {viewTypes[key]}
          </option>
        ))}
      </select>
      <Icon path={ICON_ARROW_DROPDOWN} className={styles.icon} />
    </div>
  );
};

GridListToggle.displayName = 'molecules/GridListToggle';

GridListToggle.propTypes = {
  onToggle: PropTypes.func,
  viewType: PropTypes.oneOf(Object.values(ProductViewTypes)),
};

export default GridListToggle;
