import restClient from 'api/RestClient/RestClient';
import { resourceKeys } from 'constants/restResources/restResources';
import { categoryTree } from 'constants/categoryTree/categoryTree';
import { TTL_ONE_YEAR } from 'constants/cache/cache';

const resource = resourceKeys.BASE_RESOURCE;

const CategoryAPI = (function CategoryAPI() {
  // TODO / REFACTOR: (https://interdiscount.atlassian.net/browse/DCP-2645)
  let categories = {};

  const getCategories = ({ refreshCache } = { refreshCache: false }) =>
    restClient
      .get(resource, '/menuCategories', false, {
        // Menu-Categories are refreshed (passively) in background by a cron job in app/entry.server.js.
        // Therefore we set the cache TTL here to (basically) never expire to ensure the active/blocking request
        // for this data by framework "needs" functionality (e.g. DefaultLayout.js) is always served from cache.
        cacheInSec: TTL_ONE_YEAR,
        refreshCache,
      })
      .then((res) => {
        categoryTree.set(res.data);
        categories = res.data;
        return res;
      });

  const provideInitialState = () => ({
    categories,
  });

  return {
    provideInitialState,
    getCategories,
  };
})();

export default CategoryAPI;
