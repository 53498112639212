import language from 'constants/language/language';

// Returns the feature values which will be displayed
// in main product info as well as on the feature list
const getFeatureValues = (values = [], isRanged = false) => {
  if (!values.length) {
    return null;
  } else if (values.length === 1) {
    return values[0].value;
  }

  // combine multiple values
  const featureValues = values.map((v) => v.value);
  const lastValue = featureValues.pop();
  // eslint-disable-next-line max-len
  return `${featureValues.join(', ')} ${
    isRanged ? language('productFeatures.to') : language('productFeatures.and')
  } ${lastValue}`;
};

export default getFeatureValues;
