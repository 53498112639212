import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import dataTracking from 'constants/trackingAttributes/dataTracking';
import styles from './InputRadio.scss';

const InputRadio = ({
  children,
  disabled = false,
  name,
  onClick,
  selected = false,
  showError = false,
  cyData,
  trackingAttributes,
  boldLabels = false,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const onClickHandler = () => {
    onClick();
  };

  return (
    <label
      className={cx({
        [styles.wrap]: true,
        [styles.boldLabel]: boldLabels,
        [styles.hasDanger]: showError && !isFocused,
        [styles.disabled]: disabled,
      })}
    >
      <i
        className={cx({
          [styles.indicator]: true,
          [styles.isChecked]: selected,
          [styles.hasDanger]: showError && !isFocused,
          [styles.hasFocus]: isFocused,
          [styles.disabled]: disabled,
        })}
      />
      <input
        type="radio"
        name={name}
        value={children}
        onFocus={() => {
          setIsFocused(true);
        }}
        onBlur={() => {
          setIsFocused(false);
        }}
        checked={selected}
        onChange={onClickHandler}
        className={styles.input}
        disabled={disabled}
        data-cy={cyData}
        data-tracking={dataTracking(trackingAttributes)}
      />
      <div className={styles.labelWrapper}>{children}</div>
    </label>
  );
};

InputRadio.displayName = 'atoms/InputRadio';
InputRadio.propTypes = {
  children: PropTypes.any.isRequired,
  disabled: PropTypes.bool,
  boldLabels: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  showError: PropTypes.bool,
  cyData: PropTypes.string,
  trackingAttributes: PropTypes.object,
};

export default InputRadio;
