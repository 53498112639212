import { handleActions } from 'redux-actions';
import { apiStatus, apiResponseRecaptchaNeeded } from 'constants/apiStatus/apiStatus';

import {
  GET_USER_SUCCESS,
  GET_USER_REQUEST,
  GET_USER_CONSENTS_REQUEST,
  GET_USER_CONSENTS_SUCCESS,
  UPDATE_USER_CONSENTS_SUCCESS,
  BLUR_SUPERCARD_FIELD,
  UPDATE_SUPERCARD_FIELD,
  ADD_SUPERCARD_SUCCESS,
  ADD_SUPERCARD_FAILURE,
  ADD_SUPERCARD_NETWORKERROR,
  RESET_SUPERCARD_VALIDATION,
  GET_ONLINE_ID_SUCCESS,
  GET_MY_STORE_SUCCESS,
  UPDATE_USER_FAILURE,
  ROUTER_UPDATE_LOCATION,
  RESEND_DOUBLE_OPTIN_MAIL_FAILURE,
  UPDATE_USER_CONSENTS_REQUEST,
  UPDATE_USER_CONSENTS_FAILURE,
  UPDATE_USER_CONSENTS_NETWORKERROR,
} from 'constants/ActionTypes/ActionTypes';

import validation, { ValidationTypes } from 'constants/validation/validation';

import transform from 'constants/reducerHelper/reducerHelper';
import { DSGV_CONSENTS } from 'constants/storage/storageKeys';

import userBase, { initialState } from './user.base';

const user = {
  [GET_USER_REQUEST]: (state) =>
    transform({ ...state })
      .set('apiStatus', apiStatus.request)
      .value(),

  [GET_USER_SUCCESS]: (state, action) => {
    const data = action.req?.data || {};
    const fields = data.fields || {};
    const storeDistance = state?.pointOfServiceData?.formattedDistance;

    localStorage.setItem(DSGV_CONSENTS, JSON.stringify(data.consents));

    return transform({
      ...state,
      ...data,
    })
      .set('toUpdate', {
        ...state.toUpdate,
        firstName: fields.firstName, // missing in initial state
        lastName: fields.lastName, // missing in initial state
        titleCode: fields.titleCode, // missing in initial state
        supercardCode: fields.supercardCode,
        employeeEan: fields.employeeEan,
        language: fields.language,
      })
      .set('pointOfService', data?.pointOfService || '')
      .set('pointOfServiceData', data?.pointOfServiceData || {})
      .set('pointOfServiceData.formattedDistance', storeDistance)
      .set('apiStatus', apiStatus.success)
      .omit(['addresses', 'defaultShippingAddress', 'defaultBillingAddress'])
      .value();
  },

  [GET_USER_CONSENTS_REQUEST]: (state) => {
    return transform(state).set('consentsApiStatus', apiStatus.request).value();
  },

  [GET_USER_CONSENTS_SUCCESS]: (state, action) => {
    const data = action.req?.data || {};
    const newState = transform(state)
      .set('consents', data.consents)
      .set('consentsData', data)
      .set('consentsApiStatus', apiStatus.success);
    localStorage.setItem(DSGV_CONSENTS, JSON.stringify(data.consents));
    return newState.value();
  },

  [UPDATE_USER_CONSENTS_SUCCESS]: (state, action) => {
    const data = action.req?.data || {};
    const newState = transform(state)
      .set('consentsData', data)
      .set('consents', data.consents)
      .set('consentsApiStatus', apiStatus.success);
    localStorage.setItem(DSGV_CONSENTS, JSON.stringify(data.consents));
    return newState.value();
  },

  [UPDATE_USER_CONSENTS_REQUEST]: (state) => transform(state).set('consentsApiStatus', apiStatus.request).value(),

  [UPDATE_USER_CONSENTS_FAILURE]: (state) => transform(state).set('consentsApiStatus', apiStatus.failure).value(),

  [UPDATE_USER_CONSENTS_NETWORKERROR]: (state) =>
    transform(state).set('consentsApiStatus', apiStatus.networkerror).value(),

  [BLUR_SUPERCARD_FIELD]: (state) => {
    const newState = transform(state);

    const supercardCodeValue = state?.fields?.supercardCode?.value;
    const fields = state?.fields;

    if (supercardCodeValue) {
      newState.set('fields.supercardCode.blurred', true);
    }

    const errorTypes = [ValidationTypes.Missing, ValidationTypes.PasswordToWeak, ValidationTypes.Invalid];

    const firstErrorKey = Object.keys(fields).find((key) => errorTypes.includes(fields[key].validationResult));
    const firstError = fields[firstErrorKey];

    newState.set('firstError', firstError);
    newState.set('valid', !Object.keys(firstError || {}).length);

    return newState.value();
  },

  [UPDATE_SUPERCARD_FIELD]: (state, action) => {
    const newState = transform(state);

    const value = action.payload?.value;
    const validationResult = validation.supercardCode(value);

    newState.set('fields.supercardCode.validationResult', validationResult);
    if ([ValidationTypes.Valid, ValidationTypes.Initial].includes(validationResult)) {
      newState.set('fields.supercardCode.value', value);
    }

    const fields = newState.value()?.fields;
    const errorTypes = [ValidationTypes.Missing, ValidationTypes.PasswordToWeak, ValidationTypes.Invalid];
    const firstErrorKey = Object.keys(fields).find((key) => errorTypes.includes(fields[key].validationResult));
    const firstError = fields[firstErrorKey];

    return newState
      .set('firstError', firstError)
      .set('valid', !Object.keys(firstError || {}).length)
      .value();
  },

  [ADD_SUPERCARD_SUCCESS]: (state, action) => {
    const newState = transform(state);

    newState
      .set('fields.supercardCode.validationResult', ValidationTypes.Valid)
      .set('fields.supercardCode.value', action.payload.supercardCode);

    return newState.value();
  },

  [ADD_SUPERCARD_FAILURE]: (state) => {
    const newState = transform(state);

    newState.set('fields.supercardCode.validationResult', ValidationTypes.Invalid);

    return newState.value();
  },

  [ADD_SUPERCARD_NETWORKERROR]: (state) => {
    const newState = transform(state);

    newState.set('fields.supercardCode.validationResult', ValidationTypes.NetworkError);

    return newState.value();
  },

  [RESET_SUPERCARD_VALIDATION]: (state) => {
    const newState = transform(state);

    newState.set('fields.supercardCode.validationResult', ValidationTypes.Initial);

    const fields = newState.value()?.fields;
    const errorTypes = [
      ValidationTypes.Missing,
      ValidationTypes.TakenEmail,
      ValidationTypes.PasswordToWeak,
      ValidationTypes.Invalid,
      ValidationTypes.InvalidNot16,
    ];
    const firstErrorKey = Object.keys(fields).find((key) => errorTypes.includes(fields[key].validationResult));
    const firstError = fields[firstErrorKey];

    return newState
      .set('firstError', firstError)
      .set('valid', !Object.keys(firstError || {}).length)
      .value();
  },

  [GET_MY_STORE_SUCCESS]: (state, action) => {
    const newState = transform(state);
    const myStore = action.req?.data || {};

    newState.set('pointOfServiceData.formattedDistance', myStore.formattedDistance);

    return newState.value();
  },

  [UPDATE_USER_FAILURE]: (state, action) => {
    const newState = transform(state);
    const errors = action.error?.data?.errors || [];

    // handle backend errors
    errors.forEach((error) => {
      if (error.type === 'ValidationError') {
        newState.set(`toUpdate.${error.subject}.validationResult`, ValidationTypes.Invalid);
        newState.set('valid', false);
      }

      if (error.type === apiResponseRecaptchaNeeded) {
        newState.set(`toUpdate.employeeEan.validationResult`, ValidationTypes.Invalid);
        newState.set('valid', false);
        newState.set('recaptchaNeeded', true);
      }
    });

    return newState.value();
  },
  [ROUTER_UPDATE_LOCATION]: (state) =>
    transform({
      ...state,
    })
      .set('toUpdate', {
        ...state.toUpdate,
        email: {
          name: 'email',
          validationResult: ValidationTypes.Missing,
          required: true,
        },
        password: {
          name: 'password',
          validationResult: ValidationTypes.Missing,
          required: true,
        },
        birthday: {
          name: 'birthday',
          validationResult: ValidationTypes.Missing,
          required: true,
        },
        oldPassword: {
          name: 'oldPassword',
          validationResult: ValidationTypes.Missing,
          required: true,
        },
        newPassword: {
          name: 'newPassword',
          validationResult: ValidationTypes.Missing,
          required: true,
        },
      })
      .value(),

  [RESEND_DOUBLE_OPTIN_MAIL_FAILURE]: (state, action) => {
    const isRecaptchaRequired = !action.error?.data?.errors?.includes((error) => error?.message === 're-captcha');
    return {
      ...state,
      recaptchaNeeded: isRecaptchaRequired,
      resetCaptcha: isRecaptchaRequired,
    };
  },

  [GET_ONLINE_ID_SUCCESS]: (state, action) => {
    const data = action.req?.data || {};
    return transform(state).set('onlineId', data.onlineId).value();
  },
};

const mergedReducers = Object.assign(user, userBase);

export default handleActions(mergedReducers, initialState);
