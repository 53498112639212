import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { getEnv } from 'config/config';
import language, { getLocale } from 'constants/language/language';
import { awards } from 'constants/routePaths/routePathCombinations';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import { Col, Row } from 'components/atoms/Grid/Grid';
import LogoStore from 'components/atoms/LogoStore/LogoStore';
import AddressStore from 'components/atoms/AddressStore/AddressStore';
import OpeningHoursTimeRange from 'components/atoms/OpeningHoursTimeRange/OpeningHoursTimeRange';
import StoreFinderQuantityText from 'components/molecules/StoreFinderListItem/StoreFinderQuantityText';
import bootstrap from 'scss/component.scss';
import styles from './StoreFinderListItem.scss';

const StoreFinderListItemDetail = ({
  format = '',
  type = {},
  description = '',
  address = {},
  formattedDistance = '',
  openingHours = {},
  awardUrl = '',
  isMyStore = false,
  availableQuantity = undefined,
  showDistance = false,
  availability = '',
  releaseDate = '',
}) => (
  <Col lg={9}>
    <Row>
      <Col xxs={12} sm={3} lg={3} className={styles.logo}>
        {format && <LogoStore code={format} />}
      </Col>
      <Col xxs={10} sm={7} md={6} lg={6} className={styles.address}>
        {description && (
          <AddressStore
            name={description}
            type={type ? type.code : null}
            streetName={address ? address.streetName : null}
            streetNumber={address ? address.streetNumber : null}
            postalCode={address ? address.postalCode : null}
            location={address ? address.town : null}
            isMyStore={isMyStore}
          />
        )}
        {awardUrl && (
          <div className={styles.promoImageWrapper}>
            <Link to={mapPathToLocalizedUrl(getLocale(), awards, false)} target="_blank">
              <img src={`${getEnv('api')}${awardUrl}`} alt="award" className={styles.awardIcon} />
            </Link>
          </div>
        )}
      </Col>
      <Col xxs={2} md={3} className={bootstrap.hiddenLgUp}>
        {showDistance && (
          <p className={styles.distance}>
            <strong>
              {formattedDistance} <span className={bootstrap.hiddenSmDown}>{language('pickupStore.away')}</span>
            </strong>
          </p>
        )}
      </Col>
      <Col xxs={12} sm={9} offsetSm={3} lg={3} offsetLg={0} className={styles.info}>
        {availableQuantity !== undefined && (
          <StoreFinderQuantityText quantity={availableQuantity} availability={availability} releaseDate={releaseDate} />
        )}
        <div className={bootstrap.hiddenMdDown}>
          {showDistance && (
            <p className={styles.distance}>
              <strong>
                {formattedDistance} {language('pickupStore.away')}
              </strong>
            </p>
          )}
        </div>
        <div className={styles.openTodayWrapper}>
          {openingHours?.today?.closed === false ? (
            <>
              <div className={styles.openToday}>{language('pickupStore.openToday')}</div>
              <div className={styles.openTodayHours}>
                <OpeningHoursTimeRange
                  openingDayTimeRangeDataList={openingHours.today.openingDayTimeRangeDataList}
                  formattingStyleDot={true}
                />
              </div>
            </>
          ) : (
            <div className={styles.openTodayHours}>{language('pickupStore.closed')}</div>
          )}
        </div>
      </Col>
    </Row>
  </Col>
);

StoreFinderListItemDetail.displayName = 'components/molecules/StoreFinderListItem/StoreFinderListItemDetail';

StoreFinderListItemDetail.propTypes = {
  format: PropTypes.string,
  type: PropTypes.object,
  description: PropTypes.string,
  formattedDistance: PropTypes.string,
  address: PropTypes.object,
  openingHours: PropTypes.object,
  awardUrl: PropTypes.string,
  isMyStore: PropTypes.bool,
  showDistance: PropTypes.bool,
  availableQuantity: PropTypes.number,
  availability: PropTypes.string,
  releaseDate: PropTypes.string,
};

export default StoreFinderListItemDetail;
