import React from 'react';
import { SLOTS } from 'constants/cms/slots';
import CmsSlot from 'components/organisms/CmsSlot/CmsSlot';

// eslint-disable-next-line
const CmsPagesBefore = (props) => <CmsSlot position={SLOTS.BEFORE} {...props} />;

CmsPagesBefore.displayName = 'organisms/CmsPagesBefore';

export default CmsPagesBefore;
