export const NON_CMS_PAGE = 'NON_CMS_PAGE';
export const MAIN_WALL_ID = 'MAIN_WALL_ID';
// these are actual page UIDs determined by the backend:
export const WALL_OF_ALL_ID = 'wallofall';
export const HOME_PAGE = 'HomePage';
export const CMS_UID_PAYMENT = 'payment-services';
export const CMS_UID_SERVICE = 'ServicePage';
export const CMS_UID_COMPANY = 'CompanyPage';
export const CMS_UID_LOCATIONS = 'page_unternehmen_standorte';
export const CMS_UID_TERMS = 'terms';
export const CMS_UID_BROCHURE = 'BrochurePage';
export const CMS_UID_CATEGORY_LANDINGPAGE = 'LP_CM5_Willkommen';
export const CMS_UID_RATING_TERMS = 'RatingTerms';
export const CMS_UID_RATING_GUIDELINES = 'RatingGuidelines';
export const CMS_UID_SUPERCARD = 'supercard';
export const CMS_UID_FAQ = 'Blog_FAQ_Corona';
export const CMS_UID_RETURNS_OVERVIEW = 'ServiceReturnsOverviewPage';
export const CMS_UID_RETURNS_CREATION = 'ServiceReturnsCreationPage';
export const CMS_UID_RETURNS_POLICY = 'reprise';
export const CMS_UID_TRANSPORT_DAMAGE = 'page_transport_damage';
export const CMS_UID_REPAIR = 'ServiceRepairPage';
export const CMS_UID_BLOG = 'navigationNode_00000RUH';
