import axios from 'axios';
import queryString from 'query-string';
import { postLog } from 'api/promiseMiddleware';
import { composeRecoTrackingUrl, composeRecoTopProductsUrl } from 'api/RecoAPI/RecoAPI.helper';

/**
 * An enum containing the param names for the recoURL
 */
const recoParamNames = {
  CATEGORY_ID: 'cid',
  PRODUCT_ID: 'pid',
  TEMPLATE: 'template',
  BOX: 'box',
  ITEM_IDS: 'pids',
  QUANTITIES: 'quantities',
  UNIT_TOTAL_PRICE: 'totals',
  SEARCH_TERM: 'searchterm',
  TRACKING: 'tracking',
};

/**
 * An enum containing the event names for the Reco Engine
 */
export const recoTrackingEndpoints = {
  PRODUCTS_OVERVIEW: 'categoryview',
  PRODUCT_DETAIL: 'productview',
  CLICK_ON_PRODUCT: 'click',
  ADD_TO_CART: 'basket',
  CHECKOUT: 'order',
  USER_TO_SESSION: 'usertosession',
  SEARCH: 'search',
  NULL_RESULT: 'nullresult',
  ERROR: 'error',
  HOME: 'home',
  CATEGORY: 'category',
  EMPTY_BRAND: 'emptybrand',
};

const fetchRecommendedProducts = (requestUrl) => axios.get(requestUrl);

const fetchTopRecommendations = (sessionId, type, locale, currentBasket, params = {}) => {
  try {
    const url = composeRecoTopProductsUrl(sessionId, type, locale, currentBasket, params);
    return axios.get(url);
  } catch (ex) {
    postLog(ex);
  }
};

/**
 * A function for actually firing the requests to the reco engine
 *
 * @param requestUrl {string} - The url containing all parameters and necessary ID's to send to the Reco Engine
 */
const sendRecoTrackingData = async (requestUrl) => {
  try {
    return await axios.get(requestUrl);
  } catch (error) {
    postLog(error);
    return error;
  }
};

/**
 * Responsible for tracking views of the product overview
 *
 * @param categoryId {string} - The ID of the category that is viewed
 * @param sessionId {string} - The reco-specific session id of the user
 */
const recoTrackProductsOverview = (categoryId, sessionId, searchTerm = null) => {
  let paramString = '';

  if (searchTerm) {
    const normalizedSearchTerm = normalizeSearchString(searchTerm);
    paramString = `${recoParamNames.SEARCH_TERM}=${normalizedSearchTerm}`;
  } else {
    paramString = `${recoParamNames.CATEGORY_ID}=${categoryId}`;
  }

  try {
    const url = composeRecoTrackingUrl(
      searchTerm ? recoTrackingEndpoints.SEARCH : recoTrackingEndpoints.PRODUCTS_OVERVIEW,
      sessionId,
      paramString
    );

    sendRecoTrackingData(url);
  } catch (error) {
    postLog(error);
  }
};

/**
 * Responsible for tracking views of the product detail view
 *
 * @param product {object} - The product with all accompanying parameters
 * @param sessionId {string} - The reco-specific session id of the user
 */
const recoTrackProductDetail = (product, sessionId) => {
  const productId = product.code || '';

  try {
    const paramString = `${recoParamNames.PRODUCT_ID}=${productId}`;
    const url = composeRecoTrackingUrl(recoTrackingEndpoints.PRODUCT_DETAIL, sessionId, paramString);

    sendRecoTrackingData(url);
  } catch (error) {
    postLog(error);
  }
};

/**
 * Responsible for tracking products in the current cart state
 *
 * @param products {[object]} - An object containing all product information
 * @param sessionId {string} - The reco-specific session id of the user
 */
const recoTrackAddToCart = (products, sessionId) => {
  const recoCartData = {
    [recoParamNames.ITEM_IDS]: products.map((product) => product.productCode).join(','),
    [recoParamNames.QUANTITIES]: products.map((product) => product.quantity).join(','),
  };

  try {
    const params = queryString.stringify(recoCartData);
    const url = composeRecoTrackingUrl(recoTrackingEndpoints.ADD_TO_CART, sessionId, params);

    sendRecoTrackingData(url);
  } catch (error) {
    postLog(error);
  }
};

/**
 * Responsible for tracking the product data on checkout
 *
 * @param products {[object]} - An object containing product information: {id: string, quantity: number, price: number}
 * @param sessionId {string} - The reco-specific session id of the user
 */
const recoTrackCheckout = (products, sessionId) => {
  const recoCheckoutData = {
    [recoParamNames.ITEM_IDS]: products.map((product) => product.productCode).join(','),
    [recoParamNames.QUANTITIES]: products.map((product) => product.quantity).join(','),
    [recoParamNames.UNIT_TOTAL_PRICE]: products.map((product) => product.totalPrice.value).join(','),
  };
  /* Only send request if all the necessary data is defined */
  if (
    recoCheckoutData[recoParamNames.ITEM_IDS] &&
    recoCheckoutData[recoParamNames.QUANTITIES] &&
    recoCheckoutData[recoParamNames.UNIT_TOTAL_PRICE]
  ) {
    try {
      const params = queryString.stringify(recoCheckoutData);
      const url = composeRecoTrackingUrl(recoTrackingEndpoints.CHECKOUT, sessionId, params);

      sendRecoTrackingData(url);
    } catch (error) {
      postLog(error);
    }
  }
};

/**
 * Responsible for mapping the current user to the reco session
 *
 * @param {string} sessionId
 * onlineId gets added automatically inside composeRecoTrackingUrl
 */
const recoMapUserToSession = (sessionId) => {
  try {
    const url = composeRecoTrackingUrl(recoTrackingEndpoints.USER_TO_SESSION, sessionId);

    sendRecoTrackingData(url);
  } catch (e) {
    postLog(e);
  }
};

/**
 * Responsible for tracking clicks within the reco box
 *
 * @param {string} productId
 * @param {string} trackingToken
 * @param {string} sessionId
 */
const recoTrackClickOnProduct = (productId, trackingToken, sessionId) => {
  try {
    const encToken = encodeURIComponent(trackingToken);
    if (encToken && encToken.length > 0) {
      const paramString = `${recoParamNames.PRODUCT_ID}=${productId}&trackingtoken=${encToken}`;
      const url = composeRecoTrackingUrl(recoTrackingEndpoints.CLICK_ON_PRODUCT, sessionId, paramString);
      sendRecoTrackingData(url);
    }
  } catch (error) {
    postLog(error);
  }
};

export const normalizeSearchString = (searchTerm = '') => {
  if (typeof searchTerm !== 'string') return '';
  return searchTerm
    .replace(/[|%?&\\/:"]/g, '')
    .replace(/\s+/g, '-')
    .substr(0, 150);
};

/**
 * Responsible for tracking product search
 *
 * @param {string} searchTerm
 * @param {string} sessionId
 */
const recoTrackProductSearch = (searchTerm, sessionId) => {
  try {
    const normalizedSearchTerm = normalizeSearchString(searchTerm);

    const paramString = `${recoParamNames.SEARCH_TERM}=${normalizedSearchTerm}`;

    const url = composeRecoTrackingUrl(recoTrackingEndpoints.SEARCH, sessionId, paramString);

    sendRecoTrackingData(url);
  } catch (error) {
    postLog(error);
  }
};

export {
  recoParamNames,
  fetchRecommendedProducts,
  fetchTopRecommendations,
  sendRecoTrackingData,
  recoTrackProductsOverview,
  recoTrackProductDetail,
  recoTrackAddToCart,
  recoTrackCheckout,
  recoMapUserToSession,
  recoTrackClickOnProduct,
  recoTrackProductSearch,
};
