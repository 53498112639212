import React from 'react';
import PropTypes from 'prop-types';
import OpeningHoursTimeRange from 'components/atoms/OpeningHoursTimeRange/OpeningHoursTimeRange';
import formatDate from 'constants/formatDate/formatDate';
import language from 'constants/language/language';
import styles from './OpeningHoursSpecialDay.scss';

const OpeningHoursSpecialDay = ({ name, date, closed, openingDayTimeRangeDataList }) => (
  <>
    {!!name && <div className={styles.name}>{name}</div>}
    <div className={styles.wrapper}>
      <div className={`${styles.cell} ${styles.th} ${name ? styles.hasName : ''}`}>{formatDate(date)}</div>
      <div className={`${styles.cell} ${name ? styles.hasName : ''}`}>
        {closed
          ? language('pickupStore.closed')
          : !!openingDayTimeRangeDataList && (
              <OpeningHoursTimeRange
                openingDayTimeRangeDataList={openingDayTimeRangeDataList}
                formattingStyleDot={true}
              />
            )}
      </div>
    </div>
  </>
);

OpeningHoursSpecialDay.displayName = 'atoms/OpeningHoursSpecialDay';
OpeningHoursSpecialDay.propTypes = {
  name: PropTypes.string,
  date: PropTypes.number.isRequired,
  closed: PropTypes.bool.isRequired,
  openingDayTimeRangeDataList: PropTypes.array.isRequired,
};

export default OpeningHoursSpecialDay;
