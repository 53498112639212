// @flow
import type { Cart } from 'reducers/cart/cart';
import deliveryModes from 'constants/deliveryModes/deliveryModes';
import type { StockLevelStatus } from 'constants/ProductStatus/ProductStatus';
import { stockLevelStatus } from 'constants/ProductStatus/ProductStatus';

export const NEUTRAL_PRODUCT_FLAG: string = 'neutralDeliveryPossible';

/**
 * Indicates whether the cart contains products with the neutral delivery option.
 *
 * @param  {object} The cart object from redux state
 * @return {boolean}
 */
const hasProductsWithNeutralDelivery = (cart: Object = { entries: [] }): boolean =>
  cart.entries.filter((entry) => entry.product[NEUTRAL_PRODUCT_FLAG]).length > 0;

/**
 * Indicates whether the cart contains products WITHPOUT the neutral delivery option.
 *
 * @param  {object} The cart object from redux state
 * @return {boolean}
 */
const hasProductsWithoutNeutralDelivery = (cart: Object = { entries: [] }): boolean =>
  cart.entries.filter((entry) => !entry.product[NEUTRAL_PRODUCT_FLAG]).length > 0;

/**
 * Indicates whether the selected delivery mode is homeDelivery
 *
 * @param  {object} The cart object from redux state
 * @return {boolean}
 */
const isHomeDeliverySelected = (cart: Cart): boolean =>
  cart?.deliveryModes?.[deliveryModes?.homeDelivery]?.selected ?? false;

/**
 * Indicates whether to show or hide the neutral delivery notice
 *
 * @param  {object} The cart object from redux state
 * @return {boolean}
 */
const showNeutralDeliveryNotice = (cart: Object = { entries: [] }): boolean =>
  // Is home-delivery selected?
  isHomeDeliverySelected(cart) &&
  // Is neutral delivery selected at all?
  cart.neutralDeliveryActive &&
  // Are there products WITH and WITHOUT neutral delivery in our cart?
  hasProductsWithNeutralDelivery(cart) &&
  hasProductsWithoutNeutralDelivery(cart);

/**
 * Checks if the given array only includes products with ESD availability status
 *
 * @param {Array<StockLevelStatus>} productsAvailabilityStatus - The availability status of all products to test against
 * @returns {boolean} - If the given array only includes products with ESD availability status
 */
export const isEsdOnlyCart = (productsAvailabilityStatus: Array<StockLevelStatus>): boolean =>
  productsAvailabilityStatus.every((status) => status === stockLevelStatus.m);

/**
 * Checks if the given array includes products with ESD availability status and other products
 *
 * @param {Array<StockLevelStatus>} productsAvailabilityStatus - The availability status of all products to test against
 * @returns {boolean} - If the given array includes some products with ESD availability status
 */
export const isEsdMixedCart = (productsAvailabilityStatus: Array<StockLevelStatus>): boolean =>
  !isEsdOnlyCart(productsAvailabilityStatus) &&
  productsAvailabilityStatus.some((status) => status === stockLevelStatus.m);

/**
 * Calculates the cart total difference until home delivery is free.
 */
export const getDifferenceToFreeDelivery = (surchargeThresholdValue: number, subTotalValue: number): number => {
  if (
    typeof surchargeThresholdValue !== 'number' ||
    typeof subTotalValue !== 'number' ||
    subTotalValue >= surchargeThresholdValue
  ) {
    return 0;
  }

  return surchargeThresholdValue - subTotalValue;
};

const CartUtils = {
  hasProductsWithNeutralDelivery,
  showNeutralDeliveryNotice,
};

export default CartUtils;
