import React from 'react';
import PropTypes from 'prop-types';
import styles from './HorizontalRule.scss';

const HorizontalRule = ({ text }) =>
  text ? <div className={styles.textHr}>{text}</div> : <hr className={styles.hr} />;

HorizontalRule.propTypes = {
  text: PropTypes.string,
};

export default HorizontalRule;
