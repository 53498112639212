import React from 'react';
import PropTypes from 'prop-types';

import RecoTracking from 'containers/RecoTracking/RecoTracking';
import Slider from 'components/organisms/Slider/Slider';
import ProductCard from 'components/molecules/ProductCard/ProductCard';
import Headline, { tags } from 'components/atoms/Headline/Headline';
import language from 'constants/language/language';
import chunk from 'constants/helper/chunk';
import styles from './ProductRecoSlider.scss';

const ProductRecoSlider = ({
  categories = {},
  productClickHandler = () => {},
  products = [],
  title = '',
  trackingAttributes = {},
  slidesPerView = 1,
  sliderReset,
}) => {
  const renderSlides = (chunk, slideIndex) => (
    <div className={styles.slide} key={slideIndex}>
      {chunk.map((product, index) => (
        <div className={styles.productWrapper} key={product.code}>
          <RecoTracking product={product} code={product.code}>
            <ProductCard
              product={product}
              productClickHandler={productClickHandler}
              categories={categories}
              position={index + slideIndex * 4}
              trackingAttributes={trackingAttributes}
            />
          </RecoTracking>
        </div>
      ))}
    </div>
  );

  const chunked = chunk(products, 4);
  return (
    <div>
      <Headline tag={tags.H2}>{title || language('recommendations.topProducts')}</Headline>
      <div className={styles.sliderWrapper}>
        <Slider
          trackingAttributes={trackingAttributes}
          slides={chunked.map((chunk, index) => renderSlides(chunk, index))}
          slidesPerView={slidesPerView}
          spaceBetween={20}
          sliderReset={sliderReset}
        />
      </div>
    </div>
  );
};

ProductRecoSlider.displayName = 'organisms/ProductRecoSlider';

ProductRecoSlider.propTypes = {
  categories: PropTypes.object,
  productClickHandler: PropTypes.func,
  products: PropTypes.array,
  title: PropTypes.string,
  trackingAttributes: PropTypes.object,
  slidesPerView: PropTypes.number,
  sliderReset: PropTypes.bool,
};

export default ProductRecoSlider;
