import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@interdiscount/component-library-interdiscount';

import Headline from 'components/atoms/Headline/Headline';
import FlashMessage from 'components/atoms/FlashMessage/FlashMessage';
import { ICON_MINUS_BOX_OUTLINE } from 'components/atoms/Icon/Icon';
import Spinner from 'components/atoms/Spinner/Spinner';

import AddedProduct from 'components/molecules/AddedProduct/AddedProduct';
import MessageAddedToCartButtons from 'components/molecules/MessageAddedToCartButtons/MessageAddedToCartButtons';

import SimpleProduct from 'containers/Product/SimpleProduct';

import { types as flashMessageTypes, sizes } from 'constants/flashMessages/flashMessages';
import { noop } from 'constants/noop/noop';
import i18n from 'constants/language/language';
import { apiStatus } from 'constants/apiStatus/apiStatus';

import styles from './NoStockModal.scss';

const NoStockModal = ({
  modalIsOpen = false,
  closeModal = noop,
  addToCartState = {},
  goToCart = noop,
  statusCode = {},
}) => (
  <Modal onRequestClose={closeModal} isOpen={modalIsOpen} size="LG">
    {addToCartState.status !== apiStatus.failure ? (
      <Spinner status={addToCartState.status} />
    ) : (
      <>
        <div className={styles.spacer}>
          <Headline>{i18n('modal.noStockModal.headline')}</Headline>
        </div>
        <FlashMessage
          type={flashMessageTypes.NO_STOCK}
          content={
            statusCode === 'maxOrderQuantityExceeded'
              ? i18n('modal.maxOrderQuantityExceeded.flashMessageContent')
              : i18n('modal.noStockModal.flashMessageContent')
          }
          size={sizes.DEFAULT}
        />
        <div className={styles.iconWrapper}>
          <SimpleProduct productCode={addToCartState.productCode}>
            <AddedProduct icon={ICON_MINUS_BOX_OUTLINE} />
          </SimpleProduct>
        </div>
        <MessageAddedToCartButtons closeModal={closeModal} goToCart={goToCart} />
      </>
    )}
  </Modal>
);

NoStockModal.displayName = 'organisms/NoStockModal';

NoStockModal.propTypes = {
  statusCode: PropTypes.string,
  modalIsOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  goToCart: PropTypes.func,
  addToCartState: PropTypes.object,
};

export default NoStockModal;
