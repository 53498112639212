import has from 'constants/helper/has';
import pull from 'constants/helper/pull';
import omit from 'constants/helper/omit';
import getStorage from 'constants/storage/storage';
const localStorage = getStorage(true);

export const addProductToStorage = (selectedCategory, productCode) => {
  const list = JSON.parse(localStorage.getItem('comparisonList'));
  const categories = list?.categories || {};

  if (!selectedCategory) {
    return;
  }

  // if: categoryCode is not yet in categories
  if (!has(categories, selectedCategory)) {
    categories[selectedCategory] = [productCode];
  } else if (categories[selectedCategory].length < 3) {
    categories[selectedCategory].push(productCode);
  }

  const newList = {
    categories,
    selectedCategory,
  };
  localStorage.setItem('comparisonList', JSON.stringify(newList));
};

export const removeProductFromStorage = (categoryCode, productCode) => {
  if (!categoryCode) {
    return;
  }

  const list = JSON.parse(localStorage.getItem('comparisonList'));
  let categories = list?.categories || {};

  let selectedCategory = categoryCode;

  if (categories[categoryCode]?.includes(productCode)) {
    categories[categoryCode] = pull(categories[categoryCode], productCode);

    // remove whole category if no product in it.
    // also set selected category to first category code in array
    if (categories[categoryCode].length === 0) {
      categories = omit(categories, [categoryCode]);
      selectedCategory = Object.keys(categories)[0] || '';
    }
  }

  const newList = {
    categories,
    selectedCategory,
  };
  localStorage.setItem('comparisonList', JSON.stringify(newList));
};

export const changeSelectedCategory = (selectedCategory) => {
  if (!selectedCategory) {
    return;
  }

  const list = JSON.parse(localStorage.getItem('comparisonList'));

  const newList = {
    categories: list?.categories || {},
    selectedCategory,
  };

  localStorage.setItem('comparisonList', JSON.stringify(newList));
};
