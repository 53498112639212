import { Component, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { searchStore, resetStoreSearch, getLocation, getLocationName } from 'actions/storeActions/storeActions';
import { saveAsMyStore, getUser } from 'actions/userActions/userActions';
import getTimeFromTimestamp from 'constants/getTimeFromTimestamp/getTimeFromTimestamp';
import language from 'constants/language/language';
import { locations, getFlashMessage } from 'constants/flashMessages/flashMessages';
import { setDeliveryMode, getPaymentModes } from 'actions/cartActions/cartActions';

import getStoreFinderSearchStatus from 'constants/getStoreFinderSearchStatus/getStoreFinderSearchStatus';

const getCurrentDeliveryMode = (deliveryModes) => {
  let mode = Object.values(deliveryModes).find((mod) => mod.selected);
  if (!mode) {
    mode = deliveryModes.pickup;
  }

  return mode && mode.code;
};

export const mapStateToProps = ({ user, storefinder, cart, currentTime, ui = {}, routing, products, cms }) => {
  const featureToggling = ui.featureTogglingConfig || [];

  const showCoronaState = featureToggling.find((el) => el.featureName === 'storeFinderCoronaState')?.config === 'true';

  const flashMessage = getFlashMessage(ui?.flashMessages, locations.STOREFINDER);

  const pages = cms?.navigation?.pages;

  return {
    storefinder,
    currentDeliveryMode: getCurrentDeliveryMode(cart.deliveryModes),
    currentTime,
    isLoggedIn: (user?.uid || 'anonymous') !== 'anonymous',
    myStoreId: user?.pointOfServiceData?.name || '',
    cartHasBulkyGood: cart.bulkyGoods,
    queryParams: routing?.locationBeforeTransitions?.query || {},
    breakpoint: ui.breakpoint,
    showCoronaState,
    flashMessage,
    products,
    pages,
  };
};

const mapDispatchToProps = {
  search: searchStore,
  getGeoLocation: getLocation,
  getGeoLocationName: getLocationName,
  saveAsMyStore,
  setDeliveryMode,
  getPaymentModes,
  getUser,
  resetStoreSearch,
};

export class StoreFinderContainer extends Component {
  static propTypes = {
    storefinder: PropTypes.object,
    currentDeliveryMode: PropTypes.string,
    currentTime: PropTypes.number,
    search: PropTypes.func,
    getGeoLocation: PropTypes.func,
    getGeoLocationName: PropTypes.func,
    setDeliveryMode: PropTypes.func,
    getPaymentModes: PropTypes.func,
    closeModal: PropTypes.func,
    children: PropTypes.node.isRequired,
    myStoreId: PropTypes.string,
    saveAsMyStore: PropTypes.func,
    isLoggedIn: PropTypes.bool,
    getUser: PropTypes.func,
    cartHasBulkyGood: PropTypes.bool,
    resetStoreSearch: PropTypes.func,
    queryParams: PropTypes.object,
    isInventory: PropTypes.bool,
    inventoryStores: PropTypes.array,
    breakpoint: PropTypes.string,
    showCoronaState: PropTypes.bool,
    flashMessage: PropTypes.object,
    searchAllStores: PropTypes.bool,
    products: PropTypes.object,
    productCode: PropTypes.string,
    pages: PropTypes.object,
  };

  static defaultProps = {
    storefinder: {},
    currentDeliveryMode: '',
    currentTime: Date.now(),
    search: () => {},
    getGeoLocation: () => {},
    getGeoLocationName: () => {},
    setDeliveryMode: () => {},
    getPaymentModes: () => {},
    closeModal: () => {},
    myStoreId: '',
    saveAsMyStore: () => {},
    isLoggedIn: false,
    getUser: () => {},
    isInventory: false,
    flashMessage: {},
    searchAllStores: false,
    products: {},
    pages: [],
  };

  constructor(props) {
    super(props);
    this.maxItems = this.props.showCoronaState ? 30 : 5;
    this.totalStores = 0;
    this.searchTerm = '';
    this.isSearchingByLocation = false;
    this.isSearchingByLocationActive = false;
    this.defaultLng = 8.227511999999933;
    this.defaultLat = 46.818188;
    this.state = {
      visibleStores: this.maxItems,
      searchByLocationError: '',
      showOnlyOpenStores: this.shouldShowOnlyOpenStores(props),
      showOnlyShowrooms: false,
      ignoreFirstStore: this.props.inventoryStores,
      showDistance: true,
    };
  }

  componentWillUnmount() {
    // reset search results when leaving the finder only if not inventory storefinder...
    if (!this.props.isInventory) {
      this.props.resetStoreSearch();
    }
  }

  componentDidMount() {
    if (this.props.showCoronaState) {
      this.setState({ showDistance: false });
      this.props.search(this.defaultLat, this.defaultLng);
    }

    if (this.shouldShowOnlyOpenStores(this.props)) {
      this.searchByLocation();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.showCoronaState && this.props.showCoronaState !== nextProps.showCoronaState) {
      this.maxItems = '30';
      this.setState({ showDistance: false });
      this.props.search(this.defaultLat, this.defaultLng);
    }
  }

  shouldShowOnlyOpenStores(props) {
    return props.queryParams && !!props.queryParams.showOpen;
  }

  getGoogleLocation = (store) => {
    if (this.isSearchingByLocationActive) {
      this.isSearchingByLocationActive = false;
      return store.search && store.search.locality;
    }
    return null;
  };

  resetFilters = () => {
    this.setState({
      showOnlyOpenStores: false,
      showOnlyShowrooms: false,
    });
  };

  switchGeoLocationErrorState = (newErrorState) => {
    if (this.state.searchByLocationError !== newErrorState) {
      this.setState({ searchByLocationError: newErrorState });
    }
  };

  searchByLocation = () => {
    if (!navigator.geolocation) {
      // error if browser doesn't support geo localization
      this.switchGeoLocationErrorState(language('storeFinder.search.geo.error'));
      return;
    }

    const successHandler = (position) => {
      const lat = position.coords.latitude;
      const lng = position.coords.longitude;

      this.isSearchingByLocationActive = true;

      // save user location in store
      this.props.getGeoLocationName(lat, lng).then(() => {
        this.props.search(lat, lng);
      });
      // remove old error messages
      this.switchGeoLocationErrorState('');
      this.isSearchingByLocation = true;
    };

    const errorHandler = () => {
      // error if user has blocked geo localization
      this.switchGeoLocationErrorState(language('storeFinder.search.geo.error'));
      this.isSearchingByLocation = false;
      this.isSearchingByLocationActive = false;
    };

    navigator.geolocation.getCurrentPosition(successHandler, errorHandler);
  };

  search = (searchField, searchTerm) => {
    this.searchTerm = '';
    this.isSearchingByLocation = false;
    // reset ignoreFirstStore (used for sortByDistance) on search
    // (only available if this.props.inventoryStores are not empty)
    this.setState({
      ignoreFirstStore: false,
    });
    // remove old error messages if available
    this.switchGeoLocationErrorState('');

    if (!!searchTerm && searchTerm.length > 2) {
      this.searchTerm = searchTerm;
      document.activeElement.blur();
      searchField.blur();

      this.props.getGeoLocation(searchTerm).then(() => {
        // get latitude & longitude
        const lat = this.props.storefinder?.search?.latitude || this.defaultLat;
        const lng = this.props.storefinder?.search?.longitude || this.defaultLng;
        this.props.search(lat, lng, {}, this.props.searchAllStores);
        this.setState({ showDistance: true });
      });
    } else {
      this.resetFilters();
      this.props.resetStoreSearch();
    }
  };

  selectPickupStore = (storeId) => {
    this.props.setDeliveryMode(this.props.currentDeliveryMode, storeId).then(() => {
      // update payment modes
      this.props.getPaymentModes();
      this.props.closeModal(storeId);
    });
  };

  changeShowOnlyOpenStores = (option) => {
    this.setState({ showOnlyOpenStores: option });
  };

  changeShowOnlyShowrooms = (option) => {
    this.setState({ showOnlyShowrooms: option });
  };

  toMinutes = (str) => {
    const hm = str.split(':');
    return parseInt(hm[0] * 60, 10) + parseInt(hm[1], 10);
  };

  isCurrentlyOpen = (store, currentTime) => {
    let isOpen = false;

    const timeNow = this.toMinutes(getTimeFromTimestamp(currentTime));
    const openingHours = store?.openingHours?.today?.openingDayTimeRangeDataList || [];

    if (openingHours.length) {
      for (let i = 0; i < openingHours.length; i++) {
        const openingTime = openingHours[i].openingTime && getTimeFromTimestamp(openingHours[i].openingTime);
        const closingTime = openingHours[i].closingTime && getTimeFromTimestamp(openingHours[i].closingTime);

        if (openingTime && closingTime) {
          if (timeNow >= this.toMinutes(openingTime) && timeNow <= this.toMinutes(closingTime)) {
            isOpen = true;
          }
        }
      }
    }

    return isOpen;
  };

  filterOnlyOpenStores = (stores) =>
    stores.filter((store) => typeof store !== 'undefined' && this.isCurrentlyOpen(store, this.props.currentTime));

  filterOnlyShowrooms = (stores) =>
    stores.filter((store) => {
      const showroomIdentifier = ['MEGASTORE_03', 'XXL_02'];
      const storeCode = store?.type?.code || '';

      return showroomIdentifier.includes(storeCode);
    });

  sortByDistance = (stores) =>
    stores.sort((s1, s2) => parseInt(s1.formattedDistance, 10) - parseInt(s2.formattedDistance, 10));

  sortAndFilterStores = (stores = [], showOnlyShowrooms, showOnlyOpenStores) => {
    let unsortedStores = Object.values(stores);

    if (showOnlyShowrooms) {
      unsortedStores = this.filterOnlyShowrooms(unsortedStores);
    }

    if (showOnlyOpenStores) {
      unsortedStores = this.filterOnlyOpenStores(unsortedStores);
    }

    const sortedStores = this.state.ignoreFirstStore ? unsortedStores : this.sortByDistance(unsortedStores);
    this.totalStores = sortedStores.length;

    return sortedStores.slice(0, this.state.visibleStores);
  };

  loadMoreStores = () => {
    let visibleStores = this.state.visibleStores;
    if (this.totalStores > 0) {
      visibleStores += this.maxItems;
      if (visibleStores > this.totalStores) {
        visibleStores = this.totalStores;
      }
      this.setState({ visibleStores });
    }
  };

  handleSaveAsMyStore = (code) => {
    if (this.props.isLoggedIn) {
      this.props.saveAsMyStore(code).then(() => {
        this.props.getUser();
      });
    }
  };

  render() {
    const {
      storefinder,
      myStoreId,
      isLoggedIn,
      cartHasBulkyGood,
      inventoryStores,
      breakpoint,
      flashMessage,
      products,
      productCode,
    } = this.props;
    const releaseDate = products?.[productCode]?.releaseDate;
    const availability = products?.[productCode]?.availability;
    const locality = storefinder && storefinder.search ? storefinder.search.locality : null;
    const geoLocation =
      storefinder && storefinder.search
        ? {
            lat: storefinder.search.latitude,
            lng: storefinder.search.longitude,
          }
        : null;
    const stores = inventoryStores || storefinder.results;
    const filteredStores = this.sortAndFilterStores(
      stores,
      this.state.showOnlyShowrooms,
      this.state.showOnlyOpenStores
    );
    const searchFinderStoreStatus = getStoreFinderSearchStatus({
      resultsCount: filteredStores.length,
      searchTerm: this.searchTerm,
      hasActiveFilter: this.state.showOnlyShowrooms || this.state.showOnlyOpenStores,
      isSearchingByLocation: this.isSearchingByLocation,
      hasGoogleError: !!this.state.searchByLocationError,
    });

    return cloneElement(this.props.children, {
      submitSearch: this.search,
      searchStoreByLocation: this.searchByLocation,
      searchStoreByLocationError: this.state.searchByLocationError,
      changeSearchInput: this.changeSearchInput,
      changeShowOnlyOpenStores: this.changeShowOnlyOpenStores,
      changeShowOnlyShowrooms: this.changeShowOnlyShowrooms,
      showOnlyOpenStores: this.state.showOnlyOpenStores,
      showOnlyShowrooms: this.state.showOnlyShowrooms,
      searchTerm: this.getGoogleLocation(storefinder),
      stores: filteredStores,
      selectPickupStore: this.selectPickupStore,
      totalStores: this.totalStores,
      loadMoreStores: this.loadMoreStores,
      locality,
      geoLocation,
      searchStatus: searchFinderStoreStatus,
      myStoreId,
      saveAsMyStore: this.handleSaveAsMyStore,
      isLoggedIn,
      cartHasBulkyGood,
      breakpoint,
      showDistance: this.state.showDistance,
      flashMessage,
      availability,
      releaseDate,
      pages: this.props.pages,
    });
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StoreFinderContainer);
