import React from 'react';
import ContactField from 'containers/ContactField/ContactField';
import { Row, Col } from 'components/atoms/Grid/Grid';
import InputText from 'components/atoms/InputText/InputText';
import FieldSetInput from 'components/molecules/FieldSetInput/FieldSetInput';
import InputRadioList from 'components/molecules/InputRadioList/InputRadioList';

const NameFormPartial = () => {
  return (
    <>
      <Row>
        <Col>
          <ContactField fieldName="salutation">
            <FieldSetInput>
              <InputRadioList inline />
            </FieldSetInput>
          </ContactField>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={8}>
          <ContactField fieldName="firstName">
            <FieldSetInput>
              <InputText />
            </FieldSetInput>
          </ContactField>
        </Col>
        <Col sm={12} md={8}>
          <ContactField fieldName="lastName">
            <FieldSetInput>
              <InputText />
            </FieldSetInput>
          </ContactField>
        </Col>
      </Row>
    </>
  );
};

export default NameFormPartial;
