import React, { Component, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InventoryModalStoreFinder from 'components/organisms/InventoryModalStoreFinder/InventoryModalStoreFinder';

import { getStockInfo, resetStockInfo } from 'actions/productActions/productActions';

import { getLocation, searchStore } from 'actions/storeActions/storeActions';

export const mapStateToProps = (state, ownProps) => {
  const productId = ownProps.productId;
  const product = state?.products?.[productId];
  const stockStores = state?.inventory?.[product?.displayedCode]?.shops;
  const storefinder = state?.storefinder;
  const ownStore = state?.user?.pointOfServiceData;
  const productInventory = state?.inventory;

  return {
    stockStores,
    storefinder,
    ownStore,
    product,
    productInventory,
  };
};

export const mapDispatchToProps = {
  getStockInfo,
  getLocation,
  searchStore,
  resetStockInfo,
};

const getFilteredStore = (stores, name) => stores.find((stockStore) => stockStore.posId === name);

export const filterStores = (allStores = {}, stockStores, ownStore) => {
  const filteredStores = [];

  Object.values(allStores).forEach((stockStore) => {
    const filteredStore = getFilteredStore(stockStores, stockStore.name);
    if (Object.keys(filteredStore || {})?.length) {
      const storeExistInArray = !!getFilteredStore(filteredStores, filteredStore)?.length;
      if (!storeExistInArray && filteredStore.available > 0) {
        const newEntry = {
          ...stockStore,
          quantity: filteredStore.available,
        };
        // initially own store should be at first position
        if (Object.keys(ownStore || {}).length && ownStore.name === newEntry.name) {
          filteredStores.unshift(newEntry);
        } else {
          filteredStores.push(newEntry);
        }
      }
    }
  });
  return filteredStores;
};

class Inventory extends Component {
  static displayName = 'containers/Inventory';

  static propTypes = {
    children: PropTypes.element.isRequired,
    getStockInfo: PropTypes.func,
    getLocation: PropTypes.func,
    searchStore: PropTypes.func,
    stockStores: PropTypes.array,
    storefinder: PropTypes.object,
    ownStore: PropTypes.object,
    productImages: PropTypes.array,
    product: PropTypes.object,
    resetStockInfo: PropTypes.func,
    productInventory: PropTypes.object,
  };

  static defaultProps = {
    getStockInfo: () => {},
    getLocation: () => {},
    searchStore: () => {},
    productImages: [],
    stockStores: [],
    resetStockInfo: () => {},
  };

  state = {
    isStorefinderOpen: false,
    searchTerm: 'Zürich',
  };

  componentWillUnmount() {
    this.props.resetStockInfo();
  }

  componentDidMount() {
    const productCode = this.props.product.displayedCode;
    if (productCode) {
      this.props.getStockInfo(productCode);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const productCode = this.props.product.displayedCode;
    const nextProductCode = nextProps.product.displayedCode;
    if (productCode !== nextProductCode) {
      this.props.getStockInfo(nextProductCode);
    }
  }

  searchAllStores = () => {
    this.props.getLocation(this.state.searchTerm).then((response) => {
      if (response) {
        const search = this.props.storefinder?.search;
        const lat = search?.latitude;
        const long = search?.longitude;
        this.props.searchStore(lat, long, {}, true);
      }
    });
  };

  openStorefinder = () => {
    this.setState({
      isStorefinderOpen: true,
    });
  };

  closeStorefinder = () => {
    this.setState({
      isStorefinderOpen: false,
    });
  };

  render() {
    const { stockStores, ownStore, productImages, product, storefinder, productInventory } = this.props;
    const allStores = storefinder.results;
    const stores = filterStores(allStores, stockStores, ownStore);

    let mergedOwnStore;

    if (Object.keys(ownStore || {}).length) {
      const filteredOwnStore = getFilteredStore(stockStores, ownStore.name);

      if (Object.keys(filteredOwnStore || {}).length) {
        mergedOwnStore = {
          ...ownStore,
          quantity: filteredOwnStore.available,
        };
      }
    }

    const productImage = productImages[0];
    return (
      <>
        <InventoryModalStoreFinder
          closeModal={this.closeStorefinder}
          modalIsOpen={this.state.isStorefinderOpen}
          filteredStores={stores}
          productImage={productImage}
          productName={product.name}
          productCode={product.code}
        />
        {cloneElement(this.props.children, {
          showStockInfo: stockStores.length > 0,
          onlyAvailableOnline: product.code && !product.displayedCode,
          stockInfo: stores,
          ownStore: mergedOwnStore,
          productInventory: productInventory,
          openStorefinderModal: this.openStorefinder,
        })}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Inventory);
