import pickBy from 'constants/helper/pickBy';
import has from 'constants/helper/has';
import merge from 'lodash/merge';

import restClient from 'api/RestClient/RestClient';
import { resourceKeys } from 'constants/restResources/restResources';
import { loadProducts } from 'api/ProductAPI/ProductAPI';
import { provideUrls } from 'constants/navigation/navigation';
import { NON_CMS_PAGE } from 'constants/cms/cms';
import { getLocale } from 'constants/language/language';
import { TTL_ONE_YEAR } from 'constants/cache/cache';

const CmsAPI = (function CmsAPI() {
  const resource = resourceKeys.CMS_RESOURCE;

  let pages = {};
  const setPages = (data) => {
    pages = data;
    provideUrls(data);
  };
  const getPages = () => pages;

  const getLocalizedUrls = () => pickBy(provideUrls(getPages()), (url) => url.locale === getLocale());

  /**
   * Returns CMS-Elements for navigation tree
   * @returns Array with CMS-Elements
   */
  const getNavigationNodes = ({ refreshCache } = { refreshCache: false }) =>
    restClient
      .get(resource, '/navigation', false, {
        // Navigation-Nodes are refreshed (passively) in background by a cron job in app/entry.server.js.
        // Therefore we set the cache TTL here to (basically) never expire to ensure the active/blocking request
        // for this data by framework "needs" functionality (e.g. DefaultLayout.js) is always served from cache.
        cacheInSec: TTL_ONE_YEAR,
        refreshCache,
      })
      .then((response) => {
        setPages(response.data);
        return response;
      });

  /**
   * Returns CMS-Elements categories pages
   * @param categoryId - the id of the needed category-cms-page
   * @param facetQueryParams - the facetQueryParams of the the needed category-cms-page
   * @returns Array with CMS-Elements
   */
  const getCategoryContent = (categoryId, facetQueryParams) => {
    const brands =
      Array.isArray(facetQueryParams) &&
      facetQueryParams.filter((element, index, array) => array[index - 1] === 'brand' && element);
    const brandsQuery = brands && brands.length ? `?brands=${brands.join(',')}` : '';
    return categoryId === undefined
      ? Promise.reject('Invalid Parameter categoryId')
      : restClient.get(resource, `/category/${categoryId}${brandsQuery}`);
  };

  /**
   * Returns CMS-Elements for pages
   * @param pageId - the id of the needed cms-content-page
   * @returns Array with CMS-Elements
   */
  const getPageContent = (pageId) => {
    if (pageId === undefined || pageId === NON_CMS_PAGE) {
      return Promise.reject('Invalid Parameter pageId');
    }

    return new Promise((resolve, reject) => {
      restClient
        .get(resource, `/content/${pageId}`)
        .then((res) => resolve(res))
        .catch(() => {
          // dynamically import cms fallback content on error
          import('./cmsFallback').then((dynModule) => {
            const fallbackContent = dynModule.getCmsFallbackContent(getLocale(), pageId);

            if (fallbackContent) {
              resolve({ data: fallbackContent });
            } else {
              reject();
            }
          });
        });
    });
  };

  const getPageContentNeed = (pageId) => {
    if (pageId === undefined || pageId === NON_CMS_PAGE) {
      return Promise.reject('Invalid Parameter pageId');
    }

    return restClient.get(resource, `/content/${pageId}`).then((response) => {
      const cmsDatas = response?.data?.after?.cmsDatas || [];

      const cmsProductListingComponent = cmsDatas.find((component) => has(component, 'searchQuery'));

      if (cmsProductListingComponent) {
        let searchQuery = cmsProductListingComponent.searchQuery;
        searchQuery = `query=${searchQuery}`;

        return new Promise((resolve, reject) => {
          loadProducts(searchQuery).then(
            (products) => {
              const mergedResponses = merge(products, response);
              const content = { searchQuery, ...mergedResponses };
              resolve(content);
            },
            (error) => reject(error)
          );
        });
      }
      return Promise.resolve(response);
    });
  };

  const getWallContent = (wallId) => {
    if (wallId) {
      return restClient.get(resource, `/wall/${wallId}`);
    }
    return restClient.get(resource, '/wall');
  };

  const provideInitialState = () => ({
    navigation: {
      pages: getPages(),
    },
  });

  const getUspElements = () => {
    const uspResource = resourceKeys.BASE_RESOURCE;

    return restClient.get(uspResource, `/cms/uniqueSellingProposition`);
  };

  return {
    provideInitialState,
    getLocalizedUrls,
    // actual API calls to BE:
    getNavigationNodes,
    getPageContent,
    getPageContentNeed,
    getCategoryContent,
    getWallContent,
    setPages,
    getPages,
    getUspElements,
  };
})();

export default CmsAPI;
