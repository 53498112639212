export const clientProductReportTopics = {
  image: 'image',
  video: 'video',
  features: 'features',
  description: 'description',
  documents: 'documents',
  other: 'other',
};

export const productReportTypes = {
  image: 'MEDIA',
  video: 'MEDIA',
  features: 'FEATURE',
  documents: 'BASIC',
  description: 'BASIC',
  other: 'BASIC',
};
