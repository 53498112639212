// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { browserHistory, withRouter } from 'react-router';

import Rating from 'components/organisms/Rating/Rating/Rating';
import { loadCustomerRating, createRating, updateRating } from 'actions/ratingActions/ratingActions';
import { isLoggedIn, getLoginUrl } from 'constants/user/user';

import type { IRating, ICreateRatings, IUpdateRatings } from 'flow/ratings';

type IProps = {
  children: React.Element<typeof Rating>,
  productCode: string,
  userLoggedIn: boolean,
  isLoading: boolean,
  selectedRating: ?IRating,
  ratingOverview: {},
  location: Object,
  pages?: {},
  createRating: (ICreateRatings) => Promise<any>,
  updateRating: (IUpdateRatings) => Promise<any>,
  loadCustomerRating: (string) => Promise<any>,
};

type IState = {
  showEditor: boolean,
};

const mapStateToProps = (state: Object, ownProps: IProps): Object => {
  const productCode = ownProps.productCode;
  const product = state.products[productCode] || {};

  return {
    productCode,
    selectedRating: state.ratings.selectedRating,
    userLoggedIn: isLoggedIn(state.user),
    ratingOverview: product,
    isLoading: state.ratings.xhrActive,
    pages: state?.cms?.navigation?.pages ?? null,
  };
};

const mapDispatchToProps = {
  loadCustomerRating,
  createRating,
  updateRating,
};

class RatingDetailsContainer extends React.Component<IProps, IState> {
  state = {
    showEditor: false,
  };

  componentDidMount() {
    if (this.props.location?.state?.loginRedirect) {
      // when loginRedirect is set in location state (see Login.js) the user has been redirected from
      // the login page to add/edit a rating, so we automatically open the rating editor (SPQR-9558)
      this.showEditor();
      // clear location state again
      browserHistory.replace({ pathname: this.props.location.pathname, state: {} });
    }
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.productCode !== this.props.productCode) {
      // hide editor when navigating from product details page to product detail page
      this.hideEditor();
    }
  }

  onSubmitRating = (newValues): Promise<any> => {
    const { productCode, selectedRating } = this.props;

    if (!selectedRating) {
      // add first rating
      return this.props.createRating({ ...newValues }, productCode);
    }

    // update existing rating...

    const payload = {
      ...newValues,
      headline: selectedRating.headline !== newValues.headline ? newValues.headline : undefined,
      comment: selectedRating.comment !== newValues.comment ? newValues.comment : undefined,
    };

    return this.props.updateRating({ ...payload }, productCode);
  };

  showEditor = () => {
    const { userLoggedIn, productCode } = this.props;

    if (!userLoggedIn) {
      // redirect to login page, when no user is logged in
      browserHistory.push({
        pathname: getLoginUrl(),
        state: {
          redirectTo: window.location.pathname,
        },
      });

      return;
    }

    // check if the current user already rate the selected product before showing the editor...
    this.props.loadCustomerRating(productCode).then(() => {
      this.setState({ showEditor: true });
    });
  };

  hideEditor = () => {
    this.setState({ showEditor: false });
  };

  render() {
    const { children, selectedRating, ratingOverview, isLoading, pages } = this.props;

    return React.cloneElement(children, {
      showEditor: this.state.showEditor,
      toggleRateEditor: this.showEditor,
      onSubmitRating: this.onSubmitRating,
      onCancelRating: this.hideEditor,
      selectedRating,
      ratingOverview,
      isLoading,
      pages,
    });
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RatingDetailsContainer));
