import React, { Component } from 'react';
import PropTypes from 'prop-types';
import StoreFinderSearch from 'components/organisms/StoreFinderSearch/StoreFinderSearch';
import StoreFinderList from 'components/organisms/StoreFinderList/StoreFinderList';
import StoreFinderMap from 'components/organisms/StoreFinderMap/StoreFinderMap';
import StoreFinderResultsTitle from 'components/molecules/StoreFinderResultsTitle/StoreFinderResultsTitle';
import viewTypes from 'constants/storeFinderResultsViewTypes/storeFinderResultsViewTypes';
import InventoryStorefinderProduct from 'components/atoms/InventoryStorefinderProduct/InventoryStorefinderProduct';
import { availabilityStates } from 'constants/deliveryStates/availabilityStates';
import { scrollToRef } from 'constants/scrollToElement/scrollToElement';

import Headline from 'components/atoms/Headline/Headline';
import language from 'constants/language/language';

export class StoreFinderTemplate extends Component {
  static propTypes = {
    submitSearch: PropTypes.func,
    searchStoreByLocation: PropTypes.func,
    changeShowOnlyOpenStores: PropTypes.func,
    changeShowOnlyShowrooms: PropTypes.func,
    searchStoreByLocationError: PropTypes.string,
    searchTerm: PropTypes.string,
    showOnlyOpenStores: PropTypes.bool,
    showOnlyShowrooms: PropTypes.bool,
    stores: PropTypes.array,
    totalStores: PropTypes.number,
    loadMoreStores: PropTypes.func,
    locality: PropTypes.string,
    geoLocation: PropTypes.object,
    myStoreId: PropTypes.string,
    productImage: PropTypes.object,
    productName: PropTypes.string,
    breakpoint: PropTypes.string,
    availability: PropTypes.oneOf(Object.values(availabilityStates)),
    releaseDate: PropTypes.string,
    pages: PropTypes.string,
  };

  static defaultProps = {
    submitSearch: () => {},
    searchStoreByLocation: () => {},
    changeShowOnlyOpenStores: () => {},
    changeShowOnlyShowrooms: () => {},
    searchStoreByLocationError: '',
    searchTerm: '',
    showOnlyOpenStores: false,
    showOnlyShowrooms: false,
    stores: [],
    totalStores: 0,
    loadMoreStores: () => {},
    locality: '',
    geoLocation: {},
    myStoreId: '',
    availability: '',
    pages: [],
  };

  constructor(props) {
    super(props);
    this.switchedStorePosition = null;
    this.storeFinderContainerRef = React.createRef();
    this.state = {
      activeView: viewTypes.LIST,
    };
  }

  switchView = (activeView, storeName = '') => {
    if (Object.values(viewTypes).includes(activeView)) {
      this.switchedStorePosition = null;
      // if user selected specific store
      if (storeName) {
        // find store position in array
        const position = this.props.stores.findIndex((elem) => elem.name === storeName);
        if (position > -1) {
          this.switchedStorePosition = position;
        }
      }

      // switch to map
      this.setState({ activeView }, () => scrollToRef(this.storeFinderContainerRef));
    }
  };

  render() {
    const {
      submitSearch,
      searchStoreByLocation,
      changeShowOnlyOpenStores,
      changeShowOnlyShowrooms,
      searchStoreByLocationError,
      searchTerm,
      showOnlyOpenStores,
      showOnlyShowrooms,
      stores,
      totalStores,
      loadMoreStores,
      locality,
      geoLocation,
      myStoreId,
      productImage,
      productName,
      breakpoint,
      availability,
      releaseDate,
      pages,
    } = this.props;

    const storesList = !!stores.length && Object.values(stores);

    return (
      <div>
        <div>
          <Headline margin={Headline.margins.MB_30}>{language('inventory.storeFinder.headline')}</Headline>
          <InventoryStorefinderProduct productImage={productImage} productName={productName} />
          <StoreFinderSearch
            submitSearch={submitSearch}
            searchStoreByLocation={searchStoreByLocation}
            searchStoreByLocationError={searchStoreByLocationError}
            changeShowOnlyOpenStores={changeShowOnlyOpenStores}
            changeShowOnlyShowrooms={changeShowOnlyShowrooms}
            showOnlyOpenStores={showOnlyOpenStores}
            showOnlyShowrooms={showOnlyShowrooms}
            searchTerm={searchTerm}
            isInventory
            breakpoint={breakpoint}
            pages={pages}
          />
        </div>
        <div ref={this.storeFinderContainerRef}>
          {!!storesList.length && (
            <section>
              <StoreFinderResultsTitle
                locality={locality}
                activeView={this.state.activeView}
                switchView={this.switchView}
              />
              {this.state.activeView === viewTypes.LIST ? (
                <StoreFinderList
                  stores={storesList}
                  totalStores={totalStores}
                  loadMoreStores={loadMoreStores}
                  switchView={this.switchView}
                  myStoreId={myStoreId}
                  isInventoryStorefinder
                  isStandalone
                  availability={availability}
                  releaseDate={releaseDate}
                />
              ) : (
                <StoreFinderMap
                  stores={storesList}
                  locality={locality}
                  geoLocation={geoLocation}
                  switchedStorePosition={this.switchedStorePosition}
                />
              )}
            </section>
          )}
        </div>
      </div>
    );
  }
}

export default StoreFinderTemplate;
