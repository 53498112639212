import React from 'react';
import PropTypes from 'prop-types';
import TeaserContent from 'components/molecules/TeaserContent/TeaserContent';
import { Col, Row } from 'components/atoms/Grid/Grid';
import Headline from 'components/atoms/Headline/Headline';
import styles from './TeaserContentList.scss';

const TeaserContentList = ({ title, elements }) => {
  if (!elements.length) {
    return <div>No Elements available</div>;
  }
  return (
    <div className={styles.container}>
      {title && (
        <Headline tag={Headline.tags.H2} margin={Headline.margins.MB_10}>
          {title}
        </Headline>
      )}
      <Row className={styles.list}>
        {elements.map((element, key) => (
          <Col md={6} xl={4} className={styles.entry} key={key}>
            <TeaserContent
              title={element.title}
              text={element.text}
              icon={element.logo}
              link={element.link}
              openNewTab={element.externalLink}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
};

TeaserContentList.propTypes = {
  title: PropTypes.string,
  elements: PropTypes.array,
};

TeaserContentList.defaultProps = {
  title: null,
  elements: [],
};

TeaserContentList.displayName = 'organisms/TeaserContentList';

export default TeaserContentList;
