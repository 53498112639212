import { Component, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isStoreOpen } from 'constants/storefinder/storefinder';

export const mapStateToProps = (state) => ({
  isPopOverLoginVisible: state?.ui?.isPopOverLoginVisible ?? false,
  isPopOverCartVisible: state?.ui?.isPopOverCartVisible ?? false,
  randomManagerImageUrl: state?.cms?.pages?.HomePage?.randomStoreManagerData?.imageUrl,
  storeId: state?.user?.pointOfServiceData?.name || '',
  myStore: state?.user?.pointOfServiceData,
  userApiStatus: state?.user?.apiStatus,
  cmsApiStatus: state?.cms?.requestForCmsContentStatus,
});

export class MyStoreContainer extends Component {
  static propTypes = {
    isPopOverLoginVisible: PropTypes.bool,
    isPopOverCartVisible: PropTypes.bool,
    storeId: PropTypes.string,
    myStore: PropTypes.object,
    randomManagerImageUrl: PropTypes.string,
    children: PropTypes.node.isRequired,
    userApiStatus: PropTypes.string,
    cmsApiStatus: PropTypes.string,
  };

  static defaultProps = {
    storeId: '',
    myStore: {},
    randomManagerImageUrl: '',
  };

  render() {
    const {
      myStore,
      storeId,
      randomManagerImageUrl,
      isPopOverLoginVisible,
      isPopOverCartVisible,
      userApiStatus,
      cmsApiStatus,
    } = this.props;

    return cloneElement(this.props.children, {
      isPopOverLoginVisible,
      isPopOverCartVisible,
      storeId,
      isOpen: isStoreOpen(this.props.myStore?.openStatusInfo),
      storeName: myStore?.description,
      storeManagerImageUrl: myStore?.manager?.imageUrl,
      randomManagerImageUrl,
      awardImageUrl: myStore?.awardUrl,
      userApiStatus,
      cmsApiStatus,
    });
  }
}

export default connect(mapStateToProps, {})(MyStoreContainer);
