import { useContext } from 'react';
import { FeatureToggleContext } from 'contexts/FeatureTogglingProvider/FeatureTogglingProvider';

export const getFeatureToggleConfig = (config, key) => config?.find(({ featureName }) => featureName === key)?.config;

const useFeatureToggle = (featureName = '') => {
  const { featureTogglingConfig = [] } = useContext(FeatureToggleContext) || {};

  return getFeatureToggleConfig(featureTogglingConfig, featureName);
};

export default useFeatureToggle;
