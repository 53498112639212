import React from 'react';
import PropTypes from 'prop-types';

import language from 'constants/language/language';

import FlagBox from 'components/atoms/FlagBox/FlagBox';

// pegi enum
const PegiTypes = {
  pegi16: '16',
  pegi18: '18',
};

const checkForPegiValue = (pegiValue) => !!PegiTypes[pegiValue];

const ProductCartPegiFlag = ({ className, pegiValue }) => {
  let res;

  if (checkForPegiValue(pegiValue)) {
    res = (
      <div className={className}>
        <FlagBox text={`${language('product.pegiAbove')} ${PegiTypes[pegiValue]}`} type={FlagBox.types.PEGI} />
      </div>
    );
  } else {
    res = null;
  }

  return res;
};

ProductCartPegiFlag.propTypes = {
  className: PropTypes.string,
  pegiValue: PropTypes.string,
};

ProductCartPegiFlag.displayName = 'molecules/ProductCartPegiFlag';

export default ProductCartPegiFlag;
