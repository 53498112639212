import React from 'react';
import PropTypes from 'prop-types';

const LogoCoop = ({ height = 32, width, className = '', altText = '' }) => (
  <img className={className} height={height} width={width} src="/images/logos/Coop.svg" alt={altText} />
);

LogoCoop.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  className: PropTypes.string,
  altText: PropTypes.string,
};

LogoCoop.displayName = 'atoms/LogoCoop';

export default LogoCoop;
