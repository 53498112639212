import { cloneElement, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { NEWSLETTER } from 'constants/routePaths/routePaths';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import { getLocale } from 'constants/language/language';
import { apiStatus } from 'constants/apiStatus/apiStatus';
import { newsletterFeedbackTypes } from 'constants/newsletterFeedbackTypes/newsletterFeedbackTypes';
import { unsubscribeNewsletter, getEmailFromHash } from 'actions/newsletterActions/newsletterActions';

const mapStateToProps = (state) => ({
  newsletterApiStatus: state?.user?.newsletterApiStatus || apiStatus.success,
  newsletterResult: state?.user?.newsletterResponse || 'VALID',
  newsletterUID: state?.user?.newsletterUID || '',
});
const mapDispatchToProps = {
  unsubscribeNewsletter,
  getEmailFromHash,
};

export const NewsletterOptout = ({
  hash = '',
  isPersonalized = false,
  newsletterApiStatus,
  newsletterResult,
  newsletterUID,
  unsubscribeNewsletter,
  getEmailFromHash,
  children,
  individualizedAds,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (hash) getEmailFromHash(hash);
  }, [hash, getEmailFromHash]);

  const showError = newsletterApiStatus === apiStatus.failure || !hash || newsletterResult === 'NOT_VALID';

  const feedbackType = isPersonalized
    ? newsletterFeedbackTypes.UNSUBSCRIBE_PERSONALIZED
    : newsletterFeedbackTypes.UNSUBSCRIBE;

  const handleUnsubscribe = () => {
    setIsLoading(true);
    unsubscribeNewsletter(encodeURIComponent(hash), isPersonalized)
      .then(() => {
        if (newsletterApiStatus === apiStatus.success && newsletterResult === 'VALID' && !individualizedAds) {
          browserHistory.push({
            pathname: mapPathToLocalizedUrl(getLocale(), [NEWSLETTER, feedbackType]),
            state: {
              hash,
            },
          });
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  return cloneElement(children, {
    handleUnsubscribe,
    isLoading,
    newsletterUID,
    showError,
    isPersonalized,
  });
};

NewsletterOptout.displayName = 'containers/NewsletterOptout';
NewsletterOptout.propTypes = {
  newsletterApiStatus: PropTypes.oneOf(Object.values(apiStatus)),
  newsletterResult: PropTypes.string,
  hash: PropTypes.string,
  isPersonalized: PropTypes.bool,
  unsubscribeNewsletter: PropTypes.func,
  children: PropTypes.element.isRequired,
  newsletterUID: PropTypes.string,
  getEmailFromHash: PropTypes.func,
  individualizedAds: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsletterOptout);
