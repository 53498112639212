import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { StepperButtons } from 'components/molecules/Stepper/Stepper';
import ReturnableProduct from 'components/molecules/ReturnableProduct/ReturnableProduct';
import ProductReturnSummary, { validate } from 'components/molecules/ProductReturnSummary/ProductReturnSummary';
import { scrollToRef } from 'constants/scrollToElement/scrollToElement';
import { allReasonsFilled } from 'constants/returns/returns';

const ReturnReason = ({ onNextClick = () => {}, onPrevClick = () => {}, activeStep = 0, state = {} }) => {
  const { selectedProducts } = state;
  const [products, setProducts] = useState(selectedProducts);
  const [forceValidation, setForceValidation] = useState(false);

  const invalidProducts = products.map((p) => validate(p)).filter(Boolean);
  const incompleteProducts = products.filter(
    (p) => p.returnReasons === null || p.returnReasons === undefined || !allReasonsFilled(state.returnType, p)
  );
  const returnableProducts = products.filter((p) => !p.validationError && allReasonsFilled(state.returnType, p));

  const onReasonsChange = (product) => {
    setProducts(products.map((p) => (p.id === product.id ? { ...product } : p)));
    setForceValidation(false);
  };

  const getErrorMessage = (product) => {
    const validationResult = invalidProducts.find((p) => p.id === product.id);
    return validationResult ? validationResult.error : '';
  };

  const handleNextClick = () => {
    if (invalidProducts.length > 0) {
      setForceValidation(true);
      // scroll to first invalid product
      scrollToRef(invalidProducts[0].ref);
    } else {
      onNextClick({ ...state, selectedProducts: returnableProducts });
    }
  };

  return (
    <>
      {selectedProducts.map((product) => (
        <ReturnableProduct key={product.id} product={product}>
          <ProductReturnSummary
            returnType={state.returnType}
            product={product}
            reasons={product.returnReasons}
            errorMessage={forceValidation ? getErrorMessage(product) : undefined}
            onReasonsChange={onReasonsChange}
          />
        </ReturnableProduct>
      ))}
      <StepperButtons
        onPrevClick={onPrevClick}
        onNextClick={handleNextClick}
        activeStep={activeStep}
        nextDisabled={incompleteProducts.length > 0 || returnableProducts.length === 0}
      />
    </>
  );
};

ReturnReason.displayName = 'molecules/ReturnSteps/ReturnReason';

ReturnReason.propTypes = {
  onNextClick: PropTypes.func,
  onPrevClick: PropTypes.func,
  activeStep: PropTypes.number,
  state: PropTypes.object,
};

export default ReturnReason;
