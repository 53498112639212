import { ValidationTypes } from 'constants/validation/validation';

export const contactSubjects = {
  GENERAL: 'GENERAL',
  CONSULTING_SALES: 'CONSULTING_SALES',
  SUPERPOINTS: 'SUPERPOINTS',
  FEEDBACK: 'FEEDBACK',
  PRICE_CONFIRMATION: 'PRICE_CONFIRMATION',
  INVOICE: 'INVOICE',
  ONLINE_ORDER: 'ONLINE_ORDER',
  ADDRESS: 'ADDRESS',
  REPAIR: 'REPAIR',
  RETURN: 'RETURN',
  DELIVERY: 'DELIVERY',
  CANCELATION: 'CANCELATION',
  RECEIPT: 'RECEIPT',
};

export const messageMaxLength = 1000;

export const contactTypes = {
  PHONE: 'PHONE',
  EMAIL: 'EMAIL',
};

const fieldConfig = [
  {
    salutation: {
      [contactSubjects.GENERAL]: {
        required: true,
      },
      [contactSubjects.CONSULTING_SALES]: {
        required: true,
      },
      [contactSubjects.SUPERPOINTS]: {
        required: true,
      },
      [contactSubjects.FEEDBACK]: {
        required: true,
      },
      [contactSubjects.PRICE_CONFIRMATION]: {
        required: true,
      },
      [contactSubjects.INVOICE]: {
        required: true,
      },
      [contactSubjects.ONLINE_ORDER]: {
        required: true,
      },
      [contactSubjects.ADDRESS]: {
        required: true,
      },
      [contactSubjects.REPAIR]: {
        required: true,
      },
      [contactSubjects.RETURN]: {
        required: true,
      },
      [contactSubjects.DELIVERY]: {
        required: true,
      },
      [contactSubjects.CANCELATION]: {
        required: true,
      },
      [contactSubjects.RECEIPT]: {
        required: true,
      },
    },
  },
  {
    firstName: {
      [contactSubjects.GENERAL]: {
        required: true,
      },
      [contactSubjects.CONSULTING_SALES]: {
        required: true,
      },
      [contactSubjects.SUPERPOINTS]: {
        required: true,
      },
      [contactSubjects.FEEDBACK]: {
        required: true,
      },
      [contactSubjects.PRICE_CONFIRMATION]: {
        required: true,
      },
      [contactSubjects.INVOICE]: {
        required: true,
      },
      [contactSubjects.ONLINE_ORDER]: {
        required: true,
      },
      [contactSubjects.ADDRESS]: {
        required: true,
      },
      [contactSubjects.REPAIR]: {
        required: true,
      },
      [contactSubjects.RETURN]: {
        required: true,
      },
      [contactSubjects.DELIVERY]: {
        required: true,
      },
      [contactSubjects.CANCELATION]: {
        required: true,
      },
      [contactSubjects.RECEIPT]: {
        required: true,
      },
    },
  },
  {
    lastName: {
      [contactSubjects.GENERAL]: {
        required: true,
      },
      [contactSubjects.CONSULTING_SALES]: {
        required: true,
      },
      [contactSubjects.SUPERPOINTS]: {
        required: true,
      },
      [contactSubjects.FEEDBACK]: {
        required: true,
      },
      [contactSubjects.PRICE_CONFIRMATION]: {
        required: true,
      },
      [contactSubjects.INVOICE]: {
        required: true,
      },
      [contactSubjects.ONLINE_ORDER]: {
        required: true,
      },
      [contactSubjects.ADDRESS]: {
        required: true,
      },
      [contactSubjects.REPAIR]: {
        required: true,
      },
      [contactSubjects.RETURN]: {
        required: true,
      },
      [contactSubjects.DELIVERY]: {
        required: true,
      },
      [contactSubjects.CANCELATION]: {
        required: true,
      },
      [contactSubjects.RECEIPT]: {
        required: true,
      },
    },
  },
  {
    addressLine1: {
      [contactSubjects.GENERAL]: {
        required: true,
      },
      [contactSubjects.CONSULTING_SALES]: {
        required: true,
      },
      [contactSubjects.SUPERPOINTS]: {
        required: true,
      },
      [contactSubjects.FEEDBACK]: {
        required: true,
      },
      [contactSubjects.PRICE_CONFIRMATION]: {
        required: true,
      },
      [contactSubjects.INVOICE]: {
        required: true,
      },
      [contactSubjects.ONLINE_ORDER]: {
        required: false,
      },
      [contactSubjects.ADDRESS]: {
        required: true,
      },
      [contactSubjects.REPAIR]: {
        required: false,
      },
      [contactSubjects.RETURN]: {
        required: false,
      },
      [contactSubjects.DELIVERY]: {
        required: false,
      },
      [contactSubjects.CANCELATION]: {
        required: false,
      },
    },
  },
  {
    addressLine2: {
      [contactSubjects.GENERAL]: {
        required: false,
      },
      [contactSubjects.CONSULTING_SALES]: {
        required: false,
      },
      [contactSubjects.SUPERPOINTS]: {
        required: false,
      },
      [contactSubjects.FEEDBACK]: {
        required: false,
      },
      [contactSubjects.PRICE_CONFIRMATION]: {
        required: false,
      },
      [contactSubjects.INVOICE]: {
        required: false,
      },
      [contactSubjects.ONLINE_ORDER]: {
        required: false,
      },
      [contactSubjects.ADDRESS]: {
        required: false,
      },
      [contactSubjects.REPAIR]: {
        required: false,
      },
      [contactSubjects.RETURN]: {
        required: false,
      },
      [contactSubjects.DELIVERY]: {
        required: false,
      },
      [contactSubjects.CANCELATION]: {
        required: false,
      },
    },
  },
  {
    postalCode: {
      [contactSubjects.GENERAL]: {
        required: true,
      },
      [contactSubjects.CONSULTING_SALES]: {
        required: true,
      },
      [contactSubjects.SUPERPOINTS]: {
        required: true,
      },
      [contactSubjects.FEEDBACK]: {
        required: true,
      },
      [contactSubjects.PRICE_CONFIRMATION]: {
        required: true,
      },
      [contactSubjects.INVOICE]: {
        required: true,
      },
      [contactSubjects.ONLINE_ORDER]: {
        required: false,
      },
      [contactSubjects.ADDRESS]: {
        required: true,
      },
      [contactSubjects.REPAIR]: {
        required: false,
      },
      [contactSubjects.RETURN]: {
        required: false,
      },
      [contactSubjects.DELIVERY]: {
        required: false,
      },
      [contactSubjects.CANCELATION]: {
        required: false,
      },
    },
  },
  {
    town: {
      [contactSubjects.GENERAL]: {
        required: true,
      },
      [contactSubjects.CONSULTING_SALES]: {
        required: true,
      },
      [contactSubjects.SUPERPOINTS]: {
        required: true,
      },
      [contactSubjects.FEEDBACK]: {
        required: true,
      },
      [contactSubjects.PRICE_CONFIRMATION]: {
        required: true,
      },
      [contactSubjects.INVOICE]: {
        required: true,
      },
      [contactSubjects.ONLINE_ORDER]: {
        required: false,
      },
      [contactSubjects.ADDRESS]: {
        required: true,
      },
      [contactSubjects.REPAIR]: {
        required: false,
      },
      [contactSubjects.RETURN]: {
        required: false,
      },
      [contactSubjects.DELIVERY]: {
        required: false,
      },
      [contactSubjects.CANCELATION]: {
        required: false,
      },
    },
  },
  {
    country: {
      [contactSubjects.GENERAL]: {
        required: true,
      },
      [contactSubjects.CONSULTING_SALES]: {
        required: true,
      },
      [contactSubjects.SUPERPOINTS]: {
        required: true,
      },
      [contactSubjects.FEEDBACK]: {
        required: true,
      },
      [contactSubjects.PRICE_CONFIRMATION]: {
        required: true,
      },
      [contactSubjects.INVOICE]: {
        required: true,
      },
      [contactSubjects.ADDRESS]: {
        required: true,
      },
      [contactSubjects.REPAIR]: {
        required: false,
      },
      [contactSubjects.RETURN]: {
        required: false,
      },
      [contactSubjects.DELIVERY]: {
        required: false,
      },
      [contactSubjects.CANCELATION]: {
        required: false,
      },
    },
  },
  {
    contactEmail: {
      [contactSubjects.GENERAL]: {
        required: true,
      },
      [contactSubjects.CONSULTING_SALES]: {
        required: true,
      },
      [contactSubjects.SUPERPOINTS]: {
        required: true,
      },
      [contactSubjects.FEEDBACK]: {
        required: true,
      },
      [contactSubjects.PRICE_CONFIRMATION]: {
        required: true,
      },
      [contactSubjects.INVOICE]: {
        required: true,
      },
      [contactSubjects.ONLINE_ORDER]: {
        required: true,
      },
      [contactSubjects.ADDRESS]: {
        required: true,
      },
      [contactSubjects.REPAIR]: {
        required: true,
      },
      [contactSubjects.RETURN]: {
        required: true,
      },
      [contactSubjects.DELIVERY]: {
        required: true,
      },
      [contactSubjects.CANCELATION]: {
        required: true,
      },
      [contactSubjects.RECEIPT]: {
        required: true,
      },
    },
  },
  {
    phone: {
      [contactSubjects.GENERAL]: {
        required: false,
      },
      [contactSubjects.CONSULTING_SALES]: {
        required: true,
      },
      [contactSubjects.SUPERPOINTS]: {
        required: true,
      },
      [contactSubjects.FEEDBACK]: {
        required: true,
      },
      [contactSubjects.ONLINE_ORDER]: {
        required: false,
      },
      [contactSubjects.ADDRESS]: {
        required: true,
      },
      [contactSubjects.REPAIR]: {
        required: false,
      },
      [contactSubjects.RETURN]: {
        required: false,
      },
      [contactSubjects.DELIVERY]: {
        required: false,
      },
      [contactSubjects.CANCELATION]: {
        required: false,
      },
      [contactSubjects.RECEIPT]: {
        required: false,
      },
    },
  },
  {
    articleNumber: {
      [contactSubjects.CONSULTING_SALES]: {
        required: false,
      },
    },
  },
  {
    contactTextarea: {
      [contactSubjects.GENERAL]: {
        required: true,
      },
      [contactSubjects.CONSULTING_SALES]: {
        required: true,
      },
      [contactSubjects.SUPERPOINTS]: {
        required: true,
      },
      [contactSubjects.FEEDBACK]: {
        required: true,
      },
      [contactSubjects.PRICE_CONFIRMATION]: {
        required: true,
      },
      [contactSubjects.INVOICE]: {
        required: true,
      },
      [contactSubjects.ONLINE_ORDER]: {
        required: true,
      },
      [contactSubjects.ADDRESS]: {
        required: false,
      },
      [contactSubjects.REPAIR]: {
        required: true,
      },
      [contactSubjects.RETURN]: {
        required: true,
      },
      [contactSubjects.DELIVERY]: {
        required: true,
      },
      [contactSubjects.CANCELATION]: {
        required: true,
      },
      [contactSubjects.RECEIPT]: {
        required: true,
      },
    },
  },
  {
    orderNumber: {
      [contactSubjects.ONLINE_ORDER]: {
        required: true,
      },
      [contactSubjects.REPAIR]: {
        required: false,
      },
      [contactSubjects.RETURN]: {
        required: true,
      },
      [contactSubjects.DELIVERY]: {
        required: true,
      },
      [contactSubjects.CANCELATION]: {
        required: true,
      },
      [contactSubjects.RECEIPT]: {
        required: true,
      },
    },
  },
  {
    superpoints_receiptNumber: {
      [contactSubjects.SUPERPOINTS]: {
        required: true,
      },
    },
  },
  {
    superpoints_dateOfPurchase: {
      [contactSubjects.SUPERPOINTS]: {
        required: true,
      },
      [contactSubjects.PRICE_CONFIRMATION]: {
        required: true,
      },
    },
  },
  {
    superpoints_store: {
      [contactSubjects.SUPERPOINTS]: {
        required: true,
      },
      [contactSubjects.FEEDBACK]: {
        required: false,
      },
    },
  },
  {
    superpoints_supercardCode: {
      [contactSubjects.SUPERPOINTS]: {
        required: true,
      },
    },
  },
  {
    repair_feedback_repairNumber: {
      [contactSubjects.FEEDBACK]: {
        required: false,
      },
      [contactSubjects.REPAIR]: {
        required: false,
      },
    },
  },
  {
    repair_feedback_customerNumber: {
      [contactSubjects.FEEDBACK]: {
        required: false,
      },
      [contactSubjects.INVOICE]: {
        required: false,
      },
    },
  },
  {
    repair_feedback_contractNumber: {
      [contactSubjects.FEEDBACK]: {
        required: false,
      },
      [contactSubjects.INVOICE]: {
        required: false,
      },
      [contactSubjects.PRICE_CONFIRMATION]: {
        required: false,
      },
    },
  },
  {
    repair_feedback_invoiceNumber: {
      [contactSubjects.FEEDBACK]: {
        required: false,
      },
      [contactSubjects.INVOICE]: {
        required: false,
      },
    },
  },
  {
    repair_feedback_articleNumber: {
      [contactSubjects.FEEDBACK]: {
        required: false,
      },
      [contactSubjects.INVOICE]: {
        required: false,
      },
      [contactSubjects.PRICE_CONFIRMATION]: {
        required: false,
      },
      [contactSubjects.CONSULTING_SALES]: {
        required: false,
      },
    },
  },
  {
    brand: {
      [contactSubjects.FEEDBACK]: {
        required: false,
      },
    },
  },
  {
    modelType: {
      [contactSubjects.FEEDBACK]: {
        required: false,
      },
    },
  },
  {
    purchasePrice: {
      [contactSubjects.PRICE_CONFIRMATION]: {
        required: true,
      },
    },
  },
  {
    address_salutation: {
      [contactSubjects.ADDRESS]: {
        required: true,
      },
    },
  },
  {
    address_firstName: {
      [contactSubjects.ADDRESS]: {
        required: true,
      },
    },
  },
  {
    address_lastName: {
      [contactSubjects.ADDRESS]: {
        required: true,
      },
    },
  },
  {
    address_addressLine1: {
      [contactSubjects.ADDRESS]: {
        required: true,
      },
    },
  },
  {
    address_addressLine2: {
      [contactSubjects.ADDRESS]: {
        required: false,
      },
    },
  },
  {
    address_postalCode: {
      [contactSubjects.ADDRESS]: {
        required: true,
      },
    },
  },
  {
    address_country: {
      [contactSubjects.ADDRESS]: {
        required: true,
      },
    },
  },
  {
    address_town: {
      [contactSubjects.ADDRESS]: {
        required: true,
      },
    },
  },
];

export const getVisibleFields = (selectedSubject) =>
  fieldConfig.filter((current) =>
    Object.keys(current).find((key) => {
      if (Object.keys(current[key]).includes(selectedSubject)) {
        return current[key];
      }
      return false;
    })
  );

export const isFieldRequired = ({ selectedSubject, fieldName }) => {
  const field = fieldConfig.find((config) => Object.keys(config)[0] === fieldName);
  const subject = field?.[fieldName];
  return (subject?.[selectedSubject] || { required: false }).required;
};

export const getFieldData = (action, fieldName, opts) => ({
  ...opts,
  name: fieldName,
  value: action?.payload?.[fieldName] || '',
  required: isFieldRequired({
    fieldName,
    selectedSubject: action?.payload?.subject || '',
  }),
  validationResult: ValidationTypes.Initial,
});
