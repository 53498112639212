import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import cx from 'classnames';
import styles from './ProductFlag.scss';

const ANGLES = {
  TOP: 'angleTop',
  RIGHT: 'angleRight',
};

const ProductFlag = ({ angle = ANGLES.TOP, className = '', link, productFlagClassName, text }) => (
  <div className={cx(styles.container, styles[angle], className)}>
    {link ? (
      <Link to={link} className={cx(styles.content, styles.linked, productFlagClassName)}>
        {text}
      </Link>
    ) : (
      <small className={cx(styles.content, productFlagClassName)}>{text}</small>
    )}
  </div>
);

ProductFlag.ANGLES = ANGLES;

ProductFlag.displayName = 'atoms/ProductFlag';
ProductFlag.propTypes = {
  angle: PropTypes.oneOf(Object.values(ANGLES)),
  className: PropTypes.string,
  link: PropTypes.string,
  productFlagClassName: PropTypes.string,
  text: PropTypes.string.isRequired,
};

export default ProductFlag;
