import React from 'react';
import { SLOTS } from 'constants/cms/slots';
import CmsSlot from 'components/organisms/CmsSlot/CmsSlot';

// eslint-disable-next-line
const CmsPagesAfter = (props) => <CmsSlot position={SLOTS.AFTER} {...props} />;

CmsPagesAfter.displayName = 'organisms/CmsPagesAfter';

export default CmsPagesAfter;
