import { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { mapFacetProperties } from 'containers/FacetsContainer/FacetsContainer';
import { setMainMenuIsOpen as setMainMenuIsOpenAction } from 'actions/uiActions/uiActions';

export const mapStateToProps = (state, ownProps) => {
  const productsQuery = ownProps.productsQuery;
  const facetProperties = mapFacetProperties(productsQuery, state, ownProps);
  let { facets } = facetProperties;

  // remove promotion facet from facet list
  facets = facets.filter((facet) => facet.code !== 'promotions');

  return {
    ...facetProperties,
    facets,
    routing: state.routing,
  };
};

export const mapDispatchToProps = {
  setMainMenuIsOpen: setMainMenuIsOpenAction,
};

const CmsFacetsContainer = ({
  facets,
  children,
  categories,
  selectedCategory,
  searchQueryType = '',
  categoryFacet,
  routing,
  setMainMenuIsOpen,
}) => {
  return cloneElement(children, {
    facets,
    categories: categories,
    selectedCategory: selectedCategory,
    searchQueryType: searchQueryType,
    categoryFacet,
    routing,
    setMainMenuIsOpen,
  });
};

CmsFacetsContainer.displayName = 'containers/CmsFacetsContainer';
CmsFacetsContainer.propTypes = {
  productsQuery: PropTypes.object.isRequired,
  facets: PropTypes.array,
  children: PropTypes.element,
  apiStatus: PropTypes.string,
  isOpen: PropTypes.bool,
  selectedCategory: PropTypes.string,
  categories: PropTypes.array,
  isBrandPage: PropTypes.bool,
  setFilterMenuIsOpen: PropTypes.func,
  setFilterMenuClose: PropTypes.func,
  searchQueryType: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(CmsFacetsContainer);
