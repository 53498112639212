/*
 * Redux middleware to handle promises
 * As seen in: https://github.com/caljrimmer/isomorphic-redux-app
 */
import axios from 'axios';
import { apiStatus } from 'constants/apiStatus/apiStatus';
import getStorage from 'constants/storage/storage';
import { WINDOW_ID } from 'constants/storage/storageKeys';
import { ROUTER_UPDATE_LOCATION, ROUTER_UPDATE_LOCATION_NEED, LOAD_PRODUCTS } from 'constants/ActionTypes/ActionTypes';
import { noop } from 'constants/noop/noop';

const sessionStorage = getStorage(false);

export const postLog = (error) => {
  if (!__CLIENT__) return;

  if (!(error instanceof Error)) {
    if (__DEV__) {
      console.error(`[Deprecated]: An Error object must be passed to postLog! Details: `, error);
    }
    return;
  }

  const errorReport = {
    name: error?.name,
    msg: error?.message,
    // additional field from ErrorBoundary
    ...(error.info && { info: error.info }),
    url: window.location.href,
    tab: sessionStorage.getItem(WINDOW_ID),
    userAgent: navigator.userAgent,
    referrer: document.referrer,
    stack: error.stack.split('\n').slice(0, 2).join(''),
  };

  if (error?.response) {
    errorReport.requestUrl = error?.response?.request?.responseURL ?? 'unkown';
    errorReport.reponseBody = error?.response?.data;
  }

  axios.post('/log', errorReport).catch(noop);
};

export default function promiseMiddleware(store) {
  return (next) => (action) => {
    const { promise, type, ...rest } = action;
    if (type === ROUTER_UPDATE_LOCATION || type === ROUTER_UPDATE_LOCATION_NEED) {
      next({
        ...action,
        payload: { ...action.payload, categories: store.getState().categories },
      });
      return true;
    }

    if (!promise) return next(action);

    const SUCCESS = `${type}_SUCCESS`;
    const REQUEST = `${type}_REQUEST`;
    const FAILURE = `${type}_FAILURE`;
    const NETWORKERROR = `${type}_NETWORKERROR`;
    next({ ...rest, type: REQUEST });
    return promise
      .then((req) => {
        if (req?.name !== apiStatus.networkerror) {
          if (type === LOAD_PRODUCTS) {
            next({
              ...rest,
              req,
              payload: {
                ...action.payload,
                categories: store.getState().categories,
              },
              type: SUCCESS,
            });
            return true;
          }

          next({ ...rest, req, type: SUCCESS });
          return true;
        }
        next({ ...rest, type: NETWORKERROR });
        return false;
      })
      .catch((error) => {
        const errorType = error?.name;

        postLog(error);

        if (errorType === apiStatus.networkerror) {
          next({ ...rest, error, type: NETWORKERROR });
        } else {
          next({ ...rest, error: error?.response || {}, type: FAILURE });
        }

        return false;
      });
  };
}
