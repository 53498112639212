import React from 'react';
import { IndexRoute, Route } from 'react-router';
import {
  OPTIN,
  OPTIN_TYPE_UPDATEEMAIL,
  OPTIN_TYPE_REGISTRATION,
  OPTIN_CONFIRMATION_MAIL,
  OPTIN_CONFIRMED,
  OPTIN_EXPIRED,
  OPTIN_FAILURE,
  OPTIN_USED,
  OPTIN_VALIDATION,
  OPTIN_NOT_CONFIRMED,
} from 'constants/routePaths/routePaths';
import { verifyToken } from 'routes/routeHelpers';
import NotFound from 'routes/NotFound/NotFound';
import DefaultLayout from 'components/layouts/DefaultLayout/DefaultLayout';
import NotConfirmedOptIn from 'containers/NotConfirmedOptIn/NotConfirmedOptIn';
import OptInContainer from 'containers/OptIn/OptIn';
import OptInOrgansim from 'components/organisms/OptIn/OptIn';

const subroutes = (store, type, pathOfType) => (
  <>
    <IndexRoute component={NotFound} />
    <Route
      path={OPTIN_CONFIRMATION_MAIL}
      component={OptInContainer}
      type={type}
      page={OptInOrgansim.PAGES.CONFIRMATIONMAIL}
    />
    <Route path={OPTIN_CONFIRMED} component={OptInContainer} type={type} page={OptInOrgansim.PAGES.CONFIRMED} />
    <Route path={OPTIN_EXPIRED} component={OptInContainer} type={type} page={OptInOrgansim.PAGES.EXPIRED} />
    <Route path={OPTIN_FAILURE} component={OptInContainer} type={type} page={OptInOrgansim.PAGES.FAILURE} />
    <Route path={OPTIN_USED} component={OptInContainer} type={type} page={OptInOrgansim.PAGES.USED} />
    <Route path={OPTIN_VALIDATION} onEnter={verifyToken(store, pathOfType)} />
    <Route
      path={OPTIN_NOT_CONFIRMED}
      component={NotConfirmedOptIn}
      type={type}
      page={OptInOrgansim.PAGES.NOT_CONFIRMED}
    />
  </>
);

export default (store) => (
  <Route path={OPTIN} component={DefaultLayout}>
    <IndexRoute component={NotFound} />
    <Route path={OPTIN_TYPE_UPDATEEMAIL}>
      {subroutes(store, OptInOrgansim.TYPES.UPDATEEMAIL, OPTIN_TYPE_UPDATEEMAIL)}
    </Route>
    <Route path={OPTIN_TYPE_REGISTRATION}>
      {subroutes(store, OptInOrgansim.TYPES.REGISTRATION, OPTIN_TYPE_REGISTRATION)}
    </Route>
  </Route>
);
