import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import formatDate from 'constants/formatDate/formatDate';
import { provideLink } from 'constants/navigation/navigation';
import { i18n, getLocale } from 'constants/language/language';
import { APPROVAL_STATUS, getApprovalStatus } from 'constants/ratings/ratings';
import { scrollToElement, TOP_RATING_FORM } from 'constants/scrollToElement/scrollToElement';
import { CMS_UID_RATING_TERMS, CMS_UID_RATING_GUIDELINES } from 'constants/cms/cms';
import FieldSetInput from 'components/molecules/FieldSetInput/FieldSetInput';
import InputRadioList from 'components/molecules/InputRadioList/InputRadioList';
import CancelSaveButton from 'components/molecules/CancelSaveButton/CancelSaveButton';
import RatingStatusMessage from 'components/molecules/Rating/RatingStatusMessage/RatingStatusMessage';
import Headline from 'components/atoms/Headline/Headline';
import InputText from 'components/atoms/InputText/InputText';
import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';
import Icon, { ICON_ADD } from 'components/atoms/Icon/Icon';
import FlashMessage from 'components/atoms/FlashMessage/FlashMessage';
import InputTextarea from 'components/atoms/InputTextarea/InputTextarea';
import StarRating, { SIZES as STARSIZES } from 'components/atoms/StarRating/StarRating';

import { HELPCENTER_LINK } from 'constants/links/links';

import styles from './RatingEditor.scss';
import bootstrap from 'scss/component.scss';

export default class RatingEditor extends Component {
  static propTypes = {
    rating: PropTypes.object,
    useInModal: PropTypes.bool,
    onSubmitRating: PropTypes.func,
    pages: PropTypes.object,
    buttonsDisabled: PropTypes.bool,
    onCancelRating: PropTypes.func,
  };

  static defaultProps = {
    rating: {
      productCode: '',
      productName: '',
      productImage: '',
      rating: 0,
    },
  };

  constructor(props) {
    super(props);

    const { rating, recommends, headline, comment } = props.rating;
    const initRecommends = (val) => {
      if (val === true) return 'YES';
      if (val === false) return 'NO';
      return null;
    };

    this.state = {
      value: rating,
      headline,
      comment,
      recommends: initRecommends(recommends),
      // initially open comment form when a title exists
      showCommentForm: !!headline,
      ratingComplete: false,
      errors: {},
    };
  }

  componentDidMount() {
    if (!this.props.useInModal) scrollToElement(TOP_RATING_FORM);
  }

  isNew = () => !this.props.rating || !this.props.rating.date;
  hasComment = () => this.props.rating.headline && this.props.rating.headline.length > 0;
  isRecommended = (recommends = '') => recommends === 'YES';

  onChange = (name) => (value) => {
    const { errors } = this.state;
    delete errors[name];
    if (name === 'headline') delete errors.comment;
    if (name === 'comment') delete errors.headline;
    this.setState({ errors, [name]: value });
  };

  toggleCommentForm = () => {
    this.setState({ showCommentForm: true });
  };

  onSubmitRating = () => {
    const { value, recommends, headline, comment } = this.state;

    // validate form
    const errors = {};
    const MAX_COMMENT_LENGTH = 3500;
    const isValidStr = (string) => string && string.trim().length > 0;
    if (!value) errors.value = i18n('productRating.editor.errors.rating');
    if (!['YES', 'NO'].includes(recommends)) errors.recommends = i18n('productRating.editor.errors.recommends');
    if (!isValidStr(headline) && isValidStr(comment)) errors.headline = i18n('productRating.editor.errors.title');
    if (!isValidStr(comment) && isValidStr(headline)) errors.comment = i18n('productRating.editor.errors.comment');
    if (!errors.comment && comment && comment.length > MAX_COMMENT_LENGTH) {
      errors.comment = i18n('productRating.editor.errors.commentLength', [String(MAX_COMMENT_LENGTH)]);
    }

    if (Object.keys(errors).length) {
      this.setState({ errors });
      if (!this.props.useInModal) scrollToElement('TOP_RATING_EDITOR');
      return;
    }

    this.props
      .onSubmitRating({
        rating: value,
        recommends: this.isRecommended(recommends),
        headline: headline || '',
        comment: comment || '',
      })
      .then(() => {
        this.setState({ ratingComplete: true });
        if (!this.props.useInModal) scrollToElement(TOP_RATING_FORM);
      });
  };

  onCancelRating = () => {
    scrollToElement(TOP_RATING_FORM);
    this.props.onCancelRating();
  };

  getCMSLink(pages, pageId) {
    const link = provideLink(pages, pageId, getLocale());
    return link && link.url ? link : null;
  }

  renderCommentForm = () => {
    const { rating, pages } = this.props;
    const { showCommentForm, headline, comment, errors } = this.state;
    const status = getApprovalStatus(rating.status);
    const termsLink = this.getCMSLink(pages, CMS_UID_RATING_TERMS);
    const ratingGuideLink = this.getCMSLink(pages, CMS_UID_RATING_GUIDELINES);

    if (!showCommentForm) {
      return (
        <div className={cx(styles.center, styles.mb)}>
          <ButtonLink variety={ButtonLink.varieties.stroked} onClick={this.toggleCommentForm}>
            <Icon path={ICON_ADD} />
            {i18n('productRating.editor.commentAdd')}
          </ButtonLink>
        </div>
      );
    }

    return (
      <div className={styles.commentForm}>
        <div className={styles.center}>
          <Headline margin={Headline.margins.MB_15}>{i18n('productRating.editor.commentForm')}</Headline>
        </div>

        {this.hasComment() && status !== APPROVAL_STATUS.APPROVED && (
          <div className={styles.msgBoxWrapper}>
            <RatingStatusMessage status={status} date={rating.date} rejectedMessage={rating.rejectedMessage} />
          </div>
        )}

        <div className={bootstrap.row}>
          <div className={bootstrap.colMd6}>
            <FieldSetInput
              name="headline"
              label={i18n('productRating.editor.labels.title')}
              value={headline}
              showError={!!errors.headline}
              forceValidation={!!errors.headline}
              customErrorMessage={errors.headline}
              handleChange={this.onChange('headline')}
            >
              <InputText maxLength={150} autocomplete="off" />
            </FieldSetInput>
          </div>
        </div>

        <div className={cx(bootstrap.row, styles.alignBottom)}>
          <div className={bootstrap.colLg6}>
            <FieldSetInput
              name="comment"
              label={i18n('productRating.editor.labels.comment')}
              value={comment}
              showError={!!errors.comment}
              forceValidation={!!errors.comment}
              customErrorMessage={errors.comment}
              handleChange={this.onChange('comment')}
            >
              <InputTextarea debounce={false} resizable={false} maxLength={3500} />
            </FieldSetInput>
          </div>
          <div className={cx(bootstrap.colLg6, styles.alignBottom)}>
            <div className={styles.commentHelp}>
              <small className={styles.commentHelpContact}>
                {i18n('productRating.editor.commentHelp.contact')}
                <a href={HELPCENTER_LINK + getLocale()} target="_blank" rel="noopener noreferrer">
                  {i18n('productRating.editor.commentHelp.contactLinked')}
                </a>
              </small>
              <small className={cx(styles.commentHelpLinks, styles.pNoMargin)}>
                <a href={termsLink?.url} target="_blank" rel="noopener noreferrer">
                  {i18n('productRating.editor.commentHelp.terms')}
                </a>
              </small>
              <small className={styles.commentHelpLinks}>
                <a href={ratingGuideLink?.url} target="_blank" rel="noopener noreferrer">
                  {i18n('productRating.editor.commentHelp.guidelines')}
                </a>
              </small>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderButtons = () => {
    const { useInModal, buttonsDisabled } = this.props;
    const primaryLabel = i18n(`productRating.editor.${this.isNew() ? 'okLabel' : 'editLabel'}`);
    const secondaryLabel = i18n('productRating.editor.cancelLabel');

    if (useInModal) {
      // switches to inline button alignment for modals
      return (
        <CancelSaveButton
          onSubmit={this.onSubmitRating}
          onCancel={this.onCancelRating}
          submitText={primaryLabel}
          cancelText={secondaryLabel}
          disabled={buttonsDisabled}
        />
      );
    }

    return (
      <>
        <div className={cx(styles.center, styles.mb)}>
          <ButtonLink variety={ButtonLink.varieties.flat} onClick={this.onSubmitRating} disabled={buttonsDisabled}>
            {primaryLabel}
          </ButtonLink>
        </div>
        <div className={styles.center}>
          <ButtonLink onClick={this.onCancelRating} variety={ButtonLink.varieties.basic} size={ButtonLink.sizes.sm}>
            {secondaryLabel}
          </ButtonLink>
        </div>
      </>
    );
  };

  render() {
    const { onCancelRating, useInModal, rating } = this.props;
    const { value, recommends, headline, ratingComplete, errors } = this.state;

    if (ratingComplete) {
      return (
        <div className={cx(styles.center)}>
          <Headline margin={Headline.margins.MB_30} alignment={Headline.alignments.CENTER}>
            {i18n('productRating.editor.thanks')}
          </Headline>
          <StarRating value={value} size={STARSIZES.large} />
          <h4 className={styles.successMsg}>
            {i18n(`productRating.editor.${this.isRecommended(recommends) ? 'recommended' : 'notRecommended'}`)}
          </h4>
          <p className={styles.statusTextWrapper}>
            {headline
              ? i18n('productRating.editor.commentStatus.OPEN')
              : i18n(`productRating.editor.${this.isNew() ? 'patienceNew' : 'patienceUpd'}`)}
          </p>
          <ButtonLink variety={ButtonLink.varieties.stroked} onClick={onCancelRating} size={ButtonLink.sizes.sm}>
            {i18n('productRating.editor.doneLabel')}
          </ButtonLink>
        </div>
      );
    }

    return (
      <div>
        <div className={styles.center}>
          {!useInModal && (
            <Headline margin={Headline.margins.MB_15}>
              {i18n(`productRating.${this.isNew() ? 'addRating' : 'editRating'}`)}
            </Headline>
          )}
          {!this.isNew() && <FlashMessage content={i18n('productRating.editor.editInfo', [formatDate(rating.date)])} />}
        </div>
        <div id="TOP_RATING_EDITOR" className={styles.wrapper}>
          <div className={styles.section}>
            <h4 className={cx(styles.label, styles.headline)}>{i18n('productRating.editor.labels.rating')}</h4>
            <div className={styles.form}>
              <StarRating
                editable
                value={value}
                onChange={this.onChange('value')}
                size={STARSIZES.large}
                showError={!!errors.value}
              />
              {errors.value && <small className={styles.errorMsg}>{errors.value}</small>}
            </div>
          </div>
          <div className={styles.section}>
            <h4 className={styles.label}>{i18n('productRating.editor.labels.recommends')}</h4>
            <div className={styles.form}>
              <InputRadioList
                inline
                options={{
                  YES: i18n('productRating.editor.recommendsYes'),
                  NO: i18n('productRating.editor.recommendsNo'),
                }}
                value={recommends}
                showError={!!errors.recommends}
                handleChange={this.onChange('recommends')}
              />
              {errors.recommends && <p className={styles.errorMsg}>{errors.recommends}</p>}
            </div>
          </div>
        </div>
        {this.renderCommentForm()}
        {this.renderButtons()}
      </div>
    );
  }
}
