import React, { useState, useRef, useEffect } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import debounce from 'constants/helper/debounce';
import language, { getLocale } from 'constants/language/language';
import dataTracking from 'constants/trackingAttributes/dataTracking';
import { noop } from 'constants/noop/noop';

import styles from './InputText.scss';
import bootstrap from 'scss/component.scss';

const PASSWORD_PADDINGS = {
  de: 100,
  fr: 105,
  it: 125,
};

const InputText = ({
  updateOnce = '', // Use this to just update the value once.
  value = '',
  updateValue = '',
  name = '',
  id = '',
  type = 'text',
  placeholder = '',
  autocomplete = '',
  disabled = false,
  showError = false,
  pending = false,
  pattern = null,
  maxLength = 9999,
  setFocusOnMount = false,
  handleChange = noop,
  onBlur = noop,
  onFocus = noop,
  onKeyPress = noop,
  cyData,
  trackingAttributes,
}) => {
  const inputField = useRef(null);
  const [currentValue, setCurrentValue] = useState(value);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    if (inputField && setFocusOnMount) {
      inputField.current.focus();
    }
  }, []);

  useEffect(() => {
    if (!updated && !!updateOnce) {
      setCurrentValue(updateOnce);
      setUpdated(true);
    }
  }, [updateOnce]);

  useEffect(() => {
    if (updateValue) {
      setCurrentValue(updateValue);
    }
  }, [updateValue]);

  const onChange = (e) => {
    setCurrentValue(e.target.value);
    debounceOnChange(e.target.value);
  };

  const handleFocus = () => {
    onFocus();
  };

  const handleBlur = (e) => {
    setCurrentValue(e.target.value);
    onBlur(e.target.value, e);
  };

  const debounceOnChange = debounce((value) => {
    handleChange(value);
  }, 100);

  return (
    <div className={styles.wrap} data-tracking={trackingAttributes && dataTracking(trackingAttributes)}>
      <input
        type={isPasswordVisible ? 'text' : type}
        name={name}
        id={id}
        value={currentValue}
        placeholder={placeholder}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onChange={onChange}
        ref={inputField}
        className={cx(bootstrap.formControl, styles.input, {
          [styles.hasDanger]: showError,
          [styles.pending]: pending,
        })}
        disabled={disabled}
        autoComplete={autocomplete}
        onKeyPress={onKeyPress}
        onKeyDown={onKeyPress}
        pattern={pattern}
        maxLength={maxLength}
        aria-label={name}
        style={{ paddingRight: type === 'password' && `${PASSWORD_PADDINGS[getLocale()]}px` }}
        data-cy={cyData}
      />
      {type === 'password' && (
        <button
          type="button"
          onClick={() => {
            setIsPasswordVisible(!isPasswordVisible);
          }}
          className={styles.visibilityToggler}
          data-tracking={dataTracking({
            TYPE: 'form:button',
            FUNCTION: 'apply',
            ATTRIBUTE_1: 'text',
            ATTRIBUTE_2: language(`form.fields.password.${isPasswordVisible ? 'showPasswordShort' : 'hidePassword'}`),
          })}
        >
          {language(`form.fields.password.${isPasswordVisible ? 'hidePassword' : 'showPasswordShort'}`)}
        </button>
      )}
    </div>
  );
};

InputText.propTypes = {
  updateOnce: PropTypes.string,
  value: PropTypes.string,
  updateValue: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  handleChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  disabled: PropTypes.bool,
  showError: PropTypes.bool,
  pending: PropTypes.bool,
  autocomplete: PropTypes.string,
  onKeyPress: PropTypes.func,
  pattern: PropTypes.string,
  maxLength: PropTypes.number,
  setFocusOnMount: PropTypes.bool,
  cyData: PropTypes.string,
  trackingAttributes: PropTypes.object,
};

export default InputText;
