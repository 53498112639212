import restClient from 'api/RestClient/RestClient';
import { resourceKeys } from 'constants/restResources/restResources';
import { getUserId } from 'api/UserAPI/UserAPI';
import getStorage from 'constants/storage/storage';
import { WATCHLIST_ID } from 'constants/storage/storageKeys';
import throttle from 'constants/helper/throttle';

const resource = resourceKeys.USER_RESOURCE;

const storage = getStorage(true);

const WatchlistAPI = (function WatchlistAPI() {
  const getWatchlistId = () => storage.getItem(WATCHLIST_ID);

  const removeWatchlistId = () => {
    storage.removeItem(WATCHLIST_ID);
  };

  const setWatchlistId = (_watchlistId) => {
    removeWatchlistId();

    storage.setItem(WATCHLIST_ID, _watchlistId);
  };

  const createWatchlist = () =>
    restClient
      .post(resource, `/${getUserId()}/watchlist`)
      .then((res) => {
        setWatchlistId(res?.data?.code);
        return res;
      })
      .catch((err) => {
        throw err;
      });

  // never call getWatchlist from outside. You should call getOrCreateWatchlist from outside
  const _getWatchlist = () =>
    restClient
      .get(resource, `/${getUserId()}/watchlist/${getWatchlistId()}/entries`)
      .then(
        (res) => {
          setWatchlistId(res?.data?.code);
          return res;
        },
        handleGetWatchlistError // eslint-disable-line no-use-before-define
      )
      .catch((err) => {
        throw err;
      });

  const getOrCreateWatchlist = (watchlistId = getWatchlistId()) => {
    if (watchlistId === null) {
      return createWatchlist().then(() => _getWatchlist());
    }
    return _getWatchlist();
  };

  /** This function ensures, that the automatic retries (when offline) do not race */
  const throttledGetWatchlist = throttle(_getWatchlist, 3000);

  const handleGetWatchlistError = (err) => {
    if (err && err.name === 'NETWORKERROR') {
      /* dont create a new watchlist when there was a networkerror */
      return setTimeout(throttledGetWatchlist, 1000);
    }
    return createWatchlist();
  };

  const addProductToWatchlist = (productCode) =>
    restClient.post(resource, `/${getUserId()}/watchlist/${getWatchlistId()}/entries`, { productCode });

  const addToWatchlist = (productId) => {
    if (!getWatchlistId()) {
      return createWatchlist().then(() => addProductToWatchlist(productId));
    }

    return addProductToWatchlist(productId);
  };

  const removeFromWatchlist = (arrayIndex) =>
    restClient.del(resource, `/${getUserId()}/watchlist/${getWatchlistId()}/entries/${arrayIndex}`);

  const mergeWatchlist = () => {
    const watchListId = getWatchlistId();

    // load or create watchlist before merging
    return getOrCreateWatchlist(watchListId).then((response) => {
      if (watchListId) {
        return restClient
          .put(resource, `/${getUserId()}/watchlist`, { anonymousWatchlist: watchListId })
          .then((res) => {
            setWatchlistId(res?.data?.code);
            return res;
          })
          .catch((err) => {
            throw err;
          });
      }
      return Promise.resolve(response);
    });
  };

  const deleteWatchlist = () =>
    restClient
      .del(resource, `/${getUserId()}/watchlist/${getWatchlistId()}/deleteAll`)
      .then((res) => {
        if (res) {
          removeWatchlistId();
        }
        return res;
      })
      .catch((err) => {
        throw err;
      });

  return {
    setWatchlistId,
    getWatchlistId,
    createWatchlist,
    getOrCreateWatchlist,
    addToWatchlist,
    removeFromWatchlist,
    mergeWatchlist,
    deleteWatchlist,
  };
})();

export default WatchlistAPI;
