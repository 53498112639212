import React, { Component } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { getLocale } from 'constants/language/language';
import { provideLink } from 'constants/navigation/navigation';
import { getPrivacyPolicyLink } from 'constants/links/getPrivacyPolicyLinks';
import Trustmarks from 'components/molecules/Trustmarks/Trustmarks';
import BrandFooter from 'components/molecules/BrandFooter/BrandFooter';
import PaymentServices from 'components/molecules/PaymentServices/PaymentServices';
import LoadingStateContainer from 'containers/LoadingState/LoadingState';
import CheckoutFooterLinks from 'components/molecules/CheckoutFooterLinks/CheckoutFooterLinks';
import { Col, Container, Row } from 'components/atoms/Grid/Grid';
import styles from './CheckoutFooter.scss';
import bootstrap from 'scss/component.scss';
import CookieBanner from 'components/atoms/CookieBanner/CookieBanner';

export default class CheckoutFooter extends Component {
  static displayName = 'organisms/CheckoutFooter';

  static propTypes = {
    pages: PropTypes.object,
  };

  static defaultProps = {
    pages: {},
  };

  shouldComponentUpdate() {
    return false;
  }

  renderPaymentMethods = () => (
    <div className={cx(styles.SKIN_GRAY, styles.paymentMethods)}>
      <Container>
        <Row className={styles.rowEqualHeight}>
          <Col xxs={12} className={styles.innerPayments}>
            <LoadingStateContainer>
              <PaymentServices />
            </LoadingStateContainer>
          </Col>
        </Row>
        <CookieBanner />
      </Container>
    </div>
  );

  render() {
    const locale = getLocale();
    const { pages } = this.props;
    const contactLinks = [
      provideLink(pages, 'terms', locale),
      getPrivacyPolicyLink(locale),
      provideLink(pages, 'imprint', locale),
      provideLink(pages, 'returnPolicy', locale),
      provideLink(pages, 'delivery', locale),
    ];

    return (
      <div className={styles.FooterContainer}>
        <Container>
          <Row className={bootstrap.justifyCenter}>
            <Trustmarks />
          </Row>
          <Row className={bootstrap.justifyCenter}>
            <CheckoutFooterLinks links={contactLinks} />
          </Row>
        </Container>

        {this.renderPaymentMethods()}

        <div className={bootstrap.container}>
          <div className={bootstrap.row}>
            <div className={cx(bootstrap.colLg12)}>
              <BrandFooter />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
