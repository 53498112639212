import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import language, { getLocale } from 'constants/language/language';
import { cart } from 'constants/routePaths/routePathCombinations';

import Headline from 'components/atoms/Headline/Headline';
import PopOverCartProduct from 'components/molecules/PopOverCartProduct/PopOverCartProduct';
import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';
import styles from './PopOverCart.scss';
import useTelesalesMode from 'hooks/useTelesalesMode';

const PopOverCart = ({ latestProducts, totalItems, totalPrice }) => {
  const isTelesalesMode = useTelesalesMode();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Headline tag={Headline.tags.H3} margin={Headline.margins.NONE} skin={Headline.skins.header}>
          {language('cart.title')}
        </Headline>
        <footer className={styles.summary}>
          <span className={styles.totalItems}>{`${totalItems} ${language('orderBox.article')}`}</span>
          <span className={styles.totalPrice}>
            {language('popOverCart.total')}: {totalPrice}
          </span>
        </footer>
      </div>
      <div className={styles.inner}>
        {totalItems > 0 && (
          <div>
            <p className={styles.subHeading}>
              <small className={styles.lastAdded}>{language('popOverCart.recentlyAdded')}</small>
            </p>
            <ol className={styles.list}>
              {latestProducts.map((product) => (
                <li key={product.name} className={styles.listItem}>
                  <PopOverCartProduct
                    customImageData={product.customImageData}
                    name={product.name}
                    quantity={product.quantity}
                    formattedPrice={product.formattedPrice}
                  />
                </li>
              ))}
            </ol>
          </div>
        )}
        <p
          className={cx(styles.action, {
            [styles.empty]: totalItems === 0,
          })}
        >
          <ButtonLink
            href={mapPathToLocalizedUrl(getLocale(), cart)}
            color={isTelesalesMode ? ButtonLink.colors.blue : ButtonLink.colors.red}
            size={ButtonLink.sizes.md}
          >
            <span>{language('popOverCart.goToCart')}</span>
          </ButtonLink>
        </p>
      </div>
    </div>
  );
};

PopOverCart.propTypes = {
  latestProducts: PropTypes.array,
  totalItems: PropTypes.number,
  totalPrice: PropTypes.string,
  handleOnClick: PropTypes.func,
};

PopOverCart.defaultProps = {
  latestProducts: [],
  totalItems: 0,
  totalPrice: '0.00',
  handleOnClick: () => {},
};

export default PopOverCart;
