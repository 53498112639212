import { Component, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';

import { mapQueryParamsToUrl, mapQueryStringToQueryParams, mergeQueryString } from 'constants/urlMapping/urlMapping';
import { loadProducts } from 'actions/productActions/productActions';
import { trackFacetChange } from 'actions/trackingActions/trackingActions';

import { getLocale } from 'constants/language/language';

export const mapStateToProps = (state, ownProps) => {
  const currentProductsQuery = state.productsQueries.currentQuery.queryString;

  const { facetQuery, page, sort } = ownProps;

  const queryParams = { facetQuery, page, sort };

  const computedQueryString = mergeQueryString(currentProductsQuery, queryParams);

  const productsQuery = state.productsQueries[computedQueryString] || {};

  const categories = state?.categories || {};

  return {
    queryParams,
    computedQueryString,
    currentProductsQuery,
    apiStatus: productsQuery.apiStatus,
    categories,
  };
};

export const mapDispatchToProps = {
  loadProducts,
  trackFacetChange,
};

class ProductsFacetContainer extends Component {
  static displayName = 'containers/ProductsFacetContainer';

  static propTypes = {
    children: PropTypes.element.isRequired,
    loadProducts: PropTypes.func,
    currentProductsQuery: PropTypes.string,
    page: PropTypes.number,
    sort: PropTypes.string,
    facetQuery: PropTypes.string,
    facetName: PropTypes.string,
    apiStatus: PropTypes.oneOf(['REQUEST', 'FAILURE', 'SUCCESS', 'NETWORKERROR']),
    queryParams: PropTypes.object,
    computedQueryString: PropTypes.string,
    trackFacetChange: PropTypes.func,
    categories: PropTypes.object,
  };

  onMouseEnter = debounce(() => this.loadProducts(), 120);

  onMouseLeave = () => this.onMouseEnter.cancel();

  /**
   * @param value name of the facet value
   * @param isRemoved true if user removes a facet
   */
  onMouseClick = (value, isRemoved) => {
    const facet = {
      name: this.props.facetName,
      value,
      isRemoved,
    };
    this.props.trackFacetChange(facet);
    this.loadProducts();
  };

  getSeoUrl = (locale) => {
    const queryParams = mapQueryStringToQueryParams(this.props.computedQueryString);
    return `${mapQueryParamsToUrl(queryParams, locale, this.props.categories)}`;
  };

  loadProducts = () => {
    if (!this.props.apiStatus || this.props.apiStatus === 'FAILURE') {
      // only load data if its not available
      const queryParameters = {
        ...this.props.queryParams,
      };
      this.props.loadProducts(this.props.currentProductsQuery, queryParameters);
    }
  };

  cloneLinkPropsToChildren = (children) =>
    cloneElement(children, {
      query: this.getSeoUrl(getLocale()),
      loadProducts: this.loadProducts,
      onMouseClick: this.onMouseClick,
      onMouseEnter: this.onMouseEnter,
      onMouseLeave: this.onMouseLeave,
    });

  render() {
    const { children } = this.props;

    return this.cloneLinkPropsToChildren(children);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductsFacetContainer);
