import React from 'react';
import PropTypes from 'prop-types';
import styles from './ErrorMessageInput.scss';

const ErrorMessageInput = ({ children, showError, disableMessage, cyData }) => {
  if (!showError || disableMessage) return null;

  // class `error-message` is used as an generic anchor to allow scrolling to
  // form elements with errors
  return (
    <small className={`error-message ${styles.errorMessage}`} data-cy={cyData}>
      {children}
    </small>
  );
};

ErrorMessageInput.propTypes = {
  children: PropTypes.string,
  showError: PropTypes.bool.isRequired,
  disableMessage: PropTypes.bool,
  cyData: PropTypes.string,
};

ErrorMessageInput.displayName = 'molecules/ErrorMessageInput';

export default ErrorMessageInput;
