export function replaceUmlauts(url) {
  const replacements = {
    ä: 'ae',
    ö: 'oe',
    ü: 'ue',
    Ä: 'Ae',
    Ö: 'Oe',
    Ü: 'Ue',
  };
  return url.replace(/[äöüÄÖÜ]/g, function (match) {
    return replacements[match];
  });
}
