// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import {
  loadCustomerRatingsSummary,
  loadCustomerRatings,
  ratingModalAction,
} from 'actions/ratingActions/ratingActions';
import { getLocale } from 'constants/language/language';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import { RATING_TYPES, MODAL_TYPES } from 'constants/ratings/ratings';
import {
  ACCOUNT,
  ACCOUNT_RATINGS,
  ACCOUNT_RATINGS_RATED,
  ACCOUNT_RATINGS_NOTRATED,
} from 'constants/routePaths/routePaths';

import type { IRatingState, IRatingStateType, IRating, ILoadRatings, IModalRating } from 'flow/ratings';

export type IRenderProps = {
  rated: IRatingStateType,
  unrated: IRatingStateType,
  rejected: IRatingStateType,
  pageSize: number,
  currentPage: number,
  onItemClick: (IRating) => void,
  onItemDeleteClick: (IRating) => void,
  onPageChange: (number) => void,
  categories: Object,
};

type IProps = {
  type: $Values<typeof RATING_TYPES>,
  page: number,
  pageSize: number,
  rated: IRatingStateType,
  unrated: IRatingStateType,
  rejected: IRatingStateType,
  render: (IRenderProps) => React.Node,
  categories: Object,
  ratingModalAction: (IModalRating) => void,
  loadCustomerRatingsSummary: () => Promise<any>,
  loadCustomerRatings: (ILoadRatings) => Promise<any>,
};

const mapStateToProps = (state: { ratings: IRatingState }): Object => ({
  xhrActive: state.ratings.xhrActive,
  xhrError: state.ratings.xhrError,
  rated: state.ratings.rated,
  unrated: state.ratings.unrated,
  rejected: state.ratings.rejected,
  categories: state.categories,
});

const mapDispatchToProps = {
  loadCustomerRatingsSummary,
  loadCustomerRatings,
  ratingModalAction,
};

class AccountRatingsListContainer extends React.Component<IProps> {
  static displayName = 'containers/AccountRatingsListContainer/AccountRatingsListContainer';

  static defaultProps = {
    type: RATING_TYPES.ALL,
    page: 1,
    pageSize: 10,
    categories: {},
  };

  componentDidMount() {
    this.loadEntries();
  }

  componentDidUpdate(prevProps: IProps) {
    const { type, page } = this.props;

    if (page > 1 && this.props[type].pages < prevProps[type].pages) {
      // Redirect to current page - 1 since the last rating entry on the current page has been deleted
      this.onPageChange(page - 1);
      return;
    }

    if (page !== prevProps.page) {
      // load new entries when current page has changed
      this.loadEntries();
      return;
    }

    if (type !== prevProps.type) {
      // load new entries when changing from all entries to only rated or unrated entries
      this.loadEntries();
    }
  }

  loadEntries = (): Promise<any> => {
    const { type, page, pageSize } = this.props;

    if (type === RATING_TYPES.ALL) {
      return this.props.loadCustomerRatingsSummary();
    }

    return this.props.loadCustomerRatings({ page: parseInt(page, 10), pageSize, type });
  };

  onItemClick = (rating: IRating) => {
    this.props.ratingModalAction({ rating, modalType: MODAL_TYPES.EDIT, listType: this.props.type });
  };

  onItemDeleteClick = (rating: IRating) => {
    this.props.ratingModalAction({ rating, modalType: MODAL_TYPES.DELETE, listType: this.props.type });
  };

  onPageChange = (page: number) => {
    const url = mapPathToLocalizedUrl(getLocale(), [
      ACCOUNT,
      ACCOUNT_RATINGS,
      this.props.type === RATING_TYPES.RATED ? ACCOUNT_RATINGS_RATED : ACCOUNT_RATINGS_NOTRATED,
      String(page),
    ]);
    browserHistory.push(url);
  };

  render() {
    const { render, page, pageSize, rated, unrated, rejected, categories } = this.props;

    return render({
      rated,
      unrated,
      rejected,
      pageSize,
      categories,
      currentPage: parseInt(page, 10),
      onItemClick: this.onItemClick,
      onPageChange: this.onPageChange,
      onItemDeleteClick: this.onItemDeleteClick,
    });
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountRatingsListContainer);
