import restClient from 'api/RestClient/RestClient';
import { resourceKeys } from 'constants/restResources/restResources';
import { INVALID_PARAMS } from 'constants/promises/promises';

const resource = resourceKeys.STORE_RESOURCE;
/**
 * Lists all store locations
 * @returns StoreFinderSearchPageDTO
 */
function getAllStores() {
  return restClient.get(resource, '');
}

/**
 * Returns store location based on its unique name
 * @param storeID
 * @returns PointOfServiceWsDTO
 */
function getSpecificStore(storeID) {
  if (!storeID) {
    return Promise.reject(INVALID_PARAMS);
  }

  return restClient.get(resource, `/${storeID}`);
}

/**
 * Returns store locations based on its search parameters
 * @param latitude latitude from browser data
 * @param longitude longitude from browser data
 * @param searchParams
 * @param all
 * @returns StoreFinderSearchPageDTO
 */
function searchStore(latitude, longitude, searchParams = {}, all = false) {
  if (!latitude || !longitude) {
    return Promise.reject(INVALID_PARAMS);
  }
  // radius includes complete switzerland
  const radius = 400000;
  const params = [];
  if (searchParams.bulkyGoods) params.push('bulkyGoods=true');
  const paramsStr = params.length ? `&${params.join('&')}` : '';

  return restClient.get(
    resource,
    `?latitude=${latitude}&longitude=${longitude}&radius=${radius}&pageSize=50${paramsStr}${all ? '&all=true' : ''}`
  );
}

const getMyStore = (storeId, latitude, longitude) => {
  if (!storeId || !latitude || !longitude) {
    return Promise.reject(INVALID_PARAMS);
  }

  return restClient.get(resource, `/${storeId}?latitude=${latitude}&longitude=${longitude}`);
};

const StoreAPI = {
  getAllStores,
  getSpecificStore,
  searchStore,
  getMyStore,
};

export { StoreAPI as default, getAllStores, getSpecificStore, searchStore, getMyStore };
