import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import uuid from 'uuid/v1';

import Markdown from 'components/atoms/Markdown/Markdown';
import Icon, {
  ICON_CANCEL,
  ICON_CLOSE,
  ICON_ACCESS_TIME,
  ICON_MESSAGE_INFO,
  ICON_NOTE,
  ICON_SMILEY_HAPPY,
  ICON_SMILEY_NEUTRAL,
  ICON_SMILEY_UNHAPPY,
  ICON_SMILEY_UNHAPPY_ALT,
  ICON_CUBE,
  ICON_PICKUP,
  ICON_SHIELD,
  ICON_SHOWROOMS,
  ICON_TRUCK,
} from 'components/atoms/Icon/Icon';
import { Container, Row, Col } from 'components/atoms/Grid/Grid';
import { types as flashMessageTypes, sizes } from 'constants/flashMessages/flashMessages';
import { noop } from 'constants/noop/noop';
import dataTracking from 'constants/trackingAttributes/dataTracking';
import styles from './FlashMessage.scss';

export const iconMap = {
  SUCCESS: ICON_SMILEY_HAPPY,
  WARNING: ICON_SMILEY_NEUTRAL,
  ERROR: ICON_SMILEY_UNHAPPY,
  INFO: ICON_MESSAGE_INFO,
  NOTE: ICON_NOTE,
  USP: ICON_CUBE,
  NO_STOCK: ICON_SMILEY_UNHAPPY_ALT,
  WARNING_INFO: ICON_MESSAGE_INFO,
};

const overrideIcons = {
  ACCESS_TIME: ICON_ACCESS_TIME,
  CUBE: ICON_CUBE,
  PICKUP: ICON_PICKUP,
  SHIELD: ICON_SHIELD,
  SHOWROOMS: ICON_SHOWROOMS,
  TRUCK: ICON_TRUCK,
};

export const alignments = {
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right',
};

const FlashMessage = ({
  children,
  id,
  isFlyIn = false,
  isHeader = false,
  type = flashMessageTypes.INFO,
  size = sizes.DEFAULT,
  content = '',
  overrideIcon,
  isCloseable = false,
  shouldSwipe = false,
  closeFlash = noop,
  onLoad = noop,
  removeFlashMessage = noop,
  alignment = alignments.LEFT,
  cyData,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [flashMessageTimeOut, setFlashMessageTimeOut] = useState(null);
  const flashWrapperRef = useRef(null);

  useEffect(() => {
    const height = flashWrapperRef?.current?.getBoundingClientRect().height || 0;
    onLoad(height, flashWrapperRef);
    setFlashMessageTimeOut(
      setTimeout(() => {
        removeMessage(true);
      }, 3000)
    );
    return clearTimeout(flashMessageTimeOut);
  }, []);

  const close = () => {
    setIsOpen(false);
    closeFlash();
  };

  const iconSize =
    size === sizes.LARGE || (isHeader && !type === flashMessageTypes.USP) ? Icon.sizes.lg : Icon.sizes.sm;
  const iconPath = overrideIcon ? overrideIcons[overrideIcon] : iconMap[type];

  const renderContent = () => (
    <>
      <div className={styles.iconWrapper}>
        <Icon className={styles.icon} path={iconPath} size={iconSize} />
      </div>
      <div className={cx(styles.content, styles[alignment])}>
        {children ? (
          <div className={styles.childWrapper}>{children}</div>
        ) : (
          <Markdown
            options={{
              forceInline: true,
              overrides: {
                span: {
                  props: {
                    ['data-cy']: cyData,
                  },
                },
              },
            }}
          >
            {content}
          </Markdown>
        )}
      </div>
      {isCloseable && (
        <button
          className={styles.closeButton}
          onClick={close}
          data-tracking={dataTracking({
            TYPE: 'header',
            FUNCTION: 'close',
            INTERACTION: 'click',
          })}
        >
          <Icon path={type === flashMessageTypes.USP ? ICON_CLOSE : ICON_CANCEL} />
        </button>
      )}
    </>
  );

  const removeMessage = (deleteDirectly) => {
    if (deleteDirectly) removeFlashMessage(id);
    setFlashMessageTimeOut(
      setTimeout(() => {
        removeFlashMessage(id);
      }, 3000)
    );
  };

  return (
    <div
      className={cx({ [styles.header]: isHeader }, { [styles.flyInWrapper]: isFlyIn }, { [styles.closed]: !isOpen })}
      ref={flashWrapperRef}
      onMouseLeave={() => {
        removeMessage();
      }}
      onMouseEnter={() => {
        clearTimeout(flashMessageTimeOut);
      }}
    >
      <div
        className={cx(
          styles.flashMessage,
          styles[type],
          { [styles.large]: size === sizes.LARGE },
          { [styles.shake]: shouldSwipe }
        )}
        id={`formControl-${id || uuid()}-text`}
      >
        {isHeader ? (
          <Container>
            <Row>
              <Col className={styles.noWrap}>{renderContent()}</Col>
            </Row>
          </Container>
        ) : (
          renderContent()
        )}
      </div>
    </div>
  );
};

FlashMessage.propTypes = {
  children: PropTypes.any,
  isFlyIn: PropTypes.bool,
  content: PropTypes.node,
  overrideIcon: PropTypes.string,
  type: PropTypes.oneOf(Object.values(flashMessageTypes)),
  id: PropTypes.string,
  isCloseable: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(sizes)),
  isHeader: PropTypes.bool,
  shouldSwipe: PropTypes.bool,
  closeFlash: PropTypes.func,
  onLoad: PropTypes.func,
  removeFlashMessage: PropTypes.func,
  alignment: PropTypes.oneOf(Object.values(alignments)),
  cyData: PropTypes.string,
};

FlashMessage.displayName = 'atoms/FlashMessage';

FlashMessage.alignments = alignments;

export default FlashMessage;
