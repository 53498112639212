const omit = (object = {}, paths = []) => {
  const newObject = { ...object };

  paths.forEach((path) => {
    if (!path.includes('.')) {
      delete newObject[path];
      return newObject;
    } else {
      const iterate = (obj, _path) => {
        const newPath = _path.split('.');
        if (newPath.length > 1) {
          return iterate(obj[newPath[0]], newPath.slice(1).join('.'));
        } else {
          if (obj) {
            delete obj[newPath[0]];
          }
          return newObject;
        }
      };
      iterate(newObject, path);
    }
  });

  return newObject;
};

export default omit;
