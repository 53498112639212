import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import Collapsible from 'components/molecules/Collapsible/Collapsible';
import IconLink from 'components/atoms/IconLink/IconLink';
import Icon, { ICON_KEYBOARD_ARROW_DOWN, ICON_KEYBOARD_ARROW_UP } from 'components/atoms/Icon/Icon';
import language, { getLocale } from 'constants/language/language';
import { useFeatureToggle } from 'hooks';
import styles from './StoreFinderSearch.scss';

import { CMS_UID_FAQ } from 'constants/cms/cms';
import { provideLink } from 'constants/navigation/navigation';

const StoreFinderCollapsible = ({ pages = [] }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const showInfo = useFeatureToggle('storeFinderCoronaState') === 'false' ?? false;

  const toggle = () => {
    if (!showInfo) return browserHistory.push(provideLink(pages, CMS_UID_FAQ, getLocale())?.url);
    setIsCollapsed(!isCollapsed);
  };

  const handle = () => (
    <div className={styles.colHandle}>
      <IconLink onClick={toggle}>
        <div className={styles.iconLinkContainer}>
          {showInfo && (
            <>{isCollapsed ? <Icon path={ICON_KEYBOARD_ARROW_DOWN} /> : <Icon path={ICON_KEYBOARD_ARROW_UP} />}</>
          )}
          <span className={styles.collapsibleTitle}>{language('storeFinder.collapsible.title')}</span>
        </div>
      </IconLink>
    </div>
  );

  return (
    <Collapsible handle={handle}>
      <div className={styles.collapsible}>
        {showInfo && <div className={styles.col}>{language('storeFinder.collapsible.content')}</div>}
      </div>
    </Collapsible>
  );
};

StoreFinderCollapsible.displayName = 'organisms/StoreFinderCollapsible';

StoreFinderCollapsible.propTypes = {
  pages: PropTypes.array,
};

export default StoreFinderCollapsible;
