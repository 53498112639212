import { getLocale } from 'constants/language/language';
import dateFnsIsValid from 'date-fns/isValid';
import dateFnsParse from 'date-fns/parse';
import dateFnsSubYears from 'date-fns/subYears';
import dateFnsIsAfter from 'date-fns/isAfter';
import de from 'date-fns/locale/de';
import fr from 'date-fns/locale/fr';
import it from 'date-fns/locale/it';

const localesMap = { de, fr, it };

const addZero = (number) => (number < 10 ? `0${number}` : `${number}`);

export const formatDate = (value, options = {}) => {
  // Date-String needs to be ISO 8601 conform: 2016-07-04T16:26:15.834+02:00 -> Valid
  // Strings such as "2016-07-04T16:47:26+0200" are invalid in IE (missing ":" in timezone)
  const date = new Date(value);

  if (isNaN(date)) return '';

  if (options.longMonth && typeof Intl === 'object') {
    // Progressive enhance for browsers with Intl api support
    return new Intl.DateTimeFormat(getLocale(), { day: 'numeric', month: 'long', year: 'numeric' }).format(date);
  }

  if (options.isoDate) {
    return `${date.getFullYear()}-${addZero(date.getMonth() + 1)}-${addZero(date.getDate())}`;
  }

  return `${addZero(date.getDate())}.${addZero(date.getMonth() + 1)}.${date.getFullYear()}`;
};

/**
 * Parses a given date string and returns a date object or undefined
 */
export const parseDate = (str, { format }) => {
  const parsed = dateFnsParse(str, format, new Date());
  if (dateFnsIsValid(parsed)) {
    return parsed;
  }

  return undefined;
};

/**
 * Returns the short weekday name by index
 *
 * @see https://github.com/date-fns/date-fns/blob/master/src/locale/de/_lib/localize/index.js
 */
export const formatWeekdayShort = (index, locale = 'de') => localesMap[locale].localize.day(index, { width: 'short' });

/**
 * Returns the long weekday name by index
 *
 * @see https://github.com/date-fns/date-fns/blob/master/src/locale/de/_lib/localize/index.js
 */
export const formatWeekdayLong = (index, locale = 'de') => localesMap[locale].localize.day(index, { width: 'wide' });

/**
 * Returns true if date is more than 16 years ago
 */
export const isDate16orMoreYearsAgo = (date, referenceDate = new Date()) => date <= dateFnsSubYears(referenceDate, 16);

/**
 * Returns true if date is less than 100 years ago
 */
export const isDate100orLessYearsAgo = (date, referenceDate = new Date()) => {
  if (dateFnsIsAfter(date, referenceDate)) {
    return false;
  }

  return dateFnsIsAfter(date, dateFnsSubYears(referenceDate, 100));
};

export default formatDate;
