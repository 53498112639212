import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { layouts } from 'constants/cmsImageBanner/cmsImageBanner';

import styles from './LogoBrand.scss';

const LogoBrand = ({ path, altText, className, banner, layout }) => (
  <div
    className={cx(
      styles.LogoBrandContainer,
      className,
      {
        [styles.Banner]: banner,
      },
      styles[layout]
    )}
  >
    <div className={styles.ImgContainer}>
      <img src={path} alt={altText} />
    </div>
  </div>
);

LogoBrand.propTypes = {
  path: PropTypes.string.isRequired,
  altText: PropTypes.string,
  className: PropTypes.string,
  banner: PropTypes.bool,
  layout: PropTypes.oneOf(Object.values(layouts)),
};

LogoBrand.defaultProps = {
  className: '',
  altText: '',
  banner: false,
  layout: layouts.DEFAULT,
};

LogoBrand.displayName = 'atoms/LogoBrand';

export default LogoBrand;
