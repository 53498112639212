import { ValidationTypes } from 'constants/validation/validation';

const messageMapping = (value, messages = [], type) => {
  const newValue = { ...value };
  const validationMap = {
    PasswordMismatchError: ValidationTypes.Invalid,
    DuplicateUidError: ValidationTypes.SameId,
  };

  messages.forEach((message) => {
    if (message.type === type && typeof validationMap[message.type] !== 'undefined') {
      newValue.validationResult = validationMap[message.type];
    }
  });

  return newValue;
};

export default messageMapping;
