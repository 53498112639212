import restClient from 'api/RestClient/RestClient';
import { resourceKeys } from 'constants/restResources/restResources';

const resource = resourceKeys.BRAND_RESOURCE;
const cmsResource = resourceKeys.CMS_RESOURCE;

/**
 * Returns status of a single brand
 * @param brandName
 * @returns {BrandDTO}
 */
const getBrand = (brandName) => {
  if (!brandName) {
    return Promise.reject('Invalid Parameters');
  }

  return restClient.get(resource, `/${brandName}`);
};

/**
 * Returns status of a single brand
 * @param brandName
 * @returns {BrandModel}
 */
const getBrandSearchModule = (brandName) => {
  if (!brandName) {
    return Promise.reject('Invalid Parameters');
  }

  return restClient.get(cmsResource, `/searchComponents/${brandName}`);
};

const BrandAPI = {
  getBrand,
  getBrandSearchModule,
};

export { BrandAPI as default, getBrand, getBrandSearchModule };
