// @flow
import React from 'react';
import language, { getLocale } from 'constants/language/language';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import { RATING_TYPES } from 'constants/ratings/ratings';
import { ACCOUNT, ACCOUNT_RATINGS, ACCOUNT_RATINGS_NOTRATED } from 'constants/routePaths/routePaths';
import AccountRatingsListContainer, {
  type IRenderProps,
} from 'containers/AccountRatingsListContainer/AccountRatingsListContainer';
import AccountRatingsModalContainer from 'containers/AccountRatingsModalContainer/AccountRatingsModalContainer';
import AccountRatingBox from 'components/organisms/AccountRatingBox/AccountRatingBox';
import AccountRatingStatusBox from 'components/molecules/AccountRatingStatusBox/AccountRatingStatusBox';
import Headline, { tags, skins } from 'components/atoms/Headline/Headline';
import styles from './CheckoutRatings.scss';

const CheckoutRatings = () => {
  const i18n = (nameKey) => language(`myAccountRatings.${nameKey}`);
  const getLocaleLink = (nameKey: string, subpage: Array<string> = []) => ({
    name: i18n(nameKey),
    href: mapPathToLocalizedUrl(getLocale(), [ACCOUNT, ACCOUNT_RATINGS, ...subpage]),
  });

  return (
    <>
      <AccountRatingsModalContainer key="accountRatingsModalContainer" />
      <Headline tag={tags.H2} skin={skins.pagetitle}>
        {language(`myAccountRatings.header`)}
      </Headline>
      <div className={styles.ratingContainer}>
        <AccountRatingsListContainer
          pageSize={3}
          type={RATING_TYPES.ALL}
          render={({ rejected, unrated, onItemClick }: IRenderProps) => (
            <>
              <AccountRatingStatusBox ratings={rejected.entries} onItemClick={onItemClick} />

              <AccountRatingBox
                ratings={unrated.entries}
                totalRatings={unrated.total}
                onItemClick={onItemClick}
                headline={i18n('rateOrders')}
                footerBtn={getLocaleLink('showAll', [ACCOUNT_RATINGS_NOTRATED])}
              />
            </>
          )}
        />
      </div>
    </>
  );
};

CheckoutRatings.displayName = 'templates/CheckoutRatings';

export default CheckoutRatings;
