import { isB2BCustomer } from 'constants/customerGroup/customerGroup';

export const addressTypes = {
  INVOICE: 'invoice',
  DELIVERY: 'delivery',
  UNIFIED: 'unified',
  TEMPORARY: 'TEMPORARY',
};

/**
 * Determines whether the company field is required
 * This is only the case for the FRIST address of a BUISNESS customer
 *
 * @see https://atlassian.interdiscount.ch/jira/browse/SPQR-9326
 * @see https://atlassian.interdiscount.ch/jira/browse/SPQR-10138
 */
export const isCompanyFieldRequired = (state) => isB2BCustomer(state?.user?.fields?.group?.value);
