import React from 'react';
import PropTypes from 'prop-types';
import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';
import language from 'constants/language/language';
import { getProvider, isProviderSupported } from 'constants/EmailProviderHelper/EmailProviderHelper';

const EmailProviderButton = (props) => {
  const { email } = props;

  const PROVIDER_URL_MAPPING = {
    GMAIL: 'https://mail.google.com',
    HOTMAIL: 'https://hotmail.ch',
    YAHOO: 'https://mail.yahoo.com',
    BLUEWIN: 'https://bluewin.ch/',
    GMX: 'https://gmx.ch',
    AOL: 'https://mail.aol.com',
  };

  const getContent = (mailAddress = '') => {
    const provider = getProvider(mailAddress);
    const isSupportedProvider = isProviderSupported(provider);

    return isSupportedProvider ? language(`EmailProviderButton.content.${provider}`) : '';
  };

  const getProviderUrl = (mailAddress = '') => {
    const provider = getProvider(mailAddress);
    const isSupportedProvider = isProviderSupported(provider);

    return isSupportedProvider ? PROVIDER_URL_MAPPING[provider] : '';
  };

  const getChild = (currentProps = {}, mailAddress = '') => {
    const provider = getProvider(mailAddress);
    const isSupportedProvider = isProviderSupported(provider);

    return isSupportedProvider ? React.cloneElement(<ButtonLink />, getModifiedProps(currentProps, email)) : null;
  };

  const getModifiedProps = (currentProps = {}, mailAddress = '') => {
    return {
      ...currentProps,
      children: getContent(mailAddress),
      href: getProviderUrl(mailAddress),
      isExternalLink: true,
    };
  };

  return getChild(props, email);
};

EmailProviderButton.propTypes = {
  email: PropTypes.string.isRequired,
  ...ButtonLink.propTypes,
};

EmailProviderButton.displayValue = 'molecules/EmailProviderButton';

export default EmailProviderButton;
