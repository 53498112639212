import React from 'react';
import PropTypes from 'prop-types';

import ProductComparisonIndication from 'containers/Indications/ProductComparisonIndicator';
import WatchlistIndication from 'containers/Indications/WatchlistIndicator';
import CartIndication from 'containers/Indications/Cart';
import DrilldownMenu from 'components/molecules/DrilldownMenu/DrilldownMenu';
import Badge from 'components/atoms/Badge/Badge';
import MobileNavigationItem from 'components/atoms/MobileNavigationItem/MobileNavigationItem';
import DrilldownAccordion from 'components/atoms/DrilldownAccordion/DrilldownAccordion';
import Icon, {
  ICON_ACCOUNT_CIRCLE_OUTLINE,
  ICON_COMPARE_ARROWS,
  ICON_FAVORITE_BORDER,
  ICON_FORMAT_LIST_BULLETED,
  ICON_HELP_CENTER,
  ICON_LOGIN,
  ICON_PERSON_ADD_OUTLINE,
  ICON_SHOPPING_BASKET,
  LOGOUT,
} from 'components/atoms/Icon/Icon';

import language, { getLocale } from 'constants/language/language';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import { accountLogin, accountOrders, accountRegistration } from 'constants/routePaths/routePathCombinations';
import { cypressAttributes } from 'constants/cypress/cypress';
import myAccountDrilldown from 'constants/Drilldowns/myAccountDrilldown';
import { CART, PRODUCT_COMPARISON, WATCHLIST } from 'constants/routePaths/routePaths';
import { CMS_UID_SERVICE } from 'constants/cms/cms';
import { provideLink } from 'constants/navigation/navigation';

import styles from 'components/molecules/MobileDrilldown/MobileDrilldown.scss';
import { NAVIGATION_SUB } from 'constants/trackingAttributes/trackingAttributes';

const AccountSection = ({
  isLoggedIn,
  user,
  logoutUser,
  cmsPages,
  drilldownPortal,
  handleAnimation,
  animationStyle,
  isResetToStart,
}) => {
  const myAccount = language('header.myAccount');
  const loginHeadlineShort = language('login.headlineShort');
  const registrationHeadlineShort = language('login.registration.headlineShort');
  const navigationOrders = language('navigation.orders');
  const headerProductComparison = language('header.productComparison');
  const headerShoppingList = language('header.shoppingList');
  const navigationCart = language('navigation.cart');
  const helpAccount = language('mobileDrilldown.account.help');
  const accountLogout = language('myAccount.logout');

  return (
    <DrilldownAccordion
      cyData={`${cypressAttributes.mobileNavigation.navigationSection}-account`}
      title={isLoggedIn ? myAccount : `${loginHeadlineShort} / ${registrationHeadlineShort}`}
      pos={7}
      openByDefault={!sessionStorage.getItem('mobileAccountMenu')}
      isResetToStart={isResetToStart}
    >
      {!isLoggedIn && (
        <>
          <MobileNavigationItem
            href={mapPathToLocalizedUrl(getLocale(), accountLogin)}
            tracking={{
              type: NAVIGATION_SUB,
              position: 1,
              title: loginHeadlineShort,
              parentTitle: isLoggedIn ? myAccount : `${loginHeadlineShort} / ${registrationHeadlineShort}`,
            }}
          >
            <div className={styles.iconText}>
              <div>
                <Icon path={ICON_LOGIN} alt={loginHeadlineShort} />
              </div>
              <span>{loginHeadlineShort}</span>
            </div>
          </MobileNavigationItem>
          <MobileNavigationItem
            href={mapPathToLocalizedUrl(getLocale(), accountRegistration)}
            tracking={{
              type: NAVIGATION_SUB,
              position: 2,
              title: registrationHeadlineShort,
              parentTitle: isLoggedIn ? myAccount : `${loginHeadlineShort} / ${registrationHeadlineShort}`,
            }}
          >
            <div className={styles.iconText}>
              <div>
                <Icon path={ICON_PERSON_ADD_OUTLINE} alt={registrationHeadlineShort} />
              </div>
              <span>{registrationHeadlineShort}</span>
            </div>
          </MobileNavigationItem>
        </>
      )}
      {isLoggedIn && (
        <>
          <DrilldownMenu
            dataCy={cypressAttributes.mobileNavigation.accountDrilldown}
            entries={myAccountDrilldown(user.name)}
            portal={drilldownPortal}
            title={myAccount}
            parentTitle={isLoggedIn ? myAccount : `${loginHeadlineShort} / ${registrationHeadlineShort}`}
            handleAnimation={handleAnimation}
            animationStyle={animationStyle}
          >
            <div className={styles.iconText}>
              <div>
                <Icon path={ICON_ACCOUNT_CIRCLE_OUTLINE} alt={myAccount} />
              </div>
              <span>{myAccount}</span>
            </div>
          </DrilldownMenu>
          <MobileNavigationItem
            href={mapPathToLocalizedUrl(getLocale(), accountOrders)}
            tracking={{
              type: NAVIGATION_SUB,
              position: 2,
              title: navigationOrders,
              parentTitle: isLoggedIn ? myAccount : `${loginHeadlineShort} / ${registrationHeadlineShort}`,
            }}
          >
            <div className={styles.iconText}>
              <div>
                <Icon path={ICON_FORMAT_LIST_BULLETED} alt={navigationOrders} />
              </div>
              <span>{navigationOrders}</span>
            </div>
          </MobileNavigationItem>
        </>
      )}
      <MobileNavigationItem
        href={mapPathToLocalizedUrl(getLocale(), [PRODUCT_COMPARISON])}
        tracking={{
          type: NAVIGATION_SUB,
          position: 3,
          title: headerProductComparison,
          parentTitle: isLoggedIn ? myAccount : `${loginHeadlineShort} / ${registrationHeadlineShort}`,
        }}
      >
        <div className={styles.iconText}>
          <div>
            <Icon path={ICON_COMPARE_ARROWS} alt={headerProductComparison} />
          </div>
          <span>{headerProductComparison}</span>
        </div>
        <div className={styles.badge}>
          <ProductComparisonIndication>
            <Badge showEmptyBadge={false} />
          </ProductComparisonIndication>
        </div>
      </MobileNavigationItem>
      <MobileNavigationItem
        dataCy={cypressAttributes.mobileNavigation.watchlist}
        href={mapPathToLocalizedUrl(getLocale(), [WATCHLIST])}
        tracking={{
          type: NAVIGATION_SUB,
          position: 4,
          title: headerShoppingList,
          parentTitle: isLoggedIn ? myAccount : `${loginHeadlineShort} / ${registrationHeadlineShort}`,
        }}
      >
        <div className={styles.iconText}>
          <div>
            <Icon path={ICON_FAVORITE_BORDER} alt={headerShoppingList} />
          </div>
          <span>{headerShoppingList}</span>
        </div>
        <div className={styles.badge}>
          <WatchlistIndication>
            <Badge showEmptyBadge={false} />
          </WatchlistIndication>
        </div>
      </MobileNavigationItem>
      <MobileNavigationItem
        href={mapPathToLocalizedUrl(getLocale(), [CART])}
        tracking={{
          type: NAVIGATION_SUB,
          position: 5,
          title: navigationCart,
          parentTitle: isLoggedIn ? myAccount : `${loginHeadlineShort} / ${registrationHeadlineShort}`,
        }}
        featureName="nextCartEnabled"
      >
        <div className={styles.iconText}>
          <div>
            <Icon path={ICON_SHOPPING_BASKET} alt={navigationCart} />
          </div>
          <span>{navigationCart}</span>
        </div>
        <div className={styles.badge}>
          <CartIndication>
            <Badge showEmptyBadge={false} />
          </CartIndication>
        </div>
      </MobileNavigationItem>
      {isLoggedIn && (
        <>
          <MobileNavigationItem
            href={provideLink(cmsPages, CMS_UID_SERVICE, getLocale()).url}
            tracking={{
              type: NAVIGATION_SUB,
              position: 6,
              title: helpAccount,
              parentTitle: isLoggedIn ? myAccount : `${loginHeadlineShort} / ${registrationHeadlineShort}`,
            }}
          >
            <div className={styles.iconText}>
              <div>
                <Icon path={ICON_HELP_CENTER} alt={helpAccount} />
              </div>
              <span>{helpAccount}</span>
            </div>
          </MobileNavigationItem>
          <MobileNavigationItem
            onClick={logoutUser}
            tracking={{
              type: NAVIGATION_SUB,
              position: 7,
              title: accountLogout,
              parentTitle: isLoggedIn ? myAccount : `${loginHeadlineShort} / ${registrationHeadlineShort}`,
            }}
          >
            <div>
              <div className={styles.iconText}>
                <div>
                  <Icon path={LOGOUT} alt={accountLogout} />
                </div>
                <div>
                  <span>{accountLogout}</span>
                  <div className={styles.userEmail}>{user.fields?.email?.value}</div>
                </div>
              </div>
            </div>
          </MobileNavigationItem>
        </>
      )}
    </DrilldownAccordion>
  );
};

export default AccountSection;

AccountSection.propTypes = {
  isLoggedIn: PropTypes.bool,
  user: PropTypes.object,
  logoutUser: PropTypes.func,
  cmsPages: PropTypes.array,
  drilldownPortal: PropTypes.string,
  handleAnimation: PropTypes.func,
  animationStyle: PropTypes.string,
  isResetToStart: PropTypes.bool,
};

AccountSection.defaultProps = {
  isLoggedIn: false,
  user: {},
  logoutUser: () => {},
  cmsPages: {},
  drilldownPortal: '',
};
