import React, { useState } from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid/v1';

import Icon, { ICON_SEND } from 'components/atoms/Icon/Icon';
import Headline from 'components/atoms/Headline/Headline';
import InputCheckbox from 'components/atoms/InputCheckbox/InputCheckbox';
import InputTextarea from 'components/atoms/InputTextarea/InputTextarea';
import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';

import language, { getLocale } from 'constants/language/language';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import styles from './OptoutReasonForm.scss';

const OptoutReasonForm = ({ hash = '', sendOptoutReasons = () => {} }) => {
  const [showForm, setShowForm] = useState(true);
  const [comment, setComment] = useState('');
  const [selectedOptions, setSelectedOptions] = useState({
    UNINTERESTING: false,
    NOT_PERSONALIZED: false,
    TOO_FREQUENT: false,
    DISPLAY_ISSUE: false,
  });

  const changeSelection = (option, e) => {
    setSelectedOptions({
      ...selectedOptions,
      [option]: e.target.checked,
    });
  };

  const sendForm = () => {
    const reasons = Object.keys(selectedOptions).filter((option) => selectedOptions[option]);
    sendOptoutReasons({
      hash,
      reasons,
      comment,
    }).then((response) => {
      if (response) {
        setShowForm(false);
      }
    });
  };

  const selectedOptionsLength = Object.keys(selectedOptions).filter((option) => selectedOptions[option]).length;

  return (
    <>
      {showForm ? (
        <>
          <Headline tag={Headline.tags.H4} margin={Headline.margins.NONE}>
            {language('newsletterFeedback.optoutReasons.headline')}
          </Headline>
          <div className={styles.inputForm}>
            {Object.entries(selectedOptions).map(([key, isOptionSelected]) => (
              <InputCheckbox
                id={`formControl-${uuid()}-checkbox`}
                onClick={(e) => changeSelection(key, e)}
                selected={isOptionSelected}
                key={key}
              >
                {language(`newsletterFeedback.optoutReasons.${key}`)}
              </InputCheckbox>
            ))}
          </div>
          <strong>{language('newsletterFeedback.optoutReasons.comment')}</strong>
          <InputTextarea
            value={comment}
            handleChange={(value) => setComment(value)}
            maxLength={1000}
            placeholder={language('newsletterFeedback.optoutReasons.commentPlaceholder')}
            className={styles.textArea}
          />
          <div className={styles.send}>
            <ButtonLink variety={ButtonLink.varieties.flat} onClick={sendForm} disabled={!selectedOptionsLength}>
              <Icon path={ICON_SEND} className={styles.sendIcon} />
              {language('newsletterFeedback.optoutReasons.send')}
            </ButtonLink>
          </div>
        </>
      ) : (
        <>
          <h4 className={styles.headline}>{language('newsletterFeedback.optoutReasons.thanks')}</h4>
          <ButtonLink variety={ButtonLink.varieties.flat} href={mapPathToLocalizedUrl(getLocale())}>
            {language('newsletterFeedback.optoutReasons.next')}
          </ButtonLink>
        </>
      )}
    </>
  );
};

OptoutReasonForm.propTypes = {
  hash: PropTypes.string,
  sendOptoutReasons: PropTypes.func,
};

OptoutReasonForm.displayName = 'OptoutReasonForm';

export default OptoutReasonForm;
