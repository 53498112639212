import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/atoms/Image/Image';
import Headline from 'components/atoms/Headline/Headline';
import imageSizes from 'constants/imageSizes/imageSizes';
import styles from './ProductMinimal.scss';

const ProductMinimal = ({ images, alt, name, code }) => (
  <div className={styles.container}>
    <div className={styles.image}>
      {!!images.length && (
        <Image
          alt={alt || name}
          images={images}
          size={imageSizes.default.size}
          responsive={imageSizes.default.responsive}
          code={code}
        />
      )}
    </div>
    <Headline tag={Headline.tags.H4}>{name}</Headline>
  </div>
);

ProductMinimal.propTypes = {
  name: PropTypes.string.isRequired,
  images: PropTypes.array,
  alt: PropTypes.string,
  code: PropTypes.string,
};

export default ProductMinimal;
