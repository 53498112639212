export const PAY_IN_ADVANCE = 'PAY_IN_ADVANCE';
export const PAY_AT_PICKUP = 'PAY_AT_PICKUP';
export const VISA = 'VISA';
export const MASTERCARD = 'MASTERCARD';
export const DINERS_CLUB = 'DINERS_CLUB';
export const AMERICANEXPRESS = 'AMERICANEXPRESS';
export const POSTCARD = 'POSTCARD';
export const POSTFINANCE = 'POSTFINANCE';
export const SOFORTBANKING = 'SOFORTBANKING';
export const PAYPAL = 'PAYPAL';
export const PAYCARD = 'PAYCARD';
export const MONTHLYINVOICE_PCF = 'MONTHLYINVOICE_PCF';
export const TWINT = 'TWINT';
export const COMPANYINVOICE = 'COMPANYINVOICE';
export const SAMSUNG_PAY = 'SAMSUNG_PAY';

export const paymentModes = {
  PAY_IN_ADVANCE,
  PAY_AT_PICKUP,
  VISA,
  MASTERCARD,
  DINERS_CLUB,
  AMERICANEXPRESS,
  POSTCARD,
  POSTFINANCE,
  SOFORTBANKING,
  PAYPAL,
  PAYCARD,
  MONTHLYINVOICE_PCF,
  TWINT,
  COMPANYINVOICE,
  SAMSUNG_PAY,
};
