import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import Headroom from 'react-headroom';
import debounce from 'lodash/debounce';
import matchMedia from 'constants/matchMedia/matchMedia';
import { BREAKPOINTS } from 'constants/breakpoints/breakpoints';
import { HEADER, DESKTOP_NAVIGATON } from 'constants/scrollToElement/scrollToElement';
import Search from 'containers/Search/Search';
import FlashMessageContainer from 'containers/FlashMessageContainer/FlashMessageContainer';
import LoginIndicator from 'containers/LoginIndicator/LoginIndicator';

import MetaNavigation from 'components/molecules/MetaNavigation/MetaNavigation';
import MobileNavigation from 'components/molecules/MobileNavigation/MobileNavigation';
import HeadroomNavigation from 'components/molecules/HeadroomNavigation/HeadroomNavigation';
import AppBanner from 'components/atoms/AppBanner/AppBanner';
import Sticky from 'components/atoms/Sticky/Sticky';
import { Container, Row, Col } from 'components/atoms/Grid/Grid';
import bootstrap from 'scss/component.scss';
import styles from './Header.scss';
import useTelesalesMode from 'hooks/useTelesalesMode';
import SearchHistoryProvider from 'contexts/SearchHistoryProvider/SearchHistoryProvider';

const constants = {
  FLASHMESSAGE_CONTAINER_HEIGHT: 42,
  FLASHMESSAGE_DIVIDER_HEIGHT: 4,
  SUBNAVIGATION_BORDER_WIDTH: 1,
};

const Header = () => {
  const [isSticky, setIsSticky] = useState();
  const [isHeadroomPinned, setIsHeadroomPinned] = useState();
  const [shouldShowBorder, setShouldShowBorder] = useState();
  const [topOffset, setTopOffset] = useState(0);
  const [isFlashVisible, setIsFlashVisible] = useState(true);
  const [pinnedHeight, setPinnedHeight] = useState(0);
  const [flashRef, setFlashRef] = useState(null);
  const [flashIsLoaded, setFlashIsLoaded] = useState(false);
  const isTelesalesMode = useTelesalesMode();

  useEffect(() => {
    window.addEventListener('resize', debounce(calculateOffset, 200));
    return () => {
      window.removeEventListener('resize', debounce(calculateOffset, 200));
    };
  }, [flashRef]);

  const handleUnfix = () => {
    setIsHeadroomPinned(false);
    setPinnedHeight(0);
  };

  const handleUnpin = () => {
    setTimeout(() => {
      setShouldShowBorder(true);
    }, 200);
  };

  const handlePin = () => {
    setIsHeadroomPinned(true);
    setShouldShowBorder(false);
    setPinnedHeight(topOffset + 9);
    if (matchMedia(BREAKPOINTS.MD) && !flashRef?.current) {
      setPinnedHeight(
        constants.FLASHMESSAGE_CONTAINER_HEIGHT +
          (constants.FLASHMESSAGE_DIVIDER_HEIGHT - constants.SUBNAVIGATION_BORDER_WIDTH)
      );
    }
  };

  const closeFlash = () => {
    setIsFlashVisible(false);
    if (matchMedia(BREAKPOINTS.MD)) {
      setTopOffset(36);
    } else {
      setTopOffset(0);
    }
  };

  const calculateOffset = () => {
    if (flashRef) {
      const height = flashRef.current?.getBoundingClientRect()?.height || 0;
      setOffset(height);
    }
  };

  const loaded = (height = 0, elementRef) => {
    setFlashRef(elementRef);
    setOffset(height);
    setFlashIsLoaded(true);
  };

  const setOffset = (height = 0) => {
    if (matchMedia(BREAKPOINTS.MD)) {
      setTopOffset(height + 36);
    } else {
      setTopOffset(height);
    }
  };

  return (
    <>
      <div className={styles.flashMessageWrapper}>
        <FlashMessageContainer closeFlash={closeFlash} onLoad={loaded} isLoaded={flashIsLoaded} />
      </div>
      <AppBanner />
      <div className={cx(styles.fixedBorder, { [styles.isTelesales]: isTelesalesMode })} />
      <div className={styles.outerWrapper}>
        <Container className={styles.headerWrapper}>
          <Row>
            <Col md={12} className={cx({ [bootstrap.hiddenSmDown]: !isTelesalesMode })}>
              <LoginIndicator>
                <MetaNavigation isSticky={isSticky} />
              </LoginIndicator>
            </Col>
          </Row>
        </Container>
        <div className={styles.headSpacer} />
        <Sticky className={styles.stickyWrapper} onStickyStateChange={(sticky) => setIsSticky(sticky)}>
          <Container
            id={HEADER}
            className={cx({
              [styles.showBorder]: matchMedia(BREAKPOINTS.MD)
                ? !isHeadroomPinned && shouldShowBorder
                : shouldShowBorder,
            })}
          >
            <Row className={cx(styles.searchWrapper, { [styles.isSticky]: isSticky })}>
              <SearchHistoryProvider>
                <Search isSticky={isSticky} isFlashVisible={isFlashVisible} />
              </SearchHistoryProvider>
            </Row>
            <Row id={DESKTOP_NAVIGATON} className={cx(bootstrap.hiddenMdDown, styles.headroomWrapper)}>
              <HeadroomNavigation isSticky={isSticky} />
            </Row>
          </Container>
        </Sticky>

        {/* mobile headroom component */}
        <Headroom
          className={bootstrap.hiddenLgUp}
          style={{ top: !isHeadroomPinned && 0, zIndex: '6' }}
          onUnfix={handleUnfix}
          onUnpin={handleUnpin}
          onPin={handlePin}
          pinStart={topOffset}
        >
          <div
            className={cx(bootstrap.container, styles.mobileHeadroom, {
              [styles.showBorder]: isHeadroomPinned,
            })}
            style={{ transform: `translateY(-${pinnedHeight}px)` }}
          >
            <MobileNavigation />
          </div>
        </Headroom>
      </div>
    </>
  );
};

Header.propTypes = {};
Header.defaultProps = {};
Header.displayName = 'organisms/Header';

export default Header;
