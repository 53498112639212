import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import language, { getLocale } from 'constants/language/language';
import FooterColumnCmsContainer from 'containers/FooterColumnCmsContainer/FooterColumnCmsContainer';
import UspContainer from 'containers/UspContainer/UspContainer';
import NewsletterWidget from 'components/organisms/NewsletterWidget/NewsletterWidget';
import BrandFooter from 'components/molecules/BrandFooter/BrandFooter';
import PaymentServices from 'components/molecules/PaymentServices/PaymentServices';
import UspElement from 'components/molecules/UspElement/UspElement';
import FooterColumnCms from 'components/molecules/FooterColumnCms/FooterColumnCms';
import FooterColumnContact from 'components/molecules/FooterColumnContact/FooterColumnContact';
import FooterColumnProducts from 'components/molecules/FooterColumnProducts/FooterColumnProducts';
import { Col, Container, Row } from 'components/atoms/Grid/Grid';
import Headline from 'components/atoms/Headline/Headline';
import { QualtricsCreativeContainer } from '@interdiscount/component-library-interdiscount';
import { useFeatureToggle } from 'hooks';
import styles from './Footer.scss';
import bootstrap from 'scss/component.scss';
import CookieBanner from 'components/atoms/CookieBanner/CookieBanner';
import SupercardIcon from 'components/atoms/SupercardIcon/SupercardIcon';
import LogoInterdiscount from 'components/atoms/logos/LogoInterdiscount';
import LinksSocialMedia from 'components/molecules/LinksSocialMedia/LinksSocialMedia';
import LanguageSelect from 'components/molecules/LanguageSelect/LanguageSelect';
import LegalLinks from 'components/molecules/LegalLinks/LegalLinks';
import Icon, { ICON_LANGUAGE } from 'components/atoms/Icon/Icon';
import FooterColumn from 'components/molecules/FooterColumn/FooterColumn';
import { NEW_PRODUCT_URL_REGEX, OLD_PRODUCT_URL_REGEX } from 'constants/urlHelper/urlHelper';

function isNewsletterHidden(path = '') {
  return (
    path.includes('/newsletter') || path.includes('/cart') || path.includes('/account') || path.includes('/checkout')
  );
}

export const mapStateToProps = (state) => ({
  categories: state.categories || {},
  newsletter: state.user.newsletter,
  path: state.cms?.currentPath,
});

const Footer = ({ categories = {}, path = '', newsletter = false }) => {
  const shouldShowClaim = useFeatureToggle('showClaim') === 'true';
  const isNewsletterVisible = !isNewsletterHidden(path);

  const renderHeader = () => (
    <Container>
      <UspContainer>
        <UspElement />
      </UspContainer>
    </Container>
  );

  const renderPaymentMethods = () => (
    <div>
      <FooterColumnCmsContainer type={'payment-services'}>
        <PaymentServices />
      </FooterColumnCmsContainer>
    </div>
  );

  const renderContactBox = (layout) => {
    const wrapperStyles = cx(bootstrap.colLg3, {
      [bootstrap.colMd12]: !!layout,
      [bootstrap.colMd5]: !layout,
      [styles.contactBoxPadding]: !layout,
    });

    const LANG_KEY = 'header.interdiscount';
    return (
      <div className={wrapperStyles}>
        <div className={styles.footerFirstContentWrapper}>
          <LogoInterdiscount height={40} width={113} altText={language(LANG_KEY)} />
          <FooterColumnContact />
        </div>
      </div>
    );
  };

  const renderContent = (wrapperStyles, elementStyle) => (
    <Row className={cx(wrapperStyles, styles.contentPaddingList)}>
      <div className={cx(elementStyle)}>
        <FooterColumnCmsContainer type={'service'} columnOffset={2} headlineTag={Headline.tags.H4}>
          <FooterColumnCms title={language('footer.service')} headlineTag={Headline.tags.H4} />
        </FooterColumnCmsContainer>
      </div>
      <div className={cx(elementStyle)}>
        <FooterColumnProducts categories={categories} />
      </div>
      <div className={cx(elementStyle)}>
        <FooterColumnCmsContainer type={'company'} columnOffset={2} headlineTag={Headline.tags.H4}>
          <FooterColumnCms title={language('footer.company')} headlineTag={Headline.tags.H4} />
        </FooterColumnCmsContainer>
      </div>
    </Row>
  );

  const renderContent2 = (wrapperStyles, elementStyle) => (
    <Row className={cx(wrapperStyles, styles.contentPaddingList)}>
      <div className={cx(elementStyle)}>
        <FooterColumn headline={language('footer.superPointsLink')} headlineTag={Headline.tags.H4}>
          <div className={styles.supercardIcons}>
            <a className={styles.supercardLink} href={`/${getLocale()}/cms/supercard`}>
              <SupercardIcon key={`footer-supercard-icon-${1}`} card={SupercardIcon.Cards.SUPERCARD} />
              {language('form.fields.supercardCode.label')}
            </a>
          </div>
        </FooterColumn>
      </div>
      <div className={cx(elementStyle)}>
        <FooterColumn headline="Jobs" headlineTag={Headline.tags.H4}>
          <LinksSocialMedia config="jobs" />
        </FooterColumn>
      </div>
      <div className={cx(elementStyle)}>
        <FooterColumn headline={language('footer.followUs')} headlineTag={Headline.tags.H4}>
          <LinksSocialMedia />
        </FooterColumn>
      </div>
      <div className={cx(elementStyle)}>{renderPaymentMethods()}</div>
    </Row>
  );

  return (
    <div className={styles.FooterContainer}>
      <QualtricsCreativeContainer qId="qInterceptFooter" />

      {!newsletter && isNewsletterVisible && <NewsletterWidget />}

      {shouldShowClaim && renderHeader()}

      {/* container for layout: > MD */}
      <div className={cx(styles.contentLarge, styles.footer)}>
        <Container>
          <Row className={styles.rowEqualHeight}>
            <div className={styles.firstLine}>
              {renderContactBox()}
              <Col lg={9}>{renderContent(cx(styles.equalHeight), cx(bootstrap.colLg4, styles.cmsContentPadding))}</Col>
            </div>
            <Col lg={12}>
              {renderContent2(
                cx(styles.equalHeight),
                cx(bootstrap.colLg3, styles.cmsContentPadding, styles.superCardWrapper)
              )}
            </Col>
          </Row>
        </Container>
      </div>
      {/* container for layout: <= MD */}
      <div className={cx(styles.contentSmall, styles.SKIN_GRAY, styles.footerFirstContent)}>
        <Container>
          <Row>{renderContactBox('small')}</Row>
        </Container>
      </div>
      <div className={styles.contentSmall}>
        <Container>
          <Row className={cx(styles.accordionWrapper)}>
            <Col md={12}>{renderContent(null, bootstrap.colLg4)}</Col>
            <Col md={12}>{renderContent2(null, bootstrap.colLg4)}</Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row className={styles.footerLanguageSwitchWrapper}>
          <Col className={styles.footerLanguageSwitch}>
            <div className={styles.languageSelect}>
              <Icon path={ICON_LANGUAGE} />
              <LanguageSelect useLongValue />
            </div>
            <LegalLinks />
          </Col>
        </Row>
      </Container>
      <BrandFooter />
      <CookieBanner
        excludeRoutePattern={new RegExp(OLD_PRODUCT_URL_REGEX.source + '|' + NEW_PRODUCT_URL_REGEX.source)}
      />
    </div>
  );
};
Footer.displayName = 'organisms/Footer';

Footer.propTypes = {
  categories: PropTypes.object,
  newsletter: PropTypes.bool,
  path: PropTypes.string,
};

export default connect(mapStateToProps)(Footer);
