import React from 'react';
import PropTypes from 'prop-types';
import Headline, { tags, alignments } from 'components/atoms/Headline/Headline';
import language from 'constants/language/language';
import AddServices from 'components/molecules/AddServices/AddServices';
import Accessories from './Accessories';
import { updateServices, countTimesAddedAsAccessory, showServices } from './ServiceAndAccessories.helper';
import styles from './ServicesAndAccessories.scss';

function ServicesAndAccessoriesVariant({
  updateEntry,
  productCode,
  quantity,
  productServices,
  referenceItemCodes,
  accessories,
  serviceItemCodes,
  entryID,
}) {
  return (
    <article className={styles.serviceBox}>
      {showServices(quantity) && productServices?.length > 0 && (
        <div className={styles.marginBottom}>
          <Headline alignment={alignments.LEFT} tag={tags.H3}>
            {language('cart.addService')}
          </Headline>
          <AddServices
            services={productServices}
            updateServices={updateServices(updateEntry, serviceItemCodes, entryID, productCode, quantity)}
            serviceItemCodes={serviceItemCodes}
          />
        </div>
      )}
      <Accessories
        referenceItemCodes={referenceItemCodes}
        accessories={accessories}
        countTimesAddedAsAccessory={countTimesAddedAsAccessory}
      />
    </article>
  );
}

ServicesAndAccessoriesVariant.displayName = 'organisms/ServicesAndAccessoriesVariant';

ServicesAndAccessoriesVariant.propTypes = {
  updateEntry: PropTypes.func,
  productCode: PropTypes.string,
  quantity: PropTypes.number,
  productServices: PropTypes.array,
  referenceItemCodes: PropTypes.array,
  accessories: PropTypes.array,
  serviceItemCodes: PropTypes.array,
  entryID: PropTypes.number,
};

export default ServicesAndAccessoriesVariant;
