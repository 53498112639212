import React from 'react';
import PropTypes from 'prop-types';

import { types } from 'containers/FooterColumnCmsContainer/FooterColumnCmsContainer';
import DrilldownAccordion from 'components/atoms/DrilldownAccordion/DrilldownAccordion';
import MobileNavigationItem from 'components/atoms/MobileNavigationItem/MobileNavigationItem';

import { provideLinks } from 'constants/navigation/navigation';
import { getLocale } from 'constants/language/language';
import { NAVIGATION_SUB } from 'constants/trackingAttributes/trackingAttributes';

const CmsSection = ({ title, type, pages, pos, isResetToStart }) => {
  const links = provideLinks(pages, type, getLocale());

  return (
    <DrilldownAccordion title={title} pos={pos} isResetToStart={isResetToStart}>
      {links.map((link, i) => (
        <MobileNavigationItem
          key={link.text}
          href={link.url}
          tracking={{
            type: NAVIGATION_SUB,
            position: i + 1,
            title: link.text,
            parentTitle: title,
          }}
        >
          {link.text}
        </MobileNavigationItem>
      ))}
    </DrilldownAccordion>
  );
};

export default CmsSection;

CmsSection.propTypes = {
  title: PropTypes.string,
  type: PropTypes.oneOf(Object.values(types)),
  entries: PropTypes.array,
  pages: PropTypes.object,
  pos: PropTypes.number,
  children: PropTypes.node,
  isResetToStart: PropTypes.bool,
};
