import React from 'react';
import PropTypes from 'prop-types';

import Meta from 'containers/Meta/Meta';
import FacetsContainer from 'containers/FacetsContainer/FacetsContainer';
import ProductComparisonContainer from 'containers/ProductComparison/ProductComparison';
import WallMiniContainer from 'containers/WallMini/WallMini';

import Headline from 'components/atoms/Headline/Headline';
import ProductsOverviewHeadline from 'components/atoms/ProductsOverviewHeadline/ProductsOverviewHeadline';
import { Col, Row } from 'components/atoms/Grid/Grid';

import Pagination from 'components/molecules/Pagination/Pagination';
import ProductSubnavigation from 'components/molecules/ProductSubnavigation/ProductSubnavigation';
import CardWall from 'components/organisms/CardWall/CardWall';
import CategoryRecoBox from 'components/organisms/CategoryRecoBox/CategoryRecoBox';
import ProductListHeader from 'components/molecules/ProductListHeader/ProductListHeader';

import Breadcrumb, { types } from 'components/organisms/Breadcrumb/Breadcrumb';
import ProductList from 'components/organisms/ProductList/ProductList';
import CmsElementProvider from 'components/organisms/CmsElementProvider/CmsElementProvider';
import ProductComparisonHeader from 'components/molecules/ProductComparisonHeader/ProductComparisonHeader';

import ProductListContainer from 'containers/ProductListContainer/ProductListContainer';

import ProductsOverviewConfig from './ProductsOverview.config';

import language from 'constants/language/language';
import { TOP_OF_PRODUCTS_LIST, TOP_OF_PRODUCTS_OVERVIEW } from 'constants/scrollToElement/scrollToElement';
import { ProductViewTypes } from 'constants/ProductViewTypes/ProductViewTypes';
import { cypressAttributes } from 'constants/cypress/cypress';
import { noop } from 'constants/noop/noop';

import bootstrap from 'scss/component.scss';
import styles from './ProductsOverview.scss';
import NumberResults from 'components/atoms/NumberResults/NumberResults';
import FacetFilters from 'components/molecules/FacetFilters/FacetFilters';
import { getEnv } from 'config/config';

export const getMetaDescription = (pageTitle, brandQuery) => {
  let description = 'Der Preis entscheidet';
  if (brandQuery) {
    description = `${pageTitle} ${language('meta.brand')}`;
  } else if (pageTitle) {
    description = `${language('meta.category1')} ${pageTitle} ${language('meta.category2')}`;
  }
  return description;
};

export const ProductsOverviewCategory = ({
  currentPage,
  numberOfPages,
  visibleProducts,
  totalNumberOfResults,
  sorts,
  breadcrumb,
  cmsContent,
  categoryTree,
  categoryCode,
  brandQuery,
  comparisonHasError,
  productViewType,
  categories,
  onSortChange,
  hasCmsContent,
}) => {
  const hasProducts = !!visibleProducts.length;
  const breadcrumbLength = breadcrumb && breadcrumb.length;

  const cmsPageHeadline = cmsContent?.pageData?.headline;
  const cmsPageMetaDescription = cmsContent?.metaData?.metaDescription;
  const cmsPageMetaKeywords = cmsContent?.metaData?.metaKeywords;
  const cmsPageMetaPageTitle = cmsContent?.metaData?.pageTitle;

  const lastBreadcrumbName = breadcrumb[breadcrumb.length - 1]?.name;
  const pageTitle = cmsPageHeadline || ProductsOverviewConfig.getCategoryHeadline(lastBreadcrumbName, brandQuery);
  const metaDescription = cmsPageMetaDescription || getMetaDescription(pageTitle, brandQuery);
  const metaTitle = cmsPageMetaPageTitle || ProductsOverviewConfig.getCategoryTitle(lastBreadcrumbName, brandQuery);
  const metaTags = [{ name: 'description', content: metaDescription }];

  const trackingContext = `${lastBreadcrumbName}-${categoryCode}`;

  if (cmsPageMetaKeywords) {
    metaTags.push({ name: 'keywords', content: cmsPageMetaKeywords });
  }

  const setCanonicalUrl = () => {
    if (!__CLIENT__) return null;

    const url = new URL(window.location.href);
    let { pathname, search } = url;

    const searchParams = search?.split('&');
    const brandsParams = searchParams?.find((param) => param.includes('brand='))?.split('=')[1];
    const brandsArray = brandsParams?.split('+');

    const SHOW_BRAND_CANONICAL = search && brandsArray?.length === 1 && searchParams?.length >= 1;
    const SHOW_CATEGORY_CANONICAL = !search;

    if (SHOW_BRAND_CANONICAL && !SHOW_CATEGORY_CANONICAL) {
      pathname += `?brand=${brandsArray[0]}`;
    }

    return [{ rel: 'canonical', href: `${getEnv('web')}${pathname}` }];
  };

  return (
    <div id={TOP_OF_PRODUCTS_OVERVIEW} data-cy={cypressAttributes.product.productOverview}>
      <Meta title={metaTitle} meta={metaTags} canonical={setCanonicalUrl()} />
      {__CLIENT__ && (categoryCode?.length >= 6 || comparisonHasError) && (
        <ProductComparisonContainer categoryCode={categoryCode}>
          <ProductComparisonHeader selectedCategory={categoryCode} />
        </ProductComparisonContainer>
      )}

      {/* Check beneath is for data-cy attribute not being present while SSR, so it is needed. */}
      {!__CLIENT__ ? (
        <div data-cy={cypressAttributes.product.comparisonHeader}>
          <Breadcrumb type={types.category} items={breadcrumb} hasProducts={hasProducts} categories={categories} />
        </div>
      ) : (
        <div>
          <Breadcrumb type={types.category} items={breadcrumb} hasProducts={hasProducts} categories={categories} />
        </div>
      )}

      <Row>
        <Col className={styles.headline}>
          <ProductsOverviewHeadline
            totalNumberOfResults={totalNumberOfResults}
            category={pageTitle}
            categoryLevel={breadcrumbLength}
            marginBottom={Headline.margins.MB_20}
            headlineTag={Headline.tags.H1}
          />
        </Col>
      </Row>
      <Row className={styles.ProductsOverview}>
        <Col lg={3} className={bootstrap.hiddenMdDown}>
          <FacetsContainer>
            <ProductSubnavigation
              cyData={cypressAttributes.subcategories.desktop}
              initialCategories={categories}
              categoryTree={categoryTree}
              categoryCode={categoryCode}
            />
          </FacetsContainer>
        </Col>
        <Col lg={9} className={styles.ProductsListContainer}>
          <WallMiniContainer wallId={`ws${categoryCode}`}>
            <CardWall skin={CardWall.SKINS.MINI} />
          </WallMiniContainer>
          <CmsElementProvider
            contentType={'categories'}
            fullWidth={false}
            elements={cmsContent?.before?.cmsDatas || []}
          />
          <section id={TOP_OF_PRODUCTS_LIST}>
            {totalNumberOfResults > 0 && (
              <>
                <NumberResults
                  currentPage={currentPage}
                  totalNumberOfResults={totalNumberOfResults}
                  hasCmsContent={hasCmsContent}
                />

                <FacetsContainer>
                  <FacetFilters breadcrumb={breadcrumb} />
                </FacetsContainer>

                <ProductListHeader sorts={sorts} onSortChange={onSortChange} hasCmsContent={hasCmsContent} />

                <ProductListContainer>
                  <ProductList
                    products={visibleProducts}
                    viewType={productViewType}
                    trackingContext={trackingContext}
                  />
                </ProductListContainer>
              </>
            )}
            <div className={styles.pagination}>
              <Pagination
                currentPage={currentPage}
                totalPages={numberOfPages}
                scrollToId={{
                  id: TOP_OF_PRODUCTS_LIST,
                  duration: 0,
                }}
              />
            </div>
          </section>
          {totalNumberOfResults > 1 && <CategoryRecoBox categoryCode={categoryCode} />}
          <div className={styles.cmsContent}>
            <CmsElementProvider
              contentType={'categories'}
              fullWidth={false}
              elements={cmsContent?.after?.cmsDatas || []}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

ProductsOverviewCategory.displayName = 'templates/ProductsOverviewCategory';

ProductsOverviewCategory.propTypes = {
  // all required, passed in via container routes/ProductsOverview
  categoryCode: PropTypes.string.isRequired,
  visibleProducts: PropTypes.array,
  currentPage: PropTypes.number,
  totalNumberOfResults: PropTypes.number,
  numberOfPages: PropTypes.number,
  breadcrumb: PropTypes.array,
  numberOfFacets: PropTypes.number,
  numberOfSelectedFacets: PropTypes.number,
  sorts: PropTypes.array,
  cmsContent: PropTypes.object,
  brandQuery: PropTypes.string,
  comparisonHasError: PropTypes.bool,
  productViewType: PropTypes.oneOf(Object.values(ProductViewTypes)),
  categories: PropTypes.object,
  onSortChange: PropTypes.func,
  categoryTree: PropTypes.array,
  hasCmsContent: PropTypes.bool,
};

ProductsOverviewCategory.defaultProps = {
  visibleProducts: [],
  currentPage: 0,
  breadcrumb: [],
  isFilterMenuOpen: false,
  numberOfFacets: 0,
  numberOfSelectedFacets: 0,
  sorts: [],
  currentURL: '',
  cmsContent: {},
  brandQuery: '',
  numberOfPages: 1,
  comparisonHasError: false,
  categories: {},
  onSortChange: noop,
};

export default ProductsOverviewCategory;
