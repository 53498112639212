import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Headline, { tags, margins } from 'components/atoms/Headline/Headline';
import Icon, { ICON_CHECK } from 'components/atoms/Icon/Icon';
import styles from './Stepper.scss';

const StepperStep = ({
  name = '',
  children,
  label = '',
  stepNumber = 0,
  activeStep = 0,
  lastStep = 0,
  onStepClick = () => {},
  onPrevClick = () => {},
  onNextClick = () => {},
}) => {
  const isDone = activeStep > stepNumber;
  const isFirst = stepNumber === 0;
  const isActive = activeStep === stepNumber;
  const isLast = lastStep === stepNumber;

  const contentRef = useRef(null);
  const [height, setHeight] = useState(isFirst ? 'auto' : 0);

  useEffect(() => {
    const elm = contentRef.current;
    if (!elm) return;

    if (isActive) {
      elm.style.display = 'block';
      setHeight(elm.scrollHeight);
      elm.style.display = '';
    } else {
      setHeight(0);
    }
  }, [isActive]);

  const handleClick = () => {
    if (isDone) {
      onStepClick(stepNumber);
    }
  };

  const onTransitionEnd = () => {
    if (isActive) {
      // set height to auto when entry is fully opened to resize content with window size changes
      setHeight('auto');
    }
  };

  return (
    <div
      name={name}
      className={cx(styles.step, {
        [styles.isActive]: isActive,
        [styles.isLast]: isLast,
        [styles.isDone]: isDone,
      })}
    >
      <div className={styles.stepHeader} onClick={handleClick}>
        <div className={styles.stepNumber}>
          {isDone ? <Icon className={styles.doneIcon} path={ICON_CHECK} /> : stepNumber + 1}
        </div>
        <Headline tag={tags.SPAN} margin={margins.NONE}>
          {label}
        </Headline>
      </div>
      <div className={styles.stepperContent}>
        <div className={styles.contentWrapper} ref={contentRef} onTransitionEnd={onTransitionEnd} style={{ height }}>
          {isActive && children({ onPrevClick, onNextClick, activeStep })}
        </div>
      </div>
    </div>
  );
};

StepperStep.displayName = 'molecules/Stepper/StepperStep';

StepperStep.propTypes = {
  name: PropTypes.string,
  children: PropTypes.any,
  label: PropTypes.string,
  stepNumber: PropTypes.number,
  activeStep: PropTypes.number,
  lastStep: PropTypes.number,
  onStepClick: () => {},
  onPrevClick: () => {},
  onNextClick: () => {},
};

export default StepperStep;
