import React from 'react';
import PropTypes from 'prop-types';
import language from 'constants/language/language';

import FlashMessage from 'components/atoms/FlashMessage/FlashMessage';
import { types as flashMessageTypes } from 'constants/flashMessages/flashMessages';
import { cartStatusTypes } from 'constants/cartStatusTypes/cartStatusTypes';

const ShoppingCartInfoBox = ({ cartStatusCode }) => (
  <>
    {cartStatusCode === cartStatusTypes.QUANTITY_REDUCED && (
      <FlashMessage type={flashMessageTypes.WARNING} content={language('shoppingCart.quantityReduced')} />
    )}
    {cartStatusCode === cartStatusTypes.NEW_DELIVERY_DATE && (
      <FlashMessage type={flashMessageTypes.WARNING} content={language('shoppingCart.changeDeliveryDate')} />
    )}
    {cartStatusCode === cartStatusTypes.QUANTITY_AND_DELIVERY_DATE_CHANGED && (
      <FlashMessage type={flashMessageTypes.WARNING} content={language('shoppingCart.changeQuantityAndDeliveryDate')} />
    )}
  </>
);

ShoppingCartInfoBox.displayName = 'atoms/ShoppingCartInfoBox';

ShoppingCartInfoBox.propTypes = {
  cartStatusCode: PropTypes.string.isRequired,
};

export default ShoppingCartInfoBox;
