import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import InputRadio from 'components/atoms/InputRadio/InputRadio';
import { cypressAttributes } from 'constants/cypress/cypress';
import styles from './InputRadioList.scss';

const InputRadioList = ({
  name = '',
  options = {},
  handleChange = () => {},
  inline = false,
  showError = false,
  value,
  disabled = false,
  cyData,
  shouldTrack = false,
  location,
  boldLabels = false,
}) => {
  const onClick = (value) => () => handleChange(value);

  return (
    <ul className={cx(styles.list, { [styles.inline]: inline })} data-cy={cyData}>
      {Object.entries(options).map(([key, children]) => (
        <li key={key} className={styles.item}>
          <InputRadio
            name={name}
            disabled={disabled}
            boldLabels={boldLabels}
            onClick={onClick(key)}
            selected={key === value}
            showError={showError}
            cyData={name === 'paymentMode' ? cypressAttributes.paymentModes[key.toLowerCase()]?.radio : undefined}
            trackingAttributes={
              shouldTrack && {
                TYPE: 'form:field',
                FUNCTION: 'select',
                ATTRIBUTE_1: 'radio-button',
                ATTRIBUTE_2: name,
                ATTRIBUTE_3: key,
                INNERLOCATION: location,
              }
            }
          >
            {children}
          </InputRadio>
        </li>
      ))}
    </ul>
  );
};

InputRadioList.displayName = 'molecules/InputRadioList';

InputRadioList.propTypes = {
  name: PropTypes.string,
  options: PropTypes.object,
  boldLabels: PropTypes.bool,
  handleChange: PropTypes.func,
  inline: PropTypes.bool,
  showError: PropTypes.bool,
  value: PropTypes.string,
  cyData: PropTypes.string,
  shouldTrack: PropTypes.bool,
  location: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default InputRadioList;
