import React, { useState, useEffect, useRef } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import language, { getLocale } from 'constants/language/language';
import { debounce, cancel } from 'constants/lodash/debounce';
import { STOREFINDER, WALL, CART } from 'constants/routePaths/routePaths';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import CartIndicatorContainer from 'containers/Indications/Cart';
import PopOverCartContainer from 'containers/PopOverCartContainer/PopOverCartContainer';
import PopOverCart from 'components/molecules/PopOverCart/PopOverCart';
import Icon, {
  ICON_BASKET,
  ICON_LIST,
  ICON_LOCAL_OFFER_OUTLINED,
  ICON_LOCATION_ON_OUTLINED,
} from 'components/atoms/Icon/Icon';
import { Container, Row } from 'components/atoms/Grid/Grid';
import CartIndicator from 'components/atoms/CartIndicator/CartIndicator';
import InternalOrExternalLink from 'components/atoms/InternalOrExternalLink/InternalOrExternalLink';
import useTelesalesMode from 'hooks/useTelesalesMode';
import useFeatureToggle from 'hooks/useFeatureToggle';

import bootstrap from 'scss/component.scss';
import styles from './HeadroomNavigation.scss';
import { NextFeatureLink } from 'components/atoms/NextFeatureLink/NextFeatureLink';

const debounceId = 'headroom';

const HeadroomNavigation = ({ isSticky = false }) => {
  let scrollerTimeOut;
  let popOverTimeOut;

  const nextStorefinderEnabled = useFeatureToggle('nextStorefinderEnabled') === 'true' ?? false;
  const [routePath, setRoutePath] = useState();
  const [showCart, setShowCart] = useState(false);
  const isTelesalesMode = useTelesalesMode();

  const scrollerRef = useRef(null);

  useEffect(() => {
    if (__CLIENT__) {
      setRoutePath(getActualPath());
    }
    return () => {
      cancel(debounceId);
      clearTimeout(scrollerTimeOut);
      clearTimeout(popOverTimeOut);
    };
  });

  // gets the actual path after localization
  const getActualPath = () => window.location.pathname.split('/')[2];

  const handleMouseEvent = (e, button = '') => {
    cancel(debounceId);
    clearTimeout(scrollerTimeOut);
    clearTimeout(popOverTimeOut);

    popOverTimeOut = setTimeout(() => {
      setShowCart((button === 'cart' || button === 'cartOverlay') && routePath !== 'cart');
    }, 200);

    const target = e.target.closest('a');
    const width = target?.getBoundingClientRect().width;
    const x = target?.offsetLeft;

    if (button !== 'cartOverlay' && scrollerRef) {
      scrollerRef.current.style.width = `${width}px`;
      scrollerRef.current.style.left = `${x - 10}px`;
      scrollerRef.current.style.transform = 'translateX(0)';
      scrollerRef.current.style.scale = 1;
      scrollerRef.current.style.opacity = '1';
    }
  };

  const handleMouseLeave = () => {
    if (__CLIENT__) {
      debounce(debounceId, debounceSetHovered, 500);
    }
  };

  const debounceSetHovered = () => {
    setShowCart(false);
    const widthBefore = scrollerRef.current.getBoundingClientRect().width;
    scrollerRef.current.style.width = '0px';
    scrollerRef.current.style.transform = `translateX(${widthBefore / 2}px)`;
    scrollerTimeOut = setTimeout(() => {
      scrollerRef.current.style.left = '';
    }, 200);
  };

  return (
    <Container>
      <Row>
        <div
          className={cx(bootstrap.colMd12, bootstrap.hiddenSmDown, styles.headroomContainer, {
            [styles.isSticky]: isSticky,
          })}
        >
          <div className={styles.headroomWrapper} onMouseLeave={handleMouseLeave}>
            <div className={styles.leftFlex}>
              <Link
                to={mapPathToLocalizedUrl(getLocale())}
                id="navigation"
                className={cx(
                  styles.navigationItem,
                  { [styles.isTelesales]: isTelesalesMode },
                  { [styles.active]: routePath === undefined }
                )}
                onMouseEnter={(e) => handleMouseEvent(e, 'navigation')}
              >
                <div className={styles.iconWrapper}>
                  <Icon path={ICON_LIST} />
                </div>
                {language('header.allProducts')}
              </Link>
              <NextFeatureLink
                feature="nextPromotionsEnabled"
                to={mapPathToLocalizedUrl(getLocale(), [WALL])}
                className={cx(
                  styles.navigationItem,
                  { [styles.active]: routePath === WALL },
                  { [styles.isTelesales]: isTelesalesMode }
                )}
                onMouseEnter={(e) => handleMouseEvent(e)}
              >
                <div className={styles.iconWrapper}>
                  <Icon path={ICON_LOCAL_OFFER_OUTLINED} />
                </div>
                {language('navigation.promotion')}
              </NextFeatureLink>
              <InternalOrExternalLink
                external={nextStorefinderEnabled}
                href={mapPathToLocalizedUrl(getLocale(), [STOREFINDER])}
                className={cx(
                  styles.navigationItem,
                  { [styles.active]: routePath === STOREFINDER },
                  { [styles.isTelesales]: isTelesalesMode }
                )}
                onMouseEnter={(e) => handleMouseEvent(e)}
              >
                <div className={styles.iconWrapper}>
                  <Icon path={ICON_LOCATION_ON_OUTLINED} />
                </div>
                {language('navigation.stores')}
              </InternalOrExternalLink>
            </div>
            <div>
              <NextFeatureLink
                to={mapPathToLocalizedUrl(getLocale(), [CART])}
                className={cx(
                  styles.navigationItem,
                  { [styles.isTelesales]: isTelesalesMode },
                  { [styles.active]: routePath === CART },
                  styles.cart
                )}
                onMouseEnter={(e) => handleMouseEvent(e, 'cart')}
                onMouseLeave={handleMouseLeave}
                feature="nextCartEnabled"
              >
                <div className={styles.iconWrapper}>
                  <Icon path={ICON_BASKET} />
                </div>
                <CartIndicatorContainer isCartPopOver>
                  <CartIndicator />
                </CartIndicatorContainer>
                {language('navigation.cart')}
              </NextFeatureLink>
            </div>
          </div>
          <div className={styles.scrollerContainer}>
            <div ref={scrollerRef} className={cx(styles.scroller, { [styles.isTelesales]: isTelesalesMode })} />
          </div>
          <div className={cx(styles.spacerBottom, { [styles.isSticky]: isSticky })} />
          {showCart && (
            <div
              className={cx(styles.cartPopOver, { [styles.isSticky]: isSticky })}
              onMouseEnter={(e) => handleMouseEvent(e, 'cartOverlay')}
              onMouseLeave={handleMouseLeave}
            >
              <PopOverCartContainer>
                <PopOverCart />
              </PopOverCartContainer>
            </div>
          )}
        </div>
      </Row>
    </Container>
  );
};

HeadroomNavigation.displayName = 'molecules/HeadroomNavigation';
HeadroomNavigation.propTypes = {
  isSticky: PropTypes.bool,
};

export default HeadroomNavigation;
