import { useState } from 'react';

const useDrilldown = (entries) => {
  const [menuBreadcrumb, setMenuBreadcrumb] = useState([entries]);
  const [currentMenu, setCurrentMenu] = useState(entries);
  const [isOpen, setIsOpen] = useState(false);

  const closeDrilldown = () => {
    setIsOpen(false);
    setMenuBreadcrumb([entries]);
    setCurrentMenu(entries);
  };

  const openDrilldown = () => {
    setIsOpen(true);
  };

  const drillToMenu = (menu) => {
    const currentBreadcrumb = menuBreadcrumb;
    const existingParent = menuBreadcrumb.findIndex((parentMenu) => parentMenu.id === menu.id);

    if (existingParent >= 0) {
      currentBreadcrumb.splice(existingParent + 1);
    } else {
      currentBreadcrumb.push(menu);
    }

    setMenuBreadcrumb(currentBreadcrumb);
    setCurrentMenu(menu);
  };

  return [isOpen, closeDrilldown, openDrilldown, currentMenu, menuBreadcrumb, drillToMenu];
};

export default useDrilldown;
