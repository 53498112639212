import { Component, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

export const mapStateToProps = (state) => ({
  isClient: state.ui.isClient,
});

export class LoadingState extends Component {
  static propTypes = {
    isClient: PropTypes.bool,
    children: PropTypes.element,
  };

  render() {
    return cloneElement(this.props.children, {
      isClient: this.props.isClient,
      renderIcons: this.props.isClient,
    });
  }
}

export default connect(mapStateToProps)(LoadingState);
