export const BREAKPOINTS = {
  XL: 'XL',
  LG: 'LG',
  MD: 'MD',
  SM: 'SM',
  XS: 'XS',
  XXS: 'XXS',
};

export const BREAKPOINT_MIN_WIDTH = {
  [BREAKPOINTS.XL]: 1200,
  [BREAKPOINTS.LG]: 992,
  [BREAKPOINTS.MD]: 768,
  [BREAKPOINTS.SM]: 544,
  [BREAKPOINTS.XS]: 360,
  [BREAKPOINTS.XXS]: 0,
};

/*
  Please don't use this function directly since it's always called on the client,
  and the result is written in store.ui.breakpoint
*/
export const getBreakpoint = () => {
  for (const key in BREAKPOINT_MIN_WIDTH) {
    if (Object.prototype.hasOwnProperty.call(BREAKPOINT_MIN_WIDTH, key)) {
      const width = BREAKPOINT_MIN_WIDTH[key];
      if (window.matchMedia(`(min-width:${width}px)`).matches) {
        return key;
      }
    }
  }
  return BREAKPOINTS.XXS;
};

export const smallerThan = (b1, b2) => BREAKPOINT_MIN_WIDTH[b1] < BREAKPOINT_MIN_WIDTH[b2];
