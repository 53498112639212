import React from 'react';
import PropTypes from 'prop-types';

import language from 'constants/language/language';
import { noop } from 'constants/noop/noop';
import styles from './AlternateExpander.scss';

export const AlternateExpander = ({ handleClick = noop, expanded = false }) => (
  <div className={styles.expanderWrapper} onClick={handleClick}>
    {expanded ? language('common.showLess') : `${language('common.more')} +`}
  </div>
);

AlternateExpander.propTypes = {
  handleClick: PropTypes.func,
  expanded: PropTypes.bool,
};

AlternateExpander.displayName = 'atoms/AlternateExpander';

export default AlternateExpander;
