import React from 'react';
import PropTypes from 'prop-types';
import cmsContentTypes from 'constants/cmsContentTypes/cmsContentTypes';
import styles from './CmsElementProvider.scss';
import CmsElement from 'components/molecules/CmsElement/CmsElement';
import withSmartEdit from 'containers/SmartEditContainer/withSmartEdit';
import isSmartEditMode from 'constants/smartedit/isSmartEditMode';

const CmsElementProvider = ({ contentId = '', contentType = '', elements = [], fullWidth = false }) => {
  const contentElements = cmsContentTypes[contentType];

  if (!contentType || !contentElements) return null;

  return (
    <div className={styles.container}>
      {elements.map((element, index) => (
        <CmsElement
          key={`${element.type}-${index}`}
          contentElements={contentElements}
          contentId={contentId}
          element={element}
          fullWidth={fullWidth}
          {...(isSmartEditMode(element) && { render: withSmartEdit(element.smartedit) })}
        />
      ))}
    </div>
  );
};

CmsElementProvider.displayName = 'organisms/CmsElementProvider';

CmsElementProvider.propTypes = {
  contentId: PropTypes.string,
  contentType: PropTypes.string,
  elements: PropTypes.array,
  fullWidth: PropTypes.bool,
};

export default CmsElementProvider;
