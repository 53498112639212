import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import bootstrap from 'scss/component.scss';

const Row = ({ children, className, id, onClick, cyData }) => (
  <div
    id={id}
    data-cy={cyData}
    className={cx(bootstrap.row, {
      [className]: !!className,
    })}
    onClick={onClick}
  >
    {children}
  </div>
);

Row.displayName = 'atoms/Grid/Row';

Row.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
  cyData: PropTypes.string,
};

export default Row;
