import React from 'react';
import cx from 'classnames';
import language, { getLocale } from 'constants/language/language';
import Icon, {
  ICON_FACEBOOK,
  ICON_INSTAGRAM,
  ICON_LINKEDIN,
  ICON_XING,
  ICON_YOUTUBE,
} from 'components/atoms/Icon/Icon';
import bootstrap from 'scss/component.scss';
import styles from './LinksSocialMedia.scss';
import PropTypes from 'prop-types';

const LinksSocialMedia = ({ config = 'social' }) => {
  const configs = {
    social: [
      {
        name: 'Facebook',
        url: 'https://www.facebook.com/interdiscount/',
        path: ICON_FACEBOOK,
      },
      {
        name: 'Youtube',
        url: 'https://www.youtube.com/channel/UCpgCqN4nGN7XX8qluGviMWA',
        path: ICON_YOUTUBE,
      },
      {
        name: 'Instagram',
        url: 'https://www.instagram.com/interdiscount.ch/',
        path: ICON_INSTAGRAM,
      },
    ],

    jobs: [
      {
        name: language('footer.jobOffers'),
        url: `/${getLocale()}/cms/jobs`,
      },
      {
        name: 'LinkedIn',
        url: 'https://www.linkedin.com/company/interdiscount-microspot',
        path: ICON_LINKEDIN,
      },
      {
        name: 'Xing',
        url: 'https://www.xing.com/pages/interdiscount-microspot-ch',
        path: ICON_XING,
      },
    ],
  };

  return (
    <div className={cx(styles.linksSocialMediaContainer, bootstrap.hiddenPrint)}>
      <ul className={styles.linksContainer}>
        {configs[config].map((link) => (
          <li key={link.name}>
            {link.path && <Icon path={link.path} className={styles.icon} alt={link.name} />}
            <a key={link.name} className={styles.iconLink} href={link.url} target="_blank" rel="noreferrer">
              {link.name}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

LinksSocialMedia.propTypes = {
  config: PropTypes.oneOf(['social', 'jobs']),
};
LinksSocialMedia.displayName = 'molecules/LinksSocialMedia';

export default LinksSocialMedia;
