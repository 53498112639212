import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import language from 'constants/language/language';
import formatTime from 'constants/formatTime/formatTime';
import styles from './TeaserPromotion.scss';

export const skins = {
  DETAIL: 'DETAIL',
  TEASER: 'TEASER',
  WALL: 'WALL',
  ACCESSORY: 'ACCESSORY',
};

export default class TeaserPromotion extends Component {
  static displayName = 'molecules/TeaserPromotion';
  static propTypes = {
    endTime: PropTypes.number,
    stockCurrent: PropTypes.number,
    stockMax: PropTypes.number,
    skin: PropTypes.oneOf(Object.keys(skins)),
    hasEnded: PropTypes.bool,
  };

  static defaultProps = {
    endTime: 0,
    stockCurrent: 0,
    stockMax: 0,
    skin: skins.TEASER,
  };

  constructor(props) {
    super(props);
    this.clockInterval = null;
    this.state = {
      actualDate: '',
    };
  }

  componentDidMount() {
    this.clockInterval = setInterval(() => {
      this.setState({
        actualDate: formatTime(this.props.endTime - Date.now()),
      });
    }, 1000);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props && this.clockInterval === null) {
      this.clockInterval = setInterval(() => {
        this.setState({
          actualDate: formatTime(this.props.endTime - Date.now()),
        });
      }, 1000);
    }
  }

  componentWillUnmount() {
    this.resetInterval();
  }

  promoExceeded = () => this.props.endTime < Date.now();

  isStockPromo = () => this.props.stockMax > 0;

  stockHurry = () => this.isStockPromo() && this.props.stockCurrent < 10;
  timeHurry = () => !this.isStockPromo() && this.props.endTime - Date.now() < 3600000;

  shouldPulse = () => (this.stockHurry() || this.timeHurry()) && !this.props.hasEnded;

  resetInterval = () => {
    clearInterval(this.clockInterval);
    this.clockInterval = null;
  };

  render() {
    const { stockCurrent, stockMax, skin, hasEnded } = this.props;

    if (this.promoExceeded()) {
      this.resetInterval();
      return null;
    }

    if (hasEnded) {
      this.resetInterval();
    }

    return (
      <div
        className={cx(
          styles.promoWrapper,
          { [styles.hurry]: this.shouldPulse() },
          { [styles.ended]: hasEnded },
          styles[skin]
        )}
      >
        {hasEnded ? (
          language('productPromotion.ended')
        ) : (
          <div className={cx({ [styles.pulse]: this.shouldPulse() })}>
            {this.isStockPromo() ? (
              <div className={styles.stockPromo}>
                {stockCurrent <= 0 ? 0 : stockCurrent}
                {language('productPromotion.stock.of')}
                {stockMax}
                {language('productPromotion.stock.pieces')}
              </div>
            ) : (
              <div>
                {this.state.actualDate !== '' && <div className={styles.timePromo}>{this.state.actualDate}</div>}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}
