import React, { useMemo, useState } from 'react';
import styles from './FacetFilters.scss';
import Icon, { ICON_MORE } from 'components/atoms/Icon/Icon';
import language from 'constants/language/language';
import { Modal } from '@interdiscount/component-library-interdiscount';
import cx from 'classnames';
import PropTypes from 'prop-types';
import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';
import FacetFilterAccordion from 'components/atoms/FacetFilterType/FacetFilterAccordion';
import { attachToArgument, facetHasFilters } from 'constants/facetsHelper/facetsHelper';

const AllFacetFilters = ({
  facets,
  prefetchedFacets,
  totalNumberOfResults,
  handleSliderChange,
  submitFilters,
  prefetchFilter,
  resetAllFacets,
  hasFiltersApplied,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const hasFilters = useMemo(() => facets.filter((facet) => facetHasFilters(facet)).length > 0, [facets]);

  const handleClick = () => {
    submitFilters();
    setModalIsOpen(false);
  };
  const renderButton = () => {
    return (hasFiltersApplied || hasFilters) && totalNumberOfResults > 0 ? (
      <ButtonLink color={ButtonLink.colors.red} stretched onClick={handleClick}>
        {totalNumberOfResults} {language('filter.showProducts')}
      </ButtonLink>
    ) : (
      <ButtonLink variety={ButtonLink.varieties.stroked} stretched onClick={() => setModalIsOpen(false)}>
        {language('filter.close')}
      </ButtonLink>
    );
  };

  return (
    <>
      <li key="AllFacetFitlers" className={styles.facetFiltersItem}>
        <button
          className={cx(styles.facetFiltersButton, styles.allFacetFiltersItem)}
          onClick={() => setModalIsOpen(true)}
        >
          <div className={styles.facetFiltersTitle}>
            <span className={styles.facetFiltersSelected}>{language('filter.more')}</span>
          </div>
          <Icon path={ICON_MORE} className={styles.chevron} />
        </button>
      </li>

      <Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)}>
        <div className={styles.accordionFiltersTitle}>
          <p>{language('filter.filterTitle')}</p>
          {(hasFiltersApplied || hasFilters) && (
            <ButtonLink onClick={() => resetAllFacets() || setModalIsOpen(false)} variety={ButtonLink.varieties.basic}>
              <span className={styles.ResetButton}>{language('product.facets.reset')}</span>
            </ButtonLink>
          )}
        </div>
        <ul className={cx(styles.facetFiltersList, styles.facetFiltersAccordion)}>
          {facets?.map((facet, index) => (
            <FacetFilterAccordion
              key={facet.code}
              facet={facet}
              totalNumberOfResults={totalNumberOfResults}
              prefetchedFacet={prefetchedFacets?.find((pf) => pf.code === facet.code)}
              handleSliderChange={attachToArgument(handleSliderChange, { location: index })}
              prefetchFilter={attachToArgument(prefetchFilter, { location: index })}
              index={index}
            />
          ))}
        </ul>
        <div className={styles.facetFiltersButtonWrapper}>{renderButton()}</div>
      </Modal>
    </>
  );
};

AllFacetFilters.propTypes = {
  facets: PropTypes.array,
  prefetchedFacets: PropTypes.array,
  totalNumberOfResults: PropTypes.number,
  handleSliderChange: PropTypes.func,
  submitFilters: PropTypes.func,
  prefetchFilter: PropTypes.func,
  hasFiltersApplied: PropTypes.bool,
  resetAllFacets: PropTypes.func,
};
export default AllFacetFilters;
