import React from 'react';
import PropTypes from 'prop-types';
import language from 'constants/language/language';

import { Col, Row } from 'components/atoms/Grid/Grid';
import Pagetitle from 'components/atoms/Pagetitle/Pagetitle';
import PasswordResetForm from 'components/molecules/PasswordResetForm/PasswordResetForm';
import Breadcrumb, { types } from 'components/organisms/Breadcrumb/Breadcrumb';

import PasswordResetContainer from 'containers/PasswordReset/PasswordReset';

const PasswordReset = ({ token }) => (
  <>
    <Breadcrumb type={types.invisible} />
    <Pagetitle centered>
      <span>{language('passwordReset.header')}</span>
    </Pagetitle>
    <Row>
      <Col md={8} offsetMd={2} lg={10} offsetLg={1} xl={8} offsetXl={2}>
        <p>{language('passwordReset.messageSet')}</p>
        <PasswordResetContainer>
          <PasswordResetForm token={token} />
        </PasswordResetContainer>
      </Col>
    </Row>
  </>
);

PasswordReset.propTypes = {
  token: PropTypes.string.isRequired,
};

export default PasswordReset;
