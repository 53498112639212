import { useEffect, useState, useRef } from 'react';

import getCookieStorage from 'constants/storage/cookie';

const useGoogleOptimize = (experimentId, onInit = true) => {
  const cookieName = '_gaexp';
  const optimizeCallbackLabel = 'optimize.callback';
  const optimizeActivateLabel = 'optimize.activate';
  const [optimizeVariant, setOptimizeVariant] = useState(0);
  const [hasCookie, setHasCookie] = useState(true);
  const gotVariantFromCallback = useRef(false);

  const updateVariant = (value, callbackExperimentId) => {
    // FIX: To make two A / B tests work on the same component.
    // Error appeared when second A / B test was initialized / activated.
    // For any reason the callback for the first A / B test was then triggered a second time with value undefined.
    // Because of that we just use the first callback from Google Optimize and ignoring all others.
    // If you have any idea what this issue could be, feel free to refactor it.
    if (!gotVariantFromCallback.current) {
      if (experimentId === callbackExperimentId) setOptimizeVariant(value ? Number(value) : 0);
      gotVariantFromCallback.current = true;
    }
  };

  const pushEventsToDataLayer = () => {
    if (experimentId) window.dataLayer?.push({ event: optimizeActivateLabel, experimentName: experimentId });

    window.gtag?.('event', optimizeCallbackLabel, {
      name: experimentId,
      callback: updateVariant,
    });
  };

  const triggerActivation = () => {
    pushEventsToDataLayer();
    handleOptimizeVariant();
  };

  useEffect(() => {
    if (!onInit) {
      return () => {};
    }

    pushEventsToDataLayer();

    // This timeout is used in case that there is no response from Google Optimize.
    // With this function we make sure to have a response for the experiment.
    const handle = setTimeout(() => {
      handleOptimizeVariant();
    }, 2500);

    return () => {
      window.gtag?.('event', optimizeCallbackLabel, {
        name: experimentId,
        callback: updateVariant,
        remove: true,
      });
      clearTimeout(handle);
    };
  }, [experimentId]);

  const handleOptimizeVariant = () => {
    const cookie = getCookieStorage().getItem(cookieName);

    if (!cookie || !cookie.includes(experimentId)) {
      setHasCookie(false);
      updateVariant(0, experimentId);
      return () => {};
    } else {
      setHasCookie(true);
    }

    const slicedCookie = cookie.slice(7);
    const activeExperiment = slicedCookie.split('!').find((experiment) => experiment.search(experimentId) >= 0);
    const activeExperimentVariant = parseInt(activeExperiment?.charAt(activeExperiment.length - 1), 10);

    return updateVariant(parseInt(activeExperimentVariant, 10), experimentId);
  };

  return { variant: optimizeVariant, triggerActivation, hasCookie };
};

export default useGoogleOptimize;
