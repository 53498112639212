import {
  ValidationTypes,
  emailRequired as emailValidationRegex,
  birthday,
  required,
  validateRequiredTextInput,
} from 'constants/validation/validation';

export const validateSalutation = (salutation) => required(salutation);

export const validateFirstName = (firstName) => validateRequiredTextInput(firstName);

export const validateLastName = (lastName) => validateRequiredTextInput(lastName);

export const validateEmail = (email) =>
  emailValidationRegex(email) === ValidationTypes.Valid ? ValidationTypes.Valid : ValidationTypes.Invalid;

export const validateBirthday = (date) => birthday(date);
