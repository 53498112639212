import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { cypressAttributes } from 'constants/cypress/cypress';
import isTouchDevice from 'constants/isTouchDevice/isTouchDevice';
import Row from 'components/atoms/Grid/Row';

import styles from './SearchOverlayBox.scss';

const SearchOverlayBox = ({ children, hasItems = false }) => {
  return (
    <Row
      className={cx(styles.container, { [styles.noBorder]: !hasItems }, { [styles.touchDevice]: isTouchDevice() })}
      cyData={cypressAttributes.search.searchOverlay}
    >
      {children}
    </Row>
  );
};

SearchOverlayBox.displayName = 'atoms/SearchOverlayBox';

SearchOverlayBox.propTypes = {
  children: PropTypes.element.isRequired,
  hasItems: PropTypes.bool.isRequired,
};

export default SearchOverlayBox;
