import {
  TRACK_ACCESSORIES,
  TRACK_CART_LOGIN,
  TRACK_CHECKOUT,
  TRACK_CMS_CLICK,
  TRACK_COMPARISON,
  TRACK_FACET_CHANGE,
  TRACK_NAVIGATION_ELEMENTS,
  TRACK_PLACE_ORDER,
  TRACK_PRODUCT_CLICK,
  TRACK_PRODUCT_OVERVIEW,
  TRACK_RECOMMENDED_PRODUCTS,
  TRACK_WATCHLIST,
} from 'constants/ActionTypes/ActionTypes';

export const productClick = (product, context) => ({
  type: TRACK_PRODUCT_CLICK,
  payload: {
    product,
    context,
  },
});

/**
 * Generic tracking approach for every clickable component
 * @param context
 * @returns {{payload: {context: *}, type: *}}
 */
export const trackNavigationElements = (context) => ({
  type: TRACK_NAVIGATION_ELEMENTS,
  payload: {
    context,
  },
});

export const trackCmsClick = (context) => ({
  type: TRACK_CMS_CLICK,
  payload: {
    context,
  },
});

export const trackPlaceOrder = (lastOrderId) => ({
  type: TRACK_PLACE_ORDER,
  payload: {
    lastOrderId,
  },
});

export const trackCartLogin = () => ({
  type: TRACK_CART_LOGIN,
});

export const trackFacetChange = (facets) => ({
  type: TRACK_FACET_CHANGE,
  payload: {
    facets,
  },
});

export const trackCheckout = () => ({
  type: TRACK_CHECKOUT,
});

export const trackProductOverview = () => ({
  type: TRACK_PRODUCT_OVERVIEW,
});

export const trackAccessories = (productCode) => ({
  type: TRACK_ACCESSORIES,
  payload: {
    productCode,
  },
});

export const trackWatchlist = () => ({
  type: TRACK_WATCHLIST,
});

export const trackComparison = () => ({
  type: TRACK_COMPARISON,
});

export const trackRecommendedProducts = ({
  type = '',
  boxLevel = 'box1',
  isSearch = false,
  hasRecommendedProducts = false,
}) => ({
  type: TRACK_RECOMMENDED_PRODUCTS,
  payload: {
    type,
    boxLevel,
    isSearch,
    hasRecommendedProducts,
  },
});

const actions = {
  productClick,
  trackCmsClick,
  trackPlaceOrder,
  trackFacetChange,
  trackCheckout,
  trackProductOverview,
  trackCartLogin,
  trackNavigationElements,
  trackAccessories,
  trackWatchlist,
  trackComparison,
  trackRecommendedProducts,
};

export default actions;
