import React from 'react';
import PropTypes from 'prop-types';
import language from 'constants/language/language';
import { getDiscount, getFormattedPriceValue } from 'constants/price/price';
import styles from './DiscountSummary.scss';

const DiscountSummary = ({ price }) => (
  <>
    {!!Object.keys(price.discount).length && !!Object.keys(price.basePrice).length && (
      <div className={styles.summaryEntry}>
        <span className={styles.plus}>+&nbsp;</span>
        <span>
          {language('discount.comparison')}
          {getFormattedPriceValue(price.basePrice.value)}
          {language('discount.insteadOf')}
          {getFormattedPriceValue(price.insteadPrice.value)}.
        </span>
      </div>
    )}
    <div className={styles.summaryEntry}>
      <span className={styles.plus}>+&nbsp;</span>
      <span>
        {getDiscount(price.discount)}
        {language('discount.discount')}
      </span>
    </div>
  </>
);

DiscountSummary.displayName = 'atoms/DiscountSummary';
DiscountSummary.propTypes = {
  price: PropTypes.object,
};
DiscountSummary.defaultProps = {
  price: {},
};

export default DiscountSummary;
