// @flow
/*
  Explainations what the status codes mean can be found on this page:
  http://confluence:8090/pages/viewpage.action?pageId=4948657
*/
export const stockLevelStatus = {
  a: 'A',
  b: 'B',
  c: 'C',
  d: 'D',
  e: 'E',
  f: 'F',
  g: 'G',
  h: 'H',
  i: 'I',
  m: 'M',
  z: 'Z',
  r: 'R',
  y: 'Y',
};

export type StockLevelStatus = $Keys<typeof stockLevelStatus>;

export const pegiValues = {
  noPegi: 'noPegi',
  pegi18: '18',
  pegi16: '16',
};
