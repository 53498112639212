import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import utils, { positions, sizes } from 'constants/breadcrumbUtils/breadcrumbUtils';
import language, { getLocale } from 'constants/language/language';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import SeoStructuredData, { seoStructuredDataTypes } from 'components/organisms/SeoStructuredData/SeoStructuredData';
import BreadcrumbItem from 'components/molecules/BreadcrumbItem/BreadcrumbItem';
import styles from './Breadcrumb.scss';
import { connect } from 'react-redux';
import {useBreakpointChange, useFeatureToggle} from 'hooks';
import { BREAKPOINTS } from 'constants/breakpoints/breakpoints';

export const types = {
  detail: 'detail',
  account: 'account',
  category: 'category',
  search: 'search',
  invisible: 'invisible',
  error: 'error',
};

const mapStateToProps = (state) => {
  const location = state?.routing?.locationBeforeTransitions;

  return {
    location,
  };
};

const Breadcrumb = ({ categories = {}, hasProducts = false, items = [], type, location }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const currBreakpoint = useBreakpointChange();
  const nextHomepageEnabled = useFeatureToggle('nextHomepageEnabled') === "true" ?? false;

  /**
   * Defines the breadcrumb length to determine the placeholder behaviour on
   * all desktop views. The placeholder is hidden on mobile.
   */
  const lengthToPlaceholderClassMapping = [
    { minLength: 105, size: sizes.XL },
    { minLength: 85, size: sizes.LG },
    { minLength: 60, size: sizes.MD },
    { minLength: 0, size: sizes.SM },
  ];

  /**
   * Returns style class which controls the behaviour of the placeholder
   * depending on the length of all breadcrumb items together.
   */
  const getBreadcrumbSize = (hasPlaceholder, isCategoryType) => {
    if (!isExpanded && hasPlaceholder) {
      const numberOfChars = items.map((item) => (item.name ? item.name.length : 0)).reduce((a, b) => a + b, 0);
      // add +10 to size if category because homepage item is fix
      const expansion = isCategoryType ? 10 : 0;
      const config = lengthToPlaceholderClassMapping.find((n) => n.minLength <= numberOfChars + expansion);
      return config.size;
    }
    return sizes.SM;
  };

  /**
   * Returns the first breadcrumb item which is displayed as an icon.
   * The link destination depends on the type of breadcrumb you are using.
   */
  const getHomeItem = () => ({
    name: language('breadcrumb.home'),
    href: mapPathToLocalizedUrl(),
  });

  /**
   * Returns the placeholder with onClick() handler. The placeholder won't be displayed
   * on the account page and if there aren't more than 3 breadcrumb elements.
   */
  const getPlaceholderItem = (placeholderType, itemsInTheMiddle) => {
    if (placeholderType !== types.account && !isExpanded) {
      // hide placeholder if less than 4 elements
      if (itemsInTheMiddle.length > 0) {
        return {
          name: '\u2026',
          onClick: handleOnClick,
        };
      }
    }
    return undefined;
  };

  const handleOnClick = () => {
    setIsExpanded(!isExpanded);
  };

  const itemsInTheMiddle = items.slice(1, -1);
  const homeItem = getHomeItem();
  const itemLength = items.length;
  const lastItem = items.slice(-1)[0];
  const firstItem = lastItem !== items.slice(0, 1)[0] && items.slice(0, 1)[0];
  const placeholder = getPlaceholderItem(type, itemsInTheMiddle);
  const breadcrumbSize = getBreadcrumbSize(!!placeholder, type);
  let previousSearch;

  if (__CLIENT__ && sessionStorage.getItem('previousPath')) {
    const previousPath = new URL(sessionStorage.getItem('previousPath'));
    previousSearch = previousPath.search;
  }

  return (
    <div
      id="breadcrumb"
      className={cx({
        [styles.container]: type !== types.invisible,
        [styles.invisible]: type === types.invisible,
        [styles.error]: type === types.error,
        [styles.containerBottom]: type === types.detail,
      })}
    >
      {type !== types.invisible && (
        <>
          <SeoStructuredData
            type={seoStructuredDataTypes.BREADCRUMB}
            data={[homeItem, ...items]}
            categories={categories}
          />
          <ol className={styles.breadcrumb}>
            {/* compass icon */}
            {utils.isBreadcrumbBack(location) && (
              <BreadcrumbItem
                pos={0}
                item={{
                  name:
                    currBreakpoint === 'LG' || currBreakpoint === 'XL' || matchMedia(BREAKPOINTS.LG).matches === true
                      ? language('common.returnToSearchResults')
                      : language('common.return'),
                  href: location?.search
                    ? `/${getLocale()}/search${location?.search}`
                    : `/${getLocale()}/search${previousSearch}`,
                }}
                hasPlaceholder={!!placeholder}
                skin={utils.getSkin('back', positions.back)}
              />
            )}

            <div className={cx(styles['breadcrumb__wrapper'])}>
              <ul className={cx(styles['breadcrumb__list'])}>
                <BreadcrumbItem
                  pos={1}
                  item={homeItem}
                  hasPlaceholder={!!placeholder}
                  skin={utils.getSkin(type, positions.home)}
                  useNativeLink={nextHomepageEnabled}
                />

                {firstItem ? (
                  <BreadcrumbItem
                    pos={2}
                    item={firstItem}
                    hasPlaceholder={!!placeholder}
                    skin={utils.getSkin(type, positions.first)}
                  />
                ) : (
                  ''
                )}

                {itemsInTheMiddle.map((element, index) => (
                  <BreadcrumbItem
                    key={index + 2}
                    pos={index + 2}
                    item={element}
                    hasPlaceholder={!!placeholder}
                    skin={utils.getSkin(type, positions.center, breadcrumbSize)}
                  />
                ))}

                {lastItem ? (
                  <BreadcrumbItem
                    pos={firstItem ? itemLength : 1}
                    item={lastItem}
                    hasPlaceholder={!!placeholder}
                    skin={utils.getSkin(type, positions.last)}
                    hasProducts={hasProducts}
                  />
                ) : (
                  ''
                )}
              </ul>
            </div>
          </ol>
        </>
      )}
    </div>
  );
};

Breadcrumb.displayName = 'organisms/Breadcrumb';
Breadcrumb.propTypes = {
  categories: PropTypes.object,
  hasProducts: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.object),
  type: PropTypes.oneOf(Object.values(types)).isRequired,
  location: PropTypes.object,
};

export default connect(mapStateToProps)(Breadcrumb);
