import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import { resetAddToCartState, updateEntry, addToCart } from 'actions/cartActions/cartActions';
import { openCartModal, closeCartModal } from 'actions/uiActions/uiActions';
import { getProductAccessories } from 'actions/productActions/productActions';
import { apiStatus } from 'constants/apiStatus/apiStatus';
import { numberOfReferences } from 'constants/references/references';

import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import { getLocale } from 'constants/language/language';
import { cart as cartPath } from 'constants/routePaths/routePathCombinations';

import ModalApiError from 'components/organisms/ModalApiError/ModalApiError';
import AddedToCartModalContent from 'components/organisms/AddedToCartModalContent/AddedToCartModalContent';
import NoStockModal from 'components/organisms/NoStockModal/NoStockModal';

const getServiceItemCodes = (state) => {
  let serviceItemCodes = [];
  if (!!state.cart.entries && state.cart.addToCartState) {
    const entry = state.cart.entries.find(
      (cartEntry) => cartEntry.productCode === state.cart.addToCartState.productCode
    );

    if (entry) {
      serviceItemCodes = entry.serviceItemCodes;
    }
  }
  return serviceItemCodes;
};

// Get the productCodes of the accessories related to the product that was added to the cart
export const getReferenceItemCodes = ({ cart, products }) => {
  let referenceItemCodes = [];
  if (cart.addToCartState) {
    const { statusCode, productCode } = cart.addToCartState;
    if (statusCode === 'success' && !!productCode) {
      referenceItemCodes = products[productCode].referenceItemCodes?.slice(0, numberOfReferences.ADD2CART);
    }
  }
  return referenceItemCodes;
};

export const mapDispatchToProps = {
  resetAddToCartState,
  openCartModal,
  closeCartModal,
  getProductAccessories,
  updateEntry,
  addToCart,
};

export const mapStateToProps = (state) => ({
  addToCartState: state.cart.addToCartState,
  isOpen: state?.ui?.cartModal?.isOpen ?? false,
  serviceItemCodes: getServiceItemCodes(state),
  referenceItemCodes: getReferenceItemCodes(state),
});

let instances = [];
let instanceCounter = 0;

export class AddedToCartModal extends Component {
  static displayName = 'containers/AddedToCartModal';

  static propTypes = {
    addToCartState: PropTypes.object.isRequired,
    resetAddToCartState: PropTypes.func,
    updateEntry: PropTypes.func,
    openCartModal: PropTypes.func,
    closeCartModal: PropTypes.func,
    getProductAccessories: PropTypes.func,
    addToCart: PropTypes.func,
    isOpen: PropTypes.bool,
    serviceItemCodes: PropTypes.array,
    referenceItemCodes: PropTypes.array,
  };

  static defaultProps = {
    addToCartState: {
      status: '',
      statusCode: '',
      productTitle: '',
      productServices: [],
      accessories: [],
    },
    isOpen: false,
    serviceItemCodes: [],
  };

  constructor(props) {
    super(props);
    this.uid = `addToCartModal${instanceCounter++}`;
    instances.push(this.uid);
    this.state = {
      serviceItemCodes: [],
    };
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.setState({
        serviceItemCodes: []
      })
    }
  }

  componentWillUnmount() {
    instances = instances.filter((instance) => instance !== this.uid);
  }

  closeModal = () => {
    this.props.closeCartModal();
    this.props.resetAddToCartState();
  };

  openModal = () => {
    this.props.openCartModal();
  };

  confirmAndClose = () => {
    const { entryID, code, quantity, serviceItemCodes, serviceItemAdded } = this.state;
    if (serviceItemAdded) {
      this.props
        .updateEntry(entryID, code, quantity, serviceItemCodes, this.props.addToCartState.serviceItemCodes)
    }
    this.closeModal()
  }

  retryAction = () => {
    const { productCode, quantity } = this.props.addToCartState;
    this.props.addToCart(productCode, quantity);
    this.props.getProductAccessories({ productCode, pageSize: numberOfReferences.ADD2CART });
  };

  updateEntry = (entryID, code, quantity, serviceItemCodes) => {
    this.setState({
      entryID,
      code,
      quantity,
      serviceItemCodes,
      serviceItemAdded: true,
    });
  };

  goToCart = () => {
    const { entryID, code, quantity, serviceItemCodes, serviceItemAdded } = this.state;
    const navToCart = () => {
      browserHistory.push(mapPathToLocalizedUrl(getLocale(), cartPath));
      requestAnimationFrame(() => {
        // wait for DOM updates
        window.scrollTo(0, 0);
      });
    };

    if (serviceItemAdded) {
      this.props
        .updateEntry(entryID, code, quantity, serviceItemCodes, this.props.addToCartState.serviceItemCodes)
        .finally(navToCart);
    } else {
      navToCart();
    }
  };

  render() {
    const { referenceItemCodes, isOpen, addToCartState } = this.props;
    const { status, statusCode } = addToCartState;
    const { serviceItemCodes } = this.state;
    const hasNoStock = ['noStock', 'maxOrderQuantityExceeded'].includes(statusCode);
    const hasNetWorkError = isOpen && status === apiStatus.networkerror;

    // Allow only one instance of the modal
    if (this.uid !== instances[0]) {
      return null;
    }

    if (!isOpen) {
      return null;
    }

    if (hasNetWorkError)
      return (
        <ModalApiError
          requestCloseModal={this.closeModal}
          retryAction={this.retryAction}
          isModalOpen={status === apiStatus.networkerror}
          apiStatus={apiStatus.networkerror}
        />
      );
    if (hasNoStock) {
      return (
        <NoStockModal
          statusCode={statusCode}
          modalIsOpen={isOpen}
          closeModal={this.closeModal}
          addToCartState={addToCartState}
          goToCart={this.goToCart}
        />
      );
    }

    return (
      <AddedToCartModalContent
        modalIsOpen={isOpen}
        closeModal={this.closeModal}
        confirmAndClose={this.confirmAndClose}
        addToCartState={addToCartState}
        serviceItemCodes={serviceItemCodes}
        referenceItemCodes={referenceItemCodes}
        updateEntry={this.updateEntry}
        goToCart={this.goToCart}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddedToCartModal);
