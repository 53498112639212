import React from 'react';
import { browserHistory, IndexRoute, Route } from 'react-router';

import { handleSearchRoutes, prepareStateForRoute, routeHelperKeys } from './routeHelpers';

import {
  BRAND,
  CATEGORY,
  CATEGORY_SEARCH,
  CMS,
  DOCUMENTS,
  NOTFOUND,
  ORDER_CONFIRMATION,
  PRODUCT,
  PRODUCT_CODE,
  PASSWORD,
  PASSWORD_RESET,
  PAYMENT,
  PAYMENT_CANCEL,
  PAYMENT_SUCCESS,
  PRODUCT_SEARCH,
  PRODUCT_SEARCH_EMPTY,
  STOREFINDER,
  WALL,
  CATEGORY_PRODUCTCODE,
} from 'constants/routePaths/routePaths';

import Providers from 'components/layouts/Providers/Providers';

import DefaultLayout from 'components/layouts/DefaultLayout/DefaultLayout';
import CheckoutLayout from 'components/layouts/CheckoutLayout/CheckoutLayout';

import Login from 'routes/Login/Login';
import HomePage from 'routes/HomePage/HomePage';
import NotFound from 'routes/NotFound/NotFound';

import ProductsOverview from 'routes/ProductsOverview/ProductsOverview';
import ProductDetail from 'routes/ProductDetail/ProductDetail';
import EmptyProductSearch from 'routes/EmptyProductSearch/EmptyProductSearch';

import OrderConfirmation from 'routes/checkout/OrderConfirmation/OrderConfirmation';

import PasswordReset from 'routes/PasswordReset/PasswordReset';

import PaymentSuccess from 'routes/checkout/Payment/PaymentSuccess';
import PaymentCancel from 'routes/checkout/Payment/PaymentCancel';

import WallOfAll from 'routes/WallOfAll/WallOfAll';
import CmsDynamic from 'routes/CmsDynamic/CmsDynamic';

import StoreFinder from 'routes/StoreFinder/StoreFinder';

import Documents from 'routes/Documents/Documents';
import { getProductPathForSpecificCases } from 'constants/urlHelper/urlHelper';

const routes = (extRoutes, store) => (
  <Route>
    <Route path={PAYMENT} component={CheckoutLayout}>
      <Route path={PAYMENT_SUCCESS} component={PaymentSuccess} />
      <Route path={PAYMENT_CANCEL} component={PaymentCancel} />
    </Route>
    {extRoutes.checkoutRoutes(store)}
    {extRoutes.accountRoutes(store)}
    {extRoutes.newsletterRoutes(store)}
    {extRoutes.watchlistRoutes(store)}
    {extRoutes.productComparisonRoutes(store)}
    {extRoutes.optinRoutes(store)}
    {extRoutes.smartEdit(store)}

    <Route path={PASSWORD} component={DefaultLayout}>
      <IndexRoute component={Login} />
      <Route
        path={PASSWORD_RESET}
        component={PasswordReset}
        onEnter={prepareStateForRoute(store, routeHelperKeys.PASSWORD_RESET)}
      />
    </Route>
    <Route
      path={ORDER_CONFIRMATION}
      component={DefaultLayout}
      onEnter={prepareStateForRoute(store, routeHelperKeys.ORDER_CONFIRMATION)}
    >
      <IndexRoute component={OrderConfirmation} />
    </Route>
    <Route path={WALL} component={DefaultLayout}>
      <IndexRoute component={WallOfAll} />
    </Route>
    <Route path={CMS} component={DefaultLayout}>
      <Route path="(**)" component={CmsDynamic} />
    </Route>
    <Route path={BRAND} component={DefaultLayout}>
      <Route path="(**)" component={CmsDynamic} isBrandPage />
    </Route>
    <Route path="" component={DefaultLayout}>
      <IndexRoute component={HomePage} />
      <Route path={NOTFOUND} component={NotFound} />
      <Route path={CATEGORY} component={ProductsOverview} type={ProductsOverview.types.CATEGORY} />
      <Route
        path={`${CATEGORY}/${CATEGORY_SEARCH}`}
        component={ProductsOverview}
        type={ProductsOverview.types.CATEGORY_SEARCH}
      />
      <Route path={`${PRODUCT_SEARCH}/${PRODUCT_SEARCH_EMPTY}`} component={EmptyProductSearch} />
      <Route
        path={PRODUCT_SEARCH}
        component={ProductsOverview}
        type={ProductsOverview.types.PRODUCT_SEARCH}
        onEnter={handleSearchRoutes()}
      />
      <Route path={STOREFINDER} component={StoreFinder} />
      <Route path={DOCUMENTS} component={Documents} />
    </Route>
    <Route path="" component={DefaultLayout}>
      <Route path={`${CATEGORY_PRODUCTCODE}`} component={ProductDetail} />
    </Route>

    <Route path={PRODUCT} component={DefaultLayout}>
      <Route path={`${PRODUCT_CODE}`} component={ProductDetail} />
    </Route>
  </Route>
);

export default (extRoutes) => (store) => {
  browserHistory?.listen((location) => {
    if (__CLIENT__ && !location.state?.disableScroll) {
      window.scrollTo(0, 0);
    }

    if (location.pathname.includes('--c') && location.pathname.includes('--p')) {
      const state = store.getState();
      const categories = state.categories;
      const currentCategory = state.productsQueries?.currentQuery?.categoryCode;

      if (categories?.[currentCategory]?.newURLPdp) {
        window.location.replace(window.location.origin + getProductPathForSpecificCases(location.pathname));
      }
    }
  });

  return (
    <Route component={Providers}>
      <Route path="/:locale">{routes(extRoutes, store)}</Route>
      <Route path="*" component={DefaultLayout}>
        <IndexRoute component={NotFound} />
      </Route>
    </Route>
  );
};
