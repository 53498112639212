import React from 'react';
import PropTypes from 'prop-types';
import { Element } from 'react-scroll';
import BreakpointListener from 'containers/BreakpointListener/BreakpointListener';
import ExpandableBox from 'components/molecules/ExpandableBox/ExpandableBox';
import ProductFeatureTable from 'components/molecules/ProductFeatureTable/ProductFeatureTable';
import styles from './ProductFeatures.scss';
import bootstrap from 'scss/component.scss';

const ProductFeatures = ({ classifications }) => (
  <Element name="classifications">
    <div id="classifications">
      <div className={bootstrap.hiddenPrint}>
        {classifications.length === 1 ? (
          <div key="feat1" className={styles.wrap}>
            <ProductFeatureTable name={classifications[0].name} features={classifications[0].features} />
          </div>
        ) : (
          <BreakpointListener>
            <ExpandableBox showAll={false} visibleChildren={1} stepSize={classifications.length} alternateExpander>
              {classifications.map((classification, index) => (
                <div key={`feat${index}`} className={styles.wrap}>
                  <ProductFeatureTable name={classification.name} features={classification.features} />
                </div>
              ))}
            </ExpandableBox>
          </BreakpointListener>
        )}
      </div>
      <div className={styles.visiblePrintBlock}>
        {classifications.map((classification, index) => (
          <div key={`feat${index}`} className={styles.wrap}>
            <ProductFeatureTable name={classification.name} features={classification.features} />
          </div>
        ))}
      </div>
    </div>
  </Element>
);

ProductFeatures.propTypes = {
  classifications: PropTypes.arrayOf(PropTypes.object),
};

ProductFeatures.displayName = 'molecules/ProductFeatures';

export default ProductFeatures;
