import React, { Component } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import language from 'constants/language/language';
import { BREAKPOINTS, smallerThan } from 'constants/breakpoints/breakpoints';
import { types as flashMessageTypes } from 'constants/flashMessages/flashMessages';
import { cypressAttributes } from 'constants/cypress/cypress';
import Headline, { tags } from 'components/atoms/Headline/Headline';
import IconLink from 'components/atoms/IconLink/IconLink';
import InputText from 'components/atoms/InputText/InputText';
import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';
import FlashMessage from 'components/atoms/FlashMessage/FlashMessage';
import InputCheckbox from 'components/atoms/InputCheckbox/InputCheckbox';
import { Container, Row, Col } from 'components/atoms/Grid/Grid';
import StoreFinderCollapsible from './StoreFinderCollapsible';
import Icon, { ICON_GPS_NOT_FIXED, ICON_SEARCH } from 'components/atoms/Icon/Icon';
import styles from './StoreFinderSearch.scss';

class StoreFinderSearch extends Component {
  static displayName = 'organisms/StoreFinderSearch';

  static propTypes = {
    submitSearch: PropTypes.func.isRequired,
    searchStoreByLocation: PropTypes.func.isRequired,
    searchStoreByLocationError: PropTypes.string.isRequired,
    changeShowOnlyOpenStores: PropTypes.func.isRequired,
    changeShowOnlyShowrooms: PropTypes.func.isRequired,
    showOnlyOpenStores: PropTypes.bool,
    showOnlyShowrooms: PropTypes.bool,
    cartHasBulkyGood: PropTypes.bool,
    searchTerm: PropTypes.string,
    isStandalone: PropTypes.bool,
    isInventory: PropTypes.bool,
    breakpoint: PropTypes.string,
    flashMessage: PropTypes.object,
    pages: PropTypes.array,
  };

  static defaultProps = {
    showOnlyOpenStores: false,
    showOnlyShowrooms: false,
    searchTerm: '',
    isStandalone: false,
    isInventory: false,
    flashMessage: {},
    pages: [],
  };

  getPlaceholderText = () =>
    smallerThan(this.props.breakpoint, BREAKPOINTS.SM)
      ? language('storeFinder.search.placeholderShort')
      : language('storeFinder.search.placeholder');

  submit = (e) => {
    e.preventDefault();
    const form = e.target;
    const searchField = form.querySelector('[name="storeFinderSearchField"]');
    const searchString = searchField.value.trim();
    this.props.submitSearch(searchField, searchString);
  };

  render() {
    const { isStandalone, cartHasBulkyGood, isInventory, flashMessage, pages } = this.props;
    // prepare searchfield options so we don't have to render the inputText two times
    // with slightly different options
    const searchFieldOptions = {
      type: 'search',
      name: 'storeFinderSearchField',
      placeholder: this.getPlaceholderText(),
    };

    if (this.props && !!this.props.searchTerm) {
      Object.assign(searchFieldOptions, { updateValue: this.props.searchTerm });
    }

    return (
      <div
        className={cx({
          [styles.wrapCheckout]: !this.props.isStandalone,
        })}
      >
        {!isStandalone && !isInventory && (
          <div className={cx(styles.headline, styles.headlineCheckout)}>
            <Headline margin={Headline.margins.MB_30} alignment={Headline.alignments.CENTER}>
              {language('storeFinder.search.headlineCheckout')}
            </Headline>
          </div>
        )}
        <Container>
          <Row>
            <Col>
              {isStandalone && !isInventory && (
                <div className={styles.headline}>
                  <Headline
                    tag={isStandalone ? tags.H1 : tags.H2}
                    margin={Headline.margins.MB_30}
                    alignment={Headline.alignments.CENTER}
                  >
                    {language('storeFinder.search.headline')}
                  </Headline>
                </div>
              )}
              <div className={cx(styles.searchContent, styles.searchContentCheckout)}>
                <div>
                  {flashMessage.message && <FlashMessage type={flashMessage.type} content={flashMessage.message} />}
                  {cartHasBulkyGood && (
                    <FlashMessage type={flashMessageTypes.WARNING} content={language('storeFinder.bulkyGoodNotice')} />
                  )}
                  <form onSubmit={this.submit} action=".">
                    <div className={styles.containerMain}>
                      <div className={styles.searchForm}>
                        <div className={styles.outerDiv}>
                          <InputText {...searchFieldOptions} cyData={cypressAttributes.checkout.storeFinderSearch} />
                          <div className={styles.searchButtonContainer}>
                            <ButtonLink
                              type="submit"
                              variety={ButtonLink.varieties.flat}
                              data-cy={cypressAttributes.checkout.storeFinderSubmit}
                            >
                              {language('storeFinder.search.button')}
                            </ButtonLink>
                          </div>
                        </div>
                        <i className={styles.iconSearch}>
                          <Icon path={ICON_SEARCH} />
                        </i>
                      </div>
                    </div>
                  </form>
                </div>
                <div className={styles.filter}>
                  <div className={styles.filterItem}>
                    <IconLink onClick={this.props.searchStoreByLocation}>
                      <i className={styles.iconLocation}>
                        <Icon path={ICON_GPS_NOT_FIXED} size={Icon.sizes.xs} />
                      </i>
                      <span className={styles.filterItemText}>{language('storeFinder.search.geo.text')}</span>
                    </IconLink>
                  </div>
                  <div className={styles.filterItem}>
                    <InputCheckbox
                      onClick={() => this.props.changeShowOnlyOpenStores(!this.props.showOnlyOpenStores)}
                      selected={this.props.showOnlyOpenStores}
                    >
                      {language('storeFinder.search.opening')}
                    </InputCheckbox>
                  </div>
                  <div className={styles.filterItem}>
                    <InputCheckbox
                      onClick={() => this.props.changeShowOnlyShowrooms(!this.props.showOnlyShowrooms)}
                      selected={this.props.showOnlyShowrooms}
                    >
                      {language('storeFinder.search.showroom')}
                    </InputCheckbox>
                  </div>
                </div>
                {this.props.searchStoreByLocationError !== '' && (
                  <div className={styles.locationError}>
                    <span>{this.props.searchStoreByLocationError}</span>
                  </div>
                )}
                <StoreFinderCollapsible pages={pages} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default StoreFinderSearch;
