import { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import env from 'constants/environment/environment';

const Portal = ({ children }) => {
  const [appRoot, setAppRoot] = useState(null);
  useEffect(() => {
    setAppRoot(window?.document?.getElementById(env.REACT_ROOT_ID));
  }, []);

  return appRoot ? ReactDOM.createPortal(children, appRoot) : null;
};

Portal.propTypes = {
  children: PropTypes.node.isRequired,
};

Portal.displayName = 'molecules/Portal';

export default Portal;
