import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import dataTracking from 'constants/trackingAttributes/dataTracking';

import Icon, { ICON_KEYBOARD_ARROW_RIGHT } from 'components/atoms/Icon/Icon';
import { getEnv } from 'config/config';
import Headline from 'components/atoms/Headline/Headline';
import Markdown from 'components/atoms/Markdown/Markdown';
import InternalOrExternalLink from 'components/atoms/InternalOrExternalLink/InternalOrExternalLink';

import styles from './SingleWallBanner.scss';

const colors = {
  DEFAULT: 'DEFAULT',
  BLACK: 'BLACK',
};

const SingleWallBanner = ({
  color = colors.BLACK,
  title = '',
  image = {},
  cta = {},
  logo = {},
  intro = '',
  trackingAttributes = {},
}) => (
  <InternalOrExternalLink
    href={cta.href}
    className={cx(styles.bannerContainer, { [styles.dark]: color === colors.BLACK })}
    dataTracking={dataTracking({
      TYPE: Object.keys(cta).length ? 'singlewall:cta' : 'singlewall:banner',
      LINK: cta.href,
      ATTRIBUTE_1: title,
      ...trackingAttributes,
    })}
  >
    {image.src && (
      <div className={styles.imageWrapper}>
        <img className={styles.image} src={`${getEnv('web')}${image.src}`} alt={image.alt || ''} />
      </div>
    )}
    <div className={styles.content}>
      {logo.src && (
        <div>
          <img src={`${getEnv('web')}${logo.src}`} alt={logo.alt || ''} className={styles.logo} />
        </div>
      )}
      {!logo.src && intro && <div className={styles.logo}>{intro}</div>}
      <div className={styles.text}>
        <Headline tag={Headline.tags.H3}>
          <div className={styles.headlineContent}>
            <Markdown options={{ forceInline: true }}>{title}</Markdown>
          </div>
        </Headline>
      </div>
      {cta.text && (
        <div className={styles.cta}>
          {cta.text}
          <Icon path={ICON_KEYBOARD_ARROW_RIGHT} />
        </div>
      )}
    </div>
  </InternalOrExternalLink>
);

SingleWallBanner.propTypes = {
  color: PropTypes.oneOf(Object.values(colors)),
  title: PropTypes.string,
  image: PropTypes.object,
  cta: PropTypes.object,
  logo: PropTypes.object,
  intro: PropTypes.string,
  trackingAttributes: PropTypes.object,
};

SingleWallBanner.displayName = 'molecules/SingleWallBanner';

export default SingleWallBanner;
