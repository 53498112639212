import { getCategoryUrl } from 'constants/categoryTree/categoryTree';
import { sanitizeUrlString } from 'constants/urlMapping/urlMapping';
import { getLocale } from 'constants/language/language';
import { generateSEOUrl } from 'constants/urlMapping/urlMapping';

export const getProductPath = (code, name) => `${sanitizeUrlString(name)}--p${code}`;

export const getProductLink = (categoryCode, name, code, locale = getLocale(), categories) => {
  // There should be no old PDP structure, we just keep this for ability to revert to old structure
  if (categories?.[categoryCode]?.newURLPdp === false) {
    return `${getCategoryUrl(categoryCode, locale, categories)}/${getProductPath(code, name)}`;
  }

  return `/${locale}/product/${generateSEOUrl(name, locale)}-${code}`;
};

export default getProductLink;
