import React from 'react';
import PropTypes from 'prop-types';
import DrilldownAccordion from 'components/atoms/DrilldownAccordion/DrilldownAccordion';
import MobileNavigationItem from 'components/atoms/MobileNavigationItem/MobileNavigationItem';
import { getLocale } from 'constants/language/language';

import { NAVIGATION_SUB } from 'constants/trackingAttributes/trackingAttributes';

import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import { B2B_PAGE, CMS } from 'constants/routePaths/routePaths';

const B2BSection = ({ title, pos, isResetToStart }) => {
  return (
    <DrilldownAccordion title={title} pos={pos} isResetToStart={isResetToStart}>
      <MobileNavigationItem
        id="b2b"
        href={mapPathToLocalizedUrl(getLocale(), [CMS, B2B_PAGE], false)}
        tracking={{
          type: NAVIGATION_SUB,
          position: 1,
          title,
          parentTitle: title,
        }}
      >
        {title}
      </MobileNavigationItem>
    </DrilldownAccordion>
  );
};

export default B2BSection;

B2BSection.propTypes = {
  isResetToStart: PropTypes.func,
  title: PropTypes.string,
  pos: PropTypes.number,
};
