import React from 'react';
import PropTypes from 'prop-types';

import styles from './TeaserProductActionsButton.scss';

import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';
import Icon, { ICON_BASKET } from 'components/atoms/Icon/Icon';

const TeaserProductActionsButton = ({
  buttonType = ButtonLink.varieties.flat,
  buttonSize = ButtonLink.sizes.md,
  buttonText = '',
  showIcon = false,
  buttonDisabled = false,
  buttonBlock = false,
  handleClick = () => {},
  color = ButtonLink.colors.red,
  id = '',
  cyData,
}) => (
  <div className={styles.container}>
    <ButtonLink
      variety={buttonType}
      size={buttonSize}
      color={color}
      onClick={handleClick}
      disabled={buttonDisabled}
      stretched={buttonBlock}
      id={id}
      data-cy={cyData}
    >
      <div className={styles.buttonContent}>
        {showIcon && <Icon path={ICON_BASKET} />}
        {buttonText && <span>{buttonText}</span>}
      </div>
    </ButtonLink>
  </div>
);

TeaserProductActionsButton.propTypes = {
  className: PropTypes.string,
  buttonType: PropTypes.oneOf(Object.values(ButtonLink.varieties)),
  buttonSize: PropTypes.oneOf(Object.values(ButtonLink.sizes)),
  color: PropTypes.oneOf(Object.values(ButtonLink.colors)),
  showIcon: PropTypes.bool,
  buttonText: PropTypes.string,
  buttonDisabled: PropTypes.bool,
  buttonBlock: PropTypes.bool,
  enableButtonIcon: PropTypes.bool,
  handleClick: PropTypes.func,
  isInCart: PropTypes.bool,
  id: PropTypes.string,
  cyData: PropTypes.string,
  code: PropTypes.string,
};

TeaserProductActionsButton.displayName = 'molecules/TeaserProductActionsButton';

export default TeaserProductActionsButton;
