import { useEffect, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { BREAKPOINTS } from 'constants/breakpoints/breakpoints';
import { MAIN_WALL_ID } from 'constants/cms/cms';
import { getWallContent } from 'actions/cmsActions/cmsActions';

export const mapStateToProps = (state = {}, ownProps = {}) => {
  const wallId = ownProps.wallId || MAIN_WALL_ID;
  const isWallContentLoaded = !!state.cms?.wall?.[wallId];
  const wall = state.cms?.wall?.[wallId];
  return {
    breakpoint: state.ui?.breakpoint,
    bricks: wall?.content || [],
    callToAction: wall?.callToAction,
    isWallContentLoaded,
    validUntil: wall?.validUntil,
  };
};

export const mapDispatchToProps = {
  getWallContent,
};

export const isDateInThePast = (dateString, now = new Date()) => new Date(dateString) <= now;

const Wall = ({
  breakpoint,
  bricks,
  callToAction,
  children,
  getWallContent: getWallContentFunc,
  isWallContentLoaded,
  validUntil,
  wallId,
}) => {
  useEffect(() => {
    if (!isWallContentLoaded || isDateInThePast(validUntil)) {
      getWallContentFunc(wallId);
    }
  }, []);

  if (bricks.length <= 0) return null;

  return cloneElement(children, {
    breakpoint,
    bricks,
    callToAction,
  });
};
Wall.displayName = 'containers/Wall';
Wall.propTypes = {
  breakpoint: PropTypes.oneOf(Object.values(BREAKPOINTS)),
  bricks: PropTypes.array,
  callToAction: PropTypes.shape({
    actionText: '',
    text: '',
    url: '',
  }),
  children: PropTypes.element.isRequired,
  getWallContent: PropTypes.func,
  isWallContentLoaded: PropTypes.bool,
  validUntil: PropTypes.string,
  wallId: PropTypes.string,
};
Wall.defaultProps = {
  breakpoint: '',
  bricks: [],
  callToAction: {},
  getWallContent: () => {},
  validUntil: '',
  wallId: '',
};

export default connect(mapStateToProps, mapDispatchToProps)(Wall);
