import { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

export const mapStateToProps = (state, ownProps) => {
  const productCode = ownProps.productCode;
  const product = { ...(state.products[productCode] || {}), ...ownProps.productAttributes };

  return {
    product,
  };
};

function SimpleProduct({ children, product, ...props }) {
  return cloneElement(children, {
    product,
    ...props,
  });
}

SimpleProduct.displayName = 'containers/Product/SimpleProduct';
SimpleProduct.propTypes = {
  productCode: PropTypes.string.isRequired,
  children: PropTypes.node,
  product: PropTypes.object,
};

export default connect(mapStateToProps)(SimpleProduct);
