const ConfigHelper = {
  clientSecret: 'interdiscount',
};

export const basePaths = {
  baseSiteUrl: '/idocc/occ/id',
  tokenResource: '/idauth/oauth',
  supercardUrl: '/supercard/api',
};

export default ConfigHelper;
