import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import Channel from './Channel';

class StickyContainer extends Component {
  constructor(props) {
    super(props);
    this.channel = new Channel({ inherited: 0, offset: 0, node: null });
  }

  getChildContext() {
    return { 'sticky-channel': this.channel };
  }

  UNSAFE_componentWillMount() {
    const parentChannel = this.context['sticky-channel'];
    if (parentChannel) parentChannel.subscribe(this.updateOffset);
  }

  componentDidMount() {
    // eslint-disable-next-line react/no-find-dom-node
    const node = ReactDOM.findDOMNode(this);
    this.channel.update((data) => {
      // eslint-disable-next-line no-param-reassign
      data.node = node;
    });
  }

  componentWillUnmount() {
    this.channel.update((data) => {
      // eslint-disable-next-line no-param-reassign
      data.node = null;
    });

    const parentChannel = this.context['sticky-channel'];
    if (parentChannel) parentChannel.unsubscribe(this.updateOffset);
  }

  updateOffset = ({ inherited, offset }) => {
    this.channel.update((data) => {
      // eslint-disable-next-line no-param-reassign
      data.inherited = inherited + offset;
    });
  };

  render() {
    const { withWrapper, children } = this.props;
    // In most cases an (empty) div around children is needed for proper sticky functionality. (Exception CartLayout.js)
    return withWrapper ? <div className="sticky-container">{children}</div> : children;
  }
}

StickyContainer.contextTypes = {
  'sticky-channel': PropTypes.any,
};
StickyContainer.childContextTypes = {
  'sticky-channel': PropTypes.any,
};

StickyContainer.displayName = 'atoms/StickyContainer';
StickyContainer.propTypes = {
  children: PropTypes.node.isRequired,
  withWrapper: PropTypes.bool,
};
StickyContainer.defaultProps = {
  withWrapper: true,
};

export default StickyContainer;
