import React, { Component } from 'react';
import PropTypes from 'prop-types';

const RESERVED_REGEX_PATTERN = /[.*+?^${}()|[\]\\]/g;
const createRegex = (str) => {
  const regexString = str.trim().replace(RESERVED_REGEX_PATTERN, '').split(' ').join('|');
  return new RegExp(`(${regexString})`, 'gi');
};

/**
 * Heavily influenced by https://github.com/tracemetrix/react-string-highlighter
 */

export default class AutoSuggestHighlighter extends Component {
  static displayName = 'organisms/SearchSuggest';

  static propTypes = {
    searchTerm: PropTypes.string,
    children: PropTypes.string.isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    className: 'highlight',
  };

  highlight(baseString, highlight, fn) {
    const expectedLength = baseString.length;
    const regex = createRegex(highlight);
    const markup = [];
    let marker = 0;
    let index = 0;
    baseString.replace(regex, (match, submatches, offset) => {
      if (offset > marker) {
        markup.push(baseString.substr(marker, offset - marker));
      }
      markup.push(fn(match, index));
      marker = offset + match.length;
      index++;
    });

    if (marker < expectedLength) {
      markup.push(baseString.substr(marker, expectedLength));
    }

    return markup;
  }

  render() {
    const { children, searchTerm, className } = this.props;

    if (typeof children !== 'string' || typeof searchTerm !== 'string') {
      return <span>{children}</span>;
    }

    const result = this.highlight(children.trim(), searchTerm.trim(), (match, idx) => (
      <span className={className} key={idx}>
        {match}
      </span>
    ));

    return <span>{result}</span>;
  }
}
