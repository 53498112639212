import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { StepperButtons } from 'components/molecules/Stepper/Stepper';
import PaymentIcon from 'components/atoms/PaymentIcon/PaymentIcon';
import InputRadioList from 'components/molecules/InputRadioList/InputRadioList';
import Headline from 'components/atoms/Headline/Headline';
import InputText from 'components/atoms/InputText/InputText';
import FieldSetInput from 'components/molecules/FieldSetInput/FieldSetInput';
import language from 'constants/language/language';

import validation from 'constants/validation/validation';
import { AVAILABLE_RETURN_PAYMENT_TYPES } from 'constants/returns/returns';

const translateOptions = (options) => {
  const result = {};
  Object.keys(options)
    .filter((key) => key !== AVAILABLE_RETURN_PAYMENT_TYPES.NONE)
    .forEach((key) => {
      result[key] = language(`returns.returnPayment.types.${key}`);
    });
  return result;
};

const ReturnPayment = ({ onNextClick = () => {}, onPrevClick = () => {}, activeStep = 0, state = {} }) => {
  const order = state.order;
  const [type, setReturnPaymentType] = useState(
    order.payment.paybackInfoNeeded ? state.refund.type : AVAILABLE_RETURN_PAYMENT_TYPES.NONE
  );
  const [iban, setIban] = useState(state.refund.iban || '');
  const [ibanValidationError, setIbanValidationError] = useState(false);

  const handleChange = (value) => {
    setIbanValidationError(!validation.iban(value));
    setIban(value);
  };

  const handleNextClick = () => {
    onNextClick({
      ...state,
      refund: { type, iban },
    });
  };

  return (
    <>
      {order.payment.paybackInfoNeeded ? (
        <>
          <Headline tag={Headline.tags.H4} margin={Headline.margins.NONE}>
            {language('returns.returnPayment.refundType')}
          </Headline>
          <InputRadioList
            handleChange={setReturnPaymentType}
            options={translateOptions(AVAILABLE_RETURN_PAYMENT_TYPES)}
            value={type}
          />
          {type === AVAILABLE_RETURN_PAYMENT_TYPES.IBAN && (
            <FieldSetInput
              value={iban}
              label={language('returns.returnPayment.ibanLabel')}
              handleChange={handleChange}
              showError={ibanValidationError}
              forceValidation={ibanValidationError}
              customErrorMessage={language('returns.returnPayment.ibanInvalid')}
              required
            >
              <InputText onKeyPress={validation.isValidIBANCharacter} />
            </FieldSetInput>
          )}
        </>
      ) : (
        <>
          <p>{language('returns.returnPayment.info')}</p>
          <PaymentIcon paymentMethod={order.payment.paymentCode} />
        </>
      )}
      <StepperButtons
        onPrevClick={onPrevClick}
        onNextClick={handleNextClick}
        activeStep={activeStep}
        nextDisabled={type === AVAILABLE_RETURN_PAYMENT_TYPES.IBAN && (ibanValidationError || !iban.trim().length)}
      />
    </>
  );
};

ReturnPayment.displayName = 'molecules/ReturnSteps/ReturnPayment';

ReturnPayment.propTypes = {
  onNextClick: PropTypes.func,
  onPrevClick: PropTypes.func,
  activeStep: PropTypes.number,
  state: PropTypes.object,
};

export default ReturnPayment;
