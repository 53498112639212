import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import { resendDoubleOptInMail } from 'actions/userActions/userActions';

import Meta from 'containers/Meta/Meta';
import NotConfirmedOptin from 'components/organisms/NotConfirmedOptIn/NotConfirmedOptIn';

import { HELPCENTER_LINK } from 'constants/links/links';
import { getLocale } from 'constants/language/language';
import NextFeatureEnabled from 'routes/ProductsOverview/NextFeatureEnabled';

const mapDispatchToProps = {
  resendDoubleOptInMail,
};
export const mapStateToProps = (state) => ({
  pages: state?.cms?.navigation?.pages,
  recaptchaNeeded: state?.user?.recaptchaNeeded,
  email: state?.user?.fields?.email?.value,
});

const NotConfirmedOptIn = (props) => {
  const properties = {
    type: props.route.type,
    email: props.email,
    resendMail: props.resendDoubleOptInMail,
    contactLink: HELPCENTER_LINK + getLocale(),
    recaptchaNeeded: props.recaptchaNeeded,
  };
  return (
    <>
      <NextFeatureEnabled conditions={[{ featureName: 'nextLoginRegistrationEnabled', include: 'opt-in' }]} />
      <Meta meta={[{ name: 'robots', content: 'noindex' }]} />
      <NotConfirmedOptin {...properties} />
    </>
  );
};

NotConfirmedOptIn.displayName = 'containers/NotConfirmedOptIn';
NotConfirmedOptIn.propTypes = {
  location: PropTypes.object,
  pages: PropTypes.object,
  recaptchaNeeded: PropTypes.bool,
  resendDoubleOptInMail: PropTypes.func,
  route: PropTypes.object,
  email: PropTypes.string,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NotConfirmedOptIn));
