import { cloneElement, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RecoActions from 'actions/recoActions/recoActions';
import { productClick as productClickAction, trackRecommendedProducts } from 'actions/trackingActions/trackingActions';
import getCookieStorage from 'constants/storage/cookie';
import { noop } from 'constants/noop/noop';
import { RECO_COOKIE } from 'constants/storage/storageKeys';
import matchMedia from 'constants/matchMedia/matchMedia';
import { BREAKPOINTS } from 'constants/breakpoints/breakpoints';
import { getLocale } from 'constants/language/language';
import { getMatchingProductsFromStore } from 'containers/RecommendationsBox/RecommendationsBox.helper';
import { recoTrackingEndpoints as recoTypes } from 'api/RecoAPI/RecoAPI';
import { joinProductCodes } from './ProductRecommendations.helper';

const mapStateToProps = (state, ownProps) => {
  const recommendedProducts = state.recommendations[ownProps.type] || {};
  const breadcrumb = state.productsQueries.currentQuery.breadcrumb;
  const boxLevel = ownProps.type === recoTypes.HOME ? 1 : breadcrumb?.length || 1;
  const recoBoxLevel = `box${boxLevel}`;
  const box = recommendedProducts[recoBoxLevel];
  const cartEntries = state.cart?.entries || [];

  return {
    recommendedProducts: getMatchingProductsFromStore(box || [], state?.products || {}),
    categories: state.categories || {},
    params: ownProps.params,
    title: recommendedProducts.title || '',
    type: ownProps.type,
    recoBoxLevel,
    currentBasket: joinProductCodes(cartEntries),
    cartEntries,
    recommendations: state.recommendations,
  };
};
const mapDispatchToProps = {
  loadRecommendedTopProducts: RecoActions.loadRecommendedTopProducts,
  productClick: productClickAction,
  trackRecommendedProductsAction: trackRecommendedProducts,
};

const cookie = getCookieStorage();

const emptyObject = {};
const ProductRecommendations = ({
  categories = emptyObject,
  children,
  loadRecommendedTopProducts = noop,
  trackRecommendedProductsAction = noop,
  params = emptyObject,
  productClick = noop,
  recommendedProducts = [],
  title = '',
  type = '',
  recoBoxLevel,
  currentBasket = '',
  cartEntries = [],
  sliderReset,
  recommendations = {},
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const loadTopProducts = useCallback(
    (currBasket = '') => {
      const sessionId = cookie.getItem(RECO_COOKIE);
      const locale = getLocale();

      if (params.searchterm !== searchTerm) setSearchTerm(params.searchterm);

      loadRecommendedTopProducts(sessionId, type, locale, currBasket, params, recoBoxLevel, searchTerm).then(() => {
        trackRecommendedProductsAction({
          type,
          recoBoxLevel,
          isSearch: true,
          hasRecommendedProducts: recommendedProducts?.length > 0,
        });
        setSearchTerm('');
      });
    },
    [params.searchterm, params.cid, !recommendedProducts.length]
  );

  useEffect(() => {
    if (!currentBasket || (currentBasket === '' && cartEntries.length === 0)) loadTopProducts();
    if (currentBasket && cartEntries.length > 0) loadTopProducts(currentBasket);
  }, [cartEntries.length, currentBasket, loadTopProducts]);

  if (!recommendedProducts.length) return null;

  const productsToRender = recommendedProducts.map((product) => {
    let newProduct = product;

    if (!product.recoTrackingToken && recommendations[type]?.[recoBoxLevel]) {
      recommendations[type][recoBoxLevel].find(function (rec) {
        if (rec.productId === product.code) {
          newProduct.recoTrackingToken = rec.trackingToken;
          return true;
        }

        return false;
      });
    }

    return newProduct;
  });

  const slidesPerView =
    (type === recoTypes.ERROR || type === recoTypes.HOME || type === recoTypes.NULL_RESULT) &&
    matchMedia(BREAKPOINTS.LG)
      ? 1.5
      : 1;

  return cloneElement(children, {
    categories,
    productClickHandler: productClick,
    products: productsToRender,
    title,
    type,
    slidesPerView: slidesPerView,
    sliderReset,
  });
};

ProductRecommendations.displayName = 'containers/ProductRecommendations';
ProductRecommendations.propTypes = {
  categories: PropTypes.object,
  children: PropTypes.node,
  loadRecommendedTopProducts: PropTypes.func,
  hasRecommendedProducts: PropTypes.bool,
  params: PropTypes.object,
  productClick: PropTypes.func,
  recommendedProducts: PropTypes.array,
  title: PropTypes.string,
  trackRecommendedProductsAction: PropTypes.func,
  recoBoxLevel: PropTypes.string,
  currentBasket: PropTypes.string,
  cartEntries: PropTypes.array,
  sliderReset: PropTypes.bool,
  recommendations: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductRecommendations);
