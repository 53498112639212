import {
  UPDATE_GUEST_EMAIL,
  CREATE_USER,
  CHANGE_GUEST,
  UPDATE_PASSWORD_FIELD,
  GET_USER,
  GET_USER_CONSENTS,
  UPDATE_USER_CONSENTS,
  GET_ONLINE_ID,
  UPDATE_USER,
  DEACTIVATE_USER,
  LOGIN_USER,
  CHANGE_LOGIN,
  CHANGE_PASSWORD,
  BLUR_EMAIL_FIELD,
  SUBSCRIBE_NEWSLETTER,
  LOGOUT_USER,
  CHECK_TOKEN,
  CHANGE_PERSONAL_DATA,
  BLUR_PERSONAL_DATA_FIELD,
  FORGOTTEN_PASSWORD_GENERATE_TOKEN,
  FORGOTTEN_PASSWORD_CHECK_TOKEN,
  FORGOTTEN_PASSWORD_RESTORE_PASSWORD,
  INITIALIZE_FORGOTTEN_PASSWORD_GENERATE_TOKEN,
  BLUR_PASSWORD_FIELD,
  RESET_USER,
  CHANGE_CUSTOMER_TYPE,
  DOWNLOAD_PDF,
  SAVE_AS_MY_STORE,
  VERIFY_RECAPTCHA,
  VERIFY_MAIL,
  UPDATE_BIRTHDAY_FIELD,
  BLUR_BIRTHDAY_FIELD,
  RESEND_DOUBLE_OPTIN_MAIL,
  UPDATE_AGB_FIELD,
  REQUEST_ACCOUNT_DELETION,
} from 'constants/ActionTypes/ActionTypes';
import getStorage from 'constants/storage/storage';
import { BIRTHDAY, EMAIL } from 'constants/storage/storageKeys';

import UserAPI from 'api/UserAPI/UserAPI';
import CartAPI from 'api/CartAPI/CartAPI';

const sessionStorage = getStorage(false);

export const rehydrateUserFields = (dispatch) => {
  const email = sessionStorage.getItem(EMAIL);
  const birthday = sessionStorage.getItem(BIRTHDAY);

  if (email) dispatch(updateEmailField(email));
  if (birthday) {
    dispatch(updateBirthdayField(birthday, { useToUpdate: true }));
    // trigger validation
    dispatch(blurBirthdayField({ useToUpdate: true }));
  }
};

const blurEmailField = () => ({
  type: BLUR_EMAIL_FIELD,
});

const blurPasswordField = () => ({
  type: BLUR_PASSWORD_FIELD,
});

const blurBirthdayField = (options) => ({
  type: BLUR_BIRTHDAY_FIELD,
  options,
});

const updateAGBs = () => ({
  type: UPDATE_AGB_FIELD,
});

/**
 * Action creator which sets the password for a user
 * @param value
 */
const updatePasswordField = (value, email) => ({
  type: UPDATE_PASSWORD_FIELD,
  payload: {
    value,
    email,
  },
});

/**
 * Action creator which sets the email address for a guest/anonymous user
 * @param value
 * @param validation
 */
const setEmail = (value, validation) => ({
  type: UPDATE_GUEST_EMAIL,
  payload: {
    value,
    validation,
  },
});

/**
 * Action creator which sets the email for a user
 * @param value
 */
const updateEmailField = (value) => ({
  type: UPDATE_GUEST_EMAIL,
  payload: {
    value,
  },
});

const updateBirthdayField = (value, options) => ({
  type: UPDATE_BIRTHDAY_FIELD,
  payload: {
    value,
  },
  options,
});

/**
 * Action creator which creates a new user
 * @param userData
 */
const createUser = (userData) => ({
  type: CREATE_USER,
  promise: UserAPI.createUser(userData),
});

/**
 * Action creator which creates a new user
 * @param firstName
 * @param lastName
 * @param login
 * @param titleCode
 * @param password
 */
const createUserAfterGuestCheckout = (firstName, lastName, login, titleCode, password) => ({
  type: CREATE_USER,
  promise: UserAPI.createUserAfterGuestCheckout(firstName, lastName, login, titleCode, password),
});

/**
 * Action creator which updates the email address for a guest/anonymous user
 * @param email
 */
const changeGuest = (email) => ({
  type: CHANGE_GUEST,
  promise: CartAPI.changeGuest(email),
});

/**
 * Action creator which returns customer profile
 */
const getUser = () => ({
  type: GET_USER,
  payload: {
    userId: UserAPI.getUserId(),
  },
  promise: UserAPI.getUser(),
});

const getUserConsents = () => ({
  type: GET_USER_CONSENTS,
  payload: {
    userId: UserAPI.getUserId(),
  },
  promise: UserAPI.getUserConsents(),
});

const updateUserConsents = (consents) => ({
  type: UPDATE_USER_CONSENTS,
  payload: {
    userId: UserAPI.getUserId(),
  },
  promise: UserAPI.updateUserConsents(consents),
});

const getOnlineId = () => (dispatch) =>
  dispatch({
    type: GET_ONLINE_ID,
    promise: UserAPI.getUser(),
  }).then(() => dispatch(UserAPI.getOnlineId()));

/**
 * Action creator which updates customer profile
 * @param user
 * @param shouldMap
 */
const updateUser = (user, shouldMap = true) => (dispatch) =>
  dispatch({
    type: UPDATE_USER,
    payload: {},
    promise: UserAPI.putCustomer(user, shouldMap),
  }).then((isSuccess) => {
    if (isSuccess) {
      return dispatch(getUser());
    }
    return false;
  });

/**
 * Action creator which removes customer profile
 */
const deactivateUser = () => ({
  type: DEACTIVATE_USER,
  payload: {},
  promise: UserAPI.deactivateCurrentUser(),
});

const changePersonalData = (key, value) => ({
  type: CHANGE_PERSONAL_DATA,
  payload: {
    key,
    value,
  },
});

const blurPersonalDataField = (key, value) => ({
  type: BLUR_PERSONAL_DATA_FIELD,
  payload: {
    key,
    value,
  },
});

/**
 * Action creator which changes customer's login.
 * @param username (email Adress)
 * @param password
 */
const loginUser = (username, password, keepLogin) => ({
  type: LOGIN_USER,
  payload: {
    username,
  },
  promise: UserAPI.authenticateUser(username, password, keepLogin),
});

/**
 * Changes customer's login email address
 * @param newLogin -> Customer's new login. Customer login is case insensitive.
 * @param password -> Customer's current password.
 */
const changeLogin = (password, newLogin) => ({
  type: CHANGE_LOGIN,
  payload: {},
  promise: UserAPI.changeLogin(password, newLogin),
});

/**
 * Action creator which changes customer's password
 * @param password -> Old Password
 * @param newPassword -> New Password
 */
const changePassword = (password, newPassword) => ({
  type: CHANGE_PASSWORD,
  payload: {},
  promise: UserAPI.changePassword(password, newPassword),
});

/**
 * Action creator which subscribe newsletter for user
 * @param email
 * @param subscribe -> bool if subscribed or not
 */
const subscribeNewsletter = () => ({
  type: SUBSCRIBE_NEWSLETTER,
});

const changeCustomerType = (type) => ({
  type: CHANGE_CUSTOMER_TYPE,
  payload: type,
});

const resetUser = () => ({
  type: RESET_USER,
});

const logoutUser = () => ({
  type: LOGOUT_USER,
  payload: {},
  promise: UserAPI.logout(),
});

const checkToken = () => ({
  type: CHECK_TOKEN,
  promise: UserAPI.checkToken(),
});

const initializeForgottenPasswordTokenSuccess = () => ({
  type: INITIALIZE_FORGOTTEN_PASSWORD_GENERATE_TOKEN,
});

const forgottenPasswordGenerateToken = (email) => ({
  type: FORGOTTEN_PASSWORD_GENERATE_TOKEN,
  payload: {},
  promise: UserAPI.forgottenPasswordTokenGenerate(email),
});

const forgottenPasswordCheckToken = (token) => ({
  type: FORGOTTEN_PASSWORD_CHECK_TOKEN,
  payload: {},
  promise: UserAPI.forgottenPasswordCheckToken(token),
});

const forgottenPasswordRestorePassword = (token, newPassword) => ({
  type: FORGOTTEN_PASSWORD_RESTORE_PASSWORD,
  payload: {},
  promise: UserAPI.forgottenPasswordRestorePassword(token, newPassword),
});

const downloadPdf = (pdfType) => ({
  type: DOWNLOAD_PDF,
  payload: { pdfType },
  promise: UserAPI.downloadPdf(pdfType),
});

const saveAsMyStore = (storeCode) => ({
  type: SAVE_AS_MY_STORE,
  payload: { storeCode },
  promise: UserAPI.saveAsMyStore(storeCode),
});

const verifyRecaptcha = (response, userId, area) => ({
  type: VERIFY_RECAPTCHA,
  promise: UserAPI.verifyRecaptcha(response, userId, area),
});

const verifyEmail = (email) => ({
  type: VERIFY_MAIL,
  promise: UserAPI.verifyEmail(email),
});

export const resendDoubleOptInMail = (email) => ({
  type: RESEND_DOUBLE_OPTIN_MAIL,
  promise: UserAPI.resendDoubleOptInMail(email),
});

const requestAccountDeletion = () => ({
  type: REQUEST_ACCOUNT_DELETION,
  promise: UserAPI.requestAccountDeletion(),
});

const actions = {
  setEmail,
  createUser,
  createUserAfterGuestCheckout,
  blurPasswordField,
  updatePasswordField,
  updateEmailField,
  getUser,
  getUserConsents,
  updateUserConsents,
  getOnlineId,
  updateUser,
  deactivateUser,
  loginUser,
  changeLogin,
  changePassword,
  blurEmailField,
  subscribeNewsletter,
  logoutUser,
  resetUser,
  checkToken,
  changePersonalData,
  blurPersonalDataField,
  forgottenPasswordGenerateToken,
  forgottenPasswordCheckToken,
  forgottenPasswordRestorePassword,
  initializeForgottenPasswordTokenSuccess,
  changeCustomerType,
  downloadPdf,
  changeGuest,
  saveAsMyStore,
  verifyRecaptcha,
  verifyEmail,
  updateBirthdayField,
  blurBirthdayField,
  resendDoubleOptInMail,
  updateAGBs,
  rehydrateUserFields,
  requestAccountDeletion,
};

export default actions;
