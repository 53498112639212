import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, A11y } from 'swiper';
import dataTracking from 'constants/trackingAttributes/dataTracking';
import Icon, { ICON_KEYBOARD_ARROW_LEFT, ICON_KEYBOARD_ARROW_RIGHT } from 'components/atoms/Icon/Icon';
import styles from './Slider.scss';

SwiperCore.use([Navigation, Pagination, A11y]);

const Slider = ({
  allowTouchMove = true,
  autoplay = 0,
  breakpoints = {},
  dynamicBullets = false,
  grabCursor = false,
  id = '',
  navigation = true,
  pagination = true,
  loop = true,
  slides = [],
  slidesPerGroup = 1,
  slidesPerView = 1,
  spaceBetween = 0,
  speed = 300,
  trackingAttributes = {},
  cyData = '',
  sliderReset,
}) => {
  const [swiperInstance, setSwiperInstance] = useState(null);
  const shouldLoop = slides.length <= (swiperInstance?.params?.slidesPerView || slidesPerView) ? false : loop;
  const [prevSlides, setPrevSlides] = useState(slides.length);

  useEffect(() => {
    if (!swiperInstance) {
      return;
    }

    swiperInstance.slideTo(1);
  }, [sliderReset]);

  useEffect(() => {
    if (prevSlides !== slides.length) setPrevSlides(slides.length);
  }, [slides.length]);

  if (prevSlides !== slides.length) return null;

  function swipeRight() {
    if (!swiperInstance) {
      return;
    }

    if (shouldLoop && swiperInstance.isEnd) {
      swiperInstance.slideTo(0)
    } else {
      swiperInstance.slideNext();
    }
  }

  function swipeLeft() {
    if (!swiperInstance) {
      return;
    }

    if (shouldLoop && swiperInstance.isBeginning) {
      swiperInstance.slideTo(slides.length)
    } else {
      swiperInstance.slidePrev();
    }
  }

  return (
    <Swiper
      data-cy={cyData}
      onSwiper={setSwiperInstance}
      className={styles.swiperContainer}
      id={id}
      allowTouchMove={allowTouchMove}
      autoplay={autoplay}
      breakpoints={breakpoints}
      grabCursor={grabCursor}
      slidesPerGroup={slidesPerGroup}
      slidesPerView={slidesPerView}
      spaceBetween={spaceBetween}
      speed={speed}
      pagination={
        pagination && {
          el: '.swiper-pagination',
          clickable: true,
          dynamicBullets,
          renderBullet: (index, className) =>
            `<span
            class="${className}"
            tabindex="0"
            role="button"
            aria-label="Go to slide ${index + 1}"
            data-tracking=${dataTracking({
              // eslint-disable-next-line prefer-template
              TYPE: trackingAttributes.prefix + ':dot',
              INNERLOCATION: index * slidesPerView + 1,
              ATTRIBUTE_1: trackingAttributes.parentTitle && trackingAttributes.parentTitle.replace(/\s|:/g, ''),
              ATTRIBUTE_2: trackingAttributes.tabName && trackingAttributes.tabName.replace(/\s|:/g, ''),
              FUNCTION: 'browse',
            })}
          >
          </span>`,
        }
      }
      watchOverflow
    >
      {slides.map((slide, index) => (
        <SwiperSlide key={index} className={cx(styles.slide, { [styles.ssrSlide]: !__CLIENT__ })}>
          {slide}
        </SwiperSlide>
      ))}
      <div slot="container-end" className={styles.naviContainer}>
        {navigation && (
          <div
            className={cx('swiper-button-prev', styles.swiperButton)}
            onClick={swipeLeft}
            data-tracking={
              trackingAttributes.prefix &&
              dataTracking({
                TYPE: `${trackingAttributes.prefix}:arrow`,
                INNERLOCATION: swiperInstance && swiperInstance.realIndex + 1,
                ATTRIBUTE_1: trackingAttributes.parentTitle && trackingAttributes.parentTitle.replace(/\s|:/g, ''),
                ATTRIBUTE_2: trackingAttributes.tabName && trackingAttributes.tabName.replace(/\s|:/g, ''),
                ATTRIBUTE_3: 'prev',
                FUNCTION: 'browse',
              })
            }
          >
            <Icon path={ICON_KEYBOARD_ARROW_LEFT} />
          </div>
        )}
        {pagination && <div className={cx('swiper-pagination', styles.pagination)} />}
        {navigation && (
          <div
            className={cx('swiper-button-next', styles.swiperButton)}
            onClick={swipeRight}
            data-tracking={
              trackingAttributes.prefix &&
              dataTracking({
                TYPE: `${trackingAttributes.prefix}:arrow`,
                INNERLOCATION: swiperInstance && swiperInstance.realIndex + 1,
                ATTRIBUTE_1: trackingAttributes.parentTitle && trackingAttributes.parentTitle.replace(/\s|:/g, ''),
                ATTRIBUTE_2: trackingAttributes.tabName && trackingAttributes.tabName.replace(/\s|:/g, ''),
                ATTRIBUTE_3: 'next',
                FUNCTION: 'browse',
              })
            }
          >
            <Icon path={ICON_KEYBOARD_ARROW_RIGHT} />
          </div>
        )}
      </div>
    </Swiper>
  );
};

Slider.displayName = 'organisms/Slider';
Slider.propTypes = {
  allowTouchMove: PropTypes.bool,
  autoplay: PropTypes.number,
  breakpoints: PropTypes.object,
  dynamicBullets: PropTypes.bool,
  grabCursor: PropTypes.bool,
  id: PropTypes.string,
  loop: PropTypes.bool,
  navigation: PropTypes.bool,
  pagination: PropTypes.bool,
  slides: PropTypes.array,
  slidesPerGroup: PropTypes.number,
  slidesPerView: PropTypes.number,
  spaceBetween: PropTypes.number,
  speed: PropTypes.number,
  trackingAttributes: PropTypes.object,
  cyData: PropTypes.string,
  sliderReset: PropTypes.bool,
};

export default Slider;
