import React, { useState } from 'react';

import styles from './FacetFilterType.scss';
import cx from 'classnames';
import Icon, { ARROW_DROPDOWN } from 'components/atoms/Icon/Icon';
import PropTypes from 'prop-types';
import FacetTypeSelector from 'components/atoms/FacetFilterType/FacetTypeSelector';
import { FacetPropType, getFilterPreviewText, trackFacetModal } from 'constants/facetsHelper/facetsHelper';

const FacetFilterAccordion = ({
  facet,
  prefetchedFacet,
  totalNumberOfResults,
  handleSliderChange,
  prefetchFilter,
  index,
}) => {
  const [_activeAccordion, setActiveAccordion] = useState(false);
  if (facet.code === 'categoryPath') return null;
  const disabled = !prefetchedFacet || (prefetchedFacet.type !== 'SLIDER' && prefetchedFacet.values?.length < 1);
  const activeAccordion = _activeAccordion && !disabled;

  const toggleAccordion = () => {
    setActiveAccordion(!activeAccordion);
    trackFacetModal(facet.code, index, !activeAccordion);
  };

  return (
    <li className={cx(styles.facetFiltersAccordion, { [styles.facetFiltersAccordionActive]: activeAccordion })}>
      <button onClick={toggleAccordion} disabled={disabled}>
        <div className={styles.facetFiltersHeadline}>
          <div className={styles.facetFiltersTitle}>
            <span>{facet.name}</span>
            {!activeAccordion && (
              <span className={styles.facetFiltersSelected}>
                {getFilterPreviewText(prefetchedFacet || facet, totalNumberOfResults)}
              </span>
            )}
          </div>
          <Icon path={ARROW_DROPDOWN} className={cx(styles.chevron, { [styles.chevronActive]: activeAccordion })} />
        </div>
      </button>

      <div className={cx(styles.facetFiltersAccordionWrapper, { [styles.facetFiltersAccordionOpen]: activeAccordion })}>
        <FacetTypeSelector
          facet={facet}
          prefetchedFacet={prefetchedFacet}
          handleSliderChange={handleSliderChange}
          prefetchFilter={prefetchFilter}
        />
      </div>
    </li>
  );
};

FacetFilterAccordion.propTypes = {
  facet: FacetPropType.isRequired,
  prefetchedFacet: FacetPropType.isRequired,
  totalNumberOfResults: PropTypes.number.isRequired,
  handleSliderChange: PropTypes.func,
  prefetchFilter: PropTypes.func,
  index: PropTypes.number,
};
export default FacetFilterAccordion;
