import {
  RENDER_APP_BAD_REQUEST,
  RENDER_APP_INTERNAL_SERVER_ERROR,
  RENDER_APP_NOT_FOUND,
} from 'constants/ActionTypes/ActionTypes';

export const setBadRequestError = () => ({
  type: RENDER_APP_BAD_REQUEST,
});
export const setInternalError = () => ({
  type: RENDER_APP_INTERNAL_SERVER_ERROR,
});
export const setNotFoundError = () => ({
  type: RENDER_APP_NOT_FOUND,
});

const actions = {
  setNotFoundError,
  setInternalError,
  setBadRequestError,
};

export default actions;
