import { BREAKPOINTS } from 'constants/breakpoints/breakpoints';

const matchMedia = (breakpoint) => {
  if (typeof __CLIENT__ === 'undefined' || !__CLIENT__) {
    return false;
  }

  switch (breakpoint) {
    case BREAKPOINTS.XXS:
      return window.matchMedia('(min-width:0px)').matches;
    case BREAKPOINTS.XS:
      return window.matchMedia('(min-width:360px)').matches;
    case BREAKPOINTS.SM:
      return window.matchMedia('(min-width:544px)').matches;
    case BREAKPOINTS.MD:
      return window.matchMedia('(min-width:768px)').matches;
    case BREAKPOINTS.LG:
      return window.matchMedia('(min-width:992px)').matches;
    case BREAKPOINTS.XL:
      return window.matchMedia('(min-width:1200px)').matches;
    default:
      return false;
  }
};

export default matchMedia;
