import Scroll, { scroller } from 'react-scroll';
import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed';
import env from 'constants/environment/environment';

export const HEADER = 'STICKY_HEADER';
export const DESKTOP_NAVIGATON = 'DESKTOP_NAVIGATON';
export const TOP_OF_PRODUCTS_OVERVIEW = 'TOP_OF_PRODUCTS_OVERVIEW';
export const TOP_OF_PRODUCTS_LIST = 'TOP_OF_PRODUCTS_LIST';
export const TOP_CONTENT_ANCHOR = 'TOP_CONTENT_ANCHOR';
export const TOP_PAGE_ANCHOR = env.REACT_ROOT_ID;
export const TOP_WALL_ANCHOR = 'TOP_WALL_ANCHOR';
export const TOP_RATING_FORM = 'TOP_RATING_FORM';
export const TOP_FEEDBACK_FORM = 'TOP_FEEDBACK_FORM';

export const scrollToElement = (elementToScrollTo, duration = 500, additionalOffset = 0) => {
  if (elementToScrollTo && !__TEST__) {
    const headerElm = document.getElementById(HEADER);
    const headerOffset = headerElm ? headerElm.offsetHeight * -1 : 0;

    const getOffset = () => headerOffset - additionalOffset;

    scroller.scrollTo(elementToScrollTo, {
      duration,
      offset: getOffset(),
    });
  } else {
    if (__DEV__ && !__TEST__) console.warn('elementToScrollTo is undefined'); // eslint-disable-line
  }
};

export const scrollUpToId = (elementId, duration, offset) => {
  const element = document.getElementById(elementId);
  if (element) {
    // only scroll up
    scrollToElement(elementId, duration, offset);
  } else {
    // eslint-disable-next-line
    if (__DEV__ && !__TEST__) console.warn(`Trying to scroll to non-existing element with id="${elementId}"`);
  }
};

/**
  Since we cannot pass an id to react-modal as a prop, we cannot use containerId in react-scroll.
  Even if we could, react-scroll throws an error, and does not scroll the modal:
  https://github.com/fisshy/react-scroll/issues/103

  Instead, for scrolling modals we can use scrollIntoView, although smooth scrolling is often not supported.
*/
export const scrollIntoView = (elementId) => {
  const element = document.getElementById(elementId);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
    });
  } else {
    // eslint-disable-next-line
    if (__DEV__ && !__TEST__) console.warn(`Trying to scroll to non-existing element with id="${elementId}"`);
  }
};

const waitForElementToLoad = (selector) =>
  new Promise((resolve) => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver(() => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
    return null;
  });

export const scrollFirstErrorIntoView = async () => {
  const elementToScrollTo = await waitForElementToLoad('.error-message');
  if (elementToScrollTo) {
    scrollIntoViewIfNeeded(elementToScrollTo.parentElement, {
      behavior: 'smooth',
      scrollMode: 'always',
    });
  }
};

export const scrollToRef = (ref, duration = 250) => {
  if (ref?.current?.offsetTop) {
    Scroll.animateScroll.scrollTo(ref.current.offsetTop, {
      duration,
    });
  }
};

export default scrollToElement;
