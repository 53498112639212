import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import language, { getLocale } from 'constants/language/language';

import { Modal } from '@interdiscount/component-library-interdiscount';

import Headline from 'components/atoms/Headline/Headline';
import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';
import styles from './ModalAlert.scss';

import { cart } from 'constants/routePaths/routePathCombinations';

const propTypes = {
  closeModal: PropTypes.func,
  clearUpdatedCartHistory: PropTypes.func,
  isCartEmpty: PropTypes.bool,
  stockAvailabilityChanged: PropTypes.bool,
};

const defaultProps = {
  closeModal: () => {},
};

const goToCart = () => {
  browserHistory.push(mapPathToLocalizedUrl(getLocale(), cart));
};

export default class ModalAlert extends Component {
  static displayName = 'organisms/ModalAlert';
  static propTypes = propTypes;
  static defaultProps = defaultProps;

  handleCloseModal = () => {
    if (this.props.isCartEmpty || this.props.stockAvailabilityChanged) {
      goToCart();
    } else {
      this.props.closeModal();
    }
  };

  render() {
    return (
      <Modal onRequestClose={this.handleCloseModal} isOpen>
        <ModalContent {...this.props} />
      </Modal>
    );
  }
}

export class ModalContent extends Component {
  // eslint-disable-line react/no-multi-comp
  static propTypes = propTypes;
  static defaultProps = defaultProps;

  handleGoToCart = () => {
    if (this.props.stockAvailabilityChanged) {
      this.props.clearUpdatedCartHistory();
    }
    goToCart();
  };

  render() {
    const { isCartEmpty, stockAvailabilityChanged } = this.props;

    const context = stockAvailabilityChanged ? 'stockUpdated' : 'cartUpdated';

    return (
      <div>
        <div>
          <div className={styles.headlineContainer}>
            <Headline margin={Headline.margins.MB_15}>{language('timeSensitive.modalAlert.headline')}</Headline>
          </div>
          <div className={styles.contentContainer}>
            <span>{language(`timeSensitive.modalAlert.${context}.info`)}</span>
            <span>{language(`timeSensitive.modalAlert.${context}.whatToDo`)}</span>
          </div>
          <div className={styles.buttonContainer}>
            <ButtonLink variety={ButtonLink.varieties.stroked} onClick={this.handleGoToCart} size={ButtonLink.sizes.md}>
              {language('product.goToCart')}
            </ButtonLink>
            {!isCartEmpty ||
              (!stockAvailabilityChanged && (
                <ButtonLink size={ButtonLink.sizes.md} onClick={this.props.closeModal}>
                  {language('cart.goToCart')}
                </ButtonLink>
              ))}
          </div>
        </div>
      </div>
    );
  }
}
