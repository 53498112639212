import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { RATING_COUNT_THRESHOLD, RECOMMENDS_RATE_THRESHOLD } from 'constants/ratings/ratings';
import Headline from 'components/atoms/Headline/Headline';
import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';
import RatingTotal from 'components/molecules/Rating/RatingTotal/RatingTotal';
import RatingOverview from 'components/molecules/Rating/RatingOverview/RatingOverview';
import RatingCommentsContainer from 'containers/RatingCommentsContainer/RatingCommentsContainer';
import language from 'constants/language/language';
import styles from './RatingDetails.scss';

const RatingDetails = ({
  numberOfReviews = 0,
  averageRating = 0,
  averageRecommendation = 0,
  reviewDistribution = {},
  toggleRateEditor,
}) => {
  const highlightRating = numberOfReviews >= RATING_COUNT_THRESHOLD;
  const showRecommendations =
    numberOfReviews >= RATING_COUNT_THRESHOLD && averageRecommendation >= RECOMMENDS_RATE_THRESHOLD;

  return (
    <>
      <div className={styles.header}>
        <Headline margin={Headline.margins.MB_20}>
          {language('productRating.details.title')} ({numberOfReviews})
        </Headline>
        {numberOfReviews === 0 && <p className={styles.countInfo}>{language('productRating.noRatings')}</p>}
        {numberOfReviews > 0 && numberOfReviews < RATING_COUNT_THRESHOLD && (
          <p className={styles.countInfo}>
            {language('productRating.fewRatings')}
            <br />
            <strong>{language('productRating.fewRatingsWarn1')}: </strong>
            {language('productRating.fewRatingsWarn2')}
          </p>
        )}
      </div>
      <div className={styles.wrapper}>
        <div className={styles.box}>
          <RatingTotal
            averageRating={averageRating}
            averageRecommendation={averageRecommendation}
            highlightRating={highlightRating}
            showRecommendations={showRecommendations}
          />
          <div className={cx(styles.button, styles.desktop)}>
            <ButtonLink variety={ButtonLink.varieties.stroked} onClick={toggleRateEditor}>
              {language('productRating.total.btnDefault')}
            </ButtonLink>
          </div>
        </div>
        {numberOfReviews > 0 && (
          <div className={styles.box}>
            <RatingOverview reviewDistribution={reviewDistribution} />
          </div>
        )}
      </div>
      <div className={cx(styles.button, styles.mobile)}>
        <ButtonLink variety={ButtonLink.varieties.stroked} onClick={toggleRateEditor}>
          {language('productRating.total.btnDefault')}
        </ButtonLink>
      </div>
      <RatingCommentsContainer toggleRateEditor={toggleRateEditor} />
    </>
  );
};

RatingDetails.propTypes = {
  numberOfReviews: PropTypes.number,
  averageRating: PropTypes.number,
  averageRecommendation: PropTypes.number,
  reviewDistribution: PropTypes.object,
  toggleRateEditor: PropTypes.func,
};

export default RatingDetails;
