import React from 'react';

import WallOfAllTemplate from 'components/templates/WallOfAll/WallOfAll';

import { getWallOfAllContentNeed } from 'actions/cmsActions/cmsActions';

const WallOfAll = () => <WallOfAllTemplate />;

WallOfAll.displayName = 'routes/WallOfAll';

WallOfAll.need = [getWallOfAllContentNeed];

export default WallOfAll;
