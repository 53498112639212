import { useEffect, useState } from 'react';
import { browserHistory } from 'react-router';
import getUrlForProduct from 'constants/getUrlForProduct/getUrlForProduct';
import { getLocale } from 'constants/language/language';
import { getSearchUrl } from 'constants/urlHelper/urlHelper';
import { provideSearchLink } from 'constants/navigation/navigation';
import useFeatureToggle from 'hooks/useFeatureToggle';

const useSearchKeyboardNavigation = (clearProductSuggestions, closeSearch, setSearchString, addHistoryEntry) => {
  const nextSearchEnabled = useFeatureToggle('nextSearchEnabled') === 'true' ?? false;
  const [activeIndex, setActiveIndex] = useState(-1);
  const [linkSuggestions, setLinkSuggestions] = useState([]);
  const [termSuggestions, setTermSuggestions] = useState([]);
  const [productSuggestions, setProductSuggestions] = useState([]);
  const suggestionsLength = termSuggestions.length + linkSuggestions.length;

  const handleKeyboardNavigation = (e) => {
    const keyCode = e.keyCode;
    if ([27, 37, 38, 39, 40].includes(keyCode)) {
      // When index is -1 the focus is on the input field, so we want to allow
      // cursor movement or when user types up
      if (activeIndex >= 0 || keyCode === 38) {
        e.preventDefault();
      }
      (commands[keyCode] || (() => {}))();
    }
    // keyCode 13 = Enter
    if (keyCode === 13 && activeIndex >= 0) {
      e.preventDefault();
      handleConfirmKeyDown();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyboardNavigation);
    return () => {
      window.removeEventListener('keydown', handleKeyboardNavigation);
    };
  }, [handleKeyboardNavigation]);

  const deselect = () => {
    if (activeIndex >= 0) {
      setActiveIndex(-1);
    } else {
      clearProductSuggestions();
    }
  };

  const switchSides = () => {
    if (activeIndex >= 0) {
      setActiveIndex(activeIndex < suggestionsLength ? suggestionsLength : 0);
    }
  };

  const increaseIndex = () => {
    setActiveIndex(activeIndex >= 0 ? activeIndex - 1 : suggestionsLength + productSuggestions.length - 1);
  };
  const decreaseIndex = () => {
    setActiveIndex(activeIndex < suggestionsLength + productSuggestions.length - 1 ? activeIndex + 1 : -1);
  };

  const commands = {
    27: deselect, // Escape key
    37: switchSides, // left key
    39: switchSides, // right key
    38: increaseIndex, // down key
    40: decreaseIndex, // up key
  };

  const handleConfirmKeyDown = () => {
    const suggestions = [...termSuggestions, ...linkSuggestions];

    if (activeIndex >= 0 && activeIndex < suggestions.length + productSuggestions.length) {
      if (nextSearchEnabled) {
        const url = `/${getLocale()}/search?q=${suggestions[activeIndex].toLowerCase()}`;
        window.location.href = url;
        closeSearch();
        return;
      }
      if (activeIndex >= suggestions.length) {
        const activeProduct = productSuggestions[activeIndex - suggestions.length];
        const { categoryCode, name, code } = activeProduct;
        browserHistory.push(getUrlForProduct(categoryCode, name, code, getLocale()));
      } else if (activeIndex >= termSuggestions.length) {
        // a brand/cms page is selected
        let link = suggestions[activeIndex];
        addHistoryEntry(link.text, link.url);
        browserHistory.push(suggestions[activeIndex].url.toLowerCase());
      } else {
        // a searchSuggestion is selected

        const searchTerm = suggestions[activeIndex];
        setSearchString(searchTerm);
        const { url } = provideSearchLink(searchTerm);
        addHistoryEntry(searchTerm, url);
        browserHistory.push({
          pathname: getSearchUrl(getLocale()),
          query: { search: searchTerm },
        });
      }
      closeSearch();
    }
  };

  return [
    activeIndex,
    {
      setLinkSuggestions: setLinkSuggestions,
      setTermSuggestions: setTermSuggestions,
      setProductSuggestions: setProductSuggestions,
    },
  ];
};

export default useSearchKeyboardNavigation;
