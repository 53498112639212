import React from 'react';
import PropTypes from 'prop-types';
import CmsContent, { contentTypes } from 'containers/CmsContent/CmsContent';
import { get } from 'lodash/object';
import { CmsSelectors } from 'reducers/cms/cms';
import { connect } from 'react-redux';
import isSmartEditMode from 'constants/smartedit/isSmartEditMode';
import withSmartEdit from 'containers/SmartEditContainer/withSmartEdit';

const mapStateToProps = (state) => ({ page: CmsSelectors.getCurrentPage(state) });

const CmsSlot = (props) => {
  const { contentId, hasSidebar, page, position } = props;

  const slot = get(page, position);

  const children = (
    <CmsContent position={position} contentId={contentId} contentType={contentTypes.PAGES} fullWidth={!hasSidebar} />
  );

  if (isSmartEditMode(slot)) {
    const SmartEdit = withSmartEdit(slot.smartedit);
    return <SmartEdit>{children}</SmartEdit>;
  }

  return children;
};

CmsSlot.displayName = 'organisms/CmsSlot';

CmsSlot.propTypes = {
  contentId: PropTypes.string,
  hasSidebar: PropTypes.bool,
  page: PropTypes.object,
  position: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(CmsSlot);
