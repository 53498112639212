/**
 * Created by michaels on 07.04.16.
 */
import sortBy from 'constants/helper/sortBy';
import pickBy from 'constants/helper/pickBy';

import { sanitizeUrlString } from 'constants/urlMapping/urlMapping';

import CategoryAPI from 'api/CategoryAPI/CategoryAPI';

export const getCategories = () => CategoryAPI.provideInitialState();

/**
 * Returns the whole categoryTree.
 *
 * @returns array with objects inside;
 */
export const categoryTree = (() => {
  let currentCategoryTree = getCategories();

  return {
    get: () => currentCategoryTree,
    set: (externalCategoryTree) => {
      currentCategoryTree = externalCategoryTree;
    },
    reinitialize: () => {
      currentCategoryTree = {};
    },
  };
})();

export const buildCategoryPath = (categories = [], categoryCode, withAppendedCategoryCode = true) => {
  const appendix = withAppendedCategoryCode ? `--c${categoryCode}` : '';
  return `/${categories.join('/')}${appendix}`;
};

export const getCategoriesPath = (categoryCode, categories = {}) => {
  const category = categories[categoryCode];
  if (category) {
    return category.parent !== '1' ? [...getCategoriesPath(category.parent, categories), categoryCode] : [categoryCode];
  }

  return [];
};

export const getStringCategoriesName = (categoryCode, locale, categories = {}) => {
  const category = categories[categoryCode];

  if (category && category[locale]) {
    return category[locale];
  }

  return '';
};

// FOSS-115: newURLStructure was added to have the possibility of a shorter URL structure
export const getStringCategoriesPath = (categoryCode, locale, categories = {}, newURLStructure = true) => {
  const category = categories[categoryCode];

  if (category) {
    // if current category supports new URL OR parent category has no parent -> return just this category
    if ((category.newURL && newURLStructure) || category.parent === '1') {
      return [sanitizeUrlString(category[locale])];
    } else {
      // if current category does not support new URL, iterate over all other categories and support the old pattern
      return [
        ...getStringCategoriesPath(category.parent, locale, categories, false),
        sanitizeUrlString(category[locale]),
      ];
    }
  }
  return [];
};

/**
 * Returns the given List sorted by the "priority"-key.
 *
 * @param categories - the categories (default: categoryTree)
 * @param filter - map (default: {level: 0, parent: "100"})
 */
export const getCategoryMapByPriority = (categories, filter = { level: 1, parent: '1' }) =>
  Object.entries(pickBy(categories, (cat) => !!Object.keys(filter).filter((key) => cat[key] === filter[key]).length))
    .map(([key, category]) => ({
      id: key,
      priority: category.priority,
    }))
    .sort(sortBy('priority'));

export const getCategoryMapByPriority2 = (categories, filter = { level: 1, parent: '2' }) => {
  return Object.entries(
    pickBy(categories, (cat) =>
      Object.keys(filter)
        .map((key) => cat[key] === filter[key])
        .every((value) => value)
    )
  )
    .map(([key, category]) => ({
      id: key,
      priority: category.priority,
    }))
    .sort(sortBy('priority'));
};

/**
 * Returns categoryTree-values filtered by the given parent sorted by their "priority"-key.
 *
 * @param parent - the category-Id (eg: '100')
 */
export const getCategoryMapByParent = (parent = '1') => getCategoryMapByPriority(categoryTree.get(), { parent });

/**
 * Delivers an url to the given category-code with an i18n-representation.
 *
 * @param locale
 * @param categoryCode - a specific category-code
 * @returns the string-link containing all translated categories (e.g. First-Level/Second-Level/Thrid-Level-C11400)
 */
export const getCategoryUrl = (categoryCode, locale, categories) => {
  const catPaths = getStringCategoriesPath(categoryCode, locale, categories);
  return `/${locale}${buildCategoryPath(catPaths, categoryCode, true)}`;
};

export const getCategoryLink = (categoryCode, locale, categories) => ({
  url: getCategoryUrl(categoryCode, locale, categories),
  text: getStringCategoriesName(categoryCode, locale, categories),
});

export const hasNewCategoryLevels = (categories, testCategories) => {
  return testCategories.map((elem) => {
    return Object.values(categories).some((el) => {
      return +el.parent === +elem.id;
    });
  });
};

export const getPDPCategoryPath = (breadcrumb) => {
  const breadcrumbArray = [];
  breadcrumb.map((elem) => {
    const { href } = elem;

    if (href?.includes('--c')) {
      breadcrumbArray.push(href.split('--c')[1]);
    }

    return;
  });

  return breadcrumbArray;
};

export default categoryTree.get();
