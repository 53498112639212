import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import cx from 'classnames';
import { useWindowResize } from 'hooks';
import Select from 'components/molecules/Select/Select';
import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';
import { getLocale } from 'constants/language/language';
import { getProductLink } from 'constants/getUrlForProduct/getUrlForProduct';
import styles from './ProductVariant.scss';

const ProductVariant = ({ name, options, currentProduct }) => {
  const [showSelect, setShowSelect] = useState(false);
  const variantRef = useRef(null);
  const optionsRef = useRef(null);
  useWindowResize(() => {
    if (variantRef.current.offsetWidth === optionsRef.current.offsetWidth) {
      setShowSelect(true);
    } else {
      setShowSelect(false);
    }
  }, [currentProduct.code, variantRef, optionsRef]);

  const clickHandler = (index) => {
    const option = options[index];
    const productCode = option.product.code;
    const productName = option.product.name;
    const url = getProductLink(
      currentProduct.categoryCode,
      productName,
      productCode,
      getLocale(),
      currentProduct.categories
    );
    browserHistory.push({ state: { disableScroll: true }, pathname: url });
  };

  // Don't render variants if not a client, to prevent jumping from buttons to select
  if (!__CLIENT__) {
    return null;
  }

  return (
    <div className={styles.variant} ref={variantRef}>
      <b className={styles.title}>{name}</b>
      <div className={cx(styles.options, { [styles.optionsSelect]: showSelect })} ref={optionsRef}>
        {showSelect ? (
          <Select
            options={options.map((option) => option.value)}
            optionsActiveIndex={options.findIndex((option) => option.selected)}
            optionsClickHandler={clickHandler}
          />
        ) : (
          options.map((option, index) => (
            <div key={option.product.code} className={styles.button}>
              <ButtonLink
                variety={ButtonLink.varieties.stroked}
                size={ButtonLink.sizes.sm}
                color={ButtonLink.colors.red}
                selected={option.selected}
                onClick={() => clickHandler(index)}
              >
                {option.value}
              </ButtonLink>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

ProductVariant.displayName = 'molecules/ProductVariant';
ProductVariant.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      product: PropTypes.shape({
        code: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired,
      selected: PropTypes.bool,
    })
  ).isRequired,
  currentProduct: PropTypes.shape({
    code: PropTypes.string.isRequired,
    categoryCode: PropTypes.string.isRequired,
    categories: PropTypes.object.isRequired,
  }).isRequired,
};

export default ProductVariant;
