import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Headline from 'components/atoms/Headline/Headline';
import InternalOrExternalLink from 'components/atoms/InternalOrExternalLink/InternalOrExternalLink';

import styles from './TeaserContent.scss';

const TeaserContent = ({ icon, title, text, link, openNewTab }) => (
  <div>
    {link ? (
      <InternalOrExternalLink
        className={cx(styles.container, styles.containerLink)}
        href={link}
        openNewTab={openNewTab}
      >
        <div className={styles.contentHeader}>
          <div className={styles.iconContainer}>
            <img className={styles.icon} src={icon} alt={title} />
          </div>
          <div className={styles.headlineContainer}>
            <Headline tag={Headline.tags.H4} margin={Headline.margins.NONE}>
              {title}
            </Headline>
          </div>
        </div>
        <div className={styles.contentBox}>
          <p className={styles.text}>{text}</p>
        </div>
      </InternalOrExternalLink>
    ) : (
      <div className={styles.container}>
        <div className={styles.contentHeader}>
          <div className={styles.iconContainer}>
            <img className={styles.icon} src={icon} alt={title} />
          </div>
          <div className={styles.headlineContainer}>
            <Headline margin={Headline.margins.NONE}>{title}</Headline>
          </div>
        </div>
        <div className={styles.contentBox}>
          <p className={styles.text}>{text}</p>
        </div>
      </div>
    )}
  </div>
);

TeaserContent.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
  openNewTab: PropTypes.bool,
};

TeaserContent.defaultProps = {
  title: '',
  text: '',
  link: '',
  openNewTab: false,
};

TeaserContent.displayName = 'molecules/TeaserContent';

export default TeaserContent;
