import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import language from 'constants/language/language';
import { CMS_UID_TERMS } from 'constants/cms/cms';
import { apiStatus } from 'constants/apiStatus/apiStatus';
import { ValidationTypes } from 'constants/validation/validation';
import CmsLink from 'containers/CmsLink/CmsLink';
import IconTextCombi from 'components/molecules/IconTextCombi/IconTextCombi';
import FieldSetInput from 'components/molecules/FieldSetInput/FieldSetInput';
import SpinnerOverlay from 'components/molecules/SpinnerOverlay/SpinnerOverlay';
import PasswordCreation from 'components/molecules/PasswordCreation/PasswordCreation';
import Headline from 'components/atoms/Headline/Headline';
import InputText from 'components/atoms/InputText/InputText';
import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';
import CmsLinkItem from 'components/atoms/CmsLinkItem/CmsLinkItem';
import styles from './GuestAdvice.scss';
import bootstrap from 'scss/component.scss';

const GuestAdvice = ({ user, createUserAfterGuestCheckout, updatePasswordField, email }, props) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (props.apiStatus === apiStatus.request) {
      setShowSpinner(true);
    } else {
      setShowSpinner(false);
    }
  }, [props.apiStatus]);

  const checkPassword = (validationResult) => {
    if ([ValidationTypes.PasswordGood, ValidationTypes.PasswordVeryGood].includes(validationResult)) {
      createUserAfterGuestCheckout();
    } else {
      setShowError(true);
    }
  };

  return (
    <div>
      <div className={styles.section}>{/* * displays only section box */}</div>
      <div className={cx(bootstrap.container, styles.guestAdvice)}>
        <div className={bootstrap.row}>
          <div className={cx(bootstrap.colMd12, bootstrap.colLg8, bootstrap.offsetLg2)}>
            <Headline>{language('checkoutSuccess.guestSubHeader')}</Headline>
            <p className={styles.guestAdviceBold}>{language('checkoutSuccess.guestAdvice')}</p>
            <p className={styles.agbInfo}>
              {language('formRegistration.checkboxAGB.pre')}
              <CmsLink pageId={CMS_UID_TERMS}>
                <CmsLinkItem customText={language('formRegistration.checkboxAGB.agb')} target="_blank" />
              </CmsLink>
              {language('formRegistration.checkboxAGB.connect')}
              <CmsLink pageId={CMS_UID_TERMS}>
                <CmsLinkItem
                  customText={language('formRegistration.checkboxAGB.number')}
                  target="_blank"
                  anchor={language('formRegistration.checkboxAGB.anchorForDSG')}
                />
              </CmsLink>
              *
            </p>
          </div>
        </div>
        {user?.fields?.password && (
          <div className={styles.inputContainer}>
            <div className={bootstrap.row}>
              <div className={cx(bootstrap.colMd6, bootstrap.colLg4, bootstrap.offsetLg2)}>
                <FieldSetInput forceValidation={showError} disableMessage {...user.fields.password}>
                  <PasswordCreation>
                    <InputText handleChange={(value) => updatePasswordField(value, email)} />
                  </PasswordCreation>
                </FieldSetInput>
              </div>
              <div className={cx(bootstrap.colMd6, bootstrap.colLg4, styles.btnContainer)}>
                <ButtonLink
                  size={ButtonLink.sizes.md}
                  variety={ButtonLink.varieties.flat}
                  onClick={() => {
                    checkPassword(user.fields.password.validationResult);
                  }}
                >
                  {language('checkoutSuccess.createAccount')}
                </ButtonLink>
              </div>
            </div>
          </div>
        )}
        <IconTextCombi />
        {showSpinner && <SpinnerOverlay />}
      </div>
    </div>
  );
};

GuestAdvice.displayName = 'organisms/GuestAdvice';
GuestAdvice.propTypes = {
  user: PropTypes.object,
  createUserAfterGuestCheckout: PropTypes.func,
  updatePasswordField: PropTypes.func,
  apiStatus: PropTypes.string,
  email: PropTypes.string,
};

export default GuestAdvice;
