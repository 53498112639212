import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './BannerFlag.scss';

const BannerFlag = ({ className = '', text = '', title }) => (
  <div className={cx(className, styles.wrapper)}>
    <div className={styles.flagBox}>
      <div className={styles.flagTitle}>{title}</div>
      {text && <p className={styles.flagText}>{text}</p>}
    </div>
  </div>
);

BannerFlag.displayName = 'atoms/BannerFlag';
BannerFlag.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
};

export default BannerFlag;
