import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Icon, { ICON_CLOSE } from 'components/atoms/Icon/Icon';
import { Container } from 'components/atoms/Grid/Grid';
import GalleryCarousel from 'components/molecules/GalleryCarousel/GalleryCarousel';
import Thumbnails from 'components/molecules/Thumbnails/Thumbnails';

import language from 'constants/language/language';

import styles from './LightBoxGallery.scss';

const LightBoxGallery = ({
  galleryData = [],
  activeSlide = 1,
  goToSlide = () => {},
  closeModal = () => {},
  trackingAttributes = {},
}) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    window.addEventListener('keydown', keyHandler);
    window.addEventListener('resize', resizeHandler);
    // used to get the actual window height respecting the address bar and bottom bar in safari
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    setIsLoaded(true);

    return () => {
      window.removeEventListener('keydown', keyHandler);
      window.removeEventListener('resize', resizeHandler);
    };
  });

  const keyHandler = (e) => {
    if (e.key === 'Escape') closeModal();
  };

  const resizeHandler = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  return (
    <div className={styles.modal}>
      <Container className={styles.modalWrapper}>
        <div className={styles.closeWrapper}>
          <div onClick={closeModal} className={styles.closeButton}>
            <span className={styles.closeText}>{language('modal.close')}</span>
            <Icon path={ICON_CLOSE} />
          </div>
        </div>
        <div className={styles.galleryWrapper}>
          <div className={styles.galleryRelative}>
            {isLoaded && (
              <GalleryCarousel
                galleryData={galleryData}
                activeSlide={activeSlide}
                goToSlide={goToSlide}
                isLightBoxModal
                trackingAttributes={trackingAttributes}
              />
            )}
          </div>
        </div>
        <Thumbnails activeSlide={activeSlide} goToSlide={goToSlide} galleryData={galleryData} isLightBoxModal />
      </Container>
    </div>
  );
};

LightBoxGallery.displayName = 'molecules/LightBoxGallery';
LightBoxGallery.propTypes = {
  galleryData: PropTypes.array,
  activeSlide: PropTypes.number,
  goToSlide: PropTypes.func,
  closeModal: PropTypes.func,
  trackingAttributes: PropTypes.object,
};

export default LightBoxGallery;
