import React from 'react';
import PropTypes from 'prop-types';
import language from 'constants/language/language';
import { noop } from 'constants/noop/noop';
import { Row, Col } from 'components/atoms/Grid/Grid';
import ContactField from 'containers/ContactField/ContactField';
import FieldSetInput from 'components/molecules/FieldSetInput/FieldSetInput';
import InputRadioList from 'components/molecules/InputRadioList/InputRadioList';
import Headline from 'components/atoms/Headline/Headline';
import InputText from 'components/atoms/InputText/InputText';
import InputSelect from 'components/atoms/InputSelect/InputSelect';
import InputTextarea from 'components/atoms/InputTextarea/InputTextarea';
import AddressFormPartial from './Contact.form.address.part';
import ContactDetailsPartial from './Contact.form.contactDetail.part';

import styles from './Contact.scss';
import { messageMaxLength } from 'constants/contact/contact.helper';

const FormAddress = ({ allowOnlyNumbers = noop, telNumberValidateChar = noop }) => (
  <>
    <Row>
      <Col>
        <Headline margin={Headline.margins.MB_15}>{language('service.contact.fieldsetHeadlineAddress')}</Headline>
      </Col>
    </Row>
    <Row>
      <Col>
        <ContactField fieldName="address_salutation">
          <FieldSetInput>
            <InputRadioList inline />
          </FieldSetInput>
        </ContactField>
      </Col>
    </Row>
    <Row>
      <Col sm={12} md={8}>
        <ContactField fieldName="address_firstName">
          <FieldSetInput>
            <InputText />
          </FieldSetInput>
        </ContactField>
      </Col>
      <Col sm={12} md={8}>
        <ContactField fieldName="address_lastName">
          <FieldSetInput>
            <InputText />
          </FieldSetInput>
        </ContactField>
      </Col>
    </Row>
    <Row>
      <Col sm={12} md={8}>
        <ContactField fieldName="address_addressLine1">
          <FieldSetInput>
            <InputText />
          </FieldSetInput>
        </ContactField>
      </Col>
      <Col sm={12} md={8}>
        <ContactField fieldName="address_addressLine2">
          <FieldSetInput>
            <InputText />
          </FieldSetInput>
        </ContactField>
      </Col>
    </Row>
    <Row>
      <Col sm={12} md={8}>
        <ContactField fieldName="address_postalCode">
          <FieldSetInput>
            <InputText type="tel" pattern="\d*" maxLength={4} onKeyPress={allowOnlyNumbers} />
          </FieldSetInput>
        </ContactField>
      </Col>
      <Col sm={12} md={8}>
        <ContactField fieldName="address_town">
          <FieldSetInput>
            <InputText />
          </FieldSetInput>
        </ContactField>
      </Col>
    </Row>
    <Row>
      <Col sm={12} md={8}>
        <ContactField fieldName="address_country">
          <FieldSetInput>
            <InputSelect />
          </FieldSetInput>
        </ContactField>
      </Col>
    </Row>
    <ContactDetailsPartial telNumberValidateChar={telNumberValidateChar} />
    <Row>
      <Col sm={12} md={8}>
        <hr />
      </Col>
    </Row>
    <Row>
      <Col sm={12} md={8} className={styles.sectionTwo}>
        <Headline margin={Headline.margins.MB_15}>
          {language('service.contact.additionalFieldsetHeadlineAddress')}
        </Headline>
      </Col>
    </Row>
    <AddressFormPartial allowOnlyNumbers={allowOnlyNumbers} />
    <Row>
      <Col sm={12} md={8}>
        <ContactField fieldName="contactTextarea">
          <FieldSetInput>
            <InputTextarea maxLength={messageMaxLength} />
          </FieldSetInput>
        </ContactField>
      </Col>
    </Row>
  </>
);

FormAddress.propTypes = {
  allowOnlyNumbers: PropTypes.func,
  telNumberValidateChar: PropTypes.func,
};

export default FormAddress;
