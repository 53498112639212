// Use getUserAgent() to ensure the userAgent is available in SSR
let userAgent = '';
export const getUserAgent = () => userAgent;
export const setUserAgent = (ua = '') => {
  userAgent = ua;
};

export const isIPad = () => /iPad/.test(getUserAgent());

export const isAndroid = () => /Android/.test(getUserAgent());

export const isIE11 = () => /MSIE|Trident/.test(getUserAgent());
