import checkoutRoutes from 'routes/checkout/checkout.asyncRoutes';
import accountRoutes from 'routes/account/account.asyncRoutes';
import newsletterRoutes from 'routes/Newsletter/newsletter.asyncRoutes';
import watchlistRoutes from 'routes/Watchlist/watchlist.asyncRoutes';
import productComparisonRoutes from 'routes/ProductComparison/productComparison.asyncRoutes';
import optinRoutes from 'routes/OptIn/optin.asyncRoutes';
import routesCreator from 'routes/routesCreator';
import smartEdit from 'routes/SmartEdit/smartedit.asyncRoutes';

export const getRoutePaths = () => ({
  checkoutRoutes,
  accountRoutes,
  newsletterRoutes,
  watchlistRoutes,
  productComparisonRoutes,
  optinRoutes,
  smartEdit,
});

export default routesCreator(getRoutePaths());
