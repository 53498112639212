import getTemporaryStorage from 'constants/storage/temporaryStorage';

const temporaryCookieStorage = getTemporaryStorage(false, true);

const setCookie = (name, value, expiresInDays, domain?) => {
  const cookieDomain = window.location.hostname !== 'localhost' && domain ? `domain=${domain};` : '';

  try {
    if (!expiresInDays) {
      // session cookie
      document.cookie = `${name}=${value};${cookieDomain} path=/`;
    } else {
      const d = new Date();
      d.setTime(d.getTime() + expiresInDays * 24 * 60 * 60 * 1000);
      document.cookie = `${name}=${value};expires=${d.toUTCString()};${cookieDomain}path=/`;
    }
  } catch (error) {
    console.error(error); // eslint-disable-line
  }
};

export const getCookie = (name, cookies = document.cookie) => {
  try {
    const escapeText = (s) => s.replace(/([.*+?^${}()|[\]/\\])/g, '\\$1');
    const match = cookies.match(RegExp(`(?:^|;\\s*)${escapeText(name)}=([^;]*)`));
    return match ? match[1] : null;
  } catch (error) {
    console.error(error); // eslint-disable-line
    return null;
  }
};

const deleteCookie = (name) => {
  try {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/`;
  } catch (error) {
    console.error(error); // eslint-disable-line
  }
};

const deleteAllCookies = () => {
  try {
    document.cookie.split(';').forEach((c) => {
      document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/'); // eslint-disable-line
    });
  } catch (error) {
    console.error(error); // eslint-disable-line
  }
};

const areCookiesEnabled = () => {
  try {
    if (!__CLIENT__) return false; // don't even try to use cookies on the server
    setCookie('testCookie', 'testValue');
    const result = getCookie('testCookie');
    deleteCookie('testCookie');
    return result === 'testValue';
  } catch (error) {
    return false;
  }
};

export const cookiesEnabled = areCookiesEnabled();

const getCookieStorage = () => {
  const isTemporary = __TEST__ || !__CLIENT__ || !cookiesEnabled;

  return {
    setItem(key, value, expiresInDays, domain?) {
      if (isTemporary) temporaryCookieStorage.setItem(key, value);
      else setCookie(key, value, expiresInDays, domain);
    },
    getItem(key) {
      if (isTemporary) return temporaryCookieStorage.getItem(key);
      return getCookie(key);
    },
    removeItem(key) {
      if (isTemporary) temporaryCookieStorage.removeItem(key);
      else deleteCookie(key);
    },
    clear() {
      if (isTemporary) temporaryCookieStorage.clear();
      else deleteAllCookies();
    },
  };
};

export default getCookieStorage;
