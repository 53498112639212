import React from 'react';
import PropTypes from 'prop-types';
import styles from './CartIndicator.scss';

export const skins = {
  DEFAULT: 'DEFAULT',
  MENU: 'MENU',
};

const CartIndicator = ({ counter = 0, skin = skins.DEFAULT }) => (
  <div className={styles[skin]}>
    <small className={styles.countWrapper}>{counter}</small>
  </div>
);

CartIndicator.propTypes = {
  counter: PropTypes.number,
  skin: PropTypes.oneOf(Object.values(skins)),
};

CartIndicator.displayName = 'molecules/CartIndicator';

export default CartIndicator;
