import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { usePrevious } from 'hooks';

import styles from './ProductInteractionButton.scss';

export function ProductInteractionButton({ isAdded = false, onClick, children, className, cyData, isDisabled }) {
  const isPreviouslyAdded = usePrevious(isAdded);

  return (
    <button
      onClick={onClick}
      disabled={isDisabled}
      className={cx(className, styles.productInteractionButton, { [styles.pulse]: !isPreviouslyAdded && isAdded })}
      data-cy={cyData}
    >
      {children}
    </button>
  );
}

ProductInteractionButton.propTypes = {
  productCode: PropTypes.string,
  onClick: PropTypes.func,
  isAdded: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  cyData: PropTypes.string,
  isDisabled: PropTypes.bool,
};
ProductInteractionButton.displayName = 'molecules/ProductInteractionButton';
