import language from 'constants/language/language';
import { getDayDifference, getProductDateDayDifference } from 'constants/date/date';
import { deliveryModes } from 'constants/deliveryModes/deliveryModes';

// Helper function to get label
const getLabel = (deliveryMethod, key, replacement = {}) => {
  const labelKey = `deliveryStates.detail.${
    deliveryMethod === deliveryModes.homeDelivery ? 'delivered' : 'pickUp'
  }${key}`;
  let label = language(labelKey);
  for (const [placeholder, value] of Object.entries(replacement)) {
    label = label.replace(`{${placeholder}}`, value);
  }
  return label;
};

export const getDeliveryLabel = (nowDate, endDate, deliveryMethod) => {
  const unknownDeliveryStateLabel = language('deliveryStates.detail.deliveredUnknown');

  if (!endDate || !deliveryMethod) return unknownDeliveryStateLabel;

  const { value, valueTo, futureValue } = endDate;
  if (!value || !futureValue) return unknownDeliveryStateLabel;

  const dayDifferenceData = getProductDateDayDifference(nowDate, endDate);
  const dayDifference = dayDifferenceData?.dayDifference || null;
  const weeksFrom = Math.floor(getDayDifference(new Date(nowDate), new Date(value)) / 7 + 1);
  const weeksTo = Math.floor(getDayDifference(new Date(nowDate), new Date(valueTo || futureValue)) / 7 + 1);
  const weekday = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'][
    new Date(value).getDay()
  ];

  const labelKeys = [
    { condition: dayDifference === 0, key: 'Today' },
    { condition: dayDifference === 1, key: 'Tomorrow' },
    {
      condition: dayDifference === 2,
      key: 'WeekDay',
      replacement: { WEEKDAY: language(`deliveryStates.detail.${weekday}`) },
    },
    { condition: dayDifference === 3, key: 'Day', replacement: { DAY: dayDifference } },
    { condition: dayDifference === 4, key: 'WithinDay', replacement: { DAY: dayDifference } },
    {
      condition: dayDifference <= 14,
      key: weeksFrom === weeksTo ? 'WithinDate' : 'BetweenDate',
      replacement: { WEEKSFROM: weeksFrom, WEEKSTO: weeksTo },
    },
    {
      condition: dayDifference >= 15 && dayDifference < 53,
      key: weeksFrom === weeksTo ? 'WithinDates' : 'BetweenDates',
      replacement: { WEEKSFROM: weeksFrom, WEEKSTO: weeksTo },
    },
    { condition: dayDifference >= 53 && dayDifference <= 179, key: 'WithinEightWeeks' },
  ];

  for (const { condition, key, replacement } of labelKeys) {
    if (condition) return getLabel(deliveryMethod, key, replacement);
  }

  return unknownDeliveryStateLabel;
};
