import uuid from 'uuid/v4';
import { postLog } from 'api/promiseMiddleware';
import config from './tracking.config';
import { getLocale } from 'constants/language/language';
import { MFGROUP_UUID, ORDER_CODE, RECO_COOKIE, TEMP_CART, TEMP_PRODUCTS } from 'constants/storage/storageKeys';
import getStorage from 'constants/storage/storage';
import { addressTypes } from 'constants/addresses/addresses';
import {
  addUtagDataFormError,
  addUtagDataRegistration,
  addUtagDataRegistrationPage,
  createCategoryUtagData,
  createUtagDataAccessoriesPopupHead,
  createUtagDataAccessoriesProducts,
  createUtagDataAddService,
  createUtagDataAddToCart,
  createUtagDataBody,
  createUtagDataCart,
  createUtagDataCheckout,
  createUtagDataCheckoutLogin,
  createUtagDataClickOnCms,
  createUtagDataClickOnProduct,
  createUtagDataCmsPageViewPromise,
  createUtagDataCmsProducts,
  createUtagDataComparisonProducts,
  createUtagDataDsgvConsents,
  createUtagDataDsvgConsentsEvent,
  createUtagDataGenericInteraction,
  createUtagDataHead,
  createUtagDataNavigationElement,
  createUtagDataProductDetailPromise,
  createUtagDataProductImpressionsHead,
  createUtagDataProductOverview,
  createUtagDataPurchase,
  createUtagDataRecoProduct,
  createUtagDataRemoveFromCart,
  createUtagDataRemoveService,
  createUtagDataWatchlistProducts,
  createUtagExitCookieBanner,
  createUtagMobileNavigationGeneric,
  createUtagMoreInfoCookieBanner,
  createUtagSearch,
  createUtagSearchAsYouType,
  createUtagSearchHistoryDelete,
} from 'tracking/trackingHelper';

import {
  recoTrackAddToCart,
  recoTrackCheckout,
  recoTrackProductDetail,
  recoTrackProductsOverview,
} from 'api/RecoAPI/RecoAPI';

import getCookieStorage from 'constants/storage/cookie';
import {
  COMPONENT_ATTRIBUTE_1,
  COMPONENT_ATTRIBUTE_2,
  COMPONENT_FUNCTION,
  COMPONENT_INNERLOCATION,
  COMPONENT_TYPE,
  NAVIGATION,
  NAVIGATION_BACK,
  NAVIGATION_CLOSE,
  NAVIGATION_GO_TO,
  NAVIGATION_OPEN,
  NAVIGATION_SELECT,
} from 'constants/trackingAttributes/trackingAttributes';

const cookie = getCookieStorage();

// VARIABLES
let isUtagReady = false;
const sendLaterCache = [];
const localStorage = getStorage(true);

const handleError = (e) => postLog(e);

/**
 *  send object to tealium as view action
 */
const view = (dataProp) => {
  const data = { ...dataProp };

  if (isUtagReady) {
    window.utag.data['user_profile_profileInfo_profileID'] = data['user_profile_profileInfo_profileID']; // eslint-disable-line
    window.utag.data['user_profile_profileInfo_loginStatus'] = data['user_profile_profileInfo_loginStatus']; // eslint-disable-line
    window.utag.data['page_pageInfo_pageName'] = data['page_pageInfo_pageName']; // eslint-disable-line
    try {
      window.utag.view(data);
    } catch (ex) {
      handleError(ex);
    }
  } else {
    sendLaterCache.push({
      type: 'view',
      data,
    });
  }
};

/**
 *  send object to tealium as link action
 */
export const link = (dataProp) => {
  const data = { ...dataProp };

  if (isUtagReady) {
    try {
      window.utag.link(data);
    } catch (ex) {
      handleError(ex);
    }
  } else {
    sendLaterCache.push({
      type: 'link',
      data,
    });
  }
};

/**
 * send all postponed tracking calls due to waiting for the tags to load
 */
const executeSendLaterCache = () => {
  try {
    for (let i = 0; i < sendLaterCache.length; i++) {
      if (sendLaterCache[i].type === 'view') {
        view(sendLaterCache[i].data);
      } else if (sendLaterCache[i].type === 'link') {
        link(sendLaterCache[i].data);
      }
    }
    // clear the array
    sendLaterCache.length = 0;
  } catch (ex) {
    handleError(ex);
  }
};

/**
 * only for Tealium to call from their snippet!
 * @param secretCode is the answer to life, the universe and everything
 */
const activateTracker = (secretCode) => {
  if (secretCode === 42) {
    isUtagReady = true;
    executeSendLaterCache();
  }
};

/**
 * Triggers interaction
 * @param trackingData element attribute data-tracking as object (obtainable from dataTrackingRaw method)
 */

export const trackInteraction = (trackingData) => {
  try {
    link(createUtagDataGenericInteraction(trackingData));
  } catch (ex) {
    handleError(ex);
  }
};

/**
 * Listener which fires on every click to track elements
 * @param clickedElement
 */
const trackClickInteraction = (clickedElement) => {
  const getContext = () => {
    let context = '{}';
    let element = clickedElement.target;
    while (element) {
      const data = element?.dataset?.tracking;
      if (data) {
        context = data;
        break;
      }
      element = element.parentNode;
    }
    return context;
  };
  const context = getContext();

  if (context.length <= 3) return;

  trackGenericInteraction(context);
};

export const trackGenericInteraction = (context) => {
  let parsedContext = {};
  try {
    parsedContext = JSON.parse(context);
  } catch (ex) {
    postLog(ex);
  }

  if (Object.entries(parsedContext).length) {
    try {
      link(createUtagDataGenericInteraction(parsedContext));
    } catch (ex) {
      postLog(ex);
    }
  }
};

/**
 *  send TrackingData for errors when forms are filled by the user
 */
export const trackFormError = (eventType, state) => {
  try {
    let temporary;
    let invoice;
    let delivery;
    const login = state.user && state.user.fields;

    if (state.addresses) {
      temporary = state.addresses[addressTypes.TEMPORARY] && state.addresses[addressTypes.TEMPORARY].fields;
      invoice = state.addresses[addressTypes.INVOICE] && state.addresses[addressTypes.INVOICE].fields;
      delivery = state.addresses[addressTypes.DELIVERY] && state.addresses[addressTypes.DELIVERY].fields;
    }

    link(addUtagDataFormError(createUtagDataHead(), eventType, temporary, invoice, delivery, login));
  } catch (ex) {
    handleError(ex);
  }
};

/**
 *  send TrackingData for Checkout
 */
export const trackCheckout = (state = {}) => {
  try {
    const cart = state.cart;
    const categories = state.categories || {};

    view({
      ...createUtagDataHead(),
      ...createUtagDataBody(),
      ...createUtagDataCheckout(cart, categories),
      ...createCategoryUtagData({ categoryTree: categories }),
    });
  } catch (ex) {
    handleError(ex);
  }
};

/**
 *  send TrackingData for checkout-login
 */
export const trackCheckoutLogin = (state = {}) => {
  try {
    const cart = state.cart;
    const categories = state.categories || {};

    view({
      ...createUtagDataHead(),
      ...createUtagDataBody(),
      ...createUtagDataCheckoutLogin(cart, categories),
      ...createCategoryUtagData({ categoryTree: categories }),
    });
  } catch (ex) {
    handleError(ex);
  }
};

/**
 *  send TrackingData for Purchase
 *  temp data is used for tracking when returning from DataTrans
 */
export const trackPurchase = (state = {}) => {
  try {
    const uid = state.user.uid || 'anonymous';
    const transactionID = state.orders?.lastOrder?.code || localStorage.getItem(ORDER_CODE);
    const tempProducts = localStorage.getItem(TEMP_PRODUCTS);
    const tempCart = localStorage.getItem(TEMP_CART);
    const categories = state.categories || {};
    const products = tempProducts ? JSON.parse(tempProducts) : state.products;
    const cart = tempCart ? JSON.parse(tempCart) : state.cart;

    view({
      ...createUtagDataHead(),
      ...createUtagDataBody(),
      ...createUtagDataPurchase(cart, products, uid, transactionID, categories),
      ...createCategoryUtagData({ categoryTree: categories }),
    });

    // Send a tracking call to the reco engine
    recoTrackCheckout(cart.entries, cookie.getItem(RECO_COOKIE));

    // remove temporal products and cart
    localStorage.removeItem(TEMP_PRODUCTS);
    localStorage.removeItem(TEMP_CART);
  } catch (ex) {
    handleError(ex);
  }
};

/**
 *  send TrackingData for Cart
 */
export const trackCart = (cart, categories) => {
  try {
    view({
      ...createUtagDataHead(),
      ...createUtagDataBody(),
      ...createUtagDataCart(cart, categories),
      ...createCategoryUtagData({ categoryTree: categories }),
    });
  } catch (ex) {
    handleError(ex);
  }
};

/**
 *  send TrackingData for ProductDetailView,
 *  only once after route change
 */
export const trackProductDetail = (product, categories = {}, store) => {
  const state = store.getState();
  const query = state?.productsQueries?.currentQuery;
  const categoryTree = state?.categories;

  try {
    createUtagDataProductDetailPromise(product, categories, store)
      .then((utagDataProducts) => {
        if (utagDataProducts !== undefined) {
          view({
            ...createUtagDataHead(),
            ...createUtagDataBody(),
            ...utagDataProducts,
            ...createCategoryUtagData({ query, categoryTree, product }),
          });
        }
      })
      .catch((err) => {
        throw err;
      });
    // Send a tracking call to the reco engine
    recoTrackProductDetail(product, cookie.getItem(RECO_COOKIE));
  } catch (ex) {
    handleError(ex);
  }
};

/**
 *  send TrackingData for ProductsOverview, only once after route change
 */
export const trackProductsOverview = (store) => {
  try {
    const state = store?.getState() || {};
    const productQuery = state.productsQueries?.currentQuery;
    const productQueryApiStatus = productQuery?.apiStatus;
    const searchTerm = productQuery?.searchString;
    if (productQueryApiStatus === 'SUCCESS' || productQueryApiStatus === 'FAILURE') {
      view({
        ...createUtagDataHead(),
        ...createUtagDataBody(),
        ...createUtagDataProductOverview(store),
      });
      // Send a tracking call to the reco engine
      recoTrackProductsOverview(productQuery.categoryCode, cookie.getItem(RECO_COOKIE), searchTerm);
    }
  } catch (ex) {
    handleError(ex);
  }
};

export function trackAddService(product, state, addedServiceCode, quantity = 1) {
  try {
    link({
      ...createUtagDataHead(),
      ...createUtagDataAddService(product, state.categories, addedServiceCode, quantity),
    });
  } catch (ex) {
    handleError(ex);
  }
}

export function trackRemoveService(product, state, removedServiceCode, quantity = 1) {
  try {
    link({
      ...createUtagDataHead(),
      ...createUtagDataRemoveService(product, state.categories, removedServiceCode, quantity),
    });
  } catch (ex) {
    handleError(ex);
  }
}

/**
 *  send TrackingData for addToCart
 */
export const trackAddToCart = (product, isAccessory, state, quantity) => {
  try {
    link({
      ...createUtagDataHead(),
      ...createUtagDataAddToCart(product, isAccessory, state.categories, quantity),
    });
    // Send a tracking call to the reco engine
    recoTrackAddToCart(state.cart.entries, cookie.getItem(RECO_COOKIE));
  } catch (ex) {
    handleError(ex);
  }
};

/**
 *  send TrackingData for addToCart by productCode
 */
export const trackAddToCartByProductCode = (productCode, state, productAttributes, isAccessory) => {
  try {
    trackAddToCart({ ...state.products[productCode], ...productAttributes }, isAccessory, state);
  } catch (ex) {
    handleError(ex);
  }
};

/**
 *  send TrackingData for removeFromCart
 */
export const trackRemoveFromCart = (product, quantity = 1, categories) => {
  try {
    link({
      ...createUtagDataHead(),
      ...createUtagDataRemoveFromCart(product, quantity, categories),
    });
  } catch (ex) {
    handleError(ex);
  }
};

/**
 *  send TrackingData for registration by cartId
 */
export const trackRegistration = () => {
  try {
    link({
      ...createUtagDataHead(),
      ...addUtagDataRegistration(),
    });
  } catch (ex) {
    handleError(ex);
  }
};

/**
 * send TrackingData for registration page
 */
export const trackRegistrationPage = () => {
  try {
    view({
      ...createUtagDataHead(),
      ...addUtagDataRegistrationPage(),
    });
  } catch (ex) {
    handleError(ex);
  }
};

/**
 * send TrackingData for clicks on product teasers
 */
export const trackClickOnProduct = (product, context, categories) => {
  try {
    link(createUtagDataClickOnProduct(product, context, categories));
  } catch (ex) {
    handleError(ex);
  }
};

export const trackCmsClick = (component, position, href) => {
  try {
    link(createUtagDataClickOnCms(component, position, href));
  } catch (ex) {
    handleError(ex);
  }
};

export const trackNavigationElement = (context) => {
  try {
    link(createUtagDataNavigationElement(context));
  } catch (ex) {
    handleError(ex);
  }
};

/**
 * Tracks normal page views - see trackingMiddleware.js for exceptions
 */
export const trackPageView = (store, pathname = null) => {
  const state = store.getState();
  try {
    const query = state?.productsQueries?.currentQuery;
    const categoryTree = state?.categories;
    const isSearch = state?.routing?.locationBeforeTransitions?.search;

    if (!isSearch) {
      view({
        ...createUtagDataHead(pathname),
        ...createUtagDataBody(pathname),
        ...createCategoryUtagData({ query, categoryTree, pathname }),
      });
    }
  } catch (ex) {
    handleError(ex);
  }
};

/**
 * Tracks cms page views
 */
export const trackCmsPageView = (store) => {
  createUtagDataCmsPageViewPromise(store)
    .then((state) => {
      const query = state?.productsQueries?.currentQuery;
      const categoryTree = state?.categories;
      view({
        ...createUtagDataHead(),
        ...createUtagDataBody(),
        ...createUtagDataCmsProducts(state),
        ...createCategoryUtagData({ query, categoryTree }),
      });
    })
    .catch((err) => handleError(err));
};

/**
 * Set Tealium-Scripts and initialize the tracking
 */
export const initialize = () => {
  try {
    // define a function on window object for Tealium to call
    // noinspection JSConstantReassignment
    window.activateTracker = activateTracker;

    // add a generic click-listener to the DOM to track clicks on cms elements
    document.addEventListener('click', trackClickInteraction);

    // OVERRIDE UTAG CONFIG
    window.utag_cfg_ovrd = {
      noview: true, // disable automatic view call on first load
    };

    window.utag_data = {
      platform: config.customer,
      page_pageInfo_language: `${getLocale()}-ch`,
      page_pageInfo_templateType: 'content',
      page_pageInfo_pageName: document.title,
    };

    // EMBED UTAG
    const d = document.createElement('script');
    d.src = window.__ENV_VARIABLES__.utagUrl;
    d.type = 'text/javascript';
    d.async = true;
    const a = document.getElementsByTagName('script')[0];
    a.parentNode.insertBefore(d, a);
  } catch (ex) {
    handleError(ex);
  }
};

/**
 * Creates an unique uuid for mfgroup fraud detection
 */
const createMFGroupUUID = () => {
  const mfgroupUUID = uuid();
  localStorage.setItem(MFGROUP_UUID, mfgroupUUID, 10 * 365);

  return mfgroupUUID;
};

/**
 * initialize mf group fraud tag
 */
export const initializeFraudTag = () => {
  try {
    createMFGroupUUID();
    const merchantCDN = window.__ENV_VARIABLES__.mfgroupFraudCdn;
    const merchantID = window.__ENV_VARIABLES__.mfgroupMerchantId;
    // for every new script initialization we need a new uuid
    const session = createMFGroupUUID();

    const srcURL = `${merchantCDN}/cdn?session=${session}&merchant=${merchantID}`;

    // add mfgroup script tag
    const script = document.createElement('script');
    script.src = srcURL;
    script.type = 'text/javascript';
    script.async = true;
    document.getElementsByTagName('head')[0].appendChild(script);
  } catch (ex) {
    handleError(ex);
  }
};

export const trackAccessoriesProducts = (action, state) => {
  try {
    const references = state.products?.[action.payload?.productCode]?.referenceItemCodes?.slice(0, 6);
    const products = references?.map((reference) => state.products?.[reference])?.filter(Boolean) || [];
    if (products?.length) {
      link({
        ...createUtagDataHead(),
        ...createUtagDataAccessoriesPopupHead(),
        ...createUtagDataAccessoriesProducts(products, state.categories),
      });
    }
  } catch (ex) {
    handleError(ex);
  }
};

export const trackWatchlistProducts = (state) => {
  try {
    const products = Object.values(state.watchlist?.entries);
    if (products?.length) {
      link({
        ...createUtagDataHead(),
        ...createUtagDataProductImpressionsHead(),
        ...createUtagDataWatchlistProducts(products, state.categories),
      });
    }
  } catch (ex) {
    handleError(ex);
  }
};

export const trackMobileNavigationOpen = () => {
  try {
    link({
      ...createUtagMobileNavigationGeneric(),
      [COMPONENT_TYPE]: NAVIGATION,
      [COMPONENT_FUNCTION]: NAVIGATION_OPEN,
    });
  } catch (ex) {
    postLog(ex);
  }
};

export const trackMobileNavigationClose = () => {
  try {
    link({
      ...createUtagMobileNavigationGeneric(),
      [COMPONENT_TYPE]: NAVIGATION,
      [COMPONENT_FUNCTION]: NAVIGATION_CLOSE,
    });
  } catch (ex) {
    postLog(ex);
  }
};

export const trackMobileNavigationSelect = (type, position, attribute1, attribute2 = null) => {
  const mobileNavigationData = {};

  mobileNavigationData[COMPONENT_TYPE] = type;
  mobileNavigationData[COMPONENT_INNERLOCATION] = position;
  mobileNavigationData[COMPONENT_ATTRIBUTE_1] = attribute1;

  if (attribute2) {
    mobileNavigationData[COMPONENT_ATTRIBUTE_2] = attribute2;
  }

  try {
    link({
      ...createUtagMobileNavigationGeneric(),
      [COMPONENT_FUNCTION]: NAVIGATION_SELECT,
      ...mobileNavigationData,
    });
  } catch (ex) {
    postLog(ex);
  }
};

export const trackMobileNavigationBack = (type, position, attribute1, attribute2 = null) => {
  const mobileNavigationData = {};

  mobileNavigationData[COMPONENT_TYPE] = type;
  mobileNavigationData[COMPONENT_INNERLOCATION] = position;
  mobileNavigationData[COMPONENT_ATTRIBUTE_1] = attribute1;

  if (attribute2) {
    mobileNavigationData[COMPONENT_ATTRIBUTE_2] = attribute2;
  }
  try {
    link({
      ...createUtagMobileNavigationGeneric(),
      [COMPONENT_FUNCTION]: NAVIGATION_BACK,
      ...mobileNavigationData,
    });
  } catch (ex) {
    postLog(ex);
  }
};

export const trackMobileNavigationGoTo = (type, position, attribute1, attribute2) => {
  try {
    link({
      ...createUtagMobileNavigationGeneric(),
      [COMPONENT_TYPE]: type,
      [COMPONENT_FUNCTION]: NAVIGATION_GO_TO,
      [COMPONENT_INNERLOCATION]: position,
      [COMPONENT_ATTRIBUTE_1]: attribute1,
      [COMPONENT_ATTRIBUTE_2]: attribute2,
    });
  } catch (ex) {
    postLog(ex);
  }
};

export const trackComparisonProducts = (state) => {
  try {
    const products = state.comparison?.products;
    const category = state.comparison?.selectedCategory;
    if (products?.length) {
      link({
        ...createUtagDataHead(),
        ...createUtagDataProductImpressionsHead(),
        ...createUtagDataComparisonProducts(products, category, state.categories),
      });
    }
  } catch (ex) {
    handleError(ex);
  }
};

export const trackRecommendedProducts = (
  state,
  type,
  boxLevel = 'box1',
  isSearch = false,
  hasRecommendedProducts = false
) => {
  try {
    if (hasRecommendedProducts) {
      link({
        ...createUtagDataProductImpressionsHead(isSearch ? state.ui.search : null, hasRecommendedProducts),
        ...createUtagDataRecoProduct(state, type, boxLevel),
      });
    }
  } catch (ex) {
    handleError(ex);
  }
};

export const trackSearchAsYouType = (state) => {
  try {
    link({
      ...createUtagSearchAsYouType(state),
    });
  } catch (ex) {
    postLog(ex);
  }
};

export const trackSearchType = (searchTerm, type) => {
  try {
    link({
      ...createUtagSearch(searchTerm, type),
    });
  } catch (ex) {
    postLog(ex);
  }
};

export const trackDsgvConsents = () => {
  try {
    link({
      ...createUtagDataDsgvConsents(),
      ...createUtagDataDsvgConsentsEvent(),
    });
  } catch (ex) {
    handleError(ex);
  }
};

export const trackExitCookieBanner = () => {
  try {
    link({
      ...createUtagExitCookieBanner(),
    });
  } catch (ex) {
    postLog(ex);
  }
};

export const trackMoreInfoCookieBanner = () => {
  try {
    link({
      ...createUtagMoreInfoCookieBanner(),
    });
  } catch (ex) {
    postLog(ex);
  }
};

export const trackSearchHistoryDelete = (type, url) => {
  try {
    link({
      ...createUtagSearchHistoryDelete(type, url),
    });
  } catch (ex) {
    postLog(ex);
  }
};

export default {
  initialize,
  trackProductDetail,
  trackProductsOverview,
  trackPageView,
  trackAddToCart,
  trackAddToCartByProductCode,
  trackRemoveFromCart,
  trackRegistration,
  trackCmsPageView,
  trackCheckout,
  trackFormError,
  trackRegistrationPage,
  trackClickOnProduct,
  initializeFraudTag,
  trackCart,
  trackAccessoriesProducts,
  trackWatchlistProducts,
  trackComparisonProducts,
  trackRecommendedProducts,
  trackGenericInteraction,
  trackDsgvConsents,
};
