import React from 'react';
import PropTypes from 'prop-types';

import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';
import dataTracking from 'constants/trackingAttributes/dataTracking';

import styles from './LoginAlternative.scss';

const LoginAlternative = ({ linkTo, questionText, description, buttonText }) => (
  <div className={styles.loginAlternative}>
    <div className={styles.button}>
      <ButtonLink
        href={linkTo}
        size={ButtonLink.sizes.md}
        variety={ButtonLink.varieties.flat}
        stretched
        data-tracking={dataTracking({
          TYPE: 'form:button',
          FUNCTION: 'go-to',
          ATTRIBUTE_1: 'neutral',
          ATTRIBUTE_2: buttonText,
        })}
      >
        {buttonText}
      </ButtonLink>
    </div>
    <div className={styles.text}>
      <p className={styles.bold}>{questionText}</p>
      <p>{description}</p>
    </div>
  </div>
);

LoginAlternative.propTypes = {
  linkTo: PropTypes.string,
  questionText: PropTypes.string,
  description: PropTypes.string,
  buttonText: PropTypes.string,
};

LoginAlternative.defaultProps = {
  linkTo: '/',
};

LoginAlternative.displayName = 'LoginAlternative';

export default LoginAlternative;
