const timerArray = [];

export const debounce = (id, func, delay) => {
  if (id in timerArray) {
    clearTimeout(timerArray[id]);
  }
  timerArray[id] = setTimeout(func, delay);
};

export const cancel = (timeout) => timeout in timerArray && clearTimeout(timerArray[timeout]);
