import restClient from 'api/RestClient/RestClient';
import { resourceKeys } from 'constants/restResources/restResources';
import CartAPI, { addFallbackToEntryIfNeeded, getCartId, setLastOrderGuid } from 'api/CartAPI/CartAPI';
import { getUserId } from 'api/UserAPI/UserAPI';
import { getDatatransURL, mapDatatransResponse } from '../mappings/DatatransMapping';
import language, { getLocale } from 'constants/language/language';
import { GUEST_EMAIL, LAST_CART_ID, MFGROUP_UUID, ORDER_CODE } from 'constants/storage/storageKeys';
import getStorage from 'constants/storage/storage';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import { PAYMENT, PAYMENT_CANCEL, PAYMENT_ERROR, PAYMENT_SUCCESS } from 'constants/routePaths/routePaths';
import { getEnv } from 'config/config';

const USER_RESOURCE = resourceKeys.USER_RESOURCE;

const storage = getStorage(true);

const config = {
  get successUrl() {
    const path = mapPathToLocalizedUrl(getLocale(), [PAYMENT, PAYMENT_SUCCESS]);
    return createCallbackURL(path);
  },
  get cancelUrl() {
    const path = mapPathToLocalizedUrl(getLocale(), [PAYMENT, PAYMENT_CANCEL]);
    return createCallbackURL(path);
  },
  get errorUrl() {
    const path = mapPathToLocalizedUrl(getLocale(), [PAYMENT, PAYMENT_ERROR]);
    return createCallbackURL(path);
  },
};

const createCallbackURL = (tail) => {
  if (window?.location?.origin) {
    return `${window.location.origin}${tail}`;
  }
  return getEnv('web') + tail;
};

/**
 * Authorizes cart and places the order
 * @returns OrderDTO
 */
function placeOrder(datatransResponse) {
  const cartId = getCartId();
  const userId = getUserId();

  if (!cartId || !userId) {
    return Promise.reject({ response: 'Missing Parameters' });
  }

  // the api expects only a body, when a datatrans response exists. Otherwise the body must be empty.
  if (datatransResponse) {
    return restClient
      .post(USER_RESOURCE, `/${userId}/orders/${cartId}`, mapDatatransResponse(datatransResponse))
      .then((response) => {
        setLastOrderGuid(response.data.guid);
        storage.setItem(ORDER_CODE, response.data.code);
        return response;
      });
  }

  return restClient.post(USER_RESOURCE, `/${userId}/orders/${cartId}`, {}).then((response) => {
    setLastOrderGuid(response.data.guid);
    storage.setItem(ORDER_CODE, response.data.code);
    return response;
  });
}

/**
 * Returns order history data for all orders placed by the specific user
 * @returns OrderListDTO
 */
function getAllOrders(page = 0, pageSize = 20) {
  return restClient.get(USER_RESOURCE, `/${getUserId()}/orders?currentPage=${page}&pageSize=${pageSize}`);
}

/**
 * MSP ORDER MIGRATION
 * Returns order history data for all orders placed by the specific user on MSP
 * @returns OrderListDTO
 */
// eslint-disable-next-line no-unused-vars
function getAllArchivedOrders(page = 0, pageSize = 20) {
  return restClient.get(USER_RESOURCE, `/${getUserId()}/orders/archive?currentPage=${page}&pageSize=${pageSize}`);
}

/**
 * Returns specific order details based on a specific order code.
 * @param orderID
 * @returns OrderDTO
 */
function getSpecificOrder(orderID) {
  if (!orderID) {
    return Promise.reject('Invalid Parameters');
  }

  return restClient.get(USER_RESOURCE, `/${getUserId()}/orders/${orderID}`).then((req) => {
    const newReq = req;
    const cartEntries = newReq?.data?.entries?.map((entry) => addFallbackToEntryIfNeeded(entry));
    newReq.data.entries = cartEntries;

    return newReq;
  });
}

/**
 * MSP ORDER MIGRATION
 * Returns specific order details based on a specific order code on MSP
 * @param orderID
 * @returns OrderDTO
 */
function getSpecificArchivedOrder(orderID) {
  if (!orderID) {
    return Promise.reject('Invalid Parameters');
  }

  return restClient.get(USER_RESOURCE, `/${getUserId()}/orders/archive/${orderID}`);
}

/**
 * Inititate Datatrans Transaction using the new Datatrans API
 */
function initiateDatatransTransaction() {
  return restClient
    .post(USER_RESOURCE, `/${getUserId()}/orders/${getCartId()}/v2/datatrans`, {
      ...config,
      refno2: localStorage.getItem(MFGROUP_UUID),
    })
    .then((response) => {
      // We populate localStorage with the order details since datatrans redirects to order confirmation after success
      // see ...config passed inside the body of restClient
      localStorage.setItem(LAST_CART_ID, CartAPI.getCartId());
      localStorage.setItem(GUEST_EMAIL, response.data.customerEmail);
      localStorage.setItem(ORDER_CODE, response.data.orderCode);
      return {
        baseURL: response.data.redirectUrl,
      };
    });
}

/**
 * Send Datatrans authorisation info request
 * @returns OrderDTO
 */
function getDatatransAuthorisation(refno) {
  return restClient
    .get(USER_RESOURCE, `/${getUserId()}/orders/${getCartId()}/datatrans`)
    .then((response) => getDatatransURL({ ...response.data, refno }));
}

/**
 * Get PDF with MWST for Order
 */
function generateInvoiceDocument(guid, orderCode, archived = false, shouldPrint = false) {
  if (!guid) {
    return Promise.reject('Invalid Parameter');
  }
  const archivedPath = archived ? '/archive' : '';
  const path = `/${getUserId()}/orders${archivedPath}/pdf/${guid}`;
  const fileName = `${language('myAccountOrderDetails.invoiceDocument')}_${orderCode}.pdf`;
  return restClient.getPdf(USER_RESOURCE, path, fileName, shouldPrint);
}

/**
 * Get guarantee card pdf
 */
function generateGuaranteeDocument(orderCode, productCode, productName, archived = false) {
  const archivedPath = archived ? '/archive' : '';
  const path = `/${getUserId()}/orders${archivedPath}/${orderCode}/product/${productCode}/pdf`;
  const fileName = `${language('product.guaranteeCard')}_${productName}.pdf`;
  return restClient.getPdf(USER_RESOURCE, path, fileName);
}

function checkStockAvailability() {
  return restClient.get(USER_RESOURCE, `/${getUserId()}/orders/${getCartId()}/checkstockavailability`);
}

function checkOrderValidity() {
  return restClient.get(USER_RESOURCE, `/${getUserId()}/orders/${getCartId()}/validateOrder`);
}

const requestOrderDocuments = (orderNumber, lastName, documentType) =>
  restClient.post(USER_RESOURCE, `/${getUserId()}/order/documents/${orderNumber}/${lastName}/${documentType}`);

const OrderAPI = {
  placeOrder,
  getAllOrders,
  getAllArchivedOrders,
  getSpecificOrder,
  getSpecificArchivedOrder,
  getDatatransAuthorisation,
  initiateDatatransTransaction,
  generateInvoiceDocument,
  generateGuaranteeDocument,
  checkStockAvailability,
  checkOrderValidity,
  requestOrderDocuments,
};

export {
  OrderAPI as default,
  placeOrder,
  getAllOrders,
  getAllArchivedOrders,
  getSpecificOrder,
  getSpecificArchivedOrder,
  getDatatransAuthorisation,
  initiateDatatransTransaction,
  generateInvoiceDocument,
  generateGuaranteeDocument,
  checkStockAvailability,
  checkOrderValidity,
  requestOrderDocuments,
};
