import React from 'react';
import PropTypes from 'prop-types';
import ComparisonList from 'components/molecules/ComparisonList/ComparisonList';
import ProductComparisonHeader, { skins } from 'components/molecules/ProductComparisonHeader/ProductComparisonHeader';
import ProductComparisonListHeader, {
  headerSkins,
} from 'components/molecules/ProductComparisonHeader/ProductComparisonListHeader';
// eslint-disable-next-line max-len
import ProductComparisonListHeaderMobile from 'components/molecules/ProductComparisonHeader/ProductComparisonListHeaderMobile';
import bootstrap from 'scss/component.scss';
import styles from './ProductComparisonFull.scss';

const ProductComparisonFull = ({
  addToWatchlist = () => {},
  apiStatus = '',
  categories = {},
  changeCategory = () => {},
  comparisonCategories = {},
  comparisonList = {},
  getComparisonList = () => {},
  isProductInWatchlist = () => {},
  productCodes = [],
  products = [],
  removeFromWatchlist = () => {},
  removeProduct = () => {},
  selectedCategory = '',
}) => (
  <>
    <div className={bootstrap.hiddenMdDown}>
      <ProductComparisonHeader
        products={products}
        selectedCategory={selectedCategory}
        categories={categories}
        skin={skins.LIST}
        changeCategory={changeCategory}
        getComparisonList={getComparisonList}
        productCodes={productCodes}
        removeFromComparison={removeProduct}
        apiStatus={apiStatus}
        addToWatchlist={addToWatchlist}
        removeFromWatchlist={removeFromWatchlist}
        isProductInWatchlist={isProductInWatchlist}
        comparisonCategories={comparisonCategories}
      />
      <div className={styles.distanceTop}>
        <ComparisonList comparisonList={comparisonList} getComparisonList={getComparisonList} />
      </div>
      <div>
        <ProductComparisonListHeader
          products={products}
          selectedCategory={selectedCategory}
          categories={categories}
          skin={headerSkins.BOTTOM}
          changeCategory={changeCategory}
          getComparisonList={getComparisonList}
          productCodes={productCodes}
          removeFromComparison={removeProduct}
          apiStatus={apiStatus}
          addToWatchlist={addToWatchlist}
          removeFromWatchlist={removeFromWatchlist}
          isProductInWatchlist={isProductInWatchlist}
          comparisonCategories={comparisonCategories}
        />
      </div>
    </div>
    <div className={bootstrap.hiddenLgUp}>
      <ProductComparisonListHeaderMobile
        products={products}
        categories={categories}
        selectedCategory={selectedCategory}
        removeProduct={removeProduct}
        changeCategory={changeCategory}
        getComparisonList={getComparisonList}
        productCodes={productCodes}
        apiStatus={apiStatus}
        comparisonCategories={comparisonCategories}
      />
      <ComparisonList
        comparisonList={comparisonList}
        getComparisonList={getComparisonList}
        productNames={products.map((prod) => prod.name)}
      />
      <ProductComparisonListHeaderMobile
        products={products}
        categories={categories}
        selectedCategory={selectedCategory}
        removeProduct={removeProduct}
        changeCategory={changeCategory}
        getComparisonList={getComparisonList}
        productCodes={productCodes}
        apiStatus={apiStatus}
        skin={headerSkins.BOTTOM}
        comparisonCategories={comparisonCategories}
      />
    </div>
  </>
);

ProductComparisonFull.displayName = 'molecules/ProductComparisonFull';
ProductComparisonFull.propTypes = {
  addToWatchlist: PropTypes.func,
  apiStatus: PropTypes.string,
  categories: PropTypes.object,
  changeCategory: PropTypes.func,
  comparisonCategories: PropTypes.object,
  comparisonList: PropTypes.object,
  getComparisonList: PropTypes.func,
  isProductInWatchlist: PropTypes.func,
  productCodes: PropTypes.array,
  products: PropTypes.array,
  removeFromWatchlist: PropTypes.func,
  removeProduct: PropTypes.func,
  selectedCategory: PropTypes.string,
};

export default ProductComparisonFull;
