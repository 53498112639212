import React from 'react';
import PropTypes from 'prop-types';
import IframeResizer from 'iframe-resizer-react';

import styles from './CMSIFrame.scss';

const CmsIFrame = ({ externalUrl, height }) => {
  return <IframeResizer title="Jobs" src={externalUrl} height={height} className={styles.frame} />;
};

CmsIFrame.propTypes = {
  externalUrl: PropTypes.string,
  height: PropTypes.string,
};

CmsIFrame.defaultProps = {
  externalUrl: '',
  height: '1500px',
};

export default CmsIFrame;
