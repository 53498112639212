/**
 * @name Atom: IconInformation
 * @description Renders a svg: info icon
 * @Author: Daniel Grobelny
 * @Date:   2016-03-18T11:50:49+01:00
 * @Email:  daniel.grobelny@nexum.de
 * @Last modified by:   Daniel Grobelny
 * @Last modified time: 2016-03-21T18:21:02+01:00
 */
import React from 'react';
import PropTypes from 'prop-types';
import SvgContainer from 'components/atoms/SvgContainer/SvgContainer';

const renderPath = (
  // eslint-disable-next-line max-len
  <path d="M200 400C89.6 400 0 310.4 0 200S89.6 0 200 0s200 89.6 200 200-89.6 200-200 200zm66.7-116.7c0-4.7-3.6-8.3-8.3-8.3h-25V141.7c0-4.7-3.6-8.3-8.3-8.3h-83.3c-4.7 0-8.3 3.6-8.3 8.3v41.7c0 4.7 3.6 8.3 8.3 8.3h25V275h-25c-4.7 0-8.3 3.6-8.3 8.3V325c0 4.7 3.6 8.3 8.3 8.3h116.7c4.7 0 8.3-3.6 8.3-8.3v-41.7h-.1zM233.3 50c0-4.7-3.6-8.3-8.3-8.3h-50c-4.7 0-8.3 3.6-8.3 8.3v41.7c0 4.7 3.6 8.3 8.3 8.3h50c4.7 0 8.3-3.6 8.3-8.3V50z" />
);

const IconInformation = ({ height, className }) => (
  <SvgContainer height={height} className={className}>
    {renderPath}
  </SvgContainer>
);

IconInformation.displayName = 'atoms/IconInformation';

IconInformation.propTypes = {
  height: PropTypes.number,
  className: PropTypes.string,
};

IconInformation.defaultProps = {
  height: 32,
};

export default IconInformation;
