export const types = {
  SUCCESS: 'SUCCESS',
  WARNING: 'WARNING',
  ERROR: 'ERROR',
  NO_STOCK: 'NO_STOCK',
  INFO: 'INFO',
  NOTE: 'NOTE',
  OPENING_DAY: 'OPENING_DAY',
  USP: 'USP',
  WARNING_INFO: 'WARNING_INFO',
};

export const sizes = {
  DEFAULT: 'DEFAULT',
  LARGE: 'LARGE',
};

export const locations = {
  HEADER: 'HEADER',
  PRODUCT: 'PRODUCT',
  PAYMENT: 'PAYMENT',
  CHECKOUT: 'CHECKOUT',
  CART: 'CART',
  STOREFINDER: 'STOREFINDER',
  DELIVERY_HOME: 'DELIVERY_HOME',
  DELIVERY_PICKUP: 'DELIVERY_PICKUP',
};

export const getFlashMessage = (flashMessages = [], location = '') =>
  flashMessages.filter((message) => message.location === location)[0] || {};
