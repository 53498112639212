// @flow

import invert from 'constants/helper/invert';
import { BREAKPOINTS } from 'constants/breakpoints/breakpoints';

/**
 * This maps a view breakpoint to a number to be comparable.
 */
const heightToValueMapping = {
  [BREAKPOINTS.XXS]: 1,
  [BREAKPOINTS.XS]: 2,
  [BREAKPOINTS.SM]: 3,
  [BREAKPOINTS.MD]: 4,
  [BREAKPOINTS.LG]: 5,
  [BREAKPOINTS.XL]: 6,
};

/**
 * Returns the optimal height for the current view breakpoint. If the current breakpoint
 * is not specified in heights, the function will be called recursively with the height
 * of the next lower breakpoint. If no possible heights are provided or every breakpoint
 * is tried, undefined is being returned.
 */
const getIconConfig = (allBreakpoints: any, breakpoint: string) => {
  // if breakpoint and heights are valid
  if (!!heightToValueMapping[breakpoint] && !!allBreakpoints) {
    // if breakpoint matches with given heights
    if (allBreakpoints[breakpoint]) {
      return allBreakpoints[breakpoint];
    }
    // try next lower breakpoint
    const lowerBreakpoint = invert(heightToValueMapping)[heightToValueMapping[breakpoint] - 1];
    return getIconConfig(allBreakpoints, lowerBreakpoint);
  }
  return undefined;
};

export default getIconConfig;
