import {
  trackAccessoriesProducts,
  trackAddService,
  trackAddToCart,
  trackAddToCartByProductCode,
  trackCheckout,
  trackCheckoutLogin,
  trackClickOnProduct,
  trackCmsClick,
  trackCmsPageView,
  trackComparisonProducts,
  trackDsgvConsents,
  trackFormError,
  trackNavigationElement,
  trackPageView,
  trackProductDetail,
  trackProductsOverview,
  trackPurchase,
  trackRecommendedProducts,
  trackRegistration,
  trackRegistrationPage,
  trackRemoveFromCart,
  trackRemoveService,
  trackSearchAsYouType,
  trackWatchlistProducts,
} from 'tracking/tracking';
import {
  ADD_ACCESSORY_TO_CART_SUCCESS,
  ADD_TO_CART_SUCCESS,
  CREATE_USER_FAILURE,
  CREATE_USER_SUCCESS,
  DELETE_FROM_CART_SUCCESS,
  LOAD_PRODUCT_SUCCESS,
  ROUTER_UPDATE_LOCATION,
  SEARCH_AS_YOU_TYPE_SUCCESS,
  SET_FORCE_VALIDATION_ERRORS_VISIBLE,
  TRACK_ACCESSORIES,
  TRACK_CART_LOGIN,
  TRACK_CHECKOUT,
  TRACK_CMS_CLICK,
  TRACK_COMPARISON,
  TRACK_FACET_CHANGE,
  TRACK_NAVIGATION_ELEMENTS,
  TRACK_PLACE_ORDER,
  TRACK_PRODUCT_CLICK,
  TRACK_PRODUCT_OVERVIEW,
  TRACK_RECOMMENDED_PRODUCTS,
  TRACK_WATCHLIST,
  UPDATE_ENTRY_SUCCESS,
  UPDATE_USER_CONSENTS_SUCCESS,
} from 'constants/ActionTypes/ActionTypes';
import { ACCOUNT, ACCOUNT_REGISTRATION, BRAND, CHECKOUT, CMS, PRODUCT_SEARCH } from 'constants/routePaths/routePaths';
import { getLocale } from 'constants/language/language';

const paths = {
  registration: `${ACCOUNT}/${ACCOUNT_REGISTRATION}`,
  checkout: CHECKOUT,
  search: PRODUCT_SEARCH,
  cms: CMS,
  brand: BRAND,
};

/**
 * Removes the language part (first four letters e.g. /de/) from the path
 * @return {string} path without locale
 */
export const removeLocale = (path) => path.substr(4);

/**
 * Certain pages are excluded from the pageview tracking, because
 * they will be tracked separately with some additional parameters
 * @return {boolean} - true if page is excluded, otherwise false
 */
export const isExcludedFromPageViewTracking = (path) => {
  const newPath = removeLocale(path);
  let excluded = false;

  const excludedPages = [
    /^cart$/, // exclude cart
    /^search$/, // exclude search
    /^checkout$/, // exclude checkout
    /^checkout\/login$/, // exclude checkout/login
    /^checkout\/guest$/, // exclude checkout/guest
    /^payment\/success$/, // exclude payment/success
    /^account\/registration$/, // exclude account/registration
    /^(cms|brand|$)/, // exclude cms, brand & home
    /^.*--c\d{3,}(\/.*--p\w\d{9})?$/, // exclude product and category pages
    /^.*product(\/.*-(\d{9,16})($|\?))/, // exclude new product pages structure
  ];

  excludedPages.forEach((expression) => {
    const regex = RegExp(expression);
    if (regex.test(newPath)) {
      excluded = true;
    }
  });
  return excluded;
};

/**
 * Dispatches the tracking
 *
 * @param store
 * @returns {function(*): Function}
 */
const trackingMiddleware = (store) => (next) => (action) => {
  next(action); // trigger update on store

  if (__DEV__) {
    // Disable all tracking requests in dev mode
    return;
  }

  try {
    if (__CLIENT__) {
      if (action.type.includes(ROUTER_UPDATE_LOCATION)) {
        const path = action?.payload?.pathname;

        if (!isExcludedFromPageViewTracking(path)) {
          setTimeout(() => {
            // workaround for title problem - SPQR-7337
            // timeout causes race conditions when a redirect happens in between
            // we explicitly pass down the path at initial call time to prevent this
            trackPageView(store, path);
          }, 1000);
        } else {
          // track registration page view
          if (path.includes(paths.registration)) {
            trackRegistrationPage();
          }

          // track cms page view
          const isCms = path.includes(paths.cms);
          const isBrand = path.includes(paths.brand);
          const isHome = path === `/${getLocale()}`;
          if (isCms || isBrand || isHome) {
            trackCmsPageView(store);
          }
        }
      } else {
        if (action.type.includes(TRACK_PRODUCT_OVERVIEW)) {
          setTimeout(() => {
            // link needs to be called after view, see timeout in trackPageView - FOSS-317
            trackProductsOverview(store);
          }, 1000);
        }

        // for product detail page
        if (action.type.includes(LOAD_PRODUCT_SUCCESS)) {
          const state = store.getState();
          const productCode = action.req?.data?.code;
          const product = state?.products?.[productCode];
          trackProductDetail(product, state?.categories, store);
        }

        // for clicks on teaser
        if (action.type.includes(TRACK_PRODUCT_CLICK)) {
          const product = action?.payload?.product;
          const context = action?.payload?.context;

          trackClickOnProduct(product, context, store.getState().categories);
        }

        // for clicks on any cms element
        if (action.type.includes(TRACK_CMS_CLICK)) {
          const component = action?.payload?.context?.componentName || '';
          const position = action?.payload?.context?.position || 0;
          const href = action?.payload?.context?.link || '';

          trackCmsClick(component, position, href);
        }

        // for navigation elements
        if (action.type.includes(TRACK_NAVIGATION_ELEMENTS)) {
          const context = action?.payload?.context || {};

          trackNavigationElement(context);
        }

        // for facet changes
        if (action.type.includes(TRACK_FACET_CHANGE)) {
          const state = store.getState();
          const prevFacets = state?.routing?.locationBeforeTransitions?.query || {};

          // remove non-facet values
          delete prevFacets.search;
          delete prevFacets.page;

          // merge all previous facets with the change
          const facet = action?.payload?.facets || {};
          const mergedKeys = Object.keys(prevFacets);
          const mergedValues = Object.values(prevFacets);

          // merge if necessary
          if (mergedKeys.indexOf(facet.name) > -1) {
            const idx = mergedKeys.indexOf(facet.name);
            if (facet?.isRemoved ?? false) {
              // remove deleted value
              const newValue = mergedValues[idx].replace(facet.value, '');
              if (newValue.length > 0) {
                mergedValues[idx] = newValue;
              } else {
                // remove whole entry
                mergedKeys.splice(idx, 1);
                mergedValues.splice(idx, 1);
              }
              // remove whitespaces
              if (mergedValues[idx]) {
                mergedValues[idx] = mergedValues[idx].replace('  ', ' ');
                mergedValues[idx] = mergedValues[idx].trim();
              }
            } else {
              // add new value
              mergedValues[idx] = `${mergedValues[idx]} ${facet.value}`;
              mergedValues[idx] = mergedValues[idx].trim();
            }
          } else {
            mergedKeys.push(facet.name);
            mergedValues.push(facet.value);
          }
        }

        if (action.type.includes(ADD_TO_CART_SUCCESS)) {
          trackAddToCartByProductCode(
            action?.req?.data?.entry?.product?.code,
            store.getState(),
            action?.payload?.productAttributes
          );
        }

        if (action.type.includes(ADD_ACCESSORY_TO_CART_SUCCESS)) {
          trackAddToCartByProductCode(
            action?.req?.data?.entry?.product?.code,
            store.getState(),
            action?.payload?.productAttributes,
            true
          );
        }

        if (action.type.includes(UPDATE_ENTRY_SUCCESS)) {
          const productCode = action?.req?.data?.entry?.product?.code;
          const product = action?.req?.data?.entry?.product;
          const productPriceData = store.getState()?.products[productCode].productPriceData;
          const categories = store.getState()?.categories;
          const quantity = action.req?.data?.quantityAdded ?? 0;

          if (quantity > 0) {
            trackAddToCart({ ...product, productPriceData }, null, store.getState(), quantity);
            // when adding a product, track all existing added product services
            action?.req?.data?.entry?.serviceItemCodes?.forEach((serviceCode) => {
              trackAddService(product, store.getState(), serviceCode, quantity);
            });
          } else if (quantity < 0) {
            const absoluteQuantity = Math.abs(quantity);

            trackRemoveFromCart({ ...product, productPriceData }, absoluteQuantity, categories);
            // when a product is removed, we must also track all services that are implicitely removed
            action?.req?.data?.entry?.serviceItemCodes?.forEach((serviceCode) => {
              trackRemoveService(product, store.getState(), serviceCode, absoluteQuantity);
            });
          } else {
            // if no products were added/removed, the request updated some service, so we determine
            // what to track. the quantity for each individual service will always be 1
            const { oldServiceItemCodes = [], serviceItemCodes = [] } = action.payload;
            const addedServiceCodes = serviceItemCodes.filter((service) => !oldServiceItemCodes.includes(service));
            const removedServiceCodes = oldServiceItemCodes.filter((service) => !serviceItemCodes.includes(service));

            addedServiceCodes.forEach((serviceCode) => {
              trackAddService(product, store.getState(), serviceCode, action?.payload?.quantity);
            });

            removedServiceCodes.forEach((serviceCode) => {
              trackRemoveService(product, store.getState(), serviceCode, action?.payload?.quantity);
            });
          }
        }

        if (action.type.includes(DELETE_FROM_CART_SUCCESS)) {
          const productCode = action?.req?.data?.entry?.product?.code;
          const product = action?.req?.data?.entry?.product;
          const quantity = action?.req?.data?.quantityAdded;
          const productPriceData = store.getState()?.products[productCode].productPriceData;
          const categories = store.getState()?.categories;

          trackRemoveFromCart({ ...product, productPriceData }, Math.abs(quantity), categories);
          store
            .getState()
            ?.cart?.lastDeletedItem?.deleted?.serviceItemCodes?.forEach((serviceCode) =>
              trackRemoveService(product, store.getState(), serviceCode, Math.abs(quantity))
            );
        }

        // for login-page after cart
        if (action.type.includes(TRACK_CART_LOGIN)) {
          if (store.getState().user.uid === 'anonymous') {
            trackCheckoutLogin(store.getState());
          }
        }

        // for checkout page
        if (action.type.includes(TRACK_CHECKOUT)) {
          trackCheckout(store.getState());
        }

        // for order-confirmation
        if (action.type.includes(TRACK_PLACE_ORDER)) {
          const lastOrderId = action?.payload?.lastOrderId;
          trackPurchase(store.getState(), lastOrderId);
        }

        if (action.type.includes(CREATE_USER_SUCCESS)) {
          trackRegistration();
        }

        if (action.type.includes(CREATE_USER_FAILURE)) {
          trackFormError('registration', store.getState());
        }

        // track form errors here
        if (action.type.includes(SET_FORCE_VALIDATION_ERRORS_VISIBLE)) {
          const state = store.getState();
          const pathName = state?.routing?.locationBeforeTransitions?.pathname;

          // only track if validation errors occurred
          if (action?.value) {
            // track registration page
            if (pathName.includes(paths.registration)) {
              trackFormError('registration', state);
            }
            // track checkout page
            if (pathName.includes(paths.checkout)) {
              trackFormError('checkout', state);
            }
          }
        }

        // track accessories products
        if (action.type.includes(TRACK_ACCESSORIES)) {
          setTimeout(() => {
            // workaround so accessoires gets called after add-to-cart event - SWAT-1883
            trackAccessoriesProducts(action, store.getState());
          }, 1000);
        }

        // track watchlist products
        if (action.type.includes(TRACK_WATCHLIST)) {
          setTimeout(() => {
            // link needs to be called after view, see timeout in trackPageView - SWAT-1882
            trackWatchlistProducts(store.getState());
          }, 1000);
        }

        // track comparison products
        if (action.type.includes(TRACK_COMPARISON)) {
          setTimeout(() => {
            // link needs to be called after view, see timeout in trackPageView - SWAT-1882
            trackComparisonProducts(store.getState());
          }, 1000);
        }

        // track recommended products
        if (action.type.includes(TRACK_RECOMMENDED_PRODUCTS)) {
          setTimeout(() => {
            // link needs to be called after view, see timeout in trackPageView - SWAT-1882
            trackRecommendedProducts(
              store.getState(),
              action.payload.type,
              action.payload.boxLevel,
              action.payload.isSearch,
              action.payload.hasRecommendedProducts
            );
          }, 1000);
        }

        if (action.type.includes(SEARCH_AS_YOU_TYPE_SUCCESS)) {
          trackSearchAsYouType(store.getState());
        }

        if (action.type.includes(UPDATE_USER_CONSENTS_SUCCESS)) {
          trackDsgvConsents();
        }
      }
    }
  } catch (ex) {
    console.error('Exception in tracking middleware', ex); // eslint-disable-line
  }
};

export default trackingMiddleware;
