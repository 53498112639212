import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router';
import styles from './BreadcrumbLink.scss';
import Icon, {ICON_KEYBOARD_ARROW_RIGHT} from 'components/atoms/Icon/Icon';
import {noop} from 'constants/noop/noop';

export default class BreadcrumbLink extends Component {
  static displayName = 'atoms/BreadcrumbLink';

  static propTypes = {
    name: PropTypes.string.isRequired,
    showArrow: PropTypes.bool,
    children: PropTypes.node.isRequired,
    href: PropTypes.string,
    onClick: PropTypes.func,
    pos: PropTypes.number,
    goBack: PropTypes.bool,
    useNativeLink: PropTypes.bool,
  };

  static defaultProps = {
    showArrow: true,
    href: '',
    pos: 0,
    onClick: noop,
    goBack: false,
    useNativeLink: false,
  };

  render() {
    return (
      <span className={(styles.wrapper, this.props.goBack ? styles.back : '')}>
        {this.props.useNativeLink ?
          <a href={this.props.href} title={this.props.name}>
            <span className={styles.name}>{this.props.children}</span>
          </a>
          :
          (this.props.href.length > 0 ? (
            <Link to={this.props.href} title={this.props.name}>
              <span className={styles.name}>{this.props.children}</span>
            </Link>
          ) : (
            <span onClick={this.props.onClick} className={styles.linkText}>
            {this.props.children}
          </span>
          ))}

        {this.props.showArrow ? <Icon className={styles.line} path={ICON_KEYBOARD_ARROW_RIGHT}/> : ''}
      </span>
    );
  }
}
