import { cloneElement, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setSearchString } from 'actions/uiActions/uiActions';
import { mapDirectLinks } from 'constants/suggestionMapper/suggestionMapper';
import { SearchHistoryContext } from 'contexts/SearchHistoryProvider/SearchHistoryProvider';

const mapStateToProps = (state) => ({
  pages: state?.cms?.navigation?.pages || {},
  categories: state?.categories || {},
});

const mapDispatchToProps = {
  setSearchString,
};

const SearchHistoryContainer = ({ children, pages, categories, closeSearch, setSearchString }) => {
  const { searchHistory, deleteSearchHistory, addSearchHistoryEntry, deleteSearchHistoryEntry } = useContext(
    SearchHistoryContext
  );

  const onHistoryClick = (link) => {
    if (link.type === 'search') {
      setSearchString(link.text);
    }
    addSearchHistoryEntry(link.text, link.url ?? link.uri);
    closeSearch();
  };

  const links = mapDirectLinks(searchHistory, pages, categories, true);

  return cloneElement(children, {
    deleteSearchHistoryEntry,
    deleteSearchHistory: deleteSearchHistory,
    historyLinks: links,
    onClick: onHistoryClick,
  });
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchHistoryContainer);

SearchHistoryContainer.displayName = 'containers/SearchHistoryContainer';

SearchHistoryContainer.propTypes = {
  children: PropTypes.element.isRequired,
  pages: PropTypes.object,
  categories: PropTypes.object,
  history: PropTypes.arrayOf(PropTypes.object),
  closeSearch: PropTypes.func,
  setSearchString: PropTypes.func,
};

SearchHistoryContainer.defaultProps = {
  children: {},
  pages: {},
  categories: {},
  closeSearch: () => {},
  setSearchString: () => {},
};
