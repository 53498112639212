import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import SubNavigationItem from 'components/atoms/SubNavigationItem/SubNavigationItem';
import SidebarNavigationHeader from 'components/atoms/SidebarNavigationHeader/SidebarNavigationHeader';
import bootstrap from 'scss/component.scss';
import styles from './SidebarNavigation.scss';

const SidebarNavigation = ({ items, text, baseRoute, currentPath }) => (
  <div
    itemScope
    itemType="https://schema.org/SiteNavigationElement"
    className={cx(bootstrap.colLg3, bootstrap.hiddenMdDown)}
  >
    <SidebarNavigationHeader text={text} route={baseRoute} />
    <ul className={styles.list}>
      {items.map((item, index) => (
        <SubNavigationItem
          key={index}
          link={item.url || ''}
          text={item.text}
          isActive={item.url === currentPath}
          openNewTab={item.openNewTab}
        />
      ))}
    </ul>
  </div>
);

SidebarNavigation.displayName = 'molecules/SidebarNavigation';
SidebarNavigation.propTypes = {
  items: PropTypes.array,
  text: PropTypes.string,
  baseRoute: PropTypes.string,
  currentPath: PropTypes.string,
};
SidebarNavigation.defaultProps = {
  items: [],
  text: '',
  baseRoute: '',
  currentPath: '',
};

export default SidebarNavigation;
