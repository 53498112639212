/**
 * @name cartEntriesCount
 * @description loops over cart entries to get the total count of all items in the cart
 * @author Daniel Grobelny
 */

const cartEntriesCount = (cartEntries) => {
  let entriesCount = 0;
  cartEntries.map((cartEntry) => (entriesCount += cartEntry.quantity));
  return entriesCount;
};

export default cartEntriesCount;
