import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getEnumValues } from 'constants/GetEnumValues/GetEnumValues';
import CmsElementProvider from 'components/organisms/CmsElementProvider/CmsElementProvider';
import { SLOTS } from 'constants/cms/slots';

export const contentTypes = {
  PAGES: 'pages',
  CATEGORIES: 'categories',
};

export const mapStateToProps = (state, ownProps) => {
  const { contentType, contentId, position } = ownProps;
  const cmsDatas = state?.cms?.[contentType]?.[contentId]?.[position]?.cmsDatas || [];

  return {
    elements: cmsDatas,
    comparisonHasError: state.comparison.hasError,
  };
};

const CmsContent = ({ contentType = contentTypes.PAGES, elements, fullWidth, contentId }) => (
  <CmsElementProvider contentId={contentId} contentType={contentType} fullWidth={fullWidth} elements={elements} />
);

CmsContent.displayName = 'containers/CmsContent';
CmsContent.propTypes = {
  contentId: PropTypes.string.isRequired,
  elements: PropTypes.array,
  contentType: PropTypes.oneOf(getEnumValues(contentTypes)),
  position: PropTypes.oneOf(getEnumValues(SLOTS)),
  fullWidth: PropTypes.bool,
};

export default connect(mapStateToProps)(CmsContent);
