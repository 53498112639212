import { useEffect, useState } from 'react';
import { TELESALES_MODE } from 'constants/storage/storageKeys';

const useTelesalesMode = () => {
  const [telesalesMode, setTelesalesMode] = useState(false);

  useEffect(() => {
    if (__CLIENT__ && localStorage?.getItem(TELESALES_MODE) !== null) {
      setTelesalesMode(true);
    } else {
      setTelesalesMode(false);
    }
  }, []);
  return telesalesMode;
};

export default useTelesalesMode;
