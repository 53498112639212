import queryString from 'query-string';
import { recoParamNames } from 'api/RecoAPI/RecoAPI';
import { DSGV_CONSENTS, ONLINE_ID } from 'constants/storage/storageKeys';

const hasPersonalizationEnabled = () => {
  if (__CLIENT__) {
    return JSON.parse(localStorage.getItem(DSGV_CONSENTS))?.personalization ?? 'true';
  }

  return 'true';
};

const composeRecoProductsUrl = (productId, categoryId, locale, currentBasket, sessionId) => {
  const baseUrl = window?.__ENV_VARIABLES__?.recoUrl || '';
  const prefix = window?.__ENV_VARIABLES__?.recoPrefix || '';
  const server = window?.__ENV_VARIABLES__?.recoServer || '';
  const personalization = hasPersonalizationEnabled();
  let paramString = `?sid=${sessionId}&pid=${productId}&${recoParamNames.CATEGORY_ID}=${categoryId}&titlekey=${locale}&currentbasket=${currentBasket}&${recoParamNames.TRACKING}=${personalization}`;

  //onlineId (Needs to be sent in parameter 'userid' for now because prudsys can't handle the variable change. See SWAT-4528)
  const onlineId = localStorage.getItem(ONLINE_ID) || sessionId;
  if (onlineId && personalization) {
    paramString += `&userid=${onlineId}`;
  } else if (onlineId && !personalization) {
    paramString += `&userid=${sessionId}`;
  }

  return `${baseUrl}/${prefix}/${server}/plugins/exec/prudsys/prudsys/core/recommendation/pdp${paramString}`;
};

const composeRecoTopProductsUrl = (sessionId, type, locale, currentBasket, params = {}) => {
  const baseUrl = window?.__ENV_VARIABLES__?.recoUrl || '';
  const prefix = window?.__ENV_VARIABLES__?.recoPrefix || '';
  const server = window?.__ENV_VARIABLES__?.recoServer || '';
  const personalization = hasPersonalizationEnabled();

  // format spaces in search term for prudsys learning (SWAT-6355)
  if (params.searchterm) {
    const decodedParam = decodeURIComponent(params.searchterm);
    params.searchterm = decodedParam.replaceAll(' ', '-');
  }

  let recoParams = queryString.stringify({
    sid: sessionId,
    titlekey: locale,
    currentbasket: currentBasket,
    tracking: personalization ?? 'true',
    ...params,
  });

  //onlineId (Needs to be sent in parameter 'userid' for now because prudsys can't handle the variable change. See SWAT-4528)
  const onlineId = localStorage.getItem(ONLINE_ID) || sessionId;
  if (onlineId && personalization) {
    recoParams += `&userid=${onlineId}`;
  } else if (onlineId && !personalization) {
    recoParams += `&userid=${sessionId}`;
  }
  return `${baseUrl}/${prefix}/${server}/plugins/exec/prudsys/prudsys/core/recommendation/${type}?${recoParams}`;
};

/**
 * Composes the reco engine URL necessary for tracking the different events and
 * enriches the url with config data
 *
 * @param trackEvent {string} - The event which should be tracked
 * @param sessionId {string} - The current reco session ID
 * @param [paramString] {string} - A string containing all parameters with their respective values
 *
 * @returns {string}
 */
const composeRecoTrackingUrl = (trackEvent, sessionId, paramString) => {
  if (!trackEvent || !sessionId) {
    throw new Error('composeRecoTrackingUrl did not receive all necessary params.');
  } else if (typeof trackEvent.valueOf() !== 'string' || typeof sessionId.valueOf() !== 'string') {
    throw new Error('composeRecoTrackingUrl did receive parameters that are not strings.');
  }
  const baseUrl = window?.__ENV_VARIABLES__?.recoUrl || '';
  const prefix = window?.__ENV_VARIABLES__?.recoPrefix || '';
  const server = window?.__ENV_VARIABLES__?.recoServer || '';
  const personalization = hasPersonalizationEnabled();

  let urlParams = `?sid=${sessionId}&${recoParamNames.TRACKING}=${personalization}`;
  if (paramString) {
    urlParams += `&${paramString}`;
  }
  //onlineId (Needs to be sent in parameter 'userid' for now because prudsys can't handle the variable change. See SWAT-4528)
  const onlineId = localStorage.getItem(ONLINE_ID) || sessionId;
  if (onlineId && personalization) {
    urlParams += `&userid=${onlineId}`;
  } else if (onlineId && !personalization) {
    urlParams += `&userid=${sessionId}`;
  }

  return `${baseUrl}/${prefix}/${server}/plugins/exec/prudsys/prudsys/event/${trackEvent}${urlParams}`;
};

export { composeRecoTrackingUrl, composeRecoProductsUrl, composeRecoTopProductsUrl };
