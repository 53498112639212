import React, { useState } from 'react';
import PropTypes from 'prop-types';

import CategorySection from 'components/molecules/MobileDrilldown/CategorySection';
import AccountSection from 'components/molecules/MobileDrilldown/AccountSection';
import AccountSectionContainer from 'containers/AccountSectionContainer/AccountSectionContainer';
import CmsSection from 'components/molecules/MobileDrilldown/CmsSection';

import language from 'constants/language/language';
import { types } from 'containers/FooterColumnCmsContainer/FooterColumnCmsContainer';
import { cypressAttributes } from 'constants/cypress/cypress';

import styles from './MobileDrilldown.scss';

import cx from 'classnames';
import StoresSection from './StoresSection';
import PromotionsSection from './PromotionsSection';
import { CSSTransition } from 'react-transition-group';
import LanguageSelect from '../LanguageSelect/LanguageSelect';
import MobileNavigationItem from 'components/atoms/MobileNavigationItem/MobileNavigationItem';
import BlogSection from './BlogSection';
import useTelesalesMode from 'hooks/useTelesalesMode';
import isTouchDevice from 'constants/isTouchDevice/isTouchDevice';
import B2BSection from './B2BSection';
import useFeatureToggle from 'hooks/useFeatureToggle';

const MobileDrilldown = ({ categories, cmsPages, categoryTree, categoryCode, route, isResetToStart }) => {
  const drilldownPortalId = 'mobile-drilldown-portal';
  const [currentCategory, setCurrentCategory] = useState(null);
  const [animationStyle, setAnimationStyle] = useState('forward');
  const [animate, setAnimate] = useState(false); // this use state hook is used to control the animation of categories sliding
  const [languageBuffer, setLanguageBuffer] = useState(false);
  const b2bEnabled = /true/.test(useFeatureToggle('b2bToggle'));

  const enter =
    animationStyle === 'reverse' ? styles.drilldownContainerEnterReverse : styles.drilldownContainerEnterForward;
  const active =
    animationStyle === 'reverse'
      ? styles.drilldownContainerEnterActiveReverse
      : styles.drilldownContainerEnterActiveForward;
  const done =
    animationStyle === 'reverse'
      ? styles.drilldownContainerEnterDoneReverse
      : styles.drilldownContainerEnterDoneForward;

  const handleAnimation = (style) => {
    setAnimationStyle(style);
    setAnimate(true);
    if (style === 'initial' && isTouchDevice()) {
      setLanguageBuffer(true);
    }
  };

  const isTelesalesMode = useTelesalesMode();

  return (
    <div
      data-cy={cypressAttributes.mobileNavigation.drilldownNavigation}
      className={cx(styles.mobileDrilldown, animationStyle === 'initial' ? styles.drilldownContainerWrapper : '')}
    >
      <CSSTransition
        in={animate}
        timeout={200}
        classNames={{
          enter: !animate ? '' : enter,
          enterActive: !animate ? '' : active,
          enterDone: !animate ? '' : done,
        }}
        onEntered={() => setAnimate(false)}
      >
        <div className={styles.drilldownContainer} id="mobile-drilldown-portal"></div>
      </CSSTransition>
      <CategorySection
        categories={categories}
        drilldownPortal={drilldownPortalId}
        handleAnimation={handleAnimation}
        categoryTree={categoryTree}
        categoryCode={categoryCode}
        route={route}
        currentCategory={currentCategory}
        setCurrentCategory={setCurrentCategory}
        isResetToStart={isResetToStart}
      />
      <PromotionsSection isResetToStart={isResetToStart} />
      <StoresSection isResetToStart={isResetToStart} />
      <BlogSection
        title={language('navigation.blog')}
        pages={cmsPages}
        type={types.BLOG}
        isResetToStart={isResetToStart}
        pos={4}
      />
      {b2bEnabled && <B2BSection title={language('header.b2bHeaderText')} isResetToStart={isResetToStart} pos={5} />}
      <CmsSection
        title={language('footer.company')}
        type={types.COMPANY}
        pages={cmsPages}
        pos={b2bEnabled ? 6 : 5}
        isResetToStart={isResetToStart}
      />
      <CmsSection
        title={language('footer.service')}
        type={types.SERVICE}
        pages={cmsPages}
        pos={b2bEnabled ? 7 : 6}
        isResetToStart={isResetToStart}
      />
      {!isTelesalesMode && (
        <AccountSectionContainer>
          <AccountSection
            drilldownPortal={drilldownPortalId}
            cmsPages={cmsPages}
            handleAnimation={handleAnimation}
            animationStyle={animationStyle}
            isResetToStart={isResetToStart}
          />
        </AccountSectionContainer>
      )}
      <div
        className={cx(styles.mobileLanguages, {
          [styles.mobileLanguagesBuffer]: languageBuffer,
        })}
      >
        <MobileNavigationItem className={styles.navigationItem}>
          <LanguageSelect useLongValue />
        </MobileNavigationItem>
      </div>
    </div>
  );
};

MobileDrilldown.propTypes = {
  categories: PropTypes.object,
  cmsPages: PropTypes.object,
  categoryTree: PropTypes.array,
  categoryCode: PropTypes.string,
  route: PropTypes.string,
  isHome: PropTypes.bool,
  isResetToStart: PropTypes.bool,
};

MobileDrilldown.defaultProps = {
  categories: {},
  cmsPages: {},
};

export default MobileDrilldown;
