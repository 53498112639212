import PropTypes from 'prop-types';
import language from 'constants/language/language';
import { link, trackInteraction } from 'tracking/tracking';
import { dataTrackingRaw } from 'constants/trackingAttributes/dataTracking';
import { createCategoryUtagData, createFilterUtagData, createUtagDataHead } from 'tracking/trackingHelper';
import { postLog } from 'api/promiseMiddleware';

export const getFacetRegExp = (facetCode) => new RegExp(`%3A${facetCode}(_max|_min)*%3A.+?(?=%3A|$)`, 'g');
export const facetTypes = {
  SLIDER: 'SLIDER',
  SINGLE_VALUE: 'SINGLE_VALUE',
  TOGGLE: 'TOGGLE',
};

export const CategoryPropType = PropTypes.shape({
  code: PropTypes.string,
  count: PropTypes.number,
  name: PropTypes.string,
  query: PropTypes.string,
  selected: PropTypes.bool,
});
export const FacetPropType = PropTypes.shape({
  category: PropTypes.bool,
  code: PropTypes.string,
  multiSelect: PropTypes.bool,
  name: PropTypes.string,
  priority: PropTypes.number,
  values: PropTypes.arrayOf(CategoryPropType),
  selectedValues: PropTypes.arrayOf(CategoryPropType),
  visible: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(facetTypes)),
});
export const getFilterPreviewText = (facet) => {
  if (facet.type === 'SLIDER') {
    const minMaxRange = facet.minMaxRange;
    if (minMaxRange.min === minMaxRange.minSearched && minMaxRange.max === minMaxRange.maxSearched) {
      return '';
    }

    if (facet.code === 'price') {
      return `${language('productsOverview.numberResults.of')} ${minMaxRange?.minSearched.toLocaleString(
        'de-CH'
      )} ${language('discount.savingsCurrency')} ${language(
        'productsOverview.numberResults.to'
      )} ${minMaxRange?.maxSearched.toLocaleString('de-CH')} ${language('discount.savingsCurrency')}`;
    }
    return `${language('productsOverview.numberResults.of')} ${minMaxRange?.minSearched} ${language(
      'productsOverview.numberResults.to'
    )} ${minMaxRange?.maxSearched.toLocaleString('de-CH')}`;
  }

  const selectedValues =
    facet?.type === 'TOGGLE'
      ? facet?.values.filter(({ selected, code }) => selected === true && code !== 'false')
      : facet?.selectedValues;
  if (facet.code === 'categoryPath') {
    const selectedCategoryFacet = selectedValues[selectedValues.length - 1];
    return selectedCategoryFacet?.name || '';
  }

  const [first, second, ...rest] = selectedValues;

  if (rest.length > 0) {
    return `${first.name}, ${second.name}, +${rest.length}`;
  }

  if (first && second) {
    return `${first.name}, ${second.name}`;
  }

  if (first) {
    return first.name;
  }

  return '';
};

export const facetHasFilters = (facet) => {
  if (!facet) {
    return false;
  }

  if (facet?.type === facetTypes.TOGGLE) {
    return Boolean(facet.values.find(({ selected, code }) => selected === true && code !== 'false'));
  }

  return facet.type === facetTypes.SLIDER
    ? facet.minMaxRange.max !== facet.minMaxRange.maxSearched || facet.minMaxRange.min !== facet.minMaxRange.minSearched
    : facet.selectedValues?.length > 0;
};

export const checkDisplayFacet = (facets) => {
  return facets.filter((facet) => {
    const isToggleFacet = facet.type === facetTypes.TOGGLE;
    const hasMultipleValues = facet?.values?.length > 1;
    const hasSingleTrueValue = facet?.values?.length === 1 && facet?.values?.some((f) => f.code === 'true');

    return !isToggleFacet || hasMultipleValues || hasSingleTrueValue;
  });
};

export const attachToArgument = (func, ...sources) => (arg) => func(Object.assign(arg, ...sources));

export const extractFilters = (queryString) => {
  const splitQuery = queryString /*.split('query=')[1]*/
    .split('%3A')
    .slice(2); //remove serach and sort

  let filters = {};
  for (let i = 0; i < splitQuery.length; i += 2) {
    const key = splitQuery[i];
    const value = splitQuery[i + 1];
    filters[key] = filters[key] ? `${filters[key]}+${value}` : value;
  }

  return filters;
};

const omit = (filters, filtersToOmit) => {
  const result = { ...filters };
  for (const key of Object.keys(filtersToOmit)) {
    if (filters[key] !== filtersToOmit[key]) {
      const valuesToOmit = filtersToOmit[key]?.split('+');
      const newValues = filters[key]
        ?.split('+')
        .filter((x) => !valuesToOmit.includes(x))
        .join('+');

      if (newValues) {
        result[key] = newValues;
        continue;
      }
    }

    delete result[key];
  }
  return result;
};

export const compareQueries = (newQuery, oldQuery) => {
  const newFilters = extractFilters(newQuery);
  const oldFilters = extractFilters(oldQuery);
  return omit(oldFilters, newFilters);
};

export const trackRemoveFilter = (newQuery, oldQuery, index) => {
  const diff = compareQueries(newQuery, oldQuery);
  trackInteraction(
    dataTrackingRaw({
      TYPE: 'filter:remove',
      INTERACTION: 'click',
      FUNCTION: 'filter',
      LOCATION: index,
      ATTRIBUTE_1: Object.keys(diff).join(),
      ATTRIBUTE_2: Object.values(diff).join(),
    })
  );
};

export const trackResetAllFacets = () => {
  trackInteraction(
    dataTrackingRaw({
      TYPE: 'filter:reset',
      INTERACTION: 'click',
      FUNCTION: 'filter',
    })
  );
};

export const trackFacetModal = (facetCode, index, open) => {
  trackInteraction(
    dataTrackingRaw({
      TYPE: `filter:${open ? 'open' : 'close'}`,
      INTERACTION: 'click',
      LOCATION: index,
      FUNCTION: 'filter',
      ATTRIBUTE_1: facetCode,
    })
  );
};

export const trackSelectFilter = (filter, facetCode, location, innerlocation) => {
  trackInteraction(
    dataTrackingRaw({
      TYPE: 'filter:select',
      INTERACTION: 'click',
      LOCATION: location,
      INNERLOCATION: innerlocation,
      FUNCTION: 'select',
      ATTRIBUTE_1: facetCode,
      ATTRIBUTE_2: filter.map(({ code }) => code).join(),
    })
  );
};

export const trackSliderChange = (selectedMin, selectedMax, facetCode, location) => {
  trackInteraction(
    dataTrackingRaw({
      TYPE: 'filter:select',
      INTERACTION: 'slide',
      LOCATION: location,
      FUNCTION: 'select',
      ATTRIBUTE_1: facetCode,
      ATTRIBUTE_2: `min:${selectedMin}, max:${selectedMax}`,
    })
  );
};

export const getSelectedFilters = (facets) => {
  facets = facets?.filter((facet) => facet.code !== 'categoryPath')?.filter(facetHasFilters) || [];
  //Removes the category path from the facet code
  //e.g. energyefficiencyclassenev2020111006k_00002415 -> energyefficiencyclassenev
  const categoryPathRegex = /\d+k(_.*)*/;

  let result = {};
  for (const facet of facets) {
    const key = facet.code.replace(categoryPathRegex, '');
    const value =
      facet.type === facetTypes.SLIDER
        ? `${facet.minMaxRange.minSearched}-${facet.minMaxRange.maxSearched}`
        : facet.selectedValues.map((value) => value.code).join(';');

    result[key] = value;
  }
  return result;
};

export const trackSubmitFilter = (query, categoryTree) => {
  try {
    link({ ...createUtagDataHead(), ...createFilterUtagData(query), ...createCategoryUtagData(query, categoryTree) });
  } catch (ex) {
    postLog(ex);
  }
};
