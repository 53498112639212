// @flow
import React from 'react';

import FlashMessage from 'components/atoms/FlashMessage/FlashMessage';
import { i18n } from 'constants/language/language';
import { types as flashMessageTypes } from 'constants/flashMessages/flashMessages';
import styles from './AccountRatingStatusBox.scss';

import type { IRating } from 'flow/ratings';

type IProps = {
  ratings?: Array<IRating>,
  onItemClick?: (IRating) => void,
};

const AccountRatingStatusBox = ({ ratings = [], onItemClick }: IProps) => {
  if (ratings.length === 0) {
    return null;
  }

  const onClick = (rating) => () => {
    if (onItemClick) onItemClick(rating);
  };

  return (
    <FlashMessage type={flashMessageTypes.WARNING}>
      <p className={styles.msg}>{i18n('myAccountRatings.rejectedInfo')}</p>
      <ul className={styles.list}>
        {ratings.map((rating) => (
          <li key={rating.productCode}>
            <span onClick={onClick(rating)} className={styles.link}>
              {rating.productName}
            </span>
          </li>
        ))}
      </ul>
    </FlashMessage>
  );
};

export default AccountRatingStatusBox;
