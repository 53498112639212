import language, { getLocale } from 'constants/language/language';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import {
  accountAddresses,
  accountNotifications,
  accountOrders,
  accountRatings,
  accountReturns,
  accountUserdata,
} from 'constants/routePaths/routePathCombinations';

const myAccountDrilldown = (userName) => ({
  id: 'myAccount-menu',
  title: `${userName}`,
  link: null,
  submenu: [
    {
      id: 'myAccount-orders',
      title: language('myAccountOrders.header'),
      link: mapPathToLocalizedUrl(getLocale(), accountOrders),
      submenu: null,
    },
    {
      id: 'myAccount-ratings',
      title: language('myAccountRatings.header'),
      link: mapPathToLocalizedUrl(getLocale(), accountRatings),
      submenu: null,
    },
    {
      id: 'myAccount-data',
      title: language('myAccountUserData.header'),
      link: mapPathToLocalizedUrl(getLocale(), accountUserdata),
      submenu: null,
    },
    {
      id: 'myAccount-addresses',
      title: language('myAccountAddresses.header'),
      link: mapPathToLocalizedUrl(getLocale(), accountAddresses),
      submenu: null,
    },
    {
      id: 'myAccount-returns',
      title: language('returnsOverview.header'),
      link: mapPathToLocalizedUrl(getLocale(), accountReturns),
      submenu: null,
    },
    {
      id: 'myAccount-notifications',
      title: language('myAccountNotifications.header'),
      link: mapPathToLocalizedUrl(getLocale(), accountNotifications),
      submenu: null,
    },
  ],
});

export default myAccountDrilldown;
