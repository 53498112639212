import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import language, { getLocale } from 'constants/language/language';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import { Row, Col } from 'components/atoms/Grid/Grid';
import Headline from 'components/atoms/Headline/Headline';
import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';
import Spinner from 'components/atoms/Spinner/Spinner';
import SpinnerOverlay from 'components/molecules/SpinnerOverlay/SpinnerOverlay';
import styles from './IndividualAdsOptout.scss';
import { apiStatus } from 'constants/apiStatus/apiStatus';

const IndividualAdsOptout = ({ handleUnsubscribe, newsletterUID, showError }) => {
  useEffect(() => {
    handleUnsubscribe();
  }, []);

  if (newsletterUID === undefined && !showError) {
    return <SpinnerOverlay />;
  }

  return (
    <Row>
      <Col lg={8} offsetLg={2} className={styles.wrapper}>
        {showError ? (
          <>
            <Headline tag={Headline.tags.H1}>{language('newsletterOptout.titleFailure')}</Headline>
            <div className={styles.failureFlexContainer}>
              <div className={styles.failureContainer}>
                <div className={styles.spinnerContainer}>
                  <Spinner status={apiStatus.failure} />
                </div>
                <div className={styles.messageContainer}>
                  <span>{language('newsletterOptout.failure')}</span>
                </div>
              </div>
            </div>
            <ButtonLink variety={ButtonLink.varieties.stroked} href={mapPathToLocalizedUrl(getLocale())}>
              {language('newsletterFeedback.backToHomepage')}
            </ButtonLink>
          </>
        ) : (
          <>
            <Headline tag={Headline.tags.H1}>{language('newsletterFeedback.unsubscribe.heading')}</Headline>
            <p className={styles.subhead}>
              <strong>{language('newsletterFeedback.unsubscribe.subhead')}</strong>
            </p>
            <p>{language('newsletterFeedback.unsubscribe.personalizedText')}</p>
            <ButtonLink variety={ButtonLink.varieties.stroked} href={mapPathToLocalizedUrl(getLocale())}>
              {language('newsletterFeedback.backToHomepage')}
            </ButtonLink>
          </>
        )}
      </Col>
    </Row>
  );
};

IndividualAdsOptout.displayName = 'templates/IndividualAdsOptout';
IndividualAdsOptout.propTypes = {
  handleUnsubscribe: PropTypes.func,
  newsletterUID: PropTypes.string,
  showError: PropTypes.bool,
};

export default IndividualAdsOptout;
