export const calculatePages = (page = 1, total) => {
  const maxSize = Math.min(5, total);
  const result = [];
  let upwards = true;

  // add current element
  result.push(page > total ? total : page);

  while (result.length < maxSize) {
    if (upwards) {
      if (result[result.length - 1] < total) {
        // add element at end if possible
        result.push(result[result.length - 1] + 1);
      }
    } else {
      if (result[0] > 1) {
        // add element at beginning if possible
        result.unshift(result[0] - 1);
      }
    }
    // change directions
    upwards = !upwards;
  }
  return result;
};
