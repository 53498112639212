import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { getEnv } from 'config/config';

import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';
import Icon, { ICON_READ_MORE } from 'components/atoms/Icon/Icon';
import Markdown from 'components/atoms/Markdown/Markdown';
import { Col, Row } from 'components/atoms/Grid/Grid';
import Headline from 'components/atoms/Headline/Headline';

import i18n from 'constants/language/language';

import dataTracking from 'constants/trackingAttributes/dataTracking';
import styles from './RichMediaContent.scss';

const MAX_SIZE_NEW_ELEMENTS = 6;

const RichMediaContent = ({ title = '', text = '', elements = [], trackingAttributes = {}, footNotes = [] }) => {
  const [maxSize, setMaxSize] = useState(MAX_SIZE_NEW_ELEMENTS);
  const [timesPressed, setTimesPressed] = useState(1);

  const loadMoreBricks = () => {
    setMaxSize(maxSize + MAX_SIZE_NEW_ELEMENTS);
    setTimesPressed(timesPressed + 1);
  };

  return (
    <>
      <Headline tag={Headline.tags.H2}>{title}</Headline>
      <p className={styles.text}>
        <Markdown options={{ forceInline: true }} areLinksDisabled>
          {text}
        </Markdown>
      </p>
      <Row>
        {elements.slice(0, maxSize).map((brick, index) => (
          <Col sm={6} lg={4} key={index}>
            <div className={styles.brick}>
              <div className={styles.imgWrapper}>
                <img alt={brick.imageAltText} src={`${getEnv('web')}${brick.image}`} className={styles.img} />
              </div>
              <h4 className={styles.brickTitle}>
                <Markdown areLinksDisabled options={{ forceInline: true }}>
                  {brick.title}
                </Markdown>
              </h4>
              <p className={styles.brickText}>
                <Markdown areLinksDisabled options={{ forceInline: true }}>
                  {brick.text}
                </Markdown>
              </p>
            </div>
          </Col>
        ))}
      </Row>
      {footNotes.map((note, index) => (
        <p key={index} className={styles.footNotes}>
          <Markdown options={{ forceInline: true }}>{note}</Markdown>
        </p>
      ))}
      {maxSize < elements.length && (
        <div className={styles.button}>
          <ButtonLink
            variety={ButtonLink.varieties.stroked}
            onClick={loadMoreBricks}
            data-tracking={dataTracking({
              TYPE: 'richmedia:showmore',
              INTERACTION: 'click',
              INNERLOCATION: timesPressed,
              FUNCTION: 'apply',
              SKU: trackingAttributes.sku,
            })}
          >
            <span className={styles.icon}>
              <Icon path={ICON_READ_MORE} />
            </span>
            {i18n('common.more')}
          </ButtonLink>
        </div>
      )}
    </>
  );
};

RichMediaContent.displayName = 'organisms/RichMediaContent';

RichMediaContent.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  elements: PropTypes.array,
  trackingAttributes: PropTypes.object,
  footNotes: PropTypes.array,
};

export default RichMediaContent;
