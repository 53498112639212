import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import language, { getLocale } from 'constants/language/language';
import { accountLogin } from 'constants/routePaths/routePathCombinations';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import WatchlistProduct from 'components/molecules/WatchlistProduct/WatchlistProduct';
import { Container } from 'components/atoms/Grid/Grid';
import styles from './WatchlistFull.scss';
import { productClick } from 'actions/trackingActions/trackingActions';

const accountLoginRedirect = () => ({
  pathname: mapPathToLocalizedUrl(getLocale(), accountLogin),
  state: { skin: 'watchlist' },
});

const mapDispatchToProps = {
  productClick: productClick,
};

const Watchlist = ({
  categories = {},
  isLoggedIn = false,
  products = [],
  removeFromWatchlist = () => {},
  productClick,
}) => (
  <Container className={styles.productsWrapper}>
    {!isLoggedIn && (
      <div className={styles.saveText}>
        {language('watchlist.saveText')}
        <Link to={accountLoginRedirect()}>{language('watchlist.loginText')}</Link>
      </div>
    )}
    {products.map((product) => (
      <WatchlistProduct
        product={product}
        key={product.code}
        removeProduct={removeFromWatchlist}
        categories={categories}
        onProductClick={() => productClick(product, '')}
      />
    ))}
  </Container>
);

Watchlist.displayName = 'templates/Watchlist';
Watchlist.propTypes = {
  categories: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  products: PropTypes.array,
  removeFromWatchlist: PropTypes.func,
  productClick: PropTypes.func,
};

export default connect(undefined, mapDispatchToProps)(Watchlist);
