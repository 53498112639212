import React from 'react';
import PropTypes from 'prop-types';

import { noop } from 'constants/noop/noop';
import { THEME_SEARCH } from 'constants/trackingAttributes/trackingAttributes';

import ThemeSuggestion from 'components/molecules/ThemeSuggestion/ThemeSuggestion';

import styles from './ThemeSuggestionList.scss';

const ThemeSuggestionList = ({
  directlinks = [],
  activeIndex = -1,
  searchTerm = '',
  autocompleteLength = 0,
  closeSearch = noop,
  trackSearch = noop,
  addHistoryEntry = () => {},
}) => {
  if (directlinks.length === 0) return null;

  const onClick = (searchTerm, link) => {
    addHistoryEntry(link.text, link.url);
    trackSearch(searchTerm, THEME_SEARCH);
    closeSearch();
  };

  return (
    <ul className={styles.list}>
      {directlinks.map((link, i) => (
        <li key={link.url}>
          <ThemeSuggestion
            directlink={link}
            searchTerm={searchTerm}
            isActive={i + autocompleteLength === activeIndex}
            onClick={onClick}
          />
        </li>
      ))}
    </ul>
  );
};

ThemeSuggestionList.displayName = 'molecules/ThemeSuggestionList';
ThemeSuggestionList.propTypes = {
  directlinks: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  activeIndex: PropTypes.number,
  searchTerm: PropTypes.string,
  autocompleteLength: PropTypes.number,
  closeSearch: PropTypes.func,
  trackSearch: PropTypes.func,
  addHistoryEntry: PropTypes.func,
};

export default ThemeSuggestionList;
