import React from 'react';
import PropTypes from 'prop-types';
import ProductContainer from 'containers/Product/Product';
import RecoTracking from 'containers/RecoTracking/RecoTracking';
import Slider from 'components/organisms/Slider/Slider';
import TeaserProduct, { CONTEXTS } from 'components/molecules/TeaserProduct/TeaserProduct';
import styles from './ProductsBoxSwiper.scss';

const ProductsBoxSwiper = ({ visibleElements = [], cyData }) => {
  const swiperConfig = {
    breakpoints: {
      0: {
        slidesPerView: 1,
        spacesBetween: 0,
      },
      768: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
      1200: {
        slidesPerView: 4,
      },
    },
    spaceBetween: 20,
    grabCursor: true,
  };

  const getProductItems = (elements) =>
    elements.map((element, index) => (
      <ProductContainer
        key={index}
        productCode={element.code}
        productAttributes={{ elementTitle: element.elementTitle }}
      >
        <RecoTracking>
          <TeaserProduct context={CONTEXTS.swiper} />
        </RecoTracking>
      </ProductContainer>
    ));

  return (
    <div className={styles.wrapper}>
      <Slider
        cyData={cyData}
        slides={getProductItems(visibleElements)}
        breakpoints={swiperConfig.breakpoints}
        slidesPerView={swiperConfig.slidesPerView}
        spaceBetween={swiperConfig.spaceBetween}
        grabCursor={swiperConfig.grabCursor}
      />
    </div>
  );
};

ProductsBoxSwiper.displayName = 'molecules/ProductsBoxSwiper';
ProductsBoxSwiper.propTypes = {
  visibleElements: PropTypes.array,
  cyData: PropTypes.string,
};

export default ProductsBoxSwiper;
