// @flow
import restClient from 'api/RestClient/RestClient';
import getStorage from 'constants/storage/storage';
import { LAST_RATING_DATE } from 'constants/storage/storageKeys';
import { resourceKeys } from 'constants/restResources/restResources';

import type { IRatingList, ILoadRatings, IUpdateRatings, ICreateRatings, ILoadProductRatings } from 'flow/ratings';

const storage = getStorage(true);
const RATINGS_RESOURCE = resourceKeys.RATINGS_RESOURCE;

/**
 * Loads the summary of rated, unrated and rejected ratings
 */
const getCustomerRatingsSummary = (): Promise<any> => restClient.get(RATINGS_RESOURCE, `/customer?reviewType=ALL`);

/**
 * Loads a list of ratings
 */
const getCustomersRatings = ({
  type = 'rated',
  page = 1,
  pageSize = 10,
}: ILoadRatings): Promise<{ data: IRatingList }> => {
  const reviewType = type === 'rated' ? 'REVIEWED' : 'REVIEWABLE';
  const params = [`reviewType=${reviewType}`, `currentPage=${page}`, `pageSize=${pageSize}`];

  return restClient.get(RATINGS_RESOURCE, `/customer?${params.join('&')}`);
};

/**
 * Loads a single rating by its product code
 */
const getCustomersRatingByProduct = (productCode: string): Promise<{ reviews: IRatingList }> =>
  restClient.get(RATINGS_RESOURCE, `/customer/product/${productCode}`);

/**
 * Loads a list of ratings for a specific product
 */
const getProductRatings = (
  productCode: string,
  { order = 'DATE_DESC', page = 1, pageSize = 10 }: ILoadProductRatings
): Promise<IRatingList> =>
  restClient.get(RATINGS_RESOURCE, `/product/${productCode}?order=${order}&currentPage=${page}&pageSize=${pageSize}`);

/**
 * Creates a new rating
 */
const createRating = (params: ICreateRatings, productCode: string): Promise<any> => {
  storage.setItem(LAST_RATING_DATE, Date.now());
  return restClient.post(RATINGS_RESOURCE, `/${productCode}`, params);
};

/**
 * Updates an existing rating
 */
const updateRating = (productCode: string, params: IUpdateRatings): Promise<any> => {
  storage.setItem(LAST_RATING_DATE, Date.now());
  return restClient.patch(RATINGS_RESOURCE, `/${productCode}`, params);
};

/**
 * Deletes a single rating
 */
const deleteRating = (productCode: string): Promise<any> => {
  storage.setItem(LAST_RATING_DATE, Date.now());
  return restClient.del(RATINGS_RESOURCE, `/${productCode}`);
};

export default {
  getCustomerRatingsSummary,
  getCustomersRatings,
  getCustomersRatingByProduct,
  getProductRatings,
  createRating,
  updateRating,
  deleteRating,
};
