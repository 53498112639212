import { Component, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

export const mapStateToProps = (state) => {
  const watchlistApiStatus = state?.watchlist?.apiStatus;

  return {
    watchlistApiStatus,
  };
};

class ProductInfoMainContainer extends Component {
  static displayName = 'containers/ProductInfoMainContainer';

  static propTypes = {
    children: PropTypes.node.isRequired,
    watchlistApiStatus: PropTypes.string,
  };

  render() {
    const { children, watchlistApiStatus } = this.props;

    return cloneElement(children, {
      watchlistApiStatus,
    });
  }
}

export default connect(mapStateToProps)(ProductInfoMainContainer);
