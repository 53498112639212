import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid/v1';
import { cypressAttributes } from 'constants/cypress/cypress';
import Markdown from 'components/atoms/Markdown/Markdown';
import InputCheckbox from 'components/atoms/InputCheckbox/InputCheckbox';
import PlusBox from 'components/atoms/PlusBox/PlusBox';
import styles from './AddService.scss';

const AddService = ({
  id = uuid(),
  code,
  name,
  description = '',
  price,
  handleChange,
  selected,
  incompatibleServiceItemCodes = [],
}) => (
  <div className={styles.item}>
    <InputCheckbox
      id={`formControl-${id}-checkbox`}
      onClick={() => handleChange(code, incompatibleServiceItemCodes)}
      selected={selected}
      checkboxPos={InputCheckbox.checkboxPositions.TOP}
    >
      <div className={styles.service} data-cy={cypressAttributes.additionalServices.labelAddService}>
        <div className={styles.name}>
          {name}{' '}
          <div className={styles.plusBox}>
            <PlusBox>
              <Markdown>{description}</Markdown>
            </PlusBox>
          </div>
        </div>
        <strong id={`container-${id}-price`} className={styles.price}>
          {price}
        </strong>
      </div>
    </InputCheckbox>
  </div>
);

AddService.displayName = 'molecules/AddService';

AddService.propTypes = {
  id: PropTypes.string,
  code: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  price: PropTypes.string.isRequired,
  incompatibleServiceItemCodes: PropTypes.array,
  handleChange: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default AddService;
