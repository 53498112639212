import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { setMainMenuIsOpen, setSearchFocus } from 'actions/uiActions/uiActions';

export const mapStateToProps = (state) => {
  const pathnameLength = state?.routing?.locationBeforeTransitions?.pathname?.length || 0;
  const isHome = pathnameLength <= 4;
  const categories = state?.categories || {};
  return {
    isOpen: state.ui.mainMenu.isOpen,
    breakpoint: state.ui.breakpoint,
    cmsPages: state?.cms?.navigation?.pages,
    isHome,
    categories,
    route: state?.routing?.locationBeforeTransitions?.pathname || '',
    categoryTree: state?.productsQueries?.currentQuery?.categoryTree,
    categoryCode: state?.productsQueries?.currentQuery?.categoryCode,
  };
};

export const mapDispatchToProps = { setMainMenuIsOpen, setSearchFocus };

export class MainMenu extends Component {
  static displayName = 'containers/MainMenu';

  static propTypes = {
    isOpen: PropTypes.bool,
    children: PropTypes.node.isRequired,
    setMainMenuIsOpen: PropTypes.func,
    setSearchFocus: PropTypes.func,
    breakpoint: PropTypes.string,
    isHome: PropTypes.bool,
    categories: PropTypes.object,
    cmsPages: PropTypes.any,
    categoryTree: PropTypes.array,
    categoryCode: PropTypes.string,
    route: PropTypes.string,
  };

  UNSAFE_componentWillMount() {
    if (this.props.isHome && this.props.isOpen) {
      this.props.setMainMenuIsOpen();
    }
  }

  render() {
    const {
      isOpen,
      children,
      breakpoint,
      isHome,
      categories,
      cmsPages,
      categoryTree,
      categoryCode,
      route,
    } = this.props;

    return React.Children.map(children, (child) =>
      React.cloneElement(child, {
        triggerMenu: this.props.setMainMenuIsOpen,
        triggerSearch: this.props.setSearchFocus,
        isOpen,
        breakpoint,
        isHome,
        categories,
        cmsPages,
        categoryTree,
        categoryCode,
        route,
      })
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainMenu);
