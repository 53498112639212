import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import LoadingState from 'components/molecules/LoadingState/LoadingState';
import MessageBadRequest, { pageType } from 'components/molecules/MessageBadRequest/MessageBadRequest';

import { placeOrder } from 'actions/orderActions/orderActions';
import { trackPlaceOrder } from 'actions/trackingActions/trackingActions';
import { resetCart } from 'actions/cartActions/cartActions';

import { apiStatus } from 'constants/apiStatus/apiStatus';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import { getLocale } from 'constants/language/language';
import { orderConfirmation } from 'constants/routePaths/routePathCombinations';
import getStorage from 'constants/storage/storage';

import { PAYMENT, DELIVERY, EMAIL, LAST_CART_ID } from 'constants/storage/storageKeys';

const storage = getStorage(true);
const mapDispatchToProps = {
  placeOrder,
  trackPlaceOrder,
  resetCart,
};

const mapStateToProps = (state, ownProps) => {
  const lastOrderGuid = ownProps?.location?.query?.uppCartId || storage.getItem(LAST_CART_ID);
  const orderApiStatus = state.orders.apiStatus;
  const email = JSON.parse(storage.getItem(EMAIL));
  const guestEmail = email?.value;

  return {
    queryParams: ownProps.location.query,
    orderApiStatus,
    lastOrderGuid,
    guestEmail,
  };
};

export class PaymentSuccess extends Component {
  static propTypes = {
    location: PropTypes.object,
    queryParams: PropTypes.object,
    placeOrder: PropTypes.func,
    createGuest: PropTypes.func,
    orderApiStatus: PropTypes.string,
    lastOrderGuid: PropTypes.string,
    guestEmail: PropTypes.string,
    trackPlaceOrder: PropTypes.func,
    resetCart: PropTypes.func,
  };

  componentDidMount() {
    this.handlePayment();
  }

  handlePayment = () => {
    const { lastOrderGuid } = this.props;
    this.removeStorageItems();
    this.props.trackPlaceOrder(lastOrderGuid);
    this.props.resetCart();
    browserHistory.push(`${mapPathToLocalizedUrl(getLocale(), orderConfirmation)}`);
  };

  removeStorageItems = () => {
    storage.removeItem(PAYMENT);
    storage.removeItem(DELIVERY);
    storage.removeItem(EMAIL);
  };

  render() {
    if (this.props.orderApiStatus === apiStatus.failure) {
      return <MessageBadRequest apiStatus={apiStatus.failure} pageType={pageType.checkout} />;
    }
    return <LoadingState />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSuccess);
