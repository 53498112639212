import { paymentModes } from 'constants/paymentModes/paymentModes';

export const validTranslationKeys = {
  'esdmixed-pickup-payother': 'esdMixedPickup',
  'esdmixed-homedeliverygross-payinadvance': 'esdMixedDeliveryCashInAdvance',
  'esdmixed-homedeliverygross-payother': 'esdMixedDelivery',
  'esd-software-payinadvance': 'esdCashInAdvance',
  'esd-software-payother': 'esd',
  'homedeliverygross-payinadvance': 'deliveryCashInAdvance',
  'homedeliverygross-payother': 'delivery',
  'pickup-payother': 'pickup',
};

export const getCheckoutStepsKey = (deliveryMode, paymentMode, esdOnly, esdMixed) => {
  const key = [];
  if (esdMixed) key.push('esdmixed');
  if (esdOnly) key.push('esd');
  if (deliveryMode) key.push(deliveryMode.replace(/-/g, ''));
  if (paymentMode) key.push(paymentMode === paymentModes.PAY_IN_ADVANCE ? 'payinadvance' : 'payother');
  return validTranslationKeys[key.join('-')] || 'default';
};

export const getFullCheckoutStepsKey = (deliveryMode, paymentMode, pegiRequired, esdOnly, esdMixed) => {
  const key = getCheckoutStepsKey(deliveryMode, paymentMode, esdOnly, esdMixed);

  if (key === 'default') {
    // return default.default, since we do not have a default for pegi
    return 'orderConfirmationSteps.default.default';
  }

  return `orderConfirmationSteps.${key}.${pegiRequired ? 'pegi' : 'default'}`;
};
