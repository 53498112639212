import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { BREAKPOINTS, BREAKPOINT_MIN_WIDTH } from 'constants/breakpoints/breakpoints';
import SelectModal from 'components/atoms/SelectModal/SelectModal';
import SelectDropdown from 'components/atoms/SelectDropdown/SelectDropdown';

import styles from './Select.scss';

class Select extends Component {
  static displayName = 'atoms/Select';
  static propTypes = {
    breakpoint: PropTypes.string,
    options: PropTypes.array,
    optionsActiveIndex: PropTypes.number,
    optionsClickHandler: PropTypes.func, // will be executed on click with the index of the clicked one as parameter
  };
  static defaultProps = {
    breakpoint: BREAKPOINTS.LG,
    options: [],
    optionsActiveIndex: 0,
    optionsClickHandler: () => {},
  };

  state = {
    isOpen: false,
    showModal: false,
  };

  componentDidMount() {
    this.setShowModalState();
    if (__CLIENT__) {
      window.addEventListener('resize', this.setShowModalState);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps !== this.props || nextState.isOpen !== this.state.isOpen || nextState.showModal !== this.state.showModal
    );
  }

  componentWillUnmount() {
    if (__CLIENT__) {
      window.removeEventListener('resize', this.setShowModalState);
    }
  }

  toggleSelect = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  setShowModalState = () => {
    this.setState({
      showModal: __CLIENT__ && window.outerWidth < BREAKPOINT_MIN_WIDTH[this.props.breakpoint],
    });
  };

  optionsClickHandler = (index) => {
    this.props.optionsClickHandler(index);
    this.toggleSelect();
  };

  render() {
    return (
      <div className={cx(styles.container, { [styles.open]: this.state.isOpen })}>
        <span className={styles.preview} onClick={this.toggleSelect}>
          {this.props.options[this.props.optionsActiveIndex]}
        </span>
        {this.state.isOpen && (
          <>
            {this.state.showModal ? (
              <SelectModal
                options={this.props.options}
                optionsActiveIndex={this.props.optionsActiveIndex}
                optionsClickHandler={this.optionsClickHandler}
                closeSelect={this.toggleSelect}
              />
            ) : (
              <SelectDropdown
                options={this.props.options}
                optionsActiveIndex={this.props.optionsActiveIndex}
                optionsClickHandler={this.optionsClickHandler}
                closeSelect={this.toggleSelect}
              />
            )}
          </>
        )}
      </div>
    );
  }
}

export default Select;
