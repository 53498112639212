import React, { Component } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Recaptcha from 'react-recaptcha';
import language, { getLocale } from 'constants/language/language';
import { scrollToElement } from 'constants/scrollToElement/scrollToElement';
import resolveErrorMessage from 'constants/resolveErrorMessage/resolveErrorMessage';
import validation, { ValidationTypes } from 'constants/validation/validation';
import { recaptchaAreas } from 'constants/recaptcha/recaptchaAreas';
import { types as flashMessageTypes } from 'constants/flashMessages/flashMessages';
import { LoginValidationTypes, failureTypes } from 'constants/loginValidation/loginValidation';
import PasswordReset from 'components/organisms/PasswordReset/PasswordReset';
import SpinnerOverlay from 'components/molecules/SpinnerOverlay/SpinnerOverlay';
import LabelInput from 'components/molecules/FieldSetInput/LabelInput';
import ErrorMessageInput from 'components/molecules/FieldSetInput/ErrorMessageInput';
import FlashMessage from 'components/atoms/FlashMessage/FlashMessage';
import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';
import Tooltip from 'components/atoms/Tooltip/Tooltip';
import InputCheckbox from 'components/atoms/InputCheckbox/InputCheckbox';
import InputText from 'components/atoms/InputText/InputText';
import styles from './Login.scss';
import bootstrap from 'scss/component.scss';
import isMobileApp from "constants/mobileApp/mobileApp";

let recaptchaInstance;
const recaptchaKey = __CLIENT__ && window.__ENV_VARIABLES__.recaptchaKey;

export default class Login extends Component {
  static displayName = 'organisms/Login';

  static propTypes = {
    handleLogin: PropTypes.func,
    loginValidation: PropTypes.oneOf(Object.values(LoginValidationTypes)),
    email: PropTypes.string,
    swipeErrorBox: PropTypes.bool,
    isPopOver: PropTypes.bool,
    showSpinner: PropTypes.bool,
    recaptchaNeeded: PropTypes.bool,
    resetCaptcha: PropTypes.bool,
    verifyRecaptcha: PropTypes.func,
    updateEmailField: PropTypes.func,
  };

  static defaultProps = {
    email: '',
    loginValidation: true,
    handleLogin: () => {},
    swipeErrorBox: false,
    isPopOver: false,
    showSpinner: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      keepLogin: true,
      emailValidationResult: true,
      passwordValidationResult: true,
      passwordFocus: false,
      emailFocus: false,
      showRecaptcha: false,
    };
    this.email = props.email;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.showRecaptcha && nextProps.resetCaptcha) {
      recaptchaInstance.reset();
    }
  }

  onEmailBlur = (email) => {
    this.storeEmail(email);
    const emailValid = validation.email(this.email);
    this.setState({
      emailValidationResult: this.email === '' ? ValidationTypes.Missing : emailValid,
      emailFocus: false,
    });
  };

  onPasswordBlur = (password) => {
    this.storePassword(password);
    this.setState({
      passwordValidationResult: this.password === '' ? ValidationTypes.MissingLogin : true,
      passwordFocus: false,
    });
  };

  onEmailFocus = () => {
    this.setState({ emailFocus: true });
  };

  onPasswordFocus = () => {
    this.setState({ passwordFocus: true });
  };

  storeEmail = (email) => {
    this.props.updateEmailField(email);
    this.email = email;
  };

  storePassword = (password) => {
    this.password = password;
  };

  keepLogin = (active) => {
    this.setState({ keepLogin: active });
  };

  submitLogin = (event) => {
    event.preventDefault();

    // todo: unfortunately the react autofill issue still exits: https://github.com/facebook/react/issues/1159
    this.email = document.getElementById('email').value;
    this.password = document.getElementById('password').value;

    const email = this.email;
    const password = this.password;

    const isEmailEmpty = !email;
    const isPasswordEmpty = !password;

    if (isEmailEmpty) {
      this.setState({ emailValidationResult: ValidationTypes.Missing });
    }

    if (isPasswordEmpty) {
      this.setState({ passwordValidationResult: ValidationTypes.MissingLogin });
    }

    if (!isEmailEmpty && !isPasswordEmpty && !this.props.recaptchaNeeded) {
      this.props.handleLogin(email, password, this.state.keepLogin);
    } else {
      const elementToScrollTo = email === '' ? 'emailContainer' : 'passwordContainer';
      scrollToElement(elementToScrollTo);
    }
  };

  captchaOnloadCallback = () => {
    this.setState({
      showRecaptcha: true,
    });
  };

  captchaVerifyCallback = (response) => {
    this.props.verifyRecaptcha(response, this.email, recaptchaAreas.login);
  };

  getKeyByValue = (obj, value) => {
    if (value === LoginValidationTypes.UserIsDisabled) value = LoginValidationTypes.BadCredentials;
    const keyToReturn = Object.keys(obj).find((key) => obj[key] === value);
    return `${keyToReturn.slice(0, 1).toLowerCase()}${keyToReturn.slice(1, keyToReturn.length)}`;
  };

  render() {
    const { loginValidation, swipeErrorBox, isPopOver, recaptchaNeeded } = this.props;
    return (
      <div>
        {/* form must have an action so on mobile devices show correct keyboard */}
        <form method="post" action="login" autoComplete="off" onSubmit={this.submitLogin} noValidate>
          <div className={styles.Login}>
            {failureTypes.includes(loginValidation) && !isPopOver && this.state.emailValidationResult === true && (
              <FlashMessage
                shouldSwipe={swipeErrorBox}
                type={flashMessageTypes.ERROR}
                content={language(`login.errorMessages.${this.getKeyByValue(LoginValidationTypes, loginValidation)}`)}
              />
            )}
            <div id="emailContainer" className={bootstrap.formGroup}>
              <LabelInput name="email" id="email" showError={this.state.emailValidationResult !== true}>
                {language('form.fields.email.label')}
              </LabelInput>
              <InputText
                name="email"
                id="email"
                type="email"
                onBlur={this.onEmailBlur}
                handleChange={this.storeEmail}
                value={this.email}
                showError={this.state.emailValidationResult !== true}
                autocomplete="off"
                focus={this.state.emailFocus}
                onFocus={this.onEmailFocus}
                trackingAttributes={{
                  TYPE: 'form:field',
                  FUNCTION: 'focus',
                  ATTRIBUTE_1: 'text',
                  ATTRIBUTE_2: 'email',
                }}
              />
              <ErrorMessageInput showError={this.state.emailValidationResult !== true}>
                {resolveErrorMessage(this.state.emailValidationResult, 'email')}
              </ErrorMessageInput>
            </div>
            <div
              id="passwordContainer"
              className={cx(bootstrap.formGroup, { [styles.passwordContainer]: styles.passwordContainer })}
            >
              <LabelInput name="password" id="password" showError={this.state.passwordValidationResult !== true}>
                {language('form.fields.password.label')}
              </LabelInput>
              <PasswordReset initialEmail={this.email} />
              <InputText
                name="password"
                id="password"
                type="password"
                onBlur={this.onPasswordBlur}
                handleChange={this.storePassword}
                showError={this.state.passwordValidationResult !== true}
                autocomplete="off"
                focus={this.state.passwordFocus}
                onFocus={this.onPasswordFocus}
                trackingAttributes={{
                  TYPE: 'form:field',
                  FUNCTION: 'focus',
                  ATTRIBUTE_1: 'text',
                  ATTRIBUTE_2: 'password',
                }}
              />
              <ErrorMessageInput showError={this.state.passwordValidationResult !== true}>
                {resolveErrorMessage(this.state.passwordValidationResult, 'password')}
              </ErrorMessageInput>
            </div>
          </div>
          {this.props.showSpinner && <SpinnerOverlay />}
          {(recaptchaNeeded || this.state.showRecaptcha) && (
            <div className={styles.captchaMargin}>
              <Recaptcha
                ref={(e) => (recaptchaInstance = e)} // eslint-disable-line
                sitekey={recaptchaKey}
                verifyCallback={this.captchaVerifyCallback}
                onloadCallback={this.captchaOnloadCallback}
                hl={getLocale()}
              />
            </div>
          )}
          <div className={styles.button}>
            <ButtonLink
              type="submit"
              size={ButtonLink.sizes.md}
              variety={ButtonLink.varieties.flat}
              stretched
              disabled={recaptchaNeeded}
            >
              {language('login.button.text')}
            </ButtonLink>
          </div>
          {!isMobileApp() &&
          <div className={styles.remember}>
            <div className={styles.cont}>
              <InputCheckbox
                onClick={() => this.keepLogin(!this.state.keepLogin)}
                selected={this.state.keepLogin}
                trackingAttributes={{
                  TYPE: 'form:button',
                  ATTRIBUTE_1: 'checkbox',
                  ATTRIBUTE_2: language('login.checkbox.label'),
                }}
              >
                {language('login.checkbox.label')}
                {!isPopOver && <Tooltip infoText={language('login.infoicon.text')} />}
              </InputCheckbox>
              <div className={styles.clear} />
            </div>
          </div>
          }
        </form>
      </div>
    );
  }
}
