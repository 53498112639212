import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@interdiscount/component-library-interdiscount';
import { postLog } from 'api/promiseMiddleware';
import { loadScript } from 'constants/scriptLoader/scriptLoader';

const propTypes = {
  src: PropTypes.string,
  closeModal: PropTypes.func,
};

const defaultProps = {
  src: '',
  closeModal: () => {},
};

const SCRIPT_URLS = {
  SA_SCRIPT: '//st.smartassistant.com/advisor-fe-web/assets/js-nwd/smartassistant.nwd.all.js',
  ADVISOR_SCRIPT: '//st.smartassistant.com/advisor-fe-web/custom-javascript?advisorCode=',
  SA_STYLES: '//st.smartassistant.com/advisor-fe-web/css-design?advisorCode=',
  CONTEXT_PATH: '//st.smartassistant.com/advisor-fe-web',
};

const SCRIPT_IDS = {
  SA_SCRIPT: 'saScript',
  SA_STYLES: 'saStyles',
  ADVISOR_SCRIPT: 'advisorScript',
};

export const ModalMiele = ({ closeModal, src }) => (
  <Modal size="LG" isOpen onRequestClose={closeModal}>
    <ModalContent src={src} />
  </Modal>
);

ModalMiele.propTypes = propTypes;
ModalMiele.defaultProps = defaultProps;
ModalMiele.displayName = 'organisms/ModalMiele';

export default ModalMiele;

export class ModalContent extends Component {
  // eslint-disable-line react/no-multi-comp
  static propTypes = propTypes;
  static defaultProps = defaultProps;

  /**
   * following scripts will be added to the window object and loaded at initialization:
   * - General SmartAssistant Script
   * - Individual Advisor Script
   * - General Styles
   */
  componentDidMount() {
    const { src } = this.props;

    Promise.all([
      loadScript
        .js(SCRIPT_URLS.SA_SCRIPT, SCRIPT_IDS.SA_SCRIPT)
        .then(() => loadScript.js(`${SCRIPT_URLS.ADVISOR_SCRIPT}${src}`, SCRIPT_IDS.ADVISOR_SCRIPT)),
      loadScript.css(`${SCRIPT_URLS.SA_STYLES}${src}`, SCRIPT_IDS.SA_STYLES),
    ])
      .then(() => {
        if (__CLIENT__) {
          if (window.SmartAssistant && window.smrt42_jquery) {
            window.smrt42_jquery(() => {
              window.SmartAssistant.integrate({
                divId: 'advisor-container',
                advisorContextPath: SCRIPT_URLS.CONTEXT_PATH,
                advisorCode: this.props.src,
                disableTracking: false,
              });
            });
          }
        }
      })
      .catch(postLog);
  }

  componentWillUnmount() {
    if (window && document) {
      const parent = document.getElementsByTagName('head')[0];
      parent.removeChild(document.getElementById(SCRIPT_IDS.SA_SCRIPT));
      parent.removeChild(document.getElementById(SCRIPT_IDS.SA_STYLES));
      parent.removeChild(document.getElementById(SCRIPT_IDS.ADVISOR_SCRIPT));
    }
  }

  render() {
    return <div id="advisor-container">{/* Placeholder for Miele Shop */}</div>;
  }
}
