import { handleActions } from 'redux-actions';
import has from 'constants/helper/has';
import {
  REHYDRATE_GUEST_ADDRESSES,
  CREATE_GUEST_DELIVERY_ADDRESS,
  REMOVE_GUEST_DELIVERY_ADDRESS,
  SET_GUEST_ADDRESS_SUCCESS,
  SET_GUEST_ADDRESS_FAILURE,
  SET_GUEST_ADDRESS_NETWORKERROR,
  FLAG_ADDRESS_FOR_DELETION,
  FLAG_ADDRESS_TYPE_FOR_SELECTION,
  GET_USER_SUCCESS,
  UPDATE_USER_ADDRESS_SUCCESS,
  DELETE_USER_ADDRESS_SUCCESS,
  DELETE_TEMP_ADDRESS,
  CREATE_TEMP_ADDRESS,
  COPY_INTO_TEMP_ADDRESS,
  CREATE_USER_ADDRESS_AND_SELECT_SUCCESS,
  SET_ADDRESS_SUCCESS,
  CREATE_USER_ADDRESS_SUCCESS,
  CREATE_USER_ADDRESS_FAILURE,
  BLUR_ADDRESS_FIELD,
  UPDATE_ADDRESS_FIELD,
  RESET_USER,
  CHANGE_CUSTOMER_TYPE,
} from 'constants/ActionTypes/ActionTypes';

import transform from 'constants/reducerHelper/reducerHelper';
import { addressTypes, isCompanyFieldRequired } from 'constants/addresses/addresses';
import validation from 'constants/validation/validation';
import getStorage from 'constants/storage/storage';
import { DELIVERY } from 'constants/storage/storageKeys';
import { ValidationTypes } from 'constants/validation/validation';
import { apiStatus } from 'constants/apiStatus/apiStatus';
import { isB2BCustomer } from 'constants/customerGroup/customerGroup';

const storage = getStorage(false);

export const initialState = {};

export const formFieldConfigurations = {
  salutation: {
    required: true,
  },
  firstName: {
    required: true,
    validation: validation.validateAddressFields,
  },
  lastName: {
    required: true,
    validation: validation.validateAddressFields,
  },
  company: {
    required: false,
    validation: validation.validateAddressFieldsWithAt,
  },
  phone: {
    required: true,
    validation: validation.phoneNumber,
  },
  addressLine1: {
    required: true,
    validation: validation.validateAddressFields,
  },
  addressLine2: {
    required: false,
    validation: validation.validateAddressFields,
  },
  addition: {
    required: false,
    validation: validation.validateAddressFieldsWithAt,
  },
  postalCode: {
    required: true,
    validation: validation.postalCode,
  },
  town: {
    required: true,
    validation: validation.validateAddressFields,
  },
  country: {
    value: 'CH',
    required: true,
  },
};

export const validateAddress = (address, addresses = [], user, isCompanyNotRequired) => {
  const newState = transform(address);

  // eslint-disable-next-line array-callback-return
  Object.keys(formFieldConfigurations).forEach((fieldName) => {
    let isRequired = formFieldConfigurations[fieldName].required;

    if (fieldName === 'company') {
      isRequired = isCompanyFieldRequired({ user, addresses });
    }

    if (fieldName === 'company' && isCompanyNotRequired) {
      isRequired = false;
    }

    const validationFunction = formFieldConfigurations[fieldName].validation;
    const value = address?.fields?.[fieldName]?.value;

    let validationResult = true;
    if (isRequired) {
      validationResult = validation.required(value);
    }
    if (typeof validationFunction === 'function') {
      validationResult = validationFunction(value, isRequired);
    }
    newState
      .set(`fields.${fieldName}.value`, value)
      .set(`fields.${fieldName}.required`, isRequired)
      .set(`fields.${fieldName}.validationResult`, validationResult);
  });

  const fields = newState.value().fields;
  const firstError = fields[Object.keys(fields).find((field) => fields[field].validationResult !== true)] || {};

  return newState.set('firstError', firstError).set('valid', !Object.keys(firstError).length).value();
};

export const Address = (id, existingAddress, addresses = {}, user = {}, isCompanyNotRequired) => {
  const fields = {};
  Object.keys(formFieldConfigurations || {}).forEach((key) => {
    const fieldValue = formFieldConfigurations[key];
    fields[key] = {
      name: key,
      required: fieldValue.required,
      value: fieldValue.value,
      blurred: false,
      validationResult: fieldValue.required ? validation.required(fieldValue.value) : true,
    };
  });

  if (existingAddress?.fields) {
    Object.entries(existingAddress.fields).forEach(([key, value]) => {
      fields[key].value = value.value;
    });
  }

  const validatedAddress = validateAddress(
    {
      fields,
      id,
    },
    addresses,
    user,
    isCompanyNotRequired
  );

  if (existingAddress) {
    validatedAddress.readonly = existingAddress.readonly ?? false;
    validatedAddress.invoice = existingAddress.invoice ?? false;
    validatedAddress.delivery = existingAddress.delivery ?? false;
  }

  return validatedAddress;
};

export default handleActions(
  {
    [REHYDRATE_GUEST_ADDRESSES]: (state, action) => {
      const addresses = action.payload?.addresses;
      if (!addresses) {
        return {
          [addressTypes.INVOICE]: new Address(addressTypes.INVOICE),
        };
      }
      return addresses;
    },

    [CREATE_GUEST_DELIVERY_ADDRESS]: (state) =>
      transform(state).set(addressTypes.DELIVERY, new Address(addressTypes.DELIVERY)).value(),

    [REMOVE_GUEST_DELIVERY_ADDRESS]: (state) => {
      storage.removeItem(DELIVERY);
      return transform(state).omit(addressTypes.DELIVERY).value();
    },

    [SET_GUEST_ADDRESS_SUCCESS]: (state, action) => {
      const id = action.payload?.type;
      const addressToStore = state?.[id];
      if (addressToStore) {
        storage.setItem(id, JSON.stringify(addressToStore));
      }
      return state;
    },

    [SET_GUEST_ADDRESS_FAILURE]: (state, action) => {
      const id = action.payload?.type;
      return transform(state)
        .set(`${id}`, new Address(id))
        .set(`${id}.valid`, ValidationTypes.Invalid)
        .set(`${id}.errorStatus`, apiStatus.failure)
        .value();
    },

    [SET_GUEST_ADDRESS_NETWORKERROR]: (state, action) => {
      const id = action.payload?.type;
      return transform(state)
        .set(`${id}`, new Address(id))
        .set(`${id}.valid`, ValidationTypes.Invalid)
        .set(`${id}.errorStatus`, apiStatus.networkerror)
        .value();
    },

    [FLAG_ADDRESS_FOR_DELETION]: (state, action) => {
      const id = action.payload?.addressId;
      const flag = action.payload?.flagged;
      const address = state?.[id];
      if (!id || !address) {
        return state;
      }

      return transform(state).set(`${id}.flaggedForDeletion`, flag).value();
    },

    [FLAG_ADDRESS_TYPE_FOR_SELECTION]: (state, action) => {
      const type = action.payload?.addressType;
      const flag = action.payload?.flagged;
      if (!type) {
        return state;
      }
      const newState = transform(state);

      if (flag === false) {
        newState.omit(addressTypes.TEMPORARY);
      } else if (type === addressTypes.DELIVERY && Object.keys(state).length === 1) {
        const address = new Address(addressTypes.TEMPORARY);
        address.useInCheckoutAs = addressTypes.DELIVERY;
        newState.set(addressTypes.TEMPORARY, address);
      }

      return newState.value();
    },

    [GET_USER_SUCCESS]: (state, action) => {
      const newAddresses = action.req?.data?.addresses;
      const user = action.req?.data || {};
      if (!newAddresses || !(newAddresses instanceof Array)) {
        return state;
      }
      let isCompanyNotRequired = true;
      if (
        isB2BCustomer(user.group) &&
        newAddresses.filter((address) => address.fields.company.value !== undefined).length < 1
      ) {
        isCompanyNotRequired = false;
      }

      return newAddresses
        .reduce(
          (newState, address) =>
            newState.set(address.id, new Address(address.id, address, newAddresses, user, isCompanyNotRequired)),
          transform({
            ...(has(state, 'TEMPORARY') && { TEMPORARY: state?.TEMPORARY }),
          })
        )
        .value();
    },

    [UPDATE_USER_ADDRESS_SUCCESS]: (state, action) => {
      const id = action.payload?.addressId;
      const oldAddress = state?.[id];
      const newAddress = action.req?.fields;
      const user = action.payload?.user || {};
      if (!id || !oldAddress || !newAddress) {
        return state;
      }
      const isCompanyNotRequired = action.payload?.isCompanyNotRequired;

      const newState = transform(state);

      Object.keys(formFieldConfigurations).map((key) =>
        newState.set(`${id}.fields.${key}.value`, action.req?.fields?.[key]?.value)
      );

      return newState
        .set(id, validateAddress(newState.value()[id], state, user, isCompanyNotRequired))
        .omit(addressTypes.TEMPORARY)
        .value();
    },

    [DELETE_USER_ADDRESS_SUCCESS]: (state, action) => transform(state).omit(action.payload?.addressId).value(),

    [DELETE_TEMP_ADDRESS]: (state) => transform(state).omit(addressTypes.TEMPORARY).value(),

    [CREATE_TEMP_ADDRESS]: (state) =>
      transform(state).set(addressTypes.TEMPORARY, new Address(addressTypes.TEMPORARY)).value(),

    [COPY_INTO_TEMP_ADDRESS]: (state, action) => {
      const id = action.payload?.addressId;
      const address = state?.[id];
      const user = action.payload?.user || {};
      if (!id || !address) {
        return state;
      }
      const isCompanyNotRequired = action.payload?.isCompanyNotRequired;

      return transform(state)
        .set(addressTypes.TEMPORARY, new Address(id, address, state, user, isCompanyNotRequired))
        .value();
    },

    [CREATE_USER_ADDRESS_AND_SELECT_SUCCESS]: (state, action) => {
      const id = action.req?.id;
      const tempAddress = state?.[addressTypes?.TEMPORARY];
      const user = action.payload?.user || {};
      if (!tempAddress || !id) {
        return state;
      }

      return transform(state)
        .set(id, new Address(id, tempAddress, state, user, true))
        .set(`${addressTypes.TEMPORARY}.id`, id)
        .value();
    },

    [SET_ADDRESS_SUCCESS]: (state) => transform(state).omit(addressTypes.TEMPORARY).value(),

    [CREATE_USER_ADDRESS_SUCCESS]: (state, action) => {
      const address = action.req;
      const id = address?.id;
      const user = action.payload?.user || {};
      if (!id || !address) {
        return state;
      }

      return transform(state).omit(addressTypes.TEMPORARY).set(id, new Address(id, address, state, user, true)).value();
    },

    [CREATE_USER_ADDRESS_FAILURE]: (state, action) =>
      transform(state)
        .set(addressTypes.TEMPORARY, validateAddress(state[addressTypes.TEMPORARY], state, action.payload?.user || {}))
        .value(),

    [BLUR_ADDRESS_FIELD]: (state, action) => {
      const id = action.payload?.addressId;
      const fieldName = action.payload?.fieldName;
      const fieldValue = state?.[id]?.fields?.[fieldName]?.value || '';
      if (!id || !fieldName || !has(state, id)) {
        return state;
      }

      const newState = transform(state)
        .set(`${id}.fields.${fieldName}.blurred`, true)
        .set(`${id}.fields.${fieldName}.value`, fieldValue.trim())
        .omit(`${id}.apiResponse`)
        .value();

      if (id === addressTypes.INVOICE || id === addressTypes.DELIVERY) {
        storage.setItem(id, JSON.stringify(newState[id]));
      }

      return newState;
    },

    [UPDATE_ADDRESS_FIELD]: (state, action) => {
      const id = action.payload?.addressId;
      const fieldName = action.payload?.fieldName;
      const value = action.payload?.value;
      const isCompanyNotRequired = action.payload?.isCompanyNotRequired;
      if (!id || !fieldName || !formFieldConfigurations[fieldName]) {
        return state;
      }

      const newState = transform(state)
        .set(`${id}.fields.${fieldName}.value`, value)
        .set(`${id}.fields.${fieldName}.blurred`, false);

      return newState
        .set(id, validateAddress(newState.value()[id], state, action.payload.user, isCompanyNotRequired))
        .value();
    },

    [RESET_USER]: () => initialState,

    [CHANGE_CUSTOMER_TYPE]: (state, action) => {
      const newState = transform(state);
      const id = 'invoice';
      const tempUserState = { fields: { group: { value: action.payload } } };

      return newState.set(id, validateAddress(newState.value()[id], state, tempUserState)).value();
    },
  },
  initialState
);
