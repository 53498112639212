import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import { postLog } from 'api/promiseMiddleware';
import DefaultFallback from './Fallback.js';

class ErrorBoundary extends Component {
  static getDerivedStateFromError() {
    // Update state so the next render will show our fallback page
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidUpdate(prevProps) {
    // reset state when location changes
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({ hasError: false });
    }
  }

  componentDidCatch(error, errorInfo) {
    error.info = errorInfo;
    postLog(error);
  }

  render() {
    if (this.state.hasError) {
      return this.props.passedFallBack ? this.props.passedFallBack : <DefaultFallback />;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  location: PropTypes.object,
  passedFallBack: PropTypes.node,
  children: PropTypes.node,
};

export default withRouter(ErrorBoundary);
