import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { loadProducts as loadProductsAction } from 'actions/productActions/productActions';
import { trackProductOverview as trackProductOverviewAction } from 'actions/trackingActions/trackingActions';
import { apiStatus } from 'constants/apiStatus/apiStatus';
import { getLocale } from 'constants/language/language';
import {
  computeQueryString,
  createFacetRangeQuery,
  mapQueryParamsToQueryString,
  mapQueryParamsToUrl,
  mapQueryStringToQueryParams,
  mapUrlToQueryParams,
  mergeQueryString,
  removeUnusedParams,
} from 'constants/urlMapping/urlMapping';
import MessageBadRequest, { pageType } from 'components/molecules/MessageBadRequest/MessageBadRequest';
import PageNotFound from 'components/templates/PageNotFound/PageNotFound';
import BrandProductsNotFound from 'components/templates/BrandProductsNotFound/BrandProductsNotFound';
import CmsProductListing from 'components/organisms/CmsProductListing/CmsProductListing';
import usePrevious from 'hooks/usePrevious';
import {
  getFacetRegExp,
  trackRemoveFilter,
  trackResetAllFacets,
  trackSelectFilter,
  trackSliderChange,
  trackSubmitFilter,
} from 'constants/facetsHelper/facetsHelper';

const QUERY_PARAM = 'query=';
const mapStateToProps = (state) => {
  const productsQueries = state?.productsQueries || {};
  const isFilterMenuOpen = state?.ui?.filterMenu?.isOpen ?? false;
  const categories = state.categories;
  const isBrandValid = state.cms?.requestForBrandValidity === apiStatus.success;

  return {
    productsQueries,
    isFilterMenuOpen,
    categories,
    isBrandValid,
    location: state.routing?.locationBeforeTransitions,
  };
};
const mapDispatchToProps = {
  loadProducts: loadProductsAction,
  trackProductOverview: trackProductOverviewAction,
};

const buildQueryString = (query) => {
  if (query.indexOf('query') > -1) {
    return query;
  }

  return QUERY_PARAM + query;
};

const getResultsPerQuery = (productsQueries, currentQuery) => productsQueries?.[currentQuery];

const CmsProductListingContainer = ({
  // State props
  productsQueries,
  isFilterMenuOpen,
  categories,
  isBrandValid,
  location,

  // Dispatch props
  loadProducts,
  trackProductOverview,

  // Incoming props
  brandFallback,
  inputValue,
  isBrandPage,
  searchQuery,
  searchQueryType,
}) => {
  const initialQuery = mergeQueryString(buildQueryString(searchQuery || ''));
  const [currentQueryString, setCurrentQueryString] = useState(initialQuery);
  const [prefetchedQueryString, setPrefetchedQueryString] = useState(currentQueryString);
  const previousQuery = usePrevious(currentQueryString);

  const loadAndSetQuery = async (query) => {
    if (!Object.prototype.hasOwnProperty.call(productsQueries, query)) {
      await loadProducts(query);
    }
    setPrefetchedQueryString(query);
    setCurrentQueryString(query);
  };

  // On Mount
  useEffect(() => {
    const facetQuery = mapQueryParamsToQueryString(
      mapUrlToQueryParams(location.pathname + location.search, categories)
    );
    const cleanSearchString = removeUnusedParams(location.search);

    if (cleanSearchString) {
      const queryString = buildQueryString(facetQuery);
      loadAndSetQuery(queryString);
    } else {
      loadAndSetQuery(initialQuery);
    }
  }, [location.pathname, location.search]);

  const resetFacet = (facetCode, index) => {
    const regExp = getFacetRegExp(facetCode);
    const facetQuery = currentQueryString.replaceAll(regExp, '');
    loadAndSetQuery(facetQuery);
    trackRemoveFilter(facetQuery, currentQueryString, index);
  };
  const resetAllFacets = () => {
    loadAndSetQuery(initialQuery);
    trackResetAllFacets();
  };

  useEffect(() => {
    const setUrlForProductsForSingleFacet = (facetQuery) => {
      let urlQueries = mapQueryParamsToUrl(mapQueryStringToQueryParams(facetQuery), getLocale(), categories, true);

      if (urlQueries.match(/\?promotions=\d+$/)) {
        urlQueries = '';
      }

      if (urlQueries !== location.search) {
        browserHistory.push({
          state: { disableScroll: true },
          pathname: location.pathname,
          search: urlQueries,
        });
      }
    };

    if (previousQuery && previousQuery !== currentQueryString) {
      setUrlForProductsForSingleFacet(currentQueryString);
      trackProductOverview();
    }
  }, [categories, currentQueryString, previousQuery]);

  const changeSortOrder = (sort) => {
    const newQueryString = mergeQueryString(currentQueryString, { sort, page: 0 });
    loadAndSetQuery(newQueryString);
  };

  const changePage = (page) => {
    const newQueryString = mergeQueryString(currentQueryString, { page });
    loadAndSetQuery(newQueryString);
  };

  const handleSliderChange = async ({ selectedMin, selectedMax, min, max, facetCode, location }) => {
    let facetRangeQuery = createFacetRangeQuery(currentQueryString, selectedMin, selectedMax, facetCode);
    if (selectedMax === max && selectedMin === min && facetCode === 'price') {
      facetRangeQuery = facetRangeQuery
        .replace('price_min%3A' + selectedMin, '')
        .replace('price_max%3A' + selectedMax, '');
    }
    await loadProducts(facetRangeQuery);
    setPrefetchedQueryString(facetRangeQuery);
    trackSliderChange(selectedMin, selectedMax, facetCode, location);
  };

  const submitFilters = () => {
    loadAndSetQuery(prefetchedQueryString);
    //TODO: switch after CMS Pages have currentQuery
    // trackSubmitFilter(getResultsPerQuery(productsQueries, prefetchedQueryString), categories);
    trackSubmitFilter(null, categories);
  };

  const prefetchFilter = async (filterProps) => {
    if (!filterProps) {
      setPrefetchedQueryString(currentQueryString);
      return;
    }

    const { filter, facetCode, location, innerlocation, autoSubmit } = filterProps;
    const computedQueryString = computeQueryString(filter, facetCode, prefetchedQueryString || currentQueryString);

    if (autoSubmit) {
      await loadAndSetQuery(computedQueryString);
    } else {
      await loadProducts(computedQueryString);
      setPrefetchedQueryString(computedQueryString);
    }
    trackSelectFilter(filter, facetCode, location, innerlocation);
  };

  const productsQuery = getResultsPerQuery(productsQueries, currentQueryString);
  const visibleProducts = productsQuery?.visibleProducts || [];
  const pagination = productsQuery?.pagination || {};
  const sorts = productsQuery?.sorts || [];
  const currentPage = pagination.currentPage + 1 || 1;
  const brand = isBrandPage && inputValue;
  const queryApiStatus = productsQuery?.apiStatus || apiStatus.request;
  const numberOfFacets = productsQuery?.facets?.length || 0;
  const numberOfSelectedFacets = productsQuery?.selectedFacetsCount || 0;
  const hasFiltersApplied = prefetchedQueryString !== currentQueryString;

  if (queryApiStatus !== apiStatus.success && queryApiStatus !== apiStatus.request) {
    return <MessageBadRequest apiStatus={queryApiStatus} pageType={pageType.productOverview} />;
  }

  const totalNumberOfResults = (getResultsPerQuery(productsQueries, prefetchedQueryString)?.pagination || pagination)
    .totalNumberOfResults;

  if (!totalNumberOfResults && queryApiStatus === apiStatus.success) {
    if (brandFallback && isBrandValid) {
      return <BrandProductsNotFound brand={brand} />;
    } else if (brandFallback) {
      return <PageNotFound categories={categories} />;
    }
    return null;
  }

  return (
    <div>
      {productsQuery && (
        <CmsProductListing
          prefetchedFacets={productsQueries?.[prefetchedQueryString]?.facets}
          totalNumberOfResults={totalNumberOfResults}
          visibleProducts={visibleProducts}
          currentPage={currentPage}
          numberOfPages={pagination.numberOfPages}
          sorts={sorts}
          brand={brand}
          isBrandPage={isBrandPage}
          productsQuery={productsQuery}
          changeSortOrder={changeSortOrder}
          changePage={changePage}
          searchQueryType={searchQueryType}
          numberOfFacets={numberOfFacets}
          numberOfSelectedFacets={numberOfSelectedFacets}
          isFilterMenuOpen={isFilterMenuOpen}
          handleSliderChange={handleSliderChange}
          requestStatus={queryApiStatus}
          submitFilters={submitFilters}
          hasFiltersApplied={hasFiltersApplied}
          prefetchFilter={prefetchFilter}
          resetFacet={resetFacet}
          resetAllFacets={resetAllFacets}
        />
      )}
    </div>
  );
};

CmsProductListingContainer.displayName = 'containers/CmsProductListingContainer';
CmsProductListingContainer.propTypes = {
  brandFallback: PropTypes.bool,
  categories: PropTypes.object,
  inputValue: PropTypes.string,
  isBrandPage: PropTypes.bool,
  isFilterMenuOpen: PropTypes.bool,
  loadProducts: PropTypes.func,
  productsQueries: PropTypes.object,
  isBrandValid: PropTypes.bool,
  searchQuery: PropTypes.string,
  searchQueryType: PropTypes.string,
  trackProductOverview: PropTypes.func,
  location: PropTypes.object,
};
CmsProductListingContainer.defaultProps = {
  brandFallback: false,
  categories: {},
  inputValue: '',
  isBrandPage: true,
  isFilterMenuOpen: false,
  loadProducts: () => {},
  productsQueries: {},
  isBrandValid: true,
  searchQuery: '',
  searchQueryType: '',
  trackProductOverview: () => {},
};

export default connect(mapStateToProps, mapDispatchToProps)(CmsProductListingContainer);
