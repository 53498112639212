import { scrollUpToId, TOP_CONTENT_ANCHOR } from 'constants/scrollToElement/scrollToElement';

import {
  GET_CONTACT_SUBJECTS,
  UPDATE_CONTACT_FIELD,
  BLUR_CONTACT_FIELD,
  SEND_CONTACT,
  CONTACT_VALIDATION_ERROR,
  REINITIALIZE_CONTACT_FORM,
} from 'constants/ActionTypes/ActionTypes';

import ContactAPI from 'api/ContactAPI/ContactAPI';

import { validateContact } from 'reducers/contact/contact.base';

export const getContactSubjects = () => ({
  type: GET_CONTACT_SUBJECTS,
  promise: ContactAPI.getContactSubjects(),
});

export const updateContactField = (fieldName, value) => ({
  type: UPDATE_CONTACT_FIELD,
  payload: {
    fieldName,
    value,
  },
});

export const blurContactField = (fieldName) => ({
  type: BLUR_CONTACT_FIELD,
  payload: {
    fieldName,
  },
});

export const sendContactRequest = (fields, subject) => {
  let out = { type: CONTACT_VALIDATION_ERROR };
  const validated = validateContact(fields);
  if (validated.valid) {
    scrollUpToId(TOP_CONTENT_ANCHOR);
    out = {
      type: SEND_CONTACT,
      promise: ContactAPI.sendContactRequest(fields, subject),
    };
  }
  return out;
};

export const reinitializeContactForm = () => ({
  type: REINITIALIZE_CONTACT_FORM,
});

const actions = {
  getContactSubjects,
  updateContactField,
  blurContactField,
  sendContactRequest,
  reinitializeContactForm,
};

export default actions;
