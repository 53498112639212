import React, { useState } from 'react';
import styles from 'components/atoms/FacetFilterType/FacetFilterType.scss';
import language from 'constants/language/language';
import Toggle from 'components/atoms/Toggle/Toggle';
import PropTypes from 'prop-types';
import { FacetPropType, facetTypes } from 'constants/facetsHelper/facetsHelper';

const FilterToggle = ({ facet, prefetchFilter }) => {
  const selectedFacetValues =
    facet?.type === facetTypes.TOGGLE
      ? facet?.values.filter(({ selected, code }) => selected === true && code !== 'false')
      : facet?.selectedValues;
  const [filter, setFilter] = useState(selectedFacetValues);
  const toggleText = language(`filterToggle.${facet?.code}.text`, '');

  const toggleAction = (isActive) => {
    const newFilter = facet.values.filter((val) => val.code === String(isActive) && val.code !== 'false');
    setFilter(newFilter);
    prefetchFilter({ filter: newFilter, facetCode: facet.code });
  };

  return (
    <div>
      <p className={styles.text}>{toggleText}</p>
      <div className={styles.facetFilterFlex}>
        <Toggle isActive={filter.some(({ code }) => code === 'true')} toggleAction={toggleAction} />
      </div>
    </div>
  );
};

FilterToggle.propTypes = {
  facet: FacetPropType.isRequired,
  prefetchFilter: PropTypes.func.isRequired,
};
FilterToggle.displayName = 'atoms/FilterToggle';

export default FilterToggle;
