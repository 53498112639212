import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { recoTrackingEndpoints as recoTypes } from 'api/RecoAPI/RecoAPI';
import { getCategoryMapByPriority, getCategoryUrl, getStringCategoriesName } from 'constants/categoryTree/categoryTree';
import language, { getLocale } from 'constants/language/language';
import dataTracking from 'constants/trackingAttributes/dataTracking';
import ProductRecoContainer from 'containers/ProductRecommendations/ProductRecommendations';
import ProductRecoSlider from 'components/organisms/ProductRecoSlider/ProductRecoSlider';
import { Row, Col } from 'components/atoms/Grid/Grid';
import Headline from 'components/atoms/Headline/Headline';
import Icon, { ICON_ARROW_FORWARD } from 'components/atoms/Icon/Icon';
import styles from './PageNotFound.scss';

const PageNotFound = ({ categories = {} }) => {
  const LocalLink = ({ link, text }) => <Link to={link}>{text}</Link>;
  LocalLink.propTypes = {
    link: PropTypes.string,
    text: PropTypes.string,
  };

  const locale = getLocale();
  const categoryLinks = getCategoryMapByPriority(categories)
    .map(({ id }) => ({
      id,
      name: getStringCategoriesName(id, locale, categories),
      url: getCategoryUrl(id, locale, categories),
    }))
    .sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });

  return (
    <>
      <Row className={styles.row}>
        <Col>
          <Headline tag={Headline.tags.H1}>{language('pageNotFound.title')}</Headline>
          <div className={styles.infoText}>{language('pageNotFound.info')}</div>
        </Col>
      </Row>
      <ProductRecoContainer type={recoTypes.ERROR}>
        <ProductRecoSlider />
      </ProductRecoContainer>
      <Row>
        <Col>
          <Headline tag={Headline.tags.H2}>{language('pageNotFound.rangeOverview')}</Headline>
        </Col>
        <Col>
          <ul className={styles.categories}>
            {categoryLinks.map((category, index) => (
              <li key={category.id}>
                <Headline tag={Headline.tags.H3}>
                  <Link
                    to={category.url}
                    className={styles.categoryLink}
                    data-tracking={dataTracking({
                      INTERACTION: 'click',
                      LINK: category.link,
                      INNERLOCATION: index + 1,
                      FUNCTION: 'go-to',
                      ATTRIBUTE_1: category.name,
                    })}
                  >
                    <Icon path={ICON_ARROW_FORWARD} className={styles.catgeoryListIcon} />
                    <span className={styles.catgeoryListText}>{category.name}</span>
                  </Link>
                </Headline>
              </li>
            ))}
          </ul>
        </Col>
      </Row>
    </>
  );
};

PageNotFound.displayName = 'templates/PageNotFound';
PageNotFound.propTypes = {
  categories: PropTypes.object,
};

export default PageNotFound;
