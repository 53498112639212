import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { resendDoubleOptInMail } from 'actions/userActions/userActions';
import Meta from 'containers/Meta/Meta';
import OptInOrganism from 'components/organisms/OptIn/OptIn';
import NextFeatureEnabled from 'routes/ProductsOverview/NextFeatureEnabled';

const mapStateToProps = (state, ownProps) => ({
  email: state.user?.tokenMail || ownProps.location?.state?.email,
});
const mapDispatchToProps = {
  resendDoubleOptIn: resendDoubleOptInMail,
};

const OptIn = ({ email = '', resendDoubleOptIn = () => {}, route = {} }) => (
  <>
    {/* See https://interdiscount.atlassian.net/browse/SWAT-7724?focusedCommentId=783908 */}
    <NextFeatureEnabled conditions={[{ featureName: 'nextLoginRegistrationEnabled', include: 'registration' }]} />
    <Meta meta={[{ name: 'robots', content: 'noindex' }]} />
    <OptInOrganism type={route.type} page={route.page} resendDoubleOptInMail={resendDoubleOptIn} email={email} />
  </>
);

OptIn.displayName = 'containers/OptIn';
OptIn.propTypes = {
  email: PropTypes.string,
  resendDoubleOptIn: PropTypes.func,
  route: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(OptIn);
