import Headline from 'components/atoms/Headline/Headline';

export const HeaderOverviewConfig = {
  headlineMargin: Headline.margins.NONE,
};

export const HeaderListConfig = {
  headlineMargin: Headline.margins.MB_15,
  productTitleMargin: Headline.margins.NONE,
};

export default {
  HeaderOverviewConfig,
  HeaderListConfig,
};
