import { previews } from '../../previewFeatures';

const isMobileApp = () => {
  if (__CLIENT__ && previews.appMode.status) {
    return true;
  }

  return __CLIENT__ && (navigator.userAgent.indexOf('gonative') > -1 || navigator.userAgent.indexOf('median') > -1);
};

export default isMobileApp;
