import React from 'react';
import PropTypes from 'prop-types';
import { recoTrackingEndpoints as recoTypes } from 'api/RecoAPI/RecoAPI';
import ProductRecoContainer from 'containers/ProductRecommendations/ProductRecommendations';
import ProductRecoSlider from 'components/organisms/ProductRecoSlider/ProductRecoSlider';
import translations from 'constants/language/german';

const CategoryRecoBox = ({ categoryCode }) => (
  <ProductRecoContainer params={{ cid: categoryCode }} type={recoTypes.CATEGORY}>
    <ProductRecoSlider
      trackingAttributes={{ prefix: 'productbox', parentTitle: translations.recommendations.topProducts }}
    />
  </ProductRecoContainer>
);

CategoryRecoBox.propTypes = {
  categoryCode: PropTypes.string,
};

CategoryRecoBox.displayName = 'organisms/CategoryRecoBox';

export default CategoryRecoBox;
