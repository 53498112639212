import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import language from 'constants/language/language';
import Meta from 'containers/Meta/Meta';
import PageNotFound from 'components/templates/PageNotFound/PageNotFound';
import Breadcrumb, { types } from 'components/organisms/Breadcrumb/Breadcrumb';
import { setNotFoundError } from 'actions/renderingActions/renderingActions';

const mapStateToProps = (state) => ({
  categories: state?.categories || {},
});

const mapDispatchToProps = {
  setNotFoundState: setNotFoundError,
};

const NotFoundView = ({ categories = {}, setNotFoundState }) => {
  if (!__CLIENT__) {
    setNotFoundState();
  }
  const breadCrumb = [
    {
      name: language('breadcrumb.pageNotFound'),
      href: '/',
    },
  ];
  return (
    <>
      <Meta title={language('pageTitles.notFound')} meta={[{ name: 'robots', content: 'noindex' }]} />
      <Breadcrumb type={types.category} items={breadCrumb} />
      <PageNotFound categories={categories} />
    </>
  );
};

NotFoundView.displayName = 'routes/NotFound';
NotFoundView.propTypes = {
  categories: PropTypes.object,
  setNotFoundState: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotFoundView);
