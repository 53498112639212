import React from 'react';

import Spinner from 'components/atoms/Spinner/Spinner';
import Sticky from 'components/atoms/Sticky/Sticky';

import styles from './SpinnerOverlay.scss';
import { cypressAttributes } from 'constants/cypress/cypress';

const SpinnerOverlay = () => (
  <div className={styles.stickyWrapper}>
    <Sticky className={styles.beforeSticky} cyData={cypressAttributes.loadingSpinner}>
      <div className={styles.spinnerOverlay}>
        <Spinner />
      </div>
    </Sticky>
  </div>
);

SpinnerOverlay.displayName = 'molecules/SpinnerOverlay';

export default SpinnerOverlay;
