import {
  CART,
  ACCOUNT,
  ACCOUNT_LOGIN,
  ACCOUNT_REGISTRATION,
  ACCOUNT_ORDERS,
  ACCOUNT_ADDRESSES,
  ACCOUNT_ORDER_DETAILS,
  ACCOUNT_USERDATA,
  ACCOUNT_RATINGS,
  PASSWORD,
  PASSWORD_RESET,
  CHECKOUT,
  CHECKOUT_GUEST,
  CHECKOUT_LOGIN,
  ORDER_CONFIRMATION,
  NEWSLETTER,
  NEWSLETTER_REGISTRATION,
  NEWSLETTER_VERIFICATION,
  CMS,
  SERVICEAWARDS,
  SERVICE,
  BLOG,
  ACCOUNT_NOTIFICATIONS,
  ACCOUNT_RETURNS,
  ACCOUNT_RETURN,
  ACCOUNT_RETURN_CREATE,
  ACCOUNT_DELETE_CONFIRMATION,
} from 'constants/routePaths/routePaths';

// CART
export const cart = [CART];

// Account
export const account = [ACCOUNT];
export const accountLogin = [ACCOUNT, ACCOUNT_LOGIN];
export const accountRegistration = [ACCOUNT, ACCOUNT_REGISTRATION];
export const accountOrders = [ACCOUNT, ACCOUNT_ORDERS];
export const accountAddresses = [ACCOUNT, ACCOUNT_ADDRESSES];
export const accountOrderDetails = [ACCOUNT, ACCOUNT_ORDER_DETAILS];
export const accountUserdata = [ACCOUNT, ACCOUNT_USERDATA];
export const accountRatings = [ACCOUNT, ACCOUNT_RATINGS];
export const accountNotifications = [ACCOUNT, ACCOUNT_NOTIFICATIONS];
export const accountReturns = [ACCOUNT, ACCOUNT_RETURNS];
export const accountReturnDetails = [ACCOUNT, ACCOUNT_RETURN, ACCOUNT_RETURN_CREATE];
export const accountDeleteAccountConfirmation = [ACCOUNT, ACCOUNT_DELETE_CONFIRMATION];

// Password
export const password = [PASSWORD];
export const passwordReset = [PASSWORD, PASSWORD_RESET];

// Checkout
export const checkout = [CHECKOUT];
export const checkoutGuest = [CHECKOUT, CHECKOUT_GUEST];
export const checkoutLogin = [CHECKOUT, CHECKOUT_LOGIN];

// Confirmation
export const orderConfirmation = [ORDER_CONFIRMATION];

// NEWSLETTER
export const newsletter = [NEWSLETTER];
export const newsletterRegistration = [NEWSLETTER, NEWSLETTER_REGISTRATION];
export const newsletterVerification = [NEWSLETTER, NEWSLETTER_VERIFICATION];

// STOREFINDER
export const awards = [CMS, SERVICE, SERVICEAWARDS];

// BLOG
export const blog = [CMS, BLOG];
