import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import i18n from 'constants/language/language';
import dataTracking from 'constants/trackingAttributes/dataTracking';

import styles from './PlusBox.scss';

export const PlusBox = ({ id = '', children, label = '', hideLabelOnClick = false, trackingAttributes }) => {
  const [isOpen, setOpen] = React.useState(false);
  const showHandle = !(hideLabelOnClick && isOpen);
  const handle = label ? label.replace('+', '').trim() : i18n('product.infoLink');

  const handleClick = (event) => {
    setOpen(!isOpen);
    event.preventDefault();
  };

  return (
    <span
      data-tracking={trackingAttributes && dataTracking({ ...trackingAttributes, FUNCTION: isOpen ? 'close' : 'open' })}
    >
      {showHandle && (
        <button id={id} className={styles.link} onClick={handleClick}>
          {handle} <span className={cx(styles.icon, { [styles.isOpen]: isOpen })}></span>
        </button>
      )}
      {isOpen && children}
    </span>
  );
};

PlusBox.displayName = 'atoms/PlusBox';
PlusBox.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  label: PropTypes.string,
  hideLabelOnClick: PropTypes.bool,
  trackingAttributes: PropTypes.object,
};

export default PlusBox;
