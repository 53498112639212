import React, { Component } from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';

import Expander from 'components/molecules/Expander/Expander';
import AlternateExpander from 'components/atoms/AlternateExpander/AlternateExpander';
import { scrollToElement } from 'constants/scrollToElement/scrollToElement';

const animationLength = 300;

export default class ExpandableBox extends Component {
  static displayName = 'molecules/ExpandableBox';

  static propTypes = {
    fullyExpanded: PropTypes.bool,
    visibleChildren: PropTypes.number,
    stepSize: PropTypes.number,
    children: PropTypes.node.isRequired,
    alternateExpander: PropTypes.bool,
  };

  static defaultProps = {
    fullyExpanded: false,
    stepSize: 0,
    alternateExpander: false,
  };

  constructor(props) {
    super(props);
    this.instanceId = `expand_${uuid()}`;
    this.state = {
      visibleChildren: props.visibleChildren,
      fullyExpanded: props.fullyExpanded,
      wrapperHeight: null,
      wrapperOverflowHidden: false,
    };
  }

  UNSAFE_componentWillMount() {
    if (this.isFullyExpanded()) {
      this.setState({
        fullyExpanded: true,
      });
    }
  }

  componentDidUpdate() {
    this.setWrapperHeight(this.getContainerHeight());
  }

  getContainerHeight() {
    return this.containerElement.clientHeight;
  }

  setWrapperHeight(height) {
    if (this.state.wrapperHeight !== height) {
      this.setState({
        wrapperHeight: height,
        wrapperOverflowHidden: true,
      });

      setTimeout(() => {
        this.setState({
          wrapperOverflowHidden: false,
        });
      }, animationLength);
    }
  }

  isFullyExpanded() {
    return this.props.visibleChildren === this.props.children.length;
  }

  handleOnClick = () => {
    const childrenSize = this.state.visibleChildren + this.props.stepSize;

    // check if the expandable box was fully expanded
    if (this.state.fullyExpanded) {
      // reset expandable box to initial state
      this.setState({
        visibleChildren: this.props.visibleChildren,
        fullyExpanded: false,
      });

      // scroll to a anchor point
      scrollToElement(this.instanceId, animationLength);
    } else {
      // check if the max number of children was reached
      if (childrenSize >= this.props.children.length) {
        this.setState({
          visibleChildren: this.props.children.length,
          fullyExpanded: true,
        });
      } else {
        this.setState({
          visibleChildren: childrenSize,
          fullyExpanded: false,
        });
      }
    }
  };

  retrieveChildrenToRender() {
    let visibleChildren = 0;
    if (this.state.fullyExpanded) {
      visibleChildren = this.props.children.length;
    } else {
      visibleChildren = this.state.visibleChildren;
    }
    return this.props.children.slice(0, visibleChildren);
  }

  containerElement = null;

  render() {
    const children = this.retrieveChildrenToRender();

    return (
      <div>
        <div
          id={this.instanceId}
          style={{
            height: this.state.wrapperHeight || 'auto',
            overflow: this.state.wrapperOverflowHidden ? 'hidden' : 'visible',
            transitionProperty: 'height',
            transitionDuration: `${animationLength}ms`,
          }}
        >
          <div ref={(ref) => (this.containerElement = ref)} data-test-1>
            {children}
          </div>
        </div>
        {this.props.alternateExpander ? (
          <AlternateExpander handleClick={this.handleOnClick} expanded={this.state.fullyExpanded} />
        ) : (
          <Expander handle={this.handleOnClick} fullyExpanded={this.state.fullyExpanded} />
        )}
      </div>
    );
  }
}
