import { Component, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { provideLink } from 'constants/navigation/navigation';
import { getLocale } from 'constants/language/language';

export const mapStateToProps = (state) => ({
  pages: state?.cms?.navigation?.pages || {},
});

export class CmsLink extends Component {
  static displayName = 'containers/CmsLink';

  static propTypes = {
    pages: PropTypes.object,
    children: PropTypes.node.isRequired,
    pageId: PropTypes.string.isRequired,
  };

  static defaultProps = {
    pages: {},
  };

  render() {
    const locale = getLocale();
    const { pages, children, pageId } = this.props;

    const { text, url } = provideLink(pages, pageId, locale);

    return cloneElement(children, {
      ...this.props,
      link: url,
      url,
      text,
      to: url,
    });
  }
}

export default connect(mapStateToProps)(CmsLink);
