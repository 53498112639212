const initial = {
  value: '',
};

export const mapAddressToAddressData = ({
  salutation = initial,
  firstName = initial,
  lastName = initial,
  company = initial,
  addressLine1 = initial,
  addressLine2 = initial,
  town = initial,
  postalCode = initial,
  country = initial,
  addition = initial,
  phone = initial,
  birthday = initial,
}) => ({
  titleCode: salutation.value,
  firstName: firstName.value,
  lastName: lastName.value,
  companyName: company.value,
  streetName: addressLine1.value,
  streetNumber: addressLine2.value,
  town: town.value,
  postalCode: postalCode.value,
  country: {
    isocode: country.value,
  },
  remarks: addition.value,
  phone: phone.value,
  birthday: new Date(birthday.value),
});

export const mapAddressDataToAddress = ({
  id,
  titleCode,
  firstName,
  lastName,
  companyName,
  streetName,
  streetNumber,
  town,
  postalCode,
  country = { isocode: '' },
  remarks,
  phone,
  readonly,
  billingAddress,
  shippingAddress,
}) => ({
  id,
  fields: {
    salutation: {
      value: titleCode,
    },
    firstName: {
      value: firstName,
    },
    lastName: {
      value: lastName,
    },
    company: {
      value: companyName,
    },
    addressLine1: {
      value: streetName,
    },
    addressLine2: {
      value: streetNumber,
    },
    town: {
      value: town,
    },
    postalCode: {
      value: postalCode,
    },
    country: {
      value: country.isocode,
    },
    addition: {
      value: remarks,
    },
    phone: {
      value: phone,
    },
  },
  readonly,
  invoice: billingAddress,
  delivery: shippingAddress,
});

export default {
  mapAddressToAddressData,
  mapAddressDataToAddress,
};
