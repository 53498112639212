import React from 'react';
import PropTypes from 'prop-types';
import Card from 'components/molecules/Card/Card';
import { Col, Row } from 'components/atoms/Grid/Grid';
import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';
import styles from './CardWallHome.scss';

const SKINS = Card.SKINS;

const CardWallHome = ({ cards, skin, callToAction }) => (
  <Row>
    {cards.map((card, index) => (
      <Col key={index} md={6}>
        <Card card={card} skin={skin} trackingAttributes={{ index }} />
      </Col>
    ))}
    {!!Object.entries(callToAction).length && callToAction.actionText && callToAction.text && callToAction.url && (
      <Col>
        <div className={styles.showAll}>
          <span className={styles.text}>{callToAction.text}</span>
          <div className={styles.link}>
            <ButtonLink href={callToAction.url} color={ButtonLink.colors.red} stretched>
              {callToAction.actionText}
            </ButtonLink>
          </div>
        </div>
      </Col>
    )}
  </Row>
);

CardWallHome.SKINS = SKINS;

CardWallHome.displayName = 'molecules/CardWallHome';
CardWallHome.propTypes = {
  callToAction: PropTypes.shape({
    actionText: PropTypes.string,
    text: PropTypes.string,
    url: PropTypes.string,
  }),
  cards: PropTypes.array,
  skin: PropTypes.oneOf(Object.values(SKINS)),
};
CardWallHome.defaultProps = {
  callToAction: {},
  cards: [],
  skin: SKINS.HOME,
};

export default CardWallHome;
