import { Component, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  forgottenPasswordRestorePassword,
  forgottenPasswordCheckToken,
  updatePasswordField,
} from 'actions/userActions/userActions';

export const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  forgottenPasswordRestorePassword,
  updatePasswordField,
  forgottenPasswordCheckToken,
};

export class PasswordReset extends Component {
  static displayName = 'containers/PasswordReset';
  static propTypes = {
    children: PropTypes.node.isRequired,
    user: PropTypes.object,
    updatePasswordField: PropTypes.func,
    forgottenPasswordCheckToken: PropTypes.func,
    forgottenPasswordRestorePassword: PropTypes.func,
  };

  render() {
    return cloneElement(this.props.children, {
      user: this.props.user,
      updatePasswordField: this.props.updatePasswordField,
      forgottenPasswordRestorePassword: this.props.forgottenPasswordRestorePassword,
      forgottenPasswordCheckToken: this.props.forgottenPasswordCheckToken,
      restorePasswordStatus: this.props.user.restorePasswordStatus,
      restorePasswordFailureMessage: this.props.user.restorePasswordFailureMessage,
    });
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
