export default function api(state = { errorStates: [], requests: 0, errors: {} }, action) {
  // get the base name for a group of actions
  const actionName = action.type.replace(/(_SUCCESS|_FAILURE)$/, '');

  if (/_REQUEST/.test(action.type)) {
    return {
      ...state,
      requests: state.requests + 1,
    };
  }

  if (/_SUCCESS/.test(action.type)) {
    return {
      ...state,
      errorStates: state.errorStates.filter((e) => e !== actionName),
      requests: state.requests > 0 ? state.requests - 1 : 0,
    };
  }

  if (/_FAILURE/.test(action.type)) {
    return {
      ...state,
      errorStates: [
        // move actionName to the end
        ...state.errorStates.filter((e) => e !== actionName),
        actionName,
      ],
      errors: {
        ...Object.entries(state?.errors || {})?.filter((e) => e[0] !== actionName),
        [actionName]: [...(action.error?.data?.errors || [])],
      },
      requests: state.requests > 0 ? state.requests - 1 : 0,
    };
  }

  if (/_NETWORKERROR/.test(action.type)) {
    return {
      ...state,
      errorStates: [
        // move actionName to the end
        ...state.errorStates.filter((e) => e !== actionName),
        actionName,
      ],
      requests: state.requests > 0 ? state.requests - 1 : 0,
    };
  }

  return state;
}
