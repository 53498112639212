import React from 'react';
import PropTypes from 'prop-types';
import styles from './OpeningHoursTimeRange.scss';
import sortBy from 'constants/helper/sortBy';
import getTimeFromTimestamp from 'constants/getTimeFromTimestamp/getTimeFromTimestamp';

const OpeningHoursTimeRange = ({ openingDayTimeRangeDataList = [], formattingStyleDot = false }) => {
  const openingHours = openingDayTimeRangeDataList.sort(sortBy('openingTime'));

  const timeRange = openingHours.map((o, i) =>
    !!openingDayTimeRangeDataList && openingDayTimeRangeDataList.length > 0 ? (
      <div key={i} className={styles.range}>
        <time className={styles.time}>{getTimeFromTimestamp(o.openingTime, formattingStyleDot)}</time>
        <span className={styles.to}>&ndash;</span>
        <time className={styles.time}>{getTimeFromTimestamp(o.closingTime, formattingStyleDot)}</time>
      </div>
    ) : (
      <div></div>
    )
  );

  return <div>{timeRange}</div>;
};

OpeningHoursTimeRange.propTypes = {
  openingDayTimeRangeDataList: PropTypes.array,
  formattingStyleDot: PropTypes.bool,
};

OpeningHoursTimeRange.defaultProps = {
  openingDayTimeRangeDataList: [],
  formattingStyleDot: false,
};

OpeningHoursTimeRange.displayName = 'atoms/OpeningHoursTimeRange';

export default OpeningHoursTimeRange;
