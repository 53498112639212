import { handleActions } from 'redux-actions';
import {
  RENDER_APP_BAD_REQUEST,
  RENDER_APP_INTERNAL_SERVER_ERROR,
  RENDER_APP_NOT_FOUND,
} from 'constants/ActionTypes/ActionTypes';

export const initialState = {
  badRequestError: false,
  notFoundError: false,
  internalServerError: false,
};

export default handleActions(
  {
    [RENDER_APP_BAD_REQUEST]: (state) => {
      return {
        ...state,
        badRequestError: true,
      };
    },
    [RENDER_APP_NOT_FOUND]: (state) => {
      return {
        ...state,
        notFoundError: true,
      };
    },
    [RENDER_APP_INTERNAL_SERVER_ERROR]: (state) => {
      return {
        ...state,
        internalServerError: true,
      };
    },
  },
  initialState
);
