import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@interdiscount/component-library-interdiscount';
import ProductMinimal from 'components/molecules/ProductMinimal/ProductMinimal';
import styles from './ModalUpsellingCart.scss';

const ModalUpsellingCart = ({
  alt = '',
  children,
  closeModal = () => {},
  code = '',
  images = [],
  isModalOpen = false,
  productName,
}) => (
  <Modal onRequestClose={closeModal} isOpen={isModalOpen} size="LG">
    <ProductMinimal name={productName} images={images} alt={alt} code={code} />
    <hr className={styles.hr} />
    {children}
  </Modal>
);

ModalUpsellingCart.propTypes = {
  alt: PropTypes.string,
  children: PropTypes.node.isRequired,
  closeModal: PropTypes.func,
  code: PropTypes.string,
  images: PropTypes.array,
  isModalOpen: PropTypes.bool,
  productName: PropTypes.string.isRequired,
};

export default ModalUpsellingCart;
