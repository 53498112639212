// @flow

import { handleActions } from 'redux-actions';
import keyBy from 'constants/helper/keyBy';
import { DEFAULT_DELIVERY_APPLIED, SELECTED_ADDRESS_ID } from 'constants/storage/storageKeys';

import {
  ADD_TO_CART_SUCCESS,
  ADD_TO_CART_FAILURE,
  ADD_TO_CART_NETWORKERROR,
  ADD_ACCESSORY_TO_CART_SUCCESS,
  GET_CART_SUCCESS,
  GET_CART_REQUEST,
  GET_CART_FAILURE,
  GET_CART_NETWORKERROR,
  RESET_CART_SUCCESS,
  MERGE_CART_SUCCESS,
  UPDATE_ENTRY_REQUEST,
  UPDATE_ENTRY_SUCCESS,
  UPDATE_ENTRY_FAILURE,
  UPDATE_ENTRY_NETWORKERROR,
  DELETE_FROM_CART_SUCCESS,
  DELETE_FROM_CART_REQUEST,
  DELETE_FROM_CART_FAILURE,
  DELETE_FROM_CART_NETWORKERROR,
  REMOVE_LAST_DELETED_ITEM,
  GET_PAYMENT_MODES_SUCCESS,
  GET_PAYMENT_MODES_REQUEST,
  GET_PAYMENT_MODES_FAILURE,
  GET_PAYMENT_MODES_NETWORKERROR,
  SET_PAYMENT_MODE_SUCCESS,
  SET_PAYMENT_MODE_FAILURE,
  SET_PAYMENT_MODE_NETWORKERROR,
  SET_PAYMENT_MODE_REQUEST,
  RESET_PAYMENT_MODE_SUCCESS,
  RESET_PAYMENT_MODE_FAILURE,
  GET_FOOTER_PAYMENT_MODES_SUCCESS,
  GET_FOOTER_PAYMENT_MODES_REQUEST,
  GET_FOOTER_PAYMENT_MODES_FAILURE,
  GET_FOOTER_PAYMENT_MODES_NETWORKERROR,
  RESET_ADD_TO_CART_STATE,
  GET_DELIVERY_MODES_SUCCESS,
  GET_DELIVERY_MODES_REQUEST,
  GET_DELIVERY_MODES_FAILURE,
  GET_DELIVERY_MODES_NETWORKERROR,
  SET_DELIVERY_MODE_REQUEST,
  SET_DELIVERY_MODE_SUCCESS,
  SET_DELIVERY_MODE_FAILURE,
  SET_DELIVERY_MODE_NETWORKERROR,
  UPDATE_TRANSPORT_GUARANTEE_SUCCESS,
  UPDATE_NEUTRAL_DELIVERY_REQUEST,
  UPDATE_NEUTRAL_DELIVERY_FAILURE,
  UPDATE_NEUTRAL_DELIVERY_NETWORKERROR,
  GET_CURRENT_DELIVERY_MODE_SUCCESS,
  GET_CURRENT_DELIVERY_MODE_FAILURE,
  GET_CURRENT_DELIVERY_MODE_REQUEST,
  GET_CURRENT_DELIVERY_MODE_NETWORKERROR,
  CREATE_GUEST_DELIVERY_ADDRESS,
  REMOVE_GUEST_DELIVERY_ADDRESS,
  SET_ADDRESS_SUCCESS,
  REMOVE_ADDRESS_SUCCESS,
  GET_DATATRANS_AUTHORISATION_SUCCESS,
  SAVE_PREVIOUS_PAYMENT_MODE,
  FLAG_ADDRESS_TYPE_FOR_SELECTION,
  CREATE_USER_ADDRESS_AND_SELECT_SUCCESS,
  DELETE_USER_ADDRESS_SUCCESS,
  REMOVE_VOUCHER_SUCCESS,
  APPLY_VOUCHER_REQUEST,
  APPLY_VOUCHER_SUCCESS,
  APPLY_VOUCHER_FAILURE,
  APPLY_VOUCHER_NETWORKERROR,
  CLEAR_VOUCHER_VALIDATION_RESULT,
  DELETE_TEMP_ADDRESS,
  RESET_USER,
  CHANGE_AGE_VERIFICATION_TYPE,
  VERIFY_AGE_SUCCESS,
  SET_VERIFY_AGE_MODAL_ISOPEN,
  UPDATE_PEGI_FIELD_VALUE,
  SET_PEGI_FORCE_VALIDATION,
  VERIFY_AGE_FAILURE,
  RESET_AGE_VERIFICATION_TYPE,
  APPLY_GIFTCARD_REQUEST,
  APPLY_GIFTCARD_SUCCESS,
  APPLY_GIFTCARD_FAILURE,
  APPLY_GIFTCARD_NETWORKERROR,
  REMOVE_GIFTCARD_SUCCESS,
  CLEAR_GIFTCARD_VALIDATION_RESULT,
  CLEAR_UPDATED_CART_HISTORY,
  SET_CHECKOUT_NEED_STATUS,
  ROUTER_UPDATE_LOCATION,
  CHECK_STOCK_AVAILABILITY_FAILURE,
  UPDATE_PACKAGE_ANNOUNCEMENT_SUCCESS,
  VERIFY_RECAPTCHA_SUCCESS,
  VERIFY_RECAPTCHA_FAILURE,
  VERIFY_RECAPTCHA_NETWORKERROR,
  INIT_DATATRANS_TRANSACTION_SUCCESS,
  SET_CHECKOUT_GIFTCARD_DISALLOWED_CHANGED,
} from 'constants/ActionTypes/ActionTypes';
import { MONTHLYINVOICE_PCF } from 'constants/paymentModes/paymentModes';
import { apiStatus } from 'constants/apiStatus/apiStatus';
import validation, { ValidationTypes } from 'constants/validation/validation';
import { addressTypes } from 'constants/addresses/addresses';
import transform from 'constants/reducerHelper/reducerHelper';
import { cardTypes } from 'components/molecules/AgeVerificationCard/AgeVerificationCard';

import getMessageFromResponse from './cart.message';

import getStorage from 'constants/storage/storage';
const localStorage = getStorage(true);
import { IS_LOGGED_IN } from 'constants/storage/storageKeys';

import type { Cart } from 'reducers/cart/cart';

export const initialState = {
  guid: undefined,
  entries: [],
  value: {
    currencyIso: 'CHF',
    formattedValue: '0.00',
    priceType: 'BUY',
    value: 0,
  },
  totalReducedTax: {
    currencyIso: 'CHF',
    formattedValue: '0.00',
    priceType: 'BUY',
    value: 0,
  },
  totalFullTax: {
    currencyIso: 'CHF',
    formattedValue: '0.00',
    priceType: 'BUY',
    value: 0,
  },
  totalItems: 0,
  totalPriceNet: {
    currencyIso: 'CHF',
    formattedValue: '0.00',
    priceType: 'BUY',
    value: 0,
  },
  deliveryCost: {
    currencyIso: 'CHF',
    formattedValue: '0.00',
    priceType: 'BUY',
    value: 0,
  },
  paymentCost: {
    currencyIso: 'CHF',
    formattedValue: '0.00',
    priceType: 'BUY',
    value: 0,
  },
  orderData: {
    invoiceAddress: {
      reference: '',
      flaggedForSelection: false,
    },
    deliveryAddress: {
      reference: '',
      flaggedForSelection: false,
    },
    paymentMode: {
      validationResult: ValidationTypes.MissingPaymentMethod,
    },
    hasChangedPaymentMode: false,
    deliveryMode: {},
    voucher: {
      validationResult: ValidationTypes.Initial,
    },
    giftcard: {
      validationResult: ValidationTypes.Initial,
      recaptchaNeeded: false,
    },
  },
  subTotal: {
    currencyIso: 'CHF',
    formattedValue: '0.00',
    priceType: 'BUY',
    value: 0,
  },
  paymentModes: {},
  footerPaymentModes: {},
  deliveryModes: {
    'home-delivery-gross': {
      code: 'home-delivery-gross',
    },
    pickup: {
      code: 'pickup',
    },
  },
  forceValidation: false,
  transportGuarantee: {
    active: true,
    cost: {
      currencyIso: 'CHF',
      formattedValue: '0.00',
      priceType: 'BUY',
      value: 0,
    },
  },
  neutralDeliveryActive: false,
  datatransAuthorisation: {
    dataTransSrc: 'about:blank',
  },
  addToCartState: {
    statusCode: apiStatus.request,
    status: apiStatus.request,
    productTitle: '',
    productServices: [],
  },
  appliedVouchers: [],
  orderDiscount: {},
  redeemedGiftcards: [],
  verifyAgeModal: {
    isOpen: false,
  },
  pegiVerification: {
    identificationType: cardTypes.noCard,
    pegiVerified: false,
    forceValidation: false,
    pegiVerificationHasError: false,
  },
  stockAvailabilityChanged: false,
  giftcardPayAtPickupDisallowedChanged: false,
};

export const clonePegiObject = (newState: Cart, cart: Cart) => ({
  sendCopyVerification: newState.pegiVerification.sendCopyVerification,
  pegiVerified: !newState.pegiVerification.sendCopyVerification
    ? !newState.pegiVerification.pegiVerified && !cart.pegiVerificationNeeded
    : false,
  identificationType: newState.pegiVerification.identificationType,
  [newState.pegiVerification.identificationType]:
    newState.pegiVerification[newState.pegiVerification.identificationType],
  forceValidation: newState.pegiVerification.forceValidation,
  pegiVerificationHasError: newState.pegiVerification.pegiVerificationHasError,
});

const mapRemovedEntries = (updatedEntries = []) => {
  const removedEntries = [];
  updatedEntries.forEach((entry) => {
    if (entry.entryRemoved) {
      removedEntries.push({
        productCode: entry.productCode,
        productName: entry.productName,
      });
    }
  });

  return removedEntries;
};

const getMappedEntries = (cart = {}, state) => {
  const newEntries = [];
  const cartEntries = cart.entries || [];
  const updatedEntries = cart.updatedCartData?.cartEntries || [];

  // handling 'bulkyGood' is temporarily disabled in FE
  // @see https://atlassian.interdiscount.ch/jira/browse/SPQR-10635
  cartEntries.forEach(
    ({
      product,
      quantity,
      serviceItemCodes,
      serviceItems,
      entryNumber,
      totalAdditionalService,
      basePrice,
      discount,
      postDiscountPrice,
      preDiscountTotalPrice,
      absoluteDiscount,
      totalDiscount,
      discountName,
      employeeDiscount,
      productDiscount,
      orderDiscount,
      totalPrice,
      deliveryDate,
      deliveryDateTo,
      combinationProduct,
    }) => {
      const selectedServices = [];
      const updatedEntry = updatedEntries.find((entry) => entry.productCode === product.code);
      // $FlowFixMe
      const oldEntry = state?.entries?.[entryNumber] || {};

      if (Array.isArray(serviceItemCodes)) {
        serviceItemCodes.forEach((code) => {
          const serviceItem = serviceItems.find((item) => item.code === code);
          selectedServices.push({
            code,
            name: serviceItem.name,
            description: serviceItem.description,
            price: serviceItem.price,
            totalPrice: serviceItem.priceForQuantity,
          });
        });
      }

      newEntries[entryNumber] = {
        productCode: product.code,
        quantity,
        // Temporary disable bulkyGoods handling in FE:
        // @see https://atlassian.interdiscount.ch/jira/browse/SPQR-10635
        // bulkyGoods,
        serviceItemCodes,
        serviceItems: selectedServices,
        totalAdditionalService,
        basePrice,
        priceUpdated: oldEntry.priceUpdated || (updatedEntry?.priceUpdated ?? false),
        stockUpdated: oldEntry.stockUpdated || (updatedEntry?.stockUpdated ?? false),
        discountUpdated: oldEntry.discountUpdated || (updatedEntry?.discountUpdated ?? false),
        discount,
        postDiscountPrice,
        preDiscountTotalPrice,
        absoluteDiscount,
        totalDiscount,
        discountName,
        productDiscount,
        employeeDiscount,
        orderDiscount,
        totalPrice,
        deliveryDate,
        deliveryDateTo,
        product, // todo: check if could save this information in the product reducer
        entryNumber,
        combinationProduct,
      };
    }
  );

  return { entries: newEntries, updatedEntries };
};

export const isGuest = (isLoggedIn: boolean, email: string) =>
  !isLoggedIn || email === 'dummy@interdiscount.ch' || email === 'dummy@fake.org';

const mapCartProperties = (state, cart = {}) => {
  // map entries with the new product data from cart
  const mappedEntries = getMappedEntries(cart, state);

  // map removed entries we get from the updatedCartData Object in cart that mapped on step before
  const removedEntries = mapRemovedEntries(mappedEntries.updatedEntries);

  // get voucher items object with the right code values
  const getHydratedVoucherItems = (appliedVoucherItems = [], appliedVouchers = []) =>
    appliedVoucherItems.map((voucherItem, i) => {
      const hydratedItem = { ...voucherItem };
      hydratedItem.voucherCode = appliedVouchers[i];
      return hydratedItem;
    });

  // update state with all new data
  const newState = transform(state);

  if (Object.keys(cart).length) {
    newState
      .set('guid', cart.guid)
      .set('totalReducedTax', cart.totalReducedTax)
      .set('totalFullTax', cart.totalFullTax)
      .set('totalItems', cart.totalItems)
      .set('totalPriceNet', cart.totalPriceNet)
      .set('subTotal', cart.subTotal || {})
      .set('subTotalNet', cart.subTotalNet)
      .set('subTotalFullTax', cart.subTotalFullTax)
      .set('subTotalReducedTax', cart.subTotalReducedTax)
      .set('subTotalNoTax', cart.subTotalNoTax)
      .set('subTotalOrderFullTax', cart.subTotalOrderFullTax)
      .set('subTotalOrderReducedTax', cart.subTotalOrderReducedTax)
      .set('subTotalOrderNoTax', cart.subTotalOrderNoTax)
      .set('surchargeThreshold', cart.surchargeThreshold)
      .set('value', cart.totalPrice)
      .set('transportGuarantee', cart.transportGuarantee)
      .set('neutralDeliveryActive', cart.neutralDeliveryActive)
      .set('deliveryCost', cart.deliveryCost || {})
      .set('deliveryMode', cart.deliveryMode)
      .set('paymentCost', cart.paymentCost)
      .set('orderReference', cart.orderReference)
      .set('milaApplicable', cart.milaApplicable)
      .set('appliedVouchers', getHydratedVoucherItems(cart.appliedVoucherItems, cart.appliedVouchers))
      .set('redeemedGiftcards', cart.redeemedGiftcards)
      .set('pegiVerificationNeeded', cart.pegiVerificationNeeded)
      .set('cartContainsPegiProducts', cart.cartContainsPegiProducts)
      .set('pegiCustomerValue', cart.pegiCustomerValue)
      .set('pegiVerification', clonePegiObject(newState.value(), cart))
      .set('amountRemainingToPay', cart.amountRemainingToPay)
      .set('entries', mappedEntries.entries)
      .set('cartUpdated', state.cartUpdated || (cart.updatedCartData?.cartUpdated ?? false))
      // $FlowFixMe
      .set('removedEntries', Object.keys(state.removedEntries || {}).length ? state.removedEntries : removedEntries)
      .set('orderDiscount', cart.orderDiscount)
      .set('useAlias', cart.usingPaymentAlias)

      // Temporary disable bulkyGoods handling in FE:
      // @see https://atlassian.interdiscount.ch/jira/browse/SPQR-10635
      // Indicates if the cart has at least one product with a weight of more than 30kg (The flag bulkyGoods is also set
      // to the affected cart entry)
      // .set('bulkyGoods', cart.bulkyGoods)

      .set('packageAnnouncement', cart.packageAnnouncement);
  }

  // this hack is needed, since we have to disregard everything about addresses in guest checkout,
  // because the backend delivers dummy addresses there

  const isLoggedIn = JSON.parse(localStorage.getItem(IS_LOGGED_IN));

  if (!isGuest(isLoggedIn, cart.deliveryAddress?.email || '')) {
    const paymentAddress = cart.paymentAddress || {};
    const deliveryAddress = cart.deliveryAddress || {};
    if (Object.keys(paymentAddress).length) {
      newState.set('orderData.invoiceAddress.reference', paymentAddress.id);
    }
    if (Object.keys(deliveryAddress).length) {
      if (deliveryAddress.id) {
        newState.set('orderData.deliveryAddress.reference', deliveryAddress.id);
      } else {
        newState.set('orderData.deliveryAddress.reference', paymentAddress.id);
      }
    }
  }

  // this reference is used in routes/Checkout,js to force the patch-Call when a guest already set an address
  if (!cart.deliveryAddress?.email && !cart.paymentAddress?.email) {
    newState.set('orderData.guestAddress.reference', cart?.paymentAddress?.id);
  }

  if (Object.keys(cart.deliveryMode || {}).length) {
    newState.set('orderData.deliveryMode.reference', cart.deliveryMode.code);
  }
  if (Object.keys(cart.paymentMode || {}).length) {
    const savedPaymentCode = `${cart.paymentMode.code}${state.orderData.paymentMode.useAlias ? '_SAVED' : ''}`;
    newState.set('orderData.paymentMode.reference', savedPaymentCode);
  } else {
    newState.set('orderData.paymentMode.reference', undefined);
    newState.set('orderData.paymentMode.validationResult', ValidationTypes.MissingPaymentMethod);
    newState.set('paymentModeStatus', apiStatus.success);
  }

  return newState.value();
};

export default handleActions(
  {
    [ROUTER_UPDATE_LOCATION]: (state: Cart): Cart =>
      transform(state)
        .set('verifyAgeModal.isOpen', false)
        .set('addToCartState', {
          statusCode: apiStatus.request,
          status: apiStatus.request,
          productTitle: '',
        })
        .value(),
    [RESET_CART_SUCCESS]: () => {
      localStorage.removeItem(SELECTED_ADDRESS_ID);
      return initialState;
    },
    [GET_CART_SUCCESS]: (state: Cart, action): Cart => {
      const newState = transform(state).set('apiStatus', apiStatus.success);
      return mapCartProperties(newState.value(), action.req.data);
    },
    [GET_CART_REQUEST]: (state: Cart): Cart => transform(state).set('apiStatus', apiStatus.request).value(),
    [GET_CART_FAILURE]: (state: Cart): Cart => transform(state).set('apiStatus', apiStatus.failure).value(),
    [GET_CART_NETWORKERROR]: (state: Cart): Cart => transform(state).set('apiStatus', apiStatus.networkerror).value(),
    [MERGE_CART_SUCCESS]: (state: Cart, action): Cart => mapCartProperties(state, action.req.data.cart),
    [RESET_ADD_TO_CART_STATE]: (state: Cart): Cart =>
      transform(state)
        .set('addToCartState', {
          statusCode: apiStatus.request,
          status: apiStatus.request,
          productTitle: '',
        })
        .value(),
    [ADD_TO_CART_SUCCESS]: (state: Cart, action): Cart => {
      const newState = transform(state)
        .set('lastDeletedItem', {})
        .set('addToCartState', {
          statusCode: action.req?.data?.statusCode,
          status: action.req?.data?.statusCode === 'success' ? apiStatus.success : apiStatus.failure,
          productTitle: action.req?.data?.entry?.product?.name,
          productCode: action.req?.data?.entry?.product?.code,
          entryID: action.req?.data?.entry?.entryNumber,
          quantity: action.req?.data?.entry?.quantity,
          productServices: action.req?.data?.entry?.product?.productServices,
          accessories: [],
        });

      return mapCartProperties(newState.value(), action.req.data.cart);
    },
    [ADD_TO_CART_FAILURE]: (state: Cart): Cart =>
      transform(state)
        .set('addToCartState', {
          statusCode: apiStatus.failure,
          status: apiStatus.failure,
          productTitle: '',
        })
        .value(),
    [ADD_TO_CART_NETWORKERROR]: (state: Cart, action): Cart => {
      const data = JSON.parse(action.error?.config?.data || '{}');

      return transform(state)
        .set('addToCartState', {
          statusCode: apiStatus.networkerror,
          status: apiStatus.networkerror,
          productCode: data?.product?.code,
          quantity: data.quantity,
          productTitle: '',
        })
        .value();
    },

    [ADD_ACCESSORY_TO_CART_SUCCESS]: (state: Cart, action): Cart => {
      const newState = transform(state);
      const accessoryCode = action.req?.data?.entry?.product?.code;
      const currentAccessories = newState.value()?.addToCartState?.accessories;

      if (currentAccessories === undefined) {
        newState.set('addToCartState.accessories', [accessoryCode]);
      } else {
        const accessories = [...currentAccessories];
        accessories.push(accessoryCode);
        newState.set('addToCartState.accessories', accessories);
      }

      return mapCartProperties(newState.value(), action.req?.data?.cart);
    },

    [UPDATE_ENTRY_REQUEST]: (state: Cart, action): Cart => {
      const entryID = action.payload?.entryID;
      const timestamp = action.payload?.RequestTimestamp;
      // quantity is set in the cart's entry so that when the cart is fetched from BE
      // after changing the order quantity, components on the cart page actually re-render:
      const quantity = action.payload?.quantity;

      return transform(state)
        .set(`entries[${entryID}].lastRequestTimestamp`, timestamp)
        .set(`entries[${entryID}].quantity`, quantity)
        .set('cartApiStatus', apiStatus.request)
        .value();
    },

    [UPDATE_ENTRY_SUCCESS]: (state: Cart, action): Cart => {
      const entryID = action.payload?.entryID;
      const thisRequestTimestamp = action.payload?.RequestTimestamp;

      // status of update entry for shopping cart
      const cartStatusCode = action.req?.data?.statusCode;

      let newState = transform(state);

      // only the latest request will be mapped in the store
      const lastRequestTimestamp = state?.entries?.[entryID]?.lastRequestTimestamp;
      if (lastRequestTimestamp === thisRequestTimestamp) {
        const newCart = action.req?.data?.cart;
        newState = transform(mapCartProperties(state, newCart));
      }

      return newState
        .set('lastDeletedItem', {})
        .set('cartApiStatus', apiStatus.success)
        .set(`entries[${entryID}].cartStatusCode`, cartStatusCode)
        .value();
    },

    [UPDATE_ENTRY_FAILURE]: (state: Cart): Cart => transform(state).set('cartApiStatus', apiStatus.failure).value(),

    [UPDATE_ENTRY_NETWORKERROR]: (state: Cart): Cart =>
      transform(state).set('cartApiStatus', apiStatus.networkerror).value(),

    [DELETE_FROM_CART_SUCCESS]: (state: Cart, action): Cart =>
      mapCartProperties(
        transform(state)
          .set('lastDeletedItem', {
            // TODO adapt to fit future api changes/needs
            deleted: {
              ...state.entries[action.payload.entryNumber],
              deleted: true,
            },
          })
          .set('apiStatus', apiStatus.success)
          .value(),
        action.req.data.cart
      ),

    [DELETE_FROM_CART_REQUEST]: (state: Cart): Cart => transform(state).set('apiStatus', apiStatus.request).value(),
    [DELETE_FROM_CART_FAILURE]: (state: Cart): Cart => transform(state).set('apiStatus', apiStatus.failure).value(),
    [DELETE_FROM_CART_NETWORKERROR]: (state: Cart): Cart =>
      transform(state).set('apiStatus', apiStatus.networkerror).value(),

    [REMOVE_LAST_DELETED_ITEM]: (state: Cart): Cart => transform(state).set('lastDeletedItem', {}).value(),

    [GET_PAYMENT_MODES_SUCCESS]: (state: Cart, action): Cart => {
      const paymentModes = action.req?.paymentModes || [];
      const paymentMode = action.req?.paymentMode || '';
      const alias = action.req?.alias || {};
      const newPaymentModes = paymentModes.map((payment) => ({ ...state[payment.code], ...payment }));
      const deliveryAddrRef = state?.orderData?.deliveryAddress?.reference;
      const invoiceAddrRef = state?.orderData?.invoiceAddress?.reference;

      if (Object.keys(alias).length) {
        alias.code = `${alias.code}_SAVED`;
      }

      const newState = transform(state)
        .set('paymentModes', keyBy(newPaymentModes, 'code'))
        .set('savedPaymentModes', alias)
        .set('orderData', { ...state.orderData })
        .set('apiStatus', apiStatus.success)
        .set('orderData.paymentMode', {
          reference: paymentMode,
          validationResult: paymentMode === '' ? ValidationTypes.MissingPaymentMethod : ValidationTypes.Valid,
          useAlias: false,
        });

      if (newState.value()?.paymentModes?.[MONTHLYINVOICE_PCF]) {
        const hideMonthlyInvoice = !!deliveryAddrRef && !!invoiceAddrRef && deliveryAddrRef !== invoiceAddrRef;

        newState.set(`paymentModes[${MONTHLYINVOICE_PCF}].hidden`, hideMonthlyInvoice);

        if (hideMonthlyInvoice && state?.orderData?.paymentMode?.reference === MONTHLYINVOICE_PCF) {
          newState.set('orderData.paymentMode', {
            reference: '',
            validationResult: ValidationTypes.MissingPaymentMethod,
          });
        }
      }

      newState.set('paymentModeStatus', apiStatus.success);

      return newState.value();
    },

    [GET_PAYMENT_MODES_FAILURE]: (state: Cart): Cart =>
      transform(state).set('paymentModeStatus', apiStatus.failure).value(),

    [GET_PAYMENT_MODES_REQUEST]: (state: Cart): Cart =>
      transform(state).set('paymentModeStatus', apiStatus.request).value(),

    [GET_PAYMENT_MODES_NETWORKERROR]: (state: Cart): Cart =>
      transform(state).set('paymentModeStatus', apiStatus.networkerror).value(),

    [SET_PAYMENT_MODE_SUCCESS]: (state, action): Cart =>
      mapCartProperties(
        transform(state)
          .set(
            'orderData.paymentMode.reference',
            `${action.payload.paymentMode}${action.payload.useAlias ? '_SAVED' : ''}`
          )
          .set('orderData.paymentMode.validationResult', ValidationTypes.Valid)
          .set('orderData.hasChangedPaymentMode', true)
          .set('orderData.paymentMode.useAlias', action.payload.useAlias)
          .set('paymentModeStatus', apiStatus.success)
          .value(),
        action.req.data
      ),
    [SET_PAYMENT_MODE_FAILURE]: (state: Cart): Cart =>
      transform(state)
        .set('orderData.paymentMode.validationResult', ValidationTypes.MissingPaymentMethod)
        .set('paymentModeStatus', apiStatus.failure)
        .value(),
    [SET_PAYMENT_MODE_REQUEST]: (state: Cart): Cart =>
      transform(state).set('paymentModeStatus', apiStatus.request).value(),
    [SET_PAYMENT_MODE_NETWORKERROR]: (state: Cart): Cart =>
      transform(state).set('paymentModeStatus', apiStatus.networkerror).value(),

    [RESET_PAYMENT_MODE_SUCCESS]: (state: Cart): Cart =>
      transform(state)
        .set('orderData.paymentMode.reference', undefined)
        .set('orderData.paymentMode.validationResult', ValidationTypes.MissingPaymentMethod)
        .set('orderData.paymentMode.useAlias', false)
        .set('paymentModeStatus', apiStatus.success)
        .value(),

    [RESET_PAYMENT_MODE_FAILURE]: (state) => transform(state).set('paymentModeStatus', apiStatus.failure).value(),

    [GET_FOOTER_PAYMENT_MODES_SUCCESS]: (state: Cart, action) => {
      const footerPaymentModes = action.req?.data?.paymentModes || [];
      const newFooterPaymentModes = footerPaymentModes.map((payment) => ({ ...state[payment.code], ...payment }));
      const newState = transform(state)
        .set('footerPaymentModes', keyBy(newFooterPaymentModes, 'code'))
        .set('footerPaymentModeStatus', apiStatus.success);
      return newState.value();
    },

    [GET_FOOTER_PAYMENT_MODES_FAILURE]: (state: Cart): Cart =>
      transform(state).set('footerPaymentModeStatus', apiStatus.failure).value(),

    [GET_FOOTER_PAYMENT_MODES_REQUEST]: (state: Cart): Cart =>
      transform(state).set('footerPaymentModeStatus', apiStatus.request).value(),

    [GET_FOOTER_PAYMENT_MODES_NETWORKERROR]: (state: Cart): Cart =>
      transform(state).set('footerPaymentModeStatus', apiStatus.networkerror).value(),

    [SET_DELIVERY_MODE_SUCCESS]: (state, action): Cart => {
      const data = action.req?.data;
      const resolvedLocally = action.req?.resolvedLocally;
      const defaultModeApplied = action.payload?.defaultModeApplied;
      const deliveryMode = action.payload?.deliveryMode;
      const pointOfServiceId = action.payload?.pointOfServiceId;
      localStorage.setItem(SELECTED_ADDRESS_ID, pointOfServiceId);

      localStorage.setItem(DEFAULT_DELIVERY_APPLIED, defaultModeApplied ? 'default' : 'userOverride');

      const newState = transform(state);

      const newDeliveryModes = {};

      Object.keys(state.deliveryModes).forEach((mode) => {
        const newDeliveryMode = { ...state.deliveryModes[mode] };
        newDeliveryMode.selected = deliveryMode === newDeliveryMode.code;
        newDeliveryMode.pending = false;
        newDeliveryModes[mode] = newDeliveryMode;
      });

      if (Object.keys(data?.transportGuarantee || {}).length) {
        newState.set('transportGuarantee', data.transportGuarantee);
      }

      newState
        .set('deliveryModeStatus', apiStatus.success)
        .set('orderData.deliveryMode.reference', deliveryMode)
        .set('orderData.deliveryMode.validationResult', validation.deliveryMode(deliveryMode, pointOfServiceId))
        .set('deliveryModes', newDeliveryModes);

      if (resolvedLocally) {
        return newState.value();
      }

      return mapCartProperties(newState.value(), data);
    },

    [SET_DELIVERY_MODE_REQUEST]: (state, action): Cart => {
      const deliveryMode = action.payload?.deliveryMode;
      const newState = transform(state);

      const newDeliveryModes = {};

      Object.keys(state.deliveryModes).forEach((mode) => {
        const newDeliveryMode = { ...state.deliveryModes[mode] };
        newDeliveryMode.selected = deliveryMode === newDeliveryMode.code;
        newDeliveryMode.pending = deliveryMode === newDeliveryMode.code;
        newDeliveryModes[mode] = newDeliveryMode;
      });

      newState.set('deliveryModeStatus', apiStatus.request).set('deliveryModes', newDeliveryModes);

      return newState.value();
    },

    [SET_DELIVERY_MODE_FAILURE]: (state: Cart): Cart => {
      const newState = transform(state);

      const newDeliveryModes = {};

      Object.keys(state.deliveryModes).forEach((mode) => {
        const newDeliveryMode = { ...state.deliveryModes[mode] };
        newDeliveryMode.selected = false;
        newDeliveryMode.pending = false;
        newDeliveryModes[mode] = newDeliveryMode;
      });

      newState.set('deliveryModes', newDeliveryModes).set('deliveryModeStatus', apiStatus.failure);

      return newState.value();
    },

    [SET_DELIVERY_MODE_NETWORKERROR]: (state) => {
      const newState = transform(state);
      const newDeliveryModes = {};

      Object.keys(state.deliveryModes).forEach((mode) => {
        const newDeliveryMode = { ...state.deliveryModes[mode] };
        newDeliveryMode.selected = false;
        newDeliveryMode.pending = false;
        newDeliveryModes[mode] = newDeliveryMode;
      });

      newState.set('deliveryModes', newDeliveryModes).set('deliveryModeStatus', apiStatus.networkerror);

      return newState.value();
    },

    [UPDATE_TRANSPORT_GUARANTEE_SUCCESS]: (state, action): Cart => mapCartProperties(state, action.req.data),

    [UPDATE_PACKAGE_ANNOUNCEMENT_SUCCESS]: (state, action): Cart => {
      const newState = transform(state);
      newState.set('packageAnnouncement', action.payload?.active);
      return newState.value();
    },

    [UPDATE_NEUTRAL_DELIVERY_REQUEST]: (state, action): Cart => ({
      ...state,
      // optimistic update of neutralDeliveryActive on request start
      neutralDeliveryActive: action.neutralDeliveryActive,
    }),

    [UPDATE_NEUTRAL_DELIVERY_FAILURE]: (state): Cart => ({
      ...state,
      neutralDeliveryActive: !state.neutralDeliveryActive,
    }),

    [UPDATE_NEUTRAL_DELIVERY_NETWORKERROR]: (state): Cart => ({
      ...state,
      neutralDeliveryActive: !state.neutralDeliveryActive,
    }),

    [GET_DELIVERY_MODES_SUCCESS]: (state, action): Cart => {
      const newDeliveryModesObject = keyBy(
        (action.req?.data?.deliveryModes || []).map((deliveryMode) => ({
          ...state[deliveryMode.code],
          ...deliveryMode,
        })),
        'code'
      );

      const currentDeliveryModeReference = state?.orderData?.deliveryMode?.reference;

      if (Object.keys(newDeliveryModesObject).includes(currentDeliveryModeReference)) {
        newDeliveryModesObject[currentDeliveryModeReference].selected = true;
      }

      return transform(state)
        .set('deliveryModes', newDeliveryModesObject)
        .set('deliveryModeStatus', apiStatus.success)
        .value();
    },

    [GET_DELIVERY_MODES_REQUEST]: (state: Cart): Cart =>
      transform(state).set('deliveryModeStatus', apiStatus.request).value(),

    [GET_DELIVERY_MODES_FAILURE]: (state: Cart): Cart =>
      transform(state).set('deliveryModeStatus', apiStatus.failure).value(),

    [GET_DELIVERY_MODES_NETWORKERROR]: (state: Cart): Cart =>
      transform(state).set('deliveryModeStatus', apiStatus.networkerror).value(),

    [GET_CURRENT_DELIVERY_MODE_SUCCESS]: (state, action): Cart => {
      const newState = transform(state);

      if (action.req?.data?.code) {
        newState.set('orderData.deliveryMode.reference', action.req.data.code);
      }

      const newDeliveryModes = {};

      Object.keys(state.deliveryModes).forEach((mode) => {
        const newDeliveryMode = { ...state.deliveryModes[mode] };
        newDeliveryMode.selected = action.req?.data?.code === newDeliveryMode.code;
        newDeliveryMode.pending = false;
        newDeliveryModes[mode] = newDeliveryMode;
      });

      newState
        .set('deliveryModes', newDeliveryModes)
        .set(
          'orderData.deliveryMode.validationResult',
          validation.deliveryMode(action?.req?.data?.code, action?.req?.data?.pointOfServiceId)
        )
        .set('currentDeliveryModeStatus', apiStatus.success);

      return newState.value();
    },

    [GET_CURRENT_DELIVERY_MODE_FAILURE]: (state: Cart): Cart =>
      transform(state).set('currentDeliveryModeStatus', apiStatus.failure).value(),

    [GET_CURRENT_DELIVERY_MODE_REQUEST]: (state: Cart): Cart =>
      transform(state).set('currentDeliveryModeStatus', apiStatus.request).value(),

    [GET_CURRENT_DELIVERY_MODE_NETWORKERROR]: (state: Cart): Cart =>
      transform(state).set('currentDeliveryModeStatus', apiStatus.networkerror).value(),

    [CREATE_GUEST_DELIVERY_ADDRESS]: (state: Cart): Cart => {
      const newState = transform(state).set('orderData.deliveryAddress.reference', addressTypes.DELIVERY);

      const isMonthlyInvoiceHidden =
        newState.value()?.orderData?.deliveryAddress?.reference !== state?.orderData?.invoiceAddress?.reference;

      if (isMonthlyInvoiceHidden && state?.orderData?.paymentMode?.reference === MONTHLYINVOICE_PCF) {
        newState.set('orderData.paymentMode', {
          reference: '',
          validationResult: ValidationTypes.MissingPaymentMethod,
        });
      }

      return newState.set(`paymentModes.${MONTHLYINVOICE_PCF}.hidden`, isMonthlyInvoiceHidden).value();
    },

    [REMOVE_GUEST_DELIVERY_ADDRESS]: (state: Cart): Cart =>
      transform(state)
        .set('orderData.deliveryAddress.reference', addressTypes.INVOICE)
        .set(`paymentModes.${MONTHLYINVOICE_PCF}.hidden`, false)
        .value(),

    [SET_ADDRESS_SUCCESS]: (state, action): Cart => {
      if (!action?.payload?.addressId || !action?.payload?.type) {
        return state;
      }
      const addressType = action.payload.type;
      const newState = transform(state);

      const _set = (type) =>
        newState.set(`orderData.${type}Address`, {
          reference: action.payload?.addressId,
          flaggedForSelection: false,
        });

      if (addressType === addressTypes.UNIFIED) {
        _set(addressTypes.INVOICE);
        _set(addressTypes.DELIVERY);
      } else if (addressType === addressTypes.DELIVERY) {
        _set(addressTypes.DELIVERY);
      } else {
        // INVOICE or TEMPORARY
        _set(addressTypes.INVOICE);
      }

      return newState.value();
    },

    [REMOVE_ADDRESS_SUCCESS]: (state, action): Cart => {
      const newState = transform(state);

      if (action.payload.type === addressTypes.INVOICE) {
        newState.set('orderData.invoiceAddress.reference', '');
      }
      if (action.payload.type === addressTypes.DELIVERY) {
        newState.set('orderData.deliveryAddress.reference', '');
      }

      return newState.value();
    },

    [FLAG_ADDRESS_TYPE_FOR_SELECTION]: (state, action): Cart => {
      if (action?.payload?.flagged === undefined || action?.payload?.addressType === undefined) {
        return state;
      }
      const flag = action.payload.flagged;
      const type = action.payload.addressType;
      const newState = transform(state);

      if (type === addressTypes.UNIFIED) {
        newState
          .set('orderData.invoiceAddress.flaggedForSelection', flag)
          .set('orderData.deliveryAddress.flaggedForSelection', flag);
      } else if (type === addressTypes.DELIVERY) {
        newState.set('orderData.deliveryAddress.flaggedForSelection', flag);
      } else {
        newState.set('orderData.invoiceAddress.flaggedForSelection', flag);
      }

      return newState.value();
    },

    [CREATE_USER_ADDRESS_AND_SELECT_SUCCESS]: (state, action): Cart => {
      const id = action.req?.id;
      if (!id) {
        return state;
      }
      const newState = transform(state);

      if (newState.value()?.orderData?.deliveryAddress?.flaggedForSelection) {
        newState.set('orderData.deliveryAddress', {
          reference: id,
          flaggedForSelection: false,
        });
      }

      if (newState.value()?.orderData?.invoiceAddress?.flaggedForSelection) {
        newState.set('orderData.invoiceAddress', {
          reference: id,
          flaggedForSelection: false,
        });
      }

      return newState.value();
    },

    [DELETE_USER_ADDRESS_SUCCESS]: (state, action): Cart => {
      if (!action?.payload?.addressId) {
        return state;
      }
      const id = action.payload.addressId;
      const alternativeId = action.payload.alternativeAddressId;
      const deliveryAddress = state?.orderData?.deliveryAddress?.reference;
      const invoiceAddress = state?.orderData?.invoiceAddress?.reference;
      const newState = transform(state);

      // In case delivery and invoice address is the same we can't switch the references
      // For this case we set the alternative address (next address in adressbook)
      if (id === deliveryAddress && id === invoiceAddress && !!alternativeId) {
        newState.set('orderData.deliveryAddress.reference', alternativeId);
        newState.set('orderData.invoiceAddress.reference', alternativeId);
      } else {
        // IDs are set for the other address
        if (id === deliveryAddress) {
          newState.set('orderData.deliveryAddress.reference', invoiceAddress);
        }

        if (id === invoiceAddress) {
          newState.set('orderData.invoiceAddress.reference', deliveryAddress);
        }
      }

      return newState.value();
    },

    [DELETE_TEMP_ADDRESS]: (state, action): Cart => {
      const isOnlyOneAddressInStore = action.payload?.isOnlyOneAddressInStore ?? false;
      if (isOnlyOneAddressInStore) {
        return transform(state).set('orderData.deliveryAddress.flaggedForSelection', false).value();
      }
      return state;
    },

    [SAVE_PREVIOUS_PAYMENT_MODE]: (state, action): Cart =>
      transform(state).set('orderData.previousPaymentMode', action.payload).value(),

    [APPLY_VOUCHER_REQUEST]: (state): Cart =>
      transform(state)
        .set('orderData.voucher', {
          apiStatus: apiStatus.request,
        })
        .value(),

    [APPLY_VOUCHER_SUCCESS]: (state, action): Cart =>
      mapCartProperties(
        transform(state)
          .set('orderData.voucher', {
            validationResult: ValidationTypes.Valid,
            apiStatus: apiStatus.success,
          })
          .value(),
        action.req.data
      ),

    [APPLY_VOUCHER_FAILURE]: (state: Cart, action): Cart =>
      transform(state)
        .set('orderData.voucher', {
          validationResult: getMessageFromResponse(action),
          apiStatus: apiStatus.failure,
        })
        .value(),

    [APPLY_VOUCHER_NETWORKERROR]: (state: Cart): Cart =>
      transform(state)
        .set('orderData.voucher', {
          validationResult: ValidationTypes.Invalid,
          apiStatus: apiStatus.networkerror,
        })
        .value(),

    [CLEAR_VOUCHER_VALIDATION_RESULT]: (state: Cart): Cart =>
      transform(state).set('orderData.voucher', { validationResult: ValidationTypes.Initial }).value(),

    [REMOVE_VOUCHER_SUCCESS]: (state, action): Cart =>
      mapCartProperties(
        transform(state).set('orderData.voucher', { validationResult: ValidationTypes.Initial }).value(),
        action.req.data
      ),

    [RESET_USER]: (): Cart => initialState,

    [CHANGE_AGE_VERIFICATION_TYPE]: (state, action): Cart => {
      const newVerificationType = action.payload;
      const newState = transform(state)
        .set('pegiVerification.identificationType', newVerificationType)
        .set('pegiVerification.forceValidation', false)
        .set('pegiVerification.pegiVerificationHasError', false);

      const verificationFields = () => {
        let newVerificationFields;

        switch (newVerificationType) {
          case cardTypes.identityCard:
            newVerificationFields = {
              idNumber: {
                name: 'idNumber',
                value: '',
                validationResult: ValidationTypes.Missing,
              },
              idNumberChecksum: {
                name: 'idNumberChecksum',
                value: '',
                validationResult: ValidationTypes.Missing,
              },
              birthDate: {
                name: 'birthDate',
                value: '',
                validationResult: ValidationTypes.Missing,
              },
              expirationDate: {
                name: 'expirationDate',
                value: '',
                validationResult: ValidationTypes.Missing,
              },
              expectedTotalChecksum: {
                name: 'expectedTotalChecksum',
                value: '',
                validationResult: ValidationTypes.Missing,
              },
              firstName: {
                name: 'firstName',
                value: '',
                validationResult: ValidationTypes.Missing,
              },
              lastName: {
                name: 'lastName',
                value: '',
                validationResult: ValidationTypes.Missing,
              },
              gender: {
                name: 'gender',
                value: '',
                validationResult: ValidationTypes.Missing,
              },
              identificationType: cardTypes.identityCard,
              isValid: ValidationTypes.Invalid,
            };
            break;
          case cardTypes.passport:
            newVerificationFields = {
              idNumber: {
                name: 'idNumber',
                value: '',
                validationResult: ValidationTypes.Missing,
              },
              idNumberChecksum: {
                name: 'idNumberChecksum',
                value: '',
                validationResult: ValidationTypes.Missing,
              },
              birthDate: {
                name: 'birthDate',
                value: '',
                validationResult: ValidationTypes.Missing,
              },
              expirationDate: {
                name: 'expirationDate',
                value: '',
                validationResult: ValidationTypes.Missing,
              },
              expectedTotalChecksum: {
                name: 'expectedTotalChecksum',
                value: '',
                validationResult: ValidationTypes.Missing,
              },
              firstName: {
                name: 'firstName',
                value: '',
                validationResult: ValidationTypes.Missing,
              },
              lastName: {
                name: 'lastName',
                value: '',
                validationResult: ValidationTypes.Missing,
              },
              gender: {
                name: 'gender',
                value: '',
                validationResult: ValidationTypes.Missing,
              },
              identificationType: cardTypes.passport,
              isValid: ValidationTypes.Invalid,
            };
            break;
          case cardTypes.driverLicense:
            newVerificationFields = {
              idNumber: {
                name: 'idNumber',
                value: '',
                validationResult: ValidationTypes.Missing,
              },
              birthDate: {
                name: 'birthDate',
                value: '',
                validationResult: ValidationTypes.Missing,
              },
              firstName: {
                name: 'firstName',
                value: '',
                validationResult: ValidationTypes.Missing,
              },
              lastName: {
                name: 'lastName',
                value: '',
                validationResult: ValidationTypes.Missing,
              },
              identificationType: cardTypes.driverLicense,
              isValid: ValidationTypes.Invalid,
            };
            break;
          case cardTypes.sendCopy:
            newVerificationFields = {
              identificationType: cardTypes.sendCopy,
              isValid: ValidationTypes.Valid,
            };
            break;
          default:
            newVerificationFields = undefined;
            break;
        }

        return newVerificationFields;
      };

      newState.set(`pegiVerification.${newVerificationType}`, verificationFields());

      return newState.value();
    },

    [VERIFY_AGE_SUCCESS]: (state, action): Cart => {
      const newState = transform(state);
      const verified = action.req?.data?.verified;

      if (verified) {
        if (state?.pegiVerification?.identificationType === cardTypes.sendCopy) {
          newState.set('pegiVerification.sendCopyVerification', true);
        } else {
          newState.set('pegiVerification.pegiVerified', verified);
        }

        newState.set('pegiVerification.identificationType', initialState?.pegiVerification?.identificationType);
      } else {
        newState.set('pegiVerification.pegiVerificationHasError', true);
      }

      return newState.value();
    },

    [VERIFY_AGE_FAILURE]: (state, action): Cart =>
      transform(state)
        .set('pegiVerification.pegiVerificationHasError', action.error?.data?.errors?.length > 0)
        .value(),

    [SET_VERIFY_AGE_MODAL_ISOPEN]: (state: Cart): Cart =>
      transform(state)
        .set('verifyAgeModal', {
          isOpen: state.verifyAgeModal ? !state.verifyAgeModal.isOpen : false,
        })
        .value(),

    [UPDATE_PEGI_FIELD_VALUE]: (state, action): Cart => {
      const newState = transform(state);
      const identificationType = state.pegiVerification.identificationType;
      const pegiField = `pegiVerification[${identificationType}][${action.payload.fieldName}]`;

      newState.set(`${pegiField}.value`, action.payload.value);

      const pegiValue = newState.value()?.pegiVerification?.[identificationType]?.[action.payload?.fieldName]?.value;

      newState.set(`${pegiField}.validationResult`, !pegiValue ? ValidationTypes.Invalid : ValidationTypes.Valid);

      // Check for empty/invalid values in newState
      const fields = newState.value()?.pegiVerification?.[identificationType];
      const emptyValues = Object.keys(fields).find((fieldName) => {
        const field = fields[fieldName];
        return field.validationResult && field.validationResult !== ValidationTypes.Valid;
      });

      return newState
        .set(
          `pegiVerification[${identificationType}].isValid`,
          emptyValues ? ValidationTypes.Invalid : ValidationTypes.Valid
        )
        .set('pegiVerification.pegiVerificationHasError', false)
        .value();
    },

    [SET_PEGI_FORCE_VALIDATION]: (state, action): Cart =>
      transform(state).set('pegiVerification.forceValidation', action.payload).value(),

    [RESET_AGE_VERIFICATION_TYPE]: (state: Cart): Cart =>
      transform(state)
        .set('pegiVerification.identificationType', initialState.pegiVerification.identificationType)
        .value(),

    [CLEAR_UPDATED_CART_HISTORY]: (state): Cart => {
      const newState = transform(state);
      const data = state.entries || [];
      const newEntries = [];

      data.forEach((entry) => {
        newEntries.push(
          transform(entry).set('priceUpdated', false).set('stockUpdated', false).set('discountUpdated', false).value()
        );
      });

      newState
        .set('entries', newEntries)
        .set('cartUpdated', false)
        .set('removedEntries', [])
        .set('stockAvailabilityChanged', false);

      return newState.value();
    },

    [APPLY_GIFTCARD_REQUEST]: (state): Cart =>
      transform(state)
        .set('orderData.giftcard', {
          apiStatus: apiStatus.request,
        })
        .value(),

    [APPLY_GIFTCARD_SUCCESS]: (state, action): Cart =>
      mapCartProperties(
        transform(state)
          .set('orderData.giftcard', {
            validationResult: ValidationTypes.Valid,
            apiStatus: apiStatus.success,
          })
          .value(),
        action.req.data
      ),

    [APPLY_GIFTCARD_FAILURE]: (state, action): Cart => {
      const errors = action.error?.data?.errors;
      const isGiftCardAlreadyUsed = errors?.[0]?.message === 'Giftcard is already used.'; // weak identifier
      const requiresRecaptcha = errors?.[0]?.type === ValidationTypes.GiftcardRecaptchaRequired; // weak identifier

      return transform(state)
        .set('orderData.giftcard', {
          recaptchaNeeded: requiresRecaptcha,
          validationResult: isGiftCardAlreadyUsed ? ValidationTypes.GiftcardAlreadyUsed : ValidationTypes.Invalid,
          apiStatus: apiStatus.failure,
        })
        .value();
    },
    [VERIFY_RECAPTCHA_SUCCESS]: (state, action): Cart =>
      transform(state)
        .set('orderData.giftcard', {
          recaptchaNeeded: !action.req?.data?.success,
        })
        .value(),
    [VERIFY_RECAPTCHA_FAILURE]: (state): Cart =>
      transform(state)
        .set('orderData.giftcard', {
          recaptchaNeeded: true,
        })
        .value(),
    [VERIFY_RECAPTCHA_NETWORKERROR]: (state): Cart =>
      transform(state)
        .set('orderData.giftcard', {
          recaptchaNeeded: true,
        })
        .value(),
    [APPLY_GIFTCARD_NETWORKERROR]: (state): Cart =>
      transform(state)
        .set('orderData.giftcard', {
          validationResult: ValidationTypes.Invalid,
          apiStatus: apiStatus.networkerror,
        })
        .value(),

    [REMOVE_GIFTCARD_SUCCESS]: (state, action): Cart =>
      mapCartProperties(
        transform(state).set('orderData.giftcard', { validationResult: ValidationTypes.Initial }).value(),
        action.req.data
      ),

    [CLEAR_GIFTCARD_VALIDATION_RESULT]: (state: Cart): Cart =>
      transform(state)
        .set('orderData.giftcard', {
          validationResult: ValidationTypes.Initial,
          recaptchaNeeded: state.orderData.giftcard.recaptchaNeeded,
        })
        .value(),

    [GET_DATATRANS_AUTHORISATION_SUCCESS]: (state, action): Cart =>
      transform(state)
        .set('datatransAuthorisationURL', action.req.baseURL)
        .set('datatransParams', action.req.requestParameters)
        .value(),

    [INIT_DATATRANS_TRANSACTION_SUCCESS]: (state, action): Cart =>
      transform(state).set('datatransAuthorisationURL', action.req.baseURL).value(),

    [SET_CHECKOUT_NEED_STATUS]: (state, action): Cart =>
      transform(state)
        .set('checkoutNeedStatus', action.payload?.status || apiStatus.failure)
        .value(),

    [CHECK_STOCK_AVAILABILITY_FAILURE]: (state) => transform(state).set('stockAvailabilityChanged', true).value(),
    [SET_CHECKOUT_GIFTCARD_DISALLOWED_CHANGED]: (state, action) =>
      transform(state).set('checkoutGiftcardDisallowedChanged', action.payload).value(),
  },
  initialState
);
