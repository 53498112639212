// @flow
import * as React from 'react';
import { Modal } from '@interdiscount/component-library-interdiscount';
import Headline from 'components/atoms/Headline/Headline';
import CancelSaveButton from 'components/molecules/CancelSaveButton/CancelSaveButton';

type IProps = {
  isOpen: boolean,
  title: string,
  children: React.Node,
  okLabel: string,
  cancelLabel: string,
  onCancel: () => void,
  onOk: () => void,
  buttonsDisabled?: boolean,
};

const ConfirmationModal = ({
  isOpen,
  title,
  children,
  onCancel,
  onOk,
  okLabel,
  cancelLabel,
  buttonsDisabled,
}: IProps) => (
  <Modal onRequestClose={() => onCancel()} isOpen={isOpen}>
    <Headline tag={Headline.tags.H1} margin={Headline.margins.MB_25}>
      {title}
    </Headline>
    {children}
    <CancelSaveButton
      onSubmit={() => onOk()}
      submitText={okLabel}
      onCancel={() => onCancel()}
      cancelText={cancelLabel}
      disabled={buttonsDisabled}
    />
  </Modal>
);

ConfirmationModal.displayName = 'molecules/ConfirmationModal';

export default ConfirmationModal;
