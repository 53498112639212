import React from 'react';
import PropTypes from 'prop-types';
import styles from './CheckMarkText.scss';
import Icon, { ICON_CHECK } from 'components/atoms/Icon/Icon';

const CheckMarkText = ({ children }) => (
  <span className={styles.CheckMarkText}>
    <span className={styles.Icon}>
      <Icon path={ICON_CHECK} size={Icon.sizes.xs} />
    </span>
    <span>{children}</span>
  </span>
);

CheckMarkText.displayName = 'atoms/CheckMarkText';

CheckMarkText.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default CheckMarkText;
