const imageSizes = {
  default: {
    size: 256,
    responsive: false,
  },
  searchAsYouType: {
    size: 80,
    responsive: false,
  },
  productDetail: {
    size: 1000,
    initialSizes: '130px',
    sizes: '(min-width: 1550px) 25vw, (min-width: 768px) 40vw, 90vw',
    responsive: true,
    min: 256,
  },
  productOrder: {
    size: 300,
    responsive: false,
  },
  productTeaser: {
    size: 600,
    responsive: false,
  },
};

export default imageSizes;
