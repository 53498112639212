import { noSpecialCharsRegex } from 'constants/validation/validation';

export const sanitizeValue = (value) => {
  let newValue = value;
  if (typeof value === 'string') {
    newValue = newValue.replace(noSpecialCharsRegex, '');
  }

  return newValue;
};

export default sanitizeValue;
