import React from 'react';
import PropTypes from 'prop-types';

import RecoTracking from 'containers/RecoTracking/RecoTracking';
import AddToCart from 'containers/AddToCart/AddToCart';
import TeaserProductActionsButton from 'components/molecules/TeaserProductActionsButton/TeaserProductActionsButton';
import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';
import useTelesalesMode from 'hooks/useTelesalesMode';
import uuid from 'uuid/v1';

const AddToCartButton = ({ product, productCode, buttonText, id, showIcon, cyData, productAttributes }) => {
  const code = product ? product.code : productCode;
  const isTelesalesMode = useTelesalesMode();

  return (
    <RecoTracking code={code}>
      <AddToCart productCode={code} productAttributes={productAttributes}>
        <TeaserProductActionsButton
          buttonType={ButtonLink.colors.green}
          buttonSize={ButtonLink.sizes.sm}
          buttonText={buttonText}
          buttonBlock
          showIcon={showIcon}
          id={`formControl-${id !== '' ? id : uuid()}-addToCart-button`}
          cyData={cyData}
          code={code}
          color={isTelesalesMode ? ButtonLink.colors.blue : ButtonLink.colors.red}
        />
      </AddToCart>
    </RecoTracking>
  );
};

AddToCartButton.displayName = 'molecules/AddToCartButton';
AddToCartButton.propTypes = {
  product: PropTypes.object,
  productCode: PropTypes.string,
  canBeReserved: PropTypes.bool,
  showIcon: PropTypes.bool,
  buttonText: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  cyData: PropTypes.string,
  productAttributes: PropTypes.object,
};

export default AddToCartButton;
