import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { loginNotifications } from 'reducers/ui/ui';
import Headline from 'components/atoms/Headline/Headline';
import Spinner, { SPINNER_STATES } from 'components/atoms/Spinner/Spinner';
import language from 'constants/language/language';
import { getEnumValues } from 'constants/GetEnumValues/GetEnumValues';
import bootstrap from 'scss/component.scss';
import styles from './AccountNotification.scss';

export default class AccountNotification extends Component {
  static displayName = 'molecules/PasswordResetNotification';

  static propTypes = {
    notificationKey: PropTypes.oneOf(getEnumValues(loginNotifications)),
  };

  isSuccess = () => {
    const { notificationKey } = this.props;
    return (
      notificationKey === loginNotifications.CHANGE_LOGIN_SUCCESS ||
      notificationKey === loginNotifications.PASSWORD_RESET_SUCCESS
    );
  };

  provideHeadline = () => {
    const { notificationKey } = this.props;
    return this.isSuccess()
      ? language(`accountNotification.${notificationKey}.headerDone`)
      : language(`accountNotification.${notificationKey}.headerFailure`);
  };

  provideMessage = () => {
    const { notificationKey } = this.props;
    return this.isSuccess() ? (
      <p>
        {language(`accountNotification.${notificationKey}.messageDone`)}
        <br />
        {language(`accountNotification.${notificationKey}.messageDone2`)}
      </p>
    ) : (
      <p>{language(`accountNotification.${notificationKey}.messageFailure`)}</p>
    );
  };

  render() {
    const container = classNames({
      [bootstrap.container]: true,
      [styles.container]: true,
    });
    return (
      <div className={container}>
        <div className={styles.spinner}>
          <Spinner status={this.isSuccess() ? SPINNER_STATES.SUCCESS : SPINNER_STATES.FAILURE} />
        </div>
        <Headline tag={Headline.tags.H2} margin={Headline.margins.MB_5} alignment={Headline.alignments.CENTER}>
          {this.provideHeadline()}
        </Headline>
        {this.provideMessage()}
      </div>
    );
  }
}
