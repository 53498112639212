import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import { scrollUpToId } from 'constants/scrollToElement/scrollToElement';
import { noop } from 'constants/noop/noop';
import { trackMobileNavigationGoTo } from 'tracking/tracking';
import { NextFeatureLink } from '../NextFeatureLink/NextFeatureLink';

let windowObject;
if (__CLIENT__) windowObject = window;
// @TODO to make it 100% correct, we would need a way to know the current hostname on the server

export const isInternal = (hostname) => hostname === '' || hostname === windowObject?.location?.hostname;

export const getTarget = (openNewTab, external) => (openNewTab || external ? '_blank' : '');

/**
  This function takes a string which can be either
  1. a pathname
  2. a fully qualified URL (including protocol, hostname, port, etc)
  and returns an object with hostname and pathname (similar to window.location)
*/
export const parseLink = (href = '', openNewTab = false) => {
  // mailto
  if (href.startsWith('mailto')) {
    return {
      hostname: 'mailto',
      pathname: href || '/',
      target: '',
    };
  }

  // only pathname:
  if (!href.startsWith('http')) {
    return {
      hostname: windowObject?.location?.hostname || '',
      pathname: href || '/',
      target: openNewTab ? '_blank' : '',
    };
  }

  // full URL:
  const host = href.split('/')?.[2] || '';
  const hostname = host.split(':')?.[0];
  const pathParts = href.split('/');
  const pathname = `/${pathParts.slice(3, pathParts.length).join('/')}`;
  let target;

  if (isInternal(hostname)) {
    target = openNewTab ? '_blank' : '';
  } else {
    target = '_blank';
  }

  return {
    hostname,
    pathname,
    target,
  };
};

const InternalOrExternalLink = ({
  href,
  children,
  openNewTab = false,
  className = '',
  title = '',
  onClickTrackFunc = noop,
  scrollToId = {},
  dataTracking,
  tracking,
  external,
  featureName = null,
}) => {
  if (!href) return null;

  const link = parseLink(href, openNewTab);

  const targetProp = link.target ? { target: link.target } : {};

  const handleClick = () => {
    handleTrackFunction();
    if (scrollToId) {
      requestAnimationFrame(() => {
        scrollUpToId(scrollToId.id, scrollToId.duration, scrollToId.offset);
      });
    }
  };

  const handleTrackFunction = () => {
    if (tracking) trackMobileNavigationGoTo(tracking.type, tracking.position, tracking.title, tracking.parentTitle);
    onClickTrackFunc();
  };

  if (!external && isInternal(link.hostname)) {
    if (featureName) {
      return (
        <NextFeatureLink
          to={link.pathname}
          className={className}
          onClick={handleClick}
          title={title}
          rel={openNewTab ? 'noopener' : ''}
          data-tracking={dataTracking}
          {...targetProp}
          feature={featureName}
        >
          {children}
        </NextFeatureLink>
      );
    }

    return (
      <Link
        to={link.pathname}
        className={className}
        onClick={handleClick}
        title={title}
        rel={openNewTab ? 'noopener' : ''}
        data-tracking={dataTracking}
        {...targetProp}
      >
        {children}
      </Link>
    );
  }

  return (
    <a
      href={href}
      target={link.target}
      className={className}
      onClick={handleTrackFunction}
      rel="noopener"
      title={title}
      data-tracking={dataTracking}
    >
      {children}
    </a>
  );
};

InternalOrExternalLink.displayName = 'atoms/InternalOrExternalLink';

InternalOrExternalLink.propTypes = {
  href: PropTypes.string,
  children: PropTypes.node.isRequired,
  openNewTab: PropTypes.bool,
  className: PropTypes.string, // this was created to wrap the wall teasers, so for simplicity pass wrapping classes
  title: PropTypes.string,
  onClickTrackFunc: PropTypes.func,
  scrollToId: PropTypes.shape({
    id: PropTypes.string,
    duration: PropTypes.number,
    offset: PropTypes.number,
  }),
  dataTracking: PropTypes.string,
  pos: PropTypes.number,
  parentTitle: PropTypes.string,
  type: PropTypes.string,
  tracking: PropTypes.object,
  external: PropTypes.bool,
  featureName: PropTypes.string,
};

export default InternalOrExternalLink;
