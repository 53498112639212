import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import PropTypes from 'prop-types';

import { isLoggedIn } from 'constants/user/user';
import { getLocale } from 'constants/language/language';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import { provideLink } from 'constants/navigation/navigation';
import { CMS_UID_RETURNS_CREATION } from 'constants/cms/cms';
import { AVAILABLE_RETURN_REASONS, RETURN_REASONS } from 'constants/returns/returns';
import { accountReturnDetails } from 'constants/routePaths/routePathCombinations';
import ReturnsOverview from 'components/molecules/ReturnOverview/ReturnOverview';
import { HELPCENTER_DOMAIN } from 'constants/links/links';

const mapStateToProps = (state = { user: {}, cms: {} }) => ({
  userLoggedIn: isLoggedIn(state.user),
  pages: state?.cms?.navigation?.pages ?? {},
});

const ReturnsOverviewContainer = ({ userLoggedIn, pages }) => {
  const getLink = (pageId) => {
    const link = provideLink(pages, pageId, getLocale());
    return link?.url || '';
  };

  const getTransitDamageHelpcenterLink = () => {
    if (getLocale().toLowerCase().includes('de'))
      return (
        HELPCENTER_DOMAIN +
        '/s/article/Transportschaden2?c__cun=Warranty&c__cl=Garantie&c__cc=Transport_damage&c__at=Informative&language=de'
      );
    if (getLocale().toLowerCase().includes('fr'))
      return (
        HELPCENTER_DOMAIN +
        '/s/article/Dommage-li%C3%A9-au-transport2?c__cun=Warranty&c__cl=Garantie&c__cc=Transport_warranty&c__at=Informative&language=fr'
      );
    if (getLocale().toLowerCase().includes('it'))
      return (
        HELPCENTER_DOMAIN +
        '/s/article/Danni-da-trasporto2?c__cun=Warranty&c__cl=Garanzia&c__cc=Transport_warranty&c__at=Informative&language=it'
      );
  };

  const getWarrantyHelpcenterLink = () => HELPCENTER_DOMAIN + `/s/repair?language=${getLocale()}`;

  const onReasonClick = (reason) => {
    let pathname;
    if (userLoggedIn && reason.pageId === CMS_UID_RETURNS_CREATION) {
      // user logged in -> go to return-creation in my-account
      pathname = mapPathToLocalizedUrl(getLocale(), accountReturnDetails);
    } else if (reason.pageId) {
      // user not logged in + cms page -> go to return-creation under cms
      pathname = getLink(reason.pageId);
    } else if (reason.routePath) {
      pathname = mapPathToLocalizedUrl(getLocale(), reason.routePath);
    }

    if (reason.id === AVAILABLE_RETURN_REASONS.TRANSIT_DAMAGE) {
      window.open(getTransitDamageHelpcenterLink(), '_blank').focus();
      return;
    }

    if (reason.id === AVAILABLE_RETURN_REASONS.WARRANTY) {
      window.open(getWarrantyHelpcenterLink(), '_blank').focus();
      return;
    }

    browserHistory.push({
      pathname,
      search: reason.pageId === CMS_UID_RETURNS_CREATION ? `?returnType=${reason.id}` : undefined,
    });
  };

  return <ReturnsOverview reasons={RETURN_REASONS} onReasonClick={onReasonClick} />;
};

ReturnsOverviewContainer.displayName = 'containers/ReturnsOverviewContainer';

ReturnsOverviewContainer.propTypes = {
  userLoggedIn: PropTypes.bool,
  pages: PropTypes.object,
};

export default connect(mapStateToProps)(ReturnsOverviewContainer);
