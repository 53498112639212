import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { browserHistory } from 'react-router';

import language, { getLocale } from 'constants/language/language';

import styles from './Watchlist.scss';

import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import Pagetitle from 'components/atoms/Pagetitle/Pagetitle';
import Breadcrumb, { types } from 'components/organisms/Breadcrumb/Breadcrumb';
import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';
import { accountLogin } from 'constants/routePaths/routePathCombinations';
import WatchlistEmpty from 'components/molecules/WatchlistEmpty/WatchlistEmpty';
import WatchlistFull from 'components/molecules/WatchlistFull/WatchlistFull';
import AddedToCartModal from 'containers/AddedToCartModal/AddedToCartModal';
import dataTracking from 'constants/trackingAttributes/dataTracking';

const redirectToAccount = () => {
  const accountLoginRedirect = {
    pathname: mapPathToLocalizedUrl(getLocale(), accountLogin),
    state: { skin: 'watchlist' },
  };
  browserHistory.push(accountLoginRedirect);
};

const Watchlist = ({ products, isLoggedIn, removeFromWatchlist, deleteWatchlist, categories }) => (
  <div className={cx(styles.container, { [styles.leftAlign]: styles.leftAlign && !!products.length })}>
    <Breadcrumb type={types.invisible} />
    <Pagetitle centered>{language('watchlist.headline')}</Pagetitle>
    {!products.length ? (
      <WatchlistEmpty />
    ) : (
      <WatchlistFull
        products={products}
        removeFromWatchlist={removeFromWatchlist}
        isLoggedIn={isLoggedIn}
        categories={categories}
      />
    )}
    {!!products.length && (
      <div className={styles.buttonWrapper}>
        <div
          className={styles.cancel}
          onClick={deleteWatchlist}
          data-tracking={dataTracking({
            TYPE: 'bookmark',
            ATTRIBUTE_1: 'remove_all',
            SKU: products.map((product) => product.code),
          })}
        >
          {language('watchlist.deleteWatchlist')}
        </div>
        {!isLoggedIn && (
          <ButtonLink variety={ButtonLink.varieties.flat} onClick={redirectToAccount}>
            {language('watchlist.saveButtonText')}
          </ButtonLink>
        )}
      </div>
    )}
    <AddedToCartModal />
  </div>
);

Watchlist.displayName = 'templates/Watchlist';

Watchlist.propTypes = {
  products: PropTypes.array,
  removeFromWatchlist: PropTypes.func,
  deleteWatchlist: PropTypes.func,
  isLoggedIn: PropTypes.bool,
  categories: PropTypes.object,
};

Watchlist.defaultProps = {
  products: [],
  deleteWatchlist: () => {},
  removeFromWatchlist: () => {},
  isLoggedIn: false,
  categories: {},
};

export default Watchlist;
