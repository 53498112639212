import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  clearUpdatedCartHistory as clearUpdatedCartHistoryAction,
  getCart as getCartAction,
} from 'actions/cartActions/cartActions';
import { apiStatus, invalidApiStatus } from 'constants/apiStatus/apiStatus';
import { getFlashMessage, locations } from 'constants/flashMessages/flashMessages';
import language from 'constants/language/language';
import CartLayout from 'components/layouts/CartLayout/CartLayout';
import Meta from 'containers/Meta/Meta';
import CartEmpty from 'components/molecules/CartEmpty/CartEmpty';
import MessageBadRequest, { pageType } from 'components/molecules/MessageBadRequest/MessageBadRequest';
import { trackCart } from 'tracking/tracking';
import NextFeatureEnabled from 'routes/ProductsOverview/NextFeatureEnabled';

const mapStateToProps = (state, ownProps) => ({
  cart: state.cart,
  flashMessage: getFlashMessage(state.ui.flashMessages, locations.CART),
  noEntries: ownProps?.location?.query?.noEntries ?? false,
  products: state.products,
  categories: state.categories,
});
const mapDispatchToProps = {
  clearUpdatedCartHistory: clearUpdatedCartHistoryAction,
  getCart: getCartAction,
};

const Cart = ({
  cart,
  clearUpdatedCartHistory = () => {},
  flashMessage = {},
  getCart = () => {},
  noEntries = false,
  products,
  categories,
}) => {
  const [trackedCart, setTrackedCart] = useState(null);

  useEffect(() => {
    getCart(true);
    return () => {
      clearUpdatedCartHistory();
    };
  }, []);

  useEffect(() => {
    if (cart.apiStatus === apiStatus.success && cart.guid !== trackedCart) {
      trackCart(cart, categories);
      setTrackedCart(cart.guid);
    }
  }, [cart.guid, cart.apiStatus]);

  const MetaTags = () => <Meta title={language('pageTitles.cart')} meta={[{ name: 'robots', content: 'noindex' }]} />;

  if (cart.apiStatus === apiStatus.request && !cart.guid) {
    return (
      <>
        <MetaTags />
      </>
    );
  }

  if (invalidApiStatus.indexOf(cart.apiStatus) > -1) {
    return (
      <div>
        <MetaTags />
        <MessageBadRequest pageType={pageType.general} apiStatus={cart.apiStatus} />
      </div>
    );
  }

  return (
    <div>
      <NextFeatureEnabled conditions={[{ include: 'cart', featureName: 'nextCartEnabled' }]} />
      <MetaTags />
      {cart.totalItems > 0 ? (
        <CartLayout
          cart={cart}
          products={products}
          clearUpdatedCartHistory={clearUpdatedCartHistory}
          flashMessage={flashMessage}
        />
      ) : (
        <CartEmpty cart={cart} noEntries={noEntries} />
      )}
    </div>
  );
};

Cart.displayName = 'routes/Cart';
Cart.propTypes = {
  cart: PropTypes.object.isRequired,
  clearUpdatedCartHistory: PropTypes.func,
  flashMessage: PropTypes.object,
  getCart: PropTypes.func,
  noEntries: PropTypes.bool,
  products: PropTypes.object.isRequired,
  categories: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
