import React from 'react';
import PropTypes from 'prop-types';

import Headline from 'components/atoms/Headline/Headline';
import ProductFeatureRow from './ProductFeatureRow';
import styles from './ProductFeatureTable.scss';

const ProductFeatureTable = ({ name, features }) => (
  <>
    <Headline tag={Headline.tags.H3}>{name}</Headline>
    <table className={styles.table}>
      <tbody className={styles.tbody}>
        {features.map((feature) => (
          <ProductFeatureRow
            key={feature.code}
            name={feature.name}
            values={feature.featureValues}
            unit={feature?.featureUnit?.name}
            isRange={feature.range}
          />
        ))}
      </tbody>
    </table>
  </>
);

ProductFeatureTable.propTypes = {
  name: PropTypes.string.isRequired,
  features: PropTypes.array.isRequired,
};

export default ProductFeatureTable;
