import React from 'react';
import PropTypes from 'prop-types';

import Headline from 'components/atoms/Headline/Headline';
import IconText from 'components/atoms/IconText/IconText';
import Image from 'components/atoms/Image/Image';

import formatDate from 'constants/formatDate/formatDate';
import language from 'constants/language/language';

import styles from './RatingProductSummary.scss';

const RatingProductSummary = ({ productName = '', productImage = {}, productCode = '', orderDate = '' }) => (
  <div className={styles.ratingProductSummary}>
    <div className={styles.image}>
      <Image alt={productImage.altText} images={productImage.sizes} code={productImage.code} />
    </div>
    <div>
      <Headline tag={Headline.tags.H3} margin={Headline.margins.NONE}>
        {productName}
      </Headline>
      <small className={styles.productCode}>
        {language('product.itemNumber')} {productCode}
      </small>
      {orderDate && (
        <IconText>
          <small>
            {language('myAccountRatings.list.bought')} {formatDate(orderDate)}
          </small>
        </IconText>
      )}
    </div>
  </div>
);

RatingProductSummary.propTypes = {
  productName: PropTypes.string,
  productImage: PropTypes.object,
  productCode: PropTypes.string,
  orderDate: PropTypes.string,
};

export default RatingProductSummary;
