import { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

export const mapStateToProps = (state = {}, ownProps = {}) => {
  const wallId = ownProps.wallId;
  return {
    bricks: state.cms?.wallMini?.[wallId]?.content || [],
  };
};

const WallMini = ({ children, bricks }) => {
  if (bricks.length <= 0) return null;

  return cloneElement(children, {
    bricks,
  });
};
WallMini.displayName = 'containers/WallMini';
WallMini.propTypes = {
  children: PropTypes.element.isRequired,
  bricks: PropTypes.array,
};
WallMini.defaultProps = {
  bricks: [],
};

export default connect(mapStateToProps)(WallMini);
