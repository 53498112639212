import React from 'react';
import PropTypes from 'prop-types';

import Headline from 'components/atoms/Headline/Headline';
import Icon, { ICON_LOGOUT } from 'components/atoms/Icon/Icon';
import IconLink, { skins } from 'components/atoms/IconLink/IconLink';
import PopOverIndications from 'components/molecules/PopOverIndications/PopOverIndications';

import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import language, { getLocale } from 'constants/language/language';
import { cdcAccountPopover } from 'constants/subnavigationItems/subnavigationItems';

import styles from './PopOverAccount.scss';

const PopOverAccount = ({ user, logoutUser }) => (
  <>
    <div className={styles.header}>
      <Headline tag={Headline.tags.H3} margin={Headline.margins.NONE}>
        {language('myAccount.header')}
      </Headline>
    </div>
    <div className={styles.inner}>
      {cdcAccountPopover.map((entry) => (
        <IconLink href={mapPathToLocalizedUrl(getLocale(), entry.link)} skin={skins.POPOVER} key={entry.text}>
          <div>
            <Icon path={entry.icon} />
            {language(entry.text)}
          </div>
        </IconLink>
      ))}
      <PopOverIndications />
      <IconLink onClick={logoutUser} skin={skins.POPOVER}>
        <div className={styles.logout}>
          <Icon path={ICON_LOGOUT} />
          {language('myAccount.logout')}
        </div>
      </IconLink>
      <div className={styles.uid}>{user.uid}</div>
    </div>
  </>
);

PopOverAccount.propTypes = {
  user: PropTypes.object,
  logoutUser: PropTypes.func,
};

PopOverAccount.defaultProps = {
  user: {},
  logoutUser: () => {},
};

export default PopOverAccount;
