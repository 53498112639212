import React, { Component } from 'react';
import PropTypes from 'prop-types';
import language from 'constants/language/language';

import IconCHID from 'components/atoms/icons/IconCHID/IconCHID';
import IconSwissPass from 'components/atoms/icons/IconSwissPass/IconSwissPass';
import IconFuehrerausweis from 'components/atoms/icons/IconFuehrerausweis/IconFuehrerausweis';
import IconCHIDCopy from 'components/atoms/icons/IconCHIDCopy/IconCHIDCopy';

import cx from 'classnames';
import styles from './AgeVerificationCard.scss';

export const cardTypes = {
  identityCard: 'SWISSID',
  passport: 'SWISSPASSPORT',
  driverLicense: 'DRIVINGLICENSE',
  sendCopy: 'MANUALLY',
  noCard: 'NOCARD',
};

export default class AgeVerificationCard extends Component {
  static displayName = 'atom/AgeVerificationCard';

  static propTypes = {
    cardType: PropTypes.oneOf(Object.values(cardTypes)),
    isActive: PropTypes.bool,
  };

  static cardTypes = cardTypes;

  getCardClass = () =>
    cx({
      [styles.card]: true,
      [styles.activeCard]: this.props.isActive,
    });

  getCardImage = (cardType) => {
    switch (cardType) {
      case cardTypes.identityCard:
        return (
          <div className={styles.imageWrapper}>
            <IconCHID height={100} />
          </div>
        );
      case cardTypes.passport:
        return (
          <div className={styles.imageWrapper}>
            <IconSwissPass height={100} />
          </div>
        );
      case cardTypes.driverLicense:
        return (
          <div className={styles.imageWrapper}>
            <IconFuehrerausweis height={100} />
          </div>
        );
      case cardTypes.sendCopy:
        return (
          <div className={styles.imageWrapper}>
            <IconCHIDCopy height={100} />
          </div>
        );
      default:
        return null;
    }
  };

  render() {
    const { cardType } = this.props;
    return (
      <div className={this.getCardClass()}>
        {this.getCardImage(cardType)}
        <div className={styles.cardType}>{language(`ageVerification.cardTypes.${cardType}`)}</div>
      </div>
    );
  }
}
