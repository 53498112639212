import React, { Component } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Headline from 'components/atoms/Headline/Headline';
import Icon, { ICON_KEYBOARD_ARROW_DOWN, ICON_KEYBOARD_ARROW_UP } from 'components/atoms/Icon/Icon';
import bootstrap from 'scss/component.scss';
import styles from './FooterToggle.scss';

export const skins = {
  SKIN_GRAY: 'SKIN_GRAY',
  SKIN_WHITE: 'SKIN_WHITE',
};

export default class FooterToggle extends Component {
  static displayName = 'molecules/FooterToggle';

  static propTypes = {
    shouldHide: PropTypes.bool,
    displayArrow: PropTypes.bool,
    headline: PropTypes.string,
    headlineTag: PropTypes.string,
    children: PropTypes.node,
    skin: PropTypes.oneOf(Object.keys(skins)),
  };

  static defaultProps = {
    shouldHide: true,
    displayArrow: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      shouldHide: props.shouldHide,
    };
  }

  toggleDisplay = () => {
    if (this.props.displayArrow) {
      this.setState({
        shouldHide: !this.state.shouldHide,
      });
    }
  };

  render() {
    return (
      <div className={cx(styles.container, styles[this.props.skin])}>
        {this.props.headline && (
          <div className={styles.footerHeadlines} onClick={this.toggleDisplay}>
            <Headline tag={this.props.headlineTag} margin={Headline.margins.NONE}>
              {this.props.headline}
              {this.props.displayArrow && [
                <span
                  key={1}
                  className={cx(styles.arrowIndicator, { [bootstrap.hiddenMdDown]: !this.state.shouldHide })}
                >
                  <Icon path={ICON_KEYBOARD_ARROW_DOWN} size={Icon.sizes.xs} />
                </span>,
                <span
                  key={2}
                  className={cx(styles.arrowIndicator, { [bootstrap.hiddenMdDown]: this.state.shouldHide })}
                >
                  <Icon className={styles.openArrowUp} path={ICON_KEYBOARD_ARROW_UP} size={Icon.sizes.xs} />
                </span>,
              ]}
            </Headline>
          </div>
        )}
        <div className={cx({ [bootstrap.hiddenMdDown]: this.state.shouldHide })}>{this.props.children}</div>
      </div>
    );
  }
}
