/**
 * For an array of product codes for the recommendations, this function returns an array of those
 * products with the full set of product data available in the redux store
 *
 * @param {Array} products - The recommended products without accompanying data
 * @param {object} productState - Product part of the redux store
 */
export const getMatchingProductsFromStore = (products = [], productState = {}) =>
  products.reduce((prev, curr) => {
    const code = curr.productId;
    const product = productState[code];
    if (product && product.sapSaleable && product.sapVisibility) {
      return [...prev, { ...product, elementTitle: curr.elementTitle }];
    }
    return prev;
  }, []);
