import React from 'react';
import ProductComparisonTemplate from 'components/templates/ProductComparison/ProductComparison';
import Meta from 'containers/Meta/Meta';
import ProductComparisonContainer from 'containers/ProductComparison/ProductComparison';

const ProductComparison = () => (
  <>
    <Meta meta={[{ name: 'robots', content: 'noindex' }]} />
    <ProductComparisonContainer>
      <ProductComparisonTemplate />
    </ProductComparisonContainer>
  </>
);

export default ProductComparison;
