import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import noScroll from 'no-scroll';

import { getEnv } from 'config/config';

import VideoButton from 'components/atoms/VideoButton/VideoButton';
import Image from 'components/atoms/Image/Image';

import GalleryCarousel from 'components/molecules/GalleryCarousel/GalleryCarousel';
import LightBoxGallery from 'components/molecules/LightBoxGallery/LightBoxGallery';
import Thumbnails from 'components/molecules/Thumbnails/Thumbnails';

import deepEqual from 'constants/helper/deepEqual';
import imageSizes from 'constants/imageSizes/imageSizes';

import bootstrap from 'scss/component.scss';
import styles from './Gallery.scss';

const Gallery = ({
  data = [],
  productVideoAvailable = false,
  onVideoAvailable = () => {},
  videos = [],
  trackingAttributes = {},
  promoBoxImage = {},
}) => {
  useEffect(() => {
    if (!deepEqual(data, stateData) || !deepEqual(videos, stateVideos)) {
      setStateData(data);
      setStateVideos(videos);
    }
  }, [data, videos]);

  useEffect(() => {
    return () => {
      noScroll.off();
    };
  }, []);

  const [stateData, setStateData] = useState([]);
  const [stateVideos, setStateVideos] = useState([]);
  const [activeSlide, setActiveSlide] = useState(0);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);

  const goToSlide = (index = -1) => {
    if (index >= 0) setActiveSlide(index);
  };

  const openLightbox = () => {
    noScroll.on();
    setIsLightboxOpen(true);
  };

  const closeLightbox = () => {
    noScroll.off();
    setIsLightboxOpen(false);
  };

  const getAltText = (imageData) => imageData?.altText || '';
  const getImages = (imageData) => imageData?.sizes || [];

  const renderVideo = (video) => (
    <VideoButton mid={video} productVideoAvailable={productVideoAvailable} onVideoAvailable={onVideoAvailable} />
  );

  const renderImage = (image) => (
    <Image
      key={image.code}
      alt={getAltText(image)}
      images={getImages(image)}
      size={imageSizes.productDetail.size}
      sizes={imageSizes.productDetail.sizes}
      responsive={imageSizes.productDetail.responsive}
      schemaProperty={'image'}
      code={image.code}
    />
  );

  const renderPromoBox = (image) => (
    <img className={styles.promoBoxImage} src={`${getEnv('web')}${image.url}`} alt={image.altText} />
  );

  const getGalleryData = () => {
    const [firstImage, ...rest] = data;

    // FIRST IMAGE
    const galleryData = [renderImage(firstImage)];

    // PROMOBOX
    if (Object.keys(promoBoxImage).length) {
      galleryData.push(renderPromoBox(promoBoxImage));
    }

    // VIDEOS
    videos.forEach((video) => galleryData.push(renderVideo(video)));

    // IMAGES
    rest.forEach((image) => galleryData.push(renderImage(image)));

    return galleryData;
  };

  const galleryData = getGalleryData();

  return (
    <>
      <div className={styles.galleryWrapper}>
        <div className={bootstrap.hiddenPrint}>
          {isLightboxOpen && (
            <LightBoxGallery
              activeSlide={activeSlide}
              goToSlide={goToSlide}
              galleryData={galleryData}
              closeModal={closeLightbox}
              trackingAttributes={trackingAttributes}
            />
          )}
          {galleryData && (
            <GalleryCarousel
              activeSlide={activeSlide}
              goToSlide={goToSlide}
              galleryData={galleryData}
              openLightboxModal={openLightbox}
              trackingAttributes={trackingAttributes}
            />
          )}
        </div>
      </div>
      {galleryData.length > 1 && (
        <Thumbnails
          activeSlide={activeSlide}
          goToSlide={goToSlide}
          galleryData={galleryData}
          openLightboxModal={() => setIsLightboxOpen(true)}
        />
      )}
    </>
  );
};

Gallery.displayName = 'molecules/Gallery';
Gallery.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      sizes: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string.isRequired,
          size: PropTypes.string.isRequired,
        })
      ),
      altText: PropTypes.string,
      code: PropTypes.string,
    })
  ).isRequired,
  onVideoAvailable: PropTypes.func,
  productVideoAvailable: PropTypes.bool,
  videos: PropTypes.array,
  trackingAttributes: PropTypes.object,
  promoBoxImage: PropTypes.object,
};

export default Gallery;
