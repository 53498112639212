import React from 'react';
import PropTypes from 'prop-types';
import Image, { getAltText } from 'components/atoms/Image/Image';
import imageSizesConstant from 'constants/imageSizes/imageSizes';
import styles from './PopOverCartProduct.scss';

const PopOverCartProduct = ({ customImageData = [], name, quantity, formattedPrice }) => {
  const images = customImageData?.[0]?.sizes;

  return (
    <div className={styles.row}>
      <div className={styles.image}>
        <Image
          alt={getAltText(customImageData)}
          images={images}
          sizes={imageSizesConstant.searchAsYouType.size}
          responsive={imageSizesConstant.searchAsYouType.responsive}
          code={customImageData?.[0]?.code}
        />
      </div>
      <div className={styles.textFullWidth}>
        <div>
          <div className={styles.name}>{name}</div>
        </div>
        <div className={styles.quantityPrice}>
          <div>
            <span className={styles.quantity}>{quantity}x</span>
          </div>
          <div>
            <strong className={styles.strong}>{formattedPrice}</strong>
          </div>
        </div>
      </div>
    </div>
  );
};

PopOverCartProduct.propTypes = {
  customImageData: PropTypes.array,
  name: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  formattedPrice: PropTypes.string.isRequired,
};

export default PopOverCartProduct;
