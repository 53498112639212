// @see https://github.com/yusfgungor/iban-number-validation/blob/master/src/iban-number-validation.js
const MOD_START_VAL = 0;
const MOD_MUST_EQUAL_TO_ONE = 1;

const setCharactersLocations = (ibanValue) => {
  const removedSpaceIbanValue = ibanValue.replace(/\s/g, '');
  const firstFourChar = removedSpaceIbanValue.substr(0, 4);
  const remainingChars = removedSpaceIbanValue.substr(4);

  return remainingChars.concat(firstFourChar);
};

const charactersMatchAndChange = (value) =>
  value
    .split('')
    .map((item) => parseInt(item, 36))
    .join('');

const getMod9710 = (value) => {
  const valueToArray = value.split('');

  return valueToArray.reduce(
    (accumulator, currentValue) => (accumulator * 10 + Number(currentValue)) % 97,
    MOD_START_VAL
  );
};

const isIbanValid = (ibanValue) => {
  if (ibanValue && typeof ibanValue === 'string') {
    const ibanCharactersLocationsChanged = setCharactersLocations(ibanValue);
    const ibanCharactersMatchAndChanged = charactersMatchAndChange(ibanCharactersLocationsChanged);
    const result = getMod9710(ibanCharactersMatchAndChanged) === MOD_MUST_EQUAL_TO_ONE;

    return !!result;
  }

  return false;
};

export default isIbanValid;
