// @flow
import getStorage from 'constants/storage/storage';
import { LAST_RATING_DATE } from 'constants/storage/storageKeys';

import type { IRating } from 'flow/ratings';

const storage = getStorage(true);

export const RATING_COUNT_THRESHOLD = 3;
export const RECOMMENDS_RATE_THRESHOLD = 50;

export const RATING_TYPES = {
  ALL: 'rejected',
  RATED: 'rated',
  UNRATED: 'unrated',
};

export const MODAL_TYPES = {
  DELETE: 'delete',
  EDIT: 'edit',
};

export const APPROVAL_STATUS = {
  OPEN: 'OPEN',
  PENDING: 'PENDING',
  FORWARDED: 'FORWARDED',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

/**
 * Get the approval status of a rating (Maps backend values to frontend values)
 */
export const getApprovalStatus = (status: ?string = '') => {
  switch (String(status).toUpperCase()) {
    case APPROVAL_STATUS.OPEN:
    case APPROVAL_STATUS.PENDING:
    case APPROVAL_STATUS.FORWARDED:
      // Always return "OPEN" for different (internal) backend status
      return APPROVAL_STATUS.OPEN;
    case APPROVAL_STATUS.REJECTED:
      return APPROVAL_STATUS.REJECTED;
    default:
      return APPROVAL_STATUS.APPROVED;
  }
};

export const showRatingCommentStatusBox = (rating: IRating) =>
  rating.comment && getApprovalStatus(rating.status) !== APPROVAL_STATUS.APPROVED;

/**
 * Create the full distribution object from a partial object, cause backend only sents distribution
 * keys with values greater zero
 */
export const hydrateDistribution = (distribution: { [string]: number } = {}) => {
  // $FlowFixMe
  const res = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };

  Object.keys(distribution).forEach((key) => {
    res[key] = parseInt(distribution[key], 10);
  });

  return res;
};

export const userCurrentlyRated = () => {
  const lastRatedDate = storage.getItem(LAST_RATING_DATE);
  // 4h = product api max-age header
  const h4MS = 4 * 60 * 60 * 1000;
  return lastRatedDate ? Date.now() - parseInt(lastRatedDate, 10) < h4MS : false;
};
