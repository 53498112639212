import React from 'react';
import PropTypes from 'prop-types';
import IconLink from 'components/atoms/IconLink/IconLink';
import Icon, { ICON_KEYBOARD_ARROW_DOWN, ICON_KEYBOARD_ARROW_UP } from 'components/atoms/Icon/Icon';

import language from 'constants/language/language';
import styles from './StoreFinderListItem.scss';

const MoreInformation = ({ toggle, showMore }) => (
  <div className={styles.showMoreInformation}>
    <IconLink onClick={toggle}>
      {!showMore ? <Icon path={ICON_KEYBOARD_ARROW_DOWN} /> : <Icon path={ICON_KEYBOARD_ARROW_UP} />}
      <span>{language('pickupStore.details')}</span>
    </IconLink>
  </div>
);

MoreInformation.displayName = 'components/molecules/StoreFinderListItem/MoreInformation';

MoreInformation.propTypes = {
  toggle: PropTypes.func,
  showMore: PropTypes.bool,
};

MoreInformation.defaultProps = {
  toggle: () => {},
  showMore: false,
};

export default MoreInformation;
