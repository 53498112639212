import React from 'react';
import PropTypes from 'prop-types';
import dataTracking from 'constants/trackingAttributes/dataTracking';
import getUrlForProduct from 'constants/getUrlForProduct/getUrlForProduct';
import { getLocale } from 'constants/language/language';
import EnergyEfficiency, {
  contexts as energyContexts,
  types,
} from 'components/atoms/EnergyEfficiency/EnergyEfficiency';
import Icon, { ICON_CLOSE } from 'components/atoms/Icon/Icon';
import Image from 'components/atoms/Image/Image';
import Price, { SKINS } from 'components/atoms/Price/Price';
import styles from './ProductCard.scss';
import { NextFeatureLink } from 'components/atoms/NextFeatureLink';

const ProductCard = ({
  categories = {},
  isRemovable = false,
  onRemoveClick = () => {},
  position = 0,
  product = {},
  productClickHandler = () => {},
  trackRecoProduct = () => {},
  trackingAttributes = {},
  children,
}) => {
  const {
    categoryCode,
    name,
    code,
    customImageData,
    productPriceData,
    energyEfficiency,
    energyEfficiencyEnEv2020,
  } = product;
  const link = getUrlForProduct(categoryCode, name, code, getLocale(), categories);

  const onProductClickHandler = () => {
    productClickHandler(product, '');
    trackRecoProduct();
  };

  const onRemoveClickHandler = (e) => {
    e.preventDefault();
    onRemoveClick(code);
  };

  const hasEnergyEfficiency =
    !!Object.keys(energyEfficiency || {})?.length || !!Object.keys(energyEfficiencyEnEv2020 || {})?.length;

  return (
    <NextFeatureLink
      feature="nextPdpEnabled"
      to={link}
      className={styles.productCard}
      onClick={onProductClickHandler}
      data-tracking={
        trackingAttributes.prefix &&
        dataTracking({
          TYPE: `${trackingAttributes.prefix}:offer`,
          LINK: link,
          INNERLOCATION: position + 1,
          ATTRIBUTE_1: trackingAttributes.parentTitle ? trackingAttributes.parentTitle.replace(/\s|:/g, '') : null,
          ATTRIBUTE_2: trackingAttributes.tabName ? trackingAttributes.tabName.replace(/\s|:/g, '') : null,
          FUNCTION: 'go-to',
        })
      }
    >
      <Image
        className={styles.productImage}
        images={customImageData?.[0]?.sizes}
        code={customImageData?.[0]?.code}
        aspectRatio={0}
        alt={product.name}
      />
      <div className={styles.content}>
        <div className={styles.price}>
          <Price productPriceData={productPriceData} skin={SKINS.PRODUCTCARD} />
          {hasEnergyEfficiency && (
            <EnergyEfficiency
              energyEfficiency={energyEfficiency}
              energyEfficiencyEnEv2020={energyEfficiencyEnEv2020}
              type={types.xs}
              context={energyContexts.SMALL_TEASER}
            />
          )}
        </div>
        <div className={styles.productName}>{product.name}</div>
      </div>
      {isRemovable && (
        <button type="button" className={styles.closeIcon} onClick={onRemoveClickHandler}>
          <Icon path={ICON_CLOSE} size={Icon.sizes.sm} />
        </button>
      )}
      {children && <div className={styles.children}>{children}</div>}
    </NextFeatureLink>
  );
};

ProductCard.displayName = 'molecules/ProductCard';
ProductCard.propTypes = {
  categories: PropTypes.object,
  isRemovable: PropTypes.bool,
  onRemoveClick: PropTypes.func,
  position: PropTypes.number,
  product: PropTypes.object,
  productClickHandler: PropTypes.func,
  trackRecoProduct: PropTypes.func,
  trackingAttributes: PropTypes.object,
  children: PropTypes.node,
};

export default ProductCard;
