import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'components/organisms/Slider/Slider';
import SingleWallBanner from 'components/molecules/SingleWallBanner/SingleWallBanner';
import styles from './WallSingle.scss';

const WallSingle = ({ bricks = [], productCode = '' }) => {
  const renderSlides = (slides = [], code) =>
    slides.map((slide, index) => (
      <SingleWallBanner
        key={index}
        {...slide}
        trackingAttributes={{
          SKU: code,
          INNERLOCATION: index + 1,
          FUNCTION: 'open',
        }}
      />
    ));

  return (
    <div className={styles.wrapper}>
      {bricks.length > 1 ? (
        <Slider
          allowTouchMove
          grabCursor
          id={'testSlider'}
          pagination={bricks.length > 1}
          slides={renderSlides(bricks, productCode)}
          slidesPerGroup={1}
          slidesPerView={1}
          spaceBetween={0}
          trackingAttributes={{ prefix: 'singlewall' }}
        />
      ) : (
        renderSlides(bricks, productCode)
      )}
    </div>
  );
};

WallSingle.displayName = 'organisms/WallSingle';
WallSingle.propTypes = {
  bricks: PropTypes.array,
  productCode: PropTypes.string,
};

export default WallSingle;
