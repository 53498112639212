import { cloneElement, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import getStorage from 'constants/storage/storage';
import {
  changeCategory,
  closeComparisonHeader,
  getFullComparison,
  getShortComparison,
  removeFromComparison,
} from 'actions/comparisonActions/comparisonActions';

import { addToWatchlist, removeFromWatchlist } from 'actions/watchlistActions/watchlistActions';
import { trackComparison } from 'actions/trackingActions/trackingActions';

const localStorage = getStorage(true);

export const mapStateToProps = (state, ownProps) => {
  const list = JSON.parse(localStorage.getItem('comparisonList'));
  const comparisonCategories = state?.comparison?.categories || {};
  const comparisonList = state?.comparison?.list || {};
  const products = state?.comparison?.products?.map((p) => ({ ...p, elementTitle: 'comparison' })) || [];

  const showError = state?.comparison?.hasError ?? false;

  const selectedCategory = state?.comparison?.selectedCategory || '';
  const categoryCode = showError ? selectedCategory : ownProps?.categoryCode || selectedCategory;

  const productCodes = list?.categories?.[categoryCode] || [];
  const apiStatus = state?.comparison?.apiStatus;
  const watchlistEntries = state?.watchlist?.entries;

  return {
    selectedCategory,
    productCodes,
    comparisonList,
    showError,
    comparisonCategories,
    products,
    apiStatus,
    watchlistEntries,
    categories: state.categories,
  };
};

export const mapDispatchToProps = {
  changeCategory,
  removeFromComparison,
  getFullComparison,
  getShortComparison,
  addProductToWatchlist: addToWatchlist,
  removeFromWatchlist,
  closeComparisonHeader,
  trackComparison,
};

export class ProductComparison extends Component {
  getComparisonList = (productCodes, loadFullList = false, isAllowedToTrack = false) => {
    const productCodeString = productCodes.join(',');
    if (loadFullList) {
      this.props.getFullComparison(productCodeString, this.props.selectedCategory).then(() => {
        if (isAllowedToTrack) {
          this.props.trackComparison();
        }
      });
    } else {
      this.props.getShortComparison(productCodeString, this.props.selectedCategory);
    }
  };

  changeCategory = (categoryCode) => {
    this.props.changeCategory(categoryCode);
    this.props.trackComparison();
  };

  removeProductFromComparison = (productCode, categoryCode) => {
    this.props.removeFromComparison(productCode, categoryCode);
  };

  isProductInWatchlist = (productCode) =>
    !!this.props.watchlistEntries.find((entry) => entry.code === productCode)?.length;

  removeProductFromWatchlist = (productCode) => {
    const arrayIndex = this.props.watchlistEntries.findIndex((entry) => entry.code === productCode);
    this.props.removeFromWatchlist(arrayIndex);
  };

  closeComparisonHeader = () => {
    this.props.closeComparisonHeader();
  };

  render() {
    const {
      selectedCategory,
      comparisonList,
      showError,
      comparisonCategories,
      categories,
      productCodes,
      products,
      apiStatus,
      addProductToWatchlist,
    } = this.props;

    return cloneElement(this.props.children, {
      selectedCategory,
      comparisonList,
      getComparisonList: this.getComparisonList,
      changeCategory: this.changeCategory,
      removeFromComparison: this.removeProductFromComparison,
      showError,
      comparisonCategories,
      categories,
      productCodes,
      products,
      apiStatus,
      addToWatchlist: addProductToWatchlist,
      removeFromWatchlist: this.removeProductFromWatchlist,
      isProductInWatchlist: this.isProductInWatchlist,
      closeComparisonHeader: this.closeComparisonHeader,
    });
  }
}

ProductComparison.displayName = 'containers/ProductComparison';
ProductComparison.propTypes = {
  addProductToWatchlist: PropTypes.func,
  apiStatus: PropTypes.string,
  categories: PropTypes.object,
  changeCategory: PropTypes.func,
  children: PropTypes.node,
  closeComparisonHeader: PropTypes.func,
  comparisonCategories: PropTypes.object,
  comparisonList: PropTypes.object,
  getFullComparison: PropTypes.func,
  getShortComparison: PropTypes.func,
  productCodes: PropTypes.array,
  products: PropTypes.array,
  removeFromComparison: PropTypes.func,
  removeFromWatchlist: PropTypes.func,
  selectedCategory: PropTypes.string,
  showError: PropTypes.bool,
  trackComparison: PropTypes.func,
  watchlistEntries: PropTypes.array,
};
ProductComparison.defaultProps = {
  addProductToWatchlist: () => {},
  apiStatus: '',
  categories: {},
  changeCategory: () => {},
  closeComparisonHeader: () => {},
  comparisonCategories: {},
  comparisonList: {},
  getFullComparison: () => {},
  getShortComparison: () => {},
  productCodes: [],
  products: [],
  removeFromComparison: () => {},
  removeFromWatchlist: () => {},
  selectedCategory: '',
  showError: false,
  trackComparison: () => {},
  watchlistEntries: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductComparison);
