import language from 'constants/language/language';

const getSearchTitle = (breadcrumb, searchString) => `${searchString} - ${language('pageTitles.search')}`;

const getCategoryTitle = (pageTitle, brandQuery) => {
  if (!brandQuery) {
    return `${pageTitle} - ${language('meta.categoryTitle')}`;
  }

  return `${brandQuery} - ${pageTitle}`;
};

const getCategoryHeadline = (pageTitle, brandQuery) => {
  if (!brandQuery) {
    return pageTitle;
  }

  return `${brandQuery} - ${pageTitle}`;
};

export default {
  getSearchTitle,
  getCategoryTitle,
  getCategoryHeadline,
};
