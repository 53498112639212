import React from 'react';
import PropTypes from 'prop-types';
import { getFormattedPriceValue } from 'constants/price/price';
import styles from './FormattedPrice.scss';
import cx from 'classnames';

const FormattedPrice = ({ showCentAsSup = false, value, skin = '' }) => {
  const formattedValue = getFormattedPriceValue(value);
  const beforeAndAfterDecimal = formattedValue.split('.');
  const franc = beforeAndAfterDecimal[0];
  const cent = beforeAndAfterDecimal[1];
  const hasCent = Number(cent);
  let displayCent = hasCent ? cent : '\u2013';

  // specific case - only for search
  if (skin === 'SEARCHSUGGEST' && !hasCent) {
    displayCent = '00';
  }

  return (
    <>
      {franc}.{hasCent && showCentAsSup ? <span className={cx(styles.supCent)}>{cent}</span> : displayCent}
    </>
  );
};

FormattedPrice.displayName = 'atoms/FormattedPrice';
FormattedPrice.propTypes = {
  showCentAsSup: PropTypes.bool,
  value: PropTypes.number,
  skin: PropTypes.string,
};

export default FormattedPrice;
