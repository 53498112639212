import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import language from 'constants/language/language';
import { getDeliveryState } from 'constants/deliveryStates/getDeliveryStatus';
import { availabilityStates } from 'constants/deliveryStates/availabilityStates';
import { deliveryModes } from 'constants/deliveryModes/deliveryModes';
import { noop } from 'constants/noop/noop';
import styles from './DeliveryState.scss';

export const deliverySkins = {
  CART: 'CART',
  CHECKOUT: 'CHECKOUT',
  TEASER: 'TEASER',
  PEGI: 'PEGI',
};

export const DeliveryState = ({
  skin = deliverySkins.CART,
  speedFlag = false,
  stockInfo = [],
  ownStore = {},
  openStorefinderModal = noop,
  showStockInfo = false,
  availability = availabilityStates.Z,
  deliveryDate = {},
  deliveryDateTo = {},
  pickupDate = {},
  releaseDate = '',
  isCart = false,
  deliveryMode = '',
  displayDate = false,
  neutralDeliveryPossible = false,
}) => {
  const props = {
    skin,
    speedFlag,
    displayDate,
    stockInfo,
    ownStore,
    openStorefinderModal,
    showStockInfo,
    availability,
    deliveryDate,
    deliveryDateTo,
    pickupDate,
    isCart,
    releaseDate,
    deliveryMode,
  };

  const {
    text,
    timestamp,
    timestampTo,
    immediatelyAvailable,
    isReleaseArticle,
    isInFuture,
    pickup,
    isReleaseDateInPast,
    ...rest
  } = getDeliveryState({ ...props }, skin === deliverySkins.CHECKOUT);

  return (
    <div className={styles[skin]}>
      <div className={styles.deliveryState}>
        {displayDate && (
          <>
            <div
              className={cx(styles.dlvIcon, styles[availability], { [styles.none]: skin === deliverySkins.CHECKOUT })}
            />
            {!isInFuture && (
              <span className={styles.textDate}>
                {text} {isReleaseArticle && !isReleaseDateInPast && rest.releaseDate}
                {(!isReleaseArticle || isReleaseDateInPast) &&
                  !immediatelyAvailable &&
                  deliveryMode === deliveryModes.pickup &&
                  `${pickup}${timestampTo ? ' - ' + timestampTo : ''}`}
                {(!isReleaseArticle || isReleaseDateInPast) &&
                  !immediatelyAvailable &&
                  deliveryMode === deliveryModes.homeDelivery &&
                  availability !== availabilityStates.G2 &&
                  `${timestamp}${timestampTo ? ' - ' + timestampTo : ''}`}
              </span>
            )}
            {isInFuture && skin === deliverySkins.CHECKOUT && (
              <span className={styles.textDate}>{language('deliveryStates.detail.reservation.unknown')}</span>
            )}
          </>
        )}
        {neutralDeliveryPossible && (
          <span className={styles.dlvText}>{language('b2b.myAccountOrderInfo.neutralDeliveryNote')}</span>
        )}
      </div>
    </div>
  );
};

DeliveryState.displayName = 'molecules/DeliveryState';

DeliveryState.propTypes = {
  skin: PropTypes.oneOf(Object.keys(deliverySkins)).isRequired,
  speedFlag: PropTypes.bool,
  displayDate: PropTypes.bool,
  stockInfo: PropTypes.array,
  ownStore: PropTypes.object,
  openStorefinderModal: PropTypes.func,
  showStockInfo: PropTypes.bool,
  availability: PropTypes.string,
  deliveryDate: PropTypes.object,
  deliveryDateTo: PropTypes.object,
  pickupDate: PropTypes.object,
  releaseDate: PropTypes.string,
  isCart: PropTypes.bool,
  deliveryMode: PropTypes.string,
  neutralDeliveryPossible: PropTypes.bool,
};

export default DeliveryState;
