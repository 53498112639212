import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './FlagBox.scss';

const types = {
  PEGI: 'PEGI',
  SPEED: 'SPEED',
  NEUTRAL_DELIVERY_NOTE: 'NEUTRAL_DELIVERY_NOTE',
};

const FlagBox = (props) => (
  <small className={cx(styles.FlagBox, styles[props.type])} title={props.toolTip}>
    {props.text}
  </small>
);

FlagBox.propTypes = {
  text: PropTypes.string.isRequired,
  toolTip: PropTypes.string,
  type: PropTypes.oneOf(Object.keys(types)),
};

FlagBox.types = types;

FlagBox.displayName = 'atoms/FlagBox';

export default FlagBox;
