import React from 'react';
import PropTypes from 'prop-types';
import styles from './ProductCode.scss';

const ProductCode = ({ itemNumber, label }) => (
  <small className={styles.ItemNumber}>
    {label} {itemNumber}
  </small>
);

ProductCode.propTypes = {
  itemNumber: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

ProductCode.displayName = 'atoms/ProductCode';

export default ProductCode;
