import restClient from 'api/RestClient/RestClient';
import { resourceKeys } from 'constants/restResources/restResources';
import { TTL_FIVE_MIN } from 'constants/cache/cache';

const loadGlobalFlashMessages = () =>
  restClient.get(resourceKeys.FLASHMESSAGE_RESOURCE, '', true, { cacheInSec: TTL_FIVE_MIN });

export default {
  loadGlobalFlashMessages,
};
