import FacetListItem from 'components/atoms/FacetListItem/FacetListItem';
import React from 'react';
import MobileNavigationItem from 'components/atoms/MobileNavigationItem/MobileNavigationItem';
import { ICON_ARROW_HISTORY_BACK } from 'components/atoms/Icon/Icon';
import { browserHistory } from 'react-router';
import language, { getLocale } from 'constants/language/language';
import PropTypes from 'prop-types';
import ProductsFacetContainer from 'containers/ProductsFacetContainer/ProductsFacetContainer';
import styles from './CategoryFacet.scss';
import cx from 'classnames';
import { FacetPropType } from 'constants/facetsHelper/facetsHelper';

const CategoryFacet = ({ categoryFacet, breadcrumb }) => {
  if (categoryFacet?.code !== 'categoryPath') return null;
  const last = breadcrumb ? breadcrumb[breadcrumb.length - 1] : undefined;
  const isSearch = last?.name?.startsWith(language('breadcrumb.search'));
  if (isSearch) {
    breadcrumb = breadcrumb.slice(0, -1);
  }

  const wrapperStyle = cx(styles.facetList, styles.spacer, styles.categoryFacet);
  const selectedCategoryFacet =
    categoryFacet.selectedValues?.length > 0 && categoryFacet.selectedValues[categoryFacet.selectedValues.length - 1];
  const selectedCategory = selectedCategoryFacet?.name;

  const handleReturn = () => {
    let { search } = window.location;
    browserHistory.push('/' + getLocale() + (isSearch ? `/search${search}` : ''));
  };

  const renderHomeLink = () =>
    categoryFacet.showBackLink ? (
      <MobileNavigationItem showIndicator indicatorIcon={ICON_ARROW_HISTORY_BACK} onClick={handleReturn}>
        {language('filter.category')}
      </MobileNavigationItem>
    ) : (
      <MobileNavigationItem className={cx(styles.activeFacet)} disabled id="selected" highlightedItem="selected">
        {language('filter.category')}
      </MobileNavigationItem>
    );

  const renderBreadcrumbs = () =>
    breadcrumb?.slice(0, breadcrumb.length - 1).map((element) => (
      <MobileNavigationItem
        showIndicator
        indicatorIcon={ICON_ARROW_HISTORY_BACK}
        key={element?.href}
        onClick={() => browserHistory.push(element?.href)}
      >
        {element?.name}
      </MobileNavigationItem>
    ));

  const renderSelectedCategory = () =>
    selectedCategory && (
      <MobileNavigationItem
        className={cx(styles.activeFacet, !categoryFacet.showBackLink ? styles.activeFacetFirst : '')}
        disabled
        id="selected"
        highlightedItem="selected"
      >
        {selectedCategory}
      </MobileNavigationItem>
    );

  const renderNextCategories = () =>
    categoryFacet?.values
      ?.filter((facet) => !facet?.selected)
      .map((facet) => (
        <ProductsFacetContainer key={facet.code} facetQuery={facet.query} facetName={categoryFacet.name}>
          <FacetListItem facetValue={facet} />
        </ProductsFacetContainer>
      ));

  return (
    <div className={wrapperStyle}>
      {renderHomeLink()}
      {renderBreadcrumbs()}
      {renderSelectedCategory()}
      {renderNextCategories()}
    </div>
  );
};

CategoryFacet.propTypes = {
  categoryFacet: FacetPropType.isRequired,
  breadcrumb: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      name: PropTypes.string,
    })
  ),
};
CategoryFacet.displayName = 'organisms/CategoryFacet';

export default CategoryFacet;
