import { Component, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import {
  loginUser,
  getUser,
  getUserConsents,
  updateUserConsents,
  updatePasswordField,
  updateEmailField,
  changeGuest,
  createUser,
  createUserAfterGuestCheckout,
  changeCustomerType,
  blurEmailField,
  subscribeNewsletter,
  logoutUser,
  forgottenPasswordRestorePassword,
  forgottenPasswordCheckToken,
  blurPasswordField,
  downloadPdf,
  verifyRecaptcha,
  verifyEmail,
  updateBirthdayField,
  blurBirthdayField,
  updateUser,
  updateAGBs,
} from 'actions/userActions/userActions';

import { apiStatus } from 'constants/apiStatus/apiStatus';

import { setForceValidationErrorsVisible } from 'actions/uiActions/uiActions';

import { mergeCart } from 'actions/cartActions/cartActions';
import { mergeWatchlist } from 'actions/watchlistActions/watchlistActions';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import { getLocale } from 'constants/language/language';
import { OPTIN, OPTIN_TYPE_REGISTRATION, OPTIN_CONFIRMATION_MAIL } from 'constants/routePaths/routePaths';
import { customerGroup } from 'constants/customerGroup/customerGroup';
import { addressTypes } from 'constants/addresses/addresses';
import { scrollFirstErrorIntoView } from 'constants/scrollToElement/scrollToElement';
import { B2B_REGISTRATION } from 'constants/pdfTypes/pdfTypes';
import { EMAIL } from 'constants/storage/storageKeys';

export const mapStateToProps = (state) => ({
  user: state.user,
  addresses: state.addresses,
  addressId: Object.keys(state.addresses).length > 0 ? Object.keys(state.addresses)[0] : addressTypes.TEMPORARY,
  isForceValidationErrorsVisible: state.ui.forceValidation,
  apiStatus: state?.user?.apiStatus || apiStatus.success,
  recaptchaNeeded: state?.user?.recaptchaNeeded ?? true,
  consentsApiStatus: state?.user?.consentsApiStatus,
});

const mapDispatchToProps = {
  getUser,
  getUserConsents,
  updateUserConsents,
  updatePasswordField,
  updateEmailField,
  changeGuest,
  blurEmailField,
  subscribeNewsletter,
  setForceValidationErrorsVisible,
  loginUser,
  createUser,
  createUserAfterGuestCheckout,
  changeCustomerType,
  mergeCart,
  mergeWatchlist,
  logoutUser,
  forgottenPasswordRestorePassword,
  forgottenPasswordCheckToken,
  blurPasswordField,
  downloadPdf,
  verifyRecaptcha,
  verifyEmail,
  updateBirthdayField,
  blurBirthdayField,
  updateUser,
  updateAGBs,
};

export class User extends Component {
  static displayName = 'containers/User';

  static propTypes = {
    children: PropTypes.node.isRequired,
    user: PropTypes.object,
    addresses: PropTypes.object,
    addressId: PropTypes.string,
    isForceValidationErrorsVisible: PropTypes.bool,
    updatePasswordField: PropTypes.func,
    updateEmailField: PropTypes.func,
    getUser: PropTypes.func,
    getUserConsents: PropTypes.func,
    updateUserConsents: PropTypes.func,
    blurEmailField: PropTypes.func,
    subscribeNewsletter: PropTypes.func,
    setForceValidationErrorsVisible: PropTypes.func,
    loginUser: PropTypes.func,
    createUser: PropTypes.func,
    createUserAfterGuestCheckout: PropTypes.func,
    changeCustomerType: PropTypes.func,
    mergeCart: PropTypes.func,
    mergeWatchlist: PropTypes.func,
    logoutUser: PropTypes.func,
    forgottenPasswordRestorePassword: PropTypes.func,
    forgottenPasswordCheckToken: PropTypes.func,
    blurPasswordField: PropTypes.func,
    downloadPdf: PropTypes.func,
    apiStatus: PropTypes.string,
    verifyRecaptcha: PropTypes.func,
    verifyEmail: PropTypes.func,
    recaptchaNeeded: PropTypes.bool,
    updateBirthdayField: PropTypes.func,
    blurBirthdayField: PropTypes.func,
    updateUser: PropTypes.func,
    changeGuest: PropTypes.func,
    updateAGBs: PropTypes.func,
    consentsApiStatus: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      showSpinner: false,
    };
  }

  createUserAfterGuestCheckout = () => {
    const { password } = {
      password: this.props.user.fields.password.value,
    };

    this.props.createUserAfterGuestCheckout(password).then((response) => {
      if (response) {
        browserHistory.push({
          pathname: mapPathToLocalizedUrl(getLocale(), [OPTIN, OPTIN_TYPE_REGISTRATION, OPTIN_CONFIRMATION_MAIL]),
        });
      }
    });
  };

  checkUser = (email) => this.props.verifyEmail(email);

  onUpdateEmailField = (value) => {
    sessionStorage.setItem(EMAIL, value);
    this.props.updateEmailField(value);
  };

  registerUser = () => {
    this.setState({ showSpinner: true });
    const address = this.props.addresses[this.props.addressId].fields;
    const userData = {
      firstName: address.firstName.value,
      lastName: address.lastName.value,
      login: this.props.user.fields.email.value,
      titleCode: address.salutation.value,
      password: this.props.user.fields.password.value,
      birthday: this.props.user.fields.birthday.value,
      newsletter: this.props.user.fields.newsletter.value,
      group: this.props.user.fields.group.value || customerGroup.CUSTOMERGROUP,
      supercardCode: this.props.user.fields.supercardCode.value,
      acceptAGBs: this.props.user.fields.acceptAGBs.value,
    };
    const addressData = {
      firstName: userData.firstName,
      lastName: userData.lastName,
      streetName: address.addressLine1.value,
      streetNumber: address.addressLine2.value,
      titleCode: userData.titleCode,
      postalCode: address.postalCode.value,
      country: {
        isocode: address.country.value,
      },
      town: address.town.value,
      phone: address.phone.value,
      remarks: address.addition.value,
      companyName: address.company.value,
    };
    this.props
      .createUser({
        ...userData,
        address: addressData,
      })
      .then((response) => {
        if (response) {
          browserHistory.push({
            pathname: mapPathToLocalizedUrl(getLocale(), [OPTIN, OPTIN_TYPE_REGISTRATION, OPTIN_CONFIRMATION_MAIL]),
          });
        } else {
          this.setState({ showSpinner: false });
        }
      });
  };

  handleRegistration = (addressValid, userValid, registerUser, _setForceValidationErrorsVisible, agbValid) => {
    if (userValid && addressValid && agbValid) {
      _setForceValidationErrorsVisible(false);
      registerUser();
    } else {
      _setForceValidationErrorsVisible(true);
      scrollFirstErrorIntoView();
    }
  };

  changeCustomer = (value) => {
    this.props.changeCustomerType(value);
  };

  handleDownloadPdf = () => {
    this.props.downloadPdf(B2B_REGISTRATION);
  };

  logout = (redirect) => {
    if (typeof redirect !== 'string') redirect = false;
    if (this.props.user.isLinkedWithScid) redirect = '/supercard/api/logout';
    this.props.logoutUser().then(() => {
      window.location.pathname = redirect || `/${getLocale()}`; // re-direct to homepage
    });
  };

  render() {
    return cloneElement(this.props.children, {
      checkUser: this.checkUser,
      user: this.props.user,
      isForceValidationErrorsVisible: this.props.isForceValidationErrorsVisible,
      createUserAfterGuestCheckout: this.createUserAfterGuestCheckout,
      updatePasswordField: this.props.updatePasswordField,
      updateEmailField: this.onUpdateEmailField,
      getUser: this.props.getUser,
      getUserConsents: this.props.getUserConsents,
      updateUserConsents: this.props.updateUserConsents,
      blurEmailField: this.props.blurEmailField,
      registerUser: this.registerUser,
      subscribeNewsletter: this.props.subscribeNewsletter,
      handleRegistration: () =>
        this.handleRegistration(
          this.props.addresses[this.props.addressId].valid,
          this.props.user.valid,
          this.registerUser,
          this.props.setForceValidationErrorsVisible,
          this.props.user.fields.acceptAGBs.value
        ),
      logoutUser: this.logout,
      forgottenPasswordRestorePassword: this.props.forgottenPasswordRestorePassword,
      forgottenPasswordCheckToken: this.props.forgottenPasswordCheckToken,
      blurPasswordField: this.props.blurPasswordField,
      changeCustomerType: this.changeCustomer,
      downloadB2BPdf: this.handleDownloadPdf,
      apiStatus: this.props.apiStatus,
      showSpinner: this.state.showSpinner,
      verifyRecaptcha: this.props.verifyRecaptcha,
      recaptchaNeeded: this.props.recaptchaNeeded,
      updateBirthdayField: this.props.updateBirthdayField,
      blurBirthdayField: this.props.blurBirthdayField,
      updateUser: this.props.updateUser,
      changeGuest: this.props.changeGuest,
      updateAGBs: this.props.updateAGBs,
      consentsApiStatus: this.props.consentsApiStatus,
    });
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(User);
