import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { validLocales, validLanguages, getLocale, setLocaleCookie } from 'constants/language/language';
import styles from './LanguageSelect.scss';

import { changeToLocalizedPath, getRelocalizedUrlPath } from 'constants/urlHelper/urlHelper';
import { provideUrls } from 'constants/navigation/navigation';
import getCookieStorage from 'constants/storage/cookie';

const LanguageSelectList = ({ pages, useLongValue, actualPath, categories }) => {
  const handleClick = (locale) => (e) => {
    e.preventDefault();
    setLocaleCookie(locale);
    changeToLocalizedPath(locale, provideUrls(pages), getLocale(), categories);
  };

  useEffect(() => {
    const cookies = getCookieStorage();
    if (!cookies.getItem('locale')) {
      setLocaleCookie(getLocale());
    }
  }, []);

  const values = useLongValue ? validLanguages : validLocales;

  const langItems = Object.keys(values).map((locale) => (
    <li key={locale} className={styles.option}>
      {locale === getLocale() ? (
        <span className={styles.active} id={`formControl-language${locale.toUpperCase()}-link`}>
          {values[locale]}
        </span>
      ) : (
        <a
          href={getRelocalizedUrlPath(actualPath, locale, provideUrls(pages), categories)}
          onClick={handleClick(locale)}
          className={styles.link}
        >
          {values[locale]}
        </a>
      )}
    </li>
  ));

  return (
    <ul className={cx(styles.list, { [styles.longValue]: useLongValue })}>
      {langItems.reduce((prev, curr) => [prev, curr])}
    </ul>
  );
};

LanguageSelectList.propTypes = {
  pages: PropTypes.object,
  categories: PropTypes.object,
  useLongValue: PropTypes.bool,
  actualPath: PropTypes.string,
};

LanguageSelectList.displayName = 'molecules/LanguageSelectList';

export default LanguageSelectList;
