import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Headline from 'components/atoms/Headline/Headline';
import PaymentIcon from 'components/atoms/PaymentIcon/PaymentIcon';
import { getFooterPaymentModes } from 'actions/cartActions/cartActions';
import language from 'constants/language/language';
import { Link } from 'react-router';
import styles from './PaymentServices.scss';
import FooterToggle from 'components/molecules/FooterToggle/FooterToggle';

export const mapStateToProps = (state) => ({
  footerPaymentModes: state?.cart?.footerPaymentModes ?? {},
});

export const mapDispatchToProps = {
  getFooterPaymentModes,
};

const PaymentServices = (props) => {
  const [paymentModesCodes, setPaymentModesCodes] = useState([]);

  useEffect(() => {
    props.getFooterPaymentModes();
  }, []);

  useEffect(() => {
    mapPaymentIcons();
  }, [props.footerPaymentModes]);

  const mapPaymentIcons = () => {
    const codesList = [];
    Object.keys(props.footerPaymentModes).forEach((mode) => {
      if (mode !== 'PAY_AT_PICKUP' && mode !== 'MONTHLYINVOICE_PCF' && mode !== 'PAY_IN_ADVANCE') {
        codesList.push(PaymentIcon.PaymentMethods[mode]);
        setPaymentModesCodes(codesList);
      }
    });
  };

  const renderPaymentIcons = (icons) =>
    icons.map((icon, i) => <PaymentIcon key={`footer-payment-icon-${i}`} paymentMethod={icon} />);

  return (
    <FooterToggle headline={language('footer.paymentMethods')} headlineTag={Headline.tags.H6} shouldHide={false}>
      <div height={60} once>
        {paymentModesCodes.length > 0 && (
          <div className={styles.paymentIcons}>{renderPaymentIcons(paymentModesCodes)}</div>
        )}
      </div>
      <small className={styles.description}>
        <Link
          to={language('footer.paymentMethodsExternalLink')}
          target={'_blank'}
          className={styles.paymentMethodsLink}
        >
          {language('footer.paymentMethodsLink')}
        </Link>
      </small>
    </FooterToggle>
  );
};

PaymentServices.displayName = 'molecules/PaymentServices';

PaymentServices.propTypes = {
  footerPaymentModes: PropTypes.object,
  getFooterPaymentModes: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(PaymentServices);
