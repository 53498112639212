export const cypressAttributes = {
  checkout: {
    paymentMethods: 'paymentMethods',
    salutation: 'salutation',
    emailAddress: 'emailAddress',
    invoice: {
      firstName: 'billingAddress-firstName',
      lastName: 'billingAddress-lastName',
      addressLine1: 'billingAddress-addressLine1',
      postalCode: 'billingAddress-postalCode',
      town: 'billingAddress-town',
      phoneNumber: 'billingAddress-phoneNumber',
    },
    btnDifferentShippingAddress: 'btnDifferentShippingAddress',
    delivery: {
      firstName: 'shippingAddress-firstName',
      lastName: 'shippingAddress-lastName',
      addressLine1: 'shippingAddress-addressLine1',
      postalCode: 'shippingAddress-postalCode',
      town: 'shippingAddress-town',
      phoneNumber: 'shippingAddress-phoneNumber',
    },
    birthdayDay: 'birthdayDay',
    birthdayMonth: 'birthdayMonth',
    birthdayYear: 'birthdayYear',
    buttonPickup: 'buttonPickup',
    buttonDelivery: 'buttonDelivery',
    storeFinderOpen: 'storeFinderOpen',
    storeFinderEdit: 'storeFinderEdit',
    storeFinderSearch: 'storeFinderSearch',
    storeFinderSubmit: 'storeFinderSubmit',
    storeFinderPickUpHere: 'storeFinderPickUpHere',
    btnChangeAddress: 'btnChangeAddress',
    btnChooseAddress: 'btnChooseAddress',
    btnOrderNow: 'btnOrderNow',
    voucher: {
      buttonAddVoucher: 'buttonAddVoucher',
      plusSignVoucher: 'plusSignVoucher',
      inputFieldVoucherCode: 'inputFieldVoucherCode',
      buttonRedeemVoucher: 'buttonRedeemVoucher',
      textVoucherValue: 'textVoucherValue',
      textVoucherCode: 'textVoucherCode',
      confirmationMsgVoucher: 'confirmationMsgVoucher',
      checkMarkVoucher: 'checkMarkVoucher',
      invalidVoucherCodeMsg: 'invalidVoucherCodeMsg',
    },
    subtotalPrice: 'subtotalPrice',
    totalPrice: 'totalPrice',
    itemPriceOrderOverview: 'itemPriceOrderOverview',
    discountOrderOverview: 'discountOrderOverview',
    totalPriceOrderOverview: 'totalPriceOrderOverview',
    totalWithVATOrderReview: 'totalWithVATOrderReview',
    chosenPaymentMode: 'chosenPaymentMode',
    changePaymentMode: 'changePaymentMode',
    btnSavePaymentMode: 'btnSavePaymentMode',
    errorVerifyAge: 'errorMessageVerifyAge',
    btnVerifyAge: 'buttonVerifyAge',
    labelAgeVerified: 'labelAgeVerified',
    labelAgeAlreadyVerified: 'labelAgeAlreadyVerified',
    messageAgeNotification: 'messageAgeNotification',
    storeFinderList: 'storeFinderList',
    storeFinderResults: 'storeFinderResults',
  },
  ageVerification: {
    SWISSID: 'verificationTypeID',
    SWISSPASSPORT: 'verificationTypePassport',
    DRIVINGLICENSE: 'verificationTypeDrivingLicense',
    MANUALLY: 'verificationTypeCopyOfID',
    NOCARD: 'verificationTypeNoCard',
    buttonConfirm: 'buttonConfirm',
  },
  additionalServices: {
    addAccessoriesTab: 'addAccessoriesTab',
    addServicesTab: 'addServicesTab',
    btnAddService: 'btnAddService',
    labelAddService: 'labelAddService',
    labelService: 'labelService',
    labelServicePrice: 'labelServicePrice',
  },
  account: {
    accountMenuItem: 'accountMenuItem',
    btnAddNewAddress: 'btnAddNewAddress',
    btnSaveNewAddress: 'btnSaveNewAddress',
    accountAddress: 'accountAddress',
    btnRegister: 'buttonRegister',
    errorUnderage: 'errorMessageUnderage',
    btnSavePersonalData: 'buttonSavePersonalData',
    btnEmailTaken: 'buttonEmailAlreadyExist',
    msgSavePersonalDataSuccessful: 'msgSavePersonalDataSuccessful',
    errorEmailTaken: 'errorMessageEmailTaken',
    myAccountMenuItem: 'myAccountMenuItem',
    superCardNumber: 'superCardNumber',
    errorSuperCardNumber: 'errorSuperCardNumber',
    employeeEan: 'employeeEan',
    errorEmployeeEan: 'errorEmployeeEan',
  },
  cart: {
    addToCartContinue: 'addToCartContinue',
    btnToBoxOffice: 'btnToBoxOffice',
    btnAddToCart: 'btnAddToCart',
    removeProductFromCart: 'removeProductFromCart',
    buttonMinus: 'buttonMinus',
    buttonPlus: 'buttonPlus',
    inputQty: 'inputQty',
    subTotal: 'subtotalPrice',
  },
  data: {
    mobileSearch: 'dataMobileSearch',
    desktopSearch: 'dataDesktopSearch',
  },
  search: {
    searchInput: 'searchInput',
    searchButton: 'searchButton',
    searchProduct: 'searchProduct',
    searchProductImage: 'searchProductImage',
    searchProductPrice: 'searchProductPrice',
    searchOverlay: 'searchOverlay',
    searchSuggestions: 'searchSuggestions',
    clearSearch: 'clearSearch',
  },
  product: {
    productActions: 'productActions',
    productOverview: 'productOverview',
    comparisonHeader: 'productComparisonHeader',
    comparisonHeaderError: 'productComparisonHeaderError',
    comparisonCategoriesDropdown: 'comparisonCategoriesDropdown',
    compareProductsButton: 'compareProductsButton',
  },
  paymentModes: {
    pay_at_pickup: {
      label: 'paymentModePayOnPickup',
      radio: 'radioButtonPayOnPickup',
    },
    mastercard: {
      label: 'paymentModeMastercard',
      radio: 'radioButtonMastercard',
    },
    visa: {
      label: 'paymentModeVisa',
      radio: 'radioButtonVisa',
    },
    pay_in_advance: {
      label: 'paymentModePrepayment',
      radio: 'radioButtonPrepayment',
    },
    twint: {
      label: 'paymentModeTwint',
      radio: 'radioButtonTwint',
    },
    monthlyinvoice_pcf: {
      label: 'paymentModeMonthlyInvoice',
      radio: 'radioButtonMonthlyInvoice',
    },
    americanexpress: {
      label: 'paymentModeAmericanExpress',
      radio: 'radioButtonAmericanExpress',
    },
    postcard: {
      label: 'paymentModePostcard',
      radio: 'radioButtonPostcard',
    },
    postfinance: {
      label: 'paymentModePostfinance',
      radio: 'radioButtonPostfinance',
    },
    paypal: {
      label: 'paymentModePaypal',
      radio: 'radioButtonPaypal',
    },
    paycard: {
      label: 'paymentModePaycard',
      radio: 'radioButtonPaycard',
    },
    samsung_pay: {
      label: 'paymentModeSamsung',
      radio: 'radioButtonSamsung',
    },
  },
  comparisonList: {
    addToComparisonListLink: 'addToComparisonListLink',
    addToComparisonListButtonPDP: 'addToComparisonListButtonPDP',
    addToCartButton: 'comparisonListAddToCartButton',
    arrows: 'comparisonListArrows',
    arrowsIn: 'comparisonListArrowsIn',
    arrowsOut: 'comparisonListArrowsOut',
    flashMessage: 'comparisonListFlashMessage',
    menuItem: 'comparisonListMenuItem',
    badge: 'comparisonListBadge',
    removeFromComparisonListLink: 'removeFromComparisonListLink',
  },
  homepage: {
    categories: 'homepageCategories',
  },
  subcategories: {
    desktop: 'subcategoriesDesktop',
    mobile: 'subcategoriesMobile',
  },
  mobileNavigation: {
    hamburgerIcon: 'mobileNavigationHamburger',
    drilldownNavigation: 'mobileNavigationDrilldown',
    categoryDrilldown: 'mobileNavigationCategoryDrilldown',
    accountDrilldown: 'mobileNavigationAccountDrilldown',
    navigationSection: 'mobileNavigationSection',
    navigationElement: 'mobileNavigationElement',
    navigationCategory: 'mobileNavigationCategory',
    watchlist: 'mobileNavigationWatchlist',
  },
  loadingSpinner: 'loadingSpinner',
};
