import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import LazyLoad from 'react-lazyload';
import { getEnv } from 'config/config';
import InternalOrExternalLink from 'components/atoms/InternalOrExternalLink/InternalOrExternalLink';
import dataTracking from 'constants/trackingAttributes/dataTracking';
import styles from './Card.scss';

// get colors from scss variables ($trend-colors)
const bgColors = styles.trendColorsKeys.split(', ').reduce((accumulator, currentValue) => {
  const color = currentValue === 'DEFAULT' ? 'GRAY' : currentValue;
  accumulator[color] = color;
  return accumulator;
}, {});

const SKINS = {
  ALL: 'ALL',
  MINI: 'MINI',
  HOME: 'HOME',
};

const Card = ({ card, skin, trackingAttributes }) => {
  const { bgColor, imageAltText, intro, link, picture, subtitle, text, title } = card;

  // get first image because there will be only one image
  const image = picture.sizes[0];

  // get GRAY for DEFAULT color because backend can't send GRAY as default
  const color = bgColor === 'DEFAULT' ? 'GRAY' : bgColor;

  // get content with different styling classNames to cover all display cases
  const content = () => {
    if (title && subtitle && text) {
      return (
        <div className={cx(styles.contentWrap, styles.contentAll)}>
          <div className={styles.titles}>
            <span className={cx(styles.title, styles.wordSpace)}>
              {title}
              <span className={styles.subtitle}>{subtitle}</span>
            </span>
          </div>
          <span className={styles.text}>{text}</span>
        </div>
      );
    }
    if (title && subtitle) {
      return (
        <div className={styles.contentWrap}>
          <span className={cx(styles.title, styles.wordSpace)}>{title}</span>
          <span className={styles.subtitle}>{subtitle}</span>
        </div>
      );
    }
    if (title && text) {
      return (
        <div className={styles.contentWrap}>
          <span className={cx(styles.title, styles.wordSpace)}>{title}</span>
          <span className={styles.text}>{text}</span>
        </div>
      );
    }
    if (subtitle && text) {
      return (
        <div className={styles.contentWrap}>
          <span className={cx(styles.subtitle, styles.wordSpace)}>{subtitle}</span>
          <span className={styles.text}>{text}</span>
        </div>
      );
    }
    if (!(title && subtitle && text)) {
      return (
        <div className={cx(styles.contentWrap, styles.contentSingle)}>
          {title && (
            <span className={styles.title}>
              {title}
              {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
            </span>
          )}
          {text && <span className={styles.text}>{text}</span>}
        </div>
      );
    }
    return null;
  };

  return (
    <InternalOrExternalLink
      href={link}
      className={cx(styles.card, styles[color], styles[skin])}
      dataTracking={dataTracking({
        TYPE: 'cardbrick',
        LINK: link,
        INTERACTION: 'click',
        INNERLOCATION: trackingAttributes?.index + 1,
        ATTRIBUTE_1: title?.replace(/\s|:/g, ''),
        ATTRIBUTE_2: subtitle?.replace(/\s|:/g, ''),
        ATTRIBUTE_3: text?.replace(/\s|:/g, ''),
        FUNCTION: 'go-to',
      })}
    >
      <div className={styles.intro}>
        {intro.logo ? (
          <LazyLoad once>
            <img src={`${getEnv('api')}${intro.logo}`} alt={intro.logoAltText} />
          </LazyLoad>
        ) : (
          <span className={styles.introText}>{intro.text}</span>
        )}
      </div>
      <div className={styles.image}>
        {image?.url && (
          <LazyLoad once>
            <img src={`${getEnv('api')}${image.url}`} alt={imageAltText} />
          </LazyLoad>
        )}
      </div>
      <div className={styles.content}>{content()}</div>
    </InternalOrExternalLink>
  );
};

Card.COLORS = bgColors;
Card.SKINS = SKINS;

Card.displayName = 'molecules/Card';
Card.propTypes = {
  card: PropTypes.shape({
    // allow DEFAULT because backend can't send GRAY as default
    bgColor: PropTypes.oneOf(['DEFAULT', ...Object.values(bgColors)]),
    imageAltText: PropTypes.string,
    intro: PropTypes.shape({
      logo: PropTypes.string,
      logoAltText: PropTypes.string,
      text: PropTypes.string,
    }),
    link: PropTypes.string,
    picture: PropTypes.shape({
      sizes: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string,
        })
      ),
    }),
    subtitle: PropTypes.string,
    text: PropTypes.string,
    title: PropTypes.string,
  }),
  skin: PropTypes.string,
  trackingAttributes: PropTypes.object,
};
Card.defaultProps = {
  card: {
    bgColor: bgColors.GRAY,
    imageAltText: '',
    intro: {
      logo: '',
      logoAltText: '',
      text: '',
    },
    link: '',
    picture: {
      sizes: [],
    },
    subtitle: '',
    text: '',
    title: '',
  },
  skin: SKINS.ALL,
  trackingAttributes: {},
};

export default Card;
