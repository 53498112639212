// React 16 depends on the collection types Map and Set. Older browsers and devices
// which may not yet provide these natively (e.g. IE < 11) or which have non-compliant
// implementations (e.g. IE 11)
// https://reactjs.org/docs/javascript-environment-requirements.html
import 'core-js/features/symbol';
import 'core-js/features/map';
import 'core-js/features/set';

// Polyfill for :focus-visible - (2/3)
// The :focus-visible pseudo-class applies while an element matches the :focus pseudo-class and the
// user agent determines via heuristics that the focus should be made evident on the element.
// Source: https://drafts.csswg.org/selectors-4/#the-focus-visible-pseudo
import 'focus-visible';
import 'regenerator-runtime/runtime';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { browserHistory, Router } from 'react-router';
import debounce from 'lodash/debounce';
import { syncHistoryWithStore } from 'react-router-redux';

import makeRoutes from './routes/clientRoutes';
import configureStore from './reducers/configureStore';
import clientBootstrap from 'api/clientBootstrap/clientBootstrap';
import tracking from 'tracking/tracking';
import env from 'constants/environment/environment';
import { setLocaleFromUrl, dynamicallyImportLanguage, getLocale } from 'constants/language/language';
import { setUserAgent } from 'constants/userAgent/userAgent';
import { saveBreakpoint } from 'actions/uiActions/uiActions';

setLocaleFromUrl(window.location.pathname);
setUserAgent(window.navigator.userAgent);
dynamicallyImportLanguage(getLocale()).then(() => {
  const initialState = window.__INITIAL_STATE__;
  const store = configureStore(initialState, browserHistory);
  const history = syncHistoryWithStore(browserHistory, store);

  const routes = makeRoutes(store);

  const renderPage = () => {
    // Render the React application to the DOM
    if (performance.mark) performance.mark('clientRender');
    const renderMethod = __DEV__ ? ReactDOM.render : ReactDOM.hydrate;
    renderMethod(
      <Provider store={store}>
        <Router history={history}>{routes}</Router>
      </Provider>,
      document.getElementById(env.REACT_ROOT_ID)
    );
    if (performance.mark) performance.mark('clientRenderEnd');
  };

  if (performance.mark) performance.mark('clientStart');

  clientBootstrap(store.dispatch)
    .then(renderPage)
    .then(() => {
      const values = ['payment/success', 'newsletter'];
      if (values.some((element) => window.location.pathname.includes(element))) {
        Object.defineProperty(document, 'referrer', { get: () => '/' });
      }

      window.addEventListener(
        'resize',
        debounce(() => store.dispatch(saveBreakpoint()), 300)
      );
      tracking.initialize();
    });
});
