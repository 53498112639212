import { handleActions } from 'redux-actions';

import { SET_INITIAL_CATEGORIES, GET_CATEGORIES_SUCCESS } from 'constants/ActionTypes/ActionTypes';

import CategoryAPI from 'api/CategoryAPI/CategoryAPI';

export const initialState = {};

export default handleActions(
  {
    [SET_INITIAL_CATEGORIES]: () => {
      const categories = CategoryAPI.provideInitialState();
      return {
        ...categories.categories,
      };
    },

    [GET_CATEGORIES_SUCCESS]: (state, action) => {
      const categories = action.req.data || {};
      return {
        ...categories,
      };
    },
  },
  initialState
);
