import { BLUR_CONTACT_FIELD, SEND_CONTACT_SUCCESS, CONTACT_VALIDATION_ERROR } from 'constants/ActionTypes/ActionTypes';

import transform from 'constants/reducerHelper/reducerHelper';
import validation from 'constants/validation/validation';

import formFieldConfigurations from './contact.config';

export const expandFormFields = (formFieldConfigs = {}) => {
  const newMappedFields = {};
  Object.keys(formFieldConfigs).forEach((field) => {
    const fieldValue = formFieldConfigs[field];
    newMappedFields[field] = {
      name: field,
      required: fieldValue.required,
      value: fieldValue.value,
      blurred: false,
    };
  });
  return newMappedFields;
};

export const validateFormField = (field) => {
  const fieldName = field.name;
  const fieldValue = field.value;
  const fieldRequired = field.required;
  const validationFunction = formFieldConfigurations?.[fieldName]?.validation;

  const newField = transform(field).set('validationResult', true);

  if (fieldRequired) {
    // required fields must not be empty
    newField.set('validationResult', validation.required(fieldValue));
  }

  if (typeof validationFunction === 'function') {
    if ((!fieldRequired && fieldValue) || (fieldRequired && fieldValue)) {
      newField.set('validationResult', validationFunction(fieldValue)); // is this a valid value?
    }
  }

  return newField.set('value', fieldValue).set('blurred', false).value();
};

export const validateContact = (form) => {
  const newForm = transform(form);

  Object.entries(newForm.value()).forEach(([fieldName, field]) => {
    newForm.set(fieldName, validateFormField(field));
  });

  const error = Object.values(newForm.value()).find((field) => field.validationResult !== true) || {};

  return newForm.set('firstError', error).set('valid', !Object.keys(error).length).value();
};

export const initialState = {
  fields: expandFormFields(formFieldConfigurations),
  hasFetchedSubjects: false,
};

const reducerActions = {
  [BLUR_CONTACT_FIELD]: (state, action) => {
    const fieldName = action.payload?.fieldName;
    if (!fieldName) return state;

    const validated = validateFormField(state?.fields?.[fieldName]);
    return transform(state).set(`fields.${fieldName}`, validated).set(`fields.${fieldName}.blurred`, true).value();
  },

  [CONTACT_VALIDATION_ERROR]: (state) => transform(state).set('fields', validateContact(state.fields)).value(),

  [SEND_CONTACT_SUCCESS]: (state) => {
    const newState = transform(initialState).set('sent', true);

    const contactSubjects = state?.fields?.contactSubjects?.options;
    if (contactSubjects) {
      newState.set('fields.contactSubjects.options', contactSubjects);
    }

    return newState.value();
  },
};

export default reducerActions;
