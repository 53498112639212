import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import styles from './Indicator.scss';

export const mapStateToProps = (state) => {
  const productComparisonList = state?.comparison?.categories || {};

  let productCount = 0;
  Object.values(productComparisonList).forEach((category) => {
    productCount += category.length;
  });

  return {
    productCount,
  };
};

const ProductComparisonIndicator = ({ productCount, cyData }) => {
  if (productCount <= 0) return null;

  return (
    <small className={styles.indicator} data-cy={cyData ?? 'product-comparison-indicator'}>
      {productCount}
    </small>
  );
};

ProductComparisonIndicator.displayName = 'containers/Indications/ProductComparisonIndicator';
ProductComparisonIndicator.propTypes = {
  productCount: PropTypes.number,
  cyData: PropTypes.string,
};
ProductComparisonIndicator.defaultProps = {
  productCount: 0,
};

export default connect(mapStateToProps)(ProductComparisonIndicator);
