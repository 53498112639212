import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Recaptcha from 'containers/Recaptcha/Recaptcha';
import OptInOrganism from 'components/organisms/OptIn/OptIn';
import Headline from 'components/atoms/Headline/Headline';
import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';
import MarkdownBox from 'components/molecules/MarkdownBox/MarkdownBox';
import language from 'constants/language/language';
import { recaptchaAreas } from 'constants/recaptcha/recaptchaAreas';
import dataTracking from 'constants/trackingAttributes/dataTracking';
import styles from './NotConfirmedOptIn.scss';
import Spinner, { SPINNER_STATES } from 'components/atoms/Spinner/Spinner';

const NotConfirmedOptIn = ({
  type = OptInOrganism.TYPES.REGISTRATION,
  email = '',
  contactLink = '',
  resendMail = () => {},
  recaptchaNeeded = false,
}) => {
  const [resendState, setResendState] = useState('');

  const getText = (i10n) => {
    const text = language(i10n);
    if (typeof text !== 'string') {
      return text;
    }
    const escapedEmail = email.replace(/_/g, '\\_');
    return text.replace(/{EMAIL}/g, escapedEmail).replace(/{CONTACT_LINK}/g, contactLink);
  };
  return (
    <div className={styles.wrapper}>
      <Headline tag={Headline?.tags.H1}>{language(`optIn.types.${type}.notConfirmed.headline`)}</Headline>
      <div className={styles.markdownWrapper}>
        <MarkdownBox markdown={getText(`optIn.types.${type}.notConfirmed.text`)} />
      </div>
      {recaptchaNeeded && (
        <div className={styles.recaptcha}>
          <Recaptcha userId={email} captchaArea={recaptchaAreas.resendDoiMail} />
        </div>
      )}
      <div className={styles.actionWrapper}>
        <ButtonLink
          onClick={() => {
            setResendState(SPINNER_STATES.REQUEST);
            resendMail(email).then((res) => {
              setTimeout(() => setResendState(res ? SPINNER_STATES.SUCCESS : SPINNER_STATES.FAILURE), 500);
            });
          }}
          disabled={resendState === SPINNER_STATES.REQUEST || recaptchaNeeded}
          data-tracking={dataTracking({
            TYPE: 'form:button',
            FUNCTION: 'apply',
            ATTRIBUTE_1: 'secondary',
            ATTRIBUTE_2: language(`optIn.types.${type}.notConfirmed.cta`),
            ATTRIBUTE_3: 'confirmation',
          })}
        >
          {language(`optIn.types.${type}.notConfirmed.cta`)}
        </ButtonLink>
        {resendState !== '' && (
          <div className={styles.successMessage}>
            <Spinner status={resendState} height={40} />
            {language(`optIn.responses.${resendState}`)}
          </div>
        )}
      </div>
      <div className={styles.markdownWrapper}>
        <MarkdownBox markdown={getText(`optIn.types.${type}.notConfirmed.textAfterCta`)} />
      </div>
    </div>
  );
};

NotConfirmedOptIn.displayName = 'organisms/NotConfirmedOptIn';
NotConfirmedOptIn.propTypes = {
  type: PropTypes.oneOf(Object.values(OptInOrganism.TYPES)),
  email: PropTypes.string,
  contactLink: PropTypes.string,
  resendMail: PropTypes.func,
  recaptchaNeeded: PropTypes.bool,
};

export default NotConfirmedOptIn;
