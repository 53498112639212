import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import StoreFinderSearch from 'components/organisms/StoreFinderSearch/StoreFinderSearch';
import StoreFinderList from 'components/organisms/StoreFinderList/StoreFinderList';
import StoreFinderMap from 'components/organisms/StoreFinderMap/StoreFinderMap';
import StoreFinderResultsTitle from 'components/molecules/StoreFinderResultsTitle/StoreFinderResultsTitle';
import viewTypes from 'constants/storeFinderResultsViewTypes/storeFinderResultsViewTypes';
import { scrollToRef } from 'constants/scrollToElement/scrollToElement';

import bootstrap from 'scss/component.scss';
import styles from './StoreFinderTemplate.scss';

export class StoreFinderTemplate extends Component {
  static propTypes = {
    submitSearch: PropTypes.func,
    searchStoreByLocation: PropTypes.func,
    changeShowOnlyOpenStores: PropTypes.func,
    changeShowOnlyShowrooms: PropTypes.func,
    searchStoreByLocationError: PropTypes.string,
    searchTerm: PropTypes.string,
    showOnlyOpenStores: PropTypes.bool,
    showOnlyShowrooms: PropTypes.bool,
    stores: PropTypes.array,
    selectPickupStore: PropTypes.func,
    totalStores: PropTypes.number,
    loadMoreStores: PropTypes.func,
    locality: PropTypes.string,
    geoLocation: PropTypes.object,
    isStandalone: PropTypes.bool,
    breakpoint: PropTypes.string,
    myStoreId: PropTypes.string,
    saveAsMyStore: PropTypes.func,
    isLoggedIn: PropTypes.bool,
    cartHasBulkyGood: PropTypes.bool,
    showDistance: PropTypes.bool,
    flashMessage: PropTypes.object,
  };

  static defaultProps = {
    submitSearch: () => {},
    searchStoreByLocation: () => {},
    changeShowOnlyOpenStores: () => {},
    changeShowOnlyShowrooms: () => {},
    searchStoreByLocationError: '',
    searchTerm: '',
    showOnlyOpenStores: false,
    showOnlyShowrooms: false,
    stores: [],
    selectPickupStore: () => {},
    totalStores: 0,
    loadMoreStores: () => {},
    locality: '',
    geoLocation: {},
    isStandalone: false,
    myStoreId: '',
    saveAsMyStore: () => {},
    isLoggedIn: false,
    flashMessage: {},
  };

  constructor(props) {
    super(props);
    this.switchedStorePosition = null;
    this.storeFinderContainerRef = React.createRef();
    this.state = {
      activeView: viewTypes.LIST,
    };
  }

  switchView = (activeView, storeName = '') => {
    if (Object.values(viewTypes).includes(activeView)) {
      this.switchedStorePosition = null;
      // if user selected specific store
      if (storeName) {
        // find store position in array
        const position = this.props.stores.findIndex((elem) => elem.name === storeName);
        if (position > -1) {
          this.switchedStorePosition = position;
        }
      }

      // switch to map
      this.setState({ activeView }, () => scrollToRef(this.storeFinderContainerRef));
    }
  };

  render() {
    const {
      submitSearch,
      searchStoreByLocation,
      changeShowOnlyOpenStores,
      changeShowOnlyShowrooms,
      searchStoreByLocationError,
      searchTerm,
      showOnlyOpenStores,
      showOnlyShowrooms,
      stores,
      selectPickupStore,
      totalStores,
      loadMoreStores,
      locality,
      geoLocation,
      isStandalone,
      breakpoint,
      myStoreId,
      saveAsMyStore,
      isLoggedIn,
      cartHasBulkyGood,
      showDistance,
      flashMessage,
    } = this.props;

    const storesList = stores.length ? Object.values(stores) : [];

    return (
      <div>
        <div
          className={cx(styles.wrapper, {
            [styles.wrapperStandalone]: isStandalone,
          })}
        >
          <StoreFinderSearch
            submitSearch={submitSearch}
            searchStoreByLocation={searchStoreByLocation}
            searchStoreByLocationError={searchStoreByLocationError}
            changeShowOnlyOpenStores={changeShowOnlyOpenStores}
            changeShowOnlyShowrooms={changeShowOnlyShowrooms}
            showOnlyOpenStores={showOnlyOpenStores}
            showOnlyShowrooms={showOnlyShowrooms}
            searchTerm={searchTerm}
            isStandalone={isStandalone}
            breakpoint={breakpoint}
            cartHasBulkyGood={cartHasBulkyGood}
            flashMessage={flashMessage}
          />
        </div>

        <div
          ref={this.storeFinderContainerRef}
          className={cx({
            [styles.standalone]: isStandalone,
          })}
        >
          <div className={cx({ [bootstrap.row]: isStandalone })}>
            <div className={cx({ [bootstrap.col12]: isStandalone })}>
              {!!storesList.length && (
                <section>
                  <StoreFinderResultsTitle
                    locality={locality}
                    activeView={this.state.activeView}
                    switchView={this.switchView}
                  />
                  {this.state.activeView === viewTypes.LIST ? (
                    <StoreFinderList
                      stores={storesList}
                      selectPickupStore={selectPickupStore}
                      totalStores={totalStores}
                      loadMoreStores={loadMoreStores}
                      selectPickupStoreEnabled={!isStandalone}
                      switchView={this.switchView}
                      myStoreId={myStoreId}
                      saveAsMyStore={saveAsMyStore}
                      isLoggedIn={isLoggedIn}
                      isStandalone={isStandalone}
                      showDistance={showDistance}
                    />
                  ) : (
                    <StoreFinderMap
                      stores={storesList}
                      selectPickupStore={selectPickupStore}
                      selectPickupStoreEnabled={!isStandalone}
                      locality={locality}
                      geoLocation={geoLocation}
                      switchedStorePosition={this.switchedStorePosition}
                    />
                  )}
                </section>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default StoreFinderTemplate;
