import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from 'components/molecules/Pagination/Pagination.scss';

const CmsPaginationLink = ({ children, className = '', page, changePage }) => (
  <li className={cx(styles.pageItem, className)}>
    <button
      type="button"
      className={styles.pageLink}
      onMouseDown={() => {
        changePage(page);
      }}
    >
      {children}
    </button>
  </li>
);

CmsPaginationLink.displayName = 'atoms/CmsPaginationLink';
CmsPaginationLink.propTypes = {
  changePage: PropTypes.func.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  page: PropTypes.number.isRequired,
};

export default CmsPaginationLink;
