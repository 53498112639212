import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import styles from './Indicator.scss';

export const mapStateToProps = (state) => ({
  watchlistEntries: (state?.watchlist?.entries || []).length,
});

const WatchlistIndicator = ({ watchlistEntries }) => {
  if (watchlistEntries <= 0) return null;

  return <small className={styles.indicator}>{watchlistEntries}</small>;
};

WatchlistIndicator.displayName = 'containers/Indications/WatchlistIndicator';
WatchlistIndicator.propTypes = {
  watchlistEntries: PropTypes.number,
};
WatchlistIndicator.defaultProps = {
  watchlistEntries: 0,
};

export default connect(mapStateToProps)(WatchlistIndicator);
