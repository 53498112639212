import React from 'react';
import PropTypes from 'prop-types';
import { recoTrackingEndpoints as recoTypes } from 'api/RecoAPI/RecoAPI';
import ProductRecoContainer from 'containers/ProductRecommendations/ProductRecommendations';
import ProductRecoSlider from 'components/organisms/ProductRecoSlider/ProductRecoSlider';
import Headline from 'components/atoms/Headline/Headline';
import language from 'constants/language/language';
import translations from 'constants/language/german';
import styles from './BrandProductsNotFound.scss';

const BrandProductsNotFound = ({ brand }) => (
  <>
    <div className={styles.row}>
      <Headline tag={Headline.tags.H1}>{language('brandProductsNotFound.title').replace('{BRAND}', brand)}</Headline>
      <div className={styles.infoText}>{language('brandProductsNotFound.info')}</div>
    </div>
    <ProductRecoContainer type={recoTypes.EMPTY_BRAND}>
      <ProductRecoSlider
        trackingAttributes={{ prefix: 'productbox', parentTitle: translations.recommendations.topProducts }}
      />
    </ProductRecoContainer>
  </>
);

BrandProductsNotFound.displayName = 'templates/BrandProductsNotFound';
BrandProductsNotFound.propTypes = {
  brand: PropTypes.string.isRequired,
};

export default BrandProductsNotFound;
