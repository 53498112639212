import React from 'react';
import { string, oneOf } from 'prop-types';
import LazyLoad from 'react-lazyload';
import cx from 'classnames';
import { ProductViewTypes } from 'constants/ProductViewTypes/ProductViewTypes';
import { Container, Row } from 'components/atoms/Grid/Grid';
import Headline, { tags, skins } from 'components/atoms/Headline/Headline';
import styles from './BrandTeaser.scss';

const BrandTeaser = ({ title, text, logoSrc, logoAlt, viewType }) => (
  <Container>
    <Row>
      <section
        className={cx(styles.root, {
          [styles.gridView]: viewType === ProductViewTypes.GRID,
          [styles.listView]: viewType === ProductViewTypes.LIST,
        })}
      >
        <div className={styles.content}>
          <Headline tag={tags.DIV} skin={skins.brandname} margin="mb-0">
            {title}
          </Headline>
          <div className={styles.text} dangerouslySetInnerHTML={{ __html: text }} />
        </div>
        <LazyLoad once height="50">
          <img src={logoSrc} height="50" alt={logoAlt} className={styles.logo} />
        </LazyLoad>
      </section>
    </Row>
  </Container>
);

BrandTeaser.displayName = 'molecules/BrandTeaser';

BrandTeaser.propTypes = {
  title: string,
  text: string,
  logoSrc: string,
  logoAlt: string,
  viewType: oneOf(Object.values(ProductViewTypes)),
};

export default BrandTeaser;
