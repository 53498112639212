export const ORDER_DOCUMENT_TYPES = {
  BOTH: 'BOTH',
  ORDER: 'ORDER',
  GUARANTEE: 'GUARANTEE',
};

export const getOrderDocumentType = (receipt = true, warranty = true) => {
  if (receipt && !warranty) {
    return ORDER_DOCUMENT_TYPES.ORDER;
  }

  if (!receipt && warranty) {
    return ORDER_DOCUMENT_TYPES.GUARANTEE;
  }

  return ORDER_DOCUMENT_TYPES.BOTH;
};
