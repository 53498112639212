import React from 'react';
import PropTypes from 'prop-types';

import FlagBox from 'components/atoms/FlagBox/FlagBox';

import language from 'constants/language/language';

import { pegiValues as pegiValueConstants } from 'constants/ProductStatus/ProductStatus';

export default class PegiFlag extends React.Component {
  static displayName = 'atoms/PegiFlag';

  static propTypes = {
    value: PropTypes.string.isRequired,
    toolTip: PropTypes.string,
  };

  static shouldShowPegi = (pegiValue) =>
    pegiValue && pegiValueConstants[pegiValue] && pegiValue !== pegiValueConstants.noPegi;

  getText(pegiValue) {
    if (PegiFlag.shouldShowPegi(pegiValue)) {
      return `${language('product.pegiAbove')} ${pegiValueConstants[pegiValue]}`;
    }
    return undefined;
  }

  render() {
    const { value, toolTip } = this.props;

    return (
      PegiFlag.shouldShowPegi(value) && (
        <FlagBox type={FlagBox.types.PEGI} text={this.getText(value)} toolTip={toolTip} />
      )
    );
  }
}
