import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import language from 'constants/language/language';
import LogoStore from 'components/atoms/LogoStore/LogoStore';
import Headline from 'components/atoms/Headline/Headline';
import IconMyStore from 'components/atoms/icons/IconMyStore/IconMyStore';
import styles from './AddressStore.scss';
import { cypressAttributes } from 'constants/cypress/cypress';

const SKINS = {
  DEFAULT: '',
  LOGO_RIGHT: 'logoRight',
};

const AddressStore = ({
  isMyStore = false,
  location = '',
  logoCode = '',
  name = '',
  postalCode = '',
  skin = SKINS.DEFAULT,
  streetName = '',
  streetNumber = '',
  type = '',
}) => (
  <div className={cx({ [styles[skin]]: skin })}>
    {logoCode && (
      <div className={styles.logo}>
        <LogoStore code={logoCode} />
      </div>
    )}
    <div data-cy={cypressAttributes.checkout.storeFinderResults}>
      <div className={styles.heading}>
        <Headline tag={Headline.tags.H4} margin={Headline.margins.NONE}>
          {type && language(`pickupStore.type.${type}`) && (
            <>
              {language(`pickupStore.type.${type}`)}
              <br />
            </>
          )}
          {name}
          {isMyStore && (
            <div className={styles.labelMyStore}>
              <IconMyStore height={14} />
            </div>
          )}
        </Headline>
      </div>
      <div>
        {streetName} {streetNumber}
      </div>
      <div>
        {postalCode} {location}
      </div>
    </div>
  </div>
);

AddressStore.SKINS = SKINS;

AddressStore.displayName = 'atoms/AddressStore';
AddressStore.propTypes = {
  isMyStore: PropTypes.bool,
  location: PropTypes.string,
  logoCode: PropTypes.string,
  name: PropTypes.string,
  postalCode: PropTypes.string,
  skin: PropTypes.oneOf(Object.values(SKINS)),
  streetName: PropTypes.string,
  streetNumber: PropTypes.string,
  type: PropTypes.string,
};

export default AddressStore;
