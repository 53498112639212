// @flow
import React, { type Node } from 'react';
import cx from 'classnames';
import Icon, { ICON_STAR, ICON_STAR_BORDER, ICON_STAR_HALF } from 'components/atoms/Icon/Icon';
import { noop } from 'constants/noop/noop';
import styles from './StarRating.scss';

export const SIZES = {
  extraSmall: 'extraSmall',
  small: 'small',
  medium: 'medium',
  large: 'large',
};

export const THEMES = {
  primary: 'primary',
  secondary: 'secondary',
};

const starHeights = {
  [SIZES.small]: Icon.sizes.xs,
  [SIZES.medium]: Icon.sizes.sm,
  [SIZES.large]: Icon.sizes.md,
};

type IProps = {
  value: number,
  maxValue: number,
  reviewCount: number,
  showReviewCount: boolean,
  editable: boolean,
  disabled: boolean,
  showError?: boolean,
  onChange: (rating: number) => void,
  size: $Keys<typeof SIZES>,
  theme: $Keys<typeof THEMES>,
};

const renderFullStar = ({
  key,
  disabled,
  size,
  theme,
  checked,
}: {
  key: string,
  disabled: boolean,
  size: $Keys<typeof SIZES>,
  theme: $Keys<typeof THEMES>,
  checked: boolean,
}): Node =>
  checked ? (
    <Icon
      key={key}
      path={ICON_STAR}
      size={starHeights[size]}
      className={cx(
        styles.Star,
        styles.StarChecked,
        disabled && styles.StarDisabled,
        theme === THEMES.secondary && styles.secondary
      )}
    />
  ) : (
    <Icon
      key={key}
      path={ICON_STAR_BORDER}
      size={starHeights[size]}
      className={cx(
        styles.Star,
        styles.StarUnchecked,
        disabled && styles.StarDisabled,
        theme === THEMES.secondary && styles.secondary
      )}
    />
  );

const renderFractionalStar = ({
  fraction,
  value,
  disabled,
  size,
  theme,
}: {
  fraction: number,
  value: number,
  disabled: boolean,
  size: $Keys<typeof SIZES>,
  theme: $Keys<typeof THEMES>,
}): Node => {
  if (fraction >= 0.25 && fraction < 0.75) {
    return (
      <Icon
        key={`${Math.ceil(value)} Star`}
        path={ICON_STAR_HALF}
        size={starHeights[size]}
        className={cx(
          styles.Star,
          styles.StarHalf,
          disabled && styles.StarDisabled,
          theme === THEMES.secondary && styles.secondary
        )}
      />
    );
  }
  return renderFullStar({
    key: `${Math.ceil(value)} Star`,
    value,
    disabled,
    theme,
    size,
    checked: fraction >= 0.75,
  });
};

const StarRating = ({
  value,
  maxValue,
  reviewCount,
  showReviewCount,
  editable,
  disabled,
  showError,
  size,
  theme,
  onChange,
}: IProps) => {
  if (editable) {
    return (
      <div className={cx(styles.StarRating, styles.StarRatingInteractive, showError && styles.StarRatingInvalid)}>
        {Array.from({ length: maxValue }, (_, i) => (
          <div key={`${i + 1} Star`} className={styles.StarInteractiveWrapper} onClick={() => onChange(i + 1)}>
            {value > i ? (
              <Icon
                path={ICON_STAR}
                size={starHeights[size]}
                className={cx(
                  styles.StarChecked,
                  styles.StarInteractive,
                  theme === THEMES.secondary && styles.secondary
                )}
              />
            ) : (
              <Icon
                path={ICON_STAR_BORDER}
                size={starHeights[size]}
                className={cx(
                  styles.StarUnchecked,
                  styles.StarInteractive,
                  theme === THEMES.secondary && styles.secondary
                )}
              />
            )}
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className={cx(styles.StarRating, showError && styles.StarRatingInvalid)}>
      {[
        ...Array.from({ length: Math.floor(value) }, (_, i) =>
          renderFullStar({
            key: `${i + 1} Star`,
            value,
            disabled,
            size,
            theme,
            checked: true,
          })
        ),
        value % 1 !== 0 &&
          renderFractionalStar({
            // round two decimals
            fraction: Math.round((value % 1) * 100) / 100,
            value,
            disabled,
            size,
            theme,
          }),
        ...Array.from({ length: maxValue - Math.ceil(value) }, (_, i) =>
          renderFullStar({
            key: `${Math.ceil(value) + i + 1} Star`,
            value,
            disabled,
            size,
            theme,
            checked: false,
          })
        ),
      ]}
      {!!reviewCount && showReviewCount && <span className={styles.reviewCount}>{reviewCount}</span>}
    </div>
  );
};

StarRating.defaultProps = {
  maxValue: 5,
  editable: false,
  disabled: false,
  size: SIZES.small,
  theme: THEMES.primary,
  onChange: noop,
  showReviewCount: false,
};

export default StarRating;
