import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Icon, { ICON_KEYBOARD_ARROW_RIGHT } from 'components/atoms/Icon/Icon';
import styles from 'components/atoms/MobileNavigationItem/MobileNavigationItem.scss';
import InternalOrExternalLink from 'components/atoms/InternalOrExternalLink/InternalOrExternalLink';
import { NAVIGATION_SUB } from 'constants/trackingAttributes/trackingAttributes';
import { trackMobileNavigationSelect } from 'tracking/tracking';

const MobileNavigationItem = ({
  children,
  className,
  disabled,
  hidden,
  showIndicator,
  indicatorIcon,
  onClick,
  href,
  id,
  dataCy,
  handleAnimation,
  highlightedItem,
  tracking,
  external,
  featureName,
}) => {
  if (href) {
    if (external) {
      return (
        <a
          data-cy={dataCy}
          href={href}
          className={cx(styles.navigationItem, { [className]: !!className })}
          data-tracking={tracking}
        >
          {children}
        </a>
      );
    }
    return (
      <InternalOrExternalLink
        dataCy={dataCy}
        href={href}
        className={cx(styles.navigationItem, { [className]: !!className })}
        tracking={tracking}
        featureName={featureName}
      >
        {children}
      </InternalOrExternalLink>
    );
  }

  const handleClick = () => {
    if (tracking) trackMobileNavigationSelect(NAVIGATION_SUB, tracking.position, tracking.title, tracking.parentTitle);
    onClick();
    if (handleAnimation) handleAnimation();
  };

  return (
    <button
      data-cy={dataCy}
      className={cx(
        styles.navigationItem,
        { [className]: !!className },
        highlightedItem && highlightedItem === id ? styles.navigationItem__highlighted : ''
      )}
      onClick={handleClick}
      disabled={disabled}
      hidden={hidden}
    >
      {children}
      <div className={styles.itemIcon}>{showIndicator && <Icon path={indicatorIcon} size={Icon.sizes.sm} />}</div>
    </button>
  );
};

export default MobileNavigationItem;

MobileNavigationItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  showIndicator: PropTypes.bool,
  indicatorIcon: PropTypes.string,
  onClick: PropTypes.func,
  href: PropTypes.string,
  dataCy: PropTypes.string,
  handleAnimation: PropTypes.func,
  highlightedItem: PropTypes.string,
  id: PropTypes.string,
  tracking: PropTypes.object,
  external: PropTypes.bool,
  featureName: PropTypes.string,
};

MobileNavigationItem.defaultProps = {
  children: {},
  className: '',
  disabled: false,
  showIndicator: false,
  indicatorIcon: ICON_KEYBOARD_ARROW_RIGHT,
  onClick: () => {},
  dataCy: '',
  featureName: null,
};
