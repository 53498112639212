import React from 'react';
import { Route } from 'react-router';
import { PRODUCT_COMPARISON } from 'constants/routePaths/routePaths';

import DefaultLayout from 'components/layouts/DefaultLayout/DefaultLayout';
import ProductComparison from 'routes/ProductComparison/ProductComparison';

export default function createProductComparisonRoutes() {
  return (
    <Route>
      <Route component={DefaultLayout}>
        <Route path={PRODUCT_COMPARISON} component={ProductComparison} />
      </Route>
    </Route>
  );
}
