import React from 'react';
import Breadcrumb, { types } from 'components/organisms/Breadcrumb/Breadcrumb';
import SearchTips from 'components/molecules/SearchTips/SearchTips';
import { Row, Col } from 'components/atoms/Grid/Grid';
import ProductsOverviewHeadline from 'components/atoms/ProductsOverviewHeadline/ProductsOverviewHeadline';
import styles from './EmptyProductSearch.scss';

const EmptyProductSearch = () => (
  <>
    <Breadcrumb type={types.search} />
    <Row className={styles.ProductsOverview}>
      <Col lg={9} offsetLg={3} className={styles.ProductsListContainer}>
        <ProductsOverviewHeadline totalNumberOfResults={0} />
        <SearchTips />
      </Col>
    </Row>
  </>
);

EmptyProductSearch.displayName = 'routes/EmptyProductSearch';

export default EmptyProductSearch;
