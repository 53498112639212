import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import debounce from 'constants/helper/debounce';
import Icon, { ICON_CLOSE } from 'components/atoms/Icon/Icon';
import { formatHighComma } from 'constants/number/number';
import { number } from 'constants/validation/validation';
import dataTracking from 'constants/trackingAttributes/dataTracking';
import isTouchDevice from 'constants/isTouchDevice/isTouchDevice';
import { getEnumValues } from 'constants/GetEnumValues/GetEnumValues';
import { noop } from 'constants/noop/noop';
import styles from './InputNumber.scss';
import bootstrap from 'scss/component.scss';
import skinStyles from './InputNumberSkins.scss';

export const skins = {
  PRIMARY: 'PRIMARY',
  BIRTHDATE_DAY: 'BIRTHDATE_DAY',
  BIRTHDATE_MONTH: 'BIRTHDATE_MONTH',
  BIRTHDATE_YEAR: 'BIRTHDATE_YEAR',
};

const InputNumber = React.forwardRef(
  (
    {
      value: initialValue,
      autocomplete,
      avoidInlineStates,
      changeOnBlur,
      cyData,
      disabled,
      focus,
      format,
      id,
      onBlur,
      onChange,
      onFocus,
      name,
      maxLength,
      pending,
      placeholder,
      skin,
      updateValue,
      showError,
      trackingAttributes,
    },
    ref
  ) => {
    const [value, setValue] = useState(initialValue);
    const [focused, setFocus] = useState(false);
    const [keepFocus, setKeepFocus] = useState(false);
    const [showDeleteButton, setShowDeleteButton] = useState(false);

    useEffect(() => {
      if (updateValue) {
        setValue(focused ? updateValue : formatValue(updateValue));
      }
    }, [updateValue]);

    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    const onInputChange = (e = { target: { value: '' } }) => {
      if (e.target.value === '' || isNumber(e.target.value)) {
        setValue(e.target.value);
        onChange(e.target.value);
        if (focus && !!value && isTouchDevice()) {
          if (!showDeleteButton && !avoidInlineStates) {
            setShowDeleteButton(true);
          }
        } else if (showDeleteButton && !avoidInlineStates) {
          setShowDeleteButton(false);
        }
      }
    };

    const onInputFocus = () => {
      if (!!value && isTouchDevice() && !avoidInlineStates) {
        setShowDeleteButton(true);
      }

      setFocus(true);
      setValue(formatPlain(value));
      onFocus();
    };

    const onInputBlur = (e = { target: { value: '' } }) => {
      changeOnBlur(e.target.value);
      if (!keepFocus) {
        if (!avoidInlineStates) {
          setShowDeleteButton(false);
        }
        debounceOnBlur();
      }
      setValue(focused ? formatValue(updateValue) : formatValue(value));
      setFocus(false);
    };

    const isNumber = (numberValue) => numberValue && number(numberValue) !== 'Invalid';

    const debounceOnBlur = debounce(() => {
      onBlur();
    }, 100);

    /**
     * Empties the input field when the delete button is clicked
     */
    const deleteInputFieldContent = () => {
      setValue('');
      // Variable is used to prevent validation
      // if the field content was cleared with the button
      // and the field keeps the focus
      setKeepFocus(true);
    };

    /**
     * Refocuses the input field after deletion
     */
    const refocusInputField = () => {
      onInputChange();
      setKeepFocus(false);
    };

    /**
     * Checks for a value in the input
     */

    const formatValue = (val) => {
      switch (format) {
        case InputNumber.Format.Currency:
          return formatHighComma(val);
        default:
          return val;
      }
    };

    const formatPlain = (val) => String(val).replace(/[^0-9.]/g, '');

    return (
      <div className={styles.wrap}>
        <input
          type={'tel'}
          value={value}
          name={name}
          id={id}
          placeholder={placeholder}
          onBlur={onInputBlur}
          onFocus={onInputFocus}
          onChange={onInputChange}
          ref={ref}
          className={cx(bootstrap.formControl, styles.input, skinStyles[skin], {
            [styles.disabled]: disabled,
            [styles.hasDanger]: showError && !keepFocus,
            [styles.pending]: pending,
          })}
          disabled={disabled}
          autoComplete={autocomplete}
          maxLength={maxLength}
          data-cy={cyData}
          data-tracking={trackingAttributes && dataTracking(trackingAttributes)}
        />
        {showDeleteButton && !avoidInlineStates && (
          <button
            type="button"
            className={styles.reset}
            onMouseDown={deleteInputFieldContent}
            onMouseUp={refocusInputField}
            tabIndex="-1"
          >
            <Icon path={ICON_CLOSE} />
          </button>
        )}
      </div>
    );
  }
);

InputNumber.displayName = 'atoms/InputNumber';

InputNumber.Format = {
  Currency: 'Currency',
};

InputNumber.propTypes = {
  value: PropTypes.string,
  updateValue: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  changeOnBlur: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  disabled: PropTypes.bool,
  showError: PropTypes.bool,
  pending: PropTypes.bool,
  focus: PropTypes.bool,
  autocomplete: PropTypes.string,
  avoidInlineStates: PropTypes.bool,
  skin: PropTypes.oneOf(getEnumValues(skins)),
  maxLength: PropTypes.string,
  format: PropTypes.string,
  cyData: PropTypes.string,
  trackingAttributes: PropTypes.object,
};

InputNumber.defaultProps = {
  name: '',
  value: '',
  placeholder: '',
  disabled: false,
  showError: false,
  pending: false,
  focus: false,
  maxLength: 'auto',
  avoidInlineStates: false,
  skin: skins.PRIMARY,
  changeOnBlur: noop,
  onChange: noop,
  onBlur: noop,
  onFocus: noop,
};

export default InputNumber;
