import merge from 'lodash/merge';

import restClient from 'api/RestClient/RestClient';
import { resourceKeys } from 'constants/restResources/restResources';
import { userCurrentlyRated } from 'constants/ratings/ratings';

import {
  mapFacetQueryStringToFacetQueryParams,
  mapFacetQueryParamsToCategoryCode,
} from 'constants/urlMapping/urlMapping';
import { paginationMapping, reversePaginationMapping } from '../mappings/PaginationMapping';
import CmsAPI from 'api/CmsAPI/CmsAPI';
import { numberOfReferences, initialReferencesPage } from 'constants/references/references';
import { productReportTypes } from 'constants/productReportTypes/productReportTypes';
import { INVALID_PARAMS } from 'constants/promises/promises';
import { postLog } from 'api/promiseMiddleware';
import { FALLBACK_PRODUCT_IMAGE } from 'constants/products/index';

const resource = resourceKeys.PRODUCT_RESOURCE;

type queryParamsTypes = {
  facetQuery?: string,
  page?: number,
  searchString?: string,
  sort?: string,
  categoryCode?: string,
};

/**
 * Returns details of a single product according to a product code.
 * @param productCode
 * @param fieldSet
 * @returns {ProductDTO}
 */
function getProduct(productCode, fieldSet = 'FULL') {
  if ([productCode, fieldSet].some((e) => e === undefined)) {
    return Promise.reject(INVALID_PARAMS);
  }

  // Workaround for short cache header in product endpoint. We append a cache bust when the user has
  // rated (or editied/deleted) a rating within the last 4 hours to make sure the user gets the updated
  // product response as soon as possible
  const cacheBust = userCurrentlyRated() ? `&ts=${Date.now()}` : '';

  return restClient
    .get(resource, `/${productCode}?fieldSet=${fieldSet}${cacheBust}`)
    .then((response) => {
      const newResponse = response;

      if (!newResponse.data.customImageData) {
        newResponse.data.customImageData = [FALLBACK_PRODUCT_IMAGE];
      }

      return newResponse;
    })
    .catch((error) => error);
}

function getProducts(productCodes = []) {
  if (!productCodes.length) return Promise.reject(INVALID_PARAMS);

  return restClient.get(resourceKeys.API_RESOURCE, `/products?id=${productCodes.join(',')}`);
}

// @flow
/**
 * Returns a list of products based on the searchTerm / sort / selected facets / pagination
 *
 * @param queryString
 */
function loadProducts(queryString: string) {
  const paginationURL = reversePaginationMapping(queryString);
  const url = `/search?${paginationURL}`;

  return restClient.get(resource, url).then(
    (response) => paginationMapping(response),
    (error) => error
  );
}
/**
 * Returns an array of products
 *
 * @param {Array} productsToFetch - An array of product codes that is used to fetch complete product data
 * @param {Boolean} isApiRoute - determines whether use to api route to fetch all product requests at once or send individual directly to OCC API
 */
export const fetchProductList = (productsToFetch: Array<string>, isApiRoute = false) => {
  if (productsToFetch === undefined) {
    return Promise.reject(INVALID_PARAMS);
  }
  if (productsToFetch.length === 0) {
    return Promise.reject('Received an array without product codes');
  }

  const requests = isApiRoute
    ? [getProducts(productsToFetch)]
    : productsToFetch.map((code) => getProduct(code, 'DEFAULT'));

  return Promise.allSettled(requests).then((responses) => ({
    data: responses
      .map(({ status, value }) => (status === 'fulfilled' ? value.data : null))
      .filter(Boolean)
      .flat(),
  }));
};

/**
 * Fetches time critical data for the given product codes
 * @param productCodes
 * @returns {*}
 */
function fetchTimeCriticalProductData(productCodes) {
  if (productCodes === undefined) {
    return Promise.reject(INVALID_PARAMS);
  }

  return restClient.get(resource, `/timecritical?productCodes=${productCodes}`);
}

const searchAsYouType = (() => {
  // wrapped in closure to keep track of cancelable requests
  let pastPromise = {};

  return (searchterm) => {
    if (searchterm === undefined) {
      return Promise.reject(INVALID_PARAMS);
    }

    if (pastPromise && pastPromise.cancel) {
      pastPromise.cancel('canceled product suggestion request');
    }

    const { promise, cancel } = restClient.getCancelable(
      resource,
      `/searchasyoutype?query=${encodeURIComponent(searchterm)}&amount=6`
    );

    pastPromise = {
      cancel,
    };

    promise
      .then(() => {
        pastPromise = {};
        return this;
      })
      .catch(() => {
        pastPromise = {};
      });

    return promise;
  };
})();

const suggestAsYouType = (() => {
  // wrapped in closure to keep track of cancelable requests
  let pastPromise = {};

  return (searchterm, amount = 6) => {
    if (searchterm === undefined) {
      return Promise.reject(INVALID_PARAMS);
    }

    if (pastPromise && pastPromise.cancel) {
      pastPromise.cancel('canceled suggestion request');
    }

    const { promise, cancel } = restClient.getCancelable(
      resource,
      `/suggestion?query=${encodeURIComponent(searchterm)}&amount=${amount}`
    );

    pastPromise = {
      cancel,
    };

    promise
      .then(() => {
        pastPromise = {};
        return this;
      })
      .catch(() => {
        pastPromise = {};
      });

    return promise;
  };
})();

/**
 * Returns details of a single product according to a product code.
 * @param params - Configuration object which may contain productCode, pageSize or currentPage
 * @returns {ProductDTO}
 */
function fetchProductAccessories(params: Object = {}) {
  if (params.productCode === undefined) {
    return Promise.reject(INVALID_PARAMS);
  }

  const currentPage = params?.currentPage || initialReferencesPage;
  const pageSize = params?.pageSize || numberOfReferences.FULL;

  return restClient
    .get(resource, `/${params.productCode}/references?currentPage=${currentPage}&pageSize=${pageSize}`)
    .then((result) => {
      const products = result?.data?.references || [];
      const timeCriticalProducts = [];

      products.forEach((product) => {
        if (product.target.maxOrderCounterActive) {
          timeCriticalProducts.push(product.target.code);
        }
      });

      if (timeCriticalProducts.length) {
        return fetchTimeCriticalProductData(timeCriticalProducts).then((timeCriticalData) => {
          let productAccessories = {};
          if (Array.isArray(timeCriticalData.data)) {
            timeCriticalData.data.forEach((dataForProduct) => {
              const productToMerge = products.find((prod) => prod.target.code === dataForProduct.code);
              const remainingProducts = products.filter((product) => product.target.code !== dataForProduct.code);

              productToMerge.target.promotion = {
                stockMax: dataForProduct?.stockMax || 0,
                stockCurrent: dataForProduct?.stockCurrent || 0,
                endTime: +new Date(dataForProduct?.endTime || 0),
              };

              productToMerge.target.price = {
                ...dataForProduct.price,
              };

              productAccessories = [...remainingProducts, { ...productToMerge }];
            });
          }

          return {
            ...result,
            data: {
              ...result.data,
              references: [...productAccessories],
            },
          };
        });
      }

      return result;
    });
}

/**
 * @param queryString
 * @param queryParams
 */
function fetchProducts(queryString: string, queryParams: queryParamsTypes) {
  let cmsCategoryResponse;
  let productsResponse;

  const facetQuery = queryParams?.facetQuery;
  const facetQueryParams = mapFacetQueryStringToFacetQueryParams(facetQuery);
  const category = mapFacetQueryParamsToCategoryCode(facetQueryParams);

  const promises = [
    loadProducts(queryString).then(
      (response) => {
        const promotedProducts = [];
        productsResponse = response;

        const productsData = productsResponse?.data || {};

        if (Array.isArray(productsData.products)) {
          productsData.products.forEach((product) => {
            if (product.hasPromotion || product.maxOrderCounterActive) {
              promotedProducts.push(product.code);
            }
          });
        }

        if (promotedProducts.length) {
          return fetchTimeCriticalProductData(promotedProducts).then(
            (timeCriticalData) => {
              if (Array.isArray(timeCriticalData.data)) {
                timeCriticalData.data.forEach((dataForProduct) => {
                  const productIndex = productsResponse.data.products.findIndex(
                    (product) => product.code === dataForProduct.code
                  );
                  productsResponse.data.products[productIndex] = {
                    ...productsResponse.data.products[productIndex],
                    price: {
                      ...dataForProduct.price,
                    },
                    promotion: {
                      stockMax: dataForProduct?.stockMax || 0,
                      stockCurrent: dataForProduct?.stockCurrent || 0,
                      endTime: +new Date(dataForProduct?.endTime || 0),
                    },
                  };
                });
              }
              return { ...productsResponse };
            },
            (error) => {
              postLog(error);
            }
          );
        }
        return productsResponse;
      },
      (error) => {
        if (error.response) {
          productsResponse = error.response;
        }
      }
    ),
  ];

  if (category) {
    promises.push(
      CmsAPI.getCategoryContent(category, facetQueryParams).then(
        (response) => {
          cmsCategoryResponse = response;
        },
        () => true
      )
    );
  }

  return Promise.all(promises).then(() => {
    let productsData = productsResponse?.data || {};

    if (cmsCategoryResponse) {
      productsData = merge(productsData, { cmsContent: cmsCategoryResponse?.data });
    }

    if (!Object.keys(productsData).length) {
      throw productsResponse;
    } else {
      return productsResponse;
    }
  });
}

/**
 * Returns details of a single product according to a product code.
 * @param productCode
 * @returns {ProductDTO}
 */
function fetchProductWall(productCode) {
  if (productCode === undefined) {
    return Promise.reject(INVALID_PARAMS);
  }

  return restClient.get(resourceKeys.CMS_RESOURCE, `/product/${productCode}`);
}

/**
 * Returns wall content for a given category ID
 * @param categoryCode
 * @returns []
 */
function fetchCategoryWall(categoryCode) {
  if (categoryCode === undefined) {
    return Promise.reject(INVALID_PARAMS);
  }

  return restClient.get(resourceKeys.CMS_RESOURCE, `/categorywall/${categoryCode}`);
}

function postProductReport({
  productCode,
  productReportType,
  message,
  email,
  additionalInfo,
}: {
  productCode: string,
  productReportType: string,
  message: string,
  email: string,
  additionalInfo: ?string,
}): Promise {
  if (!productCode) return Promise.reject('No product code was provided to postProductReport');
  if (!productReportType) return Promise.reject('No productReportType was provided to postProductReport');
  if (!message) return Promise.reject('No message was provided to postProductReport');
  if (!Object.keys(productReportTypes).some((typeKey) => productReportType === productReportTypes[typeKey])) {
    return Promise.reject('Invalid productReportType provided to postProductReport');
  }
  if (
    (productReportType === productReportTypes.image || productReportType === productReportTypes.video) &&
    additionalInfo === undefined
  ) {
    return Promise.reject('additionalInfo is required for image or video product reports');
  }

  return restClient.post(resource, `/${productCode}/report`, {
    productReportType,
    message,
    email,
    additionalInfo,
  });
}

const fetchStockInfo = (productCode) => {
  if (!productCode) {
    return Promise.reject(INVALID_PARAMS);
  }

  return restClient.get(resourceKeys.INVENTORY_MICROSERVICE_RESOURCE, `?productId=${productCode}`, false);
};

const ProductAPI = {
  getProduct,
  fetchProducts,
  searchAsYouType,
  suggestAsYouType,
  fetchProductAccessories,
  loadProducts,
  fetchProductWall,
  fetchCategoryWall,
  fetchTimeCriticalProductData,
  fetchStockInfo,
  postProductReport,
};

export {
  ProductAPI as default,
  getProduct,
  fetchProducts,
  searchAsYouType,
  suggestAsYouType,
  fetchProductAccessories,
  loadProducts,
  fetchProductWall,
  fetchCategoryWall,
  fetchTimeCriticalProductData,
  fetchStockInfo,
  postProductReport,
};
