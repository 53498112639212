import invert from 'constants/helper/invert';
import { seoStructuredDataTypes } from 'components/organisms/SeoStructuredData/SeoStructuredData';
import { getEnv } from 'config/config';
import language, { getLocale } from 'constants/language/language';
import { getStringCategoriesName } from 'constants/categoryTree/categoryTree';
import { seoAvailabilityStates } from 'constants/deliveryStates/availabilityStates';
import { getSearchUrl } from 'constants/urlHelper/urlHelper';
import getTimeFromTimestamp from 'constants/getTimeFromTimestamp/getTimeFromTimestamp';
import getUrlForProduct from 'constants/getUrlForProduct/getUrlForProduct';

// Structured data source: https://developers.google.com/search/docs/guides/intro-structured-data
const getJsonLd = (type, data = {}, categories) => {
  const json = {
    '@context': 'https://schema.org',
    '@type': type,
  };

  switch (type) {
    case seoStructuredDataTypes.BREADCRUMB:
      Object.assign(json, getBreadcrumbListJsonLd(data));
      break;
    case seoStructuredDataTypes.LOCALBUSINESS:
      Object.assign(json, getLocalBusinessJsonLd(data));
      break;
    case seoStructuredDataTypes.PRODUCT:
      Object.assign(json, getProductJsonLd(data, categories));
      break;
    case seoStructuredDataTypes.SEARCHACTION:
      Object.assign(json, getSearchActionJsonLd());
      break;
    default:
      Object.assign(json, data);
  }

  return JSON.stringify(json).replace(/</g, '\\u003c');
};

// Structured data markup source: https://developers.google.com/search/docs/data-types/breadcrumb
const getBreadcrumbListJsonLd = (items) => ({
  itemListElement: items.map((item, index) => ({
    '@type': 'ListItem',
    position: index + 1,
    name: item.name,
    item: getEnv('api') + item.href,
  })),
});

// Structured data markup source: https://developers.google.com/search/docs/data-types/local-business
// TODO: Add storeImages inside image array, when available from backend
const getLocalBusinessJsonLd = ({ address, description, geoPoint, openingHours }) => {
  const getOpeningHours = (special = false) => {
    const daysLabel = invert(language('common.daysAbbreviations'));
    const allOpeningHours = [];
    const openingList = special ? openingHours?.specialDayOpeningList : openingHours?.weekDayOpeningList;
    openingList.forEach((day) => {
      if (day.closed) {
        allOpeningHours.push({
          '@type': 'OpeningHoursSpecification',
          opens: '00:00',
          closes: '00:00',
          ...(special ? { validFrom: day.date, validThrough: day.date } : { dayOfWeek: daysLabel[day.weekDay] }),
        });
      } else {
        allOpeningHours.push(
          ...day.openingDayTimeRangeDataList.map((openingTimeRange) => ({
            '@type': 'OpeningHoursSpecification',
            opens: getTimeFromTimestamp(openingTimeRange.openingTime),
            closes: getTimeFromTimestamp(openingTimeRange.closingTime),
            ...(special ? { validFrom: day.date, validThrough: day.date } : { dayOfWeek: daysLabel[day.weekDay] }),
          }))
        );
      }
    });
    return allOpeningHours;
  };
  return {
    name: description,
    image: [],
    address: {
      '@type': 'PostalAddress',
      streetAddress: `${address?.streetName} ${address?.streetNumber}`,
      addressLocality: address?.town,
      postalCode: address?.postalCode,
      addressCountry: address?.country?.isocode,
    },
    geo: {
      '@type': 'GeoCoordinates',
      latitude: geoPoint?.latitude,
      longitude: geoPoint?.longitude,
    },
    telephone: address?.phone,
    openingHoursSpecification: [getOpeningHours(), getOpeningHours(true)],
  };
};

// Structured data markup source: https://developers.google.com/search/docs/data-types/product
const getProductJsonLd = (product, categories) => {
  const images = product.customImageData.map((image) => {
    const imagePath = image?.sizes?.slice(-1)?.[0]?.url;
    return imagePath ? getEnv('api') + imagePath : [];
  });
  const categoryName = getStringCategoriesName(product.categoryCode, getLocale(), categories);
  return {
    name: product.name,
    image: images,
    description: product.description,
    sku: product.code,
    manufacturer: product.manufacturer,
    category: categoryName,
    ...(+product?.numberOfReviews >= 3 && {
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: product?.averageRating,
        reviewCount: product?.numberOfReviews,
      },
    }),
    offers: {
      '@type': 'Offer',
      url: `${
        getEnv('api') + getUrlForProduct(product.categoryCode, product.name, product.code, getLocale(), categories)
      }`,
      priceCurrency: product.productPriceData?.finalPrice?.currencyIso,
      price: String(product.productPriceData?.finalPrice?.value),
      ...(product.productPriceData?.expires && { priceValidUntil: product.productPriceData.expires }),
      availability: `https://schema.org/${seoAvailabilityStates[product.availabilityStatus]}`,
    },
  };
};

// Structured data markup source: https://developers.google.com/search/docs/data-types/sitelinks-searchbox
const getSearchActionJsonLd = () => ({
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  url: getEnv('api'),
  potentialAction: {
    '@type': 'SearchAction',
    target: `${getEnv('api')}${getSearchUrl(getLocale())}?search={search_term_string}`,
    'query-input': 'required name=search_term_string',
  },
});

export { getJsonLd, getBreadcrumbListJsonLd, getLocalBusinessJsonLd, getProductJsonLd, getSearchActionJsonLd };
