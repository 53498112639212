export const onlyNumberCodeValidateChar = (e) =>
  // 8 is backspace, 9 is tab and 46 is delete
  // 99, 118 and 120 copy & paste (c = 99, v=118, x=120)
  (e.key >= 0 && e.key <= 9) ||
  e.which === 8 ||
  e.which === 9 ||
  e.which === 46 ||
  e.which === 99 ||
  e.which === 118 ||
  e.which === 120;
