import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import InternalOrExternalLink from 'components/atoms/InternalOrExternalLink/InternalOrExternalLink';
import styles from './CheckoutFooterLink.scss';

const CheckoutFooterLink = ({ text, to, className }) => (
  <span className={cx(className, styles.footerLink)}>
    <InternalOrExternalLink href={to} openNewTab>
      {text}
    </InternalOrExternalLink>
  </span>
);

CheckoutFooterLink.propTypes = {
  text: PropTypes.string,
  to: PropTypes.string,
  className: PropTypes.string,
};

CheckoutFooterLink.defaultProps = {
  text: '',
  to: '',
};

CheckoutFooterLink.displayName = 'atoms/CheckoutFooterLink';

export default CheckoutFooterLink;
