import React from 'react';
import PropTypes from 'prop-types';
import SvgContainer from 'components/atoms/SvgContainer/SvgContainer';
import styles from 'components/molecules/HeadLogo/HeadLogo.scss';

const MorphingLogoInterdiscount = ({ height, width, vbXCoords, vbYCoords, className, altText = '' }) => (
  <SvgContainer
    height={height}
    width={width}
    vbWidth={235}
    vbHeight={64}
    vbXCoords={vbXCoords}
    vbYCoords={vbYCoords}
    className={`${className} ${styles.morphing}`}
  >
    <g clipPath="url(#clip0_1651_13363)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M153.373 32.7998H146.618V49.9998H153.373V40.7998C154.314 39.7523 154.544 38.7998 155.956 38.7998C157.367 38.7998 157.346 40.2283 157.346 40.7998C157.346 41.3713 157.346 49.9998 157.346 49.9998H164.101V38.3998C164.101 34.971 161.738 32.7998 158.538 32.7998C155.339 32.7998 154.22 34.4379 153.373 35.1998V32.7998Z"
        fill="#1D1D1B"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M0.397461 2H179.2V0H0.397461V2Z" fill="#E20615" />
      <path fillRule="evenodd" clipRule="evenodd" d="M0 64H178.803V52H0V64Z" fill="#E20615" />
      <path fillRule="evenodd" clipRule="evenodd" d="M47.2832 26.0012H54.8327V4H47.2832V26.0012Z" fill="#1D1D1B" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.7797 8.7998H56.0249V25.9998H62.7797V16.7998C63.7207 15.7523 63.9506 14.7998 65.3624 14.7998C66.7738 14.7998 66.7531 16.2283 66.7531 16.7998C66.7531 17.3713 66.7531 25.9998 66.7531 25.9998H73.5078V14.3998C73.5078 10.971 71.1451 8.7998 67.9451 8.7998C64.7458 8.7998 63.6268 10.4379 62.7797 11.1998V8.7998Z"
        fill="#1D1D1B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M83.4414 13.5996H88.2095V8.79961H83.4414V3.59961H81.8521L73.9053 10.3996V13.5996H76.2893V21.1996C76.2893 23.676 78.3387 26.3996 83.0441 26.3996C85.7738 26.3996 87.6658 25.3711 88.6068 24.7996V19.1996C87.9483 19.7711 86.85 20.3996 85.6268 20.3996C84.4032 20.3996 83.4414 20.077 83.4414 19.3151V13.5996Z"
        fill="#1D1D1B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M95.7588 15.5998H99.3348C99.522 14.383 98.7658 13.2 97.5468 13.2C96.3282 13.2 95.7588 14.1957 95.7588 15.5998ZM105.692 20.3998V24.7998C105.129 25.0805 102.808 26.3998 98.5895 26.3998C92.8704 26.3998 89.4014 23.2168 89.4014 17.5064C89.4014 12.5445 92.4014 8.7998 97.7455 8.7998C103.089 8.7998 106.09 12.0338 106.09 18.3998H95.7588C95.7588 21.2083 98.5895 21.719 100.277 21.719C101.964 21.719 104.942 20.7743 105.692 20.3998Z"
        fill="#1D1D1B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M106.884 8.79998V26H113.639V19.2C113.639 17.581 116 15.4095 117.506 15.4095C119.012 15.4095 120.042 15.8939 120.791 16.4L121.188 10.4C120.717 9.54259 119.69 8.66699 117.996 8.66699C116.303 8.66699 115.239 9.33315 113.639 12V8.79998"
        fill="#1D1D1B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M174.035 37.5996H178.803V32.7996H174.035V27.5996H172.445L164.499 34.3996V37.5996H166.883V45.1996C166.883 47.676 168.932 50.3996 173.637 50.3996C176.367 50.3996 178.259 49.3711 179.2 48.7996V43.1996C178.542 43.7711 177.443 44.3996 176.22 44.3996C174.996 44.3996 174.035 44.077 174.035 43.3151V37.5996Z"
        fill="#1D1D1B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.8327 33.6958V44.4H57.2848C59.635 44.4 61.9848 42.2977 61.9848 38.9523C61.9848 35.6073 59.4307 33.6 57.1828 33.6C54.935 33.6 54.8327 33.6958 54.8327 33.6958ZM59.9577 50.0012H47.2832V28H58.3619C65.0276 28 69.5342 33.5242 69.5342 38.8582C69.5342 44.1915 66.0606 50.0012 59.9577 50.0012Z"
        fill="#1D1D1B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.3291 50.0002H77.4812V33.2002H70.3291V50.0002Z"
        fill="#1D1D1B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.9907 34.7998C90.1339 33.8536 88.2651 32.7998 85.1258 32.7998C81.4152 32.7998 78.3708 34.7871 78.3708 37.6258C78.3708 40.4645 79.2333 41.9462 83.318 43.8706C85.1258 44.7224 84.7452 45.9527 83.4133 45.9527C82.0814 45.9527 80.7495 45.6686 78.2759 43.8706V49.2639C79.7981 50.0213 81.7008 50.3998 84.555 50.3998C87.4091 50.3998 91.7854 49.7376 91.7854 45.1005C91.7854 43.114 89.5021 40.843 86.7429 39.7075C84.5453 38.8031 85.1226 37.3417 86.648 37.3417C87.4091 37.3417 89.7533 38.1932 90.9907 39.329"
        fill="#1D1D1B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71.521 29.9998C71.521 28.6742 72.588 27.5996 73.9048 27.5996C75.2217 27.5996 76.2891 28.6742 76.2891 29.9998C76.2891 31.325 75.2217 32.3996 73.9048 32.3996C72.588 32.3996 71.521 31.325 71.521 29.9998Z"
        fill="#1D1D1B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M106.487 33.6471C104.814 33.0824 103.514 32.7998 101.657 32.7998C99.799 32.7998 92.1826 33.8353 92.1826 41.6464C92.1826 49.4582 99.7059 50.3998 101.657 50.3998C103.608 50.3998 105.836 49.8351 106.487 49.5526V43.9056C105.558 44.4699 104.164 44.8467 102.957 44.8467C101.75 44.8467 98.9374 44.0472 98.9374 41.5998C98.9374 39.1532 101.471 38.5411 102.957 38.5411C104.443 38.5411 105.836 39.1997 106.487 39.6705V33.6471Z"
        fill="#1D1D1B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M145.029 43.9998V32.7998H138.274V42.7998C138.274 44.61 136.35 44.7998 135.691 44.7998C135.033 44.7998 133.903 44.0382 133.903 42.7998V32.7998H127.148V44.7998C127.148 48.5141 130.358 50.3998 132.711 50.3998C135.064 50.3998 137.939 49.3134 139.069 47.5998C140.103 49.6944 140.648 49.9998 145.823 49.9998V45.5998C145.011 45.5998 145.029 45.1596 145.029 43.9998Z"
        fill="#1D1D1B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M116.665 44.8938C118.202 44.8938 119.447 43.4612 119.447 41.6935C119.447 39.9262 118.202 38.4939 116.665 38.4939C115.13 38.4939 113.884 39.9262 113.884 41.6935C113.884 43.4612 115.13 44.8938 116.665 44.8938ZM106.884 41.5994C106.884 36.7397 111.242 32.7998 116.619 32.7998C121.995 32.7998 126.354 36.7397 126.354 41.5994C126.354 46.4595 121.995 50.3998 116.619 50.3998C111.242 50.3998 106.884 46.4595 106.884 41.5994Z"
        fill="#1D1D1B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.397461 12.3998C0.397461 7.098 4.66689 2.7998 9.93361 2.7998C15.2003 2.7998 19.4697 7.098 19.4697 12.3998C19.4697 17.702 15.2003 21.9998 9.93361 21.9998C4.66689 21.9998 0.397461 17.702 0.397461 12.3998Z"
        fill="#E20615"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.397461 49.9998H19.4697V22.7998H0.397461V49.9998Z"
        fill="#E20615"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.4565 50.0002C34.2235 50.0002 45.6942 39.4344 45.6942 26.4013C45.6942 13.3683 34.2235 3.2002 21.4565 3.2002V50.0002Z"
        fill="#E20615"
      />
    </g>
    <g clipPath="url(#clip1_1651_13363)">
      <path d="M235.2 24H195.2V64H235.2V24Z" fill="white" />
      <path d="M216.892 36.3418L223.631 34.4584L228.677 51.7371L221.938 53.6205L216.892 36.3418Z" fill="#CEE7D5" />
      <path
        d="M206.763 36.3293L206.786 36.4077L211.815 53.6323L218.562 51.7469L213.51 34.4434L206.763 36.3293Z"
        fill="#CEE7D5"
      />
      <path
        d="M214.26 55.9389C212.41 55.421 211.329 53.5135 211.858 51.7L216.894 34.4162C217.422 32.6028 219.368 31.5432 221.218 32.0611C223.068 32.5791 224.149 34.4866 223.621 36.3001L218.584 53.5838C218.056 55.3966 216.11 56.4569 214.26 55.9389Z"
        fill="#00AE6E"
      />
      <path
        d="M204.065 55.9389C202.215 55.421 201.134 53.5135 201.662 51.7L206.699 34.4162C207.227 32.6028 209.173 31.5432 211.023 32.0611C212.873 32.5791 213.954 34.4866 213.425 36.3001L208.389 53.5838C207.86 55.3966 205.915 56.4569 204.065 55.9389Z"
        fill="#00AE6E"
      />
      <path
        d="M221.802 52.6328C221.802 54.5315 223.384 56.0703 225.337 56.0703C227.29 56.0703 228.872 54.5309 228.872 52.6328C228.872 50.7347 227.29 49.1953 225.337 49.1953C223.385 49.1953 221.802 50.7341 221.802 52.6328Z"
        fill="#00AE6E"
      />
    </g>
    <defs>
      <clipPath id="clip0_1651_13363">
        <rect width="179.2" height="64" fill="white" />
      </clipPath>
      <clipPath id="clip1_1651_13363">
        <rect width="40" height="40" fill="white" transform="translate(195.2 24)" />
      </clipPath>
    </defs>
    {altText && <title>{altText}</title>}
  </SvgContainer>
);

MorphingLogoInterdiscount.displayName = 'atoms/MorphingLogoInterdiscount';

MorphingLogoInterdiscount.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  vbXCoords: PropTypes.number,
  vbYCoords: PropTypes.number,
  className: PropTypes.string,
  altText: PropTypes.string,
};

MorphingLogoInterdiscount.defaultProps = {
  height: 64,
};

export default MorphingLogoInterdiscount;
