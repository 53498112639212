import React from 'react';
import PropTypes from 'prop-types';
import UserContainer from 'containers/User/User';
import PopOverLogin from 'components/molecules/PopOverLogin/PopOverLogin';
import PopOverAccount from 'components/molecules/PopOverAccount/PopOverAccount';

const PopOverUser = ({ isLoggedIn = false }) =>
  isLoggedIn ? (
    <UserContainer>
      <PopOverAccount />
    </UserContainer>
  ) : (
    <PopOverLogin />
  );

PopOverUser.propTypes = {
  isLoggedIn: PropTypes.bool,
};

PopOverUser.displayName = 'molecules/PopOverUser';

export default PopOverUser;
