import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import has from 'constants/helper/has';
import { getVisibleFields } from 'constants/contact/contact.helper';
import Contact from 'components/organisms/Contact/Contact';
import actions, { changeContactSubject } from 'actions/contactActions/contactActions';

export const mapStateToProps = (state) => {
  if (!has(state, 'contact')) return {};
  return {
    fields: state.contact.fields || {},
    successSent: state.contact.sent || false,
    hasFetchedSubjects: state.contact.hasFetchedSubjects || false,
    firstError: has(state, 'contact.fields.firstError.name') && state.contact.fields.firstError.name,
    copyWanted: state?.contact?.fields?.copyWanted?.value ?? false,
    contactSubjects: state?.contact?.fields?.contactSubjects?.value || '',
  };
};

const mapDispatchToProps = {
  sendContactRequest: actions.sendContactRequest,
  reinitializeContactForm: actions.reinitializeContactForm,
  changeContactSubject,
  updateContactField: actions.updateContactField,
};

export class ContactSubmit extends Component {
  static displayName = 'containers/ContactSubmit';

  static propTypes = {
    fields: PropTypes.object,
    successSent: PropTypes.bool,
    hasFetchedSubjects: PropTypes.bool,
    copyWanted: PropTypes.bool,
    contactSubjects: PropTypes.string,
    sendContactRequest: PropTypes.func,
    changeContactSubject: PropTypes.func,
    reinitializeContactForm: PropTypes.func,
    updateContactField: PropTypes.func,
    firstError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  };

  static defaultProps = {
    hasFetchedSubjects: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      contactType: null,
    };
  }

  componentWillUnmount = () => this.props.reinitializeContactForm();

  handleChangeSubject = (selectedSubject) => {
    const { changeContactSubject } = this.props;
    changeContactSubject(selectedSubject);
  };

  onContactTypeChange = (type) => {
    this.setState({ contactType: type });
  };

  submit = () => {
    const { contactSubjects, sendContactRequest, fields } = this.props;

    sendContactRequest(fields, contactSubjects);
  };

  render() {
    const { contactSubjects: selectedSubject, firstError, updateContactField, successSent, copyWanted } = this.props;
    const { contactType } = this.state;
    const visibleFields = getVisibleFields(selectedSubject);
    return (
      <Contact
        successSent={successSent}
        handleSubmit={this.submit}
        firstError={firstError}
        contactType={contactType}
        onContactTypeChange={this.onContactTypeChange}
        handleChangeSubject={this.handleChangeSubject}
        visibleFields={visibleFields}
        selectedSubject={selectedSubject}
        updateContactField={updateContactField}
        copyWanted={copyWanted}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactSubmit);
