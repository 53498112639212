import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import AddToCart from 'containers/AddToCart/AddToCart';
import ProductCartPegiFlag from 'components/molecules/ProductCartPegiFlag/ProductCartPegiFlag';
import ListInteraction from 'components/molecules/ProductInteractions/ListInteraction';
import AddToCartIcon from 'components/atoms/AddToCartIcon/AddToCartIcon';
import { ICON_COMPARE_ARROWS, ICON_FAVORITE_BORDER } from 'components/atoms/Icon/Icon';
import EnergyEfficiency, {
  contexts as energyContexts,
  types,
} from 'components/atoms/EnergyEfficiency/EnergyEfficiency';
import StarRating, { SIZES as ratingSizes, THEMES as ratingThemes } from 'components/atoms/StarRating/StarRating';
import DiscountFlag, { skins } from 'components/atoms/DiscountFlag/DiscountFlag';
import Headline, { tags } from 'components/atoms/Headline/Headline';
import Image, { getAltText } from 'components/atoms/Image/Image';
import ProductFlag from 'components/atoms/ProductFlag/ProductFlag';
import Price from 'components/atoms/Price/Price';
import { Col, Container, Row } from 'components/atoms/Grid/Grid';

import getUrlForProduct from 'constants/getUrlForProduct/getUrlForProduct';
import imageSizes from 'constants/imageSizes/imageSizes';
import { getLocale } from 'constants/language/language';
import truncate from 'constants/helper/truncate';
import { BREAKPOINTS } from 'constants/breakpoints/breakpoints';
import matchMedia from 'constants/matchMedia/matchMedia';
import { apiStatus } from 'constants/apiStatus/apiStatus';

import styles from './ProductListItem.scss';
import { NextFeatureLink } from 'components/atoms/NextFeatureLink';

const ProductListItem = ({
  addToComparison = () => {},
  addToWatchlist = () => {},
  averageRating,
  canBeBought,
  categoryCode,
  code,
  customImageData,
  energyEfficiency = {},
  energyEfficiencyEnEv2020 = {},
  name,
  numberOfReviews,
  pegiValue,
  productAddedToCart,
  productAddedToComparison,
  productAddedToWatchlist,
  productPriceData = {},
  promoLabels,
  removeFromComparison = () => {},
  removeFromWatchlist = () => {},
  productClickAction,
  productAttributes,
  watchlistApiStatus,
}) => {
  const hasEnergyEfficiency =
    !!Object.keys(energyEfficiency || {})?.length || !!Object.keys(energyEfficiencyEnEv2020 || {})?.length;

  const isMobile = !matchMedia(BREAKPOINTS.MD);
  const showDiscount = !!Object.keys(productPriceData.discount || {}).length;

  const locale = getLocale();
  const link = getUrlForProduct(categoryCode, name, code, locale, {});

  const isWatchlistDisabled = watchlistApiStatus === apiStatus.request;

  return (
    <Container>
      <Row>
        <Col xs={12} className={styles.cardContainer}>
          <div className={styles.listCard}>
            <div className={styles.PegiContainer}>
              <ProductCartPegiFlag pegiValue={pegiValue} />
            </div>
            <NextFeatureLink feature="nextPdpEnabled" to={link} name={name} onClick={() => productClickAction()}>
              <div className={styles.gallery}>
                <Image
                  alt={getAltText(customImageData)}
                  images={customImageData && customImageData[0] && customImageData[0].sizes}
                  size={imageSizes.productOrder.size}
                  responsive={imageSizes.default.responsive}
                  code={customImageData[0].code}
                />
              </div>
            </NextFeatureLink>
            <div className={styles.productInformation}>
              <div className={styles.priceContainer}>
                <div className={styles.price}>
                  {canBeBought && (
                    <Price productPriceData={productPriceData} skin={Price.SKINS.TEASER} showInsteadPrice />
                  )}
                </div>
                {hasEnergyEfficiency && (
                  <div className={styles.EnergyEfficiencyContainer}>
                    <EnergyEfficiency
                      energyEfficiency={energyEfficiency}
                      energyEfficiencyEnEv2020={energyEfficiencyEnEv2020}
                      type={isMobile ? types.xs : types.sm}
                      context={energyContexts.WATCHLIST}
                    />
                  </div>
                )}
              </div>
              <NextFeatureLink feature="nextPdpEnabled" to={link} name={name} onClick={() => productClickAction()}>
                <div>
                  <Headline tag={tags.H4} className={styles.productHeadline}>
                    <div className={styles.productName}>{truncate(name, 250)}</div>
                  </Headline>
                </div>
              </NextFeatureLink>
              <div className={styles.ratingContainer}>
                <div className={cx(styles.productFlag, { [styles.showFlag]: promoLabels.length })}>
                  {promoLabels.length ? <ProductFlag text={promoLabels[0].text} /> : null}
                </div>
                <div
                  className={cx(styles.discountFlag, {
                    [styles.showDiscount]: showDiscount,
                    [styles.showFlag]: !promoLabels.length,
                  })}
                >
                  {showDiscount && <DiscountFlag skin={skins.LIST} />}
                </div>
                <div
                  className={cx(styles.rating, {
                    [styles.showRating]: !showDiscount && !promoLabels.length,
                  })}
                >
                  {averageRating > 0 && numberOfReviews > 3 && (
                    <StarRating
                      value={averageRating}
                      reviewCount={numberOfReviews}
                      theme={ratingThemes.secondary}
                      size={ratingSizes.small}
                      showReviewCount
                    />
                  )}
                </div>
              </div>
            </div>
            <div
              className={cx(styles.productInteractions, {
                [styles.forceInteractionVisibility]:
                  productAddedToWatchlist || productAddedToComparison || productAddedToCart,
              })}
            >
              <ListInteraction
                className={styles.listViewInteraction}
                addProductToList={addToComparison}
                removeProductFromList={removeFromComparison}
                addedToList={productAddedToComparison}
                productCode={code}
                addedIcon={ICON_COMPARE_ARROWS}
              />
              <ListInteraction
                className={styles.listViewInteraction}
                addProductToList={addToWatchlist}
                removeProductFromList={removeFromWatchlist}
                addedToList={productAddedToWatchlist}
                productCode={code}
                addedIcon={ICON_FAVORITE_BORDER}
                isDisabled={isWatchlistDisabled}
              />
              <AddToCart productCode={code} productAttributes={productAttributes}>
                <AddToCartIcon />
              </AddToCart>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

ProductListItem.displayName = 'molecules/ProductListItem';
ProductListItem.propTypes = {
  averageRating: PropTypes.number,
  addToComparison: PropTypes.func,
  addToWatchlist: PropTypes.func,
  availability: PropTypes.string,
  availabilityStatus: PropTypes.string,
  canBeBought: PropTypes.bool,
  canBeReserved: PropTypes.bool,
  categoryCode: PropTypes.string,
  code: PropTypes.string,
  customImageData: PropTypes.array,
  deliveryTime: PropTypes.object,
  energyEfficiency: PropTypes.object,
  energyEfficiencyEnEv2020: PropTypes.object,
  name: PropTypes.string,
  numberOfReviews: PropTypes.number,
  neutralDeliveryPossible: PropTypes.bool,
  pegiValue: PropTypes.string,
  product: PropTypes.object,
  productAddedToCart: PropTypes.bool,
  productAddedToComparison: PropTypes.bool,
  productAddedToWatchlist: PropTypes.bool,
  productOrderable: PropTypes.bool,
  productPriceData: PropTypes.object,
  promoLabels: PropTypes.array,
  removeFromComparison: PropTypes.func,
  removeFromWatchlist: PropTypes.func,
  speedProduct: PropTypes.bool,
  topProductFeatures: PropTypes.array,
  productClickAction: PropTypes.func,
  productAttributes: PropTypes.object,
  watchlistApiStatus: PropTypes.string,
};

export default ProductListItem;
