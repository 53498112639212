// @flow
import { handleActions } from 'redux-actions';
import { recoCategories } from 'containers/RecommendationsBox/RecommendationsBox';
import {
  LOAD_RECOMMENDED_PRODUCTS_SUCCESS,
  LOAD_RECOMMENDED_TOP_PRODUCTS_SUCCESS,
} from 'constants/ActionTypes/ActionTypes';
import language from 'constants/language/language';

const mapRecoResponseToStore = (recommendations, elementTitle = '') =>
  recommendations?.map((recommendation) => ({
    productId: recommendation?.data?.UID || '',
    trackingToken: recommendation?.attributes?.trackingtoken,
    elementTitle,
  }));

export const initialState = {
  nullresult: {},
  error: {},
};
export default handleActions(
  {
    [LOAD_RECOMMENDED_PRODUCTS_SUCCESS]: (state, action) => {
      const { productId } = action.payload;
      const recommendations = action.req?.data?.recommendations || {};
      const similar = recommendations[recoCategories.SIMILAR_PRODUCTS] || {};
      const boughtByOthers = recommendations[recoCategories.BOUGHT_BY_OTHERS] || {};

      const similarTitle = similar.title || language('recommendations.similar.title');
      const boughtByOthersTitle = boughtByOthers.title || language('recommendations.boughtByOthers.title');
      const recommendedProducts = {
        similar: {
          title: similarTitle,
          products: mapRecoResponseToStore(similar.content, similarTitle),
        },
        boughtByOthers: {
          title: boughtByOthersTitle,
          products: mapRecoResponseToStore(boughtByOthers.content, boughtByOthersTitle),
        },
      };
      return {
        ...state,
        [productId]: {
          ...recommendedProducts,
        },
      };
    },
    [LOAD_RECOMMENDED_TOP_PRODUCTS_SUCCESS]: (state, action) => {
      const type = action.payload?.type || '';
      const allRecommendations = action.req?.data?.recommendations || {};
      const title = allRecommendations?.box1?.title;
      const recoData = {
        title: title,
        box1: mapRecoResponseToStore(allRecommendations?.box1?.content, title),
        box2: mapRecoResponseToStore(allRecommendations?.box2?.content, title),
        box3: mapRecoResponseToStore(allRecommendations?.box3?.content, title),
      };

      return {
        ...state,
        [type]: { ...recoData },
      };
    },
  },
  initialState
);
