import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { cypressAttributes } from 'constants/cypress/cypress';
import { apiStatus as apiStatusConst } from 'constants/apiStatus/apiStatus';
import { isProductOrderable } from 'constants/deliveryStates/getDeliveryStatus';
import getUrlForProduct from 'constants/getUrlForProduct/getUrlForProduct';
import truncate from 'constants/helper/truncate';
import imageSizesConstant from 'constants/imageSizes/imageSizes';
import language, { getLocale } from 'constants/language/language';
import CartProductCounter from 'containers/CartProductCounter/CartProductCounter';
import DeliveryState, { deliverySkins } from 'components/molecules/DeliveryState/DeliveryState';
import ProductCartAccessoires from 'components/molecules/ProductCart/ProductCartAccessoires';
import ProductCartPegiFlag from 'components/molecules/ProductCartPegiFlag/ProductCartPegiFlag';
import FlashMessage from 'components/atoms/FlashMessage/FlashMessage';
import { Col, Row } from 'components/atoms/Grid/Grid';
import Image, { getAltText } from 'components/atoms/Image/Image';
import TimeSensitiveInfoBox from 'components/atoms/TimeSensitiveInfoBox/TimeSensitiveInfoBox';
import ShoppingCartInfoBox from 'components/atoms/ShoppingCartInfoBox/ShoppingCartInfoBox';
import { cartStatusTypes } from 'constants/cartStatusTypes/cartStatusTypes';
import styles from './ProductCart.scss';
import CartServicesInline from 'containers/CartServicesInline/CartServicesInline';

export const shouldShowInfoBox = (priceUpdated, stockUpdated, discountUpdated) =>
  priceUpdated || stockUpdated || discountUpdated;

const ProductCart = ({
  apiStatus = '',
  availability,
  bulkyGoods = false,
  cartEntryCodes = [],
  categories = {},
  categoryCode = '',
  code = '',
  deliveryTime = {},
  discountUpdated = false,
  displaySpeedFlag = false,
  entryID = 0,
  fetchReferences = () => {},
  hasReferences = false,
  imageSizes = [],
  isCombinationProduct = false,
  name = '',
  neutralDeliveryPossible = false,
  pegiValue = '',
  priceUpdated = false,
  productOrderable = false,
  productReferences,
  productServices,
  quantity = 1,
  releaseDate = '',
  removeFromCart = () => {},
  shouldFetchReferences = false,
  stockUpdated = false,
  cartStatusCode = cartStatusTypes.SUCCESS,
}) => {
  const showStockUpdated =
    stockUpdated &&
    cartStatusCode !== cartStatusTypes.QUANTITY_REDUCED &&
    cartStatusCode !== cartStatusTypes.QUANTITY_AND_DELIVERY_DATE_CHANGED;
  const showInfoBox = shouldShowInfoBox(priceUpdated, showStockUpdated, discountUpdated);
  const productLink = isCombinationProduct ? '' : getUrlForProduct(categoryCode, name, code, getLocale(), categories);

  return (
    <Row className={styles.container}>
      <Col>
        {showInfoBox && (
          <div className={styles.timeSensitiveBox}>
            <TimeSensitiveInfoBox
              priceUpdated={priceUpdated}
              stockUpdated={stockUpdated}
              discountUpdated={discountUpdated}
            />
          </div>
        )}
      </Col>

      <Col xxs={5} md={3}>
        <Link to={productLink} name={getAltText(imageSizes)} className={styles.image}>
          <Image
            alt={getAltText(imageSizes)}
            images={imageSizes?.[0]?.sizes}
            size={imageSizesConstant.default.size}
            responsive={imageSizesConstant.default.responsive}
            code={imageSizes?.[0]?.code}
          />
          <ProductCartPegiFlag className={styles.pegi} pegiValue={pegiValue} />
        </Link>
      </Col>

      <Col md={9} className={styles.info}>
        <div>
          <h3 className={styles.title}>
            <Link to={productLink} name={name}>
              {truncate(name, 250)}
            </Link>
          </h3>
          <small className={styles.code}>
            {language('product.itemNumberOnline')} {code}
          </small>
        </div>
        <div className={styles.counter}>
          <CartProductCounter entryID={entryID} isDisabled={isCombinationProduct} />
        </div>
      </Col>

      <Col md={9} offsetMd={3}>
        <div className={styles.delivery}>
          {cartStatusCode !== cartStatusTypes.SUCCESS && <ShoppingCartInfoBox cartStatusCode={cartStatusCode} />}
          <DeliveryState
            skin={deliverySkins.CART}
            speedFlag={displaySpeedFlag}
            neutralDeliveryPossible={neutralDeliveryPossible}
            releaseDate={releaseDate}
            productOrderable={isProductOrderable(deliveryTime, productOrderable)}
            bulkyGoods={bulkyGoods}
            availability={availability}
            isCart
          />
        </div>

        {!isCombinationProduct ? (
          <>
            {productServices?.length > 0 && (
              <div className={styles.services}>
                <CartServicesInline entryID={entryID} availableProductServices={productServices} />
              </div>
            )}

            <div className={styles.controls}>
              <div className={styles.additional}>
                {hasReferences && (
                  <ProductCartAccessoires
                    cartEntryCodes={cartEntryCodes}
                    code={code}
                    entryID={entryID}
                    fetchReferences={fetchReferences}
                    imageSizes={imageSizes}
                    name={name}
                    productReferences={productReferences}
                    shouldFetchReferences={shouldFetchReferences}
                  />
                )}
              </div>

              <div
                className={cx(styles.removeFromCart, {
                  [styles.removeFromCartIsDisabled]: apiStatus === apiStatusConst.request,
                })}
                data-cy={cypressAttributes.cart.removeProductFromCart}
              >
                <span onClick={() => removeFromCart(entryID)}>{language('cart.remove')}</span>
              </div>
            </div>
          </>
        ) : (
          <FlashMessage content={language(`cart.combinationProduct.${quantity > 1 ? 'plural' : 'singular'}`)} />
        )}
      </Col>
    </Row>
  );
};

ProductCart.displayName = 'molecules/ProductCart';
ProductCart.propTypes = {
  apiStatus: PropTypes.string,
  availability: PropTypes.string,
  bulkyGoods: PropTypes.bool,
  cartEntryCodes: PropTypes.array,
  categories: PropTypes.object,
  categoryCode: PropTypes.string,
  code: PropTypes.string,
  deliveryTime: PropTypes.object,
  discountUpdated: PropTypes.bool,
  displaySpeedFlag: PropTypes.bool,
  entryID: PropTypes.number,
  fetchReferences: PropTypes.func,
  hasReferences: PropTypes.bool,
  imageSizes: PropTypes.array,
  isCombinationProduct: PropTypes.bool,
  name: PropTypes.string,
  neutralDeliveryPossible: PropTypes.bool,
  pegiValue: PropTypes.string,
  priceUpdated: PropTypes.bool,
  productOrderable: PropTypes.bool,
  productReferences: PropTypes.array,
  productServices: PropTypes.array,
  quantity: PropTypes.number,
  releaseDate: PropTypes.string,
  removeFromCart: PropTypes.func,
  shouldFetchReferences: PropTypes.bool,
  stockUpdated: PropTypes.bool,
  cartStatusCode: PropTypes.string,
};

export default ProductCart;
