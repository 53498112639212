import React, { useMemo, useState } from 'react';
import styles from './FilterSelectboxNew.scss';
import cx from 'classnames';
import InputText from 'components/atoms/InputText/InputText';
import language from 'constants/language/language';
import InputCheckbox from 'components/atoms/InputCheckbox/InputCheckbox';
import { FacetPropType } from 'constants/facetsHelper/facetsHelper';
import PropTypes from 'prop-types';

const FilterSelectboxNew = ({ facet, prefetchedFacet, prefetchFilter }) => {
  const [filter, setFilter] = useState(facet?.selectedValues);

  const selectFacetValue = (value, index) => {
    const newFilter = filter.some(({ code }) => code === value.code)
      ? filter.filter(({ code }) => code !== value.code)
      : [...filter, value];

    setFilter(newFilter);
    prefetchFilter({ filter: newFilter, facetCode: facet.code, innerlocation: index });
  };

  const [filterTerm, setFilterTerm] = useState('');
  const facetValues = useMemo(() => {
    return !filterTerm
      ? facet.values
      : facet.values.filter(({ name }) => name.toLowerCase().startsWith(filterTerm?.toLowerCase()));
  }, [facet.values, filterTerm]);

  const wrapperStyle = cx(styles.facetList);
  return (
    <div className={wrapperStyle}>
      <div className={cx(styles.facetListContainer)}>
        {facet.values?.length >= 9 && (
          <InputText
            type="text"
            placeholder={language('filterSelectbox.search')}
            value={filterTerm}
            handleChange={setFilterTerm}
          />
        )}
        <div className={cx(styles.wrapper)}>
          {!facetValues.length ? (
            <div className={styles.noResultsContainer}>
              <p>{language('filterSelectbox.noResults')}</p>
            </div>
          ) : (
            facetValues.map((facetValue, index) => {
              const prefetchedValue = prefetchedFacet?.values?.find(({ code }) => code === facetValue.code);
              return (
                <InputCheckbox
                  key={facetValue.code}
                  selected={filter.some(({ code }) => code === facetValue.code)}
                  onClick={() => selectFacetValue(facetValue, index)}
                  disabled={!prefetchedValue}
                >
                  <div>
                    <span>{facetValue.name}</span>
                    <span className={styles.facetListItemCount}>{` ${
                      prefetchedValue?.count ? `(${prefetchedValue.count})` : ''
                    }`}</span>
                  </div>
                </InputCheckbox>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

FilterSelectboxNew.propTypes = {
  facet: FacetPropType.isRequired,
  prefetchedFacet: FacetPropType.isRequired,
  prefetchFilter: PropTypes.func.isRequired,
};
FilterSelectboxNew.displayName = 'atoms/FilterSelectboxNew';

export default FilterSelectboxNew;
