import { Component, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

export const mapStateToProps = (state) => ({
  isLoggedIn: state.user.uid !== 'anonymous',
});

export class LoginIndicator extends Component {
  static propTypes = {
    isLoggedIn: PropTypes.bool,
    children: PropTypes.element.isRequired,
  };

  static defaultProps = {
    cartEntries: [],
    isLoggedIn: false,
  };

  render() {
    return cloneElement(this.props.children, {
      isLoggedIn: this.props.isLoggedIn,
    });
  }
}

export default connect(mapStateToProps)(LoginIndicator);
