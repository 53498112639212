// @flow
import {
  LOAD_PRODUCT,
  LOAD_PRODUCTS,
  LOAD_PRODUCT_LIST,
  ROUTER_UPDATE_LOCATION_NEED,
  SEARCH_AS_YOU_TYPE,
  SUGGEST_AS_YOU_TYPE,
  CLEAR_SEARCH_AS_YOU_TYPE,
  GET_PRODUCT_ACCESSORIES,
  STARTED_PRODUCT_SEARCH,
  SET_BRAND,
  GET_PRODUCT_WALL,
  GET_CATEGORY_WALL,
  ON_VIDEO_AVAILABLE,
  GET_TIME_CRITICAL_PRODUCT_DATA,
  GET_STOCK_INFO,
  RESET_STOCK_INFO,
} from 'constants/ActionTypes/ActionTypes';
import {
  getProduct,
  fetchProducts,
  fetchProductList,
  searchAsYouType,
  suggestAsYouType,
  fetchProductAccessories,
  fetchProductWall,
  fetchCategoryWall,
  fetchTimeCriticalProductData,
  fetchStockInfo,
} from 'api/ProductAPI/ProductAPI';
import {
  mergeQueryString,
  mapUrlToQueryParams,
  mapQueryParamsToQueryString,
  mapQueryStringToQueryParams,
} from 'constants/urlMapping/urlMapping';

import CategoryAPI from 'api/CategoryAPI/CategoryAPI';
import { getFeatureToggleConfig } from 'hooks/useFeatureToggle';

type queryParamsType = {
  facetQuery?: string,
  page?: number,
  searchString?: string,
  sort?: string,
  categoryCode?: string,
};

/**
 * Action creator which returns the product detail information
 *
 * @param params.productCode
 */
export function loadProductNeed(params: Object = {}) {
  return {
    type: LOAD_PRODUCT,
    payload: { productCode: params.productCode },
    promise: getProduct(params.productCode),
  };
}

/**
 * Action creator which returns the current Product Query. This function will be used for isomorphic rendering
 *
 * @param params.productCode
 */
export function routerUpdateLocationNeed(_params, location: Object = { query: {} }) {
  const url = `${location.pathname}${location.search}`;

  return {
    type: ROUTER_UPDATE_LOCATION_NEED,
    payload: { url },
    promise: Promise.resolve(true),
  };
}

/**
 * Action creator which returns a list of products
 */
export function loadProductsNeed(_params, location: Object = { query: {} }) {
  const categories = CategoryAPI.provideInitialState().categories;
  const url = `${location.pathname}${location.search}`;
  const queryParams = mapUrlToQueryParams(url, categories);
  const queryString = mapQueryParamsToQueryString(queryParams);

  return {
    type: LOAD_PRODUCTS,
    payload: { queryString },
    promise: fetchProducts(queryString, queryParams),
  };
}

/**
 * Action creator which returns a list of products
 *
 * @param currentQueryString
 * @param changeQueryObject
 * @returns {{type: *, payload: {queryString: string}, promise: *}}
 */
export function loadProducts(currentQueryString: string, changeQueryObject?: queryParamsType) {
  const queryString =
    typeof changeQueryObject === 'undefined'
      ? currentQueryString
      : mergeQueryString(currentQueryString, changeQueryObject);
  const queryParams = mapQueryStringToQueryParams(queryString);
  const updatedQuery = new URLSearchParams(queryString);

  return {
    type: LOAD_PRODUCTS,
    payload: { queryString },
    promise: fetchProducts(updatedQuery.toString(), queryParams),
  };
}

/**
 * Action creator that returns an array of products
 *
 * @param {Array} productsToFetch - An array of product codes
 */
export const loadProductList = (productsToFetch: Array<Object>) => (dispatch, getState) => {
  const productCodes = productsToFetch && productsToFetch.map((product) => product.productId);

  if (!productCodes || !productCodes.length) {
    return;
  }

  const { ui } = getState();
  const { featureTogglingConfig } = ui;

  const isApiRoute = getFeatureToggleConfig(featureTogglingConfig, 'abApiRoute') === 'true';

  return dispatch({
    type: LOAD_PRODUCT_LIST,
    payload: { productsToFetch },
    promise: fetchProductList(productCodes, isApiRoute),
  });
};

export function loadProductSuggestions(searchTerm: string, additionalParameter = null) {
  return {
    type: SEARCH_AS_YOU_TYPE,
    payload: {
      searchTerm,
    },
    promise: searchAsYouType(searchTerm, additionalParameter),
  };
}

export function loadSuggestions(searchTerm: string, amount = undefined, addition = null) {
  return {
    type: SUGGEST_AS_YOU_TYPE,
    payload: {
      searchTerm,
    },
    promise: suggestAsYouType(searchTerm, amount, addition),
  };
}

export function clearProductSuggestions() {
  return {
    type: CLEAR_SEARCH_AS_YOU_TYPE,
  };
}

export function getProductAccessories(params: Object = {}) {
  return {
    type: GET_PRODUCT_ACCESSORIES,
    payload: { productCode: params.productCode },
    promise: fetchProductAccessories(params),
  };
}

export function getProductAccessoriesNeed(params: Object = {}) {
  return {
    type: GET_PRODUCT_ACCESSORIES,
    payload: params,
    promise: fetchProductAccessories(params),
  };
}

export function getProductWall(productCode: string) {
  return {
    type: GET_PRODUCT_WALL,
    payload: { productCode },
    promise: fetchProductWall(productCode),
  };
}

export function getProductWallNeed(params: Object = {}) {
  return {
    type: GET_PRODUCT_WALL,
    payload: { productCode: params.productCode },
    promise: fetchProductWall(params.productCode),
  };
}

export function getCategoryWall(categoryCode: string) {
  return {
    type: GET_CATEGORY_WALL,
    payload: { categoryCode },
    promise: fetchCategoryWall(categoryCode),
  };
}

export function getCategoryWallNeed(params: Object = {}) {
  return {
    type: GET_CATEGORY_WALL,
    payload: { categoryCode: params.categoryCode },
    promise: params.categoryCode ? fetchCategoryWall(params.categoryCode) : Promise.resolve(true),
  };
}

export function startedProductSearch() {
  return {
    type: STARTED_PRODUCT_SEARCH,
  };
}

export function setBrand(brand: string, pathname: string, search: string) {
  return {
    type: SET_BRAND,
    payload: {
      brand,
      pathname,
      search,
    },
  };
}

export const videoAvailable = (productCode: string) => ({ type: ON_VIDEO_AVAILABLE, productCode });

export function getTimeCriticalProductData(productCodes: string, isWall: boolean) {
  return {
    type: GET_TIME_CRITICAL_PRODUCT_DATA,
    payload: {
      productCodes,
      isWall,
    },
    promise: fetchTimeCriticalProductData(productCodes),
  };
}

export const getStockInfo = (productCode: string) => ({
  type: GET_STOCK_INFO,
  payload: { productCode },
  promise: fetchStockInfo(productCode),
});

export const resetStockInfo = () => ({
  type: RESET_STOCK_INFO,
});

const actions = {
  loadProductNeed,
  loadProductsNeed,
  loadProducts,
  routerUpdateLocationNeed,
  loadProductSuggestions,
  startedProductSearch,
  getProductAccessories,
  getProductAccessoriesNeed,
  getProductWall,
  getProductWallNeed,
  getCategoryWall,
  getCategoryWallNeed,
  videoAvailable,
  getTimeCriticalProductData,
  getStockInfo,
  resetStockInfo,
};

export default actions;
