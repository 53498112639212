import React from 'react';
import OrderDocuments from 'components/organisms/OrderDocuments/OrderDocuments';
import Breadcrumb, { types } from 'components/organisms/Breadcrumb/Breadcrumb';
import language from 'constants/language/language';

const Documents = () => (
  <>
    <Breadcrumb type={types.category} items={[{ name: language('orderDocuments.title') }]} />
    <OrderDocuments />
  </>
);

Documents.displayName = 'templates/Documents';

export default Documents;
