import React, { useEffect } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import useSearchKeyboardNavigation from 'hooks/useSearchKeyboardNavigation/useSearchKeyboardNavigation';

import { cypressAttributes } from 'constants/cypress/cypress';
import { getLocale } from 'constants/language/language';
import { getStringCategoriesName } from 'constants/categoryTree/categoryTree';
import SearchSuggestionList from 'components/molecules/SearchSuggestionList/SearchSuggestionList';
import ThemeSuggestionList from 'components/molecules/ThemeSuggestionList/ThemeSuggestionList';
import ErrorBoundary from 'containers/ErrorBoundary/ErrorBoundary';
import SearchHistoryContainer from 'containers/SearchHistoryContainer/SearchHistoryContainer';
import SearchHistory from 'components/organisms/SearchHistory/SearchHistory';

import { SUGGESTION_SEARCH } from 'constants/trackingAttributes/trackingAttributes';

import TeaserProductSmall from 'components/molecules/TeaserProductSmall/TeaserProductSmall';
import Col from 'components/atoms/Grid/Col';

import styles from './SearchSuggest.scss';
import SearchOverlayBox from 'components/atoms/SearchOverlayBox/SearchOverlayBox';

const SearchSuggest = ({
  autocomplete = [],
  searchTerm = '',
  directlinks = [],
  products = [],
  categories = {},
  clearProductSuggestions = () => {},
  closeSearch = () => {},
  trackSearch = () => {},
  addHistoryEntry = () => {},
  setSearchString = () => {},
}) => {
  const suggestions = [...autocomplete, ...directlinks];
  const hasProducts = products.length > 0;
  const hasSuggestions = autocomplete.length > 0 || directlinks.length > 0;
  const [activeIndex, { setProductSuggestions, setLinkSuggestions, setTermSuggestions }] = useSearchKeyboardNavigation(
    clearProductSuggestions,
    closeSearch,
    setSearchString,
    addHistoryEntry
  );

  useEffect(() => {
    setTermSuggestions(autocomplete);
    setLinkSuggestions(directlinks);
    setProductSuggestions(products);
  }, [autocomplete, directlinks, products]);

  const onProductClick = (product) => {
    trackSearch(product.name, SUGGESTION_SEARCH);
    closeSearch();
  };

  return (
    <>
      <div className={styles.overlay} onClick={clearProductSuggestions} />
      {(hasProducts || hasSuggestions) && (
        <SearchOverlayBox hasItems>
          <>
            {hasSuggestions && (
              <Col md={hasProducts ? 6 : 12} sm={12} className={styles.suggestionCol}>
                {autocomplete.length !== 0 && (
                  <SearchSuggestionList
                    autocomplete={autocomplete}
                    searchTerm={searchTerm}
                    activeIndex={activeIndex}
                    closeSearch={closeSearch}
                    trackSearch={trackSearch}
                    addHistoryEntry={addHistoryEntry}
                  />
                )}

                {directlinks.length !== 0 && (
                  <>
                    <hr className={styles.horizontalRule} />
                    <ThemeSuggestionList
                      directlinks={directlinks}
                      closeSearch={closeSearch}
                      activeIndex={activeIndex}
                      searchTerm={searchTerm}
                      trackSearch={trackSearch}
                      autocompleteLength={autocomplete.length}
                      addHistoryEntry={addHistoryEntry}
                    />
                  </>
                )}
              </Col>
            )}
            {hasProducts && (
              <Col md={hasSuggestions ? 6 : 12} sm={12} className={styles.suggestionCol}>
                {hasSuggestions && <hr className={cx(styles.productHorizontalRule)} />}
                <ul className={styles.ProductsContainer} data-cy={cypressAttributes.search.searchSuggestions}>
                  {products.map((product, i) => (
                    <li key={product.code}>
                      <TeaserProductSmall
                        name={product.name}
                        code={product.code}
                        customImageData={product.customImageData}
                        categoryCode={product.categoryCode}
                        categoryName={getStringCategoriesName(product.categoryCode, getLocale(), categories)}
                        price={product.productPriceData}
                        searchTerm={searchTerm}
                        isActive={i + suggestions.length === activeIndex}
                        productClick={() => onProductClick(product)}
                        categories={categories}
                      />
                    </li>
                  ))}
                </ul>
              </Col>
            )}
          </>
        </SearchOverlayBox>
      )}
      {/* Search history component has own Row */}
      {searchTerm.length < 2 && !hasSuggestions && !hasProducts && (
        <ErrorBoundary passedFallBack={<></>}>
          <SearchHistoryContainer closeSearch={closeSearch}>
            <SearchHistory />
          </SearchHistoryContainer>
        </ErrorBoundary>
      )}
    </>
  );
};

SearchSuggest.displayName = 'organisms/SearchSuggest';

SearchSuggest.propTypes = {
  autocomplete: PropTypes.array,
  clearProductSuggestions: PropTypes.func.isRequired,
  searchTerm: PropTypes.string,
  variant: PropTypes.number,
  directlinks: PropTypes.array,
  products: PropTypes.array,
  productClick: PropTypes.func,
  categories: PropTypes.object,
  closeSearch: PropTypes.func,
  trackSearch: PropTypes.func,
  addHistoryEntry: PropTypes.func,
  setSearchString: PropTypes.func,
};
SearchSuggest.defaultProps = {
  directlinks: [],
  autocomplete: [],
  searchTerm: '',
  isSticky: false,
  productClick: () => {},
  categories: {},
  closeSearch: () => {},
};

export default SearchSuggest;
