import React from 'react';
import PropTypes from 'prop-types';
import SvgContainer from 'components/atoms/SvgContainer/SvgContainer';

const renderPath = [
  <path
    key={'path'}
    // eslint-disable-next-line max-len
    d="M56.7 114.1v38h38v-38h-38zm34.5 23.4H80.1v11.1h-8.8v-11.1h-11v-8.8h11.1v-11.1h8.8v11.1h11.1c-.1 0-.1 8.8-.1 8.8zm-34.5 25h83.4v108H56.7v-108zm96.2 15.3h114.8v12.8H152.9v-12.8zm0 21.1h90.3v12.8h-90.3v-12.8zm0 21.9h114.8v12.8H152.9v-12.8zm0 21h90.3v12.8h-90.3v-12.8zm153.8-85.1c-19.7 0-35.8-10.6-35.8-23.6s16-23.6 35.8-23.6 35.8 10.6 35.8 23.6-16.1 23.6-35.8 23.6zm0-43.2c-17.5 0-31.7 8.8-31.7 19.6s14.2 19.6 31.7 19.6 31.7-8.8 31.7-19.6-14.2-19.6-31.7-19.6z"
  />,
  <path
    key={'path2'}
    // eslint-disable-next-line max-len
    d="M299.3 127.1c-1.5 0-2.6.5-3.3 1.6-.8 1.1-1.2 2.6-1.2 4.5 0 4 1.5 6.1 4.5 6.1 1.3 0 2.8-.3 4.6-.9v3.3c-1.5.6-3.2.9-5 .9-2.7 0-4.6-.8-6.1-2.4-1.4-1.6-2.1-3.9-2.1-7 0-1.9.3-3.5 1-5s1.7-2.5 3-3.3c1.3-.8 2.8-1.1 4.5-1.1 1.8 0 3.5.4 5.3 1.3l-1.3 3.2-2.1-.9c-.5-.2-1.2-.3-1.8-.3zm23.4 15.2h-3.9v-7.9h-7.2v7.9h-3.9v-18.2h3.9v7.1h7.2v-7.1h3.9v18.2z"
  />,
  <path
    key={'path3'}
    // eslint-disable-next-line max-len
    d="M383.7 56.5H73.1c-8.9 0-16.1 7.2-16.1 16.1v15.2H44.2C35.3 87.8 28 95.1 28 104v189.8c0 8.9 7.2 16.1 16.1 16.1h310.6c8.9 0 16.1-7.2 16.1-16.1v-15.2h12.8c8.9 0 16.1-7.2 16.1-16.1V72.7c.2-8.9-7.1-16.2-16-16.2zm-19.5 237.2c0 5.2-4.2 9.4-9.4 9.4H44.2c-5.2 0-9.4-4.2-9.4-9.4V104c0-5.2 4.2-9.4 9.4-9.4h310.6c5.2 0 9.4 4.2 9.4 9.4v189.7zm29-31.3c0 5.2-4.2 9.4-9.4 9.4H371V104c0-8.9-7.2-16.1-16.1-16.1H63.7V72.7c0-5.2 4.2-9.4 9.4-9.4h310.6c5.2 0 9.4 4.2 9.4 9.4v189.7z"
  />,
];
const vbWidth = 400;
const vbHeight = 400;

const IconCHIDCopy = ({ height }) => (
  <SvgContainer height={height} vbWidth={vbWidth} vbHeight={vbHeight}>
    {renderPath}
  </SvgContainer>
);

IconCHIDCopy.displayName = 'atoms/IconCHIDCopy';

IconCHIDCopy.propTypes = {
  height: PropTypes.number,
};

IconCHIDCopy.defaultProps = {
  height: 32,
};

export default IconCHIDCopy;
