import React from 'react';
import PropTypes from 'prop-types';
import ComparisonListRow from 'components/molecules/ComparisonListRow/ComparisonListRow';

const ComparisonList = ({ comparisonList = {}, productNames = [] }) => (
  <>
    {comparisonList.classifications?.map((classification, index) => (
      <ComparisonListRow key={index} classification={classification} productNames={productNames} />
    ))}
  </>
);

ComparisonList.displayName = 'molecules/ComparisonList';
ComparisonList.propTypes = {
  comparisonList: PropTypes.object,
  productNames: PropTypes.array,
};
ComparisonList.defaultProps = {
  comparisonList: {},
  productNames: [],
};

export default ComparisonList;
