import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Recaptcha from 'react-recaptcha';

import FieldSetInput from 'components/molecules/FieldSetInput/FieldSetInput';
import ErrorMessageInput from 'components/molecules/FieldSetInput/ErrorMessageInput';
import InputSelect from 'components/atoms/InputSelect/InputSelect';
import InputTextarea from 'components/atoms/InputTextarea/InputTextarea';
import InputText from 'components/atoms/InputText/InputText';
import MandatoryFieldInfo from 'components/atoms/MandatoryFieldInfo/MandatoryFieldInfo';
import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';
import UserAPI from 'api/UserAPI/UserAPI';
import language, { getLocale } from 'constants/language/language';
import {
  generateInputTopicOptions,
  validateTopic,
  validateMessage,
  validateEmail,
  validateRecaptcha,
} from './FeedbackForm.helpers';
import { ValidationTypes } from 'constants/validation/validation';
import { recaptchaAreas } from 'constants/recaptcha/recaptchaAreas';
import { clientProductReportTopics } from 'constants/productReportTypes/productReportTypes';
import { scrollFirstErrorIntoView } from 'constants/scrollToElement/scrollToElement';
import styles from './FeedbackForm.scss';

let recaptchaInstance;
// $FlowFixMe
const recaptchaKey = __CLIENT__ && window.__ENV_VARIABLES__.recaptchaKey;

class FeedbackForm extends Component {
  state = {
    topic: '',
    message: '',
    emailInput: this.props.email || '',
    // Set validation to valid to initially hide errors
    topicValidation: ValidationTypes.Valid,
    messageValidation: ValidationTypes.Valid,
    emailValidation: ValidationTypes.Valid,
    recaptchaValidation: ValidationTypes.Pending,
  };

  onCaptchaVerify = async (response) => {
    try {
      const recaptchaResponse = await UserAPI.verifyRecaptcha(
        response,
        this.state.emailInput || 'anonymous',
        recaptchaAreas.productReport
      );
      if (recaptchaResponse.data.success) this.setState({ recaptchaValidation: ValidationTypes.Valid });
    } catch (e) {
      this.setState({ recaptchaValidation: ValidationTypes.Invalid });
      recaptchaInstance.reset();
    }
  };

  setValidationResults = (cb) => {
    this.setState(
      (state) => ({
        topicValidation: validateTopic(state.topic),
        emailValidation: validateEmail(state.emailInput),
        messageValidation: validateMessage(state.message),
        recaptchaValidation: validateRecaptcha(state.recaptchaValidation, this.props.loggedIn),
      }),
      cb
    );
  };

  updateTopic = (topic) => {
    this.setState({
      topic,
      topicValidation: validateTopic(topic),
    });
  };

  updateMessage = (message) => {
    this.setState({
      message,
      messageValidation: validateMessage(message),
    });
  };

  updateEmail = (newEmail) => {
    this.setState({
      emailInput: newEmail,
      emailValidation: validateEmail(newEmail),
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setValidationResults(() => {
      if (
        this.state.topic !== '' &&
        this.state.topicValidation === ValidationTypes.Valid &&
        this.state.message !== '' &&
        this.state.messageValidation === ValidationTypes.Valid &&
        this.state.emailValidation === ValidationTypes.Valid &&
        this.state.recaptchaValidation === ValidationTypes.Valid
      ) {
        this.props.submitFeedback({ ...this.state, email: this.state.emailInput });
      } else {
        scrollFirstErrorIntoView();
      }
    });
  };

  render() {
    const { onCancelClick, loggedIn } = this.props;
    const { topic, message, emailInput } = this.state;

    return (
      <form className={styles.feedbackForm} onSubmit={this.handleSubmit}>
        <fieldset>
          <FieldSetInput
            name="feedbackFormTopic"
            value={topic}
            options={generateInputTopicOptions(clientProductReportTopics)}
            handleChange={this.updateTopic}
            showError={this.state.topicValidation !== ValidationTypes.Valid}
            validationResult={this.state.topicValidation}
            forceValidation
            required
            placeholder={language('feedbackForm.topic.placeholder')}
          >
            <InputSelect />
          </FieldSetInput>
          <FieldSetInput
            name="feedbackFormMessage"
            label={language('feedbackForm.message.label')}
            value={message}
            handleChange={this.updateMessage}
            showError={this.state.messageValidation !== ValidationTypes.Valid}
            validationResult={this.state.messageValidation}
            forceValidation
            required
          >
            <InputTextarea debounce={false} maxLength={500} />
          </FieldSetInput>
          <FieldSetInput
            name="feedbackFormEmail"
            label={language('feedbackForm.email.label')}
            value={emailInput}
            handleChange={this.updateEmail}
            showError={this.state.emailValidation !== ValidationTypes.Valid}
            validationResult={this.state.emailValidation}
            forceValidation
            showTooltip
          >
            <InputText />
          </FieldSetInput>
        </fieldset>
        {!loggedIn && (
          <>
            <Recaptcha
              ref={(e) => (recaptchaInstance = e)} // eslint-disable-line
              sitekey={recaptchaKey}
              verifyCallback={this.onCaptchaVerify}
              hl={getLocale()}
            />
            <ErrorMessageInput showError={this.state.recaptchaValidation === ValidationTypes.Invalid}>
              {language('feedbackForm.recaptchaError')}
            </ErrorMessageInput>
          </>
        )}
        <div className={styles.feedbackControls}>
          <MandatoryFieldInfo />
          <div className={styles.feedbackCancelWrapper}>
            <span onClick={onCancelClick}>{language('feedbackForm.cancel')}</span>
            <ButtonLink variety={ButtonLink.varieties.flat} type="submit">
              {language('feedbackForm.submit')}
            </ButtonLink>
          </div>
        </div>
      </form>
    );
  }
}

FeedbackForm.propTypes = {
  onCancelClick: PropTypes.func,
  submitFeedback: PropTypes.func,
  email: PropTypes.string,
  loggedIn: PropTypes.bool,
};

FeedbackForm.defaultProps = {
  email: '',
  onCancelClick: () => {},
  submitFeedback: () => {},
};

export default FeedbackForm;
