import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { scroller } from 'react-scroll';
import cx from 'classnames';

import language from 'constants/language/language';
import getFeatureValues from 'constants/getFeatureValues/getFeatureValues';

import styles from './ProductFeatureMain.scss';

export const CONTEXTS = {
  DETAIL: 'DETAIL',
  OVERVIEW: 'OVERVIEW',
};

const getClassificationFeatures = (classificationClasses) =>
  classificationClasses.reduce((acc, { features }) => [...acc, ...features], []);

const getTopFeatures = (features) => {
  const filter = (feature) => {
    if (feature?.mainProductFeature) {
      return feature;
    }
    return null;
  };

  const compare = (f1, f2) => {
    if (f1.mainProductFeature < f2.mainProductFeature) {
      return -1;
    }
    if (f1.mainProductFeature > f2.mainProductFeature) {
      return 1;
    }
    return 0;
  };

  return features.filter(filter).sort(compare).slice(0, 4);
};

const scrollToClassifications = () => {
  scroller.scrollTo('classifications', {
    duration: 700,
    delay: 100,
    smooth: true,
    offset: -170,
  });
};

const ProductFeatureMain = ({ context, classifications }) => {
  let topFeatures = [];
  let features = [];

  if (context === CONTEXTS.DETAIL) {
    features = getClassificationFeatures(classifications);
    topFeatures = getTopFeatures(features);
  } else if (context === CONTEXTS.OVERVIEW) {
    topFeatures = classifications;
  }

  const renderMoreLink = context === CONTEXTS.DETAIL && features.length > 4;

  if (!topFeatures.length && !renderMoreLink) {
    return null;
  }

  return (
    <div className={cx(styles.ProductFeatureMain, styles[context])}>
      <ul className={styles.list}>
        {topFeatures.map((feature) => (
          <li className={cx(styles.FeatureRow, styles[context])} key={feature.name}>
            <strong className={cx(styles.ProductFeatureAttribute, styles[context])}>{feature.name}</strong>
            <span className={cx(styles.ProductFeatureValue, styles[context])}>
              <span className={styles.ProductFeatureSpacer}>
                {getFeatureValues([...feature.featureValues], feature.range)}
              </span>
              {feature?.featureUnit?.name}
            </span>
          </li>
        ))}
      </ul>
      {renderMoreLink && (
        <div className={styles.moreDetailsContainer}>
          <Link onClick={scrollToClassifications}>{language('productFeatures.moreDetails')}</Link>
        </div>
      )}
    </div>
  );
};

ProductFeatureMain.displayName = 'molecules/ProductFeatureMain';

ProductFeatureMain.propTypes = {
  classifications: PropTypes.arrayOf(PropTypes.object).isRequired,
  context: PropTypes.oneOf(Object.values(CONTEXTS)),
};

ProductFeatureMain.defaultProps = {
  context: CONTEXTS.DETAIL,
};

export default ProductFeatureMain;
