import { Component, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

export const mapStateToProps = (state, ownProps) => {
  const productCode = ownProps?.productCode;
  const blogPosts = state?.cms?.blogs?.[productCode];

  return {
    blogPosts,
  };
};

class ProductBlog extends Component {
  static displayName = 'containers/ProductBlog';

  static propTypes = {
    children: PropTypes.node.isRequired,
    productCode: PropTypes.string,
    blogPosts: PropTypes.object,
  };

  static defaultProps = {
    productCode: '',
    blogPosts: {},
  };

  render() {
    const { children, blogPosts } = this.props;

    const posts = blogPosts?.posts || [];
    const title = blogPosts?.title || '';

    if (posts.length > 0) {
      return cloneElement(children, {
        title,
        posts,
      });
    }
    return null;
  }
}

export default connect(mapStateToProps)(ProductBlog);
