import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/atoms/Icon/Icon';
import InternalOrExternalLink from 'components/atoms/InternalOrExternalLink/InternalOrExternalLink';
import cx from 'classnames';
import { cypressAttributes } from 'constants/cypress/cypress';

import styles from './SubNavigationItem.scss';

const SubnavigationItem = ({ text, link, onClick, isActive, icon }) => (
  <li
    className={cx(styles.SubNavigationItem, {
      [styles.active]: isActive,
    })}
    data-cy={cypressAttributes.account.accountMenuItem}
  >
    {link ? (
      <InternalOrExternalLink href={link}>
        {!!icon && <Icon className={styles.icon} path={icon} />}
        <span itemProp="name">{text}</span>
      </InternalOrExternalLink>
    ) : (
      <div className={styles.logoutItem} onClick={onClick} onKeyPress={onClick} role="link" tabIndex="0">
        {!!icon && <Icon className={styles.icon} path={icon} />}
        <span itemProp="name">{text}</span>
      </div>
    )}
  </li>
);

SubnavigationItem.displayName = 'atoms/SubNavigationItem';
SubnavigationItem.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.string,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  icon: PropTypes.string,
};

export default SubnavigationItem;
