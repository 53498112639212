import React from 'react';
import { Route } from 'react-router';
import {
  INDIVIDUAL_ADS,
  INDIVIDUAL_ADS_UNSUBSCRIBE,
  NEWSLETTER,
  NEWSLETTER_OPTOUT,
  NEWSLETTER_OPTIN,
  NEWSLETTER_FEEDBACK_PARAMS,
  NEWSLETTER_VERIFICATION,
  NEWSLETTER_VERIFICATION_TOKEN,
} from 'constants/routePaths/routePaths';

import IndividualAdsOptout from 'routes/IndividualAdsOptout/IndividualAdsOptout';
import DefaultLayout from 'components/layouts/DefaultLayout/DefaultLayout';
import NewsletterOptout from 'routes/NewsletterOptout/NewsletterOptout';
import Newsletter from 'routes/Newsletter/Newsletter';
import NewsletterVerification from 'routes/NewsletterVerification/NewsletterVerification';
import NewsletterOptin from 'routes/NewsletterOptin/NewsletterOptin';

export default function createNewsletterRoutes() {
  return (
    <Route>
      <Route component={DefaultLayout}>
        <Route path={`${INDIVIDUAL_ADS}/${INDIVIDUAL_ADS_UNSUBSCRIBE}`} component={IndividualAdsOptout} />
        <Route path={`${NEWSLETTER}/${NEWSLETTER_OPTOUT}`} component={NewsletterOptout} />
        <Route path={`${NEWSLETTER}/${NEWSLETTER_OPTIN}`} component={NewsletterOptin} />
        <Route path={`${NEWSLETTER}/${NEWSLETTER_FEEDBACK_PARAMS}`} component={Newsletter} />
        <Route
          path={`${NEWSLETTER}/${NEWSLETTER_VERIFICATION}/${NEWSLETTER_VERIFICATION_TOKEN}`}
          component={NewsletterVerification}
        />
      </Route>
    </Route>
  );
}
