import React from 'react';
import PropTypes from 'prop-types';
import OpeningHoursTimeRange from 'components/atoms/OpeningHoursTimeRange/OpeningHoursTimeRange';
import language from 'constants/language/language';
import styles from './OpeningHoursRegular.scss';

const WeekDayOpeningHours = ({ weekDay, closed, openingDayTimeRangeDataList }) => (
  <tr>
    <th className={styles.day}>{weekDay}</th>
    <td className={styles.hours}>
      {closed
        ? language('pickupStore.closed')
        : !!openingDayTimeRangeDataList && (
            <OpeningHoursTimeRange
              openingDayTimeRangeDataList={openingDayTimeRangeDataList}
              formattingStyleDot={true}
            />
          )}
    </td>
  </tr>
);

WeekDayOpeningHours.displayName = 'atoms/WeekDayOpeningHours';
WeekDayOpeningHours.propTypes = {
  weekDay: PropTypes.string.isRequired,
  closed: PropTypes.bool.isRequired,
  openingDayTimeRangeDataList: PropTypes.array.isRequired,
};

export default WeekDayOpeningHours;
