import React from 'react';
import PropTypes from 'prop-types';

import InteractionIcon from 'components/molecules/ProductInteractions//InteractionIcon';
import { ProductInteractionButton } from 'components/molecules/ProductInteractions/ProductInteractionButton';
import Icon from 'components/atoms/Icon/Icon';

import { dataTrackingRaw } from 'constants/trackingAttributes/dataTracking';
import { noop } from 'constants/noop/noop';
import { trackInteraction } from 'tracking/tracking';

import styles from './ProductInteractions.scss';

const ListInteraction = ({
  children,
  addProductToList = noop,
  removeProductFromList = noop,
  addedToList,
  addedIcon,
  productCode,
  trackingType,
  cyData,
  isDisabled = false,
}) => {
  const handleButtonClick = () => {
    const trackingData = dataTrackingRaw({
      TYPE: trackingType,
      ATTRIBUTE_1: addedToList ? 'remove' : 'add',
      SKU: productCode,
    });
    trackInteraction(trackingData);

    if (!addedToList) {
      addProductToList(productCode);
    } else {
      removeProductFromList(productCode);
    }
  };

  return (
    <ProductInteractionButton
      onClick={handleButtonClick}
      className={styles.iconLink}
      isAdded={addedToList}
      cyData={cyData ? cyData[0] : null}
      isDisabled={isDisabled}
    >
      <InteractionIcon
        isAdded={addedToList}
        size={Icon.sizes.sm}
        addedIcon={addedIcon}
        cyData={cyData ? cyData[1] : null}
      />
      <div className={styles.iconText}>{children}</div>
    </ProductInteractionButton>
  );
};

ListInteraction.propTypes = {
  children: PropTypes.node,
  addProductToList: PropTypes.func,
  removeProductFromList: PropTypes.func,
  addedToList: PropTypes.bool,
  addedIcon: PropTypes.string,
  productCode: PropTypes.string,
  trackingType: PropTypes.string,
  cyData: PropTypes.array,
  isDisabled: PropTypes.bool,
};

ListInteraction.displayName = 'molecules/ProductInteractions/ListInteraction';

export default ListInteraction;
