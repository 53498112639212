import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import language from 'constants/language/language';
import MarkdownBox from 'components/molecules/MarkdownBox/MarkdownBox';
import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';
import Headline from 'components/atoms/Headline/Headline';
import bootstrap from 'scss/component.scss';
import styles from './CmsTextComponent.scss';

export const buttonSkins = {
  STANDARD: ButtonLink.varieties.flat,
  GHOST: ButtonLink.varieties.stroked,
};

const CmsTextComponent = ({ title = '', text = '', button = {} }) => {
  let ButtonElement = null;
  if (button && button.text && button.link) {
    const buttonSkin = (button.format && buttonSkins[button.format]) || buttonSkins.GHOST;
    ButtonElement = () => (
      <ButtonLink
        href={button.link}
        openNewTab={button.openNewTab}
        variety={buttonSkin}
        color={button.format === 'STANDARD' ? ButtonLink.colors.red : ButtonLink.colors.black}
        size={ButtonLink.sizes.sm}
        isExternalLink={button.externalLink}
      >
        <span>{button.text}</span>
      </ButtonLink>
    );
  }

  if (!title && !text && !ButtonElement) {
    return <div>{language('cmsFallback.missingContent')}</div>;
  }

  return (
    <div className={cx(bootstrap.row)}>
      <div className={bootstrap.colMd12}>
        {title && (
          <Headline tag={Headline.tags.H2} margin={Headline.margins.MB_10}>
            {title}
          </Headline>
        )}
        {text && <MarkdownBox markdown={text} />}
        {ButtonElement && (
          <div className={styles.bottomButton}>
            <ButtonElement />
          </div>
        )}
      </div>
    </div>
  );
};

CmsTextComponent.displayName = 'organisms/CmsTextComponent';

CmsTextComponent.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  button: PropTypes.object,
};

export default CmsTextComponent;
