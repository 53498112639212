import React from 'react';
import LazyLoad from 'react-lazyload';
import language, { getLocale } from 'constants/language/language';
import LogoCoop from 'components/atoms/logos/LogoCoop';
import { Col, Container, Row } from 'components/atoms/Grid/Grid';
import styles from './BrandFooter.scss';

const BrandFooter = () => (
  <Container>
    <Row>
      <Col className={styles.brandFooter}>
        <a href={`http://www.coop.ch/${getLocale()}`} title="coop.ch" target="_blank" rel="noreferrer">
          <LazyLoad once height={26}>
            <LogoCoop height={26} width={87} className={styles.coopLogo} altText={language('footer.coopName')} />
          </LazyLoad>
        </a>
        <small className={styles.coopText}>{language('footer.coop')}</small>
      </Col>
    </Row>
  </Container>
);

BrandFooter.displayName = 'molecules/BrandFooter';

export default BrandFooter;
