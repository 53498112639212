import { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { removeFromCart as removeFromCartAction } from 'actions/cartActions/cartActions';
import { getProductAccessories } from 'actions/productActions/productActions';

export const mapStateToProps = (state, ownProps) => {
  const productCode = ownProps.code;
  const product = state.products?.[productCode] || {};
  const productReferences = product.referenceItemCodes || [];
  return {
    categories: state.categories,
    productReferences,
    shouldFetchReferences: !product.hasAllReferences,
  };
};
export const mapDispatchToProps = {
  fetchReferences: (productCode) => getProductAccessories({ productCode }),
  removeFromCart: removeFromCartAction,
};

const ProductAccessories = ({
  apiStatus = '',
  availabilityStatus = '',
  bulkyGoods = false,
  cartEntryCodes = [],
  categories = {},
  categoryCode,
  children,
  code,
  discountUpdated = false,
  displaySpeedFlag = false,
  entryID,
  fetchReferences = () => {},
  hasReferences = false,
  imageSizes = [],
  name,
  neutralDeliveryPossible = false,
  pegiValue = '',
  priceUpdated = false,
  productOrderable = false,
  productReferences = [],
  productServices = [],
  releaseDate = '',
  removeFromCart = () => {},
  shouldFetchReferences = false,
  stockUpdated = false,
}) =>
  cloneElement(children, {
    apiStatus,
    availabilityStatus,
    bulkyGoods,
    cartEntryCodes,
    categories,
    categoryCode,
    code,
    discountUpdated,
    displaySpeedFlag,
    entryID,
    fetchReferences,
    hasReferences,
    imageSizes,
    name,
    neutralDeliveryPossible,
    pegiValue,
    priceUpdated,
    productOrderable,
    productReferences,
    productServices,
    releaseDate,
    removeFromCart,
    shouldFetchReferences,
    stockUpdated,
  });

ProductAccessories.displayName = 'containers/ProductAccessories';
ProductAccessories.propTypes = {
  apiStatus: PropTypes.string,
  availabilityStatus: PropTypes.string,
  bulkyGoods: PropTypes.bool,
  cartEntryCodes: PropTypes.array,
  categories: PropTypes.object,
  categoryCode: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  code: PropTypes.string.isRequired,
  discountUpdated: PropTypes.bool,
  displaySpeedFlag: PropTypes.bool,
  entryID: PropTypes.number.isRequired,
  fetchReferences: PropTypes.func,
  hasReferences: PropTypes.bool,
  imageSizes: PropTypes.array,
  name: PropTypes.string.isRequired,
  neutralDeliveryPossible: PropTypes.bool,
  pegiValue: PropTypes.string,
  priceUpdated: PropTypes.bool,
  productOrderable: PropTypes.bool,
  productReferences: PropTypes.array,
  productServices: PropTypes.array,
  releaseDate: PropTypes.string,
  removeFromCart: PropTypes.func,
  shouldFetchReferences: PropTypes.bool,
  stockUpdated: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductAccessories);
