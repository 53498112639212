import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { subscribeToNewsletter as subscribeToNewsletterAction } from 'actions/newsletterActions/newsletterActions';
import { getUser as getUserAction } from 'actions/userActions/userActions';
import NewsletterRegistrationForm from 'components/organisms/NewsletterRegistrationForm/NewsletterRegistrationForm';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import { apiStatus } from 'constants/apiStatus/apiStatus';
import { getLocale } from 'constants/language/language';
import { NEWSLETTER } from 'constants/routePaths/routePaths';
import { newsletterFeedbackTypes } from 'constants/newsletterFeedbackTypes/newsletterFeedbackTypes';
import { noop } from 'constants/noop/noop';

export const mapStateToProps = (state) => {
  const anonymousUser = 'anonymous';
  const uid = state?.user?.uid || anonymousUser;
  const newsletterApiStatus = state?.user?.newsletterApiStatus || apiStatus.success;
  const isRegisteredToNewsletter = state?.user?.newsletter ?? false;
  const isClient = state?.ui?.isClient ?? false;
  const userFields = state?.user?.fields || undefined;

  return {
    isClient,
    userFields,
    isLoggedIn: uid !== anonymousUser,
    newsletterApiStatus,
    isRegisteredToNewsletter,
    uid,
  };
};

const mapDispatchToProps = {
  subscribeToNewsletter: subscribeToNewsletterAction,
  getUser: getUserAction,
};

const NewsletterRegistrationContainer = ({
  subscribeToNewsletter = noop,
  isLoggedIn = '',
  isClient = false,
  newsletterApiStatus = apiStatus.success,
  isRegisteredToNewsletter = false,
  getUser = noop,
  userFields = undefined,
  uid,
}) => {
  const redirectTo = (param) => {
    if (newsletterApiStatus === apiStatus.success) {
      browserHistory.push(mapPathToLocalizedUrl(getLocale(), [NEWSLETTER, param]));
    }
  };

  const submit = (emailValue, birthdayValue, titleCodeValue, firstNameValue, lastNameValue) => {
    const email = emailValue.trim();

    if (
      email !== '' &&
      birthdayValue &&
      titleCodeValue.trim() !== '' &&
      firstNameValue.trim() !== '' &&
      lastNameValue.trim() !== ''
    ) {
      subscribeToNewsletter(email, birthdayValue, titleCodeValue, firstNameValue, lastNameValue).then(() => {
        // Customer Feedback Template will only show, if the customer is registered and logged in.
        // If not, the subscribe template will be shown with the message, that an e-mail was sent.
        if (isLoggedIn) {
          getUser();
        }

        // When the customer enters an email that is not associated with the currently logged in account, they need to confirm the subscription.
        if (isLoggedIn && email === uid) {
          redirectTo(newsletterFeedbackTypes.CUSTOMER);
        } else {
          redirectTo(newsletterFeedbackTypes.SUBSCRIBE);
        }
      });
    }
  };

  if (isRegisteredToNewsletter || !isClient) {
    return null;
  }

  return <NewsletterRegistrationForm userFields={userFields} isLoggedIn={isLoggedIn} handleSubmit={submit} />;
};

NewsletterRegistrationContainer.propTypes = {
  subscribeToNewsletter: PropTypes.func,
  isLoggedIn: PropTypes.bool,
  isClient: PropTypes.bool,
  newsletterApiStatus: PropTypes.oneOf(Object.values(apiStatus)),
  isRegisteredToNewsletter: PropTypes.bool,
  getUser: PropTypes.func,
  userFields: PropTypes.object,
  uid: PropTypes.string,
};

NewsletterRegistrationContainer.displayName = 'containers/NewsletterRegistrationContainer';

export default connect(mapStateToProps, mapDispatchToProps)(NewsletterRegistrationContainer);
