import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'constants/noop/noop';
import ContactField from 'containers/ContactField/ContactField';
import { Row, Col } from 'components/atoms/Grid/Grid';
import InputSelect from 'components/atoms/InputSelect/InputSelect';
import InputText from 'components/atoms/InputText/InputText';
import FieldSetInput from 'components/molecules/FieldSetInput/FieldSetInput';
import NameFormPartial from './Contact.form.name.part';

const AddressFormPartial = ({ allowOnlyNumbers = noop }) => {
  return (
    <>
      <NameFormPartial />
      <Row>
        <Col sm={12} md={8}>
          <ContactField fieldName="addressLine1">
            <FieldSetInput>
              <InputText />
            </FieldSetInput>
          </ContactField>
        </Col>
        <Col sm={12} md={8}>
          <ContactField fieldName="addressLine2">
            <FieldSetInput>
              <InputText />
            </FieldSetInput>
          </ContactField>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={8}>
          <ContactField fieldName="postalCode">
            <FieldSetInput>
              <InputText type="tel" pattern="\d*" maxLength={4} onKeyPress={allowOnlyNumbers} />
            </FieldSetInput>
          </ContactField>
        </Col>
        <Col sm={12} md={8}>
          <ContactField fieldName="town">
            <FieldSetInput>
              <InputText />
            </FieldSetInput>
          </ContactField>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={8}>
          <ContactField fieldName="country">
            <FieldSetInput>
              <InputSelect />
            </FieldSetInput>
          </ContactField>
        </Col>
      </Row>
    </>
  );
};

AddressFormPartial.propTypes = {
  allowOnlyNumbers: PropTypes.func,
  telNumberValidateChar: PropTypes.func,
};

export default AddressFormPartial;
