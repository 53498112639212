import { isProductOrderable } from 'constants/deliveryStates/getDeliveryStatus';
import { stockLevelStatus } from 'constants/ProductStatus/ProductStatus';

/**
 * The list of the availability keys/status hold as value
 * their availability state for the structured data.
 *
 * Following values are possible:
 * - Discontinued
 * - InStock
 * - InStoreOnly
 * - LimitedAvailability
 * - OnlineOnly
 * - OutOfStock
 * - PreOrder
 * - PreSale
 * - SoldOut
 *
 * source: https://schema.org/ItemAvailability#enumbers
 */
export const seoAvailabilityStates = {
  A: 'InStock',
  B: 'InStock',
  C: 'InStock',
  D: 'InStock',
  E: 'OutOfStock',
  F: 'OutOfStock',
  G: 'OutOfStock',
  H: 'OutOfStock',
  I: 'OutOfStock',
  M: 'OnlineOnly',
  R: 'PreOrder',
  Y: 'PreOrder',
  Z: 'Discontinued',
};

export const availabilityStates = {
  A: 'inStock',
  B: 'inStock',
  C: 'inStock',
  D: 'inStock',
  E: 'onOrder',
  F: 'onOrder',
  G: 'onOrder',
  G2: 'unknown',
  H: 'onOrder',
  I: 'onOrder',
  M: 'immediately',
  R: 'reservation',
  Y: 'esd',
  Z: 'notInStock',
};

export const isProductAvailable = (availability) =>
  availability === availabilityStates.A || availability === availabilityStates.M;

export const getAvailabilityState = (product) => {
  const availability =
    product.availabilityStatus === stockLevelStatus.g &&
    !isProductOrderable(product.deliveryTime) &&
    !product.productOrderable &&
    !product.deliveryDate
      ? availabilityStates.G2
      : availabilityStates[product.availabilityStatus];

  const deliveryTime = {
    ...product.deliveryTime,
    value: product.deliveryDate ? product.deliveryDate : product.deliveryTime?.value,
  };

  return {
    availability,
    deliveryTime,
  };
};

export const isInStock = (availability) =>
  [availabilityStates.A, availabilityStates.B, availabilityStates.C, availabilityStates.D].includes(availability);

export const isOnOrder = (availability) =>
  [
    availabilityStates.E,
    availabilityStates.F,
    availabilityStates.G,
    availabilityStates.H,
    availabilityStates.I,
    availabilityStates.R,
  ].includes(availability);

export const isInStockOrOnOrder = (availability) => isInStock(availability) || isOnOrder(availability);
