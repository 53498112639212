// /* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

// Check if you can merge svg paths to one path via https://yqnn.github.io/svg-path-editor/
// If you have special Icons, which require another filling on a second path you can't merge the paths together
// When you have a special case where you have 2 paths with other fillings then just use the svg in that component
// To ADD a new Icon just copy the path value and add it here as a constant
// prettier-ignore
export const ICON_ACCESS_TIME = 'M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z';
export const ICON_ACCOUNT_CIRCLE =
  'M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z';
export const ICON_ACCOUNT_CIRCLE_OUTLINE =
  'M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33C4.62 15.49 4 13.82 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z';
export const ICON_ADD = 'M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z';
export const ICON_ADD_CIRCLE_OUTLINE =
  'M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z';
export const ICON_ARROW_BACK = 'M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z';
export const ICON_ARROW_FORWARD = 'M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z';
export const ARROW_DROPDOWN = 'M7 10l5 5 5-5z';
export const ICON_BUILD =
  'M22.61,18.99 L13.53,9.91 C14.46,7.57 13.98,4.81 12.09,2.91 C9.79,0.61 6.21,0.4 3.66,2.26 L7.5,6.11 L6.08,7.52 L2.25,3.69 C0.39,6.23 0.6,9.82 2.9,12.11 C4.76,13.97 7.47,14.46 9.79,13.59 L18.9,22.7 C19.29,23.09 19.92,23.09 20.31,22.7 L22.61,20.4 C23.01,20.02 23.01,19.39 22.61,18.99 Z M19.61,20.59 L10.15,11.13 C9.54,11.58 8.86,11.85 8.15,11.95 C6.79,12.15 5.36,11.74 4.32,10.7 C3.37,9.76 2.93,8.5 3,7.26 L6.09,10.35 L10.33,6.11 L7.24,3.02 C8.48,2.95 9.73,3.39 10.68,4.33 C11.76,5.41 12.17,6.9 11.92,8.29 C11.8,9 11.5,9.66 11.04,10.25 L20.49,19.7 L19.61,20.59 Z';
export const ICON_BUSINESS_CENTER =
  'M10 16v-1H3.01L3 19c0 1.11.89 2 2 2h14c1.11 0 2-.89 2-2v-4h-7v1h-4zm10-9h-4.01V5l-2-2h-4l-2 2v2H4c-1.1 0-2 .9-2 2v3c0 1.11.89 2 2 2h6v-2h4v2h6c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2zm-6 0h-4V5h4v2z';
export const ICON_CANCEL =
  'M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z';
export const ICON_CHAT =
  'M4,4 L20,4 L20,16 L5.17,16 L4,17.17 L4,4 M4,2 C2.9,2 2.01,2.9 2.01,4 L2,22 L6,18 L20,18 C21.1,18 22,17.1 22,16 L22,4 C22,2.9 21.1,2 20,2 L4,2 Z M6,12 L14,12 L14,14 L6,14 L6,12 Z M6,9 L18,9 L18,11 L6,11 L6,9 Z M6,6 L18,6 L18,8 L6,8 L6,6 Z';
export const ICON_DATE_RANGE =
  'M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z';
export const ICON_CHECK_SQUARE_OUTLINE =
  'M19,2c1.66,0,3,1.34,3,3v14 c0,1.66-1.34,3-3,3H5c-1.66,0-3-1.34-3-3V5c0-1.66,1.34-3,3-3H19z M19,4H5C4.44,4,4,4.45,4,5v14c0,0.56,0.45,1,1,1h14 c0.56,0,1-0.45,1-1V5C20,4.44,19.55,4,19,4z M15.59,9L17,10.42l-6,6l-4-4l1.41-1.41L11,13.59L15.59,9z';
export const ICON_CALENDAR_CHECK_OUTLINE =
  'M14.12,18.49l3.93-3.93l-1.06-1.06l-2.87,2.87l-1.11-1.11l-1.06,1.06L14.12,18.49 L14.12,18.49z M20,9c1.1,0,2,0.9,2,2v10c0,1.1-0.9,2-2,2H10c-1.1,0-2-0.9-2-2V11c0-1.1,0.9-2,2-2H20z M20,11H10v10h10V11z M20,9 V5c0-1.1-0.9-2-2-2h-1V1h-2v2H7V1H5v2H4C2.9,3,2,3.9,2,5v14c0,1.1,0.9,2,2,2h4v-2H4V9h14 M4,5h14v2H4V5z';
export const ICON_CALENDAR_QUESTION_OUTLINE =
  'M20,9c1.1,0,2,0.9,2,2v10c0,1.1-0.9,2-2,2H10c-1.1,0-2-0.9-2-2V11c0-1.1,0.9-2,2-2H20z M20,11H10v10h10V11z M15.51,17.67V19h-1.36v-1.33H15.51z M14.96,13c0.63,0,1.13,0.15,1.49,0.45c0.37,0.3,0.55,0.71,0.55,1.21 c0,0.33-0.1,0.61-0.3,0.87s-0.45,0.46-0.76,0.62c-0.17,0.11-0.29,0.23-0.35,0.35c-0.06,0.12-0.09,0.29-0.09,0.49h-1.36 c0-0.33,0.07-0.61,0.21-0.79c0.13-0.18,0.37-0.38,0.71-0.61c0.18-0.11,0.32-0.24,0.41-0.39c0.11-0.15,0.16-0.33,0.16-0.55 c0-0.2-0.05-0.37-0.18-0.5c-0.12-0.12-0.3-0.19-0.51-0.19c-0.18,0-0.33,0.05-0.45,0.15c-0.12,0.11-0.18,0.26-0.19,0.46h-1.31 L13,14.57c-0.01-0.53,0.17-0.91,0.52-1.18C13.89,13.13,14.37,13,14.96,13z M20,9V5c0-1.1-0.9-2-2-2h-1V1h-2v2H7V1H5v2H4 C2.9,3,2,3.9,2,5v14c0,1.1,0.9,2,2,2h4v-2H4V9h14 M4,5h14v2H4V5z';
export const ICON_CALENDAR_DOWNLOAD_OUTLINE =
  'M15.6,17l1.55-1.35L18,16.39L15,19l-3-2.61l0.85-0.74L14.4,17v-4h1.2V17z M20,9 c1.1,0,2,0.9,2,2v10c0,1.1-0.9,2-2,2H10c-1.1,0-2-0.9-2-2V11c0-1.1,0.9-2,2-2H20z M20,11H10v10h10V11z M20,9V5c0-1.1-0.9-2-2-2 h-1V1h-2v2H7V1H5v2H4C2.9,3,2,3.9,2,5v14c0,1.1,0.9,2,2,2h4v-2H4V9h14 M4,5h14v2H4V5z';
export const ICON_CLOCK_SQUARE_OUTLINE =
  'M19,2 C20.6568542,2 22,3.34314575 22,5 L22,19 C22,20.6568542 20.6568542,22 19,22 L5,22 C3.34314575,22 2,20.6568542 2,19 L2,5 C2,3.34314575 3.34314575,2 5,2 L19,2 Z M18.9991283,4 L5.00087166,4 C4.44463086,4 4,4.4481055 4,5.00087166 L4,18.9991283 C4,19.5553691 4.4481055,20 5.00087166,20 L18.9991283,20 C19.5553691,20 20,19.5518945 20,18.9991283 L20,5.00087166 C20,4.44463086 19.5518945,4 18.9991283,4 Z M12.5,7 L12.5,12.25 L17,14.92 L16.25,16.15 L11,13 L11,7 L12.5,7 Z';
export const ICON_QUESTION_SQUARE_OUTLINE =
  'M9.78596066,8.52444444 C9.25537349,8.88888889 8.9900799,9.39555556 9.0002835,10.0977778 L9.0104871,10.1244444 L10.9797818,10.1244444 C10.9899854,9.85777778 11.0818178,9.65333333 11.2654826,9.51111111 C11.4491474,9.37777778 11.6736266,9.30666667 11.9389202,9.30666667 C12.2552317,9.30666667 12.5205253,9.39555556 12.7041901,9.55555556 C12.8878549,9.72444444 12.9694837,9.95555556 12.9694837,10.2222222 C12.9694837,10.5066667 12.8980585,10.7466667 12.7348009,10.9511111 C12.5919505,11.1555556 12.3776749,11.3333333 12.1123813,11.4755556 C11.5919978,11.7777778 11.2348718,12.0444444 11.0410034,12.2844444 C10.8369314,12.5155556 10.7246918,12.8888889 10.7246918,13.3333333 L12.7654117,13.3333333 C12.7654117,13.0577778 12.8062261,12.8355556 12.8980585,12.6755556 C12.9898909,12.5155556 13.1633521,12.3555556 13.4184421,12.2133333 C13.8776041,12 14.2551372,11.7422222 14.5510416,11.3866667 C14.846946,11.0311111 15,10.6666667 15,10.2222222 C15,9.54666667 14.7245028,9.00444444 14.1735084,8.60444444 C13.6327177,8.20444444 12.8878549,8 11.9389202,8 C11.051207,8 10.336955,8.17777778 9.78596066,8.52444444 Z M19,2 C20.6568542,2 22,3.34314575 22,5 L22,19 C22,20.6568542 20.6568542,22 19,22 L5,22 C3.34314575,22 2,20.6568542 2,19 L2,5 C2,3.34314575 3.34314575,2 5,2 L19,2 Z M18.9991283,4 L5.00087166,4 C4.44463086,4 4,4.4481055 4,5.00087166 L4,18.9991283 C4,19.5553691 4.4481055,20 5.00087166,20 L18.9991283,20 C19.5553691,20 20,19.5518945 20,18.9991283 L20,5.00087166 C20,4.44463086 19.5518945,4 18.9991283,4 Z M12.7654117,14.2222222 L12.7654117,16 L10.7246918,16 L10.7246918,14.2222222 L12.7654117,14.2222222 Z';
export const ICON_CHECK = 'M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z';
export const ICON_CHECK_SQUARED_OUTLINE =
  'M19,2 C20.6568542,2 22,3.34314575 22,5 L22,19 C22,20.6568542 20.6568542,22 19,22 L5,22 C3.34314575,22 2,20.6568542 2,19 L2,5 C2,3.34314575 3.34314575,2 5,2 L19,2 Z M18.9991283,4 L5.00087166,4 C4.44463086,4 4,4.4481055 4,5.00087166 L4,18.9991283 C4,19.5553691 4.4481055,20 5.00087166,20 L18.9991283,20 C19.5553691,20 20,19.5518945 20,18.9991283 L20,5.00087166 C20,4.44463086 19.5518945,4 18.9991283,4 Z M15.59,9 L17,10.42 L11,16.42 L7,12.42 L8.41,11.01 L11,13.59 L15.59,9 Z';
export const ICON_CHECK_CIRCLE_OUTLINE =
  'M16.59 7.58L10 14.17l-3.59-3.58L5 12l5 5 8-8zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z';
export const ICON_CLOSE =
  'M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z';
export const ICON_CANCEL_CIRCLE_OUTLINE =
  'M14.59 8L12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z';
export const ICON_COMPARE_ARROWS = 'M9.01 14H2v2h7.01v3L13 15l-3.99-4v3zm5.98-1v-3H22V8h-7.01V5L11 9l3.99 4z';
export const ICON_CREDITCARD =
  'M20,4 L4,4 C2.89,4 2.01,4.89 2.01,6 L2,18 C2,19.11 2.89,20 4,20 L20,20 C21.11,20 22,19.11 22,18 L22,6 C22,4.89 21.11,4 20,4 Z M20,18 L4,18 L4,12 L20,12 L20,18 Z M20,8 L4,8 L4,6 L20,6 L20,8 Z';
export const ICON_CUBE =
  'M17,4 L10,8.04 L10,15.96 L17,20 L24,15.96 L24,8.04 M17,6.31 L20.8,8.5 L17,10.69 L13.21,8.5 M1,7 L1,9 L8,9 L8,7 M12,10.11 L16,12.42 L16,17.11 L12,14.81 M22,10.11 L22,14.81 L18,17.11 L18,12.42 M3,11 L3,13 L8,13 L8,11 M5,15 L5,17 L8,17 L8,15';
export const ICON_DONE = 'M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z';
export const ICON_DOWNLOAD_SQUARE_OUTLINE =
  'M12.8,13.34l2.07-1.79L16,12.52L12,16 l-4-3.48l1.13-0.98l2.07,1.79V8h1.6V13.34z M19,2c1.66,0,3,1.34,3,3v14c0,1.66-1.34,3-3,3H5c-1.66,0-3-1.34-3-3V5 c0-1.66,1.34-3,3-3H19z M19,4H5C4.44,4,4,4.45,4,5v14c0,0.56,0.45,1,1,1h14c0.56,0,1-0.45,1-1V5C20,4.44,19.55,4,19,4z M15,16v1 H9v-1H15z';
export const ICON_SHOPPING_BASKET =
  'M22,9 L17.21,9 L12.83,2.44 C12.64,2.16 12.32,2.02 12,2.02 C11.68,2.02 11.36,2.16 11.17,2.45 L6.79,9 L2,9 C1.45,9 1,9.45 1,10 C1,10.09 1.01,10.18 1.04,10.27 L3.58,19.54 C3.81,20.38 4.58,21 5.5,21 L18.5,21 C19.42,21 20.19,20.38 20.43,19.54 L22.97,10.27 L23,10 C23,9.45 22.55,9 22,9 Z M12,4.8 L14.8,9 L9.2,9 L12,4.8 Z M18.5,19 L5.51,19.01 L3.31,11 L20.7,11 L18.5,19 Z';
export const ICON_ARROW_HISTORY_BACK =
  'M13.5 11.5 7.8 11.5 10.9 8.4 9.5 7 4 12.5 9.5 18 10.9 16.6 7.8 13.5 13.5 13.5C16 13.5 17.9 15.5 18 17.9L20 17.9C20 14.4 17.1 11.5 13.5 11.5Z';
export const ICON_HELP_CENTER =
  'M13.25,16.74 C13.25,17.43 12.72,18 12,18 C11.3,18 10.74,17.44 10.74,16.74 C10.74,16.03 11.3,15.49 12,15.49 C12.71,15.49 13.25,16.04 13.25,16.74 Z M11.99,6 C10.22,6 9.01,7.15 8.56,8.49 L10.2,9.18 C10.42,8.51 10.94,7.7 12,7.7 C13.62,7.7 13.94,9.22 13.37,10.03 C12.83,10.8 11.9,11.32 11.41,12.19 C11.02,12.88 11.1,13.68 11.1,14.17 L12.92,14.17 C12.92,13.24 12.99,13.05 13.14,12.76 C13.53,12.04 14.25,11.7 15.01,10.59 C15.69,9.59 15.43,8.23 14.99,7.51 C14.48,6.67 13.47,6 11.99,6 Z M19,5 L5,5 L5,19 L19,19 L19,5 M19,3 C20.1,3 21,3.9 21,5 L21,19 C21,20.1 20.1,21 19,21 L5,21 C3.9,21 3,20.1 3,19 L3,5 C3,3.9 3.9,3 5,3 L19,3 L19,3 Z';
export const LOGOUT =
  'M17 7l-1.41 1.41L18.17 11H8v2h10.17l-2.58 2.58L17 17l5-5-5-5zM4 5h8V3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8v-2H4V5z';

export const ICON_ARROW_DROPDOWN = 'M7 10l5 5 5-5z';
export const ICON_EDIT =
  'M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04a.996.996 0 000-1.41l-2.34-2.34a.996.996 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z';
export const ICON_EVENT =
  'M17 12h-5v5h5v-5ZM16 1v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2Zm3 18H5V8h14v11Z';
export const ICON_FACEAGENT =
  'M18.72 14.84c.35-.85.54-1.76.54-2.76 0-.72-.11-1.41-.3-2.05-.65.15-1.33.23-2.04.23A9.07 9.07 0 0 1 9.5 6.42a9.21 9.21 0 0 1-4.73 4.88c-.04.25-.04.52-.04.78A7.27 7.27 0 0 0 12 19.35c1.05 0 2.06-.23 2.97-.64.57 1.09.83 1.63.81 1.63-1.64.55-2.91.82-3.78.82-2.42 0-4.73-.95-6.43-2.66a9.03 9.03 0 0 1-2.24-3.69H2v-4.55h1.09a9.09 9.09 0 0 1 15.33-4.6 8.991 8.991 0 0 1 2.47 4.6H22v4.55h-.06l-3.56 3.27-5.3-.6v-1.67h4.83l.81-.97m-9.45-2.99c.3 0 .59.12.8.34a1.136 1.136 0 0 1 0 1.6c-.21.21-.5.33-.8.33-.63 0-1.14-.5-1.14-1.13 0-.63.51-1.14 1.14-1.14m5.45 0c.63 0 1.13.51 1.13 1.14 0 .63-.5 1.13-1.13 1.13-.63 0-1.14-.5-1.14-1.13 0-.63.51-1.14 1.14-1.14Z';
export const ICON_FAVORITE =
  'M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z';
export const ICON_FAVORITE_BORDER =
  'M16.5 3c-1.74 0-3.41.81-4.5 2.09C10.91 3.81 9.24 3 7.5 3 4.42 3 2 5.42 2 8.5c0 3.78 3.4 6.86 8.55 11.54L12 21.35l1.45-1.32C18.6 15.36 22 12.28 22 8.5 22 5.42 19.58 3 16.5 3zm-4.4 15.55l-.1.1-.1-.1C7.14 14.24 4 11.39 4 8.5 4 6.5 5.5 5 7.5 5c1.54 0 3.04.99 3.57 2.36h1.87C13.46 5.99 14.96 5 16.5 5c2 0 3.5 1.5 3.5 3.5 0 2.89-3.14 5.74-7.9 10.05z';
export const ICON_FORMAT_LIST_BULLETED =
  'M4 10.5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zm0-6c-.83 0-1.5.67-1.5 1.5S3.17 7.5 4 7.5 5.5 6.83 5.5 6 4.83 4.5 4 4.5zm0 12c-.83 0-1.5.68-1.5 1.5s.68 1.5 1.5 1.5 1.5-.68 1.5-1.5-.67-1.5-1.5-1.5zM7 19h14v-2H7v2zm0-6h14v-2H7v2zm0-8v2h14V5H7z';
export const ICON_GET_APP = 'M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z';
export const ICON_GPS_NOT_FIXED =
  'M20.94 11A8.994 8.994 0 0013 3.06V1h-2v2.06A8.994 8.994 0 003.06 11H1v2h2.06A8.994 8.994 0 0011 20.94V23h2v-2.06A8.994 8.994 0 0020.94 13H23v-2h-2.06zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z';
export const ICON_HOME = 'M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z';
export const ICON_STORE_OUTLINE =
  'M21.9,8.89 L20.85,4.52 C20.63,3.62 19.85,3 18.94,3 L5.05,3 C4.15,3 3.36,3.63 3.15,4.52 L2.1,8.89 C1.86,9.91 2.08,10.95 2.72,11.77 C2.8,11.88 2.91,11.96 3,12.06 L3,19 C3,20.1 3.9,21 5,21 L19,21 C20.1,21 21,20.1 21,19 L21,12.06 C21.09,11.97 21.2,11.88 21.28,11.78 C21.92,10.96 22.15,9.91 21.9,8.89 Z M18.91,4.99 L19.96,9.36 C20.06,9.78 19.97,10.2 19.71,10.53 C19.57,10.71 19.27,11 18.77,11 C18.16,11 17.63,10.51 17.56,9.86 L16.98,5 L18.91,4.99 Z M13,5 L14.96,5 L15.5,9.52 C15.55,9.91 15.43,10.3 15.17,10.59 C14.95,10.85 14.63,11 14.22,11 C13.55,11 13,10.41 13,9.69 L13,5 Z M8.49,9.52 L9.04,5 L11,5 L11,9.69 C11,10.41 10.45,11 9.71,11 C9.37,11 9.06,10.85 8.82,10.59 C8.57,10.3 8.45,9.91 8.49,9.52 Z M4.04,9.36 L5.05,5 L7.02,5 L6.44,9.86 C6.36,10.51 5.84,11 5.23,11 C4.74,11 4.43,10.71 4.3,10.53 C4.03,10.21 3.94,9.78 4.04,9.36 Z M5,19 L5,12.97 C5.08,12.98 5.15,13 5.23,13 C6.1,13 6.89,12.64 7.47,12.05 C8.07,12.65 8.87,13 9.78,13 C10.65,13 11.43,12.64 12.01,12.07 C12.6,12.64 13.4,13 14.3,13 C15.14,13 15.94,12.65 16.54,12.05 C17.12,12.64 17.91,13 18.78,13 C18.86,13 18.93,12.98 19.01,12.97 L19.01,19 L5,19 Z';
export const ICON_IMPORTANT_DEVICES =
  'M23,11.01 L18,11 C17.45,11 17,11.45 17,12 L17,21 C17,21.55 17.45,22 18,22 L23,22 C23.55,22 24,21.55 24,21 L24,12 C24,11.45 23.55,11.01 23,11.01 Z M23,20 L18,20 L18,13 L23,13 L23,20 Z M20,2 L2,2 C0.89,2 0,2.89 0,4 L0,16 C0,17.1 0.89,18 2,18 L9,18 L9,20 L7,20 L7,22 L15,22 L15,20 L13,20 L13,18 L15,18 L15,16 L2,16 L2,4 L20,4 L20,9 L22,9 L22,4 C22,2.89 21.1,2 20,2 Z M11.125,6.5 C9.67375,6.5 8.5,7.67375 8.5,9.125 C8.5,11.09375 11.125,14 11.125,14 C11.125,14 13.75,11.09375 13.75,9.125 C13.75,7.67375 12.57625,6.5 11.125,6.5 Z M11.125,10.0625 C10.6075,10.0625 10.1875,9.6425 10.1875,9.125 C10.1875,8.6075 10.6075,8.1875 11.125,8.1875 C11.6425,8.1875 12.0625,8.6075 12.0625,9.125 C12.0625,9.6425 11.6425,10.0625 11.125,10.0625 Z';
export const ICON_KEYBOARD_ARROW_DOWN = 'M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z';
export const ICON_KEYBOARD_ARROW_LEFT = 'M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z';
export const ICON_KEYBOARD_ARROW_RIGHT = 'M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z';
export const ICON_KEYBOARD_ARROW_UP = 'M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z';
export const ICON_LANGUAGE =
  'M12 22a9.676 9.676 0 0 1-3.875-.788 10.144 10.144 0 0 1-3.188-2.15 10.142 10.142 0 0 1-2.15-3.187A9.676 9.676 0 0 1 2 12c0-1.383.263-2.68.788-3.887a10.183 10.183 0 0 1 2.15-3.175 10.143 10.143 0 0 1 3.187-2.15A9.676 9.676 0 0 1 12 2c1.383 0 2.68.263 3.887.788a10.183 10.183 0 0 1 3.175 2.15 10.184 10.184 0 0 1 2.15 3.175A9.649 9.649 0 0 1 22 12a9.676 9.676 0 0 1-.788 3.875 10.143 10.143 0 0 1-2.15 3.188 10.184 10.184 0 0 1-3.175 2.15A9.649 9.649 0 0 1 12 22Zm0-2.05c.433-.6.808-1.225 1.125-1.875.317-.65.575-1.342.775-2.075h-3.8c.2.733.458 1.425.775 2.075.317.65.692 1.275 1.125 1.875Zm-2.6-.4c-.3-.55-.563-1.12-.787-1.713A14.688 14.688 0 0 1 8.05 16H5.1a8.3 8.3 0 0 0 1.813 2.175A7.195 7.195 0 0 0 9.4 19.55Zm5.2 0a7.195 7.195 0 0 0 2.487-1.375A8.299 8.299 0 0 0 18.9 16h-2.95c-.15.633-.337 1.246-.563 1.837a13.857 13.857 0 0 1-.787 1.713ZM4.25 14h3.4a13.208 13.208 0 0 1-.15-2 13.208 13.208 0 0 1 .15-2h-3.4A7.959 7.959 0 0 0 4 12a7.959 7.959 0 0 0 .25 2Zm5.4 0h4.7a13.208 13.208 0 0 0 .15-2 13.208 13.208 0 0 0-.15-2h-4.7a13.208 13.208 0 0 0-.15 2 13.208 13.208 0 0 0 .15 2Zm6.7 0h3.4a7.953 7.953 0 0 0 .25-2 7.953 7.953 0 0 0-.25-2h-3.4a13.208 13.208 0 0 1 .15 2 13.208 13.208 0 0 1-.15 2Zm-.4-6h2.95a8.298 8.298 0 0 0-1.813-2.175A7.196 7.196 0 0 0 14.6 4.45c.3.55.563 1.12.787 1.712.226.592.413 1.205.563 1.838ZM10.1 8h3.8c-.2-.733-.458-1.425-.775-2.075A12.701 12.701 0 0 0 12 4.05c-.433.6-.808 1.225-1.125 1.875-.317.65-.575 1.342-.775 2.075Zm-5 0h2.95c.15-.633.337-1.246.563-1.838C8.838 5.571 9.1 5 9.4 4.45c-.933.3-1.763.758-2.488 1.375A8.298 8.298 0 0 0 5.1 8Z';

export const ICON_LIST = 'M3 13h2v-2H3v2zm0 4h2v-2H3v2zm0-8h2V7H3v2zm4 4h14v-2H7v2zm0 4h14v-2H7v2zM7 7v2h14V7H7z';
export const ICON_LOCAL_OFFER_OUTLINED =
  'M 5.8 3.5 A 2 2 0 0 1 7.8 5.5 A 2 2 0 0 1 5.8 7.5 A 2 2 0 0 1 3.8 5.5 A 2 2 0 0 1 5.8 3.5 M 19.41 9.41 L 10.58 0.58 C 10.21 0.21 9.7 0 9.17 0 H 2 C 0.9 0 0 0.9 0 2 V 9.17 C 0 9.7 0.21 10.21 0.59 10.58 L 9.42 19.41 C 10.2 20.19 11.47 20.19 12.25 19.41 L 19.42 12.24 C 20.2 11.46 20.2 10.2 19.41 9.41 Z M 10.83 18 L 2 9.17 V 2 H 9.17 L 18 10.83 L 10.83 18 Z';
export const ICON_LOCATION_ON_OUTLINED =
  'M12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5M12,2A7,7 0 0,1 19,9C19,14.25 12,22 12,22C12,22 5,14.25 5,9A7,7 0 0,1 12,2M12,4A5,5 0 0,0 7,9C7,10 7,12 12,18.71C17,12 17,10 17,9A5,5 0 0,0 12,4Z';
export const ICON_LOGIN =
  'M11,7L9.6,8.4l2.6,2.6H2v2h10.2l-2.6,2.6L11,17l5-5L11,7z M20,19h-8v2h8c1.1,0,2-0.9,2-2V5c0-1.1-0.9-2-2-2h-8v2h8V19z';
export const ICON_MAIL =
  'M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z';
export const ICON_MAIL_OUTLINE =
  'M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V8l8 5 8-5v10zm-8-7L4 6h16l-8 5z';
export const ICON_MAP =
  'M20.5 3l-.16.03L15 5.1 9 3 3.36 4.9c-.21.07-.36.25-.36.48V20.5c0 .28.22.5.5.5l.16-.03L9 18.9l6 2.1 5.64-1.9c.21-.07.36-.25.36-.48V3.5c0-.28-.22-.5-.5-.5zM15 19l-6-2.11V5l6 2.11V19z';
export const ICON_MENU = 'M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z';
export const ICON_PERSON_ADD_OUTLINE =
  'M13,8c0-2.21-1.79-4-4-4S5,5.79,5,8s1.79,4,4,4S13,10.21,13,8z M11,8c0,1.1-0.9,2-2,2S7,9.1,7,8s0.9-2,2-2S11,6.9,11,8z M1,18v2h16v-2c0-2.66-5.33-4-8-4S1,15.34,1,18z M3,18c0.2-0.71,3.3-2,6-2c2.69,0,5.78,1.28,6,2H3z M20,15v-3h3v-2h-3V7h-2v3h-3v2 h3v3H20z';
export const ICON_MINUS_BOX_OUTLINE =
  'M19,19V5H5V19H19M19,3A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5C3,3.89 3.9,3 5,3H19M17,11V13H7V11H17Z';
export const ICON_PERSON_OUTLINE =
  'M12 5.9a2.1 2.1 0 110 4.2 2.1 2.1 0 010-4.2m0 9c2.97 0 6.1 1.46 6.1 2.1v1.1H5.9V17c0-.64 3.13-2.1 6.1-2.1M12 4C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 9c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4z';
export const ICON_PICKUP =
  'M12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5M12,2A7,7 0 0,1 19,9C19,14.25 12,22 12,22C12,22 5,14.25 5,9A7,7 0 0,1 12,2M12,4A5,5 0 0,0 7,9C7,10 7,12 12,18.71C17,12 17,10 17,9A5,5 0 0,0 12,4Z';
export const ICON_PHONE =
  'M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z';
export const ICON_READ_MORE = 'M13 7h9v2h-9zM13 15h9v2h-9zM16 11h6v2h-6zM13 12L8 7v4H2v2h6v4z';
export const ICON_REMOVE = 'M19 13H5v-2h14v2z';
export const ICON_SCHEDULE =
  'M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z';
export const ICON_SEARCH =
  'M15.5 14h-.79l-.28-.27A6.471 6.471 0 0016 9.5 6.5 6.5 0 109.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z';
export const ICON_SELL =
  'M 5.8 3.5 A 2 2 0 0 1 7.8 5.5 A 2 2 0 0 1 5.8 7.5 A 2 2 0 0 1 3.8 5.5 A 2 2 0 0 1 5.8 3.5 M 19.41 9.41 L 10.58 0.58 C 10.21 0.21 9.7 0 9.17 0 H 2 C 0.9 0 0 0.9 0 2 V 9.17 C 0 9.7 0.21 10.21 0.59 10.58 L 9.42 19.41 C 10.2 20.19 11.47 20.19 12.25 19.41 L 19.42 12.24 C 20.2 11.46 20.2 10.2 19.41 9.41 Z M 10.83 18 L 2 9.17 V 2 H 9.17 L 18 10.83 L 10.83 18 Z';
export const ICON_SEND = 'M2.01 21L23 12 2.01 3 2 10l15 2-15 2z';
export const ICON_SETTINGS =
  'M19.43 12.98c.04-.32.07-.64.07-.98 0-.34-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46a.5.5 0 0 0-.61-.22l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65A.488.488 0 0 0 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1a.566.566 0 0 0-.18-.03c-.17 0-.34.09-.43.25l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98 0 .33.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46a.5.5 0 0 0 .61.22l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.06.02.12.03.18.03.17 0 .34-.09.43-.25l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65Zm-1.98-1.71c.04.31.05.52.05.73 0 .21-.02.43-.05.73l-.14 1.13.89.7 1.08.84-.7 1.21-1.27-.51-1.04-.42-.9.68c-.43.32-.84.56-1.25.73l-1.06.43-.16 1.13-.2 1.35h-1.4l-.19-1.35-.16-1.13-1.06-.43c-.43-.18-.83-.41-1.23-.71l-.91-.7-1.06.43-1.27.51-.7-1.21 1.08-.84.89-.7-.14-1.13c-.03-.31-.05-.54-.05-.74 0-.2.02-.43.05-.73l.14-1.13-.89-.7-1.08-.84.7-1.21 1.27.51 1.04.42.9-.68c.43-.32.84-.56 1.25-.73l1.06-.43.16-1.13.2-1.35h1.39l.19 1.35.16 1.13 1.06.43c.43.18.83.41 1.23.71l.91.7 1.06-.43 1.27-.51.7 1.21-1.07.85-.89.7.14 1.13ZM12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4Zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2Z';
export const ICON_SHIELD =
  'M21 10c0 5.55-3.84 10.74-9 12-5.16-1.26-9-6.45-9-12V4l9-4 9 4v6m-9 10c3.75-1 7-5.46 7-9.78V5.3l-7-3.12L5 5.3v4.92C5 14.54 8.25 19 12 20m-1-4.58-4-4 1.41-1.41L11 12.59 15.59 8 17 9.42l-6 6Z';
export const ICON_SHOPPINGBAG =
  'M18 6h-2c0-2.21-1.79-4-4-4S8 3.79 8 6H6c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2Zm-6-2c1.1 0 2 .9 2 2h-4c0-1.1.9-2 2-2Zm6 16H6V8h2v2c0 .55.45 1 1 1s1-.45 1-1V8h4v2c0 .55.45 1 1 1s1-.45 1-1V8h2v12Z';
export const ICON_SHOWROOMS =
  'm21.9 8.89-1.05-4.37c-.22-.9-1-1.52-1.91-1.52H5.05c-.9 0-1.69.63-1.9 1.52L2.1 8.89c-.24 1.02-.02 2.06.62 2.88.08.11.19.19.28.29V19c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-6.94c.09-.09.2-.18.28-.28.64-.82.87-1.87.62-2.89Zm-2.99-3.9 1.05 4.37c.1.42.01.84-.25 1.17-.14.18-.44.47-.94.47-.61 0-1.14-.49-1.21-1.14L16.98 5l1.93-.01ZM13 5h1.96l.54 4.52c.05.39-.07.78-.33 1.07-.22.26-.54.41-.95.41-.67 0-1.22-.59-1.22-1.31V5ZM8.49 9.52 9.04 5H11v4.69c0 .72-.55 1.31-1.29 1.31-.34 0-.65-.15-.89-.41a1.42 1.42 0 0 1-.33-1.07Zm-4.45-.16L5.05 5h1.97l-.58 4.86c-.08.65-.6 1.14-1.21 1.14-.49 0-.8-.29-.93-.47-.27-.32-.36-.75-.26-1.17ZM5 19v-6.03c.08.01.15.03.23.03.87 0 1.66-.36 2.24-.95.6.6 1.4.95 2.31.95.87 0 1.65-.36 2.23-.93.59.57 1.39.93 2.29.93.84 0 1.64-.35 2.24-.95.58.59 1.37.95 2.24.95.08 0 .15-.02.23-.03V19H5Z';
export const ICON_STAR = 'M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z';
export const ICON_STAR_BORDER =
  'M22 9.24l-7.19-.62L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.63-7.03L22 9.24zM12 15.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L12 6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4z';
export const ICON_STAR_HALF =
  'M22 9.24l-7.19-.62L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.63-7.03L22 9.24zM12 15.4V6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4z';
export const ICON_STORES =
  'M21.9,8.89 L20.85,4.52 C20.63,3.62 19.85,3 18.94,3 L5.05,3 C4.15,3 3.36,3.63 3.15,4.52 L2.1,8.89 C1.86,9.91 2.08,10.95 2.72,11.77 C2.8,11.88 2.91,11.96 3,12.06 L3,19 C3,20.1 3.9,21 5,21 L19,21 C20.1,21 21,20.1 21,19 L21,12.06 C21.09,11.97 21.2,11.88 21.28,11.78 C21.92,10.96 22.15,9.91 21.9,8.89 Z M18.91,4.99 L19.96,9.36 C20.06,9.78 19.97,10.2 19.71,10.53 C19.57,10.71 19.27,11 18.77,11 C18.16,11 17.63,10.51 17.56,9.86 L16.98,5 L18.91,4.99 Z M13,5 L14.96,5 L15.5,9.52 C15.55,9.91 15.43,10.3 15.17,10.59 C14.95,10.85 14.63,11 14.22,11 C13.55,11 13,10.41 13,9.69 L13,5 Z M8.49,9.52 L9.04,5 L11,5 L11,9.69 C11,10.41 10.45,11 9.71,11 C9.37,11 9.06,10.85 8.82,10.59 C8.57,10.3 8.45,9.91 8.49,9.52 Z M4.04,9.36 L5.05,5 L7.02,5 L6.44,9.86 C6.36,10.51 5.84,11 5.23,11 C4.74,11 4.43,10.71 4.3,10.53 C4.03,10.21 3.94,9.78 4.04,9.36 Z M5,19 L5,12.97 C5.08,12.98 5.15,13 5.23,13 C6.1,13 6.89,12.64 7.47,12.05 C8.07,12.65 8.87,13 9.78,13 C10.65,13 11.43,12.64 12.01,12.07 C12.6,12.64 13.4,13 14.3,13 C15.14,13 15.94,12.65 16.54,12.05 C17.12,12.64 17.91,13 18.78,13 C18.86,13 18.93,12.98 19.01,12.97 L19.01,19 L5,19 Z';
export const ICON_SUPERCARD =
  'M2.32,17.1h17.42c-0.09,0.9-0.84,1.61-1.76,1.61H4.08C3.16,18.7,2.41,18,2.32,17.1z M19.77,14.52H2.3v-4.38c0-0.99,0.79-1.78,1.78-1.78h9.15c0.35,0,0.64-0.29,0.64-0.66c0-0.35-0.29-0.64-0.64-0.64H4.08C2.39,7.06,1,8.45,1,10.14v6.78C1,18.61,2.39,20,4.08,20h13.9c1.69,0,3.08-1.39,3.08-3.08v-4.2c0-0.37-0.31-0.66-0.66-0.66c-0.35,0-0.64,0.29-0.64,0.66V14.52z M18.95,7.99c-1.03,0-1.94,0.29-2.4,0.68c-0.26-0.42-0.42-0.9-0.42-1.43c0-1.54,1.25-2.77,2.79-2.77c1.54,0,2.77,1.23,2.77,2.77c0,0.51-0.13,0.97-0.37,1.39C20.84,8.25,19.99,7.99,18.95,7.99z M14.84,7.24c0,2.24,1.85,4.07,4.09,4.07S23,9.48,23,7.24s-1.83-4.07-4.07-4.07S14.84,5,14.84,7.24z M18.95,7.59c0.7,0,1.28-0.57,1.28-1.28s-0.57-1.25-1.28-1.25c-0.68,0-1.25,0.55-1.25,1.25S18.27,7.59,18.95,7.59z';
export const ICON_TRUCK =
  'M21 14.35a3.464 3.464 0 0 0-1.5-.35c-1.394 0-2.587.82-3.15 2h-5.7a3.489 3.489 0 0 0-3.15-2c-1.394 0-2.587.82-3.15 2H3L4 6h13.8l3.2 5.5v2.85ZM20.908 18c-.207.581-.756 1-1.408 1a1.496 1.496 0 0 1-1.408-1 1.483 1.483 0 0 1-.092-.5 1.5 1.5 0 0 1 3 0c0 .176-.036.343-.092.5Zm-12 0c-.206.581-.756 1-1.408 1a1.495 1.495 0 0 1-1.408-1A1.483 1.483 0 0 1 6 17.5a1.5 1.5 0 1 1 3 0c0 .176-.036.343-.092.5ZM4 4C2 4 2 6 2 6s-1 8-1 10 2 2 2 2h1.05a3.49 3.49 0 0 0 3.45 3 3.49 3.49 0 0 0 3.45-3h5.101a3.489 3.489 0 0 0 3.449 3 3.489 3.489 0 0 0 3.449-3c.025-.165.051-.329.051-.5V11l-4-7H4Z M14 7.5v3.998h4.835L16.5 7.5H14';
export const ICON_VERIFIED_USER_OUTLINED =
  'M12 1L3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5l-9-4zm7 10c0 4.52-2.98 8.69-7 9.93-4.02-1.24-7-5.41-7-9.93V6.3l7-3.11 7 3.11V11zm-11.59.59L6 13l4 4 8-8-1.41-1.42L10 14.17z';
export const ICON_VIEW_HEADLINE = 'M4 15h16v-2H4v2zm0 4h16v-2H4v2zm0-8h16V9H4v2zm0-6v2h16V5H4z';
export const ICON_MORE = 'M6,13H18V11H6M3,6V8H21V6M10,18H14V16H10V18Z';
export const ICON_VIEW_LIST = 'M4 14h4v-4H4v4zm0 5h4v-4H4v4zM4 9h4V5H4v4zm5 5h12v-4H9v4zm0 5h12v-4H9v4zM9 5v4h12V5H9z';
export const VIEW_MODULE =
  'M3.375 11.464h5.074V5.028H3.375v6.436zm0 7.508h5.074v-6.436H3.375v6.436zm6.088 0h5.074v-6.436H9.463v6.436zm6.088 0h5.074v-6.436h-5.074v6.436zm-6.088-7.508h5.074V5.028H9.463v6.436zm6.088-6.436v6.436h5.074V5.028h-5.074z';
export const ICON_DISCONNECT =
  'M17 7h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1 0 1.43-.98 2.63-2.31 2.98l1.46 1.46C20.88 15.61 22 13.95 22 12c0-2.76-2.24-5-5-5zm-1 4h-2.19l2 2H16zM2 4.27l3.11 3.11C3.29 8.12 2 9.91 2 12c0 2.76 2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1 0-1.59 1.21-2.9 2.76-3.07L8.73 11H8v2h2.73L13 15.27V17h1.73l4.01 4L20 19.74 3.27 3 2 4.27z';
// CUSTOM
export const ICON_ALERT =
  'M22.54 18.74c-.44-.82-.87-1.64-1.31-2.46-1.38-2.6-2.77-5.21-4.15-7.81-1.08-2.01-2.15-4.02-3.22-6.03-.37-.7-.81-1.34-1.69-1.43-1.05-.1-1.62.62-2.05 1.44-2.47 4.64-4.94 9.28-7.4 13.92-.42.8-.85 1.6-1.27 2.4-.45.84-.74 1.78 0 2.61.57.63 1.32.62 2.09.62h11.92c1.88 0 3.78.07 5.66-.01.55-.02 1.04-.21 1.42-.63.76-.84.45-1.77 0-2.62zM12 19c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm1-4c0 .55-.45 1-1 1s-1-.45-1-1v-5c0-.55.45-1 1-1s1 .45 1 1v5z';
export const ICON_BASKET =
  'M22,9 L17.21,9 L12.83,2.44 C12.64,2.16 12.32,2.02 12,2.02 C11.68,2.02 11.36,2.16 11.17,2.45 L6.79,9 L2,9 C1.45,9 1,9.45 1,10 C1,10.09 1.01,10.18 1.04,10.27 L3.58,19.54 C3.81,20.38 4.58,21 5.5,21 L18.5,21 C19.42,21 20.19,20.38 20.43,19.54 L22.97,10.27 L23,10 C23,9.45 22.55,9 22,9 Z M12,4.8 L14.8,9 L9.2,9 L12,4.8 Z M18.5,19 L5.51,19.01 L3.31,11 L20.7,11 L18.5,19 Z';
export const ICON_LOGOUT =
  'M17 7l-1.41 1.41L18.17 11H8v2h10.17l-2.58 2.58L17 17l5-5-5-5zM4 5h8V3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8v-2H4V5z';
export const ICON_MESSAGE_ERROR =
  'M23 12c0-6.08-4.92-11-11-11S1 5.92 1 12c0 6.07 4.92 11 11 11h10.96l-3.19-3.22c2-1.99 3.23-4.74 3.23-7.78zm-6.29 4.71c-.2.19-.45.29-.71.29-.26 0-.51-.1-.71-.29l-3.3-3.31-3.28 3.3a.992.992 0 01-1.41.01.987.987 0 01-.01-1.41l3.29-3.32-3.26-3.27A.996.996 0 118.73 7.3l3.25 3.26 3.27-3.29c.39-.39 1.02-.39 1.41-.01.39.39.39 1.02.01 1.41l-3.28 3.3 3.31 3.32c.4.4.4 1.03.01 1.42z';
export const ICON_MESSAGE_INFO =
  'M23 12c0-6.08-4.92-11-11-11S1 5.92 1 12c0 6.07 4.92 11 11 11h10.96l-3.19-3.22c2-1.99 3.23-4.74 3.23-7.78zm-10 4c0 .55-.45 1-1 1s-1-.45-1-1v-5c0-.55.45-1 1-1s1 .45 1 1v5zm-1-7c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z';
export const ICON_MESSAGE_NOTE =
  'M23 12c0-6.08-4.92-11-11-11S1 5.92 1 12c0 6.07 4.92 11 11 11h10.96l-3.19-3.22c2-1.99 3.23-4.74 3.23-7.78zM8 17c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm4 0c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm4 0c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z';
export const ICON_NOTE =
  'M23,12 C23,5.92 18.08,1 12,1 C5.92,1 1,5.92 1,12 C1,18.07 5.92,23 12,23 L12,23 L22.96,23 L19.77,19.78 C21.77,17.79 23,15.04 23,12 Z M8,17 C7.45,17 7,16.55 7,16 C7,15.45 7.45,15 8,15 C8.55,15 9,15.45 9,16 C9,16.55 8.55,17 8,17 Z M12,17 C11.45,17 11,16.55 11,16 C11,15.45 11.45,15 12,15 C12.55,15 13,15.45 13,16 C13,16.55 12.55,17 12,17 Z M16,17 C15.45,17 15,16.55 15,16 C15,15.45 15.45,15 16,15 C16.55,15 17,15.45 17,16 C17,16.55 16.55,17 16,17 Z';
export const ICON_MESSAGE_SUCCESS =
  'M23 12c0-6.08-4.92-11-11-11S1 5.92 1 12c0 6.07 4.92 11 11 11h10.96l-3.19-3.22c2-1.99 3.23-4.74 3.23-7.78zm-5.32-2.33l-5.97 6.03v.01c-.07.07-.15.11-.23.15-.03.02-.06.05-.09.06A.91.91 0 0111 16c-.13 0-.26-.02-.38-.07a.834.834 0 01-.12-.08c-.07-.04-.15-.08-.21-.14l-2.97-3a.987.987 0 01.01-1.41.996.996 0 011.41.01L11 13.58l5.26-5.31a.996.996 0 011.41-.01c.4.39.4 1.02.01 1.41z';
export const ICON_MESSAGE_WARNING =
  'M23 12c0-6.08-4.92-11-11-11S1 5.92 1 12c0 6.07 4.92 11 11 11h10.96l-3.19-3.22c2-1.99 3.23-4.74 3.23-7.78zm-10 1c0 .55-.45 1-1 1s-1-.45-1-1V8c0-.55.45-1 1-1s1 .45 1 1v5zm-1 4c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z';
export const ICON_MINUS_SQUARE_OUTLINE =
  'M19,2c1.66,0,3,1.34,3,3v14 c0,1.66-1.34,3-3,3H5c-1.66,0-3-1.34-3-3V5c0-1.66,1.34-3,3-3H19z M19,4H5C4.44,4,4,4.45,4,5v14c0,0.56,0.45,1,1,1h14 c0.56,0,1-0.45,1-1V5C20,4.44,19.55,4,19,4z M17,11v2H7v-2H17z';
export const ICON_PACKAGE =
  'M5.12 5h13.75l-.94-1h-12l-.81 1zm15.42.23c.29.34.46.77.46 1.27V19a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V6.5c0-.5.17-.93.46-1.27l1.38-1.68C5.12 3.21 5.53 3 6 3h12c.47 0 .88.21 1.15.55l1.39 1.68zM6 18h6v-3H6v3z';
export const ICON_SMILEY_HAPPY =
  'M12 1C5.92 1 1 5.92 1 12s4.92 11 11 11 11-4.92 11-11S18.08 1 12 1zM7.5 8C8.33 8 9 8.67 9 9.5S8.33 11 7.5 11 6 10.33 6 9.5 6.67 8 7.5 8zm10.27 7.08c-3.3 3.36-8.23 3.36-11.54 0-.26-.27-.3-.69-.11-.92.2-.22.53-.2.76.03 2.93 2.98 7.3 2.98 10.22 0 .23-.23.57-.26.76-.03.21.22.17.65-.09.92zM16.5 11c-.83 0-1.5-.67-1.5-1.5S15.67 8 16.5 8s1.5.67 1.5 1.5-.67 1.5-1.5 1.5z';
export const ICON_SMILEY_NEUTRAL =
  'M12 1C5.92 1 1 5.92 1 12s4.92 11 11 11 11-4.92 11-11S18.08 1 12 1zM6 9.5C6 8.67 6.67 8 7.5 8S9 8.67 9 9.5 8.33 11 7.5 11 6 10.33 6 9.5zm10.54 5.75l-8.89 1.74c-.3.06-.59-.17-.64-.51-.05-.34.15-.67.45-.73l8.89-1.74c.3-.05.59.17.65.51.04.34-.16.66-.46.73zM16.5 11c-.83 0-1.5-.67-1.5-1.5S15.67 8 16.5 8s1.5.67 1.5 1.5-.67 1.5-1.5 1.5z';
export const ICON_SMILEY_UNHAPPY =
  'M12 1C5.92 1 1 5.92 1 12s4.92 11 11 11 11-4.92 11-11S18.08 1 12 1zM6 9.5C6 8.67 6.67 8 7.5 8S9 8.67 9 9.5 8.33 11 7.5 11 6 10.33 6 9.5zm9.87 7.37c-.23.19-.62.16-.91-.02-1.74-1.14-4.18-1.14-5.92 0-.28.18-.68.21-.91.02-.23-.19-.15-.58.21-.81 2.16-1.41 5.16-1.41 7.32 0 .36.24.44.63.21.81zM16.5 11c-.83 0-1.5-.67-1.5-1.5S15.67 8 16.5 8s1.5.67 1.5 1.5-.67 1.5-1.5 1.5z';
export const ICON_SMILEY_UNHAPPY_ALT =
  'M12 1C5.926 1 1 5.926 1 12s4.926 11 11 11 11-4.926 11-11S18.074 1 12 1zM5.278 9.861a1.528 1.528 0 113.056.001 1.528 1.528 0 01-3.056 0zM12 13.222a2.444 2.444 0 012.444 2.445v1.222a2.444 2.444 0 11-4.888 0v-1.222A2.444 2.444 0 0112 13.222zm5.194-4.889a1.528 1.528 0 110 3.057 1.528 1.528 0 010-3.057z';
// SOCIAL MEDIA
export const ICON_FACEBOOK =
  'M5 3h14a2 2 0 012 2v14a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2zm13 2h-2.5A3.5 3.5 0 0012 8.5V11h-2v3h2v7h3v-7h3v-3h-3V9a1 1 0 011-1h2V5z';
export const ICON_INSTAGRAM =
  'M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 01-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 017.8 2zm-.2 2A3.6 3.6 0 004 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 003.6-3.6V7.6C20 5.61 18.39 4 16.4 4H7.6zm9.65 1.5a1.25 1.25 0 110 2.5 1.25 1.25 0 010-2.5zM12 7a5 5 0 110 10 5 5 0 010-10zm0 2a3 3 0 100 6 3 3 0 000-6z';
export const ICON_LINKEDIN =
  'M19 3a2 2 0 012 2v14a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2h14zm-.5 15.5v-5.3c0-1.8-1.46-3.26-3.26-3.26-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 011.4 1.4v4.93h2.79zM6.88 8.56a1.686 1.686 0 000-3.37 1.69 1.69 0 00-1.69 1.69c0 .93.76 1.68 1.69 1.68zm1.39 9.94v-8.37H5.5v8.37h2.77z';
export const ICON_XING =
  'M4.8 3C3.8 3 3 3.8 3 4.8v14.4c0 1 .8 1.8 1.8 1.8h14.4c1 0 1.8-.8 1.8-1.8V4.8c0-1-.8-1.8-1.8-1.8H4.8zm11.27 2h2.04c.12 0 .22.04.26.13.06.09.06.2 0 .31l-4.47 7.92 2.85 5.2c.06.11.06.22 0 .31-.05.08-.14.13-.25.13h-2.03c-.31 0-.47-.21-.56-.39l-2.87-5.26 4.49-7.96c.11-.2.24-.39.54-.39zM7.09 7.76H9.1c.31 0 .47.2.57.39l1.39 2.42c-.09.14-2.18 3.85-2.18 3.85-.11.19-.25.39-.56.39H6.3c-.12 0-.21-.05-.26-.14a.413.413 0 010-.31l2.14-3.79L6.82 8.2c-.05-.11-.07-.2-.01-.31.05-.08.15-.13.28-.13z';
export const ICON_YOUTUBE =
  'M10 15l5.19-3L10 9v6zm11.56-7.83c.13.47.22 1.1.28 1.9.07.8.1 1.49.1 2.09L22 12c0 2.19-.16 3.8-.44 4.83-.25.9-.83 1.48-1.73 1.73-.47.13-1.33.22-2.65.28-1.3.07-2.49.1-3.59.1L12 19c-4.19 0-6.8-.16-7.83-.44-.9-.25-1.48-.83-1.73-1.73-.13-.47-.22-1.1-.28-1.9-.07-.8-.1-1.49-.1-2.09L2 12c0-2.19.16-3.8.44-4.83.25-.9.83-1.48 1.73-1.73.47-.13 1.33-.22 2.65-.28 1.3-.07 2.49-.1 3.59-.1L12 5c4.19 0 6.8.16 7.83.44.9.25 1.48.83 1.73 1.73z';
export const ICON_ROCKET_LAUNCH = "M13.13 22.19L11.5 18.36C13.07 17.78 14.54 17 15.9 16.09L13.13 22.19M5.64 12.5L1.81 10.87L7.91 8.1C7 9.46 6.22 10.93 5.64 12.5M19.22 4C19.5 4 19.75 4 19.96 4.05C20.13 5.44 19.94 8.3 16.66 11.58C14.96 13.29 12.93 14.6 10.65 15.47L8.5 13.37C9.42 11.06 10.73 9.03 12.42 7.34C15.18 4.58 17.64 4 19.22 4M19.22 2C17.24 2 14.24 2.69 11 5.93C8.81 8.12 7.5 10.53 6.65 12.64C6.37 13.39 6.56 14.21 7.11 14.77L9.24 16.89C9.62 17.27 10.13 17.5 10.66 17.5C10.89 17.5 11.13 17.44 11.36 17.35C13.5 16.53 15.88 15.19 18.07 13C23.73 7.34 21.61 2.39 21.61 2.39S20.7 2 19.22 2M14.54 9.46C13.76 8.68 13.76 7.41 14.54 6.63S16.59 5.85 17.37 6.63C18.14 7.41 18.15 8.68 17.37 9.46C16.59 10.24 15.32 10.24 14.54 9.46M8.88 16.53L7.47 15.12L8.88 16.53M6.24 22L9.88 18.36C9.54 18.27 9.21 18.12 8.91 17.91L4.83 22H6.24M2 22H3.41L8.18 17.24L6.76 15.83L2 20.59V22M2 19.17L6.09 15.09C5.88 14.79 5.73 14.47 5.64 14.12L2 17.76V19.17Z"

const sizes = {
  xxs: 8,
  xs: 16,
  sm: 24,
  md: 32,
  lg: 40,
  xl: 48,
  xxl: 56,
};

export const BackIcon = () => (
  <svg width="13px" height="12px" viewBox="0 0 13 12" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>arrow_back-24px</title>
    <g id="Breadcrumb-MSP" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="1000-Breadcrumb-MSP" transform="translate(-39.000000, -1199.000000)">
        <g id="arrow_back-24px" transform="translate(39.000000, 1193.000000)">
          <polygon id="Path" points="0 0 19 0 19 24 0 24"></polygon>
          <polygon
            id="Path"
            fill="#e20613"
            points="13 11 3.83 11 7.42 7.41 6 6 0 12 6 18 7.41 16.59 3.83 13 13 13"
          ></polygon>
        </g>
      </g>
    </g>
  </svg>
);

const Icon = ({path, alt = '', size = sizes.sm, className = '', viewBox = '0 0 24 24', gradient, cyData}) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox={viewBox}
    fill="currentColor"
    focusable="false"
    data-cy={cyData}
  >
    {alt && <title>{alt}</title>}
    {gradient != null && gradient}
    <path d={path}></path>
  </svg>
);

Icon.sizes = sizes;

Icon.propTypes = {
  path: PropTypes.string.isRequired,
  alt: PropTypes.string,
  size: PropTypes.oneOf(Object.values(sizes)),
  className: PropTypes.string,
  viewBox: PropTypes.string,
  gradient: PropTypes.node,
  cyData: PropTypes.string,
};

export default Icon;
