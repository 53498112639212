import React from 'react';
import cx from 'classnames';

import language, { getLocale } from 'constants/language/language';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';

import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';
import Icon, { ICON_FAVORITE_BORDER } from 'components/atoms/Icon/Icon';

import styles from './WatchlistEmpty.scss';

const Watchlist = () => (
  <div className={styles.emptyWrapper}>
    <span className={cx(styles.infoText, styles.title)}>{language('watchlist.empty.title')}</span>
    <span className={cx(styles.infoText, styles.subTitle)}>{language('watchlist.empty.partOne')}</span>
    <span className={cx(styles.infoText, styles.subTitle)}>{language('watchlist.empty.partTwo')}</span>
    <div className={styles.shoppingButton}>
      <ButtonLink
        variety={ButtonLink.varieties.stroked}
        size={ButtonLink.sizes.md}
        href={mapPathToLocalizedUrl(getLocale())}
      >
        {language('watchlist.empty.shopNow')}
      </ButtonLink>
    </div>
    <div className={styles.emptyIcon}>
      <Icon path={ICON_FAVORITE_BORDER} />
    </div>
  </div>
);

Watchlist.displayName = 'molecules/Watchlist';

export default Watchlist;
