import { CUSTOMER_GROUP } from 'constants/storage/storageKeys';
import getStorage from 'constants/storage/storage';
const localStorage = getStorage(true);

export const customerGroup = {
  CUSTOMERGROUP: 'CUSTOMERGROUP',
  B2BCUSTOMERGROUP: 'B2BCUSTOMERGROUP',
  B2BCUSTOMERINVOICEGROUP: 'B2BCUSTOMERINVOICEGROUP',
};

export const b2bGroups = [customerGroup.B2BCUSTOMERGROUP, customerGroup.B2BCUSTOMERINVOICEGROUP];

export const isB2BCustomer = (group = localStorage.getItem(CUSTOMER_GROUP)) => b2bGroups.includes(group);
