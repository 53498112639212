import React from 'react';
import StoreFinderContainer from 'containers/StoreFinderContainer/StoreFinderContainer';
import StoreFinderTemplate from 'components/templates/StoreFinderTemplate/StoreFinderTemplate';
import Meta from 'containers/Meta/Meta';
import language from 'constants/language/language';
import { getEnv } from 'config/config';
import {useFeatureToggle} from "hooks/index";

const StoreFinder = () => {
  const nextStorefinderEnabled = useFeatureToggle('nextStorefinderEnabled') === "true" ?? false;
  const setCanonicalUrl = () => {
    if (!__CLIENT__) return null;

    const url = new URL(window.location.href);
    let { pathname } = url;

    return [{ rel: 'canonical', href: `${getEnv('web')}${pathname}` }];
  };

  if (__CLIENT__ && nextStorefinderEnabled) {
    window.location.reload()
    return <></>;
  }

  return (
    <>
      <Meta
        title={language('pageTitles.storeFinder')}
        titleFirst={false}
        meta={[{ name: 'description', content: language('meta.storeFinder') }]}
        canonical={setCanonicalUrl()}
      />
      <StoreFinderContainer>
        <StoreFinderTemplate isStandalone />
      </StoreFinderContainer>
    </>
  );
};

export default StoreFinder;
