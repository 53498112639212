import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import language from 'constants/language/language';
import Accessory from './Accessory';
import Headline, { alignments, tags } from 'components/atoms/Headline/Headline';
import { noop } from 'constants/noop/noop';
import chunk from 'constants/helper/chunk';
import Slider from 'components/organisms/Slider/Slider';
import styles from './Accessories.scss';
import { productClick } from 'actions/trackingActions/trackingActions';
import { ET } from 'tracking/trackingHelper';

const mapDispatchToProps = {
  productClick: productClick,
};

const Accessories = ({
  referenceItemCodes = [],
  accessories = [],
  countTimesAddedAsAccessory = noop,
  productClick,
}) => {
  if (referenceItemCodes.length <= 0) {
    return null;
  }
  const slides = chunk(referenceItemCodes, 4).map((chunk, slideIndex) => (
    <div className={styles.slide} key={slideIndex}>
      {chunk.map((accessoryCode) => (
        <div className={styles.productWrapper} key={accessoryCode}>
          <Accessory
            onProductClick={productClick}
            accessoryCode={accessoryCode}
            accessories={accessories}
            countTimesAddedAsAccessory={countTimesAddedAsAccessory}
            productAttributes={{
              elementTitle: ET.ACCESSOIRES_POPUP,
            }}
          />
        </div>
      ))}
    </div>
  ));

  return (
    <>
      <Headline alignment={alignments.LEFT} tag={tags.H3}>
        {`${language('product.addAccessoriesTab.title')} ${language('product.addAccessoriesTab.extended')}`}
      </Headline>
      <div className={styles.sliderWrapper}>
        <Slider slides={slides} slidesPerView={1} spaceBetween={20} />
      </div>
    </>
  );
};

Accessories.displayName = 'organisms/ServicesAndAccessories/Accessories';
Accessories.propTypes = {
  referenceItemCodes: PropTypes.array,
  accessories: PropTypes.array,
  countTimesAddedAsAccessory: PropTypes.func.isRequired,
  productClick: PropTypes.func,
};

export default connect(undefined, mapDispatchToProps)(Accessories);
