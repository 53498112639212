import { getCategoriesPath } from 'constants/categoryTree/categoryTree';
import { mapQueryParamsToUrl, mapQueryStringToQueryParams, mergeQueryString } from 'constants/urlMapping/urlMapping';

export const getSeoUrl = (currentQueryString, facetQuery, searchString, locale, categories) => {
  const newQueryParams = { facetQuery, searchString };

  const computedQueryString = mergeQueryString(currentQueryString, newQueryParams);

  const queryParams = mapQueryStringToQueryParams(computedQueryString);

  return mapQueryParamsToUrl(queryParams, locale, categories);
};

export const getBreadcrumbs = (categoryCode, currentQueryString, searchString = '', locale, categories = {}) => {
  let breadcrumbs = [];

  if (!categories[categoryCode]) {
    return breadcrumbs;
  }

  if (categories[categoryCode].level === 1) {
    const facetQuery = `${searchString}:relevance:categoryPath:/${getCategoriesPath(categoryCode, categories).join(
      '/'
    )}`;
    const href = getSeoUrl(currentQueryString, facetQuery, searchString, locale, categories);
    breadcrumbs = [
      {
        name: categories[categoryCode][locale],
        href,
      },
    ];
  } else {
    const parent = categories[categoryCode].parent;
    const categoryPath = getBreadcrumbs(parent, currentQueryString, searchString, locale, categories);
    const facetQuery = `${searchString}:relevance:categoryPath:/${getCategoriesPath(categoryCode, categories).join(
      '/'
    )}`;
    const href = getSeoUrl(currentQueryString, facetQuery, searchString, locale, categories);
    const breadCrumbItem = {
      name: categories[categoryCode][locale],
      href,
    };
    breadcrumbs = [...categoryPath, breadCrumbItem];
  }
  return breadcrumbs;
};

export default getBreadcrumbs;
