import React from 'react';
import PropTypes from 'prop-types';

import { getLocale } from 'constants/language/language';

const LogoStore = ({ code }) => {
  const c = code.split('_')[1];
  const locale = getLocale();
  let src;
  switch (c) {
    case '002':
      src = 'Microspot';
      break;
    case '100':
      src = 'CoopCity';
      break;
    case '101':
      src = 'CoopPronto';
      break;
    case '102':
      src = 'CoopAtHome';
      break;
    case '103':
      src = 'Coop';
      break;
    case '104':
      src = `coop-bh-${locale}`;
      break;
    case '105':
      src = `christ-${locale}`;
      break;
    case '106':
      src = 'import-parfumerie';
      break;
    case '107':
      src = 'livique';
      break;
    case '108':
      src = 'lumimart';
      break;
    default:
      src = 'Interdiscount';
  }

  return <img src={`/images/storeLogos/${src}.svg`} alt={src} />;
};

LogoStore.propTypes = {
  code: PropTypes.string.isRequired,
};

LogoStore.displayName = 'atoms/LogoStore';

export default LogoStore;
