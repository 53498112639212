import restClient from 'api/RestClient/RestClient';
import { resourceKeys } from 'constants/restResources/restResources';
import { INVALID_PARAMS } from 'constants/promises/promises';

const NEWSLETTER_RESOURCE = resourceKeys.NEWSLETTER_RESOURCE;
const EMAIL_RESOURCE = resourceKeys.EMAIL_RESOURCE;

const NewsletterAPI = (function NewsletterAPI() {
  const subscribeToNewsletter = (email, birthday, titleCode, firstName, lastName) => {
    if (!email || !birthday || !titleCode || !firstName || !lastName) {
      return Promise.reject(INVALID_PARAMS);
    }

    return restClient.post(NEWSLETTER_RESOURCE, `/register`, {
      email,
      birthday,
      titleCode,
      firstName,
      lastName,
    });
  };

  const unsubscribeNewsletter = (hash, isPersonalized = false) => {
    if (hash === undefined) {
      return Promise.reject(INVALID_PARAMS);
    }

    return restClient.put(NEWSLETTER_RESOURCE, `/unsubscribe?personalized=${isPersonalized}`, {
      newsletterHash: hash,
    });
  };

  const completeUserData = (data) => restClient.put(NEWSLETTER_RESOURCE, '', data);

  const verifyNewsletterToken = (token) => {
    if (!token) {
      return Promise.reject(INVALID_PARAMS);
    }

    return restClient.put(NEWSLETTER_RESOURCE, '/verify', { newsletterHash: token });
  };

  const getEmailFromHash = (hash) => {
    if (!hash) {
      return Promise.reject(INVALID_PARAMS);
    }

    return restClient.put(NEWSLETTER_RESOURCE, '/resolveHash', { newsletterHash: hash });
  };

  const optinNewsletter = (hash) => {
    if (!hash) {
      return Promise.reject(INVALID_PARAMS);
    }

    return restClient.post(NEWSLETTER_RESOURCE, '/optin', { newsletterHash: hash });
  };

  const verifyEmailToken = (token) => {
    if (!token) {
      return Promise.reject(INVALID_PARAMS);
    }

    return restClient.put(EMAIL_RESOURCE, '/token/emailVerification', { newsletterHash: encodeURIComponent(token) });
  };

  const sendOptoutReasons = (data) =>
    restClient.put(NEWSLETTER_RESOURCE, '/unsubscribeReason', {
      newsletterHash: encodeURIComponent(data.hash),
      unsubscribeReasons: [...data.reasons],
      text: data.comment,
    });

  return {
    subscribeToNewsletter,
    unsubscribeNewsletter,
    completeUserData,
    verifyNewsletterToken,
    getEmailFromHash,
    optinNewsletter,
    verifyEmailToken,
    sendOptoutReasons,
  };
})();

export default NewsletterAPI;
