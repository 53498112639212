import PropTypes from 'prop-types';
import {
  COMPONENT_ATTRIBUTE_1,
  COMPONENT_ATTRIBUTE_2,
  COMPONENT_ATTRIBUTE_3,
  COMPONENT_FUNCTION,
  COMPONENT_INNERLOCATION,
  COMPONENT_INTERACTION,
  COMPONENT_LINK,
  COMPONENT_LOCATION,
  COMPONENT_TYPE,
  PRODUCT_INFO_SKU,
} from 'constants/trackingAttributes/trackingAttributes';

const dataTracking = (props = {}) => {
  return JSON.stringify(dataTrackingRaw(props));
};

/**
 * @typedef {Object} TrackingDataType
 * @property {string} [TYPE]  - component_type  -  (e.g. main_type:subtype) This is what the type is called in the report and in the Tealium data layer
 * @property {string} [LINK]  - component_link  - URL to which the component points. Only set if the link is followed to another page.
 * @property {number} [LOCATION]  - component_location  - Allows distinguishing multiple instances of the same component on a page (position from top left to bottom right, starting at "1")
 * @property {number} [INNERLOCATION] - component_innerlocation - Position within a component. Set when a component contains multiple instances of an item with which it can interact (e.g. product list with multiple products).
 * @property {'click'|'drag'|'swipe'|'slide'|'submit'} [INTERACTION] - component_interaction - Technical name of the user interaction (typically the name of the event being listened to, e.g. "click", "swipe", etc.). If not set, "click" is automatically set by Tealium.
 * @property {string} [ATTRIBUTE_1] - component_attribute1  - Can be used to further detail the component, such as different variants of a component, the linked text, etc.
 * @property {string} [ATTRIBUTE_2] - component_attribute2  - Can be used to further detail the component, such as different variants of a component, the linked text, etc.
 * @property {string} [ATTRIBUTE_3] - component_attribute3  - Can be used to further detail the component, such as different variants of a component, the linked text, etc.
 * @property {'apply'|'browse'|'check'|'close'|'error'|'filter'|'focus'|'unfocus'|'go-to'|'open'|'open-overlay'|'rate'|'search'|'select'|'switch-tab'|'uncheck'|'vote'|'compare'} [FUNCTION]  - component_function  - Function of the component. What action does this trigger?
 * @property {string} [SKU] - product_productInfo_sku - Function of the component. What action does this trigger? If the link is to a product: Product SKU that is linked to
 */

/**
 *
 * @param {TrackingDataType} props
 * @returns {{}}
 */
export const dataTrackingRaw = (props = {}) => {
  const keyTypes = Object.keys(props)
    .map((prop) => ({
      [prop]: PropTypes.oneOf([
        'TYPE',
        'LINK',
        'LOCATION',
        'INNERLOCATION',
        'INTERACTION',
        'ATTRIBUTE_1',
        'ATTRIBUTE_2',
        'ATTRIBUTE_3',
        'FUNCTION',
        'SKU',
      ]),
    }))
    .reduce((objs, obj) => ({ ...objs, ...obj }), {});
  PropTypes.checkPropTypes(
    keyTypes,
    Object.keys(props)
      .map((prop) => ({ [prop]: prop }))
      .reduce((objs, obj) => ({ ...objs, ...obj }), {}),
    'tracking',
    'dataTracking'
  );

  const valueTypes = {
    TYPE: PropTypes.string,
    LINK: PropTypes.string,
    LOCATION: PropTypes.number,
    INNERLOCATION: PropTypes.number,
    INTERACTION: PropTypes.oneOf(['click', 'drag', 'swipe', 'slide' | 'submit']),
    ATTRIBUTE_1: PropTypes.string,
    ATTRIBUTE_2: PropTypes.string,
    ATTRIBUTE_3: PropTypes.string,
    FUNCTION: PropTypes.oneOf([
      'apply',
      'browse',
      'check',
      'close',
      'error',
      'filter',
      'focus',
      'unfocus',
      'go-to',
      'open',
      'open-overlay',
      'rate',
      'search',
      'select',
      'switch-tab',
      'uncheck',
      'vote',
      'compare',
    ]),
    SKU: PropTypes.string,
  };
  PropTypes.checkPropTypes(valueTypes, props, 'tracking', 'dataTracking');

  const {
    TYPE,
    LINK,
    LOCATION,
    INNERLOCATION,
    INTERACTION,
    ATTRIBUTE_1,
    ATTRIBUTE_2,
    ATTRIBUTE_3,
    FUNCTION,
    SKU,
  } = props;
  const data = {
    ...(TYPE && { [COMPONENT_TYPE]: TYPE }),
    ...(LINK && { [COMPONENT_LINK]: LINK }),
    ...(LOCATION && { [COMPONENT_LOCATION]: LOCATION }),
    ...(INNERLOCATION && { [COMPONENT_INNERLOCATION]: INNERLOCATION }),
    ...(INTERACTION && { [COMPONENT_INTERACTION]: INTERACTION }),
    ...(ATTRIBUTE_1 && { [COMPONENT_ATTRIBUTE_1]: ATTRIBUTE_1 }),
    ...(ATTRIBUTE_2 && { [COMPONENT_ATTRIBUTE_2]: ATTRIBUTE_2 }),
    ...(ATTRIBUTE_3 && { [COMPONENT_ATTRIBUTE_3]: ATTRIBUTE_3 }),
    ...(FUNCTION && { [COMPONENT_FUNCTION]: FUNCTION }),
    ...(SKU && { [PRODUCT_INFO_SKU]: SKU }),
  };
  return data;
};

export default dataTracking;
