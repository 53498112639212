import React from 'react';
import PropTypes from 'prop-types';

import Headline from 'components/atoms/Headline/Headline';
import styles from './Pagetitle.scss';
import cx from 'classnames';

const Pagetitle = ({ children, beforeBox = true, centered = false }) => (
  <div className={cx(styles.container, { [styles.beforeBox]: beforeBox })}>
    <Headline
      tag={Headline.tags.H1}
      skin={Headline.skins.pagetitle}
      alignment={centered ? Headline.alignments.CENTER : Headline.alignments.LEFT}
    >
      {children}
    </Headline>
  </div>
);

Pagetitle.propTypes = {
  children: PropTypes.node,
  beforeBox: PropTypes.bool,
  centered: PropTypes.bool,
};

Pagetitle.displayName = 'atoms/Pagetitle';

export default Pagetitle;
