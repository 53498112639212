import React from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';

import IconText from 'components/atoms/IconText/IconText';
import StarRating from 'components/atoms/StarRating/StarRating';
import TextEllipsis from 'components/atoms/TextEllipsis/TextEllipsis';
import Image from 'components/atoms/Image/Image';

import RatingStatusMessage from 'components/molecules/Rating/RatingStatusMessage/RatingStatusMessage';
import formatDate from 'constants/formatDate/formatDate';
import { getProductLink } from 'constants/getUrlForProduct/getUrlForProduct';
import { showRatingCommentStatusBox } from 'constants/ratings/ratings';
import language, { getLocale } from 'constants/language/language';

import styles from './AccountRatingList.scss';
import { ICON_CHECK } from 'components/atoms/Icon/Icon';
import { NextFeatureLink } from 'components/atoms/NextFeatureLink';

const AccountRatingList = ({ ratings = [], rated, onItemClick, onItemDelete, categories }) => {
  const renderItem = (rating) => {
    const onClick = () => {
      if (onItemClick) onItemClick(rating);
    };

    const onDelete = () => {
      if (onItemDelete) onItemDelete(rating);
    };

    const renderRatingInfo = () => (
      <div className={styles.ratingInfo}>
        <div className={styles.ratingResult}>
          {rating.rated && language(`myAccountRatings.${rating.recommends ? 'recommended' : 'notRecommended'}`)}
          <StarRating value={rating.rating} disabled={!rated} />
        </div>
        <div>
          {onItemClick && (
            <button type="button" className={styles.button} onClick={onClick}>
              <small>{language(`myAccountRatings.list.${rated ? 'linkEdit' : 'linkRate'}`)}</small>
            </button>
          )}
          {showDeleteBtn && (
            <>
              {' | '}
              <button type="button" className={styles.button} onClick={onDelete}>
                <small>{language('myAccountRatings.list.linkDelete')}</small>
              </button>
            </>
          )}
        </div>
      </div>
    );

    const showDeleteBtn = rated && onItemDelete;
    const productUrl = getProductLink(
      rating.categoryCode,
      rating.productName,
      rating.productCode,
      getLocale(),
      categories
    );

    return (
      <li key={rating.productCode} className={styles.listItem}>
        <div className={styles.itemHead}>
          <LazyLoad once height={90} placeholder={<div className={styles.image} />}>
            <div className={styles.image}>
              <Image
                alt={rating.productImage?.altText}
                images={rating.productImage?.sizes}
                code={rating.productImage?.code}
              />
            </div>
          </LazyLoad>
          <div className={styles.itemHeadRight}>
            <div className={styles.titleWrapper}>
              <h4 className={styles.headline}>
                <NextFeatureLink feature="nextPdpEnabled" to={productUrl}>
                  {rating.productName}
                </NextFeatureLink>
              </h4>
              {rating.orderDate && (
                <IconText icon={ICON_CHECK}>
                  <small>
                    {language('myAccountRatings.list.bought')} {formatDate(rating.orderDate)}
                  </small>
                </IconText>
              )}
            </div>
            {renderRatingInfo()}
          </div>
        </div>
        {rating.headline && (
          <div className={styles.commentWrapper}>
            {showRatingCommentStatusBox(rating) && <RatingStatusMessage {...rating} shortMessage />}
            <h4 className={styles.commentTitle}>{rating.headline}</h4>
            <TextEllipsis
              text={String(rating.comment)}
              showLines={2}
              moreLabel={language('productRating.comments.readMore')}
              lessLabel={language('productRating.comments.readLess')}
            />
          </div>
        )}
        {renderRatingInfo()}
      </li>
    );
  };

  return ratings.length ? <ul className={styles.list}>{ratings.map((rating) => renderItem(rating))}</ul> : null;
};

AccountRatingList.displayName = 'molecules/AccountRatingList';
AccountRatingList.propTypes = {
  ratings: PropTypes.array,
  rated: PropTypes.bool,
  onItemClick: PropTypes.func,
  onItemDelete: PropTypes.func,
  categories: PropTypes.object,
};

export default AccountRatingList;
