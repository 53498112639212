import React from 'react';
import PropTypes from 'prop-types';

import ProductFlag from 'components/atoms/ProductFlag/ProductFlag';

import styles from './ProductFlagList.scss';

const MAX_ITEMS = 3;

const ProductFlagList = ({ flags = [] }) =>
  flags
    .slice(0, MAX_ITEMS)
    .filter((flag) => !flag.cmsPromoBox && !!flag.text)
    .map((flag) => (
      <div className={styles.ProductFlag} key={flag.text}>
        <ProductFlag text={flag.text} link={flag.link} className={styles.ArrowRight} angle={ProductFlag.ANGLES.RIGHT} />
      </div>
    ));

ProductFlagList.propTypes = {
  flags: PropTypes.arrayOf((propValue, key, componentName, location, propFullName) => {
    if (propValue.length > MAX_ITEMS) {
      return new Error(
        `Invalid prop \`${propFullName}\` supplied to \`${componentName}\`. ` +
          `Max length is ${MAX_ITEMS} but got Array with length of ${propValue.length}.`
      );
    }
    return null;
  }).isRequired,
};

ProductFlagList.displayName = 'molecules/ProductFlagList';

export default ProductFlagList;
