import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'constants/noop/noop';
import Icon, { ICON_ARROW_DROPDOWN } from 'components/atoms/Icon/Icon';
import styles from './SortingProducts.scss';

const SortingProducts = ({ onChange = noop, options, value = '' }) => (
  <div className={styles.wrapper}>
    <select onChange={onChange} value={value}>
      {Object.keys(options).map((key) => (
        <option key={key} value={key}>
          {options[key]}
        </option>
      ))}
    </select>
    <Icon path={ICON_ARROW_DROPDOWN} className={styles.icon} />
  </div>
);

SortingProducts.displayName = 'molecules/SortingProducts';
SortingProducts.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.object,
  value: PropTypes.string,
};

export default SortingProducts;
