import React, { useState, useEffect } from 'react';
import cx from 'classnames';

import CartIndicatorContainer from 'containers/Indications/Cart';
import MainMenu from 'containers/MainMenu/MainMenu';

import CartIndicator from 'components/atoms/CartIndicator/CartIndicator';
import Icon, { ICON_BASKET, ICON_LOCAL_OFFER_OUTLINED, ICON_LOCATION_ON_OUTLINED } from 'components/atoms/Icon/Icon';
import MenuTrigger from 'components/atoms/MenuTrigger/MenuTrigger';
import InternalOrExternalLink from 'components/atoms/InternalOrExternalLink/InternalOrExternalLink';
import useFeatureToggle from 'hooks/useFeatureToggle';

import language, { getLocale } from 'constants/language/language';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import { STOREFINDER, WALL, CART } from 'constants/routePaths/routePaths';

import { ModalMenu } from 'components/organisms/ModalMenu/ModalMenu';
import { BREAKPOINTS } from 'constants/breakpoints/breakpoints';
import debounce from 'constants/helper/debounce';
import matchMedia from 'constants/matchMedia/matchMedia';

import styles from './MobileNavigation.scss';
import { NextFeatureLink } from 'components/atoms/NextFeatureLink/NextFeatureLink';

export const MobileNavigation = () => {
  const nextStorefinderEnabled = useFeatureToggle('nextStorefinderEnabled') === 'true' ?? false;
  const [routePath, setRoutePath] = useState();
  const [isDesktop, setIsDesktop] = useState(matchMedia(BREAKPOINTS.LG));

  const handleResize = debounce(() => {
    setIsDesktop(matchMedia(BREAKPOINTS.LG));
  }, 0);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    if (__CLIENT__) {
      setRoutePath(getActualPath());
    }
  }, []);

  // gets actual path after localization
  const getActualPath = () => window?.location.pathname.split('/')[2];

  return (
    <div className={styles.wrapper}>
      <div className={styles.navigationItem}>
        <MainMenu>
          <MenuTrigger />
          <ModalMenu isDesktop={isDesktop} />
        </MainMenu>
      </div>
      <NextFeatureLink
        feature="nextPromotionsEnabled"
        to={mapPathToLocalizedUrl(getLocale(), [WALL])}
        className={cx(styles.navigationItem, { [styles.active]: routePath === WALL })}
      >
        <Icon path={ICON_LOCAL_OFFER_OUTLINED} className={styles.icon} />
        <small>{language('navigation.promotion')}</small>
      </NextFeatureLink>
      <InternalOrExternalLink
        external={nextStorefinderEnabled}
        href={mapPathToLocalizedUrl(getLocale(), [STOREFINDER])}
        className={cx(styles.navigationItem, { [styles.active]: routePath === STOREFINDER })}
      >
        <Icon path={ICON_LOCATION_ON_OUTLINED} className={styles.icon} />
        <small>{language('navigation.stores')}</small>
      </InternalOrExternalLink>
      <NextFeatureLink
        to={mapPathToLocalizedUrl(getLocale(), [CART])}
        className={cx(styles.navigationItem, { [styles.active]: routePath === CART }, styles.cart)}
        feature="nextCartEnabled"
      >
        <Icon path={ICON_BASKET} className={styles.icon} />
        <div className={styles.cartIndicator}>
          <CartIndicatorContainer>
            <CartIndicator />
          </CartIndicatorContainer>
        </div>
        <small>{language('navigation.cart')}</small>
      </NextFeatureLink>
    </div>
  );
};

MobileNavigation.displayName = 'molecules/MobileNavigation';

export default MobileNavigation;
