// enum
export const LoginValidationTypes = {
  BadCredentials: 'Bad credentials',
  DoubleOptInAuthenticationError: 'double-opt-in-authentication-error',
  NetworkError: 'NetworkError',
  UserIsDisabled: 'User is disabled',
  SupercardMustBeUsed: 'Supercard ID login must be used for this account',
  Valid: true,
};

export const failureTypes = [
  LoginValidationTypes.BadCredentials,
  LoginValidationTypes.DoubleOptInAuthenticationError,
  LoginValidationTypes.NetworkError,
  LoginValidationTypes.UserIsDisabled,
  LoginValidationTypes.SupercardMustBeUsed,
];
