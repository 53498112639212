import React from 'react';
import PropTypes from 'prop-types';
import ProductAccessories from 'containers/ProductAccessories/ProductAccessories';
import ProductCart from 'components/molecules/ProductCart/ProductCart';

const Cart = ({ cart, products = {} }) => {
  const cartEntryCodes = cart.entries.map((e) => e.productCode);
  return cart.entries.map((entry, key) => {
    const product = products[entry.productCode] || {};

    if (entry.deleted) {
      return null;
    }

    return (
      <ProductAccessories
        apiStatus={cart.apiStatus}
        availabilityStatus={product.availabilityStatus}
        bulkyGoods={entry.bulkyGoods}
        cartEntryCodes={cartEntryCodes}
        categoryCode={product.categoryCode}
        code={entry.productCode}
        discountUpdated={entry.discountUpdated}
        displaySpeedFlag={product.speedProduct}
        entryID={key}
        hasReferences={product.hasReferences}
        imageSizes={product.customImageData}
        key={key}
        name={product.name}
        neutralDeliveryPossible={product.neutralDeliveryPossible}
        pegiValue={product.pegiValue}
        priceUpdated={entry.priceUpdated}
        productOrderable={product.productOrderable}
        productServices={product.productServices}
        releaseDate={product.releaseDate}
        stockUpdated={entry.stockUpdated}
      >
        <ProductCart
          availability={product.availability}
          deliveryTime={product.deliveryTime}
          isCombinationProduct={entry.combinationProduct}
          quantity={entry.quantity}
          cartStatusCode={entry.cartStatusCode}
        />
      </ProductAccessories>
    );
  });
};

Cart.displayName = 'organisms/Cart';
Cart.propTypes = {
  cart: PropTypes.object.isRequired,
  products: PropTypes.object,
};

export default Cart;
