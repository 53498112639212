import {
  INITIALIZE_MAIN_MENU,
  SET_MAIN_MENU_ISOPEN,
  SET_SEARCH_FOCUS,
  OPEN_CART_MODAL,
  CLOSE_CART_MODAL,
  SET_SEARCH_STRING,
  SET_MAIN_MENU_CLOSE,
  SET_FACET_COLLAPSE_FLAG,
  RESET_ALL_FACET_COLLAPSE_FLAGS,
  SET_LOGIN_NOTIFICATION,
  RESET_LOGIN_NOTIFICATION,
  DISMISS_TOPBAR,
  LOAD_IS_TOPBAR_DIMISSED,
  SAVE_CURRENT_BREAKPOINT,
  SET_FORCE_VALIDATION_ERRORS_VISIBLE,
  SHOW_POPOVER_LOGIN,
  SHOW_POPOVER_CART,
  TOGGLE_PRODUCT_VIEW,
  TOGGLE_NAVIGATION,
  LOAD_FEATURE_CONFIG,
  ADD_FLASHMESSAGE,
  REMOVE_FLASHMESSAGE,
  LOAD_FLASHMESSAGES,
  SET_VISIBLE_PRODUCTS,
} from 'constants/ActionTypes/ActionTypes';
import getStorage from 'constants/storage/storage';

const storage = getStorage(true);
const sessionStorage = getStorage(false);

import { getBreakpoint } from 'constants/breakpoints/breakpoints';
import { DATE_TOPBAR_DISMISSED, MAIN_MENU_CLOSED } from 'constants/storage/storageKeys';
import FeatureTogglingAPI from 'api/FeatureTogglingAPI/FeatureTogglingAPI';
import FlashMessageApi from 'api/FlashMessageApi/FlashMessageApi';

export const initializeMainMenu = () => {
  // immediately set menu to closed, so it is closed in next action
  sessionStorage.setItem(MAIN_MENU_CLOSED, true);

  return {
    type: INITIALIZE_MAIN_MENU,
    payload: { isOpen: false },
  };
};
export const setMainMenuIsOpen = () => ({ type: SET_MAIN_MENU_ISOPEN });
export const setMainMenuClose = () => ({ type: SET_MAIN_MENU_CLOSE });
export const setSearchFocus = () => ({ type: SET_SEARCH_FOCUS });
export const openCartModal = () => ({ type: OPEN_CART_MODAL });
export const closeCartModal = () => ({ type: CLOSE_CART_MODAL });
export const setSearchString = (searchString) => ({ type: SET_SEARCH_STRING, payload: searchString });
export const setFacetCollapseFlag = (facetCode, collapsed) => ({
  type: SET_FACET_COLLAPSE_FLAG,
  payload: {
    facetCode,
    collapsed,
  },
});
export const resetAllFacetCollapseFlags = () => ({ type: RESET_ALL_FACET_COLLAPSE_FLAGS });
export const setLoginNotification = (status, message) => ({
  type: SET_LOGIN_NOTIFICATION,
  payload: { status, message },
});
export const resetLoginNotification = () => ({
  type: RESET_LOGIN_NOTIFICATION,
});

export const dismissTopbar = () => {
  const dateTopbarDismissed = new Date().getTime();
  storage.setItem(DATE_TOPBAR_DISMISSED, dateTopbarDismissed);
  return {
    type: DISMISS_TOPBAR,
    payload: dateTopbarDismissed,
  };
};

export const loadIsTopbarDismissed = () => {
  const dateTopbarDismissed = storage.getItem(DATE_TOPBAR_DISMISSED);
  if (dateTopbarDismissed) {
    return {
      type: LOAD_IS_TOPBAR_DIMISSED,
      payload: dateTopbarDismissed,
    };
  }
  return { type: LOAD_IS_TOPBAR_DIMISSED };
};

export const saveBreakpoint = () => ({ type: SAVE_CURRENT_BREAKPOINT, payload: getBreakpoint() });

export const setForceValidationErrorsVisible = (value) => ({
  type: SET_FORCE_VALIDATION_ERRORS_VISIBLE,
  value,
});

export const showPopOverLogin = (isPopOverLoginVisible) => ({
  type: SHOW_POPOVER_LOGIN,
  isPopOverLoginVisible,
});

export const showPopOverCart = (isPopOverCartVisible) => ({
  type: SHOW_POPOVER_CART,
  isPopOverCartVisible,
});

export const toggleProductView = (viewType) => ({
  type: TOGGLE_PRODUCT_VIEW,
  payload: {
    viewType,
  },
});

export const toggleNavigation = (shouldShow) => ({
  type: TOGGLE_NAVIGATION,
  payload: {
    shouldShow,
  },
});

export const loadFeatureConfig = () => ({
  type: LOAD_FEATURE_CONFIG,
  promise: FeatureTogglingAPI.loadFeatureConfig(),
});

export const addFlashmessage = (message) => ({
  type: ADD_FLASHMESSAGE,
  payload: { message },
});

export const removeFlashmessage = (id) => ({
  type: REMOVE_FLASHMESSAGE,
  payload: { id },
});

export const loadFlashMessages = () => ({
  type: LOAD_FLASHMESSAGES,
  promise: FlashMessageApi.loadGlobalFlashMessages(),
});

export const setVisibleProducts = (products) => ({ type: SET_VISIBLE_PRODUCTS, payload: products });

const actions = {
  initializeMainMenu,
  setMainMenuIsOpen,
  setMainMenuClose,
  setSearchFocus,
  openCartModal,
  closeCartModal,
  setSearchString,
  setFacetCollapseFlag,
  resetAllFacetCollapseFlags,
  setLoginNotification,
  resetLoginNotification,
  dismissTopbar,
  loadIsTopbarDismissed,
  saveBreakpoint,
  setForceValidationErrorsVisible,
  showPopOverLogin,
  showPopOverCart,
  toggleProductView,
  toggleNavigation,
  loadFeatureConfig,
  addFlashmessage,
  removeFlashmessage,
  loadFlashMessages,
  setVisibleProducts,
};

export default actions;
