import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'constants/noop/noop';
import { Row, Col } from 'components/atoms/Grid/Grid';
import ContactField from 'containers/ContactField/ContactField';
import FieldSetInput from 'components/molecules/FieldSetInput/FieldSetInput';
import InputText from 'components/atoms/InputText/InputText';
import InputTextarea from 'components/atoms/InputTextarea/InputTextarea';
import NameFormPartial from './Contact.form.name.part';
import ContactDetailsPartial from './Contact.form.contactDetail.part';
import { messageMaxLength } from 'constants/contact/contact.helper';

const FormReceipt = ({ telNumberValidateChar = noop }) => (
  <>
    <NameFormPartial />
    <ContactDetailsPartial telNumberValidateChar={telNumberValidateChar} />
    <Row>
      <Col sm={12} md={8}>
        <ContactField fieldName="orderNumber">
          <FieldSetInput>
            <InputText type="tel" pattern="\d*" maxLength={10} />
          </FieldSetInput>
        </ContactField>
      </Col>
    </Row>
    <Row>
      <Col sm={12} md={8}>
        <ContactField fieldName="contactTextarea">
          <FieldSetInput>
            <InputTextarea maxLength={messageMaxLength} />
          </FieldSetInput>
        </ContactField>
      </Col>
    </Row>
  </>
);
FormReceipt.propTypes = {
  telNumberValidateChar: PropTypes.func,
  shouldShowPhoneDescription: PropTypes.bool,
};

export default FormReceipt;
