import React, { useState } from 'react';
import styles from './FilterSliderNew.scss';
import language from 'constants/language/language';
import InputNumber from 'components/atoms/InputNumber/InputNumber';
import Range from 'rc-slider/lib/Range';
import PropTypes from 'prop-types';
import { FacetPropType } from 'constants/facetsHelper/facetsHelper';

const FilterSliderNew = ({ facet, handleChange }) => {
  const minValue = facet?.minMaxRange?.min || 0;
  const maxValue = facet?.minMaxRange?.max || 0;
  const [minSearched, setMinSearched] = useState(facet?.minMaxRange?.minSearched || minValue);
  const [maxSearched, setMaxSearched] = useState(facet?.minMaxRange?.maxSearched || maxValue);

  /*
      check if value is lower than minValue. if yes then set minSearched to minValue.
      If this check will be done in handleMinChange, the user won't be able to delete without automatic update to minValue
     */
  const blurMinInput = (minActiveSearched) => {
    switch (true) {
      case minActiveSearched < minValue:
        setMinSearched(minValue);
        handleAfterChange([minValue, maxSearched]);
        break;
      case minActiveSearched >= maxValue:
        setMinSearched(maxValue - 1);
        handleAfterChange([maxValue - 1, maxSearched]);
        break;
      case minActiveSearched >= maxSearched:
        setMinSearched(maxSearched - 1);
        handleAfterChange([maxSearched - 1, maxSearched]);
        break;
      default:
        handleAfterChange([minActiveSearched, maxSearched]);
        break;
    }
  };

  const handleMinChange = (minSearched) => {
    const minActiveValue = !minSearched || minSearched < minValue ? parseInt(minValue, 10) : parseInt(minSearched, 10);
    setMinSearched(minActiveValue);
    blurMinInput(minActiveValue);
  };

  /*
    check if value is greather than maxValue. if yes then set maxSearched to maxValue.
    If this check will be done in handleMaxChange, the user won't be able to delete without automatic update to maxValue
   */
  const blurMaxInput = (maxActiveSearched) => {
    switch (true) {
      case maxActiveSearched > maxValue:
        setMaxSearched(maxValue);
        handleAfterChange([minSearched, maxValue]);
        break;
      case maxActiveSearched <= minValue:
        setMaxSearched(minValue + 1);
        handleAfterChange([minSearched, minValue + 1]);
        break;
      case maxActiveSearched <= minSearched:
        setMaxSearched(minSearched + 1);
        handleAfterChange([minSearched, minSearched + 1]);
        break;
      default:
        handleAfterChange([minSearched, maxActiveSearched]);
        break;
    }
  };

  const handleSliderChange = ([minSearched, maxSearched]) => {
    if (minSearched >= maxValue) {
      setMinSearched(maxValue - 1);
      setMaxSearched(maxSearched);
    } else if (maxSearched <= minValue && maxSearched === minSearched) {
      setMinSearched(minSearched);
      setMaxSearched(minValue + 1);
    } else {
      setMinSearched(minSearched);
      setMaxSearched(maxSearched);
    }
  };

  const handleMaxChange = (max) => {
    const maxActiveValue = max ? parseInt(max, 10) : parseInt(maxValue, 10);
    setMaxSearched(maxActiveValue);
    blurMaxInput(maxActiveValue);
  };

  const handleAfterChange = ([min, max]) => {
    handleChange({ selectedMin: min, selectedMax: max, minValue, maxValue, facetCode: facet.code });
  };

  return (
    <div className={styles.wrapper}>
      {facet.code === 'price' && (
        <p className={styles.text}>
          {language('discount.savingsCurrency')} {language('productsOverview.numberResults.of')} -{' '}
          {language('productsOverview.numberResults.to')}
        </p>
      )}
      <div className={styles.fieldWrapper}>
        <InputNumber
          value={(minSearched >= 0 ? minSearched : '').toLocaleString('de-CH').toString()}
          updateValue={(minSearched >= 0 ? minSearched : '').toLocaleString('de-CH').toString()}
          changeOnBlur={handleMinChange}
          format={InputNumber.Format.Currency}
          maxLength={String(String(maxValue).length + 1)}
          avoidInlineStates
        />
        <InputNumber
          value={(maxSearched || '').toLocaleString('de-CH').toString()}
          updateValue={(maxSearched || '').toLocaleString('de-CH').toString()}
          changeOnBlur={handleMaxChange}
          format={InputNumber.Format.Currency}
          maxLength={String(String(maxValue).length + 1)}
          avoidInlineStates
        />
      </div>
      <Range
        min={minValue}
        max={maxValue}
        value={[minSearched, maxSearched]}
        className={styles.wrapper}
        onChange={handleSliderChange}
        onAfterChange={handleAfterChange}
      />
    </div>
  );
};

FilterSliderNew.propTypes = {
  facet: FacetPropType.isRequired,
  handleChange: PropTypes.func.isRequired,
};
export default FilterSliderNew;
