import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { AVAILABLE_RETURN_REASONS, AVAILABLE_RETURN_PAYMENT_TYPES } from 'constants/returns/returns';
import language from 'constants/language/language';
import ModalApiError from 'components/organisms/ModalApiError/ModalApiError';
import { StepperButtons } from 'components/molecules/Stepper/Stepper';
import SpinnerOverlay from 'components/molecules/SpinnerOverlay/SpinnerOverlay';
import FormattedPrice from 'components/atoms/FormattedPrice/FormattedPrice';
import Headline from 'components/atoms/Headline/Headline';
import ReturnsAPI from 'api/ReturnsAPI/ReturnsAPI';

import styles from './ReturnOverview.scss';

const renderRefundInfo = (type, { paymentCode, iban }) => {
  if (type === AVAILABLE_RETURN_PAYMENT_TYPES.NONE) {
    return language(`formPayment.${String(paymentCode).toLowerCase()}`);
  }

  if (type === AVAILABLE_RETURN_PAYMENT_TYPES.VOUCHER) {
    return language('returns.returnPayment.types.VOUCHER');
  }

  return `${language('returns.returnPayment.types.IBAN')} ${String(iban)}`;
};

const ReturnOverview = ({ onNextClick, onPrevClick, activeStep, state }) => {
  const order = state.order;
  const selectedProducts = state.selectedProducts;
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  const handleNextClick = () => {
    setLoading(true);
    ReturnsAPI.createReturn(state)
      .then(({ returnLabelId }) => {
        setLoading(false);
        onNextClick({
          ...state,
          returnLabelId,
        });
      })
      .catch(() => {
        setShowError(true);
        setLoading(false);
      });
  };

  return (
    <>
      {loading && <SpinnerOverlay />}
      {showError && <ModalApiError isModalOpen requestCloseModal={() => setShowError(false)} />}
      <div className={styles.overview}>
        <div className={styles.overviewItem}>
          <Headline tag={Headline.tags.H4} margin={Headline.margins.NONE}>
            {language('returns.productSelect.orderNumber')} {order.code}
          </Headline>
        </div>

        <div className={styles.overviewItem}>
          <div className={styles.overviewItemTitle}>{language('orderBox.article')}</div>
          {selectedProducts.map((product) => (
            <div key={product.id} className={styles.product}>
              <div>{product.name}</div>
              <div>
                {language('product.itemNumber')} {product.code}
              </div>
              <div className={styles.row}>
                <span className={styles.amount}>{language('checkout.orderSummary.quantity')}: 1</span>
                <FormattedPrice value={product.price} />
              </div>
            </div>
          ))}
        </div>

        <div className={styles.overviewItem}>
          <div className={styles.overviewItemTitle}>{language('returns.returnOverview.contactData')}</div>
          <div>{order.user.title}</div>
          <div>
            {order.user.firstName} {order.user.lastName}
          </div>
          <div>{order.user.address.street}</div>
          <div>
            {order.user.address.postalCode} {order.user.address.city}
          </div>
          <br />
          <div>{order.user.phoneNumber}</div>
          <div>{order.user.email}</div>
        </div>
        <div className={styles.overviewItem}>
          <div className={styles.overviewItemTitle}>{language('returns.returnOverview.returnPaymentMethod')}</div>
          {renderRefundInfo(state.refund.type, {
            paymentCode: order.payment.paymentCode,
            iban: state.refund.iban,
          })}
        </div>
        {[AVAILABLE_RETURN_REASONS.WRONG_ARTICLE, AVAILABLE_RETURN_REASONS.TECHNICAL_DAMAGE].includes(
          state.returnType
        ) && (
          <div className={styles.overviewItem}>
            <div className={styles.overviewItemTitle}>{language('orderBox.deliveryCost')}</div>
            <div>{language('returns.returnOverview.none')}</div>
          </div>
        )}
      </div>
      <StepperButtons
        onPrevClick={onPrevClick}
        onNextClick={handleNextClick}
        activeStep={activeStep}
        nextDisabled={loading}
      />
    </>
  );
};

ReturnOverview.displayName = 'molecules/ReturnSteps/ReturnOverview';

ReturnOverview.propTypes = {
  onNextClick: PropTypes.func,
  onPrevClick: PropTypes.func,
  activeStep: PropTypes.number,
  state: PropTypes.object,
};

export default ReturnOverview;
