// @flow
import has from 'constants/helper/has';
import getCookieStorage from 'constants/storage/cookie';

declare var __DEV__: boolean;

const validLanguages = {
  de: 'Deutsch',
  fr: 'Français',
  it: 'Italiano',
};

const validLocales = {
  de: 'de',
  fr: 'fr',
  it: 'it',
};
const isValidLocale = (locale: string) => locale in validLocales;

const loadedLanguages = {};

let currentLocale: string; // undefined, so that not setting it in entry.*.js produces an early error

const getLocale = () => currentLocale;

/**
 Only use setLocale in tests, and setLocaleFromUrl in entry.server, entry.browser!
 (Since when changing the locale you must also delete all localized data in the redux store.)
 Also make sure the locale is a key in loadedLanguages by importing the strings
 either via
 setStaticallyImportedLanguage (for server side rendering and unit tests)
 or
 via dynamicallyImportLanguage (for client side rendering)
 */
const setLocale = (locale: string) => {
  if (!isValidLocale(locale)) {
    throw new RangeError(`Invalid locale '${locale}' given to setLocale().`);
  }
  currentLocale = locale;
};

/*
  Only use in entry.server, entry.browser! See above.
*/
const setLocaleFromUrl = (url: string) => {
  let locale: string = '';

  if (typeof url === 'string') locale = url.slice(1, 3);
  if (!isValidLocale(locale)) {
    if (__DEV__ && !__TEST__) {
      // eslint-disable-next-line
      console.warn(`Invalid locale '${locale}' detected in URL, using '${validLocales.de}' instead!`);
    }
    locale = validLocales.de;
  }
  currentLocale = locale;
  return locale;
};

const getLocaleFromUrl = (url: string) => {
  let locale: string = '';

  if (typeof url === 'string') locale = url.slice(1, 3);
  if (!isValidLocale(locale)) {
    if (__DEV__ && !__TEST__) {
      // eslint-disable-next-line
      console.warn(`Invalid locale '${locale}' detected in URL, using '${validLocales.de}' instead!`);
    }
    locale = validLocales.de;
  }
  return locale;
};

/**
 * Removes the language part (e.g. "/de/") in any internal url.
 * Doesn't affect external urls.
 */
const removeLocaleFromUrl = (url: string) => {
  if (typeof url === 'string') {
    const RegExpExternal = new RegExp(/[h][t]{2}[p][s]?[:][\\/]{2}.+/);
    const RegExpInternal = new RegExp(/[\\/][a-zA-Z]{2}[\\/].*/);

    // don't touch external links
    if (RegExpExternal.test(url)) {
      return url;
    }

    // remove language part from internal links
    if (RegExpInternal.test(url)) {
      const locale = url.substr(1, 2);
      if (isValidLocale(locale)) {
        return url.substr(3);
      }
    }
  }

  return url;
};

const setLocaleCookie = (locale: string) => {
  if (!isValidLocale(locale)) {
    throw new RangeError(`Invalid locale '${locale}' given to setLocaleCookie().`);
  }
  const cookie = getCookieStorage();
  // set expire date to in 10 years
  cookie.setItem('locale', locale, 10 * 365);
};

const setStaticallyImportedLanguage = (locale: string, strings: {}, languages: {} = loadedLanguages) => {
  if (!isValidLocale(locale)) {
    throw new RangeError(`Invalid locale '${locale}' given to setStaticallyImportedLanguage().`);
  }
  if (typeof strings !== 'object') {
    throw new TypeError('Parameter strings in setStaticallyImportedLanguage() needs to be an object.');
  }
  languages[locale] = strings; // eslint-disable-line no-param-reassign
  return languages;
};

const dynamicallyImportLanguage = (locale: string) => {
  let importPromise;

  if (locale === validLocales.fr) {
    importPromise = import(/* webpackChunkName: "fr" */ './french');
  } else if (locale === validLocales.it) {
    importPromise = import(/* webpackChunkName: "it" */ './italian');
  } else {
    importPromise = import(/* webpackChunkName: "de" */ './german');
  }

  return importPromise.then((language) => {
    loadedLanguages[locale] = language.default;
  });
};

const getMissingKeyPlaceholder = (key: string = 'UNDEFINED_I18N_KEY') => {
  const id = key.split('.').pop();
  return `[${id}]`;
};

/*
  This function can only be used after the language strings have been imported and
  saved in loadedLanguages. This happens at run-time. Therefore, if you try to use
  this function at parse-time (e.g. in defaultProps or outside Component classes)
  it will fail.
*/
const getTranslation = (
  key: string,
  defaultValue: string = getMissingKeyPlaceholder(key),
  locale: string = currentLocale,
  languages?: {} = loadedLanguages
): string => {
  // check if the language was loaded
  if (!has(languages, locale)) {
    let msg = `Language '${locale}' not loaded.`;
    if (!locale) msg = 'locale not set.';
    msg += ` Hint: Translation function cannot be used at parse-time: ${key}`;
    // warn during run-time
    if (__DEV__ && !__TEST__) console.error(msg); // eslint-disable-line no-console
    return defaultValue;
  }

  // get the localized string from imported languages
  const getValueFromStringifiedPath = (object, stringPath) =>
    stringPath?.split('.')?.reduce((accumulator, currentValue) => accumulator?.[currentValue], object);
  const translation: string = getValueFromStringifiedPath(languages?.[locale], key) ?? defaultValue;

  // log missing keys
  if (__DEV__ && translation === getMissingKeyPlaceholder(key)) {
    console.warn(`KEYNOTFOUND ${locale}:${key}`); // eslint-disable-line no-console
  }

  return translation;
};

const getTranslationWithCurriedPrefix = (prefix: string) => (key: string) => getTranslation(`${prefix}.${key}`);

/**
 * Extends the default language function with optional string replacements.
 * It replaces all %s occurrences by order with given replacements.
 *
 * const languages = {
 *   key1: 'Last edited on %s.'
 *   key2: 'Replace %s and %s.'
 * };
 *
 *  - i18n('key1', ['01.01.2018']) -> "Last edited on 01.01.2018."
 *  - i18n('key2', ['foo', 'bar']) -> "Replace foo and bar."
 */
const i18n = (key: string, replacements: Array<string> = [], locale?: string, languages?: {}) => {
  const s = getTranslation(key, getMissingKeyPlaceholder(key), locale, languages);
  let i = 0;
  return s.replace(/%s/g, () => {
    const res = replacements[i] || '';
    i++;
    return res;
  });
};

export {
  validLanguages,
  validLocales,
  isValidLocale,
  setLocale,
  setLocaleFromUrl,
  getLocaleFromUrl,
  removeLocaleFromUrl,
  getLocale,
  setLocaleCookie,
  dynamicallyImportLanguage,
  setStaticallyImportedLanguage,
  getMissingKeyPlaceholder,
  getTranslation,
  getTranslationWithCurriedPrefix,
  i18n,
};

export default getTranslation;
