import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Card from 'components/molecules/Card/Card';
import { QualtricsCreativeContainer } from '@interdiscount/component-library-interdiscount';
import bootstrap from 'scss/component.scss';
import styles from './CardWallAll.scss';

const SKINS = Card.SKINS;

const CardWallAll = ({ cards, skin }) => {
  const cardGroups = cards.reduce((accumulator, currentValue, index) => {
    if (index % 6 === 0) {
      accumulator.push([]);
    }
    accumulator[accumulator.length - 1].push(currentValue);
    return accumulator;
  }, []);

  const insertAdditionalItems = (groups) => {
    const clonedCardGroups = [...groups];

    // Insert Container at 2nd Position. This will result in 3rd row on Desktop and 4th row on Tablet.
    // This will not cost that much then checking the current Breakpoint wihtin rendering
    clonedCardGroups.splice(1, 0, ['qInterceptCardWallAll']);

    return clonedCardGroups;
  };

  return (
    <div className={cx(bootstrap.row, styles.wallWrapper)}>
      {insertAdditionalItems(cardGroups).map((cardGroup) =>
        cardGroup.map((card, cardIndex) =>
          card === 'qInterceptCardWallAll' ? (
            <div key={cardIndex} className={cx(bootstrap.colSm12, bootstrap.colLg12)}>
              <QualtricsCreativeContainer qId={card} />
            </div>
          ) : (
            <div key={cardIndex} className={cx(bootstrap.colSm12, bootstrap.colLg4)}>
              <Card card={card} skin={skin} trackingAttributes={{ index: cardIndex }} />
            </div>
          )
        )
      )}
    </div>
  );
};

CardWallAll.SKINS = SKINS;

CardWallAll.displayName = 'molecules/CardWallAll';
CardWallAll.propTypes = {
  cards: PropTypes.array,
  skin: PropTypes.oneOf(Object.values(SKINS)),
};
CardWallAll.defaultProps = {
  cards: [],
  skin: SKINS.ALL,
};

export default CardWallAll;
