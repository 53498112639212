import React from 'react';
import PropTypes from 'prop-types';

import language from 'constants/language/language';

import DiscountSummary from 'components/atoms/DiscountSummary/DiscountSummary';
import Reduction from 'components/atoms/Reduction/Reduction';

import styles from './ReductionInfo.scss';

const ReductionInfo = ({ price }) => (
  <div className={styles.reductionWrapper}>
    <div className={styles.reduction}>
      <Reduction savings={price.savings} />
    </div>
    <div className={styles.discountSummary}>
      <strong>{language('discount.savingsheadline')}</strong>
      <br />
      <DiscountSummary price={price} />
    </div>
  </div>
);

ReductionInfo.displayName = 'molecules/ReductionInfo';
ReductionInfo.propTypes = {
  price: PropTypes.object.isRequired,
};

export default ReductionInfo;
