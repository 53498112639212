import Config from './config.helper';
import isMobileApp from "constants/mobileApp/mobileApp";

export const CREDENTIALS_REACTJS_CLIENT = {
  client_id: 'reactjs_client',
  client_secret: Config.clientSecret,
  grant_type: 'client_credentials',
};

export const CREDENTIALS_IDAPP_CLIENT = {
  client_id: 'idapp_client',
  client_secret: Config.clientSecret,
  grant_type: 'client_credentials',
};

export const getCredentials = () => {
  return isMobileApp() ? CREDENTIALS_IDAPP_CLIENT : CREDENTIALS_REACTJS_CLIENT;
}

const envMap = {
  web: '',
  api: '',
  googleMaps: '',
  microserviceHost: '',
  googleStorageUrl: '',
  contactResourceUrl: '',
  proxyCDNRoutes: '',
  cronIntvMinutes: '',
};

const populateEnvMap = (vars) => {
  envMap.web = vars.webUrl ?? '';
  envMap.api = vars.apiUrl ?? '';
  envMap.googleMaps = vars.googleMapsUrl ?? '';
  envMap.microserviceHost = vars.microserviceHost ?? '';
  envMap.googleStorageUrl = vars.googleStorageUrl ?? '';
  envMap.contactResourceUrl = vars.contactResourceUrl ?? '';
  envMap.proxyCDNRoutes = vars.proxyCDNRoutes;
  envMap.cronIntvMinutes = vars.cronIntvMinutes;
};

export const initializeEnvVars = (vars) => {
  if (vars) {
    populateEnvMap(vars);
    return;
  }

  if (__CLIENT__) {
    if (window?.__ENV_VARIABLES__) {
      populateEnvMap(window.__ENV_VARIABLES__);
      return;
    }
    throw new Error('There is no URL configuration.');
  }
};

export const getCompleteEnv = () => envMap;

export const getEnv = (key) => {
  if (Object.keys(envMap).includes(key)) {
    return envMap[key];
  }
  throw new RangeError('Supplied key not in url map');
};
