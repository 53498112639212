// @flow
import * as React from 'react';
import { connect } from 'react-redux';

import { ANONYMOUS_USER_ID } from 'api/UserAPI/UserAPI';
import { postProductReport } from 'api/ProductAPI/ProductAPI';
import { clientProductReportTopics, productReportTypes } from 'constants/productReportTypes/productReportTypes';
import { scrollToElement, TOP_FEEDBACK_FORM } from 'constants/scrollToElement/scrollToElement';

const mapStateToProps = (state, ownProps) => ({
  email: state?.user?.loginValidation ?? false ? state?.user?.fields?.email?.value || '' : '',
  loggedIn: (state?.user?.loginValidation ?? false) && state?.user?.uid !== ANONYMOUS_USER_ID,
  productImageUrl: ownProps?.product?.customImageData?.[0]?.sizes?.[0]?.url,
  pages: state?.cms?.navigation?.pages ?? null,
});

type Props = {
  children: React.Element<any>,
  /** Th product of the product page the feedback form is integrated in */
  product: Object,
  /** The email of an already logged-in user */
  email: string,
  /** If the current user is logged in */
  loggedIn: boolean,
  /** The path of the first product image */
  productImageUrl: string,
  pages: Object,
};

type State = {
  /** If the form is open */
  formOpen: boolean,
  /** If the response form is open */
  responseOpen: boolean,
  /** If the product report request was requestSuccessfulful */
  requestSuccessful: ?boolean,
};

const initialState = {
  formOpen: false,
  responseOpen: false,
  requestSuccessful: null,
};

class FeedbackFormContainer extends React.Component<Props, State> {
  state: State = initialState;

  componentDidUpdate(prevProps) {
    // Close forms and reset state when changing from product page to product page
    if (this.props.product.code !== prevProps.product.code) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(initialState);
    }
  }

  onOpenForm = (): void => {
    scrollToElement(TOP_FEEDBACK_FORM);
    this.setState({ formOpen: true, responseOpen: false });
  };

  onCancel = (): void => {
    scrollToElement(TOP_FEEDBACK_FORM);
    this.setState({ formOpen: false, responseOpen: false });
  };

  onSubmitFeedback = async ({ topic, message, email }: { topic: string, message: string, email: string }) => {
    this.setState({ responseOpen: true });
    try {
      const payload: Object = {
        productCode: this.props.product.code,
        productReportType: productReportTypes[topic],
        topic,
        message,
        email,
      };

      if (topic === clientProductReportTopics.image) {
        payload.additionalInfo = this.props.productImageUrl;
      }

      if (topic === clientProductReportTopics.video) {
        // We do not yet have a video url to append to the feedback report
        payload.additionalInfo = '-';
      }

      const response = await postProductReport(payload);
      this.setState({ formOpen: false, requestSuccessful: response.status === 201 });
      scrollToElement(TOP_FEEDBACK_FORM);
    } catch (e) {
      this.setState({ formOpen: false, requestSuccessful: false });
      scrollToElement(TOP_FEEDBACK_FORM);
      console.error(e); // eslint-disable-line
    }
  };

  render() {
    const { formOpen, responseOpen, requestSuccessful } = this.state;
    const { children, email, loggedIn, pages } = this.props;

    return React.cloneElement(children, {
      formOpen,
      responseOpen,
      email,
      loggedIn,
      requestSuccessful,
      onOpenForm: this.onOpenForm,
      onCancel: this.onCancel,
      onSubmitFeedback: this.onSubmitFeedback,
      pages,
    });
  }
}

export default connect(mapStateToProps)(FeedbackFormContainer);
