import React from 'react';
import PropTypes from 'prop-types';
import OpeningHoursWeekDay from './OpeningHoursWeekDay';
import language from 'constants/language/language';
import styles from './OpeningHoursRegular.scss';

const OpeningHoursRegular = ({ weekDayOpeningList }) => (
  <table className={styles.table}>
    <caption className={styles.caption}>{language('pickupStore.openingHours')}</caption>
    <tbody>
      {weekDayOpeningList.map(
        (day, index) =>
          !!day.weekDay &&
          typeof day.openingDayTimeRangeDataList !== 'undefined' && (
            <OpeningHoursWeekDay
              key={`opening-hours-regular-${index}`}
              weekDay={day.weekDay}
              closed={day.closed}
              openingDayTimeRangeDataList={day.openingDayTimeRangeDataList}
            />
          )
      )}
    </tbody>
  </table>
);

OpeningHoursRegular.displayName = 'atoms/OpeningHoursRegular';
OpeningHoursRegular.propTypes = {
  weekDayOpeningList: PropTypes.array.isRequired,
};

export default OpeningHoursRegular;
