const pickBy = (object, func) => {
  if (!object || !func) return {};
  const obj = {};
  for (const key in object) {
    if (func(object[key])) {
      obj[key] = object[key];
    }
  }
  return obj;
};

export default pickBy;
