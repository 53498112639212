import React from 'react';
import PropTypes from 'prop-types';
import styles from './Backdrop.scss';

const Backdrop = ({ onClose }) => {
  return <div className={styles.backdrop} onClick={onClose}></div>;
};

Backdrop.propTypes = {
  onClose: PropTypes.func.isRequired,
};
export default Backdrop;
