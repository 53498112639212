import getCookieStorage, { cookiesEnabled } from 'constants/storage/cookie';
import getTemporaryStorage from 'constants/storage/temporaryStorage';

const temporarySessionStorage = getTemporaryStorage(false, false);
const temporaryLocalStorage = getTemporaryStorage(true, false);
const cookie = getCookieStorage();

const hasLocalStorage = () => {
  const str = 'testLocalStorage';
  try {
    localStorage.setItem(str, str);
    localStorage.removeItem(str);
    return true;
  } catch (e) {
    return false;
  }
};

export const storageEnabled = hasLocalStorage();

const getStorage = (permanent) => {
  const isTemporary = __TEST__ || !__CLIENT__ || (!storageEnabled && !cookiesEnabled);

  let storageProvider;

  if (isTemporary) {
    storageProvider = permanent ? temporaryLocalStorage : temporarySessionStorage;
  } else if (storageEnabled) {
    storageProvider = permanent ? localStorage : sessionStorage;
  } else {
    storageProvider = cookie;
  }

  return {
    setItem(key, value) {
      try {
        storageProvider.setItem(key, value);
        return true;
      } catch (err) {
        return false;
      }
    },
    getItem(key) {
      try {
        return storageProvider.getItem(key);
      } catch (err) {
        return null;
      }
    },
    removeItem(key) {
      try {
        storageProvider.removeItem(key);
        return true;
      } catch (err) {
        return false;
      }
    },
    clear() {
      try {
        storageProvider.clear();
        return true;
      } catch (err) {
        return false;
      }
    },
  };
};

export default getStorage;
