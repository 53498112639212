import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Icon, { ICON_ADD, ICON_REMOVE } from 'components/atoms/Icon/Icon';

import styles from 'components/atoms/DrilldownAccordion/DrilldownAccordion.scss';
import { NAVIGATION_MAIN } from 'constants/trackingAttributes/trackingAttributes';
import { trackMobileNavigationBack, trackMobileNavigationSelect } from 'tracking/tracking';
import { sanitizeAndEncode } from 'constants/sanitizeAndEncode/sanitizeAndEncode';

const DrilldownAccordion = ({ title, children, openByDefault, pos, isResetToStart, resetNavigation }) => {
  const childContainer = useRef(null);
  const [height, setHeight] = useState(childContainer.current?.scrollHeight);
  const [isOpen, toggleIsOpen] = useState(openByDefault);

  const handleClick = () => {
    toggleIsOpen(!isOpen);
    !isOpen
      ? trackMobileNavigationSelect(NAVIGATION_MAIN, pos, title)
      : trackMobileNavigationBack(NAVIGATION_MAIN, pos, title);
    handleAccountSection(pos);
  };

  const handleAccountSection = (position) => {
    if (position === 7) {
      !sessionStorage.getItem('mobileAccountMenu')
        ? sessionStorage.setItem('mobileAccountMenu', 'close')
        : sessionStorage.removeItem('mobileAccountMenu');
    }
  };

  useEffect(() => {
    if (resetNavigation) resetNavigation();
    toggleIsOpen(!!openByDefault);
  }, [isResetToStart]);

  useEffect(() => {
    setHeight(childContainer.current?.scrollHeight);
  }, [childContainer]);
  return (
    <div className={styles.container}>
      <button data-cy={sanitizeAndEncode(title)} onClick={handleClick} className={styles.accordionTitle}>
        {title}
        <Icon path={isOpen ? ICON_REMOVE : ICON_ADD} size={Icon.sizes.sm} className={styles.accordionIndicator} />
      </button>
      <div ref={childContainer} className={cx(styles.accordionContent)} style={{ height: isOpen ? height : 0 }}>
        {children}
      </div>
    </div>
  );
};

export default DrilldownAccordion;

DrilldownAccordion.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  openByDefault: PropTypes.bool,
  cyData: PropTypes.string,
  handleAnimation: PropTypes.func,
  pos: PropTypes.number,
  isResetToStart: PropTypes.bool,
  resetNavigation: PropTypes.func,
};

DrilldownAccordion.defaultProps = {
  title: '',
  children: [],
  openByDefault: false,
  cyData: '',
};
