import React from 'react';
import PropTypes from 'prop-types';

import AddToCartButton from 'components/molecules/AddToCartButton/AddToCartButton';
import ListInteraction from 'components/molecules/ProductInteractions/ListInteraction';
import { ICON_COMPARE_ARROWS, ICON_FAVORITE_BORDER } from 'components/atoms/Icon/Icon';

import language from 'constants/language/language';
import { cypressAttributes } from 'constants/cypress/cypress';

import styles from './ProductInteractions.scss';
import { apiStatus } from 'constants/apiStatus/apiStatus';

const ProductInteractions = ({
  enableLinks,
  buttonText,
  product,
  addProductToWatchlist,
  removeProductFromWatchlist,
  addedToWatchlist,
  addProductToComparison,
  removeProductFromComparison,
  addedToComparison,
  isAddToCartVisible,
  id,
  cyData,
  watchlistApiStatus,
}) => {
  const isWatchlistDisabled = watchlistApiStatus === apiStatus.request;

  return (
    <>
      {isAddToCartVisible && (
        <AddToCartButton
          product={product}
          buttonText={buttonText}
          id={id}
          showIcon
          cyData={cypressAttributes.cart.btnAddToCart}
        />
      )}
      {__CLIENT__ && enableLinks && (
        <div className={styles.ProductInteractionsRow}>
          <ListInteraction
            addProductToList={addProductToComparison}
            removeProductFromList={removeProductFromComparison}
            addedToList={addedToComparison}
            productCode={product.code}
            addedIcon={ICON_COMPARE_ARROWS}
            trackingType="compare"
            cyData={[
              cyData ?? cypressAttributes.comparisonList.addToComparisonListLink,
              addedToComparison
                ? cypressAttributes.comparisonList.arrowsIn
                : cypressAttributes.comparisonList.arrowsOut,
            ]}
          >
            <span>{language('product.compare')}</span>
          </ListInteraction>
          <ListInteraction
            addProductToList={addProductToWatchlist}
            removeProductFromList={removeProductFromWatchlist}
            addedToList={addedToWatchlist}
            productCode={product.code}
            addedIcon={ICON_FAVORITE_BORDER}
            trackingType="bookmark"
            isDisabled={isWatchlistDisabled}
          >
            <span>{language('product.addToWatchlist')}</span>
          </ListInteraction>
        </div>
      )}
    </>
  );
};

ProductInteractions.propTypes = {
  enableLinks: PropTypes.bool,
  buttonText: PropTypes.string.isRequired,
  product: PropTypes.object,
  addProductToWatchlist: PropTypes.func,
  removeProductFromWatchlist: PropTypes.func,
  addedToWatchlist: PropTypes.bool,
  addProductToComparison: PropTypes.func,
  removeProductFromComparison: PropTypes.func,
  addedToComparison: PropTypes.bool,
  displayHotText: PropTypes.bool,
  isPromo: PropTypes.bool,
  hasPromoEnded: PropTypes.bool,
  isAddToCartVisible: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  cyData: PropTypes.string,
  watchlistApiStatus: PropTypes.string,
};

ProductInteractions.displayName = 'molecules/ProductInteractions/ProductInteractions';

export default ProductInteractions;
