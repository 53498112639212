import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { useFeatureToggle } from 'hooks/index';
import language from 'constants/language/language';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import MorphingLogoInterdiscount from 'components/atoms/logos/MorphingLogoInterdiscount';
import MorphingLogoInterdiscountMedium from 'components/atoms/logos/MorphingLogoInterdiscountMedium';
import MorphingLogoInterdiscountSmall from 'components/atoms/logos/MorphingLogoInterdiscountSmall';
import styles from './HeadLogo.scss';
import bootstrap from 'scss/component.scss';

export const TYPE_CHECKOUT = 'TYPE_CHECKOUT';
export const TYPE_ERRORPAGE = 'TYPE_ERRORPAGE';
export const TYPE_SMALL = 'TYPE_SMALL';

const LANG_KEY = 'header.interdiscount';

const HeadLogo = ({ type, isSticky }) => {
  const className = cx(styles.LogoContainer, {
    [styles.Checkout]: type === TYPE_CHECKOUT,
    [styles.ErrorPage]: type === TYPE_ERRORPAGE,
    [styles.Small]: type === TYPE_SMALL,
  });
  const logoClasses = cx({
    [styles.logoSmall]: type === TYPE_SMALL,
    [styles.logoSmallCheckout]: type === TYPE_CHECKOUT,
    [styles.logoSmallErrorPage]: type === TYPE_ERRORPAGE,
    [bootstrap.hiddenMdUp]: type !== TYPE_SMALL,
  });

  const nextHomepageEnabled = useFeatureToggle('nextHomepageEnabled') === 'true' ?? false;
  const isCheckoutOrErrorPage = type === TYPE_CHECKOUT || type === TYPE_ERRORPAGE;

  const renderLogo = () => (
    <>
      {type === TYPE_SMALL ? (
        <MorphingLogoInterdiscountSmall
          height={36}
          className={logoClasses}
          altText={language('header.interdiscount')}
        />
      ) : (
        <div className={styles.flexContainer}>
          {isSticky ? (
            <MorphingLogoInterdiscountMedium
              className={cx({
                [bootstrap.hiddenSmDown]: isCheckoutOrErrorPage,
              })}
              altText={language(LANG_KEY)}
            />
          ) : (
            <>
              {isCheckoutOrErrorPage ? (
                <MorphingLogoInterdiscount
                  className={cx({
                    [bootstrap.hiddenSmDown]: isCheckoutOrErrorPage,
                  })}
                  altText={language(LANG_KEY)}
                />
              ) : (
                <>
                  <MorphingLogoInterdiscountMedium className={styles.visibleTablet} altText={language(LANG_KEY)} />
                  <MorphingLogoInterdiscount className={styles.visibleDesktop} altText={language(LANG_KEY)} />
                </>
              )}
            </>
          )}
          {isCheckoutOrErrorPage && (
            <div className={logoClasses}>
              <MorphingLogoInterdiscountSmall height={36} altText={language(LANG_KEY)} />
            </div>
          )}
        </div>
      )}
    </>
  );

  return (
    <div className={className}>
      {nextHomepageEnabled ? (
        <a href={mapPathToLocalizedUrl()}>{renderLogo()}</a>
      ) : (
        <Link to={mapPathToLocalizedUrl()}>{renderLogo()}</Link>
      )}
    </div>
  );
};

HeadLogo.propTypes = {
  type: PropTypes.string,
  isSticky: PropTypes.bool,
};

HeadLogo.defaultProps = {
  type: '',
};

HeadLogo.displayName = 'molecules/HeadLogo';

export default HeadLogo;
