import { useEffect, useState } from 'react';
import { TELESALES_STORE_CODE } from 'constants/storage/storageKeys';

const useTelesalesStore = () => {
  const [telesalesStore, setTelesalesStore] = useState('');

  useEffect(() => {
    if (__CLIENT__) {
      setTelesalesStore(localStorage.getItem(TELESALES_STORE_CODE));
    }
  }, []);
  return telesalesStore;
};

export default useTelesalesStore;
