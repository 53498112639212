import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { apiStatus } from 'constants/apiStatus/apiStatus';
import language, { getLocale } from 'constants/language/language';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';

import Headline from 'components/atoms/Headline/Headline';
import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';
import Spinner from 'components/atoms/Spinner/Spinner';
import SpinnerOverlay from 'components/molecules/SpinnerOverlay/SpinnerOverlay';

import bootstrap from 'scss/component.scss';
import styles from './NewsletterOptout.scss';

const NewsletterOptout = ({
  handleUnsubscribe,
  newsletterUID,
  showError,
  isPersonalized = false,
  isLoading = false,
}) => {
  if (isLoading) {
    return <SpinnerOverlay />;
  }

  const translationKey = isPersonalized ? 'newsletterOptoutPersonalized' : 'newsletterOptout';

  return (
    <div className={bootstrap.row}>
      {showError ? (
        <div className={cx(bootstrap.colLg8, bootstrap.offsetLg2, bootstrap.colMd12, styles.wrapper)}>
          <Headline margin={Headline.margins.MB_10} tag={Headline.tags.H1}>
            {language(`${translationKey}.titleFailure`)}
          </Headline>
          <div className={styles.failureFlexContainer}>
            <div className={cx(styles.failureContainer)}>
              <div className={styles.spinnerContainer}>
                <Spinner status={apiStatus.failure} />
              </div>
              <div className={styles.messageContainer}>
                <span>{language(`${translationKey}.failure`)}</span>
              </div>
            </div>
          </div>
          <ButtonLink variety={ButtonLink.varieties.stroked} href={mapPathToLocalizedUrl(getLocale())}>
            {language('newsletterOptout.backToHomepage')}
          </ButtonLink>
        </div>
      ) : (
        <div className={cx(bootstrap.colLg8, bootstrap.offsetLg2, bootstrap.colMd12, styles.wrapper)}>
          <Headline margin={Headline.margins.MB_10} tag={Headline.tags.H1}>
            {language(`${translationKey}.title`)}
          </Headline>
          <p className={styles.subhead}>
            <strong>{language(`${translationKey}.subTitle`)}</strong>
          </p>
          <p>
            {language(`${translationKey}.textBefore`)}
            <b>{newsletterUID}</b>
            {language(`${translationKey}.textAfter`)}
          </p>
          <p> {language(`${translationKey}.text3`)}</p>
          <ButtonLink variety={ButtonLink.varieties.flat} size={ButtonLink.sizes.md} onClick={handleUnsubscribe}>
            <span> {language(`${translationKey}.buttonText`)}</span>
          </ButtonLink>
        </div>
      )}
    </div>
  );
};

NewsletterOptout.propTypes = {
  handleUnsubscribe: PropTypes.func,
  isPersonalized: PropTypes.bool,
  newsletterUID: PropTypes.string,
  showError: PropTypes.bool,
  isLoading: PropTypes.bool,
};

NewsletterOptout.displayName = 'templates/NewsletterOptout';

export default NewsletterOptout;
