import {
  GET_CMS_CONTENT_FOR_PAGE,
  GET_CMS_NAVIGATION_NODES,
  SET_CURRENT_PAGE_ID,
  SET_REQUEST_FOR_CMSCONTENT_STATUS_TO_SUCCESS,
  GET_WALL_CONTENT,
  GET_BRAND,
  NO_BRAND_PAGE,
  GET_USP_ELEMENTS,
} from 'constants/ActionTypes/ActionTypes';
import CmsAPI from 'api/CmsAPI/CmsAPI';
import BrandAPI from 'api/BrandAPI/BrandAPI';
import { HOME_PAGE, MAIN_WALL_ID, WALL_OF_ALL_ID } from 'constants/cms/cms';
import { getLocale } from 'constants/language/language';

export const getPageContent = (pageId) => ({
  type: GET_CMS_CONTENT_FOR_PAGE,
  payload: {
    pageId,
  },
  promise: CmsAPI.getPageContent(pageId),
});

export const getBrandContentNeed = (params, location) => {
  const isBrandPage = location.pathname.indexOf('/brand/') >= 0;
  let brandName = location.pathname.split('brand/')[1];

  // The router seems to sends undefined as string at initial start
  if (brandName && brandName !== 'undefined') {
    brandName = brandName ? encodeURIComponent(decodeURIComponent(brandName).toUpperCase()) : undefined;

    if (isBrandPage) {
      return {
        type: GET_BRAND,
        promise: BrandAPI.getBrand(brandName),
      };
    }
  }

  return {
    type: NO_BRAND_PAGE,
    payload: {},
    promise: Promise.resolve(true),
  };
};

export const getPageContentNeed = (params, location) => {
  const isBasePath = (basePath) => basePath === `/${getLocale()}` || basePath === `/${getLocale()}/`;

  const path = decodeURI(location.pathname);

  const found = isBasePath(path) ? { pageId: HOME_PAGE } : CmsAPI.getLocalizedUrls()[path];
  const target = typeof found !== 'undefined' && found.pageId ? found.pageId : null;

  return {
    type: GET_CMS_CONTENT_FOR_PAGE,
    payload: {
      pageId: target,
    },
    // Only fetch page content if a valid pageId is given.
    promise: target ? CmsAPI.getPageContentNeed(target) : Promise.resolve(true),
  };
};

export const getNavigationNodes = () => ({
  type: GET_CMS_NAVIGATION_NODES,
  promise: CmsAPI.getNavigationNodes(),
});

export const setCurrentPageId = (pageId) => ({
  type: SET_CURRENT_PAGE_ID,
  payload: {
    pageId,
  },
});

export const setRequestForCmsContentStatusToSuccess = () => ({
  type: SET_REQUEST_FOR_CMSCONTENT_STATUS_TO_SUCCESS,
});

export const getWallContentNeed = () => ({
  type: GET_WALL_CONTENT,
  payload: {
    wallId: MAIN_WALL_ID,
  },
  promise: CmsAPI.getWallContent(),
});

export const getWallOfAllContentNeed = () => ({
  type: GET_WALL_CONTENT,
  payload: {
    wallId: WALL_OF_ALL_ID,
  },
  promise: CmsAPI.getWallContent(WALL_OF_ALL_ID),
});

export const getWallContent = (wallId) => ({
  type: GET_WALL_CONTENT,
  payload: {
    wallId,
  },
  promise: CmsAPI.getWallContent(wallId),
});

export const getUspElements = () => ({
  type: GET_USP_ELEMENTS,
  promise: CmsAPI.getUspElements(),
});

export default {
  getPageContent,
  getBrandContentNeed,
  getNavigationNodes,
  setCurrentPageId,
  setRequestForCmsContentStatusToSuccess,
  getPageContentNeed,
  getWallContentNeed,
  getWallOfAllContentNeed,
};
