import React from 'react';
import PropTypes from 'prop-types';

import {
  getCategoryMapByPriority2,
  getCategoryUrl,
  getStringCategoriesName,
} from 'constants/categoryTree/categoryTree';
import { getLocale } from 'constants/language/language';
import LinkList from 'components/molecules/LinkList/LinkList';

const FooterRowProduct = ({ categories }) => (
  <LinkList
    data={getCategoryMapByPriority2(categories, { parent: '1' }).map((category) => ({
      url: getCategoryUrl(category.id, getLocale(), categories),
      text: getStringCategoriesName(category.id, getLocale(), categories),
    }))}
  />
);

FooterRowProduct.displayName = 'molecules/FooterColumnProduct/FooterRowProduct';

FooterRowProduct.propTypes = {
  categories: PropTypes.object,
};

FooterRowProduct.defaultProps = {
  categories: {},
};

export default FooterRowProduct;
