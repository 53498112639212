import React, { createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

export const DynamicProvidersContext = createContext({ providers: [] });

const DynamicProvider = ({ children }) => {
  const [providers, setProviders] = useState([]);

  const add = (provider) => setProviders([...providers, provider]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const value = useMemo(() => ({ providers, add }), [providers.length]);

  return <DynamicProvidersContext.Provider value={value}>{[...providers, children]}</DynamicProvidersContext.Provider>;
};

DynamicProvider.displayName = 'contexts/DynamicProvider';

DynamicProvider.propTypes = { children: PropTypes.node.isRequired };

export default DynamicProvider;
