import * as React from 'react';
import PropTypes from 'prop-types';

import Markdown from 'components/atoms/Markdown/Markdown';
import Icon, { ICON_LOCATION_ON_OUTLINED } from 'components/atoms/Icon/Icon';
import FlashMessage from 'components/atoms/FlashMessage/FlashMessage';
import InternalOrExternalLink from "components/atoms/InternalOrExternalLink/InternalOrExternalLink";
import { STOREFINDER } from 'constants/routePaths/routePaths';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import language, { getLocale } from 'constants/language/language';
import { types as flashMessageTypes, types } from 'constants/flashMessages/flashMessages';
import styles from './FlashMessageList.scss';
import { useCookie } from 'hooks/useCookie';
import useFeatureToggle from "hooks/useFeatureToggle";
import { USP_FLASH_COOKIE } from 'constants/storage/storageKeys';
import { noop } from 'constants/noop/noop';

const renderOpenStores = (flashMessage, nextStorefinderEnabled) => {
  const storeFinderUrl = `${mapPathToLocalizedUrl(getLocale(), [STOREFINDER])}?showOpen=true`;
  return (
    <>
      <h5>{language('flashMessages.openStores.headline')}</h5>
      <span className={styles.contentWrapper}>
        {flashMessage.message}
        {' | '}
      </span>
      <InternalOrExternalLink external={nextStorefinderEnabled} className={styles.link} href={storeFinderUrl}>
        {language('flashMessages.openStores.link')}
        <Icon path={ICON_LOCATION_ON_OUTLINED} className={styles.icon} />
      </InternalOrExternalLink>
    </>
  );
};

const renderFlashMessage = (flashMessage) => (
  <>
    {flashMessage.headline && <h5>{flashMessage.headline}</h5>}
    {flashMessage.message && <Markdown options={{ forceInline: true }}>{flashMessage.message}</Markdown>}
  </>
);

const USP_MESSAGE_CLOSED = 'closed';

const FlashMessageList = ({ message = {}, closeFlash = noop, onLoad = noop }) => {
  const [flashCookie, setFlashCookie] = useCookie(USP_FLASH_COOKIE);
  const nextStorefinderEnabled = useFeatureToggle("nextStorefinderEnabled") === 'true' ?? false;

  const isUspFlashMessage = message.type === types.USP;
  const isFlashMessageClosed = isUspFlashMessage && flashCookie === USP_MESSAGE_CLOSED;

  if (isFlashMessageClosed) {
    return null;
  }

  const onCloseFlash = () => {
    if (isUspFlashMessage) {
      setFlashCookie(USP_MESSAGE_CLOSED, 7);
    }
    closeFlash();
  };

  return (
    <FlashMessage
      id={message.message}
      key={message.message}
      type={message.type === flashMessageTypes.OPENING_DAY ? flashMessageTypes.INFO : message.type}
      overrideIcon={message.icon}
      isHeader
      isCloseable
      closeFlash={onCloseFlash}
      onLoad={onLoad}
    >
      {message.type === flashMessageTypes.OPENING_DAY ? renderOpenStores(message, nextStorefinderEnabled) : renderFlashMessage(message)}
    </FlashMessage>
  );
};
FlashMessageList.propTypes = {
  message: PropTypes.object,
  closeFlash: PropTypes.func,
  onLoad: PropTypes.func,
};

FlashMessageList.displayName = 'molecules/FlashMessageList';

export default FlashMessageList;
