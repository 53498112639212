import { Component } from 'react';
import PropTypes from 'prop-types';

import { postLog } from 'api/promiseMiddleware';
import { loadScript } from 'constants/scriptLoader/scriptLoader';
import { getLocale } from 'constants/language/language';
import formatDate from 'constants/formatDate/formatDate';
import getStorage from 'constants/storage/storage';

import { SURVEY_COMPLETED } from 'constants/storage/storageKeys';

const localStorage = getStorage(true);
const scriptSrc = 'https://apis.google.com/js/platform.js?onload=renderOptIn';

export default class GoogleSurvey extends Component {
  static displayName = 'organisms/GoogleSurvey';
  static propTypes = {
    orderCode: PropTypes.string,
    email: PropTypes.string,
    orderedProducts: PropTypes.array,
  };
  static defaultProps = {
    orderCode: '',
    email: '',
    orderedProducts: [],
  };

  componentDidMount() {
    if (!__CLIENT__) {
      // disable ssr
      return;
    }

    if (localStorage.getItem(SURVEY_COMPLETED) === this.props.orderCode) {
      // show survey only once per product (hide survey on page reloads)
      return;
    }

    this.loadGoogleSurveyScript()
      .then(() => {
        window.gapi.surveyoptin.render({
          merchant_id: window?.__ENV_VARIABLES__?.gSurveyMerchant || '',
          order_id: this.props.orderCode,
          email: this.props.email,
          delivery_country: 'CH',
          estimated_delivery_date: this.getDate(),
          opt_in_style: 'CENTER_DIALOG',
          products: this.props.orderedProducts
            .filter((product) => product != null)
            .map((product) => ({ gtin: product })),
        });

        localStorage.setItem(SURVEY_COMPLETED, this.props.orderCode);
      })
      .catch(postLog);
  }

  loadGoogleSurveyScript = () =>
    new Promise((resolve) => {
      if (window.gapi && window.gapi.surveyoptin) {
        // google survey already loaded/initialized
        resolve();
        return;
      }

      loadScript.js(scriptSrc, 'googleSurvey').then(() => {
        window.___gcfg = {
          lang: getLocale(),
        };
        window.gapi.load('surveyoptin', resolve);
      });
    });

  getDate = () => {
    const week = 60000 * 60 * 24 * 7;
    const date = new Date(Date.now() + week);
    return formatDate(date, { isoDate: true });
  };

  render() {
    return null;
  }
}
