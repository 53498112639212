const store = new Map();

export const TTL_FIVE_MIN = 60 * 5;
export const TTL_ONE_YEAR = 60 * 60 * 24 * 365;

export const Cache = {
  // wrap Date.now to mock values in tests more easily
  _getCurrentTime: () => Date.now(),
  set: (key, value, ttlInSeconds = 0) => {
    const expiresAt = ttlInSeconds > 0 ? Cache._getCurrentTime() + ttlInSeconds * 1000 : undefined;
    store.set(key, [value, expiresAt]);
  },
  get: (key) => {
    if (!store.has(key)) {
      // key not stored in cache
      return undefined;
    }

    const [value, expiresAt = 0] = store.get(key) ?? [];
    const keyHasTTL = expiresAt > 0;

    if (keyHasTTL && Cache._getCurrentTime() > expiresAt) {
      // cache for key expired
      Cache.delete(key);
      return undefined;
    }

    return value;
  },
  delete(key) {
    return store.delete(key);
  },
  clear: () => store.clear(),
};
