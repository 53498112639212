import React from 'react';
import { Route, IndexRoute } from 'react-router';
import {
  CHECKOUT,
  CHECKOUT_GUEST,
  CHECKOUT_LOGIN,
  PAYMENT,
  PAYMENT_ERROR,
  CART,
} from 'constants/routePaths/routePaths';
import { checkout, checkoutLogin, cart } from 'constants/routePaths/routePathCombinations';
import { requireCartItems, requireLogin, requireNoLogin, prepareStateForRoute, routeHelperKeys } from '../routeHelpers';

import DefaultLayout from 'components/layouts/DefaultLayout/DefaultLayout';
import CheckoutLayout from 'components/layouts/CheckoutLayout/CheckoutLayout';
import Login from 'routes/Login/Login';
import CheckoutGateway from 'containers/CheckoutGateway/CheckoutGateway';
import Cart from 'routes/Cart/Cart';

/* eslint-disable no-console */

const errorLoading = (err) => {
  console.error('Dynamic page loading failed', err);
};
const loadRoute = (cb) => (module) => cb(null, module.default);

const getCheckout = (nextState, cb) => {
  import('routes/checkout/Checkout/Checkout').then(loadRoute(cb)).catch(errorLoading);
};

export default function createCheckoutRoutes(store) {
  return (
    <Route>
      <Route component={DefaultLayout}>
        <Route path={CART} component={Cart} />
      </Route>
      <Route components={CheckoutGateway}>
        <Route path={PAYMENT} component={CheckoutLayout} onEnter={requireCartItems(store, cart)}>
          <Route path={PAYMENT_ERROR} paymentError getComponent={getCheckout} />
        </Route>
        <Route path={CHECKOUT} component={CheckoutLayout}>
          <Route
            onEnter={(location, replace, callback) => {
              prepareStateForRoute(store, routeHelperKeys.CHECKOUT)();
              return requireCartItems(store, CART)(location, replace, callback);
            }}
          >
            <IndexRoute getComponent={getCheckout} onEnter={requireLogin(checkoutLogin)} />
            <Route
              path={CHECKOUT_GUEST}
              getComponent={getCheckout}
              onEnter={(location, replace) => {
                prepareStateForRoute(store, routeHelperKeys.GUEST_CHECKOUT)();
                return requireNoLogin(checkout)(location, replace);
              }}
            />
          </Route>
        </Route>
      </Route>
      <Route path={CHECKOUT} component={DefaultLayout}>
        <Route components={CheckoutGateway}>
          <Route path={CHECKOUT_LOGIN} component={Login} onEnter={requireNoLogin(checkout)} />
        </Route>
      </Route>
    </Route>
  );
}
