import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import AccountNotification from 'components/molecules/AccountNotification/AccountNotification';
import { getEnumValues } from 'constants/GetEnumValues/GetEnumValues';
import { noop } from 'constants/noop/noop';
import { loginNotifications } from 'reducers/ui/ui';

const LoginNotificationProvider = ({ loginNotification, resetLoginNotification = noop }) => {
  useEffect(() => {
    resetLoginNotification();
  }, [resetLoginNotification]);

  if (!loginNotification) {
    return null;
  }
  return <AccountNotification notificationKey={loginNotification} />;
};
LoginNotificationProvider.displayName = 'molecules/LoginNotificationProvider';
LoginNotificationProvider.propTypes = {
  loginNotification: PropTypes.oneOf(getEnumValues(loginNotifications)),
  resetLoginNotification: PropTypes.func,
};

export default LoginNotificationProvider;
