import React from 'react';
import PropTypes from 'prop-types';

import Image from 'components/atoms/Image/Image';
import language from 'constants/language/language';
import Headline from 'components/atoms/Headline/Headline';

import styles from './InventoryStorefinderProduct.scss';

const InventoryStorefinderProduct = ({ productImage = {}, productName = '' }) => (
  <div className={styles.wrapper}>
    <div className={styles.image}>
      <Image alt={productImage.altText} images={productImage.sizes} code={productImage.code} />
    </div>
    <div className={styles.productInfo}>
      <Headline tag={Headline.tags.H2} margin={Headline.margins.MB_15}>
        {productName}
      </Headline>
      {language('inventory.storeFinder.infoText')}
    </div>
  </div>
);

InventoryStorefinderProduct.propTypes = {
  productImage: PropTypes.object,
  productName: PropTypes.string,
};

InventoryStorefinderProduct.displayName = 'atom/InventoryStorefinderProduct';

export default InventoryStorefinderProduct;
