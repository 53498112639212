import React from 'react';
import PropTypes from 'prop-types';
import IconLink from 'components/atoms/IconLink/IconLink';
import Icon, { ICON_GET_APP } from 'components/atoms/Icon/Icon';
import styles from './Downloads.scss';

export const documentTypes = {
  PDF: 'PDF',
};

const getIcon = (type) => {
  if (type === documentTypes.PDF) {
    return <Icon path={ICON_GET_APP} />;
  }
  return null;
};

const DownloadLink = ({ href, onClick, title, type }) => (
  <IconLink href={href} onClick={onClick} target={IconLink.linkTargets.BLANK}>
    <div className={styles.itemInner}>
      <i className={styles.icon}>{getIcon(type)}</i>
      <span className={styles.title}>{title}</span>
    </div>
  </IconLink>
);

DownloadLink.propTypes = {
  href: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.keys(documentTypes)),
};

DownloadLink.defaultProps = {
  type: documentTypes.PDF,
  title: '',
};

export default DownloadLink;
