import React from 'react';
import PropTypes from 'prop-types';
import language from 'constants/language/language';
import { noop } from 'constants/noop/noop';
import styles from './DeliveryStateStockInfo.scss';

const DeliveryStateStockInfo = ({ ownStore = {}, openStorefinderModal = noop }) => {
  const handleClick = (storeName) => {
    ownStore?.quantity > 0 ? openStorefinderModal(storeName) : openStorefinderModal();
  };

  return (
    <div className={styles.inventoryInfo}>
      <span onClick={() => handleClick(ownStore?.name)}>
        {language('inventory.deliveryInfo.checkStoreAvailability')}
      </span>
    </div>
  );
};

DeliveryStateStockInfo.displayName = 'molecules/DeliveryStateStockInfo';

DeliveryStateStockInfo.propTypes = {
  ownStore: PropTypes.object,
  openStorefinderModal: PropTypes.func,
};

export default DeliveryStateStockInfo;
