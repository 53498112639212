import { Component, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import has from 'constants/helper/has';

import { updateContactField, blurContactField } from 'actions/contactActions/contactActions';
import { sanitizeValue } from 'constants/sanitize/sanitize';
import { fieldNames as contactFieldNames } from 'components/organisms/Contact/Contact';

export const mapStateToProps = (state, ownProps) => {
  if (!has(state, 'contact.fields')) return {};
  return {
    ...state.contact.fields[ownProps.fieldName],
    forceValidation: state.contact.fields.valid === false,
  };
};

const mapDispatchToProps = {
  updateContactField,
  blurContactField,
};

const sanitizeNeeded = (field) => {
  const noSanitize = [contactFieldNames.contactSubjects, contactFieldNames.contactEmail, contactFieldNames.salutation];

  return !noSanitize.includes(field);
};

export class ContactField extends Component {
  static displayName = 'containers/ContactField';

  static propTypes = {
    fieldName: PropTypes.string.isRequired,
    children: PropTypes.element.isRequired,
    required: PropTypes.bool,
    value: PropTypes.any,
    blurred: PropTypes.bool,
    validationResult: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    forceValidation: PropTypes.bool,
    options: PropTypes.object,
    updateContactField: PropTypes.func,
    blurContactField: PropTypes.func,
    handleChange: PropTypes.func,
  };

  static defaultProps = {
    required: false,
    handleChange: () => {},
  };

  handleChange = (potentiallyUnsafeValue) => {
    const value = sanitizeNeeded(this.props.fieldName) ? sanitizeValue(potentiallyUnsafeValue) : potentiallyUnsafeValue;
    this.props.updateContactField(this.props.fieldName, value);
    this.props.handleChange(value);
  };
  handleBlur = () => this.props.blurContactField(this.props.fieldName);

  render() {
    const { children, fieldName, required, value, blurred, validationResult, forceValidation, options } = this.props;

    return cloneElement(children, {
      name: fieldName,
      required,
      requiredAsteriskVisible: false,
      optionalTextVisible: true,
      value,
      options,
      blurred,
      validationResult,
      forceValidation,
      handleChange: this.handleChange,
      handleBlur: this.handleBlur,
    });
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactField);
