import { getCategoryLink } from 'constants/categoryTree/categoryTree';

import { provideBrandLink, provideLink, provideSearchLink } from 'constants/navigation/navigation';
import { getLocale } from 'constants/language/language';

const TYPE_MATCHERS = {
  cms: /^(\/(de|fr|it)\/)?cms/,
  brand: /^(\/(de|fr|it)\/)?brand/,
  category: /^(\/(de|fr|it)\/)?category/,
  oldCategory: /--c/,
  search: /search=/,
};

const getLink = (type, pages, categories) => {
  const links = {
    cms: (pageId) => provideLink(pages, pageId, getLocale()),
    category: (categoryId) => getCategoryLink(categoryId, getLocale(), categories),
    brand: (uid) => provideBrandLink(pages, uid, getLocale()),
    search: (searchTerm) => provideSearchLink(searchTerm),
    default: () => {},
  };

  return links[type] || links.default;
};

const getSearchHistoryLink = (url) => {
  let type = '';
  let substringIndex = '';

  switch (true) {
    case TYPE_MATCHERS.cms.test(url):
      type = 'cms';
      substringIndex = url.indexOf('cms') + type.length + 1;
      break;
    case TYPE_MATCHERS.brand.test(url):
      type = 'brand';
      substringIndex = url.indexOf('brand') + type.length + 1;
      break;
    case TYPE_MATCHERS.category.test(url):
      type = 'category';
      substringIndex = url.indexOf(':') + 1;
      break;
    case TYPE_MATCHERS.oldCategory.test(url):
      type = 'category';
      substringIndex = url.indexOf('--c') + '--c'.length;
      break;
    case TYPE_MATCHERS.search.test(url):
      type = 'search';
      substringIndex = url.indexOf('=') + 1;
      break;
    default:
      return;
  }

  return {
    type,
    value: decodeURIComponent(url.substring(substringIndex)),
  };
};

export const mapDirectLink = (directLink, pages, categories, isSearchHistory) => {
  let type = '';
  let value = '';

  if (isSearchHistory) {
    const searchHistoryLink = getSearchHistoryLink(directLink.url);
    type = searchHistoryLink.type;
    value = searchHistoryLink.value;
  } else {
    type = directLink.url.substring(0, directLink.url.indexOf(':'));
    value = directLink.url.substring(directLink.url.indexOf(':') + 1);
  }

  const link = getLink(type, pages, categories)(value);

  link.type = type;
  link.uri = directLink.url;

  if (directLink.name) {
    link.text = directLink.name;
  } else if (directLink?.searchTerm) {
    link.text = directLink.searchTerm.trim();
  } else if (!link.text && link.url) {
    link.text = link.url;
  }

  return link;
};

export const mapDirectLinks = (directLinks, pages, categories, isSearchHistory = false) =>
  directLinks
    .map((directLink) => mapDirectLink(directLink, pages, categories, isSearchHistory))
    .filter((value) => Object.keys(value).length !== 0);
