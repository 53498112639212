const keyBy = (array = [], key = '') => {
  if (!key || !array.length) return {};
  const newObject = {};
  array.forEach((entry) => {
    if (entry[key]) {
      newObject[entry[key]] = { ...entry };
    }
  });
  return newObject;
};

export default keyBy;
