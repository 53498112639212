import { browserHistory } from 'react-router';
import UserAPI, { ANONYMOUS_USER_ID } from 'api/UserAPI/UserAPI';
import { getCart } from 'actions/cartActions/cartActions';
import { enterRoute } from 'actions/routeActions/routeActions';
import { getLocale } from 'constants/language/language';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import {
  PRODUCT_SEARCH_EMPTY,
  PRODUCT_SEARCH,
  OPTIN,
  OPTIN_CONFIRMED,
  OPTIN_FAILURE,
  OPTIN_USED,
  OPTIN_EXPIRED,
} from 'constants/routePaths/routePaths';
import { verifyEmail } from 'actions/newsletterActions/newsletterActions';

export const routeHelperKeys = {
  CHECKOUT: 'checkout',
  GUEST_CHECKOUT: 'guest_checkout',
  REGISTRATION: 'account_registration',
  PASSWORD_RESET: 'password_reset',
  ORDER_CONFIRMATION: 'orderconfirmation',
};

export const isUserLoggedIn = () => UserAPI.getUserId() !== ANONYMOUS_USER_ID;

export const requireCartItems = (store, replacePathElements) => (location, replace, callback) => {
  if (__CLIENT__) {
    store.dispatch(getCart()).then(() => {
      const hasNoCartItems = store.getState().cart.totalItems === 0;
      if (hasNoCartItems) {
        const path = mapPathToLocalizedUrl(getLocale(), replacePathElements);
        replace({
          pathname: path,
        });
      }
      callback();
    });
  }

  callback();
};

export const redirectToHomepage = () => (location, replace) => {
  if (__CLIENT__) {
    const path = mapPathToLocalizedUrl(getLocale(), '/');
    replace({
      pathname: path,
    });
  }
};

export const requireLogin = (replacePathElements) => (location, replace) => {
  if (__CLIENT__) {
    if (!isUserLoggedIn()) {
      const path = mapPathToLocalizedUrl(getLocale(), replacePathElements);
      replace({
        pathname: path,
        state: {
          redirectTo: location.location.pathname,
        },
      });
    }
  }
};

export const requireNoLogin = (replacePathElements) => (location) => {
  if (__CLIENT__) {
    if (isUserLoggedIn()) {
      if (location.location.query.redirectTo) {
        replacePathElements = [location.location.query.redirectTo];
      }
      const path = mapPathToLocalizedUrl(getLocale(), replacePathElements);
      window.location.href = path;
    }
  }
};

export const prepareStateForRoute = (store, key) => () => {
  store.dispatch(enterRoute(key));
};

/**
 * redirect to an empty products overview page if no search string is provided.
 */
export const handleSearchRoutes = () => (obj, replace) => {
  if (!obj?.location?.query?.search) {
    replace(`/${getLocale()}/${PRODUCT_SEARCH}/${PRODUCT_SEARCH_EMPTY}`);
  }
};

export const verifyToken = (store, pathOfType) => (location) => {
  const token = location.location.query?.token;

  store.dispatch(verifyEmail(token)).then(() => {
    const tokenVerification = store.getState()?.user?.verificationResult;
    const email = store.getState()?.user?.tokenMail;
    let pathname = '';

    switch (tokenVerification) {
      case 'USED':
        pathname = mapPathToLocalizedUrl(getLocale(), [OPTIN, pathOfType, OPTIN_USED]);
        break;
      case 'EXPIRED':
        pathname = mapPathToLocalizedUrl(getLocale(), [OPTIN, pathOfType, OPTIN_EXPIRED]);
        break;
      case 'VALID':
        pathname = mapPathToLocalizedUrl(getLocale(), [OPTIN, pathOfType, OPTIN_CONFIRMED]);
        break;
      default:
        pathname = mapPathToLocalizedUrl(getLocale(), [OPTIN, pathOfType, OPTIN_FAILURE]);
    }

    browserHistory.push({
      pathname,
      state: {
        email,
      },
    });
  });
};
