import React from 'react';
import cx from 'classnames';

import { getLocale, i18n } from 'constants/language/language';
import FooterColumn from 'components/molecules/FooterColumn/FooterColumn';
import Icon, { ICON_FACEAGENT, ICON_STORES } from 'components/atoms/Icon/Icon';
import { skins } from 'components/molecules/FooterToggle/FooterToggle';
import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';
import useFeatureToggle from 'hooks/useFeatureToggle';
import { FOOTER_HELPCENTER_LINK } from 'constants/links/links';

import styles from './FooterColumnContact.scss';

const FooterColumnContact = () => {
  const nextStorefinderEnabled = useFeatureToggle('nextStorefinderEnabled');

  return (
    <FooterColumn shouldHide={false} displayArrow={false} skin={skins.SKIN_GRAY}>
      <div className={cx(styles.FooterColumnContact)}>
        <div className={styles.contactButton}>
          <ButtonLink color="red" isExternalLink href={FOOTER_HELPCENTER_LINK + getLocale()} stretched size="xs">
            <Icon path={ICON_FACEAGENT} size={Icon.sizes.sm} />
            {i18n('footer.helpAndContact')}
          </ButtonLink>
          <ButtonLink
            outsideRouter={nextStorefinderEnabled}
            variety={ButtonLink.varieties.stroked}
            href={`/${getLocale()}/storefinder`}
            stretched
            size="xs"
          >
            <Icon path={ICON_STORES} size={Icon.sizes.sm} />
            {i18n('footer.findStore')}
          </ButtonLink>
        </div>
      </div>
    </FooterColumn>
  );
};

FooterColumnContact.displayName = 'molecules/FooterColumnContact';

export default FooterColumnContact;
