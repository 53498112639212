import { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addToCart as addToCartAction } from 'actions/cartActions/cartActions';
import { openCartModal as openCartModalAction } from 'actions/uiActions/uiActions';
import { getProductAccessories as getProductAccessoriesAction } from 'actions/productActions/productActions';
import { trackAccessories as trackAccessoriesAction } from 'actions/trackingActions/trackingActions';
import { CONTEXTS } from 'components/molecules/TeaserProduct/TeaserProduct';
import { numberOfReferences } from 'constants/references/references';

export const mapStateToProps = (state, ownProps) => {
  const productCode = ownProps.productCode;
  const product = state?.products?.[productCode] || {};
  const cartEntries = state?.cart?.entries || [];
  const isInCart = cartEntries.findIndex((entry) => entry.productCode === productCode) >= 0;
  return {
    shouldFetchReferences: !product.hasAllReferences,
    isInCart,
  };
};

export const mapDispatchToProps = {
  addToCart: addToCartAction,
  getProductAccessories: getProductAccessoriesAction,
  openCartModal: openCartModalAction,
  trackAccessories: trackAccessoriesAction,
};

export const AddToCart = ({
  addToCart = () => {},
  children,
  context = '',
  getProductAccessories = () => {},
  isInCart = false,
  openCartModal = () => {},
  productCode,
  shouldFetchReferences = false,
  trackAccessories = () => {},
  trackRecoProduct = () => {},
  productAttributes,
}) => {
  const handleClick = async (e, quantity = 1) => {
    e.preventDefault();
    openCartModal();
    if (context === CONTEXTS.swiper) {
      trackRecoProduct();
    }

    // only fetch products if there are not all loaded
    if (shouldFetchReferences) {
      await getProductAccessories({ productCode, pageSize: numberOfReferences.ADD2CART });
    }
    addToCart(productCode, quantity, productAttributes);
    trackAccessories(productCode);
  };

  return cloneElement(children, { handleClick, isInCart });
};

AddToCart.displayName = 'containers/AddToCart';
AddToCart.propTypes = {
  addToCart: PropTypes.func,
  children: PropTypes.node,
  context: PropTypes.string,
  getProductAccessories: PropTypes.func,
  isInCart: PropTypes.bool,
  openCartModal: PropTypes.func,
  productCode: PropTypes.string.isRequired,
  shouldFetchReferences: PropTypes.bool,
  trackAccessories: PropTypes.func,
  trackRecoProduct: PropTypes.func,
  productAttributes: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddToCart);
