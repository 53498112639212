import React from 'react';
import PropTypes from 'prop-types';
import styles from './Expander.scss';
import language from 'constants/language/language';
import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';

const Expander = ({ handle, fullyExpanded = false }) => {
  const text = fullyExpanded ? language('productFeatures.expanderOpen') : language('productFeatures.expanderClose');

  return (
    <div className={styles.expandableBox}>
      <ButtonLink variety={ButtonLink.varieties.stroked} onClick={handle} size={ButtonLink.sizes.md}>
        <span>{text}</span>
      </ButtonLink>
    </div>
  );
};

Expander.displayName = 'molecules/Expander';

Expander.propTypes = {
  handle: PropTypes.func.isRequired,
  fullyExpanded: PropTypes.bool,
};

export default Expander;
