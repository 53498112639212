import validation from 'constants/validation/validation';

const formFieldConfigurations = {
  contactSubjects: {
    required: true,
    value: '',
  },
  salutation: {
    required: true,
  },
  firstName: {
    required: true,
  },
  lastName: {
    required: true,
  },
  addressLine1: {
    required: true,
  },
  addressLine2: {
    required: false,
  },
  contactEmail: {
    required: true,
    validation: validation.email,
  },
  postalCode: {
    required: true,
    validation: validation.postalCode,
  },
  town: {
    required: true,
  },
  contactTextarea: {
    required: true,
  },
  country: {
    required: true,
    value: 'CH',
  },
  address_postalCode: {
    validation: validation.postalCode,
  },
  phone: {
    validation: validation.phoneNumber,
  },
  superpoints_dateOfPurchase: {
    validation: validation.date,
  },
  superpoints_supercardCode: {
    validation: validation.supercardCode,
  },
  superpoints_receiptNumber: {
    validation: validation.receiptNumber,
  },
};

export default formFieldConfigurations;
