import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import formatDate from 'constants/formatDate/formatDate';
import language, { i18n } from 'constants/language/language';
import IconText from 'components/atoms/IconText/IconText';
import Headline from 'components/atoms/Headline/Headline';
import Markdown from 'components/atoms/Markdown/Markdown';
import StarRating from 'components/atoms/StarRating/StarRating';
import Icon, { ICON_ADD, ICON_CHECK } from 'components/atoms/Icon/Icon';
import InputSelect from 'components/atoms/InputSelect/InputSelect';
import TextEllipsis from 'components/atoms/TextEllipsis/TextEllipsis';
import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';
import styles from './RatingComments.scss';
import bootstrap from 'scss/component.scss';

const RatingComments = ({
  numberOfComments = 0,
  reviews,
  showMoreBtn,
  sortOption,
  onSortChange,
  onShowEditorClick,
  onLoadMoreClick,
}) => {
  const sortOptions = {
    DATE_DESC: i18n('productRating.comments.sort.newestFirst'),
    RATING_DESC: i18n('productRating.comments.sort.ratingDesc'),
    RATING_ASC: i18n('productRating.comments.sort.ratingAsc'),
  };

  const onLinkClick = (e) => {
    e.preventDefault();
    onShowEditorClick();
  };

  return (
    <div className={styles.ratingComments}>
      <div className={styles.center}>
        <Headline margin={Headline.margins.MB_15}>
          {i18n('productRating.comments.title')} ({numberOfComments})
        </Headline>
        {numberOfComments === 0 && (
          <p>
            {language('productRating.comments.empty')[0]}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="#" onClick={onLinkClick}>
              {language('productRating.comments.empty')[1]}
            </a>
            {language('productRating.comments.empty')[2]}
          </p>
        )}
      </div>
      {reviews.length > 0 && (
        <>
          <div className={styles.sortWrapper}>
            <InputSelect options={sortOptions} value={sortOption} handleChange={onSortChange} theme="secondary" />
          </div>
          <ul className={styles.list}>
            {reviews.map((rating, index) => (
              <li key={index} className={cx(bootstrap.row, styles.listEntry)}>
                <div className={bootstrap.colMd3}>
                  <div className={styles.details}>
                    <StarRating value={rating.rating} size="medium" />
                    <div className={styles.info}>
                      {rating.alias}
                      <strong>{formatDate(rating.date, { longMonth: true })}</strong>
                    </div>
                  </div>
                </div>

                <div className={bootstrap.colMd9}>
                  <div className={styles.content}>
                    <div className={styles.textWrapper}>
                      <h4 className={styles.headline}>{rating.headline}</h4>
                      {rating.verified && (
                        <div className={styles.verified}>
                          <IconText icon={ICON_CHECK}>
                            <Markdown options={{ forceInline: true }}>
                              {i18n('productRating.comments.verified')}
                            </Markdown>
                          </IconText>
                        </div>
                      )}
                      <TextEllipsis
                        text={String(rating.comment)}
                        showLines={4}
                        moreLabel={i18n('productRating.comments.readMore')}
                        lessLabel={i18n('productRating.comments.readLess')}
                        showLabel
                      />
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
          {showMoreBtn && (
            <div className={styles.moreBtn}>
              <ButtonLink variety={ButtonLink.varieties.stroked} onClick={onLoadMoreClick}>
                <Icon path={ICON_ADD} />
                {i18n('productRating.comments.more')}
              </ButtonLink>
            </div>
          )}
        </>
      )}
    </div>
  );
};

RatingComments.propTypes = {
  numberOfComments: PropTypes.number,
  reviews: PropTypes.array,
  sortOption: PropTypes.string,
  showMoreBtn: PropTypes.bool,
  onSortChange: PropTypes.func,
  onShowEditorClick: PropTypes.func,
  onLoadMoreClick: PropTypes.func,
};

export default RatingComments;
