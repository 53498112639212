import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Headline from 'components/atoms/Headline/Headline';
import pageTitle from 'constants/pageTitle/pageTitle';

export const mapStateToProps = (state) => ({
  pages: state.cms?.navigation?.pages,
});

export class CmsHeadline extends Component {
  static displayName = 'containers/CmsHeadline';

  static propTypes = {
    contentId: PropTypes.string,
    pages: PropTypes.object,
  };

  static defaultProps = {
    pages: {},
  };

  shouldComponentUpdate(nextProps) {
    return nextProps.contentId !== this.props.contentId;
  }

  render() {
    const { contentId, pages } = this.props;
    const heading = pageTitle(pages, contentId);

    return heading ? (
      <Headline tag={Headline.tags.H1} margin={Headline.margins.MB_20}>
        {heading}
      </Headline>
    ) : null;
  }
}

export default connect(mapStateToProps)(CmsHeadline);
