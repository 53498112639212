export const USER_ID = 'uid';
export const ONLINE_ID = 'onlineId';
export const IS_LOGGED_IN = 'isLoggedIn';
export const USER_ACTIVE = 'userActive';
export const B2B_PRICE_GROUP = 'b2bPriceGroup';
export const CUSTOMER_GROUP = 'customerGroup';
export const SUPERCARD_CUSTOMER = 'supercardCustomer';

export const REFRESH_TOKEN = 'refreshToken';
export const REFRESH_TOKEN_EXPIRES = 'refreshTokenExpires';

export const ACCESS_TOKEN = 'accessToken';
export const ACCESS_TOKEN_EXPIRES = 'accessTokenExpires';

export const ACCESS_TOKEN_CLIENT = 'accessTokenClient';

export const CART_ID = 'cartId';
export const DEFAULT_DELIVERY_APPLIED = 'defaultModeApplied';

export const LAST_ORDER_GUID = 'lastOrderGuid';
export const LAST_CART_ID = 'lastCartId';
export const GUEST_EMAIL = 'guestEmail';
export const MILA_APPLICABLE = 'milaApplicable';

export const EMAIL = 'email';
export const BIRTHDAY = 'birthday';
export const PAYMENT = 'invoice';
export const DELIVERY = 'delivery';

export const DATE_TOPBAR_DISMISSED = 'date';

export const RECO_COOKIE = 'reco';

export const USP_FLASH_COOKIE = 'flash';

export const WINDOW_ID = 'WINDOW_ID';

export const MAIN_MENU_CLOSED = 'mainMenuClosed';

export const MFGROUP_UUID = 'fraud_uid';

export const WATCHLIST_ID = 'watchlistId';

export const ORDER_CODE = 'ORDER_CODE';

export const TEMP_CART = 'TEMP_CART';
export const TEMP_PRODUCTS = 'TEMP_PRODUCTS';

// Keys for ID order confirmation
export const DELIVERY_MODE = 'DELIVERY_MODE';
export const PAYMENT_MODE = 'PAYMENT_MODE';
export const PEGI_VERIFICATION_NEEDED = 'PEGI_VERIFICATION_NEEDED';
export const ESD_ONLY_CART = 'ESD_ONLY_CART';
export const ESD_MIXED_CART = 'ESD_MIXED_CART';

export const LAST_RATING_DATE = 'lastRatedDate';

// Google Survey (order confirmation)
export const SURVEY_COMPLETED = 'SURVEY_COMPLETED';

export const CATEGORIES = 'categories';

export const SHOW_OPTIN = 'SHOW_OPTIN';

export const TELESALES_MODE = 'telesalesMode';

export const TELESALES_EMPLOYEE_CODE = 'telesalesEmployeeCode';

export const TELESALES_EMPLOYEE_GROUP = 'telesalesEmployeeGroup';

export const TELESALES_STORE_CODE = 'telesalesEmployeeStore';

export const SEARCH_HISTORY_NEXT = 'searchHistoryNext';

export const PERSONALIZED_CONTENT = 'personalizedContent';

// Dsgv
export const DSGV_CONSENTS = 'dsgvConsents';

// Telesales
export const LOGOUT_TELESALES_EMPLOYEE = 'logoutTelesalesEmployee';

// Keys for ID Street Address
export const SELECTED_ADDRESS_ID = 'selectedAddressId';
