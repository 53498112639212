import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import { getLocale } from 'constants/language/language';
import { checkout, checkoutGuest } from 'constants/routePaths/routePathCombinations';

import { isUserLoggedIn } from 'routes/routeHelpers';
import LoadingState from 'components/molecules/LoadingState/LoadingState';

export class PaymentCancel extends Component {
  componentDidMount() {
    const checkoutRoute = isUserLoggedIn()
      ? mapPathToLocalizedUrl(getLocale(), checkout)
      : mapPathToLocalizedUrl(getLocale(), checkoutGuest);

    browserHistory.push(checkoutRoute);
  }

  render() {
    return <LoadingState />;
  }
}

export default PaymentCancel;
