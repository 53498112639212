import React from 'react';
import PropTypes from 'prop-types';
import { getFormattedPriceValue } from 'constants/price/price';
import language from 'constants/language/language';

const Reduction = ({ savings }) => (
  <>
    {language('discount.savings')}
    {savings.currencyIso} {getFormattedPriceValue(savings.value)}
  </>
);

Reduction.displayName = 'atoms/Reduction';
Reduction.propTypes = {
  savings: PropTypes.object,
};
Reduction.defaultProps = {
  savings: {},
};

export default Reduction;
