import { useState } from 'react';
import { BREAKPOINTS, BREAKPOINT_MIN_WIDTH } from 'constants/breakpoints/breakpoints';
import useWindowResize from './useWindowResize';

const useBreakpointChange = () => {
  const [breakpoint, setBreakpoint] = useState(null);
  useWindowResize(() => {
    if (!__CLIENT__) {
      return false;
    }

    const clientWidth = window.innerWidth;
    let nextBreakpoint;

    if (clientWidth >= BREAKPOINT_MIN_WIDTH.XL) {
      nextBreakpoint = BREAKPOINTS.XL;
    } else if (clientWidth >= BREAKPOINT_MIN_WIDTH.LG) {
      nextBreakpoint = BREAKPOINTS.LG;
    } else if (clientWidth >= BREAKPOINT_MIN_WIDTH.MD) {
      nextBreakpoint = BREAKPOINTS.MD;
    } else if (clientWidth >= BREAKPOINT_MIN_WIDTH.SM) {
      nextBreakpoint = BREAKPOINTS.SM;
    } else if (clientWidth >= BREAKPOINT_MIN_WIDTH.XS) {
      nextBreakpoint = BREAKPOINTS.XS;
    } else {
      nextBreakpoint = BREAKPOINTS.XXS;
    }

    setBreakpoint(nextBreakpoint);
  });

  return breakpoint;
};

export default useBreakpointChange;
