import { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toggleProductView as toggleProductViewAction } from 'actions/uiActions/uiActions';
import { ProductViewTypes } from 'constants/ProductViewTypes/ProductViewTypes';
import { useWritePersistenceStore } from 'hooks/usePersistenceStore';

export const mapDispatchToProps = {
  toggleProductView: toggleProductViewAction,
};
export const mapStateToProps = (state) => {
  const viewType = state.ui?.productViewType;
  return {
    viewType,
  };
};

const ProductViewToggle = ({ children, toggleProductView = () => {}, viewType = ProductViewTypes.GRID }) => {
  const setStore = useWritePersistenceStore({ key: 'ui.viewType' });

  const toggleView = (newViewType) => {
    if (newViewType !== viewType) {
      toggleProductView(newViewType);
      setStore(newViewType);
    }
  };

  return cloneElement(children, {
    viewType,
    onToggle: toggleView,
  });
};

ProductViewToggle.displayName = 'containers/ProductViewToggle';
ProductViewToggle.propTypes = {
  children: PropTypes.node.isRequired,
  toggleProductView: PropTypes.func,
  viewType: PropTypes.oneOf(Object.values(ProductViewTypes)),
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductViewToggle);
