import React from 'react';
import PropTypes from 'prop-types';
import language from 'constants/language/language';
import { cypressAttributes } from 'constants/cypress/cypress';
import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';
import { noop } from 'constants/noop/noop';
import styles from './MessageAddedToCartButtons.scss';
import useTelesalesMode from 'hooks/useTelesalesMode';

const MessageAddedToCartButtons = ({ closeModal = noop, goToCart = noop }) => {
  const isTelesalesMode = useTelesalesMode();

  return (
    <div className={styles.buttonWrapper}>
      <ButtonLink variety={ButtonLink.varieties.stroked} onClick={closeModal}>
        {language('modal.addToCartModal.close')}
      </ButtonLink>
      <div className={styles.cartButton}>
        <ButtonLink
          size={ButtonLink.sizes.sm}
          color={isTelesalesMode ? ButtonLink.colors.blue : ButtonLink.colors.red}
          onClick={goToCart}
          data-cy={cypressAttributes.cart.addToCartContinue}
          featureName="nextCartEnabled"
        >
          {language('product.goToCart')}
        </ButtonLink>
      </div>
    </div>
  );
};

MessageAddedToCartButtons.propTypes = {
  closeModal: PropTypes.func,
  goToCart: PropTypes.func,
};

export default MessageAddedToCartButtons;
