import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router';

import { getEnv } from 'config/config';
import language, { getLocale } from 'constants/language/language';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import { awards } from 'constants/routePaths/routePathCombinations';
import { STOREFINDER } from 'constants/routePaths/routePaths';

import Markdown from 'components/atoms/Markdown/Markdown';
import Icon, { ICON_LOCATION_ON_OUTLINED } from 'components/atoms/Icon/Icon';
import Headline from 'components/atoms/Headline/Headline';
import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';
import { apiStatus } from 'constants/apiStatus/apiStatus';

import styles from './StoreFinderBox.scss';

export const StoreFinderBox = (props) => {
  const {storeName, awardImageUrl, userApiStatus, cmsApiStatus} = props;

  const combineUrl = (url) => `${getEnv('api')}${url}`;

  const getStoreManagerUrl = (url) => (url ? combineUrl(url) : '/images/content/store-manager-fallback.svg');

  const renderCustomerInfos = () => (
    <div>
      <Headline margin={Headline.margins.NONE}>{language('storeFinder.box.headline')}</Headline>
      <div className={styles.imageContainer}>
        <img
          className={styles.managerImage}
          src={getStoreManagerUrl(props.storeManagerImageUrl)}
          alt="manager"
        />
      </div>
      <div className={styles.infoBox}>
        <span className={styles.visitTextCustomer}>{language('storeFinder.box.customer.visitUs')}</span>
        <span className={styles.storeName}>{props.storeName}</span>
        {props.isOpen ? (
          <span className={cx(styles.openingInfo, styles.storeOpen)}>{language('storeFinder.box.customer.open')}</span>
        ) : (
          <span className={cx(styles.openingInfo, styles.storeClosed)}>
            {language('storeFinder.box.customer.closed')}
          </span>
        )}
      </div>
    </div>
  );

  const renderGuestInfos = () => (
    <>
      <Headline margin={Headline.margins.NONE}>{language('storeFinder.box.headline')}</Headline>
      <div className={styles.imageContainer}>
        <img
          className={styles.managerImage}
          src={getStoreManagerUrl(props.randomManagerImageUrl)}
          alt="manager"
        />
      </div>
      <div className={styles.infoBox}>
        <span className={styles.visitTextGuest}>{language('storeFinder.box.guest.advise')}</span>
        <Markdown>{language('storeFinder.box.guest.visitUs')}</Markdown>
        <ButtonLink
          href={mapPathToLocalizedUrl(getLocale(), [STOREFINDER])}
          outsideRouter
          variety={ButtonLink.varieties.stroked}
          size={ButtonLink.sizes.md}
        >
          <Icon path={ICON_LOCATION_ON_OUTLINED}/>
          <span className={styles.buttonText}>{language('storeFinder.box.guest.buttonText')}</span>
        </ButtonLink>
      </div>
    </>
  );


  return (
    <div className={styles.boxWrapper}>
      {__CLIENT__ && (
        <>
          {storeName && userApiStatus === apiStatus.success && renderCustomerInfos()}
          {!storeName && cmsApiStatus === apiStatus.success && renderGuestInfos()}
          {awardImageUrl && (
            <Link to={mapPathToLocalizedUrl(getLocale(), awards, false)} target="_blank">
              <img className={styles.awardImage} src={combineUrl(awardImageUrl)} alt="award"/>
            </Link>
          )}
        </>
      )}
    </div>
  );
}
StoreFinderBox.displayName = 'molecules/StoreFinderBox';

StoreFinderBox.propTypes = {
  isOpen: PropTypes.bool,
  storeName: PropTypes.string,
  storeManagerImageUrl: PropTypes.string,
  awardImageUrl: PropTypes.string,
  storeId: PropTypes.string,
  userApiStatus: PropTypes.string,
  cmsApiStatus: PropTypes.string,
  randomManagerImageUrl: PropTypes.string,
};

StoreFinderBox.defaultProps = {
  isOpen: false,
  storeName: '',
  storeManagerImageUrl: '',
  awardImageUrl: '',
  storeId: '',
  randomManagerImageUrl: '',
};
export default StoreFinderBox;
