/**
 * @name Container: cartIndicator
 * @description container for cart counter and go-to-cart button in header
 * @author Martin Dilg
 */

import { Component, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cartEntriesCount from 'constants/cartEntriesCount/cartEntriesCount';

export const mapStateToProps = (state) => ({
  cartEntries: state.cart.entries,
});

class Cart extends Component {
  static displayName = 'containers/Indications/Cart';

  static propTypes = {
    children: PropTypes.element.isRequired,
    cartEntries: PropTypes.array,
  };

  static defaultProps = {
    cartEntries: [],
  };

  render() {
    const counter = cartEntriesCount(this.props.cartEntries);
    if (counter === 0) return null;

    return cloneElement(this.props.children, {
      counter,
    });
  }
}

export default connect(mapStateToProps)(Cart);
