import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';

import Icon, { ICON_KEYBOARD_ARROW_LEFT, ICON_KEYBOARD_ARROW_RIGHT } from 'components/atoms/Icon/Icon';

import bootstrap from 'scss/component.scss';
import styles from './GalleryCarousel.scss';

import dataTracking from 'constants/trackingAttributes/dataTracking';

const GalleryCarousel = ({
  activeSlide = 0,
  goToSlide = () => {},
  galleryData = [],
  openLightboxModal = () => {},
  isLightBoxModal = false,
  trackingAttributes = {},
}) => {
  const [currentSlide, setCurrentSlide] = useState(activeSlide);
  const [swiper, setSwiper] = useState(null);

  useEffect(() => {
    if (swiper && activeSlide !== currentSlide) {
      swiper.slideTo(activeSlide);
      setCurrentSlide(activeSlide);
    }
  }, [activeSlide]);

  useEffect(() => {
    if (isLightBoxModal) {
      window.addEventListener('keydown', keyHandler);
    }

    return () => {
      window.removeEventListener('keydown', keyHandler);
    };
  });

  const keyHandler = (e) => {
    if (e.key === 'ArrowLeft') swipeLeft();
    if (e.key === 'ArrowRight') swipeRight();
  };

  const onSlideChange = (changedSwiper) => {
    if (changedSwiper) {
      goToSlide(changedSwiper.activeIndex);
    }
  };

  const swipeLeft = () => {
    const nextIndex = currentSlide ? currentSlide - 1 : galleryData.length - 1;
    if (swiper) {
      goToSlide(nextIndex);
    }
  };

  const swipeRight = () => {
    const nextSlideIndex = currentSlide === galleryData.length - 1 ? 0 : currentSlide + 1;
    if (swiper) {
      goToSlide(nextSlideIndex);
    }
  };

  const openLightbox = (index) => {
    goToSlide(index);
    if (!isLightBoxModal) {
      openLightboxModal();
    }
  };

  return (
    <>
      <Swiper
        onSlideChange={onSlideChange}
        className={cx(styles.galleryContainer, bootstrap.clearfix, bootstrap.hiddenPrint, {
          [styles.lightBox]: isLightBoxModal,
        })}
        spaceBetween={30}
        preloadImages
        simulateTouch
        grabCursor
        initialSlide={activeSlide}
        onSwiper={setSwiper}
      >
        {galleryData.map((data, i) => (
          <SwiperSlide
            className={cx(styles.itemContainer, { [styles.magnify]: !isLightBoxModal })}
            key={i}
            onClick={() => openLightbox(i)}
            data-tracking={dataTracking({
              TYPE: 'pds:image',
              FUNCTION: 'open-overlay',
              ATTRIBUTE_1: 'imageLink',
              SKU: trackingAttributes.sku,
            })}
          >
            {data}
          </SwiperSlide>
        ))}
      </Swiper>
      {galleryData.length > 1 && (
        <>
          <div className={styles.arrowBack} onClick={swipeLeft}>
            <Icon size={Icon.sizes.md} path={ICON_KEYBOARD_ARROW_LEFT} />
          </div>
          <div className={styles.arrowForth} onClick={swipeRight}>
            <Icon size={Icon.sizes.md} path={ICON_KEYBOARD_ARROW_RIGHT} />
          </div>
        </>
      )}
    </>
  );
};

GalleryCarousel.propTypes = {
  activeSlide: PropTypes.number,
  galleryData: PropTypes.array,
  goToSlide: PropTypes.func,
  openLightboxModal: PropTypes.func,
  isLightBoxModal: PropTypes.bool,
  trackingAttributes: PropTypes.object,
};

GalleryCarousel.displayName = 'molecules/GalleryCarousel';

export default GalleryCarousel;
