import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import bootstrap from 'scss/component.scss';
import styles from './ProductBlog.scss';

import ProductBlogPost from 'components/molecules/ProductBlogPost/ProductBlogPost';

const ProductBlog = ({ title, posts }) => (
  <Fragment>
    <hr className={bootstrap.detailHorizontalRule} />
    <div className={styles.GlobalHeader}>
      <span className={styles.Title}>{title}</span>
    </div>
    {posts.map((post, idx) => (
      <ProductBlogPost {...post} key={idx} />
    ))}
  </Fragment>
);

ProductBlog.propTypes = {
  title: PropTypes.string,
  posts: PropTypes.array,
};

ProductBlog.defaultProps = {
  title: '',
  posts: [],
};

ProductBlog.displayName = 'organisms/ProductBlog';

export default ProductBlog;
