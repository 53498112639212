import React from 'react';
import PropTypes from 'prop-types';

const Cards = {
  SUPERCARD: 'SUPERCARD',
  SUPERCARD_VISA: 'SUPERCARD_VISA',
  SUPERCARD_MASTERCARD: 'SUPERCARD_MASTERCARD',
};

/**
 * Different render method is used here, other than in paymentMethod. That's because the gradient background cannot be used inside a <symbol> tag.
 */
const SupercardIcon = ({ card }) => {
  return <img width="60" height="40" src={`/images/logos/supercards/${card}.svg`} alt={'supercard-icon'}></img>;
};

SupercardIcon.displayName = 'atoms/SupercardIcon';

SupercardIcon.Cards = Cards;

SupercardIcon.propTypes = {
  card: PropTypes.oneOf(Object.values(Cards)).isRequired,
};

export default SupercardIcon;
