import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import dataTracking from 'constants/trackingAttributes/dataTracking';
import { noop } from 'constants/noop/noop';
import Icon, { ICON_ARROW_DROPDOWN } from 'components/atoms/Icon/Icon';
import Spinner from 'components/atoms/Spinner/Spinner';
import styles from './InputSelect.scss';
import bootstrap from 'scss/component.scss';

const themes = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};

const InputSelect = ({
  value = '',
  name = '',
  handleChange = noop,
  options = {},
  showError = false,
  pending = false,
  disabled = false,
  placeholder = '',
  theme = themes.PRIMARY,
  trackingAttributes,
  cyData,
}) => {
  const selectBoxRef = useRef();

  const onChange = () => {
    if (!pending) {
      handleChange(selectBoxRef.current.value);
    }
  };

  const wrapClasses = classNames(styles.wrap, {
    [styles[theme]]: theme !== 'primary',
  });
  const selectClasses = classNames(bootstrap.formControl, styles.select, {
    [styles.hasDanger]: showError,
    [styles.disabled]: disabled,
    [styles.pending]: pending,
    [styles.placeholder]: placeholder && !value,
  });
  const iconClasses = classNames(styles.icon, {
    [styles.hasError]: showError,
    [styles.disabled]: disabled,
    [styles.pending]: pending,
  });

  return (
    <div className={wrapClasses} data-tracking={trackingAttributes && dataTracking(trackingAttributes)}>
      <select
        ref={selectBoxRef}
        onChange={onChange}
        className={selectClasses}
        disabled={disabled}
        value={value}
        aria-label="selectBox"
        name={name}
        data-cy={cyData}
      >
        {placeholder && (
          <option value="" disabled hidden>
            {placeholder}
          </option>
        )}
        {Object.keys(options).map((key) => (
          <option key={key} value={key}>
            {options[key]}
          </option>
        ))}
      </select>
      {pending ? <Spinner /> : <Icon path={ICON_ARROW_DROPDOWN} className={iconClasses} />}
    </div>
  );
};

InputSelect.displayName = 'atoms/InputSelect';

InputSelect.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  handleChange: PropTypes.func,
  options: PropTypes.object,
  showError: PropTypes.bool,
  pending: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  theme: PropTypes.oneOf(Object.values(themes)),
  trackingAttributes: PropTypes.object,
  cyData: PropTypes.string,
};

export default InputSelect;
