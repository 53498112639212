import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@interdiscount/component-library-interdiscount';
import ZoovuAdvisor from 'components/organisms/ZoovuAdvisor/ZoovuAdvisor';

const propTypes = {
  assistantId: PropTypes.string,
  closeModal: PropTypes.func,
};

const defaultProps = {
  assistantId: '',
  closeModal: () => {},
};

export const ModalZoovu = ({ closeModal, assistantId }) => (
  <Modal isOpen onRequestClose={closeModal} size="LG">
    <ZoovuAdvisor assistantId={assistantId} />
  </Modal>
);

ModalZoovu.propTypes = propTypes;
ModalZoovu.defaultProps = defaultProps;
ModalZoovu.displayName = 'organisms/ModalZoovu';
