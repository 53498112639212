// @flow

import type { Product, AvailabilityStatus } from 'reducers/products/products';
import { isB2BCustomer } from 'constants/customerGroup/customerGroup';
import merge from 'lodash/merge';
import has from 'constants/helper/has';
import { stockLevelStatus } from 'constants/ProductStatus/ProductStatus';
import { hydrateDistribution } from 'constants/ratings/ratings';
import { getAvailabilityState } from 'constants/deliveryStates/availabilityStates';

export const hasFreeDelivery = (price: number): boolean => price >= 49.0;

export const canBeReserved = (availabilityStatus: AvailabilityStatus): boolean =>
  availabilityStatus === stockLevelStatus.r;

export const isButtonDisabled = (sapSaleable: boolean): boolean => !sapSaleable;

export const canBeBought = (sapSaleable: boolean, sapVisibility: boolean): boolean => {
  if (!sapSaleable && !sapVisibility) {
    return false;
  }
  return true;
};

export default function product(state: Object = {}, payload: Product | Object): Product | Object {
  // TODO: refactor & add comments to this 🍝-code
  const newState = {};

  // remove old classifications to prevent merge errors
  const stateWithoutClassifications =
    !state.classifications?.length || !payload.classifications?.length
      ? { ...state }
      : { ...state, classifications: [] };
  merge(newState, stateWithoutClassifications, payload);

  if (state?.customImageData?.length > 0 && state?.customImageData?.length !== payload.customImageData.length) {
    newState.customImageData = [...state.customImageData, ...payload.customImageData].filter(
      (image, index, self) => index === self.findIndex((element) => element.code === image.code)
    );
  }

  if (!isB2BCustomer() && newState.neutralDeliveryPossible) {
    delete newState.neutralDeliveryPossible;
  }

  if (has(newState, 'cmsLinkedBrandPage')) {
    newState.brandPageUrl = newState?.cmsLinkedBrandPage || '';
  }

  // Only store hasFreeDelivery inside newState when finalPrice has a value
  if (newState?.productPriceData?.finalPrice?.value) {
    newState.hasFreeDelivery = hasFreeDelivery(newState?.productPriceData?.finalPrice?.value);
  }

  // see http://confluence:8090/display/IPB/TDS0001+-+Sichtbarkeit+der+Produkte for the next three lines:
  if (newState.availabilityStatus) {
    const { availability, deliveryTime } = getAvailabilityState(newState);
    newState.availability = availability;
    newState.deliveryTime = deliveryTime;
    newState.canBeReserved = canBeReserved(newState.availabilityStatus);
  }
  if (has(newState, 'sapSaleable')) newState.isButtonDisabled = isButtonDisabled(newState.sapSaleable);
  if (has(newState, 'sapSaleable') && has(newState, 'sapVisibility')) {
    newState.canBeBought = canBeBought(newState.sapSaleable, newState.sapVisibility);
  }

  if (newState.averageRating) {
    newState.averageRating = payload.averageRating || 0;
  }
  if (newState.numberOfReviews) {
    newState.numberOfReviews = payload.numberOfReviews || state.numberOfReviews || 0;
  }
  if (newState.averageRecommendation) {
    newState.averageRecommendation = payload.averageRecommendation || state.averageRecommendation || 0;
  }
  if (newState.numberOfComments) {
    newState.numberOfComments = payload.numberOfComments || state.numberOfComments || 0;
  }
  if (newState.reviews) {
    newState.reviews = payload.reviews || state.reviews || [];
  }
  if (newState.reviewDistribution) {
    newState.reviewDistribution = hydrateDistribution(payload.reviewDistribution || state.reviewDistribution || {});
  }

  return newState;
}
