import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';

import { ICON_ARROW_HISTORY_BACK } from 'components/atoms/Icon/Icon';
import MobileNavigationItem from 'components/atoms/MobileNavigationItem/MobileNavigationItem';

import { cypressAttributes } from 'constants/cypress/cypress';
import language, { getLocale } from 'constants/language/language';
import { getCategoryUrl } from 'constants/categoryTree/categoryTree';

import styles from '../CategoryDrilldown/CategoryDrilldown.scss';
import { NAVIGATION_CLICK, NAVIGATION_SUB } from 'constants/trackingAttributes/trackingAttributes';
import { trackMobileNavigationBack, trackMobileNavigationGoTo, trackMobileNavigationSelect } from 'tracking/tracking';
import { sanitizeAndEncode } from 'constants/sanitizeAndEncode/sanitizeAndEncode';

const CategoryDrilldown = ({
  categories,
  drilledCategoryTree,
  drillToCategory,
  categoryParents,
  resetNavigation,
  canDrill,
  portal,
  handleAnimation,
  highlightedItem,
}) => {
  const portalContainer = document.getElementById(portal);
  const isActiveCategory = location?.pathname.split('--c')[1];
  const POSITION_OFFSET = 2;

  const goBackInitial = () => {
    trackMobileNavigationBack(
      NAVIGATION_SUB,
      1,
      categories[categoryParents?.[categoryParents?.length - 1]][getLocale()],
      language('header.allProducts')
    );
    resetNavigation();
  };

  const handleCategoryParents = (categoryId, index) => {
    trackMobileNavigationBack(
      NAVIGATION_SUB,
      index + POSITION_OFFSET,
      categories[categoryParents?.[categoryParents?.length - 1]][getLocale()],
      categories[categoryId]?.[getLocale()]
    );
    drillToCategory(categoryId);
  };

  const showAllProducts = () => {
    trackMobileNavigationGoTo(
      NAVIGATION_CLICK,
      1,
      language('header.allProductsShort'),
      categories[categoryParents?.[categoryParents?.length - 1]][getLocale()]
    );
    browserHistory.push(getCategoryUrl(categoryParents[categoryParents.length - 1], getLocale(), categories));
  };

  const handleDrilledCategories = (category, i) => {
    if (canDrill[i]) {
      trackMobileNavigationSelect(
        NAVIGATION_SUB,
        i + POSITION_OFFSET,
        categories[category.id]?.[getLocale()],
        categories[categoryParents?.[categoryParents?.length - 1]][getLocale()]
      );
      drillToCategory(category.id);
    } else {
      trackMobileNavigationGoTo(
        NAVIGATION_CLICK,
        i + POSITION_OFFSET,
        categories[categoryParents?.[categoryParents?.length - 1]][getLocale()],
        categories[category.id]?.[getLocale()]
      );
      browserHistory.push(getCategoryUrl(category.id, getLocale(), categories));
    }
  };

  return createPortal(
    <div data-cy={cypressAttributes.mobileNavigation.categoryDrilldown}>
      <MobileNavigationItem
        dataCy={`${cypressAttributes.mobileNavigation.categoryDrilldown}-close`}
        showIndicator
        indicatorIcon={ICON_ARROW_HISTORY_BACK}
        onClick={goBackInitial}
        handleAnimation={() => handleAnimation('initial')}
        featureName="nextCategoryEnabled"
      >
        {language('header.allProducts')}
      </MobileNavigationItem>

      <div
        data-cy={`${cypressAttributes.mobileNavigation.categoryDrilldown}-breadcrumb`}
        className={styles.drilldownBreadcrumb}
      >
        {categoryParents.length >= 0 &&
          categoryParents.map((categoryId, index) => {
            const canGoBack = categoryParents.length - 1 !== index;
            return (
              <MobileNavigationItem
                disabled={!canGoBack}
                indicatorIcon={ICON_ARROW_HISTORY_BACK}
                showIndicator={canGoBack}
                key={categoryId}
                onClick={() => handleCategoryParents(categoryId, index)}
                handleAnimation={() => handleAnimation('reverse')}
                featureName="nextCategoryEnabled"
              >
                {categories[categoryId]?.[getLocale()]}
              </MobileNavigationItem>
            );
          })}
      </div>

      <MobileNavigationItem
        dataCy={`${cypressAttributes.mobileNavigation.categoryDrilldown}-all`}
        onClick={showAllProducts}
        handleAnimation={handleAnimation}
        highlightedItem={categoryParents[categoryParents.length - 1] === isActiveCategory ? isActiveCategory : null}
        id={isActiveCategory}
        featureName="nextCategoryEnabled"
      >
        {language('header.allProductsShort')}...
      </MobileNavigationItem>

      {drilledCategoryTree &&
        drilledCategoryTree.map((category, i) => (
          <MobileNavigationItem
            highlightedItem={highlightedItem}
            dataCy={sanitizeAndEncode(categories[category.id]?.[getLocale()])}
            key={category.id}
            id={category.id}
            showIndicator={canDrill[i] || false}
            onClick={() => handleDrilledCategories(category, i)}
            handleAnimation={handleAnimation}
            featureName="nextCategoryEnabled"
          >
            {categories[category.id]?.[getLocale()]}
          </MobileNavigationItem>
        ))}
    </div>,
    portalContainer
  );
};

CategoryDrilldown.propTypes = {
  categories: PropTypes.object,
  drilledCategoryTree: PropTypes.array,
  drillToCategory: PropTypes.func,
  categoryParents: PropTypes.array,
  resetNavigation: PropTypes.func,
  canDrill: PropTypes.array,
  portal: PropTypes.string,
  handleAnimation: PropTypes.func,
  highlightedItem: PropTypes.string,
};

CategoryDrilldown.defaultProps = {
  categoryTree: {},
  drilledCategoryTree: [],
  canDrill: true,
  portal: '',
};

export default CategoryDrilldown;
