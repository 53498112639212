export const loadScript = (() => {
  const load = (tag) => (url, id) =>
    new Promise((resolve, reject) => {
      if (url == null || id == null) {
        // eslint-disable-line
        reject(tag);
        return;
      }

      const element = document.createElement(tag);
      const parent = 'head';
      let attr = 'src';

      element.onload = () => resolve(url);
      element.onerror = () => reject(url);
      element.id = id;

      switch (tag) {
        case 'link':
          element.type = 'text/css';
          element.rel = 'stylesheet';
          attr = 'href';
          break;
        default:
          element.async = true;
          element.defer = true;
          break;
      }

      element[attr] = url;
      document[parent].appendChild(element);
    });

  return {
    css: load('link'),
    js: load('script'),
  };
})();
