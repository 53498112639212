import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import MarkdownBox from 'components/molecules/MarkdownBox/MarkdownBox';

import language from 'constants/language/language';
import { getBreakpoint, BREAKPOINTS, smallerThan } from 'constants/breakpoints/breakpoints';
import { noop } from 'constants/noop/noop';

import styles from './ProductDescription.scss';

const ProductDescription = ({ description = '', markupDescription = '', onToggle = noop }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const getDescription = () => {
    return <MarkdownBox markdown={markupDescription && markupDescription !== '' ? markupDescription : description} />;
  };

  const toggleDescription = () => {
    const newCollapsedState = !isCollapsed;
    setIsCollapsed(newCollapsedState);
    onToggle(newCollapsedState);
  };

  const renderExpander = () => (
    <div className={styles.expander}>
      <a onClick={toggleDescription}>{isCollapsed ? `${language('common.more')} +` : language('common.showLess')}</a>
    </div>
  );

  const descriptionToRender = getDescription();

  const contentLength = markupDescription ? markupDescription.length : descriptionToRender.length;
  const maxLength = __CLIENT__ && smallerThan(getBreakpoint(), BREAKPOINTS.MD) ? 500 : 1200;
  const showExpander = contentLength > maxLength;

  return (
    <div className={styles.container}>
      <div className={cx(styles.description, { [styles.descriptionTruncated]: isCollapsed && showExpander })}>
        {descriptionToRender}
      </div>
      {showExpander && renderExpander()}
    </div>
  );
};

ProductDescription.displayName = 'molecules/ProductDescription';
ProductDescription.propTypes = {
  description: PropTypes.string,
  markupDescription: PropTypes.string,
  onToggle: PropTypes.func,
};

export default ProductDescription;
