import { useEffect, useState } from 'react';
import { TELESALES_EMPLOYEE_CODE } from 'constants/storage/storageKeys';

const useTelesalesEmployeeCode = () => {
  const [employeeCode, setEmployeeCode] = useState('');

  useEffect(() => {
    if (__CLIENT__) {
      setEmployeeCode(localStorage.getItem(TELESALES_EMPLOYEE_CODE));
    }
  }, []);
  return employeeCode;
};

export default useTelesalesEmployeeCode;
