import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import { useLocalStorage } from 'usehooks-ts';
import { noop } from 'constants/noop/noop';
import { SEARCH_HISTORY_NEXT } from 'constants/storage/storageKeys';

export const SearchHistoryContext = createContext({
  searchHistory: [],
  deleteSearchHistory: noop,
  addSearchHistoryEntry: noop,
  deleteSearchHistoryEntry: noop,
});

const SearchHistoryProvider = ({ children }) => {
  const [searchHistory, setSearchHistory] = useLocalStorage(SEARCH_HISTORY_NEXT, []);
  const findIndex = (searchTerm) => {
    // trim shouldn't be needed, but just to be on the save site
    return searchHistory.findIndex((item) => item.searchTerm.toLowerCase().trim() === searchTerm.toLowerCase().trim());
  };

  const deleteSearchHistory = () => {
    setSearchHistory([]);
  };

  const addSearchHistoryEntry = (searchTerm, uri) => {
    const trimmedSearchTerm = searchTerm.trim();
    if (!trimmedSearchTerm || !uri) {
      throw new Error('searchTerm and uri should not be undefined, null or empty.');
    }

    const index = findIndex(trimmedSearchTerm);
    if (index >= 0) {
      // already existing -> remove it
      setSearchHistory((sh) => {
        sh.splice(index, 1);
        return sh;
      });
    }

    // add item to the top
    setSearchHistory((sh) => {
      sh.unshift({
        searchTerm: trimmedSearchTerm,
        url: uri,
      });
      if (sh.length > 10) {
        sh.length = 10;
      }
      return sh;
    });
  };

  const deleteSearchHistoryEntry = (searchTerm) => {
    const index = findIndex(searchTerm);
    if (index >= 0) {
      setSearchHistory((sh) => {
        sh.splice(index, 1);
        return sh;
      });
    }
  };

  return (
    <SearchHistoryContext.Provider
      value={{
        searchHistory,
        deleteSearchHistory,
        addSearchHistoryEntry,
        deleteSearchHistoryEntry,
      }}
    >
      {children}
    </SearchHistoryContext.Provider>
  );
};

SearchHistoryProvider.displayName = 'contexts/SearchHistoryProvider';

SearchHistoryProvider.propTypes = {
  children: PropTypes.node,
};

export default SearchHistoryProvider;
