// @flow
/**
 * Returns a date object from an ISO date string
 *
 * @param {string} dateString - A date ISO string: e.g. '2018-11-03T00:00:00'
 */
export const getDateFromString = (dateString: string): Date => {
  const regEx = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;
  if (!dateString.match(regEx)) {
    throw new Error('The received date for getDateFromString did not match the <YYYY>-<MM>-<DD>T<HH>:<MM>:<SS> format');
  }
  return new Date(dateString);
};

/**
 * Returns the UNIX UTC Time from a local time
 *
 * @param {Date} date
 */
export const getUNIXTimeFromDate = (date: Date): number => date.getTime();

/**
 * Returns difference between startdate and enddate in days.
 * calculate the time difference between both specified dates by using the inbuilt getTime(). Then calculate the number
 * of days by dividing the difference of time of both dates by the number of milliseconds per day that are (1000 * 60 * 60 * 24).
 *
 *
 * this algorithm is also used for day difference calculation in getDeliverySatus.js
 */

export const getDayDifference = (firstDate = new Date(), secondDate = new Date()) => {
  if (firstDate instanceof Date && secondDate instanceof Date) {
    const diff = Math.abs(firstDate.getTime() - secondDate.getTime());
    return Math.round(diff / (1000 * 60 * 60 * 24));
  }
  return 0;
};
const millisecondsPerYear = 31536000000; // millisecondsPerDay * 365;
/**
 * Returns difference between startdate and enddate in years.
 * calculate the time difference between both specified dates by using the inbuilt getTime(). Then calculate the number
 * of years by dividing the difference of time of both dates by the no. of milliseconds in a year that are (millisecondsPerDay * 365).
 */

export const getYearDifference = (startDate: Date, endDate: Date) => {
  if (!startDate || !endDate) return null;
  if (startDate instanceof Date && endDate instanceof Date) {
    const diffInMs = endDate.getTime() - startDate.getTime();
    return diffInMs / millisecondsPerYear;
  }
  return 0;
};

export type ProductDate = {
  expires?: string,
  futureValue?: string,
  value?: string,
  valueTo?: string,
};

/**
    example end date
    {
        expires: '2023-05-15T20:00:00.000+02:00';
        futureValue: '2023-05-17T00:00:00.000+02:00';
        value: '2023-05-16T00:00:00.000+02:00';
    }
*/
export const getProductDateDayDifference = (startDate: Date, endDate: ProductDate) => {
  if (!startDate || !endDate) return null;
  const { expires, value, futureValue, valueTo, futureValueTo } = endDate;
  if (!expires || !value || !futureValue) return null;

  // convert date strings to date objects first
  const valueCalculation = typeof valueTo !== 'undefined' ? valueTo : value;
  const futureValueCalculation = typeof futureValueTo !== 'undefined' ? futureValueTo : futureValue;
  const pExpires = new Date(expires);
  const calculatedValue = new Date(valueCalculation);
  const calculatedValueFuture = new Date(futureValueCalculation);
  let evaluatedDate;

  // first check value if its not expired
  if (startDate.getTime() < pExpires.getTime()) {
    evaluatedDate = calculatedValue;
  } else {
    // if expired, check future value
    evaluatedDate = calculatedValueFuture;
  }

  startDate.setHours(0, 0, 0, 0);
  const dayDifference = getDayDifference(startDate, evaluatedDate);

  return {
    dayDifference,
    evaluatedDate,
  };
};

/**
    example date:
    {
        expires: '2023-05-15T20:00:00.000+02:00';
        futureValue: '2023-05-17T00:00:00.000+02:00';
        value: '2023-05-16T00:00:00.000+02:00';
    }
*/
export const getProductDateDayDifferenceFromNow = (endDate: ProductDate) => {
  const { expires, value, futureValue } = endDate;
  if (!expires || !value || !futureValue) return null;
  const nowDate = new Date();
  return getProductDateDayDifference(nowDate, endDate).dayDifference;
};

/**
    example date:
    endDateString: '2023-05-15T20:00:00.000+02:00'
*/
export const getProductDateYearDifferenceFromNow = (endDateString: string) => {
  if (!endDateString) return null;
  const nowDate = new Date();
  const endDate = new Date(endDateString);
  return getYearDifference(nowDate, endDate);
};
