import axiosLibrary, { CancelToken } from 'axios';
import saveAs from 'file-saver';
import queryString from 'query-string';

import UserAPI from 'api/UserAPI/UserAPI';
import { getLocale } from 'constants/language/language';
import { Cache } from 'constants/cache/cache';

import { resources } from 'constants/restResources/restResources';
import { getPricingParameter } from 'constants/getPricingParameter/getPricingParameter';

import getCookieStorage from 'constants/storage/cookie';
import { B2B_PRICE_GROUP } from 'constants/storage/storageKeys';
import { noop } from 'constants/noop/noop';

const axiosDefaults = {
  timeout: 12000,
  headers: {
    Accept: 'application/json',
  },
};

let axios = axiosLibrary.create(axiosDefaults);

if (!__CLIENT__) {
  import('./ServersideAxios.js')
    .then((ssrAxios) => (axios = ssrAxios.default))
    .catch((err) => {
      console.log('FAILED to import SSR Axios: ', err);
    });
}

const cookie = getCookieStorage();

/**
 * Adds a pricing parameter for every b2b customer so that customer specific prices can be displayed
 * at the storefront. Has no effect on anonymous or b2c customers.
 * @param path - original path
 * @return {String} - adjusted path
 */
const appendPricingParameter = (path) => {
  let newPath = path;

  if (__CLIENT__) {
    const b2bGroup = cookie.getItem(B2B_PRICE_GROUP);
    if (b2bGroup) {
      newPath = getPricingParameter(path, b2bGroup);
    }
  }

  return newPath;
};

const getHttpHeaders = (contentType = 'application/json') => {
  const accessToken = UserAPI.loadAccessToken();

  if (accessToken) {
    return {
      withCredentials: true,
      params: {
        lang: getLocale(),
      },
      headers: {
        'Content-Type': contentType,
        Authorization: `Bearer ${accessToken}`,
      },
    };
  }

  return {
    params: {
      lang: getLocale(),
    },
  };
};

// todo. find another solution for pdf headers
const getPdfHeader = () => {
  const accessToken = UserAPI.loadAccessToken();

  if (accessToken) {
    return {
      params: {
        lang: getLocale(),
      },
      headers: {
        Accept: 'application/pdf',
        Authorization: `Bearer${accessToken}`,
      },
      responseType: 'arraybuffer',
    };
  }

  return {
    headers: {
      Accept: 'application/pdf',
    },
    responseType: 'arraybuffer',
  };
};

const handleError = (error, path) => {
  const invalidHttpStatusCodes = [401, 403];

  if (
    error?.response?.data?.errors?.[0]?.type === 'InvalidTokenError' ||
    (invalidHttpStatusCodes.indexOf(error?.response?.status) !== -1 &&
      !path.includes('forgottenpasswordtokens') &&
      !path.includes('api/orders'))
  ) {
    UserAPI.refreshAccessToken();
    throw error;
  }

  throw error;
};

const get = (resource, tail, withHttpHeaders = true, { cacheInSec, refreshCache } = { cacheInSec: 0 }) => {
  const path = appendPricingParameter(`${resources[resource]}${tail}`);
  const config = withHttpHeaders ? getHttpHeaders() : {};

  const cacheKey = `${path}?${queryString.stringify(config?.params)}`;
  const cachedResponse = refreshCache ? undefined : Cache.get(cacheKey);
  if (cachedResponse) {
    return Promise.resolve(cachedResponse);
  }

  return axios
    .get(path, config)
    .then((response) => {
      if (cacheInSec > 0) {
        Cache.set(cacheKey, response, cacheInSec);
      }

      return response;
    })
    .catch((error) => handleError(error, path));
};

const getCancelable = (resource, tail) => {
  let cancel = noop;
  const path = appendPricingParameter(`${resources[resource]}${tail}`);

  const promise = axios
    .get(path, {
      params: {
        lang: getLocale(),
      },
      cancelToken: new CancelToken((c) => {
        cancel = c;
      }),
      ...getHttpHeaders(),
    })
    .catch((error) => handleError(error, path));

  return {
    promise,
    cancel,
  };
};

const getPdf = (resource, tail, pdfName, shouldPrint = false) => {
  const path = `${resources[resource]}${tail}`;

  return axios
    .get(path, getPdfHeader())
    .then((response) => {
      const blob = new Blob([response.data], { type: 'application/pdf' });
      if (shouldPrint) {
        printBlob(blob);
      } else {
        saveAs(blob, pdfName);
      }
      return Promise.resolve(blob);
    })
    .catch((error) => handleError(error, path));
};

// print PDFs received as blobs by integrating them in a invisible iframe
const printBlob = (blob) => {
  const iframe = document.createElement('iframe');
  document.body.appendChild(iframe);
  const blobURL = URL.createObjectURL(blob);
  iframe.style.display = 'none';
  iframe.src = blobURL;
  iframe.onload = function () {
    setTimeout(function () {
      iframe.focus();
      iframe.contentWindow.print();
    }, 1);
  };
};

const postTokenless = (resource, tail, data, contentType) => {
  const path = `${resources[resource]}${tail}`;

  return axios
    .post(path, data, {
      params: {
        lang: getLocale(),
      },
      headers: {
        'Content-Type': contentType,
      },
    })
    .catch((error) => handleError(error, path));
};

const post = (resource, tail, data, contentType, additionalHeaders) => {
  const path = `${resources[resource]}${tail}`;

  const params = getHttpHeaders(contentType);
  if (additionalHeaders) {
    params.headers = {
      ...params.headers,
      ...additionalHeaders,
    };
  }

  return axios.post(path, data, params).catch((error) => handleError(error, path));
};

const put = (resource, tail, data, contentType) => {
  const path = `${resources[resource]}${tail}`;

  return axios.put(path, data, getHttpHeaders(contentType)).catch((error) => handleError(error, path));
};

const patch = (resource, tail, data) => {
  const path = `${resources[resource]}${tail}`;

  return axios.patch(path, data, getHttpHeaders()).catch((error) => handleError(error, path));
};

const del = (resource, tail) => {
  const path = `${resources[resource]}${tail}`;

  return axios.delete(path, getHttpHeaders()).catch((error) => handleError(error, path));
};

const interceptors = axios.interceptors;

export default {
  get,
  getCancelable,
  getPdf,
  postTokenless,
  post,
  put,
  patch,
  del,
  interceptors,
};
