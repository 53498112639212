import { postLog } from 'api/promiseMiddleware';

export const keepTrying = (isValueReady = () => {}, ready = () => {}, timeOut = 500, maxTries = 10) => {
  try {
    if (maxTries === 0) {
      ready();
      throw new Error(
        'Run into a time out. ' +
          'This means the isValueReady function never returned true. ' +
          'Expand this error message to see the origin of this function call.'
      );
    }
    if (isValueReady()) {
      ready();
    } else {
      setTimeout(() => {
        keepTrying(isValueReady, ready, timeOut, maxTries - 1);
      }, timeOut);
    }
  } catch (error) {
    postLog(error);
    throw error;
  }
};

export default keepTrying;
