import { Component, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import productLinkConfiguration from 'constants/productLinkConfiguration/productLinkConfiguration';
import { trackNavigationElements } from 'actions/trackingActions/trackingActions';

const mapStateToProps = (state, ownProps) => ({
  referenceItemCodes: state.products[ownProps.productCode].referenceItemCodes,
  classifications: state?.products?.[ownProps?.productCode]?.classifications || [],
  downloads: state?.products?.[ownProps?.productCode]?.documentDownloads || [],
  blogPosts: state?.cms?.blogs?.[ownProps?.productCode]?.posts || [],
});

const mapDispatchToProps = {
  trackNavElements: trackNavigationElements,
};

class ProductAnchor extends Component {
  static displayName = 'containers/ProductAnchor';

  static propTypes = {
    children: PropTypes.node.isRequired,
    productCode: PropTypes.string,
    referenceItemCodes: PropTypes.array,
    classifications: PropTypes.array,
    downloads: PropTypes.array,
    productLinks: PropTypes.array,
    blogPosts: PropTypes.array,
    trackNavElements: PropTypes.func,
  };

  static defaultProps = {
    productCode: '',
    referenceItemCodes: [],
    productLinks: [],
    trackNavElements: () => {},
  };

  render() {
    const {
      children,
      referenceItemCodes,
      productLinks,
      classifications,
      downloads,
      blogPosts,
      trackNavElements,
    } = this.props;
    const productLinkConfig = productLinkConfiguration();
    const anchorLinks = [...productLinks];

    if (classifications.length) {
      anchorLinks.push(productLinkConfig.classifications);
    }

    if (downloads.length) {
      anchorLinks.push(productLinkConfig.downloads);
    }

    if (referenceItemCodes.length) {
      anchorLinks.push(productLinkConfig.references);
    }

    // always add rating section
    anchorLinks.push(productLinkConfig.ratings);

    if (blogPosts.length) {
      anchorLinks.push(productLinkConfig.blogPost);
    }

    return cloneElement(children, {
      links: anchorLinks,
      tracking: trackNavElements,
    });
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductAnchor);
