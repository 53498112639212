import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { getCategoryMapByPriority2, getCategoryUrl } from 'constants/categoryTree/categoryTree';
import styles from './ProductNavigation.scss';
import MobileNavigationItem from 'components/atoms/MobileNavigationItem/MobileNavigationItem';
import { getLocale } from 'constants/language/language';
import { NAVIGATION_CLICK } from 'constants/trackingAttributes/trackingAttributes';
import { trackMobileNavigationGoTo } from 'tracking/tracking';
import { sanitizeAndEncode } from 'constants/sanitizeAndEncode/sanitizeAndEncode';

import { browserHistory } from 'react-router';

const ProductNavigationItems = ({ categories = {}, lastLevel = false, parent = '1', title }) => {
  const locale = getLocale();

  return (
    <ul className={cx(styles.list, { [styles.lastLevel]: lastLevel })} data-cy="homepageCategories">
      {getCategoryMapByPriority2(categories, { parent }).map((category, index) => (
        <MobileNavigationItem
          key={index}
          onClick={() => {
            trackMobileNavigationGoTo(NAVIGATION_CLICK, index + 1, categories?.[category.id]?.[locale], title);
            browserHistory.push(
              category.id !== 'CATEGORY_OFFERS' ? getCategoryUrl(category.id, locale, categories) : '#'
            );
          }}
          dataCy={sanitizeAndEncode(categories?.[category.id]?.[locale])}
        >
          {(categories?.[category.id])[locale]}
        </MobileNavigationItem>
      ))}
    </ul>
  );
};

ProductNavigationItems.displayName = 'molecules/ProductNavigationItems';
ProductNavigationItems.propTypes = {
  parent: PropTypes.string,
  onHover: PropTypes.func,
  noIcons: PropTypes.bool,
  activeParent: PropTypes.string,
  lastLevel: PropTypes.bool,
  onMouseLeave: PropTypes.func,
  categories: PropTypes.object,
  title: PropTypes.string,
  cyData: PropTypes.string,
};

export default ProductNavigationItems;
