import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import language from 'constants/language/language';
import FooterRowProduct from './FooterRowProduct';
import Headline from 'components/atoms/Headline/Headline';
import FooterColumn from 'components/molecules/FooterColumn/FooterColumn';
import bootstrap from 'scss/component.scss';

const FooterColumnProducts = ({ columnOffset, categories }) => (
  <FooterColumn
    headline={language('footer.products')}
    headlineTag={Headline.tags.H4}
    additionalClassNames={cx(bootstrap.offsetLg0, bootstrap.clearfix, bootstrap.hiddenPrint)}
    columnOffset={columnOffset}
  >
    <div>
      <FooterRowProduct categories={categories} />
    </div>
  </FooterColumn>
);

FooterColumnProducts.propTypes = {
  columnOffset: PropTypes.number,
  categories: PropTypes.object,
};

FooterColumnProducts.defaultProps = {
  columnOffset: 2,
  categories: {},
};

FooterColumnProducts.displayName = 'molecules/FooterColumnProducts';

export default FooterColumnProducts;
