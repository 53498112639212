import { ProductViewTypes } from 'constants/ProductViewTypes/ProductViewTypes';
import { useFeatureToggle, useGoogleOptimize } from 'hooks';
import { useReadPersistenceStore } from 'hooks/usePersistenceStore';

// This is used to get the initial view type that should be shown in the A/B Test (SWAT-4383)
const useGoogleViewType = () => {
  const googleOptimizeKeyMobile = useFeatureToggle('abTestListView');
  const { variant: currentVariant } = useGoogleOptimize(googleOptimizeKeyMobile);

  if (currentVariant === 0) {
    return ProductViewTypes.GRID;
  } else if (currentVariant === 1) {
    return ProductViewTypes.LIST;
  }
};

const useStorageViewType = () => useReadPersistenceStore({ key: 'ui.viewType' });

const useInitialViewType = () => {
  const initialGoogleViewType = useGoogleViewType();
  const initialStorageViewType = useStorageViewType();
  return initialStorageViewType ?? initialGoogleViewType;
};

export default useInitialViewType;
