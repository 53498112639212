import React from 'react';
import PropTypes from 'prop-types';
import getFeatureValues from 'constants/getFeatureValues/getFeatureValues';
import styles from './ProductFeatureTable.scss';

const ProductFeatureRow = ({ name, values, unit, isRange }) => (
  <tr className={styles.row}>
    <th className={styles.key}>{name}</th>
    <td className={styles.value}>
      {getFeatureValues(values, isRange)} {unit}
    </td>
  </tr>
);

ProductFeatureRow.propTypes = {
  name: PropTypes.string.isRequired,
  unit: PropTypes.string,
  values: PropTypes.array.isRequired,
  isRange: PropTypes.bool,
};

ProductFeatureRow.displayName = 'molecules/ProductFeatureRow';

export default ProductFeatureRow;
