import React from 'react';
import PropTypes from 'prop-types';

import Headline from 'components/atoms/Headline/Headline';
import Markdown from 'components/atoms/Markdown/Markdown';
import { Modal } from '@interdiscount/component-library-interdiscount';

import { getEnv } from 'config/config';

import styles from './ModalPromotion.scss';

const ModalPromotion = ({ title = '', text = '', url = '', closeModal = () => {} }) => (
  <Modal onRequestClose={closeModal} isOpen>
    <Headline tag={Headline.tags.H3}>{title}</Headline>
    <div className={styles.content}>
      {url && (
        <div className={styles.imageWrapper}>
          <img className={styles.image} src={`${getEnv('web')}${url}`} alt={title} />
        </div>
      )}
      <div className={styles.textWrapper}>
        <Markdown>{text}</Markdown>
      </div>
    </div>
  </Modal>
);

ModalPromotion.displayName = 'molecules/ModalPromotion';
ModalPromotion.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  url: PropTypes.string,
  closeModal: PropTypes.func,
};

export default ModalPromotion;
