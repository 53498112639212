import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/atoms/Image/Image';
import Icon, { ICON_CHECK } from 'components/atoms/Icon/Icon';
import Headline, { tags } from 'components/atoms/Headline/Headline';
import styles from './AddedProduct.scss';

function AddedProduct({ product = {}, icon = ICON_CHECK }) {
  const { customImageData, name } = product;
  return (
    <div className={styles.addedProduct}>
      <Image
        className={styles.productImage}
        images={customImageData?.[0]?.sizes}
        code={customImageData?.[0]?.code}
        aspectRatio={0}
        alt={name}
      />

      <div className={styles.productName}>
        <Headline tag={tags.H3}>{name}</Headline>
      </div>
      <Icon className={styles.icon} path={icon} />
    </div>
  );
}

AddedProduct.displayName = 'organisms/ServicesAndAccessories/AddedProduct';
AddedProduct.propTypes = {
  product: PropTypes.object,
  icon: PropTypes.string,
};

export default AddedProduct;
