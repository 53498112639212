export const mobileProtectionOneYear = 'ZHPV';
export const mobileProtectionTwoYears = 'ZHPG';
export const warantyTwoYears = 'ZGDL';

export const extendWarantyGroup = '01_garantieplus';
export const mobileProtectionGroup = '02_mobileProtection';

export const isMobileProtectionService = (serviceCode) =>
  [mobileProtectionOneYear, mobileProtectionTwoYears].includes(serviceCode);

export const getMobileProtectionServices = (services = []) =>
  services.filter((service) => service.groupCode === mobileProtectionGroup);

export const getWarantyServices = (services = []) =>
  services.filter((service) => service.groupCode === extendWarantyGroup);
