import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CmsCurrentPageIdContainer from 'containers/CmsCurrentPageIdContainer/CmsCurrentPageIdContainer';
import HomePageTemplate from 'components/templates/HomePage/HomePage';
import { getCategoryMapByPriority } from 'constants/categoryTree/categoryTree';
import { useFeatureToggle } from 'hooks/index';

import { getPageContentNeed, getWallContentNeed } from 'actions/cmsActions/cmsActions';

const mapStateToProps = (state) => {
  const categories = state?.categories || {};
  const categoryCount = getCategoryMapByPriority(categories).length;
  return {
    categoryCount,
  };
};

const HomePage = ({ categoryCount }) => {
  const nextHomepageEnabled = useFeatureToggle('nextHomepageEnabled') === 'true' ?? false;

  if (__CLIENT__ && nextHomepageEnabled) {
    window.location.reload();
    return <></>;
  }

  return (
    <CmsCurrentPageIdContainer>
      <HomePageTemplate categoryCount={categoryCount} />
    </CmsCurrentPageIdContainer>
  );
};

HomePage.displayName = 'routes/Homepage';

HomePage.propTypes = {
  categoryCount: PropTypes.number,
};

HomePage.need = [getPageContentNeed, getWallContentNeed];

export default connect(mapStateToProps)(HomePage);
