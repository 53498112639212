import { handleActions } from 'redux-actions';
import has from 'constants/helper/has';
import pull from 'constants/helper/pull';
import omit from 'constants/helper/omit';
import transform from 'constants/reducerHelper/reducerHelper';
import getStorage from 'constants/storage/storage';
const localStorage = getStorage(true);
import { apiStatus as API_STATUS } from 'constants/apiStatus/apiStatus';

import {
  CHANGE_COMPARISON_CATEGORY,
  ADD_PRODUCT_TO_COMPARISON,
  REMOVE_PRODUCT_FROM_COMPARISON,
  GET_PRODUCT_COMPARISON_SHORT_SUCCESS,
  GET_PRODUCT_COMPARISON_SHORT_REQUEST,
  GET_PRODUCT_COMPARISON_SHORT_FAILURE,
  GET_PRODUCT_COMPARISON_SHORT_NETWORKERROR,
  GET_PRODUCT_COMPARISON_FULL_SUCCESS,
  GET_PRODUCT_COMPARISON_FULL_REQUEST,
  GET_PRODUCT_COMPARISON_FULL_FAILURE,
  GET_PRODUCT_COMPARISON_FULL_NETWORKERROR,
  ROUTER_UPDATE_LOCATION,
  CLOSE_COMPARISON_HEADER,
} from 'constants/ActionTypes/ActionTypes';

import { removeProductFromStorage } from 'constants/updateComparisonStorage/updateComparisonStorage';
import { getProductsWithNewestProductPriceData } from 'constants/price/price';

export const initialState = {
  selectedCategory: '',
  categories: {},
};

export default handleActions(
  {
    [CHANGE_COMPARISON_CATEGORY]: (state, action) => {
      const categoryCode = action.payload?.categoryCode || '';
      if (!categoryCode) {
        return state;
      }

      const newState = transform(state).set('selectedCategory', categoryCode);

      return newState.value();
    },

    [ADD_PRODUCT_TO_COMPARISON]: (state, action) => {
      const newState = transform(state);
      const categoryCode = action.payload?.categoryCode || '';
      const productCode = action.payload?.productCode || '';
      const categories = state?.categories?.[categoryCode] || [];

      if (!categoryCode || !productCode) {
        return newState.value();
      }

      if (categories.length >= 3) {
        newState.set('selectedCategory', categoryCode);
        newState.set('hasError', true);

        // Should scroll to top if hasError is true cause ProductComparisonHeader is not sticky anymore
        if (__CLIENT__) window.scrollTo({ top: 0, left: 0, duration: 500, behavior: 'smooth' });
      } else {
        if (!categories.includes(productCode)) {
          categories.push(productCode);
          newState.set(`categories[${categoryCode}]`, categories);
          newState.set('selectedCategory', categoryCode);
        }
        newState.set('hasError', false);
      }

      return newState.value();
    },

    [REMOVE_PRODUCT_FROM_COMPARISON]: (state, action) => {
      const newState = transform(state);
      const categoryCode = action.payload?.categoryCode || '';
      const productCode = action.payload?.productCode || '';
      const categories = state?.categories || [];

      if (!categoryCode || !productCode) {
        return newState.value();
      }

      let prodsInCategory = categories?.[categoryCode] || [];

      if (!has(prodsInCategory, JSON.stringify(productCode))) {
        prodsInCategory = pull(prodsInCategory, productCode);
      }
      newState.set('selectedCategory', categoryCode);

      if (!prodsInCategory.length) {
        const omittedCats = omit(categories, [categoryCode]);
        newState.set('categories', omittedCats);

        if (!Object.keys(omittedCats || {})?.length) {
          newState.set('list', {});
        } else {
          newState.set('selectedCategory', Object.keys(omittedCats)[0]);
        }
      } else {
        newState.set(`categories[${categoryCode}]`, prodsInCategory);
      }

      return newState.set('hasError', false).value();
    },

    [GET_PRODUCT_COMPARISON_SHORT_SUCCESS]: (state, action) => {
      const newState = transform(state);
      let products = action.req?.data?.products || [];

      // Get newest productPriceData and transform prices
      products = getProductsWithNewestProductPriceData(state, products);

      return newState.set('products', products).set('apiStatus', API_STATUS.success).value();
    },

    [GET_PRODUCT_COMPARISON_SHORT_REQUEST]: (state) => transform(state).set('apiStatus', API_STATUS.request).value(),

    [GET_PRODUCT_COMPARISON_SHORT_FAILURE]: (state) => transform(state).set('apiStatus', API_STATUS.failure).value(),

    [GET_PRODUCT_COMPARISON_SHORT_NETWORKERROR]: (state) =>
      transform(state).set('apiStatus', API_STATUS.networkerror).value(),

    [GET_PRODUCT_COMPARISON_FULL_SUCCESS]: (state, action) => {
      const newState = transform(state);
      const classifications = action.req?.data?.classifications || [];
      let products = action.req?.data?.products?.products || [];

      // Get newest productPriceData and transform prices
      products = getProductsWithNewestProductPriceData(state, products);

      const categoryCode = action.payload?.categoryCode || '';
      let productCodes = action.payload?.productCodes || '';
      productCodes = productCodes.length ? productCodes.split(',') : [];

      productCodes.forEach((code) => {
        if (!products.find((prod) => prod.code === code)) {
          removeProductFromStorage(categoryCode, code);
        }
      });

      newState.set('list.classifications', classifications);

      if (products.length) {
        newState.set('products', products);
      }

      return newState.set('apiStatus', API_STATUS.success).value();
    },

    [GET_PRODUCT_COMPARISON_FULL_REQUEST]: (state) => transform(state).set('apiStatus', API_STATUS.request).value(),

    [GET_PRODUCT_COMPARISON_FULL_FAILURE]: (state) => transform(state).set('apiStatus', API_STATUS.failure).value(),

    [GET_PRODUCT_COMPARISON_FULL_NETWORKERROR]: (state) =>
      transform(state).set('apiStatus', API_STATUS.networkerror).value(),

    [ROUTER_UPDATE_LOCATION]: (state, action) => {
      const newState = transform(state);
      const { pathname } = action.payload;

      // set comparison list in store only when list in localStorage is not empty
      const list = JSON.parse(localStorage.getItem('comparisonList'));
      const categories = list?.categories || {};

      if (Object.keys(categories).length) {
        newState.set('categories', categories);
        if (has(state.categories, state.selectedCategory)) {
          newState.set('selectedCategory', state?.selectedCategory || '');
        } else {
          newState.set('selectedCategory', Object.keys(categories)[0]);
        }
      }

      const regex = /--c\d{6}/g;
      if (pathname.match(regex)) {
        const categoryCode = pathname.split('--c')[1].split('/')[0].replace('/', '').trim();
        newState.set('selectedCategory', categoryCode);
      } else {
        newState.set('hasError', false);
      }

      return newState.value();
    },

    [CLOSE_COMPARISON_HEADER]: (state) => transform(state).set('hasError', false).value(),
  },
  initialState
);
