import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import LazyLoad from 'react-lazyload';
import { getEnv } from 'config/config';
import { layouts, colors } from 'constants/cmsImageBanner/cmsImageBanner';
import { isIE11 } from 'constants/userAgent/userAgent';
import BannerFlag from 'components/atoms/BannerFlag/BannerFlag';
import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';
import LogoBrand from 'components/atoms/LogoBrand/LogoBrand';
import Headline from 'components/atoms/Headline/Headline';
import Markdown from 'components/atoms/Markdown/Markdown';
import InternalOrExternalLink from 'components/atoms/InternalOrExternalLink/InternalOrExternalLink';
import bootstrap from 'scss/component.scss';
import styles from './CmsImageBanner.scss';

const STYLE_COLORS = {
  [colors.GREEN]: styles.GREEN,
  [colors.YELLOW]: styles.YELLOW,
  [colors.ORANGE]: styles.ORANGE,
  [colors.RED]: styles.RED,
  [colors.MAGENTA]: styles.MAGENTA,
  [colors.VIOLET]: styles.VIOLET,
  [colors.BLUE]: styles.BLUE,
  [colors.LIGHT_BLUE]: styles.LIGHT_BLUE,
  [colors.DARK_BLUE]: styles.DARK_BLUE,
  [colors.BLACK]: styles.BLACK,
  [colors.LIGHT_GRAY]: styles.LIGHT_GRAY,
};

export const buttonSkins = {
  STANDARD: ButtonLink.varieties.flat,
  GHOST: ButtonLink.varieties.stroked,
};

const CmsImageBanner = ({
  backgroundColor = colors.LIGHT_GRAY,
  brandLogo = '',
  brandLogoAltText = '',
  brandPattern = false,
  button = {},
  content = '',
  isHeadline = false,
  imageUrl = '',
  imageAltText = '',
  label = {},
  layout = layouts.DEFAULT,
  title = '',
  mobileImageUrl = '',
  link = '',
}) => {
  const mobileImage = mobileImageUrl ? mobileImageUrl : imageUrl;

  const renderContent = () => (
    <div
      className={cx(styles.text, {
        [styles.reducedMargin]: styles.reducedMargin && brandLogo,
      })}
    >
      <div className={styles.restrictedWidth}>
        <Headline tag={isHeadline ? Headline.tags.H1 : Headline.tags.H2} title={title} margin={Headline.margins.NONE}>
          <Markdown>{title}</Markdown>
        </Headline>
      </div>
      <Markdown>{content}</Markdown>
      {!!Object.keys(button).length && (
        <ButtonLink
          href={button.link}
          openNewTab={button.externalLink}
          variety={(button.format && buttonSkins[button.format]) || buttonSkins.GHOST}
          color={button.format === 'STANDARD' ? ButtonLink.colors.red : ButtonLink.colors.black}
        >
          {button.text}
        </ButtonLink>
      )}
    </div>
  );

  const renderBrandLogo = () =>
    brandLogo && (
      <LazyLoad height={30} once>
        <LogoBrand
          className={styles.brandLogo}
          path={`${getEnv('web')}${brandLogo}`}
          banner
          layout={layout}
          altText={brandLogoAltText}
        />
      </LazyLoad>
    );

  const renderImage = (mobileUrl, desktopUrl, classname) => (
    <picture className={styles.backgroundImage}>
      <source media="(max-width:768px)" srcSet={mobileUrl} alt={imageAltText} />
      <img className={classname} src={desktopUrl} alt={imageAltText} />
    </picture>
  );

  return (
    <div
      className={cx(bootstrap.clearfix, styles.container, styles[layout], STYLE_COLORS[backgroundColor], {
        [bootstrap.brandPattern]: brandPattern,
      })}
    >
      {(layout === layouts.IMAGE || layout === layouts.DEFAULT) && (
        <div className={cx(styles.wrapper, { [styles.gradient]: layout === layouts.DEFAULT })}>
          {link ? (
            <InternalOrExternalLink href={link}>
              {renderImage(`${getEnv('web')}${mobileImage}`, `${getEnv('web')}${imageUrl}`, styles.backgroundImage)}
            </InternalOrExternalLink>
          ) : (
            renderImage(`${getEnv('web')}${mobileImage}`, `${getEnv('web')}${imageUrl}`, styles.backgroundImage)
          )}
          <div
            className={cx({
              [styles.bgImageWrapper]: layout === layouts.DEFAULT,
            })}
          >
            <div className={styles.contentWrapper}>
              {renderBrandLogo()}
              {layout === layouts.DEFAULT && renderContent()}
            </div>
          </div>
        </div>
      )}
      {layout !== layouts.IMAGE && layout !== layouts.DEFAULT && (
        <div className={cx(styles.wrapper)}>
          {brandPattern && <div className={cx(bootstrap.brandPattern, styles.pattern)} />}
          {!!Object.keys(label)?.length && (
            <BannerFlag className={styles.flag} color={label.color} title={label.title} text={label.text} />
          )}
          {imageUrl && layout === layouts.SPLIT_FULL && (
            <LazyLoad height={350} once>
              {!isIE11() ? (
                link ? (
                  <InternalOrExternalLink href={link}>
                    {renderImage(`${getEnv('web')}${mobileImage}`, `${getEnv('web')}${imageUrl}`, styles.imageWrapper)}
                  </InternalOrExternalLink>
                ) : (
                  renderImage(`${getEnv('web')}${mobileImage}`, `${getEnv('web')}${imageUrl}`, styles.imageWrapper)
                )
              ) : (
                <div
                  className={styles.bgImageWrapper}
                  style={{ backgroundImage: `url(${getEnv('web')}${imageUrl})` }}
                  aria-label={imageAltText}
                />
              )}
            </LazyLoad>
          )}
          {imageUrl && layout === layouts.SPLIT && (
            <LazyLoad height={350} once>
              {link ? (
                <InternalOrExternalLink href={link}>
                  {renderImage(`${getEnv('web')}${mobileImage}`, `${getEnv('web')}${imageUrl}`, styles.imageWrapper)}
                </InternalOrExternalLink>
              ) : (
                renderImage(`${getEnv('web')}${mobileImage}`, `${getEnv('web')}${imageUrl}`, styles.imageWrapper)
              )}
            </LazyLoad>
          )}
          {renderBrandLogo()}
          {renderContent()}
        </div>
      )}
    </div>
  );
};

CmsImageBanner.displayName = 'organisms/CmsImageBanner';
CmsImageBanner.propTypes = {
  backgroundColor: PropTypes.oneOf(Object.values(colors)),
  brandLogo: PropTypes.string,
  brandPattern: PropTypes.bool,
  button: PropTypes.object,
  content: PropTypes.string,
  isHeadline: PropTypes.bool,
  imageUrl: PropTypes.string,
  label: PropTypes.object,
  layout: PropTypes.oneOf(Object.values(layouts)),
  title: PropTypes.string,
  brandLogoAltText: PropTypes.string,
  imageAltText: PropTypes.string,
  mobileImageUrl: PropTypes.string,
  link: PropTypes.string,
};

export default CmsImageBanner;
