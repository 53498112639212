import DrilldownAccordion from 'components/atoms/DrilldownAccordion/DrilldownAccordion';
import language, { getLocale } from 'constants/language/language';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import CategoryDrilldownContainer from 'containers/CategoryDrilldownContainer/CategoryDrilldownContainer';
import CategoryDrilldown from 'components/molecules/CategoryDrilldown/CategoryDrilldown';
import MobileNavigationItem from 'components/atoms/MobileNavigationItem/MobileNavigationItem';

import { getCategoryMapByPriority2 } from 'constants/categoryTree/categoryTree';
import { cypressAttributes } from 'constants/cypress/cypress';
import { NAVIGATION_SUB } from 'constants/trackingAttributes/trackingAttributes';
import { sanitizeAndEncode } from 'constants/sanitizeAndEncode/sanitizeAndEncode';
import { NEW_PRODUCT_URL_REGEX, OLD_PRODUCT_URL_REGEX } from 'constants/urlHelper/urlHelper';

const CategorySection = ({
  categories,
  drilldownPortal,
  handleAnimation,
  categoryTree,
  categoryCode,
  route,
  currentCategory,
  setCurrentCategory,
  isResetToStart,
}) => {
  const [canDrill, setCanDrill] = useState([]);
  const [highlightedItem, setHiglightedItem] = useState(null);
  const topLevelCategories = getCategoryMapByPriority2(categories, { parent: '1' });
  const isCategoryPage =
    !/search|cms|brand/.test(route) &&
    !NEW_PRODUCT_URL_REGEX.test(route) &&
    !OLD_PRODUCT_URL_REGEX.test(route) &&
    /--c/.test(route);
  const POSITION_OFFSET = 1;

  const resetNavigation = () => {
    setCurrentCategory(null);
  };

  const handleCategoryShowing = (code) => {
    const parentLevel = categories[code].level;
    const nextCategories = getCategoryMapByPriority2(categories, { level: parentLevel + 1, parent: code });
    if (categoryTree?.length > 0) {
      if (nextCategories?.length > 0) {
        if (parentLevel !== 1 && !categoryTree.includes(code)) categoryTree.push(code);
        setCurrentCategory(code);
        setHiglightedItem(null);
      } else {
        setCurrentCategory(categoryTree[categoryTree.length - 1]);
        setHiglightedItem(code);
      }
      if (categoryTree.includes('1')) categoryTree.shift();
    }
  };

  const handleTopLevelCategories = (category) => setCurrentCategory(category.id);

  useEffect(() => {
    if (categoryCode) handleCategoryShowing(categoryCode);
    if (!isCategoryPage) resetNavigation();
  }, [categoryCode]);

  return (
    <>
      {currentCategory && (
        <CategoryDrilldownContainer
          categories={categories}
          canDrill={canDrill}
          setCanDrill={setCanDrill}
          categoryCode={currentCategory}
          categoryTree={categoryTree}
          resetNavigation={resetNavigation}
        >
          <CategoryDrilldown
            portal={drilldownPortal}
            handleAnimation={handleAnimation}
            highlightedItem={highlightedItem}
          />
        </CategoryDrilldownContainer>
      )}
      <DrilldownAccordion
        cyData={`${cypressAttributes.mobileNavigation.navigationSection}-categories`}
        title={language('header.allProducts')}
        pos={1}
        isResetToStart={isResetToStart}
        resetNavigation={resetNavigation}
      >
        {topLevelCategories.map((category, i) => (
          <MobileNavigationItem
            dataCy={sanitizeAndEncode(categories[category.id]?.[getLocale()])}
            showIndicator
            key={category.id}
            onClick={() => handleTopLevelCategories(category)}
            handleAnimation={handleAnimation}
            tracking={{
              type: NAVIGATION_SUB,
              position: i + POSITION_OFFSET,
              title: categories[category.id]?.[getLocale()],
              parentTitle: language('header.allProducts'),
            }}
            featureName="nextCategoryEnabled"
          >
            {categories[category.id][getLocale()]}
          </MobileNavigationItem>
        ))}
      </DrilldownAccordion>
    </>
  );
};

export default CategorySection;

CategorySection.propTypes = {
  categories: PropTypes.object,
  drilldownPortal: PropTypes.string,
  handleAnimation: PropTypes.func,
  categoryTree: PropTypes.array,
  categoryCode: PropTypes.string,
  route: PropTypes.string,
  currentCategory: PropTypes.string,
  setCurrentCategory: PropTypes.func,
  isResetToStart: PropTypes.bool,
};

CategorySection.defaultProps = {
  categories: {},
  drilldownPortal: '',
};
