import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { recoTrackingEndpoints as recoTypes } from 'api/RecoAPI/RecoAPI';
import { getBrandSearchModule } from 'api/BrandAPI/BrandAPI';
import language from 'constants/language/language';
import { cypressAttributes } from 'constants/cypress/cypress';
import ProductRecoContainer from 'containers/ProductRecommendations/ProductRecommendations';
import { ProductViewTypes } from 'constants/ProductViewTypes/ProductViewTypes';
import { TOP_OF_PRODUCTS_LIST, TOP_OF_PRODUCTS_OVERVIEW } from 'constants/scrollToElement/scrollToElement';
import FacetsContainer from 'containers/FacetsContainer/FacetsContainer';
import { noop } from 'constants/noop/noop';
import Meta from 'containers/Meta/Meta';
import ProductComparisonContainer from 'containers/ProductComparison/ProductComparison';
import ProductListContainer from 'containers/ProductListContainer/ProductListContainer';
import Breadcrumb, { types } from 'components/organisms/Breadcrumb/Breadcrumb';
import ProductList from 'components/organisms/ProductList/ProductList';
import Pagination from 'components/molecules/Pagination/Pagination';
import ProductComparisonHeader from 'components/molecules/ProductComparisonHeader/ProductComparisonHeader';
import ProductRecoSlider from 'components/organisms/ProductRecoSlider/ProductRecoSlider';
import ProductListHeader from 'components/molecules/ProductListHeader/ProductListHeader';
import { Col, Row } from 'components/atoms/Grid/Grid';
import Headline from 'components/atoms/Headline/Headline';
import ProductsOverviewHeadline from 'components/atoms/ProductsOverviewHeadline/ProductsOverviewHeadline';
import ProductsOverviewConfig from './ProductsOverview.config';
import styles from './ProductsOverview.scss';
import NumberResults from 'components/atoms/NumberResults/NumberResults';
import FacetFilters from 'components/molecules/FacetFilters/FacetFilters';
import CategoryFacet from 'components/organisms/CategoryFacet/CategoryFacet';

const ProductsOverviewSearch = ({
  currentPage,
  numberOfPages = 1,
  visibleProducts = [],
  breadcrumb = [],
  totalNumberOfResults,
  searchString = '',
  spellingSuggestion = '',
  sorts = [],
  categoryCode = '',
  comparisonHasError = false,
  productViewType = ProductViewTypes.GRID,
  onSortChange = noop,
  originalSearchString = '',
}) => {
  const [brand, setBrand] = useState({});
  const [sliderReset, setSliderReset] = useState(false);

  useEffect(() => {
    const term = searchString.toLowerCase();

    getBrandSearchModule(term).then((response) => {
      if (term && response.data.length) {
        setBrand(response.data[0]);
      } else {
        setBrand({});
      }
    });

    setSliderReset(!sliderReset);
  }, [searchString]);

  const hasProducts = !!visibleProducts.length;

  return (
    <div id={TOP_OF_PRODUCTS_OVERVIEW} data-cy={cypressAttributes.product.productOverview}>
      <Meta
        title={ProductsOverviewConfig.getSearchTitle(breadcrumb, originalSearchString)}
        meta={[
          {
            name: 'robots',
            content: 'noindex,follow',
          },
        ]}
      />
      {__CLIENT__ && (categoryCode.length >= 6 || comparisonHasError) && (
        <ProductComparisonContainer>
          <ProductComparisonHeader selectedCategory={categoryCode} />
        </ProductComparisonContainer>
      )}
      <div>
        <Breadcrumb type={types.search} items={breadcrumb} hasProducts={hasProducts} />
      </div>

      <Row>
        <Col className={styles.headline}>
          <ProductsOverviewHeadline
            totalNumberOfResults={totalNumberOfResults}
            searchString={originalSearchString}
            spellingSuggestion={spellingSuggestion}
            marginBottom={Headline.margins.MB_20}
            headlineTag={Headline.tags.H1}
            brand={brand}
          />
          {totalNumberOfResults < 1 && (
            <div className={styles.subtitle} data-cy="spellingInfo">
              {language('productsOverview.noSearchResults.description')}
            </div>
          )}
        </Col>
      </Row>
      <Row className={styles.ProductsOverview}>
        {totalNumberOfResults > 0 && (
          <Col lg={3} className={styles.FilterContainer}>
            <FacetsContainer>
              <CategoryFacet breadcrumb={breadcrumb} />
            </FacetsContainer>
          </Col>
        )}
        <Col lg={9} className={styles.ProductsListContainer}>
          <section id={TOP_OF_PRODUCTS_LIST}>
            {totalNumberOfResults > 0 && (
              <>
                <NumberResults currentPage={currentPage} totalNumberOfResults={totalNumberOfResults} />

                <FacetsContainer>
                  <FacetFilters breadcrumb={breadcrumb} />
                </FacetsContainer>

                <ProductListHeader sorts={sorts} onSortChange={onSortChange} />

                <ProductListContainer>
                  <ProductList
                    products={visibleProducts}
                    viewType={productViewType}
                    currentPage={currentPage}
                    brand={brand}
                  />
                </ProductListContainer>
              </>
            )}
            <div className={styles.pagination}>
              <Pagination
                currentPage={currentPage}
                totalPages={numberOfPages}
                scrollToId={{ id: TOP_OF_PRODUCTS_LIST, duration: 0 }}
              />
            </div>
            {totalNumberOfResults > 0 && (
              <ProductRecoContainer
                params={{ searchterm: searchString }}
                type={recoTypes.SEARCH}
                sliderReset={sliderReset}
              >
                <ProductRecoSlider />
              </ProductRecoContainer>
            )}
          </section>
        </Col>
        {totalNumberOfResults < 1 && (
          <Col lg={12}>
            <ProductRecoContainer params={{ searchterm: searchString }} type={recoTypes.NULL_RESULT}>
              <ProductRecoSlider />
            </ProductRecoContainer>
          </Col>
        )}
      </Row>
    </div>
  );
};

ProductsOverviewSearch.displayName = 'template/ProductsOverviewSearch';
ProductsOverviewSearch.propTypes = {
  // all required, passed in via container routes/ProductsOverview
  breadcrumb: PropTypes.array,
  categoryCode: PropTypes.string,
  comparisonHasError: PropTypes.bool,
  currentPage: PropTypes.number,
  isFilterMenuOpen: PropTypes.bool,
  numberOfPages: PropTypes.number,
  productViewType: PropTypes.oneOf(Object.values(ProductViewTypes)),
  searchString: PropTypes.string,
  sorts: PropTypes.array,
  spellingSuggestion: PropTypes.string,
  totalNumberOfResults: PropTypes.number,
  visibleProducts: PropTypes.array,
  onSortChange: PropTypes.func,
  originalSearchString: PropTypes.string,
};

export default ProductsOverviewSearch;
