import React from 'react';
import PropTypes from 'prop-types';

import formatDate from 'constants/formatDate/formatDate';
import { getApprovalStatus, APPROVAL_STATUS } from 'constants/ratings/ratings';
import FlashMessage from 'components/atoms/FlashMessage/FlashMessage';
import { i18n } from 'constants/language/language';
import { types as flashMessageTypes } from 'constants/flashMessages/flashMessages';

const RatingStatusMessage = ({ status, shortMessage, date, rejectedMessage }) => {
  const message = shortMessage
    ? i18n(`productRating.editor.commentStatusShort.${getApprovalStatus(status)}`, [formatDate(date)])
    : i18n(`productRating.editor.commentStatus.${getApprovalStatus(status)}`, [formatDate(date)]);

  return (
    <FlashMessage type={status === APPROVAL_STATUS.OPEN ? flashMessageTypes.INFO : flashMessageTypes.WARNING}>
      {message}
      {status === APPROVAL_STATUS.REJECTED && rejectedMessage && (
        <>
          <br />
          <strong>{i18n('productRating.editor.commentRejectedReason')}: </strong>
          {rejectedMessage}
        </>
      )}
    </FlashMessage>
  );
};

RatingStatusMessage.propTypes = {
  status: PropTypes.string,
  shortMessage: PropTypes.bool,
  date: PropTypes.string,
  rejectedMessage: PropTypes.string,
};

export default RatingStatusMessage;
