import React from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

import MobileNavigationItem from 'components/atoms/MobileNavigationItem/MobileNavigationItem';
import { ICON_ARROW_HISTORY_BACK } from 'components/atoms/Icon/Icon';

import language from 'constants/language/language';
import useDrilldown from 'hooks/useDrilldown/useDrilldown';

import styles from 'components/molecules/DrilldownMenu/DrilldownMenu.scss';
import { NAVIGATION_SUB } from 'constants/trackingAttributes/trackingAttributes';
import { trackMobileNavigationBack } from 'tracking/tracking';

import cx from 'classnames';

const DrilldownMenu = ({ children, entries, portal, dataCy, title, parentTitle, handleAnimation, animationStyle }) => {
  const [isOpen, closeDrilldown, openDrilldown, currentMenu, menuBreadcrumb, drillToMenu] = useDrilldown(entries);

  const portalContainer = document.getElementById(portal);

  const handleClosingAccountMenu = () => {
    trackMobileNavigationBack(NAVIGATION_SUB, 1, language('mobileDrilldown.overview'), title);
    handleAnimation('initial');
    closeDrilldown();
  };

  if (isOpen) {
    return createPortal(
      <div data-cy={`${dataCy}`} className={cx(animationStyle === 'initial' ? styles.drilldownContainerWrapper : '')}>
        <MobileNavigationItem
          dataCy={`${dataCy}-close`}
          indicatorIcon={ICON_ARROW_HISTORY_BACK}
          showIndicator
          onClick={handleClosingAccountMenu}
        >
          {language('mobileDrilldown.overview')}
        </MobileNavigationItem>
        <div className={styles.drilldownBreadcrumb}>
          {menuBreadcrumb &&
            menuBreadcrumb.map((menu, index) => (
              <MobileNavigationItem
                key={menu.id}
                indicatorIcon={ICON_ARROW_HISTORY_BACK}
                disabled={menu.id === currentMenu.id}
                showIndicator={menu.id !== currentMenu.id}
                onClick={() => drillToMenu(menu)}
                tracking={{
                  type: NAVIGATION_SUB,
                  position: index + 1,
                  title: menu.title,
                  parentTitle: title,
                }}
              >
                {menu.id !== currentMenu.id ? menu.title : language('header.myAccount')}
              </MobileNavigationItem>
            ))}
        </div>
        {currentMenu &&
          currentMenu.submenu.map((entry, index) => {
            return (
              <MobileNavigationItem
                dataCy={`${dataCy}-item-${entry.id}`}
                key={entry.id}
                showIndicator={!!entry.submenu}
                href={entry.link}
                onClick={() => drillToMenu(entry)}
                tracking={{
                  type: NAVIGATION_SUB,
                  position: index + 1,
                  title: entry.title,
                  parentTitle: title,
                }}
              >
                {entry.title}
              </MobileNavigationItem>
            );
          })}
      </div>,
      portalContainer
    );
  }

  return (
    <MobileNavigationItem
      dataCy={`${dataCy}-item`}
      showIndicator
      onClick={() => {
        handleAnimation();
        openDrilldown();
      }}
      tracking={{ type: NAVIGATION_SUB, position: 1, title, parentTitle }}
    >
      {children}
    </MobileNavigationItem>
  );
};

export default DrilldownMenu;

DrilldownMenu.propTypes = {
  children: PropTypes.node,
  entries: PropTypes.object,
  portal: PropTypes.string.isRequired,
  dataCy: PropTypes.string,
  title: PropTypes.string,
  parentTitle: PropTypes.string,
  handleAnimation: PropTypes.func,
  animationStyle: PropTypes.string,
};

DrilldownMenu.defaultProps = {
  children: {},
  entries: [],
  dataCy: '',
};
