import React, { useState, useEffect } from 'react';
import { scroller } from 'react-scroll';
import PropTypes from 'prop-types';

import StepperStep from './StepperStep';
import StepperButtons from './StepperButtons';

const Stepper = ({ children, onComplete = () => {}, initialStep = 0, scrollToActiveStep = true, scrollOffset = 0 }) => {
  const [activeStep, setActiveStep] = useState(initialStep > 0 ? initialStep - 1 : initialStep);
  const lastStep = React.Children.count(children) - 1;

  useEffect(() => {
    setActiveStep(initialStep > 0 ? initialStep - 1 : initialStep);
  }, [initialStep]);

  const scrollToStep = (step) => {
    setTimeout(() => {
      scroller.scrollTo(`step-${step}`, {
        duration: 300,
        smooth: true,
        offset: scrollOffset,
      });
    }, 300);
  };

  const onStepClick = (step) => {
    if (scrollToActiveStep) scrollToStep(step);
    setActiveStep(step);
  };

  const onPrevClick = () => {
    if (scrollToActiveStep) scrollToStep(activeStep - 1);
    setActiveStep(activeStep - 1);
  };

  const onNextClick = () => {
    if (scrollToActiveStep) scrollToStep(activeStep + 1);
    setActiveStep(activeStep + 1);

    if (activeStep === lastStep && onComplete) {
      onComplete();
    }
  };

  return React.Children.map(children, (child, index) =>
    React.cloneElement(child, {
      name: `step-${index}`,
      stepNumber: index,
      lastStep,
      activeStep,
      onStepClick,
      onPrevClick,
      onNextClick,
    })
  );
};

Stepper.displayName = 'molecules/Stepper';

Stepper.propTypes = {
  children: PropTypes.node.isRequired,
  onComplete: PropTypes.func,
  initialStep: PropTypes.number,
  scrollToActiveStep: PropTypes.bool,
  scrollOffset: PropTypes.number,
};

export { StepperStep, StepperButtons };

export default Stepper;
