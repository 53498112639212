import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'constants/language/language';
import Headline from 'components/atoms/Headline/Headline';
import Row from 'components/atoms/Grid/Row';

import styles from './DangerousGoods.scss';

/**
 * Will load dangerous good pictograms svgs as sprite, which is better cacheable and is only loaded on demand.
 * THIS FILE WILL BE CACHED BY THE CDN FOR ONE YEAR!
 * To update the payment icons, simply rename the file (and it's link here) to something like
 * `dangerousGoodsV2.svg`
 *
 * @see https://benadam.me/thoughts/react-svg-sprites/
 *
 */
const DangerousGoods = ({ hazardPictograms = [] }) => (
  <>
    <Headline>{i18n('dangerousGoods.title')}</Headline>
    <Row className={styles.dangerousGoodsWrapper}>
      {hazardPictograms.map((entry) => (
        <div className={styles.dangerousGoods} key={entry}>
          <svg width="48pt" height="48pt">
            <use href={`/images/logos/dangerousGoods.svg#${entry}`} />
          </svg>
          <p className={styles.text}>{entry}</p>
        </div>
      ))}
    </Row>
  </>
);

DangerousGoods.propTypes = {
  hazardPictograms: PropTypes.array,
};

export default React.memo(DangerousGoods);
