export const layouts = {
  SPLIT: 'SPLIT',
  SPLIT_FULL: 'SPLIT_FULL',
  IMAGE: 'IMAGE',
  DEFAULT: 'DEFAULT',
};

export const colors = {
  GREEN: 'GREEN',
  YELLOW: 'YELLOW',
  ORANGE: 'ORANGE',
  RED: 'RED',
  MAGENTA: 'MAGENTA',
  VIOLET: 'VIOLET',
  BLUE: 'BLUE',
  LIGHT_BLUE: 'LIGHT_BLUE',
  DARK_BLUE: 'DARK_BLUE',
  BLACK: 'BLACK',
  LIGHT_GRAY: 'LIGHT_GRAY',
};
