import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import SvgContainer from 'components/atoms/SvgContainer/SvgContainer';
import styles from 'components/molecules/HeadLogo/HeadLogo.scss';

const MorphingLogoInterdiscountMedium = ({ height, width, vbXCoords, vbYCoords, className, altText = '' }) => (
  <SvgContainer
    height={height}
    width={width}
    vbWidth={168}
    vbHeight={40}
    vbXCoords={vbXCoords}
    vbYCoords={vbYCoords}
    className={cx({ [styles.sticky]: true }, className, styles.morphing)}
  >
    <g clipPath="url(#clip0_1656_12253)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M95.8584 20.5H91.6367V31.25H95.8584V25.5C96.4466 24.8453 96.5903 24.25 97.4726 24.25C98.3548 24.25 98.3418 25.1428 98.3418 25.5C98.3418 25.8572 98.3418 31.25 98.3418 31.25H102.564V24C102.564 21.857 101.087 20.5 99.0868 20.5C97.0873 20.5 96.3879 21.5238 95.8584 22V20.5Z"
        fill="#1D1D1B"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M0.248047 1.25H112V0H0.248047V1.25Z" fill="#E20615" />
      <path fillRule="evenodd" clipRule="evenodd" d="M0 40H111.752V32.5H0V40Z" fill="#E20615" />
      <path fillRule="evenodd" clipRule="evenodd" d="M29.5518 16.2507H34.2702V2.5H29.5518V16.2507Z" fill="#1D1D1B" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.2374 5.5H35.0156V16.25H39.2374V10.5C39.8255 9.84532 39.9692 9.25 40.8515 9.25C41.7337 9.25 41.7207 10.1428 41.7207 10.5C41.7207 10.8572 41.7207 16.25 41.7207 16.25H45.9425V9C45.9425 6.85701 44.4658 5.5 42.4657 5.5C40.4662 5.5 39.7668 6.52378 39.2374 7V5.5Z"
        fill="#1D1D1B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.1505 8.5H55.1306V5.5H52.1505V2.25H51.1572L46.1904 6.5V8.5H47.6805V13.25C47.6805 14.7977 48.9613 16.5 51.9022 16.5C53.6082 16.5 54.7907 15.8572 55.3789 15.5V12C54.9673 12.3572 54.2809 12.75 53.5164 12.75C52.7516 12.75 52.1505 12.5484 52.1505 12.0722V8.5Z"
        fill="#1D1D1B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.8494 9.75H62.0844C62.2013 8.98948 61.7287 8.25012 60.9669 8.25012C60.2053 8.25012 59.8494 8.87246 59.8494 9.75ZM66.0578 12.75V15.5C65.706 15.6754 64.2549 16.5 61.6185 16.5C58.0441 16.5 55.876 14.5106 55.876 10.9416C55.876 7.84042 57.751 5.5 61.0911 5.5C64.4308 5.5 66.3061 7.52128 66.3061 11.5H59.8494C59.8494 13.2553 61.6185 13.5745 62.673 13.5745C63.7277 13.5745 65.5888 12.984 66.0578 12.75Z"
        fill="#1D1D1B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66.8027 5.50011V16.2501H71.0245V12.0001C71.0245 10.9883 72.5 9.63104 73.441 9.63104C74.3823 9.63104 75.0262 9.9338 75.4945 10.2501L75.7429 6.50011C75.4484 5.96424 74.8066 5.41699 73.7476 5.41699C72.6892 5.41699 72.0242 5.83334 71.0245 7.50011V5.50011"
        fill="#1D1D1B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M108.772 23.5H111.752V20.5H108.772V17.25H107.778L102.812 21.5V23.5H104.302V28.25C104.302 29.7977 105.582 31.5 108.523 31.5C110.229 31.5 111.412 30.8572 112 30.5V27C111.588 27.3572 110.902 27.75 110.137 27.75C109.373 27.75 108.772 27.5484 108.772 27.0722V23.5Z"
        fill="#1D1D1B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.2702 21.0599V27.75H35.8028C37.2716 27.75 38.7402 26.4361 38.7402 24.3452C38.7402 22.2546 37.1439 21 35.739 21C34.3342 21 34.2702 21.0599 34.2702 21.0599ZM37.4733 31.2507H29.5518V17.5H36.4759C40.642 17.5 43.4586 20.9526 43.4586 24.2864C43.4586 27.6197 41.2876 31.2507 37.4733 31.2507Z"
        fill="#1D1D1B"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M43.9561 31.25H48.4261V20.75H43.9561V31.25Z" fill="#1D1D1B" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.8696 21.75C56.3341 21.1586 55.1661 20.5 53.204 20.5C50.8849 20.5 48.9822 21.7421 48.9822 23.5163C48.9822 25.2904 49.5212 26.2165 52.0742 27.4192C53.204 27.9516 52.9662 28.7206 52.1337 28.7206C51.3013 28.7206 50.4688 28.543 48.9229 27.4192V30.7901C49.8742 31.2634 51.0634 31.5 52.8473 31.5C54.6311 31.5 57.3663 31.0861 57.3663 28.1879C57.3663 26.9464 55.9392 25.527 54.2148 24.8173C52.8413 24.2521 53.202 23.3387 54.1554 23.3387C54.6311 23.3387 56.0963 23.8708 56.8696 24.5808"
        fill="#1D1D1B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.7002 18.7501C44.7002 17.9216 45.3671 17.25 46.1901 17.25C47.0131 17.25 47.6802 17.9216 47.6802 18.7501C47.6802 19.5784 47.0131 20.25 46.1901 20.25C45.3671 20.25 44.7002 19.5784 44.7002 18.7501Z"
        fill="#1D1D1B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66.5544 21.0295C65.5092 20.6766 64.6964 20.5 63.5356 20.5C62.3745 20.5 57.6143 21.1472 57.6143 26.0291C57.6143 30.9115 62.3163 31.5 63.5356 31.5C64.7548 31.5 66.1479 31.1471 66.5544 30.9705V27.4411C65.9736 27.7938 65.1029 28.0293 64.3481 28.0293C63.5937 28.0293 61.836 27.5296 61.836 26C61.836 24.4709 63.4197 24.0883 64.3481 24.0883C65.277 24.0883 66.1479 24.5 66.5544 24.7942V21.0295Z"
        fill="#1D1D1B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.6429 27.5V20.5H86.4212V26.75C86.4212 27.8814 85.2189 28 84.807 28C84.3954 28 83.6895 27.524 83.6895 26.75V20.5H79.4678V28C79.4678 30.3214 81.4739 31.5 82.9445 31.5C84.4148 31.5 86.2118 30.821 86.9179 29.75C87.5645 31.0591 87.9051 31.25 91.1396 31.25V28.5C90.6322 28.5 90.6429 28.2249 90.6429 27.5Z"
        fill="#1D1D1B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.9159 28.0588C73.8762 28.0588 74.6545 27.1634 74.6545 26.0586C74.6545 24.954 73.8762 24.0588 72.9159 24.0588C71.9562 24.0588 71.1777 24.954 71.1777 26.0586C71.1777 27.1634 71.9562 28.0588 72.9159 28.0588ZM66.8027 25.9998C66.8027 22.9624 69.5266 20.5 72.8869 20.5C76.2471 20.5 78.9712 22.9624 78.9712 25.9998C78.9712 29.0373 76.2471 31.5 72.8869 31.5C69.5266 31.5 66.8027 29.0373 66.8027 25.9998Z"
        fill="#1D1D1B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.248047 7.75C0.248047 4.43637 2.91644 1.75 6.20814 1.75C9.49983 1.75 12.1682 4.43637 12.1682 7.75C12.1682 11.0639 9.49983 13.75 6.20814 13.75C2.91644 13.75 0.248047 11.0639 0.248047 7.75Z"
        fill="#E20615"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M0.248047 31.25H12.1682V14.25H0.248047V31.25Z" fill="#E20615" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4102 31.25C21.3895 31.25 28.5587 24.6464 28.5587 16.5007C28.5587 8.35506 21.3895 2 13.4102 2V31.25Z"
        fill="#E20615"
      />
    </g>
    <g clipPath="url(#clip1_1656_12253)">
      <path d="M168 0H128V40H168V0Z" fill="white" />
      <path d="M149.691 12.3418L156.43 10.4584L161.476 27.7371L154.738 29.6205L149.691 12.3418Z" fill="#CEE7D5" />
      <path
        d="M139.562 12.3293L139.585 12.4077L144.614 29.6323L151.362 27.7469L146.31 10.4434L139.562 12.3293Z"
        fill="#CEE7D5"
      />
      <path
        d="M147.06 31.9389C145.21 31.421 144.129 29.5135 144.658 27.7L149.694 10.4162C150.222 8.60275 152.168 7.54318 154.018 8.06113C155.868 8.57909 156.949 10.4866 156.421 12.3001L151.384 29.5838C150.856 31.3966 148.91 32.4569 147.06 31.9389Z"
        fill="#00AE6E"
      />
      <path
        d="M136.865 31.9389C135.015 31.421 133.934 29.5135 134.462 27.7L139.499 10.4162C140.027 8.60275 141.973 7.54318 143.823 8.06113C145.673 8.57909 146.754 10.4866 146.225 12.3001L141.189 29.5838C140.66 31.3966 138.715 32.4569 136.865 31.9389Z"
        fill="#00AE6E"
      />
      <path
        d="M154.602 28.6328C154.602 30.5315 156.184 32.0703 158.137 32.0703C160.089 32.0703 161.672 30.5309 161.672 28.6328C161.672 26.7347 160.089 25.1953 158.137 25.1953C156.185 25.1953 154.602 26.7341 154.602 28.6328Z"
        fill="#00AE6E"
      />
    </g>
    <defs>
      <clipPath id="clip0_1656_12253">
        <rect width="112" height="40" fill="white" />
      </clipPath>
      <clipPath id="clip1_1656_12253">
        <rect width="40" height="40" fill="white" transform="translate(128)" />
      </clipPath>
    </defs>

    {altText && <title>{altText}</title>}
  </SvgContainer>
);

MorphingLogoInterdiscountMedium.displayName = 'atoms/MorphingLogoInterdiscountMedium';

MorphingLogoInterdiscountMedium.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  vbXCoords: PropTypes.number,
  vbYCoords: PropTypes.number,
  className: PropTypes.string,
  altText: PropTypes.string,
};

MorphingLogoInterdiscountMedium.defaultProps = {
  height: 40,
};

export default MorphingLogoInterdiscountMedium;
