import * as TA from 'constants/trackingAttributes/trackingAttributes';
import { getCategoriesPath, getStringCategoriesName } from 'constants/categoryTree/categoryTree';

import language, { getLocale, isValidLocale, removeLocaleFromUrl } from 'constants/language/language';

import {
  CART_ID,
  DSGV_CONSENTS,
  IS_LOGGED_IN,
  ONLINE_ID,
  SUPERCARD_CUSTOMER,
  USER_ID,
} from 'constants/storage/storageKeys';
import { NON_CMS_PAGE } from 'constants/cms/cms';
import getStorage from 'constants/storage/storage';
import { isB2BCustomer } from 'constants/customerGroup/customerGroup';
import { getPrice } from 'components/molecules/CartProductCounter/CartProductCounter';
import { mergeQueryString } from 'constants/urlMapping/urlMapping';
import keepTrying from 'constants/helper/keepTrying';
import { apiStatus } from 'constants/apiStatus/apiStatus';
import { ProductViewTypes } from 'constants/ProductViewTypes/ProductViewTypes';
import isTelesalesMode from 'constants/helper/telesalesMode';

import config from './tracking.config';
import { getSelectedFilters } from 'constants/facetsHelper/facetsHelper';

const localStorage = getStorage(true);

export const ET = {
  ACCESSOIRES_POPUP: 'accessoires-popup',
  LIST_ROW: 'list-row',
  LIST_TILE: 'list-tile',
};

/**
 * Adds fields to data object
 * @param data
 * @param fieldsConst
 * @param validityConst
 * @param fields
 * @returns {*}
 */
const addFields = (data, fieldsConst, validityConst, fields) => {
  if (fields) {
    const keys = [];
    const validity = [];
    Object.values(fields).forEach((v) => {
      keys.push(v.name);
      validity.push(String(v.validationResult));
    });

    return { ...data, [fieldsConst]: keys, [validityConst]: validity };
  }
  return data;
};

/**
 * Add TrackingData object with field validation data
 * @param data
 * @param registerFields - register fields with .name and .validationResult
 * @param paymentFields - payment fields with .name and .validationResult
 * @param deliveryFields - delivery fields with .name and .validationResult
 * @param loginFields - login fields with .name and .validationResult
 * @returns TrackingData as object
 */
export const addUtagDataFieldValidity = (data, registerFields, paymentFields, deliveryFields, loginFields) => {
  let newData = {};

  newData = addFields(newData, TA.USER_REGISTER_FIELDS, TA.USER_REGISTER_VALIDITY, registerFields);
  newData = addFields(newData, TA.TRANSACTION_PAYMENT_FIELDS, TA.TRANSACTION_PAYMENT_VALIDITY, paymentFields);
  newData = addFields(newData, TA.TRANSACTION_DELIVERY_FIELDS, TA.TRANSACTION_DELIVERY_VALIDITY, deliveryFields);
  newData = addFields(newData, TA.USER_LOGIN_FIELDS, TA.USER_LOGIN_VALIDITY, loginFields);

  return { ...data, ...newData };
};

/**
 * Add TrackingData object with form error data
 * @param data - object to add to
 * @param eventType - used to send eventType + '-failure'
 * @param registerFields - register fields validity
 * @param paymentFields - payment fields validity
 * @param deliveryFields - delivery fields validity
 * @param loginFields - login fields validity
 * @returns TrackingData as object
 */
export const addUtagDataFormError = (data, eventType, registerFields, paymentFields, deliveryFields, loginFields) => {
  let newData = {};

  newData[TA.EVENT_ATTR_NAME] = `${eventType}-failure`;

  newData = addUtagDataFieldValidity(newData, registerFields, paymentFields, deliveryFields, loginFields);

  return { ...data, ...newData };
};

/**
 * Add TrackingData object for search as you type tracking
 * @param products - The prodcuts returned by the search as you type request
 * @param categories - The categories, as they are in the state
 */
export const createUtagSearchAsYouType = (state) => {
  const withoutZero = (str) => {
    if (str[0] !== '0') return str;
    return withoutZero(str.substring(1));
  };
  const categories = state.categories;
  const products = state.suggestions.result.products;

  const productAction = products.map(() => 'list');
  const elementTitle = products.map(() => 'suggestion_search');
  const skuNonZero = products.map((p) => withoutZero(p.code));

  const newData = {};

  newData[TA.PAGE_INFO_SEARCH_TERM] = state.suggestions.result.searchTerm;
  newData[TA.SEARCH_INFO_TYPE] = TA.SUGGESTION_SEARCH;
  newData[TA.PRODUCT_ACTION] = productAction;
  newData[TA.PRODUCT_INFO_ELEMENT_TITLE] = elementTitle;
  newData[TA.PRODUCT_INFO_SKU_NOZERO] = skuNonZero;

  newData[TA.TOOLGA_NONINTERACTIONEVENT] = true;
  newData[TA.EVENT_ATTR_NAME] = 'product_impression_data';
  newData[TA.SEARCH_INFO_TYPE] = TA.SUGGESTION_SEARCH;
  newData[TA.EVENT_CATEGORY_PRIMARYCATEGORY] = 'ecommerce';

  return {
    ...addUtagDataProducts({}, products, false, categories),
    ...newData,
  };
};

/**
 * Add Category by Product to TrackingData
 * @param data - TrackingData to add to
 * @param path - array containing the categories [0]=broadest
 * @returns TrackingData as object
 */
const addUtagDataCategory = (data, path) => {
  const newData = {};

  newData[`${TA.PAGE}${TA._CATEGORY_PRIMARY}`] = 'home';

  for (let i = 0; i < path.length; i++) {
    newData[`${TA.PAGE}${TA._CATEGORY_SUB}${i + 2}`] = path[i];
  }

  return { ...data, ...newData };
};

/**
 * Add Products to TrackingData
 * @param data - TrackingData to add to
 * @param products - array of products to track
 * @param isDetailPage - boolean for detailpage
 * @returns TrackingData as object
 */
export const addUtagDataProducts = (data = {}, products = [], isDetailPage = false, categories = {}) => {
  if (!products.length) {
    return data;
  }

  const newData = {};

  newData[TA.PRODUCT_INFO_SKU] = products.map((entry) => entry.code);
  newData[TA.PRODUCT_INFO_NAME] = products.map((entry) => entry.name);
  newData[TA.PRODUCT_INFO_ELEMENT_TITLE] = products.map((entry) => entry?.elementTitle);
  newData[TA.PRODUCT_ATTR_VARIANT] = products.map((product) => product.variant);
  newData[TA.PRODUCT_ATTR_BRAND] = products.map((entry) => entry.manufacturer);
  newData[TA.PRODUCT_ATTR_FINAL_PRICE] = products.map((entry) => entry?.productPriceData?.finalPrice?.value);
  newData[TA.PRODUCT_ATTR_BASE_PRICE] = products.map((entry) => entry?.productPriceData?.basePrice?.value);
  newData[TA.PRODUCT_ATTR_INSTEAD_PRICE] = products.map((entry) => entry?.productPriceData?.insteadPrice?.value);
  newData[TA.PRODUCT_ATTR_AVAILABILITY] = products.map((entry) => entry.availabilityStatus);
  newData[TA.PRODUCT_ATTR_PRODUCT_BOX_ID] = products.map((entry) => entry.productBoxId);
  newData[TA.PRODUCT_ATTR_PRODUCT_RECO_SERVICE] = products.map((entry) => entry.recoService);

  if (isDetailPage) {
    // add salesforce related information
    newData[TA.PRODUCT_INFO_MANUFACTURERARTICLENO] = products.map((entry) => entry.manufacturerAID);
    newData[TA.PRODUCT_INFO_WARRANTY] = products.map((entry) => entry.warranty);
    newData[TA.PRODUCT_INFO_ACTIVE] = products.map((entry) => entry.sapVisibility);
    newData[TA.PRODUCT_INFO_ARTICLESELLABLE] = products.map((entry) => entry.sapSaleable);
    newData[TA.PRODUCT_INFO_RELEASEDATE] = products.map((entry) => entry.releaseDate || '');
    newData[TA.PRODUCT_INFO_AVAILABLEFROM] = products.map((entry) => entry.availableOn || '');
    newData[TA.PRODUCT_INFO_STOCKLEVEL] = products.map((entry) => entry?.stock?.stockLevel);
    newData[TA.PRODUCT_INFO_AVAILABILITYSTATUSCODE] = products.map((entry) => entry.availabilityStatus);
    newData[TA.PRODUCT_INFO_MARGIN] = products.map((entry) => entry?.productPriceData?.savings?.value || '');
    newData[TA.PRODUCT_INFO_IMAGEURL] = products.map((entry) => entry?.customImageData[0]?.sizes[0]?.url);
  }
  newData[TA.PRODUCT_ATTR_QUANTITY] = ['1'];

  return { ...data, ...newData, ...addProductCategoriesData(products, categories) };
};

export const addProductCategoriesData = (products, categories) => {
  const primaryCategories = [];
  const subCategories1 = [];
  const lowestCategories = [];
  // WORKAROUND: SPQR-9682
  const primaryCategoryCodes = [];
  const subCategory1Codes = [];
  const lowestCategoryCodes = [];

  for (const product of products) {
    const categoriesPath = getCategoriesPath(product.categoryCode, categories);

    primaryCategoryCodes.push(categoriesPath[0]);
    primaryCategories.push(getStringCategoriesName(categoriesPath[0], getLocale(), categories));

    subCategory1Codes.push(categoriesPath[1]);
    subCategories1.push(getStringCategoriesName(categoriesPath[1], getLocale(), categories));

    lowestCategoryCodes.push(categoriesPath[categoriesPath.length - 1]);
    lowestCategories.push(getStringCategoriesName(categoriesPath[categoriesPath.length - 1], getLocale(), categories));
  }

  return {
    [TA.PRODUCT_CATEGORY_PRIMARYCATEGORY]: primaryCategories,
    [TA.PRODUCT_CATEGORY_SUBCATEGORY1]: subCategories1,
    [TA.PRODUCT_CATEGORY_LOWESTCATEGORY]: lowestCategories,

    // add category codes
    [TA.PRODUCT_CATEGORY_CODE_PRIMARYCATEGORY]: primaryCategoryCodes,
    [TA.PRODUCT_CATEGORY_CODE_SUBCATEGORY1]: subCategory1Codes,
    [TA.PRODUCT_CATEGORY_CODE_LOWESTCATEGORY]: lowestCategoryCodes,
  };
};

/**
 * Add Products to TrackingData
 * @param data - TrackingData to add to
 * @param products - array of products to track
 * @returns TrackingData as object
 */
const addUtagDataCartProducts = (data, products, categories = {}) => {
  if (!products) {
    return data;
  }

  const newData = {};

  newData[TA.PRODUCT_INFO_SKU] = products.map((entry) => entry.code);
  newData[TA.PRODUCT_INFO_NAME] = products.map((entry) => entry.name);
  newData[TA.PRODUCT_ATTR_VARIANT] = products.map((product) => product.variant);
  newData[TA.PRODUCT_ATTR_BRAND] = products.map((entry) => entry.manufacturer);
  newData[TA.PRODUCT_ATTR_AVAILABILITY] = products.map((entry) => entry.availabilityStatus);

  return { ...data, ...newData, ...addProductCategoriesData(products, categories) };
};

/**
 * Add registration data to TrackingData
 * @returns TrackingData as object
 */
export const addUtagDataRegistration = () => ({
  [TA.EVENT_ATTR_NAME]: 'account_signup',
});

/**
 * Add data for Registration Page
 * @returns TrackingData as object
 */
export const addUtagDataRegistrationPage = () => {
  const data = {};

  data[TA.CONVERSION_FUNNEL_STEP_NR] = '1';
  data[TA.CONVERSION_FUNNEL_STEP] = ['registration'];
  data[TA.CONVERSION_FUNNEL_NAME] = 'registration';
  data[TA.PAGE_INFO_NAME] = 'registration';
  data[TA.EVENT_ATTR_NAME] = 'registration';
  data[TA.PAGE_ATTR_VIRTUAL_PAGE_VIEW] = '1';
  data[TA.PAGE_ATTR_URL_HOST] = window.location.hostname;
  data[TA.PAGE_ATTR_URL_PATH] = window.location.pathname;

  data[TA.PAGE_CATEGORY_PRIMARY] = 'home';
  data[`${TA.PAGE_CATEGORY_SUB}2`] = 'account';
  data[`${TA.PAGE_CATEGORY_SUB}3`] = 'registration';

  return data;
};

export const createUtagDataRecoProduct = (state, recoType, recoBoxLevel) => {
  const data = {};
  const reco = state.recommendations[recoType];
  const recoBox = reco[recoBoxLevel] || [];
  const recoSimilar = reco?.similar?.products || [];
  const recoBoughtByOthers = reco?.boughtByOthers?.products || [];

  const recoProducts = [...recoBox, ...recoSimilar, ...recoBoughtByOthers]
    .map((recoProduct) => ({
      ...state.products?.[recoProduct.productId],
      code: recoProduct.productId,
      elementTitle: recoProduct.elementTitle,
      recoService: recoProduct.trackingToken,
    }))
    .filter((recoProduct) => recoProduct.code);

  return addUtagDataProducts(data, recoProducts, false, state.categories);
};

/**
 * Creates TrackingData for ProductDetail view
 * @param product - product object
 * @returns TrackingData as object
 */
export const createUtagDataProductDetailPromise = (product, categories, store) =>
  new Promise((resolve) => {
    if (!product) {
      resolve({});
    } else {
      const productCode = product.code;
      keepTrying(
        () => {
          const newState = store.getState();
          const isReady = !!newState.recommendations?.[productCode];
          const isAccessoiresProductsReady = newState.products?.[productCode]?.referenceItemCodes?.every(
            (accessoiresProductCode) => !!newState.products?.[accessoiresProductCode]
          );
          return isReady && isAccessoiresProductsReady;
        },
        () => {
          const newState = store.getState();
          resolve(newState);
        }
      );
    }
  }).then((state) => {
    if (/search=/.test(window.location.search)) {
      return Promise.resolve();
    }
    const data = {};
    data[TA.CONVERSION_FUNNEL_NAME] = ['abverkauf'];
    data[TA.CONVERSION_FUNNEL_STEP] = ['view-item', 'visit-site'];
    data[TA.EVENT_ATTR_NAME] = 'detail';
    if (!product) {
      return data;
    }
    data[TA.PAGE_INFO_NAME] = product.name;

    const productCode = product.code;
    const accessoiresProducts = (state.products?.[productCode]?.referenceItemCodes || [])
      .map((accessoiresProductCode) => ({
        ...state.products?.[accessoiresProductCode],
        elementTitle: language('cart.addAccessories'),
      }))
      .filter(Boolean);

    const visibleProductsCodes = state.ui.visibleProducts?.map((visibleProduct) => visibleProduct?.code) || [];
    const visibleProducts = accessoiresProducts.filter((product) => visibleProductsCodes.includes(product.code));
    const products = [{ ...product, elementTitle: 'detail' }, ...visibleProducts];

    return addUtagDataProducts(data, products, false, state.categories);
  });

/**
 * Creates TrackingData for ProductOverview view
 * @param query - active search query
 * @param products
 * @returns TrackingData as object
 */
export const createUtagDataProductOverview = (store) => {
  const state = store.getState();
  const {
    productsQueries: { currentQuery: query = {} },
    products,
    categories,
    ui: { productViewType },
  } = state;
  let data = {};

  if (!query) {
    return data;
  }

  const isSearchPage = query.searchString?.length > 0;
  const lastBreadcrumb = query.breadcrumb?.slice(-1)?.[0]?.name || '';

  data[TA.CONVERSION_FUNNEL_NAME] = ['abverkauf'];
  data[TA.CONVERSION_FUNNEL_STEP] = ['visit-site'];

  data[TA.FILTER_ELEMENT_NAME] = 'Productlist';

  const selectedFilters = getSelectedFilters(query?.facets);
  data[TA.FILTER_ELEMENT_SECTION] = Object.keys(selectedFilters);
  data[TA.FILTER_ELEMENT_VALUE] = Object.values(selectedFilters);

  if (isSearchPage) {
    data[TA.EVENT_ATTR_NAME] = 'search';
  } else {
    data[TA.EVENT_ATTR_NAME] = 'category';
  }

  data[TA.PRODUCT_LIST_NAME] = lastBreadcrumb;

  data[TA.FILTER_INFO_CRITERIA] = (query.selectedFacetsCount || 0).toString();
  data[TA.FILTER_INFO_TERM] = query.queryString || '';
  data[TA.FILTER_INFO_SORT] = query.sort || '';

  data[TA.FILTER_RESULT_CURRENT_PAGE] = (query.pagination?.currentPage + 1 || 1).toString();
  data[TA.FILTER_INFO_TYPE] = 'global';

  if (query.breadcrumb?.length > 1) {
    const breadcrumbs = query.breadcrumb.slice(0, query.breadcrumb.length - 1);
    data[TA.FILTER_INFO_TYPE] = breadcrumbs[breadcrumbs.length - 1]?.name;
    data = addUtagDataCategory(
      data,
      breadcrumbs.map((crumb) => crumb.name)
    );
  }

  data[TA.PAGE_INFO_NAME] = lastBreadcrumb;
  data[TA.PAGE_INFO_ONSITE_RESULTS_TOTAL] = (query.pagination?.totalNumberOfResults || 0).toString();
  data[TA.PAGE_INFO_ONSITE_RESULTS_DISPLAYED] = (query.visibleProducts?.length || 0).toString();

  const categoryUtagData = createCategoryUtagData({ query, categoryTree: categories });
  Object.assign(data, categoryUtagData);

  if (isSearchPage) {
    data[TA.PAGE_INFO_SEARCH_TERM] = query.searchString || '';
  }

  const visibleProducts =
    query.visibleProducts && products
      ? query.visibleProducts.map((code) => ({
          ...products[code],
          elementTitle: productViewType === ProductViewTypes.LIST ? ET.LIST_ROW : ET.LIST_TILE,
        }))
      : [];

  return addUtagDataProducts(data, visibleProducts, false, categories);
};

export const getProductDataFromCart = (cart = {}) => {
  const finalPrices = [];
  const quantities = [];
  const products = [];

  if (Array.isArray(cart.entries)) {
    cart.entries.forEach((entry) => {
      finalPrices.push(getPrice(entry));
      quantities.push(entry.quantity.toString());

      entry.serviceItems?.forEach((service) => {
        finalPrices.push(service.price.value);
        quantities.push(entry.quantity.toString());
      });

      products.push(entry.product);
      entry.serviceItems
        ?.map((service) => ({
          ...entry.product,
          ...service,
          manufacturer: null,
          availabilityStatus: null,
          variant: entry.product.code,
        }))
        .forEach((service) => products.push(service));
    });
  }

  return {
    finalPrices,
    quantities,
    products,
  };
};

/**
 * Creates TrackingData for Cart products
 * @param cart - current cart object
 * @returns TrackingData as object
 */
export const createUtagDataCart = (cart, categories) => {
  if (!cart) {
    return {};
  }

  const productData = getProductDataFromCart(cart);
  const newData = {};
  const isLoggedIn = localStorage.getItem(IS_LOGGED_IN);

  newData[TA.EVENT_ATTR_NAME] = 'cart';
  newData[TA.PAGE_INFO_NAME] = language('cart.title');

  newData[TA.PRODUCT_ATTR_FINAL_PRICE] = productData.finalPrices;
  newData[TA.PRODUCT_ATTR_QUANTITY] = productData.quantities;

  newData[TA.TRANSACTION_ATTR_FUNNEL_TYPE] = isLoggedIn ? 'Kunde' : 'Gast';
  newData[TA.TRANSACTION_ATTR_FUNNEL_STEP] = '1';

  return addUtagDataCartProducts(newData, productData.products, categories);
};

/**
 * Creates TrackingData for Checkout view
 * @param cart - current cart object
 * @returns TrackingData as object
 */
export const createUtagDataCheckout = (cart = {}, categories) => {
  let data = {};

  if (!Object.entries(cart).length) {
    return data;
  }

  const isLoggedIn = localStorage.getItem(IS_LOGGED_IN);

  data = { ...createUtagDataCart(cart, categories), ...data };

  data[TA.EVENT_ATTR_NAME] = 'checkout';
  data[TA.CONVERSION_FUNNEL_NAME] = ['abverkauf'];
  data[TA.CONVERSION_FUNNEL_STEP] = ['start-checkout'];
  data[TA.PAGE_INFO_NAME] = 'checkout';
  data[TA.TRANSACTION_ATTR_FUNNEL_TYPE] = isLoggedIn ? 'Kunde' : 'Gast';
  data[TA.TRANSACTION_ATTR_FUNNEL_STEP] = '3';

  return data;
};

/**
 * Creates TrackingData for Checkout login
 * @param cart - current cart object
 * @returns TrackingData as object
 */
export const createUtagDataCheckoutLogin = (cart = {}, categories) => {
  let data = {};

  if (!Object.entries(cart).length) {
    return data;
  }

  const isLoggedIn = localStorage.getItem(IS_LOGGED_IN);

  data = { ...createUtagDataCart(cart, categories), ...data };

  data[TA.EVENT_ATTR_NAME] = 'checkout';
  data[TA.CONVERSION_FUNNEL_NAME] = ['abverkauf'];
  data[TA.CONVERSION_FUNNEL_STEP] = ['start-checkout'];
  data[TA.PAGE_INFO_NAME] = 'checkout';
  data[TA.TRANSACTION_ATTR_FUNNEL_TYPE] = isLoggedIn ? 'Kunde' : 'Gast';
  data[TA.TRANSACTION_ATTR_FUNNEL_STEP] = '2';

  return data;
};

/**
 * Creates TrackingData for Checkout view
 * @param cart - current cart object
 * @param products - product list from store
 * @param uid - user id
 * @param transactionId - order code of the last order
 * @returns TrackingData as object
 *
 * todo: bigger refactoring needed
 */
export const createUtagDataPurchase = (cart = {}, products, uid, transactionId, categories) => {
  let data = {};

  if (!Object.entries(cart).length) {
    return data;
  }

  data[TA.EVENT_ATTR_NAME] = 'purchase';
  data[TA.CONVERSION_FUNNEL_NAME] = ['abverkauf'];
  data[TA.CONVERSION_FUNNEL_STEP] = ['complete'];

  data = { ...createUtagDataCart(cart, categories), ...data };

  data[TA.PAGE_INFO_NAME] = 'purchase';

  data[TA.CONVERSION_INFO_LABEL] = `${config.customer}-einkauf-getätigt`;
  data[TA.CONVERSION_CATEGORY_PRIMARY] = 'abverkauf';

  data[TA.TRANSACTION_ATTR_PAYMENT_METHOD] = cart.orderData?.paymentMode?.reference;
  // NOT AVAILABLE
  // data.transaction_attributes_taxValue= ['TaxA','TaxB','TaxC'];
  // data.transaction_item_price_totalPrice= ['20.80','6.00','100.00'];
  // data.transaction_item_price_voucherCode= ['ProduktVoucherA','ProduktVoucherB','ProduktVoucherC'];

  // NECESSARY?
  // todo; remove this bullshit, thx
  const _2digits = (number) => {
    if (number < 10) {
      return `0${number}`;
    }
    return number;
  };
  const now = new Date();
  const date = `${now.getUTCFullYear()}-${_2digits(now.getUTCMonth() + 1)}-${_2digits(now.getUTCDate() + 1)}`;
  const time = `${_2digits(now.getUTCHours())}:${_2digits(now.getUTCMinutes())}:${_2digits(now.getUTCSeconds())}`;

  data[TA.TRANSACTION_ATTR_DATE] = date;
  data[TA.TRANSACTION_ATTR_TIME] = time;

  if (cart.appliedVouchers) {
    const voucherCode = cart.appliedVouchers.map((voucher) => voucher.voucherCode);
    data[TA.TRANSACTION_TOTAL_VOUCHER_CODE] = voucherCode;
    data[TA.TRANSACTION_ATTR_DIGITAL_VOUCHER] = voucherCode;
  }

  data[TA.TRANSACTION_TOTAL_SHIPPING] = (cart.deliveryCost?.value || 0).toString();
  data[TA.TRANSACTION_TOTAL_FINAL_PRICE] = (cart.totalPriceNet?.value || 0).toString();
  data[TA.TRANSACTION_TOTAL_TOTAL_PRICE] = cart.value?.value?.toString();
  data[TA.TRANSACTION_TRANSACTION_ID] = transactionId;
  data[TA.TRANSACTION_ITEM_PRICE_TOTAL_PRICE] = (cart.value?.value || 0).toString();
  data[TA.TRANSACTION_ATTR_TAX_VALUE] = (cart.totalFullTax?.value || 0).toString();
  data[TA.TRANSACTION_ATTR_SHIPPING_METHOD] = cart.orderData?.deliveryMode?.reference || '';
  data[TA.TRANSACTION_ATTR_FUNNEL_TYPE] = uid !== 'anonymous' ? 'Kunde' : 'Gast';
  data[TA.TRANSACTION_ATTR_FUNNEL_STEP] = 'purchase';
  data[TA.WEBTRENDS_TX_DATE] = date;
  data[TA.WEBTRENDS_TX_EVENT_TYPE] = 'p';
  // data[TA.WEBTRENDS_BOUNCE_VALUE] = '1';
  data[TA.WEBTRENDS_EVENT_TYPE] = '99';

  return data;
};

/**
 * Creates TrackingData for AddToCart action
 * @param product - added product object
 * @param quantity - amount of the products added
 * @param isAccessory - used to mark "accessory"
 * @returns TrackingData as object or null if not valid parameters
 */
export const createUtagDataAddToCart = (product, isAccessory, categories, quantity = 1) => {
  const data = {};

  const newData = addUtagDataProducts(data, [product], false, categories);

  newData[TA.EVENT_ATTR_NAME] = `add${isAccessory ? '_accessory' : ''}`;
  newData[TA.CONVERSION_FUNNEL_NAME] = ['abverkauf'];
  newData[TA.CONVERSION_FUNNEL_STEP] = ['add-to-cart'];

  newData[TA.PRODUCT_ATTR_QUANTITY] = [`${quantity}`];

  return newData;
};

function createServiceData(product, categories, serviceCode, quantity = 1) {
  const service = product.productServices.find((productService) => productService.code === serviceCode);
  const serviceData = {
    ...product,
    ...service,
    variant: product.code,
  };
  const data = createUtagDataAddToCart(serviceData, false, categories, quantity);
  data[TA.PRODUCT_ATTR_FINAL_PRICE] = [service.price.value];
  data[TA.PRODUCT_ATTR_AVAILABILITY] = [null];
  data[TA.PRODUCT_ATTR_BRAND] = [null];

  return data;
}

export function createUtagDataAddService(product, categories, addedServiceCode, quantity) {
  const data = createServiceData(product, categories, addedServiceCode, quantity);

  data[TA.EVENT_ATTR_NAME] = 'add_service';

  return data;
}

export function createUtagDataRemoveService(product, categories, removedServiceCode, quantity) {
  const data = createServiceData(product, categories, removedServiceCode, quantity);

  data[TA.EVENT_ATTR_NAME] = 'remove_service';
  data[TA.CONVERSION_FUNNEL_STEP] = ['remove-from-cart'];

  return data;
}

/**
 * Creates TrackingData for RemoveFromCart action
 * @param product - removed product object
 * @param quantity - amount of the products removed
 * @returns TrackingData as object or null if not valid parameters
 */
export const createUtagDataRemoveFromCart = (product, quantity, categories) => {
  const data = {};

  if (!product) {
    return null;
  }

  const newData = addUtagDataProducts(data, [product], false, categories);

  newData[TA.EVENT_ATTR_NAME] = 'remove';
  newData[TA.CONVERSION_FUNNEL_NAME] = ['abverkauf'];
  newData[TA.CONVERSION_FUNNEL_STEP] = ['remove-from-cart'];

  newData[TA.PRODUCT_ATTR_QUANTITY] = [`${quantity}`];

  return newData;
};

/**
 * Creates the utag data for product clicks
 * @param product
 * @param context e.g. Mobiltelefone-411000
 * @returns {*}
 */
export const createUtagDataClickOnProduct = (product, context = '', categories) => {
  let data = {};

  if (!product) {
    return null;
  }

  data = addUtagDataProducts(data, [product], categories);

  if (context.length) {
    data[TA.PRODUCT_LIST_NAME] = context;
  }

  data[TA.EVENT_ATTR_NAME] = 'click';

  return data;
};

/**
 * Creates the data for clicks on promotions
 * @param component e.g. wall, etc.
 * @param position
 * @param href
 */
export const createUtagDataClickOnCms = (component, position, href) => {
  const data = {};
  const shortenedUrl = removeLocaleFromUrl(href);

  data[TA.EVENT_ATTR_NAME] = 'promo_click';
  data[TA.PROMO_INFO_CREATIVE] = component;
  data[TA.PROMO_INFO_POSITION] = position;
  data[TA.PROMO_INFO_LINK] = shortenedUrl;

  return data;
};

/**
 * Creates the data for generic interactions
 * @param context variable data set
 */
export const createUtagDataGenericInteraction = (context = {}) => {
  const TYPE = context.component_type || '';
  const LINK = context.component_link || '';
  const LOCATION = context.component_location || '';
  const INNERLOCATION = context.component_innerlocation || '';
  const INTERACTION = context.component_interaction || '';
  const ATTRIBUTE_1 = context.component_attribute1 || '';
  const ATTRIBUTE_2 = context.component_attribute2 || '';
  const ATTRIBUTE_3 = context.component_attribute3 || '';
  const ID = context.component_id || '';
  const FUNCTION = context.component_function || '';
  const SKU = context.product_productInfo_sku || '';

  return {
    [TA.EVENT_ATTR_NAME]: 'generic_interaction',
    ...(TYPE && { [TA.COMPONENT_TYPE]: TYPE }),
    ...(LINK && { [TA.COMPONENT_LINK]: LINK }),
    ...(LOCATION && { [TA.COMPONENT_LOCATION]: LOCATION }),
    ...(INNERLOCATION && { [TA.COMPONENT_INNERLOCATION]: INNERLOCATION }),
    ...(INTERACTION && { [TA.COMPONENT_INTERACTION]: INTERACTION }),
    ...(ATTRIBUTE_1 && { [TA.COMPONENT_ATTRIBUTE_1]: ATTRIBUTE_1 }),
    ...(ATTRIBUTE_2 && { [TA.COMPONENT_ATTRIBUTE_2]: ATTRIBUTE_2 }),
    ...(ATTRIBUTE_3 && { [TA.COMPONENT_ATTRIBUTE_3]: ATTRIBUTE_3 }),
    ...(ID && { [TA.COMPONENT_ID]: ID }),
    ...(FUNCTION && { [TA.COMPONENT_FUNCTION]: FUNCTION }),
    ...(SKU && { [TA.PRODUCT_INFO_SKU]: SKU }),
  };
};

/**
 * Creates the data for navigation items
 * @param context variable data set
 */
export const createUtagDataNavigationElement = (context = {}) => {
  const data = {};

  data[TA.EVENT_ATTR_NAME] = 'generic_interaction';
  data[TA.COMPONENT_TYPE] = context.component_type || '';
  data[TA.COMPONENT_LINK] = context.component_link || '';
  data[TA.COMPONENT_FUNCTION] = context.component_function || '';
  data[TA.COMPONENT_LOCATION] = context.component_location || '';
  data[TA.COMPONENT_ATTRIBUTE_1] = context.component_attribute1 || '';
  data[TA.PRODUCT_INFO_SKU] = context.component_sku || '';

  return data;
};

/**
 * Creates the head data
 */
export const createUtagDataHead = (pathname = null) => {
  const title = document.title;
  const path = pathname ?? window.location.pathname;
  const isLoggedIn = localStorage.getItem(IS_LOGGED_IN);
  const isSupercardCustomer = localStorage.getItem(SUPERCARD_CUSTOMER);
  const uid = isLoggedIn ? localStorage.getItem(USER_ID) : localStorage.getItem(CART_ID);
  const onlineId = localStorage.getItem(ONLINE_ID);
  const userAgent = navigator.userAgent;

  let newData = {};

  newData[TA.PLATFORM] = config.customer;
  newData[TA.PAGE_INFO_NAME] = title || '';
  if (isLoggedIn) {
    newData[TA.USER_PROFILE_INFO_LOGIN_STATUS] = isSupercardCustomer ? 'Kunde SCID' : 'Kunde';
  } else {
    newData[TA.USER_PROFILE_INFO_LOGIN_STATUS] = 'Anonym';
  }
  newData[TA.USER_PROFILE_INFO_CUSTOMER_TYPE] = isB2BCustomer() ? 'b2b' : 'b2c';
  newData[TA.ARRIVAL_USER_AGENT] = userAgent;
  newData[TA.PAGE_INFO_LANGUAGE] = `${getLocale()}-ch`;

  // collect category data
  let pathElements = path.split('/');
  pathElements = pathElements.filter((elem) => elem.length && !isValidLocale(elem));

  newData[TA.USER_PROFILE_INFO_EMAIL_ID] = isLoggedIn ? uid : '';

  if (isLoggedIn && onlineId) {
    newData[TA.USER_PROFILE_INFO_ONLINE_ID] = onlineId;
    newData[TA.USER_PROFILE_INFO_PROFILE_ID] = onlineId;
  }

  newData[TA.USER_PROFILE_TELESALES_AGENT] = isTelesalesMode ? 1 : 0;

  if (document.title.indexOf(language('pageTitles.notFound')) > -1) {
    newData[TA.EVENT_ATTR_NAME] = 'other';
    newData[TA.PAGE_ATTR_VIRTUAL_PAGE_VIEW] = '1';
    newData[TA.PAGE_INFO_NAME] = '404';
  }

  if (!pathElements.length) {
    newData[TA.EVENT_ATTR_NAME] = 'home';
    newData[TA.PAGE_INFO_NAME] = 'Homepage';
    newData[TA.CONTENT_INFO_TYPE] = 'Homepage';
  }

  if (path.indexOf('account') > -1) {
    newData[TA.EVENT_ATTR_NAME] = 'account';
  }

  if (path.indexOf('cms') > -1) {
    newData[TA.EVENT_ATTR_NAME] = 'content';
  }

  if (path.indexOf('service') > -1) {
    newData[TA.EVENT_ATTR_NAME] = 'service';
  }

  if (path.indexOf('orderconfirmation') > -1) {
    newData[TA.EVENT_ATTR_NAME] = 'purchase';
  }

  return newData;
};

/**
 * Creates the data body
 */
export const createUtagDataBody = (pathname = null) => {
  let newData = {};

  newData[TA.PAGE_ATTR_VIRTUAL_PAGE_VIEW] = '1';
  newData[TA.PAGE_ATTR_URL] = pathname
    ? `${window.location.protocol}//${window.location.hostname}${pathname}`
    : window.location.href;
  newData[TA.PAGE_ATTR_URL_HOST] = window.location.hostname;
  newData[TA.PAGE_ATTR_URL_PATH] = pathname ?? window.location.pathname;
  newData[TA.PAGE_ATTR_URL_QUERY] = window.location.search;

  const dsgvElts = createUtagDataDsgvConsents();
  newData = { ...newData, ...dsgvElts };

  return newData;
};

/**
 * Creates data for user consents
 * @param user
 */
export const createUtagDataDsgvConsents = () => {
  const newData = {};
  const isLoggedIn = JSON.parse(localStorage.getItem(IS_LOGGED_IN));
  const dsgvConsents = JSON.parse(localStorage.getItem(DSGV_CONSENTS));

  if (!isLoggedIn) return {};

  const calculateConsentValue = (consent) => {
    return consent ? '1' : '0';
  };

  newData[TA.USER_PROFILE_CONSENT_NEWSLETTER] = calculateConsentValue(dsgvConsents?.newsletter);
  newData[TA.USER_PROFILE_CONSENT_SMS] = calculateConsentValue(dsgvConsents?.sms);
  newData[TA.USER_PROFILE_CONSENT_IND_GLOBAL] = calculateConsentValue(dsgvConsents?.personalization);
  newData[TA.USER_PROFILE_CONSENT_IND_EMAIL] = calculateConsentValue(dsgvConsents?.personalizedNewsletter);
  newData[TA.USER_PROFILE_CONSENT_IND_SMS] = calculateConsentValue(dsgvConsents?.personalizedSms);
  newData[TA.USER_PROFILE_CONSENT_IND_POST] = calculateConsentValue(dsgvConsents?.personalizedPost);

  return newData;
};

export const createUtagDataDsvgConsentsEvent = () => {
  const newData = {};

  newData[TA.EVENT_ATTR_NAME] = 'consent_change';
  newData[TA.EVENT_CATEGORY_PRIMARY] = 'account';
  newData[TA.EVENT_EVENTINFO_EFFECT] = 'consent_change';

  return newData;
};

const getCmsDatasWithProducts = (state) => {
  const cmsDatasWithProducts = ['ProductCarouselComponent', 'CMSProductListingComponent'];
  const currentPageId = state.cms?.currentPageId;
  const currentPage = state.cms?.pages?.[currentPageId];
  const cmsDatasBefore = currentPage?.before?.cmsDatas || [];
  const cmsDatasAfter = currentPage?.after?.cmsDatas || [];
  const cmsDatas = [...cmsDatasBefore, ...cmsDatasAfter];
  const cmsDatasFiltered = cmsDatas?.filter((cmsData) => cmsDatasWithProducts.includes(cmsData.type));
  return cmsDatasFiltered;
};

const getCmsProducts = (state, cmsDatas) => {
  const page = state?.routing?.locationBeforeTransitions?.query?.page || 1;
  if (state.cms?.currentPageId === NON_CMS_PAGE) {
    // fallback for brand pages without cms page
    const brandName = state.cms?.brandName;
    const brandQuery = mergeQueryString(`query=:relevance:brand:${brandName}`, { page });
    const productsQueries = state.productsQueries || {};
    return (
      productsQueries?.[brandQuery]?.visibleProducts.map((productCode) => ({
        productCode,
        elementTitle: ET.LIST_TILE,
      })) || []
    );
  }
  const cmsProducts = cmsDatas.flatMap((cmsData) => {
    const productBoxId = cmsData.uid;
    const elementTitle = cmsData.title;
    if (cmsData.type === 'ProductCarouselComponent') {
      return cmsData?.elements?.map((element) => ({ productCode: element.code, productBoxId, elementTitle })) || [];
    }
    // CMSProductListingComponent
    const searchQuery = cmsData?.searchQuery || '';
    const productQuery = mergeQueryString(`query=${searchQuery}`, { page });
    const productsQueries = state.productsQueries || {};
    return (
      productsQueries?.[productQuery]?.visibleProducts.map((productCode) => ({
        productCode,
        productBoxId,
        elementTitle: ET.LIST_TILE,
      })) || []
    );
  });
  return cmsProducts;
};

export const createUtagDataCmsPageViewPromise = (store) =>
  new Promise((resolve) => {
    keepTrying(
      () => {
        const newState = store.getState();
        if (newState.cms?.currentPageId === NON_CMS_PAGE) {
          // fallback for brand pages without cms page
          const brandName = newState.cms?.brandName;
          const brandQuery = mergeQueryString(`query=:relevance:brand:${brandName}`);
          const productsQueries = newState.productsQueries || {};
          const isProductsQueriesReady = !!productsQueries?.[brandQuery];
          if (!isProductsQueriesReady) return false;
        } else {
          const isCmsPageReady = newState.cms?.pages?.[newState.cms?.currentPageId];
          if (!isCmsPageReady) return false;
          const cmsDatas = getCmsDatasWithProducts(newState);
          const isProductsQueriesReady = cmsDatas.every((cmsData) => {
            if (cmsData.type !== 'CMSProductListingComponent') return true;
            const searchQuery = cmsData?.searchQuery || '';
            const productQuery = mergeQueryString(`query=${searchQuery}`);
            const productsQueries = newState.productsQueries || {};
            return !!productsQueries?.[productQuery];
          });
          if (!isProductsQueriesReady) return false;
        }

        const isProductsQueriesLoaded = Object.values(newState.productsQueries).every(
          (query) => query.apiStatus !== apiStatus.request
        );
        if (!isProductsQueriesLoaded) return false;

        return true;
      },
      () => {
        const newState = store.getState();
        resolve(newState);
      }
    );
  });

export const createUtagDataCmsProducts = (state) => {
  const cmsDatas = getCmsDatasWithProducts(state);
  const cmsProducts = getCmsProducts(state, cmsDatas);
  const products = cmsProducts.map(
    (product) =>
      ({
        ...state.products?.[product.productCode],
        ...{ productBoxId: product.productBoxId },
        ...{ elementTitle: product.elementTitle },
      } || {})
  );
  return addUtagDataProducts({}, products, false, state.categories);
};

export const createUtagDataAccessoriesProducts = (products = [], categories = {}) => {
  const newProducts = products.map((product) => ({ ...product, elementTitle: ET.ACCESSOIRES_POPUP }));
  return addUtagDataProducts({}, newProducts, false, categories);
};

export const createUtagDataWatchlistProducts = (products = [], categories = {}) =>
  addUtagDataProducts({}, products, false, categories);

export const createUtagDataComparisonProducts = (products = [], category = '', categories = {}) => {
  const hydratedProducts = products.reduce((accumualtor, product) => {
    if (!product.code) return accumualtor;
    accumualtor.push({ ...product, categoryCode: category });
    return accumualtor;
  }, []);
  return addUtagDataProducts({}, hydratedProducts, false, categories);
};

export const createUtagDataProductImpressionsHead = (searchTerm) => {
  if (!searchTerm) {
    return { [TA.EVENT_ATTR_NAME]: 'product_impression_data' };
  }

  return {
    [TA.EVENT_ATTR_NAME]: 'product_impression_data',
    [TA.PAGE_INFO_SEARCH_TERM]: searchTerm,
  };
};

export const createUtagDataAccessoriesPopupHead = () => ({
  [TA.EVENT_ATTR_NAME]: 'accessories_popup',
});

export const createUtagSearch = (searchTerm, type) => ({
  [TA.EVENT_ATTR_NAME]: 'search',
  [TA.SEARCH_INFO_TYPE]: type,
  [TA.PAGE_INFO_SEARCH_TERM]: searchTerm,
});

export const createUtagMobileNavigationGeneric = () => {
  const trackingData = {};

  trackingData[TA.EVENT_ATTR_NAME] = 'generic_interaction';
  trackingData[TA.COMPONENT_LINK] = window.location.pathname;
  trackingData[TA.COMPONENT_INTERACTION] = 'click';

  return { ...trackingData };
};

export const createUtagSearchHistoryDelete = (type, url) => ({
  [TA.EVENT_ATTR_NAME]: 'generic_interaction',
  [TA.COMPONENT_TYPE]: type,
  [TA.COMPONENT_LINK]: url,
  [TA.COMPONENT_INTERACTION]: 'click',
  [TA.COMPONENT_FUNCTION]: 'clear',
});

export const createUtagExitCookieBanner = () => ({
  [TA.EVENT_ATTR_NAME]: 'generic_interaction',
  [TA.COMPONENT_TYPE]: 'cookie_banner',
  [TA.COMPONENT_INTERACTION]: 'click',
  [TA.COMPONENT_FUNCTION]: 'close',
});

export const createUtagMoreInfoCookieBanner = () => ({
  [TA.EVENT_ATTR_NAME]: 'generic_interaction',
  [TA.COMPONENT_TYPE]: 'cookie_banner',
  [TA.COMPONENT_INTERACTION]: 'click',
  [TA.COMPONENT_FUNCTION]: 'link',
});

// FOSS-913 Tracking with new category

function generateCategoryFromNumber(object, key) {
  const result = [];
  let currentKey = key;

  while (Object.prototype.hasOwnProperty.call(object, currentKey)) {
    result.push(object[currentKey]);
    currentKey = object[currentKey].parent;
  }

  return result.reverse();
}

const generateCategoryDataWithLanguage = (categories, key, language) => {
  const topLevelCategory = {};
  topLevelCategory[TA.PAGE_CATEGORY_PRIMARY] = 'home';

  const parentCategories = generateCategoryFromNumber(categories, key);

  const subCategorys = {
    ...parentCategories.reduce(
      (result, category, index) => ({
        ...result,
        [`${TA.PAGE_CATEGORY_SUB}${index + 2}`]: category[language],
      }),
      {}
    ),
  };

  return { ...topLevelCategory, ...subCategorys };
};

export const createCategoryUtagData = ({ categoryTree = null, product = null, query, pathname = null }) => {
  if (!query || Object.keys(query).length === 0) {
    if (product) {
      const code = product?.categoryCode;
      return generateCategoryDataWithLanguage(categoryTree, code, getLocale() ?? 'de');
    }
    const data = {};
    const path = pathname ?? window.location.pathname;
    path
      .split('/')
      .filter((elem) => elem.length && !isValidLocale(elem))
      .forEach((elem, idx) => (data[`${TA.PAGE_CATEGORY_SUB}${idx + 2}`] = decodeURI(elem)));
    data[TA.PAGE_CATEGORY_PRIMARY] = 'home';
    return data;
  }

  const categoryCodePath = decodeURIComponent(query.queryString)
    ?.match(/categoryPath:(\/\d+)+/g)
    ?.at(-1) // the last one is the full category path
    ?.substring('categoryPath:'.length); // remove categoryPath:
  const breadcrumbs =
    categoryCodePath
      ?.substring('/1/'.length) // remove /1/ root category
      ?.split('/')
      ?.map((categoryCode) => categoryTree[categoryCode]?.[getLocale()]) ?? [];

  const data = {};

  if (query.searchString) {
    breadcrumbs.push('search');
  }

  if (product && product.nameShort) {
    breadcrumbs.push(product.nameShort);
  }

  data[TA.PAGE_CATEGORY_PRIMARY] = 'home';
  breadcrumbs.forEach((name, idx) => (data[`${TA.PAGE_CATEGORY_SUB}${idx + 2}`] = name));
  data[TA.PAGE_CATEGORY_CODE_PATH] = categoryCodePath;
  return data;
};

export const createFilterUtagData = (query) => {
  if (!query || Object.keys(query).length === 0) {
    return null;
  }
  const selectedFilters = getSelectedFilters(query.facets);
  return {
    [TA.EVENT_ATTR_NAME]: 'filter',
    [TA.FILTER_ELEMENT_NAME]: 'Productlist',
    [TA.FILTER_INFO_CRITERIA]: (query.selectedFacetsCount || 0).toString(),
    [TA.FILTER_ELEMENT_SECTION]: Object.keys(selectedFilters),
    [TA.FILTER_ELEMENT_VALUE]: Object.values(selectedFilters),
  };
};
