import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { scrollToElement } from 'constants/scrollToElement/scrollToElement';
import { noop } from 'constants/noop/noop';
import { Col, Row } from 'components/atoms/Grid/Grid';
import Headline from 'components/atoms/Headline/Headline';
import styles from './AnchorLinks.scss';
import bootstrap from 'scss/component.scss';

const doTrackAndScroll = (link, doTrack) => {
  scrollToElement(link.linkTo, 500);
  doTrack({
    component_type: 'pds:inpagenav',
    component_attribute1: link.trackingLabel,
    component_function: 'switch-tab',
  });
};

const AnchorLinks = ({ links = [], tracking = noop }) => (
  <Row>
    <Col className={styles.wrapper}>
      <div className={styles.linkContainer}>
        {links.map((link, index) => (
          <a
            onClick={() => doTrackAndScroll(link, tracking)}
            className={cx({ [bootstrap.hiddenLgDown]: link.hideOnSmallViews }, styles.link)}
            key={index}
          >
            <Headline tag={Headline.tags.H3} margin={Headline.margins.NONE}>
              {link.displayName}
            </Headline>
          </a>
        ))}
      </div>
    </Col>
  </Row>
);

AnchorLinks.propTypes = {
  links: PropTypes.array,
  tracking: PropTypes.func,
};

AnchorLinks.displayName = 'molecules/AnchorLinks';

export default AnchorLinks;
