import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { noop } from 'constants/noop/noop';
import { Link } from 'react-router';

import AutoSuggestHighlighter from 'components/atoms/AutoSuggestHighlighter/AutoSuggestHighlighter';
import Icon, { ICON_SEARCH } from 'components/atoms/Icon/Icon';

import language from 'constants/language/language';

import styles from 'components/molecules/ThemeSuggestion/ThemeSuggestion.scss';

const ThemeSuggestion = ({ directlink, searchTerm, isActive, onClick = noop }) => {
  if (directlink.text === undefined || directlink.url === undefined) {
    return null;
  }

  // todo: remove 'toLowerCase' when BE sends all links lowercase (Also in SearchHistory.js)
  return (
    <Link
      to={decodeURI(directlink.url).toLowerCase()}
      onClick={() => onClick(directlink.text, directlink)}
      className={cx(styles.searchSuggestion, {
        [styles.active]: isActive,
      })}
    >
      <Icon path={ICON_SEARCH} className={styles.icon} />
      <div className={styles.wrapper}>
        <AutoSuggestHighlighter searchTerm={searchTerm} className={styles.highlight}>
          {directlink.text}
        </AutoSuggestHighlighter>
        <div className={styles.topicType}>{language(`search.topicTypes.${directlink.type}`)}</div>
      </div>
    </Link>
  );
};

ThemeSuggestion.displayName = 'molecules/ThemeSuggestion';

ThemeSuggestion.propTypes = {
  directlink: PropTypes.object,
  searchTerm: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
};

export default ThemeSuggestion;
