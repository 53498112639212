import { BLUR_SUPERCARD_FIELD, UPDATE_SUPERCARD_FIELD } from 'constants/ActionTypes/ActionTypes';

import userActionsBase from './userActions.base';

export const blurSupercardField = () => ({
  type: BLUR_SUPERCARD_FIELD,
});

export const updateSupercardField = (value) => ({
  type: UPDATE_SUPERCARD_FIELD,
  payload: { value },
});

export const {
  setEmail,
  createUser,
  createUserAfterGuestCheckout,
  blurPasswordField,
  updatePasswordField,
  updateEmailField,
  getUser,
  getUserConsents,
  updateUserConsents,
  getOnlineId,
  updateUser,
  deactivateUser,
  loginUser,
  changeLogin,
  changePassword,
  blurEmailField,
  subscribeNewsletter,
  acceptAGBs,
  updateAGBs,
  logoutUser,
  resetUser,
  checkToken,
  changePersonalData,
  blurPersonalDataField,
  forgottenPasswordGenerateToken,
  forgottenPasswordCheckToken,
  forgottenPasswordRestorePassword,
  initializeForgottenPasswordTokenSuccess,
  changeCustomerType,
  downloadPdf,
  changeGuest,
  saveAsMyStore,
  verifyRecaptcha,
  verifyEmail,
  blurBirthdayField,
  updateBirthdayField,
  resendDoubleOptInMail,
  rehydrateUserFields,
  requestAccountDeletion,
} = userActionsBase;

export default userActionsBase;
