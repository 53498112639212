import { getFormattedPriceValue } from 'constants/price/price';

export const getDiscount = (discount) => {
  if (!(discount instanceof Object) || Object.keys(discount).length === 0) {
    return '';
  }
  if (discount.absolute) {
    const formattedValue = getFormattedPriceValue(discount.absoluteDiscount.value);
    const beforeAndAfterDecimal = formattedValue.split('.');
    const franc = beforeAndAfterDecimal[0];
    const cent = beforeAndAfterDecimal[1];
    const hasCent = Number(cent);
    return hasCent
      ? `${discount.absoluteDiscount.currencyIso} ${formattedValue}`
      : `${discount.absoluteDiscount.currencyIso} ${franc}`;
  }
  return `${discount.percentage}%`;
};
